import { AccountGuid } from '../Accounts/Account'
import { Named } from '../common-schemas'
import { EmailAddressValue } from '../Email/EmailTypes'
import { Phone, PhoneNumberType } from '../PhoneNumbers/Phone'

export interface SimpleContactInfo {
  readonly email: EmailAddressValue
  readonly phone: Phone
}

export type SimpleContact = Named<SimpleContactDto>

export interface SimpleContactInfoDto {
  readonly email: string
  readonly phoneNumber: string
  readonly phoneNumberType: PhoneNumberType
}

export type SimpleContactDto = Named<SimpleContactInfoDto>

export type SimpleContactDtoWithAccountGuid = SimpleContactDto & {
  readonly accountGuid: AccountGuid
}

export const toSimpleContactInfo = (contact: SimpleContactDto): SimpleContactInfo => ({
  email: contact.email,
  phone: { number: contact.phoneNumber, type: contact.phoneNumberType },
})

// When you have a phone number with "type: string" instead of "type: PhoneNumberType" (if it comes out of the DB, for
// instance) then you can use this to cast it easily.
export const castSimplePhoneNumberType = (phoneNumber: Omit<Phone, 'type'> & { type: string }): Phone => ({
  ...phoneNumber,
  type: phoneNumber.type as PhoneNumberType,
})
