import { PermissionV2, debounce, noOp } from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Configure, InstantSearch, useSearchBox } from 'react-instantsearch'
import { useNavigate } from 'react-router-dom'
import {
  AlgoliaQueryHook,
  useAlgoliaClient,
} from '../../../hooks/useAlgoliaClient'
import useIsMobile from '../../../hooks/useIsMobile'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { Placeholder } from '../../../utils/Placeholder'
import { GlobalSearchInput } from '../../GlobalSearch'
import { GlobalSearchResultList } from '../../GlobalSearch/GlobalSearchDisplay'
import { GlobalSearchResultItemPreview } from '../../GlobalSearch/GlobalSearchResultItemPreview'
import {
  BreezyGlobalSearchResult,
  useBreezyHits,
} from '../../GlobalSearch/util'
import { Authorized } from '../../Permissions/Authorized/Authorized'
const ALGOLIA_SEARCH_DEBOUNCE_WAIT_TIME_MS = 250

export const AccountSearchPage = React.memo(() => {
  const companyGuid = useExpectedCompany().companyGuid

  const { client, indexName } = useAlgoliaClient()
  const queryHook = useMemo<ReturnType<typeof debounce<AlgoliaQueryHook>>>(
    () =>
      debounce((query, search) => {
        search(query)
      }, ALGOLIA_SEARCH_DEBOUNCE_WAIT_TIME_MS),
    [],
  )
  return (
    <div className="absolute inset-0 flex flex-1 flex-col overflow-auto bg-white">
      <Authorized
        to={PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL}
        notAuthorizedView={<AccountSearchUnauthorized />}
      >
        <InstantSearch
          searchClient={client}
          indexName={indexName}
          insights={true}
        >
          <Configure
            filters={`companyGuid:${companyGuid} AND recordType:ACCOUNT`}
          />
          <GlobalSearchInput queryHook={queryHook} onCancel={noOp} />
          <AccountSearchResults />
        </InstantSearch>
      </Authorized>
    </div>
  )
})

const AccountSearchResults = React.memo(() => {
  const navigate = useNavigate()
  const { query } = useSearchBox()

  const [selectedResult, setSelectedResult] = useState<
    | {
        hit: BreezyGlobalSearchResult
        sendEvent: ReturnType<typeof useBreezyHits>['sendEvent']
      }
    | undefined
  >()

  const isMobile = useIsMobile()

  const navigateToAccount = useCallback(
    (accountGuid: string) => {
      navigate(`/accounts/${accountGuid}`)
    },
    [navigate],
  )

  useEffect(() => {
    if (query !== '') {
      return
    }

    setSelectedResult(undefined)
  }, [query])

  return (
    <div className="flex min-h-0 w-full flex-1 flex-col">
      {query !== '' && (
        <>
          <div className="flex h-full w-full flex-col overflow-y-auto overflow-x-hidden md:flex-row">
            <GlobalSearchResultList
              onAccountClick={
                isMobile
                  ? (accountGuid, hit, sendEvent) => {
                      sendEvent('conversion', hit, 'Navigated via Result List')
                      navigateToAccount(accountGuid)
                    }
                  : (_, hit, sendEvent) => {
                      setSelectedResult({ hit, sendEvent })
                    }
              }
              onItemHover={noOp}
              currentlySelectedResult={selectedResult?.hit}
            />
            {!isMobile && (
              <GlobalSearchResultItemPreview
                result={selectedResult}
                onAccountClick={accountGuid => {
                  if (selectedResult) {
                    selectedResult.sendEvent(
                      'conversion',
                      selectedResult.hit,
                      'Navigated via Result List',
                    )
                  }
                  navigateToAccount(accountGuid)
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
})

const AccountSearchUnauthorized = () => (
  <div className="flex h-full w-full items-center justify-center">
    <Placeholder>Unauthorized to view this page</Placeholder>
  </div>
)

export default AccountSearchPage
