import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, useState } from 'react'
import { ActionItem } from '../../../../elements/ActionItems/ActionItems'
import ActionItemsWithEllipsis from '../../../../elements/ActionItems/ActionItemsWithEllipsis'
import { EditButton } from '../../../../elements/EditButton/EditButton'
import { m } from '../../../../utils/react-utils'
import ContentList from '../../../ContentList/ContentList'

export type ContentListItem = {
  readonly key: string
  readonly value: ReactNode | null | undefined
  readonly hideKey?: boolean
}
export type CollapsibleItemProps = {
  readonly title: ReactNode
  readonly refId?: React.ReactNode
  readonly displayId?: number
  readonly onTitleClick?: () => void
  readonly contentList?: ContentListItem[]
  readonly tag?: ReactNode
  readonly statusIndicator?: ReactNode
  readonly actionItems?: ActionItem[]
  readonly onEdit?: () => void
  readonly alwaysOpen?: boolean
  readonly defaultOpen?: boolean
}

const CollapsibleItem = m<CollapsibleItemProps>(
  ({
    title,
    refId,
    displayId,
    onTitleClick,
    contentList,
    tag,
    statusIndicator,
    actionItems,
    onEdit,
    alwaysOpen = false,
    defaultOpen = false,
  }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen)
    const hasContentListItems =
      contentList && contentList.find(item => item.value)

    return (
      <div
        className={`flex flex-col space-y-2 rounded-[8px] bg-bz-gray-200 p-3 shadow ${
          hasContentListItems && !alwaysOpen && 'hover:cursor-pointer'
        }`}
      >
        <div className="flex w-full flex-row items-center space-x-2">
          <div
            className="flex min-w-0 flex-1 flex-col"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div
              className={`${
                onTitleClick
                  ? 'text-bz-primary hover:cursor-pointer'
                  : 'text-bz-gray-900'
              } semibold_14_22 break-words`}
              onClick={e => {
                if (onTitleClick) {
                  e.stopPropagation()
                  onTitleClick()
                }
              }}
            >
              {title}
            </div>
            {refId && (
              <div className="regular_12_20 break-all text-bz-gray-700">
                Ref ID:{` `}
                {refId}
              </div>
            )}
            {displayId && (
              <div className="regular_12_20 break-all text-bz-gray-700">
                {`#${displayId}`}
              </div>
            )}
          </div>

          <div className="flex flex-row items-center justify-center space-x-2">
            {statusIndicator}
            {tag}
            {onEdit && <EditButton onClick={onEdit} />}
            {actionItems && actionItems.length > 0 && (
              <ActionItemsWithEllipsis
                direction="vertical"
                actionItems={actionItems}
              />
            )}
            {hasContentListItems && !alwaysOpen && (
              <FontAwesomeIcon
                icon={isOpen ? faChevronDown : faChevronRight}
                className="h-4 w-4 rounded-full p-1 text-bz-gray-700 hover:cursor-pointer hover:bg-gray-300 hover:text-white"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </div>
        </div>

        {hasContentListItems && (isOpen || alwaysOpen) && (
          <div className="animate-fade-in">
            <ContentList contentList={contentList} displayContents="flex" />
          </div>
        )}
      </div>
    )
  },
)

export default CollapsibleItem
