import { gql } from '../../../generated'

export const GET_INVOICE_IDENTIFYING_INFO_QUERY = gql(/* GraphQL */ `
  query GetInvoiceIdentifyingInfo($accountGuid: uuid!, $jobGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountDisplayName
    }
    jobsByPk(jobGuid: $jobGuid) {
      pointOfContact {
        fullName
      }
    }
  }
`)
