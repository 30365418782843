import { JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS } from '@breezy/shared'
import {
  faFire,
  faSackDollar,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { Col, Row } from 'antd'
import { ReactNode, memo } from 'react'
import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import JobOutcomeChip from './JobOutcomeChip'
import { JobDetailsForOutcomes } from './job-outcome-modal-types'

type ChipsPanelProps = {
  job: JobDetailsForOutcomes
  // These properties below are persisted on the Jobs model in our database. However,
  // there are instances where we use this component and we need to flag a job
  // as converted/work-completed/etc. prior to persisting this data. This distinction is why
  // we have all of these properties as separate props, rather than deriving from from
  // fetched Job data
  isJobConverted: boolean
  isMembershipSold: boolean
  isJobWorkComplete: boolean
}

const ChipsPanel = memo<ChipsPanelProps>(
  ({ job, isJobConverted, isMembershipSold, isJobWorkComplete }) => {
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

    let hotLeadChip: ReactNode = null
    if (
      JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS.includes(
        job.jobType.jobClass,
      ) &&
      job.isHotLead
    ) {
      hotLeadChip = (
        <JobOutcomeChip
          label="Hot Lead"
          success={isJobWorkComplete ? isJobConverted : false}
          iconDefinition={faFire}
        />
      )
    }

    let conversionAndOpportunityChip: ReactNode = null
    if (
      !JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS.includes(
        job.jobType.jobClass,
      )
    ) {
      conversionAndOpportunityChip = null
    } else if (isJobWorkComplete) {
      conversionAndOpportunityChip = (
        <JobOutcomeChip
          label={job.isOpportunity ? 'Opp. Converted' : 'Converted'}
          success={isJobConverted}
          resultText={isJobConverted ? 'Yes' : 'No'}
          iconDefinition={faSackDollar}
        />
      )
    } else {
      if (job.isOpportunity) {
        conversionAndOpportunityChip = (
          <JobOutcomeChip
            label="Opportunity"
            success={false}
            iconDefinition={faSackDollar}
          />
        )
      }
    }

    let membershipChip: ReactNode = null
    if (!isCompanyMaintenancePlansEnabled) {
      membershipChip = null
    } else if (job.isMembershipOpportunity) {
      if (isJobWorkComplete) {
        membershipChip = (
          <JobOutcomeChip
            label="Membership Sold"
            success={isMembershipSold}
            resultText={isMembershipSold ? 'Yes' : 'No'}
            iconDefinition={faScrewdriverWrench}
          />
        )
      } else {
        membershipChip = (
          <JobOutcomeChip
            label="Membership Opportunity"
            success={false}
            iconDefinition={faScrewdriverWrench}
          />
        )
      }
    } else if (job.isMembershipRenewalOpportunity) {
      if (isJobWorkComplete) {
        membershipChip = (
          <JobOutcomeChip
            label="Membership Renewed"
            success={isMembershipSold}
            resultText={isMembershipSold ? 'Yes' : 'No'}
            iconDefinition={faScrewdriverWrench}
          />
        )
      } else {
        membershipChip = (
          <JobOutcomeChip
            label="Membership Renewal"
            success={false}
            iconDefinition={faScrewdriverWrench}
          />
        )
      }
    }

    return (
      <Row>
        <Col className="mt-2">{hotLeadChip}</Col>
        <Col className="mt-2">{conversionAndOpportunityChip}</Col>
        <Col className="mt-2">{membershipChip}</Col>
      </Row>
    )
  },
)

export default ChipsPanel
