import {
  Account,
  AccountContact,
  AccountGuid,
  AccountJob,
  AccountLocation,
  AccountType,
  AppointmentType,
  AsyncFn,
  AttributionLinkingStrategy,
  BzAddress,
  BzTimeWindow,
  CompanyGuid,
  ComprehensiveAppointmentDetails,
  DEFAULT_SCHEDULING_CAPABILITY,
  DateTimeFormatter,
  FileRecord,
  FileStorageStrategy,
  ForCompany,
  InstallProjectType,
  InvoiceV2Status,
  JobLifecycleStage,
  JobLifecycleStatus,
  JobType,
  LoanRecord,
  LocalDate,
  MaintenancePlanCollapsibleViewModel,
  MinimalUser,
  NotificationPreferenceType,
  PaymentMethod,
  PaymentStatus,
  PaymentViewModel,
  PhoneNumberType,
  PhotoRecord,
  PrequalRecord,
  R,
  RichCompanyLeadSource,
  Tag,
  TechnicianRole,
  ZonedDateTime,
  bzExpect,
  calculateInferredAppointmentStatus,
  castSimplePhoneNumberType,
  cloneDeep,
  guidSchema,
  isNullish,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { z } from 'zod'
import { FetchComprehensiveAccountDetailsQuery } from '../../query'
import { convertFetchLoanRecordToLoanRecord } from '../loans/LoanRecordConversions'
import {
  mapGqlLocationMaintenancePlansToCollapsibles,
  mapGqlLocationMaintenancePlansToMinimals,
} from '../maintenance-plans/MaintenancePlanConversions'
import { parseEquipment, parseHvacSystem } from '../parsers'
import { convertFetchPrequalRecordToPrequalRecord } from '../prequal/PrequalRecordConversions'

export type ComprehensiveAccountDetailsJSON = FetchComprehensiveAccountDetailsQuery['accounts'][number]

// [X] Audited in BZ-921 -> No Action Required immediately
// Todo figure out a way to share the mapping logic between ComprehensiveJobDetails & ComprehensiveAccountDetails

export class ComprehensiveAccountDetails {
  constructor(private readonly data: ComprehensiveAccountDetailsJSON) {}

  toComprehensiveAccountDetailsJSON(): ComprehensiveAccountDetailsJSON {
    return cloneDeep(this.data)
  }

  toAccount(): Account {
    return {
      accountGuid: this.getAccountGuid(),
      companyGuid: this.getCompanyGuid(),
      displayName: this.getDisplayName(),
      type: this.getType(),
      referenceNumber: this.getReferenceNumber(),
      accountContacts: this.getAccountContacts(),
      accountLocations: this.getAccountLocations(),
      mailingAddress: this.getMailingAddress(),
      accountNote: this.getAccountNote(),
      accountCreatedAt: this.getAccountCreatedAt(),
      accountManager: this.getAccountManager(),
      maintenancePlans: this.getMaintenancePlans(),
    }
  }

  getAccountGuid(): AccountGuid {
    return this.data.accountGuid
  }

  getAccountManager(): MinimalUser | undefined {
    return this.data.accountManager
  }

  getReferenceNumber(): string {
    return this.data.accountReferenceNumber
  }

  getType(): AccountType {
    return this.data.accountType as AccountType
  }

  getDisplayName(): string {
    return this.data.accountDisplayName
  }

  getAccountCreatedAt(): ZonedDateTime {
    return ZonedDateTime.parse(this.data.accountCreatedAt, DateTimeFormatter.ISO_OFFSET_DATE_TIME)
  }

  getAccountNote(): string | undefined {
    return this.data.accountNote
  }

  getAppointments(): ComprehensiveAppointmentDetails[] {
    return this.data.jobs.flatMap(job => {
      return job.appointments.map(appointment => {
        const assignments = appointment.assignments.map(assignment => {
          return {
            assignmentGuid: assignment.jobAppointmentAssignmentGuid,
            assignmentStatus: assignment.assignmentStatus?.jobAppointmentAssignmentStatusType || 'TO_DO',
            technicianUserGuid: assignment.technician.userGuid,
            technician: {
              user: {
                id: assignment.technician.userGuid,
                firstName: assignment.technician.firstName,
                lastName: assignment.technician.lastName,
              },
              contact: {
                email: assignment.technician.emailAddress,
                phone: castSimplePhoneNumberType(
                  bzExpect(assignment.technician.userPhoneNumbers[0], 'Every technician should have a phone number')
                    .phoneNumber,
                ),
              },
              roles: assignment.technician.userRoles.map(
                userRole => userRole.roleById.role as unknown as TechnicianRole,
              ),
              schedulingCapability:
                assignment.technician.companyUser?.schedulingCapability ?? DEFAULT_SCHEDULING_CAPABILITY,
            },
            timeWindow: new BzTimeWindow(
              ZonedDateTime.parse(assignment.assignmentStart, DateTimeFormatter.ISO_OFFSET_DATE_TIME),
              ZonedDateTime.parse(assignment.assignmentEnd, DateTimeFormatter.ISO_OFFSET_DATE_TIME),
            ),
          }
        })

        const inferredAppointmentStatus = calculateInferredAppointmentStatus(
          appointment.cancellationStatus?.canceled || false,
          assignments.map(assignment => ({ assignmentStatus: assignment.assignmentStatus })),
        )

        const result: ComprehensiveAppointmentDetails = {
          appointmentReferenceNumber: appointment.appointmentReferenceNumber,
          appointmentGuid: appointment.jobAppointmentGuid,
          appointmentStatus: inferredAppointmentStatus,
          confirmed: appointment.confirmationStatus?.confirmed || false,
          canceled: appointment.cancellationStatus?.canceled || false,
          address: BzAddress.create(
            bzExpect(
              this.getAccountLocations().find(l => l.location.locationGuid === job.locationGuid),
              'Location should be present since it was referenced by the job',
            ).location.address,
          ),
          timeWindow: new BzTimeWindow(
            ZonedDateTime.parse(appointment.appointmentWindowStart, DateTimeFormatter.ISO_OFFSET_DATE_TIME),
            ZonedDateTime.parse(appointment.appointmentWindowEnd, DateTimeFormatter.ISO_OFFSET_DATE_TIME),
          ),
          assignments: assignments,
          jobGuid: job.jobGuid,
          jobType: job.jobType,
          associatedInstallProjectType: job.installProjectType as InstallProjectType | undefined,
          appointmentType: appointment.appointmentType as AppointmentType,
          description: appointment.description,
          endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(appointment.endOfAppointmentNextSteps),
        }

        return result
      })
    })
  }

  getPrimaryContact(): AccountContact {
    return bzExpect(
      this.getAccountContacts().find(contact => contact.primary),
      'Primary Account Contact',
    )
  }

  getAccountContacts(): AccountContact[] {
    return this.data.accountContacts.map(ac => {
      return {
        accountContactGuid: ac.accountContactGuid,
        accountGuid: this.data.accountGuid,
        companyGuid: this.data.companyGuid,
        primary: ac.primary,
        archived: ac.archived,
        contact: {
          contactGuid: ac.contact.contactGuid,
          companyGuid: this.data.companyGuid,
          firstName: ac.contact.firstName,
          lastName: ac.contact.lastName,
          salutation: ac.contact.salutation,
          title: ac.contact.title,
          notificationPreferenceType: ac.contact.notificationPreferenceType as NotificationPreferenceType,
          primaryEmailAddress: ac.contact.primaryEmailAddress
            ? {
                emailAddressGuid: ac.contact.primaryEmailAddress.emailAddressGuid,
                companyGuid: this.data.companyGuid,
                emailAddress: ac.contact.primaryEmailAddress.emailAddress,
              }
            : undefined,
          additionalEmailAddress: ac.contact.additionalEmailAddress
            ? {
                emailAddressGuid: ac.contact.additionalEmailAddress.emailAddressGuid,
                companyGuid: this.data.companyGuid,
                emailAddress: ac.contact.additionalEmailAddress.emailAddress,
              }
            : undefined,
          primaryPhoneNumber: ac.contact.primaryPhoneNumber
            ? {
                phoneNumberGuid: ac.contact.primaryPhoneNumber.phoneNumberGuid,
                companyGuid: this.data.companyGuid,
                phoneNumber: ac.contact.primaryPhoneNumber.phoneNumber,
                type: ac.contact.primaryPhoneNumber.type as PhoneNumberType,
                unsubscribed: ac.contact.primaryPhoneNumber.unsubscribed,
              }
            : undefined,
          additionalPhoneNumber: ac.contact.additionalPhoneNumber
            ? {
                phoneNumberGuid: ac.contact.additionalPhoneNumber.phoneNumberGuid,
                companyGuid: this.data.companyGuid,
                phoneNumber: ac.contact.additionalPhoneNumber.phoneNumber,
                type: ac.contact.additionalPhoneNumber.type as PhoneNumberType,
                unsubscribed: ac.contact.additionalPhoneNumber.unsubscribed,
              }
            : undefined,
        },
      }
    })
  }

  getAccountLocations(): AccountLocation[] {
    return this.data.accountLocations.map(cl => {
      return {
        accountGuid: this.data.accountGuid,
        companyGuid: this.data.companyGuid,
        isArchived: cl.isArchived,
        maintenancePlanGuids: cl.location.maintenancePlans.map(mp => mp.maintenancePlanGuid),
        location: {
          locationGuid: cl.location.locationGuid,
          displayName: cl.location.displayName,
          companyGuid: this.data.companyGuid,
          address: {
            addressGuid: cl.location.address.addressGuid,
            line1: cl.location.address.line1,
            line2: cl.location.address.line2,
            city: cl.location.address.city,
            stateAbbreviation: cl.location.address.stateAbbreviation,
            zipCode: cl.location.address.zipCode,
          },
          estimatedSquareFootage: cl.location.estimatedSquareFootage,
          estimatedBuildDate: cl.location.estimatedBuildDate
            ? LocalDate.parse(cl.location.estimatedBuildDate)
            : undefined,
          propertyType: cl.location.propertyType ?? 'unknown',
          municipality: cl.location.municipality ?? undefined,
          installedEquipment: cl.location.installedEquipment.map(parseEquipment),
          installedHvacSystems: cl.location.installedHvacSystems.map(parseHvacSystem),
          maintenancePlans: mapGqlLocationMaintenancePlansToMinimals(cl.location.maintenancePlans),
        },
      }
    })
  }

  getMailingAddress(): BzAddress | undefined {
    return this.data.mailingAddress
      ? BzAddress.create({
          line1: this.data.mailingAddress.line1,
          line2: this.data.mailingAddress.line2,
          city: this.data.mailingAddress.city,
          stateAbbreviation: this.data.mailingAddress.stateAbbreviation,
          zipCode: this.data.mailingAddress.zipCode,
        })
      : undefined
  }

  getJobs(): AccountJob[] {
    return this.data.jobs.map(job => {
      return {
        jobGuid: job.jobGuid,
        displayId: job.displayId,
        jobCreatedAt: ZonedDateTime.parse(job.createdAt, DateTimeFormatter.ISO_OFFSET_DATE_TIME),
        jobType: job.jobType as JobType,
        installProjectType: job.installProjectType as InstallProjectType | undefined,
        jobLifecycleStatus: {
          ...job.jobLifecycleStatus,
          stage: job.jobLifecycleStatus.stage as JobLifecycleStage,
          specialStatus: job.jobLifecycleStatus.specialStatus as JobLifecycleStatus['specialStatus'] | undefined,
        },
        jobLifecycleStatusUpdatedAt: job.jobLifecycleStatusUpdatedAt,
        pointOfContact: bzExpect(
          this.getAccountContacts().find(ac => ac.contact.contactGuid === job.pointOfContactGuid),
          'Account should be present',
        ),
        serviceLocation: bzExpect(
          this.getAccountLocations().find(al => al.location.locationGuid === job.locationGuid),
          'Location should be present',
        ),
        appointments: this.getAppointments().filter(appt =>
          job.appointments.map(jobAppt => jobAppt.jobAppointmentGuid).includes(appt.appointmentGuid),
        ),
        tags: job.tags.map(({ tag }) => tag),
        jobInvoices: (job.jobInvoices ?? []).map(ji => ({
          invoiceGuid: ji.invoice.invoiceGuid,
          totalUsc: ji.invoice.totalUsc,
          status: ji.invoice.status as InvoiceV2Status,
        })),
        workCompletedAt: job.workCompletedAt,
      }
    })
  }

  getCompanyGuid(): CompanyGuid {
    return this.data.companyGuid
  }

  getMaintenancePlans(): MaintenancePlanCollapsibleViewModel[] {
    return mapGqlLocationMaintenancePlansToCollapsibles(this.data.maintenancePlans)
  }

  getPayments(): PaymentViewModel[] {
    return this.data.payments.map(pl => {
      const vm: PaymentViewModel = {
        ...pl,
        ...pl.links,
        paymentMethod: pl.paymentMethod as unknown as PaymentMethod,
        loanRecord:
          !isNullish(pl.links) && !isNullish(pl.links.loanRecord)
            ? convertFetchLoanRecordToLoanRecord(pl.links.loanRecord)
            : undefined,
        accountDisplayName: pl.account?.accountDisplayName,
        status:
          pl.paymentStatusesAggregate.nodes.length > 0
            ? (pl.paymentStatusesAggregate.nodes[0].paymentStatus as unknown as PaymentStatus)
            : // NOTE: For possible non-atomic write race conditions, I think
              PaymentStatus.SUBMITTING,
      }
      return vm
    })
  }

  getLeadSource(): RichCompanyLeadSource | undefined {
    if (!this.data.accountLeadSource || this.data.accountLeadSource?.length === 0) {
      return undefined
    }

    const { companyLeadSource, referringContact, attributionDescription } = bzExpect(this.data.accountLeadSource[0])

    return {
      leadSource: {
        companyLeadSourceGuid: companyLeadSource.companyLeadSourceGuid,
        companyGuid: companyLeadSource.companyGuid,
        name:
          companyLeadSource.canonicalLeadSourceNameOverride ??
          companyLeadSource.canonicalLeadSource.canonicalLeadSourceName,
        canonicalName: companyLeadSource.canonicalLeadSource.canonicalLeadSourceName,
        attributionLinkingStrategy: (companyLeadSource.attributionLinkingStrategyOverride ??
          companyLeadSource.canonicalLeadSource.attributionLinkingStrategy) as AttributionLinkingStrategy,
        attributionPrompt:
          companyLeadSource.attributionPromptOverride ?? companyLeadSource.canonicalLeadSource.attributionPrompt,
        archivedAt: companyLeadSource.archivedAt,
      },
      referringContact: referringContact ?? undefined,
      attributionDescription: attributionDescription ?? undefined,
    }
  }

  getPhotos(): PhotoRecord[] {
    const photos: PhotoRecord[] = []

    // all files for all of the account's locations
    this.data.accountLocations.forEach(al => {
      al.location.photoLinks.forEach(p => {
        photos.find(ph => ph.photoGuid === p.photoGuid) ||
          photos.push({
            photoGuid: p.photoGuid,
            createdByUserGuid: p.photo.createdByUserGuid,
            cdnUrl: p.photo.cdnUrl,
            resourceUrn: p.photo.resourceUrn,
            createdAt: p.photo.createdAt,
          })
      })
    })

    this.data.jobs.forEach(j => {
      // all files for the job's appointments
      j.appointments.forEach(a => {
        a.photoLinks.forEach(p => {
          photos.find(ph => ph.photoGuid === p.photoGuid) ||
            photos.push({
              photoGuid: p.photoGuid,
              createdByUserGuid: p.photo.createdByUserGuid,
              cdnUrl: p.photo.cdnUrl,
              resourceUrn: p.photo.resourceUrn,
              createdAt: p.photo.createdAt,
            })
        })
        // all files for the appointments' assignments
        a.assignments.forEach(assignment => {
          assignment.photoLinks.forEach(p => {
            photos.find(ph => ph.photoGuid === p.photoGuid) ||
              photos.push({
                photoGuid: p.photoGuid,
                createdByUserGuid: p.photo.createdByUserGuid,
                cdnUrl: p.photo.cdnUrl,
                resourceUrn: p.photo.resourceUrn,
                createdAt: p.photo.createdAt,
              })
          })
        })
      })
      // all photos for the job itself
      j.photoLinks.forEach(p => {
        photos.find(ph => ph.photoGuid === p.photoGuid) ||
          photos.push({
            photoGuid: p.photoGuid,
            createdByUserGuid: p.photo.createdByUserGuid,
            cdnUrl: p.photo.cdnUrl,
            resourceUrn: p.photo.resourceUrn,
            createdAt: p.photo.createdAt,
          })
      })
    })

    // photos for the account itself
    this.data.photoLinks.forEach(p => {
      photos.find(ph => ph.photoGuid === p.photoGuid) ||
        photos.push({
          photoGuid: p.photoGuid,
          createdByUserGuid: p.photo.createdByUserGuid,
          cdnUrl: p.photo.cdnUrl,
          resourceUrn: p.photo.resourceUrn,
          createdAt: p.photo.createdAt,
        })
    })

    return R.sortWith<PhotoRecord>([R.descend(R.prop('createdAt')), R.ascend(R.prop('photoGuid'))])(photos)
  }

  getFiles(): FileRecord[] {
    const files: FileRecord[] = []

    // all files for all of the account's locations
    this.data.accountLocations.forEach(al => {
      al.location.fileLinks.forEach(f => {
        files.find(ph => ph.fileGuid === f.fileGuid) ||
          files.push({
            fileGuid: f.fileGuid,
            companyGuid: f.file.companyGuid,
            fileName: f.file.fileName,
            metadata: f.file.metadata,
            userGuid: f.file.userGuid,
            cdnUrl: f.file.cdnUrl,
            resourceUrn: f.file.resourceUrn,
            fileSizeBytes: f.file.fileSizeBytes,
            fileTypeMime: f.file.fileTypeMime,
            createdAt: f.file.createdAt,
            storageStrategy: f.file.storageStrategy as FileStorageStrategy,
          })
      })
    })

    // all files for the account's jobs
    this.data.jobs.forEach(j => {
      // all files for the job's appointments
      j.appointments.forEach(a => {
        a.fileLinks.forEach(f => {
          files.find(ph => ph.fileGuid === f.fileGuid) ||
            files.push({
              fileGuid: f.fileGuid,
              companyGuid: f.file.companyGuid,
              fileName: f.file.fileName,
              metadata: f.file.metadata,
              userGuid: f.file.userGuid,
              cdnUrl: f.file.cdnUrl,
              resourceUrn: f.file.resourceUrn,
              fileSizeBytes: f.file.fileSizeBytes,
              fileTypeMime: f.file.fileTypeMime,
              createdAt: f.file.createdAt,
              storageStrategy: f.file.storageStrategy as FileStorageStrategy,
            })
        })
        // all files for the appointments' assignments
        a.assignments.forEach(assignment => {
          assignment.fileLinks.forEach(f => {
            files.find(ph => ph.fileGuid === f.fileGuid) ||
              files.push({
                fileGuid: f.fileGuid,
                companyGuid: f.file.companyGuid,
                fileName: f.file.fileName,
                metadata: f.file.metadata,
                userGuid: f.file.userGuid,
                cdnUrl: f.file.cdnUrl,
                resourceUrn: f.file.resourceUrn,
                fileSizeBytes: f.file.fileSizeBytes,
                fileTypeMime: f.file.fileTypeMime,
                createdAt: f.file.createdAt,
                storageStrategy: f.file.storageStrategy as FileStorageStrategy,
              })
          })
        })
      })
      // all files for the job itself
      j.fileLinks.forEach(f => {
        files.find(ph => ph.fileGuid === f.fileGuid) ||
          files.push({
            fileGuid: f.fileGuid,
            companyGuid: f.file.companyGuid,
            fileName: f.file.fileName,
            metadata: f.file.metadata,
            userGuid: f.file.userGuid,
            cdnUrl: f.file.cdnUrl,
            resourceUrn: f.file.resourceUrn,
            fileSizeBytes: f.file.fileSizeBytes,
            fileTypeMime: f.file.fileTypeMime,
            createdAt: f.file.createdAt,
            storageStrategy: f.file.storageStrategy as FileStorageStrategy,
          })
      })
    })

    // all files for the account itself
    this.data.fileLinks.forEach(f => {
      files.find(ph => ph.fileGuid === f.fileGuid) ||
        files.push({
          fileGuid: f.fileGuid,
          companyGuid: f.file.companyGuid,
          fileName: f.file.fileName,
          metadata: f.file.metadata,
          userGuid: f.file.userGuid,
          cdnUrl: f.file.cdnUrl,
          resourceUrn: f.file.resourceUrn,
          fileSizeBytes: f.file.fileSizeBytes,
          fileTypeMime: f.file.fileTypeMime,
          createdAt: f.file.createdAt,
          storageStrategy: f.file.storageStrategy as FileStorageStrategy,
        })
    })

    return R.sortWith<FileRecord>([R.descend(R.prop('createdAt')), R.ascend(R.prop('fileName'))])(files)
  }

  getTags(): Tag[] {
    return this.data.tags.map(({ tag }) => tag)
  }

  getLoanRecords(): LoanRecord[] {
    return this.data.wisetackLoanRecords.map(convertFetchLoanRecordToLoanRecord)
  }

  getPrequalRecords(): PrequalRecord[] {
    return this.data.wisetackPrequalRecords.map(convertFetchPrequalRecordToPrequalRecord)
  }
}

export const ComprehensiveAccountDetailsQuerySchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('by-account-guid'), companyGuid: guidSchema, accountGuid: guidSchema }),
  z.object({
    type: z.literal('by-account-reference-number'),
    companyGuid: guidSchema,
    accountReferenceNumber: z.string(),
  }),
])

export type ComprehensiveAccountDetailsQuery = z.infer<typeof ComprehensiveAccountDetailsQuerySchema>
export type ComprehensiveAccountDetailsQuerier = AsyncFn<
  ForCompany<ComprehensiveAccountDetailsQuery>,
  ComprehensiveAccountDetails[]
>
