import { Row } from 'antd'
import React from 'react'
import { EnDash } from '../../../elements/EmDash/EmDash'

const labelTextClassName =
  'text-[#202020] text-[14px] leading-[22px] letter-spacing-[-0.14px] font-semibold'
const valueTextClassName =
  'text-[#202020] text-[14px] leading-[22px] letter-spacing-[-0.14px] ml-[6px]'

type KeyValueRowProps = {
  label: string
  value?: string | React.ReactNode
  leftAlign?: boolean
  className?: string
}

export const KeyValueRow = React.memo(
  ({ label, value, leftAlign, className = 'my-[2px]' }: KeyValueRowProps) => {
    return (
      <Row
        justify={leftAlign ? 'start' : 'space-between'}
        className={className}
      >
        <div className={labelTextClassName}>{label}</div>
        <div className={valueTextClassName}>
          {value ?? (
            <span className="text-[#8c8c8c]">
              <EnDash />
            </span>
          )}
        </div>
      </Row>
    )
  },
)
