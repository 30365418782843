import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons'
import { DrawerProps } from 'antd'
import React from 'react'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import {
  UpsertAppointmentForm,
  UpsertAppointmentFormProps,
} from './UpsertAppointmentForm/UpsertAppointmentForm'

export type UpsertAppointmentDrawerProps = UpsertAppointmentFormProps & {
  open: DrawerProps['open']
  loading?: boolean
}

export const UpsertAppointmentDrawer = React.memo<UpsertAppointmentDrawerProps>(
  ({
    open,
    mode,
    comprehensiveAppointment,
    onCancel,
    onAppointmentCreated,
    onAppointmentEdited,
    appointmentGuid,
    jobGuid,
    jobClass,
    labelClassName = '',
    justifyFormCancelSubmitButtons = 'end',
    showDivider = true,
    showFormCancelSubmitButtons = true,
    jobList,
    jobLeadGuid,
  }) => {
    const title = mode === 'edit' ? 'Edit Appointment' : 'New Appointment'

    return (
      <BzDrawer
        title={title}
        icon={faCalendarCheck}
        item={open && onCancel ? { onCancel } : undefined}
        preferredWidth={720}
      >
        {mode === 'edit' && (
          <UpsertAppointmentForm
            mode="edit"
            onCancel={onCancel}
            jobGuid={jobGuid}
            jobClass={jobClass}
            labelClassName={labelClassName}
            justifyFormCancelSubmitButtons={justifyFormCancelSubmitButtons}
            showDivider={showDivider}
            showFormCancelSubmitButtons={showFormCancelSubmitButtons}
            appointmentGuid={appointmentGuid}
            comprehensiveAppointment={comprehensiveAppointment}
            onAppointmentEdited={onAppointmentEdited}
            onAppointmentCreated={onAppointmentCreated}
            jobLeadGuid={jobLeadGuid}
          />
        )}
        {mode === 'create' && (
          <UpsertAppointmentForm
            mode="create"
            onCancel={onCancel}
            jobGuid={jobGuid}
            jobClass={jobClass}
            labelClassName={labelClassName}
            justifyFormCancelSubmitButtons={justifyFormCancelSubmitButtons}
            showDivider={showDivider}
            showFormCancelSubmitButtons={showFormCancelSubmitButtons}
            appointmentGuid={appointmentGuid}
            onAppointmentEdited={onAppointmentEdited}
            onAppointmentCreated={onAppointmentCreated}
            jobList={jobList}
            jobLeadGuid={jobLeadGuid}
          />
        )}
      </BzDrawer>
    )
  },
)

export default UpsertAppointmentDrawer
