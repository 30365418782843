import classNames from 'classnames'
import React from 'react'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useModalState } from '../../utils/react-utils'

type PrequalLearnMoreLinkProps = {
  className?: string
}

const PrequalLearnMoreLink = React.memo<PrequalLearnMoreLinkProps>(
  ({ className }) => {
    const [showModal, openModal, closeModal] = useModalState(false)
    return (
      <>
        <span
          className={classNames('cursor-pointer text-bz-primary', className)}
          onClick={openModal}
        >
          Learn more &#x2197;
        </span>
        {showModal && (
          <OnsiteBasicModal
            onClose={closeModal}
            open
            header="About pre-qualification"
          >
            <Header>Guidelines</Header>
            <Section>
              Introducing prequalification through Wisetack! This allows both
              you and your customer to know instantly what dollar amount they
              qualify for before you even finish the estimate. This creates
              instant relief from the burden of potentially large purchases,
              helping you win larger jobs while providing an excellent customer
              experience.
            </Section>
            <Header>Overview</Header>
            <Section>
              Prequalification gives your customers insight into the maximum
              amount they can borrow. Going through this process early - at the
              point of drafting an estimate - creates flexibility for both you
              and your customers. This eliminates any wasted time on lost
              estimates due to a consumer’s financial situation and also allows
              consumers to look at financing options before a tech even goes out
              for a visit.
            </Section>
            <Section>
              Prequalification to financing application in 3 Easy Steps:
              <ol className="pl-8">
                <li>Send prequalification to customer</li>
                <li>
                  Customer completes application and is shown how much they are
                  qualified to finance
                </li>
                <li>
                  Send estimate for qualifying amount so the customer can select
                  their financing option!
                </li>
              </ol>
            </Section>
            <Header>Steps for the customer</Header>
            <Section>
              Once you've sent your customer an application, they will receive a
              text from Wisetack with their link to apply. After a few easy
              steps, they’ll learn how much they are prequalified to finance.
            </Section>
          </OnsiteBasicModal>
        )}
      </>
    )
  },
)

const Header = React.memo<React.PropsWithChildren>(({ children }) => (
  <div className="mb-2 text-xl font-bold text-bz-gray-1000">{children}</div>
))

const Section = React.memo<React.PropsWithChildren>(({ children }) => (
  <div className="mb-6 text-bz-gray-1000">{children}</div>
))

export default PrequalLearnMoreLink
