import { useCallback } from 'react'
import { ProgressiveAccountCreationModalV2 } from '../../components/ProgressiveAccountCreationModal/ProgressiveAccountCreationModalV2'
import useAppNavigation from '../../hooks/useAppNav'
import { m } from '../../utils/react-utils'

const AccountCreationPage = m(() => {
  const appNav = useAppNavigation()

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        appNav.navigateToAccountList()
      }
    },
    [appNav],
  )

  return <ProgressiveAccountCreationModalV2 isOpen setIsOpen={setIsOpen} />
})

export default AccountCreationPage
