import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import classNames from 'classnames'
import React from 'react'

type RightArrowButtonProps = BaseButtonProps & {
  onClick?: () => void
  loading?: boolean
  size?: BaseButtonProps['size']
  type?: BaseButtonProps['type']
}

export const RightArrowButton = React.memo<RightArrowButtonProps>(
  ({
    onClick,
    className,
    children,
    loading,
    size = 'large',
    type = 'primary',
    ...passthrough
  }) => (
    <Button
      size={size}
      type={type}
      loading={loading}
      onClick={onClick}
      className={classNames(
        'flex flex-row items-center justify-center',
        className,
      )}
      {...passthrough}
    >
      {children} <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
    </Button>
  ),
)
