import { getConfig } from '../../../config'
import ThinDivider from '../../../elements/ThinDivider'
import { dayBreakBlue2 } from '../../../themes/theme'
import PaymentCardPanel from '../PaymentCardPanel'

const TilledSandboxAchInfo = () => {
  const isSandbox = getConfig().tilled.useSandbox

  return (
    <>
      {isSandbox && (
        <PaymentCardPanel
          className=""
          style={{ backgroundColor: dayBreakBlue2 }}
        >
          {/* className="card-no-fixed-height center-h mt-6 w-[320px] max-w-[500px]"
          style={{ backgroundColor: dayBreakBlue2 }}
        > */}
          <h2 className="grey9 m-0 mb-4">Sandbox Payment Details</h2>
          <p className="grey9 m-0">
            Account Type: <b>Checking</b>
          </p>
          <p className="grey9 m-0">
            Account Number: <b>123412341234</b>
          </p>
          <p className="grey9 m-0">
            Routing Number: <b>067014822</b>
          </p>
          <ThinDivider />
          <p className="grey9 m-0">
            Name: <b>John Doe</b>
          </p>
          <p className="grey9 m-0">
            Address Line 1: <b>370 Interlocken Blvd</b>
          </p>
          <p className="grey9 m-0">
            City: <b>Broomfield</b>
          </p>
          <p className="grey9 m-0">
            State: <b>CO</b>
          </p>
          <p className="grey9 m-0">
            Zip: <b>80021</b>
          </p>
        </PaymentCardPanel>
      )}
    </>
  )
}

export default TilledSandboxAchInfo
