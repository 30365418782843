import React, { useCallback } from 'react'
import { OnsiteModal } from '../../../../adam-components/OnsiteModal/OnsiteModal'
import { usePaymentStatusModalContext } from '../../providers/PaymentStatusModalProvider'
import { PaymentStatusCard, PaymentStatusCardProps } from './PaymentStatusCard'

export const PaymentStatusModal = React.memo<PaymentStatusCardProps>(props => {
  const { hidePaymentStatusModal } = usePaymentStatusModalContext()
  const { type, mode } = props
  const onClose = useCallback(() => {
    if (type !== 'payment-processing') {
      hidePaymentStatusModal()
    }
  }, [hidePaymentStatusModal, type])

  return (
    <OnsiteModal
      drawer
      modalClassName="z-[1020]"
      size={mode === 'embedded' ? 'default' : 'large-width'}
      onClose={onClose}
    >
      <PaymentStatusCard {...props} onClose={onClose} />
    </OnsiteModal>
  )
})
