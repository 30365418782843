import { z } from 'zod'
import { EventInputDto, HtmlString, usCentsToUsd, usdToUsCents } from '../../common'
import { uscSchema } from '../../contracts'
import { CartItem } from '../Finance/Transactions/TransactionTypes'
import { CartCalculationSummary, CartSubtotalSummary, withTotalPriceUsc } from '../Finance/Transactions/utils'
import { PricebookDiscountDescription, PricebookDiscountName } from '../Pricebook/PricebookTypes'
import { bzOptional } from '../common-schemas'

export enum DiscountType {
  FLAT = 'flat',
  RATE = 'rate',
}

type BaseDiscountInfo = {
  name: PricebookDiscountName
  description: PricebookDiscountDescription
  isActive: boolean
}
type BaseRichDiscountInfo = {
  name: PricebookDiscountName
  descriptionHtml: HtmlString
  isActive: boolean
}

type FlatRateDiscountAttributes = {
  type: DiscountType.FLAT
  discountAmountUsd: number
  discountRate?: null
}

export type FlatRateDiscount = BaseDiscountInfo & FlatRateDiscountAttributes

type RateDiscountAttributes = {
  type: DiscountType.RATE
  discountRate: number
  discountAmountUsd?: null
}

export type SimplifiedDiscount = {
  type: DiscountType
  value: number
}

export const DiscountSchema = z.object({
  type: z.nativeEnum(DiscountType),
  value: z.number().min(0),
})

export const CartDiscountSetInputDto = EventInputDto.extend({
  eventData: DiscountSchema,
})

export const simpleToCommonDiscount = (simple: SimplifiedDiscount): CommonDiscount =>
  simple.type === DiscountType.FLAT
    ? {
        type: DiscountType.FLAT,
        discountAmountUsd: simple.value,
        discountRate: null,
      }
    : {
        type: DiscountType.RATE,
        discountAmountUsd: null,
        discountRate: simple.value,
      }

export const commonToSimpleDiscount = (common: CommonDiscount): SimplifiedDiscount => ({
  type: common.type,
  value: common.type === DiscountType.FLAT ? common.discountAmountUsd : common.discountRate,
})

export type RateDiscount = BaseDiscountInfo & RateDiscountAttributes

export type Discount = FlatRateDiscount | RateDiscount

export type CommonDiscount = FlatRateDiscountAttributes | RateDiscountAttributes

const FlatRateDiscountAttributesUscSchema = z.object({
  type: z.literal(DiscountType.FLAT),
  discountAmountUsc: uscSchema,
  discountRate: bzOptional(z.undefined()),
})

const RateDiscountAttributesUscSchema = z.object({
  type: z.literal(DiscountType.RATE),
  discountRate: z.number().min(0).max(1),
  discountAmountUsc: bzOptional(z.undefined()),
})

export const CommonDiscountUscSchema = z.discriminatedUnion('type', [
  FlatRateDiscountAttributesUscSchema,
  RateDiscountAttributesUscSchema,
])
export type CommonDiscountUsc = z.infer<typeof CommonDiscountUscSchema>

export type DiscountUsc = BaseDiscountInfo & CommonDiscountUsc
export type RichDiscountUsc = Omit<BaseRichDiscountInfo, 'isActive'> & CommonDiscountUsc

export const commonDiscountToCommonDiscountUsc = (discount: CommonDiscount): CommonDiscountUsc =>
  discount.type === DiscountType.FLAT
    ? {
        type: DiscountType.FLAT,
        discountAmountUsc: usdToUsCents(discount.discountAmountUsd),
      }
    : {
        type: DiscountType.RATE,
        discountRate: discount.discountRate,
      }

export const commonDiscountUscToCommonDiscount = (discount: CommonDiscountUsc): CommonDiscount =>
  discount.type === DiscountType.FLAT
    ? {
        type: DiscountType.FLAT,
        discountAmountUsd: usCentsToUsd(discount.discountAmountUsc),
      }
    : {
        type: DiscountType.RATE,
        discountRate: discount.discountRate,
      }

export const calculateDiscountedAmount = (discount: CommonDiscount, items: CartItem[]) => {
  const { discountableAmountUsc, nonDiscountableAmountUsc } = items.reduce(
    (sums, i) => {
      if (i.isDiscountable) {
        sums.discountableAmountUsc += usdToUsCents(i.unitPriceUsd * i.quantity)
      } else {
        sums.nonDiscountableAmountUsc += usdToUsCents(i.unitPriceUsd * i.quantity)
      }
      return sums
    },
    {
      discountableAmountUsc: 0,
      nonDiscountableAmountUsc: 0,
    },
  )

  if (discountableAmountUsc === 0) {
    return {
      discountAmountUsc: 0,
      totalPriceUsc: nonDiscountableAmountUsc,
    }
  }

  switch (discount.type) {
    case DiscountType.FLAT: {
      const discountUsc = usdToUsCents(discount.discountAmountUsd)
      const flatDiscountUsc = Math.min(discountUsc, discountableAmountUsc)

      return {
        discountAmountUsc: flatDiscountUsc,
        totalPriceUsc: Math.max(nonDiscountableAmountUsc + discountableAmountUsc - flatDiscountUsc, 0),
      }
    }
    case DiscountType.RATE: {
      const rateDiscountUsc = Math.max(Math.ceil(discountableAmountUsc * discount.discountRate), 0)
      const discountedUsc = discountableAmountUsc + nonDiscountableAmountUsc - rateDiscountUsc

      return {
        discountAmountUsc: rateDiscountUsc,
        totalPriceUsc: discountedUsc,
      }
    }
  }
}

export const calculateDiscountedAmountCentsUsc = (
  discount: CommonDiscountUsc,
  subtotals: CartSubtotalSummary,
): CartCalculationSummary => {
  const { taxDiscSubtotalUsc, nonTaxDiscSubtotalUsc } = subtotals

  const discountableSubtotalUsc = taxDiscSubtotalUsc + nonTaxDiscSubtotalUsc

  switch (discount.type) {
    case DiscountType.FLAT: {
      const discountUsc = discount.discountAmountUsc

      if (discountUsc >= discountableSubtotalUsc) {
        return withTotalPriceUsc({
          ...subtotals,
          discountAmountUsc: discountableSubtotalUsc,
          taxDiscSubtotalUsc: 0,
          nonTaxDiscSubtotalUsc: 0,
        })
      }

      const discountedTaxSubtotalUsc =
        taxDiscSubtotalUsc - Math.ceil((discountUsc * taxDiscSubtotalUsc) / discountableSubtotalUsc)
      const discountedNonTaxSubtotalUsc =
        nonTaxDiscSubtotalUsc - Math.floor((discountUsc * nonTaxDiscSubtotalUsc) / discountableSubtotalUsc)

      const subtotalDiscountedAmount =
        discountableSubtotalUsc - (discountedTaxSubtotalUsc + discountedNonTaxSubtotalUsc)
      if (subtotalDiscountedAmount !== discountUsc) {
        throw new Error(
          `Expected to discount $${discountUsc / 100}, but only discounted $${subtotalDiscountedAmount / 100}. ` +
            `Total Discountable Amount: $${discountableSubtotalUsc / 100}. ` +
            `Taxable Discounted Subtotal: $${discountedTaxSubtotalUsc / 100}. Non-Taxable Discounted Subtotal: $${
              discountedNonTaxSubtotalUsc / 100
            }`,
        )
      }

      return withTotalPriceUsc({
        ...subtotals,
        discountAmountUsc: discountUsc,
        taxDiscSubtotalUsc: discountedTaxSubtotalUsc,
        nonTaxDiscSubtotalUsc: discountedNonTaxSubtotalUsc,
      })
    }
    case DiscountType.RATE: {
      // taxable
      const discountAmountTaxUsc = Math.ceil(taxDiscSubtotalUsc * discount.discountRate)
      const discountedTaxSubtotalUsc = taxDiscSubtotalUsc - discountAmountTaxUsc

      // non-taxable
      const discountAmountNonTaxUsc = Math.ceil(nonTaxDiscSubtotalUsc * discount.discountRate)
      const discountedNonTaxSubtotalUsc = nonTaxDiscSubtotalUsc - discountAmountNonTaxUsc

      return withTotalPriceUsc({
        ...subtotals,
        discountAmountUsc: discountAmountTaxUsc + discountAmountNonTaxUsc,
        taxDiscSubtotalUsc: discountedTaxSubtotalUsc,
        nonTaxDiscSubtotalUsc: discountedNonTaxSubtotalUsc,
      })
    }
  }
}
