import { useMemo } from 'react'
import { useFetchComprehensiveJobDetailsByJobGuidQuery } from './useFetchComprehensiveJobDetailsByJobGuid'

type UseFetchAppointmentProps = {
  jobGuid: string
  appointmentGuid: string
}

export const useFetchAppointment = ({
  jobGuid,
  appointmentGuid,
}: UseFetchAppointmentProps) => {
  const jobQuery = useFetchComprehensiveJobDetailsByJobGuidQuery({ jobGuid })

  const appointment = useMemo(() => {
    return jobQuery.data
      ?.getAppointments()
      .find(appointment => appointment.appointmentGuid === appointmentGuid)
  }, [appointmentGuid, jobQuery.data])

  const queryResult = {
    data: appointment,
    isFetching: jobQuery.isFetching,
    isLoading: jobQuery.isLoading,
    refetch: jobQuery.refetch,
  }

  return queryResult
}
