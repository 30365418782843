import { ComprehensiveLocationDetails } from '@breezy/backend/src/application-types'
import {
  BzDateFns,
  Guid,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  LocationContact,
  isNullish,
  richJobTypeDescriptor,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { GroupedContactsCollapsible } from '../../components/Contacts/ContactsCollapsible/ContactsCollapsible'
import EquipmentUpsertDrawer from '../../components/EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import { InstalledHvacSystemUpsertDrawer } from '../../components/InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import { LocationContactUpsertDrawer } from '../../components/LocationContacts/LocationContactUpsertDrawer'
import BzCollapsible from '../../components/Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../components/Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import PaymentsCollapsible from '../../components/Payments/PaymentsCollapsible/PaymentsCollapsible'
import AccountCollapsible from '../../components/collapsibles/AccountCollapsible/AccountCollapsible'
import AccountLocationCollapsible from '../../components/collapsibles/AccountLocationCollapsible/AccountLocationCollapsible'
import { AppointmentsCollapsible } from '../../components/collapsibles/AppointmentsCollapsible/AppointmentsCollapsible'
import { InstalledEquipmentCollapsible } from '../../components/collapsibles/InstalledEquipmentCollapsible/InstalledEquipmentCollapsible'
import { InstalledHvacSystemsCollapsible } from '../../components/collapsibles/InstalledHvacSystemsCollapsible/InstalledHvacSystemsCollapsible'
import { InvoicesV2Collapsible } from '../../components/collapsibles/InvoicesCollapsible/InvoicesV2Collapsible'
import BzColumn from '../../elements/BzColumn/BzColumn'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import { useIntercom } from '../../hooks/useIntercom'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { DetailsPageProps } from '../../utils/Refetchable'
import { useMessage } from '../../utils/antd-utils'
import { JobIcon } from '../../utils/feature-icons'
import { UpsertOp } from '../../utils/upsert-utils'
import { useMaintenancePlanCollapsiblesData } from './useMaintenancePlanCollapsiblesData'

type MaintenancePlanCollapsiblesGroupProps =
  DetailsPageProps<ComprehensiveLocationDetails> & {
    maintenancePlanGuid: Guid
    maintenancePlanVisitLinkedJobGuids: Guid[]
    editable?: boolean
  }

export const MaintenancePlanCollapsiblesGroup =
  React.memo<MaintenancePlanCollapsiblesGroupProps>(
    ({
      maintenancePlanGuid,
      data,
      refetch,
      editable = true,
      maintenancePlanVisitLinkedJobGuids = [],
    }) => {
      const message = useMessage()
      const tzId = useExpectedCompanyTimeZoneId()
      const appNav = useAppNavigation()
      const [locationContactMutState, setLocationContactMutState] =
        useState<UpsertOp<LocationContact>>()
      const [hvacSystemMutState, setHvacSystemMutState] =
        useState<UpsertOp<InstalledHvacSystem>>()
      const [equipmentMutState, setEquipmentMutState] =
        useState<UpsertOp<InstalledEquipmentSummary>>()
      const basicAccount = data.getBasicAccount()
      const accountLocation = data.getAccountLocation()

      const removeLocationContactMutation =
        trpc.location['location-contacts:remove'].useMutation()

      const { payments, appointments, jobs } =
        useMaintenancePlanCollapsiblesData(
          maintenancePlanGuid,
          maintenancePlanVisitLinkedJobGuids,
        )

      const { location, locationGuid, accountGuid } = useMemo(
        () => ({
          locationGuid: data.getLocationGuid(),
          accountGuid: data.getAccountLocation()?.accountGuid,
          location: data.getLocation(),
        }),
        [data],
      )

      const onRemoveLocationContact = useCallback(
        (locationContact: LocationContact) => {
          removeLocationContactMutation.mutate(
            {
              locationGuid: locationContact.locationGuid,
              contactGuid: locationContact.contactGuid,
            },
            {
              onSuccess() {
                refetch()
                message.success(
                  `Successfully removed Location Contact relationship`,
                )
              },
              onError() {
                message.error(`Failed to remove Location Contact relationship`)
              },
            },
          )
        },
        [removeLocationContactMutation, refetch, message],
      )

      useIntercom({ isLauncherVisible: isNullish(locationContactMutState) })

      return (
        <BzColumn noPaddingMobile>
          {basicAccount && <AccountCollapsible account={basicAccount} />}
          {accountLocation && (
            <AccountLocationCollapsible
              data={accountLocation}
              refetch={refetch}
            />
          )}

          <GroupedContactsCollapsible
            title="Contacts"
            accountContacts={data.getAccountContacts()}
            locationContacts={data.getLocationContacts()}
            onPlus={() => setLocationContactMutState('create new')}
            onEditLocationContact={c => setLocationContactMutState(c)}
            onRemoveLocationContact={c => onRemoveLocationContact(c)}
            editable={editable}
          />
          <BzCollapsible title="Job" titleIcon={JobIcon}>
            {jobs.map((job, i) => {
              return (
                <div key={job.jobGuid} className={i !== 0 ? 'mt-2' : ''}>
                  <CollapsibleItem
                    key={job.jobGuid}
                    title={richJobTypeDescriptor(job.jobType.name)}
                    displayId={job.displayId}
                    onTitleClick={() =>
                      appNav.navigateToJobDetailsPage(job.jobGuid)
                    }
                    contentList={[
                      {
                        key: 'Created on:',
                        value: BzDateFns.formatFromISO(
                          job.jobCreatedAt,
                          'MMM d, yyyy',
                          tzId,
                        ),
                      },
                      {
                        key: 'Status:',
                        value: job.jobLifecycleStatus.name,
                      },
                    ]}
                  />
                </div>
              )
            })}
          </BzCollapsible>

          <AppointmentsCollapsible
            appointments={appointments}
            editable={editable}
          />

          <InstalledEquipmentCollapsible
            installedEquipment={data.getEquipment()}
            onAdd={() => setEquipmentMutState('create new')}
            onEdit={setEquipmentMutState}
            editable={editable}
          />

          <InstalledHvacSystemsCollapsible
            installedHvacSystems={data.getInstallHvacSystems()}
            onEditInstalledHvacSystem={setHvacSystemMutState}
            onEditInstalledEquipment={setEquipmentMutState}
            onAddInstalledHvacSystem={() => setHvacSystemMutState('create new')}
            editable={editable}
          />

          <PaymentsCollapsible payments={payments} />

          {accountGuid && (
            <InvoicesV2Collapsible
              accountGuid={accountGuid}
              maintenancePlanGuid={maintenancePlanGuid}
              maintenancePlanVisitLinkedJobGuids={
                maintenancePlanVisitLinkedJobGuids
              }
            />
          )}

          {equipmentMutState === 'create new' && (
            <EquipmentUpsertDrawer
              mode="create-for-location"
              location={location}
              isOpen={!!equipmentMutState}
              onCancel={() => setEquipmentMutState(undefined)}
              onMutate={() => {
                refetch()
                setEquipmentMutState(undefined)
              }}
            />
          )}

          {equipmentMutState && equipmentMutState !== 'create new' && (
            <EquipmentUpsertDrawer
              mode="update"
              initialValues={equipmentMutState}
              location={location}
              isOpen={!!equipmentMutState}
              onCancel={() => setEquipmentMutState(undefined)}
              onMutate={() => {
                refetch()
                setEquipmentMutState(undefined)
              }}
            />
          )}

          {hvacSystemMutState === 'create new' && (
            <InstalledHvacSystemUpsertDrawer
              mode={'create-for-location'}
              location={location}
              isOpen={!!hvacSystemMutState}
              onCancel={() => setHvacSystemMutState(undefined)}
              onMutate={() => {
                refetch()
                setHvacSystemMutState(undefined)
              }}
            />
          )}

          {hvacSystemMutState && hvacSystemMutState !== 'create new' && (
            <InstalledHvacSystemUpsertDrawer
              mode={'update'}
              location={location}
              initialValues={hvacSystemMutState}
              isOpen={!!hvacSystemMutState}
              onCancel={() => setHvacSystemMutState(undefined)}
              onMutate={() => {
                refetch()
                setHvacSystemMutState(undefined)
              }}
            />
          )}

          <LocationContactUpsertDrawer
            locationGuid={locationGuid}
            locationContact={
              locationContactMutState === 'create new'
                ? undefined
                : locationContactMutState
            }
            isOpen={!!locationContactMutState}
            onClose={() => setLocationContactMutState(undefined)}
            onMutate={() => {
              refetch()
              setLocationContactMutState(undefined)
            }}
          />
        </BzColumn>
      )
    },
  )
