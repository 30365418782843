import { InputNumber, InputNumberProps } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import './NumberField.less'

type NumberFieldProps = {
  onBlur: () => void
  onChange: (e: { target: { value: number } }) => void
  name: string
  value?: number
  isMoney?: boolean
  isInt?: boolean
  placeholder?: string
  min?: number
  max?: number
  className?: string
  dataDdActionName?: string
  disabled?: boolean
  updateOnChange?: boolean
  size?: InputNumberProps['size']
  suffix?: React.ReactNode
}

export const NumberField = React.memo(
  React.forwardRef<HTMLInputElement, NumberFieldProps>(
    (
      {
        onBlur: providedOnBlur,
        onChange: providedOnChange,
        name,
        value,
        isMoney,
        isInt,
        placeholder,
        min,
        max,
        className,
        dataDdActionName,
        disabled,
        size = 'large',
        updateOnChange = false,
        suffix,
      },
      ref,
    ) => {
      const [inputValue, setInputValue] = useState(`${value ?? ''}`)

      useEffect(() => {
        setInputValue(`${value ?? ''}`)
      }, [value])

      const handleChange = useCallback(
        (newValue: string | number | null) => {
          const stringValue = newValue?.toString() ?? ''
          setInputValue(stringValue)

          if (updateOnChange) {
            const val = isInt ? parseInt(stringValue) : parseFloat(stringValue)
            providedOnChange({ target: { value: val } })
          }
        },
        [updateOnChange, providedOnChange, isInt],
      )

      const onBlur = useCallback(() => {
        const val = isInt ? parseInt(inputValue) : parseFloat(inputValue)

        if (isNaN(val)) {
          setInputValue(`${value ?? ''}`)
        } else {
          providedOnChange({ target: { value: val } })
          setInputValue(`${val}`)
        }
        providedOnBlur()
      }, [inputValue, isInt, providedOnBlur, providedOnChange, value])

      return (
        <InputNumber
          ref={ref}
          id={name}
          data-testid={name}
          size={size}
          className={classNames('bz-number-input-field w-full', className)}
          prefix={isMoney ? '$' : undefined}
          placeholder={placeholder}
          min={`${min ?? ''}`}
          max={`${max ?? ''}`}
          value={inputValue}
          onChange={handleChange}
          onBlur={onBlur}
          precision={isMoney ? 2 : isInt ? 0 : undefined}
          data-dd-action-name={dataDdActionName}
          disabled={disabled}
          suffix={suffix}
        />
      )
    },
  ),
)
