import {
  Address,
  bzExpect,
  CreditRecord,
  InstalledEquipmentGuid,
  LocationGuid,
  MaintenancePlanCollapsibleViewModel,
  MaintenancePlanDiscount,
  MaintenancePlanMinimalInfo,
  MaintenancePlanPaymentFlow,
  MaintenancePlanStatus,
} from '@breezy/shared'
import { mapQueryVisitsToVisitViewModels, VisitsQuery } from '../visits/VisitConversions'

type GqlMinimalMaintenancePlan = {
  maintenancePlanGuid: string
  status: string
  paymentFlow: MaintenancePlanPaymentFlow
  maintenancePlanDefinition?: {
    marketingInfo?: {
      name: string
    }
    flare?: { primaryColorHex: string; imageUrl: string }
  }
}

export const mapGqlLocationMaintenancePlansToMinimals = (
  mps: GqlMinimalMaintenancePlan[],
): MaintenancePlanMinimalInfo[] => mps.map(mapGqlLocationMaintenancePlanToMinimal)

export const mapGqlLocationMaintenancePlanToMinimal = (mp: GqlMinimalMaintenancePlan): MaintenancePlanMinimalInfo => ({
  maintenancePlanGuid: mp.maintenancePlanGuid ?? 'unknown',
  status: mp.status as MaintenancePlanStatus,
  paymentFlow: mp.paymentFlow as MaintenancePlanPaymentFlow,
  planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? 'unknown',
  planTypeFlare: mp.maintenancePlanDefinition ? mp.maintenancePlanDefinition?.flare : undefined,
})

type GqlCollapsibleMaintenancePlan = GqlMinimalMaintenancePlan & {
  locationGuid: LocationGuid
  yearlyPriceUsc: number
  credits: CreditRecord[]
  maintenancePlanVisits: VisitsQuery
  coveredInstalledEquipmentGuids: InstalledEquipmentGuid[]
  location?: {
    address: Address
  }
  discounts: MaintenancePlanDiscount[]
}

export const mapGqlLocationMaintenancePlansToCollapsibles = (
  mps: GqlCollapsibleMaintenancePlan[],
): MaintenancePlanCollapsibleViewModel[] => mps.map(mapGqlLocationMaintenancePlanToCollapsible)

const mapGqlLocationMaintenancePlanToCollapsible = (
  mp: GqlCollapsibleMaintenancePlan,
): MaintenancePlanCollapsibleViewModel => ({
  ...mp,
  status: mp.status as MaintenancePlanStatus,
  paymentFlow: mp.paymentFlow as unknown as MaintenancePlanPaymentFlow,
  planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? 'None',
  planTypeFlare: mp.maintenancePlanDefinition?.flare,
  locationAddress: bzExpect(mp.location?.address, 'Location should be present'),
  visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits ?? []),
  discounts: mp.discounts ?? [],
})
