import { isNullish } from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faClose, faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag as AntdTag, Popover } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import tailwindConfig from '../../tailwind.config'

type TagStyleSizeClassNamesProps = {
  padding: string
  labelPadding: string
  textSize: string
  iconSize: number
}

type TagStyleClassNamesProps = {
  bgColor: string
  textColor: string
  iconColor: string
  borderRadius: string
  border: string
}

export type TagStyleVersion = 'v1' | 'v2' | 'empty'

type TagProps = {
  tag: { name: string; color?: string; label?: string }
  tagStyleVersion?: TagStyleVersion
  truncateLength?: number
  onClose?: () => void
  icon?: IconProp
  hideIcon?: boolean
  noPopover?: boolean
  disabled?: boolean
  overrideStyle?: Partial<TagStyleClassNamesProps & TagStyleSizeClassNamesProps>
  className?: string
  compact?: boolean
}

export const TagRegularSizeStyle: TagStyleSizeClassNamesProps = {
  padding: 'px-[8px] py-[5px]',
  labelPadding: 'pt-[2px]',
  textSize: 'text-[14px] leading-[22px] letter-spacing-[-0.14px]',
  iconSize: 20,
}

export const TagCompactSizeStyle: TagStyleSizeClassNamesProps = {
  padding: 'px-[8px] py-[2px]',
  labelPadding: '',
  textSize: 'text-sm',
  iconSize: 14,
}

export const TagStyleV2: TagStyleClassNamesProps = {
  bgColor: 'bg-[#ffffff]',
  textColor: 'text-[#202020]',
  iconColor: 'text-bz-text-secondary',
  borderRadius: 'rounded-[6px]',
  border: 'border border-[#D9D9D9]',
}

export const TagStyleEmpty: TagStyleClassNamesProps = {
  bgColor: tailwindConfig.theme.extend.colors.bz['fill-quaternary'],
  textColor: 'text-bz-gray-700',
  iconColor: 'text-[#434343]',
  borderRadius: 'rounded-[6px]',
  border: 'border border-bz-border',
}

export const TagStyleV1: TagStyleClassNamesProps = {
  bgColor: '',
  textColor: '',
  iconColor: '',
  borderRadius: '',
  border: '',
}

const DisabledStyle = {
  bgColor: 'bg-[#F0F0F0]',
  textColor: 'text-[#c3c3c3]',
  iconColor: 'text-[#c3c3c3]',
}

export const Tag = React.memo<TagProps>(
  ({
    tag,
    truncateLength,
    onClose,
    icon,
    hideIcon,
    tagStyleVersion = 'v1',
    noPopover = false,
    disabled = false,
    overrideStyle,
    className,
    compact,
  }) => {
    const pointer = noPopover ? 'cursor-default' : 'hover:cursor-pointer'
    const tagStyle = useMemo(() => {
      let tagStyle = TagStyleV1
      if (tagStyleVersion === 'v2') {
        tagStyle = TagStyleV2
      }
      if (tagStyleVersion === 'empty') {
        tagStyle = TagStyleEmpty
      }

      return {
        ...tagStyle,
        ...(compact ? TagCompactSizeStyle : TagRegularSizeStyle),
      }
    }, [compact, tagStyleVersion])
    const baseStyle = { ...tagStyle, ...overrideStyle }
    const {
      bgColor,
      padding,
      labelPadding,
      textSize,
      textColor,
      iconSize,
      iconColor,
      borderRadius,
      border,
    } = disabled ? { ...baseStyle, ...DisabledStyle } : baseStyle

    const innerElement = (
      <AntdTag
        onClose={onClose}
        className={classNames(
          pointer,
          bgColor,
          padding,
          borderRadius,
          border,
          className,
        )}
      >
        <div className={`flex flex-row items-center space-x-1`}>
          {!hideIcon && (
            <FontAwesomeIcon
              icon={icon ?? faTag}
              fontSize={iconSize}
              className={`${iconColor} mr-1 min-w-[${iconSize}px]`}
            />
          )}
          {tag.label && (
            <div
              className={`${labelPadding} align-middle text-[12px] font-semibold leading-[22px] text-[#8C8C8C]`}
            >
              {tag.label}
            </div>
          )}
          <span className={`${textSize} ${textColor} align-middle`}>
            {!isNullish(truncateLength) && tag.name.length > truncateLength ? (
              <>{tag.name.slice(0, truncateLength)}...</>
            ) : (
              <>{tag.name}</>
            )}
          </span>
          {onClose && (
            <FontAwesomeIcon
              icon={faClose}
              fontSize={14}
              className="hover:cursor-pointer"
              onClick={onClose}
            />
          )}
        </div>
      </AntdTag>
    )

    return noPopover ? (
      innerElement
    ) : (
      <Popover
        content={<>{tag.name}</>}
        trigger={['hover', 'click']}
        mouseEnterDelay={0.25}
      >
        {innerElement}
      </Popover>
    )
  },
)
