import { MissingCaseError } from '../../common'

export enum AccountType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export type AccountTypeArray = AccountType[]

export const AccountTypeDisplayNames = {
  [AccountType.COMMERCIAL]: 'Commercial',
  [AccountType.RESIDENTIAL]: 'Residential',
} as const

export type AccountTypeDisplayNamesType = (typeof AccountTypeDisplayNames)[keyof typeof AccountTypeDisplayNames]

export const getDisplayNameForAccountType = (accountType: AccountType): string => {
  switch (accountType) {
    case AccountType.COMMERCIAL:
      return 'Commercial'
    case AccountType.RESIDENTIAL:
      return 'Residential'
    default:
      throw new MissingCaseError(`Missing case for accountType ${accountType}`)
  }
}
