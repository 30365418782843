import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { CompanyTimeWindowRequest, ForCompany } from '../Company/Company'
import { UserGuid } from '../Users/User'
import { bzOptional } from '../common-schemas'

export const TechnicianCapacitySchema = z.object({
  technicianCapacityGuid: guidSchema,
  userGuid: guidSchema,
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type TechnicianCapacity = z.infer<typeof TechnicianCapacitySchema>

export enum TechnicianCapacityBlockReasonType {
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  LUNCH = 'LUNCH',
  BREAK = 'BREAK',
  ON_CALL = 'ON_CALL',
  PERSONAL_APPOINTMENT = 'PERSONAL_APPOINTMENT',
  INTERNAL_MEETING = 'INTERNAL_MEETING',
  TRAINING = 'TRAINING',
  PTO = 'PTO',
  HOLIDAY = 'HOLIDAY',
  OUT_SICK = 'OUT_SICK',
  SHOP_WORK = 'SHOP_WORK',
  SUPPLY_PARTS_PICKUP = 'SUPPLY_PARTS_PICKUP',
  OTHER = 'OTHER',
}

const displayReasonTypes = {
  [TechnicianCapacityBlockReasonType.NOT_SCHEDULED]: 'Not Scheduled',
  [TechnicianCapacityBlockReasonType.LUNCH]: 'Lunch',
  [TechnicianCapacityBlockReasonType.BREAK]: 'Break',
  [TechnicianCapacityBlockReasonType.ON_CALL]: 'On Call',
  [TechnicianCapacityBlockReasonType.PERSONAL_APPOINTMENT]: 'Personal Appointment',
  [TechnicianCapacityBlockReasonType.INTERNAL_MEETING]: 'Internal Meeting',
  [TechnicianCapacityBlockReasonType.TRAINING]: 'Training',
  [TechnicianCapacityBlockReasonType.PTO]: 'PTO',
  [TechnicianCapacityBlockReasonType.HOLIDAY]: 'Holiday',
  [TechnicianCapacityBlockReasonType.OUT_SICK]: 'Out Sick',
  [TechnicianCapacityBlockReasonType.SHOP_WORK]: 'Shop Work',
  [TechnicianCapacityBlockReasonType.SUPPLY_PARTS_PICKUP]: 'Supply/Parts Pickup',
  [TechnicianCapacityBlockReasonType.OTHER]: 'Other',
}

export const formatTechnicianCapacityBlockReasonType = (type: TechnicianCapacityBlockReasonType) =>
  displayReasonTypes[type]

export const TechnicianCapacityBlockSchema = z.object({
  guid: guidSchema,
  userGuids: z.array(guidSchema),
  start: isoDateStringSchema,
  end: isoDateStringSchema,
  reasonType: z.nativeEnum(TechnicianCapacityBlockReasonType),
  reasonDescription: bzOptional(z.string()),
  recurrenceRule: bzOptional(z.string()),
  recurrenceRuleExceptions: bzOptional(z.string()),
})

export type TechnicianCapacityBlock = z.infer<typeof TechnicianCapacityBlockSchema>

export type ITechCapacityBlockReader = {
  read: AsyncFn<CompanyTimeWindowRequest, TechnicianCapacityBlock[]>
  readByUserGuid: AsyncFn<ForCompany<{ userGuid: string }>, TechnicianCapacityBlock[]>
}
export type ITechCapacityBlockWriter = {
  upsert: AsyncFn<ForCompany<TechnicianCapacityBlock>>
  delete: AsyncFn<{ guid: string }>
  deleteMultiple: AsyncFn<{ guids: string[] }>
}

export type UserBlockDeleter = AsyncFn<ForCompany<{ userGuid: UserGuid }>>
