import React from 'react'

// Escape special characters in an input so they don't break a regex. This came from ChatGPT
const escapeRegExp = (input: string) => {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

type HighlightedTextProps = {
  children: string
  highlightText: string
}

export const HighlightedText = React.memo<HighlightedTextProps>(
  ({ children, highlightText }) => {
    const regex = new RegExp(`(${escapeRegExp(highlightText)})`, 'gi')

    const textParts = children.split(regex)

    return (
      <>
        {textParts.map((part, i) =>
          regex.test(part) ? (
            <span key={i} className="text-bz-primary">
              {part}
            </span>
          ) : (
            <React.Fragment key={i}>{part}</React.Fragment>
          ),
        )}
      </>
    )
  },
)
