import React, { useMemo, useState } from 'react'
import { ScheduleContext, ScheduleContextType } from './ScheduleContext'
import { generateScheduleAppointmentColorMap } from './utils'

export const useScheduleContext = () => React.useContext(ScheduleContext)

export const ScheduleWrapper = React.memo<
  React.PropsWithChildren<
    Omit<
      ScheduleContextType,
      'appointmentColorMap' | 'selectedAssignableId' | 'setSelectedAssignableId'
    >
  >
>(props => {
  const [selectedAssignableId, setSelectedAssignableId] = useState<string>()

  const appointmentColorMap = useMemo(
    () =>
      generateScheduleAppointmentColorMap(
        props.scheduleViewModel?.assignables.assignedAppointments,
        props.scheduleViewModel?.assignables.unassignedAppointments,
      ),
    [
      props.scheduleViewModel?.assignables.assignedAppointments,
      props.scheduleViewModel?.assignables.unassignedAppointments,
    ],
  )

  return (
    <ScheduleContext.Provider
      value={{
        ...props,
        appointmentColorMap,
        selectedAssignableId,
        setSelectedAssignableId,
      }}
    >
      {props.children}
    </ScheduleContext.Provider>
  )
})
