import React, { ReactNode, useEffect, useState } from 'react'
import { useSearchParam } from 'react-use'

export type BzTab = {
  title: string
  content: ReactNode
  disabled?: boolean
}

export type BzTabsProps = {
  tabs: BzTab[]
  activeTabIndex: number
  setActiveTabIndex: (tabIndex: number) => void
}

const BzTabs = ({ tabs, activeTabIndex, setActiveTabIndex }: BzTabsProps) => {
  return (
    <div className="semibold_14_22 flex space-x-6 overflow-auto border-x-0 border-b border-t-0 border-solid border-bz-gray-500 text-bz-gray-900">
      {tabs.map((tab, i) =>
        tab.disabled ? (
          <React.Fragment key={tab.title} />
        ) : (
          <div
            className={`${
              tabs[activeTabIndex].title === tab.title &&
              'border-x-0 border-b-2 border-t-0 border-solid border-bz-primary text-bz-primary'
            } whitespace-nowrap py-3 hover:cursor-pointer`}
            onClick={() => setActiveTabIndex(i)}
            key={tab.title}
          >
            {tab.title}
          </div>
        ),
      )}
    </div>
  )
}

export const useURLSettableTabIndex = (
  tabs: BzTab[],
  defaultTabIndex: number,
  urlParamKey: string,
) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)

  const urlTab = useSearchParam(urlParamKey)

  useEffect(() => {
    if (urlTab) {
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i]
        if (tab.title === urlTab) {
          setActiveTabIndex(i)
          break
        }
      }
    }
    // We only want this to happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [activeTabIndex, setActiveTabIndex] as const
}

export default BzTabs
