import { bzExpect, dates, TimeWindowDto, ZoneId } from '@breezy/shared'
import React from 'react'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { TimezoneStrategy } from '../../utils/DateUtils'

export type DateTimeWindowProps = {
  startWindowIsoWithOffsetTimestamp: string
  endWindowIsoWithOffsetTimestamp: string
  includeDate?: boolean
  zone?: TimezoneStrategy
  overrideClassName?: string
}

export type DateTimeWindowFromWindowDtoProps = {
  window: TimeWindowDto
  includeDate?: boolean
  zone?: TimezoneStrategy
  overrideClassName?: string
}

export const DateTimeWindowFromWindowDto = ({
  window,
  includeDate = true,
  zone = { type: 'company-timezone-of-principal-user' },
  overrideClassName,
}: DateTimeWindowFromWindowDtoProps) => (
  <DateTimeWindow
    startWindowIsoWithOffsetTimestamp={window.start}
    endWindowIsoWithOffsetTimestamp={window.end}
    includeDate={includeDate}
    zone={zone}
    overrideClassName={overrideClassName}
  />
)

export const DateTimeWindow: React.FC<DateTimeWindowProps> = ({
  startWindowIsoWithOffsetTimestamp,
  endWindowIsoWithOffsetTimestamp,
  includeDate = true,
  zone = { type: 'company-timezone-of-principal-user' },
  overrideClassName,
}) => {
  const principalContext = usePrincipalUser()
  let zoneId: ZoneId

  switch (zone.type) {
    case 'browser':
    case 'company-timezone-of-principal-user':
      zoneId = ZoneId.of(
        bzExpect(
          principalContext.principal?.company?.timezone,
          'Company Timezone',
          'Missing company timezone',
        ),
      )
      break
    case 'location':
      throw new Error('Not yet supported')
    case 'explicit':
      if (typeof zone.timezone === 'string') {
        zoneId = ZoneId.of(zone.timezone)
      } else {
        zoneId = zone.timezone
      }
      break
  }

  return (
    <DateTimeWindowView
      startWindowIsoWithOffsetTimestamp={startWindowIsoWithOffsetTimestamp}
      endWindowIsoWithOffsetTimestamp={endWindowIsoWithOffsetTimestamp}
      includeDate={includeDate}
      zoneId={zoneId}
      overrideClassName={overrideClassName}
    />
  )
}

type DateTimeWindowViewProps = Omit<DateTimeWindowProps, 'zone'> & {
  zoneId: ZoneId
}

export const DateTimeWindowView = React.memo<DateTimeWindowViewProps>(
  ({
    startWindowIsoWithOffsetTimestamp,
    endWindowIsoWithOffsetTimestamp,
    includeDate = true,
    zoneId,
    overrideClassName,
  }) => {
    return (
      <div className={overrideClassName ?? 'semibold_12_20'}>
        {dates.calculateDateTimeWindow(
          startWindowIsoWithOffsetTimestamp,
          endWindowIsoWithOffsetTimestamp,
          zoneId,
          { includeDate },
        )}
      </div>
    )
  },
)
