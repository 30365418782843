import { useEffect } from 'react'

type ResizeRect = {
  width: number
  height: number
  x: number
  y: number
}

export type OnResize = (rect: ResizeRect) => void

export const useResizeObserverForElement = (
  elem: Element | undefined | null,
  onResize: OnResize,
) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      // Note this should only happen once since we only observe one thing
      for (const entry of entries) {
        onResize(entry.contentRect)
      }
    })

    if (elem) {
      resizeObserver.observe(elem)
    }

    return () => {
      if (elem) {
        // The eslint rule doesn't understand refs apparently
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(elem)
      }
    }
  }, [elem, onResize])
}

// I originally had this use `useResizeObserverForElement` by just passing `ref.current` to it. However, that didn't
// work in iOS safari for some reason. So I'm leaving it copy/pasted for now.
export const useResizeObserver = (
  ref: React.MutableRefObject<Element | null>,
  onResize: OnResize,
) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      // Note this should only happen once since we only observe one thing
      for (const entry of entries) {
        onResize(entry.contentRect)
      }
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        // The eslint rule doesn't understand refs apparently
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(ref.current)
      }
    }
  }, [onResize, ref])
}
