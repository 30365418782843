import {
  PHONE_NUMBER_TYPES,
  PhoneNumberType,
  prettifyPhoneNumberType,
} from '@breezy/shared'
import { Form } from 'antd'
import React from 'react'
import BzSelect from '../../../elements/BzSelect/BzSelect'

type PhoneNumberTypeFormItemProps = {
  name?: string
  label?: string
  type?: PhoneNumberType
  enabled: boolean
  onChange?: (newType: PhoneNumberType) => void
}

export const PhoneNumberTypeFormItem: React.FC<
  PhoneNumberTypeFormItemProps
> = ({ type, enabled, onChange, name = 'phoneNumberType', label = ' ' }) => {
  const initialValue = type ?? 'MOBILE'
  return (
    <Form.Item
      name={name}
      className="sm:pl-2"
      label={label}
      initialValue={initialValue}
      rules={[]}
    >
      <BzSelect
        title="Phone Number Type"
        size="middle"
        sheetSize="half"
        value={initialValue}
        placeholder="Phone Number Type"
        disabled={!enabled}
        options={PHONE_NUMBER_TYPES.map(value => ({
          value,
          label: prettifyPhoneNumberType(value),
        }))}
        onChange={(value: PhoneNumberType) => {
          if (onChange) {
            onChange(value)
          }
        }}
      />
    </Form.Item>
  )
}
