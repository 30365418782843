import { gql } from '../../generated'

export const PREQUAL_RECORD_SUBSCRIPTION_BY_ACCOUNT = gql(/* GraphQL */ `
  subscription PrequalRecordsSubByAccount($accountGuid: uuid!) {
    wisetackPrequalRecords(where: { accountGuid: { _eq: $accountGuid } }) {
      ...PrequalRecordWithContactName
    }
  }
`)

export const PREQUAL_RECORD_QUERY_BY_ACCOUNT = gql(/* GraphQL */ `
  query PrequalRecordsByAccount($accountGuid: uuid!) {
    wisetackPrequalRecords(where: { accountGuid: { _eq: $accountGuid } }) {
      ...PrequalRecordWithContactName
    }
  }
`)
