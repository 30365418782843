import { CompanyCommissionConfig } from '@breezy/backend/src/query'
import { UseQueryExecute, useQuery } from 'urql'
import { FETCH_COMPANY_COMMISSION_CONFIG } from '../../pages/TechnicianPerformanceSettingsPage/TechnicianPerformanceSettings.gql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

const fallbackConfigData = {
  defaultCommissionOverheadDeductionRate: 0,
  defaultCommissionOverheadFlatDeductionUsc: 0,
}

const createFallbackConfig = (
  companyGuid: string,
): CompanyCommissionConfig => ({
  ...fallbackConfigData,
  companyGuid,
})

// TODO It's probably better not to use this, and use GqlQueryLoader instead
export const useFetchCompanyCommissionConfig = (): [
  CompanyCommissionConfig,
  UseQueryExecute,
] => {
  const companyGuid = useExpectedCompanyGuid()

  const [{ data, fetching }, refetch] = useQuery({
    query: FETCH_COMPANY_COMMISSION_CONFIG,
    pause: !companyGuid,
    variables: { companyGuid: companyGuid ?? '' },
  })

  if (fetching) {
    return [createFallbackConfig(companyGuid), refetch]
  }

  if (!data?.companyCommissionConfig[0]) {
    return [createFallbackConfig(companyGuid), refetch]
  }

  return [
    {
      ...data?.companyCommissionConfig[0],
      companyGuid,
    },
    refetch,
  ]
}
