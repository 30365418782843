import {
  BusinessResourceConflictException,
  BusinessResourceDoesNotExistException,
  Event,
  EventPayload,
  IEventSourceReferenceNumberProvider,
  IEventStore,
  InvalidEntityStateException,
  IsoDateString,
  MissingCaseError,
  Mutable,
  nextGuid,
  ReadOnlyEventStore,
} from '../../../common'
import { BusinessEventPublisher } from '../../BusinessEvents/BusinessEvents'
import { GuidAndReferenceNumber, ReferenceNumberContainer } from '../../common-schemas'
import { ForCompany } from '../../Company/Company'
import { DiscountType } from '../../Discounts/DiscountTypes'
import { DisplayIdGenerator } from '../../DisplayId/DisplayId'
import { IFileStorageWriter } from '../../FileStorage'
import { ForUser, UserGuidContainer } from '../../Users/User'
import { FinanceDocumentViewModel } from '../FinanceDocumentTypes'
import { Invoice } from '../Invoicing/Invoice'
import { InvoiceEntityTypeName, InvoiceFinalizeInput } from '../Invoicing/InvoiceTypes'
import {
  calculateCartOrderSummary,
  CartDiscountSetEventTypeName,
  CartDiscountV2RemovedEventTypeName,
  CartDiscountV2UpsertEventTypeName,
  CartItem,
  CartItemGuidContainer,
  CartItemRemovedEventTypeName,
  CartItemSetEventData,
  CartItemSetEventTypeName,
  DiscountGuidContainer,
  DiscountSetEventData,
  DiscountV2EventData,
  ICartEntity,
  LinkAddedEventTypeName,
  LinkedEntitiesEventData,
  LinkRemovedEventTypeName,
  toV2Discount,
} from '../Transactions/TransactionTypes'
import {
  EstimateAcceptedEventData,
  EstimateAcceptedEventTypeName,
  EstimateCreatedEventData,
  EstimateCreatedEventTypeName,
  EstimateDeletedEventTypeName,
  EstimatePresentedEventTypeName,
  EstimateRejectedEventTypeName,
  InvoiceCreatedFromEstimateEventData,
  InvoiceCreatedFromEstimateEventTypeName,
} from './EstimateEventDataTypes'
import {
  AcceptEstimateInput,
  DeleteEstimateInput,
  EstimateCreationData,
  EstimateDisplayNameSetEventData,
  EstimateDisplayNameSetEventTypeName,
  EstimateDuplicateForAccountInput,
  EstimateEntityTypeName,
  EstimateGuidContainer,
  EstimateStatus,
  EstimateSummarySetEventData,
  EstimateSummarySetEventTypeName,
  EstimateViewModel,
  isEstimateDisplayNameSetEvent,
  isEstimateSummarySetEvent,
} from './EstimateTypes'

const entityTypeName = EstimateEntityTypeName
const notInitializedEx = () => new BusinessResourceDoesNotExistException(`${entityTypeName} is not initialized`)
const alreadyInitializedEx = () => new BusinessResourceConflictException(`${entityTypeName} is already initialized`)
const alreadyAcceptedEx = () =>
  new BusinessResourceConflictException(`${entityTypeName} is already Accepted. Changes not permitted.`)

export class Estimate implements ICartEntity {
  private readonly clock: () => IsoDateString
  private internalModel: Mutable<EstimateViewModel> | undefined

  static load = async (eventStore: IEventStore, req: ForCompany<EstimateGuidContainer>): Promise<Estimate> =>
    new Estimate(eventStore).load(req)

  static readViewModel = async (
    eventStore: IEventStore,
    req: ForCompany<EstimateGuidContainer>,
  ): Promise<EstimateViewModel> => await Estimate.load(eventStore, req).then(m => m.getComprehensiveViewModel())

  static createFinanceViewModeFromEvents = async (
    events: Event<unknown>[],
    entityReq: EstimateGuidContainer,
  ): Promise<FinanceDocumentViewModel> => {
    const x = new Estimate(new ReadOnlyEventStore(events))
    return await x
      .load({ companyGuid: events[0].companyGuid, estimateGuid: entityReq.estimateGuid })
      .then(x => x.getComprehensiveViewModel())
      .then(vm => ({ ...vm, documentType: EstimateEntityTypeName, estimateMetadata: vm }))
  }

  static invoke = async <T = undefined>(
    eventStore: IEventStore,
    req: ForCompany<EstimateGuidContainer>,
    f: (est: Estimate) => Promise<T>,
  ): Promise<T> => {
    const estimate = await Estimate.load(eventStore, req)
    return f(estimate)
  }

  constructor(private readonly eventStore: IEventStore, clockOverride?: () => IsoDateString) {
    this.clock = clockOverride ? clockOverride : () => new Date().toISOString()
  }

  // Queries

  isInitialized = (): boolean => !!this.internalModel

  getComprehensiveViewModel = (): EstimateViewModel => {
    if (!this.internalModel) throw notInitializedEx()
    return this.internalModel
  }

  getFinanceDocumentViewModel = (): FinanceDocumentViewModel => {
    const vm = this.getComprehensiveViewModel()
    return { ...vm, documentType: entityTypeName, estimateMetadata: vm }
  }

  // Commands

  init = async (data: EstimateCreationData): Promise<GuidAndReferenceNumber> => {
    if (this.internalModel) throw alreadyInitializedEx()

    const estimateGuid = nextGuid()
    this.internalModel = {
      originatingUserGuid: data.userGuid,
      estimateGuid,
      estimateVersion: 0,
      status: EstimateStatus.CREATED,
      generatedInvoiceGuids: [],
      targetDocumentType: 'Estimate',
      issuedAt: this.clock(),
      discountsV2: [],
      ...data,
      ...calculateCartOrderSummary(data),
    }

    // NOTE: Manually copying instead of spreading to avoid data leakage
    const e = this.createNextEvent<EstimateCreatedEventData>({
      eventType: EstimateCreatedEventTypeName,
      eventData: {
        transactionGuid: data.transactionGuid,
        transactionVersion: data.transactionVersion,
        accountGuid: data.accountGuid,
        items: data.items,
        discount: data.discount,
        taxRate: data.taxRate,
        referenceNumber: data.referenceNumber,
        links: data.links,
        credit: data.credit,
        summary: data.summary,
        displayName: data.displayName,
      },
      userGuid: data.userGuid,
    })

    return await this.persistAndApplyEvent(e).then(() => ({
      guid: estimateGuid,
      referenceNumber: data.referenceNumber,
    }))
  }

  // prettier-ignore
  load = async (req: ForCompany<EstimateGuidContainer>): Promise<Estimate> => {
    this.requireNotInitialized()
    const events = await this.eventStore.read({ entityGuid: req.estimateGuid, companyGuid: req.companyGuid })
    events.map(this.applyEvent)

    return this;
  }

  loadComprehensiveViewModel = async (req: ForCompany<EstimateGuidContainer>): Promise<EstimateViewModel> =>
    this.load(req).then(x => x.getComprehensiveViewModel())

  setItem = (input: ForUser<CartItemSetEventData>): Promise<void> =>
    this.applyEventIfInitializedAndNotApproved(CartItemSetEventTypeName, input)

  removeItem = (input: ForUser<CartItemGuidContainer>): Promise<void> =>
    this.applyEventIfInitializedAndNotApproved(CartItemRemovedEventTypeName, input)

  /** @deprecated Use upsertDiscountV2 and removeDiscountV2 instead */
  setDiscount = (input: ForUser<DiscountSetEventData>): Promise<void> =>
    this.applyEventIfInitializedAndNotApproved(CartDiscountSetEventTypeName, input)

  upsertDiscountV2 = async (input: ForUser<DiscountV2EventData>): Promise<void> => {
    const currentDiscounts = this.internalModel?.discountsV2 || []
    const hasDifferentType = currentDiscounts.some(discount => discount.type !== input.type)
    if (hasDifferentType) {
      const existingType = currentDiscounts[0].type
      const newType = input.type
      if (existingType === DiscountType.RATE && newType === DiscountType.FLAT) {
        throw new Error('Cannot apply a flat discount when a rate discount is already present')
      }
      if (existingType === DiscountType.FLAT && newType === DiscountType.RATE) {
        throw new Error('Cannot apply a rate discount when a flat discount is already present')
      }
      throw new Error('Cannot upsert discount with a different type from existing discounts')
    }
    if (currentDiscounts.some(discount => discount.type === DiscountType.RATE)) {
      throw new Error('Cannot apply more than one rate discount')
    }

    return this.applyEventIfInitialized(CartDiscountV2UpsertEventTypeName, input)
  }

  removeDiscountV2 = (input: ForUser<DiscountGuidContainer>): Promise<void> =>
    this.applyEventIfInitialized(CartDiscountV2RemovedEventTypeName, input)

  linkTo = (input: ForUser<LinkedEntitiesEventData>) => this.applyEventIfInitialized(LinkAddedEventTypeName, input)

  unlinkFrom = (input: ForUser<LinkedEntitiesEventData>) =>
    this.applyEventIfInitialized(LinkRemovedEventTypeName, input)

  markPresented = (input: UserGuidContainer) => this.applyEventIfInitialized(EstimatePresentedEventTypeName, input)

  markDeleted = async (input: DeleteEstimateInput): Promise<void> =>
    this.applyEventIfInitializedAndNotApproved(EstimateDeletedEventTypeName, input)

  markRejected = async (input: UserGuidContainer, businessEventPublisher: BusinessEventPublisher) => {
    await this.applyEventIfInitialized(EstimateRejectedEventTypeName, input)
    await businessEventPublisher.publish({
      businessEventType: 'ESTIMATE_REJECTED',
      companyGuid: this.getComprehensiveViewModel().companyGuid,
      data: {
        actingUserGuid: input.userGuid,
        estimateComprehensiveViewModelSnapshot: this.getComprehensiveViewModel(),
      },
    })
  }

  markAccepted = (input: ForUser<EstimateAcceptedEventData>) =>
    this.applyEventIfInitialized(EstimateAcceptedEventTypeName, input)

  generateInvoice = async (
    input: ForUser<InvoiceFinalizeInput>,
    invoiceEventStore: IEventStore,
    invoiceReferenceNumberCreator: IEventSourceReferenceNumberProvider,
    displayIdGenerator: DisplayIdGenerator,
  ): Promise<Invoice> => {
    const invoice = new Invoice(invoiceEventStore)
    const vm = this.getComprehensiveViewModel()

    const displayId = await displayIdGenerator({ companyGuid: vm.companyGuid, entityType: 'INVOICE' })

    const referenceNumber = await invoiceReferenceNumberCreator({
      companyGuid: vm.companyGuid,
      entityType: InvoiceEntityTypeName,
    })

    const { guid: invoiceGuid } = await invoice.init({
      ...vm,
      ...input,
      referenceNumber,
      displayId,
      links: { ...vm.links, estimateGuid: vm.estimateGuid },
    })

    await this.applyEventIfInitialized(InvoiceCreatedFromEstimateEventTypeName, {
      ...input,
      invoiceGuid,
      referenceNumber,
    })

    return invoice
  }

  acceptEstimate = async (
    input: AcceptEstimateInput,
    fileStorageWriter: IFileStorageWriter,
    businessEventPublisher: BusinessEventPublisher,
  ): Promise<void> => {
    const estimateViewModel = this.getComprehensiveViewModel()

    const now = new Date(this.clock()).valueOf()

    const fileUrnComponents: string[] = []
    fileUrnComponents.push('companies', estimateViewModel.companyGuid)
    fileUrnComponents.push('accounts', estimateViewModel.accountGuid)
    fileUrnComponents.push('estimates', estimateViewModel.estimateGuid)
    fileUrnComponents.push('version', `${estimateViewModel.estimateVersion}`)
    fileUrnComponents.push(`signature_${now}.png`)
    const fileUrn = fileUrnComponents.join(':')

    const signatureUrl = await fileStorageWriter.write({
      fileUrn,
      base64EncodedFile: input.base64EstimateSignaturePng,
    })

    await this.markAccepted({
      userGuid: input.userGuid,
      signatureLinkUrl: signatureUrl,
    })

    const updatedEstimateComprehensiveViewModel = this.getComprehensiveViewModel()

    await businessEventPublisher.publish({
      businessEventType: 'ESTIMATE_ACCEPTED',
      companyGuid: estimateViewModel.companyGuid,
      data: {
        actingUserGuid: input.userGuid,
        estimateComprehensiveViewModelSnapshot: updatedEstimateComprehensiveViewModel,
      },
    })
  }

  setSummary = (input: ForUser<EstimateSummarySetEventData>) =>
    this.applyEventIfInitialized(EstimateSummarySetEventTypeName, input)

  setDisplayName = (input: ForUser<EstimateDisplayNameSetEventData>) =>
    this.applyEventIfInitialized(EstimateDisplayNameSetEventTypeName, input)

  duplicateForSameJob = async (input: ForUser<ReferenceNumberContainer>) => {
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Estimate is not initialized')
    const data = this.internalModel
    if (!data.items || data.items.length === 0)
      throw new InvalidEntityStateException('Estimate has no items. An Estimate requires at least one item.')
    if (data.subtotalPriceUsd < 0)
      throw new InvalidEntityStateException(
        'Estimate total is negative. An Estimate cannot be issued for an amount less than $0',
      )

    const estimate = new Estimate(this.eventStore)
    // NOTE: Manually map here, because a spread would result in riders which ought not to come along
    return await estimate
      .init({
        companyGuid: data.companyGuid,
        transactionGuid: data.transactionGuid,
        transactionVersion: data.transactionVersion,
        accountGuid: data.accountGuid,
        items: data.items,
        discount: data.discount,
        taxRate: data.taxRate,
        links: data.links,
        credit: data.credit,
        summary: data.summary,
        referenceNumber: input.referenceNumber,
        userGuid: input.userGuid,
        displayName: data.displayName,
      })
      .then(() => estimate)
  }

  duplicateForAccount = async (input: EstimateDuplicateForAccountInput) => {
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Estimate is not initialized')
    const data = this.internalModel
    if (!data.items || data.items.length === 0)
      throw new InvalidEntityStateException('Estimate has no items. An Estimate requires at least one item.')
    if (data.subtotalPriceUsd < 0)
      throw new InvalidEntityStateException(
        'Estimate total is negative. An Estimate cannot be issued for an amount less than $0',
      )

    const estimate = new Estimate(this.eventStore)
    // NOTE: Manually map here, because a spread would result in riders which ought not to come along
    return await estimate
      .init({
        companyGuid: data.companyGuid,
        transactionGuid: data.transactionGuid,
        transactionVersion: data.transactionVersion,
        items: data.items,
        discount: data.discount,
        taxRate: data.taxRate,
        credit: data.credit,
        summary: data.summary,
        referenceNumber: input.referenceNumber,
        userGuid: input.userGuid,
        accountGuid: input.accountGuid,
        displayName: data.displayName,
        links: {
          ...data.links,
          jobGuid: input.jobGuid,
          jobAppointmentGuid: undefined,
        },
      })
      .then(() => estimate)
  }

  // Internal

  private applyEvent = (e: Event<unknown>): void => {
    if (e.eventType === EstimateCreatedEventTypeName) {
      const te = e as Event<EstimateCreatedEventData>
      this.internalModel = {
        originatingUserGuid: te.actingUserGuid,
        estimateVersion: e.entityVersion,
        estimateGuid: e.entityGuid,
        companyGuid: e.companyGuid,
        status: EstimateStatus.CREATED,
        generatedInvoiceGuids: [],
        targetDocumentType: 'Estimate',
        issuedAt: te.occurredAt,
        discountsV2: [],
        ...te.eventData,
        ...calculateCartOrderSummary(te.eventData),
      }
      if ((te.eventData.discount.discountAmountUsd ?? 0) !== 0 || (te.eventData.discount.discountRate ?? 0) !== 0) {
        this.internalModel.discountsV2 = [toV2Discount(te.eventData.discount)]
      }

      this.updateOrderSummary()
      return
    }
    if (!this.internalModel) throw notInitializedEx()

    const vm = this.internalModel
    vm.estimateVersion = e.entityVersion > vm.estimateVersion ? e.entityVersion : vm.estimateVersion
    if (e.eventType === CartItemSetEventTypeName) {
      const te = e as Event<CartItem>
      vm.items = vm.items.filter(i => i.itemGuid !== te.eventData.itemGuid).concat([te.eventData])
      this.updateOrderSummary()
    } else if (e.eventType === CartItemRemovedEventTypeName) {
      const te = e as Event<CartItemGuidContainer>
      vm.items = vm.items.filter(i => i.itemGuid !== te.eventData.itemGuid)
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountSetEventTypeName) {
      const te = e as Event<DiscountSetEventData>
      vm.discount = te.eventData
      if ((te.eventData.discountAmountUsd ?? 0) !== 0 || (te.eventData.discountRate ?? 0) !== 0) {
        vm.discountsV2 = [toV2Discount(te.eventData)]
      }
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountV2UpsertEventTypeName) {
      const te = e as Event<DiscountV2EventData>
      const existingIndex = vm.discountsV2.findIndex(d => d.discountGuid === te.eventData.discountGuid)
      if (existingIndex !== -1) {
        vm.discountsV2[existingIndex] = te.eventData
      } else {
        vm.discountsV2.push(te.eventData)
      }
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountV2RemovedEventTypeName) {
      const te = e as Event<DiscountGuidContainer>
      const existingIndex = vm.discountsV2.findIndex(d => d.discountGuid === te.eventData.discountGuid)
      if (existingIndex !== -1) {
        vm.discountsV2.splice(existingIndex, 1)
      }
      this.updateOrderSummary()
    } else if (e.eventType === LinkAddedEventTypeName) {
      const te = e as Event<LinkedEntitiesEventData>
      for (const entity of te.eventData.entities) {
        vm.links[entity.guidType] = entity.guid
      }
    } else if (e.eventType === LinkRemovedEventTypeName) {
      const te = e as Event<LinkedEntitiesEventData>
      for (const entity of te.eventData.entities)
        if (vm.links[entity.guidType] === entity.guid) vm.links[entity.guidType] = undefined
    } else if (e.eventType === EstimatePresentedEventTypeName) {
      vm.issuedAt = e.occurredAt
      vm.status = EstimateStatus.PRESENTED
    } else if (e.eventType === EstimateDeletedEventTypeName) {
      vm.status = EstimateStatus.DELETED
    } else if (e.eventType === EstimateRejectedEventTypeName) {
      vm.status = EstimateStatus.REJECTED
    } else if (e.eventType === EstimateAcceptedEventTypeName) {
      vm.status = EstimateStatus.ACCEPTED
      vm.soldAt = e.occurredAt
      vm.signatureUrl = (e as Event<EstimateAcceptedEventData>).eventData.signatureLinkUrl
    } else if (e.eventType === InvoiceCreatedFromEstimateEventTypeName) {
      const te = e as Event<InvoiceCreatedFromEstimateEventData>
      vm.generatedInvoiceGuids = vm.generatedInvoiceGuids.concat(te.eventData.invoiceGuid)
    } else if (isEstimateSummarySetEvent(e)) {
      vm.summary = e.eventData.summary
    } else if (isEstimateDisplayNameSetEvent(e)) {
      vm.displayName = e.eventData.displayName
    } else {
      throw new MissingCaseError(`Unknown event type: ${e.eventType}`)
    }
    return
  }

  private updateOrderSummary = () => {
    if (!this.internalModel) throw notInitializedEx()
    const vm = this.internalModel

    const discountType = vm.discountsV2.reduce((_, curr) => curr.type, DiscountType.FLAT)
    if (discountType === DiscountType.FLAT) {
      vm.discount = vm.discountsV2.reduce(
        (acc, curr) => {
          if (curr.type === DiscountType.FLAT) {
            return {
              type: DiscountType.FLAT,
              discountAmountUsd: (acc.discountAmountUsd || 0) + (curr.discountAmountUsd || 0),
              discountRate: null,
            }
          }
          return acc
        },
        { type: DiscountType.FLAT, discountAmountUsd: 0, discountRate: null },
      )
    }
    if (discountType === DiscountType.RATE) {
      vm.discount = vm.discountsV2.reduce(
        (acc, curr) => {
          if (curr.type === DiscountType.RATE) {
            return {
              type: DiscountType.RATE,
              discountRate: (acc.discountRate || 0) + (curr.discountRate || 0),
              discountAmountUsd: null,
            }
          }
          return acc
        },
        { type: DiscountType.RATE, discountRate: 0, discountAmountUsd: null },
      )
    }

    const cartUpdate = calculateCartOrderSummary(vm)

    this.internalModel = {
      ...vm,
      ...cartUpdate,
    }
  }

  private requireNotInitialized = (): void => {
    if (this.isInitialized()) {
      throw alreadyInitializedEx()
    }
  }

  private requireInitialized = (): void => {
    if (!this.isInitialized()) {
      throw notInitializedEx()
    }
  }

  private requireNotAccepted = (): void => {
    if ((this.internalModel?.status ?? EstimateStatus.CREATED) === EstimateStatus.ACCEPTED) {
      throw alreadyAcceptedEx()
    }
  }

  private applyEventIfInitializedAndNotApproved = async <TEventData>(
    eventType: string,
    userInput: ForUser<TEventData>,
  ): Promise<void> => {
    this.requireNotAccepted()
    return this.applyEventIfInitialized(eventType, userInput)
  }

  private applyEventIfInitialized = async <TEventData>(
    eventType: string,
    userInput: ForUser<TEventData>,
  ): Promise<void> => {
    this.requireInitialized()
    const e = this.createNextEvent<TEventData>({ eventType, eventData: userInput, userGuid: userInput.userGuid })
    return this.persistAndApplyEvent(e)
  }

  private persistAndApplyEvent = async (e: Event<unknown>): Promise<void> => {
    await this.eventStore.create(e)
    return this.applyEvent(e)
  }

  private createNextEvent = <TEventData>({
    eventType,
    eventData,
    userGuid,
  }: ForUser<EventPayload<TEventData>>): Event<TEventData> => {
    if (!this.internalModel) throw notInitializedEx()
    const { companyGuid, estimateVersion, estimateGuid } = this.internalModel
    const nextVersion = estimateVersion + 1

    return {
      companyGuid,
      entityType: entityTypeName,
      entityGuid: estimateGuid,
      entityVersion: nextVersion,
      actingUserGuid: userGuid,
      occurredAt: this.clock(),
      eventType,
      eventData,
    }
  }
}
