import { useCallback } from 'react'
import { useBeforeUnload } from 'react-router-dom'

// This will warn them of unsaved changes when they try to leave breezy
export const useUnsavedChangesWarning = (
  shouldBlock: boolean | ((e: BeforeUnloadEvent) => boolean) = true,
) => {
  const beforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (typeof shouldBlock === 'function' ? shouldBlock(e) : shouldBlock) {
        e.preventDefault()
      }
    },
    [shouldBlock],
  )
  useBeforeUnload(beforeUnload)
}
