import { PlusOutlined } from '@ant-design/icons'
import { PhotoLinks, PhotoRecord, isNullish, nextGuid } from '@breezy/shared'
import {
  faCamera,
  faEllipsis,
  faImage,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { trpc } from '../../hooks/trpc'
import { useModalState } from '../../utils/react-utils'
import { BaseLoadingSpinner } from '../LoadingSpinner'
import { ResizedImage, ResizedImageProps } from '../ResizedImage/ResizedImage'

import { useIsDesktop } from '../../hooks/useIsMobile'
import './AsyncPhotoUpload.less'
import {
  AsyncData,
  AsyncUploadWrapper,
  GetExtraData,
  OnUploadChange,
  UploadButton,
  UseUploadProps,
  WriteRecord,
  useUpload,
} from './Upload'

export type PhotoUploadExtraData = {
  cdnFileNameAndPath: string
  photoGuid: string
}

export type AsyncPhotoData = AsyncData<PhotoUploadExtraData>

export type OnPhotoUploadChange = OnUploadChange<
  PhotoUploadExtraData,
  PhotoRecord
>

export const usePhotoUpload = (
  onPhotoUploadChange: OnPhotoUploadChange,
  links?: PhotoLinks,
): UseUploadProps<PhotoUploadExtraData, PhotoRecord, PhotoLinks> & {
  loading: boolean
} => {
  const [loading, setLoading] = useState(false)
  const onUploadChange = useCallback<OnPhotoUploadChange>(
    (data, photoRecord) => {
      setLoading(!data.recordWritten)
      onPhotoUploadChange(data, photoRecord)
    },
    [onPhotoUploadChange],
  )

  const getExtraData = useCallback<GetExtraData<PhotoUploadExtraData>>(data => {
    const photoGuid = nextGuid()
    const cdnFileNameAndPath = `${photoGuid}/${data.filename}`
    return {
      photoGuid,
      cdnFileNameAndPath,
      ...data,
    }
  }, [])

  const writePhotoRecord =
    trpc.photos['photos:write-photo-record'].useMutation()

  const writeRecord = useCallback<
    WriteRecord<PhotoUploadExtraData, PhotoRecord>
  >(
    async data => {
      const photoRecord = await writePhotoRecord.mutateAsync({
        photoGuid: data.photoGuid,
        cdnUrl: data.cdnUrl,
        resourceUrn: data.resourceUrn,
        ...(links ?? {}),
      })
      return photoRecord
    },
    [links, writePhotoRecord],
  )

  return {
    loading,
    onUploadChange,
    writeRecord,
    getExtraData,
    isImage: true,
  }
}

type AsyncPhotoUploadProps = {
  onPhotoUploadChange: OnPhotoUploadChange
  links?: PhotoLinks
  disabled?: boolean
}

export const AsyncPhotoUpload = React.memo<AsyncPhotoUploadProps>(
  ({ onPhotoUploadChange, links, disabled }) => {
    const { loading, ...useUploadProps } = usePhotoUpload(
      onPhotoUploadChange,
      links,
    )

    return (
      <AsyncUploadWrapper accept="image/*" {...useUploadProps}>
        <UploadButton
          disabled={disabled}
          icon={<FontAwesomeIcon icon={faImage} />}
          loading={loading}
        >
          Choose from library
        </UploadButton>
      </AsyncUploadWrapper>
    )
  },
)

export const AsyncTakePhoto = React.memo<AsyncPhotoUploadProps>(
  ({ onPhotoUploadChange, links, disabled }) => {
    const { loading, ...useUploadProps } = usePhotoUpload(
      onPhotoUploadChange,
      links,
    )

    const uploadPhoto = useUpload(useUploadProps)

    return (
      <AsyncTakePhotoExternalUpload
        uploadPhoto={uploadPhoto}
        disabled={disabled}
        loading={loading}
      />
    )
  },
)

export const AsyncTakePhotoExternalUpload = React.memo<{
  uploadPhoto: (file: File) => Promise<void>
  disabled?: boolean
  loading?: boolean
}>(({ uploadPhoto, disabled = false, loading = false }) => {
  const takePhotoInputRef = useRef<HTMLInputElement>(null)

  // https://stackoverflow.com/questions/51273828/how-to-test-if-the-browser-support-input-capture
  const supportsCamera = useMemo(() => {
    const fakeInput = document.createElement('input')
    const supportsCamera = !isNullish(fakeInput.capture)
    fakeInput.remove()
    return supportsCamera
  }, [])

  const onCaptureInputChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    e => {
      if (e.target.files?.[0]) {
        uploadPhoto(e.target.files[0])
      }
    },
    [uploadPhoto],
  )

  if (!supportsCamera) {
    return null
  }
  return (
    <>
      <div onClick={() => takePhotoInputRef.current?.click()}>
        <UploadButton
          disabled={disabled}
          icon={<FontAwesomeIcon icon={faCamera} />}
          loading={loading}
        >
          Take photo{' '}
        </UploadButton>
      </div>
      <input
        ref={takePhotoInputRef}
        className="hidden"
        type="file"
        accept="image/*"
        capture="environment"
        onChange={onCaptureInputChange}
      />
    </>
  )
})

type AsyncPhotoUploadWithThumbnailProps = {
  thumbnail: Omit<ResizedImageProps, 'cdnUrl'>
  sourcePhotoUrl: string | undefined
  photoUrl?: string | undefined
  defaultPhotoUrl?: string | undefined
  onPhotoUploadChange: OnPhotoUploadChange
  onOpenCrop?: () => void
  onRevertToDefaultPhoto?: () => void
  onRemovePhoto?: () => void
  links?: PhotoLinks
  disabled?: boolean
  onOpenActions?: () => void
  loading?: boolean
}

export const AsyncPhotoUploadWithThumbnail =
  React.memo<AsyncPhotoUploadWithThumbnailProps>(
    ({
      onPhotoUploadChange,
      links,
      disabled,
      thumbnail,
      sourcePhotoUrl,
      photoUrl,
      defaultPhotoUrl,
      onOpenCrop,
      onRevertToDefaultPhoto,
      onRemovePhoto,
      onOpenActions,
      loading: externalLoading = false,
    }) => {
      const isDesktop = useIsDesktop()
      const [imageActionsVisible, openImageActions, closeImageActions] =
        useModalState()
      const onPhotoUploadChangeInner = useCallback<OnPhotoUploadChange>(
        (data, photoRecord) => {
          onPhotoUploadChange(data, photoRecord)
          closeImageActions()
        },
        [onPhotoUploadChange, closeImageActions],
      )
      const { loading: isLoading, ...useUploadProps } = usePhotoUpload(
        onPhotoUploadChangeInner,
        links,
      )

      const loading = useMemo(
        () => isLoading || externalLoading,
        [isLoading, externalLoading],
      )

      const onRevert = useCallback(() => {
        onRevertToDefaultPhoto?.()
        closeImageActions()
      }, [onRevertToDefaultPhoto, closeImageActions])

      const onRemove = useCallback(() => {
        onRemovePhoto?.()
        closeImageActions()
      }, [onRemovePhoto, closeImageActions])

      const canRevertToDefaultPhoto = useMemo(() => {
        if (!isNullish(defaultPhotoUrl)) {
          return defaultPhotoUrl !== photoUrl
        }
        return photoUrl !== sourcePhotoUrl
      }, [defaultPhotoUrl, photoUrl, sourcePhotoUrl])

      const imageActions = useMemo(
        () => (
          <div className="flex flex-col">
            {!isNullish(onOpenCrop) && (
              <Button
                type="text"
                className="w-full text-left"
                onClick={onOpenCrop}
              >
                Edit Crop
              </Button>
            )}
            <AsyncUploadWrapper accept="image/*" {...useUploadProps}>
              <Button
                type="text"
                loading={loading}
                className="w-full text-left"
                disabled={loading}
              >
                Replace
              </Button>
            </AsyncUploadWrapper>
            {onRevertToDefaultPhoto && (
              <Button
                type="text"
                className="w-full text-left"
                disabled={!canRevertToDefaultPhoto}
                onClick={onRevert}
              >
                Revert to Default
              </Button>
            )}
            {onRemovePhoto && (
              <Button
                type="text"
                className="w-full text-left text-red-500"
                disabled={loading}
                onClick={onRemove}
              >
                Remove
              </Button>
            )}
          </div>
        ),
        [
          onOpenCrop,
          useUploadProps,
          loading,
          onRevertToDefaultPhoto,
          canRevertToDefaultPhoto,
          onRevert,
          onRemovePhoto,
          onRemove,
        ],
      )

      return (
        <>
          {sourcePhotoUrl ? (
            <div
              className="group relative block h-fit w-fit rounded-lg border border-solid border-bz-gray-500 p-1"
              onClick={onOpenActions}
            >
              {isDesktop && (
                <div className="absolute inset-0 z-20 rounded-lg bg-bz-mask opacity-0 transition-opacity duration-200 group-hover:opacity-100" />
              )}
              {onOpenActions ? (
                <Button
                  className="absolute right-0 top-0 z-30 text-gray-600 hover:bg-bz-text-hover hover:text-gray-800 active:bg-bz-text-hover"
                  type="text"
                  icon={<FontAwesomeIcon icon={faEllipsis} />}
                />
              ) : (
                <Popover
                  open={imageActionsVisible}
                  content={imageActions}
                  onOpenChange={open => {
                    if (!open) {
                      closeImageActions()
                    } else {
                      openImageActions()
                    }
                  }}
                  placement="bottomLeft"
                  trigger="click"
                  overlayClassName="image-actions-popover"
                >
                  <Button
                    className="absolute right-0 top-0 z-30 text-gray-600 hover:bg-bz-text-hover hover:text-gray-800 active:bg-bz-text-hover"
                    type="text"
                    icon={<FontAwesomeIcon icon={faEllipsis} />}
                  />
                </Popover>
              )}

              <ResizedImage
                cdnUrl={photoUrl ?? sourcePhotoUrl}
                width={thumbnail.width}
                height={thumbnail.height}
                className={thumbnail.className}
                imageUpscale={thumbnail.imageUpscale}
                imageMargin={thumbnail.imageMargin}
                loading={loading}
              />
            </div>
          ) : (
            <div className="block h-fit w-fit border border-solid border-transparent p-1">
              <div
                className="flex"
                style={{
                  width: `${thumbnail.width}px`,
                  height: `${thumbnail.height}px`,
                }}
              >
                <AsyncUploadWrapper accept="image/*" {...useUploadProps}>
                  <ThumbnailUploadButton
                    width={thumbnail.width}
                    height={thumbnail.height}
                    icon={<PlusOutlined />}
                    disabled={disabled}
                    loading={loading}
                  >
                    Upload
                  </ThumbnailUploadButton>
                </AsyncUploadWrapper>
              </div>
            </div>
          )}
        </>
      )
    },
  )

type ThumbnailUploadButtonProps = React.PropsWithChildren<{
  width: number
  height: number
  icon: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}>

export const ThumbnailUploadButton = React.memo<ThumbnailUploadButtonProps>(
  ({ children, icon, onClick, disabled, loading, width, height }) => {
    return (
      <Button
        block
        disabled={disabled || loading}
        className="flex flex-col items-center justify-center border-dashed bg-bz-gray-300"
        style={{ width: `${width}px`, height: `${height}px` }}
        onClick={onClick}
      >
        <div className="text-base ">
          {loading ? <BaseLoadingSpinner size={8} /> : icon}
        </div>
        <div className="mt-[6px] text-sm">{children}</div>
      </Button>
    )
  },
)
