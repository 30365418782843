import { BzDateTime, UTC_TIME_ZONE, isNullish } from '@breezy/shared'
import { useQuery } from 'urql'
import {
  INVOICE_FOR_JOB_SOLD_MAINTENANCE_PLAN_QUERY,
  JOB_SOLD_MAINTENANCE_PLAN_QUERY,
} from '../JobOutcomesModal.gql'

/**
 * Queries and returns the maintenance plan that was sold for the specified job, if it exists.
 * Note that because our data model does not have a definitive link between maintenance plans
 * and jobs, this is a "best-guess" approximation as to whether the maintenance plan was sold.
 *
 * @param {string} jobGuid - Job's guid
 *
 * @returns {object|undefined} The sold maintenance plan if it exists. Returns `undefined` if it does not exist, or if
 * there was an issue with fetching the data
 */
export const useJobSoldMaintenancePlan = (jobGuid: string) => {
  const [{ data, fetching }] = useQuery({
    query: JOB_SOLD_MAINTENANCE_PLAN_QUERY,
    variables: {
      jobGuid,
    },
  })

  // TODO We can memoize a lot of this
  const [{ data: invoiceData, fetching: fetchingInvoiceData }] = useQuery({
    query: INVOICE_FOR_JOB_SOLD_MAINTENANCE_PLAN_QUERY,
    variables: {
      maintenancePlanGuid:
        data?.jobsByPk?.maintenancePlan?.maintenancePlanGuid ?? '',
    },
    pause:
      fetching ||
      isNullish(data) ||
      isNullish(data.jobsByPk) ||
      isNullish(data.jobsByPk.maintenancePlan),
  })

  if (
    fetching ||
    fetchingInvoiceData ||
    isNullish(data) ||
    isNullish(data.jobsByPk) ||
    isNullish(data.jobsByPk.maintenancePlan)
  ) {
    return undefined
  }

  const maintenancePlan = data.jobsByPk.maintenancePlan

  if (
    maintenancePlan.status !== 'ACTIVE' ||
    isNullish(maintenancePlan.activatedAt)
  ) {
    return undefined
  }

  const saleWindowStartTime = BzDateTime.fromIsoString(
    data.jobsByPk.createdAt,
    UTC_TIME_ZONE,
  )
  const saleWindowEndTime = BzDateTime.fromIsoString(
    data.jobsByPk.workCompletedAt ?? BzDateTime.nowUtcIsoString(),
    UTC_TIME_ZONE,
  )
  const saleTime = BzDateTime.fromIsoString(
    maintenancePlan.activatedAt,
    UTC_TIME_ZONE,
  )

  if (
    saleTime.isAfter(saleWindowStartTime) &&
    saleTime.isBefore(saleWindowEndTime)
  ) {
    return {
      ...maintenancePlan,
      invoiceDisplayId: invoiceData?.invoices?.[0]?.displayId,
    }
  }
}
