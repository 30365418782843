import { phoneUtils } from '@breezy/shared'
import { z } from 'zod'
import { PhoneNumberSchema } from '../PhoneNumberField/PhoneNumberField.schema'
import { SmsMessageTextAreaSchema } from '../SmsMessageTextArea/SmsMessageTextArea.schema'

export const SendAsSmsFormSchema = z.object({
  phoneNumber: PhoneNumberSchema.describe('Phone Number').refine(
    val => phoneUtils.isPossibleNumber(val, 'US'),
    val => ({ message: 'Invalid phone number', params: { phoneNumber: val } }),
  ),
  body: SmsMessageTextAreaSchema.describe('Body'),
})

export type SendAsSmsFormData = z.infer<typeof SendAsSmsFormSchema>
