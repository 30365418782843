import {
  IconDefinition,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons'
import { faCreditCard } from '@fortawesome/pro-light-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, useState } from 'react'
import { TilledInput } from '../../../elements/Tilled/TilledInput/TilledInput'
import useTilledForm from '../../../hooks/useTilled'
import { useExpectedTilledMerchantId } from '../../../providers/CompanyFinancialConfigWrapper'
import {
  CreditCardBrand,
  TilledFormFieldChangeHandler,
} from '../../../utils/tilledSdkTypes'
import { useTilledFormState } from './useTilledFormState'

export const CARD_ICON_MAP: Record<CreditCardBrand, IconDefinition> = {
  amex: faCcAmex,
  diners: faCcDinersClub,
  discover: faCcDiscover,
  jcb: faCcJcb,
  maestro: faCreditCard,
  mastercard: faCcMastercard,
  solo: faCreditCard,
  visa: faCcVisa,
  visa_debit: faCcVisa,
  visa_electron: faCcVisa,
  unknown: faCreditCard,
}

export const CARD_NUMBER_ID = 'bz-payment-card-number'
export const CARD_EXP_ID = 'bz-payment-card-exp'
export const CARD_CVV_ID = 'bz-payment-card-cvv'

/* A headless hook that handles the state of the Tilled Credit Card form */
const useTilledCreditCardFormState = () => {
  const tilledMerchantId = useExpectedTilledMerchantId()

  const [cardBrand, setCardBrand] = useState<CreditCardBrand>('unknown')

  const [ccInputState, setCcInputState] = useTilledFormState('invalid')

  const [expInputState, setExpInputState] = useTilledFormState('invalid')
  const [ccvInputState, setCcvInputState] = useTilledFormState('invalid')

  const onSubmitValidationCheck = useCallback(() => {
    let valid = true
    for (const [state, setter] of [
      [ccInputState, setCcInputState],
      [expInputState, setExpInputState],
      [ccvInputState, setCcvInputState],
    ] as const) {
      if (state !== 'valid') {
        setter('invalid-and-shown')
        valid = false
      }
    }
    return valid
  }, [
    ccInputState,
    ccvInputState,
    expInputState,
    setCcInputState,
    setCcvInputState,
    setExpInputState,
  ])

  const onTilledFieldChange = useCallback<TilledFormFieldChangeHandler>(
    event => {
      switch (event.fieldType) {
        case 'cardNumber':
          setCardBrand(event.brand ?? 'unknown')
          setCcInputState(event.valid ? 'valid' : 'invalid')
          break
        case 'cardExpiry':
          setExpInputState(event.valid ? 'valid' : 'invalid')
          break
        case 'cardCvv':
          setCcvInputState(event.valid ? 'valid' : 'invalid')
          break
      }
    },
    [setCcInputState, setCcvInputState, setExpInputState],
  )

  const {
    loading: loadingTilled,
    error: tilledError,
    value: tilledFormInfo,
  } = useTilledForm(
    tilledMerchantId,
    {
      type: 'card',
      fields: {
        cardNumber: { selector: `#${CARD_NUMBER_ID}` },
        cardExpiry: { selector: `#${CARD_EXP_ID}` },
        cardCvv: { selector: `#${CARD_CVV_ID}` },
      },
    },
    onTilledFieldChange,
  )

  return {
    ccInputState,
    expInputState,
    cardBrand,
    ccvInputState,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  }
}

export const useTilledCreditCardForm = () => {
  const {
    ccInputState,
    expInputState,
    cardBrand,
    ccvInputState,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  } = useTilledCreditCardFormState()

  const cardBrandIcon = cardBrand && CARD_ICON_MAP[cardBrand]

  const cardNumberElement = useMemo(() => {
    return (
      <TilledInput
        invalid={ccInputState === 'invalid-and-shown'}
        label="Card number"
        id={CARD_NUMBER_ID}
        prefix={
          cardBrandIcon ? (
            <FontAwesomeIcon
              // There's an issue with "IconDefinition" not quite being the same between the brands library and the regular one
              icon={cardBrandIcon as FontAwesomeIconProps['icon']}
            />
          ) : undefined
        }
      />
    )
  }, [ccInputState, cardBrandIcon])

  const cardExpirationElement = useMemo(() => {
    return (
      <TilledInput
        label="Expiration date"
        id={CARD_EXP_ID}
        invalid={expInputState === 'invalid-and-shown'}
      />
    )
  }, [expInputState])

  const cardCvvElement = useMemo(() => {
    return (
      <TilledInput
        label="CVV"
        id={CARD_CVV_ID}
        invalid={ccvInputState === 'invalid-and-shown'}
      />
    )
  }, [ccvInputState])
  return {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  }
}
