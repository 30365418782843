import {
  AssignableApptViewModel,
  AssignableApptViewModelWithBusinessProjections,
  AssignmentDTO,
  BzDateFns,
  Dfns,
  R,
  TechnicianCapacityBlock,
  TimeWindowDto,
  TimeZoneId,
} from '@breezy/shared'
import { useMemo } from 'react'
import { getNextColor } from '../../utils/color-utils'
import {
  BlockChangeInfo,
  EventChangeInfo,
} from '../ScheduleV2Page/PendingChanges/pendingChanges'
import {
  BzBlockInfo,
  BzCalendarEvent,
  BzChangeInfo,
} from '../ScheduleV2Page/scheduleUtils'
import { useScheduleContext } from './ScheduleContext'

type BlockGuid = string
type AppointmentGuid = string
type AssignmentGuid = string

export const generateScheduleAppointmentMap = (
  unassignedAppointments:
    | AssignableApptViewModelWithBusinessProjections[]
    | undefined,
  assignedAppointments:
    | AssignableApptViewModelWithBusinessProjections[]
    | undefined,
) => {
  const map: Record<AppointmentGuid, AssignableApptViewModel | undefined> = {}
  const appointments = [
    ...(unassignedAppointments ?? []),
    ...(assignedAppointments ?? []),
  ]
  for (const appointment of appointments) {
    map[appointment.appointment.guid] = appointment
  }
  return map
}

export const generateScheduleAppointmentColorMap = (
  assignedAppointments?: AssignableApptViewModelWithBusinessProjections[],
  unassignedAppointments?: AssignableApptViewModelWithBusinessProjections[],
) => {
  // Sort the appointments so if we schedule/unschedule an appointment, the colors
  // don't change
  const appointments = R.sortBy(
    assignable => assignable.appointment.guid,
    [...(assignedAppointments ?? []), ...(unassignedAppointments ?? [])],
  )
  const accountColorMap: Record<string, string> = {}
  const appointmentColorMap: Record<string, string> = {}

  let counter = 0

  for (const appointment of appointments) {
    const accountGuid = appointment.contact.accountGuid
    if (!accountColorMap[accountGuid]) {
      accountColorMap[accountGuid] = getNextColor(counter++)
    }
    appointmentColorMap[appointment.appointment.guid] =
      accountColorMap[accountGuid]
  }

  return appointmentColorMap
}

export const useScheduleAppointmentMap = () => {
  const { scheduleViewModel: vm } = useScheduleContext()

  return useMemo(
    () =>
      generateScheduleAppointmentMap(
        vm?.assignables.unassignedAppointments,
        vm?.assignables.assignedAppointments,
      ),
    [
      vm?.assignables.assignedAppointments,
      vm?.assignables.unassignedAppointments,
    ],
  )
}

export const useScheduleOriginalArrivalWindowMap = () => {
  const appointmentMap = useScheduleAppointmentMap()
  return useMemo(() => {
    const originalArrivalWindowMap: Record<AppointmentGuid, TimeWindowDto> = {}
    for (const appointmentGuid in appointmentMap) {
      const appointment = appointmentMap[appointmentGuid]
      if (appointment) {
        originalArrivalWindowMap[appointmentGuid] =
          appointment.appointment.timeWindow
      }
    }
    return originalArrivalWindowMap
  }, [appointmentMap])
}

type DeletionMetadata =
  | {
      appointmentGuid: AppointmentGuid
      isCancellingAppointment?: boolean
      blockGuid?: never
    }
  | {
      appointmentGuid?: never
      blockGuid: BlockGuid
    }

export type PendingScheduleChanges = {
  eventChangeMap: Record<AssignmentGuid | BlockGuid, BzChangeInfo | undefined>
  newEventMap: Record<AppointmentGuid | BlockGuid, BzCalendarEvent>
  deletedEventMap: Record<AssignmentGuid | BlockGuid, DeletionMetadata>
  arrivalWindowChangeMap: Record<AppointmentGuid, TimeWindowDto>
}

export const DEFAULT_PENDING_CHANGES = {
  eventChangeMap: {},
  newEventMap: {},
  deletedEventMap: {},
  arrivalWindowChangeMap: {},
} satisfies PendingScheduleChanges

export const hasPendingChanges = (changes: PendingScheduleChanges) =>
  R.keys(changes).some(key => R.keys(changes[key]).length > 0)

// Ideally I could take a generic like "T extends keyof PendingScheduleChanges" and have
// typescript figure out what type the value needs to be, but I couldn't get that to
// work.
export type SetPendingChangesArg =
  | {
      field: 'eventChangeMap'
      key: AssignmentGuid | BlockGuid
      value: EventChangeInfo | BlockChangeInfo
    }
  | {
      field: 'newEventMap'
      key: AppointmentGuid | BlockGuid
      value: BzCalendarEvent
    }
  | {
      field: 'deletedEventMap'
      key: AssignmentGuid | BlockGuid
      value: DeletionMetadata
    }
  | {
      field: 'arrivalWindowChangeMap'
      key: AppointmentGuid
      value: TimeWindowDto
    }

export const resolvePendingChanges = (
  { field, key, value }: SetPendingChangesArg,
  pendingChanges: PendingScheduleChanges,
  originalAssignmentMap: OriginalAssignmentMap,
  originalBlockMap: OriginalBlockMap,
  originalArrivalWindowMap: Record<AppointmentGuid, TimeWindowDto>,
): PendingScheduleChanges => {
  if (field === 'eventChangeMap') {
    const newEventLookupKey = isBlockChange(value)
      ? value.blockGuid
      : value.appointmentGuid
    // If we're changing a new event, instead of adding it to the change map, update
    // the new thing.
    if (pendingChanges.newEventMap[newEventLookupKey]) {
      return R.assocPath(
        ['newEventMap', newEventLookupKey],
        {
          ...pendingChanges.newEventMap[newEventLookupKey],
          userGuids: value.userGuids,
          start: value.start,
          end: value.end,
        },
        pendingChanges,
      )
    }

    if (isBlockChange(value)) {
      const existingBlock = originalBlockMap[key]
      if (
        existingBlock &&
        !R.symmetricDifference(existingBlock.userGuids, value.userGuids)
          .length &&
        new Date(existingBlock.start).getTime() ===
          new Date(value.start as string)?.getTime() &&
        new Date(existingBlock.end).getTime() ===
          new Date(value.end as string)?.getTime() &&
        existingBlock.recurrenceRule ===
          (value as BlockChangeInfo).recurrenceRule &&
        existingBlock.recurrenceRuleExceptions ===
          (value as BlockChangeInfo).recurrenceRuleExceptions &&
        existingBlock.reasonType === (value as BlockChangeInfo).reasonType &&
        existingBlock.reasonDescription ===
          (value as BlockChangeInfo).reasonDescription
      ) {
        return R.dissocPath(['eventChangeMap', key], pendingChanges)
      }
    } else {
      const existingAssignment = originalAssignmentMap[key]

      // If this change is the same as the original data (they changed an
      // appointment, then changed it back) then remove from the map instead of
      // updating it.
      if (
        existingAssignment &&
        R.equals(
          [existingAssignment.assignment.technicianUserGuid],
          value.userGuids,
        ) &&
        new Date(existingAssignment.assignment.timeWindow.start).getTime() ===
          new Date(value.start as string)?.getTime() &&
        new Date(existingAssignment.assignment.timeWindow.end).getTime() ===
          new Date(value.end as string)?.getTime()
      ) {
        return R.dissocPath(['eventChangeMap', key], pendingChanges)
      }
    }

    return R.assocPath(['eventChangeMap', key], value, pendingChanges)
  } else if (field === 'newEventMap') {
    return R.assocPath(
      ['newEventMap', key],
      {
        ...value,
        start: value.start as string,
        end: value.end as string,
      },
      pendingChanges,
    )
  } else if (field === 'deletedEventMap') {
    // If we are deleting a new event, instead of adding to `deletedEventMap` just
    // remove from `newEventMap`.
    const newEventKey = R.keys(pendingChanges.newEventMap).find(
      newEventKey =>
        pendingChanges.newEventMap[newEventKey].assignmentGuid === key ||
        pendingChanges.newEventMap[newEventKey].blockGuid === key,
    )
    if (newEventKey) {
      return R.dissocPath(['newEventMap', newEventKey], pendingChanges)
    }
    return {
      ...pendingChanges,
      eventChangeMap: R.dissoc(key, pendingChanges.eventChangeMap),
      deletedEventMap: R.assoc(key, value, pendingChanges.deletedEventMap),
    }
  } else if (field === 'arrivalWindowChangeMap') {
    if (
      originalArrivalWindowMap[key]?.start === value.start &&
      originalArrivalWindowMap[key]?.end === value.end
    ) {
      return R.dissocPath(['arrivalWindowChangeMap', key], pendingChanges)
    }
    return {
      ...pendingChanges,
      arrivalWindowChangeMap: R.assoc(
        key,
        value,
        pendingChanges.arrivalWindowChangeMap,
      ),
    }
  }
  throw new Error('Invalid change type')
}

export type OriginalAssignmentMap = Record<
  AssignmentGuid,
  | { assignment: AssignmentDTO; appointment: AssignableApptViewModel }
  | undefined
>

export type OriginalBlockMap = Record<BlockGuid, TechnicianCapacityBlock>

export const isBlockChange = (
  change: EventChangeInfo | BlockChangeInfo,
): change is BlockChangeInfo => 'blockGuid' in change

export const useScheduleOriginalAssignmentMap = () => {
  const { scheduleViewModel: vm } = useScheduleContext()

  return useMemo(() => {
    const map: OriginalAssignmentMap = {}
    for (const appointment of vm?.assignables.assignedAppointments ?? []) {
      for (const assignment of appointment.assignments) {
        map[assignment.assignmentGuid] = { assignment, appointment }
      }
    }
    return map
  }, [vm?.assignables.assignedAppointments])
}

export const useScheduleOriginalBlockMap = () => {
  const { scheduleViewModel: vm } = useScheduleContext()

  return useMemo(() => {
    const map: OriginalBlockMap = {}
    for (const block of vm?.availability.blocks ?? []) {
      map[block.guid] = block
    }
    return map
  }, [vm?.availability.blocks])
}

export const changeRRuleUntil = (rrule: string, until: string) => {
  // The "until" rule is inclusive, but we want it to exclude the selected date. So we
  // subtract a day.
  const updatedUntil = R.pipe(
    Dfns.parseISO,
    Dfns.subDays(1),
    Dfns.formatISO,
  )(until)

  const parts = rrule.split(';')
  for (const part of parts) {
    if (part.toLowerCase().startsWith('until')) {
      return rrule.replace(part, `UNTIL=${updatedUntil}`)
    }
  }

  return `${rrule};UNTIL=${updatedUntil}`
}

export const toMsbcTime = (timeHour24: number) =>
  `${timeHour24.toString().padStart(2, '0')}:00`

export const withMobiscrollBlockFixForRecurringEvent = (
  rawBlock: BzBlockInfo,
  timeZoneId: TimeZoneId,
): BzBlockInfo => {
  if (!rawBlock.recurrenceRule) {
    return rawBlock
  }

  const block = { ...rawBlock }
  // If they have a recurrence rule, we have to make a special check. Mobiscroll doesn't respect time zones when
  // working with recurring events. It uses the date of the timestamp, even if it's in UTC and we say our data is in
  // a different timezone. So if you make an event in the evening, it's the next day in UTC. Thus, it won't show the
  // first occurrence (because it looks like it's the wrong day of the week if you're recurring weekly) and it will
  // display the occurrences on the wrong days. It's so dumb because it properly transforms the times (if the time
  // is like 1am UTC it will correctly change it to the correct time to the previous night, but just not touch the
  // date). So we have to cheese it. If we detect a "crossover"--the in UTC isn't the same as the date in the local
  // timezone--we need to cheese it and make the dates one day earlier. We need to do the same thing for the
  // recurrence exceptions. We save the original values so when we do a write we are writing the correct values
  // (these new values are essentially for display-only).
  const startDate = BzDateFns.parseISO(block.start, timeZoneId)

  const UTCStartDate = BzDateFns.parseISO(block.start, BzDateFns.UTC)
  if (!BzDateFns.isSameDay(startDate, UTCStartDate)) {
    block.start = BzDateFns.formatISO(
      BzDateFns.subDays(startDate, 1),
      timeZoneId,
    )

    block.end = BzDateFns.withTimeZone(block.end, timeZoneId, date =>
      BzDateFns.subDays(date, 1),
    )

    if (block.recurrenceRuleExceptions) {
      const newExceptions: string[] = []
      for (const exception of block.recurrenceRuleExceptions.split(',')) {
        newExceptions.push(
          BzDateFns.withTimeZone(
            // I know these exceptions are an array of iso dates
            // eslint-disable-next-line breezy/no-to-iso-date-string
            BzDateFns.toIsoDateString(exception),
            timeZoneId,
            date => BzDateFns.subDays(date, 1),
          ),
        )
      }

      block.recurrenceRuleExceptions = newExceptions.join(',')
    }
  }

  return block
}
