import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type OnsitePageSectionPaddingVariant =
  | 'none'
  | 'small'
  | 'medium'
  | 'large'
  | 'large-mobile'

export type OnsitePageSectionProps = React.PropsWithChildren<{
  title?: React.ReactNode
  disabled?: boolean
  className?: string
  hideTopBorder?: boolean
  padding?: OnsitePageSectionPaddingVariant
}>

const getPaddingClassNames = (
  variant: OnsitePageSectionPaddingVariant,
  isMobile: boolean,
  hideTopBorder: boolean = false,
): string => {
  const paddingMap = {
    none: {
      full: '',
      bottom: '',
    },
    small: {
      full: isMobile ? 'py-2' : 'py-3',
      bottom: isMobile ? 'pb-2' : 'pb-3',
    },
    medium: {
      full: isMobile ? 'py-4' : 'py-6',
      bottom: isMobile ? 'pb-4' : 'pb-6',
    },
    large: {
      full: isMobile ? 'py-6' : 'py-8',
      bottom: isMobile ? 'pb-6' : 'pb-8',
    },
    'large-mobile': {
      full: 'py-6',
      bottom: 'pb-6',
    },
  }

  return hideTopBorder ? paddingMap[variant].bottom : paddingMap[variant].full
}

export const OnsitePageSection = React.memo<OnsitePageSectionProps>(
  ({
    children,
    title,
    disabled,
    className,
    hideTopBorder,
    padding = 'medium',
  }) => {
    const isMobile = useIsMobile()
    const paddingClassNames = getPaddingClassNames(
      padding,
      isMobile,
      hideTopBorder,
    )
    return (
      <div
        className={classNames(
          'border-0 border-t-bz-gray-300 first:border-t-0',
          {
            'pointer-events-none cursor-not-allowed opacity-50': disabled,
            'border-t-8 border-solid': !hideTopBorder,
          },
          paddingClassNames,
          className,
        )}
      >
        {title ? (
          <div
            className={classNames('mb-4 text-xl font-semibold', {
              'mx-4': isMobile,
            })}
          >
            {title}
          </div>
        ) : null}
        <div className={classNames('space-y-3', { 'mx-4': isMobile })}>
          {children}
        </div>
      </div>
    )
  },
)
