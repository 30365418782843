import { Divider } from 'antd'
import React, { CSSProperties } from 'react'

type ThinDividerProps = {
  dividerStyle?: 'dashed' | 'dotted' | 'solid'
  widthPx?: number
  borderColorClassName?: string
  styleOverrides?: CSSProperties
}

const ThinDivider: React.FC<ThinDividerProps> = ({
  dividerStyle = 'solid',
  widthPx = 2,
  borderColorClassName = '#f0f0f0',
  styleOverrides,
}) => {
  return (
    <Divider
      style={{
        borderTop: `${dividerStyle} ${widthPx}px ${borderColorClassName}`,
        marginTop: 12,
        marginBottom: 12,
        ...styleOverrides,
      }}
    />
  )
}

export default ThinDivider
