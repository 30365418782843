import { DateTimeFormatter, Instant, LocalDate, nativeJs, ZonedDateTime, ZoneId } from '@js-joda/core'
import { addDays, differenceInDays, isAfter, isBefore, parseISO } from 'date-fns'
import { Dfns } from './dfns'
import { InvalidExternalInputException } from './errors/BusinessException'
import { ENGLISH_LOCALE } from './locales'
import { memoize } from './lodash-helpers'
import { R } from './ramda'
import { occurrences } from './string-utils'

export { default as format } from 'date-fns/format'
export { default as parse } from 'date-fns/parse'
export { addDays, differenceInDays, isAfter, isBefore, parseISO }

type DateTimeWindowOptions = Partial<{ includeDate: boolean; alwaysShowMinutes: boolean }>

/**
 *  @example "Jul 8, 10:00 AM - 12:00 PM"
 */
export const calculateDateTimeWindow = (
  startWindowIsoWithOffsetTimestamp: string,
  endWindowIsoWithOffsetTimestamp: string,
  timezone: string | ZoneId,
  options?: DateTimeWindowOptions,
) => {
  const effectiveZoneId = typeof timezone === 'string' ? ZoneId.of(timezone) : timezone
  const startZonedDateTime = ZonedDateTime.parse(startWindowIsoWithOffsetTimestamp).withZoneSameInstant(effectiveZoneId)
  const endZonedDateTime = ZonedDateTime.parse(endWindowIsoWithOffsetTimestamp).withZoneSameInstant(effectiveZoneId)
  return toDateTimeWindow(startZonedDateTime, endZonedDateTime, options)
}

// Wish we could easily overload here
export const toDateTimeWindow = (
  startZonedDateTime: ZonedDateTime,
  endZonedDateTime: ZonedDateTime,
  options?: DateTimeWindowOptions,
) => {
  const isSameDate = startZonedDateTime.toLocalDate().isEqual(endZonedDateTime.toLocalDate())

  const isSameDateAndTime = startZonedDateTime.toLocalDateTime().isEqual(endZonedDateTime.toLocalDateTime())

  let strBuilder = ''
  if (options?.includeDate) {
    strBuilder += startZonedDateTime
      .toLocalDate()
      .format(DateTimeFormatter.ofPattern('MMM d').withLocale(ENGLISH_LOCALE))
    strBuilder += ', '
  }

  strBuilder += startZonedDateTime
    .toLocalTime()
    .format(DateTimeFormatter.ofPattern('h:mm a').withLocale(ENGLISH_LOCALE))

  if (!isSameDateAndTime) {
    // This is intentionally an en dash
    strBuilder += '–'

    if (options?.includeDate && !isSameDate) {
      strBuilder += endZonedDateTime
        .toLocalDate()
        .format(DateTimeFormatter.ofPattern('MMM d').withLocale(ENGLISH_LOCALE))

      strBuilder += ', '
    }

    strBuilder += endZonedDateTime
      .toLocalTime()
      .format(DateTimeFormatter.ofPattern('h:mm a').withLocale(ENGLISH_LOCALE))
  }

  if (!options?.alwaysShowMinutes) {
    // Remove Redundant Minutes
    strBuilder = strBuilder.replaceAll(':00', '')
  }

  // Remove Repeated AM/PM
  if (occurrences(strBuilder, ' AM') > 1) strBuilder = strBuilder.replace(' AM', '')
  if (occurrences(strBuilder, ' PM') > 1) strBuilder = strBuilder.replace(' PM', '')

  return strBuilder
}

export const coerceToLocalDate = (input: string | LocalDate | Date): LocalDate => {
  if (input instanceof LocalDate) return input

  if (typeof input === 'string') {
    try {
      return LocalDate.parse(input) // try parse as LocalDate
    } catch (error) {
      // If parsing as a local date fails, then try parsing as an Instant
      return Instant.parse(input).atZone(ZoneId.of('America/Los_Angeles')).toLocalDate()
    }
  }

  if (input instanceof Date) {
    return Instant.from(nativeJs(input)).atZone(ZoneId.of('America/Los_Angeles')).toLocalDate()
  }

  throw new InvalidExternalInputException(`Could not coerce input ${input} to LocalDate`)
}

export const toPrettyTimestamp = memoize(R.pipe(Dfns.parseISO, Dfns.format('M/d/yy @ h:mma')))

export const formatLocalDateToHumanFriendlyDate = (isoLocalDateString: string) => {
  const date = LocalDate.parse(isoLocalDateString.substring(0, 10))
  const formatter = DateTimeFormatter.ofPattern('MMM dd, yyyy').withLocale(ENGLISH_LOCALE)
  const formattedDate = date.format(formatter)
  return formattedDate
}
