import {
  BzDateFns,
  BzDateTime,
  formatEquipmentTypeString,
  formatUsc,
  getLastVisitedAt,
  getLastVisitedAtMaintPlansV3,
  getNumActiveMaintenancePlanCredits,
  getNumUnusedMaintenancePlanVisits,
  getOldestEquipmentAgeFromVisits,
  InstalledEquipment,
  isNullish,
  JobClass,
  LocalDateString,
  maintenancePlanCancellationReasonTypesDisplayNames,
  MaintenancePlanDetailsViewModel,
  MaintenancePlanPaymentIntervalDisplayNames,
  MaintenancePlanStatus,
  PaymentMethodDisplayNames,
  VisitViewModel,
} from '@breezy/shared'
import { Button, message } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { DateFormat, DateView } from '../../components/Dates'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { EditVisitsModal } from '../../components/Visits/EditVisitsModal'
import { VisitMiniCard } from '../../components/Visits/VisitMiniCard'
import { ActionItem } from '../../elements/ActionItems/ActionItems'
import ActionItemsButton from '../../elements/ActionItems/ActionItemsButton'
import ThinDivider from '../../elements/ThinDivider'
import useAppNavigation from '../../hooks/useAppNav'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { m, useModalState } from '../../utils/react-utils'
import {
  EXPIRE_VISIT_MUTATION,
  RESTORE_VISIT_MUTATION,
} from './MaintenancePlanInfoCard.gql'
import MaintenancePlanStatusTag from './MaintenancePlanStatusTag'
import { RestoreVisitModal } from './RestoreVisitModal'

type MaintenancePlanInfoCardProps = {
  data: MaintenancePlanDetailsViewModel
  actionItems?: ActionItem[]
}

const CoveredEquipmentList = m<MaintenancePlanInfoCardProps>(({ data }) => (
  <div className="col gap-y-4 rounded-md bg-[#fafafa] p-3">
    <div className="semibold_14_22 grey9">Covered Equipment:</div>
    {(data.coveredEquipment ?? []).length === 0 && (
      <div className="w-full">
        <div className="semibold_14_22 gray9">
          Legacy Plan - No Listed Equipment
        </div>
        <div className="regular_14_22 gray9 mt-2">
          Edit plan to add covered equipment information.
        </div>
      </div>
    )}
    {(data.coveredEquipment ?? []).map(e => (
      <div className="row w-full" key={e.installedEquipmentGuid}>
        <div className="flex w-1/2 flex-col space-y-2">
          <div className="semibold_14_22 gray9">
            {formatEquipmentTypeString(e.equipmentType)}
          </div>
          <div className="regular_14_22 gray9">
            {e.description || formatEquipmentTypeString(e.equipmentType)}
          </div>
        </div>
        <div className="flex w-1/2 flex-col space-y-2">
          <VerticalKeyValue
            pair={{
              key: 'Equipment Age (Years)',
              value:
                InstalledEquipment.yearsEquipmentAge(
                  new Date(),
                  e,
                )?.toString() ?? 'Unknown',
            }}
          />
        </div>
      </div>
    ))}
  </div>
))

type WithRefetch<T> = T & { refetch: () => void }
type WithIndex<T> = T & { index: number }

const PlanVisitsSection = m<WithRefetch<MaintenancePlanInfoCardProps>>(
  ({ data, refetch }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const [editVisit, setEditVisit] = useState<
      WithIndex<VisitViewModel> | undefined
    >(undefined)
    const [expireVisit, setExpireVisit] = useState<
      WithIndex<VisitViewModel> | undefined
    >(undefined)
    const [restoreVisit, setRestoreVisit] = useState<
      WithIndex<VisitViewModel> | undefined
    >(undefined)

    const [showEditModal, openEditModal, closeEditModal] = useModalState()
    const [showExpireModal, openExpireModal, closeExpireModal] = useModalState()
    const [showRestoreModal, openRestoreModal, closeRestoreModal] =
      useModalState()

    const visits = data.visits ?? []
    const sortedVisits = visits.sort((a, b) =>
      a.affinityDate.localeCompare(b.affinityDate),
    )

    const [, expireVisitMutation] = useMutation(EXPIRE_VISIT_MUTATION)
    const [, restoreVisitMutation] = useMutation(RESTORE_VISIT_MUTATION)

    const handleExpireVisit = useCallback(async () => {
      if (expireVisit) {
        try {
          const result = await expireVisitMutation({
            visitGuid: expireVisit.visitGuid,
            isExpiredOverride: true,
          })

          if (result.error) {
            throw result.error
          }

          message.success('Visit expired successfully')
          refetch()
        } catch (error) {
          console.error('Error expiring visit:', error)
          message.error('Failed to expire visit')
        }
        closeExpireModal()
      }
    }, [expireVisit, closeExpireModal, expireVisitMutation, refetch])
    const handleRestoreVisit = useCallback(
      async (newExpirationDate?: LocalDateString) => {
        if (restoreVisit) {
          try {
            const expiresAt = newExpirationDate
              ? BzDateFns.localDateStringToIsoDateString(
                  newExpirationDate,
                  tzId,
                )
              : undefined
            const result = await restoreVisitMutation({
              visitGuid: restoreVisit.visitGuid,
              expiresAt,
            })

            if (result.error) {
              throw result.error
            }

            message.success('Visit restored successfully')
            refetch()
          } catch (error) {
            console.error('Error restoring visit:', error)
            message.error('Failed to restore visit')
          }
          closeRestoreModal()
        }
      },
      [restoreVisit, restoreVisitMutation, refetch, closeRestoreModal, tzId],
    )

    return (
      <>
        <div className="col gap-y-3">
          <div className="semibold_14_22 text-[#202020]">
            Plan Visits{' '}
            <span className="text-[#8c8c8c]">({visits.length})</span>
          </div>
          {sortedVisits.map((v, idx) => (
            <div className="w-full" key={v.visitGuid}>
              <VisitMiniCard
                visit={v}
                visitIndex={idx}
                useInfoLinks
                dataTestId={`visit-mini-card-${idx}`}
                actionItems={[
                  {
                    title: 'Edit Visit',
                    onClick: () => {
                      setEditVisit({ ...v, index: idx })
                      openEditModal()
                    },
                  },
                  v.status === 'EXPIRED'
                    ? {
                        title: 'Restore Visit',
                        onClick: () => {
                          setRestoreVisit({ ...v, index: idx })
                          openRestoreModal()
                        },
                      }
                    : {
                        title: (
                          <span className="text-bz-red-600">Expire Visit</span>
                        ),
                        onClick: () => {
                          setExpireVisit({ ...v, index: idx })
                          openExpireModal()
                        },
                      },
                ]}
              />
            </div>
          ))}
        </div>
        {editVisit && showEditModal && (
          <EditVisitsModal
            visits={[editVisit]}
            accountGuid={data.accountGuid}
            locationGuid={data.locationGuid}
            planActivationDate={
              data.activatedAt
                ? BzDateTime.fromIsoString(
                    data.activatedAt,
                    tzId,
                  ).toLocalDateString()
                : BzDateTime.now(tzId).toLocalDateString()
            }
            onClose={closeEditModal}
            afterSubmit={() => {
              refetch()
              closeEditModal()
            }}
          />
        )}
        {showExpireModal && expireVisit && (
          <OnsiteConfirmModal
            open={showExpireModal}
            onCancel={closeExpireModal}
            onConfirm={handleExpireVisit}
            header="Expire plan visit?"
            confirmText="Expire"
            danger
            cancelText="Cancel"
          >
            <p>
              Are you sure you want to expire{' '}
              <span className="font-semibold">{expireVisit.name}</span>? This
              action can be reversed.
            </p>
          </OnsiteConfirmModal>
        )}
        {showRestoreModal && restoreVisit && (
          <RestoreVisitModal
            open={showRestoreModal}
            onCancel={closeRestoreModal}
            onConfirm={handleRestoreVisit}
            visit={restoreVisit}
            tzId={tzId}
          />
        )}
      </>
    )
  },
)

const MaintenancePlanInfoCard = m<
  WithRefetch<
    MaintenancePlanInfoCardProps & {
      showScheduleVisit: boolean
    }
  >
>(({ data, actionItems, refetch, showScheduleVisit }) => {
  const appNav = useAppNavigation()
  const useMpV3 = useFeatureFlag('createMaintenancePlanV2')

  const lastVisitedAt = useMemo(
    () =>
      useMpV3
        ? getLastVisitedAtMaintPlansV3(data.visits ?? [])
        : getLastVisitedAt(data),
    [data, useMpV3],
  )
  const oldestEquipmentAgeYears = useMemo(() => {
    if (useMpV3) {
      return getOldestEquipmentAgeFromVisits(data.visits ?? [])
    } else {
      return InstalledEquipment.getOldestInstalledEquipmentAgeYears(
        new Date(),
        data.coveredEquipment,
      )
    }
  }, [data, useMpV3])

  const isPaidMaintenancePlan = useMemo(
    // The paymentInterval property being set indicates that pricing has been configured for the plan
    () => data.paymentInterval && data.yearlyPriceUsc > 0,
    [data.paymentInterval, data.yearlyPriceUsc],
  )

  const isLive =
    data.status !== MaintenancePlanStatus.CANCELED &&
    data.status !== MaintenancePlanStatus.NONE &&
    data.status !== MaintenancePlanStatus.EXPIRED

  return (
    <div className="flex flex-col gap-y-4">
      <div className="semibold_20_28">
        Maintenance Plan for{' '}
        <span
          onClick={() => appNav.navigateToAccountDetailsPage(data.accountGuid)}
          className="text-bz-primary hover:cursor-pointer"
        >
          {data.primaryContactDisplayName}
        </span>
        {' at '}
        <span
          onClick={() =>
            appNav.navigateToLocationDetailsPage(data.locationGuid)
          }
          className="text-bz-primary hover:cursor-pointer"
        >
          {data.locationAddress.line1}
        </span>
      </div>
      <div className="row no-wrap gap-x-2">
        {showScheduleVisit && (
          <Button
            onClick={() =>
              appNav.navigateToCreateNewJob({
                jobClass: JobClass.MAINTENANCE.toString(),
                accountGuid: data.accountGuid,
                locationGuid: data.locationGuid,
              })
            }
          >
            Schedule Visit
          </Button>
        )}
        {actionItems && actionItems.length > 0 && (
          <ActionItemsButton
            actionItems={actionItems}
            dataTestId="maintenance-plan-details-action-items"
          />
        )}
      </div>
      <ThinDivider
        widthPx={1}
        styleOverrides={{ marginBottom: 0, marginTop: 0 }}
      />
      <div className="grid grid-cols-2 items-start gap-x-2 gap-y-4">
        <VerticalKeyValue
          pair={{
            key: 'Plan Type',
            value: data.planDefinition?.name ?? 'None',
          }}
        />
        <VerticalKeyValue
          pair={{
            key: 'Plan Status',
            value: <MaintenancePlanStatusTag {...data} />,
          }}
        />
        {isLive && (
          <>
            <VerticalKeyValue
              pair={{
                key: 'Visit Frequency',
                value: `${
                  data.planDefinition?.numVisitCreditsPerYear ?? '0'
                } per year`,
              }}
            />
            <BehindFeatureFlag
              enabledFeatureFlag="createMaintenancePlanV2"
              render={
                <VerticalKeyValue
                  pair={{
                    key: 'Unused Visits',
                    value: `${getNumUnusedMaintenancePlanVisits(
                      data.visits ?? [],
                    ).toString()} of ${data.visits?.length ?? '0'}`,
                  }}
                />
              }
              fallback={
                <VerticalKeyValue
                  pair={{
                    key: 'Unused Visits',
                    value: `${getNumActiveMaintenancePlanCredits(
                      data.credits,
                    ).toString()} of ${
                      data.planDefinition?.numVisitCreditsPerYear ?? '0'
                    }`,
                  }}
                />
              }
            />

            {data.activatedAt && (
              <>
                <VerticalKeyValue
                  pair={{
                    key: 'Activation Date',
                    value: (
                      <DateView
                        isoWithOffsetTimestamp={data.activatedAt}
                        format={DateFormat['MMM d, yyyy']}
                      />
                    ),
                  }}
                />
                <VerticalKeyValue
                  pair={{
                    key: 'Renewal Date',
                    value: data.terminatesAt ? (
                      <DateView
                        isoWithOffsetTimestamp={data.terminatesAt}
                        format={DateFormat['MMM d, yyyy']}
                      />
                    ) : (
                      'Auto-Renewing'
                    ),
                  }}
                />
              </>
            )}

            {isPaidMaintenancePlan && data.paymentInterval && (
              <VerticalKeyValue
                pair={{
                  key: 'Billing Frequency',
                  value:
                    MaintenancePlanPaymentIntervalDisplayNames[
                      data.paymentInterval
                    ],
                }}
              />
            )}
            {isPaidMaintenancePlan && data.billingAnchor && (
              <VerticalKeyValue
                pair={{
                  key: 'Billing Anchor',
                  value: data.billingAnchor,
                }}
              />
            )}

            {data.paymentSubscription?.paymentMethod && (
              <VerticalKeyValue
                pair={{
                  key: 'Payment Method',
                  value:
                    PaymentMethodDisplayNames[
                      data.paymentSubscription.paymentMethod
                    ],
                }}
              />
            )}

            <VerticalKeyValue
              pair={{
                key: 'Payment Amount',
                value: formatUsc(data.paymentAmountUsc),
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Oldest Equipment Age',
                value: !isNullish(oldestEquipmentAgeYears)
                  ? `${oldestEquipmentAgeYears} years`
                  : 'Unknown',
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Last Visit',
                value: lastVisitedAt ? (
                  <DateView
                    isoWithOffsetTimestamp={lastVisitedAt}
                    format={DateFormat['MMM d, yyyy']}
                  />
                ) : (
                  'Never'
                ),
              }}
            />
          </>
        )}
        {data.status === MaintenancePlanStatus.EXPIRED && (
          <>
            {data.activatedAt ? (
              <VerticalKeyValue
                pair={{
                  key: 'Activated Date',
                  value: <DateView isoWithOffsetTimestamp={data.activatedAt} />,
                }}
              />
            ) : (
              <VerticalKeyValue
                pair={{
                  key: 'Created Date',
                  value: <DateView isoWithOffsetTimestamp={data.createdAt} />,
                }}
              />
            )}
            {data.expiredAt && (
              <VerticalKeyValue
                pair={{
                  key: 'Expired Date',
                  value: <DateView isoWithOffsetTimestamp={data.expiredAt} />,
                }}
              />
            )}
          </>
        )}
        {data.status === MaintenancePlanStatus.CANCELED && (
          <>
            {data.activatedAt ? (
              <VerticalKeyValue
                pair={{
                  key: 'Activated Date',
                  value: <DateView isoWithOffsetTimestamp={data.activatedAt} />,
                }}
              />
            ) : (
              <VerticalKeyValue
                pair={{
                  key: 'Created Date',
                  value: <DateView isoWithOffsetTimestamp={data.createdAt} />,
                }}
              />
            )}

            <VerticalKeyValue
              pair={{
                key: 'Cancelled Date',
                value: (
                  <DateView
                    isoWithOffsetTimestamp={data.canceledAt ?? data.updatedAt}
                  />
                ),
              }}
            />
            <VerticalKeyValue
              pair={{
                key: 'Cancellation Reason Type',
                value: (
                  <p>
                    {data.cancellationReasonType
                      ? maintenancePlanCancellationReasonTypesDisplayNames[
                          data.cancellationReasonType
                        ]
                      : 'No reason type'}
                  </p>
                ),
              }}
            />
            <VerticalKeyValue
              pair={{
                key: 'Cancellation Reason',
                value: (
                  <p>{data.cancellationReason ?? 'No cancellation reason'}</p>
                ),
              }}
            />
          </>
        )}
      </div>
      <ThinDivider
        widthPx={1}
        styleOverrides={{ marginBottom: 8, marginTop: 8 }}
      />
      {isLive && !useMpV3 && <CoveredEquipmentList data={data} />}
      {useMpV3 && <PlanVisitsSection data={data} refetch={refetch} />}
    </div>
  )
})

export default MaintenancePlanInfoCard
