import { gql } from '../../generated'

export const EXPIRE_VISIT_MUTATION = gql(/* GraphQL */ `
  mutation ExpireVisit($visitGuid: uuid!, $isExpiredOverride: Boolean!) {
    updateMaintenancePlanVisits(
      where: { maintenancePlanVisitGuid: { _eq: $visitGuid } }
      _set: {
        isExpiredOverride: $isExpiredOverride
        isCompletedOverride: null
        completedAtOverride: null
        jobGuid: null
      }
    ) {
      affectedRows
    }
  }
`)

export const RESTORE_VISIT_MUTATION = gql(/* GraphQL */ `
  mutation RestoreVisit($visitGuid: uuid!, $expiresAt: timestamptz) {
    updateMaintenancePlanVisits(
      where: { maintenancePlanVisitGuid: { _eq: $visitGuid } }
      _set: { isExpiredOverride: null, expiresAt: $expiresAt }
    ) {
      affectedRows
    }
  }
`)
