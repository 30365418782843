import { AssignmentDTO, R, debounce, nextGuid } from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import { fixMbscDate, isBlockEvent } from '../ScheduleV2Page/scheduleUtils'
import { useScheduleContext } from './ScheduleContext'
import { useSchedulePendingChanges } from './SchedulePendingChangesContext'
import {
  DEFAULT_PENDING_CHANGES,
  PendingScheduleChanges,
  isBlockChange,
  useScheduleAppointmentMap,
  useScheduleOriginalAssignmentMap,
  useScheduleOriginalBlockMap,
} from './utils'

const DEBOUNCED_SAVED_MESSAGE_MS = 500
type AppointmentGuid = string
export const useCommitScheduleChanges = () => {
  const message = useMessage()

  const { refetch } = useScheduleContext()
  const { pendingChanges, setPendingChangesRaw } = useSchedulePendingChanges()
  const originalAssignmentMap = useScheduleOriginalAssignmentMap()
  const originalBlockMap = useScheduleOriginalBlockMap()
  const appointmentMap = useScheduleAppointmentMap()

  const putBlockMutation =
    trpc.technicianCapacities['technician-capacity:block:put'].useMutation()
  const deleteBlockMutation =
    trpc.technicianCapacities['technician-capacity:block:delete'].useMutation()
  const upsertAppointmentMutation =
    trpc.appointments['appointment-and-assignments:upsert'].useMutation()
  const cancelAppointmentMutation =
    trpc.appointments['appointments:cancel'].useMutation()

  const debouncedSaveMessage = useMemo(
    () =>
      debounce(
        () => message.success('Schedule saved.'),
        DEBOUNCED_SAVED_MESSAGE_MS,
      ),
    [message],
  )

  // TODO: This is a beast of a function. We should break this up so it's easier to maintain.
  return useCallback(async () => {
    const promises: Promise<unknown>[] = []

    let changesForAppointmentMap: Record<
      AppointmentGuid,
      PendingScheduleChanges
    > = {}

    for (const newEvent of R.values(pendingChanges.newEventMap)) {
      if (isBlockEvent(newEvent)) {
        promises.push(
          putBlockMutation.mutateAsync({
            guid: `${newEvent.blockGuid}`,
            userGuids: newEvent.userGuids,
            start: fixMbscDate(newEvent.start),
            end: fixMbscDate(newEvent.end),
            reasonType: newEvent.reasonType,
            reasonDescription: newEvent.reasonDescription,
            recurrenceRule: newEvent.recurring
              ? `${newEvent.recurring}`
              : undefined,
          }),
        )
      } else {
        const appointment = appointmentMap[newEvent.appointmentGuid]
        if (appointment) {
          changesForAppointmentMap = R.assocPath(
            [newEvent.appointmentGuid, 'newEventMap', newEvent.assignmentGuid],
            newEvent,
            changesForAppointmentMap,
          )
        } else {
          console.error("Couldn't find appointment for new event", newEvent)
          // Shouldn't be possible
          message.error('Something went wrong.')
        }
      }
    }

    for (const change of R.values(pendingChanges.eventChangeMap)) {
      if (!change) {
        continue
      }
      if (isBlockChange(change)) {
        const block = originalBlockMap[change.blockGuid]
        if (block) {
          promises.push(
            putBlockMutation.mutateAsync({
              ...block,
              userGuids: change.userGuids,
              start: change.start,
              end: change.end,
              reasonType: change.reasonType,
              reasonDescription: change.reasonDescription,
              recurrenceRule: change.recurrenceRule,
              recurrenceRuleExceptions: change.recurrenceRuleExceptions,
            }),
          )
        }
      } else {
        const assignment = originalAssignmentMap[change.assignmentGuid]
        if (change && assignment) {
          changesForAppointmentMap = R.assocPath(
            [change.appointmentGuid, 'eventChangeMap', change.assignmentGuid],
            change,
            changesForAppointmentMap,
          )
        } else {
          console.error("Couldn't find assignment for change", change)
          // Shouldn't be possible
          message.error('Something went wrong.')
        }
      }
    }

    for (const deletedGuid of R.keys(pendingChanges.deletedEventMap)) {
      const metadata = pendingChanges.deletedEventMap[deletedGuid]
      if (metadata.blockGuid) {
        promises.push(deleteBlockMutation.mutateAsync({ id: deletedGuid }))
      } else if (metadata.appointmentGuid && metadata.isCancellingAppointment) {
        promises.push(
          cancelAppointmentMutation.mutateAsync({
            jobAppointmentGuid: metadata.appointmentGuid,
          }),
        )
      } else {
        changesForAppointmentMap = R.assocPath(
          [metadata.appointmentGuid ?? '', 'deletedEventMap', deletedGuid],
          metadata,
          changesForAppointmentMap,
        )
      }
    }

    for (const appointmentGuid of R.keys(
      pendingChanges.arrivalWindowChangeMap,
    )) {
      changesForAppointmentMap = R.assocPath(
        [appointmentGuid, 'arrivalWindowChangeMap', appointmentGuid],
        pendingChanges.arrivalWindowChangeMap[appointmentGuid],
        changesForAppointmentMap,
      )
    }

    for (const appointmentGuid of R.keys(changesForAppointmentMap)) {
      const appointment = appointmentMap[appointmentGuid]

      if (appointment) {
        const changes = changesForAppointmentMap[appointmentGuid]
        const newAssignments: AssignmentDTO[] = []
        for (const assignment of appointment.assignments) {
          if (changes.deletedEventMap?.[assignment.assignmentGuid]) {
            continue
          }
          const change = changes.eventChangeMap?.[assignment.assignmentGuid]
          if (change) {
            for (const user of change.userGuids) {
              newAssignments.push({
                assignmentGuid: assignment.assignmentGuid,
                assignmentStatus: assignment.assignmentStatus,
                // NOTE: We assume that an appointment only has one user. This may change
                // in the future.
                technicianUserGuid: user,
                timeWindow: {
                  start: change.start,
                  end: change.end,
                },
              })
            }
          } else {
            newAssignments.push(assignment)
          }
        }
        for (const newEvent of R.values(
          changesForAppointmentMap[appointmentGuid].newEventMap,
        )) {
          if (!isBlockEvent(newEvent)) {
            for (const user of newEvent.userGuids) {
              newAssignments.push({
                assignmentGuid: nextGuid(),
                assignmentStatus: newEvent.assignmentStatus,
                technicianUserGuid: user,
                timeWindow: {
                  start: fixMbscDate(newEvent.start),
                  end: fixMbscDate(newEvent.end),
                },
              })
            }
          }
        }

        promises.push(
          upsertAppointmentMutation.mutateAsync({
            appointmentGuid,
            jobGuid: appointment.jobGuid,
            arrivalWindow:
              changesForAppointmentMap[appointmentGuid]
                ?.arrivalWindowChangeMap?.[appointmentGuid] ??
              appointment.appointment.timeWindow,
            appointmentType: appointment.appointment.appointmentType,
            description: appointment.appointment.description,
            assignments: newAssignments,
            // TODO: this might be the way to have it send notifications when this
            // changes.
            suppressAccountNotifications: true,
          }),
        )
      } else {
        console.error(
          "Couldn't find appointment from appointment change map",
          changesForAppointmentMap[appointmentGuid],
        )
        // Shouldn't be possible
        message.error('Something went wrong.')
      }
    }

    // TODO: we could have an endpoint that took an array of changes
    await Promise.all(promises)
    await refetch()

    const hasChanges = !R.equals(pendingChanges, DEFAULT_PENDING_CHANGES)
    if (hasChanges) {
      debouncedSaveMessage()
    }

    setPendingChangesRaw(DEFAULT_PENDING_CHANGES)
  }, [
    refetch,
    pendingChanges,
    setPendingChangesRaw,
    putBlockMutation,
    appointmentMap,
    message,
    originalBlockMap,
    originalAssignmentMap,
    deleteBlockMutation,
    cancelAppointmentMutation,
    upsertAppointmentMutation,
    debouncedSaveMessage,
  ])
}
