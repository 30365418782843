import { PaymentMethod } from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { useTilledAchForm } from './useTilledAchForm'
import { useTilledCreditCardForm } from './useTilledCreditCardForm'

export const useTilledPaymentForm = (paymentMethod: PaymentMethod) => {
  const {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    loadingTilled: loadingTilledCard,
    tilledError: tilledErrorCard,
    tilledFormInfo: tilledFormInfoCard,
    onSubmitValidationCheck: onSubmitValidationCheckCard,
  } = useTilledCreditCardForm()

  const {
    accountNumberElement,
    routingNumberElement,
    loadingTilled: loadingTilledAch,
    tilledError: tilledErrorAch,
    tilledFormInfo: tilledFormInfoAch,
    onSubmitValidationCheck: onSubmitValidationCheckAch,
  } = useTilledAchForm()

  const loadingTilled = useMemo(
    () =>
      paymentMethod === PaymentMethod.CARD
        ? loadingTilledCard
        : loadingTilledAch,
    [paymentMethod, loadingTilledCard, loadingTilledAch],
  )

  const tilledError = useMemo(
    () =>
      paymentMethod === PaymentMethod.CARD ? tilledErrorCard : tilledErrorAch,
    [paymentMethod, tilledErrorCard, tilledErrorAch],
  )

  const tilledFormInfo = useMemo(
    () =>
      paymentMethod === PaymentMethod.CARD
        ? tilledFormInfoCard
        : tilledFormInfoAch,
    [paymentMethod, tilledFormInfoCard, tilledFormInfoAch],
  )

  const onSubmitValidationCheck = useCallback(
    () =>
      paymentMethod === PaymentMethod.CARD
        ? onSubmitValidationCheckCard()
        : onSubmitValidationCheckAch(),
    [paymentMethod, onSubmitValidationCheckCard, onSubmitValidationCheckAch],
  )
  return {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    accountNumberElement,
    routingNumberElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  }
}
