import { faImage } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { getConfig } from '../../config'

const { imageResizerBaseUrl } = getConfig()

export type ResizedImageProps = {
  cdnUrl?: string
  width: number
  height: number
  className?: string
  style?: React.CSSProperties
  // Scale the image dimensions fetched. For small images (thumbnails) it might be better to fetch a slightly larger res
  // and let the browser downscale to get a sharper image.
  imageUpscale?: number
  // Margin around the image. This will notably NOT apply to the default image. the idea is if you put this image inside
  // a box with a border, you wouldn't want the image butting up against the edges of the border (you'd want a margin)
  // but the default, which has a gray background, would go up to the edges.
  imageMargin?: number
  loading?: boolean
}

export const ResizedImage = React.memo<ResizedImageProps>(
  ({
    cdnUrl,
    width: outerWidth,
    height: outerHeight,
    className,
    style,
    imageUpscale = 1,
    imageMargin,
    loading = false,
  }) => {
    const width = outerWidth - (imageMargin ?? 0) * 2
    const height = outerHeight - (imageMargin ?? 0) * 2

    const url = useMemo(() => {
      try {
        const url = new URL(cdnUrl ?? '')
        return `${imageResizerBaseUrl}?key=${url.pathname.slice(1)}&width=${
          width * imageUpscale
        }&height=${height * imageUpscale}`
      } catch (e) {
        return undefined
      }
    }, [cdnUrl, height, imageUpscale, width])

    // To avoid a "flashing" loading state, delay rendering it for 300 ms
    const [showLoadingState, setShowLoadingState] = useState(false)

    useEffect(() => {
      setTimeout(() => setShowLoadingState(true), 300)
    }, [])

    return url ? (
      <div
        className={classNames('relative leading-[0]', className)}
        style={{
          ...style,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {!loading && (
          <img src={url} className="relative z-10 bg-white" alt={url} />
        )}
        {showLoadingState && (
          <div
            className="absolute inset-0 flex items-center justify-center"
            style={{
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <Skeleton.Node
              active
              style={{
                width: `${width}px`,
                height: `${height}px`,
              }}
            >
              <FontAwesomeIcon
                className="text-bz-gray-600"
                style={{
                  height: `${Math.min(outerHeight / 2, 90)}px`,
                  width: `${Math.min(outerWidth / 2, 90)}px`,
                }}
                icon={faImage}
              />
            </Skeleton.Node>
          </div>
        )}
      </div>
    ) : (
      <div
        className={classNames(
          'flex items-center justify-center bg-bz-fill-quaternary text-bz-fill',
          className,
        )}
        style={{
          width: `${outerWidth}px`,
          height: `${outerHeight}px`,
          ...style,
        }}
      >
        <FontAwesomeIcon
          icon={faImage}
          height={outerHeight / 2}
          width={outerWidth / 2}
        />
      </div>
    )
  },
)
