import {
  PaymentMethod,
  PaymentMethodCardMetadata,
  ThisShouldNeverHappenError,
} from '@breezy/shared'
import { useCallback, useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { TilledFormInfo } from '../../../hooks/useTilled'
import { useMessage } from '../../../utils/antd-utils'
import { isCreditCardFormData, PaymentFormData } from './useSubmitTilledPayment'

type UseCreatePaymentMethodRecordFromSubscriptionProps<
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
> = {
  accountGuid: string
  tilledFormInfo?: TilledFormInfo
  paymentMethod: PaymentMethodType
  onError: (error: Error) => void
}

export const useCreatePaymentMethodRecordFromSubscription = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>({
  accountGuid,
  tilledFormInfo,
  paymentMethod,
  onError,
}: UseCreatePaymentMethodRecordFromSubscriptionProps<PaymentMethodType>) => {
  const message = useMessage()
  const [isLoading, setIsLoading] = useState(false)

  const createPaymentMethodFromSubscriptionMutation =
    trpc.payments[
      'unauth:payments:payment-method:create-from-payment-subscription'
    ].useMutation()

  const createPaymentMethodRecord = useCallback(
    async (
      formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
      companyGuid: string,
      paymentSubscriptionGuid: string,
      cardMetadata: PaymentMethodCardMetadata | undefined,
    ) => {
      if (!tilledFormInfo) {
        console.error('Tilled form info undefined')
        message.error('Cannot create payment method. Please try again later.')
        return
      }
      const { tilled } = tilledFormInfo
      if (!tilled) {
        console.error('Tilled client undefined')
        message.error('Cannot create payment method. Please try again later.')
        return
      }

      setIsLoading(true)

      try {
        const name = isCreditCardFormData(formData)
          ? formData.name
          : formData.accountHolderName
        if (
          !name ||
          !formData.streetAddress ||
          !formData.city ||
          !formData.state ||
          !formData.zipCode
        ) {
          throw new ThisShouldNeverHappenError(
            'Missing billing info during payment method creation',
          )
        }

        await createPaymentMethodFromSubscriptionMutation.mutateAsync({
          accountGuid,
          companyGuid,
          paymentSubscriptionGuid,
          paymentMethodType: paymentMethod,
          billingInfo: {
            name,
            streetAddress: formData.streetAddress,
            streetAddress2: formData.streetAddress2,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
          },
          cardMetadata,
        })
      } catch (err) {
        console.error(err)
        onError(err as Error)
      } finally {
        setIsLoading(false)
      }
    },
    [
      accountGuid,
      createPaymentMethodFromSubscriptionMutation,
      message,
      onError,
      paymentMethod,
      tilledFormInfo,
    ],
  )

  return { createPaymentMethodRecord, isLoading }
}
