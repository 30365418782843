import { ComponentProps } from 'react'
import SidebarItem from './SidebarItem'

export type SidebarContactProps = Omit<
  ComponentProps<typeof SidebarItem>,
  'contentList' | 'sectionLabel'
> & {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
}

export const SidebarContact = ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
  ...sidebarProps
}: SidebarContactProps) => {
  return (
    <SidebarItem
      {...sidebarProps}
      sectionLabel="Point of Contact"
      contentList={[
        {
          key: 'Name',
          value: `${firstName} ${lastName}`,
        },
        {
          key: 'Email',
          value: emailAddress,
        },
        {
          key: 'Phone',
          value: phoneNumber,
        },
      ]}
    />
  )
}
