import { JobAvailabilitiesView } from './JobLeadAvailabilityView'
import { NotCapturedView } from './NotCapturedView'
import { JobLead } from './types'
import { jobLeadsHeader } from './utils'

type JobLeadContextViewProps = {
  jobLead: JobLead
}

export const JobLeadContextView = ({ jobLead }: JobLeadContextViewProps) => {
  return (
    <div className="flex overflow-hidden">
      <div className="border-1 w-full rounded-lg border-solid border-bz-gray-500 bg-bz-gray-200 p-3 text-base">
        <div className="text-md w-full font-semibold">
          {jobLeadsHeader(jobLead)}
        </div>
        <div>
          <div className="mt-2 text-sm font-semibold">Summary</div>
          <div className="mt-1 text-sm">
            {jobLead.jobSummary ?? <NotCapturedView />}
          </div>
        </div>
        <div className="mt-1 flex min-w-0 flex-row flex-wrap *:min-w-[150px] *:flex-1">
          <div>
            <div className="mt-2 text-sm font-semibold">Availability</div>
            <div className="mt-1 text-sm">
              <JobAvailabilitiesView jobLead={jobLead} withGap />
            </div>
          </div>
          <div>
            <div className="mt-2 text-sm font-semibold">Address</div>
            <div className="mt-1 text-sm">
              {!jobLead.serviceAddressLine1 &&
              !jobLead.serviceAddressCity &&
              !jobLead.serviceAddressZipCode ? (
                <NotCapturedView />
              ) : (
                <div className="flex flex-col space-y-1">
                  {jobLead.serviceAddressLine1 && (
                    <div>
                      {jobLead.serviceAddressLine1}
                      {jobLead.serviceAddressLine2
                        ? ` ${jobLead.serviceAddressLine2}`
                        : ''}
                    </div>
                  )}
                  {(jobLead.serviceAddressCity ||
                    jobLead.serviceAddressZipCode) && (
                    <div>
                      {[
                        jobLead.serviceAddressCity,
                        jobLead.serviceAddressStateAbbreviation,
                        jobLead.serviceAddressZipCode,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-1">
          <div className="mt-2 text-sm font-semibold">Contact</div>
          <div className="mt-1 break-words text-sm">
            {!jobLead.contactEmailAddress && !jobLead.contactPhoneNumber && (
              <NotCapturedView />
            )}
            <div className="flex flex-col space-y-1">
              {jobLead.contactEmailAddress && (
                <div className="break-words">{jobLead.contactPhoneNumber}</div>
              )}
              {jobLead.contactEmailAddress && (
                <div className="break-words">{jobLead.contactEmailAddress}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
