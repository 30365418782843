import { phoneUtils } from '@breezy/shared'
import { Form } from 'antd'
import { FormInstance, useForm, useWatch } from 'antd/lib/form/Form'
import { ContactRootFormSchema } from '../../utils/ContactFormTypes'
import { PhoneFormItem } from '../Phones/PhoneFormItem/PhoneFormItem'
import { EmailAddressFormItem } from '../form-fields/EmailAddressFormItem/EmailAddressFormItem'
import { FirstNameField } from '../form-fields/FirstNameField/FirstNameField'
import { LastNameField } from '../form-fields/LastNameField/LastNameField'
import { NotificationPreferenceTypeFormItem } from '../form-fields/NotificationPreferenceSelectorItem/NotificationPreferenceSelectorItem'

export type EmbeddedContactInformationFormProps = {
  extendedForm?: FormInstance<
    Record<string, unknown> & EmbeddedContactInformationFormSchema
  >
}

export type EmbeddedContactInformationFormSchema = ContactRootFormSchema

export const EmbeddedContactInformationForm = ({
  extendedForm,
}: EmbeddedContactInformationFormProps) => {
  const [contactInformationForm] =
    useForm<EmbeddedContactInformationFormSchema>()

  const form = extendedForm ? extendedForm : contactInformationForm

  const phoneNumberValue = useWatch('phoneNumber', form)
  const emailAddressValue = useWatch('emailAddress', form)
  const notificationPreferenceValue = useWatch('notificationPreference', form)

  return (
    <Form
      form={form}
      layout="vertical"
      validateTrigger="onBlur"
      autoComplete="on"
      initialValues={{
        phoneNumber: phoneNumberValue
          ? phoneUtils.tryFormat(phoneNumberValue)
          : undefined,
      }}
    >
      <div className="flex space-x-4">
        <div className="w-full">
          <FirstNameField labelClassName="semibold_14_22 grey9" />
        </div>
        <div className="w-full">
          <LastNameField labelClassName="semibold_14_22 grey9" />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full">
          <PhoneFormItem
            required={!emailAddressValue}
            enabledSmsCapableControl={!!phoneNumberValue}
            requiredMessage="Phone Number is required if email address not set"
            onChangeType={phoneNumberType => {
              form.setFieldsValue({
                phoneNumberType,
              })
            }}
            onBlur={e => {
              form.setFieldsValue({
                phoneNumber: phoneUtils.tryFormat(e.target.value),
              })
            }}
            labelClassName="semibold_14_22 grey9"
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full">
          <EmailAddressFormItem
            labelClassName="semibold_14_22 grey9"
            required={!phoneNumberValue}
            requiredMessage="Email is required if phone number not set"
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full">
          <NotificationPreferenceTypeFormItem
            labelClassName="semibold_14_22 grey9"
            value={notificationPreferenceValue}
            onChange={value => {
              form.setFieldsValue({ notificationPreference: value })
            }}
          />
        </div>
      </div>
    </Form>
  )
}
