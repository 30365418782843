import { UseQueryResult } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'

type useRequeryProps<T> = {
  query: UseQueryResult<T>
  version: number
}

export const useRequery = <T>({ query, version }: useRequeryProps<T>) => {
  const refetchRef = useRef(query.refetch)
  refetchRef.current = query.refetch
  useEffect(() => {
    refetchRef.current()
  }, [version])
}
