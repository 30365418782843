import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../../../elements/Forms/TextField'

const SendEmailFormSchema = z.object({
  to: z.string().email(),
})

type SendEmailFormData = z.infer<typeof SendEmailFormSchema>

type SendEmailFormProps = {
  isLoading: boolean
  onSubmit: (data: SendEmailFormData) => void
  onCancel: () => void
}

export const SendEmailForm = React.memo<SendEmailFormProps>(
  ({ isLoading, onSubmit, onCancel }) => {
    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm<SendEmailFormData>({
      resolver: zodResolver(SendEmailFormSchema),
    })

    return (
      <Form
        className="mt-3 flex w-full flex-col"
        layout="vertical"
        onSubmitCapture={handleSubmit(onSubmit)}
      >
        <ReactHookFormItem
          control={control}
          name="to"
          label="Email Address"
          required
          errors={errors}
          render={({ field }) => (
            <TextField
              {...field}
              ddActionName="BZ Payment Workflow - Send Another Copy - Email Address"
            />
          )}
        />
        <div className="mt-2 flex w-full flex-row gap-3 pb-6">
          <Button
            className="flex-1 font-semibold"
            size="large"
            data-dd-action-name="BZ Payment Workflow - Send Another Copy - Cancel"
            disabled={isLoading}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="flex-1 font-semibold"
            type="primary"
            size="large"
            htmlType="submit"
            data-dd-action-name="BZ Payment Workflow - Send Another Copy - Send"
            loading={isLoading}
            disabled={isLoading}
          >
            Send
          </Button>
        </div>
      </Form>
    )
  },
)
