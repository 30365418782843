import { PageHeader } from '@ant-design/pro-components'
import { faGear } from '@fortawesome/pro-light-svg-icons'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { SettingsPage } from '../../elements/SettingsPage/SettingsPage'
import { MapAppPreferencePicker } from './MapAppPreferencePicker'

export const UserSettingsView = () => {
  return (
    <>
      <MapAppPreferencePicker />
    </>
  )
}

export const UserSettingsPage = () => {
  return (
    <SettingsPage requiresCompanyUser>
      <div className="w-1/2 content-center">
        <PageHeader title={<PageTitle title="User Settings" icon={faGear} />} />
      </div>

      <ScrollCard
        className="mt-auto flex h-full w-full flex-col gap-3"
        hasPageHeading
      >
        <UserSettingsView />
      </ScrollCard>
    </SettingsPage>
  )
}
