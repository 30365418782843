import {
  Account,
  CreditRecord,
  ESTIMATED_EQUIPMENT_AGE_OPTIONS,
  ESTIMATED_EQUIPMENT_AGE_TO_MAX_AGE_MAP,
  JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS,
  JobClass,
  isCreditActive,
  isNullish,
} from '@breezy/shared'
import {
  IconDefinition,
  faCheck,
  faInfoCircle,
  faX,
} from '@fortawesome/pro-light-svg-icons'
import {
  faFire,
  faSackDollar,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Form, FormInstance, Switch, Tooltip } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { CSSProperties, memo, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import ThinDivider from '../../elements/ThinDivider'
import { useCanManageTechnicianPerformance } from '../../hooks/permission/useCanManageTechnicianPerformance'
import { useCanViewTechnicianPerformance } from '../../hooks/permission/useCanViewTechnicianPerformance'
import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import useIsMobile from '../../hooks/useIsMobile'
import { usePrevious } from '../../hooks/usePrevious'
import {
  FIND_ACTIVE_MAINTENANCE_PLAN_AT_ACCOUNT_LOCATION,
  FIND_JOB_TYPE_REVENUE_POTENTIAL,
} from './CreateOrEditJobForm.gql'
import { CreateOrEditNewJobFormSchema } from './CreateOrEditNewJobFormSchema'

type JobRevenuePotentialFormProps = {
  account?: Account
  form: FormInstance<CreateOrEditNewJobFormSchema>
  formMode: 'edit' | 'create'
  initialValues?: Pick<
    CreateOrEditNewJobFormSchema,
    | 'isOpportunity'
    | 'isHotLead'
    | 'isMembershipOpportunity'
    | 'isMembershipRenewalOpportunity'
  >
  jobType: {
    jobTypeGuid: string
    name: string
    jobClass: JobClass
  }
  /**
   * This is a very hacky workaround to force the component to re-render. We need
   * this component to run logic whenever an equipment-age field changes. However,
   * the equipment-age is a dynamic multi-select as the user can select any number
   * of equipment types, so we can't just watch a single field or a known set of fields.
   * The easiest way to handle this is to hook into the `onValuesChange` prop in antd's Form component.
   * However, we can't easily do that here since the Form component is rendered in a parent.
   * So instead, we use this hacky workaround where we have the parent component set this
   * prop whenever it detects a change in an equipment-age field,
   * thus forcing a re-render and causing our desired logic to run.
   */
  jobEquipmentAgeChanged: number
  jobLocationGuid?: string
}

/**
 * `JobRevenuePotentialForm` is a sub-form for configuring various job properties that
 * describe the job's potential to to generate revenue. These properties are used for
 * reporting performance metrics (e.g. Technician Performance). Currently, these properties include:
 *
 * * Opportunity
 * * Hot Lead
 * * Membership Opportunity
 *
 * @component
 * @param {Object} props - The properties that define the component's behavior and display.
 * @param {Account} [props.account] - The account associated with the job.
 * @param {FormInstance<CreateOrEditNewJobFormSchema>} props.form - The antd form instance for managing form state.
 * @param {'edit' | 'create'} props.formMode - Indicates whether the job is being created or edited.
 * @param {Object} props.jobType - The type of the job. A subset of {@code JobType} properties
 * @param {number} props.jobEquipmentAgeChanged - A number that changes whenever an equipment-age field changes, triggering a re-render.
 * @param {string} [props.jobLocationGuid] - The unique identifier for the job location.
 * @param {Object} props.initialValues - Initial values for the properties. Only meant to be used when editing an existing job.
 * @returns {JSX.Element} The rendered `JobRevenuePotentialForm` component.
 */
export const JobRevenuePotentialForm = memo<JobRevenuePotentialFormProps>(
  ({
    account,
    form,
    formMode,
    initialValues,
    jobType,
    jobEquipmentAgeChanged,
    jobLocationGuid,
  }) => {
    const previousJobEquipmentAgeChanged = usePrevious(jobEquipmentAgeChanged)
    const canViewTechnicianPerformance = useCanViewTechnicianPerformance()
    const [isHotLeadByDefault, setIsHotLeadByDefault] = useState(false)
    const isOpportunityValue = useWatch('isOpportunity', form)
    const preexistingEquipmentInfoValues = useWatch(
      'preexistingEquipmentInfo',
      form,
    )
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

    const [
      {
        data: activeMaintenancePlansData,
        fetching: fetchingActiveMaintenancePlansData,
      },
    ] = useQuery({
      query: FIND_ACTIVE_MAINTENANCE_PLAN_AT_ACCOUNT_LOCATION,
      variables: {
        companyGuid: account?.companyGuid || '',
        accountGuid: account?.accountGuid || '',
        locationGuid: jobLocationGuid || '',
      },
      pause: isNullish(account) || isNullish(jobLocationGuid),
    })

    const jobLocationActiveMaintenancePlan = useMemo(() => {
      if (
        !fetchingActiveMaintenancePlansData &&
        activeMaintenancePlansData?.maintenancePlansQueryable &&
        activeMaintenancePlansData.maintenancePlansQueryable.length > 0
      ) {
        return activeMaintenancePlansData.maintenancePlansQueryable[0]
      }

      return null
    }, [
      activeMaintenancePlansData?.maintenancePlansQueryable,
      fetchingActiveMaintenancePlansData,
    ])

    const shouldShowOpportunityAndHotLeadFields = useMemo(() => {
      return JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS.includes(
        jobType.jobClass,
      )
    }, [jobType.jobClass])

    // Number of available credits for the job location's maintenance plan.
    // If the location does not have an active maintenance plan, this will be 0
    const activeMaintenancePlanAvailableCredits = useMemo(() => {
      if (jobLocationActiveMaintenancePlan) {
        return (
          jobLocationActiveMaintenancePlan.credits as CreditRecord[]
        ).filter(c => isCreditActive(c)).length
      } else {
        return 0
      }
    }, [jobLocationActiveMaintenancePlan])

    // Membership Opportunity field should be shown if and only if the job location does
    // NOT have an active maintenance plan
    const shouldShowMembershipOpportunityField = useMemo(() => {
      if (!isCompanyMaintenancePlansEnabled) {
        return false
      } else {
        return isNullish(jobLocationActiveMaintenancePlan)
      }
    }, [isCompanyMaintenancePlansEnabled, jobLocationActiveMaintenancePlan])

    const jobShouldBeMembershipOpportunityByDefault = useMemo(() => {
      if (formMode === 'edit') {
        return initialValues?.isMembershipOpportunity ?? false
      }

      return shouldShowMembershipOpportunityField
    }, [
      formMode,
      initialValues?.isMembershipOpportunity,
      shouldShowMembershipOpportunityField,
    ])

    useEffect(() => {
      if (shouldShowMembershipOpportunityField) {
        form.setFieldValue(
          'isMembershipOpportunity',
          jobShouldBeMembershipOpportunityByDefault,
        )
      } else {
        form.setFieldValue('isMembershipOpportunity', false)
      }
    }, [
      form,
      jobShouldBeMembershipOpportunityByDefault,
      shouldShowMembershipOpportunityField,
    ])

    const shouldShowMembershipRenewalOpportunityField = useMemo(() => {
      if (shouldShowMembershipOpportunityField) {
        return false
      } else if (!isCompanyMaintenancePlansEnabled) {
        return false
      } else {
        // Display Membership Renewal Opportunity field if and only if the Job has an Active
        // Maintenance Plan that is manually-renewing with 0 or 1 credits remaining
        if (jobLocationActiveMaintenancePlan) {
          return (
            !isNullish(jobLocationActiveMaintenancePlan.terminatesAt) &&
            (activeMaintenancePlanAvailableCredits === 0 ||
              activeMaintenancePlanAvailableCredits === 1)
          )
        } else {
          // We should never reach this branch, but just in case
          return false
        }
      }
    }, [
      activeMaintenancePlanAvailableCredits,
      isCompanyMaintenancePlansEnabled,
      jobLocationActiveMaintenancePlan,
      shouldShowMembershipOpportunityField,
    ])

    const jobShouldBeMembershipRenewalOpportunityByDefault = useMemo(() => {
      if (formMode === 'edit') {
        return initialValues?.isMembershipRenewalOpportunity ?? false
      }

      return shouldShowMembershipRenewalOpportunityField
    }, [
      formMode,
      initialValues?.isMembershipRenewalOpportunity,
      shouldShowMembershipRenewalOpportunityField,
    ])

    useEffect(() => {
      if (shouldShowMembershipRenewalOpportunityField) {
        form.setFieldValue(
          'isMembershipRenewalOpportunity',
          jobShouldBeMembershipRenewalOpportunityByDefault,
        )
      } else {
        form.setFieldValue('isMembershipRenewalOpportunity', false)
      }
    }, [
      form,
      jobShouldBeMembershipOpportunityByDefault,
      jobShouldBeMembershipRenewalOpportunityByDefault,
      shouldShowMembershipOpportunityField,
      shouldShowMembershipRenewalOpportunityField,
    ])

    const findJobTypeRevenuePotentialQuery = useQuery({
      query: FIND_JOB_TYPE_REVENUE_POTENTIAL,
      variables: {
        jobTypeGuid: jobType.jobTypeGuid,
      },
    })

    const [{ data: jobTypeData, fetching: fetchingJobTypeData }] =
      findJobTypeRevenuePotentialQuery

    // Whenever a equipment-age form field changes, determine if we should update
    // the isHotLead field based on the job type's hotLeadMinimumEquipmentAgeYears
    useEffect(() => {
      if (!shouldShowOpportunityAndHotLeadFields) {
        return
      }

      if (jobEquipmentAgeChanged === previousJobEquipmentAgeChanged) {
        return
      }

      if (
        jobTypeData &&
        !fetchingJobTypeData &&
        jobTypeData.jobTypesByPk?.isOpportunity
      ) {
        const preexistingEquipmentInfoFieldValues = (
          preexistingEquipmentInfoValues ?? []
        )
          .map(eqType => `equipment-age-${eqType}`)
          .map(
            fieldName =>
              form.getFieldValue(
                fieldName,
              ) as (typeof ESTIMATED_EQUIPMENT_AGE_OPTIONS)[number],
          )

        const isPotentialHotLead = jobTypeData.jobTypesByPk?.isPotentialHotLead
        const minimumEquipmentAgeRequiredForHotLead =
          jobTypeData.jobTypesByPk?.hotLeadMinimumEquipmentAgeYears

        if (
          isPotentialHotLead &&
          !isNullish(minimumEquipmentAgeRequiredForHotLead)
        ) {
          for (const fieldValue of preexistingEquipmentInfoFieldValues) {
            const estimatedEquipmentAge =
              ESTIMATED_EQUIPMENT_AGE_TO_MAX_AGE_MAP[fieldValue] ?? -1

            if (
              estimatedEquipmentAge >= minimumEquipmentAgeRequiredForHotLead
            ) {
              setIsHotLeadByDefault(true)
              form.setFieldsValue({
                isHotLead: true,
              })
              return
            }
          }
        }

        setIsHotLeadByDefault(false)
        form.setFieldsValue({
          isHotLead: false,
        })
      }
    }, [
      jobEquipmentAgeChanged,
      fetchingJobTypeData,
      form,
      jobTypeData,
      preexistingEquipmentInfoValues,
      formMode,
      initialValues?.isHotLead,
      previousJobEquipmentAgeChanged,
      shouldShowOpportunityAndHotLeadFields,
    ])

    useEffect(() => {
      if (formMode === 'edit') {
        form.setFieldValue('isHotLead', initialValues?.isHotLead ?? false)
      }
    }, [form, formMode, initialValues?.isHotLead])

    // Determine whether the job is an opportunity based on its job type
    useEffect(() => {
      if (!shouldShowOpportunityAndHotLeadFields) {
        form.setFieldsValue({
          isOpportunity: false,
          isHotLead: false,
        })
        return
      }

      if (jobTypeData && !fetchingJobTypeData) {
        if (jobTypeData.jobTypesByPk?.isOpportunity) {
          form.setFieldsValue({
            isOpportunity: true,
          })
        } else {
          form.setFieldsValue({
            isOpportunity: false,
            isHotLead: false,
          })
        }
      }
    }, [
      jobTypeData,
      jobType.jobTypeGuid,
      fetchingJobTypeData,
      form,
      preexistingEquipmentInfoValues,
      shouldShowOpportunityAndHotLeadFields,
    ])

    const opportunityTooltipText = useMemo(() => {
      if (isOpportunityValue) {
        return (
          `Opportunities represent potential revenue generation. This job has been designated an Opportunity because it has a ${jobType.name} job type. ` +
          `This behavior can be configured by an admin in the "Company Settings - Job Types" page.`
        )
      } else {
        return (
          `Opportunities represent potential revenue generation. This job has not been designated an Opportunity because it has a ${jobType.name} job type. ` +
          `This behavior can be configured by an admin in the "Company Settings - Job Types" page.`
        )
      }
    }, [isOpportunityValue, jobType.name])

    const hotLeadTooltipText = useMemo(() => {
      if (isNullish(jobTypeData?.jobTypesByPk)) {
        return undefined
      }

      if (!jobTypeData?.jobTypesByPk?.isPotentialHotLead) {
        return (
          `Hot Leads are jobs with high potential for equipment replacements or other lucrative opportunities. ` +
          `This job has not been automatically marked as a Hot Lead because it has a ${jobType.name} job type. This behavior ` +
          `can be configured by an admin in the "Company Settings - Job Types" page.`
        )
      }

      if (isHotLeadByDefault) {
        return (
          `Hot Leads are jobs with high potential for equipment replacements or other lucrative opportunities. ` +
          `This ${jobType.name} job has been automatically marked as a Hot Lead because it services equipment older than ${jobTypeData?.jobTypesByPk?.hotLeadMinimumEquipmentAgeYears} years. This behavior ` +
          `can be configured by an admin in the "Company Settings - Job Types" page.`
        )
      } else {
        return (
          `Hot Leads are jobs with high potential for equipment replacements or other lucrative opportunities. ` +
          `This ${jobType.name} job has not been automatically marked as a Hot Lead because it services equipment younger than ${jobTypeData?.jobTypesByPk?.hotLeadMinimumEquipmentAgeYears} years. This behavior ` +
          `can be configured by an admin in the "Company Settings - Job Types" page.`
        )
      }
    }, [isHotLeadByDefault, jobType.name, jobTypeData?.jobTypesByPk])

    const membershipOpportunityTooltipText = useMemo(() => {
      if (jobShouldBeMembershipOpportunityByDefault) {
        return (
          `A Membership Opportunity represents a chance to sell a Maintenance Plan membership. This job has been automatically marked ` +
          `as a Membership Opportunity because the location does NOT have an active Maintenance Plan.`
        )
      } else {
        return `A Membership Opportunity represents a chance to sell a Maintenance Plan membership. This location already has an active Maintenance Plan.`
      }
    }, [jobShouldBeMembershipOpportunityByDefault])

    const membershipRenewalOpportunityTooltipText = useMemo(() => {
      if (jobShouldBeMembershipRenewalOpportunityByDefault) {
        return (
          `A Membership Renewal Opportunity represents a chance to renew an active Maintenance Plan. This job has been automatically marked ` +
          `as a Membership Renewal Opportunity because location has a manually-renewing Maintenance Plan with 1 or less credits remaining.`
        )
      } else {
        return `A Membership Renewal Opportunity represents a chance to renew an active Maintenance Plan.`
      }
    }, [jobShouldBeMembershipRenewalOpportunityByDefault])

    // Only show the divider if there are actually fields to show
    const showEndDivider = useMemo(
      () =>
        shouldShowOpportunityAndHotLeadFields ||
        shouldShowMembershipOpportunityField ||
        shouldShowMembershipRenewalOpportunityField,
      [
        shouldShowMembershipOpportunityField,
        shouldShowMembershipRenewalOpportunityField,
        shouldShowOpportunityAndHotLeadFields,
      ],
    )

    const isMobile = useIsMobile()

    if (!canViewTechnicianPerformance) {
      return null
    }

    return (
      <>
        <div style={{ padding: 0, boxShadow: 'none' }} className="mb-4">
          {shouldShowOpportunityAndHotLeadFields && (
            <>
              {/* Opportunity is based on the job type and cannot be overridden */}
              <JobRevenuePotentialFormItem
                disabled={true}
                formItemName="isOpportunity"
                icon={faSackDollar}
                text={isMobile ? 'Opportunity' : 'Flag as an Opportunity'}
                tooltip={opportunityTooltipText}
                extraStyle={{
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                }}
              />

              <JobRevenuePotentialFormItem
                // A Job cannot be a Hot Lead if its not an Opportunity
                disabled={!isOpportunityValue}
                formItemName="isHotLead"
                icon={faFire}
                text={isMobile ? 'Hot Lead' : 'Flag as a Hot Lead'}
                tooltip={hotLeadTooltipText}
              />
            </>
          )}

          {shouldShowMembershipOpportunityField && (
            <JobRevenuePotentialFormItem
              icon={faScrewdriverWrench}
              formItemName="isMembershipOpportunity"
              text={
                isMobile
                  ? 'Membership Opportunity'
                  : 'Flag as a Membership Opportunity'
              }
              tooltip={membershipOpportunityTooltipText}
              extraStyle={{
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
            />
          )}

          {shouldShowMembershipRenewalOpportunityField && (
            <JobRevenuePotentialFormItem
              icon={faScrewdriverWrench}
              formItemName="isMembershipRenewalOpportunity"
              text={
                isMobile
                  ? 'Membership Renewal Opportunity'
                  : 'Flag as a Membership Renewal Opportunity'
              }
              tooltip={membershipRenewalOpportunityTooltipText}
              extraStyle={{
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
            />
          )}
        </div>
        {showEndDivider && <ThinDivider />}
      </>
    )
  },
)

type JobRevenuePotentialFormItemProps = {
  disabled?: boolean
  formItemName: string
  icon: IconDefinition
  text: string
  tooltip?: string
  extraStyle?: CSSProperties
}

const JobRevenuePotentialFormItem = memo<JobRevenuePotentialFormItemProps>(
  ({ disabled, formItemName, icon, text, tooltip, extraStyle }) => {
    const canManageTechnicianPerformance = useCanManageTechnicianPerformance()

    return (
      <div
        className="pr-4 md:pr-0"
        style={{
          // Workaround to get a border thinner than 1px, which isn't normally possible
          // when using the border property in CSS
          boxShadow: 'inset 0 0 0 0.6px rgb(217, 217, 217)',
          ...extraStyle,
        }}
      >
        <div
          style={{
            height: '44px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0',
            padding: '0',
          }}
        >
          <Col span={21}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 0 0 16px',
              }}
            >
              <FaIconWithCircularBackground
                iconDefinition={icon}
                color="#AD4E00"
                backgroundColor="#FFE7BA"
                diameterPx={28}
              />
              {text}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-2 h-4 w-4 text-bz-gray-700"
                  />
                </Tooltip>
              )}
            </div>
          </Col>
          <Col span={3}>
            <Form.Item
              name={formItemName}
              valuePropName="checked"
              style={{ marginTop: '12px', marginBottom: '12px' }}
            >
              <Switch
                disabled={!!disabled || !canManageTechnicianPerformance}
                checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                unCheckedChildren={<FontAwesomeIcon icon={faX} />}
              />
            </Form.Item>
          </Col>
        </div>
      </div>
    )
  },
)
