import {
  AccountGuid,
  PaymentMethod,
  TilledPaymentErrorDetail,
  getTilledUserFriendlyErrorMessage,
} from '@breezy/shared'
import React, { useCallback, useEffect } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../../adam-components/OnsiteModal/OnsiteModal'
import { useExpectedTilledMerchantId } from '../../../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyGuid } from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import { useCreateTilledPaymentMethod } from '../../hooks/useCreateTilledPaymentMethod'
import { PaymentFormData } from '../../hooks/useSubmitTilledPayment'
import { useTilledPaymentForm } from '../../hooks/useTilledPaymentForm'
import { CreditCardPaymentForm } from '../CreditCardPaymentForm/CreditCardPaymentForm'
type AddPaymentMethodModalProps = {
  accountGuid: AccountGuid
  onClose: () => void
  refetch: () => void
}

const AddPaymentMethodModal = React.memo<AddPaymentMethodModalProps>(
  ({ accountGuid, onClose, refetch }) => {
    const companyGuid = useExpectedCompanyGuid()
    const tilledMerchantId = useExpectedTilledMerchantId()
    const message = useMessage()
    const {
      cardNumberElement,
      cardExpirationElement,
      cardCvvElement,
      loadingTilled,
      tilledError,
      tilledFormInfo,
      onSubmitValidationCheck,
    } = useTilledPaymentForm(PaymentMethod.CARD)
    const onError = useCallback(
      (err: Error | TilledPaymentErrorDetail) => {
        console.error(err)
        message.error(
          getTilledUserFriendlyErrorMessage((err as Error)?.message),
        )
        refetch()
      },
      [message, refetch],
    )

    const { createPaymentMethod, isLoading: isCreatingPaymentMethod } =
      useCreateTilledPaymentMethod({
        accountGuid,
        tilledFormInfo,
        paymentMethod: PaymentMethod.CARD,
        onError,
      })

    const isLoading = loadingTilled || isCreatingPaymentMethod

    useEffect(() => {
      if (!loadingTilled && tilledError) {
        console.error(tilledError)
        message.error('Something went wrong. Try again later.')
      }
    }, [loadingTilled, message, tilledError])

    const onSubmit = useCallback(
      async (
        formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
        companyGuid: string,
        tilledMerchantId: string,
      ) => {
        await createPaymentMethod(formData, companyGuid, tilledMerchantId)
        refetch()
        onClose()
      },
      [createPaymentMethod, onClose, refetch],
    )
    return (
      <OnsiteModal drawer onClose={onClose} size="large">
        <OnsiteModalContent
          onClose={onClose}
          disabled={isLoading}
          header="Add Payment Method"
        >
          <CreditCardPaymentForm
            isLoading={isLoading}
            onCancel={onClose}
            onSubmitValidationCheck={onSubmitValidationCheck}
            onSubmit={data =>
              onSubmit(
                {
                  ...data,
                },
                companyGuid,
                tilledMerchantId,
              )
            }
            withAddressTypeAhead
            primaryButtonText="Save"
            formStyle="modal"
          >
            <div>
              {cardNumberElement}
              <div className="flex flex-row space-x-3">
                {cardExpirationElement}
                {cardCvvElement}
              </div>
            </div>
          </CreditCardPaymentForm>
        </OnsiteModalContent>
      </OnsiteModal>
    )
  },
)

export default AddPaymentMethodModal
