import { Address, BzAddress } from '@breezy/shared'
import { ComponentProps } from 'react'
import AddressMultiLineView from '../../Addresses/AddressMultiLineView/AddressMultiLineView'
import SidebarItem from './SidebarItem'

export type SidebarAddressProps = Omit<
  ComponentProps<typeof SidebarItem>,
  'contentList' | 'sectionLabel'
> & {
  addressLabel: string
  address: Address
}

export const SidebarAddress = ({
  address,
  addressLabel,
  ...sidebarLocationProps
}: SidebarAddressProps) => {
  return (
    <SidebarItem
      {...sidebarLocationProps}
      sectionLabel={addressLabel}
      contentList={[
        {
          key: 'Address',
          value: <AddressMultiLineView address={BzAddress.create(address)} />,
        },
      ]}
    />
  )
}
