import { Account, debounce } from '@breezy/shared'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoComplete, Input } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { BzMobileSingleSelect } from '../../../elements/BzMobileSelect/BzMobileSelect'
import { AccountDropdownOptionsQueryQuery } from '../../../generated/user/graphql'
import { trpc } from '../../../hooks/trpc'
import { useIsMobileOrTouchOrTechApp } from '../../../hooks/useIsMobile'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { ACCOUNT_DROPDOWN_OPTIONS_QUERY } from './AccountSearchAutocomplete.gql'

const makeAccountLabel = (
  fullName: string | undefined,
  phoneNumber: string | undefined,
  emailAddress: string | undefined,
  maintenancePlanName: string | undefined,
  archived: boolean | undefined,
) => {
  const label = [fullName, phoneNumber, emailAddress, maintenancePlanName]
    .filter(Boolean)
    .join(' - ')
  if (archived) {
    return `(Archived) ${label}`
  }
  return label
}

type AccountSearchAutocompleteProps = {
  currentAccount: Account | undefined
  setCurrentAccount: (account: Account) => void
  showLabel?: boolean
}

const AccountSearchAutocomplete = React.memo<AccountSearchAutocompleteProps>(
  ({ currentAccount, setCurrentAccount, showLabel = true }) => {
    const companyGuid = useExpectedCompanyGuid()
    const [accountSearchText, setAccountSearchTextRaw] = useState('')
    const [debouncedAccountSearchText, setDebouncedAccountSearchText] =
      useState('')

    const debouncedSetAccountSearchText = useMemo(
      () =>
        debounce((text: string) => setDebouncedAccountSearchText(text), 300, {
          leading: true,
        }),
      [],
    )

    const [autcompleteErrored, setAutocompleteErrored] = useState(false)

    const _ilike = `%${debouncedAccountSearchText}%`

    const [{ data: accountsData, fetching: fetchingAccounts }] = useQuery({
      query: ACCOUNT_DROPDOWN_OPTIONS_QUERY,
      pause: !debouncedAccountSearchText,
      variables: {
        where: {
          _or: [
            {
              accountDisplayName: {
                _ilike,
              },
            },
            {
              accountContacts: {
                contact: {
                  fullName: {
                    _ilike,
                  },
                },
              },
            },
            {
              accountContacts: {
                contact: {
                  primaryPhoneNumber: {
                    phoneNumber: {
                      _ilike,
                    },
                  },
                },
              },
            },
            {
              accountContacts: {
                contact: {
                  primaryEmailAddress: {
                    emailAddress: {
                      _ilike,
                    },
                  },
                },
              },
              tags: {
                tag: {
                  name: {
                    _ilike,
                  },
                },
              },
            },
            {
              tags: {
                tag: {
                  name: {
                    _ilike,
                  },
                },
              },
            },
            {
              maintenancePlans: {
                maintenancePlanDefinition: {
                  marketingInfo: {
                    name: {
                      _ilike,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    })

    // Very annoying quirk. When we search, the query will rerun and give us `undefined` as the value, making the list
    // we show empty while they type. So when they type we set this and when we're done loading we unset this. It
    // defaults to this if set and falls back to the real value otherwise.
    const [previousAccountsData, setPreviousAccountsData] =
      useState<AccountDropdownOptionsQueryQuery>()

    const setAccountSearchText = useCallback(
      (text: string) => {
        setPreviousAccountsData(accountsData)
        setAccountSearchTextRaw(text)
        debouncedSetAccountSearchText(text)
      },
      [accountsData, debouncedSetAccountSearchText],
    )

    useEffect(() => {
      if (!fetchingAccounts) {
        setPreviousAccountsData(undefined)
      }
    }, [fetchingAccounts])

    const accountOptions = useMemo(
      () =>
        accountSearchText
          ? (previousAccountsData ?? accountsData)?.accounts.map(
              ({
                accountGuid,
                accountContacts,
                maintenancePlans,
                archived,
              }) => {
                const primaryContact = accountContacts[0]?.contact
                if (!primaryContact) {
                  return { value: accountGuid, label: 'Unknown' }
                }
                return {
                  value: accountGuid,
                  label: makeAccountLabel(
                    primaryContact.fullName,
                    primaryContact.primaryPhoneNumber?.phoneNumber,
                    primaryContact.primaryEmailAddress?.emailAddress,
                    maintenancePlans[0]?.maintenancePlanDefinition
                      ?.marketingInfo?.name,
                    archived,
                  ),
                }
              },
            ) ?? []
          : [],
      [accountSearchText, accountsData, previousAccountsData],
    )

    const [selectedAccountGuid, setSelectedAccountGuid] = useState<string>()

    const fetchAccountQuery = trpc.accounts['accounts:query'].useQuery(
      {
        type: 'by-account-guid',
        companyGuid,
        accountGuid: selectedAccountGuid ?? '',
      },
      {
        enabled: !!selectedAccountGuid,
        retry: false,
      },
    )

    useEffect(() => {
      if (fetchAccountQuery.data?.accounts[0]) {
        setCurrentAccount(fetchAccountQuery.data?.accounts[0])
      }
    }, [fetchAccountQuery.data, setCurrentAccount])

    // We don't want to show a loading spinner on every load. The query is lightning fast after you have a few letters
    // in; it was not noticeably slow while on "Slow 3G" throttling. If we show the loading spinner on load it will show
    // after every key stroke for a split second, which sucks. It will also always show at the very end because of the
    // debounce. So we don't show it based on the query fetching. However, if you are on Slow 3G and type a single
    // letter, it does take some time to load. So if it isn't instantaneous (which we'll say is ~300ms) THEN we show the
    // spinner.
    const [showFetchingAccounts, setShowFetchingAccounts] = useState(false)

    useEffect(() => {
      if (fetchingAccounts) {
        let cancel = false
        setTimeout(() => {
          if (!cancel) {
            setShowFetchingAccounts(true)
          }
        }, 300)
        return () => {
          cancel = true
        }
      } else {
        setShowFetchingAccounts(false)
      }
    }, [fetchingAccounts])

    const shouldShowMobile = useIsMobileOrTouchOrTechApp()

    return shouldShowMobile ? (
      <BzMobileSingleSelect
        title="Select Account"
        placeholder="Search for Account by Contact Name, Phone Number, Email, Address"
        onSearch={setAccountSearchText}
        options={accountOptions}
        value={currentAccount?.accountGuid}
        labelOverride={
          currentAccount?.accountContacts[0]
            ? makeAccountLabel(
                `${currentAccount.accountContacts[0].contact.firstName} ${currentAccount.accountContacts[0].contact.lastName}`,
                currentAccount.accountContacts[0]?.contact.primaryPhoneNumber
                  ?.phoneNumber,
                currentAccount.accountContacts[0]?.contact.primaryEmailAddress
                  ?.emailAddress,
                currentAccount.maintenancePlans[0]?.planTypeName,
                currentAccount.archived,
              )
            : ''
        }
        onChange={accountGuid => {
          const selectedAccount = accountOptions.find(
            option => option.value === accountGuid,
          )
          if (selectedAccount) {
            setSelectedAccountGuid(accountGuid)
            setAccountSearchText(selectedAccount.label)
            setAutocompleteErrored(false)
          }
        }}
        emptyState="Start typing to search for an account."
        loading={showFetchingAccounts}
      />
    ) : (
      <div>
        {showLabel && (
          <div className="semibold_14_22 grey9">Select existing account*</div>
        )}
        <AutoComplete
          disabled={fetchAccountQuery.isFetching}
          value={accountSearchText}
          onSearch={(searchText: string) => setAccountSearchText(searchText)}
          onSelect={(value, { label }) => {
            setSelectedAccountGuid(value)
            setAccountSearchText(label)
            setAutocompleteErrored(false)
          }}
          onBlur={() => {
            if (!currentAccount) {
              setAutocompleteErrored(true)
            }
          }}
          options={accountOptions}
          style={{ width: '100%' }}
          allowClear
        >
          <Input
            data-testid="accountSearch"
            prefix={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="mr-1 text-bz-gray-800"
              />
            }
            placeholder="Search for accounts by name, phone number, email, or address"
            size="large"
            status={autcompleteErrored ? 'error' : undefined}
          />
        </AutoComplete>
      </div>
    )
  },
)

export default AccountSearchAutocomplete
