import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { useStrictContext } from '../../utils/react-utils'

type BannerStatusMessageProps = {
  boldText: string
  regularText: string
}

export const BannerStatusMessage = React.memo<BannerStatusMessageProps>(
  ({ boldText, regularText }) => (
    <div className="inline">
      <strong className="mr-1 font-semibold">{boldText}</strong>
      <span>{regularText}</span>
    </div>
  ),
)

export type BannerColor = 'orange' | 'gray'

type BannerIconProps = {
  icon: IconProp
  className?: string
  iconClassName?: string
}

const getBannerIconColorClassNames = (
  color: BannerColor,
): { iconBgClassName: string; iconFillClassName: string } => {
  return {
    orange: {
      iconBgClassName: 'bg-bz-orange-200',
      iconFillClassName: 'text-bz-orange-800',
    },
    gray: {
      iconBgClassName: 'bg-bz-gray-200',
      iconFillClassName: 'text-bz-gray-800',
    },
  }[color]
}

export const BannerIcon = ({
  icon,
  className,
  iconClassName,
}: BannerIconProps) => {
  const { color } = useStrictContext(BannerContext)
  const { iconBgClassName, iconFillClassName } =
    getBannerIconColorClassNames(color)
  return (
    <div
      className={classNames(
        `flex h-8 w-8 items-center justify-center rounded-full ${iconBgClassName} flex-shrink-0`,
        className,
      )}
    >
      <FontAwesomeIcon
        icon={icon}
        className={classNames('h-4 w-4', iconFillClassName, iconClassName)}
      />
    </div>
  )
}

type BannerContextType = {
  color: BannerColor
}

const BannerContext = React.createContext<BannerContextType | undefined>(
  undefined,
)

const getBannerColorClassNames = (
  color: BannerColor,
): { bgClassName: string; borderColorClassName: string } => {
  return {
    orange: {
      bgClassName: 'bg-bz-orange-100',
      borderColorClassName: 'border-bz-orange-300',
    },
    gray: {
      bgClassName: 'bg-bz-fill-quaternary',
      borderColorClassName: 'border-bz-gray-500',
    },
  }[color]
}

export type BannerProps = {
  className?: string
  children?: ReactNode
  color?: BannerColor
}

/**
 * A generic Banner component.
 *
 * Usage:
 *
 * ```
 * import { Banner, BannerContent } from "src/elements/Banner/Banner"
 *
 * export const MyBanner = () => {
 *   return (
 *     <Banner>
 *        <BannerIcon icon={faOctagonExclamation} />
 *        <p>Content goes here</p>
 *     </Banner>
 *   )
 * }
 * ```
 */
export const Banner = ({
  children,
  color = 'orange',
  className,
}: BannerProps) => {
  const { bgClassName, borderColorClassName } = getBannerColorClassNames(color)
  return (
    <BannerContext.Provider value={{ color }}>
      <div
        className={classNames(
          `flex flex-wrap items-center justify-between gap-y-3 rounded-lg border border-solid px-4 py-3`,
          bgClassName,
          borderColorClassName,
          className,
        )}
      >
        {children}
      </div>
    </BannerContext.Provider>
  )
}
