import React from 'react'

import {
  LOAN_APPLICATION_PLACEHOLDER_LINK,
  LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX,
  LOAN_APPLICATION_SMS_CHAR_MAX,
  phoneUtils,
} from '@breezy/shared'
import { createTsForm, createUniqueFieldSchema } from '@ts-react/form'
import { z } from 'zod'
import PhoneNumberField from '../../PreviewMessage/PhoneNumberField/PhoneNumberField'
import { PhoneNumberSchema } from '../../PreviewMessage/PhoneNumberField/PhoneNumberField.schema'
import { SendAsSmsFormData } from '../../PreviewMessage/SendSmsForm/SendSmsForm'
import SmsMessageTextArea from '../../PreviewMessage/SmsMessageTextArea/SmsMessageTextArea'
import FinancingAppFormWrapper from '../FinancingAppFormWrapper'

type FinancingSmsAppFormProps = {
  phoneNumber?: string
  defaultSms: string
  onCancel: () => void
  onSubmit: (data: SendAsSmsFormData) => void
  dataDdActionName?: string
  disabled?: boolean
}

export const LoanApplicationSMSMessageTextArea = React.memo(() => {
  return <SmsMessageTextArea maxLength={LOAN_APPLICATION_SMS_CHAR_MAX} />
})

export const SmsMessageTextAreaWithPlaceholderLinkSchema =
  createUniqueFieldSchema(
    z
      .string()
      .max(LOAN_APPLICATION_SMS_CHAR_MAX, {
        message: `Text message must be ${LOAN_APPLICATION_SMS_CHAR_MAX} characters or fewer`,
      })
      .regex(
        LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX,
        `The text message must contain the text ${LOAN_APPLICATION_PLACEHOLDER_LINK} to indicate where the link to the loan application will be placed.`,
      ),
    'smsMessageTextArea',
  )

const sendSmsFieldMapping = [
  [
    SmsMessageTextAreaWithPlaceholderLinkSchema,
    LoanApplicationSMSMessageTextArea,
  ],
  [PhoneNumberSchema, PhoneNumberField],
] as const

const SendSmsForm = createTsForm(sendSmsFieldMapping, {
  FormComponent: FinancingAppFormWrapper,
})

export const FinancingSendAsSmsFormSchema = z.object({
  phoneNumber: PhoneNumberSchema.describe('Phone Number').refine(
    val => phoneUtils.isPossibleNumber(val, 'US'),
    val => ({ message: 'Invalid phone number', params: { phoneNumber: val } }),
  ),
  body: SmsMessageTextAreaWithPlaceholderLinkSchema.describe('Body'),
})

const FinancingSendSmsForm = React.memo<FinancingSmsAppFormProps>(
  ({
    phoneNumber = '',
    defaultSms,
    onCancel,
    onSubmit,
    dataDdActionName,
    disabled = false,
  }) => {
    return (
      <SendSmsForm
        schema={FinancingSendAsSmsFormSchema}
        formProps={{
          onCancel,
          submitButtonText: 'Send Application',
          disabled,
          dataDdActionName,
        }}
        onSubmit={onSubmit}
        defaultValues={{
          phoneNumber,
          body: defaultSms,
        }}
      >
        {({ phoneNumber, body }) => (
          <div>
            {phoneNumber}
            {body}
          </div>
        )}
      </SendSmsForm>
    )
  },
)

export default FinancingSendSmsForm
