import { isNullish } from '@breezy/shared'
import { OperationDefinitionNode } from 'graphql'
import {
  AnyVariables,
  CombinedError,
  Exchange,
  Operation,
  OperationResult,
  makeErrorResult,
} from 'urql'
import { filter, map, pipe, tap } from 'wonka'

export type ConsecutiveFailureTracker = Record<string, number>

const MAX_ATTEMPTS = 3

/**
 * The `failureLimitExchange` is a custom Urql exchange that limits the number of consecutive failures for a specific operation.
 * If an operation fails more than a specified number of times (default is 3), the exchange will stop forwarding the operation to the next exchange in the chain.
 *
 * @param {ConsecutiveFailureTracker} failureTracker - An object that tracks the number of consecutive failures for each operation. The keys are operation keys, and the values are the number of consecutive failures.
 * @returns {Exchange} An Exchange that can be used in the `exchanges` option when creating a Urql client.
 *
 * @example
 * import { createClient, dedupExchange, cacheExchange, fetchExchange } from 'urql';
 * import { failureLimitExchange } from './UrqlFailureLimitExchange';
 *
 * const failureTracker: ConsecutiveFailureTracker = useMemo(() => {
 *   return {}
 * }, [])
 *
 * const client = createClient({
 *   url: '/graphql',
 *   exchanges: [
 *     dedupExchange,
 *     cacheExchange,
 *     failureLimitExchange(failureTracker),
 *     fetchExchange
 *   ],
 * });
 */
export const failureLimitExchange =
  (failureTracker: ConsecutiveFailureTracker): Exchange =>
  ({ forward }) => {
    return ops$ => {
      return pipe(
        ops$,
        tap((operation: Operation) => {
          const { key } = operation
          if (isNullish(failureTracker[key])) {
            failureTracker[key] = 0
          }
        }),
        filter((operation: Operation) => {
          const { key } = operation
          if (failureTracker[key] >= MAX_ATTEMPTS) {
            return false
          }

          return true
        }),
        forward,
        map<OperationResult, OperationResult>((result: OperationResult) => {
          const { operation, error } = result
          const { key } = operation

          if (error) {
            // Increment retry count on error
            failureTracker[key] = (failureTracker[key] || 0) + 1

            if (failureTracker[key] >= MAX_ATTEMPTS) {
              const newError = new Error(
                formatMaximumAttemptsReachedErrorMessage(error, operation),
              )
              return makeErrorResult(operation, newError, error.response)
            }
          } else {
            // Reset retry count on success
            failureTracker[key] = 0
          }

          return result
        }),
      )
    }
  }

const formatMaximumAttemptsReachedErrorMessage = (
  error: CombinedError,
  operation: Operation<unknown, AnyVariables>,
) => {
  const operationType = operation.kind
  const operationName = (
    operation.query.definitions[0] as OperationDefinitionNode
  )?.name?.value

  const errorMessage = error.message
  if (error.graphQLErrors.length > 0) {
    return `${errorMessage}. Maximum attempts reached for this ${operationName} ${operationType} operation. Please try reloading the page or contact support if the issue persists.`
  } else {
    return `${errorMessage}. Maximum attempts reached for this ${operationName} ${operationType} operation. Please check your network connectivity and try again.`
  }
}
