import { AccountGuid } from '@breezy/shared'

import { Divider } from 'antd'
import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { ReminderStatusTypesEnum } from '../../generated/user/graphql'
import { useMessage } from '../../utils/antd-utils'
import AccountReminderView from './AccountReminderView/AccountReminderView'
import { UPDATE_REMINDER_STATUS_MUTATION } from './AccountReminders.gql'
import useAccountReminders from './AccountReminders.hooks'
import './AccountReminders.less'
import { AccountRemindersForm } from './AccountRemindersForm/AccountRemindersForm'
import {
  AccountRemindersWrapper,
  useAccountRemindersContext,
} from './AccountRemindersWrapper/AccountRemindersWrapper'
import CreateAccountReminderButton from './CreateAccountReminderButton/CreateAccountReminderButton'

type AccountRemindersProps = {
  accountGuid: AccountGuid
}

const AccountReminders = React.memo<AccountRemindersProps>(
  ({ accountGuid }) => {
    const [isCreating, setIsCreating] = useState(false)
    const { reminders, updateReminderStatus, refetch, fetching } =
      useAccountReminders(accountGuid)
    const message = useMessage()

    const [{ fetching: submitting }, executeMutation] = useMutation(
      UPDATE_REMINDER_STATUS_MUTATION,
    )

    const toggleCreate = useCallback(() => {
      setIsCreating(createStatus => !createStatus)
    }, [])

    const toggleReminderStatus = useCallback(
      async (reminderGuid: string, status: ReminderStatusTypesEnum) => {
        await executeMutation({
          reminderGuid,
          status: status === 'COMPLETE' ? 'INCOMPLETE' : 'COMPLETE',
        })

        updateReminderStatus(reminderGuid, status)

        if (status === 'INCOMPLETE') {
          message.success('Reminder completed')
        }
      },
      [executeMutation, message, updateReminderStatus],
    )

    const numReminders = useMemo(
      () => reminders.length ?? 0,
      [reminders.length],
    )

    const createSubmitted = useCallback(() => {
      refetch()
      message.success('Reminder created')
    }, [refetch, message])

    return (
      <AccountRemindersWrapper
        accountGuid={accountGuid}
        submitting={submitting}
        disabled={fetching}
        isCreating={isCreating}
        refetch={refetch}
      >
        <div className="flex flex-col">
          <AccountReminderHeader
            numReminders={numReminders}
            toggle={toggleCreate}
            loading={fetching}
          />
          <Divider className="my-3" />
          {isCreating && (
            <AccountRemindersForm
              submitted={createSubmitted}
              hide={toggleCreate}
            />
          )}
          {!reminders.length && !isCreating && (
            <EmptyState toggle={toggleCreate} />
          )}
          {reminders.length ? (
            <div className="flex flex-col">
              {reminders.map(ar => {
                return (
                  <AccountReminderView
                    key={ar.reminder.reminderGuid}
                    reminder={ar.reminder}
                    toggleStatus={toggleReminderStatus}
                  />
                )
              })}
            </div>
          ) : null}
        </div>
      </AccountRemindersWrapper>
    )
  },
)

type AccountReminderHeaderProps = {
  numReminders: number
  loading: boolean
  toggle: () => void
}

const AccountReminderHeader = React.memo<AccountReminderHeaderProps>(
  ({ numReminders, loading, toggle }) => {
    const { isEditing, isCreating } = useAccountRemindersContext()

    const disabled = useMemo(
      () => loading || isCreating || isEditing,
      [loading, isCreating, isEditing],
    )
    return (
      <div className="flex flex-row items-center justify-between">
        <div className="font-semibold">{numReminders} reminders</div>
        <CreateAccountReminderButton
          type="primary"
          className={cn(
            'bg-daybreak-blue-200 text-daybreak-blue-800 shadow-none',
            {
              'opacity-[.36]': disabled,
            },
          )}
          onClick={toggle}
          loading={loading}
          disabled={disabled || isEditing}
        />
      </div>
    )
  },
)

type EmptyStateProps = {
  toggle: () => void
}

const EmptyState = React.memo<EmptyStateProps>(({ toggle }) => {
  return (
    <div className="flex flex-col items-center gap-3 rounded-lg bg-bz-gray-200 px-4 py-6">
      <div className="text-center text-bz-gray-1000">
        You have no reminders for this account. Try creating one now and prevent
        important items from slipping through the cracks!
      </div>
      <CreateAccountReminderButton
        type="text"
        className="font-normal text-bz-primary"
        onClick={toggle}
      />
    </div>
  )
})

export default AccountReminders
