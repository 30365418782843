import { Button } from 'antd'
import React from 'react'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useStrictContext } from '../../utils/react-utils'
import { SelfServePaymentFormContext } from './selfServePaymentUtils'

type ContactConfirmationDialogProps = {
  onConfirm: () => void
  onCancel: () => void
  loading?: boolean
  contact: {
    firstName: string
    firstNameInitial?: string
  }
}

const ContactConfirmationDialog = React.memo<ContactConfirmationDialogProps>(
  ({ onConfirm, onCancel, loading = false, contact }) => {
    const { companyName } = useStrictContext(SelfServePaymentFormContext)
    return (
      <OnsiteBasicModal
        header="Confirm identity"
        open
        onClose={onCancel}
        footer={
          <div className="flex justify-end gap-3 pt-2">
            <Button size="large" disabled={loading} onClick={onCancel}>
              It's Not, Cancel
            </Button>
            <Button
              size="large"
              disabled={loading}
              onClick={onConfirm}
              type="primary"
            >
              Yes, That's Me!
            </Button>
          </div>
        }
      >
        <div className="flex flex-col">
          {`Hello ${contact.firstName}, before we proceed with the financing application we want to make sure it’s you. This is because the application will be associated with your contact information should you need to contact customer support.`}
          <p className="mt-8">
            <span>{'If your name is '}</span>
            <span className="font-semibold">
              {`“${contact.firstNameInitial}”`}
            </span>
            {`, please proceed. If it isn’t, please reach out to `}
            <span className="font-semibold">{companyName}</span>
            {` so that they may send a separate link.`}
          </p>
        </div>
      </OnsiteBasicModal>
    )
  },
)

export default ContactConfirmationDialog
