import { Link } from 'react-router-dom'

type LinkProps = {
  readonly guid: string
  readonly referenceNumber?: string
  readonly label?: string
  readonly prefix?: string
  readonly noUrlPluralize?: boolean
  readonly className?: string
}

const GeneralDetailsLink = ({
  guid,
  referenceNumber,
  label,
  entityName,
  prefix = ' ',
  noUrlPluralize = false,
  className,
}: LinkProps & { entityName: string; entityNameAlias?: string }) => (
  <Link
    to={`/${entityName.toLowerCase() + (noUrlPluralize ? '' : 's')}/${guid}`}
    className={className}
  >
    {prefix}
    {label ?? referenceNumber ?? `${entityName} Details`}
  </Link>
)

export const JobDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="Job" />
)

export const InvoiceDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="Invoice" noUrlPluralize />
)

export const AccountDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="Account" />
)

export const UserDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="User" />
)

export const MaintenancePlanDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="Maintenance-Plan" />
)

export const paymentDetailsLinkUrl = (guid: string) => `/payments/${guid}`

export const PaymentDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="Payment" />
)

export const PayoutDetailsLink = (l: LinkProps) => (
  <GeneralDetailsLink {...l} entityName="Payout" />
)
