import { telephoneNumberSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

export type PhoneNumberFormItemProps = {
  name?: string
  label?: string
  phoneNumber?: string
  disabled?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  labelClassName?: string
  required: boolean
  requiredMessage?: string
}

export const PhoneNumberFormItem: React.FC<PhoneNumberFormItemProps> = ({
  phoneNumber,
  disabled,
  required,
  requiredMessage,
  onBlur,
  name = 'phoneNumber',
  label = 'Phone',
  labelClassName = '',
}) => (
  <Form.Item
    name={name}
    label={label}
    className={labelClassName}
    initialValue={phoneNumber}
    rules={[
      {
        required,
        validator: async (_, value) => {
          if (!value && required) {
            throw new Error(requiredMessage ?? 'Phone number required')
          }
          if (!value) {
            return Promise.resolve()
          }

          const result = telephoneNumberSchema.safeParse(value)
          if (!result.success) {
            return Promise.reject(
              new Error(
                result.error.issues[0]?.message || result.error.message,
              ),
            )
          }
        },
      },
    ]}
  >
    <Input
      defaultValue={phoneNumber}
      onBlur={onBlur}
      autoComplete="tel"
      type="tel"
      name={name}
      id={name}
      maxLength={14}
      disabled={disabled}
    />
  </Form.Item>
)
