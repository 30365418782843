import { ComprehensiveAccountDetails } from '@breezy/backend/src/application-types'
import {
  DateTimeFormatter,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  getDisplayNameForAccountType,
  isNullish,
  noOp,
} from '@breezy/shared'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useFetchComprehensiveAccountDetails } from '../../hooks/fetch/useFetchComprehensiveAccountDetails'
import { m } from '../../utils/react-utils'
import { DateFormat, DateView } from '../Dates'
import { LoadingSpinner } from '../LoadingSpinner'
import { TagList } from '../Tags'
import TrpcQueryLoader from '../TrpcQueryLoader'
import {
  AccountContactsCollapsible,
  AccountJobsCollapsible,
  AccountLocationsCollapsible,
  InstalledEquipmentCollapsible,
  InstalledHvacSystemsCollapsible,
} from '../collapsibles'
import EstimatesCollapsible from '../collapsibles/EstimatesCollapsible/EstimatesCollapsible'
import { InvoicesV2Collapsible } from '../collapsibles/InvoicesCollapsible/InvoicesV2Collapsible'
import {
  GlobalSearchResultError,
  GlobalSearchResultItemPreviewEmpty,
} from './GlobalSearchLoadStates'
import { BreezyGlobalSearchResult, useBreezyHits } from './util'

type GlobalSearchResultItemPreviewInnerProps = {
  search: {
    hit: BreezyGlobalSearchResult
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent']
  }
  account: ComprehensiveAccountDetails
  onAccountClick: (accountGuid: string) => void
}

const GlobalSearchResultItemPreviewInner =
  m<GlobalSearchResultItemPreviewInnerProps>(
    ({ search, account, onAccountClick }) => {
      const installedEquipment = useMemo(() => {
        const equipment: InstalledEquipmentSummary[] = []
        for (const location of account.getAccountLocations()) {
          equipment.push(...(location.location.installedEquipment || []))
        }
        return equipment
      }, [account])

      const hvacSystems = useMemo(() => {
        const hvacSystems: InstalledHvacSystem[] = []
        for (const location of account.getAccountLocations()) {
          hvacSystems.push(...(location.location.installedHvacSystems || []))
        }
        return hvacSystems
      }, [account])

      return (
        <div className="flex h-full w-full flex-col bg-white shadow-inner">
          <div
            className="flex flex-col overflow-y-auto overflow-x-hidden bg-bz-gray-100 px-3 py-4 shadow-inner"
            onScroll={() => {
              search.sendEvent(
                'view',
                search.hit,
                'User Scrolls In Preview Pane',
              )
            }}
          >
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold text-bz-gray-900">
                {account.getDisplayName()}
                {search.hit.archived ? ' (Archived)' : ''}
              </h2>

              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-bz-gray-900">
                    Account Type
                  </span>
                  <span className="text-sm">
                    {getDisplayNameForAccountType(account.getType())}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-bz-gray-900">
                    Ref. ID
                  </span>
                  <span className="text-sm">
                    {account.getReferenceNumber()}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-bz-gray-900">
                    Created On
                  </span>
                  <span className="text-sm">
                    <DateView
                      format={DateFormat['MMM d, yyyy']}
                      isoWithOffsetTimestamp={account
                        .getAccountCreatedAt()
                        .format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)}
                      zone={{ type: 'company-timezone-of-principal-user' }}
                    />
                  </span>
                </div>
              </div>

              {account.getTags().length > 0 && (
                <div className="mt-2 flex flex-col">
                  <span className="mb-1 text-sm font-semibold text-bz-gray-900">
                    Tags
                  </span>

                  <TagList tags={account.getTags()} spacingY={2} />
                </div>
              )}
            </div>

            <Divider />

            <div className="flex flex-col space-y-3">
              <AccountContactsCollapsible
                accountGuid={account.getAccountGuid()}
                accountContacts={account.getAccountContacts()}
              />

              <AccountLocationsCollapsible
                accountGuid={account.getAccountGuid()}
                accountLocations={account.getAccountLocations()}
                refetch={noOp}
              />

              <AccountJobsCollapsible
                accountGuid={account.getAccountGuid()}
                jobs={account.getJobs()}
              />

              <InvoicesV2Collapsible accountGuid={account.getAccountGuid()} />

              <EstimatesCollapsible
                guids={{ accountGuid: account.getAccountGuid() }}
                allowCreate={false}
              />

              <InstalledEquipmentCollapsible
                installedEquipment={installedEquipment}
              />

              <InstalledHvacSystemsCollapsible
                installedHvacSystems={hvacSystems}
              />
            </div>
          </div>

          <div className="mt-auto flex flex-col items-center bg-bz-gray-100 px-5 py-2 drop-shadow-xl">
            <Button
              type="primary"
              size="large"
              className="flex w-full flex-row items-center justify-center space-x-2"
              onClick={() => {
                search.sendEvent(
                  'conversion',
                  search.hit,
                  'Navigated via Account Details Button',
                )
                onAccountClick(account.getAccountGuid())
              }}
            >
              <span>View Account Details</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      )
    },
  )

type GlobalSearchResultItemPreviewProps = {
  result?: {
    hit: BreezyGlobalSearchResult
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent']
  }
  onAccountClick: (
    accountGuid: string,
    hit: BreezyGlobalSearchResult,
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent'],
  ) => void
}

export const GlobalSearchResultItemPreview =
  m<GlobalSearchResultItemPreviewProps>(({ result, onAccountClick }) => {
    const fetchAccountDetails = useFetchComprehensiveAccountDetails({
      accountGuid: result?.hit.accountGuid ?? '',
      opts: {
        enabled: !isNullish(result),
      },
    })

    return (
      <div
        className={classNames([
          'h-full flex-shrink-0 flex-grow-0 basis-1/2',
          'flex flex-col',
        ])}
      >
        <div
          className={classNames([
            'h-full',
            'bg-bz-gray-300',
            'flex flex-col',
            'overflow-y-auto',
            'overflow-x-hidden',
          ])}
        >
          <TrpcQueryLoader
            query={fetchAccountDetails}
            idleComponent={
              <div className="mx-8 mt-20">
                <GlobalSearchResultItemPreviewEmpty />
              </div>
            }
            loadingComponent={
              <div className="mt-20">
                <LoadingSpinner />
              </div>
            }
            errorComponent={
              <div className="mx-8 mt-20">
                <GlobalSearchResultError
                  icon={faTriangleExclamation}
                  title="Well, this is embarrassing..."
                  message="We're experiencing an issue displaying the account's details."
                  onTryAgainClick={() => fetchAccountDetails.refetch()}
                />
              </div>
            }
            render={account => (
              <>
                {result && (
                  <GlobalSearchResultItemPreviewInner
                    search={{
                      hit: result.hit,
                      sendEvent: result.sendEvent,
                    }}
                    account={account}
                    onAccountClick={accountGuid =>
                      onAccountClick(accountGuid, result.hit, result.sendEvent)
                    }
                  />
                )}
              </>
            )}
          />
        </div>
      </div>
    )
  })
