import cn from 'classnames'
import useIsMobile, { useIsMediumScreen } from '../../hooks/useIsMobile'

export type ThreeColumnTemplateProps = {
  columnOne: React.ReactNode
  columnTwo: React.ReactNode
  columnThree: React.ReactNode
}

const containerStyleClassNames = ['rounded-md']
const columnStyleClassNames = ['bg-white', 'shadow-md', 'rounded-xl']
const containerLayoutClassNames = [
  'grid',
  'grid-flow-row-dense',
  'grid-cols-10',
  'gap-5',
  'flex-1',
  'min-h-0',
]
const columnLayoutClassNames = [
  'min-h-0',
  'overflow-auto',
  'overscroll-contain',
]

const ThreeColumnTemplate = ({
  columnOne,
  columnTwo,
  columnThree,
}: ThreeColumnTemplateProps) => {
  const isMediumScreen = useIsMediumScreen()
  const isMobile = useIsMobile()

  const columnThreeStyleClassNames = isMobile ? [] : columnStyleClassNames

  return (
    <div className={cn(containerStyleClassNames, containerLayoutClassNames)}>
      <div
        className={cn(
          isMobile
            ? 'col-span-10'
            : isMediumScreen
            ? 'order-1 col-span-5'
            : 'col-span-3',
          columnStyleClassNames,
          columnLayoutClassNames,
        )}
      >
        {columnOne}
      </div>
      <div
        className={cn(
          isMobile
            ? 'col-span-10'
            : isMediumScreen
            ? 'order-3 col-span-10'
            : 'col-span-4',
          columnStyleClassNames,
          columnLayoutClassNames,
        )}
      >
        {columnTwo}
      </div>
      <div
        className={cn(
          isMobile
            ? 'col-span-10'
            : isMediumScreen
            ? 'order-2 col-span-5'
            : 'col-span-3',
          columnThreeStyleClassNames,
          columnLayoutClassNames,
        )}
      >
        {columnThree}
      </div>
    </div>
  )
}

export default ThreeColumnTemplate
