import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../../../hooks/useIsMobile'
import {
  PaymentWorkflowFormStyle,
  defaultPaymentWorkflowFooterPaddingStyles,
  defaultPaymentWorkflowFooterStyles,
} from '../../PaymentWorkflowWizard'

type PaymentFooterButtonsProps = {
  onCancel?: () => void
  isLoading?: boolean
  primaryButtonText?: string
  footerClassName?: string
  dataDdActionName?: string
  disabled?: boolean
  withBorder?: boolean
  formStyle: PaymentWorkflowFormStyle
}

const PaymentFooterButtons = React.memo<PaymentFooterButtonsProps>(
  ({
    onCancel,
    isLoading,
    primaryButtonText = 'Save',
    footerClassName,
    dataDdActionName,
    disabled = false,
    formStyle = 'modal',
  }) => {
    const isMobile = useIsMobile()
    return (
      <>
        <div
          className={classNames(
            'flex flex-row items-center justify-between gap-3 bg-white',
            formStyle === 'modal' ? defaultPaymentWorkflowFooterStyles : '',
            formStyle === 'modal'
              ? defaultPaymentWorkflowFooterPaddingStyles
              : '',
            isMobile ? 'mx-[-16px]' : 'mx-[-24px]',
            footerClassName,
          )}
        >
          {onCancel && (
            <Button block size="large" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
          )}
          <Button
            block
            type="primary"
            size="large"
            data-testid="pay-button"
            data-dd-action-name={dataDdActionName}
            htmlType="submit"
            loading={isLoading}
            disabled={disabled || isLoading}
          >
            {primaryButtonText}
          </Button>
        </div>
        {isLoading && <div className="absolute inset-0 z-10 cursor-wait" />}
      </>
    )
  },
)

export default PaymentFooterButtons
