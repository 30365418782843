import { isNullish } from '@breezy/shared'
import { EmDash } from '../EmDash/EmDash'

export const MaybeDataPoint = ({
  children,
}: {
  children: React.ReactNode | undefined | null
}) => {
  if (isNullish(children) || children === '') {
    return <EmDash />
  }

  return <>{children}</>
}
