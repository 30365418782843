import { useEffect, useMemo, useState } from 'react'
import {
  AnyVariables,
  UseQueryArgs,
  UseQueryExecute,
  UseQueryState,
  useQuery,
} from 'urql'

/**
 * `useQueryMaxAttempts` executes urql's `useQuery` hook, but stops fetching after a number of consecutive
 * failed attempts.
 *
 * Note that the re-execute function returned in the UseQueryResponse will NOT adhere to the
 * specified number of attempts, and can be used to force a re-fetch regardless of errors
 *
 * @param {UseQueryArgs<Variables, Data>} args - The arguments to pass to the `useQuery` hook.
 * @param {number} maxAttemptsNetworkErrors - The maximum number of retries before stopping if the query
 *  fails due to network errors. Defaults to 3.
 * @param {number} maxAttemptsGraphqlErrors - The maximum number of retries before stopping if the query
 *  fails due to graphql errors. Defaults to 3.
 *
 * @returns {[UseQueryState<Data, Variables>, UseQueryExecute, boolean]} - The response from the `useQuery` hook,
 *    plus a boolean flag indicating if the query has stopped due to consecutive errors.
 */
export const useQueryMaxAttempts = <Data, Variables extends AnyVariables>(
  args: UseQueryArgs<Variables, Data>,
  maxAttemptsNetworkErrors = 3,
  maxAttemptsGraphqlErrors = 3,
): [UseQueryState<Data, Variables>, UseQueryExecute, boolean] => {
  const [networkFailureCount, setNetworkFailureCount] = useState(0)
  const [gqlFailureCount, setGqlFailureCount] = useState(0)
  const tooManyAttempts = useMemo(
    () =>
      networkFailureCount >= maxAttemptsNetworkErrors ||
      gqlFailureCount >= maxAttemptsGraphqlErrors,
    [
      gqlFailureCount,
      maxAttemptsGraphqlErrors,
      maxAttemptsNetworkErrors,
      networkFailureCount,
    ],
  )
  const [response, executeQuery] = useQuery<Data, Variables>({
    ...args,
    pause: !!args.pause || tooManyAttempts,
  })

  useEffect(() => {
    if (response.error) {
      if (response.error.networkError) {
        setNetworkFailureCount(prevFailureCount => prevFailureCount + 1)
      }

      if (response.error.graphQLErrors.length > 0) {
        setGqlFailureCount(prevFailureCount => prevFailureCount + 1)
      }
    } else if (response.data) {
      setNetworkFailureCount(0)
      setGqlFailureCount(0)
    }
  }, [response.data, response.error])

  return [response, executeQuery, tooManyAttempts]
}
