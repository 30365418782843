import {
  INVOICE_V2_FRIENDLY_STATUS_NAMES,
  InvoiceStatuses,
  InvoiceV2Status,
} from '@breezy/shared'
import React from 'react'
import StatusTag, {
  StatusTagColor,
} from '../../../elements/StatusTag/StatusTag'

export type InvoiceStatusTagProps = {
  status: InvoiceStatuses | InvoiceV2Status | 'Draft'
}

const toStatusTagColor = (
  status: InvoiceStatuses | InvoiceV2Status | 'Draft',
): StatusTagColor => {
  switch (status) {
    case InvoiceStatuses.CREATED:
      return 'cyan'
    case 'OPEN':
      return 'geek-blue'
    case InvoiceStatuses.PRESENTED:
      return 'purple'
    case InvoiceStatuses.ACCEPTED:
      return 'magenta'
    case InvoiceStatuses.VOID:
    case InvoiceStatuses.REJECTED:
    case 'VOIDED':
      return 'red'
    case InvoiceStatuses.FULLY_PAID:
    case 'PAID':
      return 'green'
    case InvoiceStatuses.REFUNDED:
      return 'blue'
    case 'DRAFT':
    case 'UNCOLLECTABLE':
    case 'Draft':
    default:
      return 'gray'
  }
}

const InvoiceStatusTag = React.memo<InvoiceStatusTagProps>(({ status }) => {
  const label =
    status in INVOICE_V2_FRIENDLY_STATUS_NAMES
      ? INVOICE_V2_FRIENDLY_STATUS_NAMES[
          status as keyof typeof INVOICE_V2_FRIENDLY_STATUS_NAMES
        ]
      : status
  return (
    <StatusTag color={toStatusTagColor(status)} text={label} border="strong" />
  )
})

export default InvoiceStatusTag
