import { useCallback, useEffect, useMemo } from 'react'
import { AnyVariables, UseQueryResponse, UseQueryState } from 'urql'
import { typedMemo } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'

type GqlMultiQueryLoaderProps<T extends readonly unknown[]> = {
  queries: { [K in keyof T]: UseQueryResponse<T[K]> }
  errorComponent?: JSX.Element
  errorMessage?: string
  loadingComponent?: JSX.Element
  idleComponent?: JSX.Element
  render: (data: T) => JSX.Element
}

export const GqlMultiQueryLoader = typedMemo(
  <T extends readonly unknown[]>({
    queries,
    errorComponent,
    errorMessage,
    loadingComponent,
    idleComponent,
    render,
  }: GqlMultiQueryLoaderProps<T>) => {
    const states = useMemo<UseQueryState<unknown, AnyVariables>[]>(
      () => queries.map(q => q[0]),
      [queries],
    )
    const error = useMemo(
      () => states.find(s => !!s.error)?.error ?? undefined,
      [states],
    )
    useEffect(() => {
      if (error) {
        console.error({ error })
      }
    }, [error])

    if (states.every(s => !s.fetching && !s.data)) {
      return <>{idleComponent}</>
    }

    if (states.some(s => s.fetching)) {
      return loadingComponent || <LoadingSpinner />
    }

    if (states.some(s => s.error) && errorComponent) {
      return errorComponent
    }
    if (
      (states.some(s => s.error) && !errorComponent) ||
      states.some(s => !s.data)
    )
      return <div>{errorMessage || `Failed to load data`}</div>

    return render(states.map(q => q.data) as unknown as T)
  },
)

type GqlQueryLoaderProps<T> = {
  query: UseQueryResponse<T>
  errorComponent?: JSX.Element
  errorMessage?: string
  loadingComponent?: JSX.Element
  idleComponent?: JSX.Element
  render: (data: T) => JSX.Element
}

export const GqlQueryLoader = typedMemo(
  <T,>({ query, render, ...rest }: GqlQueryLoaderProps<T>) => {
    const queries = useMemo<[UseQueryResponse<T>]>(() => [query], [query])
    const ourRender = useCallback((data: [T]) => render(data[0]), [render])
    return (
      <GqlMultiQueryLoader queries={queries} render={ourRender} {...rest} />
    )
  },
)

export default GqlQueryLoader
