import {
  AppointmentStatusDisplayNames,
  InferredAppointmentStatus,
} from '@breezy/shared'
import classNames from 'classnames'
import React from 'react'
import StatusTag, {
  StatusTagColor,
  StatusTagProps,
} from '../../elements/StatusTag/StatusTag'

type AppointmentStatusTagProps = Omit<StatusTagProps, 'text' | 'color'> & {
  status: InferredAppointmentStatus
}

export const toInferredAppointmentStatusDetails = (
  status: InferredAppointmentStatus,
): { text: string; color: StatusTagColor } => {
  switch (status) {
    case 'UNASSIGNED':
      return {
        text: AppointmentStatusDisplayNames['UNASSIGNED'],
        color: 'gray',
      }
    case 'ASSIGNED':
      return {
        text: AppointmentStatusDisplayNames['ASSIGNED'],
        color: 'cyan',
      }
    case 'EN_ROUTE':
      return {
        text: AppointmentStatusDisplayNames['EN_ROUTE'],
        color: 'blue',
      }
    case 'IN_PROGRESS':
      return {
        text: AppointmentStatusDisplayNames['IN_PROGRESS'],
        color: 'purple',
      }
    case 'COMPLETED':
      return {
        text: AppointmentStatusDisplayNames['COMPLETED'],
        color: 'green',
      }
    case 'CANCELED':
      return {
        text: AppointmentStatusDisplayNames['CANCELED'],
        color: 'red',
      }
    default:
      return { text: 'Unknown', color: 'gray' }
  }
}

export const AppointmentStatusTag = React.memo<AppointmentStatusTagProps>(
  ({ status, className, border = 'regular', ...rest }) => {
    return (
      <StatusTag
        className={classNames('!bg-bz-gray-200', className)}
        text={toInferredAppointmentStatusDetails(status).text}
        color={toInferredAppointmentStatusDetails(status).color}
        border={border}
        {...rest}
      />
    )
  },
)
