import xss from 'xss'
import { z } from 'zod'
import { AsyncFn } from '../../../common'
import { ForCompanyUser } from '../../Company/Company'
import { bzOptional } from '../../common-schemas'
import { InitiateLoanTransactionRequestSchema } from './LoanTransactions'
import { InitiatePrequalRequestSchema } from './Prequal'

export const WISETACK_LEARN_MORE_HTML_LINK = `<a href="https://www.wisetack.com/consumers" target="_blank">Learn More About Wisetack Financing</a>`
export const WISETACK_FULL_DISCLOSURE_HTML_MESSAGE = `* All financing is subject to credit approval. Your terms may vary. Payment options through Wisetack are provided by our lending partners. For example, a $1,200 purchase could cost $104.89 a month for 12 months, based on an 8.9% APR, or $400 a month for 3 months, based on a 0% APR. Offers range from 0-35.9% APR based on creditworthiness. No other financing charges or participation fees. See additional terms at <a href="https://wisetack.com/faqs" target="_blank">https://wisetack.com/faqs</a>.`

// This is used to calculate the max length of the loan application text
export const WISETACK_LOAN_APPLICATION_LINK_NUM_CHARS = 31
export const LOAN_APPLICATION_PLACEHOLDER_LINK = '[Link Goes Here]'
export const LOAN_APPLICATION_SMS_CHAR_MAX =
  160 - (WISETACK_LOAN_APPLICATION_LINK_NUM_CHARS - LOAN_APPLICATION_PLACEHOLDER_LINK.length)

// TODO add the char count for the prequal link
export const LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX = /\[Link Goes Here\]/
export const LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX_GLOBAL = /\[Link Goes Here\]/g

export const sanitize = (str: string) => xss(str)

export const replaceEmailPlaceholderWithLink = (
  input: string,
  loanApplicationLink: string,
  linkText = 'Loan Application',
) =>
  input.replace(
    LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX_GLOBAL,
    `<a href="${loanApplicationLink}" target="_blank">${linkText}</a>`,
  )

export const replaceSmsPlaceholderWithLink = (input: string, loanApplicationLink: string) =>
  input.replace(LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX, loanApplicationLink)

export const createDefaultLoanApplicationEmail = (firstName: string | undefined, companyName: string) =>
  `<p>Dear${
    firstName ? ` ${firstName}` : ''
  },</p><p>Thanks for choosing ${companyName}.</p><p>To help you manage the cost of HVAC services, we've partnered with Wisetack to offer you a straightforward financing option.</p><p>The application only takes a few minutes to complete and you’ll receive a decision just as quickly. There are no hidden fees and checking your eligibility won’t impact your credit score!</p><p>Uninterrupted comfort and efficiency for your home is just a click away.</p><p>View financing options now:</p><p>${LOAN_APPLICATION_PLACEHOLDER_LINK}</p><p>Don't hesitate to contact us if you have any questions. Have a great day!</p><p>${companyName}</p>`

export const createDefaultLoanApplicationSms = (companyName: string) =>
  `Thanks for choosing ${companyName}. Explore financing payments options: ${LOAN_APPLICATION_PLACEHOLDER_LINK}\n\nContact us if you have any questions!`

export const createDefaultPrequalApplicationEmail = (firstName: string | undefined, companyName: string) =>
  `<p>Dear${
    firstName ? ` ${firstName}` : ''
  },</p><p>Thanks for choosing ${companyName}.</p><p>To help you manage the cost of HVAC services, we've partnered with Wisetack to offer you an opportunity to pre-qualify for financing.</p><p>It’s a simple, no obligation process that has no hidden fees. Checking your eligibility won’t impact your credit score!</p><p>Check if you pre-qualify now:</p><p>${LOAN_APPLICATION_PLACEHOLDER_LINK}</p><p>Don't hesitate to contact us if you have any questions. Have a great day!</p><p>${companyName}</p>`
export const createDefaultPrequalApplicationSms = (companyName: string) =>
  `Thanks for choosing ${companyName}. Check if you pre-qualify for financing ${LOAN_APPLICATION_PLACEHOLDER_LINK}`

export const SendLoanApplicationSchema = InitiateLoanTransactionRequestSchema.extend({
  deliveryMethod: z.enum(['EMAIL', 'SMS']),
  body: z.string(),
  to: z.string(),
  subject: bzOptional(z.string()),
})

export type SendLoanApplicationRequest = z.infer<typeof SendLoanApplicationSchema>

export type LoanApplicationSender = AsyncFn<ForCompanyUser<SendLoanApplicationRequest>>

export const SendPrequalApplicationSchema = InitiatePrequalRequestSchema.extend({
  deliveryMethod: z.enum(['EMAIL', 'SMS']),
  body: z.string(),
  to: z.string(),
  subject: bzOptional(z.string()),
  // Used to track the invoice that the prequal application is originating from for invoice event auditing
  invoiceGuid: bzOptional(z.string()),
})

export type SendPrequalApplicationRequest = z.infer<typeof SendPrequalApplicationSchema>

export type PrequalApplicationSender = AsyncFn<ForCompanyUser<SendPrequalApplicationRequest>>

export const SendLoanApplicationEmailSchema = SendLoanApplicationSchema.extend({
  loanApplicationLink: z.string(),
})

export type SendLoanApplicationEmailRequest = z.infer<typeof SendLoanApplicationEmailSchema>
export type LoanApplicationEmailSender = AsyncFn<Omit<SendLoanApplicationEmailRequest, 'deliveryMethod'>, void>

export const SendLoanApplicationSmsSchema = SendLoanApplicationSchema.extend({
  loanApplicationLink: z.string(),
})
export type SendLoanApplicationSmsRequest = z.infer<typeof SendLoanApplicationSmsSchema>

export type LoanApplicationSmsSender = AsyncFn<Omit<SendLoanApplicationSmsRequest, 'deliveryMethod'>, void>

export const SendPrequalApplicationEmailSchema = SendPrequalApplicationSchema.extend({
  prequalApplicationLink: z.string(),
})
export type SendPrequalApplicationEmailRequest = z.infer<typeof SendPrequalApplicationEmailSchema>
export type PrequalApplicationEmailSender = AsyncFn<Omit<SendPrequalApplicationEmailRequest, 'deliveryMethod'>, void>

export const SendPrequalApplicationSmsSchema = SendPrequalApplicationSchema.omit({
  companyGuid: true,
  accountGuid: true,
  jobGuid: true,
}).extend({
  prequalApplicationLink: z.string(),
})
export type SendPrequalApplicationSmsRequest = z.infer<typeof SendPrequalApplicationSmsSchema>
export type PrequalApplicationSmsSender = AsyncFn<Omit<SendPrequalApplicationSmsRequest, 'deliveryMethod'>, void>
