import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import BzCollapsible from '../../../../Page/BzCollapsible/BzCollapsible'

export type AppointmentDetailCollapseProps = {
  title: string
  titleIcon: IconDefinition
  content: React.ReactNode
  allowExpandIfNoChildren?: boolean
  showChildrenLength?: boolean
  defaultOpen?: boolean
}

const AppointmentDetailCollapse = ({
  title,
  titleIcon,
  content,
  allowExpandIfNoChildren,
  defaultOpen,
}: AppointmentDetailCollapseProps) => {
  return (
    <BzCollapsible
      title={title}
      titleIcon={titleIcon}
      defaultOpen={defaultOpen}
      allowExpandIfNoChildren={allowExpandIfNoChildren}
      technicianAppStyle
    >
      {content}
    </BzCollapsible>
  )
}

export default AppointmentDetailCollapse
