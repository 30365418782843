import {
  AbridgedInvoiceMetadata,
  BzDateFns,
  formatMoney,
  generateMinifiedInvoicePaymentLink,
} from '@breezy/shared'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'urql'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { getConfig } from '../../../../config'
import { trpc } from '../../../../hooks/trpc'
import {
  SetIsDirty,
  useExternalIsDirty,
} from '../../../../hooks/useExternalIsDirty'
import useIsMobile from '../../../../hooks/useIsMobile'
import {
  useExpectedCompany,
  useExpectedPrincipal,
} from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import { LoadingSpinner } from '../../../LoadingSpinner'
import PreviewMessageOptionSelection, {
  MessageOption,
} from '../../../PreviewMessage/PreviewMessageOptionSelection/PreviewMessageOptionSelection'
import {
  SendAsEmailFormData,
  createSendEmailForm,
} from '../../../PreviewMessage/SendEmailForm/SendEmailForm'
import { SendAsEmailFormSchema } from '../../../PreviewMessage/SendEmailForm/SendEmailForm.schema'
import {
  SendAsSmsFormData,
  createSendSmsForm,
} from '../../../PreviewMessage/SendSmsForm/SendSmsForm'
import { SendAsSmsFormSchema } from '../../../PreviewMessage/SendSmsForm/SendSmsForm.schema'
import {
  CONTACT_BY_GUID_QUERY,
  PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY,
} from './SendInvoiceDrawer.gql'

const config = getConfig()

const createDefaultEmail = ({
  firstName,
  serviceDate,
  invoiceNumber,
  amountDueUsd,
  paymentLink,
  companyName,
}: {
  firstName?: string
  serviceDate?: string
  invoiceNumber: number
  amountDueUsd: number
  paymentLink: string
  companyName: string
}) =>
  `<p><strong>Hello ${
    firstName ? ` ${firstName}` : ''
  },</strong></p><p>We hope this message finds you well. We wanted to let you know that the service${
    serviceDate ? ` on ${serviceDate}` : ''
  } has been successfully completed.</p><p>Your invoice #${invoiceNumber} in the amount of ${formatMoney(
    amountDueUsd,
  )} is now ready for review and payment. We've made it easy and secure for you to pay online with the link below.</p><p><strong>Payment link</strong><br/><a href="${paymentLink}" rel="noopener noreferrer">${paymentLink}</a></p><p>Let me know if you have any questions. Thanks and have a great day!</p><p>${companyName}</p>`

const createDefaultSms = (
  firstName: string | undefined,
  invoiceNumber: number,
  paymentLink: string,
  userFirstName: string,
) => `Hello${firstName ? ` ${firstName}` : ''},

Your payment for Invoice #${invoiceNumber} is due.

Payment link
${paymentLink}
${paymentLink.length > 30 ? '' : '\nThanks and have a great day!'}
${userFirstName}`

type CustomFormProps = React.PropsWithChildren<{
  onSubmit: () => void
  amountDueUsd: number
  isLoading: boolean
  onCancel: () => void
  onBack?: () => void
  showAmountDue?: boolean
  footerClassName?: string
  primaryButtonText?: string
}>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const CustomForm = ({
  children,
  onSubmit,
  onCancel,
  onBack,
  amountDueUsd,
  isLoading,
  showAmountDue = true,
  footerClassName = 'border-0 border-t border-solid border-t-bz-gray-400 px-6 py-3',
  primaryButtonText = 'Send Invoice',
}: CustomFormProps) => {
  const isMobile = useIsMobile()

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <Form
        disabled={isLoading}
        onSubmitCapture={onSubmit}
        layout="vertical"
        className="flex min-h-0 flex-1 flex-col"
        requiredMark={label => (
          <div>
            {label}
            <span className="ml-1 text-bz-red-500">*</span>
          </div>
        )}
      >
        <div
          className={classNames(
            'min-h-0 flex-1 overflow-auto pb-4',
            isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
          )}
        >
          {children}
          {showAmountDue && (
            <div className="mt-1 rounded-lg border border-solid border-bz-gray-500 p-4">
              <div className="mb-2 font-semibold">Amount Due</div>
              <div className="text-4xl font-semibold text-bz-gray-1000">
                {formatMoney(amountDueUsd)}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(
            'flex flex-row items-center justify-between',
            footerClassName,
            isMobile ? 'mx-[-16px]' : 'mx-[-24px]',
          )}
        >
          <Button
            className={classNames({
              'flex-1': !onBack,
            })}
            onClick={onCancel}
            size="large"
          >
            Cancel
          </Button>
          <div
            className={classNames('flex flex-row items-center space-x-3', {
              'flex-1': !onBack,
            })}
          >
            {onBack && (
              <Button
                size="large"
                onClick={onBack}
                icon={
                  <FontAwesomeIcon icon={faArrowLeft} className="text-base" />
                }
              >
                Go Back
              </Button>
            )}
            <Button
              size="large"
              type="primary"
              className={classNames({
                'flex-1': !onBack,
              })}
              onClick={onSubmit}
              loading={isLoading}
            >
              {primaryButtonText}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

const SendEmailForm = createSendEmailForm(CustomForm)
const SendSmsForm = createSendSmsForm(CustomForm)

type SendPaymentLinkFormProps = {
  onCancel: (force?: boolean) => void
  onBack?: () => void
  amountDueUsd: number
  invoice: AbridgedInvoiceMetadata
  selectedContactGuid?: string
  footerClassName?: string
  showAmountDue?: boolean
  wrapperClassName?: string
  primaryButtonText?: string
  setIsDirty?: SetIsDirty
  onSuccess?: () => void
  isCustomAmount?: boolean
}

export const SendPaymentLinkForm = React.memo<SendPaymentLinkFormProps>(
  ({
    onCancel,
    onBack,
    amountDueUsd,
    invoice,
    selectedContactGuid,
    footerClassName,
    showAmountDue,
    wrapperClassName,
    primaryButtonText,
    setIsDirty,
    onSuccess,
    isCustomAmount = false,
  }) => {
    const isMobile = useIsMobile()
    const message = useMessage()

    const company = useExpectedCompany()
    const user = useExpectedPrincipal()

    const [{ data: accountData, fetching: fetchingAccountData }] = useQuery({
      query: PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY,
      variables: { accountGuid: invoice.accountGuid },
    })

    const [{ data: contactData, fetching: fetchingContactData }] = useQuery({
      query: CONTACT_BY_GUID_QUERY,
      variables: {
        query: {
          contactGuid: { _eq: selectedContactGuid },
          companyGuid: { _eq: company.companyGuid },
        },
      },
      pause: !selectedContactGuid,
    })

    const contact =
      contactData?.contacts[0] ??
      accountData?.accountsByPk?.accountContacts?.[0].contact

    const [paymentLink, setPaymentLink] = useState<string>()

    useEffect(() => {
      if (!paymentLink) {
        ;(async () => {
          try {
            const url = await generateMinifiedInvoicePaymentLink(
              config.accountAppFullUrl,
              invoice.referenceNumber,
              selectedContactGuid,
              isCustomAmount ? amountDueUsd : undefined,
            )
            setPaymentLink(url)
          } catch (e) {
            console.error(e)
            message.error('Failed to generate link.')
          }
        })()
      }
    }, [
      amountDueUsd,
      invoice.referenceNumber,
      isCustomAmount,
      message,
      paymentLink,
      selectedContactGuid,
    ])

    const [messageOption, setMessageOption] = useState<MessageOption>('email')

    const { mutate: sendInvoice, isLoading: isSendInvoiceLoading } =
      trpc.invoice['invoicing:send-invoice'].useMutation({
        onSuccess: () => {
          message.success(
            contact?.firstName && contact?.lastName
              ? `Invoice sent to ${contact.firstName} ${contact.lastName}`
              : 'Invoice sent',
          )
          onSuccess?.()
          onCancel(true)
        },
        onError: () => {
          message.error('Failed to send invoice. Try again later.')
        },
      })

    const onEmailSubmit = useCallback(
      (data: SendAsEmailFormData) => {
        sendInvoice({
          invoiceGuid: invoice.invoiceGuid,
          deliveryMethod: 'EMAIL',
          body: data.body,
          to: data.emailAddress,
          subject: data.subject,
          paymentLink: paymentLink ?? '',
        })
      },
      [invoice.invoiceGuid, paymentLink, sendInvoice],
    )
    const onSmsSubmit = useCallback(
      (data: SendAsSmsFormData) => {
        sendInvoice({
          invoiceGuid: invoice.invoiceGuid,
          deliveryMethod: 'SMS',
          body: data.body,
          to: data.phoneNumber,
          paymentLink: paymentLink ?? '',
        })
      },
      [invoice.invoiceGuid, paymentLink, sendInvoice],
    )

    const defaultEmailValues = useMemo(() => {
      return {
        emailAddress: contact?.primaryEmailAddress?.emailAddress ?? '',
        subject: `Invoice #${invoice.displayId} from ${company.name}`,
        body: createDefaultEmail({
          firstName: contact?.firstName,
          serviceDate:
            invoice.serviceCompletionDate &&
            BzDateFns.localDateToFriendlyDateString(
              invoice.serviceCompletionDate,
            ),
          invoiceNumber: invoice.displayId,
          amountDueUsd,
          paymentLink: paymentLink ?? '',
          companyName: company.name,
        }),
      }
    }, [
      amountDueUsd,
      company.name,
      contact?.firstName,
      contact?.primaryEmailAddress?.emailAddress,
      invoice.displayId,
      invoice.serviceCompletionDate,
      paymentLink,
    ])

    const defaultPhoneValues = useMemo(() => {
      return {
        phoneNumber: contact?.primaryPhoneNumber?.phoneNumber ?? '',
        body: createDefaultSms(
          contact?.firstName,
          invoice.displayId,
          paymentLink ?? '',
          user.firstName,
        ),
      }
    }, [
      contact?.firstName,
      contact?.primaryPhoneNumber?.phoneNumber,
      invoice.displayId,
      paymentLink,
      user.firstName,
    ])

    const emailForm = useForm<SendAsEmailFormData>({
      defaultValues: defaultEmailValues,
    })
    const smsForm = useForm<SendAsSmsFormData>({
      defaultValues: defaultPhoneValues,
    })

    const isDirty = emailForm.formState.isDirty || smsForm.formState.isDirty

    useExternalIsDirty(setIsDirty, isDirty)

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal(isDirty)

    const onCancelWithConfirm = useCallback(
      () =>
        withConfirmation(() => {
          emailForm.reset()
          smsForm.reset()
          onCancel()
        }),
      [emailForm, onCancel, smsForm, withConfirmation],
    )

    const onBackWithConfirm = useMemo(() => {
      if (!onBack) {
        return undefined
      }
      return () =>
        withConfirmation(() => {
          emailForm.reset()
          smsForm.reset()
          onBack()
        })
    }, [emailForm, onBack, smsForm, withConfirmation])

    if (fetchingAccountData || fetchingContactData || !paymentLink) {
      return <LoadingSpinner />
    }

    return (
      <>
        <div
          className={classNames(
            'flex min-h-0 flex-1 flex-col pb-0',
            wrapperClassName,
            {
              'p-6': !wrapperClassName && !isMobile,
              'p-4': !wrapperClassName && isMobile,
            },
          )}
        >
          <PreviewMessageOptionSelection
            messageOption={messageOption}
            setMessageOption={setMessageOption}
          />
          <div
            className={classNames('flex min-h-0 flex-1 flex-col', {
              hidden: messageOption !== 'email',
            })}
          >
            <SendEmailForm
              form={emailForm}
              schema={SendAsEmailFormSchema}
              onSubmit={onEmailSubmit}
              formProps={{
                onCancel: onCancelWithConfirm,
                onBack: onBackWithConfirm,
                amountDueUsd,
                isLoading: isSendInvoiceLoading,
                footerClassName,
                showAmountDue,
                primaryButtonText,
              }}
              defaultValues={defaultEmailValues}
            />
          </div>
          <div
            className={classNames('flex min-h-0 flex-1 flex-col', {
              hidden: messageOption !== 'sms',
            })}
          >
            <SendSmsForm
              form={smsForm}
              schema={SendAsSmsFormSchema}
              onSubmit={onSmsSubmit}
              formProps={{
                onCancel: onCancelWithConfirm,
                onBack: onBackWithConfirm,
                amountDueUsd,
                isLoading: isSendInvoiceLoading,
                footerClassName,
                showAmountDue,
                primaryButtonText,
              }}
              defaultValues={defaultPhoneValues}
            />
          </div>
        </div>

        <CloseConfirmModal {...closeConfirmProps} />
      </>
    )
  },
)
