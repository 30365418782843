import { gql } from '../../../generated'

export const PREQUAL_RECORD_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription PrequalRecord($companyGuid: uuid!, $prequalRecordGuid: uuid!) {
    wisetackPrequalRecords(
      where: {
        companyGuid: { _eq: $companyGuid }
        wisetackPrequalRecordGuid: { _eq: $prequalRecordGuid }
      }
    ) {
      ...PrequalRecord
    }
  }
`)
