import { PermissionV2 } from '@breezy/shared'

import { SharedRoutes } from '@breezy/shared'
import { Navigate } from 'react-router-dom'
import AccountDetailsPage from '../pages/AccountDetailsPage/AccountDetailsPage'
import JobCreationPage from '../pages/JobCreationPage/JobCreationPage'
import JobDetailsPage from '../pages/JobDetailsPage/JobDetailsPage'
import LocationDetailsPage from '../pages/LocationDetailsPage/LocationDetailsPage'
import MaintenancePlanDetailsPage from '../pages/MaintenancePlanDetailsPage/MaintenancePlanDetailsPage'
import MaintenancePlanPayPage from '../pages/MaintenancePlanPayPage/MaintenancePlanPayPage'
import PaymentDetailsPage from '../pages/PaymentDetailsPage/PaymentDetailsPage'
import { ReactRouteMap } from './ReactRoute'

const noPermissionRequired = undefined

export const SharedReactRoutes = {
  JOB_CREATION: {
    ...SharedRoutes.JOB_CREATION,
    element: <JobCreationPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  JOB_DETAILS: {
    ...SharedRoutes.JOB_DETAILS,
    element: <JobDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  PAYMENT_DETAILS: {
    ...SharedRoutes.PAYMENT_DETAILS,
    element: <PaymentDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  MAINTENANCE_PLAN_PAYMENT: {
    ...SharedRoutes.MAINTENANCE_PLAN_PAYMENT,
    element: <MaintenancePlanPayPage />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
  },
  ACCOUNT_DETAILS: {
    ...SharedRoutes.ACCOUNT_DETAILS,
    element: <AccountDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  LOCATION_DETAILS: {
    ...SharedRoutes.LOCATION_DETAILS,
    element: <LocationDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  MAINTENANCE_PLAN_DETAILS: {
    ...SharedRoutes.MAINTENANCE_PLAN_DETAILS,
    element: <MaintenancePlanDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
} satisfies ReactRouteMap
