import { faLocationDot } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { blue6 } from '../../../../../themes/theme'
import { m } from '../../../../../utils/react-utils'

type CollapsibleLikeLinkProps = {
  readonly to: string
  readonly text: string
}

const CollapsibleLikeLink = m<CollapsibleLikeLinkProps>(({ to, text }) => {
  return (
    <div className={`w-full rounded-[8px] bg-white px-3 py-3 shadow-md`}>
      <Link to={to} className="semibold_14_22">
        <FontAwesomeIcon
          className="h-4 w-4"
          icon={faLocationDot}
          style={{ color: blue6, marginRight: 8 }}
        />{' '}
        {text}
      </Link>
    </div>
  )
})

export default CollapsibleLikeLink
