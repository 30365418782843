import cn from 'classnames'
import { ReactNode, RefObject } from 'react'
import { createPortal } from 'react-dom'
import { BzCloseButton } from '../../elements/BzCloseButton/BzCloseButton'
import useIsMobile from '../../hooks/useIsMobile'
import { Styled } from '../../utils/Stylable'

export type FullScreenModalProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children?: ReactNode
  containerRef?: RefObject<HTMLDivElement>
  zIndexClassName?: string
}

const FullScreenModal = ({
  isOpen,
  setIsOpen,
  children,
  className,
  containerRef,
  zIndexClassName = 'z-[1000]',
}: Styled<FullScreenModalProps>) => {
  const isMobile = useIsMobile()
  const closeButtonPaddingClassName = isMobile ? 'pt-3 pb-5' : 'p-9'
  const paddingClassName = isMobile ? 'py-3 px-5' : 'pt-[44px] pb-9 px-9'

  return createPortal(
    <div
      ref={containerRef}
      className={cn(
        'fixed bottom-0 left-0 right-0 top-0 h-screen w-screen overflow-auto bg-white',
        zIndexClassName,
        isOpen ? 'block' : 'hidden',
        paddingClassName,
        className,
      )}
    >
      <div
        className={cn(
          'fixed right-0 top-0 z-[100000]',
          closeButtonPaddingClassName,
        )}
      >
        <BzCloseButton onClose={() => setIsOpen(false)} />
      </div>

      {children}
    </div>,
    document.body,
  )
}

export default FullScreenModal
