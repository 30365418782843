import {
  AppointmentChecklistInstance,
  BzDateTime,
  ComprehensiveAppointmentDetails,
  formatName,
  MatchToShape,
  noOp,
} from '@breezy/shared'
import { faListCheck } from '@fortawesome/pro-light-svg-icons'
import { Divider } from 'antd'
import React, { useMemo } from 'react'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import LabeledItem from '../../../elements/LabeledItem/LabeledItem'
import { useFetchAppointment } from '../../../hooks/fetch/useFetchAppointment'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import AddressMultiLineView from '../../Addresses/AddressMultiLineView/AddressMultiLineView'
import { AppointmentChecklistInstanceForm } from '../../AppointmentChecklists/AppointmentChecklistInstanceForm'
import { LoadingSpinner } from '../../LoadingSpinner'

type ChecklistSectionProps = {
  instance: AppointmentChecklistInstance
}

const ChecklistSection = React.memo<ChecklistSectionProps>(({ instance }) => {
  const timeZoneId = useExpectedCompany().timezone
  const dateLabel = useMemo(() => {
    const date = BzDateTime.fromIsoString(instance.updatedAt, timeZoneId)
    return `${date.toDateFormat('MMM d, yyyy')} at ${date.toDateFormat(
      'h:mm a',
    )}`
  }, [instance.updatedAt, timeZoneId])

  return (
    <div>
      <h3>{instance.checklist.name}</h3>
      <div className="mb-4 grid grid-cols-2 gap-2">
        <LabeledItem title="Last edited by">
          {formatName(instance.lastEditor)}
        </LabeledItem>
        <LabeledItem title="Last edited on">{dateLabel}</LabeledItem>
      </div>
      <AppointmentChecklistInstanceForm items={instance.checklist.items} />
    </div>
  )
})

export type ChecklistsDrawerLoaderProps = {
  appointmentGuid: string
  jobGuid: string
  onClose: () => void
}

export const ChecklistsDrawerLoader = React.memo<ChecklistsDrawerLoaderProps>(
  ({ appointmentGuid, jobGuid, onClose }) => {
    const { data, isFetching } = useFetchAppointment({
      jobGuid,
      appointmentGuid,
    })
    const drawerItem = useMemo(() => ({ onCancel: onClose ?? noOp }), [onClose])

    return (
      <BzDrawer
        title="Checklist Details"
        icon={faListCheck}
        item={drawerItem}
        preferredWidth={720}
      >
        {!!data && <ChecklistViewSection appointment={data} />}
        {!data && isFetching && <LoadingSpinner />}
      </BzDrawer>
    )
  },
)

type ChecklistAppointment = MatchToShape<
  ComprehensiveAppointmentDetails,
  {
    appointmentGuid: true
    timeWindow: true
    appointmentType: true
    address: true
    appointmentChecklistInstances: true
  }
>

type ChecklistsDrawerProps = {
  appointment: ChecklistAppointment
  onClose: () => void
}

export const ChecklistsDrawer = React.memo<ChecklistsDrawerProps>(
  ({ appointment, onClose }) => {
    const drawerItem = useMemo(() => ({ onCancel: onClose }), [onClose])

    return (
      <BzDrawer
        title="Checklist Details"
        icon={faListCheck}
        item={drawerItem}
        preferredWidth={720}
      >
        <ChecklistViewSection appointment={appointment} />
      </BzDrawer>
    )
  },
)

type ChecklistViewSectionProps = {
  appointment: ChecklistAppointment
}

const ChecklistViewSection = React.memo<ChecklistViewSectionProps>(
  ({ appointment }) => {
    const timeZoneId = useExpectedCompany().timezone
    const appointmentDate = useMemo(() => {
      const date = BzDateTime.fromDate(
        appointment.timeWindow.startDate(),
        timeZoneId,
      )

      return `${date.toDateFormat('MMM d, yyy')} at ${date.toDateFormat(
        'h:mm a',
      )}`
    }, [appointment.timeWindow, timeZoneId])

    return (
      <div>
        <h3>General Information</h3>
        <div className="grid grid-cols-2 gap-2">
          <LabeledItem title="Appointment">
            {appointment.appointmentType} appointment on {appointmentDate}
          </LabeledItem>
          <LabeledItem title="Location">
            <AddressMultiLineView address={appointment.address} linkToMapApp />
          </LabeledItem>
        </div>
        <Divider />
        {appointment.appointmentChecklistInstances?.map(instance => (
          <ChecklistSection
            key={instance.appointmentChecklistInstanceGuid}
            instance={instance}
          />
        ))}
      </div>
    )
  },
)
