import { Account, AccountGuid } from '@breezy/shared'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { trpc } from '../trpc'

/** @deprecated use frontend hasura */
export const useFetchAccountByAccountGuidQuery = ({
  accountGuid,
  opts = {},
}: {
  accountGuid: AccountGuid
  opts?: {
    enabled?: boolean
    onSuccess?: (account: Account) => void
  }
}) => {
  return trpc.accounts['accounts:query'].useQuery(
    {
      type: 'by-account-guid',
      companyGuid: useExpectedCompany().companyGuid,
      accountGuid,
    },
    {
      select: data => {
        if (data.accounts.length !== 1) {
          throw new Error(`Failed to locate account by id = ${accountGuid}`)
        }
        return data.accounts[0]
      },
      enabled: opts.enabled,
      onSuccess: opts.onSuccess,
    },
  )
}
