import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsOfficeApp } from '../useIsOfficeApp'
import { useIsAuthorized } from './useIsAuthorized'

export const useCanViewOfficeAccount = () => {
  const isOfficeApp = useIsOfficeApp()
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    if (isOfficeApp) {
      return isAuthorized(PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW)
    }

    return true
  }, [isOfficeApp, isAuthorized])
}
