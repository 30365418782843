import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import cn from 'classnames'
import React, { useMemo } from 'react'
import Switch from '../../elements/Switch/Switch'
import useIsMobile from '../../hooks/useIsMobile'
import { useFinancingWizardContext } from './FinancingWizardDrawer'
import useIsMobileOrTablet from './hooks/useIsMobileOrTablet'

type FinancingWizardFooterProps = {
  onCancel: () => void
  onSubmit: () => void
  submitButtonText: string
  dataDdActionName?: string
  disabled?: boolean
  disablePrimaryButton?: boolean
  className?: string
}

const FinancingWizardFooter = React.memo<FinancingWizardFooterProps>(
  ({ className, ...rest }) => {
    const isMobileOrTablet = useIsMobileOrTablet()
    const isMobile = useIsMobile()
    const footerLayout = useMemo(
      () => (isMobileOrTablet ? 'condensed' : 'full'),
      [isMobileOrTablet],
    )

    return (
      <div
        className={cn(
          'flex flex-row justify-between border-0 border-t-4 border-solid border-t-bz-gray-400 py-4',
          'px-4 md:px-6',
          'absolute bottom-0 left-0 right-0 z-10 bg-white',
          {
            'pb-7': isMobile,
            '-mx-6': !isMobile,
            '-mx-4': isMobile,
          },
          className,
        )}
      >
        <Switch value={footerLayout}>
          {{
            full: () => <FullFooter {...rest} />,
            condensed: () => <CondensedFooter {...rest} />,
            default: () => null,
          }}
        </Switch>
      </div>
    )
  },
)

const CondensedFooter = React.memo<FinancingWizardFooterProps>(
  ({
    onCancel,
    disabled = false,
    disablePrimaryButton = false,
    submitButtonText,
    onSubmit,
    dataDdActionName,
  }) => {
    return (
      <div className="flex flex-1 flex-row space-x-2">
        <Button
          className="flex-1 rounded border-solid border-bz-gray-600 font-semibold text-bz-gray-800"
          onClick={onCancel}
          disabled={disabled}
          size="large"
          data-dd-action-name="bz-financing-cancel-button-click"
        >
          Cancel
        </Button>
        <Button
          className="flex-1 rounded font-semibold"
          type="primary"
          size="large"
          htmlType="submit"
          onClick={onSubmit}
          data-dd-action-name={dataDdActionName}
          disabled={disablePrimaryButton || disabled}
        >
          {submitButtonText}
        </Button>
      </div>
    )
  },
)

const FullFooter = React.memo<FinancingWizardFooterProps>(
  ({
    onCancel,
    disabled = false,
    disablePrimaryButton = false,
    onSubmit,
    submitButtonText,
    dataDdActionName,
  }) => {
    const { moveBack, canGoBack } = useFinancingWizardContext()

    return (
      <>
        <div className="flex-1">
          <Button
            className="rounded border-solid border-bz-gray-600 font-semibold text-bz-gray-800"
            onClick={onCancel}
            disabled={disabled}
            data-dd-action-name="bz-financing-cancel-button-click"
            size="large"
          >
            Cancel
          </Button>
        </div>
        <div className="flex flex-row justify-end gap-3">
          {canGoBack && (
            <Button
              className="rounded border-solid border-bz-gray-600 font-semibold text-bz-gray-800"
              onClick={moveBack}
              disabled={disabled}
              data-dd-action-name="bz-financing-go-back-button-click"
              icon={
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-bz-gray-800"
                />
              }
              size="large"
            >
              Go Back
            </Button>
          )}
          <Button
            className="rounded font-semibold"
            type="primary"
            size="large"
            htmlType="submit"
            onClick={onSubmit}
            data-dd-action-name={dataDdActionName}
            disabled={disablePrimaryButton || disabled}
          >
            {submitButtonText}
          </Button>
        </div>
      </>
    )
  },
)

export default FinancingWizardFooter
