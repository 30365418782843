import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { CompanyGuidContainer, ForCompany, ForCompanyUser } from '../Company/Company'
import { Guid, bzOptional, dataFilterConfigSchema } from '../common-schemas'

export const JOB_LIFECYCLE_STAGES = ['Opportunity', 'Upcoming', 'Ongoing', 'Won', 'Closed'] as const

// Statuses we need to be able to identify even if the user changes their names.
export const SPECIAL_LIFECYCLE_STATUSES = ['Canceled', 'Closed - Lost', 'Completed'] as const

// A subset of the above statuses that are considered "closed" statuses.
export const CLOSED_STATUSES = ['Canceled', 'Closed - Lost', 'Completed'] as const

export type SpecialLifecycleStatus = (typeof SPECIAL_LIFECYCLE_STATUSES)[number]

export type JobLifecycleStage = (typeof JOB_LIFECYCLE_STAGES)[number]

export const jobLifecycleStatusSchema = z.object({
  jobLifecycleStatusGuid: guidSchema,
  name: z.string(),
  description: z.string(),
  stage: z.enum(JOB_LIFECYCLE_STAGES),
  color: z.string(),
  isDefault: z.boolean(),
  isWorkComplete: z.boolean(),
  isLeadWon: z.boolean(),
  isFirstAppointmentBookedAutomationEnabled: z.boolean(),
  isAllOutstandingAppointmentsAssignedAutomationEnabled: z.boolean(),
  isTechnicianDispatchedAutomationEnabled: z.boolean(),
  isTechnicianArrivesAtAppointmentAutomationEnabled: z.boolean(),
  isAllAppointmentsCompletedAutomationEnabled: z.boolean(),
  isCreateLinkedJobAutomationEnabled: z.boolean(),
  isNoAdditionalWorkRequiredAutomationEnabled: z.boolean(),
  specialStatus: bzOptional(z.enum(SPECIAL_LIFECYCLE_STATUSES)),
  jobCount: bzOptional(z.number()),
})

export type JobLifecycleStatusWithoutLifecycleGuid = z.infer<typeof jobLifecycleStatusSchema>
export type JobLifecycleStatus = JobLifecycleStatusWithoutLifecycleGuid & {
  jobLifecycleGuid: string
}

export const JOB_LIFECYCLES_TYPES = ['JOB', 'SALES'] as const

export type JobLifecycleType = (typeof JOB_LIFECYCLES_TYPES)[number]

export const upsertJobLifecycleRequestSchema = z.object({
  jobLifecycleGuid: guidSchema,
  name: z.string(),
  description: z.string(),
  statuses: z.array(jobLifecycleStatusSchema),
  type: z.enum(JOB_LIFECYCLES_TYPES),
  deletedStatuses: bzOptional(
    z.array(
      z.object({
        jobLifecycleStatusGuidToDelete: guidSchema,
        jobLifecycleStatusGuidToTransitionTo: bzOptional(guidSchema),
      }),
    ),
  ),
})

export type UpsertJobLifecycleRequest = z.infer<typeof upsertJobLifecycleRequestSchema>

export type JobLifecycleUpserter = AsyncFn<ForCompanyUser<UpsertJobLifecycleRequest>>

export type JobLifecycle = UpsertJobLifecycleRequest & {
  lastModifiedBy: string
  createdAt: string
  updatedAt: string
  companyGuid: string
  isArchived: boolean
  statuses: JobLifecycleStatus[]
}

export type JobLifecycleReader = AsyncFn<CompanyGuidContainer, JobLifecycle[]>

export const jobLifecycleStatusChangerSchema = z.object({
  jobGuid: guidSchema,
  jobLifecycleStatusGuid: guidSchema,
  updatedAt: bzOptional(isoDateStringSchema), // Will back-date it if present
})

export type JobLifecycleStatusChangerRequest = z.infer<typeof jobLifecycleStatusChangerSchema>

export type JobLifecycleStatusChanger = AsyncFn<ForCompanyUser<JobLifecycleStatusChangerRequest>>

export const jobLifecycleViewGuidSchema = z.object({
  jobLifecycleViewGuid: guidSchema,
})

export type JobLifecycleViewGuidContainer = z.infer<typeof jobLifecycleViewGuidSchema>

export const jobsViewSchema = z.object({
  lifecycleName: bzOptional(z.string()),
  filters: bzOptional(dataFilterConfigSchema.array()),
  filtersV2: bzOptional(z.string()),
})

export type JobsView = z.infer<typeof jobsViewSchema>

export const jobLifecycleViewSchema = jobLifecycleViewGuidSchema.extend({
  view: jobsViewSchema,
  title: z.string(),
})

export type JobLifecycleView = z.infer<typeof jobLifecycleViewSchema>

export type JobLifecycleViewReader = AsyncFn<CompanyGuidContainer, JobLifecycleView[]>

export type JobLifecycleViewWriter = AsyncFn<ForCompany<JobLifecycleView>>

export type JobLifecycleViewDeleter = AsyncFn<ForCompany<JobLifecycleViewGuidContainer>>

export const jobLifecycleDeleterInputSchema = z.object({
  jobLifecycle: z.object({
    fromJobLifecycleGuid: guidSchema,
    toJobLifecycleGuid: guidSchema,
  }),
  jobLifecycleStatuses: z
    .object({
      fromJobLifecycleStatusGuid: guidSchema,
      toJobLifecycleStatusGuid: guidSchema,
    })
    .array(),
})

export type JobLifecycleDeleterInput = ForCompany<z.infer<typeof jobLifecycleDeleterInputSchema>>

export type JobLifecycleDeleter = AsyncFn<JobLifecycleDeleterInput>

type AutomationToStatusLookup = {
  isFirstAppointmentBookedAutomationEnabled?: Guid
  isAllOutstandingAppointmentsAssignedAutomationEnabled?: Guid
  isTechnicianDispatchedAutomationEnabled?: Guid
  isTechnicianArrivesAtAppointmentAutomationEnabled?: Guid
  isAllAppointmentsCompletedAutomationEnabled?: Guid
  isNoAdditionalWorkRequiredAutomationEnabled?: Guid
}

export const getAutomationToStatusLookup = (lifecycle: JobLifecycle) =>
  lifecycle.statuses.reduce((memo, nextStatus) => {
    if (nextStatus.isFirstAppointmentBookedAutomationEnabled) {
      memo.isFirstAppointmentBookedAutomationEnabled = nextStatus.jobLifecycleStatusGuid
    }
    if (nextStatus.isAllOutstandingAppointmentsAssignedAutomationEnabled) {
      memo.isAllOutstandingAppointmentsAssignedAutomationEnabled = nextStatus.jobLifecycleStatusGuid
    }
    if (nextStatus.isTechnicianDispatchedAutomationEnabled) {
      memo.isTechnicianDispatchedAutomationEnabled = nextStatus.jobLifecycleStatusGuid
    }
    if (nextStatus.isTechnicianArrivesAtAppointmentAutomationEnabled) {
      memo.isTechnicianArrivesAtAppointmentAutomationEnabled = nextStatus.jobLifecycleStatusGuid
    }
    if (nextStatus.isAllAppointmentsCompletedAutomationEnabled) {
      memo.isAllAppointmentsCompletedAutomationEnabled = nextStatus.jobLifecycleStatusGuid
    }
    if (nextStatus.isNoAdditionalWorkRequiredAutomationEnabled) {
      memo.isNoAdditionalWorkRequiredAutomationEnabled = nextStatus.jobLifecycleStatusGuid
    }

    return memo
  }, {} as AutomationToStatusLookup)
