import { message } from 'antd'

type BaseBlobOperationProps = {
  getBlob: () => Promise<Blob>
  operationText: string
  onStarted?: () => void
  onFinished?: () => void
}

type DownloadBlobOperationProps = BaseBlobOperationProps & {
  downloadFilename: string
}

export const useOpenBlobInNewTab =
  () =>
  async ({
    getBlob,
    operationText,
    onStarted,
    onFinished,
  }: BaseBlobOperationProps) => {
    try {
      onStarted?.()
      const blob = await getBlob()
      const blobUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.target = '_blank'
      link.click()
    } catch (e) {
      console.error(`There was an error ${operationText}`, e)
      message.error(`Failed to ${operationText}`)
    } finally {
      onFinished?.()
    }
  }

export const useDownloadBlob =
  () =>
  async ({
    getBlob,
    downloadFilename,
    operationText,
    onStarted,
    onFinished,
  }: DownloadBlobOperationProps) => {
    try {
      onStarted?.()

      const blob = await getBlob()
      const blobUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = downloadFilename
      link.click()
    } catch (e) {
      console.error(`There was an error ${operationText}`, e)
      message.error(`Failed to ${operationText}}`)
    } finally {
      onFinished?.()
    }
  }
