import { JobClass } from '../Job'

export const JOB_CLASSES_THAT_TRIGGER_MAINTENANCE_REMINDER_CAMPAIGN = [
  JobClass.INSTALL,
  JobClass.SERVICE,
  JobClass.MAINTENANCE,
]

type MaintenanceReminderEmailTemplateParameters = {
  bodyContent: string
  customerName: string
  signOff: string
}

export const generateMaintenanceReminderEmailContent = (params: MaintenanceReminderEmailTemplateParameters) => {
  return `
    <p>Hello ${params.customerName},</p>
    ${params.bodyContent}
    <p>Warm regards,</p>
    <p>${params.signOff}</p>
  `
}

export const DEFAULT_MAINTENANCE_REMINDER_CUSTOM_EMAIL_CONTENT =
  `<p>As part of our commitment to ensuring your comfort and the longevity of your HVAC system, we recommend scheduling a maintenance visit or tune-up. Regular maintenance helps prevent unexpected breakdowns, improves system efficiency, and extends the life of your HVAC equipment.</p>` +
  `<p><u>Why Schedule Now?</u> Avoid the rush and ensure your system is prepared for the upcoming season. Our expert technicians will thoroughly inspect your system, perform necessary adjustments, and replace any worn components.</p>` +
  `<p>To schedule your maintenance visit, simply reply contact our support team at the information provided below. We're here to help ensure your comfort and satisfaction.</p>`
