import { BasicAccount, getDisplayNameForAccountType } from '@breezy/shared'
import useAppNavigation from '../../../hooks/useAppNav'
import { AccountIcon } from '../../../utils/feature-icons'
import { m } from '../../../utils/react-utils'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type AccountCollapsibleProps = {
  readonly account: BasicAccount
}

const AccountCollapsible = m<AccountCollapsibleProps>(({ account }) => {
  const appNav = useAppNavigation()
  const { accountGuid, displayName, type } = account

  return (
    <BzCollapsible title="Account" titleIcon={AccountIcon}>
      <CollapsibleItem
        title={displayName}
        onTitleClick={() => appNav.navigateToAccountDetailsPage(accountGuid)}
        contentList={[
          { key: 'Type', value: getDisplayNameForAccountType(type) },
        ]}
        alwaysOpen
      />
    </BzCollapsible>
  )
})

export default AccountCollapsible
