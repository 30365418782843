import { isNullish } from '@breezy/shared'
import {
  faChevronLeft,
  faCircleInfo,
  faClose,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { createPortal } from 'react-dom'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useIOSScrollingFix } from '../../utils/ios-fixes'
import { useModalState } from '../../utils/react-utils'
import {
  OnsiteEmbeddedContext,
  useGoBack,
  useOnsitePageDimensions,
} from './onsitePageUtils'

export type OnsitePageContainerProps = React.PropsWithChildren<{
  statusText?: string
  statusColorClassName?: string
  title?: React.ReactNode
  spaceForNav?: boolean
  containerRef?: React.RefObject<HTMLDivElement>
  info?: React.ReactNode
  infoHeader?: string
  closeInsteadOfBack?: boolean
  className?: string
}>

export const OnsitePageContainer = React.memo<OnsitePageContainerProps>(
  ({
    children,
    statusText,
    title,
    statusColorClassName = 'bg-bz-gray-400 text-bz-gray-900',
    containerRef,
    spaceForNav = true,
    info,
    infoHeader = 'Info',
    closeInsteadOfBack,
    className,
  }) => {
    const { embedded } = useContext(OnsiteEmbeddedContext)
    const goBack = useGoBack()

    useIOSScrollingFix(containerRef?.current)

    const pageDimensions = useOnsitePageDimensions()

    const [infoOpen, openInfo, closeInfo] = useModalState()

    const content = (
      <div
        className={classNames(
          'flex max-h-full flex-col text-base text-bz-gray-1000',
          embedded ? 'relative w-full' : 'fixed inset-x-0 z-[1001] bg-white',
          spaceForNav && !embedded ? `bottom-[72px] top-0` : 'inset-y-0 h-full',
          {
            'border-0 border-b border-solid border-bz-gray-500': !embedded,
            'pb-4': embedded,
          },
          className,
        )}
      >
        <div
          className={classNames(
            'relative mx-4 flex h-9 items-center justify-center text-center text-lg font-semibold',
            embedded ? 'mb-2 mt-6' : 'my-2',
          )}
        >
          {title}
          {!embedded && (
            <Button
              className="back-button absolute left-0 flex h-9 w-9 items-center justify-center text-lg text-bz-gray-800"
              shape="circle"
              onClick={goBack}
              icon={
                <FontAwesomeIcon
                  icon={closeInsteadOfBack ? faClose : faChevronLeft}
                />
              }
            />
          )}
          {info && (
            <>
              <Button
                className="absolute right-0 flex h-9 w-9 items-center justify-center text-lg text-bz-gray-800"
                shape="circle"
                onClick={openInfo}
                icon={<FontAwesomeIcon icon={faCircleInfo} />}
              />
              <OnsiteBasicModal
                drawer
                headerBordered
                open={infoOpen}
                header={infoHeader}
                onClose={closeInfo}
              >
                {info}
              </OnsiteBasicModal>
            </>
          )}
        </div>
        {!isNullish(statusText) && (
          <div
            data-testid="status-bar"
            className={classNames(
              'py-1 text-center text-sm font-semibold',
              statusColorClassName,
            )}
          >
            {statusText}
          </div>
        )}
        <div
          className="relative flex-1 overflow-y-auto overflow-x-hidden"
          ref={containerRef}
        >
          <div
            className="mx-auto flex min-h-0 flex-1 flex-col"
            style={pageDimensions.style}
          >
            {children}
          </div>
        </div>
      </div>
    )

    if (embedded) {
      return content
    }
    return createPortal(content, document.body)
  },
)
