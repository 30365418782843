import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsTechnicianApp } from '../useIsTechnicianApp'
import { useIsAuthorized } from './useIsAuthorized'

export const useCanManageSchedule = () => {
  const isAuthorized = useIsAuthorized()
  const isTechnicianApp = useIsTechnicianApp()

  return useMemo(() => {
    if (isTechnicianApp) {
      return isAuthorized(PermissionV2.FIELD_SCHEDULE_CREATE)
    } else {
      return isAuthorized(PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE)
    }
  }, [isAuthorized, isTechnicianApp])
}
