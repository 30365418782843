import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'

type StatusType = 'success' | 'error' | 'processing'

type StatusCardProps = {
  type: StatusType
  icon: IconDefinition
  title: string
  description?: string
  children: React.ReactNode
  footer?: React.ReactNode
  className?: string
}

const statusStyles: Record<
  StatusType,
  { bg: string; iconBg: string; iconColor: string }
> = {
  success: {
    bg: 'bg-daybreak-blue-100',
    iconBg: 'bg-daybreak-blue-200',
    iconColor: 'text-[#1677FF]',
  },
  error: {
    bg: 'bg-bz-red-100',
    iconBg: 'bg-bz-red-200',
    iconColor: 'text-bz-red-600',
  },
  processing: {
    bg: 'bg-bz-orange-100',
    iconBg: 'bg-bz-orange-200',
    iconColor: 'text-bz-orange-800',
  },
}

export const StatusCard = React.memo<StatusCardProps>(
  ({ type, icon, title, description, children, footer, className }) => {
    const { bg, iconBg, iconColor } = useMemo(() => statusStyles[type], [type])

    return (
      <div
        className={classNames(
          'flex flex-col items-center transition-all ease-in-out',
          className,
        )}
      >
        <div className={`rounded-full ${bg} mb-2 p-2`}>
          <div
            className={`flex h-[56px] w-[56px] items-center justify-center rounded-full ${iconBg} p-2`}
          >
            <FontAwesomeIcon
              className={`h-[32px] w-[32px] ${iconColor}`}
              icon={icon}
            />
          </div>
        </div>
        <div className="mb-2 text-[30px] font-semibold">{title}</div>
        <div className="mb-4 text-center text-[16px]">{description}</div>
        <StatusDetailContainer type={type}>{children}</StatusDetailContainer>
        {footer}
      </div>
    )
  },
)

type StatusDetailContainerProps = {
  type: StatusType
  className?: string
}

const statusDetailContainerStyles = {
  success: 'border-bz-gray-500 bg-bz-gray-200 divide-bz-gray-500',
  error: 'border-bz-red-200 bg-bz-red-100 divide-bz-red-200',
  processing: 'border-bz-gray-500 bg-bz-gray-200 divide-bz-gray-500',
}

const StatusDetailContainer = React.memo<
  React.PropsWithChildren<StatusDetailContainerProps>
>(({ type, className, children }) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-col divide-x-0 divide-y divide-dashed rounded-lg border border-solid p-3',
        statusDetailContainerStyles[type],
        '[&>*:first-child]:pt-0', // Remove top padding from the first child
        '[&>*:last-child]:pb-0', // This targets the last child and removes its bottom padding
        className,
      )}
    >
      {children}
    </div>
  )
})
