import {
  BzDateTime,
  Guid,
  LoanRecord,
  LoanStatusDisplayNames,
  PrequalRecord,
  PrequalStatusDisplayNames,
  R,
  formatLoanAmount,
  formatPrequalAmount,
  getFinancingRecordType,
  isLoanRecord,
  isPrequalRecord,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { useQuery } from 'urql'
import { EmDash } from '../../../elements/EmDash/EmDash'
import Switch from '../../../elements/Switch/Switch'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import LoanDetailsLink from '../../Financing/LoanDetailsLink/LoanDetailsLink'
import LoanStatusTag from '../../Financing/LoanStatusTag/LoanStatusTag'
import { WisetackLogo } from '../../Financing/WisetackLogo'
import { useFinancingWizard } from '../../Financing/hooks/useFinancingWizard'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import PrequalStatusTag from '../../PrequalStatusTag/PrequalStatusTag'
import { PREQUAL_CONTACT_NAME_QUERY } from './PrequalContactName.gql'

type WisetackFinancingCollapsibleProps = {
  accountGuid: Guid
  jobGuid?: string
  loanRecords: LoanRecord[]
  prequalRecords?: PrequalRecord[]
  allowCreate?: boolean
  refetch?: () => void
}

const WisetackFinancingCollapsible =
  React.memo<WisetackFinancingCollapsibleProps>(
    ({
      accountGuid,
      jobGuid,
      loanRecords,
      prequalRecords = [],
      allowCreate = false,
      refetch,
    }) => {
      const sortedMergedRecords = useMemo(
        () =>
          R.sort(R.descend(R.prop('updatedAt')), [
            ...prequalRecords,
            ...loanRecords,
          ]),
        [prequalRecords, loanRecords],
      )

      const { showFinancingWizard, financingWizard } = useFinancingWizard({
        accountGuid,
        jobGuid,
        onCancel: refetch,
      })

      return (
        <>
          <BzCollapsible
            title="Wisetack Financing"
            titleIcon={<WisetackLogo size="sm" />}
            onPlus={allowCreate ? showFinancingWizard : undefined}
          >
            {sortedMergedRecords.map((record, i) => {
              const recordType = getFinancingRecordType(record)
              return (
                <Switch value={recordType} key={i}>
                  {{
                    prequal: () =>
                      isPrequalRecord(record) ? (
                        <PrequalRecordItem prequalRecord={record} />
                      ) : null,
                    loan: () =>
                      isLoanRecord(record) ? (
                        <LoanRecordItem
                          key={record.loanRecordGuid}
                          loanRecord={record}
                        />
                      ) : null,
                    default: () => null,
                  }}
                </Switch>
              )
            })}
          </BzCollapsible>
          {financingWizard}
        </>
      )
    },
  )

const PrequalRecordItem = ({
  prequalRecord,
}: {
  prequalRecord: PrequalRecord
}) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const prequalContactNameQuery = useQuery({
    query: PREQUAL_CONTACT_NAME_QUERY,
    variables: {
      where: {
        wisetackPrequalRecordGuid: { _eq: prequalRecord.prequalRecordGuid },
      },
    },
  })
  return (
    <GqlQueryLoader
      query={prequalContactNameQuery}
      render={data => (
        <CollapsibleItem
          key={prequalRecord.prequalRecordGuid}
          alwaysOpen={true}
          title={
            <LoanDetailsLink
              record={prequalRecord}
              text="Pre-qualification"
              className="font-semibold"
            />
          }
          tag={<PrequalStatusTag status={prequalRecord.status} />}
          contentList={[
            {
              key: 'Name',
              value: (
                <div>
                  {data.wisetackPrequalRecords[0].contact?.fullName ?? (
                    <EmDash />
                  )}
                </div>
              ),
            },
            {
              key: 'Updated',
              value: (
                <div>
                  {BzDateTime.fromIsoString(
                    prequalRecord.updatedAt,
                    tzId,
                  ).toDateFormat('MMMM dd, yyyy')}
                </div>
              ),
            },
            {
              key: 'Status',
              value: PrequalStatusDisplayNames[prequalRecord.status],
            },
            {
              key: 'Amount',
              value: formatPrequalAmount(prequalRecord),
            },
          ]}
        />
      )}
    />
  )
}

const LoanRecordItem = ({ loanRecord }: { loanRecord: LoanRecord }) => {
  const tzId = useExpectedCompanyTimeZoneId()

  return (
    <CollapsibleItem
      key={loanRecord.loanRecordGuid}
      alwaysOpen={true}
      title={
        <LoanDetailsLink
          record={loanRecord}
          text="Loan Application"
          className="font-semibold"
        />
      }
      tag={
        <LoanStatusTag status={loanRecord.latestLoanRecordStatus.loanStatus} />
      }
      contentList={[
        {
          key: 'Name',
          value: <div>{loanRecord.contactName}</div>,
        },
        {
          key: 'Updated',
          value: (
            <div>
              {BzDateTime.fromIsoString(
                loanRecord.updatedAt,
                tzId,
              ).toDateFormat('MMMM dd, yyyy')}
            </div>
          ),
        },
        {
          key: 'Status',
          value:
            LoanStatusDisplayNames[
              loanRecord.latestLoanRecordStatus.loanStatus
            ],
        },
        {
          key: 'Amount',
          value: formatLoanAmount(loanRecord),
        },
      ]}
    />
  )
}

export default WisetackFinancingCollapsible
