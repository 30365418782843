import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

type BzCloseButtonProps = {
  readonly onClose: () => void
  readonly onBack?: () => void
  readonly disabled?: boolean
  readonly dataTestId?: string
}

export const BzCloseButton = ({
  onClose,
  onBack,
  disabled,
  dataTestId,
}: BzCloseButtonProps) => {
  return (
    <Button
      data-testid={dataTestId ?? 'modal-close-button'}
      className="border-color-[#d8d8d8] flex h-10 w-10 items-center justify-center text-[16px] text-[#4f4f4f] shadow-none hover:border-[#1890FF] hover:bg-[#E6F7FF] hover:text-[#1890FF]"
      shape="circle"
      disabled={disabled}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        ;(onBack ?? onClose)?.()
      }}
    >
      <FontAwesomeIcon icon={onBack ? faChevronLeft : faXmark} />
    </Button>
  )
}
