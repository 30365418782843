import { AppointmentGuid } from '@breezy/shared'
import React, { useCallback, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { z } from 'zod'
import { useStrictContext } from '../../utils/react-utils'

type DismissedMap = Record<AppointmentGuid, boolean>

type PersistedDismissedRunningLateAlertsContextType = {
  dismissedMap: DismissedMap
  dismiss: (appointmentGuid: AppointmentGuid) => void
}

export const PersistedDismissedRunningLateAlertsContext = React.createContext<
  PersistedDismissedRunningLateAlertsContextType | undefined
>(undefined)

const RUNNING_LATE_DISMISSED_ALERTS_KEY =
  'schedule-running-late-dismissed-alerts'

export const PersistedDismissedRunningLateAlertsContextProvider =
  React.memo<React.PropsWithChildren>(({ children }) => {
    const [dismissedMapLS, setDismissedMapLS] = useLocalStorage<string>(
      RUNNING_LATE_DISMISSED_ALERTS_KEY,
      '{}',
    )

    const [dismissedMapLocal, setDismissedMapLocal] = useState<DismissedMap>(
      () => {
        try {
          return z.record(z.boolean()).parse(JSON.parse(dismissedMapLS ?? '{}'))
        } catch {
          return {}
        }
      },
    )

    const dismiss = useCallback(
      (appointmentGuid: AppointmentGuid) => {
        const newMap = { ...dismissedMapLocal, [appointmentGuid]: true }
        setDismissedMapLocal(newMap)
        setDismissedMapLS(JSON.stringify(newMap))
      },
      [dismissedMapLocal, setDismissedMapLS],
    )

    return (
      <PersistedDismissedRunningLateAlertsContext.Provider
        value={{ dismissedMap: dismissedMapLocal, dismiss }}
      >
        {children}
      </PersistedDismissedRunningLateAlertsContext.Provider>
    )
  })

export const usePersistedDismissedRunningLateAlerts = (
  appointmentGuid: AppointmentGuid,
) => {
  const { dismiss: contextDismiss, dismissedMap } = useStrictContext(
    PersistedDismissedRunningLateAlertsContext,
  )

  const dismiss = useCallback(() => {
    contextDismiss(appointmentGuid)
  }, [appointmentGuid, contextDismiss])

  return { isDismissed: !!dismissedMap[appointmentGuid], dismiss }
}
