import { PaymentMethod } from '@breezy/shared'
import { faCreditCard } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'antd'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useTilled from '../../../hooks/useTilled'
import { useExpectedMerchantId } from '../../../providers/PrincipalUser'
import { dustRed6 } from '../../../themes/theme'
import { TilledFormFieldChangeHandler } from '../../../utils/tilledSdkTypes'
import { LoadingSpinner } from '../../LoadingSpinner'
import PayButton from '../PayButton'
import PaymentCardPanel from '../PaymentCardPanel'
import { PaymentMethodFormProps } from '../PaymentTypes'
import { tilledFieldOptions, tilledFormFieldStyle } from './TilledStyles'
import { useTilledCreatePaymentMethod } from './useTilledCreatePaymentMethod'

// TODO: MP - Refactor This Whole Thing When Building the UI
const paymentMethod = PaymentMethod.CARD

const CardCreatePaymentMethodForm = React.memo<PaymentMethodFormProps>(
  ({ onSuccess, paymentContext }) => {
    const merchantAccountId = useExpectedMerchantId()
    const {
      register,
      formState: { errors },
      handleSubmit,
      getValues,
    } = useForm()
    const [validFields, setValidFields] = useState<Set<string>>(new Set())
    const [paymentSubmissionError, setPaymentSubmissionError] = useState<
      string | undefined
    >(undefined)
    const cardStyle = paymentSubmissionError
      ? { border: `2px solid ${dustRed6}` }
      : {}
    const readyToSubmitPayment =
      validFields.size === 3 &&
      validFields.has('cardNumber') &&
      validFields.has('cardExpiry') &&
      validFields.has('cardCvv')
    const onTilledFieldChange: TilledFormFieldChangeHandler = event => {
      if (event.valid)
        setValidFields(prev => new Set(prev).add(event.fieldType))
      else
        setValidFields(prev => {
          const s = new Set(prev)
          s.delete(event.fieldType)
          return s
        })
    }

    const tilledLoader = useTilled(
      merchantAccountId,
      {
        type: 'card',
        fields: {
          cardNumber: { selector: '#card-number-element' },
          cardExpiry: { selector: '#card-expiration-element' },
          cardCvv: { selector: '#card-cvv-element' },
        },
      },
      onTilledFieldChange,
      tilledFieldOptions,
    )

    const tilledRequestContext = tilledLoader.value

    const submit = useTilledCreatePaymentMethod({
      paymentContext,
      tilled: tilledRequestContext?.tilled,
      paymentMethodType: 'card',
      getAchDebitDetails: undefined,
      onPaymentSubmissionError: setPaymentSubmissionError,
      onSuccess: (
        paymentMethodId: string,
        paymentMethodAdditionalInfo: string,
      ) =>
        onSuccess(paymentMethodId, paymentMethodAdditionalInfo, paymentMethod),
    })

    if (tilledLoader.error)
      return <div>Failed to load Tilled. Use another Payment Method</div>

    if (tilledLoader.loading)
      return (
        <div className="card-no-fixed-height center-h h-24 w-[320px] max-w-[500px]">
          <LoadingSpinner />
        </div>
      )

    if (Object.entries(errors).length > 0)
      console.warn({
        reactFormValues: getValues(),
        errors,
        tilledForm: tilledRequestContext?.form,
      })

    return (
      <>
        <form className="credit-card_form" onSubmit={handleSubmit(submit)}>
          <PaymentCardPanel style={cardStyle}>
            <div className="pr-2">
              <Row className="center-h center-children-vh mb-2">
                <FontAwesomeIcon
                  icon={faCreditCard}
                  className="center-v mr-4"
                  style={{ fontSize: 24 }}
                />
                <h2 className="m-0">Credit Card Payment</h2>
              </Row>
              {paymentSubmissionError && (
                <div className="error-red my-2 p-2 text-center">
                  <b>Payment Error:</b>
                  <br />
                  {paymentSubmissionError}
                </div>
              )}
              <label className="flex flex-col p-2">
                <div style={{ marginBottom: 8 }} className="required">
                  Card Number
                </div>
                <div>
                  <div
                    className="tilled-js_input max-width-max ml-2 flex justify-between rounded-md border p-2"
                    style={tilledFormFieldStyle}
                  >
                    <div
                      id="card-number-element"
                      {...register('cardNumber', { maxLength: 15 })}
                    />
                    <div id="card-brand-icon" />
                  </div>
                  {errors.cardNumber?.type === 'required' &&
                    'Card Number is required'}
                </div>
              </label>
              <div className="grid grid-cols-2">
                <label className="flex flex-col p-2">
                  <div style={{ marginBottom: 8 }} className="required">
                    Expiration
                  </div>
                  <div
                    className="tilled-js_input ml-2 rounded-md border"
                    id="card-expiration-element"
                    style={tilledFormFieldStyle}
                    {...register('expiration', { maxLength: 5 })}
                  />
                  {errors.expiration?.type === 'required' &&
                    'Expiration is required'}
                </label>
                <label className="flex flex-col p-2">
                  <div style={{ marginBottom: 8 }} className="required">
                    CVV
                  </div>
                  <div
                    className="tilled-js_input ml-2 rounded-md border"
                    id="card-cvv-element"
                    style={tilledFormFieldStyle}
                    {...register('cvv', { maxLength: 3 })}
                  />
                  {errors.cvv?.type === 'required' && 'cvv is required'}
                </label>
              </div>
              <PayButton
                readyToSubmitPayment={readyToSubmitPayment}
                submit={submit}
              />
            </div>
          </PaymentCardPanel>
        </form>
      </>
    )
  },
)

export default CardCreatePaymentMethodForm
