import {
  AmountUscContainer,
  LoanPromoResponse,
  MIN_FINANCEABLE_AMOUNT_USC,
} from '@breezy/shared'
import React from 'react'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import useIsMobile from '../../../hooks/useIsMobile'
import { useLoanPromo } from '../../../hooks/useLoanPromo'
import { m } from '../../../utils/react-utils'
import TrpcQueryLoader from '../../TrpcQueryLoader'

interface LinkifyProps {
  children: string
}

const Linkify = m<LinkifyProps>(({ children }) => {
  const isUrl = (word: string) => {
    const urlPattern =
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
    return word.match(urlPattern)
  }

  const addMarkup = (word: string) => {
    return isUrl(word)
      ? `<a class="grey8 underline" href="${word}">${word}</a>`
      : word
  }

  const words = children.split(' ')
  const formatedWords = words.map((w, i) => addMarkup(w))
  const html = formatedWords.join(' ')
  return (
    <span
      className="regular_12_16 grey8 text-[12px]"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
})

const LoanPromoDisclaimer = m<Pick<LoanPromoResponse, 'disclosure'>>(
  ({ disclosure }) => (
    <div className="regular_12_16 grey8 my-4 text-[12px]">
      <Linkify>{disclosure}</Linkify>
    </div>
  ),
)

const LoanPromoDisclaimerLoader = m<Partial<AmountUscContainer>>(
  ({ amountUsc }) => {
    const { shouldShowLoanPromo, query } = useLoanPromo({
      amountUsc: amountUsc ?? MIN_FINANCEABLE_AMOUNT_USC,
    })

    return shouldShowLoanPromo ? (
      <TrpcQueryLoader
        query={query}
        render={data => <LoanPromoDisclaimer {...data} />}
      />
    ) : null
  },
)

type LoanPromoShortDisclaimerProps = {
  amountUsc: number | undefined
  className?: string
}
export const LoanPromoShortDisclaimer =
  React.memo<LoanPromoShortDisclaimerProps>(({ amountUsc, className }) => {
    const { shouldShowLoanPromo } = useLoanPromo({
      amountUsc: amountUsc ?? MIN_FINANCEABLE_AMOUNT_USC,
    })
    const isMobile = useIsMobile()
    return (
      <RenderIf if={!!shouldShowLoanPromo}>
        <HtmlRenderer
          htmlContent={`<div class="regular_12_16 grey8 ${
            isMobile ? 'my-3' : 'my-4'
          }  ${className} text-[12px]">*All financing is subject to credit approval. Your terms may vary. Payment options through Wisetack are provided by our <a class="grey8 underline" href="https://www.wisetack.com/lenders">lending partners</a>. See <a class="grey8 underline" href="http://wisetack.com/faqs">http://wisetack.com/faqs</a>.</div>`}
        />
      </RenderIf>
    )
  })

export default LoanPromoDisclaimerLoader
