import { gql } from '../../../generated'

export const INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription InvoiceCollapsibleData($where: InvoicesBoolExp!) {
    invoices(where: $where, orderBy: { issuedAt: DESC_NULLS_FIRST }) {
      serviceCompletionDate
      displayId
      invoiceGuid
      status
      totalUsc
      maintenancePlanLink {
        maintenancePlanGuid
      }
      jobLink {
        jobGuid
        job {
          displayId
        }
      }
      invoiceTerm
      issuedAt
    }
  }
`)
