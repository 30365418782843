import React, { useCallback } from 'react'
import { useModalState, useStrictContext } from '../../../utils/react-utils'
import { PaymentStatusCardProps } from '../components/PaymentStatus/PaymentStatusCard'
import { PaymentStatusModal } from '../components/PaymentStatus/PaymentStatusModal'
import { usePaymentStatusContext } from './PaymentStatusProvider'

type PaymentStatusModalContextTypes = {
  showPaymentStatusModal: (props: PaymentStatusCardProps) => void
  hidePaymentStatusModal: () => void
}

const PaymentStatusContext = React.createContext<
  PaymentStatusModalContextTypes | undefined
>({
  showPaymentStatusModal: () => {},
  hidePaymentStatusModal: () => {},
})

export const usePaymentStatusModalContext = () => {
  const context = useStrictContext(PaymentStatusContext)
  return context
}

export const PaymentStatusModalProvider = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const [open, openModal, closeModal] = useModalState(false)
    const {
      paymentStatusProps,
      setPaymentStatusProps,
      clearPaymentStatusProps,
    } = usePaymentStatusContext()

    const showPaymentStatusModal = useCallback(
      (props: PaymentStatusCardProps) => {
        openModal()
        setPaymentStatusProps(props)
      },
      [openModal, setPaymentStatusProps],
    )

    const hidePaymentStatusModal = useCallback(() => {
      closeModal()
      clearPaymentStatusProps()
    }, [clearPaymentStatusProps, closeModal])

    return (
      <PaymentStatusContext.Provider
        value={{
          showPaymentStatusModal,
          hidePaymentStatusModal,
        }}
      >
        {children}
        {open && paymentStatusProps && (
          <PaymentStatusModal {...paymentStatusProps} />
        )}
      </PaymentStatusContext.Provider>
    )
  },
)
