import { z } from 'zod'
import { AsyncFn, TimeZoneId } from '../../common'
import { CompanyGuid, CompanyGuidContainer, ForCompany } from '../Company/Company'

export const DEFAULT_COMPANY_STARTING_WORK_HOUR = 6
export const DEFAULT_COMPANY_ENDING_WORK_HOUR = 20

export const workingHoursSchema = z.object({
  startHour: z.number().min(0).max(23),
  endHour: z.number().min(0).max(23),
})

export type WorkingHours = z.infer<typeof workingHoursSchema>

export interface CompanySchedulingInfo extends CompanyGuidContainer {
  timeZoneId: TimeZoneId
  workingHours: WorkingHours
}

export type ICompanyWorkingHoursReader = AsyncFn<CompanyGuid, WorkingHours>
export type ICompanyWorkingHoursWriter = AsyncFn<ForCompany<WorkingHours>>

export const withDefaultCompanyWorkingHoursFactory = (inner: ICompanyWorkingHoursReader) => {
  return async (companyGuid: CompanyGuid): Promise<WorkingHours> => {
    try {
      return await inner(companyGuid)
    } catch {
      return {
        startHour: DEFAULT_COMPANY_STARTING_WORK_HOUR,
        endHour: DEFAULT_COMPANY_ENDING_WORK_HOUR,
      }
    }
  }
}
