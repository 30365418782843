import classNames from 'classnames'
import React from 'react'

type SmallCardProps = React.PropsWithChildren<{
  header?: React.ReactNode
  headClassName?: string
  cardClassName?: string
  dataTestId?: string
}>

export const SmallCard = React.memo<SmallCardProps>(
  ({ header, headClassName, cardClassName, children, dataTestId }) => {
    return (
      <div
        className={classNames(
          'rounded-xl border border-solid border-bz-gray-500 bg-white p-3 shadow-sm',
          cardClassName,
        )}
        data-testid={dataTestId}
      >
        {header && (
          <div
            className={classNames(
              'mb-2 border-0 border-b border-dashed border-b-bz-gray-500 pb-2 font-semibold',
              headClassName,
            )}
          >
            {header}
          </div>
        )}
        <div>{children}</div>
      </div>
    )
  },
)
