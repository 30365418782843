import {
  BzDateFns,
  BzDateTime,
  InstalledEquipment,
  VisitViewModel,
} from '@breezy/shared/src'
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'
import { ActionItem } from '../../elements/ActionItems/ActionItems'
import ActionItemsButton from '../../elements/ActionItems/ActionItemsButton'
import { SmallCard } from '../../elements/Card/SmallCard'
import { KeyValueRow } from '../../pages/CreateOrEditMaintenancePlanPage/components/KeyValueRow'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { JobDetailsLink } from '../Links/EntityLinks'
import VisitStatusTag from './VisitStatusTag'

type VisitMiniCardProps = {
  visitIndex: number
  visit: VisitViewModel
  leftAlign?: boolean
  useInfoLinks?: boolean
  actionItems?: ActionItem[]
  dataTestId?: string
}

export const VisitMiniCard = memo<VisitMiniCardProps>(
  ({
    visitIndex,
    visit,
    leftAlign = false,
    useInfoLinks,
    actionItems,
    dataTestId,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { name, status, visitEquipment, visitJob, affinityDate } = visit
    const jobLabel = visitJob
      ? `${visitJob.jobTypeName} #${visitJob.jobDisplayId}`
      : undefined
    return (
      <SmallCard
        dataTestId={dataTestId}
        header={
          <div className="space-between flex w-full flex-row gap-x-1">
            <div className="text-bz-grey-9000 text-[14px] font-semibold leading-[22px]">
              Visit #{visitIndex + 1} ({name})
            </div>
            <div className="flex flex-row">
              <div className="mb-[4px]">
                <VisitStatusTag status={status} />
              </div>
              {actionItems && (
                <ActionItemsButton
                  actionItems={actionItems}
                  customButton={
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      data-testid={`${dataTestId}-action-items-button`}
                      className="cursor-pointer px-[8px] pt-1 text-[16px]"
                    />
                  }
                />
              )}
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-y-1">
          <KeyValueRow
            label="Target Date"
            value={BzDateTime.fromDateString(
              affinityDate,
              tzId,
            ).toHumanFriendlyMonthYear()}
            className="mb-0"
            leftAlign={leftAlign}
          />
          {visit.visitJob?.workCompletedAt ? (
            <KeyValueRow
              label="Completed Date"
              value={BzDateFns.formatFromISO(
                visit.visitJob.workCompletedAt,
                'MMM d, yyyy',
                tzId,
              )}
              className="mb-0"
              leftAlign={leftAlign}
            />
          ) : null}
          {visitEquipment.length < 1 && (
            <KeyValueRow
              label="Equipment"
              className="mb-0"
              leftAlign={leftAlign}
            />
          )}
          {visitEquipment.map(equipment => (
            <KeyValueRow
              label="Equipment"
              value={`${InstalledEquipment.calculateFriendlyName(equipment)}`}
              className="mb-0"
              leftAlign={leftAlign}
            />
          ))}
          <KeyValueRow
            label="Job"
            value={
              visitJob ? (
                useInfoLinks ? (
                  <JobDetailsLink label={jobLabel} guid={visitJob.jobGuid} />
                ) : (
                  jobLabel
                )
              ) : undefined
            }
            className="mb-0"
            leftAlign={leftAlign}
          />
        </div>
      </SmallCard>
    )
  },
)
