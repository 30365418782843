import { AssignedApptViewModel, UserGuid, groupBy } from '@breezy/shared'

export const groupAppointmentAssignments = (
  principalUserGuid: UserGuid,
  appt: AssignedApptViewModel,
) => {
  const grouped = groupBy(appt.assignments, a =>
    a.technicianUserGuid === principalUserGuid ? 'me' : 'others',
  )
  return {
    myAssignment:
      grouped['me'] && grouped['me'].length > 0 ? grouped['me'][0] : undefined,
    otherAssignments: grouped['others'] ?? [],
  }
}

export const getAssignment = (
  principalUserGuid: UserGuid,
  appt: AssignedApptViewModel,
) => {
  const result = groupAppointmentAssignments(
    principalUserGuid,
    appt,
  ).myAssignment
  return result
}
