import { AutoComplete, AutoCompleteProps } from 'antd'
import classNames from 'classnames'
import { BaseSelectRef } from 'rc-select'
import React from 'react'

type Option = { label: string; value: string }

const filterOption = (input: string, option?: Option) => {
  if (!option || !option.label) {
    return false
  }

  return option.label.toString().toLowerCase().includes(input.toLowerCase())
}

type AutoCompleteFieldProps = AutoCompleteProps<string, Option> & {
  ddActionName?: string
  name: string
}

export const AutoCompleteField = React.memo(
  React.forwardRef<BaseSelectRef, AutoCompleteFieldProps>(
    ({ name, size = 'large', className, ddActionName, ...rest }, ref) => (
      <AutoComplete<string, Option>
        ref={ref}
        id={name}
        data-dd-action-name={ddActionName}
        data-testid={name}
        size={size}
        className={classNames('w-full', className)}
        popupMatchSelectWidth={false}
        filterOption={filterOption}
        {...rest}
      />
    ),
  ),
)
