import {
  MaintenancePlanMinimalInfo,
  MaintenancePlanStatus,
} from '@breezy/shared'
import { faCrown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import { m } from '../../utils/react-utils'

type MaintenancePlanVipIconProps = {
  maintenancePlan?: Pick<MaintenancePlanMinimalInfo, 'status' | 'planTypeName'>
  size?: number
  customMargin?: string
  inline?: boolean
}

const MaintenancePlanVipIcon = m<MaintenancePlanVipIconProps>(
  ({ maintenancePlan, size = 24, customMargin, inline = true }) => {
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

    const square = `min-w-[${size}px] h-[${size}px]`
    const margin = customMargin || 'mx-1'
    if (
      !maintenancePlan ||
      maintenancePlan.status !== MaintenancePlanStatus.ACTIVE ||
      !isCompanyMaintenancePlansEnabled
    )
      return null

    const innerIcon = (
      <FontAwesomeIcon
        icon={faCrown}
        className={`grey9 text-size-[${size}px]`}
      />
    )

    const innerElement = inline ? (
      <span
        className={`${margin} ${square} center-children-vh`}
        // NOTE: Tailwind doesn't work for custom widths
        style={{ minWidth: size, width: size }}
      >
        {innerIcon}
      </span>
    ) : (
      <div
        className={`${margin} ${square} center-children-vh`}
        // NOTE: Tailwind doesn't work for custom widths
        style={{ width: size }}
      >
        {innerIcon}
      </div>
    )

    return (
      <Tooltip
        title={`VIP - ${maintenancePlan.planTypeName} Maintenance Plan Member`}
      >
        {innerElement}
      </Tooltip>
    )
  },
)

export default MaintenancePlanVipIcon
