import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch, SwitchProps } from 'antd'
import React from 'react'

type SwitchFieldProps = SwitchProps & {
  name: string
  withIcons?: boolean
}

export const SwitchField = React.memo(
  React.forwardRef<HTMLElement, SwitchFieldProps>(
    ({ name, size = 'large', className, value, withIcons, ...rest }, ref) => (
      <Switch
        ref={ref}
        id={name}
        data-testid={name}
        checked={value}
        checkedChildren={
          withIcons && (
            <div className="ml-[-2px] mr-[4px] pt-[0px]">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-[3px] text-[16px]"
              />
            </div>
          )
        }
        unCheckedChildren={
          withIcons && (
            <div className="ml-[4px] mr-[-2px] pt-[0px]">
              <FontAwesomeIcon
                icon={faClose}
                className="mt-[1px] text-[16px]"
              />
            </div>
          )
        }
        {...rest}
      />
    ),
  ),
)
