import {
  JobOutcomesFormSchema,
  isNullish,
  uscMultiply,
  usdToUsCents,
} from '@breezy/shared'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useFetchCompanyCommissionConfig } from '../../../hooks/fetch/useFetchCompanyCommissionConfig'

/**
 * Returns a Tuple-2 with data about the form's overhead allocation deduction.
 *
 * The first element is the calculated overhead allocation deduction
 * based on values from the JobOutcomes form. The unit is in US cents
 *
 * The second element is the overhead allocation deduction percent value if the
 * deduction uses a percentage-based rate. This means if the deduction is 10%, this
 * second element will return 10, NOT 0.1. If the deduction uses a fixed value,
 * this second element will be undefined.
 */
export const useOverheadAllocationDeduction = (
  form: UseFormReturn<JobOutcomesFormSchema>,
  totalSoldRevenueUsc: number,
): [number, number | undefined] => {
  const [companyCommissionConfig] = useFetchCompanyCommissionConfig()

  const formJobOverheadAllocationDeductionPercent = form.watch(
    'overheadAllocationDeductionPercent',
  )
  const formJobOverheadAllocationFlatDeductionUsd = form.watch(
    'overheadAllocationFlatDeductionUsd',
  )
  const formJobOverheadAllocationDeductionType = form.watch(
    'overheadAllocationDeductionType',
  )

  return useMemo(() => {
    if (formJobOverheadAllocationDeductionType === 'PERCENT') {
      if (!isNullish(formJobOverheadAllocationDeductionPercent)) {
        const deductionRate = formJobOverheadAllocationDeductionPercent / 100
        return [
          uscMultiply(totalSoldRevenueUsc, deductionRate),
          formJobOverheadAllocationDeductionPercent,
        ]
      }
    } else if (formJobOverheadAllocationDeductionType === 'FIXED') {
      if (!isNullish(formJobOverheadAllocationFlatDeductionUsd)) {
        return [
          usdToUsCents(formJobOverheadAllocationFlatDeductionUsd),
          undefined,
        ]
      }
    }

    // If we reach this point, it means that no overhead deductions have been specified
    // for the job. So, we try to fallback to the default overhead deduction defined
    // in the company settings
    if (
      !isNullish(companyCommissionConfig.defaultCommissionOverheadDeductionRate)
    ) {
      const deductionPercent =
        companyCommissionConfig.defaultCommissionOverheadDeductionRate * 100
      return [
        uscMultiply(
          totalSoldRevenueUsc,
          companyCommissionConfig.defaultCommissionOverheadDeductionRate,
        ),
        deductionPercent,
      ]
    } else if (
      !isNullish(
        companyCommissionConfig.defaultCommissionOverheadFlatDeductionUsc,
      )
    ) {
      return [
        companyCommissionConfig.defaultCommissionOverheadFlatDeductionUsc,
        undefined,
      ]
    }

    return [0, undefined]
  }, [
    formJobOverheadAllocationDeductionType,
    companyCommissionConfig.defaultCommissionOverheadDeductionRate,
    companyCommissionConfig.defaultCommissionOverheadFlatDeductionUsc,
    formJobOverheadAllocationDeductionPercent,
    totalSoldRevenueUsc,
    formJobOverheadAllocationFlatDeductionUsd,
  ])
}
