import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import { PhotoRecord, noOp } from '@breezy/shared'
import cn from 'classnames'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSubscription } from 'urql'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { PhotoDetailDrawer } from '../../../components/PhotoDetailDrawer/PhotoDetailDrawer'
import { AsyncPhotoUpload } from '../../../components/Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../../../components/Upload/Upload'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import {
  LINKED_JOB_DATA_SUBSCRIPTION,
  LinkedJobData,
} from '../JobDetailsPage.gql'

const linkedJobDataQueryToPhotoRecordMapper = (
  data: LinkedJobData,
): PhotoRecord[] => {
  if (data.jobsWithLinkedJob.length === 0) {
    return []
  }

  return data.jobsWithLinkedJob[0].linked_job.photoLinks.map(
    ({ photoGuid, photo }) => ({
      photoGuid,
      cdnUrl: photo.cdnUrl,
      createdAt: photo.createdAt,
      resourceUrn: photo.resourceUrn,
      createdByUserGuid: photo.createdByUserGuid,
    }),
  )
}

type PhotosListProps = {
  job: ComprehensiveJobDetails
  canManagePhotos: boolean
  onPhotoUploaded: () => void
  onPhotoDeleted: () => void
}

export const PhotosList = ({
  job,
  canManagePhotos,
  onPhotoUploaded,
  onPhotoDeleted,
}: PhotosListProps) => {
  const linkedJobsEnabled = useFeatureFlag('linkedJobs')

  const linkedJobDataQuery = useSubscription({
    query: LINKED_JOB_DATA_SUBSCRIPTION,
    variables: { jobGuid: job.getJobGuid() },
    pause: !linkedJobsEnabled,
  })

  const [editingPhoto, setEditingPhoto] = useState<PhotoRecord | null>(null)
  const [editingLinkedPhoto, setEditingLinkedPhoto] =
    useState<PhotoRecord | null>(null)

  const photoLinks = useMemo(() => ({ jobGuid: job.getJobGuid() }), [job])
  const onPhotoUploadChange = useSynchronousUpload(onPhotoUploaded)

  return (
    <div className="w-full">
      {canManagePhotos && (
        <AsyncPhotoUpload
          links={photoLinks}
          onPhotoUploadChange={onPhotoUploadChange}
        />
      )}

      <div className="my-5 w-full columns-2 gap-2">
        {job.getPhotos().map(photo => (
          <div
            className={cn(
              'mb-2 flex flex-col items-center',
              canManagePhotos ? 'hover:cursor-pointer' : '',
            )}
            key={photo.photoGuid}
            onClick={canManagePhotos ? () => setEditingPhoto(photo) : undefined}
          >
            <img src={photo.cdnUrl} alt="belongs to job" />
          </div>
        ))}
      </div>

      <GqlQueryLoader
        query={linkedJobDataQuery}
        render={data => (
          <RenderIf
            if={
              data.jobsWithLinkedJob[0]?.linkPhotos &&
              data.jobsWithLinkedJob.length > 0
            }
          >
            <div className="flex flex-col gap-5">
              <div className="mb-5 bg-bz-gray-300 px-3 py-2">
                <span className="text-sm font-semibold text-bz-gray-900">
                  Linked from{' '}
                  <Link
                    to={`/jobs/${data.jobsWithLinkedJob[0]?.linked_job.jobGuid}`}
                  >
                    Job #{data.jobsWithLinkedJob[0]?.linked_job.displayId}
                  </Link>
                </span>
              </div>

              <div className="mb-5 w-full columns-2 gap-2">
                {linkedJobDataQueryToPhotoRecordMapper(data).map(photo => (
                  <div
                    className={cn(
                      'mb-2 flex flex-col items-center',
                      canManagePhotos ? 'hover:cursor-pointer' : '',
                    )}
                    key={photo.photoGuid}
                    onClick={
                      canManagePhotos
                        ? () => setEditingLinkedPhoto(photo)
                        : undefined
                    }
                  >
                    <img src={photo.cdnUrl} alt="belongs to job" />
                  </div>
                ))}
              </div>
            </div>
          </RenderIf>
        )}
      />

      {editingPhoto && (
        <PhotoDetailDrawer
          photo={editingPhoto}
          onClose={() => {
            setEditingPhoto(null)
          }}
          onDelete={() => {
            setEditingPhoto(null)
            onPhotoDeleted()
          }}
          editable={canManagePhotos}
        />
      )}

      {editingLinkedPhoto && (
        <PhotoDetailDrawer
          photo={editingLinkedPhoto}
          onClose={() => {
            setEditingLinkedPhoto(null)
          }}
          onDelete={noOp}
          editable={false}
        />
      )}
    </div>
  )
}
