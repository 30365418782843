import {
  defaultNotificationPreferenceType,
  NotificationPreferenceType,
  NotificationPreferenceTypeDisplayNames,
} from '@breezy/shared'
import { Form } from 'antd'
import BzSelect from '../../../elements/BzSelect/BzSelect'

type NotificationPreferenceTypeFormItemProps = {
  value?: NotificationPreferenceType
  onChange: (value: NotificationPreferenceType) => void
  notificationPreference?: NotificationPreferenceType
  labelClassName?: string
}

export const NotificationPreferenceTypeFormItem: React.FC<
  NotificationPreferenceTypeFormItemProps
> = ({ notificationPreference, labelClassName = '', value, onChange }) => {
  return (
    <Form.Item
      name="notificationPreference"
      label="Notifications"
      className={labelClassName}
      initialValue={notificationPreference || defaultNotificationPreferenceType}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <BzSelect
        title="Notifications"
        size="middle"
        sheetSize="half"
        value={value}
        onChange={onChange}
        options={Object.values(NotificationPreferenceType).map(n => ({
          label: NotificationPreferenceTypeDisplayNames[n],
          value: n,
        }))}
      />
    </Form.Item>
  )
}
