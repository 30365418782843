import {
  AvatarData,
  MatchToShape,
  Person,
  RenderablePerson,
  Technician,
  toTechnicianRoleDisplayName,
} from '@breezy/shared'
import { userColor } from './color-utils'

export interface RenderableTechnicianResource extends RenderablePerson {
  id: string
  displayCategory: string
  categories: string[]
}

export const getAvatarShortStringForPerson = (user: {
  firstName: string
  lastName: string
}) => (user.firstName || 'F')[0] + (user.lastName || 'L')[0]

type RenderCompatiblePerson = MatchToShape<
  Person,
  {
    firstName: true
    lastName: true
    id: true
    deactivatedAt: true
  }
>

export const toRenderablePerson = (
  person: RenderCompatiblePerson,
): RenderablePerson => {
  const avatarShortString =
    (person.firstName || 'F')[0] + (person.lastName || 'L')[0]
  const avatarData: AvatarData = {
    avatarAltShortString: avatarShortString,
    avatarAltColor: userColor(person.id),
    userGuid: person.id,
    deactivatedAt: person.deactivatedAt,
  }

  return {
    displayName: `${person.firstName} ${person.lastName}`,
    avatarData,
  }
}

type RenderCompatibleTechnician = MatchToShape<
  Technician,
  {
    user: {
      firstName: true
      lastName: true
      id: true
      deactivatedAt: true
    }
    roles: true
  }
>

export const toRenderableTechnician = (
  technician: RenderCompatibleTechnician,
): RenderableTechnicianResource => {
  const { user, roles } = technician

  return {
    ...toRenderablePerson(user),
    id: user.id,
    displayCategory:
      roles.length === 0
        ? 'No technician role'
        : toTechnicianRoleDisplayName(roles[0]),
    categories: roles.map(r => r.toString()),
  }
}
