import { Account } from '@breezy/shared'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createTsForm } from '@ts-react/form'
import { Button, Form } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { z } from 'zod'
import useIsMobile from '../../../../hooks/useIsMobile'
import ContactSelectionField from '../../../Financing/FinancingConfigureAppForms/ContactSelectionField/ContactSelectionField'
import { ContactGuidSchema } from '../../../Financing/FinancingConfigureAppForms/ContactSelectionField/ContactSelectionField.schema'

type CustomFormProps = React.PropsWithChildren<{
  onSubmit: () => void
  onCancel: () => void
  onBack?: () => void
  footerClassName?: string
  primaryButtonText?: string
}>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const CustomForm = ({
  children,
  onSubmit,
  onCancel,
  onBack,
  footerClassName = 'border-0 border-t border-solid border-t-bz-gray-400 px-6 py-3',
  primaryButtonText = 'Preview Message',
}: CustomFormProps) => {
  const isMobile = useIsMobile()

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <Form
        onSubmitCapture={onSubmit}
        layout="vertical"
        className="flex min-h-0 flex-1 flex-col"
        requiredMark={label => (
          <div>
            {label}
            <span className="ml-1 text-bz-red-500">*</span>
          </div>
        )}
      >
        <div
          className={classNames(
            'min-h-0 flex-1 overflow-auto pb-4',
            isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
          )}
        >
          {children}
        </div>
        <div
          className={classNames(
            'flew-row flex items-center justify-between',
            isMobile ? 'mx-[-16px]' : 'mx-[-24px]',
            footerClassName,
          )}
        >
          <Button
            className={classNames({
              'flex-1': !onBack,
            })}
            onClick={onCancel}
            size="large"
          >
            Cancel
          </Button>
          <div
            className={classNames('flex flex-row items-center space-x-3', {
              'flex-1': !onBack,
            })}
          >
            {onBack && (
              <Button
                size="large"
                onClick={onBack}
                icon={
                  <FontAwesomeIcon icon={faArrowLeft} className="text-base" />
                }
              >
                Go Back
              </Button>
            )}
            <Button
              className={classNames({
                'flex-1': !onBack,
              })}
              size="large"
              type="primary"
              onClick={onSubmit}
            >
              {primaryButtonText ?? isMobile ? 'Preview' : 'Preview Message'}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

const SelectContactFormSchema = z.object({
  contactGuid: ContactGuidSchema.describe('Contact'),
})

export type SelectContactFormData = z.infer<typeof SelectContactFormSchema>

const mapping = [[ContactGuidSchema, ContactSelectionField]] as const

const ContactForm = createTsForm(mapping, {
  FormComponent: CustomForm,
})

type SelectContactFormProps = React.PropsWithChildren<{
  account: Account
  refetchAccount: () => void
  selectedContactGuid?: string
  selectContact: (contactGuid: string) => void
  onBack?: () => void
  onCancel: () => void
  wrapperClassName?: string
  footerClassName?: string
}>
const SelectContactForm = React.memo<SelectContactFormProps>(
  ({
    account,
    refetchAccount,
    selectedContactGuid,
    selectContact,
    onBack,
    onCancel,
    wrapperClassName,
    footerClassName,
  }) => {
    const isMobile = useIsMobile()
    const onSubmit = (data: SelectContactFormData) => {
      selectContact(data.contactGuid)
    }

    return (
      <div
        className={classNames(
          'flex min-h-0 flex-1 flex-col pb-0',
          wrapperClassName,
          {
            'p-6': !wrapperClassName && !isMobile,
            'p-4': !wrapperClassName && isMobile,
          },
        )}
      >
        <ContactForm
          schema={SelectContactFormSchema}
          onSubmit={onSubmit}
          formProps={{
            onBack,
            onCancel,
            footerClassName,
          }}
          props={{
            contactGuid: {
              account,
              refetchAccount,
              description:
                'The payment link will display financing as a payment option. In the event that the customer wants to apply for financing, you need to select who the primary contact will be. This is so the financing application can be associated with their information for your own data keeping purposes.',
            },
          }}
          defaultValues={{
            contactGuid:
              selectedContactGuid ??
              account.accountContacts[0]?.contact.contactGuid ??
              '',
          }}
        />
      </div>
    )
  },
)

export default SelectContactForm
