import { isNullish, noOp } from '@breezy/shared'
import { Drawer } from 'antd'
import PhotoDetailAndEditView, {
  PhotoDetailProps,
} from './PhotoDetailAndEditView'

import { useIntercom } from '../../../hooks/useIntercom'
import './PhotoDetailDrawer.less'

const PhotoDetailDrawer = ({
  photo,
  onCancel = noOp,
  onMutate = noOp,
}: Partial<PhotoDetailProps>) => {
  useIntercom({ isLauncherVisible: isNullish(photo) })

  return (
    <Drawer
      title="Photo Detail"
      placement={'bottom'}
      closable={true}
      onClose={onCancel}
      height="large"
      styles={{ header: { display: 'none' } }}
      open={!!photo}
    >
      {photo && (
        <PhotoDetailAndEditView
          photo={photo}
          onMutate={onMutate}
          onCancel={onCancel}
        />
      )}
      {!photo && <div style={{ height: 500 }}></div>}
    </Drawer>
  )
}

export default PhotoDetailDrawer
