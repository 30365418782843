import classNames from 'classnames'
import React from 'react'
import RenderIf from '../../elements/RenderIf/RenderIf'
import { WisetackLogo } from '../Financing/WisetackLogo'
type PrequalifiedBannerWrapperProps = {
  className?: string
  hasLogo?: boolean
  type: 'success' | 'error'
}

const PrequalifiedBannerWrapper = React.memo<
  React.PropsWithChildren<PrequalifiedBannerWrapperProps>
>(({ children, className, type = 'success', hasLogo = false }) => {
  return (
    <div
      className={classNames(
        'flex flex-row items-center gap-3 rounded-lg border border-solid px-4 py-3',
        {
          'border-bz-green-300 bg-bz-green-100': type === 'success',
          'border-bz-red-300 bg-bz-red-100': type === 'error',
        },
        className,
      )}
    >
      <RenderIf if={hasLogo}>
        <div className="h-[36px] w-[36px]">
          <WisetackLogo size="md" />
        </div>
      </RenderIf>
      <div>{children}</div>
    </div>
  )
})
export default PrequalifiedBannerWrapper
