import { isNullish } from './type-utils'

export const createQueryStringFromFields = (fields: Record<string, unknown>, selectedArgs?: string[]): string => {
  const queryString = Object.entries(fields).reduce((acc, [key, value]) => {
    if ((!selectedArgs || selectedArgs.includes(key)) && !isNullish(value)) {
      return acc + `${key}=${value}&`
    }
    return acc
  }, '?')
  return queryString.length > 1 ? queryString.slice(0, -1) : ''
}
