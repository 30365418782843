import { z } from 'zod'

export const ZipCodeSchema = z
  .string()
  .max(5)
  .describe('Zip code')
  .transform((zip, ctx) => {
    const zipInt = parseInt(zip)
    const zipStr = `${zipInt}`.padStart(5, '0')
    if (zip !== zipStr) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid zip code.',
      })
      return z.NEVER
    }
    return zipStr
  })
