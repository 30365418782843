import { BzDateFns, IsoDateString, TimeZoneId, formatUsc } from '../../../common'
import { WisetackSamplePrequalEvent } from '../../../contracts/WisetackWebhookEventContracts'
import { AprPercentage, PrequalRecord } from './Prequal'

const convertIsoDateStringToWisetackLocalDateFormatString = (isoDateString: IsoDateString, tzId: TimeZoneId) =>
  BzDateFns.format(BzDateFns.parseISO(isoDateString, tzId), 'MM-dd-yyyy')

export const createInitiatedPrequalStatusWebhook = (
  prequalRecord: PrequalRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSamplePrequalEvent => ({
  prequalId: prequalRecord.externalPrequalGuid,
  merchantId: prequalRecord.merchantGuid,
  prequalStatus: 'INITIATED',
  date: BzDateFns.nowISOString(),
  expirationDate: convertIsoDateStringToWisetackLocalDateFormatString(
    BzDateFns.addMonths(BzDateFns.parseISO(prequalRecord.createdAt, companyTimeZoneId), 2).toISOString(),
    companyTimeZoneId,
  ),
  eventType: 'PREQUAL',
})

export const createPrequalifiedPrequalStatusWebhook = (
  prequalRecord: PrequalRecord,
  maxQualifiedAmountUsc: number,
  aprRange: AprPercentage[],
  companyTimeZoneId: TimeZoneId,
): WisetackSamplePrequalEvent => ({
  prequalId: prequalRecord.externalPrequalGuid,
  merchantId: prequalRecord.merchantGuid,
  prequalStatus: 'PREQUALIFIED',
  date: BzDateFns.nowISOString(),
  expirationDate: convertIsoDateStringToWisetackLocalDateFormatString(
    BzDateFns.addWeeks(BzDateFns.parseISO(prequalRecord.createdAt, companyTimeZoneId), 2).toISOString(),
    companyTimeZoneId,
  ),
  maxQualifiedAmount: formatUsc(maxQualifiedAmountUsc),
  aprRange,
  eventType: 'PREQUAL',
})

export const createDeclinedPrequalStatusWebhook = (prequalRecord: PrequalRecord): WisetackSamplePrequalEvent => ({
  prequalId: prequalRecord.externalPrequalGuid,
  merchantId: prequalRecord.merchantGuid,
  prequalStatus: 'DECLINED',
  date: BzDateFns.nowISOString(),
  eventType: 'PREQUAL',
})
