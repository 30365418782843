import z from 'zod'
import { PermissionV1 } from './Permission'

export enum RoleId {
  INSTALL_TECHNICIAN = 'INSTALL_TECHNICIAN',
  MAINTENANCE_TECHNICIAN = 'MAINTENANCE_TECHNICIAN',
  SERVICE_TECHNICIAN = 'SERVICE_TECHNICIAN',
  SALES_TECHNICIAN = 'SALES_TECHNICIAN',
  CUSTOMER_SERVICE_REPRESENTATIVE = 'CUSTOMER_SERVICE_REPRESENTATIVE',
  DISPATCHER = 'DISPATCHER',
  ADMIN = 'ADMIN',
  ACCOUNTANT = 'ACCOUNTANT',
}

export const toRoleDisplayName = (role: RoleId): string => {
  switch (role) {
    case RoleId.INSTALL_TECHNICIAN:
      return 'Install Tech'
    case RoleId.MAINTENANCE_TECHNICIAN:
      return 'Maintenance Tech'
    case RoleId.SERVICE_TECHNICIAN:
      return 'Service Tech'
    case RoleId.SALES_TECHNICIAN:
      return 'Sales Tech'
    case RoleId.CUSTOMER_SERVICE_REPRESENTATIVE:
      return 'Customer Service Rep'
    case RoleId.DISPATCHER:
      return 'Dispatcher'
    case RoleId.ADMIN:
      return 'Admin'
    case RoleId.ACCOUNTANT:
      return 'Accountant'
    default:
      return 'Unknown'
  }
}

const roleToFieldMapping = {
  [RoleId.INSTALL_TECHNICIAN]: true,
  [RoleId.MAINTENANCE_TECHNICIAN]: true,
  [RoleId.SERVICE_TECHNICIAN]: true,
  [RoleId.SALES_TECHNICIAN]: true, // sales techs are both inside and outside
  [RoleId.CUSTOMER_SERVICE_REPRESENTATIVE]: false,
  [RoleId.DISPATCHER]: false,
  [RoleId.ADMIN]: false,
  [RoleId.ACCOUNTANT]: false,
}

export const isAFieldRole = (role: RoleId) => {
  return roleToFieldMapping[role]
}

export const hasAnyFieldRoles = (roles: RoleId[]) => {
  return roles.some(isAFieldRole)
}

export const RoleSchema = z.object({
  role: z.nativeEnum(RoleId),
  name: z.string(),
})

export type Role = z.infer<typeof RoleSchema>

const globalPermissions = [PermissionV1.READ_COMPANY_USERS] // READ_COMPANY_USERS

/**
 * PermissionV2 Tech permissions
 * tech get limited permissions in the mobile app
 *
 * READ_COMPANY_USERS
 * READ_COMPANY
 * USE_FIELD_EXPERIENCE
 * FIELD_JOB_INFORMATION_MANAGE_ASSIGNED, FIELD_JOB_INFORMATION_VIEW_ASSIGNED, FIELD_JOB_INFORMATION_CREATE
 * FIELD_ACCOUNTS_CONTACTS_VIEW_FULL, FIELD_ACCOUNT_CONTACTS_MANAGE_ASSIGNED, FIELD_ACCOUNT_CONTACTS_VIEW_ASSIGNED
 * FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_VIEW, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_NONE
 * FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED_TODAY, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED_CURRENT
 *
 */
const technicianPermissions = globalPermissions.concat([
  PermissionV1.VIEW_JOBS, // FIELD_JOB_INFORMATION_VIEW_ASSIGNED, FIELD_JOB_INFORMATION_CREATE
  PermissionV1.READ_COMPANY, // READ_COMPANY
  PermissionV1.READ_ACCOUNT, // FIELD_ACCOUNTS_CONTACTS_VIEW_FULL, FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED, FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED
  PermissionV1.VIEW_PRICING, // permission not being used
  PermissionV1.MANAGE_EQUIPMENT, // every company user is allowed to view/manage maint plans
  PermissionV1.VIEW_EQUIPMENT, // every company user is allowed to view/manage maint plans
  PermissionV1.COLLECT_PAYMENTS, // this is only being used to restrict endpoints, we're not currently modeling this in v2 and I think it's fine
  PermissionV1.VIEW_PAYMENTS, // FIELD_JOB_INFORMATION_VIEW_ASSIGNED
  PermissionV1.VIEW_CONTACTS, // FIELD_ACCOUNTS_CONTACTS_VIEW_FULL
  PermissionV1.VIEW_TECHNICIAN_ASSIGNMENTS, // FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_VIEW, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_NONE
  PermissionV1.MANAGE_INVOICING, // Looks like for techs this is only used generate pdfs? Maybe we add FIELD_JOB_INFORMATION_MANAGE_ASSIGNED and check if it's for a job they were assigned?
  PermissionV1.MANAGE_APPOINTMENT_ASSIGNMENTS, // FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED_TODAY, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED_CURRENT
  PermissionV1.VIEW_MAINTENANCE_PLANS, // every company user is allowed to view/manage maint plans
  PermissionV1.MANAGE_MAINTENANCE_PLANS, // every company user is allowed to view/manage maint plans
  PermissionV1.USE_FIELD_EXPERIENCE, // USE_FIELD_EXPERIENCE
  PermissionV1.VIEW_APPOINTMENT_CHECKLISTS, // no restrictions for techs
  PermissionV1.EDIT_APPOINTMENT_CHECKLIST_INSTANCES, // no restrictions for techs
])

/**
 * PermissionV2 Admin permissions
 * admin gets all app-specific permissions
 *
 * READ_COMPANY_USERS
 * READ_COMPANY
 * USE_OFFICE_EXPERIENCE
 * OFFICE_ACCOUNTS_JOBS_MANAGE, OFFICE_ACCOUNTS_JOBS_VIEW,
 * OFFICE_SCHEDULE_APPOINTMENTS_MANAGE,OFFICE_SCHEDULE_APPOINTMENTS_VIEW
 * OFFICE_FINANCIAL_INFORMATION_MANAGE, OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW, OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW, OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW, OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
 * OFFICE_REPORTS_MANAGE, OFFICE_REPORTS_VIEW
 * OFFICE_COMPANY_SETTINGS_MANAGE_TEAM, OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK, OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS, OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS, OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES, OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES, OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS
 * USE_FIELD_EXPERIENCE
 * FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL, FIELD_ACCOUNTS_CONTACTS_MANAGE_NO_DELETE, FIELD_ACCOUNTS_CONTACTS_VIEW_FULL, FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED, FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED,
 * FIELD_JOB_INFORMATION_MANAGE_FULL, FIELD_JOB_INFORMATION_MANAGE_ASSIGNED, FIELD_JOB_INFORMATION_VIEW_ASSIGNED, FIELD_JOB_INFORMATION_CREATE
 * FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED_TODAY, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_ASSIGNED_CURRENT, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_FULL, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_VIEW, FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM_NONE
 *
 */
const ADMIN_PERMISSIONS = globalPermissions.concat([
  PermissionV1.CREATE_COMPANY_USER, // OFFICE_COMPANY_SETTINGS_MANAGE_TEAM
  PermissionV1.MANAGE_ACCOUNTS, // OFFICE: OFFICE_ACCOUNTS_JOBS_MANAGE, OFFICE_ACCOUNTS_JOBS_VIEW, FIELD: FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL, FIELD_ACCOUNTS_CONTACTS_MANAGE_NO_DELETE, FIELD_ACCOUNTS_CONTACTS_VIEW_FULL, FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED, FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED
  PermissionV1.READ_ACCOUNT, // Covered by above
  PermissionV1.MANAGE_CONTACT, // Covered by above
  PermissionV1.MANAGE_JOBS, // OFFICE: Covered by above OFFICE_ACCOUNTS_JOBS_MANAGE, FIELD: FIELD_JOB_INFORMATION_MANAGE_FULL, FIELD_JOB_INFORMATION_MANAGE_ASSIGNED, FIELD_JOB_INFORMATION_VIEW_ASSIGNED, FIELD_JOB_INFORMATION_CREATE
  PermissionV1.MANAGE_COMPANY_USERS, // OFFICE_COMPANY_SETTINGS_MANAGE_TEAM
  PermissionV1.VIEW_JOBS, // Covered by above
  PermissionV1.MANAGE_TECHNICIAN_CAPACITY, // n/a
  PermissionV1.SCHEDULE_ASSIGNMENTS, // OFFICE_SCHEDULE_APPOINTMENTS_MANAGE, OFFICE_SCHEDULE_APPOINTMENTS_VIEW
  PermissionV1.PRICING_ADMIN, // OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK
  PermissionV1.VIEW_PRICING, // n/a
  PermissionV1.VIEW_LOCATIONS, // covered by OFFICE_ACCOUNTS_JOBS
  PermissionV1.MANAGE_LOCATIONS, // covered by OFFICE_ACCOUNTS_JOBS
  PermissionV1.VIEW_EQUIPMENT, // n/a
  PermissionV1.MANAGE_EQUIPMENT, // n/a
  PermissionV1.COLLECT_PAYMENTS, // n/a
  PermissionV1.VIEW_PAYMENTS, // OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW
  PermissionV1.VIEW_CONTACTS, // covered by OFFICE_ACCOUNT_JOBS and FIELD_ACCOUNTS_CONTACTS
  PermissionV1.VIEW_TECHNICIAN_ASSIGNMENTS, // covered by OFFICE_SCHEDULE_APPOINTMENTS and FIELD_SCHEDULE_APPOINTMENTS_MANAGE_TEAM
  PermissionV1.MANAGE_INVOICING, // covered by OFFICE_FINANCIAL_INFORMATION_MANAGE
  PermissionV1.VIEW_INVOICING, // covered by OFFICE_FINANCIAL_INFORMATION_MANAGE
  PermissionV1.ISSUE_REFUNDS, // covered by OFFICE_FINANCIAL_INFORMATION_MANAGE
  PermissionV1.MANAGE_APPOINTMENT_ASSIGNMENTS, // covered by OFFICE_SCHEDULE_APPOINTMENTS_MANAGE
  PermissionV1.MANAGE_COMPANY_SETTINGS, // OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS, OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES, OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES, OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS
  PermissionV1.PHOTOS_ADMIN, // covered by OFFICE_ACCOUNTS_JOBS_MANAGE
  PermissionV1.VIEW_MAINTENANCE_PLANS, // n/a
  PermissionV1.MANAGE_QUICKBOOKS_INTEGRATION, // OFFICE_FINANCIAL_INFORMATION_MANAGE
  PermissionV1.MANAGE_NOTES, // covered by OFFICE_ACCOUNTS_JOBS_MANAGE
  PermissionV1.VIEW_SCHEDULE, // covered by OFFICE_SCHEDULE_APPOINTMENTS
  PermissionV1.USE_OFFICE_EXPERIENCE, // USE_OFFICE_EXPERIENCE
  PermissionV1.VIEW_APPOINTMENT_CHECKLISTS, // OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS
  PermissionV1.MANAGE_APPOINTMENT_CHECKLISTS, // OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS
  PermissionV1.EDIT_APPOINTMENT_CHECKLIST_INSTANCES, // OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS
  PermissionV1.DEACTIVATE_USER, // OFFICE_COMPANY_SETTINGS_MANAGE_TEAM
  PermissionV1.MANAGE_JOB_LIFECYCLES, // OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES
  PermissionV1.READ_JOB_LIFECYCLES, // n/a, everybody can read
  PermissionV1.MANAGE_JOB_TYPES, // OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES
])

export const inferredPermissions: { [key in RoleId]: PermissionV1[] } = {
  [RoleId.INSTALL_TECHNICIAN]: technicianPermissions,
  [RoleId.MAINTENANCE_TECHNICIAN]: technicianPermissions,
  [RoleId.SALES_TECHNICIAN]: technicianPermissions,
  [RoleId.SERVICE_TECHNICIAN]: technicianPermissions,
  [RoleId.CUSTOMER_SERVICE_REPRESENTATIVE]: [...ADMIN_PERMISSIONS],
  [RoleId.DISPATCHER]: [...ADMIN_PERMISSIONS],
  [RoleId.ADMIN]: [...ADMIN_PERMISSIONS],
  [RoleId.ACCOUNTANT]: [...ADMIN_PERMISSIONS],
}
