import { gql } from '../../generated'

export const FETCH_COMPANY_TECHNICIAN_PERFORMANCE_CONFIG = gql(/* GraphQL */ `
  query FetchCompanyTechnicianPerformanceConfig($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      technicianPerformanceEnabled
    }
  }
`)

export const FETCH_COMPANY_COMMISSION_CONFIG = gql(/* GraphQL */ `
  query FetchCompanyTechnicianPerformanceCommissionConfig($companyGuid: uuid!) {
    companyCommissionConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      defaultCommissionOverheadDeductionRate
      defaultCommissionOverheadFlatDeductionUsc
    }
  }
`)

export const UPSERT_COMPANY_COMMISSION_CONFIG = gql(/* GraphQL */ `
  mutation UpsertCompanyCommissionConfig(
    $config: CompanyCommissionConfigInsertInput!
  ) {
    insertCompanyCommissionConfigOne(
      object: $config
      onConflict: {
        constraint: company_commission_config_pkey
        updateColumns: [
          defaultCommissionOverheadDeductionRate
          defaultCommissionOverheadFlatDeductionUsc
        ]
      }
    ) {
      defaultCommissionOverheadDeductionRate
      defaultCommissionOverheadFlatDeductionUsc
    }
  }
`)
