export function bzGroupBy<T>(list: T[], keyGetter: (item: T) => string): Map<string, T[]> {
  const map = new Map<string, T[]>()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function bzGroupByToRecord<T>(list: T[], keyGetter: (item: T) => string): Record<string, T[]> {
  const record: Record<string, T[]> = {}
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = record[key]
    if (!collection) {
      record[key] = [item]
    } else {
      collection.push(item)
    }
  })
  return record
}

export function bzReduce<T, R = T>(list: (T | null | undefined)[], mapFn: (item: T) => R) {
  return list.reduce((acc: R[], i: T | null | undefined) => {
    if (i) {
      acc.push(mapFn(i))
    }

    return acc
  }, [])
}

export function bzDistinct<T>(list: T[]) {
  return Array.from(new Set(list))
}

export function bzDistinctAppend<T>(list: T[], item: T) {
  return bzDistinct([...list, item])
}

export function bzDistinctBy<T>(list: T[], keyGetter: (item: T) => string) {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    map.set(key, item)
  })
  return Array.from(map.values())
}

export function toArray<T>(value: T | undefined): T[] {
  return value ? [value] : []
}

/**
 * Takes a list and returns a tuple with two lists. It runs "splitWhen" on each element. When "splitWhen" is "true",
 * this returns all elements before it as the first array, and it and the rest of the elements as the second.
 * @param list the list to split
 * @param splitWhen the predicate that tells us when to split
 */
export const splitArray = <T>(list: T[], splitWhen: (item: T) => boolean): [T[], T[]] => {
  const firstList: T[] = []
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (splitWhen(item)) {
      return [firstList, list.slice(i)]
    }
    firstList.push(item)
  }
  return [firstList, []]
}
