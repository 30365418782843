import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsAuthorized } from './useIsAuthorized'

export const useHasFieldAccountsFullAccess = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    return isAuthorized(PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL)
  }, [isAuthorized])
}
