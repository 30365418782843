import { R } from '@breezy/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { useBlocker as useReactRouterBlocker } from 'react-router-dom'
import { useStrictContext } from '../utils/react-utils'

export type BlockerFunction = Exclude<
  Parameters<typeof useReactRouterBlocker>[0],
  boolean
>

type OnlyBlockForUrl = string | RegExp | (() => boolean)

type RegisterBlocker = (
  key: string,
  shouldBlock: BlockerFunction,
  onlyBlockForUrl?: OnlyBlockForUrl | OnlyBlockForUrl[],
) => void
type DeregisterBlocker = (key: string) => void

export type BlockerContextType = {
  registerBlocker: RegisterBlocker
  deregisterBlocker: DeregisterBlocker
  blocker: ReturnType<typeof useReactRouterBlocker>
}

export const BlockerContext = React.createContext<
  BlockerContextType | undefined
>(undefined)

export const useBlocker = (
  key: string,
  shouldBlock: BlockerFunction,
  // If this is set, the block check will only be performed if the current URL matches the given string, RegExp, or
  // function, or any of them if it's an array.
  onlyBlockForUrl?: OnlyBlockForUrl | OnlyBlockForUrl[],
) => {
  const { blocker, registerBlocker, deregisterBlocker } =
    useStrictContext(BlockerContext)

  useEffect(() => {
    registerBlocker(key, shouldBlock, onlyBlockForUrl)
    return () => {
      deregisterBlocker(key)
    }
  }, [deregisterBlocker, key, registerBlocker, shouldBlock, onlyBlockForUrl])

  return blocker
}

type BlockerWrapperProps = React.PropsWithChildren

export const BlockerWrapper = React.memo<BlockerWrapperProps>(
  ({ children }) => {
    const [blockerMap, setBlockerMap] = useState<
      Record<
        string,
        {
          shouldBlock: BlockerFunction
          onlyBlockForUrl?: OnlyBlockForUrl | OnlyBlockForUrl[]
        }
      >
    >({})

    const registerBlocker = useCallback<RegisterBlocker>(
      (key, shouldBlock, onlyBlockForUrl) => {
        setBlockerMap(prev => ({
          ...prev,
          [key]: { shouldBlock, onlyBlockForUrl },
        }))
      },
      [],
    )

    const deregisterBlocker = useCallback<DeregisterBlocker>(key => {
      setBlockerMap(prev => {
        const newBlockerMap = { ...prev }
        delete newBlockerMap[key]
        return newBlockerMap
      })
    }, [])

    const shouldBlock = useCallback<BlockerFunction>(
      props => {
        for (const key of R.keys(blockerMap)) {
          const { shouldBlock, onlyBlockForUrl } = blockerMap[key]

          if (onlyBlockForUrl) {
            const matches = Array.isArray(onlyBlockForUrl)
              ? onlyBlockForUrl
              : [onlyBlockForUrl]
            for (const urlMatch of matches) {
              if (
                typeof urlMatch === 'string' &&
                !window.location.href.includes(urlMatch)
              ) {
                continue
              }
              if (
                urlMatch instanceof RegExp &&
                !urlMatch.test(window.location.href)
              ) {
                continue
              }

              if (typeof urlMatch === 'function' && !urlMatch()) {
                continue
              }
            }
          }
          if (shouldBlock(props)) {
            return true
          }
        }
        return false
      },
      [blockerMap],
    )

    const blocker = useReactRouterBlocker(shouldBlock)

    return (
      <BlockerContext.Provider
        value={{ registerBlocker, deregisterBlocker, blocker }}
      >
        {children}
      </BlockerContext.Provider>
    )
  },
)
