// NOTE: Do "291.60 * 100" in the Chrome Dev Console. You'll get 29160.000000000004. That's because IEEE 754 sucks some
// times. Any time you do any kind of arithmetic with IEEE 754 you could end up with a .999999999999 or a .000000000001
// or something like that. The number coming out of here HAS to be an integer. ChatGPT astutely suggested simply using
// `Math.round` because if we know the output should always be an integer, it will always solve those precision issues.
// Note if we ever have a USD number with fractions of a cent, this could fuck shit up and put us in an Office Space
// situation where money disappears.
/** @deprecated Only use US Cents (integer) for transfer units */
export const usdToUsCents = (usdAmount: number) => Math.round(usdAmount * 100)
/** @deprecated Only use US Cents (integer) for transfer units */
export const usCentsToUsd = (uscAmount: number) => uscAmount / 100

/** For handling Amounts of US Cents that are non-integers. */
export const usCentsWithFractionalsToUsd = (uscAmount: number) => {
  const roundedAbsoluteValueUsd = Math.round(Math.abs(uscAmount)) / 100
  return uscAmount >= 0 ? roundedAbsoluteValueUsd : -roundedAbsoluteValueUsd
}

/** @deprecated Only use US Cents (integer) for transfer units */
export const isFullyPaidUsd = (paidAmountUsd: number, totalAmountUsd: number) =>
  usdToUsCents(Number(paidAmountUsd)) >= usdToUsCents(Number(totalAmountUsd))

/** @deprecated Only use US Cents (integer) for transfer units */
export const usdMultiply = (amountUsd: number, multiplier: number) => {
  if (multiplier === 0) return 0
  if (multiplier === 1) return amountUsd

  const cents = usdToUsCents(amountUsd)
  const multipliedPennies = cents * multiplier
  const toNextPenny = Math.ceil(multipliedPennies)
  const finalAmountUsd = usCentsToUsd(toNextPenny)
  return finalAmountUsd
}

/** @deprecated Only use US Cents (integer) for transfer units */
export const usdDivide = (usdAmount: number, divisor: number) => {
  if (divisor === 1) return usdAmount

  const usCents = usdToUsCents(usdAmount)
  const dividedRawAmount = usCents / divisor
  const toNextPennyUpAmount = Math.ceil(dividedRawAmount)
  const finalUsdAmount = usCentsToUsd(toNextPennyUpAmount)
  return finalUsdAmount
}

export const uscDivide = (uscAmount: number, divisor: number) => {
  if (divisor === 1) return uscAmount

  return Math.round(uscAmount / divisor)
}

export const uscMultiply = (uscAmount: number, multiplier: number) => {
  if (multiplier === 0) return 0
  if (multiplier === 1) return uscAmount

  return Math.round(uscAmount * multiplier)
}

/** @deprecated Only use US Cents (integer) for transfer units */
export const usdAdd = (amountUsd: number, ...addendsUsd: number[]) => {
  const sumPennies = addendsUsd.reduce((prev, curr) => prev + usdToUsCents(curr), usdToUsCents(amountUsd))
  const finalAmountUsd = usCentsToUsd(sumPennies)
  return finalAmountUsd
}

/** @deprecated Only use US Cents (integer) for transfer units */
export const usdRoundUpToNextPenny = (usdAmount: number) => {
  const pennies = usdToUsCents(usdAmount)
  const toNextPenny = Math.ceil(pennies)
  const finalAmountUsd = usCentsToUsd(toNextPenny)
  return finalAmountUsd
}

export const formatUsc = (uscAmount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(usCentsToUsd(uscAmount))
}

/** For when we want a money amount to be concise. If it doesn't have
 * any fractional values, it'll show 0 decimals, otherwise it'll show
 * 2 decimals.
 */
export const formatUscConcise = (uscAmount: number) => {
  const hasDecimalPart = uscAmount % 100 !== 0
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hasDecimalPart ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(usCentsToUsd(uscAmount))
}

export const formatUscWholeDollars = (uscAmount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(usCentsToUsd(uscAmount))
}

/** @deprecated Only use US Cents (integer) for transfer units */
export const formatMoney = (usdAmount: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(usdAmount)

/** @deprecated Only use US Cents (integer) for transfer units */
export const usdRoundUpToNextDollar = (usdAmount: number) => {
  return Math.ceil(usdAmount)
}

/** @deprecated Only use US Cents (integer) for transfer units */
export const formatUsdWholeDollars = (usdAmount: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(usdAmount)

export const usdStringToUsCents = (usdString: string): number => {
  const numberString = usdString.replace(/[^0-9.-]+/g, '')
  const usd = parseFloat(numberString)
  return usdToUsCents(usd)
}

export const formatAbbreviatedUsc = (uscAmount: number): string => {
  // Convert cents to dollars
  const dollars = usCentsToUsd(uscAmount)

  let hasDecimal = true
  let suffix = ''
  let divisor = 1

  if (dollars < 1_000) {
    hasDecimal = false
  } else if (dollars < 1_000_000) {
    suffix = 'K'
    divisor = 1_000
  } else {
    suffix = 'M'
    divisor = 1_000_000
  }

  return `${(dollars / divisor).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: hasDecimal ? 1 : 0,
  })}${suffix}`
}
