import { DocumentType, gql } from 'src/generated/user'

export const COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query JobDetailsPageCompanyConfigQuery($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      companyGuid
      accountManagerEnabled
    }
  }
`)

export const LINKED_JOB_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription JobDetailsPageLinkedJobDataQuery($jobGuid: uuid!) {
    jobsWithLinkedJob(where: { jobGuid: { _eq: $jobGuid } }) {
      jobGuid
      linkNotes
      linkPhotos
      linkAttachments
      linked_job {
        jobGuid
        displayId
        noteLinks {
          noteGuid
        }
        photoLinks {
          photoGuid
          photo {
            cdnUrl
            resourceUrn
            createdAt
            createdByUserGuid
          }
        }
      }
    }
  }
`)

export type LinkedJobData = DocumentType<typeof LINKED_JOB_DATA_SUBSCRIPTION>

export const LINKED_JOBS_QUERY = gql(/* GraphQL */ `
  query JobDetailsPageLinkedJobsQuery($jobGuid: uuid!) {
    jobsWithLinkedJob(where: { jobGuid: { _eq: $jobGuid } }) {
      linked_job {
        displayId
        jobGuid
        createdAt
        jobType {
          name
        }
        location {
          address {
            line1
            line2
            city
            stateAbbreviation
            zipCode
            addressGuid
          }
        }
        jobLifecycleStatus {
          name
        }
      }
    }
  }
`)

export const LINKS_TO_JOBS_QUERY = gql(/* GraphQL */ `
  query JobDetailsPageLinkedToJobsQuery($jobGuid: uuid!) {
    jobsWithLinkedJob(where: { linkedJobGuid: { _eq: $jobGuid } }) {
      job {
        displayId
        jobGuid
        createdAt
        jobType {
          name
        }
        location {
          address {
            line1
            line2
            city
            stateAbbreviation
            zipCode
            addressGuid
          }
        }
        jobLifecycleStatus {
          name
        }
      }
    }
  }
`)
