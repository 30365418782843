import { Account, isVisitUnused, JobClass } from '@breezy/shared'
import { Form, FormInstance } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import React, { useEffect, useRef } from 'react'
import { useLocationActiveMaintenancePlans } from '../../hooks/useLocationActiveMaintenancePlans'
import { JobMaintenancePlanVisitToggle } from '../JobMaintenancePlanVisitToggle/JobMaintenancePlanVisitToggle'
import { LoadingSpinner } from '../LoadingSpinner'
import { CreateOrEditNewJobFormSchema } from './CreateOrEditNewJobFormSchema'

type CreateOrEditJobMaintenancePlanVisitsFieldProps = {
  form: FormInstance<CreateOrEditNewJobFormSchema>
  account: Account
  jobClass?: JobClass
  locationGuid: string
}

export const CreateOrEditJobMaintenancePlanVisitsField =
  React.memo<CreateOrEditJobMaintenancePlanVisitsFieldProps>(
    ({ form, account, jobClass, locationGuid }) => {
      const { maintenancePlans, fetching } = useLocationActiveMaintenancePlans({
        accountGuid: account.accountGuid,
        locationGuid,
      })
      const activeMaintenancePlanVisitGuid = useWatch(
        'maintenancePlanVisitGuid',
        form,
      )

      const hasSetDefaultRef = useRef(false)

      useEffect(() => {
        if (
          !hasSetDefaultRef.current &&
          jobClass === JobClass.MAINTENANCE &&
          maintenancePlans.length > 0
        ) {
          const defaultVisit = maintenancePlans
            .flatMap(mp => mp.visits)
            .find(v => isVisitUnused(v))
          if (defaultVisit) {
            form.setFieldValue(
              'maintenancePlanVisitGuid',
              defaultVisit.visitGuid,
            )
            hasSetDefaultRef.current = true
          }
        }
      }, [form, jobClass, maintenancePlans])

      if (fetching) return <LoadingSpinner />

      if (maintenancePlans.length === 0) return null

      return (
        <Form.Item name="maintenancePlanVisitGuid">
          <JobMaintenancePlanVisitToggle
            maintenancePlans={maintenancePlans}
            activeMaintenancePlanVisitGuid={activeMaintenancePlanVisitGuid}
            onVisitSelected={(maintenancePlanVisitGuid: string | undefined) => {
              form.setFieldValue(
                'maintenancePlanVisitGuid',
                maintenancePlanVisitGuid,
              )
            }}
            dataTestId="create-or-edit-job-maintenance-plan-visits-field"
            mode="form"
          />
        </Form.Item>
      )
    },
  )
