import { LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import React from 'react'
import useIsMobile from '../../../hooks/useIsMobile'
import {
  ESTIMATE_MESSAGE_GENERATOR_PRESETS,
  EstimateMessageGeneratorPreset,
} from '../hooks/useGenerateEstimateMessage'

type EstimateMessageGeneratorProps = {
  isGenerating: boolean
  onGenerateMessage: (preset: EstimateMessageGeneratorPreset) => Promise<void>
}

const WandIcon = () => (
  <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m7.313 2.344.437-1.188C7.781 1.063 7.875 1 8 1c.094 0 .188.063.219.156l.437 1.188 1.188.437A.235.235 0 0 1 10 3c0 .125-.063.219-.156.25l-1.188.438-.437 1.156A.235.235 0 0 1 8 5c-.125 0-.219-.063-.25-.156l-.438-1.157-1.156-.437C6.063 3.219 6 3.125 6 3c0-.094.063-.188.156-.219l1.157-.437ZM1.438 13.375 13.344 1.469a1.49 1.49 0 0 1 2.125 0L16.53 2.53a1.49 1.49 0 0 1 0 2.125L4.625 16.563a1.49 1.49 0 0 1-2.125 0L1.437 15.5a1.49 1.49 0 0 1 0-2.125Zm13.687-9.781-.719-.719-3.281 3.281.719.719 3.281-3.281ZM.219 4.688 2 4l.656-1.75C2.687 2.094 2.844 2 3 2c.125 0 .281.094.313.25L4 4l1.75.688c.156.03.25.187.25.312 0 .156-.094.313-.25.344L4 6l-.688 1.781C3.283 7.906 3.126 8 3 8c-.156 0-.313-.094-.344-.219L2 6 .219 5.344C.094 5.312 0 5.156 0 5c0-.125.094-.281.219-.313Zm11 8L13 12l.656-1.75c.031-.156.188-.25.344-.25.125 0 .281.094.313.25L15 12l1.75.688c.156.03.25.187.25.312 0 .156-.094.313-.25.344L15 14l-.688 1.781c-.03.125-.187.219-.312.219-.156 0-.313-.094-.344-.219L13 14l-1.781-.656C11.094 13.313 11 13.156 11 13c0-.125.094-.281.219-.313Z"
      fill="url(#a)"
    />
    <defs>
      <radialGradient
        id="a"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 11 -11 0 9 6)"
      >
        <stop stopColor="#69B1FF" />
        <stop offset="1" stopColor="#0053AC" />
      </radialGradient>
    </defs>
  </svg>
)

export const EstimateMessageGenerator =
  React.memo<EstimateMessageGeneratorProps>(
    ({ isGenerating, onGenerateMessage }) => {
      const isMobile = useIsMobile()

      return (
        <div className="mt-4 overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg bg-bz-fill-quaternary px-2 py-1">
            {isGenerating ? (
              <div className="flex h-[32px] items-center space-x-2">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                />
                <span className="text-sm font-medium text-gray-600">
                  Generating estimate message...
                </span>
              </div>
            ) : (
              <div className="flex items-center space-x-3 whitespace-nowrap">
                <div className="flex flex-shrink-0 items-center space-x-1">
                  <WandIcon />
                  <span className="text-base font-semibold text-[#2876CB]">
                    {isMobile ? 'AI' : 'Breezy AI'}
                  </span>
                </div>
                <div className="flex space-x-2 overflow-x-auto">
                  {ESTIMATE_MESSAGE_GENERATOR_PRESETS.map(preset => (
                    <Button
                      key={preset}
                      onClick={() => onGenerateMessage(preset)}
                      disabled={isGenerating}
                      className="flex-shrink-0"
                    >
                      {preset}
                    </Button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    },
  )
