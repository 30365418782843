import {
  Address,
  BzDateFns,
  CardOnFile,
  CardOnFileSchema,
  TimeZoneId,
  bzOptional,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import AddressLineOneField from '../../../../elements/Forms/AddressLineOneField'
import FormBody from '../../../../elements/Forms/FormBody'
import FormDivider from '../../../../elements/Forms/FormDivider'
import FormHeader from '../../../../elements/Forms/FormHeader'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { StateField, StateSchema } from '../../../../elements/Forms/StateField'
import { TextField } from '../../../../elements/Forms/TextField'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import {
  SetIsDirty,
  useExternalIsDirty,
} from '../../../../hooks/useExternalIsDirty'
import { preventFormSubmitOnEnter } from '../../../../utils/form-helpers'
import { PaymentWorkflowFormStyle } from '../../PaymentWorkflowWizard'
import { ZipCodeSchema } from '../../utils/ZipCodeSchema'
import PaymentFooterButtons from '../PaymentFooterButtons/PaymentFooterButtons'
import TilledSandboxCreditCardDevTools from '../TilledSandboxCreditCardDevTools/TilledSandboxCreditCardDevTools'
import CardOnFileRadioButtonListField from './CardOnFileRadioButtonListField'
import SavePaymentMethodField from './SavePaymentMethodField'
import SendEmailField from './SendEmailField'

const CreditCardPaymentFormSchema = z
  .object({
    selectedCardOnFile: bzOptional(CardOnFileSchema),
    name: bzOptional(z.string().min(1).describe('Name on card')),
    streetAddress: bzOptional(
      z.string().min(1).describe('Street address or PO Box'),
    ),
    streetAddress2: bzOptional(z.string().describe('Apt, Unit, Building #')),
    city: bzOptional(z.string().min(1).describe('City')),
    state: bzOptional(StateSchema.describe('State')),
    zipCode: bzOptional(ZipCodeSchema),
    savePaymentMethod: z.boolean(),
    sendEmail: z.boolean(),
    emailAddress: bzOptional(z.string().email().describe('Email address')),
  })
  .refine(
    data => {
      if (!data.selectedCardOnFile) {
        return (
          data.name &&
          data.streetAddress &&
          data.city &&
          data.state &&
          data.zipCode
        )
      }
      return true
    },
    {
      message: 'Required fields are missing',
      path: ['name', 'streetAddress', 'city', 'state', 'zipCode'],
    },
  )

export type CreditCardPaymentFormData = z.infer<
  typeof CreditCardPaymentFormSchema
>

type CreditCardPaymentFormProps = {
  onSubmit: (data: CreditCardPaymentFormData) => void
  onSubmitValidationCheck: () => boolean
  onCancel?: () => void
  streetAddress?: string
  city?: string
  state?: string
  zipCode?: string
  name?: string
  formStyle: PaymentWorkflowFormStyle
  className?: string
  footerClassName?: string
  primaryButtonText?: string
  isLoading?: boolean
  withAddressTypeAhead?: boolean
  withSendEmail?: boolean
  withSavePaymentMethod?: boolean
  setIsDirty?: SetIsDirty
} & (
  | {
      cardsOnFile: CardOnFile[]
      tzId: TimeZoneId
    }
  | {
      cardsOnFile?: never
      tzId?: never
    }
)

export const CreditCardPaymentForm = React.memo<
  React.PropsWithChildren<CreditCardPaymentFormProps>
>(
  ({
    onSubmit,
    onSubmitValidationCheck,
    onCancel,
    streetAddress,
    city,
    state,
    zipCode,
    name,
    cardsOnFile,
    className,
    children,
    setIsDirty,
    primaryButtonText = 'Save',
    isLoading = false,
    formStyle = 'inline',
    footerClassName,
    withAddressTypeAhead = false,
    withSendEmail = false,
    withSavePaymentMethod = false,
    tzId,
  }) => {
    const creditCardPaymentForm = useForm<CreditCardPaymentFormData>({
      defaultValues: {
        name: name ?? undefined,
        streetAddress: streetAddress ?? undefined,
        city: city ?? undefined,
        state: state ?? undefined,
        zipCode: zipCode ?? undefined,
        savePaymentMethod: false,
        sendEmail: false,
        emailAddress: undefined,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(CreditCardPaymentFormSchema),
    })

    const {
      control,
      formState: { errors, isValid, isDirty },
      handleSubmit,
      setValue,
      watch,
      trigger,
    } = creditCardPaymentForm

    const selectedCardOnFile = watch('selectedCardOnFile')

    useExternalIsDirty(setIsDirty, isDirty)

    const ourOnSubmit = useCallback(
      (data: CreditCardPaymentFormData) => {
        const hasCardOnFile = !!data.selectedCardOnFile

        if (hasCardOnFile) {
          onSubmit(data)
        } else if (onSubmitValidationCheck()) {
          // Only run the tilled submit validation if we're not using a card on file
          onSubmit(data)
        }
      },
      [onSubmit, onSubmitValidationCheck],
    )

    const onStateAbbreviationChange = useCallback(
      (state: string) => {
        setValue('state', state)
      },
      [setValue],
    )

    const onAddressChanged = useCallback(
      (addr: Address | string) => {
        if (typeof addr === 'string') {
          setValue('streetAddress', addr)
        } else {
          setValue('state', addr.stateAbbreviation)
          setValue('city', addr.city)
          setValue('zipCode', addr.zipCode)
          setValue('streetAddress', addr.line1)
          setValue('streetAddress2', addr.line2 ?? '')
          trigger()
        }
      },
      [setValue, trigger],
    )

    return (
      <FormProvider {...creditCardPaymentForm}>
        <Form
          className={classNames('flex min-h-0 flex-1 flex-col', className)}
          layout="vertical"
          onSubmitCapture={handleSubmit(ourOnSubmit)}
          onKeyDown={preventFormSubmitOnEnter}
        >
          <FormBody
            paddingClassName={formStyle === 'inline' ? 'pb-1' : undefined}
          >
            <RenderIf if={!!cardsOnFile}>
              <CardOnFileRadioButtonListField
                cardsOnFile={cardsOnFile ?? []}
                tzId={tzId ?? BzDateFns.UTC}
              />
            </RenderIf>
            <div className="relative">
              <FormHeader>Payment Information</FormHeader>
              <TilledSandboxCreditCardDevTools />
            </div>
            <div className="flex flex-col gap-2">
              <ReactHookFormItem
                control={control}
                className="flex-1"
                name="name"
                label="Name on card"
                required
                errors={errors}
                render={({ field }) => (
                  <TextField
                    {...field}
                    ddActionName="BZ Payment Workflow - Credit Card - Name on Card"
                  />
                )}
              />
              {children}
            </div>
            <FormDivider />
            <FormHeader>Billing Address</FormHeader>
            <div className="mt-3 flex flex-col gap-2">
              <ReactHookFormItem
                control={control}
                name="streetAddress"
                required
                label="Street address or PO Box"
                errors={errors}
                render={({ field }) => {
                  return withAddressTypeAhead ? (
                    <AddressLineOneField
                      showTypedAddress
                      size="large"
                      {...field}
                      onAddressChanged={onAddressChanged}
                    />
                  ) : (
                    <TextField {...field} />
                  )
                }}
              />
              <ReactHookFormItem
                control={control}
                name="streetAddress2"
                label="Apt, Unit, Building #"
                errors={errors}
                render={({ field }) => <TextField {...field} />}
              />
              <div className="grid grid-cols-2 gap-3 md:grid-cols-3">
                <ReactHookFormItem
                  className="col-span-2 md:col-span-1"
                  control={control}
                  name="city"
                  label="City"
                  required
                  errors={errors}
                  render={({ field }) => <TextField {...field} />}
                />
                <ReactHookFormItem
                  className="col-span-1"
                  control={control}
                  name="state"
                  label="State"
                  required
                  errors={errors}
                  render={({ field }) => (
                    <StateField
                      {...field}
                      onChange={onStateAbbreviationChange}
                    />
                  )}
                />
                <ReactHookFormItem
                  className="col-span-1"
                  control={control}
                  name="zipCode"
                  label="Zip Code"
                  required
                  errors={errors}
                  render={({ field }) => <TextField {...field} />}
                />
              </div>
            </div>

            <RenderIf if={withSavePaymentMethod}>
              <FormDivider />
              <SavePaymentMethodField />
            </RenderIf>

            <RenderIf if={withSendEmail}>
              <FormDivider />
              <SendEmailField />
              <FormDivider />
            </RenderIf>
          </FormBody>
          <PaymentFooterButtons
            onCancel={onCancel}
            isLoading={isLoading}
            dataDdActionName="BZ Payment Workflow - Pay - Credit Card"
            disabled={!selectedCardOnFile && !isValid}
            formStyle={formStyle}
            footerClassName={footerClassName}
            primaryButtonText={primaryButtonText}
          />
        </Form>
      </FormProvider>
    )
  },
)
