import { useCallback, useMemo } from 'react'
import { TilledInput } from '../../../elements/Tilled/TilledInput/TilledInput'
import useTilledForm from '../../../hooks/useTilled'
import { useExpectedTilledMerchantId } from '../../../providers/CompanyFinancialConfigWrapper'
import { TilledFormFieldChangeHandler } from '../../../utils/tilledSdkTypes'
import { useTilledFormState } from './useTilledFormState'

const BANK_ACCOUNT_NUMBER_ID = 'bz-payment-bank-account-number'
const BANK_ROUTING_NUMBER_ID = 'bz-payment-bank-routing-number'
/* A headless hook that handles the state of the Tilled ACH form */
const useTilledAchFormState = () => {
  const tilledMerchantId = useExpectedTilledMerchantId()

  const [bankAccountNumberInputState, setBankAccountNumberInputState] =
    useTilledFormState('invalid')
  const [bankRoutingNumberInputState, setBankRoutingNumberInputState] =
    useTilledFormState('invalid')

  const onSubmitValidationCheck = useCallback(() => {
    let valid = true
    for (const [state, setter] of [
      [bankAccountNumberInputState, setBankAccountNumberInputState],
      [bankRoutingNumberInputState, setBankRoutingNumberInputState],
    ] as const) {
      if (state !== 'valid') {
        setter('invalid-and-shown')
        valid = false
      }
    }
    return valid
  }, [
    bankAccountNumberInputState,
    bankRoutingNumberInputState,
    setBankAccountNumberInputState,
    setBankRoutingNumberInputState,
  ])

  const onTilledFieldChange = useCallback<TilledFormFieldChangeHandler>(
    event => {
      switch (event.fieldType) {
        case 'bankAccountNumber':
          setBankAccountNumberInputState(event.valid ? 'valid' : 'invalid')
          break
        case 'bankRoutingNumber':
          setBankRoutingNumberInputState(event.valid ? 'valid' : 'invalid')
          break
      }
    },
    [setBankAccountNumberInputState, setBankRoutingNumberInputState],
  )

  const {
    loading: loadingTilled,
    error: tilledError,
    value: tilledFormInfo,
  } = useTilledForm(
    tilledMerchantId,
    {
      type: 'ach_debit',
      fields: {
        bankAccountNumber: { selector: `#${BANK_ACCOUNT_NUMBER_ID}` },
        bankRoutingNumber: { selector: `#${BANK_ROUTING_NUMBER_ID}` },
      },
    },
    onTilledFieldChange,
  )

  return {
    bankAccountNumberInputState,
    bankRoutingNumberInputState,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  }
}

export const useTilledAchForm = () => {
  const {
    bankAccountNumberInputState,
    bankRoutingNumberInputState,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  } = useTilledAchFormState()

  const accountNumberElement = useMemo(() => {
    return (
      <TilledInput
        label="Account Number"
        id={BANK_ACCOUNT_NUMBER_ID}
        invalid={bankAccountNumberInputState === 'invalid-and-shown'}
      />
    )
  }, [bankAccountNumberInputState])

  const routingNumberElement = useMemo(() => {
    return (
      <TilledInput
        label="Routing Number"
        id={BANK_ROUTING_NUMBER_ID}
        invalid={bankRoutingNumberInputState === 'invalid-and-shown'}
      />
    )
  }, [bankRoutingNumberInputState])

  return {
    accountNumberElement,
    routingNumberElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  }
}
