import {
  BzDateFns,
  CardOnFile,
  TimeZoneId,
  getCardOnFileDisplayName,
  isCardExpired,
} from '@breezy/shared'
import React from 'react'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import { CardOnFileStatusTag } from '../../../CardOnFileStatusTag/CardOnFileStatusTag'
type CardOnFilePreviewProps = {
  cardOnFile: CardOnFile
  tzId: TimeZoneId
}

const CardOnFilePreview = React.memo<CardOnFilePreviewProps>(
  ({ cardOnFile, tzId }) => {
    return (
      <div className="flex items-center gap-2">
        <div className="flex flex-1 @container/card-on-file">
          <div className="flex flex-col">
            <div className="flex items-center font-semibold">
              {getCardOnFileDisplayName(cardOnFile)}
            </div>
            <div className="text-sm text-bz-gray-700">
              Exp. {cardOnFile.paymentMethodCardMetadata?.expMonth ?? 'MM'}/
              {cardOnFile.paymentMethodCardMetadata?.expYear ?? 'YYYY'}
              <RenderIf if={!!cardOnFile.paymentMethodBillingInfo?.name}>
                <span className="ml-1 inline-block">{` • ${cardOnFile.paymentMethodBillingInfo?.name} •`}</span>
                <span className="ml-1 inline-block">{`Added ${BzDateFns.format(
                  BzDateFns.parseISO(cardOnFile.createdAt, tzId),
                  'MM/dd/yyyy',
                )}`}</span>
              </RenderIf>
            </div>
          </div>
        </div>
        <RenderIf if={isCardExpired(cardOnFile)}>
          <div className="flex items-center justify-end">
            <CardOnFileStatusTag cardOnFile={cardOnFile} />
          </div>
        </RenderIf>
      </div>
    )
  },
)

export default CardOnFilePreview
