import {
  BzDateFns,
  BzDateTime,
  bzExpect,
  getNumUnusedMaintenancePlanVisits,
  isNullish,
  JobClass,
  MaintenancePlanDetailsViewModel,
  MaintenancePlanPaymentFlow,
  MaintenancePlanPaymentInterval,
  MaintenancePlanStatus,
  PermissionV2,
  usCentsToUsd,
} from '@breezy/shared'
import {
  faOctagonExclamation,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import AccountManagerBanner from '../../components/Accounts/AccountManagerBanner/AccountManagerBanner'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import EmptyColumnThree from '../../components/EmptyColumnThree/EmptyColumnThree'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import NotesListCard from '../../components/Notes/NotesListCard/NotesListCard'
import BzTabs, {
  BzTab,
  useURLSettableTabIndex,
} from '../../components/Page/BzTabs/BzTabs'
import TagColumn from '../../components/Page/Columns/TagColumn/TagColumn'
import { Page } from '../../components/Page/Page'
import ThreeColumnTemplate from '../../components/Page/ThreeColumnTemplate'
import PaymentWorkflowWizard, {
  PaymentSuccessHandler,
  PaymentSuccessMethod,
} from '../../components/PaymentWorkflow/PaymentWorkflowWizard'
import { usePaymentStatusModalContext } from '../../components/PaymentWorkflow/providers/PaymentStatusModalProvider'
import {
  Authorized,
  NotAuthorized,
} from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import LocationCollapsibles from '../../components/collapsibles/LocationCollapsiblesGroup/LocationCollapsiblesGroup'
import { ActionItem } from '../../elements/ActionItems/ActionItems'
import {
  Banner,
  BannerIcon,
  BannerStatusMessage,
} from '../../elements/Banner/Banner'
import BzColumn from '../../elements/BzColumn/BzColumn'
import { useFetchAbridgedInvoiceByMaintenancePlanGuid } from '../../hooks/fetch/useFetchAbridgedInvoiceByMaintenancePlanGuid'
import { useFetchComprehensiveLocationDetails } from '../../hooks/fetch/useFetchComprehensiveLocationDetails'
import { useCanViewEntityHistory } from '../../hooks/permission/useCanViewEntityHistory'
import { useCanViewLocation } from '../../hooks/permission/useCanViewLocation'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useMerchantId,
} from '../../providers/PrincipalUser'
import { DetailsPageProps } from '../../utils/Refetchable'
import { useMessage } from '../../utils/antd-utils'
import { m, useModalState } from '../../utils/react-utils'
import { MaintenancePlanActivatedModal } from '../CreateOrEditMaintenancePlanPage/MaintenancePlanActivatedModal'
import { MaintenancePlanCancelModal } from './MaintenancePlanCancelModal'
import { MaintenancePlanCollapsiblesGroup } from './MaintenancePlanCollapsiblesGroup'
import MaintenancePlanInfoCard from './MaintenancePlanInfoCard'
import { MaintenancePlanRenewModal } from './MaintenancePlanRenewModal'

const MAINTENANCE_PLAN_TAB_KEY = 'tab'

const MaintenancePlanDetailsPageAuthWrapper = m(() => {
  return (
    <Page requiresCompanyUser={true}>
      <Authorized to={PermissionV2.READ_COMPANY_USERS}>
        <MaintenancePlanDetailsPageLoader />
      </Authorized>
      <NotAuthorized to={PermissionV2.READ_COMPANY_USERS}>
        <>
          You do not have permission to view the resource that is being
          requested
        </>
      </NotAuthorized>
    </Page>
  )
})

type MaintenancePlanStatusBannerProps = {
  status: MaintenancePlanStatus
  onClick: () => void
  className?: string
}

const maintenancePlanStatusWithCta = [
  MaintenancePlanStatus.PENDING,
  MaintenancePlanStatus.LAPSED,
  MaintenancePlanStatus.EXPIRED,
]

const MaintenancePlanStatusBanner =
  React.memo<MaintenancePlanStatusBannerProps>(
    ({ status, className, onClick }) => {
      const info = useMemo(() => {
        switch (status) {
          case MaintenancePlanStatus.PENDING:
            return (
              <BannerStatusMessage
                boldText="This plan has not been paid for."
                regularText="Complete the activation process by collecting payment."
              />
            )
          case MaintenancePlanStatus.LAPSED:
            return (
              <BannerStatusMessage
                boldText="A payment for this plan has lapsed."
                regularText="Please update the payment information to try again."
              />
            )
          case MaintenancePlanStatus.EXPIRED:
            return (
              <BannerStatusMessage
                boldText="This plan has expired and needs to be renewed"
                regularText={`for service to continue. Reach out to the customer and click "Renew Plan".`}
              />
            )
          default:
            return null
        }
      }, [status])

      const cta = useMemo(() => {
        switch (status) {
          case MaintenancePlanStatus.PENDING:
            return 'Collect Payment'
          case MaintenancePlanStatus.LAPSED:
            return 'Update Payment Information'
          case MaintenancePlanStatus.EXPIRED:
            return 'Renew Plan'
        }
      }, [status])

      if (!maintenancePlanStatusWithCta.includes(status)) {
        return null
      }

      return (
        <Banner
          className={classNames('items-center', className)}
          color="orange"
        >
          <div className="flex items-start gap-3 md:items-center">
            <BannerIcon icon={faOctagonExclamation} />
            {info}
          </div>
          <Button
            className="ml-10 md:ml-0"
            type="primary"
            size="large"
            onClick={onClick}
          >
            {cta}
          </Button>
        </Banner>
      )
    },
  )

const MaintenancePlanDetailsPage = m<
  DetailsPageProps<MaintenancePlanDetailsViewModel>
>(({ data, refetch }) => {
  const message = useMessage()
  const vm = data
  const tzId = useExpectedCompanyTimeZoneId()
  const appNav = useAppNavigation()
  const canViewEntityHistory = useCanViewEntityHistory()
  const merchantId = useMerchantId()
  const companyGuid = useExpectedCompanyGuid()
  const [isCanceling, setIsCanceling] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [renewModalOpen, openRenewModal, closeRenewModal] = useModalState(false)

  const [activatedModalOpen, showActivatedModal, hideActivatedModal] =
    useModalState(false)

  const [paymentWorkflowOpen, showPaymentWorkflow, hidePaymentWorkflow] =
    useModalState(false)
  const [
    paymentSubscriptionWorkflowOpen,
    showPaymentSubscriptionWorkflow,
    hidePaymentSubscriptionWorkflow,
  ] = useModalState(false)

  const useMpV3 = useFeatureFlag('createMaintenancePlanV2')
  const [activatedMaintenancePlanMeta, setActivatedMaintenancePlanMeta] =
    useState<
      | { paymentMethod: PaymentSuccessMethod; paymentAmountUsc: number }
      | undefined
    >()

  const oneShotInvoiceLoader = useFetchAbridgedInvoiceByMaintenancePlanGuid({
    maintenancePlanGuid: data.maintenancePlanGuid,
  })

  const tabs = useMemo<BzTab[]>(
    () => [
      {
        title: 'Notes',
        content: vm.maintenancePlanGuid && (
          <NotesListCard
            linkData={{
              primaryNoteType: 'MAINTENANCE_PLAN',
              maintenancePlanGuid: vm.maintenancePlanGuid,
            }}
            title=""
          />
        ),
      },
    ],
    [vm],
  )

  const [activeTabIndex, setActiveTabIndex] = useURLSettableTabIndex(
    tabs,
    0,
    MAINTENANCE_PLAN_TAB_KEY,
  )

  const retryPaymentMut =
    trpc.maintenancePlans['maintenance-plans:retry-payment'].useMutation()
  const retryPayment = useCallback(async () => {
    if (!merchantId) {
      message.error(
        'Payment Module not enabled. Please speak with your back office team to activate Payments.',
      )
      return
    }

    if (!vm.paymentSubscriptionGuid) {
      message.error('No active payment subscription found')
      return
    }

    setIsUploading(true)
    try {
      await retryPaymentMut.mutateAsync({
        merchantId,
        companyGuid,
        paymentSubscriptionGuid: vm.paymentSubscriptionGuid,
      })
      message.success('Retried payment succeeded')
      refetch()
    } catch (e) {
      message.error(
        `Retried payment failed: '${(e as Error)?.message ?? 'Unknown error'}'`,
      )
    }
    setIsUploading(false)
  }, [
    retryPaymentMut,
    merchantId,
    companyGuid,
    refetch,
    vm.paymentSubscriptionGuid,
    message,
  ])

  const actions: ActionItem[] = []

  if (!useMpV3 && data.status === MaintenancePlanStatus.CANCELED) {
    actions.push({
      title: 'Reactivate Plan',
      onClick: () =>
        appNav.navigateToMaintenancePlanPayPage(data.maintenancePlanGuid),
    })
  }

  if (useMpV3 && data.status === MaintenancePlanStatus.EXPIRED) {
    actions.push({
      title: 'Renew Plan',
      onClick: () => openRenewModal(),
    })
  }

  if (data.status === MaintenancePlanStatus.PENDING) {
    actions.push({
      title: 'Collect Payment',
      onClick: () =>
        appNav.navigateToMaintenancePlanPayPage(data.maintenancePlanGuid),
    })
  }

  if (
    data.paymentSubscriptionGuid &&
    (data.status === MaintenancePlanStatus.LAPSED ||
      data.status === MaintenancePlanStatus.ACTIVE)
  ) {
    actions.push({
      title: 'Update Payment Method',
      onClick: () =>
        appNav.navigateToMaintenancePlanPayPage(data.maintenancePlanGuid, true),
    })
  }

  if (
    merchantId &&
    data.status === MaintenancePlanStatus.LAPSED &&
    data.paymentSubscriptionGuid
  ) {
    actions.push({
      title: 'Retry Payment',
      onClick: retryPayment,
    })
  }

  if (
    data.status !== MaintenancePlanStatus.CANCELED &&
    data.status !== MaintenancePlanStatus.EXPIRED
  ) {
    actions.push({
      title: <span className="text-red-600">Cancel Plan</span>,
      onClick: () => setIsCanceling(true),
    })
  }

  if (canViewEntityHistory) {
    actions.push({
      title: 'View History',
      onClick: () =>
        appNav.navigateToEntityHistoryViewer(data.maintenancePlanGuid),
    })
  }

  const hasAvailableVisits = useMemo(() => {
    return getNumUnusedMaintenancePlanVisits(data.visits ?? []) > 0
  }, [data.visits])

  const columnOne = (
    <SectionedCard
      className="pt-2"
      noBorder
      accentBarColor={vm.planDefinition?.flare?.primaryColorHex ?? '#13C2C2'}
      sections={[
        {
          content: vm && (
            <MaintenancePlanInfoCard
              data={vm}
              actionItems={actions}
              refetch={refetch}
              showScheduleVisit={hasAvailableVisits}
            />
          ),
        },
      ]}
    />
  )

  const columnOneUploading = useMemo(
    () => (
      <TagColumn
        title="Maintenance Plan"
        icon={faScrewdriverWrench}
        headerCssClass={'bg-daybreak-blue-500'}
      >
        <div className="h-full w-full">
          <div className="flex h-full flex-col items-center justify-center">
            <LoadingSpinner />
            <div className="mt-4 text-center text-lg font-semibold">
              Cancelling Plan...
            </div>
          </div>
        </div>
      </TagColumn>
    ),
    [],
  )

  const onCancelCancellation = useCallback(() => setIsCanceling(false), [])
  const onCancellationComplete = useCallback(() => {
    setIsCanceling(false)
    refetch()
  }, [refetch])

  const activeTab = tabs[activeTabIndex]

  const columnTwo = (
    <BzColumn>
      <BzTabs
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {activeTab.content}
    </BzColumn>
  )

  const { hidePaymentStatusModal } = usePaymentStatusModalContext()
  const onPaymentSuccess = useCallback<PaymentSuccessHandler>(
    (paymentMethod, paymentAmountUsc) => {
      showActivatedModal()
      hidePaymentStatusModal()
      hidePaymentWorkflow()
      setActivatedMaintenancePlanMeta({ paymentMethod, paymentAmountUsc })
      refetch()
    },
    [hidePaymentStatusModal, hidePaymentWorkflow, refetch, showActivatedModal],
  )

  const onPaymentSubscriptionSuccess = useCallback<PaymentSuccessHandler>(
    (paymentMethod, paymentAmountUsc) => {
      showActivatedModal()
      hidePaymentStatusModal()
      hidePaymentSubscriptionWorkflow()
      setActivatedMaintenancePlanMeta({ paymentMethod, paymentAmountUsc })
      refetch()
    },
    [
      hidePaymentStatusModal,
      hidePaymentSubscriptionWorkflow,
      refetch,
      showActivatedModal,
    ],
  )

  const onCtaClick = useCallback(() => {
    switch (data.status) {
      case MaintenancePlanStatus.PENDING: {
        // This means the plan was configured to auto-renew, but the user
        // has not yet paid.
        if (data.paymentFlow === MaintenancePlanPaymentFlow.NONE) {
          showPaymentSubscriptionWorkflow()
        } else {
          if (!oneShotInvoiceLoader.invoice) {
            message.error('Missing invoice. Contact Breezy Support.')
            break
          } else {
            showPaymentWorkflow()
          }
        }
        break
      }
      case MaintenancePlanStatus.LAPSED:
        appNav.navigateToMaintenancePlanPayPage(data.maintenancePlanGuid, true)
        break
      case MaintenancePlanStatus.EXPIRED:
        openRenewModal()
        break
    }
  }, [
    appNav,
    data.maintenancePlanGuid,
    data.paymentFlow,
    data.status,
    message,
    oneShotInvoiceLoader.invoice,
    openRenewModal,
    showPaymentSubscriptionWorkflow,
    showPaymentWorkflow,
  ])
  return (
    <>
      <div className="w-full">
        <AccountManagerBanner />
        <BehindFeatureFlag
          enabledFeatureFlag="createMaintenancePlanV2"
          render={
            <MaintenancePlanStatusBanner
              className="mb-4"
              status={data.status}
              onClick={onCtaClick}
            />
          }
        />
        <ThreeColumnTemplate
          columnOne={isUploading ? columnOneUploading : columnOne}
          columnTwo={columnTwo}
          columnThree={<ThirdColumn data={data} />}
        />
      </div>
      <MaintenancePlanCancelModal
        item={
          isCanceling
            ? {
                vm,
                onSubmit: onCancellationComplete,
                onClose: onCancelCancellation,
              }
            : undefined
        }
      />
      {renewModalOpen && (
        <MaintenancePlanRenewModal
          maintenancePlan={vm}
          onClose={closeRenewModal}
        />
      )}
      {paymentWorkflowOpen && oneShotInvoiceLoader.invoice && (
        <PaymentWorkflowWizard
          meta={{
            invoice: oneShotInvoiceLoader.invoice,
            invoiceAmountDueUsd: usCentsToUsd(
              oneShotInvoiceLoader.invoice.amountDueUsc,
            ),
            editableAmount: false,
          }}
          onPaymentSuccess={onPaymentSuccess}
          onCancel={hidePaymentWorkflow}
          mode="base"
        />
      )}
      {paymentSubscriptionWorkflowOpen && (
        <PaymentWorkflowWizard
          meta={{
            editableAmount: false,
            maintenancePlan: {
              maintenancePlanGuid: data.maintenancePlanGuid,
              accountGuid: data.accountGuid,
              companyGuid: data.companyGuid,
              paymentInterval:
                data.paymentInterval ?? MaintenancePlanPaymentInterval.YEARLY,
              totalPriceUsc: data.paymentAmountUsc,
              taxRateGuid: data.pricebookTaxRateGuid ?? null,
              activationDate: data.activatedAt
                ? BzDateFns.formatLocalDate(
                    BzDateFns.parseISO(data.activatedAt, tzId),
                  )
                : BzDateTime.now(tzId).toLocalDateString(),
            },
          }}
          onPaymentSuccess={onPaymentSubscriptionSuccess}
          hiddenOptions={['Payment Link']}
          onCancel={hidePaymentSubscriptionWorkflow}
          disabledOptions={[
            {
              option: 'Cash',
              info: 'Cannot be used for auto-renewing plans',
            },
            {
              option: 'Check',
              info: 'Cannot be used for auto-renewing plans',
            },
            {
              option: 'Other',
              info: 'Cannot be used for auto-renewing plans',
            },
          ]}
        />
      )}
      {activatedModalOpen && data.planDefinition && (
        <MaintenancePlanActivatedModal
          primaryContactEmail={data.primaryContactEmail}
          maintenancePlanGuid={data.maintenancePlanGuid}
          planDefinition={data.planDefinition}
          data={{
            isFreePlan: false,
            isAutoRenewing: true,
            isImportedPlan: false,
            billingPaymentInterval:
              data.paymentInterval ?? MaintenancePlanPaymentInterval.MONTHLY,
            billingAnchorDay: BzDateTime.now(tzId)
              .toJsJodaLocalDate()
              .dayOfMonth()
              .toString(),
            activationDate: BzDateTime.now(tzId).toLocalDateString(),
            accountGuid: data.accountGuid,
            locationGuid: data.locationGuid,
          }}
          onClose={hideActivatedModal}
          paymentMethod={activatedMaintenancePlanMeta?.paymentMethod}
          paymentAmountUsc={activatedMaintenancePlanMeta?.paymentAmountUsc}
          hideViewPlanDetails
          closeText="Close"
        />
      )}
    </>
  )
})

const ThirdColumn = m<{ data: MaintenancePlanDetailsViewModel }>(({ data }) => {
  const locationGuid = useMemo(() => {
    return data?.locationGuid
  }, [data])
  const { canView: canViewLocation, isLoading } =
    useCanViewLocation(locationGuid)

  return isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <LoadingSpinner />
    </div>
  ) : canViewLocation ? (
    <AuthedThirdColumn data={data} />
  ) : (
    <EmptyColumnThree />
  )
})

const AuthedThirdColumn = m<{ data: MaintenancePlanDetailsViewModel }>(
  ({ data: maintenancePlanData }) => {
    const locationGuid = useMemo(() => {
      return maintenancePlanData?.locationGuid
    }, [maintenancePlanData])
    const collapsiblesQuery = useFetchComprehensiveLocationDetails({
      locationGuid,
      opts: { enabled: !!locationGuid },
    })

    const maintenancePlanVisitLinkedJobGuids = useMemo(() => {
      return (
        maintenancePlanData.visits
          ?.map(v => v.visitJob?.jobGuid)
          .filter(j => !isNullish(j)) ?? []
      )
    }, [maintenancePlanData.visits])

    return (
      (locationGuid && (
        <TrpcQueryLoader
          query={collapsiblesQuery}
          render={data => (
            <BehindFeatureFlag
              enabledFeatureFlag="createMaintenancePlanV2"
              render={
                <MaintenancePlanCollapsiblesGroup
                  maintenancePlanGuid={maintenancePlanData.maintenancePlanGuid}
                  data={data}
                  maintenancePlanVisitLinkedJobGuids={
                    maintenancePlanVisitLinkedJobGuids
                  }
                  refetch={collapsiblesQuery.refetch}
                />
              }
              fallback={
                <LocationCollapsibles
                  data={data}
                  refetch={collapsiblesQuery.refetch}
                  defaultJobClass={JobClass.MAINTENANCE}
                  hideMaintenancePlans
                />
              }
            />
          )}
        />
      )) || <EmptyColumnThree />
    )
  },
)

const MaintenancePlanDetailsPageLoader = m(() => {
  const maintenancePlanGuid = bzExpect(
    useParams().maintenancePlanGuid,
    'maintenancePlanGuid',
    'MaintenancePlanGuid is required',
  )

  const query = trpc.maintenancePlans['maintenance-plans:get-details'].useQuery(
    { maintenancePlanGuid },
  )

  return (
    <TrpcQueryLoader
      query={query}
      render={data => (
        <MaintenancePlanDetailsPage data={data} refetch={query.refetch} />
      )}
    />
  )
})

export default MaintenancePlanDetailsPageAuthWrapper
