import { AbridgedInvoiceMetadataWithAmountDue } from '@breezy/shared/src'
import {
  mapInvoiceToAbidgedInvoiceMetadata,
  useFetchInvoicesSubscription,
} from '../../pages/Invoices/invoiceUtils'

type AbridgedInvoiceMetadataLoaderReturn = {
  invoice?: AbridgedInvoiceMetadataWithAmountDue
  loading: boolean
}

export const useFetchAbridgedInvoiceByMaintenancePlanGuid = ({
  maintenancePlanGuid,
  pause,
}: {
  maintenancePlanGuid: string
  pause?: boolean
}): AbridgedInvoiceMetadataLoaderReturn => {
  const res = useFetchInvoicesSubscription(
    {
      maintenancePlanLink: {
        maintenancePlanGuid: {
          _eq: maintenancePlanGuid,
        },
      },
    },
    pause,
  )

  const rawInvoice =
    res.data && (res.data.invoices?.length ?? 0) > 0
      ? res.data.invoices[0]
      : undefined
  const invoice = rawInvoice
    ? mapInvoiceToAbidgedInvoiceMetadata(rawInvoice.invoiceGuid, rawInvoice)
    : undefined

  return {
    invoice,
    loading: res.fetching,
  }
}
