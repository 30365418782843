import { CompanyTechnicianPerformanceConfig } from '@breezy/shared'
import { UseQueryExecute } from 'urql'
import { FETCH_COMPANY_TECHNICIAN_PERFORMANCE_CONFIG } from '../../pages/TechnicianPerformanceSettingsPage/TechnicianPerformanceSettings.gql'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { useQueryMaxAttempts } from '../useQueryMaxAttempts'

const fallbackConfig: CompanyTechnicianPerformanceConfig = {
  technicianPerformanceEnabled: false,
}

export const useFetchTechnicianPerformanceConfig = (): [
  CompanyTechnicianPerformanceConfig,
  UseQueryExecute,
] => {
  const companyGuid = useExpectedPrincipal()?.company?.companyGuid

  const [{ data, fetching }, refetch, maxAttemptsReached] = useQueryMaxAttempts(
    {
      query: FETCH_COMPANY_TECHNICIAN_PERFORMANCE_CONFIG,
      pause: !companyGuid,
      variables: { companyGuid: companyGuid ?? '' },
      requestPolicy: 'network-only',
    },
  )

  if (fetching) {
    return [fallbackConfig, refetch]
  }
  if (maxAttemptsReached) {
    return [fallbackConfig, refetch]
  }

  return [data?.companyConfig[0] ?? fallbackConfig, refetch]
}
