import { z } from 'zod'
import { AsyncFn, nextGuid } from '../../../common'
import { AccountGuid } from '../../Accounts/Account'
import { Address } from '../../Address/Address'
import { CompanyGuidContainer, ForCompany } from '../../Company/Company'
import { ICompanyPublish } from '../../Company/CompanyAtoms'
import { BzDateTime, UTC_TIME_ZONE } from '../../DateTime/BzDateTime'
import { ToEmailAddressContainer, emailAddressValueSchema } from '../../Email/EmailTypes'
import { JobGuid } from '../../Job'
import { MaintenancePlanGuid } from '../../MaintenancePlans/MaintenancePlanTypes'
import { Guid, bzOptional } from '../../common-schemas'
import { CartItem, CartOrderSummary } from '../Transactions/TransactionTypes'
import { PaymentMethod, PaymentRecordGuid, PaymentRecordGuidContainer } from './PaymentTypes'

export type ReceiptLineItem = Pick<CartItem, 'name' | 'quantity' | 'unitPriceUsd'>

export type ReceiptOrderSummary = Omit<CartOrderSummary, 'creditAmountUsd'> & {
  readonly lineItems: ReceiptLineItem[]
}

export type ReceiptCompanyInformation = {
  readonly companyName: string
  readonly companyLogoUrl?: string
}

export type PaymentReceipt = ReceiptCompanyInformation &
  ReceiptOrderSummary & {
    readonly paymentRecordGuid: PaymentRecordGuid
    readonly paymentDateTime: BzDateTime
    readonly paymentMethod: PaymentMethod
    readonly paymentAmountUsd: number
    readonly isFullPayment: boolean
    readonly invoiceDisplayId: string
    readonly invoiceTotalAmountUsd: number
    readonly serviceAddress?: Address
    readonly accountGuid: AccountGuid
    readonly jobGuid?: JobGuid
    readonly maintenancePlanGuid?: MaintenancePlanGuid
    readonly paymentSubscriptionGuid?: Guid
  }

export type PaymentReceiptReader = AsyncFn<ForCompany<PaymentRecordGuidContainer>, PaymentReceipt>
export type PaymentReceiptEmailRequest = PaymentReceipt & ToEmailAddressContainer & CompanyGuidContainer
export type IPaymentReceiptEmailer = AsyncFn<PaymentReceiptEmailRequest>

export const paymentReceiptEmailPublishRequestSchema = z.object({
  paymentRecordGuid: z.string(),
  to: bzOptional(emailAddressValueSchema),
})

export type PaymentReceiptEmailPublishRequest = PaymentRecordGuidContainer & Partial<ToEmailAddressContainer>
export type IPaymentReceiptEmailPublisher = ICompanyPublish<PaymentReceiptEmailPublishRequest>

export const samplePaymentReceiptForTests: PaymentReceipt = {
  companyName: 'HVAC Company',
  companyLogoUrl: 'https://breezy-nonprod.b-cdn.net/logos/hvac_logo.jpeg',
  subtotalPriceUsd: 1200,
  discountAmountUsd: 50,
  taxAmountUsd: 120,
  totalPriceUsd: 1270,
  lineItems: [],
  isFullPayment: false,
  paymentMethod: PaymentMethod.CARD,
  paymentAmountUsd: 800,
  paymentDateTime: BzDateTime.now(UTC_TIME_ZONE),
  invoiceDisplayId: '#2333',
  invoiceTotalAmountUsd: 1270,
  paymentRecordGuid: nextGuid(),
  accountGuid: nextGuid(),
  maintenancePlanGuid: nextGuid(),
  paymentSubscriptionGuid: nextGuid(),
  serviceAddress: {
    line1: '123 Main St',
    line2: 'Apt 1',
    city: 'New York',
    stateAbbreviation: 'NY',
    zipCode: '10001',
  },
}
