import { isNullish } from '@breezy/shared'
import { InputNumber } from 'antd'
import React, { forwardRef, useCallback } from 'react'
import { Styled } from '../../utils/Stylable'

type UsdInputNumberProps = Readonly<{
  min?: number
  style?: React.CSSProperties
  disabled?: boolean
  className?: string
  value: number | undefined
  // Use `any` type because that's the typing that react-hook-form's Controller uses
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
}>

const priceSimpleRegex = /[^\d.-]/g
const cleanedFloatValue = (value: string | undefined, min?: number): number => {
  const intermediate = (value ?? '').replace(priceSimpleRegex, '')
  const num = Number.parseFloat(intermediate)
  if (isNaN(num)) {
    return min ?? 0
  }

  if (!isNullish(min) && num < min) {
    return min
  }

  return num
}
const PARSER = cleanedFloatValue

export const UsdInputNumber = forwardRef<
  HTMLInputElement,
  Styled<UsdInputNumberProps>
>(({ min, disabled, className, ...rest }: Styled<UsdInputNumberProps>, ref) => {
  const parser = useCallback(
    (value: string | undefined): number => {
      return PARSER(value, min)
    },
    [min],
  )

  const formatter = useCallback(
    (formValue: unknown, { userTyping }: { userTyping: boolean }): string => {
      const value = `${formValue}`

      if (userTyping) {
        return value
      }
      const num = cleanedFloatValue(value, min)
      if (isNaN(num)) {
        return ''
      }
      return num.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency',
      })
    },
    [min],
  )

  return (
    <InputNumber
      ref={ref}
      className={className}
      min={min}
      formatter={formatter}
      parser={parser}
      disabled={isNullish(disabled) ? undefined : disabled}
      {...rest}
    />
  )
})
