import { m } from '../../../utils/react-utils'
import { LoadingSpinner } from '../../LoadingSpinner'

const TechAppFullScreenLoadingSpinner = m(() => (
  <div className="column center-children-vh min-h-[80vh]">
    <LoadingSpinner checkLongerThanExpected />
  </div>
))

export default TechAppFullScreenLoadingSpinner
