import { gql } from '../../../generated'

export const ACCOUNT_DROPDOWN_OPTIONS_QUERY = gql(/* GraphQL */ `
  query AccountDropdownOptionsQuery($where: AccountsBoolExp!) {
    accounts(where: $where) {
      accountGuid
      archived
      accountContacts(where: { primary: { _eq: true } }) {
        contact {
          fullName
          primaryPhoneNumber {
            phoneNumber
          }
          primaryEmailAddress {
            emailAddress
          }
        }
      }
      maintenancePlans(limit: 1) {
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
        }
      }
    }
  }
`)
