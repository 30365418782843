import { z } from 'zod'
import { guidSchema } from '../../contracts/_common'
import { LocationGuid } from '../Locations/Location'
import {
  convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary,
  InstalledEquipmentSummary,
  InstalledEquipmentSummaryDto,
} from './InstalledEquipment'

/**
 * There are many many different sizes of filters in HVAC, these are some of the most common ones.
 */
export const COMMON_HVAC_FILTER_SIZES = [
  '24" x 24" x 1"',
  '28" x 30" x 1"',
  '30" x 32" x 1"',
  '24" x 28" x 2"',
  '28" x 30" x 2"',
  '30" x 32" x 2"',
  '30" x 36" x 2"',
  '28" x 30" x 1" (Electronic)',
  '30" x 32" x 1" (Electronic)',
  '30" x 36" x 1" (Electronic)',
]

export const InstalledHvacSystemGuidSchema = guidSchema
export type InstalledHvacSystemGuid = z.infer<typeof InstalledHvacSystemGuidSchema>

export const ZoningInfoSchema = z.object({
  zones: z.array(
    z.object({
      name: z.string(),
    }),
  ),
})
export type ZoningInfo = z.infer<typeof ZoningInfoSchema>

export type InstalledHvacSystem = {
  installedHvacSystemGuid: InstalledHvacSystemGuid
  locationGuid: LocationGuid
  filterSize?: string
  friendlyName?: string
  notes?: string
  zoningInfo: ZoningInfo
  installedEquipment: InstalledEquipmentSummary[]
}

export type InstalledHvacSystemDto = {
  installedHvacSystemGuid: InstalledHvacSystemGuid
  locationGuid: LocationGuid
  filterSize?: string
  friendlyName?: string
  notes?: string
  zoningInfo: ZoningInfo
  installedEquipment: InstalledEquipmentSummaryDto[]
}

export const convertInstalledHvacSystemDtoToInstalledHvacSystem = (
  installedHvacSystemDto: InstalledHvacSystemDto,
): InstalledHvacSystem => {
  return {
    ...installedHvacSystemDto,
    installedEquipment: installedHvacSystemDto.installedEquipment.map(
      convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary,
    ),
  }
}
