import {
  JobOutcomesFormSchema,
  isNullish,
  uscMultiply,
  usdToUsCents,
} from '@breezy/shared'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'

export const useJobCostsDeductionUsc = (
  form: UseFormReturn<JobOutcomesFormSchema>,
  totalSoldRevenueUsc: number,
) => {
  const formJobCostsDeductionPercent = form.watch('jobCostsDeductionPercent')
  const formJobCostsFlatDeductionUsd = form.watch('jobCostsFlatDeductionUsd')
  const formJobCostsDeductionType = form.watch('jobCostsDeductionType')

  return useMemo(() => {
    switch (formJobCostsDeductionType) {
      case 'PERCENT':
        if (!isNullish(formJobCostsDeductionPercent)) {
          const deductionRate = (formJobCostsDeductionPercent ?? 0) / 100
          return uscMultiply(totalSoldRevenueUsc, deductionRate)
        }

        break
      case 'FIXED':
        if (!isNullish(formJobCostsFlatDeductionUsd)) {
          const flatDeduction = usdToUsCents(formJobCostsFlatDeductionUsd ?? 0)
          return flatDeduction
        }

        break
      default:
        return 0
    }

    return 0
  }, [
    formJobCostsDeductionPercent,
    formJobCostsDeductionType,
    formJobCostsFlatDeductionUsd,
    totalSoldRevenueUsc,
  ])
}
