import { TRPCClientError } from '@trpc/client'
import { UiErrorDetail } from '../components/ErrorModal/ErrorModal'

export const toTrpcUiError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: TRPCClientError<any>,
  defaultVerb: string,
): UiErrorDetail => {
  const statusCode = error.data.httpStatus as number
  const action = error.data.path
  if (statusCode === 400)
    return {
      content: error.message,
    }
  if (statusCode === 401)
    return {
      title: 'Unauthorized',
      content: 'You are not authorized',
    }
  if (statusCode === 403)
    return {
      title: 'Forbidden',
      content: `You are not allowed to perform '${action}'`,
    }
  if (statusCode === 404)
    return {
      title: 'Not Found',
      content: 'The requested resource was not found',
    }
  if (statusCode === 500) return { content: error.message }
  return {
    title: `Failed to ${defaultVerb} ${action}`,
    content: error.message,
  }
}
