import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'antd'
import classNames, { default as classnames } from 'classnames'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Highlight, useInstantSearch, useSearchBox } from 'react-instantsearch'
import { m } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { useGlobalSearch } from './GlobalSearchContext'
import {
  GlobalSearchResultError,
  GlobalSearchResultNotFound,
} from './GlobalSearchLoadStates'
import { GlobalSearchResultItemPreview } from './GlobalSearchResultItemPreview'
import { BreezyGlobalSearchResult, useBreezyHits } from './util'

type GlobalSearchResultItemProps = {
  hit: BreezyGlobalSearchResult
  onHover: (result: BreezyGlobalSearchResult) => void
  onAccountClick: (accountGuid: string) => void
  isSelected: boolean
}

const GlobalSearchResultItem = m<GlobalSearchResultItemProps>(
  ({ hit, onHover, isSelected, onAccountClick }) => {
    const { archived } = hit
    return (
      <div
        className="group flex flex-row items-start px-3 py-[6px]"
        style={{
          cursor: isSelected ? 'pointer' : 'default',
          backgroundColor: isSelected ? '#1890FF' : 'transparent',
        }}
        onMouseEnter={() => onHover(hit)}
        onClick={() => onAccountClick(hit.accountGuid)}
      >
        <FontAwesomeIcon
          icon={faUser}
          className="mr-2 self-center text-bz-gray-500"
          scale={1}
        />
        <div
          className={classNames('flex flex-col justify-center font-semibold', {
            'text-bz-text-tertiary': archived && !isSelected,
            'text-white': isSelected,
          })}
        >
          <div className="flex flex-row items-center">
            <Highlight
              hit={hit}
              attribute={'accountDisplayName'}
              classNames={{
                root: isSelected ? 'text-white' : '',
                highlighted: classnames([
                  'text-bz-primary bg-transparent p-0',
                  isSelected ? 'text-white underline underline-offset-2' : '',
                ]),
              }}
            />
            {hit.archived ? <div className="ml-1">(Archived)</div> : undefined}
          </div>

          {hit.contacts.length !== 0 && (
            <span className="mt-1 text-xs">
              {hit.primaryPhoneNumber && (
                <>
                  <Highlight
                    hit={hit}
                    attribute={'primaryPhoneNumber'}
                    classNames={{
                      root: isSelected ? 'text-white' : undefined,
                      highlighted: classnames([
                        'text-bz-primary bg-transparent p-0',
                        isSelected
                          ? 'text-white underline underline-offset-2'
                          : undefined,
                      ]),
                    }}
                  />
                  {hit.primaryEmailAddress && ' • '}
                </>
              )}

              {hit.primaryEmailAddress && (
                <Highlight
                  hit={hit}
                  attribute={'primaryEmailAddress'}
                  classNames={{
                    root: isSelected ? 'text-white' : '#595959',
                    highlighted: classnames([
                      'text-bz-primary bg-transparent p-0',
                      isSelected
                        ? 'text-white underline underline-offset-2'
                        : '',
                    ]),
                  }}
                />
              )}
            </span>
          )}
        </div>

        {isSelected && (
          <div className="ml-auto self-center">
            <FontAwesomeIcon icon={faArrowRight} className="text-white" />
          </div>
        )}
      </div>
    )
  },
)

type GlobalSearchResultListProps = {
  onItemHover: (
    result: BreezyGlobalSearchResult,
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent'],
  ) => void
  onAccountClick: (
    accountGuid: string,
    result: BreezyGlobalSearchResult,
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent'],
  ) => void
  currentlySelectedResult?: BreezyGlobalSearchResult
}

export const GlobalSearchResultList = m<GlobalSearchResultListProps>(
  ({ onItemHover, onAccountClick, currentlySelectedResult }) => {
    const { query, refine } = useSearchBox()
    const { status } = useInstantSearch()
    const { hits, sendEvent } = useBreezyHits({ escapeHTML: true })

    return (
      <div
        className="flex h-full flex-shrink-0 flex-grow-0 flex-col overflow-y-auto overflow-x-hidden bg-bz-gray-100
       md:basis-1/2"
      >
        {status === 'idle' && hits.length === 0 ? (
          <div className="mt-20">
            <GlobalSearchResultNotFound noResultQueryString={query} />
          </div>
        ) : (
          <>
            <div className="bg-bz-gray-300 px-4 py-2">
              <span className="text-xs text-bz-gray-700">
                <strong>ACCOUNTS</strong>
              </span>
            </div>

            {status === 'error' && (
              <GlobalSearchResultError
                icon={faTriangleExclamation}
                title="Well, this is embarrassing..."
                message="There was an issue displaying the search results"
                onTryAgainClick={() => refine(query)}
              />
            )}

            {status === 'loading' && (
              <div className="mt-16">
                <LoadingSpinner />
              </div>
            )}

            {hits.length > 0 && (
              <>
                {hits.map((hit, idx) => (
                  <Fragment key={hit.objectID}>
                    <GlobalSearchResultItem
                      hit={hit}
                      onHover={hit => onItemHover(hit, sendEvent)}
                      onAccountClick={accountGuid =>
                        onAccountClick(accountGuid, hit, sendEvent)
                      }
                      isSelected={
                        !!currentlySelectedResult &&
                        currentlySelectedResult.objectID === hit.objectID
                      }
                    />

                    {idx !== hits.length - 1 && <Divider className="my-0" />}
                  </Fragment>
                ))}
              </>
            )}
          </>
        )}
      </div>
    )
  },
)

export type GlobalSearchResultsProps = {
  onAccountClick: (accountGuid: string) => void
}

export const GlobalSearchResults = m<GlobalSearchResultsProps>(
  ({ onAccountClick }) => {
    const { query } = useSearchBox()
    const { setGlobalSearchVisible } = useGlobalSearch()
    const [selectedResult, setSelectedResult] = useState<
      | {
          hit: BreezyGlobalSearchResult
          sendEvent: ReturnType<typeof useBreezyHits>['sendEvent']
        }
      | undefined
    >()

    const onGlobalSearchClose = useCallback(() => {
      if (query !== '') {
        return
      }

      setGlobalSearchVisible(false)
    }, [query, setGlobalSearchVisible])

    const onCloseGlobalSearchKeyPressed = useCallback(
      (event: KeyboardEvent) => {
        if (event.key !== 'Escape') {
          return
        }

        setGlobalSearchVisible(false)
      },
      [setGlobalSearchVisible],
    )

    useEffect(() => {
      window.addEventListener('keyup', onCloseGlobalSearchKeyPressed)
      return () =>
        window.removeEventListener('keyup', onCloseGlobalSearchKeyPressed)
    }, [onCloseGlobalSearchKeyPressed])

    useEffect(() => {
      if (query !== '') {
        return
      }

      setSelectedResult(undefined)
    }, [query])

    return (
      <div
        className="flex h-full w-full flex-row overflow-y-auto overflow-x-hidden rounded-b-lg"
        onClick={onGlobalSearchClose}
      >
        {query !== '' && (
          <>
            <GlobalSearchResultList
              onItemHover={(hit, sendEvent) =>
                setSelectedResult({ hit, sendEvent })
              }
              currentlySelectedResult={selectedResult?.hit}
              onAccountClick={accountGuid => {
                if (selectedResult) {
                  selectedResult.sendEvent(
                    'conversion',
                    selectedResult.hit,
                    'Navigated via Result List',
                  )
                }
                onAccountClick(accountGuid)
              }}
            />
            <GlobalSearchResultItemPreview
              result={selectedResult}
              onAccountClick={accountGuid => {
                if (selectedResult) {
                  selectedResult.sendEvent(
                    'conversion',
                    selectedResult.hit,
                    'Navigated via Result List',
                  )
                }
                onAccountClick(accountGuid)
              }}
            />
          </>
        )}
      </div>
    )
  },
)
