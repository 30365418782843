import { z } from 'zod'
import { AsyncFn } from '../../common'
import { AccountGuidContainer } from '../Accounts/Account'
import { ForCompany } from '../Company/Company'
import { Email } from '../Email/EmailTypes'
import { UserGuidContainer } from '../Users/User'
import { bzOptional, ForAddonFields } from '../common-schemas'

export type ToTechnicianMessage = UserGuidContainer & { message: string }
export type TechnicianMessenger = AsyncFn<ForCompany<ToTechnicianMessage>>

export type ToAccountPrimaryContactEmail = Omit<Email, 'to'> &
  AccountGuidContainer & { errorIfNoEmailAddress?: boolean }
export type AccountPrimaryContactEmailer = AsyncFn<ForCompany<ToAccountPrimaryContactEmail>>

export const basicBrandedEmailRequestBaseSchema = z.object({
  emailAddress: z.string().email(),
  body: z.string(),
  subject: z.string(),
})

export const basicBrandedEmailRequestSchema = basicBrandedEmailRequestBaseSchema.extend({
  method: z.literal('EMAIL'),
})
export const basicBrandedEmailRequestWithBillingProfileSchema = basicBrandedEmailRequestBaseSchema.extend({
  method: z.literal('EMAIL_WITH_BILLING_PROFILE'),
  companyLogoUrl: z.string(),
  contactName: bzOptional(z.string()),
  contactPhoneNumber: bzOptional(z.string()),
  contactEmailAddress: bzOptional(z.string()),
  websiteUrl: bzOptional(z.string()),
  websiteDisplayName: bzOptional(z.string()),
})

export const basicBrandedSMSRequestSchema = z.object({
  phoneNumber: z.string(),
  body: z.string(),
  method: z.literal('SMS'),
})

export const basicBrandedMessengerRequestSchema = z.discriminatedUnion('method', [
  basicBrandedEmailRequestSchema,
  basicBrandedEmailRequestWithBillingProfileSchema,
  basicBrandedSMSRequestSchema,
])

export type BasicBrandedEmailRequest = z.infer<typeof basicBrandedEmailRequestSchema>
export type BasicBrandedEmailWithBillingProfileRequest = z.infer<
  typeof basicBrandedEmailRequestWithBillingProfileSchema
>
export type BasicBrandedSMSRequest = z.infer<typeof basicBrandedSMSRequestSchema>
export type BasicBrandedMessengerRequest = z.infer<typeof basicBrandedMessengerRequestSchema>

export type BasicBrandedMessenger = AsyncFn<ForAddonFields<'companyGuid' | 'companyName', BasicBrandedMessengerRequest>>
