import { isEstimateOverviewPath } from '../../pages/EstimatesFlow/estimatesFlowUtils'
import {
  BackButtonOverrideResolver,
  HistoryStackLocation,
  getUrlFromHistoryStackLocation,
} from './BackButtonOverridesWrapper'

const isCreatePage = (location: HistoryStackLocation) =>
  getUrlFromHistoryStackLocation(location).pathname.endsWith('/new-estimate')

const isAcceptedPage = (location: HistoryStackLocation) => {
  const url = getUrlFromHistoryStackLocation(location)

  return isEstimateOverviewPath(url.pathname) && !!url.searchParams.get('a')
}

const isPresentingPage = (location: HistoryStackLocation) => {
  const url = getUrlFromHistoryStackLocation(location)
  return isEstimateOverviewPath(url.pathname) && !!url.searchParams.get('p')
}

const isOverviewPage = (location: HistoryStackLocation) => {
  const url = getUrlFromHistoryStackLocation(location)
  return isEstimateOverviewPath(url.pathname)
}

export const estimateBackButtonOverrideResolver: BackButtonOverrideResolver = (
  previousPage,
  historyStack,
) => {
  const newPage = historyStack[historyStack.length - 1]

  // If we landed on the create page...
  if (isCreatePage(newPage)) {
    // If we went from the create page to some other page on the create page, then don't skip anything.
    if (isCreatePage(previousPage)) {
      return 0
    }
    // Otherwise, skip all create pages
    let count = 2
    while (isCreatePage(historyStack[historyStack.length - count])) {
      count++
    }
    // The count includes the page that we checked that WASN'T a create page. We need to do -1 because we DON'T want to
    // skip that one.
    return count - 1
  }

  // If we came from the accepting page, or we land on the accepted page from outside the estimates world, skip any of
  // the presenting-related pages
  if (
    isAcceptedPage(previousPage) ||
    (!isOverviewPage(previousPage) && isAcceptedPage(newPage))
  ) {
    let count = 1

    while (isPresentingPage(historyStack[historyStack.length - count])) {
      count++
    }
    // Similar to above, count is counting the last page looked at, which did NOT match
    return count - 1
  }

  return 0
}
