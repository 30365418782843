import { formatPercentage, PricebookTaxRateDto } from '@breezy/shared'
import { faCoins, faEdit } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import {
  ApplyDynamicPricingSection,
  ApplyDynamicPricingSectionProps,
} from '../../../components/ApplyDynamicPricingSection/ApplyDynamicPricingSection'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'

type TaxAndDynamicSectionProps = ApplyDynamicPricingSectionProps & {
  customerFacing?: boolean
  taxRate: PricebookTaxRateDto
  openTaxRateEdit?: () => void
}

export const TaxAndDynamicSection = React.memo<TaxAndDynamicSectionProps>(
  ({
    customerFacing,
    taxRate,
    openTaxRateEdit,
    dynamicPricingType,
    setDynamicPricingType,
  }) => {
    const dynamicPricingEnabled = useFeatureFlag('dynamic-pricing')

    const showDynamicPricing = dynamicPricingEnabled && !customerFacing

    return (
      <OnsitePageSection
        title={showDynamicPricing ? 'Tax Rate & Dynamic Pricing' : 'Tax Rate'}
      >
        <OnsitePageSimpleSectionItem
          icon={<FontAwesomeIcon icon={faCoins} />}
          rightContent={
            openTaxRateEdit ? (
              <Button
                size="large"
                icon={<FontAwesomeIcon icon={faEdit} />}
                onClick={openTaxRateEdit}
              >
                Edit
              </Button>
            ) : null
          }
        >
          {`${formatPercentage(taxRate.rate)} (${taxRate.name})`}
        </OnsitePageSimpleSectionItem>
        {showDynamicPricing && (
          <ApplyDynamicPricingSection
            dynamicPricingType={dynamicPricingType}
            setDynamicPricingType={setDynamicPricingType}
          />
        )}
      </OnsitePageSection>
    )
  },
)
