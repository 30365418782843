import {
  AbridgedInvoiceMetadata,
  PaymentMethod,
  PaymentStatus,
  nextGuid,
  usCentsToUsd,
} from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import Switch from '../../../../elements/Switch/Switch'
import { trpc } from '../../../../hooks/trpc'
import { SetIsDirty } from '../../../../hooks/useExternalIsDirty'
import { useInvalidateInvoiceQuery } from '../../../../hooks/useInvalidateInvoiceQuery'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../../../providers/PrincipalUser'
import { useFetchInvoiceIdentifyingInfo } from '../../../Invoicing/InvoiceView/useFetchInvoiceIdentifyingInfo'
import {
  PaymentAmountUsc,
  defaultPaymentWorkflowFooterPaddingStyles,
  defaultPaymentWorkflowFooterStyles,
  usePaymentWorkflowContext,
} from '../../PaymentWorkflowWizard'
import { useInvoiceLinks } from '../../hooks/useInvoiceLinks'
import { usePaymentStatusModalContext } from '../../providers/PaymentStatusModalProvider'
import { usePaymentStatusContext } from '../../providers/PaymentStatusProvider'
import CashForm from '../CashForm/CashForm'
import CheckForm from '../CheckForm/CheckForm'
import OtherPaymentForm from '../OtherPaymentForm/OtherPaymentForm'

type OtherPaymentWorkflowProps<PaymentMethodType extends PaymentMethod> = {
  paymentAmountUsc: PaymentAmountUsc
  invoice: AbridgedInvoiceMetadata
  onCancel: (force?: boolean) => void
  paymentMethod: PaymentMethodType
  setIsDirty?: SetIsDirty
}

export type OtherPaymentDto = {
  externalPaymentId?: string
  paymentMethodAdditionalInfo?: string
  note?: string
}

const OtherPaymentWorkflow = <PaymentMethodType extends PaymentMethod>({
  paymentAmountUsc,
  invoice,
  onCancel,
  paymentMethod,
  setIsDirty,
}: OtherPaymentWorkflowProps<PaymentMethodType>) => {
  const { showPaymentStatusModal } = usePaymentStatusModalContext()
  const tzId = useExpectedCompanyTimeZoneId()
  const { mode, onPaymentSuccess, onPaymentError } = usePaymentWorkflowContext()
  const invalidate = useInvalidateInvoiceQuery()
  const companyGuid = useExpectedCompanyGuid()
  const { isProcessingPayment } = usePaymentStatusContext()
  const { setIsProcessingPayment } = usePaymentWorkflowContext()
  const links = useInvoiceLinks(invoice.invoiceGuid)
  const { accountGuid } = invoice

  const identifyingInfoQuery = useFetchInvoiceIdentifyingInfo(
    accountGuid,
    links.jobGuid,
  )

  const nameOfPayer = useMemo(() => {
    return (
      identifyingInfoQuery.data?.jobsByPk?.pointOfContact?.fullName ??
      identifyingInfoQuery.data?.accountsByPk?.accountDisplayName ??
      ''
    )
  }, [identifyingInfoQuery.data])

  const recordPaymentMutation = trpc.payments['payments:record'].useMutation()
  const onSubmit = useCallback(
    async (paymentMeta: OtherPaymentDto) => {
      setIsProcessingPayment(true)
      const paymentRecordGuid = nextGuid()
      try {
        await recordPaymentMutation.mutateAsync({
          ...links,
          invoiceGuid: invoice.invoiceGuid,
          accountGuid: accountGuid,
          companyGuid,
          paymentRecordGuid,
          status: PaymentStatus.PAID,
          statusDetail: PaymentStatus.PAID,
          invoiceReferenceNumber: `${invoice.displayId}`,
          amountUsd: usCentsToUsd(paymentAmountUsc),
          externalPaymentId: paymentMeta.externalPaymentId,
          note: paymentMeta.note,
          paymentMethodAdditionalInfo: paymentMeta.paymentMethodAdditionalInfo,
          paymentMethod,
        })
        // Show the Payment Completed Modal w/ the success state
        showPaymentStatusModal({
          mode,
          type: 'payment-success',
          data: {
            nameOfPayer,
            paymentAmountUsc: paymentAmountUsc,
            paymentMethod,
            invoiceDisplayId: invoice.displayId,
            emailAddressLinks: {
              accountGuid,
              jobGuid: links.jobGuid,
            },
            paymentRecordGuid,
            tzId,
            companyGuid,
          },
        })
        onPaymentSuccess?.(paymentMethod, paymentAmountUsc)
        onCancel(true)
      } catch (error) {
        // Handle record payment error
        console.error('Error recording other payment', error)
        showPaymentStatusModal({
          mode,
          type: 'payment-error',
          data: {
            nameOfPayer,
            paymentAmountUsc,
            paymentMethod,
            errorMessage:
              (error as Error)?.message ??
              'An unknown error occurred while recording payment. Contact support for assistance.',
          },
        })
        onPaymentError?.()
      }
      setIsProcessingPayment(false)
      invalidate()
    },
    [
      setIsProcessingPayment,
      invalidate,
      recordPaymentMutation,
      links,
      invoice.invoiceGuid,
      invoice.displayId,
      accountGuid,
      companyGuid,
      paymentAmountUsc,
      paymentMethod,
      showPaymentStatusModal,
      mode,
      nameOfPayer,
      tzId,
      onPaymentSuccess,
      onCancel,
      onPaymentError,
    ],
  )

  return (
    <Switch value={paymentMethod}>
      {{
        [PaymentMethod.CHECK]: () => (
          <CheckForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isProcessingPayment}
            setIsDirty={setIsDirty}
            footerClassName={classNames(
              defaultPaymentWorkflowFooterStyles,
              defaultPaymentWorkflowFooterPaddingStyles,
            )}
          />
        ),
        [PaymentMethod.CASH]: () => (
          <CashForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isProcessingPayment}
            setIsDirty={setIsDirty}
            footerClassName={classNames(
              defaultPaymentWorkflowFooterStyles,
              defaultPaymentWorkflowFooterPaddingStyles,
            )}
          />
        ),
        [PaymentMethod.OTHER]: () => (
          <OtherPaymentForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isProcessingPayment}
            setIsDirty={setIsDirty}
            footerClassName={classNames(
              defaultPaymentWorkflowFooterStyles,
              defaultPaymentWorkflowFooterPaddingStyles,
            )}
          />
        ),
        default: () => null,
      }}
    </Switch>
  )
}

export default React.memo(OtherPaymentWorkflow) as typeof OtherPaymentWorkflow
