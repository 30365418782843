import { Input } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { useStringFieldInfo, useTsController } from '@ts-react/form'
import BaseTextField from '../BaseTextField/BaseTextField'

const DEFAULT_MAX_LENGTH = 160

type SmsMessageTextAreaProps = {
  maxLength?: number
}

const SmsMessageTextArea = React.memo<SmsMessageTextAreaProps>(
  ({ maxLength = DEFAULT_MAX_LENGTH }) => {
    const {
      field: { onChange, value, name },
    } = useTsController<string>()

    const { placeholder } = useStringFieldInfo()

    const length = value?.length ?? 0

    return (
      <BaseTextField>
        <Input.TextArea
          id={name}
          rows={10}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
        />
        <div
          className={classNames(
            'mt-1 text-xs text-bz-gray-700 transition-all ease-in-out',
            {
              'text-red-500': length > maxLength,
            },
          )}
        >
          {length}/{maxLength}
        </div>
      </BaseTextField>
    )
  },
)

export default SmsMessageTextArea
