import { twilioPhoneNumberToBreezyPhone } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { useCompanyGuid } from '../../providers/PrincipalUser'
import { FETCH_CONTACTS_BY_PHONE_NUMBER_GQL } from './ContactByPhoneNumber.gql'

export type ContactLite = {
  accountGuid: string
  contactGuid: string
  fullName: string
  firstName: string
  lastName: string
}

export const useFetchContactLiteByPhoneNumber = (
  phoneNumber: string | undefined,
): ContactLite | undefined => {
  const companyGuid = useCompanyGuid()
  const breezyFormattedPhoneNumber = phoneNumber
    ? twilioPhoneNumberToBreezyPhone(phoneNumber)
    : ''

  const [{ data }] = useQuery({
    query: FETCH_CONTACTS_BY_PHONE_NUMBER_GQL,
    variables: {
      phoneNumber: breezyFormattedPhoneNumber,
      companyGuid: companyGuid ?? '',
    },
    pause: !breezyFormattedPhoneNumber || !companyGuid,
  })

  const contact = useMemo(() => {
    return (
      data?.phoneNumbers[0]?.contacts[0] ??
      data?.phoneNumbers[0]?.contactsAdditionalPhone[0]
    )
  }, [data])

  const contactLite = useMemo(() => {
    if (
      !contact ||
      !contact.accountContacts ||
      contact.accountContacts.length === 0
    )
      return undefined

    return {
      accountGuid: contact?.accountContacts[0]?.accountGuid ?? '',
      contactGuid: contact?.contactGuid ?? '',
      fullName: contact?.fullName ?? '',
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
    }
  }, [contact])

  return contactLite
}
