import { useMutation } from 'urql'
import { gql } from '../../generated'
const UPSERT_INSTALLED_HVAC_SYSTEM_AND_LINKED_EQUIPMENT_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertInstallHvacSystemAndLinkedEquipment(
      $installedHvacSystemGuid: uuid!
      $locationGuid: uuid!
      $filterSize: String
      $friendlyName: String
      $notes: String
      $zoningInfoJson: jsonb!
      $linkedInstalledEquipmentGuids: [uuid!]!
    ) {
      insertInstalledHvacSystemsOne(
        object: {
          installedHvacSystemGuid: $installedHvacSystemGuid
          locationGuid: $locationGuid
          filterSize: $filterSize
          friendlyName: $friendlyName
          notes: $notes
          zoningInfoJson: $zoningInfoJson
        }
        onConflict: {
          constraint: installed_hvac_systems_pkey
          updateColumns: [filterSize, friendlyName, notes, zoningInfoJson]
        }
      ) {
        __typename
      }
      addNewEquipmentLinks: updateInstalledEquipment(
        _set: { installedHvacSystemGuid: $installedHvacSystemGuid }
        where: {
          installedEquipmentGuid: { _in: $linkedInstalledEquipmentGuids }
        }
      ) {
        __typename
      }
      removeOldEquipmentLinks: updateInstalledEquipment(
        _set: { installedHvacSystemGuid: null }
        where: {
          installedEquipmentGuid: { _nin: $linkedInstalledEquipmentGuids }
          installedHvacSystemGuid: { _eq: $installedHvacSystemGuid }
        }
      ) {
        __typename
      }
    }
  `)

export const useUpsertInstalledHvacSystemAndLinkedEquipment = () => {
  const [{ fetching }, upsert] = useMutation(
    UPSERT_INSTALLED_HVAC_SYSTEM_AND_LINKED_EQUIPMENT_MUTATION,
  )

  return {
    fetching,
    upsert,
  }
}
