import {
  AppointmentGuid,
  EndOfAppointmentNextStepsV1,
  EndOfAppointmentNextStepsV1JsonSchema,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form, Radio } from 'antd'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { z } from 'zod'
import FormBody from '../../../../../elements/Forms/FormBody'
import { ReactHookFormItem } from '../../../../../elements/Forms/ReactHookFormItem'
import { TextAreaField } from '../../../../../elements/Forms/TextAreaField'
import { useExpectedCompanyGuid } from '../../../../../providers/PrincipalUser'
import { UPDATE_END_OF_APPOINTMENT_NEXT_STEPS } from './EndOfAppointmentNextSteps.gql'

const EndOfAppointmentNextStepsSchema =
  EndOfAppointmentNextStepsV1JsonSchema.shape.data.refine(
    data => {
      return data.allOnsiteWorkCompleted === false
        ? data.allOnsiteWorkNotCompletedDetails
        : true
    },
    {
      message: 'Required when all onsite work is not completed',
      path: ['allOnsiteWorkNotCompletedDetails'],
    },
  )

export type EndOfAppointmentNextStepsData = z.infer<
  typeof EndOfAppointmentNextStepsSchema
>

type EndOfAppointmentNextStepsFormProps = {
  appointmentGuid: AppointmentGuid
  onCancel: () => void
  onSuccess: () => void
}

export const EndOfAppointmentNextStepsForm =
  React.memo<EndOfAppointmentNextStepsFormProps>(
    ({ onCancel, onSuccess, appointmentGuid }) => {
      const companyGuid = useExpectedCompanyGuid()
      const {
        control,
        formState: { errors },
        watch,
        setError,
        handleSubmit,
      } = useForm<EndOfAppointmentNextStepsData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(EndOfAppointmentNextStepsSchema),
      })

      const [, executeMutation] = useMutation(
        UPDATE_END_OF_APPOINTMENT_NEXT_STEPS,
      )

      const allOnsiteWorkCompleted = watch('allOnsiteWorkCompleted')

      const onSubmitInner = useCallback(
        async ({
          allOnsiteWorkCompleted,
          allOnsiteWorkNotCompletedDetails,
          dispatcherNote,
        }: EndOfAppointmentNextStepsData) => {
          if (!allOnsiteWorkCompleted && !allOnsiteWorkNotCompletedDetails) {
            setError('allOnsiteWorkNotCompletedDetails', {
              type: 'required',
              message: 'Required when all onsite is not completed',
            })
            return
          }
          const endOfAppointmentNextStepsPayload = {
            version: 'v1',
            data: {
              allOnsiteWorkCompleted,
              allOnsiteWorkNotCompletedDetails,
              dispatcherNote,
            },
          } satisfies EndOfAppointmentNextStepsV1

          await executeMutation({
            companyGuid,
            jobAppointmentGuid: appointmentGuid,
            endOfAppointmentNextSteps: endOfAppointmentNextStepsPayload,
          })
          onSuccess()
        },
        [appointmentGuid, companyGuid, executeMutation, onSuccess, setError],
      )

      return (
        <Form
          className="flex min-h-[75vh] flex-1 flex-col sm:min-h-0"
          layout="vertical"
          onSubmitCapture={handleSubmit(onSubmitInner)}
        >
          <FormBody>
            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="allOnsiteWorkCompleted"
              label="Has all on-site work for the job been completed?"
              helperText="Select 'Yes' if all work for this job are complete. This refers to the entire scope of work, not just today’s visit"
              errors={errors}
              render={({ field }) => (
                <Radio.Group
                  value={field.value}
                  onChange={e => field.onChange(e.target.value)}
                  optionType="button"
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            />

            {allOnsiteWorkCompleted === false && (
              <ReactHookFormItem
                control={control}
                className="flex-1"
                name="allOnsiteWorkNotCompletedDetails"
                label="What work still remains?"
                errors={errors}
                render={({ field }) => (
                  <TextAreaField
                    placeholder="e.g. Waiting on Parts Order, Repair / Install not complete"
                    autoSize={{ minRows: 3 }}
                    {...field}
                  />
                )}
              />
            )}

            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="dispatcherNote"
              label="Add note for dispatch (optional)"
              errors={errors}
              render={({ field }) => (
                <TextAreaField
                  placeholder="e.g. All work completed and paid for."
                  autoSize={{ minRows: 3 }}
                  {...field}
                />
              )}
            />
          </FormBody>
          <>
            <div
              className={classNames(
                'mx-[-16px] flex flex-row items-center justify-between gap-3 border-0 border-t-4 border-solid border-t-bz-gray-400 bg-white p-0 px-4 pt-4 md:-mx-6 md:px-6',
              )}
            >
              {onCancel && (
                <Button block size="large" onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <Button block type="primary" size="large" htmlType="submit">
                Submit
              </Button>
            </div>
          </>
        </Form>
      )
    },
  )
