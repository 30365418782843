import { Account, AccountLocation, BzAddress, Guid } from '@breezy/shared'
import { faEdit, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import BzRadioButton from '../../../../elements/BzRadioButton/BzRadioButton'
import { useCanManageAccount } from '../../../../hooks/permission/useCanManageAccount'
import CreateOrEditNewAccountLocationForm from '../../../CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import { LoadingSpinner } from '../../../LoadingSpinner'

export type LocationRadioSelectionProps = {
  accountGuid: Guid
  accountLocations: AccountLocation[]
  refetchAccountLocations: () => void
  stepEntityContextName: string
  setAccount?: (account: Account) => void
  selectedLocationGuid: string | undefined
  setSelectedLocationGuid: (locationGuid: string) => void
  getItemDisabledReason?: (location: AccountLocation) => string | undefined
}

const LocationRadioSelection = ({
  accountGuid,
  accountLocations,
  refetchAccountLocations,
  stepEntityContextName,
  selectedLocationGuid,
  setSelectedLocationGuid,
  getItemDisabledReason,
}: LocationRadioSelectionProps) => {
  const [addingNewLocation, setAddingNewLocation] = useState(false)
  const [editingLocations, setEditingLocations] = useState<string[]>([])
  const { canManage, isLoading } = useCanManageAccount(accountGuid)

  const removeLocationFromEditingLocations = useCallback(
    (locationGuid: string) => {
      setEditingLocations(previousEditingLocations =>
        previousEditingLocations.filter(
          editingLocationGuid => editingLocationGuid !== locationGuid,
        ),
      )
    },
    [],
  )

  return (
    <div>
      <div className="mb-3">
        <div className="semibold_16_24 grey9">Service Location</div>
        <div className="semibold_14_22 gray7 font-normal">
          Select the location that requires the servicing for this{' '}
          {stepEntityContextName}
        </div>
      </div>
      <div className="mb-4 flex flex-col space-y-3">
        {accountLocations.map(accountLocation =>
          editingLocations.includes(accountLocation.location.locationGuid) ? (
            <div key={accountLocation.location.locationGuid}>
              <CreateOrEditNewAccountLocationForm
                accountGuid={accountGuid}
                editingLocation={accountLocation.location}
                hideDisplayNameField
                showDivider={false}
                justifyFormCancelSubmitButtons="start"
                labelClassName="semibold_14_22 grey9"
                flexRowSpaceX="space-x"
                onCancelButtonPressed={() =>
                  removeLocationFromEditingLocations(
                    accountLocation.location.locationGuid,
                  )
                }
                onLocationUpdated={() => {
                  refetchAccountLocations()
                  removeLocationFromEditingLocations(
                    accountLocation.location.locationGuid,
                  )
                }}
              />
            </div>
          ) : (
            <div
              className="flex space-x-2"
              key={accountLocation.location.locationGuid}
            >
              <div className="col w-full">
                <div className="row two-sides w-full">
                  <BzRadioButton
                    label={
                      <div>
                        <div className="semibold_14_22 grey9">
                          {BzAddress.formatAddressSingleLine(
                            accountLocation.location.address,
                          )}
                        </div>
                      </div>
                    }
                    checked={
                      selectedLocationGuid ===
                      accountLocation.location.locationGuid
                    }
                    onChange={locationGuid =>
                      setSelectedLocationGuid(locationGuid)
                    }
                    value={accountLocation.location.locationGuid}
                    disabled={!!getItemDisabledReason?.(accountLocation)}
                  />
                  {canManage && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="gray7 h-4 w-4 hover:cursor-pointer"
                      onClick={() => {
                        setEditingLocations(previousEditingLocations =>
                          previousEditingLocations.concat(
                            accountLocation.location.locationGuid,
                          ),
                        )
                      }}
                    />
                  )}
                </div>
                {!!getItemDisabledReason?.(accountLocation) && (
                  <div className="semibold_14_22 green6 mt-1 w-full text-center">{`(${getItemDisabledReason(
                    accountLocation,
                  )})`}</div>
                )}
              </div>
            </div>
          ),
        )}
      </div>
      {addingNewLocation ? (
        <CreateOrEditNewAccountLocationForm
          accountGuid={accountGuid}
          onCancelButtonPressed={() => setAddingNewLocation(false)}
          justifyFormCancelSubmitButtons="end"
          labelClassName="semibold_14_22 grey9"
          showDivider={false}
          flexRowSpaceX="space-x"
          onLocationAdded={() => {
            refetchAccountLocations()
            setAddingNewLocation(false)
          }}
        />
      ) : isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : canManage ? (
        <div
          className="flex items-center space-x-[10px] hover:cursor-pointer"
          onClick={() => setAddingNewLocation(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="blue6 h-5 w-5" />
          <div className="semibold_14_22 blue6"> Add new location</div>
        </div>
      ) : null}
    </div>
  )
}

export default LocationRadioSelection
