import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { jobLifecycleStatusSchema } from '../JobLifecycles/JobLifecycles'
import { bzOptional } from '../common-schemas'
import { JOB_BUSINESS_EVENT_TYPES, jobBusinessEventTypeSchema } from './EventTypes'

const jobBusinessEventSchema = z.object({
  businessEventType: z.enum(JOB_BUSINESS_EVENT_TYPES),
  companyGuid: guidSchema,
})

export const jobLifecycleStatusChangedBusinessEventSchema = jobBusinessEventSchema.extend({
  data: z.object({
    jobGuid: guidSchema,
    userGuid: bzOptional(guidSchema),
    updatedAt: z.string(),
    status: z.object({
      from: jobLifecycleStatusSchema.pick({
        jobLifecycleStatusGuid: true,
        stage: true,
        name: true,
        isWorkComplete: true,
      }),
      to: jobLifecycleStatusSchema.pick({
        jobLifecycleStatusGuid: true,
        stage: true,
        name: true,
        isWorkComplete: true,
      }),
    }),
  }),
})

/**
 * The event emitted when a Job's Lifecycle Status changes from one status to another
 */
export type JobLifecycleStatusChangedBusinessEvent = z.infer<typeof jobLifecycleStatusChangedBusinessEventSchema>

/**
 * A business event that is emitted when something happens to a Job
 */
export type JobBusinessEvent = JobLifecycleStatusChangedBusinessEvent

export const jobBusinessEventQuerierInputSchema = z.object({
  companyGuid: guidSchema,
  jobBusinessEventType: jobBusinessEventTypeSchema.array(),
})
export type JobBusinessEventQuerierInput = z.infer<typeof jobBusinessEventQuerierInputSchema>

export type JobBusinessEventQuerier = AsyncFn<JobBusinessEventQuerierInput, JobBusinessEvent[]>
