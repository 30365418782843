import { z } from 'zod'
import { BzDateFns } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { Guid, isoWithOffsetDateSchema } from '../common-schemas'

export type LeadSourceGuid = Guid

const LeadSourceSchema = z.object({
  leadSourceGuid: guidSchema,
  name: z.string(),
  requiresContactAttribution: z.boolean(),
  createdAt: isoWithOffsetDateSchema,
})

export type LeadSource = z.infer<typeof LeadSourceSchema>

export const unknownLeadSource = (): LeadSource => ({
  leadSourceGuid: '48ee3a88-1728-46b8-b236-a413c5b8cd89',
  name: 'Unknown',
  requiresContactAttribution: false,
  // Clearly an ISO string
  // eslint-disable-next-line breezy/no-to-iso-date-string
  createdAt: BzDateFns.toIsoDateString('2022-10-28T01:25:15.159711+00:00'),
})
