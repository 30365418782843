import {
  InternalNotification,
  InternalNotificationMetadataType,
  toPlural,
} from '@breezy/shared'
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { faReceipt } from '@fortawesome/pro-regular-svg-icons'
import { faBellOn, faComment } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Badge, Popover } from 'antd'
import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getConfig } from '../../config'
import { trpc } from '../../hooks/trpc'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import { Notification } from './Notification'

const { featureFlags } = getConfig()

// Poll for notifications every 30 seconds
const NOTIFICATION_POLLING_PERIOD = 1000 * 30

export const useNotifications = (all?: boolean) => {
  const principalUser = usePrincipalUser()

  const query = trpc.internalNotifications[
    'internal-notifications:query'
  ].useQuery(
    {
      type: all ? 'all' : 'unacknowledged',
    },
    {
      enabled: !!(
        featureFlags['notificationPollingEnabled'] &&
        principalUser.principal?.company?.companyGuid
      ),
      refetchInterval: NOTIFICATION_POLLING_PERIOD,
    },
  )
  return query
}

// After this many notifications, we'll have a "And N more" button that takes to the full page.
const MAX_VISIBLE_NOTIFICATIONS = 5

type NotificationIconProps = {
  type: InternalNotificationMetadataType
}
export const NotificationIcon = React.memo<NotificationIconProps>(
  ({ type }) => {
    return (
      <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-bz-gray-300 p-3">
        <FontAwesomeIcon
          icon={
            type === 'ACCOUNT_REMINDER_DUE'
              ? faBellOn
              : type === 'TAGGED_IN_NOTE'
              ? faComment
              : type === 'ESTIMATE'
              ? faReceipt
              : faComment
          }
          className="h-[20px] w-[20px] text-bz-gray-600"
        />
      </div>
    )
  },
)

type NotificationListProps = {
  notifications?: InternalNotification[]
  refetch: () => Promise<unknown>
  onItemClick: () => void
}

const NotificationList = React.memo<NotificationListProps>(
  ({ notifications, refetch, onItemClick }) => {
    const [truncatedList, numMore] = useMemo(() => {
      return [
        notifications?.slice(0, MAX_VISIBLE_NOTIFICATIONS),
        Math.max(0, (notifications?.length || 0) - MAX_VISIBLE_NOTIFICATIONS),
      ]
    }, [notifications])

    return (
      // "mx-[-13px]" here is to "undo" the padding from the Popover component the borders between the notifications can
      // span the entire container. Also, the `max-h-[calc(90vh-70px)]` and `overflow-auto` are so this thing never
      // spills over outside the page. 70px is the height of the page header.
      <div className="mx-[-13px] max-h-[calc(90vh-70px)] max-w-sm overflow-auto">
        {truncatedList?.map(notification => (
          <Notification
            key={notification.internalNotificationGuid}
            notification={notification}
            refetch={refetch}
            onClick={onItemClick}
            className="border-0 border-b border-solid border-slate-200"
            showDate={true}
          />
        ))}
        {!truncatedList?.length && (
          <div className="px-4 py-1">No new notifications</div>
        )}
        <div className="pt-2 text-center">
          <Link to="/notifications" onClick={onItemClick}>
            {numMore ? `and ${numMore} more` : 'See all'}
          </Link>
        </div>
        {getConfig().env !== 'production' &&
          !featureFlags.notificationPollingEnabled && (
            <div className="px-2 pt-2 text-center">
              <span>
                Notification Polling is disabled. To enable, set{' '}
                <code>featureFlags.notificationPollingEnabled</code> to{' '}
                <code>true</code> in <code>config/local.json</code> and restart
                the app.
              </span>
            </div>
          )}
      </div>
    )
  },
)

export const useNewNotificationCount = () => {
  const message = useMessage()
  const query = useNotifications()

  const [previousLatestDate, setPreviousLatestDate] = useState<string>()

  const [newNotificationsCount, setNewNotificationsCount] = useState(0)
  const [prevNotificationsCount, setPrevNotificationsCount] = useState(0)

  const updateNotificationCount = useCallback(
    (newCount: number) => {
      setPrevNotificationsCount(newNotificationsCount)
      setNewNotificationsCount(newCount)
    },
    [newNotificationsCount],
  )

  useEffect(() => {
    if (query.data) {
      // The first time we get notifications, keep track of the most recent one (they
      // come in reverse chronological order). Next time (previousLatestDate is set), we
      // want to see how many notifications are created AFTER that one. Since we reset
      // previousLatestDate when they open the notifications, this will also reset the
      // "latest" to the first one, making the count 0. Special case for this logic: if
      // when we load they have NO notifications, we will make the date as "" instead of
      // `undefined`. We need to do this because otherwise the first notification will
      // be set as the "latest", and it should really set the count to 1.
      if (!query.data.length) {
        setPreviousLatestDate('')
      } else if (previousLatestDate === '') {
        updateNotificationCount(query.data.length)
      } else if (previousLatestDate) {
        let count = 0
        for (const notification of query.data) {
          if (notification.createdAt > previousLatestDate) {
            count++
          } else {
            break
          }
        }
        updateNotificationCount(count)
      } else {
        setPreviousLatestDate(query.data[0].createdAt)
      }
    }
  }, [previousLatestDate, query.data, updateNotificationCount])

  useEffect(() => {
    const notificationChange = newNotificationsCount - prevNotificationsCount
    if (notificationChange > 0) {
      message.info(
        `${notificationChange} new ${toPlural(
          notificationChange,
          'notification',
        )}`,
      )
    }
  }, [message, newNotificationsCount, prevNotificationsCount])

  return [newNotificationsCount, setPreviousLatestDate] as const
}

export const Notifications = m(() => {
  const query = useNotifications()
  const [newNotificationsCount, setLatestNotificationReadAtDate] =
    useNewNotificationCount()

  const setOpen = useCallback(
    (open: boolean) => {
      if (open) {
        // When they open the notification pane, we clear the new notifications badge
        setLatestNotificationReadAtDate(undefined)
      }
    },
    [setLatestNotificationReadAtDate],
  )

  const closePopover = useCallback(() => setOpen(false), [setOpen])

  return (
    <Popover
      placement="bottomRight"
      trigger="hover"
      content={
        <NotificationList
          notifications={query.data}
          refetch={query.refetch}
          onItemClick={closePopover}
        />
      }
      onOpenChange={setOpen}
    >
      <Badge count={newNotificationsCount}>
        <Avatar
          className={cn(
            {
              'bg-[#1890ff]': !!query.data?.length,
            },
            'mt-[-2px] h-[36px] w-[36px] border border-solid border-[#D9D9D9] bg-white hover:bg-[#F0F0F0]',
          )}
          icon={<FontAwesomeIcon icon={faBell} className="grey8 mt-2" />}
        />
      </Badge>
    </Popover>
  )
})

export default Notifications
