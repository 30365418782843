import { useMemo } from 'react'
import { getConfig } from '../config'
import { FETCH_COMPANY_MAINTENANCE_PLAN_ENABLED } from '../pages/MaintenancePlanConfigPage/MaintenancePlanConfig.gql'
import { usePrincipalUser } from '../providers/PrincipalUser'
import { useQueryMaxAttempts } from './useQueryMaxAttempts'

const { env } = getConfig()
const isDemo = env === 'demo'

/**
 * Whether the company has enabled maintenance plan functionality in their
 * company-specific settings. Note that this is not related to Breezy's
 * internal maintenance plan feature flags
 */
export const useCompanyMaintenancePlansEnabled = (): boolean => {
  const { companyGuid, merchantId } =
    usePrincipalUser()?.principal?.company ?? {}

  const [{ data, fetching }, , maxAttemptsReached] = useQueryMaxAttempts({
    query: FETCH_COMPANY_MAINTENANCE_PLAN_ENABLED,
    pause: !companyGuid,
    variables: { companyGuid: companyGuid ?? '' },
    requestPolicy: 'network-only',
  })

  const configMaintenancePlansEnabled = useMemo(() => {
    if (fetching) {
      return false
    }
    if (maxAttemptsReached) {
      return false
    }

    return data?.companyConfig[0]?.maintenancePlansEnabled ?? false
  }, [data?.companyConfig, fetching, maxAttemptsReached])

  return isDemo || (!!merchantId && configMaintenancePlansEnabled)
}
