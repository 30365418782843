import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { AddressDtoSchema } from '../Address/Address'
import { ForCompanyUser } from '../Company/Company'
import { bzOptional } from '../common-schemas'

export const accountBillingAddressUpsertInputSchema = z.object({
  accountGuid: guidSchema,
  billingAddress: bzOptional(AddressDtoSchema),
  mailingAddressGuid: bzOptional(guidSchema),
})

export type AccountBillingAddressUpsertInput = z.infer<typeof accountBillingAddressUpsertInputSchema>

export type AccountBillingAddressUpserter = AsyncFn<ForCompanyUser<AccountBillingAddressUpsertInput>>
