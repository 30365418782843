import React from 'react'

import {
  LOAN_APPLICATION_PLACEHOLDER_LINK,
  LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX,
} from '@breezy/shared'
import { createTsForm, createUniqueFieldSchema } from '@ts-react/form'
import { z } from 'zod'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import RichTextArea from '../../PreviewMessage/RichTextAreaField/RichTextAreaField'
import { SendAsEmailFormData } from '../../PreviewMessage/SendEmailForm/SendEmailForm'
import TextField from '../../PreviewMessage/TextField/TextField'
import FinancingAppFormWrapper from '../FinancingAppFormWrapper'

type FinancingEmailAppFormProps = {
  contactEmailAddress?: string
  defaultEmailBody: string
  onCancel: () => void
  onSubmit: (data: SendAsEmailFormData) => void
  dataDdActionName?: string
  disabled?: boolean
}

export const RichTextAreaWithPlaceholderLinkSchema = createUniqueFieldSchema(
  z
    .string()
    .regex(
      LOAN_APPLICATION_PLACEHOLDER_LINK_REGEX,
      `The email must contain the text ${LOAN_APPLICATION_PLACEHOLDER_LINK} to indicate where the link to the loan application will be placed.`,
    ),
  'richTextArea',
)

const sendEmailFieldMapping = [
  [z.string(), TextField],
  [RichTextAreaWithPlaceholderLinkSchema, RichTextArea],
] as const

const SendEmailForm = createTsForm(sendEmailFieldMapping, {
  FormComponent: FinancingAppFormWrapper,
})

export const FinancingSendAsEmailFormSchema = z.object({
  emailAddress: z
    .string()
    .nonempty('You must include an email')
    .email()
    .describe('Email Address'),
  subject: z
    .string()
    .nonempty('You must include a subject')
    .describe('Subject Header'),
  body: RichTextAreaWithPlaceholderLinkSchema.describe('Body'),
})

const FinancingSendEmailForm = React.memo<FinancingEmailAppFormProps>(
  ({
    contactEmailAddress = '',
    defaultEmailBody,
    onCancel,
    onSubmit,
    dataDdActionName,
    disabled = false,
  }) => {
    const company = useExpectedCompany()

    return (
      <SendEmailForm
        schema={FinancingSendAsEmailFormSchema}
        formProps={{
          onCancel,
          submitButtonText: 'Send Application',
          disabled,
          dataDdActionName,
        }}
        onSubmit={onSubmit}
        defaultValues={{
          emailAddress: contactEmailAddress,
          subject: `Financing application for ${company.name}`,
          body: defaultEmailBody,
        }}
      >
        {({ emailAddress, subject, body }) => (
          <div>
            {emailAddress}
            {subject}
            {body}
          </div>
        )}
      </SendEmailForm>
    )
  },
)

export default FinancingSendEmailForm
