import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons'
import React, { useMemo } from 'react'
import { Tag } from '../../../components/Tags'

type SupportedPaymentTypeProps = {
  isFreePlan: boolean
  isAutoRenewing: boolean
  isImportedPlan: boolean
}

export const SupportedPaymentTypesSection =
  React.memo<SupportedPaymentTypeProps>(
    ({ isFreePlan, isAutoRenewing, isImportedPlan }) => {
      const tags = useMemo(() => {
        const tags: Record<string, boolean> = {}
        tags['Credit Card'] = !isFreePlan && !isImportedPlan
        tags['ACH'] = !isFreePlan && !isImportedPlan
        tags['Check'] = !isFreePlan && !isImportedPlan && !isAutoRenewing
        tags['Cash'] = !isFreePlan && !isImportedPlan && !isAutoRenewing
        tags['Other'] = !isFreePlan && !isImportedPlan && !isAutoRenewing
        return tags
      }, [isFreePlan, isAutoRenewing, isImportedPlan])

      return (
        <div className="flex w-full flex-col">
          <div className="pb-2 text-[14px] font-semibold leading-[22px] text-[#1e1e1e]">
            Supported Payment Types
          </div>
          <div className="flex flex-row flex-wrap gap-x-0 gap-y-2">
            {Object.entries(tags).map(([tag, isEnabled]) => (
              <Tag
                key={tag}
                tag={{
                  name: tag,
                  color: isEnabled ? '#ffffff' : '#f0f0f0',
                }}
                icon={isEnabled ? faCheck : faXmark}
                noPopover
                disabled={!isEnabled}
                overrideStyle={{
                  iconColor: isEnabled ? '#202020' : undefined,
                  bgColor: isEnabled ? 'bg-white' : undefined,
                  textSize: 'text-[12px] leading-[20px]',
                  iconSize: 16,
                }}
              />
            ))}
          </div>
          <div className="pt-[2px] text-[14px] leading-[22px] text-[#979797]">
            Payment type availability is dependent on the auto-renewal and
            pricing configuration.
          </div>
        </div>
      )
    },
  )
