import {
  faFileText,
  faHandshake,
  faMagnifyingGlass,
  faMoneyBill,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { blue6 } from '../../themes/theme'
import { Styled } from '../../utils/Stylable'
import { LoadingSpinner } from '../LoadingSpinner'
import PaymentCenterUiCard from './PaymentCenterUiCard'
import { PaymentAsyncUiStatus } from './PaymentTypes'

type PaymentAsyncStatusCardProps = {
  readonly status: PaymentAsyncUiStatus
  readonly titleOverride?: null | string
  readonly overrideTitles?: Partial<Record<PaymentAsyncUiStatus, string>>
}

const titles = {
  [PaymentAsyncUiStatus.IDLE]: 'Loading...',
  [PaymentAsyncUiStatus.PREPARING]: 'Initializing Transaction...',
  [PaymentAsyncUiStatus.VERIFYING]: 'Verifying Payment Method...',
  [PaymentAsyncUiStatus.SUBMITTING]: 'Submitting Payment...',
  [PaymentAsyncUiStatus.RECORDING]: 'Recording Payment...',
  [PaymentAsyncUiStatus.SUCCEEDED]: null,
}

const iconStyle = { fontSize: 52, color: blue6, marginBottom: 16 }

const icons = {
  [PaymentAsyncUiStatus.IDLE]: null,
  [PaymentAsyncUiStatus.PREPARING]: (
    <FontAwesomeIcon icon={faHandshake} style={iconStyle} />
  ),
  [PaymentAsyncUiStatus.VERIFYING]: (
    <FontAwesomeIcon icon={faMagnifyingGlass} style={iconStyle} />
  ),
  [PaymentAsyncUiStatus.SUBMITTING]: (
    <FontAwesomeIcon icon={faMoneyBill} style={iconStyle} />
  ),
  [PaymentAsyncUiStatus.RECORDING]: (
    <FontAwesomeIcon icon={faFileText} style={iconStyle} />
  ),
  [PaymentAsyncUiStatus.SUCCEEDED]: null,
}

const PaymentAsyncStatusCard = ({
  status,
  titleOverride,
  overrideTitles,
  className,
}: Styled<PaymentAsyncStatusCardProps>) => {
  return status === PaymentAsyncUiStatus.IDLE ? null : (
    <PaymentCenterUiCard className={className}>
      {icons[status]}
      <h1 className="mt-4 w-full text-center">
        {titleOverride ||
          (overrideTitles && overrideTitles[status]) ||
          titles[status]}
      </h1>
      <LoadingSpinner />
    </PaymentCenterUiCard>
  )
}

export default PaymentAsyncStatusCard
