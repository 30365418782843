import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { SwitchField } from '../../../../elements/Forms/SwitchField'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import { CreditCardPaymentFormData } from './CreditCardPaymentForm'

const SavePaymentMethodField = React.memo(() => {
  const { control, watch } = useFormContext<CreditCardPaymentFormData>()
  const selectedCardOnFile = watch('selectedCardOnFile')

  return (
    <Controller
      control={control}
      name="savePaymentMethod"
      render={({ field }) => (
        <div className="mb-2 flex flex-row items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-2">
            <label
              htmlFor="savePaymentMethod"
              className={classNames('font-semibold', {
                'cursor-not-allowed opacity-50': !!selectedCardOnFile,
                'cursor-pointer': !selectedCardOnFile,
              })}
            >
              Save payment info for next time
            </label>
            <RenderIf if={!!selectedCardOnFile}>
              <Tooltip title="Cannot save payment method while a card on file is selected">
                <FontAwesomeIcon
                  className="text-bz-gray-700"
                  icon={faInfoCircle}
                />
              </Tooltip>
            </RenderIf>
          </div>
          <SwitchField withIcons {...field} disabled={!!selectedCardOnFile} />
        </div>
      )}
    />
  )
})

export default SavePaymentMethodField
