import { emailAddressValueSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

type EmailAddressFormItemProps = {
  emailAddress?: string
  labelClassName?: string
  required: boolean
  requiredMessage?: string
  label?: string
  name?: string
}

export const EmailAddressFormItem: React.FC<EmailAddressFormItemProps> = ({
  required,
  requiredMessage,
  emailAddress,
  labelClassName = '',
  label,
  name,
}) => (
  <Form.Item
    name={name ?? 'emailAddress'}
    initialValue={emailAddress}
    label={label ?? 'Email Address'}
    className={labelClassName}
    rules={[
      {
        required,
        validator: async (_, value) => {
          if (!value && required) {
            return Promise.reject(
              new Error(requiredMessage ?? 'Email addresses is required'),
            )
          }

          if (!value) {
            return
          }

          const result = emailAddressValueSchema.safeParse(value)
          if (!result.success) {
            return Promise.reject(
              new Error(
                result.error.issues[0]?.message || result.error.message,
              ),
            )
          }
        },
      },
    ]}
  >
    <Input
      defaultValue={emailAddress}
      autoComplete="email"
      type="email"
      name="emailAddress"
      id="emailAddress"
    />
  </Form.Item>
)
