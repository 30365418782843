import { gql } from 'src/generated/user'

export const COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query CreateOrEditAccountFormCompanyConfigQuery($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      companyGuid
      accountManagerEnabled
    }
  }
`)
