import {
  AccountContact,
  AccountGuid,
  BzContact,
  R,
  prettifyPhoneNumberType,
} from '@breezy/shared'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { memo, useCallback, useState } from 'react'
import { ActionItem } from '../../../elements/ActionItems/ActionItems'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { trpc } from '../../../hooks/trpc'
import { ContactIcon } from '../../../utils/feature-icons'
import { AccountContactTag } from '../../Accounts/AccountContactTag/AccountContactTag'
import { CreateOrEditNewContactForm } from '../../Contacts/CreateOrEditNewContactForm/CreateOrEditNewContactForm'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem, {
  ContentListItem,
} from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

import { Switch } from 'antd'
import { useMutation } from 'urql'
import { CallablePhoneLink } from '../../../elements/CallablePhoneLink/CallablePhoneLink'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { useMessage } from '../../../utils/antd-utils'
import { SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION } from './AccountContactsCollapsible.gql'

type AccountContactsCollapsibleProps = {
  accountGuid: AccountGuid
  accountContacts: AccountContact[]
  editable?: boolean
  refetch?: () => void
}

export const AccountContactsCollapsible = memo<AccountContactsCollapsibleProps>(
  ({ accountGuid, accountContacts, editable, refetch }) => {
    const message = useMessage()
    const [upsertContactDrawerIsOpen, setUpsertContactDrawerIsOpen] =
      useState(false)

    const [accountContactBeingEdited, setAccountContactBeingEdited] =
      useState<AccountContact | null>(null)

    const [showArchivedAccountContacts, setShowArchivedAccountContacts] =
      useState(false)

    const onUpsertContactDrawerClosed = useCallback(() => {
      setUpsertContactDrawerIsOpen(false)
      setAccountContactBeingEdited(null)
    }, [setUpsertContactDrawerIsOpen])

    const onEditContactClick = useCallback(
      (accountContact: AccountContact) => {
        setAccountContactBeingEdited(accountContact)
        setUpsertContactDrawerIsOpen(true)
      },
      [setUpsertContactDrawerIsOpen, setAccountContactBeingEdited],
    )

    const onAddNewContactClick = useCallback(() => {
      setUpsertContactDrawerIsOpen(true)
    }, [setUpsertContactDrawerIsOpen])

    const onAccountContactUpserted = useCallback(() => {
      setUpsertContactDrawerIsOpen(false)
      refetch?.()
      if (!accountContactBeingEdited) {
        message.success('Successfully added new Contact')
      } else {
        message.success('Successfully updated Contact')
      }
      setAccountContactBeingEdited(null)
    }, [accountContactBeingEdited, message, refetch])

    const updatePrimaryAccountContactMutation =
      trpc.accountContacts['account-contacts:update-primary'].useMutation()

    const [
      { fetching: mutatingAccountContactArchived },
      executeAccountContactArchivedMutation,
    ] = useMutation(SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION)

    const archiveAccountContact = useCallback(
      async (accountContact: AccountContact) => {
        const bzContact = BzContact.create(accountContact.contact)

        if (accountContact.primary) {
          message.error(
            `Primary contacts cannot be archived. To archive this contact you must first change the primary contact on the account`,
          )
          return
        }

        if (accountContacts.filter(ac => !ac.archived).length <= 1) {
          message.error(
            "Cannot archive a contact when it is the account's only active contact",
          )
          return
        }

        if (accountContact.archived) {
          message.error(
            `Cannot archive contact ${bzContact.fullName} because it is already archived`,
          )
          return
        }

        try {
          await executeAccountContactArchivedMutation({
            accountContactGuid: accountContact.accountContactGuid,
            archived: true,
          })

          refetch?.()

          message.success(`Archived contact ${bzContact.fullName}`)
        } catch (e) {
          message.error(`Failed to archive contact ${bzContact.fullName}`)
        }
      },
      [
        accountContacts,
        executeAccountContactArchivedMutation,
        message,
        refetch,
      ],
    )

    const unarchiveAccountContact = useCallback(
      async (accountContact: AccountContact) => {
        const bzContact = BzContact.create(accountContact.contact)

        if (!accountContact.archived) {
          message.warning(
            `Cannot unarchive contact ${bzContact.fullName} because it is already unarchived`,
          )
          return
        }

        try {
          await executeAccountContactArchivedMutation({
            accountContactGuid: accountContact.accountContactGuid,
            archived: false,
          })

          refetch?.()

          message.success(`Unarchived contact ${bzContact.fullName}`)
        } catch (e) {
          message.error(`Failed to unarchive contact ${bzContact.fullName}`)
        }
      },
      [executeAccountContactArchivedMutation, message, refetch],
    )

    const getActionItemsForAccountContact = (
      accountContact: AccountContact,
    ): ActionItem[] => {
      const items: ActionItem[] = []

      // Display Action Items as disabled if we're creating/updating a contact
      // or if a query/mutation is in progress
      const disabled =
        upsertContactDrawerIsOpen || mutatingAccountContactArchived

      if (!accountContact.primary && !accountContact.archived) {
        items.push({
          title: 'Make Primary',
          disabled,
          onClick: () => {
            if (accountContact.archived) {
              message.error(`Cannot make an archived contact primary`)
            } else {
              updatePrimaryAccountContactMutation.mutate(
                {
                  accountContactGuid: accountContact.accountContactGuid,
                  accountGuid: accountContact.accountGuid,
                },
                {
                  onSuccess(data, variables, context) {
                    message.success('Successfully updated contact')
                    refetch?.()
                  },
                },
              )
            }
          },
        })
      }

      if (accountContact.archived) {
        items.push({
          title: 'Unarchive',
          disabled,
          onClick: () => unarchiveAccountContact(accountContact),
        })
      } else {
        items.push({
          title: 'Archive',
          disabled,
          onClick: () => archiveAccountContact(accountContact),
        })
      }

      return items
    }

    const generateContentList = (contact: BzContact): ContentListItem[] => {
      const contentList: ContentListItem[] = []

      if (contact.primaryEmailAddress) {
        contentList.push({
          key: 'Email:',
          value: (
            <a
              href={`mailto:${contact.primaryEmailAddress.emailAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className="break-all"
            >
              {contact.primaryEmailAddress.emailAddress}
            </a>
          ),
        })
      }

      if (contact.primaryPhoneNumber) {
        const phoneNumberContent = `${
          contact.primaryPhoneNumber.phoneNumber
        } (${prettifyPhoneNumberType(contact.primaryPhoneNumber.type)})`

        contentList.push({
          key: 'Phone:',
          value: contact.primaryPhoneNumber ? (
            <CallablePhoneLink
              phoneNumber={contact.primaryPhoneNumber.phoneNumber}
            >
              {contact.primaryPhoneNumber.unsubscribed ? (
                <span className="line-through">{phoneNumberContent}</span>
              ) : (
                phoneNumberContent
              )}
              {contact.primaryPhoneNumber.unsubscribed && ' (Opted out)'}
            </CallablePhoneLink>
          ) : (
            <EmDash />
          ),
        })
      }

      return contentList
    }

    return (
      <>
        <BzCollapsible
          title="Contacts"
          defaultOpen
          titleIcon={faUser}
          onPlus={editable ? onAddNewContactClick : undefined}
        >
          <>
            {accountContacts.findIndex(ac => ac.archived) !== -1 && (
              <div className="flex items-center space-x-2">
                <Switch
                  checked={showArchivedAccountContacts}
                  onChange={checked => setShowArchivedAccountContacts(checked)}
                />
                <label>
                  {showArchivedAccountContacts
                    ? 'Show Archived'
                    : 'Hide Archived'}
                </label>
              </div>
            )}

            {R.sort((ac1, ac2) => {
              // Sort primary contacts first and archived contacts last
              return ac1.primary ? -1 : ac2.primary ? 1 : ac1.archived ? 1 : -1
            }, accountContacts)
              .filter(
                accountContact =>
                  showArchivedAccountContacts || !accountContact.archived,
              )
              .map((accountContact, i) => {
                const contact = BzContact.create(accountContact.contact)
                return (
                  <div
                    key={contact.contactGuid}
                    className={i !== 0 ? 'mt-2' : ''}
                  >
                    <CollapsibleItem
                      alwaysOpen={true}
                      key={accountContact.accountContactGuid}
                      title={contact.fullName}
                      tag={
                        <AccountContactTag accountContact={accountContact} />
                      }
                      onEdit={
                        editable
                          ? () => onEditContactClick(accountContact)
                          : undefined
                      }
                      actionItems={
                        editable
                          ? getActionItemsForAccountContact(accountContact)
                          : undefined
                      }
                      contentList={generateContentList(contact)}
                    />
                  </div>
                )
              })}
          </>
        </BzCollapsible>

        {editable && (
          <BzDrawer
            title={accountContactBeingEdited ? 'Edit Contact' : 'Add Contact'}
            icon={ContactIcon}
            preferredWidth={720}
            item={
              upsertContactDrawerIsOpen
                ? { onCancel: onUpsertContactDrawerClosed }
                : undefined
            }
            destroyOnClose
          >
            <CreateOrEditNewContactForm
              onCancelButtonPressed={onUpsertContactDrawerClosed}
              accountGuid={accountGuid}
              editingAccountContact={accountContactBeingEdited ?? undefined}
              onAccountContactAdded={onAccountContactUpserted}
              onAccountContactUpdated={onAccountContactUpserted}
            />
          </BzDrawer>
        )}
      </>
    )
  },
)
