import { Card } from 'antd'
import { memo } from 'react'

type EmptyOutcomeCardProps = {
  bodyText: string
}

/**
 * A Card component meant to be used when there is no information or data to display.
 *
 * @component
 * @example
 * <EmptyOutcomeCard bodyText="No revenue has been attributed for this job." />
 *
 * @param {object} props - The properties that define the EmptyOutcomeCard component.
 * @param {string} props.bodyText - The text to be displayed in the body of the card.
 *
 * @returns {ReactElement} The EmptyOutcomeCard component
 */
const EmptyOutcomeCard = memo<EmptyOutcomeCardProps>(({ bodyText }) => {
  return (
    <Card
      size="small"
      bodyStyle={{
        color: 'rgba(0, 0, 0, 0.45)',
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="inline-flex w-full bg-gray-50 p-0"
    >
      <p className="m-3">{bodyText}</p>
    </Card>
  )
})

export default EmptyOutcomeCard
