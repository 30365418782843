import React, { Suspense, useMemo } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { withGeneralFallbackOnError as errHandled } from '../components/GeneralErrorFallback/GeneralErrorFallback'
import { LoadingSpinner } from '../components/LoadingSpinner'

import { PermissionV1, PermissionV2, isNullish } from '@breezy/shared'
import { Button } from 'antd'
import { useIsAuthorized } from '../hooks/permission/useIsAuthorized'
import { type OfficeReactRoutesInfo } from '../utils/OfficeReactRoutes'
import { ReactRoute } from '../utils/ReactRoute'
import { type TechnicianReactRoutesInfo } from '../utils/TechnicianReactRoutes'
import { Applications } from '../utils/application-type'
import { AnalyticsWrapper } from './AnalyticsWrapper'
import { AppContextWrapper } from './AppContextWrapper'
import { useIsCompanyUser } from './PrincipalUser'

type RouterProps = {
  routeWrapper: (children: JSX.Element, header?: React.ReactNode) => JSX.Element
} & (
  | {
      applicationContext: Applications.OFFICE
      routes: OfficeReactRoutesInfo
    }
  | {
      applicationContext: Applications.TECHNICIAN
      routes: TechnicianReactRoutesInfo
    }
)

const NoPermission = React.memo(() => {
  const navigate = useNavigate()
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-6 p-3 text-center">
      <div className="text-xl">
        You do not have permission to view this page.
      </div>
      <Button size="large" type="primary" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </div>
  )
})

type AuthRouteProps = {
  permission: PermissionV1 | PermissionV2 | undefined
  fallback?: React.ReactNode
  requiresCompanyUser?: boolean
}

const AuthRoute = React.memo<AuthRouteProps>(
  ({ permission, fallback, requiresCompanyUser = false }) => {
    const isAuthorized = useIsAuthorized()
    const isCompanyUser = useIsCompanyUser()

    if (requiresCompanyUser && !isCompanyUser) {
      return <Navigate to="/no-company" replace />
    }

    if (isNullish(permission)) {
      return <Outlet />
    }

    // TS needed this to be wrapped in a fragment
    return (
      <>
        {isAuthorized(permission) ? <Outlet /> : fallback || <NoPermission />}
      </>
    )
  },
)

// React Router v6 requires children of
// a Route to be either a Route or a fragment so this can't be
// a nice <ChildRoutes /> component :(
const renderChildRoutes = (routes?: ReactRoute[]) => {
  if (!routes) return null

  return routes.map(route => (
    // With React Router 6 nested routes we need to wrap a child route in a parent route
    // with a component that checks for permissions.
    // Check out the AuthLayout pattern: https://reactrouter.com/en/main/start/overview#nested-routes
    // https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
    <Route
      key={`${route.path}-auth`}
      element={
        <AuthRoute
          permission={route.permission}
          fallback={route.unauthedElement}
          requiresCompanyUser={route.principalUserRequiresCompany}
        />
      }
    >
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children && renderChildRoutes(route.children)}
      </Route>
    </Route>
  ))
}

const SuspenseRouteWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <Suspense
      fallback={
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      }
    >
      {children}
    </Suspense>
  )
}

const Router = React.memo<RouterProps>(
  ({ applicationContext, routeWrapper, routes }) => {
    const router = useMemo(
      () =>
        createBrowserRouter(
          createRoutesFromElements(
            <Route
              element={
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <AnalyticsWrapper>{errHandled(<Outlet />)}</AnalyticsWrapper>
                </QueryParamProvider>
              }
            >
              {Object.entries(routes)
                .map(r => r[1])
                .filter(r => !!(r.element || r.LAZY_element))
                .map(r => (
                  <Route
                    key={`${r.path}-auth`}
                    element={
                      <AuthRoute
                        permission={r.permission}
                        fallback={r.unauthedElement}
                        requiresCompanyUser={r.principalUserRequiresCompany}
                      />
                    }
                  >
                    <Route
                      key={r.path}
                      path={r.path}
                      element={routeWrapper(
                        <SuspenseRouteWrapper>
                          {r.element}
                        </SuspenseRouteWrapper>,
                        r.header,
                      )}
                    >
                      {renderChildRoutes(r.children)}
                    </Route>
                  </Route>
                ))}
            </Route>,
          ),
        ),
      [routeWrapper, routes],
    )

    return (
      <AppContextWrapper appType={applicationContext}>
        <RouterProvider router={router} />
      </AppContextWrapper>
    )
  },
)

export default Router
