import classNames from 'classnames'
import { Composable } from '../../utils/Composable'
import { Stylable } from '../../utils/Stylable'

const PaymentCenterUiCard = ({
  children,
  style,
  className,
}: Composable & Stylable) => {
  return (
    <div
      className={classNames(
        'card-no-fixed-height column center-children-vh center-h min-h-[440px] w-[320px] max-w-[500px] overflow-y-hidden',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export default PaymentCenterUiCard
