import {
  AccountContact,
  MaintenancePlanDefinition,
  VisitViewModel,
} from '@breezy/shared'
import { faEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons'
import { useFormContext } from 'react-hook-form'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import { BzSwitch } from '../../../elements/BzSwitch/BzSwitch'
import { MediumCard } from '../../../elements/Card/MediumCard'
import useIsMobile from '../../../hooks/useIsMobile'
import { MaintenancePlanCardHeader } from '../components/MaintenancePlanCardHeader'
import { RightLinkButton } from '../components/RightLinkButton'
import { StaticHeightOptionCardHeader } from '../components/StaticHeightOptionCardHeader'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'
import { MaintenancePlanWizardPageSection } from '../MaintenancePlanWizardPageSection'
import { FinalPricingSection } from './FinalPricingSection'
import { ReadOnlyBillingInfoSection } from './ReadOnlyBillingInfoSection'
import { ReadOnlyVisitsSection } from './ReadOnlyVisitsSection'

type ReviewMaintenancePlanPanelProps = {
  accountContact: AccountContact
  selectedPlanType: MaintenancePlanDefinition
  visits: VisitViewModel[]
  goToConfigurePlanStep: () => void
  onSubmit: () => void
}

export const ReviewMaintenancePlanPanel = ({
  accountContact,
  selectedPlanType,
  visits,
  goToConfigurePlanStep,
  onSubmit,
}: ReviewMaintenancePlanPanelProps) => {
  const isMobile = useIsMobile()
  const { watch, setValue } = useFormContext<ConfigureMaintenancePlanFormData>()

  const shouldSendActivationEmail = watch('shouldSendActivationEmail')
  const isImportedPlan = watch('isImportedPlan')

  return (
    <>
      <MaintenancePlanWizardPageSection
        title={
          <div className="flex flex-row items-center justify-center">
            <div className="mr-4 flex-1 text-[20px] font-semibold leading-[28px] text-[rgba(0,0,0,0.88)]">
              Maintenance Plan
            </div>
            <RightLinkButton
              onClick={goToConfigurePlanStep}
              title={isMobile ? 'Edit' : 'Edit Plan'}
            />
          </div>
        }
      >
        <SectionedCard
          small={false}
          accentBarColor={selectedPlanType.flare?.primaryColorHex ?? '#13C2C2'}
          sections={[
            {
              coloredIn: false,
              verticalPaddingClassName: 'py-6',
              content: (
                <MaintenancePlanCardHeader
                  maintenancePlanDefinition={selectedPlanType}
                />
              ),
            },
            {
              verticalPaddingClassName: 'py-4',
              content: <ReadOnlyBillingInfoSection />,
            },
            {
              verticalPaddingClassName: 'py-4',
              content: <ReadOnlyVisitsSection visits={visits} />,
            },
          ]}
        />
      </MaintenancePlanWizardPageSection>
      {!isImportedPlan && (
        <MaintenancePlanWizardPageSection>
          <MediumCard>
            <StaticHeightOptionCardHeader
              faIcon={isMobile ? undefined : faEnvelopeOpenText}
              iconBgColor="#f5f5f5"
              iconClassName="text-[20px] text-[#565656]"
              title="Send Activation Email"
              description="The customer will receive an email that contains the details of the maintenance plan."
              extra={
                <div className="flex pt-[7px]">
                  <BzSwitch
                    checked={shouldSendActivationEmail}
                    data-testid="send-activation-email-switch"
                    onChange={e => setValue('shouldSendActivationEmail', e)}
                  />
                </div>
              }
              titleClassName="truncate mb-[2px]"
              descriptionClassName="mb-0 font-normal"
              titleLevel={5}
            />
          </MediumCard>
        </MaintenancePlanWizardPageSection>
      )}
      <MaintenancePlanWizardPageSection>
        <FinalPricingSection
          accountContact={accountContact}
          maintenancePlanDefinition={selectedPlanType}
          onSubmit={onSubmit}
        />
      </MaintenancePlanWizardPageSection>
    </>
  )
}
