import {
  AbridgedInvoiceMetadata,
  AccountGuid,
  PaymentMethod,
  PaymentMethodCardMetadata,
  PaymentMethodCardType,
  PaymentStatus,
  ThisShouldNeverHappenError,
  TilledPaymentErrorDetail,
  getTilledPaymentErrorDetail,
  nextGuid,
  usCentsToUsd,
} from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import { useCallback, useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { TilledFormInfo } from '../../../hooks/useTilled'
import { useMessage } from '../../../utils/antd-utils'
import {
  CardInfo,
  TilledCardFundingType,
  TilledPaymentMethodRequest,
} from '../../../utils/tilledSdkTypes'
import { AchPaymentFormData } from '../components/AchPaymentForm/AchPaymentForm'
import { CreditCardPaymentFormData } from '../components/CreditCardPaymentForm/CreditCardPaymentForm'
import { useCreateTilledPaymentMethod } from './useCreateTilledPaymentMethod'

export type PaymentFormData<
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
> = PaymentMethodType extends PaymentMethod.ACH
  ? AchPaymentFormData
  : PaymentMethodType extends PaymentMethod.CARD
  ? CreditCardPaymentFormData
  : never

export const isAchPaymentFormData = (
  formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
): formData is AchPaymentFormData => 'accountType' in formData

export const isCreditCardFormData = (
  formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
): formData is CreditCardPaymentFormData => 'name' in formData

type UseSubmitTilledPaymentProps<PaymentMethodType extends PaymentMethod> = {
  accountGuid: AccountGuid
  invoice: AbridgedInvoiceMetadata
  paymentAmountUsc: number
  tilledFormInfo?: TilledFormInfo
  links?: {
    jobGuid?: string
    jobAppointmentGuid?: string
    maintenancePlanGuid?: string
  }
  paymentMethod: PaymentMethodType
  /* Hands error handling off to the caller */
  onError: (error: TilledPaymentErrorDetail | Error) => void
}

const TilledFundingToPaymentMethodCardType: Record<
  TilledCardFundingType,
  PaymentMethodCardType
> = {
  credit: 'CREDIT',
  debit: 'DEBIT',
  prepaid: 'PREPAID',
  unknown: 'UNKNOWN',
}

export const convertTilledCardInfoToPaymentMethodCardMetadata = (
  card: CardInfo,
): PaymentMethodCardMetadata => ({
  lastFourDigits: card.last4,
  expirationYear: `${card.exp_year}`,
  expirationMonth: `${card.exp_month}`.padStart(2, '0'),
  cardBrand: card.brand,
  cardType: card.funding
    ? TilledFundingToPaymentMethodCardType[card.funding]
    : 'UNKNOWN',
})

export const getTilledPaymentMethodDetails = <
  PaymentMethodType extends PaymentMethod,
>(
  paymentMethod: PaymentMethodType,
  formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
): TilledPaymentMethodRequest => {
  if (paymentMethod === PaymentMethod.ACH && isAchPaymentFormData(formData)) {
    return {
      type: 'ach_debit',
      billing_details: {
        name: formData.accountHolderName,
        address: {
          zip: `${formData.zipCode}`,
          country: 'US',
          street: formData.streetAddress,
          street2: formData.streetAddress2,
          city: formData.city,
          state: formData.state,
        },
      },
      ach_debit: {
        account_type: formData.accountType,
        account_holder_name: formData.accountHolderName.slice(0, 22),
      },
    }
  }

  if (paymentMethod === PaymentMethod.CARD && isCreditCardFormData(formData)) {
    if (!formData.selectedCardOnFile && !formData.name)
      throw new ThisShouldNeverHappenError(
        'Missing name during payment method creation',
      )
    return {
      type: 'card',
      billing_details: {
        name: formData.name ?? 'Unknown',
        address: {
          zip: `${formData.zipCode}`,
          country: 'US',
          street: formData.streetAddress,
          street2: formData.streetAddress2,
          city: formData.city,
          state: formData.state,
        },
        email: formData.sendEmail ? formData.emailAddress : undefined,
      },
    }
  }

  throw new ThisShouldNeverHappenError(
    'Invalid payment method or Tilled form data.',
  )
}

type SubmitTilledPaymentReferrer = 'self-serve' | 'payment-workflow'

type RecordSubmitPaymentRUMEvent = {
  referrer: SubmitTilledPaymentReferrer
  status: 'success' | 'error'
  paymentMethod: PaymentMethod
  savedNewPaymentMethod?: boolean
  usedExistingPaymentMethod?: boolean
}
const recordSubmitPaymentRUMEvent = (event: RecordSubmitPaymentRUMEvent) => {
  datadogRum.addAction('bz-payment-tilled-submit', event)
}
export const useSubmitTilledPayment = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>({
  accountGuid,
  tilledFormInfo,
  invoice,
  paymentAmountUsc,
  links,
  paymentMethod,
  onError,
}: UseSubmitTilledPaymentProps<PaymentMethodType>) => {
  const message = useMessage()
  const registerIntentMutation =
    trpc.payments['unauth:payments:register-intent'].useMutation()
  const recordPaymentMutation =
    trpc.payments['unauth:payments:record'].useMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [didSucceed, setDidSucceed] = useState(false)
  const { createPaymentMethod, isLoading: isCreatingPaymentMethod } =
    useCreateTilledPaymentMethod({
      accountGuid,
      tilledFormInfo,
      paymentMethod,
      onError,
    })

  const getPaymentMethodDetails = useCallback(
    (formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>) =>
      getTilledPaymentMethodDetails(paymentMethod, formData),
    [paymentMethod],
  )

  const onSubmit = useCallback(
    async (
      formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
      companyGuid: string,
      tilledMerchantId: string,
      referrer: SubmitTilledPaymentReferrer,
    ) => {
      if (!tilledFormInfo) {
        console.error('Tilled form info undefined')
        message.error('Cannot submit payment. Please try again later.')
        return
      }
      const { tilled } = tilledFormInfo
      if (!tilled) {
        console.error('Tilled client undefined')
        message.error('Cannot submit payment. Please try again later.')
        return
      }

      setIsLoading(true)
      const paymentRecordGuid = nextGuid()
      const paymentMethodRecordGuid: string | undefined = isCreditCardFormData(
        formData,
      )
        ? formData.selectedCardOnFile?.paymentMethodRecordGuid
        : undefined
      try {
        const tilledPaymentMethodRequest = getPaymentMethodDetails(formData)
        const tilledPaymentMethodId: string | undefined = isCreditCardFormData(
          formData,
        )
          ? formData.selectedCardOnFile?.externalPaymentMethodId
          : undefined

        await recordPaymentMutation.mutateAsync({
          invoiceGuid: invoice.invoiceGuid,
          jobGuid: links?.jobGuid,
          jobAppointmentGuid: links?.jobAppointmentGuid,
          maintenancePlanGuid: links?.maintenancePlanGuid,
          accountGuid,
          companyGuid,
          paymentRecordGuid,
          status: PaymentStatus.SUBMITTING,
          invoiceReferenceNumber: `${invoice.displayId}`,
          amountUsd: usCentsToUsd(paymentAmountUsc),
          paymentMethod,
          errorInfo: null,
          paymentMethodRecordGuid,
        })

        const { clientSecret } = await registerIntentMutation.mutateAsync({
          paymentRecordGuid,
          accountGuid,
          companyGuid,
          merchantId: tilledMerchantId,
          invoiceGuid: invoice.invoiceGuid,
          amountUsd: usCentsToUsd(paymentAmountUsc),
          jobGuid: links?.jobGuid,
          jobAppointmentGuid: links?.jobAppointmentGuid,
        })
        const payment = await tilled.confirmPayment(clientSecret, {
          payment_method: tilledPaymentMethodId ?? tilledPaymentMethodRequest,
        })

        const status =
          payment.status === 'processing'
            ? PaymentStatus.PENDING
            : payment.status === 'succeeded'
            ? PaymentStatus.PAID
            : PaymentStatus.FAILED

        await recordPaymentMutation.mutateAsync({
          invoiceGuid: invoice.invoiceGuid,
          accountGuid,
          companyGuid,
          paymentRecordGuid,
          status,
          statusDetail: payment.last_payment_error?.message ?? payment.status,
          invoiceReferenceNumber: `${invoice.displayId}`,
          occurredAt: payment.created_at,
          amountUsd: usCentsToUsd(payment.amount),
          externalPaymentId: payment.id,
          paymentMethod,
          jobGuid: links?.jobGuid,
          jobAppointmentGuid: links?.jobAppointmentGuid,
          maintenancePlanGuid: links?.maintenancePlanGuid,
          paymentMethodRecordGuid,
          errorInfo: payment.last_payment_error
            ? getTilledPaymentErrorDetail(
                paymentMethod,
                payment.last_payment_error,
              ) ?? null
            : null,
        })

        if (payment.last_payment_error) {
          onError?.(payment.last_payment_error)
          recordSubmitPaymentRUMEvent({
            referrer,
            status: 'error',
            paymentMethod,
            savedNewPaymentMethod: false,
            usedExistingPaymentMethod: !!tilledPaymentMethodId,
          })
        } else {
          // Save the payment method _only_ after the payment is successfully recorded. This will prevent us from
          // optimistically saving the payment method and then having the payment fail without having a way to
          // let the user manage their card on file. Also don't try and save the payment method if it's already
          // on file.
          if (!tilledPaymentMethodId && formData.savePaymentMethod) {
            await createPaymentMethod(formData, companyGuid, tilledMerchantId)
          }
          recordSubmitPaymentRUMEvent({
            referrer,
            status: 'success',
            paymentMethod,
            savedNewPaymentMethod:
              !tilledPaymentMethodId && formData.savePaymentMethod,
            usedExistingPaymentMethod: !!tilledPaymentMethodId,
          })

          setDidSucceed(true)
        }
      } catch (err: unknown) {
        console.error(err)
        onError?.(err as Error)
      } finally {
        setIsLoading(false)
      }

      return paymentRecordGuid
    },
    [
      tilledFormInfo,
      message,
      getPaymentMethodDetails,
      recordPaymentMutation,
      invoice.invoiceGuid,
      invoice.displayId,
      links?.jobGuid,
      links?.jobAppointmentGuid,
      links?.maintenancePlanGuid,
      accountGuid,
      paymentAmountUsc,
      paymentMethod,
      registerIntentMutation,
      onError,
      createPaymentMethod,
    ],
  )

  return {
    onSubmit,
    isLoading: isLoading || isCreatingPaymentMethod,
    didSucceed,
  }
}
