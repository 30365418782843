import {
  BadInputException,
  PrequalRecord,
  PrequalStatus,
  WISETACK_PREQUAL_CALLBACK_URI,
  WisetackSamplePrequalEvent,
  bzExpect,
  createDeclinedPrequalStatusWebhook,
  createInitiatedPrequalStatusWebhook,
  createPrequalifiedPrequalStatusWebhook,
  isNullish,
} from '@breezy/shared'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { getConfig } from '../../../config'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'

const config = getConfig()

export type WisetackWebhookPrequalTestMutation = {
  prequalRecord: PrequalRecord
  maxQualifiedAmountUsc?: number
  changedStatus: PrequalStatus
  onSuccess?: () => void
}

export const useWisetackPrequalWebhookTestMutation = () => {
  const tzId = useExpectedCompanyTimeZoneId()

  const callWisetackWebhook = async (data: WisetackSamplePrequalEvent) => {
    const response = await fetch(
      `${config.apiUrl}/${WISETACK_PREQUAL_CALLBACK_URI}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )

    // The webhook is async and returns a 200 response immediately, so
    // we add an artificial delay here to give the live query time
    // to update the UI with the updated loan record status.
    await new Promise(resolve => setTimeout(resolve, 1000))

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    return 'Webhook called successfully'
  }

  const wisetackWebhookMutation = useMutation(callWisetackWebhook, {
    onSuccess: _ => {},
    onError: error => {
      console.error('Error generating test data:', error)
    },
  })

  const loading = wisetackWebhookMutation.isLoading

  const triggerWisetackWebhook = useCallback(
    ({
      prequalRecord,
      maxQualifiedAmountUsc,
      changedStatus,
    }: WisetackWebhookPrequalTestMutation) => {
      let data: WisetackSamplePrequalEvent | undefined
      switch (changedStatus) {
        case 'INITIATED':
          if (!isNullish(maxQualifiedAmountUsc))
            throw new BadInputException(
              'maxQualifiedAmountUsc should never be passed in for INITIATED status',
            )

          data = createInitiatedPrequalStatusWebhook(prequalRecord, tzId)
          break
        case 'PREQUALIFIED':
          if (isNullish(maxQualifiedAmountUsc))
            throw new BadInputException(
              'maxQualifiedAmountUsc is required for PREQUALIFIED status',
            )
          if (maxQualifiedAmountUsc < 0)
            throw new BadInputException(
              'maxQualifiedAmountUsc must be greater than 0',
            )
          data = createPrequalifiedPrequalStatusWebhook(
            prequalRecord,
            maxQualifiedAmountUsc,
            // TODO: make this configurable
            ['0.00%', '4.90%', '5.90%', '7.90%'],
            tzId,
          )
          break
        case 'DECLINED':
          if (!isNullish(maxQualifiedAmountUsc))
            throw new BadInputException(
              'maxQualifiedAmountUsc should never be passed in for DECLINED status',
            )
          data = createDeclinedPrequalStatusWebhook(prequalRecord)
          break
      }

      return wisetackWebhookMutation.mutate(bzExpect(data))
    },
    [tzId, wisetackWebhookMutation],
  )

  return {
    triggerWisetackWebhook,
    loading,
  }
}
