import { z } from 'zod'
import { AsyncFn } from '../../common'
import { CompanyGuid } from '../Company/Company'
import { Point, PointSchema } from '../Geo/Geo'
import { USStateAbbreviationSchema } from '../Geolocation/USStates'
import { Guid, bzOptional } from '../common-schemas'

export const AddressDtoSchema = z.object({
  line1: z.string(),
  line2: bzOptional(z.string()),
  city: z.string(),
  stateAbbreviation: USStateAbbreviationSchema,
  zipCode: z.string(),
  geoLocation: bzOptional(PointSchema),
})

export type AddressDto = z.infer<typeof AddressDtoSchema>

export type AddressGuid = Guid

export interface Address {
  line1: string
  line2?: string
  city: string
  stateAbbreviation: string
  zipCode: string
  geoLocation?: Point
}

export type IdentifiableAddress = Address & {
  addressGuid: AddressGuid
}

export const sampleIdentifiableAddress: IdentifiableAddress = {
  addressGuid: '2a4f4254-fae0-493a-9bf9-ab12ea1d9914',
  line1: '123 Main St',
  line2: 'Apt 1',
  city: 'New York',
  stateAbbreviation: 'NY',
  zipCode: '10001',
  geoLocation: {
    type: 'Point',
    coordinates: [-73.987308, 40.757824],
  },
}

export const streetAddressLine1And2Condensed = (line1: string, line2?: string): string =>
  [line1, line2].filter(Boolean).join(', ')

export class BzAddress implements Address {
  public readonly zip5: string
  public readonly canonicalFullAddress: string
  static create = (a: Address): BzAddress => {
    return new BzAddress(a.line1, a.line2, a.city, a.stateAbbreviation, a.zipCode, a.geoLocation)
  }
  static formatAddressSingleLine = (address: Address): string => {
    return BzAddress.create(address).formatSingleLine()
  }
  static formatAddressLine1And2Condensed = (address: Address): string => {
    return BzAddress.create(address).streetAddressLine1And2Condensed()
  }

  static getZip5 = (address: Address) => address.zipCode.slice(0, 5)

  static makeCanonicalFullAddress = (address: Address) =>
    `${address.line1}|${address.line2 || ''}|${address.city}|${address.stateAbbreviation}|${BzAddress.getZip5(address)}`

  constructor(
    public line1: string,
    public line2: string | undefined,
    public city: string,
    public stateAbbreviation: string,
    public zipCode: string,
    public geoLocation?: Point,
  ) {
    this.zip5 = BzAddress.getZip5(this)
    this.canonicalFullAddress = BzAddress.makeCanonicalFullAddress(this)
  }

  toString = (): string => this.canonicalFullAddress

  streetAddressLine1And2Condensed = (): string => {
    return streetAddressLine1And2Condensed(this.line1, this.line2)
  }
  formatSingleLine = (): string =>
    `${this.line1}, ${this.line2 ? this.line2 + ', ' : ''}${this.city}, ${this.stateAbbreviation}, ${this.zip5}`
}

export type IAddressWriter = AsyncFn<BzAddress, IdentifiableAddress>

export type AddressQuery =
  | { type: 'by-address-guid'; addressGuid: AddressGuid }
  | { type: 'by-canonical-form'; canonicalForm: string }

export type IAddressQuerier = AsyncFn<AddressQuery, IdentifiableAddress[]>

export type AddressGeocodeMigratorResponse = {
  addressesMigrated: number
  addressesNotMigrated: {
    addressGuid: AddressGuid
    reason: string
  }[]
}
export type AddressGeocodeMigrator = AsyncFn<CompanyGuid, AddressGeocodeMigratorResponse>
