import { Input } from 'antd'
import React from 'react'

import { useStringFieldInfo, useTsController } from '@ts-react/form'
import BaseTextField from '../BaseTextField/BaseTextField'

const TextField = React.memo(() => {
  const {
    field: { onChange, value, name },
  } = useTsController<string>()

  const { placeholder } = useStringFieldInfo()

  return (
    <BaseTextField>
      <Input
        name={name}
        size="large"
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </BaseTextField>
  )
})

export default TextField
