import {
  GraphQLError,
  OperationDefinitionNode,
  OperationTypeNode,
} from 'graphql'
import { AnyVariables, Operation } from 'urql'

export const getUrqlOperationName = (
  operation: Operation<unknown, AnyVariables>,
) => (operation.query.definitions[0] as OperationDefinitionNode).name?.value

export const toGraphQlError = (
  error: GraphQLError,
  operation: Operation<unknown, AnyVariables>,
) => {
  // Extract the verb (query, mutation, subscription) from the operation
  const operationType = operation.kind

  const defaultVerb = operationType.startsWith(OperationTypeNode.MUTATION)
    ? 'execute'
    : operationType.startsWith(OperationTypeNode.SUBSCRIPTION)
    ? 'subscribe'
    : 'query'

  const operationName = getUrqlOperationName(operation)
  return {
    errorTitle: `Failed to ${defaultVerb} ${operationName ?? ''}`,
    errorMessage: `${error.message}`,
  }
}
