import {
  Account,
  BzDateTime,
  CreditRecord,
  JobClass,
  UTC_TIME_ZONE,
  isCreditActive,
  isNullish,
  isNullishOrEmpty,
} from '@breezy/shared'
import { faCheck, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, Form, FormInstance, Row, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import classNames from 'classnames'
import { ReactNode, memo, useEffect, useMemo } from 'react'
import { useQuery } from 'urql'
import ThinDivider from '../../elements/ThinDivider'
import { FIND_ACTIVE_MAINTENANCE_PLAN_AT_ACCOUNT_LOCATION } from './CreateOrEditJobForm.gql'
import { CreateOrEditNewJobFormSchema } from './CreateOrEditNewJobFormSchema'

type MaintenancePlanMembershipInfoCardProps = {
  form: FormInstance<CreateOrEditNewJobFormSchema>
  formMode: 'edit' | 'create'
  initialValue?: boolean
  account?: Account
  hideField: boolean
  jobTypeGuid?: string
  previousJobTypeGuid?: string
  jobClass?: JobClass
  selectedLocationGuid?: string
  labelClassName: string
  className?: string
}

/**
 * ! This is very similar to the JobMaintenancePlanMembershipCard component. Ideally
 * ! we'd try to combine these into a single component in the future, or at least share
 * ! larger parts of the logic between them
 */
export const MaintenancePlanMembershipInfoCard =
  memo<MaintenancePlanMembershipInfoCardProps>(
    ({
      form,
      formMode,
      initialValue,
      account,
      hideField,
      jobTypeGuid,
      previousJobTypeGuid,
      jobClass,
      selectedLocationGuid,
      labelClassName,
      className,
    }) => {
      const useMaintenancePlanCreditValue = useWatch(
        'useMaintenancePlanCredit',
        form,
      )

      // If the user has active maintenance plans, we should check to see if they have an
      // plan with available credits for the selected job location. The fetched Account data
      // doesn't readily have this information, so we have to do a separate fetch
      const [
        {
          data: activeMaintenancePlansData,
          fetching: fetchingActiveMaintenancePlansData,
        },
      ] = useQuery({
        query: FIND_ACTIVE_MAINTENANCE_PLAN_AT_ACCOUNT_LOCATION,
        variables: {
          companyGuid: account?.companyGuid || '',
          accountGuid: account?.accountGuid || '',
          locationGuid: selectedLocationGuid || '',
        },
        pause: isNullish(account) || isNullish(selectedLocationGuid),
      })

      const jobLocationActiveMaintenancePlan = useMemo(() => {
        if (
          !fetchingActiveMaintenancePlansData &&
          activeMaintenancePlansData?.maintenancePlansQueryable &&
          activeMaintenancePlansData.maintenancePlansQueryable.length > 0
        ) {
          return activeMaintenancePlansData.maintenancePlansQueryable[0]
        }

        return null
      }, [
        activeMaintenancePlansData?.maintenancePlansQueryable,
        fetchingActiveMaintenancePlansData,
      ])

      // Number of available credits for the job location's maintenance plan.
      // If the location does not have an active maintenance plan, this will be 0
      const activeMaintenancePlanAvailableCredits = useMemo(() => {
        if (jobLocationActiveMaintenancePlan) {
          return (
            jobLocationActiveMaintenancePlan.credits as CreditRecord[]
          ).filter(c => isCreditActive(c)).length
        } else {
          return 0
        }
      }, [jobLocationActiveMaintenancePlan])

      const showUseMaintenancePlanCreditContent = useMemo(() => {
        if (hideField || isNullishOrEmpty(jobTypeGuid)) {
          return false
        }

        if (jobLocationActiveMaintenancePlan) {
          return activeMaintenancePlanAvailableCredits > 0
        }

        return false
      }, [
        activeMaintenancePlanAvailableCredits,
        hideField,
        jobLocationActiveMaintenancePlan,
        jobTypeGuid,
      ])

      // "Use Maintenance Plan Credit" is enabled by default if the selected job
      // type has a class of MAINTENANCE
      useEffect(() => {
        // In edit mode, you can only change job type within the same job class,
        // so there's no need for this functionality
        if (
          formMode === 'edit' ||
          !showUseMaintenancePlanCreditContent ||
          previousJobTypeGuid === jobTypeGuid
        ) {
          return
        }

        if (jobClass === JobClass.MAINTENANCE) {
          form.setFieldValue('useMaintenancePlanCredit', true)
        } else {
          form.setFieldValue('useMaintenancePlanCredit', false)
        }
      }, [
        form,
        formMode,
        jobClass,
        jobTypeGuid,
        previousJobTypeGuid,
        showUseMaintenancePlanCreditContent,
      ])

      // Unfortunately the form's initialValue prop doesn't seem to work for the useMaintenancePlanCredit
      // checkbox, so we manually set it here
      useEffect(() => {
        form.setFieldValue('useMaintenancePlanCredit', initialValue)
      }, [form, initialValue])

      if (
        !jobLocationActiveMaintenancePlan ||
        isNullish(jobLocationActiveMaintenancePlan.activatedAt)
      ) {
        return null
      }

      let creditUsageContent: ReactNode = null
      if (!showUseMaintenancePlanCreditContent) {
        creditUsageContent = null
      } else {
        const availableCreditsRemainingText = `Completing this job will use one of your Maintenance Plan credits. You'll have ${
          activeMaintenancePlanAvailableCredits - 1
        } credits left afterwards.`
        const noCreditsRemainingText =
          `A Maintenance Plan credit will NOT be used when this job is ` +
          `completed because it has no credits remaining.`

        creditUsageContent = (
          <>
            <ThinDivider />
            <Row>
              <Col span={20}>
                <span className="semibold_14_22 grey9">
                  {`Use Plan Credit (${activeMaintenancePlanAvailableCredits} remaining)`}
                </span>
                <div className="italic text-gray-500">
                  {useMaintenancePlanCreditValue ? (
                    <>
                      {activeMaintenancePlanAvailableCredits > 0
                        ? availableCreditsRemainingText
                        : noCreditsRemainingText}
                    </>
                  ) : (
                    <>
                      Check the box above to have this job use a credit for the
                      associated Maintenance Plan when it is completed
                    </>
                  )}
                </div>
              </Col>
              <Col span={4} className="flex items-center justify-center">
                <Form.Item
                  name="useMaintenancePlanCredit"
                  className={classNames(
                    'flex h-full items-center',
                    labelClassName,
                  )}
                  valuePropName="checked"
                >
                  <Switch
                    className="p-[1px]"
                    disabled={!showUseMaintenancePlanCreditContent}
                    checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                    unCheckedChildren={<FontAwesomeIcon icon={faX} />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )
      }

      return (
        <>
          <Card
            bodyStyle={{ padding: '0px', margin: '0px' }}
            className={classNames('bg-[#FAFAFA] px-4 py-3', className)}
          >
            <h3 className="mb-0.5">Maintenance Plan Member</h3>
            <p className="m-0">
              {
                <>
                  This location has been a
                  <strong>{` ${jobLocationActiveMaintenancePlan.maintenancePlanDefinition?.marketingInfo?.name} `}</strong>
                  plan member since
                  <strong>{` ${BzDateTime.fromIsoString(
                    jobLocationActiveMaintenancePlan.activatedAt,
                    UTC_TIME_ZONE,
                  ).toHumanFriendlyMonthDayYear()}. `}</strong>
                  {jobLocationActiveMaintenancePlan.lastVisitedAt ? (
                    <>
                      The last maintenance visit was on
                      <strong>{` ${BzDateTime.fromIsoString(
                        jobLocationActiveMaintenancePlan.lastVisitedAt,
                        UTC_TIME_ZONE,
                      ).toHumanFriendlyMonthDayYear()}.`}</strong>
                    </>
                  ) : (
                    <>This location has not yet had a maintenance visit.</>
                  )}
                </>
              }
            </p>

            {creditUsageContent}
          </Card>
          <ThinDivider />
        </>
      )
    },
  )
