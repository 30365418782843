import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useDidTechCreateJob } from '../technician/useDidTechCreateJob'
import { useIsTechAssignedToJob } from '../technician/useIsTechAssignedToJob'
import { useIsOfficeApp } from '../useIsOfficeApp'
import { useHasFieldJobInformationFullAccess } from './useHasFieldJobInformationFullAccess'
import { useIsAuthorized } from './useIsAuthorized'

const useCanManageOfficeJob = () => {
  const isOfficeApp = useIsOfficeApp()
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    if (isOfficeApp)
      return isAuthorized(PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE)

    return true
  }, [isOfficeApp, isAuthorized])
}

const useCanManageFieldAssignedJobInformation = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    return isAuthorized(PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED)
  }, [isAuthorized])
}
export const useCanManageJob = (jobGuid: string) => {
  const isOfficeApp = useIsOfficeApp()
  const { isTechAssignedToJob, isLoading } = useIsTechAssignedToJob(jobGuid)
  const hasFieldJobInformationFullAccess = useHasFieldJobInformationFullAccess()
  const canManageOfficeJob = useCanManageOfficeJob()
  const { techCreatedJob, isLoading: isLoadingTechCreatedJob } =
    useDidTechCreateJob(jobGuid)
  const canManageFieldAssignedJobInformation =
    useCanManageFieldAssignedJobInformation()

  let canManage = false
  let loadingStatus = true

  if (isOfficeApp) {
    canManage = canManageOfficeJob
    loadingStatus = false
  } else if (hasFieldJobInformationFullAccess) {
    canManage = true
    loadingStatus = false
  } else if (canManageFieldAssignedJobInformation) {
    canManage = isTechAssignedToJob || techCreatedJob
    loadingStatus = isLoading || isLoadingTechCreatedJob
  } else {
    loadingStatus = false
  }

  return {
    canManage,
    isLoading: loadingStatus,
  }
}
