import { search as fuzzySearch } from 'fast-fuzzy'
import { z } from 'zod'
import { AsyncFn, isNullish, normalizeString } from '../../common'
import { guidSchema } from '../../contracts/_common'
import type { CompanyGuidContainer, ForCompanyUser } from '../Company/Company'
import { JobClass } from '../Job/JobClass'
import { bzOptional } from '../common-schemas'

export const upsertJobTypeRequestSchema = z
  .object({
    jobTypeGuid: guidSchema,
    name: z.string(),
    description: bzOptional(z.string()),
    jobClass: z.nativeEnum(JobClass),
    jobLifecycleGuid: guidSchema,
    isOpportunity: z.boolean(),
    // BZ-2708: Refactor this as "conversionThresholdUsc"
    opportunityConversionThresholdUsc: bzOptional(z.number().int().gte(0)),
    isPotentialHotLead: z.boolean(),
    hotLeadMinimumEquipmentAgeYears: bzOptional(z.number().int().gte(0)),
  })
  .refine(
    data => {
      if (!isNullish(data.isOpportunity)) {
        return !isNullish(data.opportunityConversionThresholdUsc)
      } else {
        return true
      }
    },
    { message: 'Job Type must have an Opportunity Conversion Threshold' },
  )
  .refine(
    data => {
      if (data.isPotentialHotLead) {
        return !isNullish(data.hotLeadMinimumEquipmentAgeYears)
      } else {
        return isNullish(data.hotLeadMinimumEquipmentAgeYears)
      }
    },
    {
      message:
        "Job Type must have a Hot Lead Minimum Equipment Age if and only if it's flagged as a Potential Hot Lead",
    },
  )

export type JobType = z.infer<typeof upsertJobTypeRequestSchema>

export type JobTypeMinimal = Omit<
  JobType,
  'isOpportunity' | 'opportunityConversionThresholdUsc' | 'isPotentialHotLead' | 'hotLeadMinimumEquipmentAgeYears'
>

export type UpsertJobTypeRequest = JobType

export type JobTypeUpserter = AsyncFn<ForCompanyUser<JobType>>

export type FullJobType = JobType & {
  companyGuid: string
  lastModifiedBy: string
  createdAt: string
  updatedAt: string
  archivedAt?: string
}

export type JobTypesReader = AsyncFn<CompanyGuidContainer, FullJobType[]>

export const tryMatchJobType = (jobTypes: JobType[], searchTerm: string): JobType | undefined => {
  if (jobTypes.length === 0 || !searchTerm || searchTerm.trim().length === 0) {
    return undefined
  }

  return fuzzySearch(normalizeString(searchTerm), jobTypes, {
    keySelector: jobType => jobType.name,
  })[0]
}
