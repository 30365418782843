import type { Router } from '@breezy/backend/src/api-server'
import {
  createTRPCReact,
  inferReactQueryProcedureOptions,
} from '@trpc/react-query'
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server'

export const trpc = createTRPCReact<Router>()

// infer the types for your router
export type ReactQueryOptions = inferReactQueryProcedureOptions<Router>
export type RouterInputs = inferRouterInputs<Router>
export type RouterOutputs = inferRouterOutputs<Router>
