import { isNullish } from '@breezy/shared'
import { useCallback, useEffect } from 'react'
import { useIsTechApp } from '../providers/AppContextWrapper'

type UseIntercomInput = {
  /**
   * Is the Intercom launcher icon visible on the screen?
   */
  isLauncherVisible?: boolean
}

type UseIntercomReturn = {
  isIntercomAvailable: () => boolean
  /**
   * Sets whether or not the Intercom launcher icon is visible or not on the screen.
   * @param isVisible Is the launcher icon visible?
   */
  setLauncherVisible: (isVisible: boolean) => void
}

/**
 * A hook that allows you interact with the Intercom JS widget
 */
export const useIntercom = (opts?: UseIntercomInput): UseIntercomReturn => {
  const isTechApp = useIsTechApp()

  const isIntercomAvailable = useCallback(() => {
    return !isNullish(window.Intercom) && typeof window.Intercom === 'function'
  }, [])

  useEffect(() => {
    if (!isIntercomAvailable() || !isTechApp) {
      return
    }
    window.Intercom('update', {
      hide_default_launcher: true,
    })
  }, [isIntercomAvailable, isTechApp])

  const setLauncherVisible = useCallback(
    (isVisible: boolean) => {
      if (!isIntercomAvailable() || isTechApp) {
        return
      }

      window.Intercom('update', {
        hide_default_launcher: !isVisible,
      })
    },
    [isIntercomAvailable, isTechApp],
  )

  const hideWindow = useCallback(() => {
    if (!isIntercomAvailable()) {
      return
    }

    window.Intercom('hide')
  }, [isIntercomAvailable])

  useEffect(() => {
    if (!opts) {
      return
    }

    setLauncherVisible(opts.isLauncherVisible ?? true)
  }, [opts, setLauncherVisible])

  useEffect(() => {
    window.addEventListener('beforeunload', hideWindow)

    return () => {
      window.removeEventListener('beforeunload', hideWindow)
    }
  }, [hideWindow])

  return {
    isIntercomAvailable,
    setLauncherVisible,
  }
}
