import axios from 'axios'

// https://aws.amazon.com/blogs/compute/build-a-serverless-private-url-shortener/
const MINIFIER_ENDPOINT = 'https://qr4tdpmno7.execute-api.us-east-1.amazonaws.com/prod/admin_shrink_url'

export const minifyUrl = async (url: string): Promise<string> => {
  const response = await axios.post(
    MINIFIER_ENDPOINT,
    { url_long: url, cdn_prefix: 'brzy.io' },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data.url_short
}
