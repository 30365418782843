import { blue6 } from '../themes/theme'

export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

const hashCode = (s: string) => {
  let hash = 0,
    i,
    chr
  if (s.length === 0) return hash
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return Math.abs(hash)
}

const LIGHTENING_FACTOR = 0.6

/**
 * Lightens the specified HEX color
 * @param hexColor The color to lighten
 * @param lighteningFactor How much we want the color to be lightened by (between 0 and 1)
 * @returns The lightened color
 */
export const lightenHexColor = (
  hexColor: string,
  lighteningFactor: number = LIGHTENING_FACTOR,
): string => {
  if (hexColor.startsWith('#')) {
    hexColor = hexColor.slice(1)
  }

  // Convert hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)

  // Ensures the lightening factor is always between 0 and 1
  const clampedLighteningFactor = Math.max(0, Math.min(lighteningFactor, 1))

  // Increase each color component by the factor, making sure it doesn't exceed 255
  const newR = Math.min(
    Math.round(r + clampedLighteningFactor * (255 - r)),
    255,
  )
  const newG = Math.min(
    Math.round(g + clampedLighteningFactor * (255 - g)),
    255,
  )
  const newB = Math.min(
    Math.round(b + clampedLighteningFactor * (255 - b)),
    255,
  )

  // Convert the new RGB color back to hex
  const newHexR = newR.toString(16).padStart(2, '0')
  const newHexG = newG.toString(16).padStart(2, '0')
  const newHexB = newB.toString(16).padStart(2, '0')

  return `#${newHexR}${newHexG}${newHexB}`
}

export const COLORS = [
  blue6,
  '#9254DE', // golden purple
  '#FFA940', // sunset orange
  '#36CFC9', // medium turquoise
  '#F759AB', // bubble gum
  '#FF7875', // salmon
  '#FFBB96', // peach-orange
  '#13C2C2', // turquoise
  '#FFEC3D', // yellow
  '#73D13D', // mantis
  '#F5222D', // carmine
  '#597EF7', // cornflower blue
  '#BAE637', // lime
  '#722ED1', // electric indigo
  '#B37FEB', // light pastel purple
  '#FF85C0', // cotton candy
  '#EB2F96', // deep cerise
  '#FA8C16', // tangerine
  '#40A9FF', // bright sky blue
] as const

export const LIGHT_COLORS = COLORS.map(lightenHexColor)

export const getNextColor = (i: number) => COLORS[i % COLORS.length]
export const getNextLightColor = (i: number) =>
  LIGHT_COLORS[i % LIGHT_COLORS.length]

export const lightUserColor = (s: string): string => {
  const index = hashCode(s || '') % LIGHT_COLORS.length

  return LIGHT_COLORS[index]
}

export const userColor = (s: string): string => {
  const index = hashCode(s || '') % COLORS.length

  return COLORS[index]
}

// ChatGPT
export const getAppropriateTextColor = (backgroundHexColor: string) => {
  const hexColor = backgroundHexColor.replace('#', '')

  // Convert hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16) / 255
  const g = parseInt(hexColor.slice(2, 4), 16) / 255
  const b = parseInt(hexColor.slice(4, 6), 16) / 255

  // Calculate the brightness of the color in the YIQ color space
  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  // Return "black" for bright colors and "white" for dark colors
  return brightness > 0.5 ? 'black' : 'white'
}
