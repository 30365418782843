import {
  MaintenancePlanMinimalInfo,
  MaintenancePlanStatus,
} from '@breezy/shared'
import { hexToRGB } from '../utils/color-utils'

type MaintenancePlanStylingProps = {
  readonly maintenancePlan?: MaintenancePlanMinimalInfo
  readonly opacity?: number
}

type MaintenancePlanStyling = {
  readonly borderClassName: string
  readonly borderColor?: string
  readonly color?: string
}

const useMaintenancePlanStyling = ({
  maintenancePlan,
  opacity = 0.35,
}: MaintenancePlanStylingProps): MaintenancePlanStyling => {
  const mpHexColor =
    maintenancePlan?.status === MaintenancePlanStatus.ACTIVE
      ? maintenancePlan?.planTypeFlare?.primaryColorHex
      : undefined

  const borderColor = mpHexColor ? hexToRGB(mpHexColor, opacity) : undefined
  const border =
    maintenancePlan?.status === MaintenancePlanStatus.ACTIVE &&
    maintenancePlan?.planTypeFlare
      ? `border-solid border-2 rounded-md`
      : ''

  return {
    borderClassName: border,
    borderColor,
    color: borderColor,
  }
}

export default useMaintenancePlanStyling
