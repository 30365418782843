import { PermissionV1, PermissionV2 } from '@breezy/shared'
import { useIsPrincipalAuthorized } from '../../../hooks/permission/useIsPrincipalAuthorized'
import {
  useIsCompanyUser,
  usePrincipalUser,
} from '../../../providers/PrincipalUser'

type AuthProps = {
  children: JSX.Element
  to?: PermissionV1 | PermissionV2
  overrideAuthorized?: boolean
  notAuthorizedView?: JSX.Element
  allowsNonCompanyUser?: boolean
}

export const Authorized = ({
  children,
  to,
  overrideAuthorized,
  notAuthorizedView,
  allowsNonCompanyUser,
}: AuthProps) => {
  const { principal } = usePrincipalUser()
  const isCompanyUser = useIsCompanyUser()
  const isPrincipalAuthorized = useIsPrincipalAuthorized(principal, to)

  if (!allowsNonCompanyUser && !isCompanyUser) return null
  return overrideAuthorized || !to || isPrincipalAuthorized
    ? children
    : notAuthorizedView ?? null
}

export const NotAuthorized = ({ children, to }: AuthProps) => {
  const { principal } = usePrincipalUser()
  const isPrincipalAuthorized = useIsPrincipalAuthorized(principal, to)

  return isPrincipalAuthorized ? null : children
}
