import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { ButtonType } from 'antd/lib/button'
import React from 'react'

type PaymentMethodButtonProps = {
  icon: IconDefinition
  text: string
  onClick: () => void
  type?: ButtonType
  ghost?: boolean
}

const PaymentMethodButton = React.memo(
  ({ icon, text, onClick, type, ghost }: PaymentMethodButtonProps) => (
    <Button
      ghost={ghost}
      type={type ?? 'primary'}
      onClick={onClick}
      className="box-shadow-depth-1 m-[8px] h-[40px] w-[240px]"
      icon={<FontAwesomeIcon className="mr-[12px] text-[20px]" icon={icon} />}
    >
      {text}
    </Button>
  ),
)

export default PaymentMethodButton
