import { gql } from '../../../generated'

export const COMPANY_USERS_QUERY = gql(/* GraphQL */ `
  query CompanyUsers($companyGuid: uuid!) {
    companyUsers(where: { companyGuid: { _eq: $companyGuid } }) {
      userByUserGuid {
        deactivatedAt
        userGuid
        firstNameInitial
      }
    }
  }
`)
