import { bzExpect } from '@breezy/shared'
import { useQuery } from 'urql'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { TECH_ACCOUNT_APPT_ASSIGNMENTS_QUERY } from './TechnicianHooks.gql'

export const useIsTechAssignedToAccount = (accountGuid: string) => {
  const principal = useExpectedPrincipal()
  const companyGuid = bzExpect(
    principal.company?.companyGuid,
    'companyGuid',
    'User Has No Company',
  )

  const [techAssignedToAccountQuery] = useQuery({
    query: TECH_ACCOUNT_APPT_ASSIGNMENTS_QUERY,
    variables: {
      accountGuid,
      companyGuid,
      technicianUserGuid: principal.userGuid,
    },
  })

  return {
    isLoading: techAssignedToAccountQuery.fetching,
    isTechAssignedToAccount: Boolean(
      techAssignedToAccountQuery.data?.jobAppointments?.length &&
        techAssignedToAccountQuery.data.jobAppointments.length > 0,
    ),
  }
}
