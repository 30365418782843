import { gql } from '../../generated'

export const INSERT_PRICEBOOK_ITEM = gql(/* GraphQL */ `
  mutation InsertPricebookItem($pricebookItem: PricebookItemsInsertInput!) {
    insertPricebookItemsOne(object: $pricebookItem) {
      __typename
    }
  }
`)

export const GET_PRICEBOOK_DISCOUNTS = gql(/* GraphQL */ `
  query GetPricebookDiscounts($companyGuid: uuid!) {
    pricebookDiscounts(
      where: { companyGuid: { _eq: $companyGuid }, isActive: { _eq: true } }
    ) {
      description
      discountAmountUsd
      discountRate
      name
      type
      pricebookDiscountGuid
    }
  }
`)

export const GET_PRICEBOOK_TAX_RATES = gql(/* GraphQL */ `
  query GetPricebookTaxRates {
    pricebookTaxRates(where: { isActive: { _eq: true } }) {
      rate
      name
      pricebookTaxRateGuid
      accountTypes
      zipCodes
    }
  }
`)
