import { z } from 'zod'
import { AsyncFn, SemVer } from '../../common'

export type LlmCompletionClient = AsyncFn<string, LlmCompletion<string>>

export type LlmTextMessage = {
  type: 'text'
  content: string
}
export type LlmImageMessage = {
  type: 'image'
  imageUrl: string
  detail?: 'low' | 'high' | 'auto'
}

export type LlmMessage = LlmTextMessage | LlmImageMessage

export type LlmRequiredToolOutputInfo = {
  toolName: string
  toolCallId: string
}

export interface LlmThread {
  getRequiredToolOutputInfo: () => LlmRequiredToolOutputInfo[]
  sendMessages: (messages: LlmMessage[]) => Promise<string>
  submitRequiredToolOutputs: (toolOutputs: { toolCallId: string; result: string }[]) => Promise<string>
}

export type LlmThreadCreatorRequest = {
  assistantId: string
}

export type LlmThreadCreatorResponse = LlmThread

export type LlmThreadCreator = AsyncFn<LlmThreadCreatorRequest, LlmThreadCreatorResponse>

export type LlmCompletionClientWithZod = <Output>(
  prompt: string,
  outputSchema: z.ZodSchema<Output>,
) => Promise<LlmCompletion<Output>>

export const stringContainerSchema = z.object({
  value: z.string(),
})

export type StringContainer = {
  value: string
}

export type LlmPromptVersion = SemVer
export type LlmPromptText = string

export type LlmPrompt = {
  version: LlmPromptVersion
  text: LlmPromptText
}

export type LlmJsonOperation<Input, Output> = LlmPrompt & {
  inputSchema?: z.ZodSchema<Input>
  outputSchema?: z.ZodSchema<Output>
}

export type LlmPromptTextContainer = { prompt: LlmPromptText }
export type LlmInputContainer<Input> = { input: Input }

export type LlmModelMetadata = {
  modelName: string
}

export type LlmCompletion<Response> = {
  response: Response
  metadata: LlmModelMetadata
}

export type LlmCompletionGenerationRequest<Input> = Partial<LlmInputContainer<Input>>

export type LlmCompletionGenerator<Input = string, Response = string> = AsyncFn<
  LlmCompletionGenerationRequest<Input>,
  LlmCompletion<Response>
>

export const createLlmRuntimePromptText = <Input extends object>(
  input: LlmPromptTextContainer &
    Partial<LlmInputContainer<Input>> & {
      generatedRecommendationValue?: number
    },
): string => {
  let promptText = input.prompt
  if (input.input) {
    promptText += `\n${JSON.stringify(input.input)}`
  }
  if (input.generatedRecommendationValue) {
    promptText += `\nGenerated Recommendation Value: ${input.generatedRecommendationValue}`
  }
  return promptText
}

export const createLlmJsonOperation = <Input, Output>(
  prompt: LlmPrompt,
  inputSchema: z.ZodSchema<Input>,
  outputSchema: z.ZodSchema<Output>,
): LlmJsonOperation<Input, Output> => {
  return {
    ...prompt,
    inputSchema,
    outputSchema,
  }
}

export const createLlmJsonOutputOperation = <Output>(
  prompt: LlmPrompt,
  outputSchema: z.ZodSchema<Output>,
): LlmJsonOperation<unknown, Output> => {
  return { ...prompt, outputSchema }
}
