import {
  BzDateFns,
  EstimateV2Status,
  HtmlString,
  IsoDateString,
  JobGuid,
  WISETACK_FULL_DISCLOSURE_HTML_MESSAGE,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { DownloadableInvoice } from '../../../components/DownloadableInvoice/DownloadableInvoice'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { BasicEstimateOption } from '../BasicEstimateOption'
import {
  DEFAULT_ESTIMATE_OPTION_NAME,
  Option,
  useAnyFinanceable,
} from '../estimatesFlowUtils'

type DownloadableEstimateProps = {
  downloadableElementRef: React.RefObject<HTMLDivElement>
  messageHtml?: HtmlString
  jobGuid: JobGuid
  displayId: number
  createdAt: IsoDateString
  status: EstimateV2Status
  options: Option[]
  companyName: string
  contactFullName: string
  signatureFileUrl?: string
  acceptedOnBehalfByUser?: {
    firstName: string
    lastName: string
  }
  acceptedAt?: IsoDateString
  disclaimer: string
}

export const DownloadableEstimate = React.memo<DownloadableEstimateProps>(
  ({
    downloadableElementRef,
    messageHtml,
    jobGuid,
    displayId,
    createdAt,
    status,
    options,
    companyName,
    contactFullName,
    signatureFileUrl,
    acceptedOnBehalfByUser,
    acceptedAt,
    disclaimer,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const isAccepted = status === 'ACCEPTED'

    const summaryData = useMemo(
      () => ({
        options: options.map(option => ({
          name: option.displayName || DEFAULT_ESTIMATE_OPTION_NAME,
          totalUsc: option.totalUsc,
        })),
      }),
      [options],
    )
    const midBannerText = useMemo(
      () =>
        isAccepted ? undefined : (
          <>
            Please review the{' '}
            {options.length ? (
              <>
                <strong>{options.length} options</strong> that have
              </>
            ) : (
              'estimate that has'
            )}{' '}
            been prepared for your review
          </>
        ),
      [isAccepted, options.length],
    )
    const isAnyFinanceable = useAnyFinanceable(options)

    const displayedOptions = useMemo(() => {
      if (isAccepted) {
        const selectedOption = options.find(option => option.selected)
        if (selectedOption) {
          return (
            <BasicEstimateOption
              customerFacing
              showLoanDisclosure={false}
              subtotalCollapsible={false}
              option={selectedOption}
              index={selectedOption.seq}
            />
          )
        }
      } else {
        return options.map((option, index) => (
          <BasicEstimateOption
            customerFacing
            showLoanDisclosure={false}
            key={option.optionGuid}
            subtotalCollapsible={false}
            index={index}
            option={option}
          />
        ))
      }
    }, [isAccepted, options])

    return (
      <DownloadableInvoice
        downloadableElementRef={downloadableElementRef}
        jobGuid={jobGuid}
        displayId={displayId}
        issuedDate={createdAt}
        messageHtml={messageHtml}
        summaryData={summaryData}
        midBannerText={midBannerText}
        disclaimer={disclaimer}
      >
        <div className="mt-6 space-y-3">{displayedOptions}</div>
        {options.length > 1 && !isAccepted && (
          <div className="mt-5 text-center font-semibold">
            Choose 1 of the options above and contact {companyName} with your
            decision.
          </div>
        )}

        {isAccepted && (
          <div className="mt-4 flex flex-row space-x-6 text-base *:flex-1">
            {(
              [
                [
                  signatureFileUrl ? (
                    <img
                      crossOrigin="anonymous"
                      src={signatureFileUrl}
                      alt="signature"
                      className="w-full"
                    />
                  ) : acceptedOnBehalfByUser ? (
                    `Accepted by ${acceptedOnBehalfByUser.firstName} ${acceptedOnBehalfByUser.lastName} on behalf of ${contactFullName} with their permission.`
                  ) : (
                    'N/A'
                  ),
                  'Signature',
                ],
                [
                  acceptedAt
                    ? BzDateFns.formatFromISO(acceptedAt, 'MMMM d, yyyy', tzId)
                    : '',
                  'Date',
                ],
              ] as const
            ).map(([content, label]) => (
              <div key={label}>
                <div className="align-start flex h-[126px] flex-col justify-end">
                  {content}
                </div>
                <div className="mt-2 border-0 border-t border-solid border-bz-gray-500 pt-2 text-base font-semibold">
                  {label}
                </div>
              </div>
            ))}
          </div>
        )}

        {isAnyFinanceable && (
          <div
            className="mt-5 text-xs text-bz-gray-800"
            dangerouslySetInnerHTML={{
              __html: WISETACK_FULL_DISCLOSURE_HTML_MESSAGE,
            }}
          />
        )}
      </DownloadableInvoice>
    )
  },
)
