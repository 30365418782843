import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'

type DangerConfirmModalProps = {
  title?: string
  okButtonText?: React.ReactNode
  open: boolean
  onOk: () => void
  onCancel: () => void
  promptText: React.ReactNode
}

export const DangerConfirmModal = ({
  title = 'Are you sure?',
  okButtonText = 'OK',
  open,
  onOk,
  onCancel,
  promptText,
}: DangerConfirmModalProps) => (
  <Modal
    title={title}
    open={open}
    onOk={onOk}
    onCancel={onCancel}
    okText={okButtonText}
    okButtonProps={{ danger: true }}
  >
    <div className="column">
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        size="4x"
        color="#ffcc33"
        style={{ marginBottom: 12 }}
      />
      <div className="text-center">{promptText}</div>
    </div>
  </Modal>
)

export default DangerConfirmModal
