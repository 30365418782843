import { AsyncFn, R } from '../../common'
import { CompanyGuidContainer, ForCompany, ForCompanyUser } from '../Company/Company'
import { EquipmentType } from '../Equipment/EquipmentType'
import { CreateNewJobDTO, JobDetailsDTO, JobReferenceInfo, UpdateJobDTO } from './Dtos'
import { JobClass } from './JobClass'

export enum JobToEquipmentRelationship {
  SERVICING = 'SERVICING',
  MAINTAINING = 'MAINTAINING',
  INSTALLING = 'INSTALLING',
  DEACTIVATING = 'DEACTIVATING',
  LINKED = 'LINKED',
}

export const JOB_TO_EQUIPMENT_RELATIONSHIP_TYPES = {
  SERVICING: {
    jobClasses: [JobClass.SERVICE],
  },
  MAINTAINING: {
    jobClasses: [JobClass.MAINTENANCE],
  },
  INSTALLING: {
    jobClasses: [JobClass.INSTALL],
  },
  DEACTIVATING: {
    jobClasses: [JobClass.INSTALL],
  },
  LINKED: {
    jobClasses: [
      JobClass.SERVICE,
      JobClass.MAINTENANCE,
      JobClass.SALES,
      JobClass.ESTIMATE_REPAIR,
      JobClass.ESTIMATE_REPLACE,
      JobClass.INSTALL,
      JobClass.WARRANTY,
      JobClass.CALLBACK,
    ],
  },
} as const

export const JOB_TO_EQUIPMENT_RELATIONSHIP_TYPE_VALUES = R.keys(JOB_TO_EQUIPMENT_RELATIONSHIP_TYPES)
export type JobToEquipmentRelationshipType = (typeof JOB_TO_EQUIPMENT_RELATIONSHIP_TYPE_VALUES)[number]
export type EquipmentTypeJobLink = {
  equipmentType: EquipmentType
  estimatedEquipmentAge?: string
  relationshipType: JobToEquipmentRelationshipType
}
export type JobGuid = string

export interface JobGuidContainer {
  readonly jobGuid: JobGuid
}

export type IJobDetailsReader = AsyncFn<JobGuid, JobDetailsDTO>

export type IJobUpdater = AsyncFn<ForCompany<UpdateJobDTO>, void>

export type JobDisplayId = number

export type NewJobCreatorResult = JobReferenceInfo

export type NewJobCreator = AsyncFn<ForCompanyUser<CreateNewJobDTO>, NewJobCreatorResult>

export type JobSearchableRecordResyncRecordsWriter = AsyncFn<CompanyGuidContainer>
