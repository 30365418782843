import { createContext, useContext } from 'react'
import { Composable } from '../utils/Composable'
import { Applications } from '../utils/application-type'

type AppContextWrapperContext = {
  appType: Applications
}

type AppContextWrapperProps = AppContextWrapperContext & Composable

const AppContext = createContext<AppContextWrapperContext>({
  appType: Applications.OFFICE,
})

export const AppContextWrapper = ({
  children,
  appType,
}: AppContextWrapperProps) => {
  return (
    <AppContext.Provider value={{ appType }}>{children}</AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
export const useIsTechApp = () => {
  const { appType } = useAppContext()

  return appType === Applications.TECHNICIAN
}

export const useIsOfficeApp = () => {
  const { appType } = useAppContext()
  return appType === Applications.OFFICE
}

export const useIsAccountApp = () => {
  const { appType } = useAppContext()
  return appType === Applications.ACCOUNT
}
