import React from 'react'

type LabeledItemProps = React.PropsWithChildren<{
  readonly style?: React.CSSProperties
  readonly title: string
}>

const LabeledItem = ({ title, children, style }: LabeledItemProps) => (
  <div className="item-segment" style={style}>
    <h4 className="gray7">{title}</h4>
    {children}
  </div>
)

export default LabeledItem
