import { PrequalRecord, PrequalRecordWithContactName } from '@breezy/shared'
import { FetchPrequalRecordQuery, PrequalRecordWithContactNameFragment } from '../../query'

export type FetchPrequalRecord = FetchPrequalRecordQuery['wisetackPrequalRecords'][number]
export type FetchPrequalRecordStatus = FetchPrequalRecordQuery['wisetackPrequalRecords'][number]['status']
export type FetchPrequalRecordWithContactName = PrequalRecordWithContactNameFragment
export const convertFetchPrequalRecordToPrequalRecord = (input: FetchPrequalRecord): PrequalRecord => ({
  prequalRecordGuid: input.wisetackPrequalRecordGuid,
  companyGuid: input.companyGuid,
  accountGuid: input.accountGuid,
  contactGuid: input.contactGuid,
  jobGuid: input.jobGuid,
  merchantGuid: input.wisetackMerchantGuid,
  prequalifiedAmountUsc: input.prequalifiedAmountUsc,
  maxQualifiedAmountUsc: input.maxQualifiedAmountUsc,
  externalPrequalGuid: input.wisetackPrequalGuid,
  aprRange: input.aprRange,
  status: input.status,
  expirationDate: input.expirationDate,
  prequalifiedAt: input.prequalifiedAt,
  declinedAt: input.declinedAt,
  expiredAt: input.expiredAt,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
  prequalLink: input.prequalLink,
})

export const convertPrequalRecordToFetchPrequalRecord = (input: PrequalRecord): FetchPrequalRecord => ({
  wisetackPrequalRecordGuid: input.prequalRecordGuid,
  companyGuid: input.companyGuid,
  accountGuid: input.accountGuid,
  contactGuid: input.contactGuid,
  jobGuid: input.jobGuid,
  wisetackMerchantGuid: input.merchantGuid,
  prequalifiedAmountUsc: input.prequalifiedAmountUsc,
  maxQualifiedAmountUsc: input.maxQualifiedAmountUsc,
  wisetackPrequalGuid: input.externalPrequalGuid,
  aprRange: input.aprRange,
  status: input.status,
  expirationDate: input.expirationDate,
  prequalifiedAt: input.prequalifiedAt,
  declinedAt: input.declinedAt,
  expiredAt: input.expiredAt,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
  prequalLink: input.prequalLink,
})

export const convertFetchedToPrequalRecordWithContactName = (
  input: FetchPrequalRecordWithContactName,
): PrequalRecordWithContactName => {
  const { contact, ...rest } = input
  const prequalRecord = convertFetchPrequalRecordToPrequalRecord(rest)
  return {
    ...prequalRecord,
    contactFullName: contact?.fullName ?? '',
  }
}
