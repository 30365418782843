import { R } from '@breezy/shared'
import classNames from 'classnames'
import React, { useState } from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceContext } from '../invoiceUtils'

type DisclaimerSectionProps = {
  className?: string
}

export const DisclaimerSection = React.memo<DisclaimerSectionProps>(
  ({ className }) => {
    const { disclaimer } = useStrictContext(InvoiceContext)

    const [disclaimerExpanded, setDisclaimerExpanded] = useState(false)
    return (
      <OnsitePageSection title="Disclaimer" className={className}>
        <div
          className={classNames('text-sm', {
            'line-clamp-3': !disclaimerExpanded,
          })}
        >
          {disclaimer}
        </div>
        {/* NOTE: we're being a little lazy here. If the disclaimer isn't sufficiently long, this button "won't do
      anything" because the line clamp isn't doing anything because it's not longer than 3 lines. But it's a big
      hassle to use JS to determine if the line clamp is really clamping and then show this when I expect the
      disclaimer to be rather long and the window to be rather narrow. */}
        <div
          className="mt-1 cursor-pointer underline transition-all ease-in-out hover:text-bz-gray-700"
          onClick={() => setDisclaimerExpanded(R.not)}
        >
          {disclaimerExpanded ? 'See less' : 'Read more...'}
        </div>
      </OnsitePageSection>
    )
  },
)
