import { z } from 'zod'
import { TaggableNoteLinkDataSchema } from './Notes'
import { PersonSchema, guidSchema, isoDateStringSchema } from './_common'

export const InternalNotificationTaggedInNoteMetadataSchema = z.object({
  type: z.literal('TAGGED_IN_NOTE'),
  noteGuid: guidSchema,
  taggedByUser: PersonSchema,
  sourceData: TaggableNoteLinkDataSchema,
})

export const InternalNotificationAccountReminderDueMetadataSchema = z.object({
  type: z.literal('ACCOUNT_REMINDER_DUE'),
  reminderGuid: guidSchema,
  accountDisplayName: z.string(),
  accountGuid: guidSchema,
  dueAt: isoDateStringSchema,
})

export const InternalNotificationEstimateMetadataSchema = z.object({
  type: z.literal('ESTIMATE'),
  estimateGuid: guidSchema,
  operation: z.enum(['REVIEWED', 'ACCEPTED'] as const),
  contactFullName: z.string(),
})

export const InternalNotificationMetadataSchema = z.discriminatedUnion('type', [
  InternalNotificationTaggedInNoteMetadataSchema,
  InternalNotificationAccountReminderDueMetadataSchema,
  InternalNotificationEstimateMetadataSchema,
])

export type InternalNotificationMetadata = z.infer<typeof InternalNotificationMetadataSchema>
export type InternalNotificationAccountReminderDueMetadata = z.infer<
  typeof InternalNotificationAccountReminderDueMetadataSchema
>
export type InternalNotificationEstimateMetadata = z.infer<typeof InternalNotificationEstimateMetadataSchema>
export type InternalNotificationTaggedInNoteMetadata = z.infer<typeof InternalNotificationTaggedInNoteMetadataSchema>
