import {
  formatName,
  isInternalBreezyAdminUser,
  isNullish,
} from '@breezy/shared'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getConfig } from '../config'
import { Composable } from '../utils/Composable'
import { usePrincipalUser } from './PrincipalUser'

// https://developers.intercom.com/installing-intercom/web/methods/
declare global {
  function Intercom(cmd: 'show'): void
  function Intercom(cmd: 'hide'): void
  function Intercom(cmd: 'boot', options: Record<string, unknown>): void
  function Intercom(cmd: 'update', options: Record<string, unknown>): void
  function Intercom(cmd: 'shutdown'): void
  function Intercom(cmd: 'onShow', callback: () => void): void
  function Intercom(cmd: 'onHide', callback: () => void): void
}

const intercomEnabled = getConfig().intercomEnabled
const env = getConfig().env

const isDemo = env === 'demo'

export const AnalyticsWrapper: React.FC<Composable> = ({ children }) => {
  return (
    <>
      <IntercomAnalytics />
      <DatadogAnalytics />
      {children}
    </>
  )
}

const IntercomAnalytics: React.FC<Composable> = ({ children }) => {
  const { principal, impersonatedUser, loggingOutStatus } = usePrincipalUser()

  useEffect(() => {
    if (!intercomEnabled) return
    if (!principal) return

    // If the logged in user is a breezy admin or if the user is being impersonated then don't initiate intercom sdk.
    // This is to avoid an impersonating admin receiving the messages that are intended for the user that is being
    // impersonated. However, don't skip if it's demo mode, because in demo we impersonate and want to show Intercom and
    // don't have this issue where we'd get the unread messages for a real person.
    if (!isDemo && (isInternalBreezyAdminUser(principal) || impersonatedUser))
      return

    if (!window.Intercom) return // Ad-Blocker or something

    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'fa1q9sj3',
      user_id: principal.userGuid,
      name: formatName(principal),
      email: principal.emailAddress,
      company: principal.company
        ? {
            id: principal.company.companyGuid,
            name: principal.company.name,
          }
        : undefined,
      roles: principal.roles.map(r => r.name).join(', '),
      phone: principal.phoneNumbers.map(pn => pn.phoneNumber)[0],
      environment: env,
    })

    ///
    /// The code below adds an invisible background with a click-handler that imperatively closes the intercom help
    /// widget. This was an acute problem because we had no way of manually closing the help widget on screen sizes
    /// above 450px. This is far from ideal, but workable
    ///
    /// Dustin told me to do this ⬇️
    ///

    let intervalTimer: NodeJS.Timeout | null

    const clearTimer = () => {
      if (intervalTimer) {
        clearInterval(intervalTimer)
        intervalTimer = null
      }
    }

    window.Intercom('onShow', () => {
      clearTimer()

      // There is no guarantee that the intercom-container will be on the page at this point. It appears the content is
      // dynamically generated and injected asynchronously. So, we retry in a loop until it is present
      intervalTimer = setInterval(() => {
        const container = document.getElementById('intercom-container')
        if (
          container &&
          isNullish(document.getElementById('intercom-close-button-hack'))
        ) {
          clearTimer()

          const invisibleBackgroundContainer = document.createElement('div')

          invisibleBackgroundContainer.setAttribute(
            'style',
            'position: absolute; top: 0; left: 0; opacity: 0; width: 100vw; height: 100vh; z-index: 1000000',
          )
          invisibleBackgroundContainer.setAttribute(
            'id',
            'intercom-close-button-hack',
          )
          invisibleBackgroundContainer.setAttribute(
            'onclick',
            'window.Intercom("hide")',
          )

          container.appendChild(invisibleBackgroundContainer)
        }
      }, 100)
    })

    window.Intercom('onHide', () => {
      clearTimer()

      const element = document.getElementById('intercom-close-button-hack')
      if (element && element.parentNode) {
        element.parentNode.removeChild(element)
      }
    })
  }, [impersonatedUser, principal])

  useEffect(() => {
    if (!intercomEnabled) return
    if (loggingOutStatus === 'initiated') {
      window.Intercom('shutdown')
      return
    }
  }, [loggingOutStatus])

  // Notify Intercom any time the pathname or query parameters update
  const location = useLocation()
  useEffect(() => {
    if (!intercomEnabled) return
    Intercom('update', {
      user_id: principal?.userGuid,
    })
  }, [location.pathname, location.search, principal?.userGuid])

  return null
}
// Hook for initializing Datadog RUM
export const initializeDatadogRum = () => {
  const {
    rumApplicationId,
    rumClientToken,
    rumEnabled,
    rumService,
    env,
    apiUrl,
    querierApiUrl,
    querierWsApiUrl,
    platformVersion,
  } = getConfig()

  if (!rumEnabled || !isNullish(datadogRum.getInitConfiguration())) return

  datadogLogs.init({
    clientToken: rumClientToken,
    site: 'datadoghq.com',
    service: rumService,
    env,
    version: platformVersion,
    // NOTE: this was too noisy. Mostly from network issues and google maps weirdness. If we want to tackle those in the
    // future (maybe try/catch the gmaps stuff somehow and/or figure out how to filter network issues from this) we can.
    // forwardErrorsToLogs: true,
    // NOTE: This is because all errors (above) was too noisy. If THIS is too noisy, we can make it opt-in-only by
    // removing it.
    forwardConsoleLogs: ['error'],
    // NOTE: if we turn both of those off, we can manually trigger logs with this:
    // https://docs.datadoghq.com/logs/log_collection/javascript/#custom-logs
  })

  datadogRum.init({
    applicationId: rumApplicationId,
    clientToken: rumClientToken,
    service: rumService,
    site: 'datadoghq.com',
    allowedTracingUrls: [
      {
        match: (url: string) =>
          url.startsWith(apiUrl) ||
          url.startsWith(querierApiUrl) ||
          url.startsWith(querierWsApiUrl),
        propagatorTypes: ['tracecontext', 'datadog'],
      },
    ],
    env: env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    silentMultipleInit: true,
    telemetrySampleRate: 100,
    version: platformVersion,
    enableExperimentalFeatures: ['feature_flags'],
  })

  datadogRum.startSessionReplayRecording()
}

// Hook for setting the Datadog RUM user
function useSetDatadogRumUser() {
  const { principal, impersonatedUser } = usePrincipalUser()

  useEffect(() => {
    if (isNullish(datadogRum.getInitConfiguration()) || !principal) {
      datadogRum.clearUser()
      return
    }

    const userInfo = {
      id: principal.userGuid,
      email: principal.emailAddress,
      name: formatName(principal),
      companyName: principal.company?.name,
      companyGuid: principal.company?.companyGuid,
      roles: principal.roles.map(r => r.name).join(', '),
      impersonating: !isNullish(impersonatedUser?.userGuid),
    }

    datadogRum.setUser(userInfo)
    datadogLogs.setUser(userInfo)
  }, [principal, impersonatedUser])
}

// Hook for handling Datadog RUM on logout
function useHandleDatadogRumLogout() {
  const { loggingOutStatus } = usePrincipalUser()

  useEffect(() => {
    if (isNullish(datadogRum.getInitConfiguration())) return

    if (loggingOutStatus === 'initiated') {
      datadogRum.clearUser()
    }
  }, [loggingOutStatus])
}

const DatadogAnalytics = React.memo(() => {
  useSetDatadogRumUser()
  useHandleDatadogRumLogout()

  return null
})
