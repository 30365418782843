import { isNullish } from '@breezy/shared'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useIsMobile, { useIsSmallScreen } from '../../../../hooks/useIsMobile'
import {
  OnResize,
  useResizeObserver,
} from '../../../../hooks/useResizeObserver'
import { PresentCarouselLandscape } from './PresentCarouselLandscape'
import { PresentCarouselPortrait } from './PresentCarouselPortrait'
import { PresentCarouselProps } from './utils'

const CONSTANTS = {
  IMAGE: {
    // Max width of the image. Duh
    MAX_WIDTH: 600,
    // Distance from the top to offset the image
    BASE_TOP_OFFSET: 24,
    // When you scroll, the image gets smaller and becomes transparent
    SCROLL_SHRINK: {
      // The opacity when you're fully scrolled down
      START_OPACITY: 0.9,
      // The final opacity
      END_OPACITY: 0.1,
      // The scale of the size that you end up with when you fully scroll
      SCALE_FACTOR: 0.8,
    },
    // When you switch between cards, the image for the first card shrinks down and the image for the new one pops up.
    GENIE_EFFECT: {
      // The scale of the size that the image starts at when it comes out of the genie lamp (and goes back down to when
      // it goes back in).
      SCALE_FACTOR: 0.8,
      // When the image goes into the genie bottle, it goes "down". The amount it goes down is proportional to the size
      // of the image. This is that proportion.
      TRANSLATION_FACTOR: 0.5,
    },
  },
  CARD: {
    // Max card width
    MAX_WIDTH: 754,
    // Margin between the bottom of the card and the bottom of the container
    BOTTOM_MARGIN: 16,
    // Minimum distance between the top of the card and the top of the container when the container isn't scrolled. This
    // may be bigger if the card is small.
    MIN_TOP_MARGIN: {
      MOBILE: 240,
      NON_MOBILE: 370,
    },
    // Space between the card and the sides of the container. If the space available is greater than the max width of
    // the card, that distance will be used instead of these.
    MIN_SIDE_MARGIN: {
      MOBILE: 16,
      NON_MOBILE: 40,
    },
  },
} as const

export const PresentCarousel = React.memo<PresentCarouselProps>(props => {
  const isMobile = useIsMobile()
  const isSmallScreen = useIsSmallScreen()
  const markerRef = useRef<HTMLSpanElement>(null)
  const [topOffset, setTopOffset] = useState<number>()

  useLayoutEffect(() => {
    if (!markerRef.current) {
      return
    }
    const markerOffset = markerRef.current.getBoundingClientRect().top

    setTopOffset(markerOffset)
  }, [])

  const offsetHasLoaded = !isNullish(topOffset)

  const containerRef = useRef<HTMLDivElement>(null)

  const [containerWidth, setContainerWidth] = useState(0)

  const onResize = useCallback<OnResize>(({ width }) => {
    setContainerWidth(width)
  }, [])

  useResizeObserver(containerRef, onResize)

  const itemWidth = useMemo(
    () =>
      Math.min(
        containerWidth -
          CONSTANTS.CARD.MIN_SIDE_MARGIN[isMobile ? 'MOBILE' : 'NON_MOBILE'] *
            2,
        CONSTANTS.CARD.MAX_WIDTH,
      ),
    [containerWidth, isMobile],
  )

  const sidePadding = useMemo(
    () => (containerWidth - itemWidth) / 2,
    [containerWidth, itemWidth],
  )

  const topPosition = props.header ? 0 : topOffset

  return (
    <>
      <span ref={markerRef} />
      <div
        ref={containerRef}
        className="present-carousel fixed inset-x-0 bottom-0 z-10 bg-white"
        style={{ top: `${topPosition}px` }}
      >
        {offsetHasLoaded &&
          itemWidth > 0 &&
          (isSmallScreen ? (
            <PresentCarouselPortrait
              {...props}
              containerWidth={containerWidth}
              sidePadding={sidePadding}
              itemWidth={itemWidth}
            />
          ) : (
            <PresentCarouselLandscape
              {...props}
              containerWidth={containerWidth}
            />
          ))}
      </div>
    </>
  )
})
