import { DocumentType, gql } from '../../../generated'

export const ESTIMATES_FRAGMENT = gql(/* GraphQL */ `
  fragment EstimateCollapsibleEstimate on Estimates {
    job {
      jobGuid
      displayId
      jobType {
        name
      }
    }
    estimateGuid
    displayId
    status
    createdByUser {
      fullName
    }
    createdAt
    estimateOptions(orderBy: { seq: ASC }) {
      estimateOptionGuid
      displayName
      seq
      totalUsc
      isSelected
    }
  }
`)

export type EstimateCollapsibleEstimate = DocumentType<
  typeof ESTIMATES_FRAGMENT
> & { isLinkedEstimate?: boolean }

export const ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription EstimatesForCollapsible($where: EstimatesBoolExp!) {
    estimates(where: $where, orderBy: { createdAt: DESC }) {
      ...EstimateCollapsibleEstimate
    }
  }
`)

export const LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription EstimatesCollapsibleLinkedEstimatesSubscription(
    $jobGuid: uuid!
  ) {
    jobsByPk(jobGuid: $jobGuid) {
      linkedJobEstimates {
        jobGuid
        estimate {
          ...EstimateCollapsibleEstimate
        }
      }
    }
  }
`)
