import {
  DiscountType,
  JobGuid,
  MaintenancePlanStatus,
  SimplifiedDiscount,
  nextGuid,
} from '@breezy/shared'
import { useMemo } from 'react'
import { DiscountPickerDiscount } from '../components/Pricebook/DiscountMultiPicker'
import { useFetchComprehensiveJobDetailsByJobGuidQuery } from './fetch/useFetchComprehensiveJobDetailsByJobGuid'

type MaintenancePlanJobDiscountProps = {
  readonly jobGuid?: JobGuid
}

export const useMaintenancePlanJobDiscount = ({
  jobGuid,
}: MaintenancePlanJobDiscountProps): SimplifiedDiscount | undefined => {
  const jobDetailsQuery = useFetchComprehensiveJobDetailsByJobGuidQuery({
    jobGuid,
  })

  if (!jobDetailsQuery.data) return undefined

  const jobClass = jobDetailsQuery.data.getJobType().jobClass
  const maintenancePlan = jobDetailsQuery.data.getMaintenancePlan()
  if (!maintenancePlan) return undefined
  if (maintenancePlan.status !== MaintenancePlanStatus.ACTIVE) return undefined

  const mpDiscounts = maintenancePlan.discounts ?? []
  const matchingDiscount = mpDiscounts.find(
    discount => discount.discountJobClass === jobClass,
  )
  if (!matchingDiscount) return undefined

  return {
    type: DiscountType.RATE,
    value: matchingDiscount.discountRate,
  }
}

export const useMaintenancePlanJobOnsiteDiscount = ({
  jobGuid,
}: MaintenancePlanJobDiscountProps): DiscountPickerDiscount[] => {
  const mpSimplifiedDiscount = useMaintenancePlanJobDiscount({ jobGuid })
  const mpOnsiteDiscounts = useMemo(() => {
    if (!mpSimplifiedDiscount) return []

    const onsiteDiscount: DiscountPickerDiscount = {
      discountGuid: nextGuid(),
      name: 'Maintenance Plan Discount',
      descriptionHtml: 'Maintenance Plan Discount',
      type: DiscountType.RATE,
      discountRate: mpSimplifiedDiscount.value,
    }
    return [onsiteDiscount]
  }, [mpSimplifiedDiscount])

  return mpOnsiteDiscounts
}
