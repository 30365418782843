import {
  MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION,
  MaintenancePlanPaymentViewModel,
  MaintenancePlanStatus,
  RenewalTypeOption,
} from '@breezy/shared'
import { Button, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { memo, useCallback, useState } from 'react'
import PaymentCardPanel from '../../components/Payments/PaymentCardPanel'
import { PaymentAsyncUiStatus } from '../../components/Payments/PaymentTypes'
import LimitedWidthColumn from '../../elements/LimitedWidthColumn/LimitedWidthColumn'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'

type FreeMaintenancePlanActivationViewProps = {
  readonly maintenancePlan: MaintenancePlanPaymentViewModel
  readonly onSuccessfulActivation: () => void
  readonly renewalTypeOption: RenewalTypeOption
  readonly setPaymentAsyncUiStatus: (status: PaymentAsyncUiStatus) => void
}

type FreePlanActivationContext = {
  autoRenewPlan: boolean
}

export const FreeMaintenancePlanActivationView =
  memo<FreeMaintenancePlanActivationViewProps>(
    ({
      maintenancePlan,
      onSuccessfulActivation,
      renewalTypeOption,
      setPaymentAsyncUiStatus,
    }) => {
      const message = useMessage()

      const [activationContext, setActivationContext] =
        useState<FreePlanActivationContext>({
          autoRenewPlan: renewalTypeOption === 'MANUAL' ? false : true,
        })

      const onChangeAutoRenewPlan = useCallback((e: CheckboxChangeEvent) => {
        setActivationContext(prevState => ({
          ...prevState,
          autoRenewPlan: e.target.checked,
        }))
      }, [])

      const activateFreeMaintenancePlan =
        trpc.maintenancePlans[
          'maintenance-plans:activate-free-plan'
        ].useMutation()

      const onClickActivatePlan = useCallback(() => {
        // We're not actually paying anything here, but we set the Payment status
        // for UI purposes
        setPaymentAsyncUiStatus(PaymentAsyncUiStatus.SUBMITTING)

        activateFreeMaintenancePlan.mutateAsync(
          {
            maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
            numDaysUntilAutoCancelation: activationContext.autoRenewPlan
              ? undefined
              : MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION,
          },
          {
            onSuccess: () => {
              message.success('Activated Free Maintenance Plan!')
              setPaymentAsyncUiStatus(PaymentAsyncUiStatus.IDLE)
              onSuccessfulActivation()
            },
            onError: () => {
              message.error('Failed to activate Free Maintenance Plan')
              setPaymentAsyncUiStatus(PaymentAsyncUiStatus.IDLE)
            },
          },
        )
      }, [
        activateFreeMaintenancePlan,
        activationContext.autoRenewPlan,
        maintenancePlan.maintenancePlanGuid,
        message,
        onSuccessfulActivation,
        setPaymentAsyncUiStatus,
      ])

      return (
        <div className="row center-h center-children-h flex-wrap">
          <LimitedWidthColumn>
            <PaymentCardPanel>
              <div className="column center-h center-children-h">
                <div className="column center-h mt-[-4px] w-[240px] space-y-2">
                  <h1 className="m0 grey9 text-[24px]">
                    Free Maintenance Plan
                  </h1>

                  {maintenancePlan.status === MaintenancePlanStatus.ACTIVE ? (
                    <p className={'regular_14_22 gray7 w-fit'}>
                      Plan is already active!
                    </p>
                  ) : (
                    <>
                      {renewalTypeOption === 'BOTH' && (
                        <>
                          <Checkbox
                            checked={activationContext.autoRenewPlan}
                            onChange={onChangeAutoRenewPlan}
                          >
                            Auto-renew Plan
                          </Checkbox>

                          <p className={'regular_14_22 gray7 w-fit'}>
                            {activationContext.autoRenewPlan
                              ? 'Plan will automatically renew until cancelled'
                              : 'Plan will automatically cancel in one year. It must be resold after cancellation.'}
                          </p>
                        </>
                      )}

                      <Button type="primary" onClick={onClickActivatePlan}>
                        Activate Free Plan
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </PaymentCardPanel>
          </LimitedWidthColumn>
        </div>
      )
    },
  )
