import {
  BzDateFns,
  InstalledEquipment,
  LocationMaintenancePlanViewModel,
  VisitViewModel,
  getNumUnusedMaintenancePlanVisits,
  isVisitUnused,
} from '@breezy/shared'
import { faCheck, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from 'antd'
import React, { useMemo } from 'react'
import BzSelect from '../../elements/BzSelect/BzSelect'
import { Callout } from '../../elements/Callout/Callout'
import ThinDivider from '../../elements/ThinDivider'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { BaseLoadingSpinner } from '../LoadingSpinner'
type JobMaintenancePlanVisitToggleProps = {
  maintenancePlans: LocationMaintenancePlanViewModel[]
  activeMaintenancePlanVisitGuid?: string
  onVisitSelected: (maintenancePlanVisitGuid: string | undefined) => void
  canEditVisits?: boolean
  className?: string
  loading?: boolean
  dataTestId?: string
  mode?: 'form' | 'live-update'
}

export const JobMaintenancePlanVisitToggle =
  React.memo<JobMaintenancePlanVisitToggleProps>(
    ({
      maintenancePlans,
      activeMaintenancePlanVisitGuid,
      onVisitSelected,
      className,
      loading = false,
      dataTestId,
      mode = 'live-update',
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const numMaintenancePlans = useMemo(
        () => maintenancePlans.length,
        [maintenancePlans],
      )

      if (numMaintenancePlans === 0) return null

      if (numMaintenancePlans === 1) {
        const maintenancePlan = maintenancePlans[0]
        const visits = maintenancePlan.visits
        const hasNotAssignedVisit =
          getNumUnusedMaintenancePlanVisits(visits) === visits.length
        return (
          <Callout
            title={
              <div className="flex flex-row items-center">
                Maintenance Plan Member
                {loading && <BaseLoadingSpinner className="ml-2" size={4} />}
              </div>
            }
            dataTestId={dataTestId}
            description={
              <div>
                This location has been a{' '}
                <strong>
                  {maintenancePlan?.maintenancePlanDefinition?.marketingInfo
                    ?.name ?? 'Maintenance Plan'}
                </strong>{' '}
                member since{' '}
                <strong>
                  {BzDateFns.formatFromISO(
                    maintenancePlan.activatedAt ?? BzDateFns.nowISOString(),
                    'MMMM d, yyyy',
                    tzId,
                  )}
                </strong>
                .
                {hasNotAssignedVisit
                  ? ' This location does not have any completed maintenance jobs on record.'
                  : ''}
              </div>
            }
            className={className}
          >
            <MaintenancePlanVisitSwitch
              key={maintenancePlan.maintenancePlanGuid}
              maintenancePlan={{
                maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
                name: 'Use Plan Credit',
              }}
              visits={maintenancePlan.visits}
              activeMaintenancePlanVisitGuid={activeMaintenancePlanVisitGuid}
              onVisitSelected={onVisitSelected}
              loading={loading}
              mode={mode}
              dataTestId={dataTestId}
              index={0}
            />
          </Callout>
        )
      }

      return (
        <Callout
          title="Maintenance Plans"
          description={`This location has multiple active maintenance plans. Please select which visit you'd like to use for this job.`}
          className={className}
        >
          {maintenancePlans.map((maintenancePlan, i) => (
            <>
              <MaintenancePlanVisitSwitch
                key={maintenancePlan.maintenancePlanGuid}
                maintenancePlan={{
                  maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
                  name: maintenancePlan?.maintenancePlanDefinition
                    ?.marketingInfo?.name,
                }}
                visits={maintenancePlan.visits}
                dataTestId={dataTestId}
                activeMaintenancePlanVisitGuid={activeMaintenancePlanVisitGuid}
                onVisitSelected={onVisitSelected}
                index={i}
                mode={mode}
              />
              {i < maintenancePlans.length - 1 && (
                <ThinDivider
                  dividerStyle="dotted"
                  styleOverrides={{
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                />
              )}
            </>
          ))}
        </Callout>
      )
    },
  )

type MaintenancePlanVisitSwitchProps = {
  maintenancePlan: {
    maintenancePlanGuid: string
    name?: string
  }
  activeMaintenancePlanVisitGuid?: string
  visits: VisitViewModel[]
  onVisitSelected: (maintenancePlanVisitGuid: string | undefined) => void
  dataTestId?: string
  index: number
  loading?: boolean
  mode?: 'form' | 'live-update'
}
const MaintenancePlanVisitSwitch = React.memo<MaintenancePlanVisitSwitchProps>(
  ({
    maintenancePlan,
    activeMaintenancePlanVisitGuid,
    visits,
    onVisitSelected,
    loading = false,
    mode = 'live-update',
    dataTestId,
    index,
  }) => {
    const isSelected = useMemo(
      () =>
        !!activeMaintenancePlanVisitGuid &&
        visits.map(v => v.visitGuid).includes(activeMaintenancePlanVisitGuid),
      [activeMaintenancePlanVisitGuid, visits],
    )

    const visitOptions = useMemo(() => {
      return visits
        .map((v, i) => ({
          ...v,
          index: i,
          isUnused: isVisitUnused(v),
        }))
        .map(v => {
          // Let the active maintenance plan visit be "selected" by default
          const isSelected = v.visitGuid === activeMaintenancePlanVisitGuid
          const concatenatedCoveredEquipmentType = v.visitEquipment
            .map(ve => InstalledEquipment.calculateFriendlyName(ve))
            .join(', ')
          const label = `Visit #${v.index + 1} (${v.name}) ${
            concatenatedCoveredEquipmentType
              ? `— ${concatenatedCoveredEquipmentType}`
              : ''
          }`
          return {
            label,
            disabled: !v.isUnused && !isSelected,
            value: v.visitGuid,
          }
        })
    }, [activeMaintenancePlanVisitGuid, visits])

    const defaultVisit = useMemo(
      () => visits.find(v => isVisitUnused(v)) ?? visits[0],
      [visits],
    )

    const hasAssignableVisits = useMemo(
      () => getNumUnusedMaintenancePlanVisits(visits) > 0 || isSelected,
      [visits, isSelected],
    )

    const numVisits = useMemo(() => {
      if (loading) return getNumUnusedMaintenancePlanVisits(visits)
      // If the current maintenance plan is selected and the visit is "assignable", show the optimistic number of visits before the visit
      // is actually assigned to the job during a write.
      // Note: we don't actually want this if the parent component is doing live updates like on the job details page
      // for example because the number of visits will actually be 1 less than the optimistic number of visits if the control
      // swaps which visit is selected.
      if (mode === 'form' && isSelected) {
        const visit = visits.find(
          v => v.visitGuid === activeMaintenancePlanVisitGuid,
        )
        if (visit && isVisitUnused(visit)) {
          return Math.max(0, getNumUnusedMaintenancePlanVisits(visits) - 1)
        }
        return getNumUnusedMaintenancePlanVisits(visits)
      }
      return getNumUnusedMaintenancePlanVisits(visits)
    }, [loading, visits, isSelected, activeMaintenancePlanVisitGuid, mode])

    return (
      <div className="flex flex-col">
        <div className="mb-2 flex flex-row items-center justify-between gap-1">
          <div className="flex flex-col gap-[2px]">
            <div className="flex flex-row items-center text-xs font-semibold leading-5">
              {maintenancePlan.name ?? 'Maintenance Plan'}
              {` (${numVisits} of ${visits.length} remaining)`}
            </div>
            <div className="text-xs italic leading-5">
              {hasAssignableVisits
                ? `A maintenance plan credit will be used when this job is completed.`
                : `No more visits are available for this maintenance plan.`}
            </div>
          </div>
          <Switch
            checked={isSelected}
            disabled={!hasAssignableVisits}
            data-testid={`${dataTestId}-${index}-switch`}
            onChange={checked => {
              if (checked) {
                onVisitSelected(defaultVisit.visitGuid)
              } else {
                onVisitSelected(undefined)
              }
            }}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faX} />}
          />
        </div>
        {isSelected && (
          <BzSelect
            title="Select Visit"
            name={`${dataTestId}-${index}-select-visit`}
            options={visitOptions}
            value={activeMaintenancePlanVisitGuid}
            onChange={onVisitSelected}
          />
        )}
      </div>
    )
  },
)
