import { BzDateFns, InMemorySingleEntityEventStore, nextGuid } from '../../common'
import { TaxRateSetEventData } from '../Finance/Transactions/TransactionTypes'
import { MaintenancePlan } from './MaintenancePlan'

export const maintenancePlanTestableSampleData = {
  // eslint-disable-next-line breezy/no-to-iso-date-string
  frozenMoment: BzDateFns.toIsoDateString('2023-03-27T07:00:00Z'),
  maintenancePlanGuid: nextGuid(),
  companyGuid: nextGuid(),
  userGuid: nextGuid(),
  accountGuid: nextGuid(),
  locationGuid: nextGuid(),
  maintenancePlanDefinitionGuid: nextGuid(),
}

const { frozenMoment, maintenancePlanGuid, companyGuid, userGuid, accountGuid, locationGuid } =
  maintenancePlanTestableSampleData

const instantiateInMemoryMaintenancePlan = (
  maybeEventStore: InMemorySingleEntityEventStore | undefined = undefined,
) => {
  const time = frozenMoment
  const eventStore = maybeEventStore ?? new InMemorySingleEntityEventStore()
  const mp = new MaintenancePlan(eventStore, () => time)
  return {
    eventStore,
    mp,
  }
}

const instantiateAndInitInMemoryMaintenancePlan = async (taxRate?: TaxRateSetEventData) => {
  const { mp, eventStore } = instantiateInMemoryMaintenancePlan()
  return await mp
    .init({ companyGuid, userGuid, accountGuid, locationGuid, maintenancePlanGuid, taxRate })
    .then(_ => ({ mp, eventStore }))
}

export const createInMemoryMaintenancePlan = instantiateAndInitInMemoryMaintenancePlan
