import { LoanStatus, LoanStatusAbbrDisplayNames } from '@breezy/shared'
import React from 'react'
import StatusTag, {
  StatusTagColor,
} from '../../../elements/StatusTag/StatusTag'

export type LoanStatusTagProps = {
  status: LoanStatus
}

const toStatusDetails = (
  status: LoanStatus,
): { text: string; color: StatusTagColor } => {
  switch (status) {
    case 'PENDING':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'gray',
      }
    case 'INITIATED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'gray',
      }
    case 'ACTIONS_REQUIRED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'orange',
      }
    case 'AUTHORIZED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'blue',
      }
    case 'LOAN_TERMS_ACCEPTED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'blue',
      }
    case 'LOAN_CONFIRMED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'green',
      }
    case 'SETTLED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'purple',
      }
    case 'DECLINED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'red',
      }
    case 'EXPIRED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'gray',
      }
    case 'CANCELED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'gray',
      }
    case 'REFUNDED':
      return {
        text: LoanStatusAbbrDisplayNames[status],
        color: 'gray',
      }
    default:
      return { text: 'Unknown', color: 'gray' }
  }
}

const LoanStatusTag = React.memo<LoanStatusTagProps>(({ status }) => {
  return (
    <StatusTag
      color={toStatusDetails(status).color}
      text={toStatusDetails(status).text}
      border="strong"
    />
  )
})

export default LoanStatusTag
