import { isNullish } from '@breezy/shared'
import { UseSubscriptionState } from 'urql'
import { typedMemo } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { LoaderGenericProps } from '../TrpcQueryLoader'

type UrqlSubscriptionLoaderProps<T> = LoaderGenericProps<T> & {
  subscription: UseSubscriptionState<T>
}

const UrqlSubscriptionLoader = typedMemo(
  <T,>({
    subscription,
    errorComponent,
    errorMessage,
    notFoundComponent,
    loadingComponent,
    render,
  }: UrqlSubscriptionLoaderProps<T>) => {
    const { data, error, fetching } = subscription

    if (fetching) {
      return loadingComponent || <LoadingSpinner />
    }

    if (error) {
      console.error(error)
      if (error.message === 'Not Found' && notFoundComponent) {
        return notFoundComponent
      }
      if (errorComponent) {
        return errorComponent
      }
      return <div>{errorMessage || `Failed to load data`}</div>
    }

    if (isNullish(data)) {
      return <div>{errorMessage || `No data available`}</div>
    }

    return render(data)
  },
)

export default UrqlSubscriptionLoader
