import {
  BackButtonOverrideResolver,
  HistoryStackLocation,
  getUrlFromHistoryStackLocation,
} from './BackButtonOverridesWrapper'

const isCreatePage = (location: HistoryStackLocation) =>
  getUrlFromHistoryStackLocation(location).pathname.includes('/invoice/new')

export const invoiceBackButtonOverrideResolver: BackButtonOverrideResolver = (
  previousPage,
  historyStack,
) => {
  const newPage = historyStack[historyStack.length - 1]

  // If we landed on the create page...
  if (isCreatePage(newPage)) {
    // If we went from the create page to some other page on the create page, then don't skip anything.
    if (isCreatePage(previousPage)) {
      return 0
    }
    // Otherwise, skip all create pages
    let count = 2
    while (isCreatePage(historyStack[historyStack.length - count])) {
      count++
    }
    // The count includes the page that we checked that WASN'T a create page. We need to do -1 because we DON'T want to
    // skip that one.
    return count - 1
  }

  return 0
}
