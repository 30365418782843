import { mapQueryVisitsToVisitViewModels } from '@breezy/backend/src/application-types/visits/VisitConversions'
import { DUMMY_GUID, LocationMaintenancePlanViewModel } from '@breezy/shared'
import { useQuery, UseQueryExecute } from 'urql'
import { gql } from '../generated'

const LOCATION_MAINTENANCE_PLANS_QUERY = gql(/* GraphQL */ `
  query LocationMaintenancePlans($accountGuid: uuid!, $locationGuid: uuid!) {
    maintenancePlansQueryable(
      where: {
        accountGuid: { _eq: $accountGuid }
        locationGuid: { _eq: $locationGuid }
        status: { _eq: "ACTIVE" }
      }
    ) {
      maintenancePlanGuid
      status
      terminatesAt
      lastVisitedAt
      activatedAt
      maintenancePlanDefinition {
        marketingInfo {
          name
        }
      }
      maintenancePlanVisits {
        ...MaintenancePlanVisit
      }
    }
  }
`)

export const useLocationActiveMaintenancePlans = ({
  accountGuid,
  locationGuid,
  pause = false,
}: {
  accountGuid: string
  locationGuid?: string
  pause?: boolean
}): {
  maintenancePlans: LocationMaintenancePlanViewModel[]
  fetching: boolean
  refetch: UseQueryExecute
} => {
  const [
    {
      data: activeMaintenancePlansData,
      fetching: fetchingActiveMaintenancePlansData,
    },
    refetch,
  ] = useQuery({
    query: LOCATION_MAINTENANCE_PLANS_QUERY,
    variables: {
      accountGuid: accountGuid,
      locationGuid: locationGuid ?? DUMMY_GUID,
    },
    pause,
  })

  return {
    maintenancePlans:
      activeMaintenancePlansData?.maintenancePlansQueryable
        .map(mp => ({
          ...mp,
          visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits),
          // Only consider maintenance plans with at least one visit
        }))
        .filter(mp => mp.visits.length) ?? [],
    fetching: fetchingActiveMaintenancePlansData,
    refetch,
  }
}
