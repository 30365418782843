import { AvatarData, Dfns, R, User } from '@breezy/shared'
import { Avatar, Popover } from 'antd'
import React from 'react'
import { getAppropriateTextColor, userColor } from '../../utils/color-utils'

type PersonAvatarProps = AvatarData & {
  size?: number
  fontSize?: number
}

export const mapUserToAvatarData = (user: User): AvatarData => {
  const avatarShortString =
    (user.firstName || 'F')[0] + (user.lastName || 'L')[0]
  return {
    avatarAltShortString: avatarShortString,
    avatarAltColor: userColor(user.userGuid),
    deactivatedAt: user.deactivatedAt,
    userGuid: user.userGuid,
  }
}

const PersonAvatar = React.memo<PersonAvatarProps>(
  ({
    avatarAltShortString,
    avatarImgSrc,
    avatarAltColor,
    size = 32,
    fontSize = 10,
    deactivatedAt,
    userGuid,
  }) => {
    const backgroundColor = avatarAltColor || userColor(userGuid)
    const content = avatarImgSrc ? (
      <Avatar
        src={
          <img
            id="avatar"
            className="avatar"
            src={avatarImgSrc}
            style={{ minWidth: size, width: size }}
            alt="Avatar"
          />
        }
      />
    ) : (
      <div
        style={{
          minWidth: size,
          width: size,
          height: size,
          minHeight: size,
          background: backgroundColor,
          borderRadius: '100%',
          color: getAppropriateTextColor(backgroundColor),
          fontSize: `${fontSize}px`,
          lineHeight: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {avatarAltShortString}
      </div>
    )
    if (deactivatedAt) {
      return (
        <Popover
          content={
            <div>
              User deactivated on{' '}
              {R.pipe(
                Dfns.parseISO,
                Dfns.format('MMMM d, yyyy'),
              )(deactivatedAt)}
            </div>
          }
        >
          <span className="opacity-30">{content}</span>
        </Popover>
      )
    }
    return content
  },
)
export default PersonAvatar
