import {
  DiscountType,
  HtmlString,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import { useCallback, useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { Option } from '../estimatesFlowUtils'

// Presets for the estimate message generator
export const ESTIMATE_MESSAGE_GENERATOR_PRESETS = [
  'Summarize Options',
  'Rewrite',
  'Greeting',
  'Up-sell',
] as const

export type EstimateMessageGeneratorPreset =
  (typeof ESTIMATE_MESSAGE_GENERATOR_PRESETS)[number]

type UseGenerateEstimateMessageProps = {
  options: Option[]
  pointOfContactName?: string
  setMessageHtml: (html: HtmlString) => void
  messageHtml: HtmlString
}

const generatePromptForPreset = (
  preset: EstimateMessageGeneratorPreset,
  formattedOptions: string,
  pointOfContactName: string | undefined,
  messageHtml: HtmlString,
): string => {
  const greeting = pointOfContactName ? `${pointOfContactName},` : ''
  const basePrompt = `
    Generate a customer message for an estimate with the following options:
    
    ${formattedOptions}
    
    ${
      greeting
        ? `Start the message with "Hi ${greeting}"`
        : 'Start the message with a general greeting'
    }

    Consolidate the greeting with the first paragraph. The greeting should be in-line.
    Address the customer politely and summarize the estimate options.
    Explain the differences and benefits between each option without trying to upsell.
    Provide a professional message that highlights the unique aspects of each option.
    Summarize the line items in-line within a paragraph for each option, rather than as separate bullets.
    Put the bolded option name and the total on the same line.
    Format all of the totals in the estimate with the preceding currency symbol ($).
    Format the message with proper paragraphs, each wrapped in <p> tags.
    Return the message as a JSON object with a single key "message" containing the formatted HTML paragraph. Always use HTML, NEVER use Markdown.
    For example: {"message": "<p>${
      greeting ? `Hi ${greeting} ` : ''
    }Your first paragraph here...</p><p>Your second paragraph here...</p>"}
  `

  switch (preset) {
    case 'Greeting':
      return `
        Generate a short, warm greeting for a customer regarding their estimate.
        ${
          greeting
            ? `Start with "Hi ${greeting}"`
            : 'Start with a general greeting'
        }
        
        Consolidate the greeting with the first paragraph. The greeting should be in-line with the first paragraph. e.g Hi Bob, thank you for considering our services. We've prepared an estimate for you to review.
        Create a friendly and welcoming tone without summarizing the estimate options.
        End with a brief introduction to the estimate, encouraging them to review it.
        Format the message with proper paragraphs, each wrapped in <p> tags.
        Return the message as a JSON object with a single key "message" containing the formatted HTML paragraph. Always use HTML, NEVER use Markdown.
        For example: {"message": "<p>${
          greeting ? `Hi ${greeting} ` : ''
        }Thank you for considering our services. We've prepared an estimate for you to review.</p>"}
      `
    case 'Up-sell':
      return `
        Generate a customer message for an estimate that focuses on upselling the recommended option:
        
        ${formattedOptions}
        
        ${
          greeting
            ? `Start the message with "Hi ${greeting}"`
            : 'Start with a general greeting'
        }

        Consolidate the greeting with the first paragraph. The greeting should be in-line with the first paragraph. e.g Hi Bob, thank you for...
        Address the customer politely and provide an overview of the estimate options.
        Emphasize the value proposition of the recommended option.
        Compare the recommended option to other options to highlight its additional value.
        If there's no recommended option, focus on the higher-priced options instead.
        Highlight unique features or benefits of the recommended or premium choices.
        Mention potential long-term savings or return on investment for the recommended or more expensive options.
        Use persuasive language without being pushy.
        Don't add any signature or closing after the final paragraph.
        Wrap any Option names (not the line items) in strong tags.
        Format all of the totals in the estimate with the preceding currency symbol ($).
        Format the message with proper paragraphs, each wrapped in <p> tags.
        Return the message as a JSON object with a single key "message" containing the formatted HTML paragraph. Always use HTML, NEVER use Markdown!!!!!!!
        For example: {"message": "<p>${
          greeting ? `Hi ${greeting} ` : ''
        }We're excited to present you with several options, each offering unique benefits...</p><p>Our recommended option stands out by providing [specific benefits]. This choice not only offers [key features], but also provides long-term value through [advantages]...</p>"}
      `
    case 'Rewrite':
      return !isNullish(messageHtml) && messageHtml?.toString().length > 0
        ? `
        Edit and clean up the following estimate message:

        ${messageHtml}
        
        Please fix any grammar mistakes, improve readability, and make it sound more professional.
        Maintain the overall structure and content of the message, but enhance its clarity and polish.
        Format the message with proper paragraphs, each wrapped in <p> tags.
        Return the message as a JSON object with a single key "message" containing the formatted HTML paragraph. Always use HTML, NEVER use Markdown.
        For example: {"message": "<p>Improved first paragraph...</p><p>Enhanced second paragraph...</p>"}
      `
        : basePrompt
    default:
      return basePrompt
  }
}

export const useGenerateEstimateMessage = ({
  options,
  pointOfContactName,
  setMessageHtml,
  messageHtml,
}: UseGenerateEstimateMessageProps) => {
  const [isGenerating, setIsGenerating] = useState(false)
  const toastMessage = useMessage()
  const generateAIMessage =
    trpc.devTools['devtools:generate-llm-completion'].useMutation()

  const generateEstimateMessage = useCallback(
    async (preset: EstimateMessageGeneratorPreset) => {
      setIsGenerating(true)
      datadogRum.addAction('bz-generate-estimate-message', {
        preset,
      })
      try {
        const formattedOptions = options
          .map((option, index) => {
            const lineItems = option.lineItems
              .map(item => `${item.name} x ${item.quantity}`)
              .join(', ')

            const discounts = option.discounts
              .map(discount =>
                discount.type === DiscountType.FLAT &&
                discount.discountAmountUsc
                  ? `$${(discount.discountAmountUsc / 100).toFixed(2)} off`
                  : discount.type === DiscountType.RATE && discount.discountRate
                  ? `${(discount.discountRate * 100).toFixed(2)}% off`
                  : '',
              )
              .join(', ')

            return `
          Option Name: ${option.displayName || `Option #${index + 1}`}
          ${option.recommended ? `Recommended: Yes` : ''}
          Total: **${usCentsToUsd(option.totalUsc)}**
          Line Items: ${lineItems}
          ${discounts ? `Discounts: ${discounts}` : ''}
        `
          })
          .join('\n\n')

        const prompt = generatePromptForPreset(
          preset,
          formattedOptions,
          pointOfContactName,
          messageHtml,
        )

        const result = await generateAIMessage.mutateAsync(prompt)

        if (result) {
          try {
            const parsedResult = JSON.parse(result)
            if (parsedResult.message) {
              setMessageHtml(parsedResult.message)
              toastMessage.success('Estimate message generated successfully')
            } else {
              console.error('Invalid response format:', result)
              toastMessage.error(
                'Failed to generate estimate message. Please try again.',
              )
            }
          } catch (parseError) {
            console.error('Error parsing AI response:', parseError)
            toastMessage.error(
              'Failed to parse generated estimate message. Please try again.',
            )
          }
        } else {
          toastMessage.error(
            'Failed to generate estimate message. Please try again.',
          )
        }
      } catch (error) {
        console.error('Error generating estimate message:', error)
        toastMessage.error(
          'Failed to generate estimate message. Please try again.',
        )
      } finally {
        setIsGenerating(false)
      }
    },
    [
      generateAIMessage,
      options,
      pointOfContactName,
      setMessageHtml,
      toastMessage,
      messageHtml,
    ],
  )

  return { generateEstimateMessage, isGenerating }
}
