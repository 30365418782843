import {
  BzDateFns,
  CalculatePaths,
  Guid,
  IsoDateString,
  isNullish,
} from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faFileInvoiceDollar,
  faHomeUser,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useStrictContext } from '../../../utils/react-utils'

type EstimateInfoModalContentLinkProps = {
  header: string
  subHeader: string
  icon: IconProp
  onClick: () => void
}

const EstimateInfoModalContentLink = ({
  header,
  subHeader,
  icon,
  onClick,
}: EstimateInfoModalContentLinkProps) => {
  return (
    <div
      key={header}
      onClick={onClick}
      className="flex cursor-pointer flex-row items-center transition-opacity hover:opacity-50"
    >
      <div className="flex h-11 w-11 items-center justify-center rounded-full bg-bz-gray-300">
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="ml-3 flex-1">
        <div className="text-base font-semibold">{header}</div>
        <div className="text-bz-gray-800">{subHeader}</div>
      </div>

      <Button
        type="text"
        icon={<FontAwesomeIcon className="text-base" icon={faChevronRight} />}
      />
    </div>
  )
}

export type EstimateInfoContextType = {
  displayId: number
  createdAt: IsoDateString
  jobGuid: string
  jobType: string
  jobDisplayId: number
  jobCreatedAt: IsoDateString
  accountGuid: string
  contactFullName: string
  accountCreatedAt: IsoDateString
  invoiceGuid?: Guid
  invoiceIssuedAt?: IsoDateString
  invoiceDisplayId?: number
  linkedJob?: {
    jobGuid: string
    jobType: string
    jobDisplayId: number
    jobCreatedAt: IsoDateString
  }
}

export const EstimateInfoContext = React.createContext<
  EstimateInfoContextType | undefined
>(undefined)

export const EstimateInfoModalContent = React.memo(() => {
  const linkedJobFFEnabled = useFeatureFlag('linkedJobs')
  const navigate = useNavigate()
  const tzId = useExpectedCompanyTimeZoneId()
  const {
    displayId,
    createdAt,
    jobGuid,
    jobType,
    jobDisplayId,
    jobCreatedAt,
    accountGuid,
    contactFullName,
    accountCreatedAt,
    linkedJob,
    invoiceGuid,
    invoiceIssuedAt,
    invoiceDisplayId,
  } = useStrictContext(EstimateInfoContext)

  const createdAtFormatted = useMemo(
    () => BzDateFns.formatFromISO(createdAt, 'MMMM d, yyyy', tzId),
    [createdAt, tzId],
  )
  const jobCreatedAtFormatted = useMemo(
    () => BzDateFns.formatFromISO(jobCreatedAt, 'MMMM d, yyyy', tzId),
    [jobCreatedAt, tzId],
  )
  const accountCreatedAtFormatted = useMemo(
    () => BzDateFns.formatFromISO(accountCreatedAt, 'MMMM d, yyyy', tzId),
    [accountCreatedAt, tzId],
  )

  const estimateLinkItems: (EstimateInfoModalContentLinkProps & {
    key: string
  })[] = useMemo(() => {
    const items: (EstimateInfoModalContentLinkProps & {
      key: string
    })[] = [
      {
        key: 'originalJob',
        header: `Job #${jobDisplayId} (${jobType}) (Original Job)`,
        subHeader: `Created on: ${jobCreatedAtFormatted}`,
        icon: faWrench,
        onClick: () => navigate(CalculatePaths.jobDetails({ jobGuid })),
      },
      {
        key: 'contact',
        header: contactFullName,
        subHeader: `Customer since: ${accountCreatedAtFormatted}`,
        icon: faHomeUser,
        onClick: () => navigate(CalculatePaths.accountDetails({ accountGuid })),
      },
    ]

    if (linkedJobFFEnabled && !isNullish(linkedJob)) {
      const formattedDate = BzDateFns.formatFromISO(
        linkedJob.jobCreatedAt,
        'MMMM d, yyyy',
        tzId,
      )

      items.unshift({
        key: 'linkedJob',
        header: `Job #${linkedJob.jobDisplayId} (${linkedJob.jobType}) (Linked Job)`,
        subHeader: `Created on: ${formattedDate}`,
        icon: faWrench,
        onClick: () =>
          navigate(CalculatePaths.jobDetails({ jobGuid: linkedJob.jobGuid })),
      })
    }

    if (invoiceGuid && invoiceIssuedAt) {
      const invoiceIssuedAtFormatted = BzDateFns.formatFromISO(
        invoiceIssuedAt,
        'MMMM d, yyyy',
        tzId,
      )

      items.unshift({
        key: 'invoice',
        header: `Invoice #${invoiceDisplayId}`,
        subHeader: `Issued: ${invoiceIssuedAtFormatted}`,
        icon: faFileInvoiceDollar,
        onClick: () =>
          navigate(CalculatePaths.invoiceOverview({ invoiceGuid })),
      })
    }

    return items
  }, [
    accountCreatedAtFormatted,
    accountGuid,
    contactFullName,
    invoiceDisplayId,
    invoiceGuid,
    invoiceIssuedAt,
    jobCreatedAtFormatted,
    jobDisplayId,
    jobGuid,
    jobType,
    linkedJob,
    linkedJobFFEnabled,
    navigate,
    tzId,
  ])

  return (
    <>
      <div className="grid grid-cols-[1fr_min-content_1fr] text-base">
        <div>
          <div className="mb-1 font-semibold">ID</div>

          <div>#{displayId}</div>
        </div>

        <Divider
          dashed
          type="vertical"
          className="mx-6 h-full border-bz-gray-500"
        />

        <div>
          <div className="mb-1 font-semibold">Issue Date</div>

          <div>{createdAtFormatted}</div>
        </div>
      </div>

      <Divider dashed className="my-4 border-bz-gray-500" />

      <div className="mb-3 text-base font-semibold">Related links</div>

      <div className="space-y-4">
        {estimateLinkItems.map(({ key, header, subHeader, icon, onClick }) => (
          <EstimateInfoModalContentLink
            key={key}
            header={header}
            subHeader={subHeader}
            icon={icon}
            onClick={onClick}
          />
        ))}
      </div>
    </>
  )
})
