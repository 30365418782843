import { HistoricalAppointmentDTO, richJobTypeDescriptor } from '@breezy/shared'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { useNavigate } from 'react-router-dom'
import { DateFormat } from '../../../../../utils/DateUtils'
import { DateTimeWindow, DateView } from '../../../../Dates'
import CollapsibleItem from '../../../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import AppointmentDetailCollapse from '../AppointmentDetailCollapse/AppointmentDetailCollapse'

export const HistoricalAppointmentsCard = ({
  historicalAppointments,
}: {
  historicalAppointments: HistoricalAppointmentDTO[]
}) => {
  return (
    <AppointmentDetailCollapse
      title={`Past Appointments`}
      titleIcon={faCalendar}
      allowExpandIfNoChildren={false}
      content={
        <div className="">
          {historicalAppointments.length === 0 && (
            <>There are no historical appointments at this location</>
          )}
          {historicalAppointments.length > 0 && (
            <div className="flex flex-col gap-4">
              {historicalAppointments.map((appt, apptIndex) => {
                return (
                  <div className="" key={appt.appointmentReferenceNumber}>
                    <HistoricalAppointment
                      appt={appt}
                      defaultOpen={apptIndex < 3}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      }
      showChildrenLength={false}
      defaultOpen={false}
    />
  )
}

const HistoricalAppointment = ({
  appt,
  defaultOpen,
}: {
  appt: HistoricalAppointmentDTO
  defaultOpen: boolean
}) => {
  const navigate = useNavigate()
  return (
    <CollapsibleItem
      key={appt.jobAppointmentGuid}
      title={appt.appointmentReferenceNumber}
      defaultOpen={defaultOpen}
      onTitleClick={() => navigate(`/appointments/${appt.jobAppointmentGuid}`)}
      contentList={[
        {
          key: 'Date:',
          value: (
            <DateView
              isoWithOffsetTimestamp={appt.appointmentWindowStart}
              format={DateFormat['MMM d, yyyy']}
              zone={{
                type: 'browser',
              }}
            />
          ),
        },
        {
          key: 'Arrival Window:',
          value: (
            <DateTimeWindow
              includeDate={false}
              overrideClassName="regular_14_22 grey9"
              startWindowIsoWithOffsetTimestamp={appt.appointmentWindowStart}
              endWindowIsoWithOffsetTimestamp={appt.appointmentWindowEnd}
            />
          ),
        },
        {
          key: 'Job Type:',
          value: <>{richJobTypeDescriptor(appt.jobType.name)}</>,
        },
        {
          key: 'Job Status:',
          value: <>{appt.jobLifecycleStatus.name}</>,
        },
      ]}
    />
  )
}
