import {
  AssignedApptViewModel,
  AssignmentWithTechnicianNameDTO,
  BzContact,
  formatEquipmentJobLink,
} from '@breezy/shared'
import { useMemo } from 'react'
import { DateTimeWindowFromWindowDto } from '../../../../../elements/DateTimeWindow/DateTimeWindow'
import { MaybeDataPoint } from '../../../../../elements/MaybeDataPoint/MaybeDataPoint'
import { AppointmentStatusTag } from '../../../../../pages/ScheduleV2Page/AppointmentStatusTag'
import { ContactLink } from '../../../../ContactLabel/ContactLabel'
import LocationInfoLink from '../../../../LocationInfoLink'
import { TagList } from '../../../../Tags'
import JobDetailsLink from '../../JobDetailsLink/JobDetailsLink'

export type AppointmentDetailCardProps = {
  appt: AssignedApptViewModel
  myAssignment: AssignmentWithTechnicianNameDTO | undefined
  tagsForJob?: { tagGuid: string; name: string; color: string }[]
}

const AboutAppointmentCard = ({
  appt,
  myAssignment,
  tagsForJob,
}: AppointmentDetailCardProps) => {
  const pointOfContact = useMemo(
    () => BzContact.create(appt.pointOfContact),
    [appt.pointOfContact],
  )
  return (
    <div className={`flex flex-col space-y-2 pb-4`}>
      <div className="row two-sides flex space-x-2">
        <JobDetailsLink
          {...appt}
          extra={
            myAssignment && (
              <span className="ml-2 text-black">
                ({appt.appointment.appointmentType})
              </span>
            )
          }
        />
        <div className="regular_14_22 grey9">
          <AppointmentStatusTag status={appt.appointment.appointmentStatus} />
        </div>
      </div>
      <div>
        <LocationInfoLink location={appt.location} />
      </div>
      {myAssignment && (
        <div>
          <div className="semibold_14_22 grey9">Technician Assigned Time</div>
          <DateTimeWindowFromWindowDto
            window={myAssignment.timeWindow}
            includeDate={true}
            overrideClassName="regular_14_22 grey9"
          />
        </div>
      )}
      <div>
        <div className="semibold_14_22 grey9">Customer Arrival Window</div>
        <DateTimeWindowFromWindowDto
          window={appt.appointment.timeWindow}
          includeDate={true}
          overrideClassName="regular_14_22 grey9"
        />
      </div>
      <div>
        <div className="semibold_14_22 gray9">Description</div>
        <div>
          <MaybeDataPoint>{appt.appointment.description}</MaybeDataPoint>
        </div>
      </div>
      <div>
        <div className="semibold_14_22 grey9">Point of Contact</div>
        <ContactLink
          className="regular_14_22"
          {...appt}
          contactFullName={appt.pointOfContact}
        />
        {pointOfContact.primaryPhoneNumber?.phoneNumber && (
          <div>{pointOfContact.primaryPhoneNumber.phoneNumber}</div>
        )}
      </div>

      {appt.equipmentTypeJobLinks.length > 0 && (
        <div>
          <div className="semibold_14_22 grey9">Equipment</div>
          <div className="regular_14_22 grey9">
            {appt.equipmentTypeJobLinks.map(et => (
              <div key={et.equipmentType}>{formatEquipmentJobLink(et)}</div>
            ))}
          </div>
        </div>
      )}
      {appt.summary && (
        <div>
          <div className="semibold_14_22 grey9">Job Summary</div>
          <div className="regular_14_22 grey9">{appt.summary}</div>
        </div>
      )}
      {tagsForJob && (
        <div className="flex flex-col space-y-1">
          <span className="text-sm font-semibold text-bz-gray-900">
            Job Tags
          </span>
          <TagList tags={tagsForJob} spacingY={2} />
        </div>
      )}
    </div>
  )
}

export default AboutAppointmentCard
