import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { bzOptional } from '../common-schemas'

export type Tag = {
  tagGuid: string
  companyGuid: string
  createdByUserGuid?: string | null
  name: string
  color: string
}

export const tagSchema = z.object({
  tagGuid: guidSchema,
  companyGuid: guidSchema,
  createdByUserGuid: bzOptional(guidSchema),
  name: z.string().min(1),
  color: z.string(),
}) satisfies z.ZodType<Tag>

export const tagsQuerierInputSchema = z.object({
  companyGuid: guidSchema,
  jobGuid: bzOptional(guidSchema),
})
export type TagsQuerierInput = z.infer<typeof tagsQuerierInputSchema>
export type TagsQuerier = AsyncFn<TagsQuerierInput, Tag[]>

export type TagsWriter = AsyncFn<Tag>

export const tagsDeleterInputSchema = z.object({ companyGuid: guidSchema, tagGuid: guidSchema })
export type TagsDeleterInput = z.infer<typeof tagsDeleterInputSchema>
export type TagsDeleter = AsyncFn<TagsDeleterInput>
