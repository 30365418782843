import {
  AssignedApptViewModel,
  BzDateFns,
  BzDateTime,
  bzExpect,
  Dfns,
  getDatesInRangeForRRule,
  groupBy,
  IsoDateString,
  parseRRule,
  TechnicianCapacityBlock,
  TimeZoneId,
} from '@breezy/shared'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserTimeClockStatusCard } from '../../../../components/UserTimeClockStatusCard/UserTimeClockStatusCard'
import { useFetchAppointmentBlocksAndAppointmentsLiveQuery } from '../../../../hooks/fetch/useFetchAppointmentBlocksAndAppointmentsLiveQuery'
import { useTechnicianScheduleAppointmentPermissions } from '../../../../hooks/permission/useTechnicianScheduleAppointmentPermissions'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../../../providers/PrincipalUser'
import BzCollapsible from '../../../Page/BzCollapsible/BzCollapsible'
import TechAppFullScreenLoadingSpinner from '../../TechAppFullScreenLoadingSpinner/TechAppFullScreenLoadingSpinner'
import { getAssignment } from '../AppointmentDetail/AppointmentDetailUtils'
import { MyAppointmentCard } from './AppointmentCard'
import './AppointmentsList.less'
import { BlockCard } from './BlockCard'

type AssignmentOrBlock =
  | {
      type: 'assigned'
      assigned: AssignedApptViewModel
      start: string
    }
  | {
      type: 'block'
      block: TechnicianCapacityBlock
      start: string
    }

type GroupedItems = {
  group: string
  items: AssignmentOrBlock[]
}

const Today = 'Today'
const Current = "Today's Schedule"
const Next30Days = 'Next 30 Days'
const Past30Days = 'Past 30 Days'

const AllTimeGroups = [Today, Next30Days, Past30Days, Current] as const
type TimeGroup = (typeof AllTimeGroups)[number]

const getGrouping = (
  start: IsoDateString,
  end: IsoDateString,
  tzId: TimeZoneId,
) => {
  const startDate = BzDateFns.parseISO(start, tzId)
  const endDate = BzDateFns.parseISO(end, tzId)

  if (BzDateFns.isBeforeToday(startDate, tzId)) {
    return Past30Days
  }
  if (BzDateFns.windowIsToday({ start: startDate, end: endDate }, tzId)) {
    return Today
  }
  return Next30Days
}

const AppointmentsList = () => {
  const principalUserGuid = useExpectedPrincipal().userGuid
  const navigate = useNavigate()
  const timezone = useExpectedCompanyTimeZoneId()
  const companyGuid = useExpectedCompanyGuid()

  const {
    canManageTheirAppointments,
    canManageTheirAppointmentsToday,
    canManageTheirCurrentAppointment,
    canViewHistoricalAppointments,
  } = useTechnicianScheduleAppointmentPermissions()

  const { data, loading } = useFetchAppointmentBlocksAndAppointmentsLiveQuery({
    jobAppointmentsWhere: {
      assignments: {
        technicianUserGuid: { _eq: principalUserGuid },
        assignmentStart: {
          _gte: BzDateTime.startOfToday(timezone).minusDays(30).toIsoString(),
        },
        assignmentEnd: {
          _lte: BzDateTime.startOfToday(timezone).plusDays(30).toIsoString(),
        },
      },
    },
    techCapacityBlocksWhere: {
      _and: [
        { companyGuid: { _eq: companyGuid } },
        {
          _or: [
            {
              _and: [
                {
                  start: {
                    _gte: BzDateTime.startOfToday(timezone)
                      .minusDays(30)
                      .toIsoString(),
                  },
                },
                {
                  end: {
                    _lte: BzDateTime.startOfToday(timezone)
                      .plusDays(30)
                      .toIsoString(),
                  },
                },
              ],
            },
            {
              _and: [
                { recurrenceRule: { _isNull: false } },
                {
                  start: {
                    _lte: BzDateTime.startOfToday(timezone)
                      .plusDays(30)
                      .toIsoString(),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  })

  const resolvedBlocks = useMemo(() => {
    const past30Days = Dfns.subDays(
      30,
      BzDateTime.startOfToday(timezone).toDate(),
    )
    const next30Days = Dfns.addDays(
      30,
      BzDateTime.startOfToday(timezone).toDate(),
    )
    const blocks: TechnicianCapacityBlock[] = []
    for (const block of data?.blocks ?? []) {
      if (block.recurrenceRule) {
        const date = Dfns.parseISO(block.start)
        const dates = getDatesInRangeForRRule({
          rrule: parseRRule(block.recurrenceRule, date),
          originalDate: date,
          start: past30Days,
          end: next30Days,
          exceptions: block.recurrenceRuleExceptions
            ?.split(',')
            .map(Dfns.parseISO),
        })
        for (const date of dates) {
          const convertDate = (dateToConvert: IsoDateString) => {
            let parsedDate = BzDateFns.parseISO(dateToConvert, timezone)
            parsedDate = BzDateFns.setYear(parsedDate, BzDateFns.getYear(date))
            parsedDate = BzDateFns.setMonth(
              parsedDate,
              BzDateFns.getMonth(date),
            )
            parsedDate = BzDateFns.setDate(parsedDate, BzDateFns.getDate(date))
            return BzDateFns.formatISO(parsedDate, timezone)
          }

          blocks.push({
            ...block,
            start: convertDate(block.start),
            end: convertDate(block.end),
          })
        }
      } else {
        blocks.push(block)
      }
    }
    return blocks
  }, [data?.blocks, timezone])

  const visibleGroups = useMemo(() => {
    const timeGroups: TimeGroup[] = [Next30Days]

    if (canManageTheirAppointments) {
      timeGroups.unshift(Today)
    } else if (canManageTheirAppointmentsToday) {
      timeGroups.unshift(Today)
    } else if (canManageTheirCurrentAppointment) {
      timeGroups.unshift(Current)
    }

    if (canViewHistoricalAppointments) {
      timeGroups.push(Past30Days)
    }

    return timeGroups
  }, [
    canManageTheirAppointments,
    canManageTheirAppointmentsToday,
    canManageTheirCurrentAppointment,
    canViewHistoricalAppointments,
  ])

  if (!data || loading) {
    return <TechAppFullScreenLoadingSpinner />
  }

  const getMyAssignment = (appt: AssignedApptViewModel) =>
    bzExpect(
      getAssignment(principalUserGuid, appt),
      'My Assignments',
      'Logically include only my appointments upstream',
    )

  const getCurrentAppointment = (
    assignedAppointments: AssignmentOrBlock[],
  ): AssignmentOrBlock | undefined => {
    return assignedAppointments
      .filter(
        a =>
          a.type === 'assigned' &&
          ['ASSIGNED', 'EN_ROUTE', 'IN_PROGRESS'].includes(
            a.assigned.appointment.appointmentStatus,
          ),
      )
      .sort((a, b) => a.start.localeCompare(b.start))[0]
  }

  const blocksGrouping = groupBy(resolvedBlocks, block => {
    return getGrouping(block.start, block.end, timezone)
  })

  const assignedAppointmentsGrouping = groupBy(data.assignments, assigned => {
    const myAssignment = getMyAssignment(assigned)
    return getGrouping(
      myAssignment.timeWindow.start,
      myAssignment.timeWindow.end,
      timezone,
    )
  })

  blocksGrouping[Current] = blocksGrouping[Today]
  assignedAppointmentsGrouping[Current] = assignedAppointmentsGrouping[Today]

  const dateGrouped: GroupedItems[] = visibleGroups.reduce(
    (acc: GroupedItems[], group: string) => {
      const blocks: AssignmentOrBlock[] =
        blocksGrouping[group]?.map(block => ({
          type: 'block',
          block,
          start: block.start,
        })) || []

      let assigneds: AssignmentOrBlock[] =
        assignedAppointmentsGrouping[group]?.map(a => ({
          type: 'assigned',
          assigned: a,
          start: getMyAssignment(a).timeWindow.start,
        })) || []

      if (group === Current) {
        const currentAppointment = getCurrentAppointment(assigneds)
        assigneds = currentAppointment ? [currentAppointment] : []
      }

      // If the tech doesn't have access to view all appointments, we only
      // show the future misc block for the next 30 days
      if (group === Next30Days && !canManageTheirAppointments) {
        assigneds = []
      }

      // make historical appointments appear in reverse chronological order
      if (canViewHistoricalAppointments && group === Past30Days) {
        acc.push({
          group,
          items: [...blocks, ...assigneds].sort((a, b) =>
            b.start.localeCompare(a.start),
          ),
        })
      } else {
        acc.push({
          group,
          items: [...blocks, ...assigneds].sort((a, b) =>
            a.start.localeCompare(b.start),
          ),
        })
      }

      return acc
    },
    [],
  )

  return (
    <div className="flex flex-col pb-4">
      <div className="mb-3">
        <UserTimeClockStatusCard />
      </div>

      {dateGrouped.map(({ group, items }, index) => {
        return (
          <BzCollapsible
            key={group}
            title={group}
            backgroundClassName="bg-inherit"
            tailwindPaddingX="px-0"
            hideShadow
            expandIconClassName=""
            showZeroChildrenLength
            defaultOpen
            tailwindPaddingYOverride="pb-3"
            expandIconPosition="left"
            childrenWrapper={({ children }) => (
              <div className="flex w-full flex-col space-y-3">{children}</div>
            )}
            emptyContent={() => {
              return (
                <div className="mt-3 w-full rounded-lg bg-white p-3 text-black shadow-sm">
                  <p className="mt-2 w-full text-center">
                    You currently have no assigned appointments for this time
                    period
                  </p>
                </div>
              )
            }}
          >
            {(items ?? []).map((item, appointmentIndex) => (
              <div
                className="flex w-full flex-col rounded-lg bg-white p-3 pb-5 shadow-md"
                key={appointmentIndex}
              >
                {item.type === 'assigned' ? (
                  <div className="w-full">
                    <div
                      key={item.assigned.appointment.guid}
                      onClick={() =>
                        navigate(
                          `/appointments/${item.assigned.appointment.guid}`,
                        )
                      }
                      className="cursor-pointer"
                    >
                      <span className="text-black">
                        <MyAppointmentCard
                          appointmentDetails={item.assigned}
                          myAssignment={getMyAssignment(item.assigned)}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <BlockCard key={item.block.guid} {...item.block} />
                )}
              </div>
            ))}
          </BzCollapsible>
        )
      })}
    </div>
  )
}

export default AppointmentsList
