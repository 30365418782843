import { LoadingSpinner } from '../../components/LoadingSpinner'
import { OnsitePageContainer } from './OnsitePageContainer'

export const OnsitePageLoader = () => (
  <OnsitePageContainer title="Loading...">
    <div className="absolute inset-0 flex items-center justify-center">
      <LoadingSpinner />
    </div>
  </OnsitePageContainer>
)
