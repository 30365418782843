import { Contact, ContactGuid } from '@breezy/shared'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { trpc } from '../trpc'

export const useFetchContactByContactGuidQuery = ({
  contactGuid,
  opts = {},
}: {
  contactGuid: ContactGuid
  opts?: {
    enabled?: boolean
    onSuccess?: (account: Contact) => void
  }
}) => {
  return trpc.contacts['contacts:query'].useQuery(
    {
      type: 'by-contact-guid',
      companyGuid: useExpectedCompany().companyGuid,
      contactGuid,
    },
    {
      select: data => {
        if (data.length !== 1) {
          throw new Error(`Failed to locate contact by guid = ${contactGuid}`)
        }
        return data[0]
      },
      enabled: opts.enabled,
      onSuccess: opts.onSuccess,
    },
  )
}
