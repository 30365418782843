import { PaymentMethod } from '@breezy/shared'
import React, { useCallback } from 'react'
import Switch from '../../../../elements/Switch/Switch'
import { PaymentWorkflowMode } from '../../PaymentWorkflowWizard'
import { PaymentErrorCard, PaymentErrorCardProps } from './PaymentErrorCard'
import { PaymentProcessingCard } from './PaymentProcessingCard'
import {
  PaymentSuccessCard,
  PaymentSuccessCardProps,
} from './PaymentSuccessCard'

export type CommonPaymentStatusProps = {
  /* This is the name of the payer typed in for the billing address */
  nameOfPayer: string

  /* The amount charged in cents */
  paymentAmountUsc: number

  /* The payment method used */
  paymentMethod: PaymentMethod
}

export type PaymentStatusCardProps = {
  mode?: PaymentWorkflowMode
} & (
  | {
      type: 'payment-processing'
      data: {
        paymentMethod: PaymentMethod
      }
    }
  | {
      type: 'payment-success'
      data: PaymentSuccessCardProps
    }
  | {
      type: 'payment-error'
      data: PaymentErrorCardProps
    }
)

export const PaymentStatusCard = React.memo<
  PaymentStatusCardProps & {
    onClose?: () => void
  }
>(({ type, data, onClose }) => {
  const onCloseInner = useCallback(() => {
    if (type !== 'payment-processing') {
      onClose?.()
    }
  }, [onClose, type])

  return (
    <div className="flex flex-col items-center">
      <Switch value={type}>
        {{
          'payment-processing': () => (
            <PaymentProcessingCard paymentMethod={data.paymentMethod} />
          ),
          'payment-success': () =>
            type === 'payment-success' && (
              <PaymentSuccessCard {...data} onClose={onCloseInner} />
            ),
          'payment-error': () =>
            type === 'payment-error' && (
              <PaymentErrorCard {...data} onClose={onCloseInner} />
            ),
          default: () => null,
        }}
      </Switch>
    </div>
  )
})
