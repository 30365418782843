import { gql } from '../../generated'

export const ALL_JOB_ASSIGNED_TECHNICIANS_QUERY = gql(/* GraphQL */ `
  query AllJobAssignedTechnicians($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      jobAppointmentAssignments {
        technicianUserGuid
        jobAppointment {
          invoicesQueryables {
            invoiceGuid
          }
          jobAppointmentInvoices {
            invoiceGuid
          }
        }
      }
    }
  }
`)

export const USERS_INFO_FOR_REVENUE_ATTRIBUTION_QUERY = gql(/* GraphQL */ `
  query UsersInfoForRevenueAttribution($userGuids: [uuid!]!) {
    users(where: { userGuid: { _in: $userGuids } }) {
      userGuid
      firstName
      lastName
      firstNameInitial
    }
  }
`)

export const ALL_REVENUE_ATTRIBUTIONS_FOR_JOB_QUERY = gql(/* GraphQL */ `
  query AllRevenueAttributionsForJob($jobGuid: uuid!) {
    jobTechnicianSoldRevenueAttributions(
      where: { jobGuid: { _eq: $jobGuid } }
    ) {
      invoiceGuid
      itemGuid
      revenueUsc
      technicianUserGuid
    }
    jobTechnicianEarnedRevenueAttributions(
      where: { jobGuid: { _eq: $jobGuid } }
    ) {
      invoiceGuid
      itemGuid
      revenueUsc
      technicianUserGuid
    }
  }
`)

export const JOB_SOLD_MAINTENANCE_PLAN_QUERY = gql(/* GraphQL */ `
  query SoldMaintenancePlanForJob($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      createdAt
      workCompletedAt
      maintenancePlan {
        maintenancePlanGuid
        status
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
        }
        activatedAt
        yearlyPriceUsc
      }
    }
  }
`)

export const INVOICE_FOR_JOB_SOLD_MAINTENANCE_PLAN_QUERY = gql(/* GraphQL */ `
  query InvoiceForJobSoldMaintenancePlan($maintenancePlanGuid: uuid!) {
    invoices(
      where: {
        maintenancePlanLink: {
          maintenancePlanGuid: { _eq: $maintenancePlanGuid }
        }
      }
    ) {
      displayId
    }
  }
`)
