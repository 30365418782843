import { faDollarCircle } from '@fortawesome/pro-light-svg-icons'
import FeatureBlockedWarningView from '../../elements/FeatureBlockedWarningView/FeatureBlockedWarningView'
import { blue6 } from '../../themes/theme'

const NoMerchantIdSetupWarningView = () => {
  return (
    <FeatureBlockedWarningView
      icon={faDollarCircle}
      iconColor={blue6}
      title="Payment Module Not Available"
      blockedMessage="Payment Module Requires Merchant Account Onboarding"
      remediationMessage="Speak to your back office manager or contact the Breezy Sales Team"
    />
  )
}

export default NoMerchantIdSetupWarningView
