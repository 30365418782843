import { ReactNode } from 'react'
import { MigrationJunk } from './MigrationJunk'

export const MigrationJunkView = ({
  migrationJunk,
}: {
  migrationJunk: MigrationJunk
}) => {
  return (
    <div className="w-full">
      <div className="mb-3 grid w-full grid-cols-1 sm:grid-cols-2 sm:gap-4">
        {Object.entries(migrationJunk.junk).map(([key, value]) => {
          return (
            <div key={key}>
              <strong>{key}</strong>
              {`${value}`.startsWith('http') ? (
                <p className="break-all">
                  <a
                    href={`${value}`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${value}`}</a>
                </p>
              ) : (
                <p className="break-all">
                  {typeof value === 'boolean'
                    ? value.toString()
                    : (value as ReactNode)}
                </p>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
