import { useCallback } from 'react'
import { FieldValues, UseFormSetValue } from 'react-hook-form'

// Why is this necessary? `setValue` from `useForm` does not mark that field as dirty (our touched) by default.
export const useDirtyingSetValue = <T extends FieldValues>(
  rawSetValue: UseFormSetValue<T>,
): UseFormSetValue<T> =>
  useCallback<typeof rawSetValue>(
    (name, value, options) =>
      // The issue is in the real `rawSetValue` it's getting the type of "value" from "name". It's doing like "value:
      // Schema[name]". Since I'm just using `typeof rawSetValue` it thinks "value" can be "messageValue |
      // taxRateValue | thisValue | thatValue" and it's complaining that I don't know if that matches "name". But I'm
      // just making a wrapper around `rawSetValue`. I'm not going to jump through hoops to make the types match
      // perfectly when I know the caller will have to invoke it correctly anyway. Hence the "as any"
      //  eslint-disable-next-line @typescript-eslint/no-explicit-any
      rawSetValue(name, value as any, {
        shouldDirty: true,
        shouldTouch: true,
        ...options,
      }),
    [rawSetValue],
  )
