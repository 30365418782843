import { AccountGuid } from '@breezy/shared'

import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

type AccountRemindersContextProps = {
  accountGuid: AccountGuid
  submitting: boolean
  isEditing: boolean
  toggleEditing: () => void
  isCreating: boolean
  disabled?: boolean
  refetch: () => void
}

const AccountRemindersContext = createContext<
  AccountRemindersContextProps | undefined
>(undefined)

export const AccountRemindersWrapper = React.memo<
  PropsWithChildren<
    Omit<AccountRemindersContextProps, 'isEditing' | 'toggleEditing'>
  >
>(({ children, ...props }) => {
  const [isEditing, setIsEditing] = useState(false)

  const toggleEditing = useCallback(
    () => setIsEditing(editStatus => !editStatus),
    [],
  )

  return (
    <AccountRemindersContext.Provider
      value={{ ...props, isEditing, toggleEditing }}
    >
      {children}
    </AccountRemindersContext.Provider>
  )
})

export const useAccountRemindersContext = () => {
  const context = useContext(AccountRemindersContext)
  if (!context) {
    throw new Error(
      'useAccountRemindersContext must be used within a AccountRemindersProvider',
    )
  }
  return context
}
