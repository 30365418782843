import { AssignmentWithTechnicianNameDTO } from '@breezy/shared'
import { faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { DateTimeWindowFromWindowDto } from '../../../../../elements/DateTimeWindow/DateTimeWindow'
import AssignmentStatusTag from '../../../../../pages/TechnicianSchedulePage/AssignmentStatusTag'
import AppointmentDetailCollapse from '../AppointmentDetailCollapse/AppointmentDetailCollapse'

export const OtherAssignedTechniciansCard = ({
  otherTechnicianAssignments,
}: {
  otherTechnicianAssignments: AssignmentWithTechnicianNameDTO[]
}) => {
  return (
    <AppointmentDetailCollapse
      title={`Other Technicians Assigned`}
      titleIcon={faUserGroup}
      content={otherTechnicianAssignments.map(assignmentWithTechnicianName => (
        <div
          className="flex items-center space-x-4"
          key={assignmentWithTechnicianName.assignmentGuid}
        >
          <div className="semibold_14_22 grey9">
            {`${assignmentWithTechnicianName.technicianFirstName} ${assignmentWithTechnicianName.technicianLastName}`}
          </div>
          <DateTimeWindowFromWindowDto
            window={assignmentWithTechnicianName.timeWindow}
            includeDate={true}
            overrideClassName="regular_14_22 grey9"
          />
          <AssignmentStatusTag
            assignmentStatus={assignmentWithTechnicianName.assignmentStatus}
          />
        </div>
      ))}
    />
  )
}
