import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import {
  JobGuid,
  MaintenancePlanCollapsibleAndConsumptionViewModel,
  MaintenancePlanGuid,
  MaintenancePlanStatus,
  isCreditActive,
} from '@breezy/shared'
import { useFetchComprehensiveJobDetailsByJobGuidQuery } from './fetch/useFetchComprehensiveJobDetailsByJobGuid'

type MaintenancePlanVisitProps = {
  readonly jobGuid?: JobGuid
}

const hasActiveCredits = (
  mp: MaintenancePlanCollapsibleAndConsumptionViewModel,
) => mp.credits?.some(c => isCreditActive(c))

export const getJobDoesNotHaveValidMaintenancePlanReason = (
  j: ComprehensiveJobDetails | undefined,
): string | undefined => {
  if (!j) return 'No Job Details'

  const finalJobInfo = {
    maintenancePlan: j.getMaintenancePlan(),
  }
  if (!finalJobInfo.maintenancePlan)
    return 'No Associated Maintenance Plan for this Account-Location'

  const mp = finalJobInfo.maintenancePlan
  const isMaintenancePlanActive = mp.status === MaintenancePlanStatus.ACTIVE

  if (!isMaintenancePlanActive) return 'Maintenance Plan is not active'
  if (!hasActiveCredits(mp)) return 'No Active Credits'

  return undefined
}

/** @deprecated cleanup with maint plans v3 */
const useMaintenancePlanVisitGuid = ({
  jobGuid,
}: MaintenancePlanVisitProps): MaintenancePlanGuid | undefined => {
  const jobDetailsQuery = useFetchComprehensiveJobDetailsByJobGuidQuery({
    jobGuid,
  })

  const reason = getJobDoesNotHaveValidMaintenancePlanReason(
    jobDetailsQuery.data,
  )
  const canUseMaintenancePlanVisit =
    !reason && !!jobDetailsQuery.data?.getUseMaintenancePlanCredit()

  const maintenancePlanGuid =
    jobDetailsQuery.data?.getMaintenancePlan()?.maintenancePlanGuid
  return canUseMaintenancePlanVisit ? maintenancePlanGuid : undefined
}

export default useMaintenancePlanVisitGuid
