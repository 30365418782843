import { z } from 'zod'
import { guidSchema } from '../../contracts/_common'
import { AccountGuid } from '../Accounts/Account'
import { ApptAssignmentGuid, JobAppointmentGuid } from '../Appointments/Appointments'
import { JobGuid } from '../Job'
import { Guid, bzOptional } from '../common-schemas'

export const hasAtLeastOneLink = (links: BzLinks): boolean =>
  !!links.jobGuid ||
  !!links.accountGuid ||
  !!links.jobAppointmentGuid ||
  !!links.apptAssignmentGuid ||
  !!links.locationGuid

export type BzLinks = {
  readonly locationGuid?: Guid
  readonly accountGuid?: AccountGuid
  readonly jobGuid?: JobGuid
  readonly jobAppointmentGuid?: JobAppointmentGuid
  readonly apptAssignmentGuid?: ApptAssignmentGuid
}

export const bzLinksSchema = z.object({
  locationGuid: bzOptional(guidSchema),
  accountGuid: bzOptional(guidSchema),
  jobGuid: bzOptional(guidSchema),
  jobAppointmentGuid: bzOptional(guidSchema),
  apptAssignmentGuid: bzOptional(guidSchema),
})
