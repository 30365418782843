import { calculateInvoiceTotals } from '@breezy/shared'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import LoanPromoQualifyNow from '../../components/Financing/LoanPromo/LoanPromoQualifyNow'
import { PrequalifiedBannerInfo } from '../../components/PrequalifiedBannerInfo/PrequalifiedBannerInfo'
import useIsMobile, { useIsTouchScreen } from '../../hooks/useIsMobile'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useStrictContext } from '../../utils/react-utils'
import { LineItemList } from '../Invoices/components/LineItemList'
import { TotalsSection } from '../Invoices/components/TotalsSection'
import {
  EstimateDataContext,
  EstimatesContext,
  OptionCartItem,
  OptionDiscount,
  useIsFinanceableCart,
} from './estimatesFlowUtils'

type OptionContainerProps = {
  header: JSX.Element
  footer?: React.ReactNode
  lineItems: OptionCartItem[]
  discounts?: OptionDiscount[]
  openItemPicker?: () => void
  onLineItemClick?: (index: number) => void
  onDiscountClick?: (index: number) => void
  onReorder?: (itemIndex: number, dropIndex: number) => void
  subtotalCollapsible?: boolean
  className?: string
  style?: React.CSSProperties
  recommended?: boolean
  selected?: boolean
  isEditing?: boolean
  anchorFooter?: boolean
  showLoanDisclosure?: boolean
  showPromoPrequal?: boolean
  noBorder?: boolean
  customerFacing?: boolean
}

export const OptionContainer = React.memo<OptionContainerProps>(
  ({
    header,
    footer,
    lineItems,
    discounts: externalDiscounts,
    openItemPicker,
    onLineItemClick,
    onDiscountClick,
    subtotalCollapsible = true,
    className,
    style,
    recommended,
    selected,
    isEditing,
    anchorFooter,
    showLoanDisclosure = true,
    showPromoPrequal = false,
    onReorder,
    noBorder,
    customerFacing,
  }) => {
    const isMobile = useIsMobile()
    const wisetackEnabled = useWisetackEnabled()

    const discounts = useMemo(
      () => externalDiscounts ?? [],
      [externalDiscounts],
    )

    const isTouchScreen = useIsTouchScreen()
    const { taxRate, dynamicPricingType } =
      useStrictContext(EstimateDataContext)
    const { accountGuid, jobGuid, prequalContactGuid, companyName } =
      useStrictContext(EstimatesContext)

    const summary = useMemo(
      () =>
        calculateInvoiceTotals(
          lineItems,
          taxRate.rate,
          discounts,
          [],
          dynamicPricingType,
        ),
      [discounts, dynamicPricingType, lineItems, taxRate.rate],
    )
    const isFinanceable = useIsFinanceableCart(summary.totalUsc)

    const accentBarColor = useMemo(() => {
      if (noBorder) {
        return undefined
      }
      if (selected) {
        return '#52C41A'
      }
      if (recommended) {
        return '#1677FF'
      }
      return '#F0F0F0'
    }, [noBorder, recommended, selected])

    return (
      <SectionedCard
        className={className}
        style={style}
        accentBarColor={accentBarColor}
        noBorder={noBorder}
        sections={[
          {
            coloredIn: true,
            content: header,
            verticalPaddingClassName: isMobile ? 'p-4' : 'p-6',
          },
          {
            verticalPaddingClassName: 'py-1',
            flex: 1,
            content:
              lineItems.length === 0 && openItemPicker ? (
                <div
                  className={classNames(
                    'cursor-pointer rounded-lg bg-bz-gray-200 px-4 py-5 text-center text-sm text-bz-gray-900',
                    isMobile ? 'my-4' : 'my-6',
                  )}
                  onClick={openItemPicker}
                >
                  Line items will be displayed here.{' '}
                  {isTouchScreen ? 'Tap' : 'Click'} to add one now.
                </div>
              ) : (
                <LineItemList
                  onReorder={onReorder}
                  lineItems={lineItems}
                  discounts={discounts}
                  onItemClick={onLineItemClick}
                  onDiscountClick={onDiscountClick}
                  // Note: for this we WANT it when customer facing because we want it to scale up the line item numbers
                  dynamicPricingType={
                    customerFacing ? dynamicPricingType : undefined
                  }
                />
              ),
          },
          {
            verticalPaddingClassName: anchorFooter
              ? classNames('pb-2', isMobile ? 'pt-3' : 'pt-4')
              : undefined,
            content: (
              <>
                <TotalsSection
                  {...summary}
                  defaultTotalsOpen={false}
                  subtotalCollapsible={subtotalCollapsible}
                  discounts={discounts}
                  hideTotalSubTotal
                  dynamicPricingType={
                    // Note: for this we DON'T want it when customer facing because we don't want it to break out the
                    // markup numbers.
                    customerFacing ? undefined : dynamicPricingType
                  }
                />
                {isFinanceable && !isEditing && (
                  <LoanPromoQualifyNow
                    type="prequal"
                    accountGuid={accountGuid}
                    jobGuid={jobGuid}
                    showLoanDisclosure={showLoanDisclosure}
                    withQualifyNowButton={showPromoPrequal}
                    prequalContactGuid={prequalContactGuid}
                    amountUsc={summary.totalUsc}
                  />
                )}
                {!isEditing && showPromoPrequal && wisetackEnabled && (
                  <PrequalifiedBannerInfo
                    accountGuid={accountGuid}
                    companyName={companyName}
                    className="mt-3"
                  />
                )}
                {anchorFooter ? null : footer}
              </>
            ),
          },
        ]}
        anchoredFooter={anchorFooter && footer}
      />
    )
  },
)
