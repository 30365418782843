import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { m } from '../../utils/react-utils'
import ProgressiveFullScreenModalStepper from '../ProgressiveFullScreenModalStepper/ProgressiveFullScreenModalStepper'
import CreationStepHeading from '../ProgressiveJobCreationModal/JobCreationSteps/CreationStepHeading'

export type StepDefinition = {
  id: number
  stepName: string
  heading: string
  subheading: string
  preRequiredData: string[]

  hideProgressButtons?: boolean
  hideStepper?: boolean
  proceedRequiredData?: string
}

export type TransitionErrorReason = {
  fromStep: StepDefinition
  toStep: StepDefinition
  reason: string
}

type ProgressiveStepControllerProps = {
  onFinish: () => void
  finishButtonText: string
  sidebarTitle: string
  steps: StepDefinition[]
  hasSelectedData: (data: string) => boolean
  getStepComponent: (step: StepDefinition) => ReactNode
  getSidebarItems: (step: StepDefinition) => ReactNode
  transitionStepAsync: (
    currentNext: StepDefinition,
    nextStep: StepDefinition,
  ) => Promise<TransitionErrorReason | 'done'>

  requestedStep?: StepDefinition
  clearRequestedStep?: () => void

  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  hidePreviousNextButtons?: boolean
  hideSteps?: boolean
}

type ProgressiveStepContentProps = {
  step: StepDefinition
  children: ReactNode
}

export const ProgressiveStepContent = m<ProgressiveStepContentProps>(
  ({ step, children }) => {
    return (
      <div className="flex flex-col space-y-6">
        <CreationStepHeading {...step} />
        {children}
      </div>
    )
  },
)

export const ProgressiveStepController = m<ProgressiveStepControllerProps>(
  ({
    isOpen,
    setIsOpen,
    sidebarTitle,
    steps,
    onFinish,
    finishButtonText,
    hasSelectedData,
    hidePreviousNextButtons,
    hideSteps,
    getStepComponent,
    getSidebarItems,
    transitionStepAsync,
    requestedStep,
    clearRequestedStep,
  }) => {
    const [currentStep, setCurrentStep] = useState<StepDefinition>(steps[0])
    const [lastError, setLastError] = useState<string>()

    const canReverse = useCallback(
      () => steps.indexOf(currentStep) > 0,
      [steps, currentStep],
    )

    const canProceed = useCallback(() => {
      return (
        !currentStep.proceedRequiredData ||
        hasSelectedData(currentStep.proceedRequiredData)
      )
    }, [currentStep, hasSelectedData])

    const stepComponent = useMemo(
      () => getStepComponent(currentStep),
      [currentStep, getStepComponent],
    )
    const boolNoOp = useCallback((v: boolean) => {}, [])
    const sidebarItems = useMemo(
      () => getSidebarItems(currentStep),
      [currentStep, getSidebarItems],
    )

    const goToStepAsync = useCallback(
      async (fromStep: number, toStep: number) => {
        if (toStep < 0 || toStep >= steps.length) {
          console.error(`Invalid toStep: ${toStep}`)
          return
        }
        if (fromStep === toStep) {
          return
        }

        const nextStep = steps[toStep]
        const errorOrDone = await transitionStepAsync(currentStep, nextStep)
        if (errorOrDone === 'done') {
          setCurrentStep(nextStep)
          setLastError(undefined)
        } else {
          setLastError(errorOrDone.reason)
        }
      },
      [currentStep, transitionStepAsync, steps, setLastError, setCurrentStep],
    )

    const goToStep = useCallback(
      (fromStep: number, toStep: number) =>
        goToStepAsync(fromStep, toStep).then(x => {}),
      [goToStepAsync],
    )

    useEffect(() => {
      if (requestedStep && steps.includes(requestedStep)) {
        goToStep(steps.indexOf(currentStep), steps.indexOf(requestedStep))
        clearRequestedStep?.()
      }
    }, [requestedStep, clearRequestedStep, goToStep, steps, currentStep])

    return (
      <ProgressiveFullScreenModalStepper
        isOpen={isOpen ?? true}
        setIsOpen={setIsOpen ?? boolNoOp}
        steps={steps.map(x => x.stepName)}
        currentStep={steps.indexOf(currentStep)}
        goToStep={goToStep}
        onFinish={onFinish}
        finishActionButtonText={finishButtonText}
        canProceed={canProceed}
        lastError={lastError}
        canReverse={canReverse}
        hidePreviousNextButtons={hidePreviousNextButtons}
        hideSteps={hideSteps}
        sidebarTitle={sidebarTitle}
        sidebarContent={sidebarItems}
      >
        {stepComponent}
      </ProgressiveFullScreenModalStepper>
    )
  },
)
