import { gql } from '../../../../generated'

export const PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query PrimaryContactInfoForAccount($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountContacts(where: { primary: { _eq: true } }) {
        contact {
          contactGuid
          firstName
          lastName
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            phoneNumber
          }
        }
      }
    }
  }
`)

export const CONTACT_BY_GUID_QUERY = gql(/* GraphQL */ `
  query QueryContact($query: ContactsBoolExp!) {
    contacts(where: $query) {
      ...Contact
    }
  }
`)
