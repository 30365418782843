import {
  Account,
  AccountType,
  BzAddress,
  bzExpect,
  CalculatePaths,
  ContactGuid,
  getStateAbbreviationOrUndefined,
  Guid,
  isNullish,
  JobGuid,
  nextGuid,
  NotificationPreferenceType,
  ThisShouldNeverHappenError,
  tryMatchContactByEmailAddress,
  tryMatchContactByName,
  tryMatchContactByPhoneNumber,
  tryMatchJobType,
  tryMatchLocationByAddress,
} from '@breezy/shared'
import { tryFormat } from '@breezy/shared/src/common/phone-utils'
import { Divider } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, {
  ElementType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { useMutation, useQuery } from 'urql'
import { EmDash } from '../../elements/EmDash/EmDash'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useFetchAccountByAccountGuidQuery } from '../../hooks/fetch/useFetchAccountByGuid'
import { trpc } from '../../hooks/trpc'
import { JobLeadContextView } from '../../pages/JobLeadsPage/JobLeadContextView'
import { CONVERT_JOB_LEAD } from '../../pages/JobLeadsPage/JobLeads.gql'
import { JobLead } from '../../pages/JobLeadsPage/types'
import {
  useCompanyDefaultAccountManagerUserGuid,
  useExpectedCompany,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import ErrorMessages from '../../utils/ErrorMessages'
import {
  CreateOrEditJobForm,
  makeEquipmentTypeJobLinks,
  onFormSubmitValues,
} from '../CreateOrEditJobForm/CreateOrEditJobForm'
import { CreateOrEditNewJobFormSchema } from '../CreateOrEditJobForm/CreateOrEditNewJobFormSchema'
import CreateOrEditNewAccountLocationForm, {
  AccountLocationFormSchema,
} from '../CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import {
  EmbeddedContactInformationForm,
  EmbeddedContactInformationFormSchema,
} from '../EmbeddedContactInformationForm/EmbeddedContactInformationForm'
import { LoadingSpinner } from '../LoadingSpinner'
import ProgressiveFullScreenModalStepper from '../ProgressiveFullScreenModalStepper/ProgressiveFullScreenModalStepper'
import { SidebarAccount } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarAccount'
import { SidebarContact } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarContact'
import SidebarItem from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarItem'
import { SidebarLocation } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarLocation'
import AccountSearchAutocomplete from './AccountSearchAutocomplete/AccountSearchAutocomplete'
import CreateNewAccountForm, {
  AccountFormSchema,
} from './CreateOrEditAccountForm/CreateOrEditAccountForm'
import CreationStepHeading from './JobCreationSteps/CreationStepHeading'
import LinkedInformation from './LinkedInformation/LinkedInformation'
import NewOrExistingOptions from './NewOrExistingOptions/NewOrExistingOptions'

const existingLocationSteps = ['Job Details']
const existingAccountSteps = ['Contact', 'Location', 'Job Details']
const createAccountSteps = ['Contact', 'Location', 'Account', 'Job Details']
const heading = 'Create New Job'

export type ProgressiveJobCreationModalProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedJobTypeGuid?: string
  selectedAccount?: Account
  selectedAccountGuid?: Guid
  jobLead?: JobLead
  disableCreateNewAppointment?: boolean
  onSuccess?: (jobGuid: JobGuid) => void
}

const DividerWrapper = React.memo<{
  children: React.ReactNode[]
  divider?: ReactNode
  WrapperComponent?: ElementType
}>(({ children, divider = Divider, WrapperComponent = 'div' }) => {
  // Filter out any null or undefined children
  const validChildren = React.Children.toArray(children).filter(Boolean)

  // Interleave each child with a divider
  return (
    <>
      {validChildren.map((child, index) => {
        // Dynamically render the specified WrapperComponent
        const Wrapper = WrapperComponent
        return (
          <Wrapper key={index}>
            {child}
            {index < validChildren.length - 1 && divider}
          </Wrapper>
        )
      })}
    </>
  )
})

const ProgressiveJobCreationModal = ({
  isOpen,
  setIsOpen,
  selectedAccount,
  selectedAccountGuid,
  disableCreateNewAppointment,
  onSuccess,
  selectedJobTypeGuid,
  jobLead,
}: ProgressiveJobCreationModalProps) => {
  const message = useMessage()
  const companyGuid = useExpectedCompany().companyGuid
  const preselectedAccountGuidQP = useSearchParam('accountGuid') ?? undefined
  const preselectedContactGuid = useSearchParam('contactGuid') ?? undefined
  const preselectedFirstName = useSearchParam('firstName') ?? undefined
  const preselectedLastName = useSearchParam('lastName') ?? undefined
  const preselectedLocationGuid = useSearchParam('locationGuid') ?? undefined
  const preselectedLinkedJobGuid = useSearchParam('linkedJobGuid') ?? undefined
  const preselectedJobClass = useSearchParam('jobClass') ?? undefined
  const preselectedPhoneNumber = useSearchParam('phoneNumber') ?? undefined
  const preselectedLeadSourceGuid =
    useSearchParam('leadSourceGuid') ?? undefined
  const preselectedCreateNewAccount =
    useSearchParam('createNewAccount') ?? undefined

  const [createOrEditNewJobForm] = useForm<CreateOrEditNewJobFormSchema>()
  const [createNewPrimaryContactForm] =
    useForm<EmbeddedContactInformationFormSchema>()
  const [createNewLocationForm] = useForm<AccountLocationFormSchema>()
  const [createNewAccountForm] = useForm<AccountFormSchema>()

  useEffect(() => {
    if (preselectedPhoneNumber) {
      createNewPrimaryContactForm.setFieldsValue({
        phoneNumber: preselectedPhoneNumber,
        firstName: preselectedFirstName,
        lastName: preselectedLastName,
      })
    }
  }, [
    preselectedPhoneNumber,
    createNewPrimaryContactForm,
    preselectedFirstName,
    preselectedLastName,
  ])

  useEffect(() => {
    if (preselectedLeadSourceGuid) {
      createNewAccountForm.setFieldsValue({
        leadSourceGuid: preselectedLeadSourceGuid,
      })
    }
  }, [preselectedLeadSourceGuid, createNewAccountForm])

  // NOTE: false means we are editing an existing account
  const [createAccount, setCreateAccount] = useState(
    !!preselectedCreateNewAccount,
  )
  const [currentAccount, setCurrentAccount] = useState<Account | undefined>(
    selectedAccount,
  )
  const [currentStep, setCurrentStep] = useState(
    selectedAccount ? 2 : createAccount ? 1 : 0,
  )
  const [initialStep] = useState(currentStep)

  const [selectedContactGuid, setSelectedContactGuid] = useState<
    string | undefined
  >(preselectedContactGuid)
  const [selectedLocationGuid, setSelectedLocationGuid] = useState<string>(
    preselectedLocationGuid ?? '',
  )

  const [primaryContact, setPrimaryContact] =
    useState<EmbeddedContactInformationFormSchema>()
  const [locationInformation, setLocationInformation] =
    useState<AccountLocationFormSchema>()
  const [accountInformation, setAccountInformation] =
    useState<AccountFormSchema>()

  const preselectedAccountGuid = selectedAccountGuid ?? preselectedAccountGuidQP

  const isLoading = useMemo(
    () => preselectedAccountGuid && !currentAccount,
    [preselectedAccountGuid, currentAccount],
  )

  useFetchAccountByAccountGuidQuery({
    accountGuid: preselectedAccountGuid ?? '',
    opts: {
      enabled: !!preselectedAccountGuid,
      onSuccess: data => setCurrentAccountAndAdvance(data),
    },
  })

  const accountQuery = useFetchAccountByAccountGuidQuery({
    accountGuid: currentAccount?.accountGuid ?? '',
    opts: {
      enabled: false,
      onSuccess: data => setCurrentAccount(data),
    },
  })

  const defaultCompanyAccountManagerUserGuid =
    useCompanyDefaultAccountManagerUserGuid()

  const createNewJobMutation = trpc.jobs['jobs:create'].useMutation()

  const createJobForNewAccountMutation =
    trpc.jobs['jobs:create-for-new-account'].useMutation()

  const jobTypesQuery = trpc.jobTypes['job-types:get'].useQuery()

  const selectableJobTypes = useMemo(() => {
    const nonArchived =
      jobTypesQuery.data?.filter(jobType => isNullish(jobType.archivedAt)) ?? []
    if (preselectedJobClass) {
      const matchingJobs = nonArchived.filter(
        jobType =>
          jobType.jobClass.toString().toLowerCase() ===
          preselectedJobClass.toLowerCase(),
      )
      if (matchingJobs.length > 0) {
        return matchingJobs
      }
    }

    return nonArchived
  }, [jobTypesQuery.data, preselectedJobClass])

  const [tagsQuery] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const fetchCompanyLeadSourcesQuery = trpc.leadAttribution[
    'company-lead-sources:fetch'
  ].useQuery({ companyGuid })

  const navigate = useNavigate()

  const canProgressToNextStepExistingAccount = useCallback(() => {
    if (currentStep === 1 && !currentAccount) {
      return false
    }
    if (
      currentStep === 2 &&
      (selectedContactGuid === '' || selectedLocationGuid === '')
    ) {
      return false
    }
    return true
  }, [currentStep, currentAccount, selectedContactGuid, selectedLocationGuid])

  const setCurrentAccountAndAdvance = useCallback(
    (account: Account) => {
      const maybePrimaryContactGuid =
        account.accountContacts.filter(a => a.primary)[0]?.contact
          ?.contactGuid ?? ''

      // if (contactGuid.length > 0 && !selectedContactGuid) {
      //   setSelectedContactGuid(contactGuid)
      // }

      // if (account.accountLocations.length > 0 && !selectedLocationGuid) {
      //   setSelectedLocationGuid(
      //     account.accountLocations[0].location.locationGuid,
      //   )
      // }

      if (jobLead) {
        let maybeContactGuid: ContactGuid | undefined = undefined
        if (jobLead.contactPhoneNumber) {
          maybeContactGuid = tryMatchContactByPhoneNumber(
            account.accountContacts.map(ac => ac.contact),
            jobLead.contactPhoneNumber,
          )
        }

        if (!maybeContactGuid && jobLead.contactEmailAddress) {
          maybeContactGuid = tryMatchContactByEmailAddress(
            account.accountContacts.map(ac => ac.contact),
            jobLead.contactEmailAddress,
          )
        }

        if (
          !maybeContactGuid &&
          (jobLead.contactFirstName || jobLead.contactLastName)
        ) {
          maybeContactGuid = tryMatchContactByName(
            account.accountContacts.map(ac => ac.contact),
            jobLead.contactFirstName,
            jobLead.contactLastName,
          )
        }

        maybeContactGuid = maybeContactGuid ?? maybePrimaryContactGuid

        if (maybeContactGuid.length > 0) {
          setSelectedContactGuid(maybeContactGuid)
        }

        if (jobLead.serviceAddressLine1) {
          const allLocations = account.accountLocations.map(al => {
            return al.location
          })

          const maybeMatchingLocationGuid = tryMatchLocationByAddress(
            allLocations,
            {
              line1: jobLead.serviceAddressLine1,
              line2: jobLead.serviceAddressLine2,
              city: jobLead.serviceAddressCity,
              stateAbbreviation: jobLead.serviceAddressStateAbbreviation,
              zipCode: jobLead.serviceAddressZipCode,
            },
          )

          if (maybeMatchingLocationGuid) {
            setSelectedLocationGuid(maybeMatchingLocationGuid)
          }
        }

        setCurrentStep(2)
      } else {
        const effectiveContactGuid =
          preselectedContactGuid ?? maybePrimaryContactGuid
        const nextStep =
          effectiveContactGuid.length > 0 && selectedLocationGuid.length > 0
            ? 3
            : 2
        if (effectiveContactGuid.length > 0 && !selectedContactGuid) {
          setSelectedContactGuid(effectiveContactGuid)
        }

        if (account.accountLocations.length > 0 && !selectedLocationGuid) {
          setSelectedLocationGuid(
            account.accountLocations[0].location.locationGuid,
          )
        }

        setCurrentStep(nextStep)
      }

      setCurrentAccount(account)
    },
    [
      jobLead,
      preselectedContactGuid,
      selectedLocationGuid,
      selectedContactGuid,
    ],
  )

  const canProgressToNextStep = useCallback(() => {
    if (
      fetchCompanyLeadSourcesQuery.isLoading ||
      jobTypesQuery.isLoading ||
      tagsQuery.fetching
    ) {
      return false
    }

    if (createAccount) {
      return true
    }
    return canProgressToNextStepExistingAccount()
  }, [
    canProgressToNextStepExistingAccount,
    createAccount,
    fetchCompanyLeadSourcesQuery.isLoading,
    jobTypesQuery.isLoading,
    tagsQuery.fetching,
  ])

  const canReverseToPreviousStep = useCallback(() => {
    if (
      currentStep === 0 ||
      (preselectedAccountGuid && preselectedLocationGuid) ||
      currentStep === initialStep
    ) {
      return false
    }
    return true
  }, [
    currentStep,
    initialStep,
    preselectedAccountGuid,
    preselectedLocationGuid,
  ])

  const prefillCreateNewLocationFormWithJobLeadInfo = useCallback(() => {
    if (!jobLead) {
      return
    }

    if (jobLead.serviceAddressLine1) {
      createNewLocationForm.setFieldValue(
        'addressLineOne',
        jobLead.serviceAddressLine1,
      )
    }

    if (jobLead.serviceAddressLine1 && jobLead.serviceAddressLine2) {
      createNewLocationForm.setFieldValue(
        'addressLineTwo',
        jobLead.serviceAddressLine2,
      )
    }

    if (jobLead.serviceAddressCity) {
      createNewLocationForm.setFieldValue(
        'addressCity',
        jobLead.serviceAddressCity,
      )
    }

    if (jobLead.serviceAddressStateAbbreviation) {
      createNewLocationForm.setFieldValue(
        'addressState',
        jobLead.serviceAddressStateAbbreviation,
      )
    }

    if (jobLead.serviceAddressZipCode) {
      createNewLocationForm.setFieldValue(
        'addressZipCode',
        jobLead.serviceAddressZipCode,
      )
    }
  }, [createNewLocationForm, jobLead])

  const setCurrentStepGuardedCreateAccount = useCallback(
    (fromStep: number, toStep: number) => {
      const movingForwardInWizard = toStep > fromStep
      const movingBackwardInWizard = toStep < fromStep

      if (fromStep === 1 && movingForwardInWizard) {
        createNewPrimaryContactForm
          .validateFields()
          .then(values => {
            setPrimaryContact(values)
            if (jobLead) {
              prefillCreateNewLocationFormWithJobLeadInfo()
            }
            setCurrentStep(toStep)
          })
          .catch(console.error)
      } else if (fromStep === 2 && movingForwardInWizard) {
        createNewLocationForm
          .validateFields()
          .then(values => {
            setLocationInformation(values)
            setCurrentStep(toStep)
          })
          .catch(console.error)
      } else if (fromStep === 3 && movingForwardInWizard) {
        createNewAccountForm
          .validateFields()
          .then(values => {
            setAccountInformation(values)
            setCurrentStep(toStep)
          })
          .catch(console.error)
      } else if (fromStep === 1 && movingBackwardInWizard) {
        setPrimaryContact(undefined)
        setLocationInformation(undefined)
        setAccountInformation(undefined)
        setCurrentStep(toStep)
      } else if (fromStep === 2 && movingBackwardInWizard) {
        setLocationInformation(undefined)
        setAccountInformation(undefined)
        setCurrentStep(toStep)
      } else if (fromStep === 3 && movingBackwardInWizard) {
        setAccountInformation(undefined)
        setCurrentStep(toStep)
      } else {
        return setCurrentStep(toStep)
      }
    },
    [
      createNewPrimaryContactForm,
      jobLead,
      prefillCreateNewLocationFormWithJobLeadInfo,
      createNewLocationForm,
      createNewAccountForm,
    ],
  )

  const setCurrentStepGuardedExistingAccount = useCallback(
    (fromStep: number, toStep: number) => {
      const movingForwardInWizard = toStep > fromStep
      const movingBackwardInWizard = toStep < fromStep

      if (movingForwardInWizard && !canProgressToNextStep()) {
        return
      }
      if (movingBackwardInWizard && !canReverseToPreviousStep()) {
        return
      }

      // If the user goes backwards in the stepper from set contact & location step to the select account step then
      // unset account, contact, and location
      if (movingBackwardInWizard && toStep === 1) {
        setCurrentAccount(undefined)
        setSelectedContactGuid('')
        setSelectedLocationGuid('')
      }

      setCurrentStep(toStep)
    },
    [canProgressToNextStep, canReverseToPreviousStep],
  )

  const setCurrentStepGuarded = useCallback(
    (fromStep: number, toStep: number) => {
      if (createAccount) {
        setCurrentStepGuardedCreateAccount(fromStep, toStep)
      } else {
        setCurrentStepGuardedExistingAccount(fromStep, toStep)
      }
    },
    [
      createAccount,
      setCurrentStepGuardedCreateAccount,
      setCurrentStepGuardedExistingAccount,
    ],
  )

  const [, executeMutation] = useMutation(CONVERT_JOB_LEAD)

  const convertJobLead = useCallback(
    async (jobGuid: JobGuid) => {
      if (jobLead) {
        await executeMutation({ jobGuid, jobLeadGuid: jobLead.jobLeadGuid })
      }
    },
    [executeMutation, jobLead],
  )

  const onSuccessfulMutate = useCallback(
    async ({
      jobGuid,
      shouldCreateAppointmentAfterwards,
    }: {
      jobGuid: JobGuid
      shouldCreateAppointmentAfterwards?: boolean
    }) => {
      if (jobLead) {
        await convertJobLead(jobGuid)
      }

      if (onSuccess) {
        onSuccess(jobGuid)
      } else {
        navigate({
          pathname: CalculatePaths.jobDetails({ jobGuid }),
          search: shouldCreateAppointmentAfterwards
            ? createSearchParams({
                createAppointment: 'true',
                jobLeadGuid: jobLead?.jobLeadGuid ?? '',
              }).toString()
            : undefined,
        })
      }
    },
    [convertJobLead, jobLead, navigate, onSuccess],
  )

  const createNewJobViaApi = useCallback(
    (values: onFormSubmitValues & { account: Account }) => {
      const {
        installProjectType,
        locationGuid,
        summary,
        isOpportunity,
        isHotLead,
        isMembershipOpportunity,
        isMembershipRenewalOpportunity,
        leadSourceGuid,
        leadSourceReferringContactGuid,
        leadSourceAttributionDescription,
        pointOfContactGuid,
        account,
        shouldCreateAppointmentAfterwards,
        tags,
        useMaintenancePlanCredit,
        maintenancePlanVisitGuid,
        customerPurchaseOrderNumber,
        linkedJobGuid,
        linkLinkedJobNotes,
        linkLinkedJobPhotos,
        linkLinkedJobAttachments,
        linkLinkedJobEstimates,
      } = values
      try {
        const jobTypeGuid = values.jobTypeGuid ?? selectedJobTypeGuid
        const jobGuid = nextGuid()

        createNewJobMutation.mutate(
          {
            jobGuid,
            jobTypeGuid,
            locationGuid,
            accountGuid: account.accountGuid,
            installProjectType,
            equipmentTypeJobLinks: makeEquipmentTypeJobLinks(
              values,
              bzExpect(
                jobTypesQuery.data?.find(jt => jt.jobTypeGuid === jobTypeGuid),
              ),
            ),
            summary,
            pointOfContactGuid,
            // These fields can be undefined if the form items are not shown (e.g. if the
            // technicianPerformance FF is off). In this case, default them to false. These
            // defaults can potentially be removed in the future
            isOpportunity: isOpportunity ?? false,
            isHotLead: isHotLead ?? false,
            isMembershipOpportunity: isMembershipOpportunity ?? false,
            isMembershipRenewalOpportunity:
              isMembershipRenewalOpportunity ?? false,
            companyLeadSource: {
              leadSourceGuid,
              leadSourceReferringContactGuid,
              leadSourceAttributionDescription,
            },
            tags: tags?.map(tagGuid => ({ jobGuid, tagGuid })),
            useMaintenancePlanCredit: useMaintenancePlanCredit ?? false,
            maintenancePlanVisitGuid,
            customerPurchaseOrderNumber,
            linkedJob: !isNullish(linkedJobGuid)
              ? {
                  linkedJobGuid,
                  linkNotes: linkLinkedJobNotes ?? false,
                  linkPhotos: linkLinkedJobPhotos ?? false,
                  linkAttachments: linkLinkedJobAttachments ?? false,
                  linkEstimates: linkLinkedJobEstimates ?? [],
                }
              : undefined,
          },
          {
            onSuccess({ jobGuid }) {
              onSuccessfulMutate({ jobGuid, shouldCreateAppointmentAfterwards })
            },
          },
        )
      } catch (e) {
        console.error(e)
        return
      }
    },
    [
      createNewJobMutation,
      jobTypesQuery.data,
      onSuccessfulMutate,
      selectedJobTypeGuid,
    ],
  )

  const handleCreateJobFormSubmit = useCallback(() => {
    if (
      createAccount &&
      primaryContact &&
      accountInformation &&
      locationInformation
    ) {
      const stateAbbreviation = getStateAbbreviationOrUndefined(
        locationInformation.addressState,
      )
      if (!stateAbbreviation) {
        const msg = ErrorMessages.invalidStateSelection
        message.error(msg)
        throw new ThisShouldNeverHappenError(msg)
      }

      const jobGuid = nextGuid()

      createOrEditNewJobForm
        .validateFields()
        .then(values => {
          const jobTypeGuid = values.jobTypeGuid ?? selectedJobTypeGuid
          const accountGuid = nextGuid()
          createJobForNewAccountMutation.mutate(
            {
              accountInfo: {
                accountGuid,
                ...accountInformation,
              },
              contactInfo: {
                ...primaryContact,
              },
              serviceLocationInfo: {
                address: {
                  line1: locationInformation.addressLineOne,
                  line2: locationInformation.addressLineTwo,
                  city: locationInformation.addressCity,
                  stateAbbreviation,
                  zipCode: locationInformation.addressZipCode,
                },
                municipality: locationInformation.municipality,
              },
              companyLeadSource: {
                leadSourceGuid: values.leadSourceGuid,
                leadSourceReferringContactGuid:
                  values.leadSourceReferringContactGuid,
                leadSourceAttributionDescription:
                  values.leadSourceAttributionDescription,
              },
              initialJobDetails: {
                ...values,
                jobGuid,
                companyLeadSource: {
                  leadSourceGuid: values.leadSourceGuid,
                  leadSourceReferringContactGuid:
                    values.leadSourceReferringContactGuid,
                  leadSourceAttributionDescription:
                    values.leadSourceAttributionDescription,
                },
                summary: values.summary || '',
                jobTypeGuid,
                equipmentTypeJobLinks: makeEquipmentTypeJobLinks(
                  values,
                  bzExpect(
                    jobTypesQuery.data?.find(
                      jt => jt.jobTypeGuid === jobTypeGuid,
                    ),
                  ),
                ),
                installProjectType: values.installProjectType,
                // These fields can be undefined if the form items are not shown (e.g. if the
                // technicianPerformance FF is off). In this case, default them to false. These
                // defaults can potentially be removed in the future
                isOpportunity: values.isOpportunity ?? false,
                isHotLead: values.isHotLead ?? false,
                isMembershipOpportunity:
                  values.isMembershipOpportunity ?? false,
                isMembershipRenewalOpportunity:
                  values.isMembershipRenewalOpportunity ?? false,
                tags: values.tags?.map(tagGuid => ({ jobGuid, tagGuid })),
                useMaintenancePlanCredit: false, // New accounts won't have a Maintenance Plan
                maintenancePlanVisitGuid: undefined, // New accounts won't have a Maintenance Plan
                customerPurchaseOrderNumber: values.customerPurchaseOrderNumber,
                linkedJob: !isNullish(values.linkedJobGuid)
                  ? {
                      linkedJobGuid: values.linkedJobGuid,
                      linkNotes: values.linkLinkedJobNotes ?? false,
                      linkPhotos: values.linkLinkedJobPhotos ?? false,
                      linkAttachments: values.linkLinkedJobAttachments ?? false,
                      linkEstimates: values.linkLinkedJobEstimates ?? [],
                    }
                  : undefined,
              },
              accountTags:
                accountInformation.accountTagGuids?.map(tagGuid => ({
                  accountGuid,
                  tagGuid,
                })) ?? [],
            },
            {
              onSuccess({ jobGuid }) {
                onSuccessfulMutate({
                  jobGuid,
                  shouldCreateAppointmentAfterwards:
                    values.shouldCreateAppointmentAfterwards,
                })
              },
            },
          )
        })
        .catch(console.error)
    } else {
      createOrEditNewJobForm
        .validateFields()
        .then(values => {
          if (
            !currentAccount ||
            !selectedLocationGuid ||
            !selectedContactGuid
          ) {
            return
          }
          createNewJobViaApi({
            ...values,
            account: currentAccount,
            locationGuid: selectedLocationGuid,
            pointOfContactGuid: selectedContactGuid,
          })
        })
        .catch(console.error)
    }
  }, [
    createAccount,
    primaryContact,
    accountInformation,
    locationInformation,
    selectedJobTypeGuid,
    createOrEditNewJobForm,
    createJobForNewAccountMutation,
    jobTypesQuery.data,
    onSuccessfulMutate,
    currentAccount,
    selectedLocationGuid,
    selectedContactGuid,
    createNewJobViaApi,
    message,
  ])

  const prefillCreateNewPrimaryContactFormWithJobLeadInfo = useCallback(() => {
    if (!jobLead) {
      return
    }

    if (jobLead.contactFirstName) {
      createNewPrimaryContactForm.setFieldValue(
        'firstName',
        jobLead.contactFirstName,
      )
    }

    if (jobLead.contactLastName) {
      createNewPrimaryContactForm.setFieldValue(
        'lastName',
        jobLead.contactLastName,
      )
    }

    if (jobLead.contactEmailAddress) {
      createNewPrimaryContactForm.setFieldValue(
        'emailAddress',
        jobLead.contactEmailAddress,
      )

      createNewPrimaryContactForm.setFieldValue(
        'notificationPreference',
        NotificationPreferenceType.Email,
      )
    }

    if (jobLead.contactPhoneNumber) {
      createNewPrimaryContactForm.setFieldValue(
        'phoneNumber',
        tryFormat(jobLead.contactPhoneNumber),
      )

      createNewPrimaryContactForm.setFieldValue(
        'notificationPreference',
        NotificationPreferenceType.Sms,
      )
    }
  }, [createNewPrimaryContactForm, jobLead])

  const NewOrExistingOptionsNode = (
    <div className="flex flex-col space-y-6">
      <CreationStepHeading heading={heading} />
      {jobLead ? (
        <NewOrExistingOptions
          jobLead={jobLead}
          onRecommendedAccountSelected={setCurrentAccountAndAdvance}
          onClickExistingAccount={() => {
            setCreateAccount(false)
            currentAccount ? setCurrentStep(2) : setCurrentStep(1)
          }}
          onClickNewAccount={() => {
            if (jobLead) {
              prefillCreateNewPrimaryContactFormWithJobLeadInfo()
            }
            setCreateAccount(true)
            setCurrentStep(1)
          }}
        />
      ) : (
        <NewOrExistingOptions
          onClickExistingAccount={() => {
            setCreateAccount(false)
            currentAccount ? setCurrentStep(2) : setCurrentStep(1)
          }}
          onClickNewAccount={() => {
            setCreateAccount(true)
            setCurrentStep(1)
          }}
        />
      )}
    </div>
  )

  const hiddenJobCreationFields = useMemo<
    (keyof CreateOrEditNewJobFormSchema)[]
  >(() => {
    const hiddenFields: (keyof CreateOrEditNewJobFormSchema)[] = [
      'locationGuid',
      'pointOfContactGuid',
    ]

    if (disableCreateNewAppointment) {
      hiddenFields.push('shouldCreateAppointmentAfterwards')
    }
    return hiddenFields
  }, [disableCreateNewAppointment])

  const getPrimaryComponentExistingAccount = () => {
    switch (currentStep) {
      case 0:
        return <div>{NewOrExistingOptionsNode}</div>
      case 1:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Link an existing account for this job"
            />
            <AccountSearchAutocomplete
              currentAccount={currentAccount}
              setCurrentAccount={setCurrentAccountAndAdvance}
            />
          </div>
        )
      case 2:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Choose the contact & location for this job"
            />
            {currentAccount && (
              <LinkedInformation
                account={currentAccount}
                refetchAccount={accountQuery.refetch}
                selectedContactGuid={selectedContactGuid}
                setSelectedContactGuid={setSelectedContactGuid}
                selectedLocationGuid={selectedLocationGuid}
                setSelectedLocationGuid={setSelectedLocationGuid}
              />
            )}
          </div>
        )
      case 3:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Add the job details and information"
            />
            <CreateOrEditJobForm
              formMode="create"
              creatingJobForNewAccount={false}
              companyGuid={companyGuid}
              account={currentAccount}
              onCancel={() => setIsOpen(false)}
              flexRowSpaceX="space-x"
              labelClassName="semibold_14_22 grey9"
              hiddenFields={hiddenJobCreationFields}
              initialValues={{
                pointOfContactGuid: selectedContactGuid,
                locationGuid: selectedLocationGuid,
                jobTypeGuid:
                  selectedJobTypeGuid ??
                  (jobLead && jobLead.jobType
                    ? tryMatchJobType(selectableJobTypes, jobLead.jobType)
                        ?.jobTypeGuid
                    : undefined),
                linkedJobGuid: preselectedLinkedJobGuid,
                shouldCreateAppointmentAfterwards: true,
                leadSourceGuid: preselectedLeadSourceGuid,
                summary: jobLead?.jobSummary,
              }}
              showDivider={false}
              createOrEditJobForm={createOrEditNewJobForm}
              showFormCancelSubmitButtons={false}
              jobTypes={selectableJobTypes}
              jobTags={tagsQuery.data?.tags ?? []}
              leadSources={fetchCompanyLeadSourcesQuery?.data ?? []}
              linkedJobGuid={preselectedLinkedJobGuid}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  const getPrimaryComponentNewAccount = () => {
    switch (currentStep) {
      case 0:
        return <div>{NewOrExistingOptionsNode}</div>
      case 1:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Create the point of contact for this account"
            />
            <EmbeddedContactInformationForm
              extendedForm={createNewPrimaryContactForm}
            />
          </div>
        )
      case 2:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Create the service location for this job"
            />
            <CreateOrEditNewAccountLocationForm
              showDivider={false}
              hideDisplayNameField
              showCancelSubmitButtons={false}
              flexRowSpaceX="space-x"
              labelClassName="semibold_14_22 grey9"
              extendedForm={createNewLocationForm}
            />
          </div>
        )
      case 3:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Create the new account for this job"
            />
            <CreateNewAccountForm
              mode="embedded-create"
              extendedForm={createNewAccountForm}
              companyGuid={companyGuid}
              hiddenFields={[
                'leadSourceGuid',
                'leadSourceReferringContactGuid',
                'leadSourceAttributionDescription',
              ]}
              initialValues={{
                accountDisplayName: `${createNewPrimaryContactForm.getFieldValue(
                  'firstName',
                )} ${createNewPrimaryContactForm.getFieldValue(
                  'lastName',
                )} - ${createNewLocationForm.getFieldValue('addressLineOne')}`,
                accountManagerUserGuid: defaultCompanyAccountManagerUserGuid,
                leadSourceGuid: preselectedLeadSourceGuid,
              }}
              accountTags={tagsQuery.data?.tags ?? []}
            />
          </div>
        )
      case 4:
        return (
          <div className="flex flex-col space-y-6">
            <CreationStepHeading
              heading={heading}
              subheading="Add the job details and information"
            />
            <CreateOrEditJobForm
              formMode="create"
              creatingJobForNewAccount
              companyGuid={companyGuid}
              account={currentAccount}
              onCancel={() => setIsOpen(false)}
              flexRowSpaceX="space-x"
              labelClassName="semibold_14_22 grey9"
              // A new account wont have a maintenance plan, so hide the checkbox
              hiddenFields={hiddenJobCreationFields.concat([
                'useMaintenancePlanCredit',
                'maintenancePlanVisitGuid',
              ])}
              initialValues={{
                pointOfContactGuid: selectedContactGuid,
                locationGuid: selectedLocationGuid,
                shouldCreateAppointmentAfterwards: true,
                linkedJobGuid: preselectedLinkedJobGuid,
                leadSourceGuid: preselectedLeadSourceGuid,
                jobTypeGuid:
                  selectedJobTypeGuid ??
                  (jobLead && jobLead.jobType
                    ? tryMatchJobType(selectableJobTypes, jobLead.jobType)
                        ?.jobTypeGuid
                    : undefined),
                summary: jobLead?.jobSummary,
              }}
              showDivider={false}
              createOrEditJobForm={createOrEditNewJobForm}
              showFormCancelSubmitButtons={false}
              jobTypes={selectableJobTypes}
              jobTags={tagsQuery.data?.tags ?? []}
              leadSources={fetchCompanyLeadSourcesQuery?.data ?? []}
              linkedJobGuid={preselectedLinkedJobGuid}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  const getPrimaryComponent = () => {
    if (createAccount) {
      return getPrimaryComponentNewAccount()
    } else {
      return getPrimaryComponentExistingAccount()
    }
  }

  const getSidebarItemsNewAccount = () => {
    const contactNode: ReactNode =
      (currentStep > 1 && (
        <SidebarContact
          firstName={createNewPrimaryContactForm.getFieldValue('firstName')}
          lastName={createNewPrimaryContactForm.getFieldValue('lastName')}
          phoneNumber={createNewPrimaryContactForm.getFieldValue('phoneNumber')}
          emailAddress={createNewPrimaryContactForm.getFieldValue(
            'emailAddress',
          )}
          onEdit={() => setCurrentStep(1)}
          hideDivider
        />
      )) ||
      null

    const locationNode: ReactNode =
      (currentStep > 2 && (
        <SidebarLocation
          displayName={createNewLocationForm.getFieldValue('displayName')}
          address={{
            line1: createNewLocationForm.getFieldValue('addressLineOne'),
            line2: createNewLocationForm.getFieldValue('addressLineTwo'),
            city: createNewLocationForm.getFieldValue('addressCity'),
            stateAbbreviation:
              getStateAbbreviationOrUndefined(
                createNewLocationForm.getFieldValue('addressState'),
              ) ?? '',
            zipCode: createNewLocationForm.getFieldValue('addressZipCode'),
          }}
          onEdit={() => setCurrentStep(2)}
          hideDivider
        />
      )) ||
      null

    const accountNode: ReactNode =
      (currentStep > 3 && (
        <SidebarAccount
          displayName={createNewAccountForm.getFieldValue('displayName')}
          accountType={
            createNewAccountForm.getFieldValue('accountType') as AccountType
          }
          onEdit={() => setCurrentStep(3)}
          hideDivider
        />
      )) ||
      null

    const jobLeadsNode: ReactNode =
      (jobLead && currentStep > 0 && (
        <div className="mt-2 space-y-2">
          <div className="semibold_16_24 grey9">Job Lead Info</div>
          <JobLeadContextView jobLead={jobLead} />
        </div>
      )) ||
      null

    return (
      <div className="flex flex-col">
        <DividerWrapper divider={<Divider className="mb-2 mt-3" />}>
          {contactNode}
          {locationNode}
          {accountNode}
          {jobLeadsNode}
        </DividerWrapper>
      </div>
    )
  }

  const getSidebarItemsExistingAccount = () => {
    const currentAccountContact = currentAccount?.accountContacts.find(
      accountContact =>
        accountContact.contact.contactGuid === selectedContactGuid,
    )
    const currentAccountLocation = currentAccount?.accountLocations.find(
      accountLocation =>
        accountLocation.location.locationGuid === selectedLocationGuid,
    )

    const contactNode: ReactNode =
      (currentAccountContact && (
        <SidebarItem
          sectionLabel="Point of Contact"
          contentList={[
            {
              key: 'Name',
              value: `${currentAccountContact.contact.firstName} ${currentAccountContact.contact.lastName}`,
            },
            {
              key: 'Email',
              value: currentAccountContact.contact.primaryEmailAddress
                ?.emailAddress ? (
                `${currentAccountContact.contact.primaryEmailAddress.emailAddress}`
              ) : (
                <EmDash />
              ),
            },
            {
              key: 'Phone',
              value: currentAccountContact.contact.primaryPhoneNumber
                ?.phoneNumber ? (
                `${currentAccountContact.contact.primaryPhoneNumber.phoneNumber}`
              ) : (
                <EmDash />
              ),
            },
          ]}
          onEdit={!preselectedAccountGuid ? () => setCurrentStep(1) : undefined}
          hideDivider
        />
      )) ||
      null

    const locationNode: ReactNode =
      (currentAccountLocation && (
        <SidebarItem
          sectionLabel="Service Location"
          contentList={[
            {
              key: 'Address',
              value: `${BzAddress.formatAddressSingleLine(
                currentAccountLocation?.location.address,
              )}`,
            },
          ]}
          onEdit={
            !preselectedLocationGuid ? () => setCurrentStep(2) : undefined
          }
          hideDivider
        />
      )) ||
      null

    const jobLeadsNode: ReactNode =
      (jobLead && currentStep > 0 && (
        <div className="mt-2 space-y-2">
          <div className="semibold_16_24 grey9">Job Lead Info</div>
          <JobLeadContextView jobLead={jobLead} />
        </div>
      )) ||
      null

    return (
      <div className="flex flex-col">
        <DividerWrapper divider={<Divider className="mb-2 mt-3" />}>
          {contactNode}
          {locationNode}
          {jobLeadsNode}
        </DividerWrapper>
      </div>
    )
  }

  const getSidebarItems = () => {
    if (createAccount) {
      return getSidebarItemsNewAccount()
    } else {
      return getSidebarItemsExistingAccount()
    }
  }

  return (
    <>
      {isLoading && (
        <div className="center-children-vh h-full min-h-[500px] w-full">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <ProgressiveFullScreenModalStepper
          steps={
            preselectedAccountGuid && preselectedLocationGuid
              ? existingLocationSteps
              : createAccount
              ? createAccountSteps
              : existingAccountSteps
          }
          // The stepper current step is offset by way to account for an interstitial screen that makes the user select
          // which stepper to take. This is before the stepper UI is rendered so its passed -1 value for the current
          // step when it initializes. As such, we adjust for that in the goToStep and preprocess the result so the rest
          // of the code in our component can be insulated from this oddity ¯\_(ツ)_/¯
          currentStep={currentStep - 1}
          goToStep={(from, to) => setCurrentStepGuarded(from + 1, to + 1)}
          canProceed={canProgressToNextStep}
          canReverse={canReverseToPreviousStep}
          onFinish={handleCreateJobFormSubmit}
          hidePreviousNextButtons={currentStep === 0}
          hideSteps={currentStep === 0}
          finishActionButtonText="Create Job"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          sidebarTitle="Job Details"
          sidebarContent={currentStep === 0 ? null : getSidebarItems()}
          creationModal={true}
        >
          {getPrimaryComponent()}
        </ProgressiveFullScreenModalStepper>
      )}
    </>
  )
}

export default ProgressiveJobCreationModal
