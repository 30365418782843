import { createClient } from 'graphql-ws'
import { subscriptionExchange } from 'urql'

import { isNullish } from '@breezy/shared'
import { getConfig } from '../config'

const config = getConfig()

const getWsClient = (graphQLAccessToken?: string) =>
  createClient({
    url: config.querierWsApiUrl,
    // repeat indefinitely
    retryAttempts: Infinity,
    retryWait: async (retries: number) => {
      // retry backoff to 5s, then repeat
      const delayMs = Math.min((retries + 1) * 400, 5000)

      await new Promise(resolve => setTimeout(resolve, delayMs))
    },
    connectionParams: {
      // if an authorization header passed when it's set to jwt auth, it'll
      // infer that the user is unauthenticated and set the hasura role to the HASURA_GRAPHQL_UNAUTHORIZED_ROLE
      // env var we set in our hasura docker container
      // https://hasura.io/docs/latest/auth/authentication/unauthenticated-access/
      headers: !isNullish(graphQLAccessToken)
        ? {
            authorization: `Bearer ${graphQLAccessToken}`,
          }
        : {},
    },
    // should always retry so the client reconnects during deployments
    shouldRetry: () => true,
  })

export const getUrqlSubscriptionExchange = (graphQLAccessToken?: string) => {
  const wsClient = getWsClient(graphQLAccessToken)

  return subscriptionExchange({
    forwardSubscription: request => {
      return {
        subscribe: sink => {
          const input = { ...request, query: request.query || '' }
          const dispose = wsClient.subscribe(input, sink)

          return {
            unsubscribe: dispose,
          }
        },
      }
    },
  })
}

export const useUrqlSubscriptionExchange = (graphQLAccessToken?: string) => {
  return getUrqlSubscriptionExchange(graphQLAccessToken)
}
