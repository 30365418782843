import { Auth0Provider } from '@auth0/auth0-react'
import { getConfig } from '../config'

const config = getConfig()

type Props = {
  children: JSX.Element
}

export default function AuthWrapper(props: Props) {
  const domain = config.auth0Domain
  const clientId = config.auth0ClientId
  const audience = config.auth0Audience

  if (!domain || !clientId || !audience) {
    throw new Error(
      `Auth Misconfiguration: Expected domain, clientId, and audience to be set in conf`,
    )
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
    >
      {props.children}
    </Auth0Provider>
  )
}
