import {
  BzLinks,
  LinkedNoteViewModel,
  NoteLinkData,
  R,
  isNullish,
  isNullishOrEmpty,
  nextGuid,
} from '@breezy/shared'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPaperclip, IconPhoto } from '@tabler/icons-react'
import { Button, Select } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { trpc } from 'src/hooks/trpc'
import { FilePickerDrawer } from '../FilePickerDrawer/FilePickerDrawer'
import PhotoPickerDrawer from '../PhotoPickerDrawer/PhotoPickerDrawer'
import TrpcQueryLoader from '../TrpcQueryLoader'
import { WysiwygEditor } from '../WysiwygEditor/WysiwygEditor'

type NoteFormProps = {
  linkData: NoteLinkData
  onSuccess?: () => unknown
  onError?: () => unknown
  onLoadingChange?: (loading: boolean) => void
  addText?: string
  note?: LinkedNoteViewModel
  editable?: boolean
}

export const NoteForm = React.memo<NoteFormProps>(
  ({
    onSuccess,
    onError,
    linkData,
    onLoadingChange,
    addText = 'Add Note',
    note,
    editable = true,
  }) => {
    const noteGuid = note?.noteGuid || nextGuid()
    const [value, updateValue] = useState(note?.value || '')
    const [taggedUsers, setTaggedUsers] = useState<string[]>(
      note ? [...note.taggedUsers] : [],
    )

    const usersQuery = trpc.user['users:get'].useQuery()

    const userOptions = useMemo(
      () =>
        R.sort(
          R.ascend(option => option.label),
          (usersQuery.data?.users || [])
            .filter(user => !user.deactivatedAt)
            .map(user => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.userGuid,
            })),
        ),
      [usersQuery.data?.users],
    )

    const builtLinkData: BzLinks = useMemo(
      () => ({
        locationGuid:
          linkData.primaryNoteType === 'LOCATION'
            ? linkData.locationGuid
            : undefined,
        accountGuid:
          linkData.primaryNoteType === 'ACCOUNT'
            ? linkData.accountGuid
            : undefined,
        jobGuid:
          linkData.primaryNoteType === 'JOB' ? linkData.jobGuid : undefined,
        apptAssignmentGuid:
          linkData.primaryNoteType === 'ASSIGNMENT'
            ? linkData.apptAssignmentGuid
            : undefined,
        jobAppointmentGuid:
          linkData.primaryNoteType === 'JOB_APPOINTMENT'
            ? linkData.jobAppointmentGuid
            : undefined,
      }),
      [linkData],
    )

    const canAddPhotosOrFiles = useMemo(() => {
      // This is very ugly, but sometimes we get passed a maintenance guid and
      // those don't allow any photos/files to be uploaded to them.
      // Someone please refactor when you can lmao
      return (
        !isNullishOrEmpty(builtLinkData.locationGuid) ||
        !isNullishOrEmpty(builtLinkData.accountGuid) ||
        !isNullishOrEmpty(builtLinkData.jobGuid) ||
        !isNullishOrEmpty(builtLinkData.apptAssignmentGuid) ||
        !isNullishOrEmpty(builtLinkData.jobAppointmentGuid)
      )
    }, [builtLinkData])

    const queryPhotos = trpc.photos['photos:get-by-links'].useQuery(
      builtLinkData,
      {
        enabled: canAddPhotosOrFiles,
      },
    )
    const queryFiles = trpc.files['files:get-by-links'].useQuery(
      builtLinkData,
      {
        enabled: canAddPhotosOrFiles,
      },
    )
    const mutateNote = trpc.notes['notes:put-linked'].useMutation()
    const [selectedPhotos, setSelectedPhotos] = useState<
      { photoGuid: string; cdnUrl: string }[]
    >(
      note?.noteAttachments
        ?.filter(attachment => !isNullish(attachment.photo))
        .map(attachment => ({
          // These will always be valid from the filter, so this should be fine
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          photoGuid: attachment.photo!.photoGuid,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          cdnUrl: attachment.photo!.cdnUrl,
        })) ?? [],
    )
    const [selectedFiles, setSelectedFiles] = useState<
      { fileGuid: string; cdnUrl: string; fileName: string }[]
    >(
      note?.noteAttachments
        ?.filter(attachment => !isNullish(attachment.file))
        .map((attachment, idx) => ({
          // These will always be valid from the filter, so this should be fine
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fileGuid: attachment.file!.fileGuid,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          cdnUrl: attachment.file!.cdnUrl,
          fileName: `Attachment ${idx}`,
        })) ?? [],
    )

    const onSubmit = useCallback(() => {
      onLoadingChange?.(true)
      mutateNote.mutate(
        {
          linkData,
          noteGuid,
          value,
          taggedUsers,
          attachments: [
            ...selectedPhotos.map(photo => ({
              photoGuid: photo.photoGuid,
              fileGuid: null,
            })),
            ...selectedFiles.map(file => ({
              photoGuid: null,
              fileGuid: file.fileGuid,
            })),
          ],
        },
        {
          onSuccess: () => {
            onSuccess?.()
            onLoadingChange?.(false)
            updateValue('')
            setTaggedUsers([])
            setSelectedPhotos([])
            setSelectedFiles([])
          },
          onError: () => {
            onError?.()
            onLoadingChange?.(false)
          },
        },
      )
    }, [
      linkData,
      mutateNote,
      onError,
      onLoadingChange,
      onSuccess,
      taggedUsers,
      value,
      noteGuid,
      selectedPhotos,
      selectedFiles,
    ])

    const [photoDrawerOpen, setPhotoDrawerOpen] = useState(false)
    const [fileDrawerOpen, setFileDrawerOpen] = useState(false)

    const wysiwygEditorCustomControls = useMemo(() => {
      const controls = []
      if (canAddPhotosOrFiles) {
        controls.push({
          onClick: () => {
            queryPhotos.refetch()
            setPhotoDrawerOpen(true)
          },
          icon: <IconPhoto stroke={1.5} size="1rem" />,
          label: 'Add Photo',
        })

        controls.push({
          onClick: () => {
            queryFiles.refetch()
            setFileDrawerOpen(true)
          },
          icon: <IconPaperclip stroke={1.5} size="1rem" />,
          label: 'Add File',
        })
      }

      return controls
    }, [canAddPhotosOrFiles, queryFiles, queryPhotos])

    return (
      <div>
        <WysiwygEditor
          value={value}
          customControls={wysiwygEditorCustomControls}
          onChange={updateValue}
          disabled={!editable}
        />

        <div className="mb-3 mt-3 flex items-center justify-between">
          {linkData.primaryNoteType === 'PHOTO' ? (
            // Makes the justify-between happy
            <span />
          ) : (
            <Select
              className="mr-2 flex-1"
              mode="multiple"
              allowClear
              placeholder="Tag users in this note"
              onChange={setTaggedUsers}
              value={taggedUsers}
              options={userOptions}
              optionFilterProp="label"
            />
          )}
          <Button
            className="account-notes-list-card-add-note-button"
            type="primary"
            onClick={onSubmit}
            disabled={value.length < 1}
          >
            {addText}
          </Button>
        </div>

        <div className="flex flex-row space-x-2 overflow-x-auto">
          {selectedPhotos?.map(photo => (
            <img
              key={photo.photoGuid}
              src={photo.cdnUrl}
              alt="attachment"
              className="h-32 w-32 flex-auto flex-shrink-0 flex-grow-0 rounded-sm object-contain"
            />
          ))}
        </div>
        <div className="flex flex-row space-x-2 overflow-x-auto">
          {selectedFiles?.map(file => (
            <div key={file.fileGuid}>
              <FontAwesomeIcon icon={faPaperclip} />
              <span className="ml-1">{file.fileName}</span>
            </div>
          ))}
        </div>

        <TrpcQueryLoader
          query={queryPhotos}
          loadingComponent={<></>}
          render={data => (
            <PhotoPickerDrawer
              photos={data}
              photosSelected={selectedPhotos}
              linkData={linkData}
              onPhotosSelected={setSelectedPhotos}
              onClose={
                photoDrawerOpen ? () => setPhotoDrawerOpen(false) : undefined
              }
              onPhotosUpdated={() => queryPhotos.refetch()}
            />
          )}
        />

        <TrpcQueryLoader
          query={queryFiles}
          loadingComponent={<></>}
          render={data => (
            <FilePickerDrawer
              files={data}
              filesSelected={selectedFiles}
              onFilesUpdated={() => queryFiles.refetch()}
              onFilesSelected={setSelectedFiles}
              linkData={builtLinkData}
              onClose={
                fileDrawerOpen ? () => setFileDrawerOpen(false) : undefined
              }
            />
          )}
        />
      </div>
    )
  },
)
