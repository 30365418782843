import { z } from 'zod'
import { Event, EventInputDto, HtmlString, usCentsToUsd, usdToUsCents } from '../../../common'
import { guidSchema, htmlStringSchema } from '../../../contracts/_common'
import { AccountGuid, AccountGuidContainer } from '../../Accounts/Account'
import { ActingCompanyUserIds, CompanyGuid } from '../../Company/Company'
import { DiscountType } from '../../Discounts/DiscountTypes'
import { PricebookTaxRateGuid } from '../../Pricebook/PricebookTypes'
import { ForUser } from '../../Users/User'
import { Guid, bzOptional } from '../../common-schemas'
import { EstimateGuid } from '../Estimates/EstimateTypes'
import { ConcreteFinanceDocumentType } from '../FinanceDocumentTypes'
import { InvoiceGuid } from '../Invoicing/InvoiceTypes'
import { UpchargeType } from '../Upcharges/UpchargeTypes'
import { calculateCartOrderSummaryUsc } from './TransactionFunctionsUsc'
import { CartCalculationSummary, CartSubtotalSummary, withTotalPriceUsc } from './utils'

export const transactionGuidRequestSchema = z.object({
  companyGuid: guidSchema,
  transactionGuid: guidSchema,
})

export type TransactionGuid = Guid
export type TransactionGuidContainer = {
  readonly transactionGuid: TransactionGuid
}
export const TransactionEntityTypeName = 'Transaction' as const

export type CreditSetEventData = {
  creditGuid: Guid
  creditDisplayName: string
  creditMaxAmountUsc?: number
  creditOriginEntityType?: string
  creditOriginEntityId?: string
}

export type CreditSetEvent = Event<CreditSetEventData>
export const CreditSetEventTypeName = 'CreditSet' as const

export const creditSetEventDataSchema = z.object({
  creditGuid: guidSchema,
  creditMaxAmountUsc: bzOptional(z.number().min(0)),
  creditDisplayName: z.string(),
  creditOriginEntityType: bzOptional(z.string()),
  creditOriginEntityId: bzOptional(z.string()),
})

/** @deprecated */
export type ComprehensiveTransactionViewModel = TransactionMetadata &
  Cart &
  CartOrderSummary &
  TransactionLinks &
  TransactionGeneratedDocuments & {
    targetDocumentType: ConcreteFinanceDocumentType
    originatingUserGuid: Guid
    summary?: string
    displayName?: string
    discountsV2: DiscountV2EventData[]
  }

/** @deprecated */
export type TransactionMetadata = {
  readonly transactionGuid: TransactionGuid
  readonly transactionVersion: number
  readonly companyGuid: CompanyGuid
  readonly accountGuid: AccountGuid
}

/** @deprecated */
export type TransactionGeneratedDocuments = {
  readonly childEstimateGuids?: EstimateGuid[]
  readonly childInvoiceGuids?: InvoiceGuid[]
}

/** @deprecated */
export type TransactionLinkMap = {
  jobGuid?: Guid
  jobAppointmentGuid?: Guid
  locationGuid?: Guid
  estimateGuid?: Guid
  estimateV2Guid?: Guid
  maintenancePlanGuid?: Guid
  paymentSubscriptionGuid?: Guid
}

/** @deprecated */
export type TransactionLinks = {
  links: TransactionLinkMap
}

export const CartNoDiscount: DiscountSetEventData = {
  type: DiscountType.FLAT,
  discountAmountUsd: 0,
  discountRate: null,
}

/** @deprecated Use CartUsc instead */
export type Cart = {
  items: CartItem[]
  discount: DiscountSetEventData
  credit?: CreditSetEventData
  taxRate: TaxRateSetEventData
}

export type CartUsc = {
  items: CartItemUsc[]
  discount: DiscountSetEventDataUsc
  upcharge?: UpchargeSetEventDataUsc
  credit?: CreditSetEventData
  taxRate: TaxRateSetEventData
}

export enum CartItemType {
  SERVICE = 'SERVICE',
  MATERIAL = 'MATERIAL',
  EQUIPMENT = 'EQUIPMENT',
  LABOR = 'LABOR',
  MEMBERSHIP = 'MEMBERSHIP',
  CREDIT = 'CREDIT',
  UNKNOWN = 'UNKNOWN',
}

export const CartItemTypeDisplayNames = {
  [CartItemType.SERVICE]: 'Service',
  [CartItemType.MATERIAL]: 'Material',
  [CartItemType.EQUIPMENT]: 'Equipment',
  [CartItemType.LABOR]: 'Labor',
  [CartItemType.MEMBERSHIP]: 'Membership',
  [CartItemType.CREDIT]: 'Credit',
  [CartItemType.UNKNOWN]: 'Other',
} as const

export type CartItemDisplayNamesType = (typeof CartItemTypeDisplayNames)[keyof typeof CartItemTypeDisplayNames]

export const displayNameForCartItemType = (cartItemType: CartItemType) => {
  switch (cartItemType) {
    case CartItemType.SERVICE:
      return 'Service'
    case CartItemType.MATERIAL:
      return 'Material'
    case CartItemType.EQUIPMENT:
      return 'Equipment'
    case CartItemType.LABOR:
      return 'Labor'
    case CartItemType.MEMBERSHIP:
      return 'Membership'
    case CartItemType.CREDIT:
      return 'Credit'
    case CartItemType.UNKNOWN:
      return 'Other'
  }
}

/* @deprecated Use CartOrderSummaryUsc instead */
export type CartOrderSummary = {
  subtotalPriceUsd: number
  creditAmountUsd: number
  totalPriceUsd: number
  discountAmountUsd: number
  taxAmountUsd: number
}

export type CartOrderSummaryUsc = {
  subtotalPriceUsc: number
  creditAmountUsc: number
  totalPriceUsc: number
  discountAmountUsc: number
  upchargeAmountUsc: number
  taxAmountUsc: number
}

export type CartItemGuid = Guid
export type CartItemGuidContainer = {
  itemGuid: CartItemGuid
}

const BaseCartItemSchema = z.object({
  itemGuid: guidSchema,
  name: z.string(),
  description: htmlStringSchema,
  quantity: z.number().gt(0),
  isTaxable: z.boolean(),
  isDiscountable: z.boolean(),
  itemType: z.nativeEnum(CartItemType),
  photoGuid: bzOptional(guidSchema),
  photoCdnUrl: bzOptional(z.string()),
})

export const CartItemSchema = BaseCartItemSchema.extend({
  unitPriceUsd: z.number().min(0),
})
export const CartItemUscSchema = BaseCartItemSchema.extend({
  unitPriceUsc: z.number().min(0),
})

/** @deprecated Use CartItemUsc instead */
export type CartItem = z.infer<typeof CartItemSchema>
export type CartItemUsc = z.infer<typeof CartItemUscSchema>

export const cartItemToCartItemUsc = ({ unitPriceUsd, ...rest }: CartItem): CartItemUsc => ({
  unitPriceUsc: usdToUsCents(unitPriceUsd),
  ...rest,
})
export const cartItemUscToCartItem = ({ unitPriceUsc, ...rest }: CartItemUsc): CartItem => ({
  unitPriceUsd: usCentsToUsd(unitPriceUsc),
  ...rest,
})

/** @deprecated */
export type TransactionCreatedEventData = TaxRateSetEventData &
  AccountGuidContainer &
  TransactionLinks & { items?: CartItem[]; credit?: CreditSetEventData } & {
    transactionGuid?: TransactionGuid
    targetDocumentType: ConcreteFinanceDocumentType
  }

/** @deprecated */
export type TransactionCreatedInput = TransactionCreatedEventData & ActingCompanyUserIds

/** @deprecated */
export const TransactionLinksMapSchema = z.object({
  jobGuid: bzOptional(guidSchema),
  jobAppointmentGuid: bzOptional(guidSchema),
  locationGuid: bzOptional(guidSchema),
  estimateGuid: bzOptional(guidSchema),
  maintenancePlanGuid: bzOptional(guidSchema),
  paymentSubscriptionGuid: bzOptional(guidSchema),
})

/** @deprecated */
export const TransactionCreatedInputDto = z.object({
  companyGuid: guidSchema,
  taxRateGuid: guidSchema,
  accountGuid: guidSchema,
  rate: z.number().min(0).max(1),
  links: TransactionLinksMapSchema,
  items: bzOptional(z.array(CartItemSchema)),
  credit: bzOptional(creditSetEventDataSchema),
  targetDocumentType: z.enum(['Estimate', 'Invoice']),
})

/** @deprecated */
export type TransactionCreatedEvent = Event<TransactionCreatedEventData>

/** @deprecated */
export const TransactionCreatedEventTypeName = 'TransactionCreated' as const

// NOTE: This is copied because it's a hard schema for external data
export type CartItemSetEventData = {
  itemGuid: CartItemGuid
  name: string
  description: HtmlString
  quantity: number
  unitPriceUsd: number
  isTaxable: boolean
  isDiscountable: boolean
  itemType: CartItemType
}
export type CartItemSetEvent = Event<CartItemSetEventData>
export const CartItemSetEventTypeName = 'CartItemSet' as const

export const CartItemSetInputEventDto = EventInputDto.extend({
  eventData: z.object({
    itemGuid: guidSchema,
    name: z.string(),
    description: htmlStringSchema,
    quantity: z.number().gt(0),
    unitPriceUsd: z.number(),
    isTaxable: z.boolean(),
    isDiscountable: z.boolean(),
    itemType: z.nativeEnum(CartItemType),
  }),
})

export type CartItemSetInputEventDtoType = z.infer<typeof CartItemSetInputEventDto>

export type CartItemRemovedEvent = Event<Guid>
export const CartItemRemovedEventTypeName = 'CartItemRemoved' as const

export const CartItemRemovedInputEventDto = EventInputDto.extend({
  eventData: guidSchema,
})

const singleDiscountGuid = '00000000-0000-1111-aaaa-000000000000'

export const toV2Discount = (discount: DiscountSetEventData): DiscountV2EventData => {
  if (discount.type === DiscountType.RATE) {
    return {
      type: DiscountType.RATE,
      discountRate: discount.discountRate,
      discountGuid: singleDiscountGuid,
      displayName: `Rate Discount`,
      description: `Rate Discount`,
    }
  }
  return {
    type: DiscountType.FLAT,
    discountAmountUsd: discount.discountAmountUsd,
    discountGuid: singleDiscountGuid,
    displayName: `Flat Discount`,
    description: `Flat Discount`,
  }
}

// For Multi-Discounts
export type DiscountV2EventData = DiscountSetEventData & {
  discountGuid: Guid
  displayName: string
  description: HtmlString
}

export const DiscountV2UpsertInputSchema = EventInputDto.extend({
  discountGuid: guidSchema,
  displayName: z.string(),
  description: htmlStringSchema,
  type: z.nativeEnum(DiscountType),
  value: z.number().min(0),
})

export type DiscountV2UpsertInput = z.infer<typeof DiscountV2UpsertInputSchema>

export const DiscountV2RemoveInputSchema = EventInputDto.extend({
  discountGuid: guidSchema,
})

export type DiscountV2RemoveInput = z.infer<typeof DiscountV2RemoveInputSchema>

/** @deprecated Use DiscountSetEventDataUsc instead */
// NOTE: This is copied because it's a hard schema for external data
export type DiscountSetEventData =
  | {
      type: DiscountType.FLAT
      discountAmountUsd: number
      discountRate?: null
    }
  | {
      type: DiscountType.RATE
      discountRate: number
      discountAmountUsd?: null
    }

export type DiscountSetEventDataUsc =
  | {
      type: DiscountType.FLAT
      discountAmountUsc: number
      discountRate?: undefined
    }
  | {
      type: DiscountType.RATE
      discountRate: number
      discountAmountUsc?: undefined
    }

export type UpchargeSetEventDataUsc = {
  type: UpchargeType.FLAT
  upchargeAmountUsc: number
}

export type CartDiscountSetEvent = Event<DiscountSetEventData>
export const CartDiscountSetEventTypeName = 'CartDiscountSet' as const

export type CartDiscountV2UpsertEvent = Event<DiscountV2EventData>
export const CartDiscountV2UpsertEventTypeName = 'CartDiscountV2Upserted' as const

export type DiscountGuidContainer = {
  discountGuid: Guid
}
export type CartDiscountV2RemovedEvent = Event<DiscountGuidContainer>
export const CartDiscountV2RemovedEventTypeName = 'CartDiscountV2Removed' as const

export type TaxRateSetEventData = {
  taxRateGuid: PricebookTaxRateGuid
  rate: number
}

export type TaxRateSetEvent = Event<TaxRateSetEventData>
export const TaxRateSetEventTypeName = 'TaxRateSet' as const

export const TaxRateSetInputDto = EventInputDto.extend({
  eventData: z.object({
    taxRateGuid: guidSchema,
    rate: z.number().min(0).max(1),
  }),
})

/** @deprecated */
export type TransactionInvoiceCreatedEventData = {
  invoiceGuid: InvoiceGuid
  invoiceVersion: number
  referenceNumber: string
}
/** @deprecated */
export const InvoiceCreatedEventTypeName = 'InvoiceCreated' as const

/** @deprecated */
export type EstimateCreatedFromTransactionEventData = {
  estimateGuid: EstimateGuid
  referenceNumber: string
  summary?: string
  displayName?: string
}
/** @deprecated */
export const EstimateCreatedFromTransactionEventTypeName = 'EstimateCreated' as const
/** @deprecated */
export type EstimateCreatedFromTransactionEvent = Event<EstimateCreatedFromTransactionEventData>

/** @deprecated */
export type LinkedEntityItem = {
  guidType: keyof TransactionLinkMap
  guid: Guid
}
/** @deprecated */
export type LinkedEntitiesEventData = {
  entities: LinkedEntityItem[]
}
/** @deprecated */
export const LinkAddedEventTypeName = 'LinkAdded' as const
/** @deprecated */
export const LinkRemovedEventTypeName = 'LinkRemoved' as const

/** @deprecated */
export type LinkAddedEvent = Event<LinkedEntitiesEventData>
/** @deprecated */
export type LinkRemovedEvent = Event<LinkedEntitiesEventData>

/** @deprecated */
export type EventEntityIDs = {
  entityGuid: Guid
  entityType: string
}

export const calculateCartOrderSummary = (cart: Cart) =>
  convertCartOrderSummaryUscToCartOrderSummaryUsd(calculateCartOrderSummaryUsc(convertCartUsdToCartUsc(cart)))

export const convertCartUsdToCartUsc = (cart: Cart): CartUsc => ({
  items: cart.items.map(i => ({
    ...i,
    unitPriceUsc: usdToUsCents(i.unitPriceUsd),
  })),
  discount:
    cart.discount.type === DiscountType.RATE
      ? { ...cart.discount, discountAmountUsc: undefined }
      : {
          ...cart.discount,
          discountRate: undefined,
          discountAmountUsc: usdToUsCents(cart.discount.discountAmountUsd),
        },
  taxRate: cart.taxRate,
  credit: cart.credit,
})

export const convertCartOrderSummaryUscToCartOrderSummaryUsd = (cart: CartOrderSummaryUsc): CartOrderSummary => ({
  subtotalPriceUsd: usCentsToUsd(cart.subtotalPriceUsc),
  creditAmountUsd: usCentsToUsd(cart.creditAmountUsc),
  totalPriceUsd: usCentsToUsd(cart.totalPriceUsc),
  discountAmountUsd: usCentsToUsd(cart.discountAmountUsc),
  taxAmountUsd: usCentsToUsd(cart.taxAmountUsc),
})

export const calculateCartTaxCents = (
  taxRate: TaxRateSetEventData,
  subtotals: CartSubtotalSummary,
): CartCalculationSummary => {
  const { taxNonDiscSubtotalUsc, taxDiscSubtotalUsc } = subtotals
  const taxAmountNonDiscountableUsc = Math.ceil(taxNonDiscSubtotalUsc * taxRate.rate)
  const taxAmountDiscountableUsc = Math.ceil(taxDiscSubtotalUsc * taxRate.rate)

  return withTotalPriceUsc({
    ...subtotals,
    taxAmountUsc: taxAmountNonDiscountableUsc + taxAmountDiscountableUsc,
    taxNonDiscSubtotalUsc: taxNonDiscSubtotalUsc + taxAmountNonDiscountableUsc,
    taxDiscSubtotalUsc: taxDiscSubtotalUsc + taxAmountDiscountableUsc,
  })
}

export interface ICartEntity {
  setItem: (input: ForUser<CartItemSetEventData>) => Promise<void>
  removeItem: (input: ForUser<CartItemGuidContainer>) => Promise<void>
  /** @deprecated Use upsertDiscountV2 and removeDiscountV2 instead */
  setDiscount: (input: ForUser<DiscountSetEventData>) => Promise<void>
  upsertDiscountV2: (input: ForUser<DiscountV2EventData>) => Promise<void>
  removeDiscountV2: (input: ForUser<DiscountGuidContainer>) => Promise<void>
}

/**
 * Fires when the Summary has been set.
 */
/** @deprecated */
export type TransactionSummarySetEvent = Event<TransactionSummarySetEventData>
/** @deprecated */
export type TransactionSummarySetEventData = { readonly summary?: string }
export const TransactionSummarySetEventTypeName = 'TransactionSummarySet' as const
export const isTransactionSummarySetEvent = (event: Event<unknown>): event is TransactionSummarySetEvent =>
  event.eventType === TransactionSummarySetEventTypeName

/**
 * Fires when the DisplayName has been set.
 */
/** @deprecated */
export type TransactionDisplayNameSetEvent = Event<TransactionDisplayNameSetEventData>
/** @deprecated */
export type TransactionDisplayNameSetEventData = { readonly displayName?: string }
/** @deprecated */
export const TransactionDisplayNameSetEventTypeName = 'TransactionDisplayNameSet' as const
/** @deprecated */
export const isTransactionDisplayNameSetEvent = (event: Event<unknown>): event is TransactionDisplayNameSetEvent =>
  event.eventType === TransactionDisplayNameSetEventTypeName
