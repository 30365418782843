import {
  MaintenancePlanDefinition,
  MaintenancePlanPaymentInterval,
  formatUscWholeDollars,
  isNullish,
} from '@breezy/shared'
import { useMemo } from 'react'

type MaintenancePlanPricingProps = {
  maintenancePlanDefinition: MaintenancePlanDefinition
  isImportedPlan?: boolean
  paymentInterval?: MaintenancePlanPaymentInterval
  pricingAdjustmentUscPerPeriodUsc?: number
}

export const useMaintenancePlanPricing = ({
  maintenancePlanDefinition,
  isImportedPlan,
}: MaintenancePlanPricingProps) => {
  const hasFixedPricing = useMemo(
    () => !isNullish(maintenancePlanDefinition.yearlyStaticPriceUsc),
    [maintenancePlanDefinition],
  )

  const [pricingInformationPrimary, pricingInformationSecondary] =
    useMemo(() => {
      if (isImportedPlan) {
        return ['Imported Plan', 'Pricing N/A']
      }
      // At the time of writing, only Monthly and Annual payment intervals are supported.
      // The Pricing display should prioritize monthly price breakdowns if both payment intervals
      // are allowed, otherwise it should prioritize the allowed payment type.
      const allowsMonthlyPaymentInterval =
        maintenancePlanDefinition.allowedPaymentIntervals.find(
          paymentInterval =>
            paymentInterval.paymentInterval ===
            MaintenancePlanPaymentInterval.MONTHLY,
        )

      if (hasFixedPricing) {
        const fixedAnnualPriceUsc =
          maintenancePlanDefinition.yearlyStaticPriceUsc as number

        if (allowsMonthlyPaymentInterval) {
          return [
            `${formatUscWholeDollars(
              Math.round(fixedAnnualPriceUsc / 12),
            )} per month`,
            `/ ${formatUscWholeDollars(fixedAnnualPriceUsc)} per year`,
          ]
        } else {
          return [
            `${formatUscWholeDollars(fixedAnnualPriceUsc)} per year`,
            `/ ${formatUscWholeDollars(
              Math.round(fixedAnnualPriceUsc / 12),
            )} per month`,
          ]
        }
      } else {
        return ['Legacy plan type', 'Do not sell']
      }
    }, [
      hasFixedPricing,
      isImportedPlan,
      maintenancePlanDefinition.allowedPaymentIntervals,
      maintenancePlanDefinition.yearlyStaticPriceUsc,
    ])

  return {
    pricingInformationPrimary,
    pricingInformationSecondary,
  }
}
