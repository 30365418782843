import { Address } from '../Address/Address'

export const appTypes: MapAppType[] = ['google', 'apple', 'waze']
export type MapAppType = 'google' | 'apple' | 'waze'

export const getMapAppType = (mapAppName: string | undefined): MapAppType | undefined => {
  if (!mapAppName) return undefined

  const appType = appTypes.find(type => type.toLowerCase() === mapAppName.toLowerCase())
  return appType
}

const mapAppTypeBaseUrl: Record<MapAppType, string> = {
  google: 'https://maps.google.com/maps',
  apple: 'https://maps.apple.com/maps',
  waze: 'https://waze.com/ul',
}

export const getMapAppUrl = (address: Address, preferredMapAppType: MapAppType | undefined) => {
  const mapAppTypeOrDefault = preferredMapAppType ?? 'google'
  const hasLatLong = !!address.geoLocation
  const [longitude, latitude] = address.geoLocation?.coordinates ?? []
  const baseUrl = mapAppTypeBaseUrl[mapAppTypeOrDefault]

  const url = new URL(baseUrl)

  url.searchParams.append(
    'q',
    address.line1 + ', ' + address.city + ', ' + address.stateAbbreviation + ' ' + address.zipCode,
  )
  // Including lat/long breaks apple maps, is ignored by google maps, and is needed by waze.
  if (hasLatLong && preferredMapAppType === 'waze') {
    url.searchParams.append('ll', `${latitude},${longitude}`)
  }

  return url.toString()
}
