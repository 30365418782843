import { gql } from '../../generated'

export const TAGS_MINIMAL_FOR_COMPANY_QUERY = gql(/* GraphQL */ `
  query TagsMinimalForCompanyQuery($companyGuid: uuid!) {
    tags(
      where: { companyGuid: { _eq: $companyGuid } }
      orderBy: { name: ASC }
    ) {
      ...TagMinimal
    }
  }
`)
