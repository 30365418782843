import { BzDateFns, IsoDateString, richJobTypeDescriptor } from '@breezy/shared'
import { faWrench } from '@fortawesome/pro-light-svg-icons'
import { useMemo } from 'react'
import useAppNavigation from '../../hooks/useAppNav'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import BzCollapsible from '../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type LinkedJobsCollapsibleJob = {
  jobGuid: string
  jobType: string
  createdAt: IsoDateString
  address: string
  status: string
}

export type LinkedJobsCollapsibleProps = {
  jobs: LinkedJobsCollapsibleJob[]
}

export const LinkedJobsCollapsible = ({ jobs }: LinkedJobsCollapsibleProps) => {
  const { timezone } = useExpectedCompany()
  const appNav = useAppNavigation()

  const collapsibleTitle = useMemo(
    () => `Linked Jobs (${jobs.length})`,
    [jobs.length],
  )

  return (
    <BzCollapsible title={collapsibleTitle} titleIcon={faWrench}>
      <div className="flex flex-col gap-2">
        {jobs.map(job => (
          <CollapsibleItem
            key={job.jobGuid}
            title={richJobTypeDescriptor(job.jobType)}
            onTitleClick={() => appNav.navigateToJobDetailsPage(job.jobGuid)}
            defaultOpen
            contentList={[
              {
                key: 'Status',
                value: job.status,
              },
              {
                key: 'Created on:',
                value: BzDateFns.formatFromISO(
                  job.createdAt,
                  'MMM d, yyyy',
                  timezone,
                ),
              },
              {
                key: 'Address:',
                value: job.address,
              },
            ]}
          />
        ))}
      </div>
    </BzCollapsible>
  )
}
