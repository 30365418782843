import { bzExpect } from '@breezy/shared'
import { useQuery } from 'urql'
import { ANON_CONTACT_QUERY } from './useContactQuery.anon-gql'

export const useContactQuery = (companyGuid: string, contactGuid?: string) => {
  const [{ data: contactData }] = useQuery({
    query: ANON_CONTACT_QUERY,
    variables: {
      companyGuid: companyGuid,
      contactGuid: bzExpect(contactGuid),
    },
  })

  return {
    contact: contactData?.contacts[0],
  }
}
