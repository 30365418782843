import { Guid } from './common-schemas'

export interface Person {
  id: Guid

  firstName: string
  lastName: string

  deactivatedAt?: string
}

export interface PersonWithAccountGuid extends Person {
  accountContacts: {
    accountGuid: Guid
  }[]
}

export type AvatarData = {
  avatarAltShortString: string
  avatarAltColor?: string
  avatarImgSrc?: string
  deactivatedAt?: string
  userGuid: string
}

export interface UserDisplayNameContainer {
  readonly userDisplayName: string
}

export interface RenderablePerson {
  displayName: string
  avatarData: AvatarData
}

export type PersonViewModel = Person & RenderablePerson

export const formatName = (name?: Pick<Person, 'firstName' | 'lastName'>) =>
  name ? `${name.firstName} ${name.lastName}` : ''
