import { MinimalUser } from '@breezy/shared'
import { faCrown, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FaIconButton from '../../../elements/FaIconButton/FaIconButton'
import { lightUserColor } from '../../../utils/color-utils'

type AccountManagerBannerProps = {
  readonly accountManager?: MinimalUser | null | undefined
  readonly onEditClicked?: () => void
}

const AccountManagerBanner = ({
  accountManager,
  onEditClicked,
}: AccountManagerBannerProps) => {
  const padding = onEditClicked ? 'py-1' : 'py-2'

  return (
    <>
      {accountManager && (
        <div
          className={`row flex-between center-children-v mb-3 flex rounded-md shadow-md ${padding} px-4`}
          style={{
            backgroundColor: lightUserColor(accountManager.userGuid),
          }}
        >
          <div className="row row center-children-v flex w-full">
            <FontAwesomeIcon
              icon={faCrown}
              style={{ color: '#595959', fontSize: 18, marginRight: 8 }}
            />
            <div className="semibold_12_22 grey9">
              Account Manager:{' '}
              <b>
                {accountManager.firstName} {accountManager.lastName}
              </b>
            </div>
          </div>
          {onEditClicked && (
            <FaIconButton
              icon={faEdit}
              onClick={onEditClicked}
              style={{ marginTop: 0 }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default AccountManagerBanner
