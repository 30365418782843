import { Modal } from 'antd'
import classNames from 'classnames'
export type AssignmentStatusStepsClockInModalProps = {
  open: boolean
  isLoading: boolean
  onClockInClicked: () => void
  onCancelClicked: () => void
}

export const AssignmentStatusStepsClockInModal = ({
  open,
  isLoading,
  onClockInClicked,
  onCancelClicked,
}: AssignmentStatusStepsClockInModalProps) => {
  return (
    <Modal
      title="You are not clocked in!"
      open={open}
      onOk={onClockInClicked}
      onCancel={onCancelClicked}
      confirmLoading={isLoading}
      okText="Clock In"
      wrapClassName={classNames('z-[1031]')}
      classNames={{
        mask: 'z-[1031]',
      }}
    >
      <p>
        You cannot start an assignment unless you are clocked in. Please clock
        in to continue.
      </p>
    </Modal>
  )
}
