import { faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { useCallback } from 'react'
import { m } from '../../utils/react-utils'

type EditButtonProps = {
  onClick: () => void
  name?: string
}

export const EditButton = m<EditButtonProps>(({ onClick, name }) => {
  const title = name ? `Edit ${name}` : 'Edit'
  const ourOnClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    },
    [onClick],
  )

  return (
    <Tooltip title={title}>
      <Button
        title={title}
        type="text"
        className="flex items-center justify-center"
        onClick={ourOnClick}
        icon={<FontAwesomeIcon icon={faPencil} className="h-4 w-4" />}
        shape="circle"
      />
    </Tooltip>
  )
})
