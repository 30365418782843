import { Button } from 'antd'
import { memo } from 'react'

type ButtonProps = {
  onClick: () => void
  title: string
}

export const RightLinkButton = memo(({ onClick, title }: ButtonProps) => (
  <Button
    type="link"
    onClick={onClick}
    className="py-[2px] pr-0 text-[16px] leading-[24px]"
  >
    {title}
  </Button>
))
