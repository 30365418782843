import { OfficeRoutes, PermissionV2, SharedRoutes } from '@breezy/shared'
import {
  faFileContract,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'
import {
  faFileContract as faFileContractRegular,
  faHouseUser,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { faPlus as faPlusThick } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, MenuProps } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnsiteMenuItem } from '../adam-components/OnsiteMenuItem'
import { OnsiteBasicModal } from '../adam-components/OnsiteModal/OnsiteModal'
import { Authorized } from '../components/Permissions/Authorized/Authorized'
import { useIsAuthorized } from '../hooks/permission/useIsAuthorized'
import { useCompanyMaintenancePlansEnabled } from '../hooks/useCompanyMaintenancePlansEnabled'
import { useFeatureFlag } from '../hooks/useFeatureFlags'
import { useIsOfficeApp } from '../hooks/useIsOfficeApp'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { AddMaintenancePlanModal } from '../pages/MaintenancePlansListPage/AddMaintenancePlanModal'
import { AccountIcon, JobIcon } from '../utils/feature-icons'
import { m, useModalState } from '../utils/react-utils'
import './GlobalCreateButton.less'

type CreateButtonProps = React.PropsWithChildren<{
  readonly uri: string
}>

const NewButton = m<CreateButtonProps>(({ uri, children }) => {
  const navigate = useNavigate()

  return <div onClick={() => navigate(uri)}>{children}</div>
})

const makeMenuItem = (
  label: string,
  uri: string,
  icon: IconDefinition,
): ItemType => ({
  key: label,
  label: <NewButton uri={uri}>{label}</NewButton>,
  icon: <FontAwesomeIcon className="h-4 w-4" icon={icon} />,
})

type OfficeGlobalCreateButtonProps = {
  onMaintenancePlanCreated?: () => void
}

const OfficeGlobalCreateButton = ({
  onMaintenancePlanCreated,
}: OfficeGlobalCreateButtonProps) => {
  const isAuthorized = useIsAuthorized()
  const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
  const maintPlanV3Enabled = useFeatureFlag('createMaintenancePlanV2')
  const [
    addMaintenancePlanModalOpen,
    openAddMaintenancePlanModal,
    closeAddMaintenancePlanModal,
  ] = useModalState(false)

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('globalMpw', 'office-global-create-add-new')
  const [
    importMaintenancePlanModalOpen,
    openImportMaintenancePlanModal,
    closeImportMaintenancePlanModal,
  ] = useMaintenancePlanWizardFlags('importPlan', 'office-global-create-import')

  const menu = useMemo(() => {
    const menuItems: MenuProps['items'] = []
    if (isAuthorized(PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE)) {
      menuItems.push(
        makeMenuItem('Create Job', OfficeRoutes.JOB_CREATION.path, JobIcon),
        makeMenuItem(
          'Create Account',
          SharedRoutes.ACCOUNT_CREATION.path,
          AccountIcon,
        ),
      )
    }
    if (isCompanyMaintenancePlansEnabled && maintPlanV3Enabled) {
      menuItems.push({
        key: 'create-maintenance-plan',
        label: 'Create Maintenance Plan',
        icon: <FontAwesomeIcon className="h-4 w-4" icon={faFileContract} />,
        onClick: openAddMaintenancePlanModal,
      })
    }
    return { items: menuItems }
  }, [
    isAuthorized,
    isCompanyMaintenancePlansEnabled,
    maintPlanV3Enabled,
    openAddMaintenancePlanModal,
  ])

  const handleAddNewPlan = useCallback(() => {
    closeAddMaintenancePlanModal()
    openMaintenancePlanWizard()
  }, [closeAddMaintenancePlanModal, openMaintenancePlanWizard])

  const handleImportPlan = useCallback(() => {
    closeAddMaintenancePlanModal()
    openImportMaintenancePlanModal()
  }, [closeAddMaintenancePlanModal, openImportMaintenancePlanModal])

  const handleMaintenancePlanCreated = useCallback(() => {
    closeMaintenancePlanWizard()
    onMaintenancePlanCreated?.()
  }, [closeMaintenancePlanWizard, onMaintenancePlanCreated])

  const handleMaintenancePlanImported = useCallback(() => {
    closeImportMaintenancePlanModal()
    onMaintenancePlanCreated?.()
  }, [closeImportMaintenancePlanModal, onMaintenancePlanCreated])

  return (
    <>
      <Dropdown menu={menu}>
        <Button
          type="primary"
          title="Create"
          className="relative flex h-[36px] items-center gap-2 rounded-full"
        >
          <div className="inset-0 flex items-center justify-center">
            <FontAwesomeIcon icon={faPlusThick} />
          </div>
          <span className="font-bold">Create</span>
        </Button>
      </Dropdown>
      {addMaintenancePlanModalOpen && (
        <AddMaintenancePlanModal
          onCancel={() => {
            closeAddMaintenancePlanModal()
            closeMaintenancePlanWizard()
            closeImportMaintenancePlanModal()
          }}
          onAddNewPlan={handleAddNewPlan}
          onImportNewPlan={handleImportPlan}
        />
      )}
      {maintenancePlanWizardOpen && (
        <MaintenancePlanWizard
          onRamp="office-global-create-add-new"
          onClose={handleMaintenancePlanCreated}
        />
      )}
      {importMaintenancePlanModalOpen && (
        <MaintenancePlanWizard
          isImport
          onRamp="office-global-create-import"
          onClose={handleMaintenancePlanImported}
        />
      )}
    </>
  )
}

const TechGlobalCreateButton = () => {
  const isAuthorized = useIsAuthorized()
  const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('globalMpw', 'tech-global-create')
  const show = useCallback(() => setIsOpen(true), [])
  const hide = useCallback(() => {
    setIsOpen(false)
    closeMaintenancePlanWizard()
  }, [closeMaintenancePlanWizard])
  const menuItems = useMemo(() => {
    const items: React.ReactNode[] = []

    if (isAuthorized(PermissionV2.FIELD_JOB_INFORMATION_CREATE)) {
      items.push(
        <OnsiteMenuItem
          key="create-job"
          label="Create Job"
          onClick={() => {
            hide()
            navigate(OfficeRoutes.JOB_CREATION.path)
          }}
          icon={faWrench}
        />,
      )
    }

    if (isAuthorized(PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL)) {
      items.push(
        <OnsiteMenuItem
          key="create-account"
          label="Create Account"
          onClick={() => {
            hide()
            navigate(SharedRoutes.ACCOUNT_CREATION.path)
          }}
          icon={faHouseUser}
        />,
      )
    }

    if (isCompanyMaintenancePlansEnabled) {
      items.push(
        <OnsiteMenuItem
          key="create-maintenance-plan"
          label="Create Maintenance Plan"
          onClick={() => {
            hide()
            openMaintenancePlanWizard()
          }}
          icon={faFileContractRegular}
        />,
      )
    }

    return items
  }, [
    hide,
    isAuthorized,
    isCompanyMaintenancePlansEnabled,
    navigate,
    openMaintenancePlanWizard,
  ])
  return (
    <div className="tech-create-button">
      <Button
        type="primary"
        title="Create"
        className="flex h-[36px] w-[36px] items-center justify-center"
        onClick={show}
      >
        <FontAwesomeIcon className="h-[20px]" icon={faPlusThick} />
      </Button>
      {isOpen && (
        <OnsiteBasicModal
          open={isOpen}
          onClose={hide}
          header="Global Actions"
          headerBordered
          children={<div className="flex flex-col gap-4">{menuItems}</div>}
        />
      )}
      {maintenancePlanWizardOpen && (
        <MaintenancePlanWizard
          onRamp="tech-global-create"
          onClose={closeMaintenancePlanWizard}
        />
      )}
    </div>
  )
}

const GlobalCreateButton = () => {
  const isOfficeApp = useIsOfficeApp()

  return isOfficeApp ? <OfficeGlobalCreateButton /> : <TechGlobalCreateButton />
}

const AuthGlobalCreateButton = () => (
  <Authorized>
    <GlobalCreateButton />
  </Authorized>
)

export default AuthGlobalCreateButton
