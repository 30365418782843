import { ContentListItem } from '../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type DisplayContentsMode = 'flex' | 'grid'

export type ContentListProps = {
  readonly contentList: ContentListItem[]
  readonly hideKeys?: boolean
  readonly displayContents?: DisplayContentsMode
}

const ContentList = ({
  contentList,
  hideKeys,
  displayContents = 'flex',
}: ContentListProps) => {
  return (
    <div className="flex flex-col space-y-1">
      {contentList
        .filter(item => !!item.value)
        .map((item, i) => (
          <div
            className={`${
              displayContents === 'flex'
                ? 'flex space-x-2'
                : 'grid grid-cols-2 items-center'
            }`}
            key={item.key}
          >
            {!hideKeys && !item.hideKey && (
              <div className="semibold_14_22 grey9">{item.key}</div>
            )}
            <div
              className={`regular_14_22 grey9 text-ellipsis ${
                hideKeys || item.hideKey ? '' : 'ml-2'
              }`}
            >
              {item.value}
            </div>
          </div>
        ))}
    </div>
  )
}

export default ContentList
