import z from 'zod'
import { guidSchema, isoDateStringSchema, timeZoneIdSchema } from '../../contracts'
import { CompanySchema } from '../Company/Company'
import { emailAddressValueSchema } from '../Email/EmailTypes'
import { NotificationPreferenceType } from '../NotificationPreferenceTypes'
import { PermissionV1, PermissionV2 } from '../Permission'
import { PhoneNumberTypeSchema, telephoneNumberSchema } from '../PhoneNumbers/Phone'
import { RoleId } from '../Role'
import { SchedulingCapabilitySchema, UserSchema } from '../Users/User'
import { bzOptional, firstNameSchema, lastNameSchema } from '../common-schemas'

export const SinglePhoneEmailContactSchema = z.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  primaryPhoneNumber: telephoneNumberSchema,
  phoneNumberType: PhoneNumberTypeSchema,
  primaryEmailAddress: emailAddressValueSchema,
  notificationPreference: z.nativeEnum(NotificationPreferenceType),
})

export const CreateNewAccountContactDTOSchema = SinglePhoneEmailContactSchema.extend({
  accountGuid: guidSchema,
})

export type CreateNewAccountContactDTO = z.infer<typeof CreateNewAccountContactDTOSchema>

export const UpdateAccountContactDTOSchema = SinglePhoneEmailContactSchema.extend({
  accountContactGuid: guidSchema,
  primary: z.boolean(),
})

export type UpdateAccountContactDTO = z.infer<typeof UpdateAccountContactDTOSchema>

export const DeleteAccountContactDTOSchema = z.object({
  accountContactGuid: guidSchema,
})

export type DeleteAccountContactDTO = z.infer<typeof DeleteAccountContactDTOSchema>

export const UpdateAccountDTOSchema = z.object({
  accountGuid: guidSchema,
  accountNote: bzOptional(z.string()),
})

export const EditUserRolesDtoSchema = z.object({
  userGuid: UserSchema.shape.userGuid,
  roles: bzOptional(z.array(z.nativeEnum(RoleId))),
})

export type EditUserRolesDto = z.infer<typeof EditUserRolesDtoSchema>

export const EditUserDtoSchema = z.object({
  userGuid: UserSchema.shape.userGuid,
  companyGuid: bzOptional(CompanySchema.shape.companyGuid),
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: telephoneNumberSchema,
  phoneNumberType: PhoneNumberTypeSchema,
  roles: bzOptional(z.array(z.nativeEnum(RoleId))),
  permissions: bzOptional(z.array(z.nativeEnum(PermissionV1))),
  permissionsV2: bzOptional(z.array(z.nativeEnum(PermissionV2))),
  schedulingCapability: bzOptional(SchedulingCapabilitySchema),
})

export type EditUserDto = z.infer<typeof EditUserDtoSchema>

export const CreateTechnicianCapacityDTOSchema = z.object({
  technicianCapacityGuid: guidSchema,
  userGuid: guidSchema,
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type CreateTechnicianCapacityDTO = z.infer<typeof CreateTechnicianCapacityDTOSchema>

export const UpdateTechnicianCapacityDTOSchema = z.object({
  technicianCapacityGuid: guidSchema,
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type UpdateTechnicianCapacityDTO = z.infer<typeof UpdateTechnicianCapacityDTOSchema>

export const DeleteTechnicianCapacityDTOSchema = z.object({
  technicianCapacityGuid: guidSchema,
})

export type DeleteTechnicianCapacityDTO = z.infer<typeof DeleteTechnicianCapacityDTOSchema>

export type UpdateAccountDTO = z.infer<typeof UpdateAccountDTOSchema>

export const FetchCompanyDTOSchema = z.object({
  companyGuid: guidSchema,
})

export type FetchCompanyDTO = z.infer<typeof FetchCompanyDTOSchema>

export const CreateCompanyDTOSchema = z.object({
  name: z.string(),
  timezone: timeZoneIdSchema,
})

export type CreateCompanyDTO = z.infer<typeof CreateCompanyDTOSchema>

export const EditCompanyDTOSchema = CreateCompanyDTOSchema.extend({
  companyGuid: guidSchema,
})

export type EditCompanyDTO = z.infer<typeof EditCompanyDTOSchema>

export const DeleteRecordDTOSchema = z.object({
  id: z.string(),
})

export type DeleteRecordDTO = z.infer<typeof DeleteRecordDTOSchema>

export const SearchUsersForImpersonationDTOSchema = z.object({
  searchString: z.string(),
})

export type SearchUsersForImpersonationDTO = z.infer<typeof SearchUsersForImpersonationDTOSchema>

export const UserImpersonationSearchItemDTOSchema = z.object({
  emailAddress: z.string(),
  userGuid: z.string(),
  firstName: z.string(),
  lastName: z.string(),
})

export type UserImpersonationSearchDTOItem = z.infer<typeof UserImpersonationSearchItemDTOSchema>

export const ConfirmAppointmentDTOSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('by-appointment-reference-number'),
    appointmentReferenceNumber: z.string(),
    jobAppointmentConfirmed: z.boolean(),
  }),
  z.object({
    type: z.literal('by-appointment-guid'),
    appointmentGuid: z.string(),
    jobAppointmentConfirmed: z.boolean(),
  }),
])

export type ConfirmAppointmentDTO = z.infer<typeof ConfirmAppointmentDTOSchema>
