import { MaintenancePlanDefinition } from '@breezy/shared'
import { Popover, Typography } from 'antd'
import ThinDivider from '../../../elements/ThinDivider'
import { MaintenancePlanInfoSection } from '../MaintenancePlanTypeOptionCard'
import { useMaintenancePlanPricing } from '../hooks/useMaintenancePlanPricing'

type MaintenancePlanTypeDetailPopoverProps = {
  maintenancePlanDefinition: MaintenancePlanDefinition
}

export const MaintenancePlanTypeDetailPopover = ({
  maintenancePlanDefinition,
}: MaintenancePlanTypeDetailPopoverProps) => {
  const { pricingInformationPrimary, pricingInformationSecondary } =
    useMaintenancePlanPricing({
      maintenancePlanDefinition,
    })

  // NOTE: This is not pixel-perfect to the designs.
  // To do that we'd need to change the text sizes and spacings of all these child elements
  return (
    <Popover
      placement="top"
      content={
        <div className="flex flex-col p-1">
          <div className="mb-3 text-[16px] font-semibold">Plan Details</div>
          <MaintenancePlanInfoSection
            maintenancePlanDefinition={maintenancePlanDefinition}
          />
          <ThinDivider />
          <div className="flex flex-row">
            <Typography.Text className="mr-2 text-base font-semibold text-[#000]">
              {pricingInformationPrimary}
            </Typography.Text>
            <Typography.Text className="text-base font-normal text-[#8c8c8c]">
              {pricingInformationSecondary}
            </Typography.Text>
          </div>
        </div>
      }
    >
      <div className="mt-2 w-fit min-w-[240px] cursor-pointer text-[14px] leading-[22px] text-[#1677fe]">
        View Plan Details
      </div>
    </Popover>
  )
}
