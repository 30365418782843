import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsAuthorized } from '../../hooks/permission/useIsAuthorized'

export const useCanViewTeamSchedule = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(
    () => isAuthorized(PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW),
    [isAuthorized],
  )
}

const useCanViewMyFutureAppointments = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(
    () =>
      isAuthorized(PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE),
    [isAuthorized],
  )
}

export const useCanViewPastAppointments = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(
    () =>
      isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED,
      ),
    [isAuthorized],
  )
}

const useCanViewFullDayAppointments = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(
    () =>
      isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY,
      ),
    [isAuthorized],
  )
}

const useCanViewCurrentAppointment = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(
    () =>
      isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT,
      ),
    [isAuthorized],
  )
}

export const useCanOnlyViewCurrentAppointment = () => {
  const canViewFullDay = useCanViewFullDayAppointments()
  const canViewCurrent = useCanViewCurrentAppointment()

  return canViewCurrent && !canViewFullDay
}

export const useCanOnlyViewTodaysAssignedAppointments = () => {
  const canViewMyFutureAppointments = useCanViewMyFutureAppointments()
  const canViewCurrent = useCanViewCurrentAppointment()
  const canViewFullDay = useCanViewFullDayAppointments()

  return (canViewFullDay || canViewCurrent) && !canViewMyFutureAppointments
}
