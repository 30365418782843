import { isNullish } from '@breezy/shared'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const validatorAny = () => async (_: any, __: any) => {}

export const validatorNotNullish =
  (elementName: string) => async (_: any, value: any) => {
    if (isNullish(value)) {
      return Promise.reject(new Error(`${elementName} is required`))
    }
  }

export const validatorNotFalsy =
  (elementName: string) => async (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error(`${elementName} is required`))
    }
  }

export const validatorPositiveNonZeroFloatNumber =
  (elementName: string) => async (_: any, value: any) => {
    if (Number.isNaN(Number.parseFloat(value))) {
      return Promise.reject(new Error(`Invalid ${elementName}`))
    }
    if (Number.parseFloat(value) <= 0) {
      return Promise.reject(new Error(`${elementName} must be positive`))
    }
  }

export const validatorPositiveNonZeroInteger =
  (elementName: string) => async (_: any, value: any) => {
    if (Number.isNaN(Number.parseInt(value))) {
      return Promise.reject(new Error(`Invalid ${elementName}`))
    }
    if (Number.parseInt(value) <= 0) {
      return Promise.reject(new Error(`${elementName} must be positive`))
    }
  }

export const validatorFloatNumberBetweenZeroAndOne =
  (elementName: string) => async (_: any, value: any) => {
    if (Number.isNaN(Number.parseFloat(value))) {
      return Promise.reject(new Error(`Invalid ${elementName}`))
    }
    if (Number.parseFloat(value) < 0 || Number.parseFloat(value) > 1) {
      return Promise.reject(new Error(`${elementName} must be between 0 and 1`))
    }
  }

export const validatorFloatNumberBetweenZeroAndOneHundred =
  (elementName: string) => async (_: any, value: any) => {
    if (Number.isNaN(Number.parseFloat(value))) {
      return Promise.reject(new Error(`Invalid ${elementName}`))
    }
    if (Number.parseFloat(value) < 0 || Number.parseFloat(value) > 100) {
      return Promise.reject(
        new Error(`${elementName} must be between 0 and 100`),
      )
    }
  }
