import { isNullish } from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import { Button, Row, Typography } from 'antd'
import { memo, useMemo } from 'react'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { CreateAccountLocationModalForm } from '../../components/CreateAccountLocationModalForm/CreateAccountLocationModalForm'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import { useFetchComprehensiveAccountDetails } from '../../hooks/fetch/useFetchComprehensiveAccountDetails'
import useIsMobile from '../../hooks/useIsMobile'
import { useModalState } from '../../utils/react-utils'
import { Carousel } from '../EstimatesFlow/components/Carousel'
import { LocationOptionCard } from './LocationOptionCard'
import { renderCarouselHeader } from './components/CarouselHeader'
import { SectionHeaderText } from './components/SectionHeaderText'
import { WizardColum } from './components/WizardColum'
import { usePageContainerWidth } from './hooks/usePageContainerWidth'
const GUTTER = 12

type Props = {
  selectedAccountGuid: string
  onLocationSelected: (locationGuid: string) => void
  goToNextStep: () => void
  fetchComprehensiveAccountQuery: ReturnType<
    typeof useFetchComprehensiveAccountDetails
  >
  refetchAccountData: () => void
}

export const SelectLocationPanel = memo<Props>(
  ({
    selectedAccountGuid,
    onLocationSelected,
    goToNextStep,
    fetchComprehensiveAccountQuery,
    refetchAccountData,
  }) => {
    const [
      createNewAccountLocationModalOpen,
      openCreateNewAccountLocationModal,
      closeCreateNewAccountLocationModal,
    ] = useModalState()

    const comprehensiveAccount = useMemo(() => {
      if (
        (fetchComprehensiveAccountQuery.fetchStatus === 'idle' &&
          fetchComprehensiveAccountQuery.isLoading) ||
        fetchComprehensiveAccountQuery.isLoading ||
        fetchComprehensiveAccountQuery.isError
      ) {
        return undefined
      }

      return fetchComprehensiveAccountQuery.data
    }, [fetchComprehensiveAccountQuery])

    const isMobile = useIsMobile()

    const { scrollbarWidth, pageContainerWidth } = usePageContainerWidth()

    const itemWidth = useMemo(() => {
      if (isMobile) {
        // Subtract 26px from the container width to show a peek of the next item
        return Math.min(
          pageContainerWidth - 32 - (scrollbarWidth ?? 0) - 26,
          360,
        )
      }
      return 600
    }, [isMobile, pageContainerWidth, scrollbarWidth])

    const carouselChildren = useMemo(() => {
      if (!isNullish(comprehensiveAccount)) {
        const numLocations = comprehensiveAccount.getAccountLocations().length
        return comprehensiveAccount
          .getAccountLocations()
          .map((accountLocation, i) => {
            return (
              <div
                key={accountLocation.location.locationGuid}
                style={numLocations > 1 ? { width: `${itemWidth}px` } : {}}
                // pb-2 is to make sure the box shadow isn't cut off
                className="h-full max-h-full pb-2"
              >
                <LocationOptionCard
                  accountLocation={accountLocation}
                  onLocationSelected={onLocationSelected}
                  goToNextStep={goToNextStep}
                  dataTestId={`location-option-${i}`}
                />
              </div>
            )
          })
      } else {
        return (
          <div className="center-vh flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        )
      }
    }, [comprehensiveAccount, itemWidth, onLocationSelected, goToNextStep])

    const shouldUseCarousel = useMemo(() => {
      return (
        comprehensiveAccount &&
        comprehensiveAccount.getAccountLocations().length > 1
      )
    }, [comprehensiveAccount])

    const carouselMargin = useMemo(
      () =>
        isMobile
          ? 16
          : Math.max((pageContainerWidth - 754 - (scrollbarWidth ?? 0)) / 2, 0),
      [isMobile, pageContainerWidth, scrollbarWidth],
    )
    if (fetchComprehensiveAccountQuery.isLoading) {
      return (
        <WizardColum>
          <SectionHeaderText text="Select Location" />
          <SectionedCard
            heightClassName="min-h-[312px]"
            loading
            sections={[]}
          />
        </WizardColum>
      )
    }

    return (
      <>
        {shouldUseCarousel ? (
          <div style={{ margin: `0 -${carouselMargin}px` }}>
            <Carousel
              renderHeaderWithDots={renderCarouselHeader('Select Location')}
              margin={carouselMargin}
              mistyMargin={carouselMargin}
              gutter={GUTTER}
            >
              {carouselChildren}
            </Carousel>
          </div>
        ) : (
          <div className="flex flex-col gap-y-4">
            <SectionHeaderText text="Select Location" />
            <>{carouselChildren}</>
          </div>
        )}
        <Button
          type="text"
          className="mb-0 mt-4 h-[44px] w-full p-0 hover:bg-transparent"
          onClick={openCreateNewAccountLocationModal}
        >
          <Row className="h-11 items-center">
            <FaIconWithCircularBackground
              iconDefinition={faLocationDot}
              backgroundColor="#FFF"
              borderColor="#D9D9D9"
              diameterPx={44}
            />
            <Typography.Title level={5} className="mb-0 ml-[6px] text-center">
              Create new location
            </Typography.Title>
          </Row>
        </Button>
        {createNewAccountLocationModalOpen && (
          <CreateAccountLocationModalForm
            accountGuid={selectedAccountGuid}
            onModalCancel={closeCreateNewAccountLocationModal}
            onLocationCreated={() => {
              closeCreateNewAccountLocationModal()
              refetchAccountData()
            }}
          />
        )}
      </>
    )
  },
)
