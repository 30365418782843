import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useFeatureFlag } from '../hooks/useFeatureFlags'

// https://getbreezyapp.atlassian.net/wiki/spaces/BZ/pages/177897473/On+Demand+Client+Refreshes
export const ForceHardRefreshWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const [unmountApp, setUnmountApp] = useState(false)

    const shouldHardRefresh = useFeatureFlag('should-hard-refresh')
    const killSwitch = useFeatureFlag('should-hard-refresh-killswitch')
    const previousFlagValue = useRef<boolean | undefined>(undefined)
    useEffect(() => {
      if (killSwitch) {
        console.info(
          'Should Hard Refresh Kill Switch is on. Skipping hard refresh.',
        )
        return
      } else {
        const wasFalse = previousFlagValue.current === false
        const isNowTrue = shouldHardRefresh
        if (wasFalse && isNowTrue) {
          console.info('Hard refresh signal detected. Triggering hard refresh.')
          // There are parts of the app that block refreshing. Namely, there are beforeUnload listeners when there are
          // unsaved changes. To prevent those from blocking our refresh, we need to unmount the whole app before we
          // trigger the refresh.
          setUnmountApp(true)
        }
      }
      previousFlagValue.current = shouldHardRefresh
    }, [killSwitch, shouldHardRefresh])

    useLayoutEffect(() => {
      if (unmountApp) {
        // Make sure this is at the bottom of the event queue or it will happen to quickly. In my tests it works for 0,
        // but 100ms doesn't really matter from a UX perspective. We're hoping they never see this anyway.
        setTimeout(() => window.location.reload(), 100)
      }
    }, [unmountApp])

    if (unmountApp) {
      return null
    }
    return <>{children}</>
  },
)
