import { Button } from 'antd'
import React from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsiteWhiteLabelSection } from '../../../adam-components/OnsitePage/OnsiteWhiteLabelSection'
import LoanPromoDisclaimer from '../../../components/Financing/LoanPromo/LoanPromoDisclaimer'
import { useStrictContext } from '../../../utils/react-utils'
import {
  InvoiceContext,
  InvoiceDataContext,
  useIsInvoiceFinanceable,
} from '../invoiceUtils'
import { DisclaimerSection } from './DisclaimerSection'
import { InvoiceContent } from './InvoiceContent'
import { SignatureSection } from './SignatureSection'

type PresentableInvoiceContentProps = {
  hasSignature?: boolean
  onCollectPayment?: () => void
  onSignatureClick?: () => void
}

export const PresentableInvoiceContent =
  React.memo<PresentableInvoiceContentProps>(
    ({ onCollectPayment, onSignatureClick, hasSignature }) => {
      const { companyBlurb, companyName, logoUrl, status } =
        useStrictContext(InvoiceContext)
      const { invoiceTotals } = useStrictContext(InvoiceDataContext)

      const isFinanceable = useIsInvoiceFinanceable(status, invoiceTotals)

      return (
        <>
          <OnsitePageSection title="Invoice">
            <InvoiceContent
              customerFacing
              footer={
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onCollectPayment}
                  disabled={!onCollectPayment}
                >
                  Collect Payment
                </Button>
              }
            />
          </OnsitePageSection>
          <SignatureSection
            hasSignature={hasSignature}
            onSignatureClick={onSignatureClick}
          />

          <OnsiteWhiteLabelSection
            companyBlurb={companyBlurb}
            companyName={companyName}
            logoUrl={logoUrl}
          />
          <DisclaimerSection />
          {isFinanceable && (
            <OnsitePageSection>
              <LoanPromoDisclaimer amountUsc={invoiceTotals.dueUsc} />
            </OnsitePageSection>
          )}
        </>
      )
    },
  )
