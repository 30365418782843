import { z } from 'zod'
import { AsyncFn, LocalDateString } from '../../common'
import {
  MaintenancePlanCancellationReasonType,
  MaintenancePlanConfiguredEventData,
  MaintenancePlanPricingSetEventData,
  guidSchema,
  isoDateStringSchema,
  maintenancePlanConfigurationSchema,
  maintenancePlanPricingSchema,
} from '../../contracts'
import { AccountGuid } from '../Accounts/Account'
import { ForCompanyUser } from '../Company/Company'
import { BillingProfileDto } from '../Finance/CompanyBillingProfileTypes'
import {
  PaymentSubscriptionRecord,
  updatePaymentSubscriptionContextSchema,
} from '../Finance/PaymentSubscriptions/PaymentSubscriptionTypes'
import { LocationGuid } from '../Locations/Location'
import { bzOptional } from '../common-schemas'
import { MaintenancePlanDefinition } from './MaintenancePlanConfigTypes'
import {
  BasicTemporalMaintenancePlanViewModel,
  MaintenancePlanGuid,
  MaintenancePlanTaxRate,
} from './MaintenancePlanTypes'

export type MaintenancePlanCreationRequest = {
  readonly maintenancePlanGuid: MaintenancePlanGuid
  readonly accountGuid: AccountGuid
  readonly locationGuid: LocationGuid
  readonly taxRate?: MaintenancePlanTaxRate
  readonly configuration?: MaintenancePlanConfiguredEventData
  readonly pricing?: MaintenancePlanPricingSetEventData
}

export const maintenancePlanCreationRequestSchema = z.object({
  maintenancePlanGuid: guidSchema,
  accountGuid: guidSchema,
  locationGuid: guidSchema,
  taxRate: bzOptional(
    z.object({
      taxRateGuid: guidSchema,
      rate: z.number(),
    }),
  ),
  configuration: maintenancePlanConfigurationSchema,
  pricing: maintenancePlanPricingSchema,
})

export const maintenancePlanCancellationRequestSchema = z.object({
  maintenancePlanGuid: guidSchema,
  cancellationReason: z.string(),
  cancellationReasonType: z.nativeEnum(MaintenancePlanCancellationReasonType),
  suppressCancellationEmail: bzOptional(z.boolean()),
  shouldExpireVisitsImmediately: bzOptional(z.boolean()),
})

export type MaintenancePlanCancellationRequest = z.infer<typeof maintenancePlanCancellationRequestSchema>

export const updateMaintenancePlanAndSubscriptionRequestSchema = z.object({
  maintenancePlanGuid: guidSchema,
  configuration: maintenancePlanConfigurationSchema,
  pricing: maintenancePlanPricingSchema,
  subscriptionUpdateInfo: bzOptional(updatePaymentSubscriptionContextSchema),
})

export type UpdateMaintenancePlanAndSubscriptionRequest = z.infer<
  typeof updateMaintenancePlanAndSubscriptionRequestSchema
>

export type UpdateMaintenancePlanAndSubscription = AsyncFn<ForCompanyUser<UpdateMaintenancePlanAndSubscriptionRequest>>

export const freeMaintenancePlanActivationRequestSchema = z.object({
  maintenancePlanGuid: guidSchema,
  numDaysUntilAutoCancelation: bzOptional(z.number()),
})

export type FreeMaintenancePlanActivationRequest = z.infer<typeof freeMaintenancePlanActivationRequestSchema>

export const importedMaintenancePlanActivationRequestSchema = z.object({
  maintenancePlanGuid: guidSchema,
  activatedAt: isoDateStringSchema,
  numDaysUntilAutoCancelation: z.number(),
})

export type ImportedMaintenancePlanActivationRequest = z.infer<typeof importedMaintenancePlanActivationRequestSchema>

export type MaintenancePlanActivationEmailRequest = {
  readonly plan: BasicTemporalMaintenancePlanViewModel
  readonly paymentSubscription?: PaymentSubscriptionRecord
  readonly companyName: string
}

export type MaintenancePlanActivationEmailInfo = MaintenancePlanActivationEmailRequest & {
  readonly billingProfile: BillingProfileDto
  readonly planDefinition: MaintenancePlanDefinition
}

export type SendMaintenancePlanActivationEmail = AsyncFn<MaintenancePlanActivationEmailRequest>

export type MaintenancePlanCancellationEmailRequest = {
  readonly plan: BasicTemporalMaintenancePlanViewModel
  readonly cancellationDate: LocalDateString
  readonly refundAmountUsc?: number
  readonly companyName: string
}

export type SendMaintenancePlanCancellationEmail = AsyncFn<MaintenancePlanCancellationEmailRequest>
