import { VisitStatus, toTitleCase } from '@breezy/shared'
import React from 'react'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'

export type VisitStatusTagProps = {
  status: VisitStatus
  className?: string
}

const toStatusDetails = (
  status: VisitStatus,
): { text: string; color: StatusTagColor } => {
  switch (status) {
    case 'UNSCHEDULED':
      return {
        text: toTitleCase(status),
        color: 'darkGray',
      }
    case 'OVERDUE':
      return {
        text: toTitleCase(status),
        color: 'magenta',
      }
    case 'SCHEDULED':
      return {
        text: toTitleCase(status),
        color: 'blue',
      }
    case 'COMPLETED':
      return {
        text: toTitleCase(status),
        color: 'green',
      }
    case 'EXPIRED':
      return {
        text: toTitleCase(status),
        color: 'orange',
      }
    default:
      return { text: 'Unknown', color: 'gray' }
  }
}

const VisitStatusTag = React.memo<VisitStatusTagProps>(
  ({ status, className }) => {
    return (
      <StatusTag
        color={toStatusDetails(status).color}
        text={toStatusDetails(status).text}
        border="strong"
        className={className}
      />
    )
  },
)

export default VisitStatusTag
