import { gql } from '../../generated'

export const FETCH_COMPANY_PHONE_SETTINGS = gql(`
  query FetchCompanyPhoneSettings($companyGuid: uuid!) {
    integratedPhoneCompanyConfig(where: {companyGuid: {_eq: $companyGuid}}) {
      integratedPhoneEnabled
      company {
        integratedPhones {
          phoneNumber
          companyLeadSource {
            canonicalLeadSourceName
            canonicalLeadSourceNameOverride
          }
        }
      }
    }
  }
`)
