import { z } from 'zod'
import { AsyncFn, IsoDateString } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { ForCompany, ForCompanyUser } from '../Company/Company'
import { BzLinks, bzLinksSchema } from '../Links/Links'
import { UserGuid } from '../Users/User'
import { Guid, bzOptional } from '../common-schemas'

export type PhotoGuid = Guid
export type PhotoGuidContainer = { photoGuid: PhotoGuid }
export type PhotoCdnUrl = string
export type PhotoResourceUrn = string

export type PhotoFullId = {
  readonly photoGuid: PhotoGuid
  readonly extension: string
}

export type Photo = PhotoFullId & {
  readonly base64Image: string
}

export const photoFullIdSchema = z.object({
  photoGuid: guidSchema,
  extension: z.string(),
})

export const linkedPhotoWithNotesSchema = photoFullIdSchema.extend({
  base64Image: z.string(),
  contentType: bzOptional(z.string()),
  locationGuid: bzOptional(guidSchema),
  accountGuid: bzOptional(guidSchema),
  jobGuid: bzOptional(guidSchema),
  jobAppointmentGuid: bzOptional(guidSchema),
  apptAssignmentGuid: bzOptional(guidSchema),
  notes: bzOptional(z.array(z.string())),
  fileName: bzOptional(z.string()),
})

export type LinkedPhotoWithNotes = z.infer<typeof linkedPhotoWithNotesSchema>

export const photoRecordSchema = z
  .object({
    photoGuid: guidSchema,
    cdnUrl: z.string(),
    resourceUrn: z.string(),
  })
  .merge(bzLinksSchema)

export type PhotoRecord = z.infer<typeof photoRecordSchema> & {
  readonly createdByUserGuid: UserGuid
  readonly createdAt: IsoDateString
}

export const photoLinksSchema = bzLinksSchema

export type PhotoLinks = BzLinks

export type PhotoRecordWithLinks = PhotoRecord & PhotoLinks

export type IPhotosReader = AsyncFn<ForCompany<PhotoLinks>, PhotoRecordWithLinks[]>
export type IPhotoDeleter = AsyncFn<PhotoGuid>

export type ILinkedPhotoRecordWriter = AsyncFn<ForCompany<Omit<PhotoRecordWithLinks, 'createdAt'>>, PhotoRecord>
export type ILinkedPhotoWriter = AsyncFn<ForCompanyUser<LinkedPhotoWithNotes>, PhotoRecord>
export type ILinkedPhotoDeleter = AsyncFn<PhotoGuid>

export const signedUploadUrlGeneratorRequestSchema = z.object({
  filename: z.string(),
})

export type SignedUploadUrlGeneratorRequest = z.infer<typeof signedUploadUrlGeneratorRequestSchema>

export type SignedUploadUrlResponse = {
  uploadUrl: string
  resultUrl: string
  arn: string
}

export type SignedUploadUrlGenerator = AsyncFn<ForCompany<SignedUploadUrlGeneratorRequest>, SignedUploadUrlResponse>
export const photoCropAreaSchema = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
})
export type PhotoCropArea = z.infer<typeof photoCropAreaSchema>

export const photoCropperRequestSchema = z.object({
  imageUrl: z.string(),
  area: photoCropAreaSchema,
})

export type PhotoCropperRequest = z.infer<typeof photoCropperRequestSchema>

export type PhotoCropper = AsyncFn<ForCompanyUser<PhotoCropperRequest>, PhotoRecord>
