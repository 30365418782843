import { Log } from '../logging'
import { BreezyErrorCategory } from './BreezyErrorCategory'
import { BreezyErrorSeverity } from './BreezyErrorSeverity'

/**
 * Errors are unrecoverable and shouldn't be handled in meaningful way. Exceptions on the hand should be dealt
 * with. The PlatformError captures a class of errors that are unrecoverable. Generally the user will experience this
 * as some sort of 5xx
 */
export class PlatformError extends Error {
  public readonly severity: BreezyErrorSeverity
  public readonly category = 'platform-error' as BreezyErrorCategory
  public readonly errorMessage: string
  public readonly cause?: Error

  constructor(message: string, cause: Error | undefined = undefined, severity: BreezyErrorSeverity = 'ERROR') {
    super(message, {
      cause,
    })
    this.errorMessage = message
    this.cause = cause
    this.severity = severity
  }

  static isPlatformError(u: unknown): u is PlatformError {
    return u instanceof PlatformError
  }

  /** @deprecated Use subclasses of Platform Error instead */
  static mapToPlatformError(u: unknown): PlatformError {
    if (u instanceof PlatformError) return u

    if (u instanceof Error) return new PlatformError('An unexpected platform error occurred', u)

    if (typeof u === 'string') return new PlatformError(`An unexpected database error occurred: ${u}`)

    return new PlatformError(`An unexpected database error occurred with value of ${JSON.stringify(u, null, 2)}`)
  }
}

export class GqlError extends PlatformError {
  public readonly category = 'gql-error' as BreezyErrorCategory

  static queryError = (e: Error): GqlQueryError => new GqlQueryError(e.message, e)
  static mutationError = (e: Error): GqlMutationError => new GqlMutationError(e.message, e)
}

/**
 * This is to begin differentiating between types of errors. Right now we are missing true causes for GQL errors,
 * this at least will narrow our context to GQL as opposed to anything that could go wrong on the platform
 */
class GqlQueryError extends GqlError {
  static mapped = (e: Error): GqlQueryError => new GqlQueryError(e.message, e)
}

/**
 * This is to begin differentiating between types of errors. Right now we are missing true causes for GQL errors,
 * this at least will narrow our context to GQL as opposed to anything that could go wrong on the platform
 */
class GqlMutationError extends GqlError {
  static mapped = (e: Error): GqlMutationError => new GqlMutationError(e.message, e)
}

/**
 * If this error is thrown at runtime it generally implies a bug on the engineering side. This can however be used
 * to satisfy the compiler.
 */
export class ThisShouldNeverHappenError extends PlatformError {}

/**
 * Can be used as the default case in switch/case statements.
 */
export class MissingCaseError extends ThisShouldNeverHappenError {}

/**
 * This error is thrown when an external schema does not match what the application expects.
 * Virtually always indicates a developer broken contract.
 */
export class SchemaMismatchError extends PlatformError {}

/**
 * This error is when you are manually telling a transaction to roll back.
 */
export class RollbackTransactionError extends PlatformError {}

/**
 * This error is thrown when a lock is not acquired.
 */
export class LockNotAcquiredError extends PlatformError {}

/**
 * This error is thrown when a lock is not acquired and the maximum number of retries has been exceeded.
 */
export class LockRetriesExceededError extends PlatformError {}

/**
 * Handling an exhaustive list of possible return values from the database is often times unfeasible.
 * This is a catch-all error for unhandled database errors.
 */
export class UnexpectedDatabaseError extends PlatformError {
  static mapToUnexpectedDatabaseError(u: unknown): UnexpectedDatabaseError {
    Log.warn('An unexpected database error occurred', u)

    if (
      u instanceof UnexpectedDatabaseError ||
      u instanceof RollbackTransactionError ||
      u instanceof LockNotAcquiredError ||
      u instanceof LockRetriesExceededError
    )
      return u

    if (u instanceof Error) return new UnexpectedDatabaseError(`An unexpected database error occurred: ${u.message}`, u)

    if (typeof u === 'string') return new UnexpectedDatabaseError(`An unexpected database error occurred: ${u}`)

    return new UnexpectedDatabaseError(
      `An unexpected database error occurred with value of ${JSON.stringify(u, null, 2)}`,
    )
  }
}
