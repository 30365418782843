import { Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Composable } from '../../utils/Composable'

export type UiErrorDetail = {
  title?: string
  content: string
}

export interface ErrorInstance {
  destroy: () => void
  update: (errorDetailUpdate: UiErrorDetail) => void
}

export interface ErrorModalState {
  pushError: (error: UiErrorDetail) => void
  clearAllErrors: () => void
}

export const ErrorModalContext = React.createContext<ErrorModalState>({
  pushError: _ => {},
  clearAllErrors: () => {},
})

const ErrorModal: React.FC<Composable> = ({ children }) => {
  const [pendingErrors, setPendingErrors] = useState<UiErrorDetail[]>([])

  const clearAllErrors = useCallback(() => Modal.destroyAll(), [])
  const pushError = useCallback((error: UiErrorDetail) => {
    setPendingErrors(prevErrors => [error, ...prevErrors])
  }, [])

  useEffect(() => {
    if (pendingErrors.length > 0) {
      Modal.error({
        ...pendingErrors[0],
      })

      setPendingErrors(prevErrors => prevErrors.slice(1))
    }
  }, [clearAllErrors, pendingErrors])

  return (
    <ErrorModalContext.Provider value={{ pushError, clearAllErrors }}>
      {children}
    </ErrorModalContext.Provider>
  )
}

export const useErrorModal = () => React.useContext(ErrorModalContext)

export default ErrorModal
