import { BzDateTime } from '@breezy/shared'
import { Form, Radio } from 'antd'
import { memo, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import DatePicker from '../../../components/DatePicker/DatePicker'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'

interface ConfigureImportInfoSectionProps {}

export const ImportInfoSectionHeader = () => {
  return (
    <div>
      <div className="mb-1 text-base font-semibold text-bz-text">
        Import Info
      </div>
      <div className="text-md text-bz-text-tertiary">
        Configure the import settings for this existing plan.
      </div>
    </div>
  )
}

export const ConfigureImportInfoSection = memo<ConfigureImportInfoSectionProps>(
  () => {
    const isMobile = useIsMobile()
    const tzId = useExpectedCompanyTimeZoneId()
    const {
      control,
      formState: { errors },
      watch,
      setValue,
    } = useFormContext<ConfigureMaintenancePlanFormData>()

    const isImportedPlan = watch('isImportedPlan')
    const activationDate = watch('activationDate')

    const planRenewalDateValue = useMemo(() => {
      const activationDateTime = BzDateTime.fromDateString(activationDate, tzId)
      return activationDateTime.plusYears(1).toDate()
    }, [activationDate, tzId])

    return (
      <div className="flex flex-row gap-x-3">
        <div className="mb-2 flex flex-1 flex-col gap-y-6">
          <ImportInfoSectionHeader />

          <div>
            <Form.Item
              label="Plan Expiration Date"
              required={true}
              className="mb-0"
            >
              <DatePicker
                size={isMobile ? 'large' : 'middle'}
                value={planRenewalDateValue}
                format={'MMM d, yyyy'}
                className="w-full"
                onChange={d => {
                  if (d) {
                    setValue(
                      'activationDate',
                      BzDateTime.fromDate(d, tzId)
                        .plusYears(-1)
                        .toLocalDateString(),
                    )
                  }
                }}
              />
            </Form.Item>
            <div className="letter-spacing-[-0.14px] mt-[4px] text-[14px] leading-[22px] text-[#8C8C8C]">
              The date the imported plan will expire and need to be renewed
              and/or resold.
            </div>
          </div>

          <ReactHookFormItem
            noBottomMargin
            control={control}
            errors={errors}
            name="isAutoRenewing"
            label={'Auto-renews'}
            required={true}
            render={({ field }) => (
              <Radio.Group {...field} size={isMobile ? 'large' : 'middle'}>
                <Radio.Button value={true} disabled={isImportedPlan}>
                  Yes
                </Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            )}
            helperText="Imported plans will not auto-renew."
          />
        </div>

        <div className="flex-1" />
      </div>
    )
  },
)
