import { z } from 'zod'
import { RichTextAreaSchema } from '../RichTextAreaField/RichTextAreaField.schema'

export const SendAsEmailFormSchema = z.object({
  emailAddress: z
    .string()
    .nonempty('You must include an email')
    .email()
    .describe('Email Address'),
  subject: z
    .string()
    .nonempty('You must include a subject')
    .describe('Subject Header'),
  body: RichTextAreaSchema.describe('Body'),
})

export type SendAsEmailFormData = z.infer<typeof SendAsEmailFormSchema>
