import { useState } from 'react'

import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'

export const useFullscreenIframe = () => {
  const [isIframeOpen, setIframeOpen] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')

  const toggleIframe = (url: string) => {
    setIframeSrc(url)
    setIframeOpen(prev => !prev)
  }

  const iframe = (
    <FullScreenModal
      isOpen={isIframeOpen}
      setIsOpen={setIframeOpen}
      className="flex items-center justify-center"
    >
      <iframe
        title="Wisetack Loan Application"
        src={iframeSrc}
        className="m-auto h-full w-full"
        frameBorder="0"
      />
    </FullScreenModal>
  )
  return { iframe, toggleIframe }
}
