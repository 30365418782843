import { toTitleCase } from '@breezy/shared/src'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faMobileSignal,
  faOctagonExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import RenderIf from '../../elements/RenderIf/RenderIf'
import { LoadingSpinner } from '../LoadingSpinner'

type UploadingStatusContentProps = {
  header: string
  operationName: string
}

export const UploadingStatusContent = React.memo<UploadingStatusContentProps>(
  ({ header, operationName }) => {
    const [showSlowInternetCheckCard, setShowSlowInternetCheckCard] =
      useState(false)
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowSlowInternetCheckCard(true)
      }, 15000) // 15000 milliseconds = 15 seconds

      return () => clearTimeout(timer) // Cleanup the timer when the component unmounts or updates
    }, [])
    return (
      <div className="flex flex-col items-center justify-center pb-6 pt-6 transition-all ease-in-out">
        <div className="flex h-[52px] w-[52px] flex-col items-center justify-center">
          <LoadingSpinner noMinHeight />
        </div>
        <div className="mb-4 mt-4 text-[30px] font-semibold">{header}</div>
        <div className="flex flex-col gap-3">
          <InfoCard
            icon={faOctagonExclamation}
            cardBorderClassName="border-bz-orange-300"
            cardBgClassName="bg-bz-orange-100"
            iconBgClassName="bg-bz-orange-200"
            iconColorClassName="text-bz-orange-800"
            iconSizeClassName="h-5 w-5"
          >
            <span className="font-semibold">
              Please do not close or navigate away from this page
            </span>{' '}
            until {operationName} is complete.
          </InfoCard>
          <RenderIf if={showSlowInternetCheckCard}>
            <InfoCard
              icon={faMobileSignal}
              cardBorderClassName="border-[#91CAFF]"
              cardBgClassName="bg-[#E6F4FF]"
              iconBgClassName="bg-[#BAE0FF]"
              iconColorClassName="text-[#003EB3]"
              iconSizeClassName="h-4 w-4"
            >
              <span className="font-semibold">
                {toTitleCase(operationName)} is taking longer than expected.
              </span>{' '}
              Check if your device is connected to the internet.
            </InfoCard>
          </RenderIf>
        </div>
      </div>
    )
  },
)

type InfoCardProps = {
  icon: IconProp
  cardBorderClassName: string
  cardBgClassName: string
  iconBgClassName: string
  iconColorClassName: string
  iconSizeClassName: string
}
const InfoCard = React.memo<React.PropsWithChildren<InfoCardProps>>(
  ({
    icon,
    cardBorderClassName,
    cardBgClassName,
    iconBgClassName,
    iconColorClassName,
    iconSizeClassName,
    children,
  }) => {
    return (
      <div
        className={classNames(
          'flex items-center gap-3 rounded-lg border border-solid  px-4 py-3',
          cardBorderClassName,
          cardBgClassName,
        )}
      >
        <div
          className={classNames(
            'flex h-[28px] w-[28px] items-center justify-center rounded-full p-2',
            iconBgClassName,
          )}
        >
          <FontAwesomeIcon
            className={classNames(iconSizeClassName, iconColorClassName)}
            icon={icon}
          />
        </div>
        <div className="flex-1">{children}</div>
      </div>
    )
  },
)
