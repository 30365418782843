import { CardOnFile } from '@breezy/shared'
import { ExternalCardsOnFileQuery } from '../../query'

export const convertPaymentMethodRecordToCardOnFile = (
  row: ExternalCardsOnFileQuery['paymentMethodRecords'][0],
): CardOnFile => ({
  ...row,
  paymentMethodType: row.paymentMethodType,
  // eventually when we refactor the `payment_subscriptions` table to fkey into `payment_method_records`
  // we should move the hasura relationship from the external_payment_method_id to the payment_method_record_guid
  hasPaymentSubscription: row.paymentSubscriptions?.length > 0,
})
