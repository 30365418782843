import { Form } from 'antd'
import React from 'react'

import { useStringFieldInfo, useTsController } from '@ts-react/form'

type BaseTextFieldProps = React.PropsWithChildren

const BaseTextField = React.memo<BaseTextFieldProps>(({ children }) => {
  const { error } = useTsController<string>()

  const { label } = useStringFieldInfo()

  return (
    <Form.Item
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      {children}
    </Form.Item>
  )
})

export default BaseTextField
