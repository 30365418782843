import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  InstalledEquipmentSummary,
  LocalDate,
  formatEquipmentTypeString,
} from '@breezy/shared'
import { faCopy, faTools } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import CopyToClipboard from '../../../elements/CopyToClipboard/CopyToClipboard'
import { EquipmentDetailsLink } from '../../EquipmentDetailsModal/EquipmentDetailsModal'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

export const InstalledEquipmentCollapsible = ({
  installedEquipment,
  onEdit,
  onAdd,
  editable = true,
}: {
  installedEquipment: InstalledEquipmentSummary[]
  onEdit?: (equipment: InstalledEquipmentSummary) => void
  onAdd?: () => void
  editable?: boolean
}) => {
  return (
    <BzCollapsible
      title="Equipment"
      titleIcon={faTools}
      onPlus={editable ? onAdd : undefined}
    >
      {installedEquipment.map((equipment, equipmentIndex) => {
        return (
          <div key={equipment.installedEquipmentGuid} className="mt-2">
            <InstalledEquipmentCollapsibleItem
              equipment={equipment}
              defaultOpen={equipmentIndex < 3}
              onEdit={onEdit}
              editable={editable}
            />
          </div>
        )
      })}
    </BzCollapsible>
  )
}

export const InstalledEquipmentCollapsibleItem = ({
  equipment,
  defaultOpen,
  onEdit,
  editable = true,
}: {
  equipment: InstalledEquipmentSummary
  defaultOpen: boolean
  onEdit?: (equipment: InstalledEquipmentSummary) => void
  editable?: boolean
}) => {
  const age = useMemo(() => {
    if (!equipment.installationDate) {
      return 0
    }
    return Math.floor(
      equipment.installationDate.until(LocalDate.now()).toTotalMonths() / 12,
    )
  }, [equipment.installationDate])
  return (
    <CollapsibleItem
      key={equipment.installedEquipmentGuid}
      title={
        <EquipmentDetailsLink
          equipment={equipment}
          linkText={formatEquipmentTypeString(equipment.equipmentType)}
        />
      }
      defaultOpen={defaultOpen}
      contentList={[
        {
          key: 'Manufacturer:',
          value: equipment.manufacturer,
        },
        {
          key: 'Installed On:',
          value: equipment.installationDate
            ? `${equipment.installationDate.format(
                DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                  ENGLISH_LOCALE,
                ),
              )} (${age} year${age === 1 ? '' : 's'} old)`
            : '',
        },
        {
          key: 'Installed By:',
          value: equipment.installationParty,
        },
        {
          key: 'Serial Number:',
          value: equipment.serialNumber ? (
            <CopyToClipboard
              payload={equipment.serialNumber}
              label={
                <span className="flex items-center gap-2">
                  {equipment.serialNumber}
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              }
            />
          ) : undefined,
        },
        {
          key: 'Model Number:',
          value: equipment.modelNumber ? (
            <CopyToClipboard
              payload={equipment.modelNumber}
              label={
                <span className="flex items-center gap-2">
                  {equipment.modelNumber}
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              }
            />
          ) : undefined,
        },
        {
          key: 'Est. End of Life:',
          value: equipment.estimatedEndOfLifeDate
            ? equipment.estimatedEndOfLifeDate.format(
                DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                  ENGLISH_LOCALE,
                ),
              )
            : '',
        },
        {
          key: 'Avg. Life Expectancy:',
          value: equipment.averageLifeExpectancyYears,
        },
      ]}
      onEdit={editable && onEdit ? () => onEdit?.(equipment) : undefined}
    />
  )
}
