import React from 'react'

type SectionHeaderTextProps = {
  text: string
}

export const SectionHeaderText = React.memo(
  ({ text }: SectionHeaderTextProps) => {
    return (
      <div className="break-words text-[20px] font-[600] leading-[28px] text-[rgba(0,0,0,0.88)]">
        {text}
      </div>
    )
  },
)
