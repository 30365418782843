import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type ActionBarProps = React.PropsWithChildren<{
  className?: string
}>

export const ActionBar = React.memo<ActionBarProps>(
  ({ children, className }) => {
    const isMobile = useIsMobile()
    return (
      <div
        data-testid="onsite-action-bar"
        className={classNames(
          'flex flex-row items-center space-x-3 border-0 border-t-8 border-solid border-t-bz-gray-300',
          // On mobile, give it some extra bottom-padding for the iPhone action bar
          // https://breezy-rgo6994.slack.com/files/U05PFL26A0K/F07ANERDYER/cleanshot_2024-07-02_at_19.00.40_2x.png
          isMobile ? 'p-4 pb-8' : ' py-5',
          className,
        )}
      >
        {children}
      </div>
    )
  },
)
