import {
  faEllipsis,
  faEllipsisVertical,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import { ReactNode, useState } from 'react'
import ActionItems, { ActionItem } from './ActionItems'
import './ActionItems.less'

type ActionItemsWithButtonProps = {
  readonly direction?: 'horizontal' | 'vertical'
  readonly actionItems: ActionItem[]
  readonly customButton?: ReactNode
  readonly dataTestId?: string
}

const ActionItemsButton = ({
  direction = 'horizontal',
  actionItems,
  customButton,
  dataTestId,
}: ActionItemsWithButtonProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  return (
    <Popover
      content={
        <ActionItems
          actionItems={actionItems.map(ac => ({
            ...ac,
            onClick: () => {
              setPopoverVisible(false)
              ac.onClick()
            },
          }))}
        />
      }
      placement="bottom"
      overlayClassName="action-items-with-ellipsis"
      showArrow={false}
      trigger="click"
      onOpenChange={setPopoverVisible}
      open={popoverVisible}
    >
      {customButton ?? (
        <Button data-testid={dataTestId} className="px-2">
          <FontAwesomeIcon
            icon={direction === 'horizontal' ? faEllipsis : faEllipsisVertical}
            className="pt-1 text-[16px]"
          />
        </Button>
      )}
    </Popover>
  )
}

export default ActionItemsButton
