import { BzDateFns, formatMoney, IsoDateString } from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import useIsMobile from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
type ProcessingPaymentsModalMeta = {
  paymentAmountUsd: number
  occurredAt: IsoDateString
}
type ProcessingPaymentsModalProps = ProcessingPaymentsModalMeta & {
  onClose: () => void
}

export const ProcessingPaymentsModal = React.memo<ProcessingPaymentsModalProps>(
  ({ onClose, paymentAmountUsd, occurredAt }) => {
    const isMobile = useIsMobile()
    return (
      <OnsiteBasicModal
        open
        drawer
        headerBordered
        onClose={onClose}
        header="Pending ACH Payment"
        footer={
          <Button
            className={classNames('mt-4', {
              'mb-5': isMobile,
            })}
            size="large"
            type="primary"
            onClick={onClose}
          >
            Got It
          </Button>
        }
      >
        <ProcessingPaymentsContent
          paymentAmountUsd={paymentAmountUsd}
          occurredAt={occurredAt}
        />
      </OnsiteBasicModal>
    )
  },
)

export const useProcessingPaymentsModal = () => {
  const [props, setProps] = useState<ProcessingPaymentsModalMeta>()
  const showProcessingPaymentsModal = useCallback(
    (props: ProcessingPaymentsModalMeta) => {
      setProps(props)
    },
    [],
  )
  const hideProcessingPaymentsModal = useCallback(() => {
    setProps(undefined)
  }, [])
  const content = useMemo(() => {
    if (!props) return null
    return (
      <ProcessingPaymentsModal
        {...props}
        onClose={hideProcessingPaymentsModal}
      />
    )
  }, [hideProcessingPaymentsModal, props])

  const processingPaymentsModal = useMemo(() => {
    return ReactDOM.createPortal(content, document.body)
  }, [content])

  return {
    processingPaymentsModal,
    showProcessingPaymentsModal,
    hideProcessingPaymentsModal,
  }
}

export const ProcessingPaymentsContent =
  React.memo<ProcessingPaymentsModalMeta>(
    ({ paymentAmountUsd, occurredAt }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const formattedDate = BzDateFns.formatFromISO(
        occurredAt,
        'EEEE, MMMM d',
        tzId,
      )

      return (
        <>
          <p>
            A payment of{' '}
            <span className="font-semibold">
              {formatMoney(paymentAmountUsd)} was made on {formattedDate}
            </span>
            , and is currently being processed. This payment will clear the
            outstanding balance.
          </p>
          <p>
            ACH payments typically take 3-5 business days to process.{' '}
            <span className="font-semibold">
              Additional payments cannot be collected
            </span>{' '}
            until this ACH payment either clears or fails.
          </p>
        </>
      )
    },
  )
