import {
  BzDateFns,
  formatUsc,
  getLastVisitedAtMaintPlansV3,
  isNullish,
  isVisitUnused,
} from '@breezy/shared'
import { memo, useMemo } from 'react'
import { Banner } from '../../elements/Banner/Banner'
import { SmallCard } from '../../elements/Card/SmallCard'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { KeyValueRow } from './components/KeyValueRow'
import { useLocationActiveMaintenancePlan } from './hooks/useLocationActiveMaintenancePlan'

type Props = {
  locationActiveMaintenancePlan?: ReturnType<
    typeof useLocationActiveMaintenancePlan
  >['activeMaintenancePlan']
}

export const LocationOptionMaintenancePlanCard = memo<Props>(
  ({ locationActiveMaintenancePlan }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const lastVisitedAt = useMemo(
      () =>
        getLastVisitedAtMaintPlansV3(
          locationActiveMaintenancePlan?.visits ?? [],
        ),
      [locationActiveMaintenancePlan],
    )
    const totalVisits = useMemo(
      () => locationActiveMaintenancePlan?.visits.length,
      [locationActiveMaintenancePlan],
    )
    const numVisitsRemaining = useMemo(
      () =>
        locationActiveMaintenancePlan?.visits.filter(v => isVisitUnused(v))
          .length,
      [locationActiveMaintenancePlan],
    )

    return isNullish(locationActiveMaintenancePlan) ? (
      <Banner color="gray">
        <div className="w-full text-sm text-bz-text-tertiary">
          This location does not have a maintenance plan!
        </div>
      </Banner>
    ) : (
      <div className="w-full pr-0 md:w-1/2 md:pr-1.5">
        <SmallCard
          header={
            locationActiveMaintenancePlan.maintenancePlanDefinition
              ?.marketingInfo?.name ?? 'Maintenance Plan'
          }
          headClassName="text-base"
        >
          <div className="space-y-2">
            <KeyValueRow
              label="Sold Date"
              value={
                locationActiveMaintenancePlan.activatedAt
                  ? BzDateFns.formatFromISO(
                      locationActiveMaintenancePlan.activatedAt,
                      'MMM d, yyyy',
                      tzId,
                    )
                  : undefined
              }
            />
            <KeyValueRow
              label="Annual Revenue"
              value={
                locationActiveMaintenancePlan.yearlyPriceUsc
                  ? formatUsc(locationActiveMaintenancePlan.yearlyPriceUsc)
                  : undefined
              }
            />
            <KeyValueRow
              label="Plan Visits Remaining"
              value={`${numVisitsRemaining} of ${totalVisits}`}
            />
            <KeyValueRow
              label="Last Maint. Date"
              value={
                lastVisitedAt &&
                BzDateFns.formatFromISO(lastVisitedAt, 'MMM d, yyyy', tzId)
              }
            />
          </div>
        </SmallCard>
      </div>
    )
  },
)
