import {
  BzDateTime,
  LocalDateString,
  MaintenancePlanPaymentInterval,
  NumPeriodsPerYearForInterval,
  TaxRateSetEventData,
  TimeZoneId,
  toTitleCase,
  uscDivide,
  uscMultiply,
} from '@breezy/shared'

export const toPlanBillingLabel = (
  isFreePlan: boolean,
  isAutoRenewing: boolean,
  paymentInterval: MaintenancePlanPaymentInterval,
) => {
  if (isFreePlan) {
    return isAutoRenewing
      ? 'No Billing - Free Plan - Forever'
      : 'No Billing - Free Plan  - 1 Year'
  }

  return `Annual Plan, Paid ${toTitleCase(paymentInterval.toString())}`
}

export const getBillingAnchorDate = (
  billingAnchorDay: string,
  activationDate: LocalDateString,
  tzId: TimeZoneId,
): LocalDateString | undefined => {
  const billingAnchorDayOfMonth = Number(billingAnchorDay)
  if (
    isNaN(billingAnchorDayOfMonth) ||
    billingAnchorDayOfMonth < 1 ||
    billingAnchorDayOfMonth > 31
  ) {
    return undefined
  }

  let currentDateTime = BzDateTime.fromDateString(activationDate, tzId)
  while (currentDateTime.dayOfMonth() !== billingAnchorDayOfMonth) {
    currentDateTime = currentDateTime.plusDays(1)
  }
  return currentDateTime.toLocalDateString()
}

export const calcSubtotalPerInterval = ({
  isFreePlan,
  isImportedPlan,
  subtotalYearlyPriceUsc,
  adjustmentYearlyPriceUsc,
  billingPaymentInterval,
}: {
  isFreePlan: boolean
  isImportedPlan: boolean
  subtotalYearlyPriceUsc: number
  adjustmentYearlyPriceUsc: number
  billingPaymentInterval: MaintenancePlanPaymentInterval
}) =>
  isFreePlan || isImportedPlan
    ? 0
    : uscDivide(
        subtotalYearlyPriceUsc + adjustmentYearlyPriceUsc,
        NumPeriodsPerYearForInterval[billingPaymentInterval],
      )

export const calcMaintenancePlanPricing = ({
  isFreePlan,
  isImportedPlan,
  subtotalYearlyPriceUsc,
  adjustmentYearlyPriceUsc,
  billingPaymentInterval,
  taxRate,
}: {
  isFreePlan: boolean
  isImportedPlan: boolean
  subtotalYearlyPriceUsc: number
  adjustmentYearlyPriceUsc: number
  billingPaymentInterval: MaintenancePlanPaymentInterval
  taxRate: TaxRateSetEventData
}) => {
  const intervalSubtotalPriceUsc = calcSubtotalPerInterval({
    isFreePlan,
    isImportedPlan,
    subtotalYearlyPriceUsc,
    adjustmentYearlyPriceUsc,
    billingPaymentInterval,
  })

  const intervalTaxPriceUsc = uscMultiply(
    intervalSubtotalPriceUsc,
    taxRate.rate,
  )

  const intervalTotalPriceUsc = intervalSubtotalPriceUsc + intervalTaxPriceUsc

  const annualTotalPriceUsc = uscMultiply(
    intervalTotalPriceUsc,
    NumPeriodsPerYearForInterval[billingPaymentInterval],
  )

  return {
    intervalSubtotalPriceUsc,
    intervalTaxPriceUsc,
    intervalTotalPriceUsc,
    annualTotalPriceUsc,
  }
}

export const zeroTaxRate: TaxRateSetEventData = {
  // This needs to be a real guid to satisfy the zod guidSchema form validation
  taxRateGuid: '63feeac0-4ec1-11ef-8646-af448ef42b3e',
  rate: 0,
}

export const isZeroTaxRate = (taxRate: {
  taxRateGuid: string | null
  rate: number
}) => taxRate.taxRateGuid === zeroTaxRate.taxRateGuid
