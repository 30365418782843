import { gql } from '../../generated'

export const FETCH_END_OF_JOB_END_OF_APPOINTMENT_WORKFLOW_ENABLED =
  gql(/* GraphQL */ `
    query FetchEndOfJobAppointmentWorkflowEnabled($companyGuid: uuid!) {
      companyConfigByPk(companyGuid: $companyGuid) {
        endOfJobAppointmentWorkflowEnabled
      }
    }
  `)
