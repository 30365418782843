import { isNullish } from '@breezy/shared'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { ReactNode } from 'react'

type BzRadioButtonProps = {
  label?: ReactNode
  value: string
  checked: boolean
  onChange: (value: string) => void
  name?: string
  disabled?: boolean
  className?: string
  labelClassName?: string
  padRight?: boolean
}

const BzRadioButton = ({
  label,
  value,
  checked,
  onChange,
  name,
  disabled,
  className,
  labelClassName,
  padRight = true,
}: BzRadioButtonProps) => {
  return (
    <div
      className={classNames(
        'flex items-center',
        disabled ? 'opacity-50' : 'cursor-pointer',
        className,
      )}
      onClick={() => {
        if (!disabled) {
          onChange(value)
        }
      }}
    >
      <input
        name={name}
        type="radio"
        value={value}
        checked={checked}
        className="hidden"
        readOnly
      />

      <FontAwesomeIcon
        icon={checked ? faDotCircle : faCircle}
        className={classNames('h-6 w-6', checked ? 'blue6' : 'grey7', {
          'mr-3': padRight,
        })}
      />

      {isNullish(label) ? null : <div className={labelClassName}>{label}</div>}
    </div>
  )
}

export default BzRadioButton
