import { useMemo } from 'react'
import { useQuery } from 'urql'
import { gql } from '../../../generated'

const INVOICE_LINKS_QUERY = gql(/* GraphQL */ `
  query InvoiceLinks($invoiceGuid: uuid!) {
    invoices(where: { invoiceGuid: { _eq: $invoiceGuid } }) {
      jobLink {
        jobGuid
      }
      jobAppointmentLink {
        jobAppointmentGuid
      }
      maintenancePlanLink {
        maintenancePlanGuid
      }
      locationLink {
        locationGuid
      }
      estimateLink {
        estimateGuid
      }
    }
  }
`)

export const useInvoiceLinks = (invoiceGuid: string) => {
  const [{ data }] = useQuery({
    query: INVOICE_LINKS_QUERY,
    variables: { invoiceGuid },
  })

  return useMemo(
    () => ({
      jobGuid: data?.invoices[0]?.jobLink?.jobGuid,
      jobAppointmentGuid:
        data?.invoices[0]?.jobAppointmentLink?.jobAppointmentGuid,
      maintenancePlanGuid:
        data?.invoices[0]?.maintenancePlanLink?.maintenancePlanGuid,
      locationGuid: data?.invoices[0]?.locationLink?.locationGuid,
      estimateGuid: data?.invoices[0]?.estimateLink?.estimateGuid,
    }),
    [data],
  )
}
