import xss from 'xss'
import { AsyncFn, Log, minifyUrl } from '../../../common'
import { ForCompany, ForCompanyUser } from '../../Company/Company'
import { WhiteLabelEmailer } from '../../Email/EmailTypes'
import { FeatureFlagReader } from '../../FeatureFlags/FeatureFlags'
import { InvoiceV2SendInvoice } from '../../InvoicesV2/InvoicesV2'
import { buildWhiteLabelSMSMessage } from '../../Sms/MessageBuilding'
import { ISmsMessenger } from '../../Sms/types'
import { BreezySystemUserGuid } from '../../Users/User'
import { IBillingProfileReader } from '../CompanyBillingProfileTypes'
import { IEventSourceItemLoader } from '../FinanceDocumentTypes'
import { SendInvoiceRequest } from './InvoiceTypes'

export const generateInvoicePaymentLink = (
  accountAppFullUrl: string,
  invoiceReferenceNumber: string,
  contactGuid = '',
  amountDueUsd?: number,
) => {
  const contactGuidSuffix = contactGuid ? `/${contactGuid}` : ''
  const amountDueUsdSuffix = amountDueUsd ? `?amountDueUsd=${amountDueUsd}` : ''
  return `${accountAppFullUrl}/invoice/${invoiceReferenceNumber}${contactGuidSuffix}${amountDueUsdSuffix}`
}

export const generateMinifiedInvoicePaymentLink = async (...props: Parameters<typeof generateInvoicePaymentLink>) =>
  minifyUrl(generateInvoicePaymentLink(...props))

export type CreateSendInvoiceSmsRequest = ForCompany<Omit<SendInvoiceRequest, 'deliveryMethod'>>
export type InvoiceSenderSms = AsyncFn<CreateSendInvoiceSmsRequest>
export const createSendInvoiceSms =
  (sms: ISmsMessenger): AsyncFn<CreateSendInvoiceSmsRequest> =>
  async request => {
    const { to, body } = request

    try {
      await sms({
        to,
        message: buildWhiteLabelSMSMessage({
          message: xss(body),
        }),
      })
      Log.info(`Consumer SMS sent for Send Invoice ${request.invoiceGuid}.`)
      return
    } catch (error) {
      Log.error(`Consumer SMS failed for Send Invoice ${request.invoiceGuid}.`, error)
      throw error
    }
  }

export type InvoiceSender = AsyncFn<
  ForCompanyUser<
    SendInvoiceRequest & {
      companyName: string
    }
  >
>

export type CreateSendInvoiceEmailRequest = ForCompany<
  Omit<SendInvoiceRequest, 'deliveryMethod'> & {
    companyName: string
  }
>
export type InvoiceSenderEmail = AsyncFn<CreateSendInvoiceEmailRequest>
export const createSendInvoiceEmail =
  (emailer: WhiteLabelEmailer, billingProfileReader: IBillingProfileReader): AsyncFn<CreateSendInvoiceEmailRequest> =>
  async request => {
    const { companyGuid, to, subject, body, companyName } = request

    const billingProfile = await billingProfileReader({ companyGuid })
    const sanitizedSubject = subject ? xss(subject) : subject

    try {
      const publishEmailResult = await emailer({
        companyGuid,
        to,
        subject: sanitizedSubject ?? '',
        message: xss(body),
        templateId: 'basic-branded-email',
        messageMetadata: {
          companyLogoUrl: billingProfile.businessLogoURL,
          companyName,
          contactPhoneNumber: billingProfile.businessBillingContactPhoneNumber.phoneNumber,
          contactEmailAddress: billingProfile.businessBillingEmailAddress.emailAddress,
          websiteUrl: billingProfile.businessWebsiteURL,
          websiteDisplayName: billingProfile.businessWebsiteDisplayName,
        },
      })

      Log.info(`Consumer Email sent for Send Invoice ${request.invoiceGuid}.`)
      return publishEmailResult
    } catch (error) {
      Log.error(`Consumer Email failed for Send Invoice ${request.invoiceGuid}.`, error)
      throw error
    }
  }

export const createInvoiceSender =
  (
    emailSender: InvoiceSenderEmail,
    smsSender: InvoiceSenderSms,
    loader: IEventSourceItemLoader,
    featureFlagReader: FeatureFlagReader,
    invoiceV2SendInvoice: InvoiceV2SendInvoice,
  ): InvoiceSender =>
  async request => {
    const { deliveryMethod, companyGuid, invoiceGuid, userGuid, paymentLink } = request

    if (deliveryMethod === 'EMAIL') {
      await emailSender(request)
    } else if (deliveryMethod === 'SMS') {
      await smsSender(request)
    } else {
      throw new Error('Could not Send Invoice through an invalid delivery method')
    }

    const invoiceV2Enabled = await featureFlagReader('invoicesV2', request.companyGuid, BreezySystemUserGuid)
    if (invoiceV2Enabled) {
      await invoiceV2SendInvoice({
        invoiceGuid,
        companyGuid,
        userGuid,
        paymentLink,
        deliveryMethod,
      })
    } else {
      const invoice = await loader.loadInvoice({
        companyGuid,
        entityGuid: invoiceGuid,
      })
      await invoice.sendSelfServePaymentLink({ userGuid, paymentLink })
    }
  }
