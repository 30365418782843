import {
  Address,
  LocalDateString,
  MaintenancePlanPaymentInterval,
  PaymentMethod,
} from '@breezy/shared'

export enum PaymentAsyncUiStatus {
  IDLE = 'IDLE',
  PREPARING = 'PREPARING',
  VERIFYING = 'VERIFYING',
  SUBMITTING = 'SUBMITTING',
  RECORDING = 'RECORDING',
  SUCCEEDED = 'SUCCEEDED',
}

export type PaymentMethodCreationContextProps = {
  isEditing?: boolean
  paymentBillingDetails: PaymentSubscriptionBillingDetails
  paymentInterval: MaintenancePlanPaymentInterval
  paymentPeriodAmountUsc: number
  onPaymentAsyncUiStatusChange: (status: PaymentAsyncUiStatus) => void
}

export type PaymentMethodFormProps = {
  paymentContext: PaymentMethodCreationContextProps
  onSuccess: (
    paymentMethodId: string,
    paymentMethodAdditionalInfo: string,
    paymentMethod: PaymentMethod,
  ) => void
}

export type PaymentBillingDetails = {
  readonly name: string
  readonly address: Address
}

export type PaymentSubscriptionBillingDetails = PaymentBillingDetails & {
  readonly customBillingStartDate?: LocalDateString
  readonly autoRenewSubscription: boolean
}
