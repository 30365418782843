import React, { CSSProperties } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useIsMobile from '../../hooks/useIsMobile'
import { useIsCompanyUser } from '../../providers/PrincipalUser'
import { Styled } from '../../utils/Stylable'
import { m } from '../../utils/react-utils'

const LEGACY_PADDING_VALUE = 16

type ReloadOnRouteChangeStrategy =
  | 'none'
  | 'path-change-only'
  | 'path-or-query-params'

export type PageProps = {
  children: React.ReactNode
  requiresCompanyUser: boolean
  reloadOnRouteChangeStrategy?: ReloadOnRouteChangeStrategy
} & (
  | {
      disableLayout: true
      style?: never
      className?: never
    }
  | {
      disableLayout?: false
      readonly style?: React.CSSProperties
      readonly className?: string
    }
)

export const Page = m<Styled<PageProps>>(
  ({
    style,
    className,
    requiresCompanyUser,
    children,
    reloadOnRouteChangeStrategy = 'none',
    disableLayout = false,
  }) => {
    const isCompanyUser = useIsCompanyUser()
    const isMobile = useIsMobile()

    const loc = useLocation()
    const key =
      reloadOnRouteChangeStrategy === 'none'
        ? undefined
        : reloadOnRouteChangeStrategy === 'path-change-only'
        ? loc.pathname
        : `${loc.pathname}__${loc.search}`

    if (requiresCompanyUser && !isCompanyUser) {
      return <Navigate to="/no-company" replace />
    }

    if (disableLayout) {
      return <React.Fragment key={key}>{children}</React.Fragment>
    }

    const effectiveStyles: CSSProperties = {
      padding: isMobile ? 0 : LEGACY_PADDING_VALUE,
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      ...(style || {}),
    }

    return (
      <div key={key} style={effectiveStyles} className={className}>
        {children}
      </div>
    )
  },
)
