import { Log } from './logging'

// Copied verbatim from dd-trace/dogstatsd
export interface IMetrics {
  /**
   * Increments a metric by the specified value, optionally specifying tags.
   * @param {string} stat The dot-separated metric name.
   * @param {number} value The amount to increment the stat by.
   * @param {[tag:string]:string|number} tags Tags to pass along, such as `{ foo: 'bar' }`. Values are combined with config.tags.
   */
  increment(stat: string, value?: number, tags?: { [tag: string]: string | number }): void

  /**
   * Decrements a metric by the specified value, optionally specifying tags.
   * @param {string} stat The dot-separated metric name.
   * @param {number} value The amount to decrement the stat by.
   * @param {[tag:string]:string|number} tags Tags to pass along, such as `{ foo: 'bar' }`. Values are combined with config.tags.
   */
  decrement(stat: string, value?: number, tags?: { [tag: string]: string | number }): void

  /**
   * Sets a distribution value, optionally specifying tags.
   * @param {string} stat The dot-separated metric name.
   * @param {number} value The amount to increment the stat by.
   * @param {[tag:string]:string|number} tags Tags to pass along, such as `{ foo: 'bar' }`. Values are combined with config.tags.
   */
  distribution(stat: string, value?: number, tags?: { [tag: string]: string | number }): void

  /**
   * Sets a gauge value, optionally specifying tags.
   * @param {string} stat The dot-separated metric name.
   * @param {number} value The amount to increment the stat by.
   * @param {[tag:string]:string|number} tags Tags to pass along, such as `{ foo: 'bar' }`. Values are combined with config.tags.
   */
  gauge(stat: string, value?: number, tags?: { [tag: string]: string | number }): void

  /**
   * Forces any unsent metrics to be sent
   *
   * @beta This method is experimental and could be removed in future versions.
   */
  flush(): void
}

export const blackHoleMetrics = (): IMetrics => ({
  increment: function (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ): void {
    Log.debug(`increment: stat=${stat}, value=${value}, ${tags}`)
  },
  decrement: function (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ): void {
    Log.debug(`decrement: stat=${stat}, value=${value}, ${tags}`)
  },
  distribution: function (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ): void {
    Log.debug(`distribution: stat=${stat}, value=${value}, ${tags}`)
  },
  gauge: function (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ): void {
    Log.debug(`gauge: stat=${stat}, value=${value}, ${tags}`)
  },
  flush: function (): void {
    Log.debug(`flush`)
    return
  },
})

export class Metrics {
  static metricsImpl: IMetrics = blackHoleMetrics()
  static enrichMetrics: () => Record<string, string | number> | undefined = () => ({})

  static init = (metrics: IMetrics, enrichMetrics: () => Record<string, string | number> | undefined) => {
    Metrics.metricsImpl = metrics
    Metrics.enrichMetrics = enrichMetrics
  }
  static get = (): IMetrics => Metrics.metricsImpl

  static increment = (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ) => {
    return Metrics.metricsImpl?.increment(stat, value, {
      ...(Metrics.enrichMetrics() ?? {}),
      ...tags,
    })
  }

  static decrement = (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ) => {
    return Metrics.metricsImpl?.decrement(stat, value, {
      ...(Metrics.enrichMetrics() ?? {}),
      ...tags,
    })
  }

  static distribution = (
    stat: string,
    value?: number | undefined,
    tags?: { [tag: string]: string | number } | undefined,
  ) => {
    return Metrics.metricsImpl?.distribution(stat, value, {
      ...(Metrics.enrichMetrics() ?? {}),
      ...tags,
    })
  }

  static gauge = (stat: string, value?: number | undefined, tags?: { [tag: string]: string | number } | undefined) => {
    return Metrics.metricsImpl?.gauge(stat, value, {
      ...(Metrics.enrichMetrics() ?? {}),
      ...tags,
    })
  }

  static flush = () => Metrics.metricsImpl?.flush()
}
