import { useMemo } from 'react'
import { useQuery } from 'urql'
import { gql } from '../../../generated'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'

const FETCH_CONTACT_INVOICE_LOAN_AMOUNT_QUERY = gql(/* GraphQL */ `
  query FetchContactInvoiceLoanAmounts(
    $accountGuid: uuid!
    $companyGuid: uuid!
  ) {
    wisetackLoanRecords(
      where: {
        accountGuid: { _eq: $accountGuid }
        companyGuid: { _eq: $companyGuid }
      }
    ) {
      requestedLoanAmountUsc
      contactGuid
      invoiceGuid
      wisetackLoanStatuses(
        limit: 1
        orderBy: { occurredAt: DESC, createdAt: DESC }
      ) {
        loanStatus
      }
    }
  }
`)

export const getContactInvoiceMapKey = (
  contactGuid: string,
  invoiceGuid: string,
) => `${contactGuid}-${invoiceGuid}`

export const useFetchContactInvoiceAmountMap = (accountGuid: string) => {
  const companyGuid = useExpectedCompanyGuid()

  const [res] = useQuery({
    query: FETCH_CONTACT_INVOICE_LOAN_AMOUNT_QUERY,
    variables: { accountGuid, companyGuid },
  })

  const contactInvoiceAmountMap = useMemo(
    () =>
      res.data?.wisetackLoanRecords
        .filter(
          record => record.wisetackLoanStatuses[0].loanStatus !== 'CANCELED',
        )
        .reduce((acc, record) => {
          acc[getContactInvoiceMapKey(record.contactGuid, record.invoiceGuid)] =
            record.requestedLoanAmountUsc
          return acc
        }, {} as Record<string, number>),
    [res.data?.wisetackLoanRecords],
  )

  return { contactInvoiceAmountMap, loading: res.fetching }
}
