import {
  MaintenancePlanDefinition,
  formatPercentage,
  jobClassDisplayNames,
} from '@breezy/shared'
import {
  IconDefinition,
  faCalendar,
  faCircleCheck,
  faTimer,
} from '@fortawesome/pro-regular-svg-icons'
import { Button, Col, Row, Typography } from 'antd'
import { memo, useCallback, useMemo } from 'react'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import ThinDivider from '../../elements/ThinDivider'
import { ExpandableHeightOptionCardHeader } from './components/ExpandableHeightOptionCardHeader'
import { MaintenancePlanTypeOption } from './create-or-edit-maintenance-plan-page-types'
import { useMaintenancePlanPricing } from './hooks/useMaintenancePlanPricing'

type Props = {
  readonly maintenancePlanTypeOption: MaintenancePlanTypeOption
  readonly isImportedPlan: boolean
  readonly onSelectMaintenancePlanType: (
    maintenancePlanDefinition: MaintenancePlanDefinition,
  ) => void
}

type MaintenancePlanInfoProps = {
  maintenancePlanDefinition: MaintenancePlanDefinition
  labelTextSizeClassName?: string
}

export const MaintenancePlanInfoSection = memo<MaintenancePlanInfoProps>(
  ({ maintenancePlanDefinition, labelTextSizeClassName }) => {
    return (
      <div className="flex flex-col gap-y-0">
        <LineItem faIcon={faTimer} title={'12-month Plan'} />
        <LineItem
          faIcon={faCalendar}
          title={`${maintenancePlanDefinition.numVisitCreditsPerYear} Annual ${
            maintenancePlanDefinition.numVisitCreditsPerYear > 1
              ? 'Visits'
              : 'Visit'
          }`}
        />

        <ThinDivider
          dividerStyle="dotted"
          widthPx={2}
          styleOverrides={{
            marginTop: '8px',
            marginBottom: '8px',
          }}
        />

        {maintenancePlanDefinition.discounts.map(discount => {
          const percentageDiscount = formatPercentage(discount.discountRate)
          return (
            <LineItem
              key={discount.maintenancePlanDefinitionDiscountGuid}
              faIcon={faCircleCheck}
              title={`${percentageDiscount} Discount for ${
                jobClassDisplayNames[discount.discountJobClass]
              }`}
            />
          )
        })}
        {maintenancePlanDefinition.benefits.map(benefit => {
          return (
            <LineItem
              key={benefit.maintenancePlanDefinitionBenefitGuid}
              faIcon={faCircleCheck}
              title={benefit.name}
              description={benefit.description}
            />
          )
        })}
      </div>
    )
  },
)

export const MaintenancePlanTypeOptionCard = memo<Props>(
  ({
    maintenancePlanTypeOption,
    onSelectMaintenancePlanType,
    isImportedPlan,
  }) => {
    const maintenancePlanDefinition = useMemo(
      () => maintenancePlanTypeOption.plan,
      [maintenancePlanTypeOption.plan],
    )

    const { pricingInformationPrimary, pricingInformationSecondary } =
      useMaintenancePlanPricing({
        maintenancePlanDefinition,
        isImportedPlan,
      })

    const onClickSelectPlan = useCallback(() => {
      onSelectMaintenancePlanType(maintenancePlanDefinition)
    }, [maintenancePlanDefinition, onSelectMaintenancePlanType])

    return (
      <SectionedCard
        className="h-[585px] max-h-[585px]"
        accentBarColor={
          maintenancePlanDefinition.flare?.primaryColorHex ?? '#13C2C2'
        }
        sections={[
          {
            coloredIn: false,
            verticalPaddingClassName: 'pt-6 pb-4',
            content: (
              <ExpandableHeightOptionCardHeader
                title={maintenancePlanDefinition.name}
                description={maintenancePlanDefinition.shortDescription}
                descriptionRowsBeforeTruncation={2}
                heightClassName="min-h-[76px]"
              />
            ),
          },
          {
            coloredIn: false,
            content: (
              <MaintenancePlanInfoSection
                maintenancePlanDefinition={maintenancePlanDefinition}
              />
            ),
          },
        ]}
        anchoredFooter={
          <div className="space-between flex w-full flex-row items-center gap-x-2">
            <Col className="w-1/2">
              <Row>
                <Typography.Text className="text-xl font-semibold text-[#000]">
                  {pricingInformationPrimary}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="text-base font-normal text-[#8c8c8c]">
                  {pricingInformationSecondary}
                </Typography.Text>
              </Row>
            </Col>
            <Col className="w-1/2">
              <Button
                type="primary"
                onClick={onClickSelectPlan}
                className="min-h-[40px] w-full"
                size="large"
                data-testid={`select-plan-button-${maintenancePlanDefinition.name}`}
              >
                Select Plan
              </Button>
            </Col>
          </div>
        }
      />
    )
  },
)

type LineItemProps = {
  faIcon: IconDefinition
  title: string
  description?: string
}

const LineItem = memo<LineItemProps>(({ faIcon, title, description }) => {
  return (
    <div className="flex min-h-[42px] w-full flex-row gap-x-3 pb-[6px] pt-[4px]">
      <div className="overflow-hidden">
        <FaIconWithCircularBackground
          iconDefinition={faIcon}
          diameterPx={32}
          backgroundColor="rgba(0, 0, 0, 0.04)"
          iconClassName="text-[16px] text-[#565656] m-0 p-0"
        />
      </div>
      <div className="flex w-[279px] flex-col justify-center">
        <Typography.Title
          level={4}
          className="mb-0 break-words text-[16px] leading-[24px] text-[#202020]"
        >
          {title}
        </Typography.Title>
        {description && (
          <div className="text-left text-[14px] leading-[22px] text-[#595959]">
            {description}
          </div>
        )}
      </div>
    </div>
  )
})
