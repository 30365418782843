import { useCallback } from 'react'
import ProgressiveJobCreationModal from '../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import useAppNavigation from '../../hooks/useAppNav'
import { m } from '../../utils/react-utils'

const JobCreationPage = m(() => {
  const appNav = useAppNavigation()
  const dismiss = appNav.navigateBack

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        dismiss()
      }
    },
    [dismiss],
  )

  return <ProgressiveJobCreationModal isOpen setIsOpen={setIsOpen} />
})

export default JobCreationPage
