import { ComprehensiveAccountDetails } from '@breezy/backend/src/application-types'
import {
  BzDateFns,
  BzDateTime,
  Guid,
  InstalledEquipmentSummary,
  isNullish,
  LocalDateString,
  VisitRequest,
  visitRequestSchema,
  VisitViewModel,
} from '@breezy/shared'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, message } from 'antd'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { z } from 'zod'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { SwitchField } from '../../elements/Forms/SwitchField'
import { TextField } from '../../elements/Forms/TextField'
import ThinDivider from '../../elements/ThinDivider'
import { useFetchComprehensiveAccountDetails } from '../../hooks/fetch/useFetchComprehensiveAccountDetails'
import { getAffinityDateOptions } from '../../pages/CreateOrEditMaintenancePlanPage/configureMaintenancePlanFormSchema'
import { InstalledEquipmentPicker } from '../../pages/CreateOrEditMaintenancePlanPage/ConfigureMaintenancePlanPanel/InstalledEquipmentPicker'
import { EquipmentCards } from '../../pages/CreateOrEditMaintenancePlanPage/LocationOptionCard'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { LoadingSpinner } from '../LoadingSpinner'
import { UpsertVisitRequest, useUpsertVisitsViewModel } from './useUpsertVisit'

type WithIndex<T> = T & { index: number }

type EditVisitsModalProps = {
  accountGuid: Guid
  locationGuid: Guid
  planActivationDate: LocalDateString
  visits: WithIndex<VisitViewModel>[]
  onClose: () => void
  afterSubmit: () => void
}

const adjustToStartOfMonth = (date: LocalDateString): LocalDateString => {
  return BzDateFns.formatLocalDate(
    BzDateFns.startOfMonth(BzDateFns.parseLocalDate(date)),
  )
}

export const EditVisitsModal = ({
  accountGuid,
  locationGuid,
  planActivationDate,
  visits,
  onClose: onCloseExternal,
  afterSubmit,
}: EditVisitsModalProps) => {
  const {
    data: account,
    isLoading,
    refetch,
  } = useFetchComprehensiveAccountDetails({ accountGuid })
  const { upsertVisits, isUpserting } = useUpsertVisitsViewModel()
  const [showEquipmentPicker, setShowEquipmentPicker] = useState(false)
  const [activeVisitIndex, setActiveVisitIndex] = useState<number | null>(null)

  const visitRequests: VisitRequest[] = useMemo(
    () =>
      visits.map(visit => ({
        ...visit,
        jobGuid: visit.visitJob?.jobGuid,
        shouldLinkToJob: !!visit.visitJob,
        installedEquipmentGuids:
          visit.visitEquipment.map(e => e.installedEquipmentGuid) ?? [],
      })),
    [visits],
  )

  const [isDirty, setIsDirty] = useState(false)

  const form = useForm<{ visits: VisitRequest[] }>({
    resolver: zodResolver(
      z.object({
        visits: visitRequestSchema.array(),
      }),
    ),
    defaultValues: { visits: visitRequests },
  })

  const { control, handleSubmit, setValue } = form
  const { fields } = useFieldArray({
    control,
    name: 'visits',
  })

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal(
    isDirty || form.formState.isDirty,
  )

  const onClose = useCallback(() => {
    withConfirmation(onCloseExternal)
  }, [withConfirmation, onCloseExternal])

  const onSubmit = useCallback(
    (data: { visits: VisitRequest[] }) => {
      const visitReqs: UpsertVisitRequest[] = data.visits.map(
        (baseVisit, index) => ({
          baseVisit,
          maintenancePlanGuid: visits[index].maintenancePlanGuid,
          companyGuid: visits[index].companyGuid,
          visitDates: {
            issuedAt: visits[index].issuedAt,
            expiresAt: visits[index].expiresAt,
            dueAt: visits[index].dueAt,
          },
        }),
      )

      upsertVisits(
        visitReqs,
        () => {
          afterSubmit()
        },
        e => {
          console.error('Error upserting visits', e)
          message.error('Error upserting visits')
        },
      )
      setIsDirty(false)
    },
    [visits, afterSubmit, upsertVisits],
  )

  const installedEquipment = useMemo(() => {
    return (
      account
        ?.getAccountLocations()
        .find(l => l.location.locationGuid === locationGuid)?.location
        .installedEquipment ?? []
    )
  }, [account, locationGuid])

  const addEquipment = useCallback(
    (equips: InstalledEquipmentSummary[], visitIndex: number) => {
      const existingEquipmentGuids =
        form.getValues(`visits.${visitIndex}.installedEquipmentGuids`) || []
      const newEquipmentGuids = [
        ...new Set([
          ...existingEquipmentGuids,
          ...equips.map(e => e.installedEquipmentGuid),
        ]),
      ]
      setValue(
        `visits.${visitIndex}.installedEquipmentGuids`,
        newEquipmentGuids,
      )
      setShowEquipmentPicker(false)
      setIsDirty(true)
    },
    [setValue, setIsDirty, form],
  )

  const content = useMemo(() => {
    if (isLoading || isUpserting || !account) {
      return <LoadingSpinner />
    }

    if (showEquipmentPicker && !isNullish(activeVisitIndex)) {
      return (
        <InstalledEquipmentPicker
          mode="inline"
          locationGuid={locationGuid}
          installedEquipment={installedEquipment}
          selectedEquipmentGuids={
            form.getValues(
              `visits.${activeVisitIndex}.installedEquipmentGuids`,
            ) || []
          }
          onSave={equips => {
            addEquipment(equips, activeVisitIndex)
            setActiveVisitIndex(null)
          }}
          onCancel={() => {
            setShowEquipmentPicker(false)
            setActiveVisitIndex(null)
          }}
          refetch={refetch}
          setIsDirty={setIsDirty}
        />
      )
    }

    return (
      <OnsiteModalContent
        onClose={onClose}
        header="Edit Visits"
        footer={
          <OnsiteModalFooter
            onCancel={onClose}
            onSubmit={handleSubmit(onSubmit)}
          />
        }
      >
        <FormProvider {...form}>
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              {index > 0 && <ThinDivider />}
              <ConfigureVisitForm
                account={account}
                selectedLocationGuid={locationGuid}
                activationDate={planActivationDate}
                visitIndex={index}
                setShowEquipmentPicker={show => {
                  setShowEquipmentPicker(show)
                  setActiveVisitIndex(index)
                }}
                setIsDirty={setIsDirty}
              />
            </React.Fragment>
          ))}
        </FormProvider>
      </OnsiteModalContent>
    )
  }, [
    isLoading,
    isUpserting,
    account,
    showEquipmentPicker,
    activeVisitIndex,
    onClose,
    handleSubmit,
    onSubmit,
    form,
    fields,
    locationGuid,
    installedEquipment,
    refetch,
    addEquipment,
    planActivationDate,
  ])

  return (
    <>
      <OnsiteModal size="large" drawer onClose={onClose}>
        {content}
      </OnsiteModal>
      <CloseConfirmModal {...closeConfirmProps} />
    </>
  )
}

type ConfigureVisitFormProps = {
  account: ComprehensiveAccountDetails
  activationDate: LocalDateString
  visitIndex: number
  selectedLocationGuid: Guid
  setShowEquipmentPicker: (show: boolean) => void
  setIsDirty: (isDirty: boolean) => void
}

const ConfigureVisitForm = memo<ConfigureVisitFormProps>(
  ({
    account,
    selectedLocationGuid,
    visitIndex,
    activationDate,
    setShowEquipmentPicker,
    setIsDirty,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const [, setRerenderNonce] = useState(0)
    const {
      control,
      formState: { errors },
      watch,
      setValue,
    } = useFormContext<{ visits: VisitRequest[] }>()

    const installedEquipmentGuids = watch(
      `visits.${visitIndex}.installedEquipmentGuids`,
    )

    const isCompletedOverride = watch(
      `visits.${visitIndex}.isCompletedOverride`,
    )
    const shouldLinkToJob = watch(`visits.${visitIndex}.shouldLinkToJob`)
    const jobGuid = watch(`visits.${visitIndex}.jobGuid`)
    const affinityDate = watch(`visits.${visitIndex}.affinityDate`)

    const isCompleted = isCompletedOverride ?? !!jobGuid

    useEffect(() => {
      if (affinityDate) {
        const adjustedDate = adjustToStartOfMonth(affinityDate)
        if (adjustedDate !== affinityDate) {
          setValue(`visits.${visitIndex}.affinityDate`, adjustedDate)
        }
      }
    }, [affinityDate, setValue, visitIndex])

    const monthOptions = useMemo(
      () => getAffinityDateOptions(activationDate, tzId),
      [activationDate, tzId],
    )

    const monthOptionsWithLabels = useMemo(() => {
      return monthOptions.map(month => {
        return {
          label: BzDateFns.format(BzDateFns.parseLocalDate(month), 'MMMM yyyy'),
          value: month,
        }
      })
    }, [monthOptions])

    const installedEquipment = useMemo(() => {
      return (
        account
          .getAccountLocations()
          .find(l => l.location.locationGuid === selectedLocationGuid)?.location
          .installedEquipment ?? []
      )
    }, [account, selectedLocationGuid])

    const visitSelectedEquipment = useMemo(
      () =>
        installedEquipmentGuids.map(
          guid =>
            installedEquipment.find(e => e.installedEquipmentGuid === guid)!,
        ),
      [installedEquipmentGuids, installedEquipment],
    )

    const toggleVisitCompleted = useCallback(() => {
      const newIsCompleted = !isCompleted
      setValue(`visits.${visitIndex}.isCompletedOverride`, newIsCompleted)
      if (!newIsCompleted) {
        setValue(`visits.${visitIndex}.shouldLinkToJob`, false)
        setValue(`visits.${visitIndex}.jobGuid`, undefined)
      }
      setRerenderNonce(prev => prev + 1)
      setIsDirty(true)
    }, [isCompleted, setValue, setRerenderNonce, setIsDirty, visitIndex])

    const jobOptions = useMemo(() => {
      const jobs = account
        .getJobs()
        .filter(
          j => j.serviceLocation.location.locationGuid === selectedLocationGuid,
        )
        .sort((a, b) => b.jobCreatedAt.compareTo(a.jobCreatedAt))
      return jobs.map(job => ({
        label: `Job #${job.displayId} - (${
          job.jobType.name
        }) - ${BzDateTime.fromJsJoda(
          job.jobCreatedAt,
        ).toHumanFriendlyMonthDayYear()}`,
        value: job.jobGuid,
      }))
    }, [account, selectedLocationGuid])

    const hasJobOptions = useMemo(() => jobOptions.length > 0, [jobOptions])

    const defaultJobOption = useMemo(() => {
      return hasJobOptions ? jobOptions[0] : undefined
    }, [hasJobOptions, jobOptions])

    const toggleLinkToJob = useCallback(() => {
      const newShouldLinkToJob = !shouldLinkToJob
      setValue(`visits.${visitIndex}.shouldLinkToJob`, newShouldLinkToJob)

      if (newShouldLinkToJob && defaultJobOption) {
        setValue(`visits.${visitIndex}.jobGuid`, defaultJobOption.value)
      } else {
        setValue(`visits.${visitIndex}.jobGuid`, undefined)
      }

      setRerenderNonce(prev => prev + 1)
      setIsDirty(true)
    }, [
      setValue,
      defaultJobOption,
      shouldLinkToJob,
      setRerenderNonce,
      setIsDirty,
      visitIndex,
    ])

    const removeEquipment = useCallback(
      (installedEquipmentGuid: Guid) => {
        const existingEquipment = visitSelectedEquipment
        const newEquipment = existingEquipment.filter(
          e => e.installedEquipmentGuid !== installedEquipmentGuid,
        )
        setValue(
          `visits.${visitIndex}.installedEquipmentGuids`,
          newEquipment.map(e => e.installedEquipmentGuid),
        )
        setIsDirty(true)
      },
      [setValue, visitSelectedEquipment, setIsDirty, visitIndex],
    )

    const visitCompletedClassName = isCompleted
      ? 'bg-[#fafafa]'
      : 'bg-transparent'
    const allowLinkToJob = isCompleted

    return (
      <>
        <div className="flex w-full flex-col gap-y-3">
          <div className="mb-1 flex flex-row gap-x-3">
            <div className="w-full">
              <ReactHookFormItem
                noBottomMargin
                control={control}
                errors={errors}
                name={`visits.${visitIndex}.name`}
                label={`Visit #${visitIndex + 1}`}
                required={false}
                className="w-full"
                labelCol={{ span: 24 }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Visit name"
                    size="large"
                    className="w-full"
                    disabled={isCompleted}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <ReactHookFormItem
                noBottomMargin
                control={control}
                errors={errors}
                name={`visits.${visitIndex}.affinityDate`}
                label="Placeholder Date"
                required={true}
                className="w-full"
                labelCol={{ span: 24 }}
                render={({ field }) => (
                  <SelectField
                    options={monthOptionsWithLabels}
                    {...field}
                    size="large"
                    title="Visit Placeholder Date"
                    className="w-full"
                    disabled={isCompleted}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-[2px] text-[14px] font-semibold leading-[22px]">
              Equipment to Service
            </div>
            <div className="mb-3 text-[14px] leading-[22px] text-[#8c8c8c]">
              Link the location's equipment to the visit for streamlined service
              and data tracking.
            </div>
            <div className="flex min-h-[56px] flex-col gap-y-3 rounded-2xl bg-[#fafafa] p-3">
              {visitSelectedEquipment && (
                <EquipmentCards
                  installedEquipments={visitSelectedEquipment ?? []}
                  onRemove={removeEquipment}
                />
              )}
              <Button
                type="link"
                className="pl-3 text-left"
                onClick={() => {
                  setShowEquipmentPicker(true)
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Link Equipment for Visit
              </Button>
            </div>
          </div>
          <div
            className={`flex flex-col rounded-2xl p-[13px] ${visitCompletedClassName}`}
          >
            <div className="flex flex-row gap-x-3">
              <div className="w-full">
                <ReactHookFormItem
                  noBottomMargin
                  control={control}
                  errors={errors}
                  name={`visits.${visitIndex}.isCompletedOverride`}
                  label="Mark Visit as Completed"
                  labelPosition="right"
                  required={true}
                  render={({ field }) => (
                    <SwitchField
                      {...field}
                      withIcons
                      checked={isCompleted}
                      onChange={toggleVisitCompleted}
                    />
                  )}
                />
              </div>
              {allowLinkToJob && (
                <div className="w-full">
                  <ReactHookFormItem
                    noBottomMargin
                    control={control}
                    errors={errors}
                    name={`visits.${visitIndex}.shouldLinkToJob`}
                    label={`Link to existing Job${
                      hasJobOptions ? '' : ' (No jobs)'
                    }`}
                    labelPosition="right"
                    required={true}
                    render={({ field }) => (
                      <SwitchField
                        {...field}
                        withIcons
                        onChange={checked => {
                          toggleLinkToJob()
                        }}
                        disabled={!hasJobOptions}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            {shouldLinkToJob && (
              <ReactHookFormItem
                noBottomMargin
                control={control}
                errors={errors}
                name={`visits.${visitIndex}.jobGuid`}
                label=""
                required={false}
                className="mt-3"
                render={({ field }) => (
                  <SelectField
                    {...field}
                    title=""
                    size="large"
                    options={jobOptions}
                  />
                )}
              />
            )}
          </div>
        </div>
      </>
    )
  },
)
