import {
  Account,
  AccountGuid,
  AccountLocation,
  AccountType,
  AppointmentType,
  AsyncFn,
  AttributionLinkingStrategy,
  BzAddress,
  BzContact,
  BzDateFns,
  BzTimeWindow,
  Company,
  CompanyGuid,
  ComprehensiveAppointmentDetails,
  ContactGuid,
  DEFAULT_SCHEDULING_CAPABILITY,
  DateTimeFormatter,
  EquipmentType,
  EquipmentTypeJobLink,
  FileRecord,
  FileStorageStrategy,
  ForCompany,
  InstallProjectType,
  IsoDateString,
  JobClass,
  JobGuid,
  JobLifecycleStage,
  JobLifecycleStatus,
  JobTeamMember,
  JobToEquipmentRelationshipType,
  JobType,
  LeadSourceGuid,
  LocalDate,
  Location,
  LocationGuid,
  MaintenancePlanCollapsibleAndConsumptionViewModel,
  MaintenancePlanMinimalInfo,
  MaintenancePlanPaymentFlow,
  MaintenancePlanPaymentInterval,
  MaintenancePlanStatus,
  NotificationPreferenceType,
  PaymentMethod,
  PaymentStatus,
  PaymentViewModel,
  PhoneNumberType,
  PhotoRecord,
  R,
  RichCompanyLeadSource,
  Tag,
  TechnicianRole,
  ZoneId,
  ZonedDateTime,
  bzExpect,
  calculateInferredAppointmentStatus,
  castSimplePhoneNumberType,
  cloneDeep,
  guidSchema,
  isNullish,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { z } from 'zod'
import { FetchComprehensiveJobDetailsQuery, FileFragment } from '../../query'
import { convertFetchLoanRecordToLoanRecord } from '../loans/LoanRecordConversions'
import { parseEquipment } from '../parsers'
import { mapQueryVisitsToVisitViewModels } from '../visits/VisitConversions'

export type ComprehensiveJobDetailsJSON = FetchComprehensiveJobDetailsQuery['jobs'][number]

// [X] Audited in BZ-921 -> No Action Required immediately
// Todo figure out a way to share the mapping logic between ComprehensiveJobDetails & ComprehensiveAccountDetails

export class ComprehensiveJobDetails {
  constructor(private readonly data: ComprehensiveJobDetailsJSON) {}

  toComprehensiveJobDetailsJSON(): ComprehensiveJobDetailsJSON {
    return cloneDeep(this.data)
  }

  getJobGuid(): JobGuid {
    return this.data.jobGuid
  }

  getDisplayId(): number {
    return this.data.displayId
  }

  getInstallProjectType(): InstallProjectType | undefined {
    return this.data.installProjectType as InstallProjectType | undefined
  }

  getJobDisplayName(): string {
    return `${this.getJobType().name} job for ${
      this.getPointOfContact().fullName
    } at ${this.getAddressOfServiceLocation().streetAddressLine1And2Condensed()}`
  }

  getSummary(): string | undefined {
    // [X] Audited in BZ-921 -> No Action Required immediately
    // todo update the data model
    return this.data.summary
  }

  isOpportunity(): boolean {
    return this.data.isOpportunity
  }

  isHotLead(): boolean {
    return this.data.isHotLead
  }

  isMembershipOpportunity(): boolean {
    return this.data.isMembershipOpportunity
  }

  isMembershipRenewalOpportunity(): boolean {
    return this.data.isMembershipRenewalOpportunity
  }

  isConverted(): boolean | undefined {
    return this.data.isConverted
  }

  isMembershipSold(): boolean | undefined {
    return this.data.isMembershipSold
  }

  getCommissionOverheadDeductionRate(): number | undefined {
    return this.data.commissionOverheadDeductionRate
  }

  getCommissionOverheadFlatDeductionUsc(): number | undefined {
    return this.data.commissionOverheadFlatDeductionUsc
  }

  getCommissionJobCostsDeductionRate(): number | undefined {
    return this.data.commissionJobCostsDeductionRate
  }

  getCommissionJobCostsFlatDeductionUsc(): number | undefined {
    return this.data.commissionJobCostsFlatDeductionUsc
  }

  getLeadSource(): RichCompanyLeadSource | undefined {
    if (this.data.jobLeadSource.length === 0) {
      return undefined
    }

    const { companyLeadSource, referringContact, attributionDescription } = bzExpect(this.data.jobLeadSource[0])

    return {
      leadSource: {
        companyLeadSourceGuid: companyLeadSource.companyLeadSourceGuid,
        companyGuid: companyLeadSource.companyGuid,
        name:
          companyLeadSource.canonicalLeadSourceNameOverride ??
          companyLeadSource.canonicalLeadSource.canonicalLeadSourceName,
        canonicalName: companyLeadSource.canonicalLeadSource.canonicalLeadSourceName,
        attributionLinkingStrategy: (companyLeadSource.attributionLinkingStrategyOverride ??
          companyLeadSource.canonicalLeadSource.attributionLinkingStrategy) as AttributionLinkingStrategy,
        attributionPrompt:
          companyLeadSource.attributionPromptOverride ?? companyLeadSource.canonicalLeadSource.attributionPrompt,
        archivedAt: companyLeadSource.archivedAt,
      },
      referringContact: referringContact ?? undefined,
      attributionDescription: attributionDescription ?? undefined,
    }
  }

  getCreatedOn(): ZonedDateTime {
    return ZonedDateTime.parse(this.data.createdAt, DateTimeFormatter.ISO_OFFSET_DATE_TIME)
  }

  getWorkCompletedAt(): IsoDateString | undefined {
    return this.data.workCompletedAt
  }

  getServiceLocation(): Location {
    return bzExpect(
      this.getAccount()
        .accountLocations.map(cl => cl.location)
        .filter(l => l.locationGuid === this.data.locationGuid)[0],
      'Expected job to have a service location',
    )
  }

  getMaintenancePlanVisitGuid(): string | undefined {
    const serviceLocation = this.getServiceLocation()
    const maintenancePlanVisits = serviceLocation.maintenancePlanVisits

    return maintenancePlanVisits?.find(mpv => mpv.jobGuid === this.getJobGuid())?.maintenancePlanVisitGuid
  }

  getLinkedMaintenancePlanGuid(): string | undefined {
    const visits = mapQueryVisitsToVisitViewModels(this.data.maintenancePlans.flatMap(mp => mp.maintenancePlanVisits))
    const linkedVisit = visits.find(v => v.visitJob?.jobGuid === this.data.jobGuid)
    return linkedVisit?.maintenancePlanGuid
  }

  getAppointments(): ComprehensiveAppointmentDetails[] {
    const rawAppointments = this.data.appointments
    return rawAppointments.map(appointment => {
      const assignments = appointment.assignments.map(assignment => {
        return {
          assignmentGuid: assignment.jobAppointmentAssignmentGuid,
          assignmentStatus: assignment.assignmentStatus?.jobAppointmentAssignmentStatusType || 'TO_DO',
          technicianUserGuid: assignment.technician.userGuid,
          technician: {
            user: {
              id: assignment.technician.userGuid,
              firstName: assignment.technician.firstName,
              lastName: assignment.technician.lastName,
            },
            contact: {
              email: assignment.technician.emailAddress,
              phone: castSimplePhoneNumberType(
                bzExpect(assignment.technician.userPhoneNumbers[0], 'Every technician should have a phone number')
                  .phoneNumber,
              ),
            },
            roles: assignment.technician.userRoles.map(userRole => userRole.roleById.role as unknown as TechnicianRole),
            schedulingCapability:
              assignment.technician.companyUser?.schedulingCapability ?? DEFAULT_SCHEDULING_CAPABILITY,
          },
          timeWindow: new BzTimeWindow(
            ZonedDateTime.parse(assignment.assignmentStart, DateTimeFormatter.ISO_OFFSET_DATE_TIME).withZoneSameInstant(
              this.getCompanyTimezoneId(),
            ),
            ZonedDateTime.parse(assignment.assignmentEnd, DateTimeFormatter.ISO_OFFSET_DATE_TIME).withZoneSameInstant(
              this.getCompanyTimezoneId(),
            ),
          ),
        }
      })

      const inferredAppointmentStatus = calculateInferredAppointmentStatus(
        appointment.cancellationStatus?.canceled || false,
        assignments.map(assignment => ({ assignmentStatus: assignment.assignmentStatus })),
      )

      return {
        appointmentReferenceNumber: appointment.appointmentReferenceNumber,
        appointmentGuid: appointment.jobAppointmentGuid,
        appointmentStatus: inferredAppointmentStatus,
        confirmed: appointment.confirmationStatus?.confirmed || false,
        canceled: appointment.cancellationStatus?.canceled || false,
        address: this.getAddressOfServiceLocation(),
        timeWindow: new BzTimeWindow(
          ZonedDateTime.parse(
            appointment.appointmentWindowStart,
            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
          ).withZoneSameInstant(this.getCompanyTimezoneId()),
          ZonedDateTime.parse(
            appointment.appointmentWindowEnd,
            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
          ).withZoneSameInstant(this.getCompanyTimezoneId()),
        ),
        assignments: assignments,
        jobGuid: this.getJobGuid(),
        jobType: this.getJobType(),
        associatedInstallProjectType: this.getInstallProjectType(),
        appointmentType: appointment.appointmentType as AppointmentType,
        description: appointment.description,
        appointmentChecklistInstances: appointment.appointmentChecklistInstances,
        endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(appointment.endOfAppointmentNextSteps),
      }
    })
  }

  getAccountLocations(): AccountLocation[] {
    return this.getAccount().accountLocations
  }

  getAddressOfServiceLocation(): BzAddress {
    return BzAddress.create(this.getServiceLocation().address)
  }

  getPointOfContact(): BzContact {
    return BzContact.create(
      bzExpect(
        this.getAccount()
          .accountContacts.map(ac => ac.contact)
          .filter(c => c.contactGuid === this.data.pointOfContactGuid)[0],
        'Expected job to have a point of contact',
      ),
    )
  }

  getJobType(): JobType {
    return {
      ...this.data.jobType,
      jobClass: this.data.jobType.jobClass as JobClass,
    }
  }

  getAccount(): Account {
    return {
      accountGuid: this.data.account.accountGuid,
      displayName: this.data.account.accountDisplayName,
      accountManager: this.data.account.accountManager,
      type: this.data.account.accountType as AccountType,
      referenceNumber: this.data.account.accountReferenceNumber,
      companyGuid: this.getCompanyGuid(),
      accountContacts: this.data.account.accountContacts.map(ac => {
        return {
          accountContactGuid: ac.accountContactGuid,
          accountGuid: this.data.account.accountGuid,
          companyGuid: this.getCompanyGuid(),
          primary: ac.primary,
          archived: ac.archived,
          contact: {
            contactGuid: ac.contact.contactGuid,
            companyGuid: this.getCompanyGuid(),
            firstName: ac.contact.firstName,
            lastName: ac.contact.lastName,
            salutation: ac.contact.salutation,
            title: ac.contact.title,
            notificationPreferenceType: ac.contact.notificationPreferenceType as NotificationPreferenceType,
            primaryEmailAddress: ac.contact.primaryEmailAddress
              ? {
                  emailAddressGuid: ac.contact.primaryEmailAddress.emailAddressGuid,
                  companyGuid: this.getCompanyGuid(),
                  emailAddress: ac.contact.primaryEmailAddress.emailAddress,
                }
              : undefined,
            additionalEmailAddress: ac.contact.additionalEmailAddress
              ? {
                  emailAddressGuid: ac.contact.additionalEmailAddress.emailAddressGuid,
                  companyGuid: this.getCompanyGuid(),
                  emailAddress: ac.contact.additionalEmailAddress.emailAddress,
                }
              : undefined,
            primaryPhoneNumber: ac.contact.primaryPhoneNumber
              ? {
                  phoneNumberGuid: ac.contact.primaryPhoneNumber.phoneNumberGuid,
                  companyGuid: this.getCompanyGuid(),
                  phoneNumber: ac.contact.primaryPhoneNumber.phoneNumber,
                  type: ac.contact.primaryPhoneNumber.type as PhoneNumberType,
                  unsubscribed: ac.contact.primaryPhoneNumber.unsubscribed,
                }
              : undefined,
            additionalPhoneNumber: ac.contact.additionalPhoneNumber
              ? {
                  phoneNumberGuid: ac.contact.additionalPhoneNumber.phoneNumberGuid,
                  companyGuid: this.getCompanyGuid(),
                  phoneNumber: ac.contact.additionalPhoneNumber.phoneNumber,
                  type: ac.contact.additionalPhoneNumber.type as PhoneNumberType,
                  unsubscribed: ac.contact.additionalPhoneNumber.unsubscribed,
                }
              : undefined,
          },
        }
      }),
      accountLocations: this.data.account.accountLocations.map(cl => {
        return {
          accountGuid: this.data.account.accountGuid,
          companyGuid: this.getCompanyGuid(),
          isArchived: cl.isArchived,
          location: {
            locationGuid: cl.location.locationGuid,
            companyGuid: this.getCompanyGuid(),
            displayName: cl.location.displayName,
            address: {
              addressGuid: cl.location.address.addressGuid,
              line1: cl.location.address.line1,
              line2: cl.location.address.line2,
              city: cl.location.address.city,
              stateAbbreviation: cl.location.address.stateAbbreviation,
              zipCode: cl.location.address.zipCode,
            },
            estimatedSquareFootage: cl.location.estimatedSquareFootage,
            estimatedBuildDate: cl.location.estimatedBuildDate
              ? LocalDate.parse(cl.location.estimatedBuildDate)
              : undefined,
            propertyType: cl.location.propertyType ?? 'unknown',
            municipality: cl.location.municipality ?? undefined,
            installedEquipment: cl.location.installedEquipment.map(parseEquipment),
            maintenancePlanVisits: cl.location.maintenancePlans
              .map(mp => mp.maintenancePlanVisits)
              .flat()
              .map(mpv => ({
                maintenancePlanGuid: mpv.maintenancePlanGuid,
                maintenancePlanVisitGuid: mpv.maintenancePlanVisitGuid,
                jobGuid: mpv.jobGuid,
              })),
          },
        }
      }),
      mailingAddress: this.data.account.mailingAddress
        ? {
            line1: this.data.account.mailingAddress.line1,
            line2: this.data.account.mailingAddress.line2,
            city: this.data.account.mailingAddress.city,
            stateAbbreviation: this.data.account.mailingAddress.stateAbbreviation,
            zipCode: this.data.account.mailingAddress.zipCode,
          }
        : undefined,
      accountNote: this.data.account.accountNote,
      accountCreatedAt: this.data.account.accountCreatedAt,
      maintenancePlans: this.getMaintenancePlanMinimals(),
    }
  }

  getCompany(): Company {
    return {
      companyGuid: this.data.company.companyGuid,
      name: this.data.company.name,
      merchantId: this.data.company.billingProfile?.tilledMerchantId ?? undefined,
      // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1017
      // eslint-disable-next-line breezy/no-to-time-zone-id
      timezone: BzDateFns.toTimeZoneId(this.data.company.timezone),
    }
  }

  getCompanyGuid(): CompanyGuid {
    return this.getCompany().companyGuid
  }

  getCompanyTimezoneId(): ZoneId {
    return ZoneId.of(this.getCompany().timezone)
  }

  getJobDetailsEditViewModel(): EditJobViewModel {
    return {
      jobGuid: this.data.jobGuid,
      displayId: this.data.displayId,
      locationGuid: this.getServiceLocation().locationGuid,
      accountGuid: this.getAccount().accountGuid,
      summary: this.data.summary ?? '',
      jobType: this.getJobType(),
      pointOfContactGuid: this.getPointOfContact().contactGuid,
      isOpportunity: this.data.isOpportunity,
      isHotLead: this.data.isHotLead,
      isMembershipOpportunity: this.data.isMembershipOpportunity,
      isMembershipRenewalOpportunity: this.data.isMembershipRenewalOpportunity,
      leadSourceGuid: this.getLeadSource()?.leadSource.companyLeadSourceGuid,
      leadSourceReferringContactGuid: this.getLeadSource()?.referringContact?.id,
      leadSourceAttributionDescription: this.getLeadSource()?.attributionDescription,
      installProjectType: this.data.installProjectType as InstallProjectType | undefined,
      equipmentTypeJobLinks: this.data.equipmentTypeJobLinks.map(link => ({
        equipmentType: link.equipmentType as EquipmentType,
        relationshipType: link.relationshipType as JobToEquipmentRelationshipType,
        estimatedEquipmentAge: link.estimatedEquipmentAge,
      })),
      tagGuids: this.getTags().map(tag => tag.tagGuid),
      useMaintenancePlanCredit: this.data.useMaintenancePlanCredit,
      customerPurchaseOrderNumber: this.data.customerPurchaseOrderNumber,
    }
  }

  getEquipmentTypeJobLinks(): EquipmentTypeJobLink[] {
    return this.data.equipmentTypeJobLinks.map(et => ({
      equipmentType: et.equipmentType as EquipmentType,
      relationshipType: et.relationshipType as JobToEquipmentRelationshipType,
      estimatedEquipmentAge: et.estimatedEquipmentAge,
    }))
  }

  getPayments(): PaymentViewModel[] {
    return this.data.paymentLinks.map(pl => {
      const vm: PaymentViewModel = {
        ...pl.payment,
        ...pl.payment.links,
        paymentMethod: pl.payment.paymentMethod as unknown as PaymentMethod,
        accountDisplayName: pl.payment.account?.accountDisplayName,
        loanRecord:
          !isNullish(pl.payment.links) && !isNullish(pl.payment.links.loanRecord)
            ? convertFetchLoanRecordToLoanRecord(pl.payment.links.loanRecord)
            : undefined,
        status:
          pl.payment.paymentStatusesAggregate.nodes.length > 0
            ? (pl.payment.paymentStatusesAggregate.nodes[0].paymentStatus as unknown as PaymentStatus)
            : // NOTE: For possible non-atomic write race conditions, I think
              PaymentStatus.SUBMITTING,
      }
      return vm
    })
  }

  getPhotos(): PhotoRecord[] {
    const photos: PhotoRecord[] = []

    // all photos for all of the account's locations
    // this includes the service location
    this.data.account.accountLocations.forEach(al => {
      if (this.getServiceLocation().locationGuid === al.location.locationGuid) {
        al.location.photoLinks.forEach(p => {
          photos.find(ph => ph.photoGuid === p.photoGuid) ||
            photos.push({
              photoGuid: p.photoGuid,
              createdByUserGuid: p.photo.createdByUserGuid,
              cdnUrl: p.photo.cdnUrl,
              resourceUrn: p.photo.resourceUrn,
              createdAt: p.photo.createdAt,
            })
        })
      }
    })

    // all photos for the job's appointments
    this.data.appointments.forEach(a => {
      a.photoLinks.forEach(p => {
        photos.find(ph => ph.photoGuid === p.photoGuid) ||
          photos.push({
            photoGuid: p.photoGuid,
            createdByUserGuid: p.photo.createdByUserGuid,
            cdnUrl: p.photo.cdnUrl,
            resourceUrn: p.photo.resourceUrn,
            createdAt: p.photo.createdAt,
          })
      })
      // all photos for the job's assignments
      a.assignments.forEach(assignment => {
        assignment.photoLinks.forEach(p => {
          photos.find(ph => ph.photoGuid === p.photoGuid) ||
            photos.push({
              photoGuid: p.photoGuid,
              createdByUserGuid: p.photo.createdByUserGuid,
              cdnUrl: p.photo.cdnUrl,
              resourceUrn: p.photo.resourceUrn,
              createdAt: p.photo.createdAt,
            })
        })
      })
    })

    // all photos for the job itself
    this.data.photoLinks.forEach(p => {
      photos.find(ph => ph.photoGuid === p.photoGuid) ||
        photos.push({
          photoGuid: p.photoGuid,
          createdByUserGuid: p.photo.createdByUserGuid,
          cdnUrl: p.photo.cdnUrl,
          resourceUrn: p.photo.resourceUrn,
          createdAt: p.photo.createdAt,
        })
    })

    return R.sortWith<PhotoRecord>([R.descend(R.prop('createdAt')), R.ascend(R.prop('photoGuid'))])(photos)
  }

  getFiles(): FileRecord[] {
    const files: FileRecord[] = []

    const addFileIfAbsent = (f: FileFragment): void => {
      files.find(fh => fh.fileGuid === f.fileGuid) ||
        files.push({
          fileGuid: f.fileGuid,
          cdnUrl: f.file.cdnUrl,
          resourceUrn: f.file.resourceUrn,
          companyGuid: f.file.companyGuid,
          userGuid: f.file.userGuid,
          fileName: f.file.fileName,
          metadata: f.file.metadata,
          fileSizeBytes: f.file.fileSizeBytes,
          fileTypeMime: f.file.fileTypeMime,
          createdAt: f.file.createdAt,
          storageStrategy: f.file.storageStrategy as FileStorageStrategy,
        })
    }

    // all files for all of the account's locations
    // this includes the service location
    this.data.account.accountLocations.forEach(al => {
      if (this.getServiceLocation().locationGuid === al.location.locationGuid) {
        al.location.fileLinks.forEach(addFileIfAbsent)
      }
    })

    // all files for the job's appointments
    this.data.appointments.forEach(appointment => {
      appointment.fileLinks.forEach(addFileIfAbsent)

      // all files for the job's assignments
      appointment.assignments.forEach(assignment => {
        assignment.fileLinks.forEach(addFileIfAbsent)
      })
    })

    // all files for the job itself
    this.data.fileLinks.forEach(addFileIfAbsent)

    return R.sortWith<FileRecord>([R.descend(R.prop('createdAt')), R.ascend(R.prop('fileName'))])(files)
  }
  getMaintenancePlans(): MaintenancePlanCollapsibleAndConsumptionViewModel[] {
    const maintenancePlans = (this.data.maintenancePlans ?? []) // eslint-disable-next-line breezy/no-typeof-null-or-undefined
      .filter(mp => typeof mp !== 'undefined') // for some reason isNullish isn't giving us that nice TS 5.2 syntactic sugar

    return (
      maintenancePlans
        .map(mp => this.convertToMaintenancePlanCollapsibleAndConsumptionViewModel(mp))
        // eslint-disable-next-line breezy/no-typeof-null-or-undefined
        .filter(mp => typeof mp !== 'undefined') // for some reason isNullish isn't giving us that nice TS 5.2 syntactic sugar
    )
  }

  convertToMaintenancePlanCollapsibleAndConsumptionViewModel(
    mp: FetchComprehensiveJobDetailsQuery['jobs'][0]['maintenancePlan'] | undefined,
  ): MaintenancePlanCollapsibleAndConsumptionViewModel | undefined {
    if (isNullish(mp?.maintenancePlanDefinition)) return undefined
    const mpDef = bzExpect(mp?.maintenancePlanDefinition, 'Maintenance Plan Definition should be present')
    return mp
      ? {
          ...mp,
          definition: {
            ...mpDef,
            ...bzExpect(mpDef.marketingInfo, 'Marketing Info for Maintenance Plan Definition should be present'),
            discounts: mpDef.discounts.map(d => ({
              ...d,
              discountJobClass: d.discountJobClass as JobClass,
            })),
            allowedPaymentIntervals: mpDef.allowedPaymentIntervals.map(pi => ({
              ...pi,
              paymentInterval: pi.paymentInterval as MaintenancePlanPaymentInterval,
            })),
          },
          status: mp.status as MaintenancePlanStatus,
          paymentFlow: mp.paymentFlow as unknown as MaintenancePlanPaymentFlow,
          planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? 'None',
          planTypeFlare: mp.maintenancePlanDefinition?.flare,
          locationAddress: bzExpect(mp.location?.address, 'Location should be present'),
          visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits ?? []),
          coveredEquipmentTypes:
            mp.maintenancePlanCoveredEquipmentTypes.map(e => e.equipmentType as EquipmentType) ?? [],
        }
      : undefined
  }

  /** @deprecated cleanup with maint plans v3 */
  getMaintenancePlan(): MaintenancePlanCollapsibleAndConsumptionViewModel | undefined {
    const mp = this.data.maintenancePlan
    if (isNullish(mp?.maintenancePlanDefinition)) return undefined
    const mpDef = bzExpect(mp?.maintenancePlanDefinition, 'Maintenance Plan Definition should be present')
    return mp
      ? {
          ...mp,
          definition: {
            ...mpDef,
            ...bzExpect(mpDef.marketingInfo, 'Marketing Info for Maintenance Plan Definition should be present'),
            discounts: mpDef.discounts.map(d => ({
              ...d,
              discountJobClass: d.discountJobClass as JobClass,
            })),
            allowedPaymentIntervals: mpDef.allowedPaymentIntervals.map(pi => ({
              ...pi,
              paymentInterval: pi.paymentInterval as MaintenancePlanPaymentInterval,
            })),
          },
          status: mp.status as MaintenancePlanStatus,
          paymentFlow: mp.paymentFlow as unknown as MaintenancePlanPaymentFlow,
          planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? 'None',
          planTypeFlare: mp.maintenancePlanDefinition?.flare,
          locationAddress: bzExpect(mp.location?.address, 'Location should be present'),
          visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits ?? []),
          coveredEquipmentTypes:
            mp.maintenancePlanCoveredEquipmentTypes.map(e => e.equipmentType as EquipmentType) ?? [],
        }
      : undefined
  }

  getMaintenancePlanMinimals(): MaintenancePlanMinimalInfo[] {
    const mp = this.getMaintenancePlan()
    return mp ? [mp] : []
  }
  getJobLifecycleStatusGuid() {
    return this.data.jobLifecycleStatusGuid
  }

  getJobLifecycleStatus(): JobLifecycleStatus {
    return {
      ...this.data.jobLifecycleStatus,
      stage: this.data.jobLifecycleStatus.stage as JobLifecycleStage,
      specialStatus: this.data.jobLifecycleStatus.specialStatus as JobLifecycleStatus['specialStatus'] | undefined,
    }
  }

  getJobTypeInfo() {
    return this.data.jobType
  }

  getTags(): Tag[] {
    return this.data.tags.map(({ tag }) => tag)
  }

  getUseMaintenancePlanCredit(): boolean {
    return this.data.useMaintenancePlanCredit
  }

  getMaintenancePlanCreditUsedAt(): IsoDateString | undefined {
    return this.data.maintenancePlanCreditUsedAt
  }

  getCustomerPurchaseOrderNumber(): string | undefined {
    return this.data.customerPurchaseOrderNumber
  }

  getTeamMembers(): JobTeamMember[] {
    return this.data.jobTeamMembers
  }
}

export type EditJobViewModel = {
  jobGuid: JobGuid
  displayId: number
  locationGuid: LocationGuid
  accountGuid: AccountGuid
  jobType: JobType
  installProjectType?: InstallProjectType
  summary: string
  pointOfContactGuid: ContactGuid
  isOpportunity: boolean
  isHotLead: boolean
  isMembershipOpportunity: boolean
  isMembershipRenewalOpportunity: boolean
  leadSourceGuid?: LeadSourceGuid
  leadSourceReferringContactGuid?: ContactGuid
  leadSourceAttributionDescription?: string
  equipmentTypeJobLinks: EquipmentTypeJobLink[]
  tagGuids: Tag['tagGuid'][]
  maintenancePlanVisitGuid?: string
  useMaintenancePlanCredit: boolean
  customerPurchaseOrderNumber?: string
  workCompletedAt?: IsoDateString
}

export const ComprehensiveJobDetailsQuerySchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('by-job-guid'), companyGuid: guidSchema, jobGuid: guidSchema }),
  z.object({ type: z.literal('by-job-display-id'), companyGuid: guidSchema, displayId: z.number() }),
  z.object({ type: z.literal('by-account-guid'), companyGuid: guidSchema, accountGuid: guidSchema }),
  z.object({ type: z.literal('by-company-guid'), companyGuid: guidSchema }),
])

export type ComprehensiveJobDetailsQuery = z.infer<typeof ComprehensiveJobDetailsQuerySchema>
export type IQueryComprehensiveJobDetails = AsyncFn<ForCompany<ComprehensiveJobDetailsQuery>, ComprehensiveJobDetails[]>
