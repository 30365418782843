import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { ActingCompanyUserIds, ForCompany, ForCompanyUser } from '../Company/Company'
import { fileRecordSchema } from '../Files/Files'
import { bzOptional } from '../common-schemas'

export const PRICEBOOK_RESTORE_VERSION_BACKUP_NAME = 'Pricebook Restore Automated Backup'

const PricebookDataSchemaVersionSchema = z.union([z.literal(1), z.literal(2)])
export const CURRENT_PRICEBOOK_DATA_SCHEMA_VERSION = 2
export type PricebookDataSchemaVersion = z.infer<typeof PricebookDataSchemaVersionSchema>

const PricebookVersionRecordSchema = z.object({
  pricebookVersionGuid: guidSchema,
  dataSchemaVersion: PricebookDataSchemaVersionSchema,
  createdByUserGuid: guidSchema,
  createdAt: isoDateStringSchema,
  companyGuid: guidSchema,
  pricebookItemsFileGuid: guidSchema,
  pricebookItemsFile: fileRecordSchema,
  pricebookCategoriesFileGuid: bzOptional(guidSchema),
  pricebookCategoriesFile: bzOptional(fileRecordSchema),
})

export type PricebookVersionRecord = z.infer<typeof PricebookVersionRecordSchema>

export type PricebookVersionWriter = AsyncFn<ForCompanyUser<{ pricebookVersionName?: string }>>
export type PricebookVersionGuidContainer = { pricebookVersionGuid: string }

export type PricebookVersionRecordWriter = AsyncFn<
  ForCompanyUser<
    PricebookVersionGuidContainer & {
      dataSchemaVersion: PricebookDataSchemaVersion
      pricebookItemsFileGuid: string
      pricebookCategoriesFileGuid: string
      pricebookVersionName?: string
    }
  >,
  PricebookVersionRecord
>

export type PricebookVersionRecordReader = AsyncFn<ForCompany<PricebookVersionGuidContainer>, PricebookVersionRecord>

export type PricebookVersionRestorer = AsyncFn<ActingCompanyUserIds & PricebookVersionGuidContainer>
