import {
  JobOutcomesCommissionDeductionsPopoverFormSchema,
  JobOutcomesFormSchema,
  JobOutcomesRevenueAttributionSubformSchema,
  usdToUsCents,
} from '@breezy/shared'
import { Button, Col, Row } from 'antd'
import { ReactNode, memo, useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ThinDivider from '../../elements/ThinDivider'
import CommissionableBaseCollapsibleCard from './CommissionableBaseCollapsibleCard'
import EmptyOutcomeCard from './EmptyOutcomeCard'
import InvoiceRevenueAttributionTable from './InvoiceRevenueAttributionTable'
import UserRevenueAttributionTotalCard from './UserRevenueAttributionTotalCard'
import { useCommissionableBaseUsc } from './hooks/useCommissionableBaseUsc'
import { useJobCostsDeductionUsc } from './hooks/useJobCostsDeductionUsc'
import { useOverheadAllocationDeduction } from './hooks/useOverheadAllocationDeduction'
import { useUsersWithRevenueAttribution } from './hooks/useUsersWithRevenueAttribution'
import {
  JobDetailsForOutcomes,
  JobOutcomeInvoice,
  RevenueAttributionUser,
} from './job-outcome-modal-types'

type RevenueAttributionPanelProps = {
  job: JobDetailsForOutcomes
  paidInvoices: JobOutcomeInvoice[]
  allAttributableUsers: RevenueAttributionUser[]
  isEditing: boolean
  disableEditButton: boolean
  onClickEditButton: () => void
  onClickCancelEditingButton: () => void
  onSubformSubmit: (values: JobOutcomesRevenueAttributionSubformSchema) => void
  onCommissionDeductionsPopoverSubformSubmit: (
    values: JobOutcomesCommissionDeductionsPopoverFormSchema,
  ) => void
}

const RevenueAttributionPanel: React.FC<RevenueAttributionPanelProps> = ({
  job,
  paidInvoices,
  allAttributableUsers,
  isEditing,
  disableEditButton,
  onClickEditButton,
  onClickCancelEditingButton,
  onSubformSubmit,
  onCommissionDeductionsPopoverSubformSubmit,
}) => {
  const { formState, getValues, resetField } =
    useFormContext<JobOutcomesFormSchema>()

  let content: ReactNode = null
  if (paidInvoices.length === 0) {
    content = (
      <Col className="w-1/2 pr-1.5">
        <EmptyOutcomeCard bodyText="Revenue attribution is unavailable since no paid invoices exist for this job." />
      </Col>
    )
  } else if (isEditing) {
    content = (
      <RevenueAttributionPanelEditMode
        job={job}
        paidInvoices={paidInvoices}
        allAttributableUsers={allAttributableUsers}
        onCommissionDeductionsPopoverSubformSubmit={
          onCommissionDeductionsPopoverSubformSubmit
        }
      />
    )
  } else {
    content = (
      <RevenueAttributionPanelViewMode
        allAttributableUsers={allAttributableUsers}
        job={job}
      />
    )
  }

  const onSubmit = useCallback(() => {
    try {
      if (Object.keys(formState.errors).length > 0) {
        return
      }

      const revenueAttributionGuidToInvoiceMap = getValues(
        'revenueAttributionGuidToInvoiceMap',
      )

      onSubformSubmit({
        revenueAttributionGuidToInvoiceMap,
      })

      const overheadAllocationDeductionPercent = getValues(
        'overheadAllocationDeductionPercent',
      )
      const overheadAllocationFlatDeductionUsd = getValues(
        'overheadAllocationFlatDeductionUsd',
      )
      const overheadAllocationDeductionType = getValues(
        'overheadAllocationDeductionType',
      )
      const jobCostsDeductionPercent = getValues('jobCostsDeductionPercent')
      const jobCostsFlatDeductionUsd = getValues('jobCostsFlatDeductionUsd')
      const jobCostsDeductionType = getValues('jobCostsDeductionType')
      const technicianGuidToCommissionAndBonusMap = getValues(
        'technicianGuidToCommissionAndBonusMap',
      )

      // We call the resetField API here to set the form's default value to this new
      // value. This ensures that if the user re-edits this subform and then cancels,
      // the values will be reset to the last saved values, rather than the initial
      // values from when the Job Outcomes Modal was first rendered

      // This is ugly but it looks like you have to manually reset each field in this nested
      // object structure. Otherwise it looks like the form's defaultValues for these
      // are set to shallow copy references of the passed objects
      Object.entries(revenueAttributionGuidToInvoiceMap).forEach(
        ([invoiceGuid, invoice]) => {
          Object.entries(invoice.guidToItemMap).forEach(
            ([itemGuid, revenueAttributionData]) => {
              resetField(
                `revenueAttributionGuidToInvoiceMap.${invoiceGuid}.guidToItemMap.${itemGuid}.totalRevenueUsc`,
                {
                  defaultValue: revenueAttributionData.totalRevenueUsc,
                },
              )
              resetField(
                `revenueAttributionGuidToInvoiceMap.${invoiceGuid}.guidToItemMap.${itemGuid}.soldRevenueAttributionUserGuids`,
                {
                  defaultValue:
                    revenueAttributionData.soldRevenueAttributionUserGuids,
                },
              )
              resetField(
                `revenueAttributionGuidToInvoiceMap.${invoiceGuid}.guidToItemMap.${itemGuid}.earnedRevenueAttributionUserGuids`,
                {
                  defaultValue:
                    revenueAttributionData.earnedRevenueAttributionUserGuids,
                },
              )
            },
          )
        },
      )

      Object.entries(technicianGuidToCommissionAndBonusMap).forEach(
        ([technicianUserGuid, commissionAndBonusData]) => {
          resetField(
            `technicianGuidToCommissionAndBonusMap.${technicianUserGuid}.commissionPercent`,
            {
              defaultValue: commissionAndBonusData.commissionPercent,
            },
          )
          resetField(
            `technicianGuidToCommissionAndBonusMap.${technicianUserGuid}.bonusUsd`,
            {
              defaultValue: commissionAndBonusData.bonusUsd,
            },
          )
        },
      )

      resetField('overheadAllocationDeductionPercent', {
        defaultValue: overheadAllocationDeductionPercent,
      })
      resetField('overheadAllocationFlatDeductionUsd', {
        defaultValue: overheadAllocationFlatDeductionUsd,
      })
      resetField('overheadAllocationDeductionType', {
        defaultValue: overheadAllocationDeductionType,
      })
      resetField('jobCostsDeductionPercent', {
        defaultValue: jobCostsDeductionPercent,
      })
      resetField('jobCostsFlatDeductionUsd', {
        defaultValue: jobCostsFlatDeductionUsd,
      })
      resetField('jobCostsDeductionType', {
        defaultValue: jobCostsDeductionType,
      })
    } catch (e) {
      console.error(e)
    }
  }, [formState.errors, getValues, onSubformSubmit, resetField])

  const onCancel = useCallback(() => {
    resetField('revenueAttributionGuidToInvoiceMap')
    resetField('overheadAllocationDeductionPercent')
    resetField('overheadAllocationFlatDeductionUsd')
    resetField('overheadAllocationDeductionType')
    resetField('jobCostsDeductionPercent')
    resetField('jobCostsFlatDeductionUsd')
    resetField('jobCostsDeductionType')
    resetField('technicianGuidToCommissionAndBonusMap')

    onClickCancelEditingButton()
  }, [onClickCancelEditingButton, resetField])

  const ctaButtons = useMemo(() => {
    if (paidInvoices.length === 0) {
      return null
    } else if (isEditing) {
      return (
        <Col>
          <Button
            key="cancel"
            onClick={onCancel}
            htmlType="button"
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>
          <Button
            key="save"
            onClick={onSubmit}
            htmlType="button"
            type="primary"
          >
            Save
          </Button>
        </Col>
      )
    } else {
      return (
        <Col>
          <Button
            type="default"
            disabled={disableEditButton}
            onClick={onClickEditButton}
          >
            Edit
          </Button>
        </Col>
      )
    }
  }, [
    disableEditButton,
    isEditing,
    onCancel,
    onClickEditButton,
    onSubmit,
    paidInvoices.length,
  ])

  return (
    <>
      <Row className="space-between mb-2">
        <h3>Revenue Attribution</h3>
        {ctaButtons}
      </Row>

      <Row>{content}</Row>
    </>
  )
}

type RevenueAttributionPanelViewModeProps = {
  allAttributableUsers: RevenueAttributionUser[]
  job: JobDetailsForOutcomes
}

const RevenueAttributionPanelViewMode =
  memo<RevenueAttributionPanelViewModeProps>(
    ({ allAttributableUsers, job }) => {
      const form = useFormContext<JobOutcomesFormSchema>()
      const usersWithAttribution = useUsersWithRevenueAttribution(
        allAttributableUsers,
        form,
      )

      if (Object.keys(usersWithAttribution).length === 0) {
        return (
          <div className="w-full pb-6">
            <Col className="w-1/2 pr-1.5">
              <EmptyOutcomeCard bodyText="Currently, no revenue attribution exists for this job. To start attributing revenue, please click the 'Edit' button." />
            </Col>
          </div>
        )
      }

      return (
        <div className="w-full pb-6">
          {
            // We're going to put 2 Cards in a row, so split this array of users into chunks of 2
            Array.from(
              {
                length: Math.ceil(Object.keys(usersWithAttribution).length / 2),
              },
              (_v, i) =>
                Object.entries(usersWithAttribution).slice(i * 2, i * 2 + 2),
            ).map(([user1Data, user2Data], i) => (
              <Row justify="space-between" key={i} className="mb-3 w-full">
                {user1Data && (
                  <Col key={user1Data[0]} className="w-1/2 pr-1.5">
                    <UserRevenueAttributionTotalCard
                      key={user1Data[0]}
                      user={user1Data[1].user}
                      job={job}
                      disableEditBonusAndCommissionButtons={false}
                      totalSoldRevenueUsc={user1Data[1].totalSoldRevenueUsc}
                      totalEarnedRevenueUsc={user1Data[1].totalEarnedRevenueUsc}
                    />
                  </Col>
                )}
                {user2Data && (
                  <Col key={user2Data[0]} className="w-1/2 pl-1.5">
                    <UserRevenueAttributionTotalCard
                      key={user2Data[0]}
                      user={user2Data[1].user}
                      job={job}
                      disableEditBonusAndCommissionButtons={false}
                      totalSoldRevenueUsc={user2Data[1].totalSoldRevenueUsc}
                      totalEarnedRevenueUsc={user2Data[1].totalEarnedRevenueUsc}
                    />
                  </Col>
                )}
              </Row>
            ))
          }
        </div>
      )
    },
  )

type RevenueAttributionPanelEditModeProps = {
  job: JobDetailsForOutcomes
  paidInvoices: JobOutcomeInvoice[]
  allAttributableUsers: RevenueAttributionUser[]
  onCommissionDeductionsPopoverSubformSubmit: (
    values: JobOutcomesCommissionDeductionsPopoverFormSchema,
  ) => void
}

const RevenueAttributionPanelEditMode: React.FC<
  RevenueAttributionPanelEditModeProps
> = ({
  job,
  paidInvoices,
  allAttributableUsers,
  onCommissionDeductionsPopoverSubformSubmit,
}) => {
  const form = useFormContext<JobOutcomesFormSchema>()
  const [
    disableAllBonusAndCommissionLinks,
    setDisableAllBonusAndCommissionLinks,
  ] = useState(false)

  // TODO We should do these calculations using a materialized view, or have it
  // persisted somewhere in the DB
  const soldRevenueSubtotalUsc = paidInvoices.reduce(
    (acc, invoice) => acc + usdToUsCents(invoice.subtotalPriceUsd),
    0,
  )

  const [overheadAllocationDeductionUsc, overheadAllocationDeductionPercent] =
    useOverheadAllocationDeduction(form, soldRevenueSubtotalUsc)
  const jobCostsDeductionUsc = useJobCostsDeductionUsc(
    form,
    soldRevenueSubtotalUsc,
  )
  const maximumCommissionableBaseUsc = useCommissionableBaseUsc(
    form,
    soldRevenueSubtotalUsc,
  )

  const usersWithAttribution = useUsersWithRevenueAttribution(
    allAttributableUsers,
    form,
  )

  return (
    <>
      {paidInvoices.map((paidInvoice, i) => {
        return (
          <InvoiceRevenueAttributionTable
            key={paidInvoice.invoiceGuid}
            invoice={paidInvoice}
            allAttributableUsers={allAttributableUsers}
            headerStyle={i > 0 ? { marginTop: '16px' } : undefined}
          />
        )
      })}
      <ThinDivider
        dividerStyle="dashed"
        widthPx={1}
        styleOverrides={{ marginTop: '16px', marginBottom: '16px' }}
      />
      <CommissionableBaseCollapsibleCard
        job={job}
        totalSoldRevenueUsc={soldRevenueSubtotalUsc}
        overheadAllocationDeductionUsc={overheadAllocationDeductionUsc}
        overheadAllocationDeductionPercent={overheadAllocationDeductionPercent}
        jobCostsDeductionUsc={jobCostsDeductionUsc}
        maximumCommissionableBaseUsc={maximumCommissionableBaseUsc}
        onCommissionDeductionsPopoverSubformSubmit={
          onCommissionDeductionsPopoverSubformSubmit
        }
      />
      <ThinDivider
        dividerStyle="dashed"
        widthPx={1}
        styleOverrides={{ marginTop: '16px', marginBottom: '16px' }}
      />
      <Row style={{ width: '100%' }}>
        <h4>Attribution Totals</h4>
      </Row>
      <div className="w-full pb-6">
        {
          // We're going to put 2 Cards in a row, so split this array of users into chunks of 2
          Array.from(
            { length: Math.ceil(Object.keys(usersWithAttribution).length / 2) },
            (_v, i) =>
              Object.entries(usersWithAttribution).slice(i * 2, i * 2 + 2),
          ).map(([user1Data, user2Data], i) => (
            <Row justify="space-between" key={i} className="mb-3 w-full">
              {user1Data && (
                <Col key={user1Data[0]} className="w-1/2 pr-1.5">
                  <UserRevenueAttributionTotalCard
                    key={user1Data[0]}
                    user={user1Data[1].user}
                    job={job}
                    disableEditBonusAndCommissionButtons={
                      disableAllBonusAndCommissionLinks
                    }
                    onOpenEditPopover={() =>
                      setDisableAllBonusAndCommissionLinks(true)
                    }
                    onCloseEditPopover={() =>
                      setDisableAllBonusAndCommissionLinks(false)
                    }
                    totalSoldRevenueUsc={user1Data[1].totalSoldRevenueUsc}
                    totalEarnedRevenueUsc={user1Data[1].totalEarnedRevenueUsc}
                  />
                </Col>
              )}
              {user2Data && (
                <Col key={user2Data[0]} className="w-1/2 pl-1.5">
                  <UserRevenueAttributionTotalCard
                    key={user2Data[0]}
                    user={user2Data[1].user}
                    job={job}
                    disableEditBonusAndCommissionButtons={
                      disableAllBonusAndCommissionLinks
                    }
                    onOpenEditPopover={() =>
                      setDisableAllBonusAndCommissionLinks(true)
                    }
                    onCloseEditPopover={() =>
                      setDisableAllBonusAndCommissionLinks(false)
                    }
                    totalSoldRevenueUsc={user2Data[1].totalSoldRevenueUsc}
                    totalEarnedRevenueUsc={user2Data[1].totalEarnedRevenueUsc}
                  />
                </Col>
              )}
            </Row>
          ))
        }
      </div>
    </>
  )
}

export default RevenueAttributionPanel
