import { gql } from '../../generated'

export const UPSERT_BASE_VISIT_MUTATION = gql(/* GraphQL */ `
  mutation UpsertMaintenancePlanVisits(
    $objects: [MaintenancePlanVisitsInsertInput!]!
  ) {
    insertMaintenancePlanVisits(
      objects: $objects
      onConflict: {
        constraint: maintenance_plan_visits_pkey
        updateColumns: [
          affinityDate
          companyGuid
          completedAtOverride
          dueAt
          expiresAt
          isCompletedOverride
          isExpiredOverride
          issuedAt
          jobGuid
          maintenancePlanGuid
          name
          updatedAt
        ]
      }
    ) {
      affectedRows
    }
  }
`)

export const UPSERT_INSTALLED_EQUIPMENTS_MUTATION = gql(/* GraphQL */ `
  mutation UpsertMaintenancePlanVisitInstalledEquipments(
    $maintenancePlanVisitGuid: uuid!
    $installedEquipments: [MaintenancePlanVisitInstalledEquipmentsInsertInput!]!
  ) {
    deleteMaintenancePlanVisitInstalledEquipments(
      where: { maintenancePlanVisitGuid: { _eq: $maintenancePlanVisitGuid } }
    ) {
      affectedRows
    }

    insertMaintenancePlanVisitInstalledEquipments(
      objects: $installedEquipments
    ) {
      affectedRows
    }
  }
`)

export const CLEAR_VISIT_JOB_LINK_MUTATION = gql(/* GraphQL */ `
  mutation ClearMaintenancePlanVisitJobLinkFrontend($jobGuid: uuid!) {
    clearMaintenancePlanVisitLink: updateMaintenancePlanVisits(
      where: { jobGuid: { _eq: $jobGuid } }
      _set: {
        jobGuid: null
        isCompletedOverride: null
        completedAtOverride: null
      }
    ) {
      __typename
    }
  }
`)
