import {
  BzDateFns,
  CANCELABLE_LOAN_STATUSES,
  CalculatePaths,
  Guid,
  LoanRecord,
  LoanStatusAbbrDisplayNames,
  PermissionV2,
  formatUsc,
} from '@breezy/shared'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Modal } from 'antd'
import React, { useCallback } from 'react'
import { useSubscription } from 'urql'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import CopyToClipboard from '../../../elements/CopyToClipboard/CopyToClipboard'
import { LocalDateView } from '../../../elements/DateView/LocalDateView'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import { Link } from '../../../elements/Link/Link'
import Switch from '../../../elements/Switch/Switch'
import { gql } from '../../../generated'
import { trpc } from '../../../hooks/trpc'
import { useIsProduction } from '../../../hooks/useIsProduction'
import {
  useExpectedCompanyTimeZoneId,
  useIsImpersonating,
} from '../../../providers/PrincipalUser'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import { Authorized } from '../../Permissions/Authorized/Authorized'
import UrqlSubscriptionLoader from '../../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import DevToolLoanSimulator from '../DevToolLoanSimulator/DevToolLoanSimulator'
import LoanStatusTag from '../LoanStatusTag/LoanStatusTag'

const LOAN_RECORD_INVOICE_DETAILS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription LoanRecordInvoiceDetailsSubscription($invoiceGuid: uuid!) {
    wisetackLoanRecords(where: { invoiceGuid: { _eq: $invoiceGuid } }) {
      invoiceV2 {
        invoiceGuid
        displayId
        serviceCompletionDate
        messageHtml
      }
    }
  }
`)

type LoanDetailsModalProps = {
  loanRecord: LoanRecord
  onClose: () => void
}

const LoanDetailsModal = React.memo<LoanDetailsModalProps>(
  ({ loanRecord, onClose }) => {
    const [cancelOpen, setCancelOpen] = React.useState(false)
    const isImpersonating = useIsImpersonating()
    const isProduction = useIsProduction()
    const tzId = useExpectedCompanyTimeZoneId()

    const cancelLoanMutation = trpc.financing[
      'financing:cancel-loan'
    ].useMutation({
      onSuccess: () => {
        onClose()
      },
    })

    const cancel = useCallback(async () => {
      await cancelLoanMutation.mutateAsync({
        transactionGuid: loanRecord.externalTransactionGuid,
      })
    }, [cancelLoanMutation, loanRecord.externalTransactionGuid])

    return (
      <OnsiteBasicModal
        header="Loan Application Details"
        open
        onClose={onClose}
        footer={null}
      >
        <div className="grid grid-cols-2 gap-x-2">
          <Column label="Contact Name" value={loanRecord.contactName} />
          <Column
            label="Application Link"
            value={
              <CopyToClipboard
                label="Copy link"
                payload={loanRecord.loanApplicationLink}
                isUrl
              />
            }
          />
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          {false && <Column label="Pre-qualified Amount" value={<EmDash />} />}
          <Column
            label="Requested Financing Amount"
            value={formatUsc(loanRecord.requestedLoanAmountUsc)}
          />
          <Column
            label="Approved Financing Amount"
            value={
              loanRecord.approvedLoanAmountUsc ? (
                formatUsc(loanRecord.approvedLoanAmountUsc)
              ) : (
                <EmDash />
              )
            }
          />
          {/* TODO - update this when we persist maximum_amount_offered_usc */}
          {false && (
            <Column label="Maximum Amount Offered" value={<EmDash />} />
          )}
          <Column
            label="Processing Fee"
            value={
              loanRecord.processingFeeUsc ? (
                formatUsc(loanRecord.processingFeeUsc)
              ) : (
                <EmDash />
              )
            }
          />
          <Column
            label="Status"
            value={
              <Switch value={loanRecord.latestLoanRecordStatus.loanStatus}>
                {{
                  DECLINED: () => (
                    <>
                      <p>
                        <LoanStatusTag
                          status={loanRecord.latestLoanRecordStatus.loanStatus}
                        />
                        <br />
                        <br />
                        <span className="text-bz-gray-700">
                          The loan application was declined. To continue with
                          financing, try re-applying for the loan with a
                          different contact on the account.
                        </span>
                      </p>
                      <p>
                        <span className="text-bz-gray-700">
                          To create a new contact, navigate to the{' '}
                          <Link to={`/accounts/${loanRecord.accountGuid}`}>
                            Account details page
                          </Link>{' '}
                          and add a new contact to the account.
                        </span>
                      </p>
                    </>
                  ),
                  default: () => (
                    <LoanStatusTag
                      status={loanRecord.latestLoanRecordStatus.loanStatus}
                    />
                  ),
                }}
              </Switch>
            }
          />
          {CANCELABLE_LOAN_STATUSES.includes(
            loanRecord.latestLoanRecordStatus.loanStatus,
          ) && (
            <Column
              label="Actions"
              value={
                <Button
                  className="mt-1 border border-solid border-bz-red-500 bg-bz-red-100 text-bz-red-500 hover:border-bz-red-600 hover:bg-bz-red-200 hover:text-bz-red-600"
                  onClick={() => setCancelOpen(true)}
                  disabled={cancelLoanMutation.isLoading}
                  icon={
                    <FontAwesomeIcon
                      className="ml-[2px] text-bz-red-500"
                      icon={faTrash}
                    />
                  }
                >
                  Cancel Application
                </Button>
              }
            />
          )}
          {loanRecord.latestLoanRecordStatus.loanStatusDetail && (
            <Column
              label="Actions Required"
              value={
                loanRecord.latestLoanRecordStatus.loanStatusDetail ?? <EmDash />
              }
            />
          )}
          {/* TODO: update this when we implement refunds */}
          {false && <Column label="Refund" value={<EmDash />} />}
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          <WisetackInvoiceV2Details invoiceGuid={loanRecord.invoiceGuid} />
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          <Column
            label="Application Start Date"
            value={BzDateFns.format(
              BzDateFns.parseISO(loanRecord.createdAt, tzId),
              'MMM dd, yyyy',
            )}
          />
          <Column
            label="Expiration Date"
            value={
              loanRecord.expiresAt ? (
                BzDateFns.format(
                  BzDateFns.parseISO(loanRecord.expiresAt, tzId),
                  'MMM dd, yyyy',
                )
              ) : (
                <EmDash />
              )
            }
          />
          <Column
            label="Date of Last Update"
            value={BzDateFns.format(
              BzDateFns.parseISO(
                loanRecord.loanRecordStatuses.sort((a, b) =>
                  a.createdAt.localeCompare(b.createdAt),
                )[0]?.createdAt ?? loanRecord.updatedAt,
                tzId,
              ),
              'MMM dd, yyyy',
            )}
          />
        </div>
        {loanRecord.loanRecordStatuses.length > 1 && (
          <>
            <Divider />
            <div className="col mt-[-8px] w-full">
              <BzCollapsible title="Status History" tailwindPaddingX="px-0">
                {loanRecord.loanRecordStatuses
                  .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                  .map((status, index) => (
                    <div className="row flex-between w-full" key={index}>
                      <div className="col-span-1">
                        <div className="text-sm">
                          {LoanStatusAbbrDisplayNames[status.loanStatus]}
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="text-sm font-semibold text-bz-gray-900">
                          {BzDateFns.format(
                            BzDateFns.parseISO(status.createdAt, tzId),
                            'MMM dd, yyyy',
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </BzCollapsible>
            </div>
          </>
        )}
        {!isProduction && (
          <Authorized
            to={PermissionV2.LOAN_SIMULATOR}
            overrideAuthorized={isImpersonating}
          >
            <DevToolLoanSimulator loanRecordGuid={loanRecord.loanRecordGuid} />
          </Authorized>
        )}
        <Modal
          title="Are you sure you want to cancel?"
          open={cancelOpen}
          onOk={async () => {
            await cancel()
          }}
          confirmLoading={cancelLoanMutation.isLoading}
          onCancel={() => setCancelOpen(false)}
          okButtonProps={{ danger: true }}
        >
          <div className="column">
            <div className="text-center">
              Once you cancel the loan application, you will not be able to
              un-cancel it.
            </div>
          </div>
        </Modal>
      </OnsiteBasicModal>
    )
  },
)

const WisetackInvoiceV2Details = React.memo<{ invoiceGuid: Guid }>(
  ({ invoiceGuid }) => {
    const [subscription] = useSubscription({
      query: LOAN_RECORD_INVOICE_DETAILS_SUBSCRIPTION,
      variables: {
        invoiceGuid,
      },
    })

    return (
      <UrqlSubscriptionLoader
        subscription={subscription}
        render={data => {
          const invoice = data.wisetackLoanRecords[0].invoiceV2

          if (!invoice) return <></>

          return (
            <>
              <Column
                label="Invoice #"
                value={
                  <Link
                    to={CalculatePaths.invoiceOverview({
                      invoiceGuid: invoice.invoiceGuid,
                    })}
                  >
                    {invoice.displayId}
                  </Link>
                }
              />
              <Column
                label="Service Completed On"
                value={
                  invoice.serviceCompletionDate ? (
                    <LocalDateView localDate={invoice.serviceCompletionDate} />
                  ) : (
                    <EmDash />
                  )
                }
              />
              {invoice.messageHtml && (
                <Column
                  label="Transaction Purpose"
                  value={<HtmlRenderer htmlContent={invoice.messageHtml} />}
                />
              )}
            </>
          )
        }}
      />
    )
  },
)

const Label = React.memo<React.PropsWithChildren>(({ children }) => {
  return (
    <div className="text-sm font-semibold text-bz-gray-900">{children}</div>
  )
})

const Column = React.memo<{ label: string; value: React.ReactNode }>(
  ({ label, value }) => {
    return (
      <div className="flex flex-col gap-1">
        <Label>{label}</Label>
        {<div className="text-sm">{value}</div>}
      </div>
    )
  },
)

export default LoanDetailsModal
