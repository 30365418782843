import { z } from 'zod'
import { AsyncFn, IsoDateString, TimeZoneId } from '../../../common'
import { guidSchema, uscSchema } from '../../../contracts/_common'
import { Guid, bzOptional } from '../../common-schemas'
import { LoanRecord, LoanStatus, WithMerchantId } from './Lending'

export const InitiateLoanTransactionRequestSchema = z.object({
  companyGuid: guidSchema,
  accountGuid: guidSchema,
  invoiceGuid: guidSchema,
  contactGuid: guidSchema,
  jobGuid: bzOptional(guidSchema),
  requestedLoanAmountUsc: uscSchema,
  serviceDescription: z.string(),
})
export type InitiateLoanTransactionRequest = z.infer<typeof InitiateLoanTransactionRequestSchema>

export const InitiateLoanTransactionRequestUnauthorizedSchema = z.object({
  companyGuid: guidSchema,
  invoiceGuid: guidSchema,
  contactGuid: guidSchema,
})

const CreateLoanTransactionRequestSchema = InitiateLoanTransactionRequestSchema.extend({
  transactionGuid: guidSchema,
  merchantGuid: guidSchema,
  loanApplicationLink: z.string(),
  prequalRecordGuid: bzOptional(guidSchema),
})

export type CreateLoanTransactionRequest = z.infer<typeof CreateLoanTransactionRequestSchema>

export type InitiateLoanTransactionUnauthorizedRequest = z.infer<
  typeof InitiateLoanTransactionRequestUnauthorizedSchema
>
type LoanRecordGuidContainer = {
  loanRecordGuid: Guid
}

export type LoanTransactionInitiatorUnauthorizedResponse = LoanRecordGuidContainer & {
  loanApplicationLink: string
}

export type LoanTransactionInitiatorUnauthorized = AsyncFn<
  InitiateLoanTransactionUnauthorizedRequest,
  LoanTransactionInitiatorUnauthorizedResponse
>

export type LoanTransactionInitiator = AsyncFn<InitiateLoanTransactionRequest, LoanRecord>

const CancelLoanTransactionRequestSchema = z.object({
  transactionGuid: guidSchema,
})

export type CancelLoanTransactionRequest = z.infer<typeof CancelLoanTransactionRequestSchema>

export type LoanTransactionCanceler = AsyncFn<CancelLoanTransactionRequest, void>

export type ExternalInitiateLoanTransactionRequest = WithMerchantId<
  InitiateLoanTransactionRequest & {
    issuedAt: IsoDateString
    companyTimeZoneId: TimeZoneId
    serviceDescription: string
  }
>

export type ExternalInitiateLoanTransactionResponse = {
  externalLoanTransactionId: Guid
  loanApplicationLink: string
  status: LoanStatus
  transactionAmountUsc: number
}

export type ExternalLoanTransactionInitiator = AsyncFn<
  ExternalInitiateLoanTransactionRequest,
  ExternalInitiateLoanTransactionResponse
>

export type ExternalLoanCanceler = AsyncFn<{ merchantId: string; data: { transactionId: string } }, void>
