import classNames from 'classnames'
import React, { ReactNode } from 'react'

export type ActionItem = {
  readonly title: string | ReactNode
  readonly onClick: () => void
  readonly disabled?: boolean
}

type ActionItemsProps = {
  actionItems: ActionItem[]
  centered?: boolean
}

const ActionItems = React.memo<ActionItemsProps>(
  ({ actionItems, centered }) => {
    return (
      <div className="rounded-lg py-2">
        {actionItems.map((actionItem, index) => (
          <div
            className={classNames(
              'regular_14_22 break-words px-3 py-1',
              actionItem.disabled
                ? 'text-bz-gray-600'
                : 'text-bz-gray-900 hover:cursor-pointer hover:bg-bz-gray-200',
              {
                'text-center': centered,
              },
            )}
            onClick={e => {
              if (actionItem.disabled) return
              e.stopPropagation()
              actionItem.onClick()
            }}
            key={index}
          >
            {actionItem.title}
          </div>
        ))}
      </div>
    )
  },
)

export default ActionItems
