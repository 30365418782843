import { gql } from '../../generated'

export const FINANCING_ACCOUNT_INFO_QUERY = gql(/* GraphQL */ `
  query FinancingAccountInfo($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountGuid
      accountContacts {
        primary
        accountContactGuid
        contact {
          contactGuid
          companyGuid
          firstName
          lastName
          notificationPreferenceType
          primaryEmailAddress {
            emailAddressGuid
            emailAddress
            companyGuid
          }
          primaryPhoneNumber {
            phoneNumber
            phoneNumberGuid
            type
            companyGuid
          }
          salutation
          title
        }
        archived
        accountGuid
        companyGuid
      }
    }
  }
`)
