import { createTsForm } from '@ts-react/form'
import { FormComponent } from '@ts-react/form/lib/src/createSchemaForm'
import { z } from 'zod'
import RichTextArea from '../RichTextAreaField/RichTextAreaField'
import { RichTextAreaSchema } from '../RichTextAreaField/RichTextAreaField.schema'
import TextField from '../TextField/TextField'
import { SendAsEmailFormSchema } from './SendEmailForm.schema'

export type SendAsEmailFormData = z.infer<typeof SendAsEmailFormSchema>

const sendEmailFieldMapping = [
  [z.string(), TextField],
  [RichTextAreaSchema, RichTextArea],
] as const

export const createSendEmailForm = (form: FormComponent) =>
  createTsForm(sendEmailFieldMapping, {
    FormComponent: form,
  })
