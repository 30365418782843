import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { AccountGuid, AccountGuidContainer } from '../Accounts/Account'
import { CompanyGuid, ForCompany, ForCompanyUser } from '../Company/Company'
import { Guid, bzOptional } from '../common-schemas'
import { Contact, ContactUpserterDTOSchema } from '../contacts/Contact'

type AccountContactGuid = Guid
export type AccountContact = {
  accountContactGuid: AccountContactGuid
  accountGuid: AccountGuid
  companyGuid: CompanyGuid
  primary: boolean
  archived: boolean
  contact: Contact
}

const BaseUpsertAccountContactDTOSchema = z.object({
  accountContactGuid: guidSchema,
  accountGuid: guidSchema,
  primary: z.boolean(),
})

export const UpsertAccountContactDTOSchema = BaseUpsertAccountContactDTOSchema.extend({
  contactGuid: bzOptional(guidSchema),
  contact: ContactUpserterDTOSchema,
})

const LinkedUpsertAccountContactDTOSchema = BaseUpsertAccountContactDTOSchema.extend({
  contactGuid: guidSchema,
})

export type UpsertAccountContactDTO = z.infer<typeof UpsertAccountContactDTOSchema>
type LinkedUpsertAccountContactDTO = z.infer<typeof LinkedUpsertAccountContactDTOSchema>
export type AccountContactUpserter = AsyncFn<ForCompanyUser<UpsertAccountContactDTO | LinkedUpsertAccountContactDTO>>

export const PrimaryAccountContactUpdaterDTOSchema = z.object({
  accountContactGuid: guidSchema,
  accountGuid: guidSchema,
})

export type PrimaryAccountContactUpdaterDTO = z.infer<typeof PrimaryAccountContactUpdaterDTOSchema>

/**
 * Not this will have the side effect of marking all other account-contacts for this
 * account as non-primary
 */
export type PrimaryAccountContactUpdater = AsyncFn<ForCompanyUser<PrimaryAccountContactUpdaterDTO>>

export type AccountPrimaryContactReader = AsyncFn<ForCompany<AccountGuidContainer>, Contact>
