import { ForwardedRef } from 'react'
import { typedForwardRef, typedMemo } from '../../utils/react-utils'
import BzSelect, { BzSelectProps } from '../BzSelect/BzSelect'
import { BzSelectMode } from '../BzSelect/BzSelectTypes'

type SelectFieldProps<
  SelectMode extends BzSelectMode = 'default',
  T extends string = '',
> = BzSelectProps<SelectMode, T>

export const SelectField = typedMemo(
  typedForwardRef(
    <SelectMode extends BzSelectMode = 'default', T extends string = ''>(
      props: SelectFieldProps<SelectMode, T>,
      ref: ForwardedRef<HTMLDivElement>,
    ) => (
      <div ref={ref}>
        {/* For whatever reason, TypeScript says the `props` isn't the right type because it doesn't have
            "IntrinsicAttributes" or something */}
        <BzSelect {...(props as React.ComponentProps<typeof BzSelect>)} />
      </div>
    ),
  ),
) as typeof BzSelect
