import { z } from 'zod'
import { AsyncFn } from '../../../common'
import { guidSchema } from '../../../contracts/_common'
import { bzOptional } from '../../common-schemas'
import { Account } from '../Account'

const FindByAccountGuidQuerySchema = z.object({
  type: z.literal('by-account-guid'),
  companyGuid: guidSchema,
  accountGuid: guidSchema,
})

export type FindByAccountGuidQuery = z.infer<typeof FindByAccountGuidQuerySchema>

const FindAllAccountsQuerySchema = z.object({
  companyGuid: guidSchema,
  type: z.literal('all'),
  limit: bzOptional(z.number()),
  offset: bzOptional(z.number()),
})

export type FindAllAccountsQuery = z.infer<typeof FindAllAccountsQuerySchema>

export const AccountsQuerySchema = z.discriminatedUnion('type', [
  FindByAccountGuidQuerySchema,
  FindAllAccountsQuerySchema,
])
export type AccountsQuery = z.infer<typeof AccountsQuerySchema>

export type AccountQuerierResult = {
  accounts: Array<Account>
  totalCount?: number
}

export type IAccountQuerier = AsyncFn<AccountsQuery, AccountQuerierResult>

export type IFindByAccountGuidQuerier = AsyncFn<Omit<FindByAccountGuidQuery, 'type'>, AccountQuerierResult>
export type IFindAllAccountsForACompanyQuerier = AsyncFn<Omit<FindAllAccountsQuery, 'type'>, AccountQuerierResult>
