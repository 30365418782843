import { CardOnFile, isCardExpired } from '@breezy/shared'
import React from 'react'
import StatusTag from '../../elements/StatusTag/StatusTag'

export const CardOnFileStatusTag = React.memo<{ cardOnFile: CardOnFile }>(
  ({ cardOnFile }) => {
    if (isCardExpired(cardOnFile)) {
      return <StatusTag text="Expired" color="red" border="strong" />
    }

    return null
  },
)
