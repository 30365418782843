import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React from 'react'
import { ReminderStatusTypesEnum } from '../../../generated/user/graphql'

type CompleteStatusIconProps = {
  status: ReminderStatusTypesEnum
  toggle?: () => Promise<void>
  submitting?: boolean
}

const CompleteStatusIcon = React.memo<CompleteStatusIconProps>(
  ({ status, toggle }) => {
    return (
      <div
        onClick={toggle}
        className={cn(
          'flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-solid',
          {
            'border-bz-gray-700 hover:border-bz-green-800 hover:bg-bz-green-100':
              status === 'INCOMPLETE',
          },
          {
            'border-bz-green-800 bg-bz-green-800': status === 'COMPLETE',
          },
        )}
      >
        <FontAwesomeIcon
          icon={faCheck}
          size="sm"
          className={cn({
            'text-bz-gray-700 hover:text-bz-green-800': status === 'INCOMPLETE',
            'text-bz-gray-100': status === 'COMPLETE',
          })}
        />
      </div>
    )
  },
)

export default CompleteStatusIcon
