import { convertFetchPrequalRecordToPrequalRecord } from '@breezy/backend/src/application-types'
import {
  DECLINEABLE_PREQUAL_STATUSES,
  PrequalRecord,
  PrequalStatus,
} from '@breezy/shared'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

import { usdToUsCents } from '@breezy/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'antd'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSubscription } from 'urql'
import { z } from 'zod'
import { NumberField } from '../../../elements/Forms/NumberField'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import Switch from '../../../elements/Switch/Switch'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import PrequalStatusTag from '../../PrequalStatusTag/PrequalStatusTag'
import UrqlSubscriptionLoader from '../../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import { PREQUAL_RECORD_SUBSCRIPTION } from './DevToolPrequalSimulator.gql'
import { useWisetackPrequalWebhookTestMutation } from './useWisetackPrequalWebhookTestMutation'

type DevToolPrequalSimulatorProps = {
  prequalRecordGuid: string
}

type BasicNextPrequalStepInitiatorProps = {
  prequalRecord: PrequalRecord
  currentStatus: PrequalStatus
  maxQualifiedAmountUsc?: number
  targetStatus: PrequalStatus
}
const BasicNextPrequalStepInitiator =
  React.memo<BasicNextPrequalStepInitiatorProps>(
    ({ prequalRecord, maxQualifiedAmountUsc, currentStatus, targetStatus }) => {
      const { triggerWisetackWebhook, loading } =
        useWisetackPrequalWebhookTestMutation()

      return (
        <div className="flex items-center gap-4">
          <PrequalStatusTag status={currentStatus} />
          <FontAwesomeIcon icon={faArrowRight} />
          <PrequalStatusTag status={targetStatus} />
          <Button
            type="primary"
            disabled={loading}
            onClick={() => {
              triggerWisetackWebhook({
                prequalRecord,
                maxQualifiedAmountUsc,
                changedStatus: targetStatus,
              })
            }}
          >
            Simulate
          </Button>
        </div>
      )
    },
  )
const MaxPrequalAmountSchema = z.object({
  prequalAmountUsd: z
    .number()
    .min(0.01, 'Payment amount must be greater than $0'),
})

type MaxPrequalAmount = z.infer<typeof MaxPrequalAmountSchema>

const DevToolPrequalSimulator = React.memo<DevToolPrequalSimulatorProps>(
  ({ prequalRecordGuid }) => {
    const [showSimulator, setShowSimulator] = useState(false)
    const [showAdvanced, setShowAdvanced] = useState(false)
    const companyGuid = useExpectedCompany().companyGuid
    const [prequalRecordSubscription] = useSubscription({
      query: PREQUAL_RECORD_SUBSCRIPTION,
      variables: { prequalRecordGuid, companyGuid },
    })

    const {
      control,
      formState: { errors },
      watch,
    } = useForm<MaxPrequalAmount>({
      defaultValues: {
        prequalAmountUsd: 10000,
      },
      mode: 'onChange',
      resolver: zodResolver(MaxPrequalAmountSchema),
    })

    const prequalAmountUsd = watch('prequalAmountUsd')

    const maxPrequalAmountUsc = useMemo(
      () => usdToUsCents(prequalAmountUsd),
      [prequalAmountUsd],
    )

    return (
      <UrqlSubscriptionLoader
        subscription={prequalRecordSubscription}
        render={prequalRecordSubscription => {
          const prequalRecord = convertFetchPrequalRecordToPrequalRecord(
            prequalRecordSubscription.wisetackPrequalRecords[0],
          )

          return (
            <>
              <Button
                className="max-w-0 pl-0"
                type="link"
                onClick={() => setShowSimulator(prev => !prev)}
              >
                {!showSimulator ? 'Show Dev Tools' : 'Hide Dev Tools'}
              </Button>
              {showSimulator ? (
                <div className="flex flex-col rounded-md bg-daybreak-blue-200 p-2">
                  <div className="text-lg font-semibold">Prequal Simulator</div>
                  <div className="mt-2 inline-block">
                    <FontAwesomeIcon className="mr-1" icon={faCircleQuestion} />
                    <span className="text-bz-gray-800">
                      Make sure you refresh or switch tabs after triggering a
                      loan application update to see the changes reflected.
                    </span>
                  </div>
                  <div className="mt-4 flex flex-col">
                    <Switch value={prequalRecord.status}>
                      {{
                        PENDING: () => (
                          <BasicNextPrequalStepInitiator
                            prequalRecord={prequalRecord}
                            currentStatus="PENDING"
                            targetStatus="INITIATED"
                          />
                        ),
                        INITIATED: () => (
                          <div className="flex flex-col">
                            <ReactHookFormItem
                              name="prequalAmountUsd"
                              control={control}
                              errors={errors}
                              label="Prequal Amount"
                              render={({ field }) => (
                                <NumberField isMoney {...field} />
                              )}
                            />
                            <BasicNextPrequalStepInitiator
                              prequalRecord={prequalRecord}
                              currentStatus="INITIATED"
                              maxQualifiedAmountUsc={maxPrequalAmountUsc}
                              targetStatus="PREQUALIFIED"
                            />
                          </div>
                        ),
                        PREQUALIFIED: () =>
                          'This loan has already been prequalified',
                        DECLINED: () => 'This loan has already been declined',
                        default: () => 'Something went wrong',
                      }}
                    </Switch>
                    <div className="mt-4">
                      <Button
                        className="max-w-0 pl-0"
                        type="link"
                        onClick={() => setShowAdvanced(prev => !prev)}
                      >
                        Advanced
                      </Button>
                      {showAdvanced ? (
                        <div className="mt-4 flex flex-col">
                          <div className="flex flex-col">
                            <div className="font-semibold">Decline Loan</div>
                            {DECLINEABLE_PREQUAL_STATUSES.includes(
                              prequalRecord.status,
                            ) ? (
                              <BasicNextPrequalStepInitiator
                                prequalRecord={prequalRecord}
                                currentStatus={prequalRecord.status}
                                targetStatus="DECLINED"
                              />
                            ) : (
                              <div className="text-red-500">
                                This loan cannot be declined
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )
        }}
      />
    )
  },
)

export default DevToolPrequalSimulator
