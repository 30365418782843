import { z } from 'zod'
import { MissingCaseError, R } from '../../common'
import { AssignableApptViewModel } from '../Appointments/Appointments'
import { BzDuration } from '../DateTime/BzDuration'
import { JobType } from '../JobTypes/JobTypes'
export enum JobClass {
  SERVICE = 'SERVICE',
  MAINTENANCE = 'MAINTENANCE',
  ESTIMATE_REPAIR = 'ESTIMATE_REPAIR',
  ESTIMATE_REPLACE = 'ESTIMATE_REPLACE',
  INSTALL = 'INSTALL',
  WARRANTY = 'WARRANTY',
  CALLBACK = 'CALLBACK',
  SALES = 'SALES',
  UNKNOWN = 'UNKNOWN',
}

export const JobClassSchema = z.nativeEnum(JobClass)

export const jobClassDisplayNames = {
  [JobClass.SERVICE]: 'Service',
  [JobClass.MAINTENANCE]: 'Maintenance',
  [JobClass.ESTIMATE_REPAIR]: 'Estimate Repair',
  [JobClass.ESTIMATE_REPLACE]: 'Estimate Replace',
  [JobClass.INSTALL]: 'Install',
  [JobClass.WARRANTY]: 'Warranty',
  [JobClass.CALLBACK]: 'Callback',
  [JobClass.SALES]: 'Sales',
  [JobClass.UNKNOWN]: 'Unknown',
}

const jobClassShortDisplayNames = {
  [JobClass.SERVICE]: 'Service',
  [JobClass.MAINTENANCE]: 'Maintenance',
  [JobClass.ESTIMATE_REPAIR]: 'Est Repair',
  [JobClass.ESTIMATE_REPLACE]: 'Est Replace',
  [JobClass.INSTALL]: 'Install',
  [JobClass.WARRANTY]: 'Warranty',
  [JobClass.CALLBACK]: 'Callback',
  [JobClass.SALES]: 'Sales',
  [JobClass.UNKNOWN]: 'Unknown',
}

export enum JobClassGroup {
  SERVICE = 'SERVICE',
  MAINTENANCE = 'MAINTENANCE',
  INSTALL = 'INSTALL',
  SALES = 'SALES',
  OTHER = 'OTHER',
}

const jobClassGroupDisplayNames = {
  [JobClassGroup.SERVICE]: 'Service',
  [JobClassGroup.MAINTENANCE]: 'Maintenance',
  [JobClassGroup.INSTALL]: 'Install',
  [JobClassGroup.SALES]: 'Sales',
  [JobClassGroup.OTHER]: 'Other',
}

export const JOBS_V2_CLASSES = [JobClass.INSTALL, JobClass.SERVICE, JobClass.MAINTENANCE, JobClass.SALES] as const
export type JobsV2Class = (typeof JOBS_V2_CLASSES)[number]

export const toJobClassGroup = (jobClass: JobClass): JobClassGroup => {
  switch (jobClass) {
    case JobClass.SERVICE:
      return JobClassGroup.SERVICE
    case JobClass.ESTIMATE_REPAIR:
      return JobClassGroup.SERVICE
    case JobClass.MAINTENANCE:
      return JobClassGroup.MAINTENANCE
    case JobClass.ESTIMATE_REPLACE:
      return JobClassGroup.INSTALL
    case JobClass.INSTALL:
      return JobClassGroup.INSTALL
    case JobClass.WARRANTY:
      return JobClassGroup.INSTALL
    case JobClass.CALLBACK:
      return JobClassGroup.SERVICE
    case JobClass.SALES:
      return JobClassGroup.SALES
    case JobClass.UNKNOWN:
      return JobClassGroup.OTHER
    default:
      throw new MissingCaseError(`Job Class: ${jobClass}`)
  }
}

export const defaultDurationForJobClass = {
  [JobClass.INSTALL]: BzDuration.parse('08:30'), // Based on Advisor Estimate),
  [JobClass.WARRANTY]: BzDuration.parse('02:30'),
  [JobClass.CALLBACK]: BzDuration.parse('02:30'),
  [JobClass.SERVICE]: BzDuration.parse('01:30'), // Based on Advisor Estimate),
  [JobClass.MAINTENANCE]: BzDuration.parse('01:30'), // Based on Advisor Estimate),
  [JobClass.ESTIMATE_REPLACE]: BzDuration.parse('01:30'),
  [JobClass.ESTIMATE_REPAIR]: BzDuration.parse('01:30'),
  [JobClass.SALES]: BzDuration.parse('01:30'),
  [JobClass.UNKNOWN]: BzDuration.parse('01:30'),
}

export const isJobClass = (x: unknown): x is JobClass => {
  return typeof x === 'string' && Object.keys(JobClass).includes(x)
}

export const formatJobClass = (s: string): string => jobClassDisplayNames[s as JobClass]
export const formatJobClassShort = (s: string): string => jobClassShortDisplayNames[s as JobClass]
export const formatJobClassGroup = (s: JobClassGroup): string => jobClassGroupDisplayNames[s as JobClassGroup]

const DEFAULT_INSTALL_JOB_PROJECT_TYPE_MAP = {
  NEW_CONSTRUCTION: {
    display: 'New Construction',
    shortDisplay: 'New Construction',
    description: 'Newly constructed residential building or home',
    isDefaultOption: true,
  },
  RETROFIT: {
    display: 'Retrofit — Partial System Upgrade',
    shortDisplay: 'Retrofit',
    description: 'Partial upgrade of an existing HVAC system',
    isDefaultOption: true,
  },
  SYSTEM_REPLACEMENT: {
    display: 'Full System Replacement',
    shortDisplay: 'System Replacement',
    description: 'Replaces an existing HVAC system with a new one',
    isDefaultOption: true,
  },
} as const

export type DefaultInstallProjectTypeDisplay =
  (typeof DEFAULT_INSTALL_JOB_PROJECT_TYPE_MAP)[keyof typeof DEFAULT_INSTALL_JOB_PROJECT_TYPE_MAP]['display']

const INSTALL_JOB_PROJECT_TYPE_MAP = {
  ...DEFAULT_INSTALL_JOB_PROJECT_TYPE_MAP,
  RADIANT_FLOOR_HEATING: {
    display: 'Radiant Floor Heating',
    shortDisplay: 'Radiant Floor',
    description: 'Installation of Radiant Flooring',
    isDefaultOption: false,
  },
  DUCTING_REPLACEMENT: {
    display: 'Ducting Replacement',
    shortDisplay: 'Ducting Replacement',
    description: 'Ducting Replacement',
    isDefaultOption: false,
  },
} as const

export const INSTALL_JOB_PROJECT_TYPE = R.keys(INSTALL_JOB_PROJECT_TYPE_MAP)
export type InstallProjectType = (typeof INSTALL_JOB_PROJECT_TYPE)[number]

const PROJECT_TYPES_THAT_MAY_REQUIRE_EXISTING_EQUIPMENT_DEACTIVATION: InstallProjectType[] = [
  'RETROFIT',
  'SYSTEM_REPLACEMENT',
]

export const defaultInstallProjectTypes = () => {
  return Object.entries(INSTALL_JOB_PROJECT_TYPE_MAP)
    .filter(([key, value]) => value.isDefaultOption)
    .map(([key, value]) => key) as InstallProjectType[]
}

export const displayForInstallProjectType = (installProjectType: InstallProjectType) => {
  return INSTALL_JOB_PROJECT_TYPE_MAP[installProjectType].display
}

export const shortDisplayForInstallProjectType = (installProjectType: InstallProjectType) => {
  return INSTALL_JOB_PROJECT_TYPE_MAP[installProjectType].shortDisplay
}

export const installProjectTypeRequiredForJobClass = (jobClass: JobClass) =>
  jobClass === JobClass.INSTALL || jobClass === JobClass.ESTIMATE_REPLACE

export const newEquipementRequiredForJobClass = (jobClass: JobClass) =>
  jobClass === JobClass.INSTALL || jobClass === JobClass.ESTIMATE_REPLACE || jobClass === JobClass.SALES

export const existingEquipmentMayRequireDeactivation = (projectType: InstallProjectType): boolean => {
  return PROJECT_TYPES_THAT_MAY_REQUIRE_EXISTING_EQUIPMENT_DEACTIVATION.includes(projectType)
}

export const richJobTypeDescriptor = (jobTypeName: JobType['name']): string => {
  return jobTypeName
}

export const apptShouldShowJobClass = (appt: AssignableApptViewModel) =>
  appt.appointment.appointmentType !== 'Other' && !installProjectTypeRequiredForJobClass(appt.jobType.jobClass)

export const buildAppointmentTitle = (appt: AssignableApptViewModel) => {
  if (appt.appointment.appointmentType === 'Other') {
    return richJobTypeDescriptor(appt.jobType.name)
  }

  return `${appt.appointment.appointmentType} for ${richJobTypeDescriptor(appt.jobType.name)}`
}

export const selectableJobClasses = [JobClass.SERVICE, JobClass.MAINTENANCE, JobClass.INSTALL, JobClass.SALES] as const

export const filterableJobClasses = [JobClass.SERVICE, JobClass.MAINTENANCE, JobClass.INSTALL, JobClass.SALES] as const
