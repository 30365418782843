import { CardOnFile, TimeZoneId, isCardExpired } from '@breezy/shared'
import { Divider } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import FormHeader from '../../../../elements/Forms/FormHeader'
import { RadioButtonListField } from '../../../../elements/Forms/RadioButtonListField'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import CardOnFilePreview from '../CardOnFilePreview/CardOnFilePreview'
import { CreditCardPaymentFormData } from './CreditCardPaymentForm'

type CardOnFileRadioButtonListFieldProps = {
  cardsOnFile: CardOnFile[]
  tzId: TimeZoneId
}
const CardOnFileRadioButtonListField =
  React.memo<CardOnFileRadioButtonListFieldProps>(({ cardsOnFile, tzId }) => {
    const {
      control,
      formState: { errors },
      watch,
      setValue,
    } = useFormContext<CreditCardPaymentFormData>()

    const selectedCardOnFile = watch('selectedCardOnFile')

    const cardOnFileOptions = useMemo(
      () =>
        cardsOnFile?.map(card => ({
          label: <CardOnFilePreview cardOnFile={card} tzId={tzId} />,
          value: card.paymentMethodRecordGuid,
          disabled: isCardExpired(card),
        })) ?? [],
      [cardsOnFile, tzId],
    )

    const onCardOnFileChange = useCallback(
      (id: string) => {
        const resetCardOnFile =
          id === selectedCardOnFile?.paymentMethodRecordGuid
        setValue(
          'selectedCardOnFile',
          resetCardOnFile
            ? undefined
            : cardsOnFile?.find(card => card.paymentMethodRecordGuid === id),
        )

        // If a card on file is selected, we should not save the payment method and need to reset the form state
        if (!resetCardOnFile) {
          setValue('savePaymentMethod', false)
        }
      },
      [setValue, selectedCardOnFile?.paymentMethodRecordGuid, cardsOnFile],
    )

    return (
      cardsOnFile && (
        <>
          <FormHeader>Saved Credit Cards</FormHeader>
          {cardOnFileOptions.length ? (
            <ReactHookFormItem
              noBottomMargin
              control={control}
              name="selectedCardOnFile"
              errors={errors}
              render={({ field }) => (
                <div className="pb-4 pt-3">
                  <RadioButtonListField
                    {...field}
                    options={cardOnFileOptions}
                    value={selectedCardOnFile?.paymentMethodRecordGuid}
                    labelClassName="block w-full"
                    onChange={e => {
                      onCardOnFileChange(e.target.value)
                    }}
                  />
                </div>
              )}
            />
          ) : (
            <div
              className={classNames(
                'mb-4 mt-3 rounded-lg border border-solid border-bz-gray-500 bg-bz-gray-200 p-3 text-bz-gray-900',
              )}
            >
              No cards have been saved yet.
            </div>
          )}
          <Divider className="mb-4 mt-2" />
        </>
      )
    )
  })

export default CardOnFileRadioButtonListField
