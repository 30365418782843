import { CartItem, CartItemType, Guid, R } from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import useIsMobile from '../../hooks/useIsMobile'
import { usePricebook } from '../../providers/PricebookProvider'
import {
  ItemPicker,
  ItemPickerItem,
  ItemPickerRenderCreateItemProps,
} from './ItemPicker'
import { LineItemForm } from './LineItemForm'

type PricebookItemPickerProps = {
  isSubmitting?: boolean
  onSubmit: (items: CartItem[]) => void
  onCancel: () => void
  disableManualItemCreation?: boolean
  canSelectMultiple?: boolean
}

export const PricebookItemPicker = React.memo<PricebookItemPickerProps>(
  ({
    onSubmit,
    onCancel,
    disableManualItemCreation,
    isSubmitting,
    canSelectMultiple = true,
  }) => {
    const isMobile = useIsMobile()

    const { items, rawItems, loaded } = usePricebook()

    const [selectedItemCountMap, setSelectedItemCountMap] = useState<
      Record<Guid, number>
    >({})

    const onItemSelect = useCallback((id: Guid, count: number) => {
      setSelectedItemCountMap(map => ({ ...map, [id]: count }))
    }, [])

    const [adHocItems, setAdHocItems] = useState<CartItem[]>([])

    const onSave = useCallback(() => {
      const items: CartItem[] = []

      for (const item of rawItems) {
        const count = selectedItemCountMap[item.pricebookItemGuid]
        if (count) {
          items.push({
            itemGuid: item.pricebookItemGuid,
            name: item.name,
            description: item.description,
            quantity: count,
            unitPriceUsd: item.priceUsd,
            isTaxable: item.isTaxable,
            isDiscountable: item.isDiscountable,
            photoGuid: item.photo?.photoGuid,
            photoCdnUrl: item.photo?.cdnUrl,
            // PricebookItemTypeEnum is a subset of CartItemType but because TypeScript enums suck, it doesn't know
            // that.
            itemType: item.itemType as unknown as CartItemType,
          })
        }
      }

      for (const item of adHocItems) {
        const count = selectedItemCountMap[item.itemGuid]
        if (count) {
          items.push(item)
        }
      }

      onSubmit(items)
    }, [adHocItems, onSubmit, rawItems, selectedItemCountMap])

    const [createItemIsDirty, setCreateItemIsDirty] = useState(false)

    const renderCreateItem = useCallback(
      ({ onCancel, onSave }: ItemPickerRenderCreateItemProps) => (
        <LineItemForm
          onCancel={onCancel}
          onBack={isMobile ? undefined : onCancel}
          setIsDirty={setCreateItemIsDirty}
          onSave={item => {
            if (!item.savedToPricebook) {
              setAdHocItems(items => [...items, item])
            }
            onSave(item.itemGuid, item.quantity)
          }}
        />
      ),
      [isMobile],
    )

    const adHocPickerItems = useMemo<ItemPickerItem[]>(
      () =>
        adHocItems.map(item => ({
          id: item.itemGuid,
          name: item.name,
          value: item.unitPriceUsd,
        })),
      [adHocItems],
    )

    const hasCartItems = useMemo(() => {
      for (const value of R.values(selectedItemCountMap)) {
        if (value) {
          return true
        }
      }
      return false
    }, [selectedItemCountMap])

    const [showConfirmCancel, setShowConfirmCancel] = useState(false)

    const ourOnCancel = useCallback(() => {
      if (hasCartItems || createItemIsDirty) {
        setShowConfirmCancel(true)
      } else {
        onCancel()
      }
    }, [createItemIsDirty, hasCartItems, onCancel])

    const resetShowConfirmCancel = useCallback(
      () => setShowConfirmCancel(false),
      [],
    )

    return (
      <OnsiteModal onClose={ourOnCancel} size="large">
        <ItemPicker
          items={items}
          title="Add line item"
          onCancel={ourOnCancel}
          onSave={onSave}
          selectedItemCountMap={selectedItemCountMap}
          onItemSelect={onItemSelect}
          renderCreateItem={
            disableManualItemCreation ? undefined : renderCreateItem
          }
          adHocItems={adHocPickerItems}
          isSubmitting={isSubmitting}
          isLoading={!loaded}
          canSelectMultiple={canSelectMultiple}
          enablePhotos
        />
        {showConfirmCancel && (
          <CloseConfirmModal
            onCancelCancel={resetShowConfirmCancel}
            onConfirmCancel={onCancel}
            content={
              hasCartItems ? (
                <>
                  You've selected items from the pricebook. If you exit, they
                  won't be added to the estimate.
                  <br />
                  <br />
                  Are you sure you want to exit?
                </>
              ) : undefined
            }
          />
        )}
      </OnsiteModal>
    )
  },
)
