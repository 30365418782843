import { DUMMY_GUID } from '@breezy/shared'
import { useQuery } from 'urql'
import { GET_INVOICE_IDENTIFYING_INFO_QUERY } from './InvoiceView.gql'

export const useFetchInvoiceIdentifyingInfo = (
  accountGuid: string,
  jobGuid?: string,
) => {
  const [identifyingInfoQuery] = useQuery({
    query: GET_INVOICE_IDENTIFYING_INFO_QUERY,
    variables: {
      accountGuid,
      jobGuid: jobGuid ?? DUMMY_GUID,
    },
  })

  return identifyingInfoQuery
}
