import { z } from 'zod'
import { AsyncFn, NotNullish } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { CompanyGuid, ForCompanyUser } from '../Company/Company'
import { EmailAddress, emailAddressValueSchema } from '../Email/EmailTypes'
import { NotificationPreferenceType } from '../NotificationPreferenceTypes'
import { PhoneNumber, PhoneNumberTypeSchema, castPhoneNumberType, telephoneNumberSchema } from '../PhoneNumbers/Phone'
import { Guid, bzOptional, firstNameSchema, lastNameSchema } from '../common-schemas'

export type ContactGuid = Guid

export type Contact = {
  contactGuid: ContactGuid
  companyGuid: CompanyGuid
  firstName: string
  lastName: string
  salutation?: string
  title?: string
  notificationPreferenceType: NotificationPreferenceType
  primaryEmailAddress?: EmailAddress
  additionalEmailAddress?: EmailAddress
  primaryPhoneNumber?: PhoneNumber
  additionalPhoneNumber?: PhoneNumber
}

const EmailAddressEntitySchema = z.object({
  emailAddressGuid: guidSchema,
  companyGuid: guidSchema,
  emailAddress: emailAddressValueSchema,
})

const PhoneNumberEntitySchema = z.object({
  phoneNumberGuid: guidSchema,
  companyGuid: guidSchema,
  phoneNumber: telephoneNumberSchema,
  type: PhoneNumberTypeSchema,
})

export const ContactUpserterDTOSchema = z.object({
  contactGuid: guidSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  salutation: bzOptional(z.string()),
  title: bzOptional(z.string()),
  notificationPreferenceType: z.nativeEnum(NotificationPreferenceType),
  primaryEmailAddress: bzOptional(EmailAddressEntitySchema),
  additionalEmailAddress: bzOptional(EmailAddressEntitySchema),
  primaryPhoneNumber: bzOptional(PhoneNumberEntitySchema),
  additionalPhoneNumber: bzOptional(PhoneNumberEntitySchema),
})

export type ContactUpserterDTO = z.infer<typeof ContactUpserterDTOSchema>

export type ContactUpserter = AsyncFn<ForCompanyUser<ContactUpserterDTO>>

const FindByContactGuidQuerySchema = z.object({
  type: z.literal('by-contact-guid'),
  contactGuid: guidSchema,
  companyGuid: bzOptional(guidSchema),
})
export type FindByContactGuidQuery = z.infer<typeof FindByContactGuidQuerySchema>

const FindByContactEmailAddressQuerySchema = z.object({
  type: z.literal('by-email-address'),
  emailAddress: emailAddressValueSchema,
  companyGuid: guidSchema,
})
export type FindByContactEmailAddressQuery = z.infer<typeof FindByContactEmailAddressQuerySchema>

const FindByContactPhoneNumberQuerySchema = z.object({
  type: z.literal('by-phone-number'),
  phoneNumber: telephoneNumberSchema,
  companyGuid: guidSchema,
})
export type FindByContactPhoneNumberQuery = z.infer<typeof FindByContactPhoneNumberQuerySchema>

const FindByNameQuerySchema = z.object({ type: z.literal('by-name'), name: z.string().min(1), companyGuid: guidSchema })
export type FindByNameQuery = z.infer<typeof FindByNameQuerySchema>

const SearchContactsQuerySchema = z.object({
  type: z.literal('search'),
  companyGuid: guidSchema,
  searchValue: z.string(),
})
export type SearchContactsQuery = z.infer<typeof SearchContactsQuerySchema>

const FindAllContactsQuerySchema = z.object({
  companyGuid: guidSchema,
  type: z.literal('all'),
})
export type FindAllContactsQuery = z.infer<typeof FindAllContactsQuerySchema>
export const ContactQuerySchema = z.discriminatedUnion('type', [
  FindByContactGuidQuerySchema,
  FindByContactEmailAddressQuerySchema,
  FindByContactPhoneNumberQuerySchema,
  FindByNameQuerySchema,
  FindAllContactsQuerySchema,
  SearchContactsQuerySchema,
])

export type ContactQuery = z.infer<typeof ContactQuerySchema>

export type ContactQuerier = AsyncFn<ContactQuery, Contact[]>
export type FindByContactGuidQuerier = AsyncFn<Omit<FindByContactGuidQuery, 'type'>, Contact[]>
export type FindByContactEmailAddressQuerier = AsyncFn<Omit<FindByContactEmailAddressQuery, 'type'>, Contact[]>
export type FindByContactPhoneNumberQuerier = AsyncFn<Omit<FindByContactPhoneNumberQuery, 'type'>, Contact[]>
export type FindByNameQuerier = AsyncFn<Omit<FindByNameQuery, 'type'>, Contact[]>
export type FindAllContactsForACompanyQuerier = AsyncFn<Omit<FindAllContactsQuery, 'type'>, Contact[]>
export type ContactSearchQuerier = AsyncFn<Omit<SearchContactsQuery, 'type'>, Contact[]>
export type PhoneNumberUnsubscriber = AsyncFn<{ phoneNumber: string }>

export class BzContact implements Contact {
  static create(contact: Contact): BzContact {
    return new BzContact(contact)
  }

  private constructor(private readonly contact: Contact) {}
  get contactGuid(): ContactGuid {
    return this.contact.contactGuid
  }

  get companyGuid(): CompanyGuid {
    return this.contact.companyGuid
  }
  get firstName(): string {
    return this.contact.firstName
  }
  get lastName(): string {
    return this.contact.lastName
  }
  get salutation(): string | undefined {
    return this.contact.salutation
  }
  get title(): string | undefined {
    return this.contact.title
  }
  get notificationPreferenceType(): NotificationPreferenceType {
    return this.contact.notificationPreferenceType
  }
  get primaryEmailAddress(): EmailAddress | undefined {
    return this.contact.primaryEmailAddress
  }
  get additionalEmailAddress(): EmailAddress | undefined {
    return this.contact.additionalEmailAddress
  }
  get primaryPhoneNumber(): PhoneNumber | undefined {
    return this.contact.primaryPhoneNumber
  }
  get additionalPhoneNumber(): PhoneNumber | undefined {
    return this.contact.additionalPhoneNumber
  }

  get emailAddresses(): EmailAddress[] {
    return [this.contact.primaryEmailAddress, this.contact.additionalEmailAddress].filter(Boolean) as EmailAddress[]
  }

  get phoneNumbers(): PhoneNumber[] {
    return [this.contact.primaryPhoneNumber, this.contact.additionalPhoneNumber].filter(Boolean) as PhoneNumber[]
  }
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}

// Given a Contact where the phone number types and notificationPreferenceType are string(like they might come from a
// DB), cast them to the proper TypeScript type.
export const castContactEnumTypes = (
  contact: Omit<Contact, 'primaryPhoneNumber' | 'additionalPhoneNumber' | 'notificationPreferenceType'> & {
    notificationPreferenceType: string
    primaryPhoneNumber?: Omit<NotNullish<Contact['primaryPhoneNumber']>, 'type'> & { type: string }
    additionalPhoneNumber?: Omit<NotNullish<Contact['additionalPhoneNumber']>, 'type'> & { type: string }
  },
): Contact => ({
  ...contact,
  notificationPreferenceType: contact.notificationPreferenceType as NotificationPreferenceType,
  primaryPhoneNumber: contact.primaryPhoneNumber ? castPhoneNumberType(contact.primaryPhoneNumber) : undefined,
  additionalPhoneNumber: contact.additionalPhoneNumber ? castPhoneNumberType(contact.additionalPhoneNumber) : undefined,
})
