import { bzExpect } from '@breezy/shared'
import { useQuery } from 'urql'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { JOB_CREATED_BY_USER_QUERY } from './TechnicianHooks.gql'

export const useDidTechCreateJob = (jobGuid: string) => {
  const principal = useExpectedPrincipal()
  const companyGuid = bzExpect(
    principal.company?.companyGuid,
    'companyGuid',
    'User Has No Company',
  )

  const [techCreatedJobQuery] = useQuery({
    query: JOB_CREATED_BY_USER_QUERY,
    variables: { jobGuid, companyGuid },
  })

  return {
    isLoading: techCreatedJobQuery.fetching,
    techCreatedJob: Boolean(
      techCreatedJobQuery.data?.jobs.length &&
        techCreatedJobQuery.data?.jobs[0].createdByUserGuid ===
          principal.userGuid,
    ),
  }
}
