import { useAuth0 } from '@auth0/auth0-react'
import {
  IMPERSONATION_HEADER_KEY,
  UserImpersonationSearchDTOItem,
  UserImpersonationSearchItemDTOSchema,
  isNullish,
} from '@breezy/shared'
import { useCookie } from 'react-use'
import { IMPERSONATE_USER_KEY } from '../components/Admin/AdminImpersonateUser/AdminImpersonateUser'
export const CYPRESS_AUTH_COOKIE = 'cy-auth0'
export const CYPRESS_IMPERSONATE_USER_COOKIE = 'cy-impersonate-user'
interface User {
  email?: string
  emailVerified?: boolean
  userId?: string
  avatar?: string
}

interface AuthHook {
  user?: User
  loading: boolean
  isAuthenticated: boolean
  getToken(): Promise<string | undefined>
  loginWithRedirect(): Promise<void>
  logout(): void
  getHeaders(): Promise<{ [x: string]: string | undefined }>
}

type CypressAuth0Cookie = {
  user: User | undefined
  access_token: string | undefined
}
const useCypressAuth = (
  cyAuth0Cookie: string | null,
  cyImpersonateUserCookie: string | null,
): AuthHook => {
  let cypressUser: CypressAuth0Cookie['user'] | undefined,
    cypressToken: CypressAuth0Cookie['access_token'] | undefined
  if (cyAuth0Cookie) {
    const cyAuth0: CypressAuth0Cookie = JSON.parse(cyAuth0Cookie)
    cypressUser = cyAuth0.user
    cypressToken = cyAuth0.access_token as string
  }

  return {
    user: cypressUser,
    loading: false,
    isAuthenticated: !!cypressUser,
    getToken: async () => cypressToken,
    loginWithRedirect: async () => {},
    logout: () => {},
    getHeaders: async () => {
      const impersonatedUserString =
        cyImpersonateUserCookie ?? sessionStorage.getItem(IMPERSONATE_USER_KEY)
      const parsed = UserImpersonationSearchItemDTOSchema.safeParse(
        JSON.parse(impersonatedUserString || '{}'),
      )

      if (parsed.success) {
        const impersonatedUser = parsed.data
        const impersonatedUserGuid = impersonatedUser.userGuid
        if (impersonatedUserGuid) {
          return {
            authorization: `Bearer ${cypressToken}`,
            [IMPERSONATION_HEADER_KEY]: impersonatedUserGuid,
          }
        }
      }

      return {
        authorization: `Bearer ${cypressToken}`,
      }
    },
  }
}

export const useAuth = (): AuthHook => {
  const res = useAuth0()
  const [cyAuth0Cookie] = useCookie(CYPRESS_AUTH_COOKIE)
  const [cyImpersonateUserCookie] = useCookie(CYPRESS_IMPERSONATE_USER_COOKIE)
  const cypressAuth = useCypressAuth(cyAuth0Cookie, cyImpersonateUserCookie)

  const isCypress = !isNullish(window) && window.Cypress

  if (isCypress && cyAuth0Cookie) {
    return cypressAuth
  }

  return {
    user: res.user && {
      email: res.user.email,
      emailVerified: res.user.email_verified,
      avatar: res.user.picture,
    },
    loading: res.isLoading,
    isAuthenticated: res.isAuthenticated,
    getToken: async () => {
      return await res.getAccessTokenSilently()
    },
    loginWithRedirect: res.loginWithRedirect,
    logout: () => {
      localStorage.clear()
      sessionStorage.clear()
      res.logout({ returnTo: window.location.origin })
    },
    getHeaders: async () => {
      const token = await res.getAccessTokenSilently()

      const impersonatedUser = sessionStorage.getItem(IMPERSONATE_USER_KEY)

      const impersonatedUserGuid = !isNullish(impersonatedUser)
        ? (JSON.parse(impersonatedUser) as UserImpersonationSearchDTOItem)
            .userGuid
        : undefined

      if (impersonatedUserGuid) {
        return {
          authorization: `Bearer ${token}`,
          [IMPERSONATION_HEADER_KEY]: impersonatedUserGuid,
        }
      }

      return {
        authorization: `Bearer ${token}`,
      }
    },
  }
}
