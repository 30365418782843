import { AvatarData, Guid } from '@breezy/shared'
import { m } from '../../utils/react-utils'
import PersonAvatar from '../PersonAvatar/PersonAvatar'

export interface PersonResourceViewProps {
  userGuid: Guid
  displayName: string
  displayCategory: string
  avatarData: AvatarData
}

export const PersonResourceView = m<PersonResourceViewProps>(
  ({ displayName, displayCategory, avatarData }) => (
    <div
      style={{ display: 'flex', alignItems: 'center' }}
      className="technician-resource-item"
    >
      <PersonAvatar {...avatarData} />
      <div style={{ paddingLeft: 8 }}>
        <div className="regular_14_22 gray9">{displayName}</div>
        <div
          className="regular_12_20 gray7"
          style={{
            marginTop: -3,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {displayCategory}
        </div>
      </div>
    </div>
  ),
)

export default PersonResourceView
