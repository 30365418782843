import { USStates } from '@breezy/shared'
import { AutoComplete, Form, Input } from 'antd'
import { ComponentProps, useState } from 'react'

export type USStateFormItemProps = {
  required: boolean
  shouldSetNameProp?: boolean
  labelClassName?: string
  initialStateAbbreviation?: string
  showAbbreviation?: boolean
  showLabel?: boolean
  autocompleteProps?: ComponentProps<typeof AutoComplete>
}

const USStateFormItem = ({
  required,
  shouldSetNameProp = true,
  labelClassName = '',
  initialStateAbbreviation,
  showAbbreviation = false,
  showLabel = true,
  autocompleteProps,
}: USStateFormItemProps) => {
  const [options, setOptions] = useState(
    USStates.map(state => ({
      key: state.abbreviation,
      value: state.abbreviation,
    })),
  )

  return (
    <Form.Item
      name={shouldSetNameProp ? 'addressState' : undefined}
      label={showLabel ? 'State' : null}
      className={labelClassName}
      required={required} // Todo: The second part of this ternary looks wrong and confusing
      initialValue={
        initialStateAbbreviation && showAbbreviation
          ? initialStateAbbreviation
          : USStates.find(
              state => state.abbreviation === initialStateAbbreviation,
            )?.name
      }
      rules={[
        {
          validator: async (_, value) => {
            if (!value) {
              return Promise.reject(new Error('State is required'))
            }
            if (!USStates.find(state => state.abbreviation === value)) {
              return Promise.reject(new Error('State is invalid'))
            }
          },
        },
      ]}
    >
      <AutoComplete
        options={options}
        onSearch={text =>
          setOptions(
            USStates.filter(
              s =>
                s.abbreviation.toLowerCase().includes(text.toLowerCase()) ||
                s.name.toLowerCase().includes(text.toLowerCase()),
            ).map(state => ({
              key: state.abbreviation,
              value: state.abbreviation,
            })),
          )
        }
        placeholder="Select a state"
        {...autocompleteProps}
      >
        <Input autoComplete="off" />
      </AutoComplete>
    </Form.Item>
  )
}

export default USStateFormItem
