import { R } from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../../../hooks/useIsMobile'

type SteppersProps = {
  currentIndex: number
  totalSteps: number
  scrollToIndex: (index: number) => void
}

export const Steppers = React.memo<SteppersProps>(
  ({ currentIndex, totalSteps, scrollToIndex }) => {
    const isMobile = useIsMobile()
    return (
      <div
        className={classNames(
          'z-20 flex flex-row items-center border-0 border-t-4 border-solid border-bz-border-secondary bg-white py-4',
          isMobile ? 'px-4' : 'px-6',
        )}
      >
        <Button
          data-testid="previous"
          size="large"
          className={classNames(isMobile ? 'min-w-[96px]' : 'min-w-[200px]')}
          disabled={currentIndex === 0}
          onClick={() => scrollToIndex(currentIndex - 1)}
        >
          Previous
        </Button>
        <div className="flex flex-1 flex-row items-center justify-center gap-x-1">
          {R.range(0, totalSteps).map(index => (
            <div
              key={index}
              onClick={() => scrollToIndex(index)}
              className={classNames(
                'h-[10px] w-[10px] cursor-pointer rounded-full transition-all ease-in-out',
                index === currentIndex ? 'bg-bz-primary' : 'bg-bz-gray-600',
              )}
            />
          ))}
        </div>
        <Button
          data-testid="next"
          size="large"
          className={classNames(isMobile ? 'min-w-[96px]' : 'min-w-[200px]')}
          disabled={currentIndex === totalSteps - 1}
          onClick={() => scrollToIndex(currentIndex + 1)}
        >
          Next
        </Button>
      </div>
    )
  },
)
