import {
  AccountGuid,
  BzDateFns,
  JobAppointmentGuid,
  JobGuid,
  LocationGuid,
  R,
  formatUsc,
  isNullish,
} from '@breezy/shared'
import { faReceipt } from '@fortawesome/pro-regular-svg-icons'
import React, { useCallback, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSubscription } from 'urql'
import StatusTag from '../../../elements/StatusTag/StatusTag'
import { EstimatesBoolExp } from '../../../generated/user/graphql'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import {
  JOB_APPOINTMENT_GUID_QUERY_PARAM,
  getEstimateV2StatusDisplayInfo,
} from '../../../pages/EstimatesFlow/estimatesFlowUtils'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { BehindFeatureFlag } from '../../BehindFeatureFlag'
import { LoadingSpinner } from '../../LoadingSpinner'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import {
  ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
  EstimateCollapsibleEstimate,
  LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
} from './EstimateCollapsible.gql'

export type EstimatesCollapsibleGuids = {
  accountGuid: AccountGuid
  jobGuid?: JobGuid
  jobAppointmentGuid?: JobAppointmentGuid
  locationGuid?: LocationGuid
}

export type EstimatesCollapsibleProps = {
  allowCreate?: boolean
  guids: EstimatesCollapsibleGuids
  technicianAppStyle?: boolean
}

type CollapsibleItemRowProps = React.PropsWithChildren<{
  label: React.ReactNode
}>

const CollapsibleItemRow = React.memo<CollapsibleItemRowProps>(
  ({ label, children }) => {
    return (
      <div className="flex max-w-full text-bz-gray-900">
        <div className="mr-2 truncate font-semibold">{label}</div>
        {children}
      </div>
    )
  },
)

export const EstimatesCollapsible = React.memo<EstimatesCollapsibleProps>(
  ({ allowCreate: externalAllowCreate = true, technicianAppStyle, guids }) => {
    const linkedJobsEnabled = useFeatureFlag('linkedJobs')
    const navigate = useNavigate()

    const tzId = useExpectedCompanyTimeZoneId()

    // In V2 we require a job guid so we can't have a + unless we have the job guid.
    const allowCreate = guids.jobGuid && externalAllowCreate

    const onPlus = useCallback(() => {
      guids.jobGuid &&
        navigate({
          pathname: `/jobs/${guids.jobGuid}/new-estimate`,
          search: guids.jobAppointmentGuid
            ? `${JOB_APPOINTMENT_GUID_QUERY_PARAM}=${guids.jobAppointmentGuid}`
            : '',
        })
    }, [guids.jobAppointmentGuid, guids.jobGuid, navigate])

    const whereExpression = useMemo<EstimatesBoolExp>(() => {
      let where: EstimatesBoolExp = {
        job: {
          accountGuid: {
            _eq: guids.accountGuid,
          },
        },
      }

      if (guids.jobGuid) {
        where = R.mergeDeepRight(where, {
          jobGuid: {
            _eq: guids.jobGuid,
          },
        })
      }
      if (guids.locationGuid) {
        where = R.mergeDeepRight(where, {
          job: {
            locationGuid: {
              _eq: guids.locationGuid,
            },
          },
        })
      }

      return where
    }, [guids.accountGuid, guids.jobGuid, guids.locationGuid])

    const [{ data: estimatesData, fetching: estimatesFetching }] =
      useSubscription({
        query: ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
        variables: {
          where: whereExpression,
        },
      })

    const [
      { data: linkedEstimatesData, fetching: linkedEstimatesDataFetching },
    ] = useSubscription({
      query: LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
      variables: { jobGuid: guids.jobGuid ?? '' },
      pause: isNullish(guids.jobGuid) || !linkedJobsEnabled,
    })

    const estimates = useMemo<EstimateCollapsibleEstimate[]>(
      () => [
        ...((estimatesData?.estimates ?? []) as EstimateCollapsibleEstimate[]),
        ...((linkedEstimatesData?.jobsByPk?.linkedJobEstimates.map(
          linkedJobEstimate => ({
            ...linkedJobEstimate.estimate,
            isLinkedEstimate: true,
          }),
        ) ?? []) as EstimateCollapsibleEstimate[]),
      ],
      [
        estimatesData?.estimates,
        linkedEstimatesData?.jobsByPk?.linkedJobEstimates,
      ],
    )

    return (
      <BzCollapsible
        title="Estimates"
        titleIcon={faReceipt}
        technicianAppStyle={technicianAppStyle}
        onPlus={allowCreate ? onPlus : undefined}
      >
        {estimatesFetching || linkedEstimatesDataFetching ? (
          <LoadingSpinner noMinHeight />
        ) : (
          estimates.map(
            ({
              estimateGuid,
              displayId,
              status,
              createdByUser,
              createdAt,
              estimateOptions,
              isLinkedEstimate,
              job,
            }) => {
              const statusInfo = getEstimateV2StatusDisplayInfo(status)

              // We have a different appearance if there's only one option. If the estimate is accepted, that's
              // basically the same as there being only one option.
              const singleOption =
                estimateOptions.length === 1
                  ? estimateOptions[0]
                  : estimateOptions.find(option => option.isSelected)

              return (
                <div
                  key={estimateGuid}
                  className="w-full rounded-lg bg-bz-gray-200 p-3"
                >
                  <div className="flex flex-row items-center justify-between">
                    <Link
                      className="font-semibold"
                      to={`/estimates/${estimateGuid}`}
                    >
                      #{displayId}
                    </Link>
                    <StatusTag
                      color={statusInfo.statusTagColor}
                      text={statusInfo.label}
                    />
                  </div>
                  {singleOption ? (
                    <CollapsibleItemRow label="Total Price">
                      {formatUsc(singleOption.totalUsc)}
                    </CollapsibleItemRow>
                  ) : (
                    estimateOptions.map(
                      ({ estimateOptionGuid, displayName, seq, totalUsc }) => (
                        <CollapsibleItemRow
                          key={estimateOptionGuid}
                          label={`#${seq + 1}: ${displayName || 'Option'}`}
                        >
                          {formatUsc(totalUsc)}
                        </CollapsibleItemRow>
                      ),
                    )
                  )}
                  <CollapsibleItemRow label="Created Date">
                    {BzDateFns.formatFromISO(createdAt, 'MMM d, yyyy', tzId)}
                  </CollapsibleItemRow>

                  <CollapsibleItemRow label="Prepared by">
                    {createdByUser.fullName ?? 'Technician'}
                  </CollapsibleItemRow>

                  <BehindFeatureFlag
                    enabledFeatureFlag="linkedJobs"
                    render={
                      isLinkedEstimate && (
                        <CollapsibleItemRow label="Linked Jobs">
                          <Link to={`/jobs/${job.jobGuid}`}>
                            <span className="font-semibold">
                              #{job.displayId} {job.jobType.name}
                            </span>
                          </Link>
                        </CollapsibleItemRow>
                      )
                    }
                  />
                </div>
              )
            },
          )
        )}
      </BzCollapsible>
    )
  },
)

export default EstimatesCollapsible
