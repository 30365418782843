import { gql } from '../../generated'

export const FILE_UPDATE_NAME_MUTATION = gql(/* GraphQL */ `
  mutation FileUpdateName($fileGuid: uuid!, $fileName: String!) {
    updateFilesByPk(
      pkColumns: { fileGuid: $fileGuid }
      _set: { fileName: $fileName }
    ) {
      fileName
    }
  }
`)
