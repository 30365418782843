import { memo, useCallback } from 'react'
import { useFetchContactLiteByPhoneNumber } from '../../components/PhoneIntegrated/useFetchContactLiteByPhoneNumber'
import { useTwilioPhone } from '../../components/PhoneIntegrated/useTwilioPhone'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'

type CallablePhoneLinkProps = {
  phoneNumber: string
  children: React.ReactNode
}

export const CallablePhoneLink = memo<CallablePhoneLinkProps>(
  ({ phoneNumber, children }) => {
    const { beginVoiceCallTo } = useTwilioPhone()
    const canUseIntegratedPhone = useCanUseIntegratedPhone()
    const contact = useFetchContactLiteByPhoneNumber(phoneNumber)

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        beginVoiceCallTo({ phoneNumber, contact })
      },
      [beginVoiceCallTo, contact, phoneNumber],
    )

    const href = `tel:${phoneNumber}`

    return canUseIntegratedPhone ? (
      <a
        href={href}
        onClick={handleClick}
        className="phone__number__link cursor-pointer"
      >
        {children}
      </a>
    ) : (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="phone__number__link cursor-pointer"
      >
        {children}
      </a>
    )
  },
)
