import { Frequency, RRule, Weekday } from 'rrule'
import { BzDateFns, IsoDateString, TimeZoneId } from './BzDateFns'
import { Dfns } from './dfns'
import { R } from './ramda'

/**
 * NOTE: there is a library that does all this, but it's broken and isn't being maintained. It breaks source maps. Very
 * annoying.
 *
 * UPDATE: The library seems to be working now. https://github.com/jkbrzt/rrule
 */

export const WEEKDAY_BUTTON_OPTIONS = [
  { label: 'Sun', value: 'SU', fullName: 'Sunday' },
  { label: 'Mon', value: 'MO', fullName: 'Monday' },
  { label: 'Tue', value: 'TU', fullName: 'Tuesday' },
  { label: 'Wed', value: 'WE', fullName: 'Wednesday' },
  { label: 'Thu', value: 'TH', fullName: 'Thursday' },
  { label: 'Fri', value: 'FR', fullName: 'Friday' },
  { label: 'Sat', value: 'SA', fullName: 'Saturday' },
] as const

export const FREQUENCY_OPTIONS = [
  { value: Frequency.DAILY, label: 'day' },
  { value: Frequency.WEEKLY, label: 'week' },
  { value: Frequency.MONTHLY, label: 'month' },
  { value: Frequency.YEARLY, label: 'year' },
] as const

/** @deprecated use the rrule npm package */
export type LegacyWeekday = (typeof WEEKDAY_BUTTON_OPTIONS)[number]['value']

/** @deprecated use the rrule npm package */
export const LEGACY_FREQUENCY_OPTIONS = [
  {
    value: 'DAILY',
    label: 'day',
  },
  { value: 'WEEKLY', label: 'week' },
  { value: 'MONTHLY', label: 'month' },
  { value: 'YEARLY', label: 'year' },
] as const
/** @deprecated use the rrule npm package */
export type LegacyFrequency = (typeof LEGACY_FREQUENCY_OPTIONS)[number]['value']

const BY_MONTH_OPTIONS = ['BY_DAY', 'BY_WEEKDAY', 'BY_LAST_WEEKDAY'] as const
/** @deprecated use the rrule npm package */
export type ByMonthOption = (typeof BY_MONTH_OPTIONS)[number]

/** @deprecated use the rrule npm package */
export type ParsedRRule = {
  freq: LegacyFrequency
  interval: number
  weekdayMap: Record<LegacyWeekday, boolean>
  byMonthOption: ByMonthOption
  until?: string
}

/** @deprecated use the rrule npm package */
export const DEFAULT_DOW_MAP = {
  SU: false,
  MO: false,
  TU: false,
  WE: false,
  TH: false,
  FR: false,
  SA: false,
}

// Returns the nth "day of week" of the month. For example, if the date is the third
// monday of the month, it will return 3.
export const getNthDOWOfMonth = (date: Date) => {
  const dayOfMonth = BzDateFns.getDate(date)
  const nthDayOfMonth = Math.floor((dayOfMonth - 1) / 7) + 1
  return nthDayOfMonth
}

// Returns true if the given date is the last of its "day of week" of the month (aka
// "the last monday of the month").
export const isLastDOWOfMonth = (date: Date) => {
  const nextWeek = Dfns.addWeeks(1, date)
  return Dfns.format('M', date) !== Dfns.format('M', nextWeek)
}

/** @deprecated use the rrule npm package */
export const DEFAULT_PARSED_RRULE = {
  freq: 'DAILY',
  interval: 1,
  weekdayMap: DEFAULT_DOW_MAP,
  byMonthOption: 'BY_DAY',
} satisfies ParsedRRule

/** @deprecated use the rrule npm package */
export const parseRRule = (rrule: string, date?: Date): ParsedRRule => {
  const parsedRRule: ParsedRRule = R.clone(DEFAULT_PARSED_RRULE)

  if (date) {
    const preSelectedDOW = Dfns.format('iiiiii', date).toUpperCase() as LegacyWeekday
    parsedRRule.weekdayMap[preSelectedDOW] = true
  }
  const parts = rrule.split(';')
  for (const part of parts) {
    const [key, value] = part.split('=')
    if (key === 'FREQ') {
      parsedRRule.freq = value as LegacyFrequency
    } else if (key === 'INTERVAL') {
      parsedRRule.interval = parseInt(value)
    } else if (key === 'BYDAY') {
      const weekdays = value.split(',')
      parsedRRule.weekdayMap = R.clone(DEFAULT_PARSED_RRULE.weekdayMap)
      for (const day of weekdays) {
        parsedRRule.weekdayMap[day as LegacyWeekday] = true
      }
    } else if (key === 'BYSETPOS') {
      // If they chose either "the nth Monday of the month" or "the last Monday of the month" then `BYSETPOS` will be
      // one value that starts with either a -1 or another number 1-5.
      if (value.startsWith('-')) {
        parsedRRule.byMonthOption = 'BY_LAST_WEEKDAY'
      } else {
        parsedRRule.byMonthOption = 'BY_WEEKDAY'
      }
    } else if (key === 'BYMONTHDAY') {
      // TODO: there are weird implications if a calendar appointment moves to a new date. The
      // way we parse the rule we're assuming if it's monthly "by day" then that means it's
      // the "26th of March" if the selected date is the 26th of March. But the rule isn't
      // encoded that way, so if the calendar appointment changes, the rule needs to be
      // updated.
      parsedRRule.byMonthOption = 'BY_DAY'
    } else if (key === 'UNTIL') {
      parsedRRule.until = value
    }
  }

  return parsedRRule
}

/** @deprecated use the rrule npm package */
export const makeRRule = ({ freq, interval, weekdayMap, byMonthOption, until }: ParsedRRule, startingDate: Date) => {
  const parts = [`FREQ=${freq}`, `INTERVAL=${interval}`]
  if (until) {
    parts.push(`UNTIL=${until}`)
  }
  const dow = Dfns.format('iiiiii', startingDate).toUpperCase()
  if (freq === 'MONTHLY') {
    switch (byMonthOption) {
      case 'BY_DAY':
        parts.push(`BYMONTHDAY=${startingDate.getDate()}`)
        break
      case 'BY_WEEKDAY':
        parts.push(`BYSETPOS=${getNthDOWOfMonth(startingDate)}`)
        parts.push(`BYDAY=${dow}`)
        break
      case 'BY_LAST_WEEKDAY':
        parts.push('BYSETPOS=-1')
        parts.push(`BYDAY=${dow}`)
        break
    }
  }
  if (freq === 'WEEKLY') {
    const weekdays = R.keys(weekdayMap).filter(weekday => weekdayMap[weekday as LegacyWeekday])
    parts.push(`BYDAY=${weekdays.join(',')}`)
  }
  return parts.join(';')
}

/** @deprecated use the rrule npm package */
export const legacyChangeRRuleUntil = (rrule: string, until: string) => {
  // The "until" rule is inclusive, but we want it to exclude the selected date. So we
  // subtract a day.
  const updatedUntil = R.pipe(Dfns.parseISO, Dfns.subDays(1), Dfns.formatISO)(until)

  const parts = rrule.split(';')
  for (const part of parts) {
    if (part.toLowerCase().startsWith('until')) {
      return rrule.replace(part, `UNTIL=${updatedUntil}`)
    }
  }

  return `${rrule};UNTIL=${updatedUntil}`
}

export const changeRRuleUntil = (rrule: string, until: IsoDateString, tzId: TimeZoneId) => {
  // The "until" rule is inclusive, but we want it to exclude the selected date. So we subtract a day.
  const updatedUntil = BzDateFns.withTimeZone(until, tzId, date => BzDateFns.subDays(date, 1))

  const parts = rrule.split(';')
  for (const part of parts) {
    if (part.toLowerCase().startsWith('until')) {
      return rrule.replace(part, `UNTIL=${updatedUntil}`)
    }
  }

  return `${rrule};UNTIL=${updatedUntil}`
}

const getNthDOWOfMonthForDate = (date: Date, dow: number, n: number) => {
  const startOfMonth = Dfns.startOfMonth(date)

  const startOfMonthDOW = Dfns.getDay(startOfMonth)
  const daysBetween = (7 + dow - startOfMonthDOW) % 7

  let currentDate = Dfns.addDays(daysBetween, startOfMonth)

  // -1 because if it's the 1st monday, then we want this to be the date and we
  // don't want to loop
  for (let i = 0; i < n - 1; ++i) {
    currentDate = Dfns.addWeeks(1, currentDate)
  }
  return currentDate
}

const getLastDOWOfMonthForDate = (date: Date, dow: number) => {
  const startOfMonth = Dfns.startOfMonth(date)

  const startOfMonthDOW = Dfns.getDay(startOfMonth)
  const daysBetween = (7 + dow - startOfMonthDOW) % 7

  let currentDate = Dfns.addDays(daysBetween, startOfMonth)

  while (!isLastDOWOfMonth(currentDate)) {
    currentDate = Dfns.addWeeks(1, currentDate)
  }
  return currentDate
}

/** @deprecated use the rrule npm package */
export const getDatesInRangeForRRule = ({
  rrule,
  originalDate,
  start,
  end,
  exceptions,
  allowBeforeOriginal,
}: {
  rrule: ParsedRRule
  originalDate: Date
  start: Date
  end: Date
  exceptions?: Date[]
  allowBeforeOriginal?: boolean
}): Date[] => {
  // The original start date of the rrule
  const fixedOriginal = Dfns.startOfDay(originalDate)

  // The start date for the range of dates we want to generate
  const fixedStart = Dfns.startOfDay(start)
  // The end date for the range of dates we want to generate
  const fixedEnd = Dfns.endOfDay(end)

  // If we allow before original, we just get the matching dates in the specified date range
  let currentDate: Date = originalDate
  while (Dfns.isBefore(fixedStart, currentDate)) {
    if (rrule.freq === 'WEEKLY') {
      currentDate = Dfns.addWeeks(rrule.interval || 1, currentDate)
    } else {
      currentDate = fixedStart
    }
  }

  // On a normal calendar, you create an appt and say "repeat this every week". If you go to the previous week on this
  // calendar you wouldn't see an appointment because it starts with the original appt. Unless we're overriding this
  // behavior with allowBeforeOriginal, we skip any dates in the provided date range that happen before the original
  // date.
  if (!allowBeforeOriginal && Dfns.isBefore(fixedOriginal, fixedStart)) {
    currentDate = fixedOriginal
  }
  const dates: Date[] = []

  const exceptionMap: Record<string, true> = {}
  for (const exception of exceptions || []) {
    exceptionMap[Dfns.format('yyyy-MM-dd', exception)] = true
  }

  // Until is inclusive. We need to make sure we are doing > end of the date
  const until = rrule.until ? Dfns.endOfDay(Dfns.parseISO(rrule.until)) : undefined

  const originalDOW = Dfns.getDay(originalDate)
  const originalNthDOWOfMonth = getNthDOWOfMonth(originalDate)

  // If we're yearly, we have to start on the same day as the original date and
  // increment by a year until we get past the end of the range. I take the original
  // date but set the year to the year of the start date. If my range is 6/1/23 ->
  // 6/30/23, and my original date is 6/15/23, then I'm right there. If my original date
  // was 6/15/2022, then I get 6/15/2023, which is what I want too. If my original date
  // is 5/15/2023, then that's before my start date and I should skip it. If my original
  // date was 7/15/2023, then that's after my end date and the while loop won't enter.
  if (rrule.freq === 'YEARLY') {
    currentDate = Dfns.setYear(Dfns.getYear(fixedStart), fixedOriginal)
  }
  if (rrule.freq === 'MONTHLY') {
    if (rrule.byMonthOption === 'BY_DAY') {
      // Very similar logic to YEARLY
      currentDate = Dfns.setMonth(Dfns.getMonth(fixedStart), fixedOriginal)
    } else if (rrule.byMonthOption === 'BY_WEEKDAY') {
      currentDate = getNthDOWOfMonthForDate(fixedStart, originalDOW, originalNthDOWOfMonth)
    } else if (rrule.byMonthOption === 'BY_LAST_WEEKDAY') {
      currentDate = getLastDOWOfMonthForDate(fixedStart, originalDOW)
    } else {
      throw new Error(`Invalid RRule byMonthOption: ${rrule.byMonthOption}`)
    }
  }

  while (currentDate <= fixedEnd) {
    if (until && currentDate > until) {
      break
    }

    let datesToCheck = [currentDate]
    if (rrule.freq === 'WEEKLY') {
      datesToCheck = []
      for (let i = 0; i < WEEKDAY_BUTTON_OPTIONS.length; ++i) {
        const day = WEEKDAY_BUTTON_OPTIONS[i].value
        if (rrule.weekdayMap[day]) {
          datesToCheck.push(Dfns.setDay(i, currentDate))
        }
      }
    }
    for (const date of datesToCheck) {
      // Don't count it if it's before our range. There are edge cases where this can happen
      const isAfterStart = date >= fixedStart

      const isNotException = !exceptionMap[Dfns.format('yyyy-MM-dd', date)]
      if (isAfterStart && isNotException) {
        dates.push(date)
      }
    }

    if (rrule.freq === 'DAILY') {
      currentDate = Dfns.addDays(rrule.interval, currentDate)
    } else if (rrule.freq === 'WEEKLY') {
      currentDate = Dfns.addWeeks(rrule.interval, currentDate)
    } else if (rrule.freq === 'MONTHLY') {
      if (rrule.byMonthOption === 'BY_DAY') {
        currentDate = Dfns.addMonths(rrule.interval, currentDate)
      } else if (rrule.byMonthOption === 'BY_WEEKDAY') {
        currentDate = getNthDOWOfMonthForDate(
          Dfns.addMonths(rrule.interval, currentDate),
          originalDOW,
          originalNthDOWOfMonth,
        )
      } else if (rrule.byMonthOption === 'BY_LAST_WEEKDAY') {
        currentDate = getLastDOWOfMonthForDate(Dfns.addMonths(rrule.interval, currentDate), originalDOW)
      } else {
        throw new Error(`Invalid RRule byMonthOption: ${rrule.byMonthOption}`)
      }
    } else if (rrule.freq === 'YEARLY') {
      currentDate = Dfns.addYears(rrule.interval, currentDate)
    } else {
      throw new Error(`Invalid RRule frequency: ${rrule.freq}`)
    }
  }

  return dates
}

export const splitOutUntil = (rrule: string): [newRRule: string, originalUntil: string] => {
  const ruleParts = rrule.split(';')
  let originalUntil = ''
  const newRRule = ruleParts
    .filter(part => {
      if (part.toLowerCase().startsWith('until=')) {
        originalUntil = part
        return false
      }
      return true
    })
    .join(';')

  return [newRRule, originalUntil]
}

export const getPrettyRRuleDescription = (rrule: string) => {
  const [sanitizedRRule, until] = splitOutUntil(rrule)

  let rule: RRule

  // The RRule library can't handle normally-formatted ISO date strings. It can't have an offset (needs to be Z), can't
  // have dashes or colons, and can't have milliseconds
  if (until) {
    // We want the until because it will say "until January 31st, 2024" or whatever. Any time we are parsing a string as
    // an ISO date string, we should try/catch in case the string is malformed. Also, after we sanitize it it might
    // still make RRule fail for some reason. So we're try/catching that whole thing and if it fails, we just parse it
    // without the until.
    try {
      const untilDate = BzDateFns.parseISO(until.replace('UNTIL=', '') as IsoDateString, BzDateFns.UTC)
      const untilStr =
        untilDate
          // This will give us a string in the right format with "Z" (no offset)
          .toISOString()
          // Get rid of the colons and dashes
          .replaceAll(/[-:]/g, '')
          // Get rid of the milliseconds
          .slice(0, -5) + 'Z'
      rule = RRule.fromString(`${sanitizedRRule};UNTIL=${untilStr}`)
    } catch (e) {
      console.error('Could not parse rrule until. Until:', until, 'RRule:', rrule, 'Error:', e)
      rule = RRule.fromString(sanitizedRRule)
    }
  } else {
    rule = RRule.fromString(sanitizedRRule)
  }

  // Another issue: RRule's "toText" doesn't work properly with BYSETPOS. If I have `BYDAY=WE` and `BYSETPOS=1` then it
  // should be "every first wednesday" but it's just "every month on wednesday". We can fix it by replacing the
  // `BYDAY=WE` with `BYDAY=1WE` (prefix the day with the bysetpos). Also, for BYSETPOS and BYWEEKDAY are arrays. The
  // library supports multiple (every 1st wednesday and thursday or every 1st and 3rd wednesday). We don't support that
  // but it's not too hard to do so we might as well.
  if (rule.options.bysetpos?.length && rule.options.byweekday?.length) {
    rule = new RRule({
      ...rule.options,
      byweekday: R.xprod(rule.options.bysetpos, rule.options.byweekday).map(
        ([bysetpos, byweekday]) => new Weekday(byweekday, bysetpos),
      ),
    })
  }
  const text = rule.toText()
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
}
