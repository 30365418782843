import { BzAddress, INVOICE_TERMS_V2, R } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { OnsiteConfirmModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { DateField } from '../../../elements/Forms/DateField'
import { RadioButtonListField } from '../../../elements/Forms/RadioButtonListField'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../../elements/Forms/SelectField'
import { TextField } from '../../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../../elements/Forms/useReactHookFormSubmit'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useStrictContext } from '../../../utils/react-utils'
import {
  InvoiceEditDataContext,
  InvoiceInfoFormData,
  InvoiceInfoFormSchema,
  PRETTY_INVOICE_TERMS,
} from '../invoiceUtils'

const INVOICE_TERM_OPTIONS = INVOICE_TERMS_V2.map(value => ({
  value,
  label: PRETTY_INVOICE_TERMS[value],
}))

type SectionProps = React.PropsWithChildren<{
  title: string
  subTitle: string
  last?: boolean
}>

const Section = React.memo<SectionProps>(
  ({ title, subTitle, last, children }) => {
    return (
      <div
        className={classNames(
          'border-0 border-t border-solid border-bz-gray-400 pt-6',
          {
            'pb-6': !last,
          },
        )}
      >
        <div className="mb-1 text-base font-semibold">{title}</div>
        <div className="mb-3 text-sm text-bz-gray-700">{subTitle}</div>
        <div>{children}</div>
      </div>
    )
  },
)

type InvoiceEditInfoModalProps = {
  onClose: () => void
  defaultValues: InvoiceInfoFormData
  displayId: number
  onSave: (data: InvoiceInfoFormData) => void
}

export const InvoiceEditInfoModal = React.memo<InvoiceEditInfoModalProps>(
  ({ onClose, defaultValues: providedDefaultValues, displayId, onSave }) => {
    const isMobile = useIsMobile()
    const { account, job } = useStrictContext(InvoiceEditDataContext)

    const defaultValues = useMemo<InvoiceInfoFormData>(
      () =>
        R.mergeDeepLeft(providedDefaultValues, {
          billingAddress: {
            accountBillingAddressGuid: account.mailingAddress?.addressGuid,
          },
        }),
      [account.mailingAddress?.addressGuid, providedDefaultValues],
    )
    const {
      formState: { errors, isDirty },
      control,
      handleSubmit,
    } = useForm<InvoiceInfoFormData>({
      resolver: zodResolver(InvoiceInfoFormSchema),
      defaultValues,
    })

    const onSubmit = useCallback(
      async (data: InvoiceInfoFormData) => {
        onSave(data)
        onClose()
      },
      [onClose, onSave],
    )

    const serviceAddressOptions = useMemo(
      () =>
        account.accountLocations.map(({ location, locationGuid }) => ({
          value: locationGuid,
          label: (
            <span className="font-semibold">
              {BzAddress.formatAddressSingleLine(location.address)}
            </span>
          ),
        })),
      [account.accountLocations],
    )

    const billingContactOptions = useMemo(
      () =>
        account.accountContacts.map(({ contact }) => ({
          value: contact.contactGuid,
          label: (
            <div className="text-sm">
              <div className="font-semibold">{contact.fullName}</div>
              <div className="text-bz-gray-700">
                {[
                  contact.primaryEmailAddress?.emailAddress,
                  contact.primaryPhoneNumber?.phoneNumber,
                ]
                  .filter(Boolean)
                  .join(' | ')}
              </div>
            </div>
          ),
        })),
      [account.accountContacts],
    )

    const billingAddressOptions = useMemo(() => {
      const options: {
        label: React.ReactNode
        value: InvoiceInfoFormData['billingAddress']['type']
      }[] = [
        {
          label: (
            <span className="font-semibold">(Same as service address)</span>
          ),
          value: 'same_as_service',
        },
        // https://getbreezyapp.atlassian.net/browse/BZ-3031
        // {
        //   label: 'TODO',
        //   value: 'adhoc',
        // },
      ]

      if (account.mailingAddress) {
        options.push({
          label: (
            <span className="font-semibold">
              {BzAddress.formatAddressSingleLine(account.mailingAddress)}
            </span>
          ),
          value: 'account_billing_address',
        })
      }

      return options
    }, [account.mailingAddress])

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    return (
      <OnsiteConfirmModal
        open
        header="Edit info"
        onCancel={onClose}
        onConfirm={triggerSubmit}
        confirmDisabled={!isDirty}
        confirmText="Save"
        size="large-width"
      >
        <Form onSubmitCapture={handleSubmit(onSubmit)} layout="vertical">
          <div className="mb-6 grid grid-cols-2 gap-x-3 gap-y-6">
            <div
              className={classNames({
                'col-span-2': isMobile,
              })}
            >
              <ReactHookFormItem
                required
                noBottomMargin
                control={control}
                name="serviceCompletionDate"
                label="Service Completion Date"
                errors={errors}
                render={({ field }) => <DateField {...field} />}
              />
            </div>
            <div
              className={classNames({
                'col-span-2': isMobile,
              })}
            >
              <ReactHookFormItem
                required
                noBottomMargin
                control={control}
                name="invoiceTerm"
                label="Due Date"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    size="large"
                    options={INVOICE_TERM_OPTIONS}
                    title="Due Date"
                  />
                )}
              />
            </div>
            <Form.Item label="Invoice Number" className="mb-0">
              <TextField disabled value={`${displayId}`} />
            </Form.Item>
            <ReactHookFormItem
              noBottomMargin
              control={control}
              name="customerPurchaseOrderNumber"
              label="PO Number"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          {/* We can only edit this for a maintenance plan invoice. It doesn't make sense to change the service address
              for a job-based one. */}
          {!job && (
            <Section
              title="Select service address"
              subTitle="The location where the service (job) was performed."
            >
              <ReactHookFormItem
                noBottomMargin
                control={control}
                name="serviceLocationGuid"
                errors={errors}
                render={({ field }) => (
                  <RadioButtonListField
                    {...field}
                    options={serviceAddressOptions}
                  />
                )}
              />
            </Section>
          )}
          <Section
            title="Select billing contact"
            subTitle="The contact that the invoice should be addressed to."
          >
            <ReactHookFormItem
              noBottomMargin
              control={control}
              name="billingContactGuid"
              errors={errors}
              render={({ field }) => (
                <RadioButtonListField
                  {...field}
                  options={billingContactOptions}
                />
              )}
            />
          </Section>
          <Section
            last
            title="Select billing address"
            subTitle="The location for billing purposes."
          >
            <ReactHookFormItem
              noBottomMargin
              control={control}
              name="billingAddress.type"
              errors={errors}
              render={({ field }) => (
                <RadioButtonListField
                  {...field}
                  options={billingAddressOptions}
                />
              )}
            />
          </Section>
          {submitElement}
        </Form>
      </OnsiteConfirmModal>
    )
  },
)
