import React from 'react'
import { useLocalStorage } from 'react-use'

type CSModeContextType = {
  isCSMode: boolean
  setIsCSMode: (isCSMode: boolean) => void
}

export const CSModeContext = React.createContext<CSModeContextType>({
  isCSMode: false,
  setIsCSMode: () => {},
})

export const CSModeWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const [isCSMode, setIsCSMode] = useLocalStorage('IS_CS_MODE', false)

    return (
      <CSModeContext.Provider value={{ isCSMode: !!isCSMode, setIsCSMode }}>
        {children}
      </CSModeContext.Provider>
    )
  },
)
