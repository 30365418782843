import { formatUsc } from '@breezy/shared'
import { faCheck, faX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Divider, Form, FormInstance, Input, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { CreateOrEditJobFormLinkedJobQueryQuery } from '../../../generated/user/graphql'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'
import { LINKED_JOB_QUERY } from '../CreateOrEditJobForm.gql'
import { CreateOrEditNewJobFormSchema } from '../CreateOrEditNewJobFormSchema'

export type LinkJobDataFormSectionProps = {
  form: FormInstance<CreateOrEditNewJobFormSchema>
  companyGuid: string
  linkedJobGuid: string
}

export const LinkJobDataFormSection = ({
  form,
  companyGuid,
  linkedJobGuid,
}: LinkJobDataFormSectionProps) => {
  const linkedJobQuery = useQuery({
    query: LINKED_JOB_QUERY,
    variables: { companyGuid, jobGuid: linkedJobGuid },
  })

  return (
    <Card
      className="bg-bz-gray-200 px-4 py-3"
      bodyStyle={{ padding: 0, margin: 0 }}
    >
      <GqlQueryLoader
        query={linkedJobQuery}
        render={data => <LinkedJobDataFormContent form={form} data={data} />}
      />
    </Card>
  )
}

type LinkedJobDataFormContentProps = {
  form: FormInstance<CreateOrEditNewJobFormSchema>
  data: CreateOrEditJobFormLinkedJobQueryQuery
}
const LinkedJobDataFormContent = React.memo(
  ({ form, data }: LinkedJobDataFormContentProps) => {
    const [showAcceptedEstimatesSelect, setShowAcceptedEstimatesSelect] =
      useState(false)

    useEffect(() => {
      if (!showAcceptedEstimatesSelect) {
        form.setFieldValue('linkLinkedJobEstimates', undefined)
      }
    }, [form, showAcceptedEstimatesSelect])

    const job = data.jobs[0]
    const numNotes = job.noteLinksAggregate.aggregate?.count ?? 0
    const numPhotos = job.photoLinksAggregate.aggregate?.count ?? 0
    const numAttachments = job.fileLinksAggregate.aggregate?.count ?? 0
    const acceptedEstimates = job.estimates

    // Initialize the form values to true if there's any linked data from the linked job
    useEffect(() => {
      if (job) {
        if (numNotes > 0) {
          form.setFieldsValue({ linkLinkedJobNotes: true })
        }
        if (numPhotos > 0) {
          form.setFieldsValue({ linkLinkedJobPhotos: true })
        }
        if (numAttachments > 0) {
          form.setFieldsValue({ linkLinkedJobAttachments: true })
        }
        if (acceptedEstimates.length > 0) {
          setShowAcceptedEstimatesSelect(true)
          form.setFieldsValue({
            linkLinkedJobEstimates: acceptedEstimates.map(
              estimate => estimate.estimateGuid,
            ),
          })
        }
      }
    }, [form, numNotes, numPhotos, numAttachments, acceptedEstimates, job])

    return (
      <div className="flex w-full flex-col gap-3">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-semibold text-bz-gray-1000">
            Link Data from Previous Job
          </span>

          <p className="m-0 text-xs leading-5">
            The previous job was a{' '}
            <span className="font-semibold">
              {job.jobType.name} ({job.jobLifecycleStatus.jobLifecycle.name})
            </span>{' '}
            for{' '}
            <span className="font-semibold">
              {job.pointOfContact.fullName ?? 'Unknown'}
            </span>
            . Select the data that you’d like to link into the new job.
          </p>
        </div>

        <Divider className="m-0" />

        <Form.Item name="linkedJobGuid" noStyle hidden>
          <Input />
        </Form.Item>

        <div className="flex flex-row items-center gap-3">
          <Form.Item name="linkLinkedJobNotes" className="m-0">
            <Switch
              disabled={numNotes === 0}
              onChange={value => {
                form.setFieldsValue({ linkLinkedJobNotes: value })
              }}
              unCheckedChildren={<FontAwesomeIcon icon={faX} />}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            />
          </Form.Item>
          <span className="font-semibold">Notes ({numNotes})</span>
        </div>

        <div className="flex flex-row items-center gap-3">
          <Form.Item name="linkLinkedJobPhotos" className="m-0">
            <Switch
              disabled={numPhotos === 0}
              unCheckedChildren={<FontAwesomeIcon icon={faX} />}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              onChange={value => {
                form.setFieldsValue({ linkLinkedJobPhotos: value })
              }}
            />
          </Form.Item>

          <span className="font-semibold">Photos ({numPhotos})</span>
        </div>

        <div className="flex flex-row items-center gap-3">
          <Form.Item name="linkLinkedJobAttachments" className="m-0">
            <Switch
              disabled={numAttachments === 0}
              unCheckedChildren={<FontAwesomeIcon icon={faX} />}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              onChange={value => {
                form.setFieldsValue({ linkLinkedJobAttachments: value })
              }}
            />
          </Form.Item>

          <span className="font-semibold">Attachments ({numAttachments})</span>
        </div>

        <div className="flex w-full flex-row items-start gap-3">
          <Switch
            disabled={false}
            unCheckedChildren={<FontAwesomeIcon icon={faX} />}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            checked={showAcceptedEstimatesSelect}
            onChange={value => {
              setShowAcceptedEstimatesSelect(value)
            }}
          />

          <div className="flex w-full flex-col gap-2">
            <span className="font-semibold">
              Accepted Estimates ({acceptedEstimates.length})
            </span>

            <Form.Item
              name="linkLinkedJobEstimates"
              hidden={!showAcceptedEstimatesSelect}
            >
              <Select
                className="w-full"
                mode="multiple"
                options={acceptedEstimates.map(estimate => ({
                  label: `#${estimate.displayId} (${formatUsc(
                    estimate.estimateOptions[0].totalUsc,
                  )})`,
                  value: estimate.estimateGuid,
                }))}
                onChange={value => {
                  form.setFieldsValue({ linkLinkedJobEstimates: value })
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    )
  },
)
