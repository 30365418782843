import { bzOptional } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FormBody from '../../../../elements/Forms/FormBody'
import FormHeader from '../../../../elements/Forms/FormHeader'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { TextAreaField } from '../../../../elements/Forms/TextAreaField'
import { TextField } from '../../../../elements/Forms/TextField'
import {
  SetIsDirty,
  useExternalIsDirty,
} from '../../../../hooks/useExternalIsDirty'
import { OtherPaymentDto } from '../OtherPaymentWorkflow/OtherPaymentWorkflow'
import PaymentFooterButtons from '../PaymentFooterButtons/PaymentFooterButtons'

const CheckFormSchema = z.object({
  checkNumber: bzOptional(z.string().describe('Check Number')),
  bankName: bzOptional(z.string().describe('Bank Name')),
  note: bzOptional(z.string()).describe('Note'),
})

export type CheckFormData = z.infer<typeof CheckFormSchema>

type CheckFormProps = {
  onCancel?: () => void
  onSubmit: (data: OtherPaymentDto) => void
  isLoading: boolean
  footerClassName?: string
  setIsDirty?: SetIsDirty
}

const CheckForm = React.memo<CheckFormProps>(
  ({ onCancel, onSubmit, isLoading, footerClassName, setIsDirty }) => {
    const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
    } = useForm<CheckFormData>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(CheckFormSchema),
    })

    useExternalIsDirty(setIsDirty, isDirty)

    const onSubmitInner = useCallback(
      (data: CheckFormData) => {
        const externalPaymentId = data.checkNumber
          ? `Check Number: ${data.checkNumber}`
          : undefined
        const paymentAdditionalInfo = data.bankName
          ? `Bank Name: ${data.bankName}`
          : undefined
        onSubmit({
          note: data.note,
          externalPaymentId,
          paymentMethodAdditionalInfo: paymentAdditionalInfo,
        })
      },
      [onSubmit],
    )
    return (
      <Form
        className="flex min-h-0 flex-1 flex-col"
        layout="vertical"
        onSubmitCapture={handleSubmit(onSubmitInner)}
      >
        <FormBody>
          <FormHeader>Payment Information</FormHeader>

          <div className="flex flex-row gap-3">
            <ReactHookFormItem
              className="flex-1"
              control={control}
              name="checkNumber"
              label="Check Number"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              className="flex-1"
              control={control}
              name="bankName"
              label="Bank Name"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          <ReactHookFormItem
            control={control}
            className="flex-1"
            name="note"
            label="Payment Memo"
            errors={errors}
            render={({ field }) => (
              <TextAreaField
                {...field}
                placeholder="(Optional) Additional details about the payment"
              />
            )}
          />
        </FormBody>
        <PaymentFooterButtons
          onCancel={onCancel}
          isLoading={isLoading}
          formStyle="modal"
          primaryButtonText="Record Payment"
          footerClassName={footerClassName}
          dataDdActionName="BZ Payment Workflow - Pay - Check"
        />
      </Form>
    )
  },
)

export default CheckForm
