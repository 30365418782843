import { z } from 'zod'
import { AsyncFn } from '../../common'
import { ForCompany } from '../Company/Company'

export const DISPLAY_ID_ENTITY_TYPES = ['INVOICE', 'JOB', 'ESTIMATE', 'PRICEBOOK_VERSION'] as const

export const DisplayIdGeneratorRequestSchema = z.object({
  entityType: z.enum(DISPLAY_ID_ENTITY_TYPES),
})

export type DisplayIdGeneratorRequest = z.infer<typeof DisplayIdGeneratorRequestSchema>

export type DisplayIdGenerator = AsyncFn<ForCompany<DisplayIdGeneratorRequest>, number>
