import { R, memoize } from '@breezy/shared'
import React, { useEffect, useMemo } from 'react'
import {
  Location,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom'
import { useSessionStorage } from 'react-use'
import { estimateBackButtonOverrideResolver } from './estimateBackButtonOverrides'
import { invoiceBackButtonOverrideResolver } from './invoiceBackButtonOverride'
import { maintenancePlanWizardBackButtonOverrideResolver } from './maintenancePlanWizardBackButtonOverride'

// This will always be `${pathname}${search}${hash}`
export type HistoryStackLocation = string & {
  brand: 'backButtonOverrideLocation'
}

// Convenience function to get a very versatile URL object from a HistoryStackLocation
export const getUrlFromHistoryStackLocation = memoize(
  (location: HistoryStackLocation): URL => {
    return new URL(`${window.location.origin}${location}`)
  },
)

/**
 * This function takes a history stack. It assumes there was a back button press, navigating from the "previousPage" to
 * the last element in the array. The function returns a number. That number is the number of pages to SKIP. If a
 * resolver looks at the state and determines we should leap frog the next page in the stack, it would return 1. If the
 * pages in the stack don't match any of its logic, it returns 0. Note that it does NOT return "-1", indicating that the
 * state results in a "-1" navigation.
 *
 * Notes:
 * - The "previous page" is always the original page that the user went back on. If a resolver that resolves before this
 *   one skipped over a page, this resolver will still get the original previous page (but an updated history stack with
 *   the skipped-over pages removed).
 * - Once a resolver matches, we don't continue resolving. Thus, there are strange implications because the order the
 *   resolvers run could impact each other. Suppose the the history is a->b->c->d. Suppose you have two resolvers: the
 *   first resolver goes to "b" when they go back from "c"->"d" and second goes "a" when they go back from "c"->"d". By
 *   the second one's logic, they should go to "a" since they go back from "c"-"d", but they will go to "b" because the
 *   first one ran and we stopped checking. In practice I'm not too worried about either of these cases because these
 *   overrides should be module-specific and shouldn't overlap. If I need custom logic for the estimates flow, for
 *   instance, that should all be in one resolver and there shouldn't be another resolver somewhere looking at estimate
 *   urls.
 */
export type BackButtonOverrideResolver = (
  previousPage: HistoryStackLocation,
  historyStack: HistoryStackLocation[],
) => number

const BACK_BUTTON_RESOLVERS: BackButtonOverrideResolver[] = [
  maintenancePlanWizardBackButtonOverrideResolver,
  estimateBackButtonOverrideResolver,
  invoiceBackButtonOverrideResolver,
]

export const getHistoryStackLocationFromLocation = (
  location: Location,
): HistoryStackLocation =>
  [location.pathname, location.search, location.hash].join(
    '',
  ) as HistoryStackLocation

// Given the navigation type, the current location and the history stack check various edge cases (refresh, forward
// button, etc) to figure out what really happened
export const getActualPopType = (
  navigationType: NavigationType,
  currentLocation: HistoryStackLocation,
  historyStack: HistoryStackLocation[],
): 'NOT_POP' | 'INVALID' | 'BACK' | 'REFRESH' | 'FORWARD' => {
  // A back is a POP so if it's not a pop it's not a back
  if (navigationType !== 'POP') {
    return 'NOT_POP'
  }
  // This honestly shouldn't be possible. When they hit back, the last element in the stack is where they came from and
  // the second-to-last is where they landed. If there was one element in the array, that means they just landed in our
  // app and we recorded the first url. So if they go back from there, that should take them out of our app, which means
  // this would never trigger because they'd be out of our app. But better safe than sorry.
  if (historyStack.length <= 1) {
    return 'INVALID'
  }

  const previousPage = historyStack[historyStack.length - 1]

  // Edge case: refreshing the page is a "POP" for some reason. So if we go back but end up on the same page, don't do
  // anything extra. Note that it shouldn't be possible to have two adjacent items in the history be equal because going
  // from a url to the same url doesn't change `currentLocationString`. Thus, when we get a POP that lands them at the
  // same place they were (I only know of "refresh" doing this but if something else does then the logic still holds)
  // that isn't a "back" so we shouldn't go back extra.
  if (currentLocation === previousPage) {
    return 'REFRESH'
  }
  // Edge case: a browser "forward" comes in as a "POP". Obviously if they go forward we don't want to do any of this
  // back button logic. When they go back, the last element in our stack is where they came from and the second-to-last
  // is where they went back to. So if they current location doesn't match the second-to-last element, then they
  // couldn't have gone back (this covers if they do a browser forward. I'm not sure if there are other scenarios, other
  // than refresh, where a "POP" doesn't send them back to the previous page, but if there is the same logic should
  // apply).
  if (currentLocation !== historyStack[historyStack.length - 2]) {
    return 'FORWARD'
  }
  return 'BACK'
}

// Given the navigation type, the current location and the history stack determine how many pages we should skip over.
export const getBackwardSteps = (
  navigationType: NavigationType,
  currentLocation: HistoryStackLocation,
  historyStack: HistoryStackLocation[],
  backButtonResolvers: BackButtonOverrideResolver[] = BACK_BUTTON_RESOLVERS,
) => {
  const popType = getActualPopType(
    navigationType,
    currentLocation,
    historyStack,
  )
  if (popType !== 'BACK') {
    return 0
  }

  const previousPage = historyStack[historyStack.length - 1]

  // Pop off the "previousPage"
  const stack = historyStack.slice(0, -1)

  for (const resolver of backButtonResolvers) {
    const steps = resolver(previousPage, stack)
    // Once we find a resolver that has a skip, we stop looking.
    if (steps) {
      return steps
    }
  }
  // If none of the resolvers matched, we must not have any skips.
  return 0
}

// To avoid getting out of hand memory-wise, don't save more than 50 locations
const MAX_STACK_SIZE = 50

// Given a navigation type, the current location, the history stack, and the number of pending backward steps, return
// what the new history stack should be.
export const getNextHistoryStack = (
  navigationType: NavigationType,
  currentLocation: HistoryStackLocation,
  historyStack: HistoryStackLocation[],
  backwardSteps: number,
): HistoryStackLocation[] => {
  // Putting the logic for adding something to the top of the stack up here so I can use it in two places. The ".slice"
  // limits the size of our stack
  const getCurrentLocationPushedToTop = () =>
    [...historyStack, currentLocation].slice(-MAX_STACK_SIZE)

  switch (navigationType) {
    case NavigationType.Push:
      return getCurrentLocationPushedToTop()
    case NavigationType.Replace: {
      // If they replaced, we have to replace the top of the stack. It shouldn't be possible to have an empty stack,
      // but better safe than sorry.
      if (historyStack.length > 0) {
        return R.update(-1, currentLocation, historyStack)
      } else {
        console.error(
          `"REPLACE" navigation with no history stack should not be possible. History stack: ${JSON.stringify(
            historyStack,
          )}`,
        )
        return historyStack
      }
    }
    case NavigationType.Pop: {
      // There is a potential edge case where we land on the page, it's a "POP", but there is no history stack. I
      // believe I resolved it by making the default state of the stack the current location. With that, it shouldn't be
      // possible logically to get an empty stack. But putting this here to be safe.
      if (!historyStack.length) {
        return [currentLocation]
      }

      const popType = getActualPopType(
        navigationType,
        currentLocation,
        historyStack,
      )

      switch (popType) {
        case 'BACK': {
          // If we're going back, we need to pop off the top of the stack. If there are backwardSteps, however, we do this in
          // a separate useEffect. In that case, just return the stack as-is.
          if (backwardSteps === 0) {
            return historyStack.slice(0, -1)
          }
          return historyStack
        }
        case 'FORWARD':
          // A forward is effectively the same as a "PUSH"
          return getCurrentLocationPushedToTop()
        // The only remaining states are "NOT_POP", "INVALID", and "REFRESH". I know it's not "NOT_POP" because this is
        // inside an if block that checks if it's pop. I don't care what happens with "INVALID", so returning the stack
        // as-is is fine. For "REFRESH", I want to leave the stack as-is.
        case 'NOT_POP':
        case 'INVALID':
        case 'REFRESH':
        default:
          return historyStack
      }
    }
  }
}

type HistoryStackContextType = {
  historyStack: HistoryStackLocation[]
}

export const HistoryStackContext = React.createContext<
  HistoryStackContextType | undefined
>(undefined)

/**
 * This wrapper detects when a browser back operation has happened and uses custom logic to determine, based on the
 * history, whether certain pages should be skipped. For instance, going from a "listing page" -> "creation page" ->
 * "overview page" then hitting back should skip over the creation page.
 *
 * As various elements of the tech app need more custom logic, we can write them and add them to the
 * `backButtonResolvers` array above.
 *
 * NOTE: Because of the browser-dependent and custom-logic-dependent nature of this, it is strongly encouraged, if you
 * add a new resolver, to add Cypress tests as well as unit tests.
 *
 * TODO: Bug: if someone does `navigate(-2)` or anything more than `-1`, we don't keep track of how many back that was
 * and our state gets out of sync.
 */
export const BackButtonOverridesWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const navigationType = useNavigationType()

    // This is for a performance optimization (and potentially for correctness). Our various useMemos/useEffects will
    // change based on this, which means they only change when the real URL really changes. This is easier than the
    // location object because string comparison is easy.
    const currentLocation = useMemo(
      () => getHistoryStackLocationFromLocation(location),
      [location],
    )

    // This is a stack of all the pages they've visited in their session. Session storage is per-tab. Doing it in
    // session storage means if they hit refresh we'll still have it.
    const [historyStack, setHistoryStack] = useSessionStorage<
      HistoryStackLocation[]
      // Default (on mount) to the current location.
    >('history-stack', [currentLocation])

    // In a perfect world, the user would hit "back", I would intercept that, and "go back more" based on some logic.
    // There's basically no way to do that because every way that I've found to "hook into" the back button triggers
    // AFTER it happens. That means that the previous page, a page that we may want to skip over, will appear for a
    // split second. Putting everything in one `useEffect` that detects and then does `navigate` has the same problem:
    // we render the current `children` for one tick before we navigate. I need a way to know that I WILL be skipping
    // over BEFORE I skip over so that I can avoid displaying the page we're skipping. So that's what this does. Before
    // we render everything after a location change, we do all our computations to see if we need to go backwards extra
    // steps. If this is 0, then that means we don't (could mean we added a new page to the stack, hit forward, or hit
    // back but the logic didn't match any overrides). So when this is 0 the rest of the code does nothing. When it's
    // greater than 0, we'll have a `useEffect` that triggers the navigation and, while that's happening, block the page
    // from rendering for that split second.
    const backwardSteps = useMemo(() => {
      // We're only looking at "BACK"s, which doesn't include "REPLACE" and obviously doesn't include "PUSH"
      if (navigationType === 'POP') {
        return getBackwardSteps(navigationType, currentLocation, historyStack)
      }
      return 0
      // We ONLY want changes to the real location (currentLocation) to trigger this.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation])

    // The thing that actually moves us back extra steps if we need to skip over pages
    useEffect(() => {
      if (backwardSteps) {
        // Pop off the number of pages we're popping off PLUS the one they originally "back"'d.
        setHistoryStack(historyStack.slice(0, -(backwardSteps + 1)))
        navigate(-backwardSteps)
      }
      // We ONLY want to trigger a navigate if `backwardSteps` changes. We can have this thing running several times and
      // doing too many navigations.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backwardSteps])

    // Keeping the history stack in sync
    useEffect(() => {
      setHistoryStack(
        getNextHistoryStack(
          navigationType,
          currentLocation,
          historyStack,
          backwardSteps,
        ),
      )
      // We ONLY want changes to the real location (currentLocation) to trigger this.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation])

    // If there are backwards steps, I don't want to render anything. That's because backward steps imply that the
    // already-executed back operation should result skip one or more pages. Thus, the page that should be currently
    // rendered should be skipped. See the comment before the "backwardSteps" `useMemo` for more details. Without this,
    // that page flashes for a split second. Now, a white screen flashes, which isn't particularly jarring as a user
    // because I kind of expect something like that when hitting the back button.
    if (backwardSteps) {
      return null
    }

    return (
      <HistoryStackContext.Provider value={{ historyStack }}>
        {children}
      </HistoryStackContext.Provider>
    )
  },
)
