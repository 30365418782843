import { CalculatePaths, JobGuid, richJobTypeDescriptor } from '@breezy/shared'
import React from 'react'
import { Link } from 'react-router-dom'
import useAppNavigation from '../../../../hooks/useAppNav'
import { m } from '../../../../utils/react-utils'
import VerticalKeyValue from '../../../Page/Columns/TagColumn/VerticalKeyValue'

type JobDetailsLinkProps = {
  jobGuid: JobGuid
  jobType: {
    name: string
  }
  extra?: React.ReactNode
}

type OnLinkClick = Partial<{
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}>

const JobDetailsLink = m<JobDetailsLinkProps>(({ jobGuid, jobType, extra }) => {
  const appNav = useAppNavigation()

  return (
    <JobDetailsLinkView
      jobGuid={jobGuid}
      jobType={jobType}
      extra={extra}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        appNav.navigateToJobDetailsPage(jobGuid)
      }}
    />
  )
})

type JobDetailsLinkViewProps = JobDetailsLinkProps &
  Partial<OnLinkClick> & {
    absolute?: boolean
  }

export const JobDetailsLinkView = React.memo<JobDetailsLinkViewProps>(
  ({ absolute = false, ...rest }) => {
    return (
      <VerticalKeyValue
        spacing="narrow"
        pair={{
          key: 'Job Type',
          value: absolute ? (
            <JobDetailsAnchor {...rest} />
          ) : (
            <JobDetailsReactRouterLink {...rest} />
          ),
        }}
      />
    )
  },
)

const JobDetailsReactRouterLink = ({
  jobGuid,
  jobType,
  extra,
  onClick,
}: JobDetailsLinkProps & OnLinkClick) => {
  return (
    <Link
      to={CalculatePaths.jobDetails({ jobGuid })}
      onClick={onClick}
      className="text-blue-500 no-underline visited:text-blue-500"
    >
      {richJobTypeDescriptor(jobType.name)}
      {extra}
    </Link>
  )
}

const JobDetailsAnchor = ({
  jobGuid,
  jobType,
  extra,
  onClick,
}: JobDetailsLinkProps & OnLinkClick) => {
  return (
    <a
      target="_blank"
      href={CalculatePaths.jobDetails({ jobGuid })}
      onClick={onClick}
      rel="noopener noreferrer"
      className="text-blue-500 no-underline visited:text-blue-500"
    >
      {richJobTypeDescriptor(jobType.name)}
      {extra}
    </a>
  )
}

export default JobDetailsLink
