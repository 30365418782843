import { Address, BzAddress } from '@breezy/shared'
import { faMapLocationDot } from '@fortawesome/pro-light-svg-icons'
import React, { useCallback, useMemo } from 'react'
import FaIconButton from '../elements/FaIconButton/FaIconButton'
import { useCanViewLocation } from '../hooks/permission/useCanViewLocation'
import useAppNavigation from '../hooks/useAppNav'
import { useMapLink } from '../hooks/useMapLink'
import { blue6 } from '../themes/theme'
import { m } from '../utils/react-utils'
import AddressMultiLineView from './Addresses/AddressMultiLineView/AddressMultiLineView'
import VerticalKeyValue from './Page/Columns/TagColumn/VerticalKeyValue'

export type LocationInfoLinkLocation = {
  locationGuid: string
  displayName?: string
  address: Address
}

type LocationInfoLinkProps = {
  location: LocationInfoLinkLocation
  addressSingleLine?: boolean
}

const LocationInfoLink = m<LocationInfoLinkProps>(
  ({ location, addressSingleLine = false }) => {
    const appNav = useAppNavigation()

    const navigateToLocationDetails = useCallback(() => {
      appNav.navigateToLocationDetailsPage(location.locationGuid)
    }, [appNav, location.locationGuid])

    const { canView, isLoading } = useCanViewLocation(location.locationGuid)

    return (
      <LocationInfoLinkView
        location={location}
        linkAddress={!canView}
        onAddressClick={
          canView || isLoading ? navigateToLocationDetails : undefined
        }
        addressSingleLine={addressSingleLine}
      />
    )
  },
)

export const LocationInfoLinkSimple = m<LocationInfoLinkProps>(
  ({ location, addressSingleLine = false }) => {
    const appNav = useAppNavigation()

    const navigateToLocationDetails = useCallback(() => {
      appNav.navigateToLocationDetailsPage(location.locationGuid)
    }, [appNav, location.locationGuid])

    return (
      <LocationInfoLinkView
        location={location}
        onAddressClick={navigateToLocationDetails}
        addressSingleLine={addressSingleLine}
      />
    )
  },
)

type LocationInfoLinkViewProps = {
  location: LocationInfoLinkLocation
  linkAddress?: boolean
  addressSingleLine?: boolean
  onAddressClick?: () => void
}

export const LocationInfoLinkView = React.memo<LocationInfoLinkViewProps>(
  ({
    location,
    linkAddress = false,
    addressSingleLine = false,
    onAddressClick,
  }) => {
    const address = useMemo(
      () => BzAddress.create(location.address),
      [location.address],
    )
    const [mapsButtonUrl] = useMapLink(address)
    return (
      <VerticalKeyValue
        spacing="narrow"
        pair={{
          key: 'Service Location',
          value: (
            <div className="row two-sides flex">
              <div
                className="regular_14_22 gray9 w-fit cursor-pointer"
                onClick={e => e.stopPropagation()}
              >
                <AddressMultiLineView
                  displayName={location.displayName}
                  address={address}
                  linkToMapApp={linkAddress}
                  addressSingleLine={addressSingleLine}
                  onClick={onAddressClick}
                />
              </div>
              <FaIconButton
                icon={faMapLocationDot}
                style={{ color: blue6 }}
                onClick={e => {
                  e.stopPropagation()
                  window.open(mapsButtonUrl, '_blank')
                }}
              />
            </div>
          ),
        }}
      />
    )
  },
)

export default LocationInfoLink
