import {
  EquipmentInstallationParty,
  EquipmentInstallationPartySchema,
  EquipmentOperationalStatus,
  EquipmentOperationalStatusDisplayNames,
  EquipmentOperationalStatusSchema,
  EquipmentType,
  Guid,
  KNOWN_MANUFACTURERS,
  bzOptional,
  formatEquipmentType,
  fuzzyMatch,
  localDateSchema,
  nextGuid,
  toTitleCase,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { OnsiteModalContent } from '../../adam-components/OnsiteModal/OnsiteModal'
import { OptionalDateField } from '../../elements/Forms/OptionalDateField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import { TextField } from '../../elements/Forms/TextField'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExternalIsDirty } from '../../hooks/useExternalIsDirty'
import useIsMobile from '../../hooks/useIsMobile'
import { useMessage } from '../../utils/antd-utils'

// Define the form schema
const createEquipmentFormSchema = z.object({
  equipmentType: z.nativeEnum(EquipmentType),
  manufacturer: bzOptional(z.string()),
  modelNumber: bzOptional(z.string()),
  serialNumber: bzOptional(z.string()),
  installationDate: bzOptional(localDateSchema),
  installationParty: bzOptional(EquipmentInstallationPartySchema),
  estimatedEndOfLifeDate: bzOptional(localDateSchema),
  operationalStatus: EquipmentOperationalStatusSchema,
  description: bzOptional(z.string()),
})

type CreateEquipmentFormSchema = z.infer<typeof createEquipmentFormSchema>

type EquipmentUpsertModalFormV2Props = {
  locationGuid: string
  onCancel: () => void
  onSave: (installedEquipmentGuid: Guid) => void
  setIsDirty: (isDirty: boolean) => void
}

const ItemRow = ({ children }: { children: React.ReactNode[] }) => {
  return (
    <div className="flex w-full flex-row flex-nowrap gap-x-4">
      {(children ?? []).map((child, index) => (
        <div className="w-full" key={index}>
          {child}
        </div>
      ))}
    </div>
  )
}

export const EquipmentUpsertModalFormV2 =
  React.memo<EquipmentUpsertModalFormV2Props>(
    ({ locationGuid, onCancel, onSave, setIsDirty }) => {
      const message = useMessage()
      const btnFormSubmit = useRef<HTMLInputElement>(null)
      const isMobile = useIsMobile()
      const upsertEquipmentMutation =
        trpc.installedEquipment['installed-equipment:upsert'].useMutation()
      const [isUploading, setIsUploading] = useState(false)

      const form = useForm<CreateEquipmentFormSchema>({
        resolver: zodResolver(createEquipmentFormSchema),
        mode: 'onChange',
        defaultValues: {
          operationalStatus: EquipmentOperationalStatus.IN_SERVICE,
        },
      })
      const {
        control,
        formState: { errors, isDirty },
      } = form

      useExternalIsDirty(setIsDirty, isDirty)

      const onFormSubmit = form.handleSubmit(async values => {
        const installedEquipmentGuid = nextGuid()
        try {
          setIsUploading(true)
          upsertEquipmentMutation.mutate(
            {
              ...values,
              installedEquipmentGuid,
              locationGuid,
            },
            {
              onSuccess() {
                message.success(
                  `${formatEquipmentType(
                    values.equipmentType,
                  )} added successfully!`,
                )
                onSave(installedEquipmentGuid)
                form.reset()
                setIsUploading(false)
              },
              onError(err) {
                console.error(`Failed to create equipment: ${err}`)
                message.error(
                  "Oops! We couldn't add the new equipment. Please try again, or reach out to our support team for help if the problem persists.",
                )
                setIsUploading(false)
              },
            },
          )
        } catch (err) {
          console.error(`Failed to create equipment: ${err}`)
          message.error(
            "Oops! We couldn't add the new equipment. Please try again, or reach out to our support team for help if the problem persists.",
          )
          setIsUploading(false)
        }
      })

      const onModalClose = useCallback(() => {
        onCancel()
      }, [onCancel])

      const formFieldSize = useMemo(() => {
        return isMobile ? 'large' : 'middle'
      }, [isMobile])

      return (
        <FormProvider {...form}>
          <OnsiteModalContent
            onClose={onModalClose}
            onBack={onModalClose}
            header="Create Equipment"
            footer={
              <div className="absolute inset-x-0 bottom-0 z-10 flex w-full flex-row justify-between border-0 border-t-4 border-solid border-t-bz-gray-400 bg-white px-6 pb-6 pt-4">
                <Button
                  key="cancel"
                  size="large"
                  className="mr-3 w-full font-semibold"
                  onClick={onModalClose}
                  loading={isUploading}
                >
                  Cancel
                </Button>
                <Button
                  key="createEquipment"
                  htmlType="button"
                  type="primary"
                  size="large"
                  className="w-full font-semibold"
                  loading={isUploading}
                  onClick={() => {
                    btnFormSubmit.current?.click()
                  }}
                  disabled={Object.entries(errors).length > 0}
                >
                  Save
                </Button>
              </div>
            }
          >
            <Form
              layout="vertical"
              onFinish={onFormSubmit}
              validateTrigger="onBlur"
              className="mb-[80px] flex flex-col gap-y-6"
            >
              <ItemRow>
                <ReactHookFormItem
                  noBottomMargin
                  name="equipmentType"
                  label="Equipment Type"
                  required={true}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      disabled={isUploading}
                      size={formFieldSize}
                      title="Select equipment type"
                      placeholder="Select equipment type"
                      showSearch
                      filterOption={(inputValue, option) => {
                        return !![
                          option?.children,
                          option?.value,
                          option?.label,
                        ]
                          .filter(Boolean)
                          .map(v => `${v}`)
                          .find(v => fuzzyMatch(v, inputValue))
                      }}
                      options={Object.values(EquipmentType).map(type => ({
                        label: formatEquipmentType(type),
                        value: type,
                      }))}
                    />
                  )}
                />
                <ReactHookFormItem
                  noBottomMargin
                  name="manufacturer"
                  label="Manufacturer"
                  required={false}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      disabled={isUploading}
                      size={formFieldSize}
                      title="Select a manufacturer"
                      placeholder="Select a manufacturer"
                      options={KNOWN_MANUFACTURERS.map(manufacturer => ({
                        label: manufacturer,
                        value: manufacturer,
                      }))}
                    />
                  )}
                />
              </ItemRow>

              <ReactHookFormItem
                noBottomMargin
                name="modelNumber"
                label="Model Number"
                required={false}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter a Model Number"
                    disabled={isUploading}
                    size={formFieldSize}
                  />
                )}
              />

              <ReactHookFormItem
                noBottomMargin
                name="serialNumber"
                label="Serial Number"
                required={false}
                control={control}
                errors={errors}
                className="mt-[-16px]"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter a Serial Number"
                    disabled={isUploading}
                    size={formFieldSize}
                  />
                )}
              />

              <ThinDivider
                styleOverrides={{ marginTop: 0, marginBottom: 0 }}
                widthPx={1}
              />

              <ItemRow>
                <ReactHookFormItem
                  noBottomMargin
                  name="installationParty"
                  label="Installation Party"
                  required={false}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      disabled={isUploading}
                      allowClear
                      size={formFieldSize}
                      sheetSize="half"
                      title="Select installation party"
                      placeholder="Select installation party"
                      options={Object.values(EquipmentInstallationParty).map(
                        p => ({
                          label: toTitleCase(p),
                          value: p,
                        }),
                      )}
                    />
                  )}
                />

                <ReactHookFormItem
                  noBottomMargin
                  name="operationalStatus"
                  label="Operational Status"
                  required={true}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      disabled={isUploading}
                      size={formFieldSize}
                      sheetSize="half"
                      title="Select operational status"
                      options={Object.values(EquipmentOperationalStatus).map(
                        s => ({
                          label: EquipmentOperationalStatusDisplayNames[s],
                          value: s,
                        }),
                      )}
                    />
                  )}
                />
              </ItemRow>

              <ItemRow>
                <ReactHookFormItem
                  noBottomMargin
                  name="installationDate"
                  label="Est. Installation Date"
                  required={false}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <OptionalDateField
                      {...field}
                      disabled={isUploading}
                      size={formFieldSize}
                      placeholder="Select estimated install date"
                    />
                  )}
                />

                <ReactHookFormItem
                  noBottomMargin
                  name="estimatedEndOfLifeDate"
                  label="Est. End of Life Date"
                  required={false}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <OptionalDateField
                      {...field}
                      disabled={isUploading}
                      size={formFieldSize}
                      placeholder="Select estimated EOL date"
                    />
                  )}
                />
              </ItemRow>

              <ThinDivider
                styleOverrides={{ marginTop: 0, marginBottom: 0 }}
                widthPx={1}
              />

              <ReactHookFormItem
                noBottomMargin
                name="description"
                label="Description"
                required={false}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TextAreaField
                    {...field}
                    rows={4}
                    placeholder="Enter a description for the piece of equipment"
                    disabled={isUploading}
                    size={formFieldSize}
                  />
                )}
              />

              <input ref={btnFormSubmit} type="submit" className="hidden" />
            </Form>
          </OnsiteModalContent>
        </FormProvider>
      )
    },
  )
