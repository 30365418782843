import { Tag as BreezyTag, MaintenancePlanMinimalInfo } from '@breezy/shared'
import { faTag } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag as AntdTag, Popover } from 'antd'
import { HTMLAttributeAnchorTarget } from 'react'
import { Link } from 'react-router-dom'
import { Styled } from '../../utils/Stylable'
import { m, stopPropagation } from '../../utils/react-utils'
import MaintenancePlanVipIcon from '../MaintenancePlanVipIcon/MaintenancePlanVipIcon'
import { Tag } from '../Tags'

type MaintenancePlanVipElementInnerProps = {
  label: string
  maintenancePlan?: MaintenancePlanMinimalInfo
  tags?: BreezyTag[]
  iconPosition?: 'left' | 'right'
  iconMargin?: string
  spaceBetween?: boolean
}

const MaintenancePlanVipElementInner = ({
  label,
  maintenancePlan,
  tags,
  iconPosition = 'right',
  iconMargin = 'mr-1',
  spaceBetween = false,
}: MaintenancePlanVipElementInnerProps) => {
  const spaceBetweenClassName = spaceBetween
    ? 'flex row flex-between flex-nowrap w-full center-children-v row'
    : 'w-full center-children-v row'

  return (
    <div className={spaceBetweenClassName}>
      {iconPosition === 'left' && (
        <>
          <MaintenancePlanVipIcon
            maintenancePlan={maintenancePlan}
            customMargin={iconMargin ?? 'mr-1'}
            inline={!spaceBetween}
          />
        </>
      )}

      <div className="flex flex-row gap-x-2">
        {label}

        {tags && tags.length > 0 && (
          <Popover
            content={
              <div className="flex flex-col gap-y-2">
                {tags.map(tag => (
                  <div key={tag.tagGuid} className="w-max">
                    <Tag tag={tag} />
                  </div>
                ))}
              </div>
            }
            trigger={['hover']}
            placement="right"
          >
            <AntdTag icon={<FontAwesomeIcon icon={faTag} fontSize={14} />}>
              {' '}
              +{tags.length}
            </AntdTag>
          </Popover>
        )}
      </div>

      {iconPosition === 'right' && (
        <>
          {' '}
          <MaintenancePlanVipIcon
            maintenancePlan={maintenancePlan}
            customMargin={iconMargin}
            inline={!spaceBetween}
          />
        </>
      )}
    </div>
  )
}

type MaintenancePlanVipElementProps = {
  readonly labelText: string
  readonly urlTo?: string
  readonly target?: HTMLAttributeAnchorTarget | undefined
  readonly maintenancePlan?: MaintenancePlanMinimalInfo

  readonly iconOnLeft?: boolean
  readonly plainLabel?: boolean
  readonly spaceBetween?: boolean
  readonly customIconMargin?: string
  readonly textSizeClassName?: string

  readonly tags?: BreezyTag[]
}

export const MaintenancePlanVipElement = m<
  Styled<MaintenancePlanVipElementProps>
>(
  ({
    className,
    style,
    urlTo,
    labelText,
    target,
    maintenancePlan,
    tags,
    iconOnLeft = false,
    plainLabel = false,
    spaceBetween = false,

    customIconMargin,
    textSizeClassName = 'regular_12_20',
  }) => {
    if (!!urlTo && !plainLabel)
      return (
        <Link
          target={target}
          onClick={stopPropagation}
          className={`row center-children-v react-plain-link ${textSizeClassName} w-fit ${className}`}
          to={urlTo}
          style={{ margin: 'auto 0', ...style }}
          data-testid="maintenance-plan-vip-label"
        >
          <MaintenancePlanVipElementInner
            label={labelText}
            maintenancePlan={maintenancePlan}
            iconMargin={customIconMargin}
            iconPosition={iconOnLeft ? 'left' : 'right'}
            spaceBetween={spaceBetween}
            tags={tags}
          />
        </Link>
      )

    return (
      <div
        className={`row center-children-v flex-start grey7 ${textSizeClassName} w-fit ${className}`}
        data-testid="maintenance-plan-vip-label"
      >
        <MaintenancePlanVipElementInner
          label={labelText}
          maintenancePlan={maintenancePlan}
          iconMargin={customIconMargin}
          iconPosition={iconOnLeft ? 'left' : 'right'}
          spaceBetween={spaceBetween}
          tags={tags}
        />
      </div>
    )
  },
)
