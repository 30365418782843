import React, { useEffect } from 'react'
import { useSchedulePendingChanges } from './SchedulePendingChangesContext'
import { useCommitScheduleChanges } from './useCommitScheduleChanges'
import { hasPendingChanges } from './utils'

export const CommitScheduleChangesListener = React.memo(() => {
  const { pendingChanges } = useSchedulePendingChanges()
  const commitScheduleChanges = useCommitScheduleChanges()
  useEffect(() => {
    if (hasPendingChanges(pendingChanges)) {
      commitScheduleChanges()
    }
    // I only want this to happen when pendingChanges changes, not commitChanges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingChanges])

  return null
})
