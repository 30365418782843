import {
  APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG,
  AppointmentChecklistInstanceItem,
  AppointmentChecklistItemType,
  R,
} from '@breezy/shared'
import {
  faCheck,
  faClose,
  faFlag,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, Form, Input, Radio } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import cn from 'classnames'
import React, { useCallback } from 'react'

// Arbitrary value I picked because it looked the best.
const TEXT_AREA_ROWS = 4

type ChecklistItemProps = {
  name: string
  type: AppointmentChecklistItemType
  value: string
  notRequired?: boolean
  onChange: (value: string) => void
  readonly: boolean
}

const ChecklistItem = React.memo<ChecklistItemProps>(
  ({ name, type, value, onChange, notRequired, readonly }) => {
    const ourRequired = readonly ? false : !notRequired
    if (type === 'CHECKBOX') {
      return (
        <Form.Item className="mb-6" required={ourRequired} label={name}>
          {readonly ? (
            <div className="text-base font-semibold">{value}</div>
          ) : (
            <Radio.Group
              value={value}
              onChange={e => onChange(e.target.value)}
              optionType="button"
            >
              {APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG.CHECKBOX.options.map(
                option => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ),
              )}
            </Radio.Group>
          )}
        </Form.Item>
      )
    }
    if (type === 'TEXT') {
      return (
        <Form.Item className="mb-6" label={name} required={ourRequired}>
          {readonly ? (
            <div className="text-base font-semibold">{value}</div>
          ) : (
            <TextArea
              value={value}
              onChange={e => onChange(e.target.value)}
              rows={TEXT_AREA_ROWS}
            />
          )}
        </Form.Item>
      )
    }
    if (type === 'INPUT') {
      return (
        <Form.Item className="mb-6" label={name} required={ourRequired}>
          {readonly ? (
            <div className="text-base font-semibold">{value}</div>
          ) : (
            <Input value={value} onChange={e => onChange(e.target.value)} />
          )}
        </Form.Item>
      )
    }
    if (type === 'PASS_FAIL_FLAG') {
      return (
        <Form.Item label={name} required={ourRequired}>
          <div className="flex flex-row space-x-2">
            {value || !readonly ? (
              (
                [
                  [
                    'Pass',
                    faCheck,
                    'border-bz-green-600',
                    'bg-bz-green-100',
                    'text-bz-green-800',
                  ],
                  [
                    'Flag',
                    faFlag,
                    'border-bz-orange-600',
                    'bg-bz-orange-100',
                    'text-bz-orange-800',
                  ],
                  [
                    'Fail',
                    faClose,
                    'border-bz-red-600',
                    'bg-bz-red-100',
                    'text-bz-red-800',
                  ],
                ] satisfies [
                  (typeof APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG.PASS_FAIL_FLAG.options)[number],
                  IconDefinition,
                  string,
                  string,
                  string,
                ][]
              ).map(
                ([label, icon, borderColor, bgColor, selectedLabelColor]) => {
                  const isSelected = value === label
                  if (readonly && !isSelected) {
                    return null
                  }
                  return (
                    <Button
                      key={label}
                      className={cn(
                        `flex min-w-0 max-w-[88px] items-center justify-center gap-[6px] rounded-sm`,
                        {
                          [selectedLabelColor]: isSelected,
                          'flex-1': !readonly,
                          'text-black': !isSelected,
                          [bgColor]: isSelected,
                          'bg-transparent': !isSelected,
                          [borderColor]: isSelected,
                          'border-bz-gray-500': !isSelected,
                        },
                      )}
                      onClick={() => {
                        if (readonly) {
                          return
                        }
                        if (isSelected) {
                          onChange('')
                        } else {
                          onChange(label)
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={icon}
                        className={cn('h-4 w-4', selectedLabelColor)}
                      />
                      {label}
                    </Button>
                  )
                },
              )
            ) : (
              <Button className="rounded-sm">Blank</Button>
            )}
          </div>
        </Form.Item>
      )
    }
    if (type === 'YES_NO') {
      return (
        <Form.Item className="mb-6" required={ourRequired} label={name}>
          {readonly ? (
            <div className="text-base font-semibold">{value}</div>
          ) : (
            <Radio.Group
              value={value}
              onChange={e => onChange(e.target.value)}
              optionType="button"
            >
              {APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG.YES_NO.options.map(
                option => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ),
              )}
            </Radio.Group>
          )}
        </Form.Item>
      )
    }
    return null
  },
)

type AppointmentChecklistInstanceFormProps = {
  items: AppointmentChecklistInstanceItem[]
  setItems?: (value: AppointmentChecklistInstanceItem[]) => void
  setIsDirty?: (value: boolean) => void
}

export const AppointmentChecklistInstanceForm =
  React.memo<AppointmentChecklistInstanceFormProps>(
    ({ items, setItems, setIsDirty }) => {
      const updateItem = useCallback(
        (index: number, value: string) => {
          setItems?.(
            R.adjust(index, item => ({ ...item, response: value }), items),
          )
          setIsDirty?.(true)
        },
        [items, setItems, setIsDirty],
      )

      const readonly = !setItems
      return (
        <div>
          <Form layout="vertical" className="min-h-0 flex-1">
            {items.map((item, i) => (
              <ChecklistItem
                // Note: order won't change so `i` is a fine key. Checklist item name SHOULD
                // be unique, but better safe than sorry.
                key={`${item.name}_${i}`}
                {...item}
                value={item.response || ''}
                onChange={value => updateItem(i, value)}
                readonly={readonly}
              />
            ))}
          </Form>
        </div>
      )
    },
  )
