import { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import { useCompanyUserContext } from '../../../hooks/useCompanyUserContext'
import { COMPANY_USERS_QUERY } from './AccountReminderAssignmentField.gql'

export const useAccountReminderAssignmentField = () => {
  const { companyGuid, principalUserGuid } = useCompanyUserContext()

  const [{ data: companyUsersData, fetching }] = useQuery({
    query: COMPANY_USERS_QUERY,
    variables: {
      companyGuid,
    },
  })

  const isCurrentUser = useCallback(
    (userGuid: string) => userGuid === principalUserGuid,
    [principalUserGuid],
  )

  const companyUserOptions = useMemo(() => {
    if (!companyUsersData) return []
    return companyUsersData.companyUsers.map(companyUser => ({
      label: `${companyUser.userByUserGuid.firstNameInitial} ${
        isCurrentUser(companyUser.userByUserGuid.userGuid) ? '(You)' : ''
      }`,
      value: companyUser.userByUserGuid.userGuid,
    }))
  }, [companyUsersData, isCurrentUser])

  const defaultValue = useMemo(() => {
    if (!companyUserOptions?.length) return ''
    return (
      companyUserOptions.map(option => option.value).find(isCurrentUser) ||
      companyUserOptions[0].value ||
      ''
    )
  }, [companyUserOptions, isCurrentUser])

  return {
    fetching,
    companyUserOptions,
    defaultValue,
  }
}
