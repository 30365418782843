import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useFetchTechnicianPerformanceConfig } from '../fetch/useFetchTechnicianPerformanceConfig'
import { useIsAuthorizedTo } from './useIsAuthorized'

export const useCanViewTechnicianPerformance = () => {
  const isAuthorized = useIsAuthorizedTo(
    PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW,
  )
  const [technicianPerformanceConfig] = useFetchTechnicianPerformanceConfig()
  const canViewTechnicianPerformance = useMemo(
    () =>
      technicianPerformanceConfig.technicianPerformanceEnabled && isAuthorized,
    [isAuthorized, technicianPerformanceConfig.technicianPerformanceEnabled],
  )

  return canViewTechnicianPerformance
}
