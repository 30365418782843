import {
  ApptAssignmentGuid,
  AssignmentStatus,
  JobGuid,
  isNullish,
  noOp,
} from '@breezy/shared'
import { faBan, faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useSubscription } from 'urql'
import { OnsiteMenuItem } from '../../../../../adam-components/OnsiteMenuItem'
import { OnsiteBasicModal } from '../../../../../adam-components/OnsiteModal/OnsiteModal'
import { BzStepper } from '../../../../../elements/BzStepper/BzStepper'
import { trpc } from '../../../../../hooks/trpc'
import { usePrincipalUser } from '../../../../../providers/PrincipalUser'
import { useMessage } from '../../../../../utils/antd-utils'
import { EndOfAppointmentNextStepsForm } from '../EndOfAppointmentNextStepsForm/EndOfAppointmentNextStepsForm'

import { useIsEndOfAppointmentWorkflowEnabledForCompany } from '../../../../../hooks/fetch/useIsEndOfAppointmentWorkflowEnabledForCompany'
import { FETCH_END_OF_APPOINTMENT_NEXT_STEPS_SUBSCRIPTION } from '../EndOfAppointmentNextStepsForm/EndOfAppointmentNextSteps.gql'
import {
  FETCH_ACTIVE_APPOINTMENT_ASSIGNMENTS_FOR_USER,
  TECH_CLOCKED_IN_QUERY,
} from './AssignmentStatusSteps.gql'
import { AssignmentStatusStepsClockInModal } from './AssignmentStatusStepsClockInModal'

type UnknownClockedInStatusDisposition =
  | 'assume-pessimistic'
  | 'assume-optimistic'

const TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX = new RegExp(
  /Technician already has an active assignment: ([0-9a-f-]+)/i,
)

const MODAL_Z_CLASSNAME = 'z-[1031]'

type AssignmentStatusStepsProps = {
  assignmentStatus: AssignmentStatus
  apptGuid: string
  apptAssignmentGuid: ApptAssignmentGuid
  jobGuid: JobGuid
  onMutate?: () => void
  markCompletedError?: string
}

type AssignmentStatusStep = {
  index: number
  status: AssignmentStatus
  assignmentIsActive: boolean
  bgColorClassName: string
  label: string
  transitionButtonLabel: string
  labelElement?: React.ReactNode
  iconElement?: React.ReactNode
  nextStep?: AssignmentStatusStep
  unknownClockedInStatusDisposition: UnknownClockedInStatusDisposition
  hideStep?: boolean
}

const CanceledStep: AssignmentStatusStep = {
  index: 4,
  hideStep: true,
  status: 'CANCELED',
  label: 'Canceled',
  assignmentIsActive: false,
  bgColorClassName: 'bg-[#F5222D]',
  transitionButtonLabel: 'Assignment Canceled',
  unknownClockedInStatusDisposition: 'assume-pessimistic',
  labelElement: (
    <span className="font-semibold text-bz-red-800">Cancel Appointment</span>
  ),
  iconElement: (
    <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-bz-red-200">
      <FontAwesomeIcon
        className="flex h-5 w-5 flex-col justify-center text-bz-red-800"
        icon={faBan}
      />
    </div>
  ),
}

const CompletedStep: AssignmentStatusStep = {
  index: 3,
  status: 'COMPLETED',
  label: 'Completed',
  assignmentIsActive: false,
  bgColorClassName: 'bg-[#52C41A]',
  transitionButtonLabel: 'Assignment Completed',
  unknownClockedInStatusDisposition: 'assume-pessimistic',
}

const InProgressStep: AssignmentStatusStep = {
  index: 2,
  status: 'IN_PROGRESS',
  label: 'In Progress',
  assignmentIsActive: true,
  nextStep: CompletedStep,
  bgColorClassName: 'bg-[#1890FF]',
  transitionButtonLabel: 'Mark Completed',
  unknownClockedInStatusDisposition: 'assume-optimistic',
}

const EnRouteStep: AssignmentStatusStep = {
  index: 1,
  status: 'EN_ROUTE',
  label: 'En Route',
  assignmentIsActive: true,
  nextStep: InProgressStep,
  bgColorClassName: 'bg-[#1890FF]',
  transitionButtonLabel: 'Mark In Progress',
  unknownClockedInStatusDisposition: 'assume-optimistic',
}

const ToDoStep: AssignmentStatusStep = {
  index: 0,
  status: 'TO_DO',
  label: 'To Do',
  assignmentIsActive: false,
  nextStep: EnRouteStep,
  bgColorClassName: 'bg-[#1890FF]',
  transitionButtonLabel: 'Mark En Route',
  unknownClockedInStatusDisposition: 'assume-pessimistic',
}

const AllSteps: Record<AssignmentStatus, AssignmentStatusStep> = {
  TO_DO: ToDoStep,
  EN_ROUTE: EnRouteStep,
  IN_PROGRESS: InProgressStep,
  COMPLETED: CompletedStep,
  CANCELED: CanceledStep,
}

export const AssignmentStatusSteps = ({
  assignmentStatus,
  apptGuid,
  apptAssignmentGuid,
  jobGuid,
  onMutate = noOp,
  markCompletedError,
}: AssignmentStatusStepsProps) => {
  const message = useMessage()
  const companyUser = usePrincipalUser().expectCompanyUserPrincipal()
  const isEndOfAppointmentWorkflowEnabledForCompany =
    useIsEndOfAppointmentWorkflowEnabledForCompany()
  const principalUserGuid = companyUser.userGuid
  const companyGuid = companyUser.company.companyGuid

  const [currentStep, setCurrentStep] = useState<AssignmentStatusStep>(
    AllSteps[assignmentStatus],
  )
  const [
    showConfirmSendNotificationToCustomer,
    setShowConfirmSendNotificationToCustomer,
  ] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const showStepModal = useCallback(() => setIsOpen(true), [setIsOpen])
  const hideStepModal = useCallback(() => setIsOpen(false), [setIsOpen])
  const [showMarkCompleteError, setShowMarkCompleteError] = useState(false)
  const [
    showEndOfAppointmentWorkflowModal,
    setShowEndOfAppointmentWorkflowModal,
  ] = useState(false)
  const [showNotClockedInModal, setShowNotClockedInModal] = useState<
    { open: false } | { open: true; callback: () => void }
  >({ open: false })

  const [
    endOfAppointmentWorkFlowComplete,
    setEndOfAppointmentWorkFlowComplete,
  ] = useState(false)

  const [isClockedInRes] = useSubscription({
    query: TECH_CLOCKED_IN_QUERY,
    variables: {
      companyGuid,
      userGuid: principalUserGuid,
    },
  })

  const [endOfAppointmentNextStepsRes] = useSubscription({
    query: FETCH_END_OF_APPOINTMENT_NEXT_STEPS_SUBSCRIPTION,
    variables: {
      jobAppointmentGuid: apptGuid,
    },
  })

  useEffect(() => {
    if (
      !isNullish(
        endOfAppointmentNextStepsRes.data?.jobAppointmentsByPk
          ?.endOfAppointmentNextSteps,
      ) &&
      !endOfAppointmentWorkFlowComplete
    ) {
      setEndOfAppointmentWorkFlowComplete(true)
    }
  }, [
    endOfAppointmentNextStepsRes.data?.jobAppointmentsByPk
      ?.endOfAppointmentNextSteps,
    endOfAppointmentWorkFlowComplete,
  ])

  const clockedInStatus = useMemo(() => {
    return isClockedInRes.data
      ? isClockedInRes.data.timesheetEntries.length > 0
        ? 'clocked-in'
        : 'not-clocked-in'
      : 'unknown'
  }, [isClockedInRes.data])

  const updateAssignmentStatusMutation =
    trpc.assignments['assignments:update-status'].useMutation()
  const clockInMutation =
    trpc.timesheets['timesheets:update-time-clock-status'].useMutation()

  const [
    { data: activeAppointmentAssignmentsForPrincipal },
    refetchActiveAppointmentAssignmentsForPrincipal,
  ] = useQuery({
    query: FETCH_ACTIVE_APPOINTMENT_ASSIGNMENTS_FOR_USER,
    variables: {
      userGuid: principalUserGuid,
      companyGuid,
    },
  })

  const principalHasAnotherActiveAssignment = useMemo(() => {
    const hasActiveAssignment =
      activeAppointmentAssignmentsForPrincipal?.jobAppointmentAssignments &&
      activeAppointmentAssignmentsForPrincipal?.jobAppointmentAssignments
        .length > 0

    if (hasActiveAssignment) {
      const activeAssignment =
        activeAppointmentAssignmentsForPrincipal?.jobAppointmentAssignments[0]

      return (
        activeAssignment.jobAppointmentAssignmentGuid !== apptAssignmentGuid
      )
    } else {
      return false
    }
  }, [
    activeAppointmentAssignmentsForPrincipal?.jobAppointmentAssignments,
    apptAssignmentGuid,
  ])

  const otherActiveAssignmentGuid = useMemo(() => {
    if (principalHasAnotherActiveAssignment) {
      return activeAppointmentAssignmentsForPrincipal
        ?.jobAppointmentAssignments[0].jobAppointmentGuid
    } else {
      return null
    }
  }, [
    activeAppointmentAssignmentsForPrincipal?.jobAppointmentAssignments,
    principalHasAnotherActiveAssignment,
  ])

  const updateAssignmentStatus = useCallback(
    (
      from: AssignmentStatus,
      to: AssignmentStatus,
      options?: { suppressEnRouteAccountNotification?: boolean },
    ) => {
      setCurrentStep(AllSteps[to])

      updateAssignmentStatusMutation.mutate(
        {
          apptAssignmentGuid,
          assignmentStatus: to,
          jobGuid,
          suppressEnRouteAccountNotification:
            options?.suppressEnRouteAccountNotification,
          apptGuid,
        },
        {
          onSuccess() {
            from === 'TO_DO' &&
            to === 'EN_ROUTE' &&
            options?.suppressEnRouteAccountNotification === false
              ? message.success(
                  'Customer has been notified that you are en route',
                )
              : message.success('Successfully updated assignment status')
            onMutate()
            refetchActiveAppointmentAssignmentsForPrincipal()
          },
          onError(e) {
            if (
              e?.shape?.message &&
              e.shape.message.match(
                TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX,
              )
            ) {
              const conflictingAppointmentGuid = e.shape.message.match(
                TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX,
              )?.[1]
              message.error(
                <span>
                  {
                    'Cannot update this assignment status because the technician already has '
                  }
                  <a href={`/appointments/${conflictingAppointmentGuid}`}>
                    an active assignment
                  </a>
                </span>,
              )
            } else {
              message.error('Failed to update assignment status')
            }

            // revert optimistically updated state
            setCurrentStep(AllSteps[from])
          },
        },
      )
    },
    [
      updateAssignmentStatusMutation,
      apptAssignmentGuid,
      jobGuid,
      apptGuid,
      message,
      onMutate,
      refetchActiveAppointmentAssignmentsForPrincipal,
    ],
  )

  const clockInAndChangeAssignment = useCallback(async () => {
    if (!showNotClockedInModal.open) {
      return
    }

    await clockInMutation.mutateAsync({})
    showNotClockedInModal.callback()
  }, [clockInMutation, showNotClockedInModal])

  const markNextStatusButtonDisabled = useMemo(
    () =>
      !!currentStep.nextStep?.assignmentIsActive &&
      principalHasAnotherActiveAssignment,
    [currentStep, principalHasAnotherActiveAssignment],
  )

  const changeStatusRequested = useCallback(
    (
      status: AssignmentStatus | undefined,
      endOfAppointmentWorkFlowCompleteOverride?: boolean,
    ) => {
      const effectiveEndOfAppointmentWorkFlowComplete = isNullish(
        endOfAppointmentWorkFlowCompleteOverride,
      )
        ? endOfAppointmentWorkFlowComplete
        : endOfAppointmentWorkFlowCompleteOverride

      if (!status) return

      const transitionToStep = AllSteps[status]
      if (
        transitionToStep.assignmentIsActive &&
        principalHasAnotherActiveAssignment
      ) {
        return
      }

      const originalStep = currentStep
      if (transitionToStep.status === 'COMPLETED' && markCompletedError) {
        hideStepModal()
        setShowMarkCompleteError(true)
        return
      }

      if (
        transitionToStep.status === 'COMPLETED' &&
        !effectiveEndOfAppointmentWorkFlowComplete &&
        isEndOfAppointmentWorkflowEnabledForCompany
      ) {
        hideStepModal()
        setShowEndOfAppointmentWorkflowModal(true)
        return
      }

      if (transitionToStep.status === 'EN_ROUTE') {
        hideStepModal()
        setShowConfirmSendNotificationToCustomer(true)
        return
      }

      // optimistically update
      setCurrentStep(transitionToStep)
      setShowEndOfAppointmentWorkflowModal(false)
      hideStepModal()

      updateAssignmentStatusMutation.mutate(
        {
          apptAssignmentGuid: apptAssignmentGuid,
          assignmentStatus: status,
          jobGuid,
          apptGuid,
        },
        {
          onSuccess() {
            message.success('Successfully updated assignment status')
            onMutate()
            refetchActiveAppointmentAssignmentsForPrincipal()
          },
          onError(e) {
            if (
              e?.shape?.message &&
              e.shape.message.match(
                TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX,
              )
            ) {
              const conflictingAppointmentGuid = e.shape.message.match(
                TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX,
              )?.[1]
              message.error(
                <span>
                  {
                    'Cannot update this assignment status because the technician already has '
                  }
                  <a href={`/appointments/${conflictingAppointmentGuid}`}>
                    an active assignment
                  </a>
                </span>,
              )
            } else {
              message.error('Failed to update assignment status')
            }
            // revert optimistically updated state
            setCurrentStep(originalStep)
          },
        },
      )
    },
    [
      apptAssignmentGuid,
      apptGuid,
      currentStep,
      endOfAppointmentWorkFlowComplete,
      hideStepModal,
      isEndOfAppointmentWorkflowEnabledForCompany,
      jobGuid,
      markCompletedError,
      message,
      onMutate,
      principalHasAnotherActiveAssignment,
      refetchActiveAppointmentAssignmentsForPrincipal,
      updateAssignmentStatusMutation,
    ],
  )

  const requireClockedIn = useCallback(
    (unknownDisposition: UnknownClockedInStatusDisposition, fn: () => void) => {
      if (
        clockedInStatus === 'not-clocked-in' ||
        (clockedInStatus === 'unknown' &&
          unknownDisposition === 'assume-pessimistic')
      ) {
        if (clockedInStatus === 'unknown') {
          console.warn(`Assuming user is not clocked in pessimistically`)
        }

        setShowNotClockedInModal({
          open: true,
          callback: fn,
        })
        return
      }

      fn()
    },
    [clockedInStatus, setShowNotClockedInModal],
  )

  const dropdownItems = useMemo(() => {
    return Object.values(AllSteps)
      .filter(step => !step.hideStep)
      .map((step, i) => (
        <OnsiteMenuItem
          key={step.status}
          disabled={
            (step.assignmentIsActive && principalHasAnotherActiveAssignment) ||
            currentStep.status === step.status
          }
          label={step.labelElement ?? step.label}
          onClick={() =>
            requireClockedIn(step.unknownClockedInStatusDisposition, () => {
              changeStatusRequested(step.status)
            })
          }
          icon={
            step.iconElement ?? (
              <span className="text-bz-900 text-lg font-semibold">
                {step.index}
              </span>
            )
          }
        />
      ))
  }, [
    principalHasAnotherActiveAssignment,
    currentStep,
    requireClockedIn,
    changeStatusRequested,
  ])

  const progressStep = useCallback(() => {
    requireClockedIn(currentStep.unknownClockedInStatusDisposition, () => {
      changeStatusRequested(currentStep.nextStep?.status)
    })
  }, [
    changeStatusRequested,
    currentStep.nextStep?.status,
    currentStep.unknownClockedInStatusDisposition,
    requireClockedIn,
  ])

  return (
    <div>
      {currentStep.nextStep && (
        <BzStepper
          steps={Object.values(AllSteps)
            .slice(0, 4)
            .map(item => item.label)}
          currentStep={currentStep.index}
          finishActionButtonText="Completed"
          goToStep={(_, __) => null}
          canProceed={() => false}
          canReverse={() => false}
          onFinish={() => null}
          hidePreviousNextButtons={true}
        />
      )}
      <div className="mt-3 flex w-full flex-row items-center gap-2 divide-x-[1px]">
        <Button
          className={classNames(
            'flex h-fit flex-1 items-center rounded-md rounded-r-none border-none py-2',
            { 'text-gray-300': markNextStatusButtonDisabled },
            currentStep.bgColorClassName,
          )}
          disabled={markNextStatusButtonDisabled}
          type="primary"
        >
          <div
            onClick={progressStep}
            className="flex w-full items-center justify-center space-x-2 font-semibold"
          >
            {currentStep.transitionButtonLabel}
          </div>
        </Button>
        <Button
          onClick={showStepModal}
          className={classNames(
            'h-[40px] w-[40px] items-center rounded-md border-none px-2 text-white',
            currentStep.bgColorClassName,
          )}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </Button>
      </div>
      {markNextStatusButtonDisabled && (
        <div className="mt-2 text-left text-bz-gray-1000">
          Note that you cannot start this appointment until your currently
          assigned appointment has been completed (or canceled).
          <Link to={`/appointments/${otherActiveAssignmentGuid}`}>
            {' Go to current appointment'}
          </Link>
        </div>
      )}
      <Modal
        title="Send Notification to Customer?"
        open={showConfirmSendNotificationToCustomer}
        onOk={() => {
          updateAssignmentStatus(currentStep.status, 'EN_ROUTE', {
            suppressEnRouteAccountNotification: false,
          })
          setShowConfirmSendNotificationToCustomer(false)
        }}
        onCancel={() => {
          updateAssignmentStatus(currentStep.status, 'EN_ROUTE', {
            suppressEnRouteAccountNotification: true,
          })
          setShowConfirmSendNotificationToCustomer(false)
        }}
        okText="Yes"
        cancelText="No"
        closable={false}
        maskClosable={false}
        wrapClassName={MODAL_Z_CLASSNAME}
        classNames={{
          mask: MODAL_Z_CLASSNAME,
        }}
      >
        Would you like the system notify the customer that you are on your way?
      </Modal>
      {markCompletedError ? (
        <Modal
          title="Cannot Mark Appointment as Complete"
          open={showMarkCompleteError}
          onOk={() => {
            setShowMarkCompleteError(false)
          }}
          onCancel={() => setShowMarkCompleteError(false)}
          closable
          // https://stackoverflow.com/a/59436045/2108612
          cancelButtonProps={{ style: { display: 'none' } }}
          wrapClassName={MODAL_Z_CLASSNAME}
          classNames={{
            mask: MODAL_Z_CLASSNAME,
          }}
        >
          {markCompletedError}
        </Modal>
      ) : null}
      {showEndOfAppointmentWorkflowModal && (
        <OnsiteBasicModal
          drawer
          header="Next Steps"
          open
          onClose={() => setShowEndOfAppointmentWorkflowModal(false)}
          size="large"
        >
          <EndOfAppointmentNextStepsForm
            appointmentGuid={apptGuid}
            onCancel={() => setShowEndOfAppointmentWorkflowModal(false)}
            onSuccess={() => {
              setShowEndOfAppointmentWorkflowModal(false)
              changeStatusRequested('COMPLETED', true)
            }}
          />
        </OnsiteBasicModal>
      )}

      <AssignmentStatusStepsClockInModal
        open={showNotClockedInModal.open}
        isLoading={clockInMutation.isLoading}
        onClockInClicked={async () => {
          await clockInAndChangeAssignment()
          setShowNotClockedInModal({ open: false })
        }}
        onCancelClicked={() => setShowNotClockedInModal({ open: false })}
      />
      {isOpen && (
        <OnsiteBasicModal
          open={isOpen}
          onClose={hideStepModal}
          header="Change status"
          headerBordered
          children={<div className="flex flex-col gap-4">{dropdownItems}</div>}
        />
      )}
    </div>
  )
}
