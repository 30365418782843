import {
  BackButtonOverrideResolver,
  HistoryStackLocation,
  getUrlFromHistoryStackLocation,
} from './BackButtonOverridesWrapper'

const isMaintenancePlanWizardPage = (location: HistoryStackLocation) =>
  getUrlFromHistoryStackLocation(location).searchParams.has('mpw') ||
  getUrlFromHistoryStackLocation(location).searchParams.has('globalMpw') ||
  getUrlFromHistoryStackLocation(location).searchParams.has('importPlan')

export const maintenancePlanWizardBackButtonOverrideResolver: BackButtonOverrideResolver =
  (previousPage, historyStack) => {
    const newPage = historyStack[historyStack.length - 1]

    // If we landed on a page with the MaintenancePlanWizard flag...
    if (isMaintenancePlanWizardPage(newPage)) {
      let count = 2

      // We want to skip all the wizard pages that we've landed on.
      while (
        isMaintenancePlanWizardPage(historyStack[historyStack.length - count])
      ) {
        count++
      }
      // The count includes the page that we checked that WASN'T a MaintenancePlanWizard page. We need to do -1 because we DON'T want to skip that one.
      return count - 1
    }

    return 0
  }
