import {
  AbridgedFinancingInvoice,
  BzDateFns,
  TimeZoneId,
  formatMoney,
  isFinanceable,
} from '@breezy/shared'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDescription, useTsController } from '@ts-react/form'
import { Form, Select, Tooltip } from 'antd'
import React from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../../../providers/PrincipalUser'

type InvoiceSelectionFieldProps = {
  invoices: AbridgedFinancingInvoice[]
}

const formatInvoiceOption = (
  invoice: AbridgedFinancingInvoice,
  tzId: TimeZoneId,
) => {
  const displayId = invoice.displayId
  const formattedPrice = formatMoney(invoice.totalAmountUsd ?? 0)
  const formattedDate = invoice.issuedAt
    ? BzDateFns.format(
        BzDateFns.parseISO(invoice.issuedAt, tzId),
        'MMM. d, yyyy',
      )
    : ''

  return `Invoice #${displayId} (${formattedPrice}) - ${formattedDate}`
}

const InvoiceSelectionField = React.memo<InvoiceSelectionFieldProps>(
  ({ invoices }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const {
      field: { onChange, value },
      error,
    } = useTsController<string>()

    const { label, placeholder } = useDescription()

    return (
      <Form.Item
        label={
          <span className="flex flex-row items-center gap-1">
            {label}
            <Tooltip
              title={
                'Invoices can only be financed if they are between $500 and $25,000'
              }
            >
              <FontAwesomeIcon icon={faCircleQuestion} />
            </Tooltip>
          </span>
        }
        key={`form-item-${value}`}
        validateStatus={error ? 'error' : 'success'}
        help={error?.errorMessage}
      >
        <Select
          placeholder={placeholder}
          allowClear={false}
          value={value}
          key={value}
          onChange={(value: string) => onChange(value)}
          className="w-full"
        >
          {invoices.map(invoice => {
            return (
              <Select.Option
                key={invoice.invoiceGuid}
                value={invoice.invoiceGuid}
                disabled={!isFinanceable(invoice)}
              >
                {formatInvoiceOption(invoice, tzId)}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    )
  },
)

export default InvoiceSelectionField
