import {
  LoanChangedStatus,
  LoanRecord,
  WisetackSampleLoanApplicationEvent,
  bzExpect,
  createAuthorizedLoanRecordStatusWebhook,
  createCanceledLoanRecordStatusWebhook,
  createDeclinedLoanRecordStatusWebhook,
  createInitiatedLoanRecordStatusWebhook,
  createLoanConfirmedLoanRecordStatusWebhook,
  createLoanTermsAcceptedLoanRecordStatusWebhook,
  createPartiallyApprovedLoanRecordStatusWebhook,
  createSettledLoanRecordStatusWebhook,
  isNullish,
  usdToUsCents,
} from '@breezy/shared'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { getConfig } from '../../../config'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'

const config = getConfig()

export type WisetackWebhookTestMutation = {
  loanRecord: LoanRecord
  changedStatus: LoanChangedStatus
  partialAmountUsd?: number
  onSuccess?: () => void
}

export const useWisetackWebhookTestMutation = () => {
  const tzId = useExpectedCompanyTimeZoneId()

  const callWisetackWebhook = async (
    data: WisetackSampleLoanApplicationEvent,
  ) => {
    const response = await fetch(
      `${config.apiUrl}/webhooks/wisetack-transaction`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )

    // The webhook is async and returns a 200 response immediately, so
    // we add an artificial delay here to give the live query time
    // to update the UI with the updated loan record status.
    await new Promise(resolve => setTimeout(resolve, 1000))

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    return 'Webhook called successfully'
  }

  const wisetackWebhookMutation = useMutation(callWisetackWebhook, {
    onSuccess: _ => {},
    onError: error => {
      console.error('Error generating test data:', error)
    },
  })

  const loading = wisetackWebhookMutation.isLoading

  const triggerWisetackWebhook = useCallback(
    ({
      loanRecord,
      changedStatus,
      partialAmountUsd,
    }: WisetackWebhookTestMutation) => {
      let data: WisetackSampleLoanApplicationEvent | undefined
      switch (changedStatus) {
        case 'INITIATED':
          data = createInitiatedLoanRecordStatusWebhook(loanRecord, tzId)
          break
        case 'AUTHORIZED':
          if (!isNullish(partialAmountUsd)) {
            data = createPartiallyApprovedLoanRecordStatusWebhook(
              loanRecord,
              usdToUsCents(partialAmountUsd),
              tzId,
            )
          } else {
            data = createAuthorizedLoanRecordStatusWebhook(loanRecord, tzId)
          }
          break
        case 'LOAN_TERMS_ACCEPTED':
          data = createLoanTermsAcceptedLoanRecordStatusWebhook(
            loanRecord,
            tzId,
          )
          break
        case 'LOAN_CONFIRMED':
          data = createLoanConfirmedLoanRecordStatusWebhook(loanRecord, tzId)
          break
        case 'SETTLED':
          data = createSettledLoanRecordStatusWebhook(loanRecord, tzId)
          break
        case 'DECLINED':
          data = createDeclinedLoanRecordStatusWebhook(loanRecord, tzId)
          break
        case 'CANCELED':
          data = createCanceledLoanRecordStatusWebhook(loanRecord, tzId)
          break
      }

      return wisetackWebhookMutation.mutate(bzExpect(data))
    },
    [tzId, wisetackWebhookMutation],
  )

  return {
    triggerWisetackWebhook,
    loading,
  }
}
