import { AsyncFn, MissingCaseError, isNullish } from '../../common'
import { CompanyGuid } from '../Company/Company'
import { Person } from '../Person'
import { Role, RoleId } from '../Role'
import { SimpleContactInfo } from '../contacts/types'
import { SchedulingCapability } from './User'

export interface Technician {
  user: Person
  contact: SimpleContactInfo
  roles: TechnicianRole[]
  schedulingCapability: SchedulingCapability
}

export enum TechnicianRole {
  INSTALL_TECHNICIAN = 'INSTALL_TECHNICIAN',
  MAINTENANCE_TECHNICIAN = 'MAINTENANCE_TECHNICIAN',
  SERVICE_TECHNICIAN = 'SERVICE_TECHNICIAN',
  SALES_TECHNICIAN = 'SALES_TECHNICIAN',
}

export const toTechnicianRoleDisplayName = (role: TechnicianRole): string => {
  switch (role) {
    case TechnicianRole.INSTALL_TECHNICIAN:
      return 'Install Tech'
    case TechnicianRole.MAINTENANCE_TECHNICIAN:
      return 'Maintenance Tech'
    case TechnicianRole.SERVICE_TECHNICIAN:
      return 'Service Tech'
    case TechnicianRole.SALES_TECHNICIAN:
      return 'Sales Tech'
    default:
      return 'Unknown'
  }
}

export const toTechnicianRoleShortName = (role: TechnicianRole): string => {
  switch (role) {
    case TechnicianRole.INSTALL_TECHNICIAN:
      return 'Install'
    case TechnicianRole.MAINTENANCE_TECHNICIAN:
      return 'Maint.'
    case TechnicianRole.SERVICE_TECHNICIAN:
      return 'Service'
    case TechnicianRole.SALES_TECHNICIAN:
      return 'Sales'
    default:
      return 'Unknown'
  }
}

export const isTechnicianRole = (roleId: RoleId): boolean =>
  roleId === RoleId.INSTALL_TECHNICIAN ||
  roleId === RoleId.MAINTENANCE_TECHNICIAN ||
  roleId === RoleId.SERVICE_TECHNICIAN ||
  roleId === RoleId.SALES_TECHNICIAN

export const hasTechnicianRole = (roleIds: RoleId[]): boolean => roleIds.some(isTechnicianRole)

export const getTechnicianRolesFromUserRoles = (userRoles: readonly Role[]): TechnicianRole[] =>
  userRoles
    .filter(r => isTechnicianRole(r.role))
    .map(r => {
      switch (r.role) {
        case RoleId.INSTALL_TECHNICIAN:
          return TechnicianRole.INSTALL_TECHNICIAN
        case RoleId.MAINTENANCE_TECHNICIAN:
          return TechnicianRole.MAINTENANCE_TECHNICIAN
        case RoleId.SERVICE_TECHNICIAN:
          return TechnicianRole.SERVICE_TECHNICIAN
        case RoleId.SALES_TECHNICIAN:
          return TechnicianRole.SALES_TECHNICIAN
        default:
          throw new MissingCaseError('Unexpected Role')
      }
    })
    .filter((r): r is TechnicianRole => !isNullish(r))

export type CompanyTechniciansReader = AsyncFn<CompanyGuid, Technician[]>
