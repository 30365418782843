import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { ForCompany } from '../Company/Company'
import { Guid, bzOptional } from '../common-schemas'

export type MigrationJunk = {
  migrationJunkGuid: Guid
  junk: Record<string, unknown>
  uiLabel: string | null
}

export const migrationJunkReaderRequestSchema = z
  .object({
    accountGuid: bzOptional(guidSchema),
    locationGuid: bzOptional(guidSchema),
    jobGuid: bzOptional(guidSchema),
  })
  .refine(val => val.accountGuid || val.locationGuid || val.jobGuid, {
    message: 'One of accountGuid, locationGuid, or jobGuid must be set',
  })

export type MigrationJunkReaderRequest = z.infer<typeof migrationJunkReaderRequestSchema>

export type MigrationJunkReader = AsyncFn<ForCompany<MigrationJunkReaderRequest>, MigrationJunk | undefined>
