import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import cn from 'classnames'
import React, { ReactNode, useMemo } from 'react'
import { useIsSmallScreen } from '../../hooks/useIsMobile'
import { m } from '../../utils/react-utils'

export type BzStepperProps = {
  steps: readonly string[]
  currentStep: number
  finishActionButtonText: string
  goToStep: (fromStep: number, toStep: number) => void
  canProceed: () => boolean
  /** This prevents the Next Button from even being activated */
  cannotProceedReason?: string
  /** This does not prevent the Next Button from being activated, but does show a recent issue */
  lastError?: string
  canReverse: () => boolean
  children?: ReactNode
  onFinish: () => void
  hideSteps?: boolean
  hidePreviousNextButtons?: boolean
  creationModal?: boolean
  hideBackButtonIcon?: boolean
}

const getBackgroundAndTextColorForStepCircle = (
  index: number,
  currentStep: number,
) => {
  if (index < currentStep) {
    return 'bg-[#52C41A] text-white'
  } else if (index === currentStep) {
    return 'bg-[#1890FF] text-white'
  } else {
    return 'text-[#8C8C8C] border border-[#8C8C8C] border-solid'
  }
}

const getBackgroundColorForSeparationLine = (
  index: number,
  currentStep: number,
) => {
  if (index < currentStep) {
    return 'bg-[#1890FF]'
  } else {
    return 'bg-[#D9D9D9]'
  }
}

type BzStepsProps = {
  steps: readonly string[]
  currentStep: number
  creationModal?: boolean
  className?: string
}

export const BzSteps = React.memo<BzStepsProps>(
  ({ steps, currentStep, creationModal, className }) => {
    return (
      <div
        className={cn(
          'flex-between flex w-full flex-row justify-between',
          className,
        )}
      >
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className="mx-1 flex flex-1 flex-col items-center">
              <div
                className={`flex h-6 w-6 items-center justify-center rounded-full ${getBackgroundAndTextColorForStepCircle(
                  index,
                  currentStep,
                )}`}
              >
                <div className="text-base">
                  {index < currentStep ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    index + 1
                  )}
                </div>
              </div>
              <div className="whitespace-nowrap text-sm">{step}</div>
            </div>
            {index < steps.length - 1 && (
              <div className="center-vh flex flex-auto flex-col items-center">
                <div
                  className={`h-[1px] ${creationModal} ${getBackgroundColorForSeparationLine(
                    index,
                    currentStep,
                  )} w-full overflow-hidden`}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  },
)

export const BzStepper = m<BzStepperProps>(
  ({
    steps,
    finishActionButtonText,
    currentStep,
    goToStep,
    canProceed,
    cannotProceedReason,
    canReverse,
    children,
    onFinish,
    hideSteps = false,
    hidePreviousNextButtons = false,
    creationModal,
    lastError,
  }) => {
    const isSmallScreen = useIsSmallScreen()

    const nextButton = useMemo(() => {
      const cannotProceed = !canProceed()

      const button = (
        <Button
          type="primary"
          block={isSmallScreen}
          size={isSmallScreen ? 'large' : 'middle'}
          onClick={() =>
            currentStep >= steps.length - 1
              ? onFinish()
              : goToStep(currentStep, currentStep + 1)
          }
          disabled={cannotProceed}
        >
          {currentStep >= steps.length - 1 ? finishActionButtonText : 'Next'}
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Button>
      )
      if (cannotProceedReason) {
        return <Tooltip title={cannotProceedReason}>{button}</Tooltip>
      }
      return button
    }, [
      canProceed,
      cannotProceedReason,
      currentStep,
      finishActionButtonText,
      goToStep,
      isSmallScreen,
      onFinish,
      steps.length,
    ])

    return (
      <>
        {!hideSteps && (
          <BzSteps
            steps={steps}
            currentStep={currentStep}
            creationModal={creationModal}
          />
        )}
        {children}
        {lastError && <div className="text-red-500">{lastError}</div>}
        {!hidePreviousNextButtons && (
          <div
            className={cn('pb-4 pt-9', {
              'space-between flex': !isSmallScreen,
              'flex flex-row justify-end space-x-2': isSmallScreen,
            })}
          >
            <Button
              block={isSmallScreen}
              onClick={() => goToStep(currentStep, currentStep - 1)}
              size={isSmallScreen ? 'large' : 'middle'}
              disabled={!canReverse()}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Previous
            </Button>
            {nextButton}
          </div>
        )}
      </>
    )
  },
)
