import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import { Composable } from '../../utils/Composable'
import { m } from '../../utils/react-utils'

export type GlobalSearchContextProps = {
  globalSearchVisible: boolean
  setGlobalSearchVisible: (isVisible: boolean) => void
}

export const GlobalSearchContext = createContext({} as GlobalSearchContextProps)
export const useGlobalSearch = () => useContext(GlobalSearchContext)

export const GlobalSearchWrapper = m<Composable>(({ children }) => {
  const [globalSearchVisible, setGlobalSearchVisible] = useState(false)

  const toggleGlobalSearch = useCallback(() => {
    setGlobalSearchVisible(isVisible => !isVisible)
  }, [setGlobalSearchVisible])

  useLayoutEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        toggleGlobalSearch()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [toggleGlobalSearch])

  return (
    <GlobalSearchContext.Provider
      value={{ globalSearchVisible, setGlobalSearchVisible }}
    >
      {children}
    </GlobalSearchContext.Provider>
  )
})
