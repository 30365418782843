import { Row } from 'antd'
import { getConfig } from '../../../config'
import { dayBreakBlue2 } from '../../../themes/theme'

const TilledSandboxCcInfo = () => {
  const isSandbox = getConfig().tilled.useSandbox

  return (
    <>
      {isSandbox && (
        <div className="column space-y-4">
          <div
            className="card-no-fixed-height center-h w-[320px] max-w-[500px]"
            style={{ backgroundColor: dayBreakBlue2 }}
          >
            <h2 className="grey9 m-0 mb-4">Sandbox - Visa</h2>
            <p className="grey9 m-0">
              CC: <b>4111 1111 1111 1111</b>
            </p>
            <Row className="center-h">
              <p className="grey9 m-0">
                Exp: <b>08/26</b>
              </p>
              <p className="grey9 m-0 ml-8">
                CVV: <b>111</b>
              </p>
            </Row>
          </div>
          <div
            className="card-no-fixed-height center-h w-[320px] max-w-[500px]"
            style={{ backgroundColor: dayBreakBlue2 }}
          >
            <h2 className="grey9 m-0 mb-4">Sandbox - Mastercard</h2>
            <p className="grey9 m-0">
              CC: <b>5100 4000 0000 0000</b>
            </p>
            <Row className="center-h">
              <p className="grey9 m-0">
                Exp: <b>08/26</b>
              </p>
              <p className="grey9 m-0 ml-8">
                CVV: <b>111</b>
              </p>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

export default TilledSandboxCcInfo
