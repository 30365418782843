import { PhotoRecordWithLinks } from '@breezy/shared'
import { faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, Tooltip } from 'antd'
import { useMemo, useState } from 'react'
import { trpc } from '../../hooks/trpc'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import DangerConfirmModal from '../DangerConfirmModal/DangerConfirmModal'
import { PhotoNotesListCard } from '../PhotoNotesListCard.tsx/PhotoNotesListCard'

export type PhotoDetailDrawerProps = {
  photo: PhotoRecordWithLinks
  onClose: () => void
  onDelete: () => void
  editable?: boolean
}

// TODO: Tech Debt - This is a slightly modified duplicate of the PhotoDetailDrawer in the Technician folder. We should consolidate these two components.
export const PhotoDetailDrawer = ({
  photo,
  onClose,
  onDelete,
  editable = true,
}: PhotoDetailDrawerProps) => {
  const message = useMessage()
  const photosRemoveMutation = trpc.photos['photos:remove'].useMutation()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const user = useExpectedPrincipal()

  const createdByUser = useMemo(() => {
    return user.userGuid === photo.createdByUserGuid
  }, [user, photo.createdByUserGuid])

  const canDelete = useMemo(() => {
    return editable || createdByUser
  }, [editable, createdByUser])

  const deletePhoto = () => {
    photosRemoveMutation.mutate(
      {
        photoGuid: photo.photoGuid,
      },
      {
        onSuccess: () => {
          message.success('Photo deleted')
          onDelete()
        },
      },
    )
  }

  return (
    <div className="w-full">
      <Drawer
        title={'Photo'}
        onClose={onClose}
        open={!!photo}
        width={720}
        styles={{ body: { paddingBottom: 80 } }}
        destroyOnClose
      >
        <div className="flex w-full flex-col space-y-2">
          <div className="flex w-full justify-center">
            <div className="w-full max-w-[70%]">
              <img
                src={photo.cdnUrl}
                className="object-fit rounded-xl"
                alt="Current to remove or add notes to"
              />
            </div>
          </div>

          <PhotoNotesListCard photoGuid={photo.photoGuid} editable={editable} />

          {editable && canDelete ? (
            <Button
              type="primary"
              danger
              className="full-width my-1 rounded"
              onClick={() => setIsConfirmModalOpen(true)}
            >
              <FontAwesomeIcon icon={faTrashCan} style={{ marginRight: 8 }} />
              Delete Photo
            </Button>
          ) : (
            <Tooltip trigger="hover" title="Linked photos cannot be deleted">
              <Button
                type="primary"
                danger
                className="full-width my-1 rounded"
                onClick={() => setIsConfirmModalOpen(true)}
                disabled
              >
                <FontAwesomeIcon icon={faTrashCan} style={{ marginRight: 8 }} />
                Delete Photo
              </Button>
            </Tooltip>
          )}
        </div>
      </Drawer>

      <DangerConfirmModal
        title="Are you sure?"
        open={isConfirmModalOpen && editable && canDelete}
        onOk={deletePhoto}
        onCancel={() => setIsConfirmModalOpen(false)}
        promptText="This will permanently delete the selected photo!"
      />
    </div>
  )
}
