import { useCallback, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import { FinancingOption } from '@breezy/shared'
import { CancelProps } from '../../../elements/BzDrawer/BzDrawer'
import {
  FinancingWizard,
  FinancingWizardMetadata,
} from '../FinancingWizardDrawer'
type UseFinancingWizardProps = FinancingWizardMetadata &
  Partial<CancelProps> & {
    financingOption?: FinancingOption
  }

export const useFinancingWizard = (input: UseFinancingWizardProps) => {
  const [financingMetadata, setFinancingMetadata] = useState<
    (FinancingWizardMetadata & CancelProps) | undefined
  >()

  const closeModal = useCallback(() => {
    setFinancingMetadata(undefined)
    input.onCancel?.()
  }, [input])

  const showFinancingWizard = useCallback(() => {
    setFinancingMetadata({
      ...input,
      onCancel: closeModal,
    })
  }, [input, closeModal])

  const wizardContent = useMemo(() => {
    if (!financingMetadata) {
      return null
    }

    return (
      <FinancingWizard
        {...financingMetadata}
        financingOption={input.financingOption}
      />
    )
  }, [financingMetadata, input.financingOption])

  const financingWizard = useMemo(() => {
    if (!wizardContent) {
      return null
    }

    return ReactDOM.createPortal(wizardContent, document.body)
  }, [wizardContent])

  return {
    showFinancingWizard,
    financingWizard,
  }
}
