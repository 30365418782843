import { z } from 'zod'
import { AsyncFn } from '../../common'
import { SearchableEntity } from './SearchableEntity'
import { SearchableRecord, searchableRecordSchema } from './SearchableRecord'

export const searchResultsSchema = searchableRecordSchema.array()
export type SearchResults = z.infer<typeof searchResultsSchema>

/**
 * Writes new records to the search service.
 */
export type SearchableEntityWriter = AsyncFn<SearchableEntity[], SearchableRecord[]>

/**
 * Deletes existing records from the search service
 */
export type SearchableEntityDeleter = AsyncFn<string[]>
