import {
  Contact,
  LOCATION_CONTACT_RELATION_TYPE_MAP,
  LocationContact,
  LocationContactRelationType,
  NotificationPreferenceType,
  nextGuid,
  phoneUtils,
} from '@breezy/shared'
import { Divider, Form, Radio, Select } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { useState } from 'react'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { ContactRootFormSchema } from '../../utils/ContactFormTypes'
import { useMessage } from '../../utils/antd-utils'
import { CompanyContactAutocomplete } from '../CompanyContactAutocomplete/CompanyContactAutocomplete'
import { PhoneFormItem } from '../Phones/PhoneFormItem/PhoneFormItem'
import { EmailAddressFormItem } from '../form-fields/EmailAddressFormItem/EmailAddressFormItem'
import { FirstNameField } from '../form-fields/FirstNameField/FirstNameField'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { LastNameField } from '../form-fields/LastNameField/LastNameField'
import { NotificationPreferenceTypeFormItem } from '../form-fields/NotificationPreferenceSelectorItem/NotificationPreferenceSelectorItem'
import { LocationContactTag } from './LocationContactTag'

export type UpsertLocationContactFormSchema = ContactRootFormSchema & {
  locationContactRelationshipType: LocationContactRelationType[]
  notes: string
}

export type LocationContactUpsertFormProps = {
  locationGuid: string
  existingLocationContact?: LocationContact
  onCancel: () => void
  onMutate: () => void
  labelClassName?: string
  flexRowSpaceX?: string
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  showDivider?: boolean
}

const CONTACT_STRATEGIES = ['new', 'existing'] as const
type CONTACT_STRATEGY = (typeof CONTACT_STRATEGIES)[number]
const defaultContactStrategy: CONTACT_STRATEGY = 'existing'

const mapContactToContactFormFields = (
  contact?: Contact | null,
): ContactRootFormSchema => {
  return {
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    phoneNumber: contact?.primaryPhoneNumber?.phoneNumber ?? '',
    phoneNumberType: contact?.primaryPhoneNumber?.type ?? 'MOBILE',
    emailAddress: contact?.primaryEmailAddress?.emailAddress ?? '',
    notificationPreference:
      contact?.notificationPreferenceType ?? NotificationPreferenceType.Sms,
  }
}

export const LocationContactUpsertForm = ({
  locationGuid,
  onCancel,
  existingLocationContact,
  onMutate,
  labelClassName,
  flexRowSpaceX = 'space-x-2',
  justifyFormCancelSubmitButtons,
  showDivider = false,
}: LocationContactUpsertFormProps) => {
  const message = useMessage()
  const companyGuid = useExpectedCompany().companyGuid
  const [contactStrategy, setContactStrategy] = useState<CONTACT_STRATEGY>(
    defaultContactStrategy,
  )
  const [autocompleteSelectedContact, setAutocompleteSelectedContact] =
    useState<Contact | null>()

  const [form] = useForm<UpsertLocationContactFormSchema>()
  const phoneNumberValue = useWatch('phoneNumber', form)

  const [initialValues] = useState<UpsertLocationContactFormSchema>(() => {
    return {
      locationContactRelationshipType:
        existingLocationContact?.locationContactRelationTypes ?? [],
      notes: existingLocationContact?.notes ?? '',
      ...mapContactToContactFormFields(existingLocationContact?.contact),
    }
  })

  const upsertLocationContactMutation =
    trpc.location['location-contacts:upsert'].useMutation()

  const shouldShowContactFormInputs =
    existingLocationContact ||
    contactStrategy === 'new' ||
    (contactStrategy === 'existing' && autocompleteSelectedContact)

  const onFormSubmit = async (values: UpsertLocationContactFormSchema) => {
    const effectiveContact =
      existingLocationContact?.contact ?? autocompleteSelectedContact

    upsertLocationContactMutation.mutate(
      {
        locationGuid,
        locationContactRelationTypes: values.locationContactRelationshipType,
        notes: values.notes,
        contact: {
          ...(effectiveContact ?? {}),
          contactGuid: effectiveContact?.contactGuid ?? nextGuid(),
          firstName: values.firstName,
          lastName: values.lastName,
          primaryPhoneNumber: {
            phoneNumberGuid:
              effectiveContact?.primaryPhoneNumber?.phoneNumberGuid ??
              nextGuid(),
            companyGuid,
            phoneNumber: values.phoneNumber,
            type: values.phoneNumberType,
          },
          primaryEmailAddress: {
            emailAddressGuid:
              effectiveContact?.primaryEmailAddress?.emailAddressGuid ??
              nextGuid(),
            companyGuid,
            emailAddress: values.emailAddress,
          },
          notificationPreferenceType: values.notificationPreference,
        },
      },
      {
        onSuccess(data, variables, context) {
          message.success(
            `Successfully ${
              existingLocationContact ? 'updated' : 'created'
            } Location Contact`,
          )
          onMutate()
        },
        onError(error, variables, context) {
          message.success(
            `Failed to ${
              existingLocationContact ? 'update' : 'create'
            } Location Contact`,
          )
        },
      },
    )
  }

  const notificationPreferenceValue = useWatch('notificationPreference', form)

  return (
    <div className="w-full p-2 sm:p-0">
      <Form
        form={form}
        layout="vertical"
        validateTrigger="onBlur"
        onFinish={onFormSubmit}
        initialValues={initialValues}
      >
        <div
          className={`mt-3 flex  w-full flex-col sm:flex-row ${flexRowSpaceX}`}
        >
          <div className="w-full">
            <div className="semibold_16_24 grey9 mb-3">Contact</div>
            {!existingLocationContact && (
              <>
                <Radio.Group
                  size="small"
                  options={[
                    {
                      label: 'Link to Existing Contact',
                      value: 'existing',
                    },
                    { label: 'Create New Contact', value: 'new' },
                  ]}
                  onChange={e => {
                    setContactStrategy(e.target.value)
                    setAutocompleteSelectedContact(null)
                    form.setFieldsValue(mapContactToContactFormFields(null))
                  }}
                  value={contactStrategy}
                  optionType="button"
                  buttonStyle="solid"
                />
                {contactStrategy === 'existing' && (
                  <div className="mt-5">
                    <Form.Item
                      required
                      label="Search for Contact"
                      name="existingContact"
                      className={labelClassName}
                      // rules={[
                      //   {
                      //     required: true,
                      //   },
                      // ]}
                    >
                      <CompanyContactAutocomplete
                        onContactUpdated={contact => {
                          form.setFieldsValue(
                            mapContactToContactFormFields(contact),
                          )
                          setAutocompleteSelectedContact(contact)
                        }}
                        onInputChange={() => {
                          setAutocompleteSelectedContact(null)
                        }}
                      />
                    </Form.Item>
                  </div>
                )}
              </>
            )}
            {shouldShowContactFormInputs && (
              <div className="mt-2">
                <div className={`flex ${flexRowSpaceX}`}>
                  <div className="w-full">
                    <FirstNameField labelClassName={labelClassName} />
                  </div>
                  <div className="w-full">
                    <LastNameField labelClassName={labelClassName} />
                  </div>
                </div>
                <div className={`flex ${flexRowSpaceX}`}>
                  <div className="w-full">
                    <PhoneFormItem
                      enabledSmsCapableControl={!!phoneNumberValue}
                      onBlur={e =>
                        form.setFieldsValue({
                          phoneNumber: phoneUtils.tryFormat(e.target.value),
                        })
                      }
                      labelClassName={labelClassName}
                      required
                    />
                  </div>
                </div>
                <div className={`flex ${flexRowSpaceX}`}>
                  <div className="w-1/2">
                    <EmailAddressFormItem
                      required
                      labelClassName={labelClassName}
                    />
                  </div>
                </div>
                <div className={`flex ${flexRowSpaceX}`}>
                  <div className="w-1/2">
                    <NotificationPreferenceTypeFormItem
                      value={notificationPreferenceValue}
                      onChange={value => {
                        form.setFieldsValue({ notificationPreference: value })
                      }}
                      labelClassName={labelClassName}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`flex w-full flex-col sm:flex-row ${flexRowSpaceX}`}>
          <div className="mt-3 w-full">
            <Form.Item
              name="locationContactRelationshipType"
              label="Relationship"
              className={labelClassName}
            >
              <Select mode="multiple" placeholder="Relationship">
                {Object.entries(LOCATION_CONTACT_RELATION_TYPE_MAP).map(
                  ([type, definition]) => {
                    return (
                      <Select.Option key={type} value={type}>
                        <div className="inline">
                          <LocationContactTag
                            locationContactRelationType={
                              type as LocationContactRelationType
                            }
                          />
                        </div>
                      </Select.Option>
                    )
                  },
                )}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={`flex w-full flex-col sm:flex-row ${flexRowSpaceX}`}>
          <div className="mt-3 w-full">
            <Form.Item name="notes" label="Notes" className={labelClassName}>
              <TextArea
                allowClear
                rows={5}
                placeholder="Optionally add a note about the relationship "
              />
            </Form.Item>
          </div>
        </div>
        {showDivider && <Divider />}
        <FormCancelSubmitButtons
          onCancel={onCancel}
          justify={justifyFormCancelSubmitButtons}
        />
      </Form>
    </div>
  )
}
