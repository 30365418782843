import { createTsForm } from '@ts-react/form'
import { FormComponent } from '@ts-react/form/lib/src/createSchemaForm'

import { z } from 'zod'
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField'
import { PhoneNumberSchema } from '../PhoneNumberField/PhoneNumberField.schema'
import SmsMessageTextArea from '../SmsMessageTextArea/SmsMessageTextArea'
import { SmsMessageTextAreaSchema } from '../SmsMessageTextArea/SmsMessageTextArea.schema'
import { SendAsSmsFormSchema } from './SendSmsForm.schema'

export type SendAsSmsFormData = z.infer<typeof SendAsSmsFormSchema>
const sendSmsFieldMapping = [
  [SmsMessageTextAreaSchema, SmsMessageTextArea],
  [PhoneNumberSchema, PhoneNumberField],
] as const

export const createSendSmsForm = (form: FormComponent) =>
  createTsForm(sendSmsFieldMapping, {
    FormComponent: form,
  })
