import {
  AssignableApptViewModel,
  BzAddress,
  JobClass,
  formatTechnicianCapacityBlockReasonType,
} from '@breezy/shared'
import {
  faCalendar,
  faCircleQuestion,
  faScrewdriverWrench,
  faShield,
  faTrash,
  faWrench,
} from '@fortawesome/pro-light-svg-icons'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popconfirm } from 'antd'
import cn from 'classnames'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { TechnicianResource } from '../../components/Scheduler/scheduleUtils'
import { StackedAvatars } from '../../components/StackedAvatars/StackedAvatars'
import { useCanManageSchedule } from '../../hooks/permission/useCanManageSchedule'
import { stopPropagation } from '../../utils/react-utils'
import {
  BlockCalendarEvent,
  fixMbscDate,
} from '../ScheduleV2Page/scheduleUtils'
import {
  RecurringChangeModal,
  RecurringChangeType,
} from './RecurringChangeModal'
import { ScheduleContext } from './ScheduleContext'
import { useSchedulePendingChanges } from './SchedulePendingChangesContext'
import { changeRRuleUntil } from './utils'

const JOB_CLASS_DISPLAY_INFO = {
  [JobClass.INSTALL]: {
    icon: faScrewdriverWrench,
  },
  [JobClass.ESTIMATE_REPAIR]: {
    icon: faCircleQuestion,
  },
  [JobClass.ESTIMATE_REPLACE]: {
    icon: faCircleQuestion,
  },
  [JobClass.MAINTENANCE]: {
    icon: faWrench,
  },
  [JobClass.SERVICE]: {
    icon: faWrench,
  },
  [JobClass.WARRANTY]: {
    icon: faShield,
  },
  [JobClass.CALLBACK]: {
    icon: faShield,
  },
  [JobClass.UNKNOWN]: {
    icon: faShield,
  },
  [JobClass.SALES]: {
    icon: faDollarSign,
  },
} satisfies Record<JobClass, unknown>

type ScheduleEventContentProps = {
  icon: React.ReactNode
  content: React.ReactNode
  techs?: TechnicianResource[]
  onDelete: () => void
  onAvatarClick?: () => void
}

const ScheduleEventContent = React.memo<ScheduleEventContentProps>(
  ({ icon, content, techs, onDelete, onAvatarClick }) => {
    const { scheduleView } = useContext(ScheduleContext)
    const canManageSchedule = useCanManageSchedule()
    return (
      <div
        className={cn(
          'relative w-full max-w-full items-center gap-2 p-2 text-black',
          scheduleView === 'DISPATCH'
            ? 'flex flex-row'
            : 'flex flex-wrap items-center',
        )}
      >
        <div className="flex flex-wrap items-center justify-center gap-2">
          {techs && (
            <StackedAvatars users={techs} onAvatarClick={onAvatarClick} />
          )}
          <div className={cn('min-w-[16px]')}>
            <span onClick={stopPropagation}>
              <Popconfirm
                okText="Delete"
                title="Are you sure you want to delete this event?"
                okButtonProps={{ danger: true }}
                onConfirm={onDelete}
              >
                <span
                  className={cn(
                    // The "trash-icon" class is in Schedule.less. It makes this thing appear
                    // when we hover
                    canManageSchedule ? 'trash-icon' : 'hidden',
                    'opacity-50 hover:opacity-100',
                  )}
                >
                  <FontAwesomeIcon size="lg" icon={faTrash} />
                </span>
              </Popconfirm>
            </span>
            {/* The "job-icon" class is in Schedule.less. It makes this thing hide when we hover */}
            <span className={cn(canManageSchedule ? 'job-icon' : '')}>
              {icon}
            </span>
          </div>
        </div>
        <div className="flex-1">{content}</div>
      </div>
    )
  },
)

type NonBlockEventContentProps = {
  appointment: AssignableApptViewModel
  assignmentGuid: string
  techs?: TechnicianResource[]
  onAvatarClick?: () => void
}

export const NonBlockEventContent = React.memo<NonBlockEventContentProps>(
  ({ appointment, assignmentGuid, techs, onAvatarClick }) => {
    const { setPendingChanges } = useSchedulePendingChanges()

    const bzAddress = useMemo(
      () => BzAddress.create(appointment.location.address),
      [appointment.location.address],
    )

    const onDelete = useCallback(() => {
      setPendingChanges({
        field: 'deletedEventMap',
        key: assignmentGuid,
        value: {
          appointmentGuid: appointment.appointment.guid,
        },
      })
    }, [appointment.appointment.guid, assignmentGuid, setPendingChanges])

    return (
      <ScheduleEventContent
        icon={
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              size="lg"
              icon={JOB_CLASS_DISPLAY_INFO[appointment.jobType.jobClass].icon}
            />
          </div>
        }
        content={
          <div>
            {appointment.contact.name} -{' '}
            {bzAddress.streetAddressLine1And2Condensed()}, {bzAddress.zip5}
          </div>
        }
        techs={techs}
        onDelete={onDelete}
        onAvatarClick={onAvatarClick}
      />
    )
  },
)

type BlockEventContentProps = {
  block: BlockCalendarEvent
  techs?: TechnicianResource[]
  onAvatarClick?: () => void
}

export const BlockEventContent = React.memo<BlockEventContentProps>(
  ({ block, techs, onAvatarClick }) => {
    const { setPendingChanges } = useSchedulePendingChanges()
    const content =
      block.reasonDescription ??
      formatTechnicianCapacityBlockReasonType(block.reasonType)

    const [pendingRecurringDelete, setPendingRecurringDelete] =
      useState<BlockCalendarEvent>()

    const onDelete = useCallback(() => {
      if (block.recurring) {
        setPendingRecurringDelete(block)
      } else {
        setPendingChanges({
          field: 'deletedEventMap',
          key: block.blockGuid,
          value: {
            blockGuid: block.blockGuid,
          },
        })
      }
    }, [block, setPendingChanges])

    const onRecurringDeleteCancel = useCallback(() => {
      setPendingRecurringDelete(undefined)
    }, [])

    const onRecurringDeleteOk = useCallback(
      (changeType: RecurringChangeType) => {
        setPendingRecurringDelete(undefined)

        if (!pendingRecurringDelete) {
          return
        }

        const start = fixMbscDate(pendingRecurringDelete.start)

        // If it's all events, then we just delete the original and the rest follow. If
        // it's this and future, we update the "UNTIL" part of the RRule. If it's this
        // event only, we just add it to the recurrence exceptions list.
        switch (changeType) {
          case 'ALL_EVENTS':
            setPendingChanges({
              field: 'deletedEventMap',
              key: block.blockGuid,
              value: {
                blockGuid: block.blockGuid,
              },
            })
            break
          case 'THIS_AND_FUTURE':
            setPendingChanges({
              field: 'eventChangeMap',
              key: block.blockGuid,
              value: {
                blockGuid: block.blockGuid,
                userGuids: block.userGuids,
                start: fixMbscDate(block.original?.start),
                end: fixMbscDate(block.original?.end),
                reasonType: block.reasonType,
                reasonDescription: block.reasonDescription,
                recurrenceRule: block.original?.recurring
                  ? changeRRuleUntil(`${block.original?.recurring}`, start)
                  : undefined,
                recurrenceRuleExceptions: block.recurringException
                  ? `${block.recurringException}`
                  : undefined,
              },
            })
            break
          case 'THIS_EVENT_ONLY':
            setPendingChanges({
              field: 'eventChangeMap',
              key: block.blockGuid,
              value: {
                blockGuid: block.blockGuid,
                userGuids: block.userGuids,
                start: fixMbscDate(block.original?.start),
                end: fixMbscDate(block.original?.end),
                reasonType: block.reasonType,
                reasonDescription: block.reasonDescription,
                recurrenceRule: block.recurring
                  ? `${block.recurring}`
                  : undefined,
                recurrenceRuleExceptions: block.recurringException
                  ? `${block.recurringException},${start}`
                  : start,
              },
            })
            break
        }
      },
      [
        block.blockGuid,
        block.original?.end,
        block.original?.recurring,
        block.original?.start,
        block.reasonDescription,
        block.reasonType,
        block.recurring,
        block.recurringException,
        block.userGuids,
        pendingRecurringDelete,
        setPendingChanges,
      ],
    )

    return (
      <>
        <ScheduleEventContent
          icon={<FontAwesomeIcon size="lg" icon={faCalendar} />}
          content={content}
          techs={techs}
          onDelete={onDelete}
          onAvatarClick={onAvatarClick}
        />
        {pendingRecurringDelete && (
          <span onClick={stopPropagation}>
            <RecurringChangeModal
              onCancel={onRecurringDeleteCancel}
              onOk={onRecurringDeleteOk}
            />
          </span>
        )}
      </>
    )
  },
)
