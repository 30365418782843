import { bzExpect } from '@breezy/shared'
import {
  useExpectedCompany,
  usePrincipalUser,
} from '../providers/PrincipalUser'

export const useCompanyUserContext = () => {
  const companyGuid = bzExpect(
    usePrincipalUser()?.principal?.company?.companyGuid,
  )
  const timeZoneId = useExpectedCompany().timezone
  const principalUserGuid = bzExpect(usePrincipalUser()?.principal?.userGuid)

  return { companyGuid, timeZoneId, principalUserGuid }
}
