import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaymentCenterUiCard from '../../components/Payments/PaymentCenterUiCard'
import ThinDivider from '../../elements/ThinDivider'
import { m } from '../../utils/react-utils'

type FeatureBlockedWarningViewProps = {
  readonly icon: IconDefinition
  readonly iconColor: string
  readonly title: string
  readonly blockedMessage: string
  readonly remediationMessage: string
}

const FeatureBlockedWarningView = m(
  ({
    icon,
    iconColor,
    title,
    blockedMessage,
    remediationMessage,
  }: FeatureBlockedWarningViewProps) => {
    return (
      <PaymentCenterUiCard>
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: 52, color: iconColor, marginBottom: 16 }}
        />
        <h2 className="text-center">{title}</h2>
        <ThinDivider />
        <div className="regular_14_22 font-semi-bold py-2 text-center">
          {blockedMessage}
        </div>
        <ThinDivider />
        <div className="regular_14_22 font-semi-bold text-center">
          {remediationMessage}
        </div>
      </PaymentCenterUiCard>
    )
  },
)

export default FeatureBlockedWarningView
