import { PhotoRecordWithLinks } from '@breezy/shared'
import { useEffect, useRef } from 'react'
import { trpc } from '../../../../../hooks/trpc'
import { LoadingSpinner } from '../../../../LoadingSpinner'
import TrpcQueryLoader from '../../../../TrpcQueryLoader'
import { useTechExpDrawers } from '../../../TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'

type LocationPhotosProps = {
  locationGuid: string
  apptVersion: number
  onMutate: () => void
}

type PhotoGridProps = {
  photos: PhotoRecordWithLinks[]
  onMutate: () => void
}

const PhotosGrid = ({ photos, onMutate }: PhotoGridProps) => {
  return (
    <>
      {photos.length === 0 && (
        <div className="center-children-vh h-16 w-full">No photos found</div>
      )}
      {photos.length > 0 && (
        <div className="columns-2 gap-2">
          {photos.map((p, index) => (
            <PhotoAsDetailDrawerLink
              photo={p}
              onMutate={onMutate}
              key={index}
            />
          ))}
        </div>
      )}
    </>
  )
}

type PhotoDetailProps = {
  photo: PhotoRecordWithLinks
  onMutate: () => void
}

const PhotoAsDetailDrawerLink = ({ photo, onMutate }: PhotoDetailProps) => {
  const drawers = useTechExpDrawers()

  return (
    <div
      style={{
        maxHeight: '18em',
        overflow: 'hidden',
        cursor: 'pointer',
        marginBottom: '8px',
      }}
      onClick={() => drawers.viewOrEditPhoto(photo, onMutate)}
      className="rounded-md"
    >
      <img
        style={{ borderRadius: 4 }}
        src={photo.cdnUrl}
        className="h-full w-full rounded-md object-cover"
        alt="Location"
      />
    </div>
  )
}

const LocationPhotos = ({
  locationGuid,
  onMutate,
  apptVersion,
}: LocationPhotosProps) => {
  const photosQuery = trpc.photos['photos:get-by-links'].useQuery({
    locationGuid,
  })
  const refetchRef = useRef(photosQuery.refetch)
  refetchRef.current = photosQuery.refetch
  useEffect(() => {
    refetchRef.current()
  }, [apptVersion])

  return (
    <div>
      <div className="card-title-md mb-3">Location Photos</div>
      <TrpcQueryLoader
        query={photosQuery}
        errorMessage="Failed to load location photos"
        errorComponent={
          <div className="text-center">Could not load photos</div>
        }
        loadingComponent={
          <div className="h-16 w-full">
            <LoadingSpinner />
          </div>
        }
        render={photos => (
          <PhotosGrid
            photos={photos}
            onMutate={() => {
              onMutate()
              photosQuery.refetch()
            }}
          />
        )}
      />
    </div>
  )
}

export default LocationPhotos
