import { BzDateFns, IsoDateString, bzExpect, isNullish } from '@breezy/shared'
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useSubscription } from 'urql'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompany,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import tailwindConfig from '../../tailwind.config'
import { CURRENT_ASSIGNMENT_TIMESHEET_ENTRIES_QUERY } from './UserTimeClockStatusCard.gql'

export type UserAssignmentTimeClockStatusCardProps = {
  jobAppointmentAssignmentGuid: string
}
export const UserAssignmentTimeClockStatusCard = ({
  jobAppointmentAssignmentGuid,
}: UserAssignmentTimeClockStatusCardProps) => {
  const company = useExpectedCompany()
  const user = useExpectedPrincipal()

  const [res] = useSubscription({
    query: CURRENT_ASSIGNMENT_TIMESHEET_ENTRIES_QUERY,
    variables: {
      companyGuid: company.companyGuid,
      userGuid: user.userGuid,
      jobAppointmentAssignmentGuid,
    },
  })

  const updatePauseResumeStatus =
    trpc.assignments['assignments:update-pause-resume-status'].useMutation()

  const isPaused: boolean = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return false
    }

    return !['DRIVE-TIME', 'ON-SITE'].includes(
      res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
        .timesheetEntryActivity?.activityName ?? '',
    )
  }, [res.data])

  const startTime: string | null = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return null
    }

    return BzDateFns.format(
      BzDateFns.parseISO(
        bzExpect(res.data?.timesheetEntries[0].finalStartTime),
        company.timezone,
      ),
      'h:mm a',
    )
  }, [company.timezone, res.data])

  const endTime: string | null = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return null
    }

    const { timesheetEntries } = res.data

    if (
      isNullish(
        res.data?.timesheetEntries[timesheetEntries.length - 1].finalEndTime,
      )
    ) {
      return null
    }

    return BzDateFns.format(
      BzDateFns.parseISO(
        res.data.timesheetEntries[timesheetEntries.length - 1]
          .finalEndTime as IsoDateString,
        company.timezone,
      ),
      'h:mm a',
    )
  }, [company.timezone, res.data])

  const startingElapsedTime: number = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return 0
    }

    let time = res.data.timesheetEntries
      .filter(entry => entry.timesheetEntryActivity?.isPayable ?? false)
      .reduce<number>(
        (acc, entry) => acc + (entry.entryLengthInMinutes ?? 0),
        0,
      )

    if (isNullish(endTime) && !isPaused) {
      const now = BzDateFns.now(company.timezone)
      const lastEntryStartTime = BzDateFns.parseISO(
        bzExpect(
          res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
            .finalStartTime,
        ),
        company.timezone,
      )
      time += Math.abs(BzDateFns.differenceInMinutes(now, lastEntryStartTime))
    }

    return time
  }, [company.timezone, endTime, isPaused, res.data])

  const [localElapsedTime, setLocalElapsedTime] = useState(0)

  useEffect(() => {
    setLocalElapsedTime(startingElapsedTime)
  }, [startingElapsedTime])

  useEffect(() => {
    if (isPaused) {
      return
    }

    const intervalId = setInterval(() => {
      setLocalElapsedTime(prev => prev + 1)
    }, 1000 * 60)

    return () => clearInterval(intervalId)
  }, [isPaused])

  if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
    return null
  }

  return (
    <Card className="shadow-md" bodyStyle={{ padding: '12px' }}>
      <div className="flex w-full flex-row items-center gap-4">
        <div className="flex flex-col">
          <span className="text-base font-bold text-bz-gray-1000">
            {startTime}
          </span>
          <span className="text-xs text-bz-gray-1000">start time</span>
        </div>

        {!isNullish(endTime) && (
          <div className="flex flex-col">
            <span className="text-base font-bold text-bz-gray-1000">
              {endTime}
            </span>
            <span className="text-xs text-bz-gray-1000">end time</span>
          </div>
        )}

        <div
          className="flex flex-col"
          style={{ marginLeft: isNullish(endTime) ? undefined : 'auto' }}
        >
          <span
            className="text-base font-bold"
            style={{
              color: isPaused
                ? tailwindConfig.theme.extend.colors['bz-orange'][700]
                : tailwindConfig.theme.extend.colors['bz-green'][800],
            }}
          >
            {Math.floor(localElapsedTime / 60) > 0 &&
              `${Math.floor(localElapsedTime / 60)} h `}
            {localElapsedTime % 60} min
          </span>
          <span className="text-xs text-bz-gray-1000">
            {isNullish(endTime)
              ? isPaused
                ? 'paused'
                : 'elapsed'
              : 'job time'}
          </span>
        </div>

        {isNullish(endTime) && (
          <Button
            className="ml-auto border-bz-gray-900"
            icon={
              <FontAwesomeIcon
                icon={isPaused ? faPlay : faPause}
                color={tailwindConfig.theme.extend.colors['bz-gray'][900]}
                size="lg"
              />
            }
            onClick={() => {
              if (
                isNullish(res.data) ||
                res.data.timesheetEntries.length === 0
              ) {
                return
              }
              const assignment =
                res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
                  .timesheetEntryLinkData[0]
              updatePauseResumeStatus.mutate({
                jobGuid: bzExpect(assignment.jobGuid),
                jobAppointmentGuid: bzExpect(assignment.jobAppointmentGuid),
                jobAppointmentAssignmentGuid: bzExpect(
                  assignment.jobAppointmentAssignmentGuid,
                ),
              })
            }}
            disabled={updatePauseResumeStatus.isLoading}
          >
            <span className="text-sm font-semibold text-bz-gray-900">
              {isPaused ? 'Resume' : 'Pause'}
            </span>
          </Button>
        )}
      </div>
    </Card>
  )
}
