import {
  bzExpect,
  getVisitStatus,
  InstalledEquipment,
  LocalDate,
  LocalDateString,
  VisitJobViewModel,
  VisitViewModel,
} from '@breezy/shared'
import { FetchMaintenancePlanDetailsQuery } from '../../query'

type VisitQueryJob = NonNullable<
  FetchMaintenancePlanDetailsQuery['maintenancePlansQueryable'][0]['maintenancePlanVisits'][0]['job']
>

export type VisitsQuery = NonNullable<
  FetchMaintenancePlanDetailsQuery['maintenancePlansQueryable'][0]['maintenancePlanVisits']
>

export const mapToVisitJob = (job: VisitQueryJob): VisitJobViewModel => ({
  jobGuid: job.jobGuid,
  jobDisplayId: job.displayId,
  jobTypeName: job.jobType.name,
  workCompletedAt: job.workCompletedAt,
})

export const mapQueryVisitsToVisitViewModels = (visits: VisitsQuery): VisitViewModel[] => {
  return visits
    .sort((a, b) => a.affinityDate.localeCompare(b.affinityDate))
    .map(v => {
      const visitEquipment = (v.maintenancePlanVisitInstalledEquipments ?? [])
        .filter(e => !!e.installedEquipment)
        .map(e => ({
          installedEquipmentGuid: bzExpect(e.installedEquipment?.installedEquipmentGuid, 'Installed Equipment Guid'),
          equipmentType: bzExpect(e.installedEquipment?.equipmentType, 'Equipment Type'),
          manufacturer: e.installedEquipment?.manufacturer,
          installationDate: e.installedEquipment?.installationDate
            ? LocalDate.parse(e.installedEquipment.installationDate)
            : undefined,
          estimatedEndOfLifeDate: e.installedEquipment?.estimatedEndOfLifeDate
            ? LocalDate.parse(e.installedEquipment.estimatedEndOfLifeDate)
            : undefined,
          averageLifeExpectancyYears: e.installedEquipment?.averageLifeExpectancyYears,
          modelNumber: e.installedEquipment?.modelNumber,
          serialNumber: e.installedEquipment?.serialNumber,
        }))
      const oldestEquipmentAgeYears = InstalledEquipment.getOldestInstalledEquipmentAgeYears(new Date(), visitEquipment)
      return {
        maintenancePlanGuid: v.maintenancePlanGuid,
        companyGuid: v.companyGuid,
        visitGuid: v.maintenancePlanVisitGuid,
        name: v.name,
        affinityDate: v.affinityDate as LocalDateString,
        status: getVisitStatus(v),
        visitJob: v.job ? mapToVisitJob(v.job) : undefined,
        visitEquipment,
        oldestEquipmentAgeYears,
        isExpiredOverride: v.isExpiredOverride,
        isCompletedOverride: v.isCompletedOverride,
        completedAtOverride: v.completedAtOverride,
        issuedAt: v.issuedAt,
        expiresAt: v.expiresAt,
        dueAt: v.dueAt,
      }
    })
}
