import { Account, MatchToShape } from '@breezy/shared'
import React, { useEffect } from 'react'
import ContactRadioSelection, {
  ContactRadioSelectionProps,
} from './ContactRadioSelection/ContactRadioSelection'
import LocationRadioSelection, {
  LocationRadioSelectionProps,
} from './LocationRadioSelection/LocationRadioSelection'

type LinkedInformationAccount = MatchToShape<
  Account,
  {
    accountGuid: true
    accountLocations: true
    accountContacts: true
  }
>

type LinkedInformationProps = Pick<
  ContactRadioSelectionProps,
  'selectedContactGuid' | 'setSelectedContactGuid'
> &
  Pick<
    LocationRadioSelectionProps,
    'selectedLocationGuid' | 'setSelectedLocationGuid'
  > & {
    account: LinkedInformationAccount
    refetchAccount: () => void
  }
const LinkedInformation = React.memo<LinkedInformationProps>(
  ({
    account,
    refetchAccount,
    selectedContactGuid,
    setSelectedContactGuid,
    selectedLocationGuid,
    setSelectedLocationGuid,
  }) => {
    useEffect(() => {
      if (
        account &&
        account.accountContacts.length > 0 &&
        (!selectedContactGuid ||
          !account.accountContacts.find(
            accountContact =>
              accountContact.contact.contactGuid === selectedContactGuid,
          ))
      ) {
        setSelectedContactGuid(account.accountContacts[0].contact.contactGuid)
      }
    }, [account, selectedContactGuid, setSelectedContactGuid])

    useEffect(() => {
      if (
        account &&
        account.accountLocations.length > 0 &&
        (!selectedLocationGuid ||
          !account.accountLocations.find(
            accountLocation =>
              accountLocation.location.locationGuid === selectedLocationGuid,
          ))
      ) {
        setSelectedLocationGuid(
          account.accountLocations[0].location.locationGuid,
        )
      }
    }, [account, selectedLocationGuid, setSelectedLocationGuid])

    if (!account) {
      return <div> Error, missing account </div>
    }

    return (
      <div>
        <ContactRadioSelection
          accountGuid={account.accountGuid}
          accountContacts={account.accountContacts}
          refetchAccountContacts={refetchAccount}
          selectedContactGuid={selectedContactGuid}
          setSelectedContactGuid={setSelectedContactGuid}
          title="Point of Contact"
          description="Select a Contact that will be the primary touch point for
        communication "
        />
        <LocationRadioSelection
          accountGuid={account.accountGuid}
          accountLocations={account.accountLocations}
          refetchAccountLocations={refetchAccount}
          stepEntityContextName="Job"
          selectedLocationGuid={selectedLocationGuid}
          setSelectedLocationGuid={setSelectedLocationGuid}
        />
      </div>
    )
  },
)

export default LinkedInformation
