import { ComprehensiveLocationDetails } from '@breezy/backend/src/application-types'
import {
  DateTimeFormatter,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  JobClass,
  LocationContact,
  isNullish,
  richJobTypeDescriptor,
} from '@breezy/shared'
import { useCallback, useMemo, useState } from 'react'
import BzColumn from '../../../elements/BzColumn/BzColumn'
import { useCanCreateJobs } from '../../../hooks/permission/useCanCreateJob'
import { trpc } from '../../../hooks/trpc'
import useAppNavigation from '../../../hooks/useAppNav'
import { useIntercom } from '../../../hooks/useIntercom'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { DetailsPageProps } from '../../../utils/Refetchable'
import { useMessage } from '../../../utils/antd-utils'
import { JobIcon } from '../../../utils/feature-icons'
import { m } from '../../../utils/react-utils'
import { UpsertOp } from '../../../utils/upsert-utils'
import { GroupedContactsCollapsible } from '../../Contacts/ContactsCollapsible/ContactsCollapsible'
import { DateFormat, DateView } from '../../Dates'
import EquipmentUpsertDrawer from '../../EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import { InstalledHvacSystemUpsertDrawer } from '../../InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import { LocationContactUpsertDrawer } from '../../LocationContacts/LocationContactUpsertDrawer'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import PaymentsCollapsible from '../../Payments/PaymentsCollapsible/PaymentsCollapsible'
import AccountCollapsible from '../AccountCollapsible/AccountCollapsible'
import AccountLocationCollapsible from '../AccountLocationCollapsible/AccountLocationCollapsible'
import { AppointmentsCollapsible } from '../AppointmentsCollapsible/AppointmentsCollapsible'
import EstimatesCollapsible from '../EstimatesCollapsible/EstimatesCollapsible'
import { InstalledEquipmentCollapsible } from '../InstalledEquipmentCollapsible/InstalledEquipmentCollapsible'
import { InstalledHvacSystemsCollapsible } from '../InstalledHvacSystemsCollapsible/InstalledHvacSystemsCollapsible'
import { InvoicesV2Collapsible } from '../InvoicesCollapsible/InvoicesV2Collapsible'
import MaintenancePlansCollapsible from '../MaintenancePlansCollapsible/MaintenancePlansCollapsible'

type LocationCollapsiblesConfig = {
  readonly hideMaintenancePlans?: boolean
  readonly hideAccount?: boolean
  readonly hideLocation?: boolean
  readonly defaultJobClass?: JobClass
}

type LocationCollapsiblesGroupProps =
  DetailsPageProps<ComprehensiveLocationDetails> &
    LocationCollapsiblesConfig & {
      editable?: boolean
    }

const LocationCollapsiblesGroup = m<LocationCollapsiblesGroupProps>(
  ({
    data,
    refetch,
    hideAccount,
    hideLocation,
    hideMaintenancePlans,
    defaultJobClass,
    editable = true,
  }) => {
    const message = useMessage()

    const appNav = useAppNavigation()
    const [locationContactMutState, setLocationContactMutState] =
      useState<UpsertOp<LocationContact>>()
    const [hvacSystemMutState, setHvacSystemMutState] =
      useState<UpsertOp<InstalledHvacSystem>>()
    const [equipmentMutState, setEquipmentMutState] =
      useState<UpsertOp<InstalledEquipmentSummary>>()
    const basicAccount = data.getBasicAccount()
    const accountLocation = data.getAccountLocation()
    const canCreateNewJobs = useCanCreateJobs()

    const removeLocationContactMutation =
      trpc.location['location-contacts:remove'].useMutation()

    const { location, locationGuid, accountGuid, maintenancePlans } = useMemo(
      () => ({
        locationGuid: data.getLocationGuid(),
        accountGuid: data.getAccountLocation()?.accountGuid,
        location: data.getLocation(),
        maintenancePlans: data.getMaintenancePlans(),
      }),
      [data],
    )

    const onRemoveLocationContact = useCallback(
      (locationContact: LocationContact) => {
        removeLocationContactMutation.mutate(
          {
            locationGuid: locationContact.locationGuid,
            contactGuid: locationContact.contactGuid,
          },
          {
            onSuccess() {
              refetch()
              message.success(
                `Successfully removed Location Contact relationship`,
              )
            },
            onError() {
              message.error(`Failed to remove Location Contact relationship`)
            },
          },
        )
      },
      [removeLocationContactMutation, refetch, message],
    )

    const createNewJob = useCallback(() => {
      appNav.navigateToCreateNewJob({
        accountGuid,
        locationGuid,
        jobClass: defaultJobClass,
      })
    }, [appNav, accountGuid, locationGuid, defaultJobClass])

    useIntercom({ isLauncherVisible: isNullish(locationContactMutState) })
    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags('mpw', 'location-details')

    const onCloseMpWizard = useCallback(() => {
      closeMaintenancePlanWizard()
      refetch()
    }, [closeMaintenancePlanWizard, refetch])

    return (
      <BzColumn noPaddingMobile>
        {!hideMaintenancePlans && (
          <MaintenancePlansCollapsible
            plans={maintenancePlans}
            hideCanceledPlans
            createNew={() => {
              openMaintenancePlanWizard()
            }}
            titleOverride="Maintenance Plan"
          />
        )}
        {maintenancePlanWizardOpen && (
          <MaintenancePlanWizard
            onRamp="location-details"
            accountGuid={accountGuid}
            locationGuid={locationGuid}
            onClose={onCloseMpWizard}
          />
        )}
        {!hideAccount && basicAccount && (
          <AccountCollapsible account={basicAccount} />
        )}
        {!hideLocation && accountLocation && (
          <AccountLocationCollapsible
            data={accountLocation}
            refetch={refetch}
          />
        )}

        <GroupedContactsCollapsible
          title="Contacts"
          accountContacts={data.getAccountContacts()}
          locationContacts={data.getLocationContacts()}
          onPlus={() => setLocationContactMutState('create new')}
          onEditLocationContact={c => setLocationContactMutState(c)}
          onRemoveLocationContact={c => onRemoveLocationContact(c)}
          editable={editable}
        />
        <BzCollapsible
          title="Job"
          titleIcon={JobIcon}
          onPlus={editable && canCreateNewJobs ? createNewJob : undefined}
        >
          {data.getJobs().map((job, i) => {
            return (
              <div key={job.jobGuid} className={i !== 0 ? 'mt-2' : ''}>
                <CollapsibleItem
                  key={job.jobGuid}
                  title={richJobTypeDescriptor(job.jobType.name)}
                  displayId={job.displayId}
                  onTitleClick={() =>
                    appNav.navigateToJobDetailsPage(job.jobGuid)
                  }
                  contentList={[
                    {
                      key: 'Created on:',
                      value: (
                        <DateView
                          format={DateFormat['MMM d, yyyy']}
                          isoWithOffsetTimestamp={job.jobCreatedAt.format(
                            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
                          )}
                          zone={{ type: 'company-timezone-of-principal-user' }}
                        />
                      ),
                    },
                    {
                      key: 'Status:',
                      value: job.jobLifecycleStatus.name,
                    },
                  ]}
                />
              </div>
            )
          })}
        </BzCollapsible>

        <AppointmentsCollapsible
          appointments={data.getAppointments()}
          editable={editable}
        />

        <InstalledEquipmentCollapsible
          installedEquipment={data.getEquipment()}
          onAdd={() => setEquipmentMutState('create new')}
          onEdit={setEquipmentMutState}
          editable={editable}
        />

        <InstalledHvacSystemsCollapsible
          installedHvacSystems={data.getInstallHvacSystems()}
          onEditInstalledHvacSystem={setHvacSystemMutState}
          onEditInstalledEquipment={setEquipmentMutState}
          onAddInstalledHvacSystem={() => setHvacSystemMutState('create new')}
          editable={editable}
        />

        <PaymentsCollapsible payments={data.getPayments()} />

        {accountGuid && (
          <InvoicesV2Collapsible
            accountGuid={accountGuid}
            locationGuid={locationGuid}
          />
        )}
        {accountGuid && (
          <EstimatesCollapsible guids={{ accountGuid, locationGuid }} />
        )}

        {equipmentMutState === 'create new' && (
          <EquipmentUpsertDrawer
            mode="create-for-location"
            location={location}
            isOpen={!!equipmentMutState}
            onCancel={() => setEquipmentMutState(undefined)}
            onMutate={() => {
              refetch()
              setEquipmentMutState(undefined)
            }}
          />
        )}

        {equipmentMutState && equipmentMutState !== 'create new' && (
          <EquipmentUpsertDrawer
            mode="update"
            initialValues={equipmentMutState}
            location={location}
            isOpen={!!equipmentMutState}
            onCancel={() => setEquipmentMutState(undefined)}
            onMutate={() => {
              refetch()
              setEquipmentMutState(undefined)
            }}
          />
        )}

        {hvacSystemMutState === 'create new' && (
          <InstalledHvacSystemUpsertDrawer
            mode={'create-for-location'}
            location={location}
            isOpen={!!hvacSystemMutState}
            onCancel={() => setHvacSystemMutState(undefined)}
            onMutate={() => {
              refetch()
              setHvacSystemMutState(undefined)
            }}
          />
        )}

        {hvacSystemMutState && hvacSystemMutState !== 'create new' && (
          <InstalledHvacSystemUpsertDrawer
            mode={'update'}
            location={location}
            initialValues={hvacSystemMutState}
            isOpen={!!hvacSystemMutState}
            onCancel={() => setHvacSystemMutState(undefined)}
            onMutate={() => {
              refetch()
              setHvacSystemMutState(undefined)
            }}
          />
        )}

        <LocationContactUpsertDrawer
          locationGuid={locationGuid}
          locationContact={
            locationContactMutState === 'create new'
              ? undefined
              : locationContactMutState
          }
          isOpen={!!locationContactMutState}
          onClose={() => setLocationContactMutState(undefined)}
          onMutate={() => {
            refetch()
            setLocationContactMutState(undefined)
          }}
        />
      </BzColumn>
    )
  },
)

export default LocationCollapsiblesGroup
