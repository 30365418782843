export const formatNumberAsNth = (n: number): string => {
  if (n === 11 || n === 12 || n === 13) {
    return n + 'th'
  }
  switch (n % 10) {
    case 1:
      return n + 'st'
    case 2:
      return n + 'nd'
    case 3:
      return n + 'rd'
    default:
      return n + 'th'
  }
}

// Like number.toLocaleString() but if the number is greater than 1000, it adds a k.
export const formatThousandsAsK = (n: number) => {
  if (n < 1000) {
    return n.toLocaleString()
  }
  return Math.round(n / 1000).toLocaleString('en-us', { maximumFractionDigits: 1 }) + 'k'
}

/**
 * Clamps a number so that it always is between a specified range
 *
 * @param n The number to clamp
 * @param min What `n` should be set to if it's less than this number
 * @param max What `n` should be set to if it's greater than this number
 * @returns The clamped number.
 */
export const clamp = (n: number, min: number, max: number): number => {
  return Math.max(min, Math.min(n, max))
}

export const roundToNDecimalPlaces = (n: number, decimalPlaces: number) =>
  n.toLocaleString('en-US', { maximumFractionDigits: decimalPlaces })

export const formatPercentage = (percentage: number, decimalPlaces = 1) =>
  `${roundToNDecimalPlaces(percentage * 100, decimalPlaces)}%`

export const parseNumberFromString = (s: string): number => {
  const cleanedString = s.replace(/\$\s?|(,*)/g, '')
  // Use a regex to check if the cleaned string is a valid number (including decimal numbers)
  if (!/^-?\d+(\.\d+)?$/.test(cleanedString)) {
    throw new Error(`Unable to parse "${s}" as a number.`)
  }
  const parsed = Number.parseFloat(cleanedString)
  if (isNaN(parsed)) {
    throw new Error(`Unable to parse "${s}" as a number.`)
  }

  return parsed
}

export const getAverageIgnoreZeros = (numbers: number[]): number => {
  let count = 0
  let total = 0
  for (const num of numbers) {
    if (num) {
      count++
      total += num
    }
  }
  return total / count
}
