import { useMessage } from '../../../utils/antd-utils'
import {
  AchDebitDetails,
  TilledClient,
  TilledPaymentMethodRequest,
} from '../../../utils/tilledSdkTypes'
import {
  PaymentAsyncUiStatus,
  PaymentMethodCreationContextProps,
} from '../PaymentTypes'
import { createSanitizedTilledPaymentMethodRequest } from './tilled-utils'

type TilledPaymentMethodCreatedHooks = {
  onSuccess: (
    paymentMethodId: string,
    paymentMethodAdditionalInfo: string,
  ) => void
  onPaymentSubmissionError: (errorMsg: string) => void
}

type TilledConfirmHookProps = TilledPaymentMethodCreatedHooks & {
  paymentContext: PaymentMethodCreationContextProps
  tilled?: TilledClient
}

type TilledCreatePaymentMethodHookProps = TilledConfirmHookProps &
  (
    | {
        paymentMethodType: 'card'
        getAchDebitDetails: undefined
      }
    | {
        paymentMethodType: 'ach_debit'
        getAchDebitDetails: () => AchDebitDetails
      }
  )

export type TilledPaymentMethod = Awaited<
  ReturnType<TilledClient['createPaymentMethod']>
>

export const useTilledCreatePaymentMethod = ({
  paymentContext,
  tilled,
  paymentMethodType,
  getAchDebitDetails,
  onSuccess,
  onPaymentSubmissionError,
}: TilledCreatePaymentMethodHookProps) => {
  const message = useMessage()
  const create = createTilledPaymentMethod(message)
  const { onPaymentAsyncUiStatusChange, paymentBillingDetails } = paymentContext

  if (!tilled) {
    return async () => message.error('Tilled request context is not available')
  }

  return async () => {
    const billingDetails = {
      name: paymentBillingDetails.name,
      address: {
        street: paymentBillingDetails.address.line1,
        city: paymentBillingDetails.address.city,
        state: paymentBillingDetails.address.stateAbbreviation,
        zip: paymentBillingDetails.address.zipCode,
        country: 'US',
      },
    }

    const props =
      paymentMethodType === 'ach_debit'
        ? {
            type: paymentMethodType,
            billingDetails,
            getAchDebitDetails,
          }
        : {
            type: paymentMethodType,
            billingDetails,
          }
    const req = createSanitizedTilledPaymentMethodRequest(props)

    onPaymentAsyncUiStatusChange(PaymentAsyncUiStatus.VERIFYING)

    await create({
      tilled,
      paymentMethod: req,
      onSuccess,
      onPaymentSubmissionError,
    })
  }
}
export const getTilledPaymentMethodAdditionalInfo = (
  paymentMethod: TilledPaymentMethod,
) => {
  // NOTE: Highly valuable for debugging and ensuring the integration is working.
  // I don't fully trust the integration and it doesn't always work with React as desired,
  // so let's leave this one here
  console.debug({ paymentMethod })

  let additionalInfo = 'Payment Details Unknown'
  if (paymentMethod.card) {
    additionalInfo = `${paymentMethod.card.brand.toUpperCase()} - ${
      paymentMethod.card.last4
    }`
  } else if (paymentMethod.ach_debit) {
    additionalInfo = `ACH - ${paymentMethod.ach_debit.account_type} - ${paymentMethod.ach_debit.last2}`
  }

  return additionalInfo
}

export const createTilledPaymentMethod = (
  message: ReturnType<typeof useMessage>,
) => {
  return async ({
    tilled,
    paymentMethod,
    onSuccess,
    onPaymentSubmissionError,
  }: TilledPaymentMethodCreatedHooks & {
    tilled: TilledClient
    paymentMethod: TilledPaymentMethodRequest
  }) => {
    try {
      const response = await tilled.createPaymentMethod(paymentMethod)

      const additionalInfo = getTilledPaymentMethodAdditionalInfo(response)

      onSuccess(response.id, additionalInfo)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.warn(error)
      message.warning(error)
      onPaymentSubmissionError('Could not process payment.')
    }
  }
}
