import { Input, InputProps, InputRef } from 'antd'
import classNames from 'classnames'
import React from 'react'

type PhoneNumberFieldProps = InputProps & {
  ddActionName?: string
}

export const PhoneNumberField = React.memo(
  React.forwardRef<InputRef, PhoneNumberFieldProps>(
    ({ name, size = 'large', className, ddActionName, ...rest }, ref) => (
      <Input
        ref={ref}
        id={name}
        data-dd-action-name={ddActionName}
        data-testid={name}
        size={size}
        autoComplete="tel"
        type="tel"
        maxLength={14}
        className={classNames('w-full', className)}
        {...rest}
      />
    ),
  ),
)
