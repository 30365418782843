import {
  BzDateFns,
  EquipmentType,
  IsoDateString,
  LabelScannerFormData,
  LabelScannerResponse,
  LocalDate,
  PhotoLinks,
  R,
  WithRequiredKeys,
  formatEquipmentType,
  isNullish,
} from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { useCanManageJob } from '../../hooks/permission/useCanManageJob'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { PrefillEquipmentFormData } from '../EquipmentUpsertForm/EquipmentUpsertForm'
import {
  AsyncPhotoData,
  AsyncPhotoUpload,
  AsyncTakePhoto,
} from '../Upload/AsyncPhotoUpload'
import gridSVG from './Grid.svg'
import './LabelScanner.less'

const CAPPED_WIDTH_CLASSNAME = `max-w-[754px]`

type ReticlePartProps = {
  y: 'top' | 'bottom'
  x: 'left' | 'right'
}

const ReticlePart = React.memo<ReticlePartProps>(({ x, y }) => (
  <div
    className={classNames(
      'absolute h-[50px] w-[50px] border-4  border-solid border-bz-border',
      x === 'left' ? 'left-0 border-r-0' : 'right-0 border-l-0',
      y === 'top' ? 'top-0 border-b-0' : 'bottom-0 border-t-0',
      {
        'rounded-tl-2xl': x === 'left' && y === 'top',
        'rounded-tr-2xl': x === 'right' && y === 'top',
        'rounded-bl-2xl': x === 'left' && y === 'bottom',
        'rounded-br-2xl': x === 'right' && y === 'bottom',
      },
    )}
  />
))

type DataRectangleProps = React.PropsWithChildren<{
  label: React.ReactNode
}>

const DataRectangle = React.memo<DataRectangleProps>(({ label, children }) => (
  <div className="text-base">
    <div className="font-semibold text-bz-text">{label}</div>
    <div className="mt-[1px] break-words text-bz-text">{children}</div>
  </div>
))

const LABEL_SCANNER_FORM_KEY_TO_DISPLAY_NAME: Record<
  keyof LabelScannerFormData,
  string
> = {
  name: 'Name',
  equipmentType: 'Equipment Type',
  avgLifeExpectancy: 'Life Exp.',
  manufacturer: 'Manufacturer',
  modelNumber: 'Model Number',
  serialNumber: 'Serial Number',
  installationDate: 'Install Date',
  manufactureDate: 'Manufacture Date',
}

type InfoPanelContainerProps = {
  data: LabelScannerResponse
}

const InfoPanelContainer = React.memo<InfoPanelContainerProps>(({ data }) => {
  const [showMore, setShowMore] = useState(false)

  const tzId = useExpectedCompanyTimeZoneId()

  return (
    <div className="flex flex-1 flex-col items-center">
      <div
        className={classNames(
          'mb-6 w-full flex-shrink-0 border-0 border-b border-dashed border-b-bz-border pb-6',
          CAPPED_WIDTH_CLASSNAME,
        )}
      >
        <div
          className={classNames('text-base', {
            'line-clamp-3': !showMore,
          })}
        >
          {data.message}
        </div>
        <Button className="p-0" type="link" onClick={() => setShowMore(R.not)}>
          {showMore ? 'See less' : 'Read more...'}
        </Button>
      </div>

      <div
        className={classNames(
          'mt-2 grid w-full grid-cols-2 gap-x-3 gap-y-6',
          CAPPED_WIDTH_CLASSNAME,
        )}
      >
        {R.keys(data.form).map(key => {
          if (key === 'name') {
            return null
          }
          let value = data.form[key]

          if (key === 'equipmentType') {
            value = formatEquipmentType(data.form[key] as EquipmentType)
          }
          if (
            value &&
            (key === 'installationDate' || key === 'manufactureDate')
          ) {
            try {
              const date = BzDateFns.parseISO(
                // We're protected by the try/catch from doing anything unsafe here
                value as IsoDateString,
                tzId,
              )
              value = BzDateFns.format(date, 'MMM d, yyyy')
            } catch (e) {
              console.error('Failed to parse date', e)
            }
          }

          const displayName =
            LABEL_SCANNER_FORM_KEY_TO_DISPLAY_NAME[
              key as keyof LabelScannerFormData
            ]

          return (
            <DataRectangle key={key} label={displayName}>
              {value}
            </DataRectangle>
          )
        })}
        {R.keys(data.extraFields).map(key => (
          <DataRectangle key={key} label={key}>
            {data.extraFields[key]}
          </DataRectangle>
        ))}
      </div>
    </div>
  )
})

type LabelScannerProps = {
  onClose: () => void
  links: WithRequiredKeys<PhotoLinks, 'jobGuid'>
  onAddEquipment?: (summary: PrefillEquipmentFormData) => void
}

export const LabelScanner = React.memo<LabelScannerProps>(
  ({ onClose, links, onAddEquipment }) => {
    const { canManage: canManageJob } = useCanManageJob(links.jobGuid)

    const isMobile = useIsMobile()

    const message = useMessage()
    const [imageInfo, setImageInfo] = useState<AsyncPhotoData | undefined>()

    const imageUrl = imageInfo?.cdnUrl

    const scanImageMutation =
      trpc.labelScanner['label-scanner:process-image'].useMutation()

    const processImage = useCallback(async () => {
      if (!imageUrl || !imageInfo?.photoGuid) {
        return
      }
      try {
        await scanImageMutation.mutateAsync({
          imageUrl,
          photoGuid: imageInfo.photoGuid,
        })
      } catch (e) {
        console.error('Failed to process scanned label', e)
        message.error(
          'There was an issue processing your image. Try again later',
        )
      }
    }, [imageInfo?.photoGuid, imageUrl, message, scanImageMutation])

    useEffect(() => {
      if (
        imageInfo?.recordWritten &&
        !scanImageMutation.data &&
        !scanImageMutation.isLoading
      ) {
        processImage()
      }
    }, [
      imageInfo?.recordWritten,
      processImage,
      scanImageMutation.data,
      scanImageMutation.isLoading,
    ])

    const addAsEquipment = useCallback(() => {
      if (!scanImageMutation.data) {
        return
      }

      const averageLifeExpectancyYears =
        scanImageMutation.data.form.avgLifeExpectancy ?? 15

      let installationDate: LocalDate | undefined = undefined

      if (scanImageMutation.data.form.installationDate) {
        const parsedInstallationDate = new Date(
          scanImageMutation.data.form.installationDate,
        )
        if (!isNaN(parsedInstallationDate.getTime())) {
          installationDate = LocalDate.parse(
            BzDateFns.formatLocalDate(parsedInstallationDate),
          )
        }
      }
      let estimatedEndOfLifeDate: LocalDate | undefined = undefined

      if (
        scanImageMutation.data.form.manufactureDate ||
        scanImageMutation.data.form.installationDate
      ) {
        let baseDate: Date | undefined = undefined
        if (scanImageMutation.data.form.installationDate) {
          const parsedInstallationDate = new Date(
            scanImageMutation.data.form.installationDate,
          )
          if (!isNaN(parsedInstallationDate.getTime())) {
            baseDate = parsedInstallationDate
          }
        }
        if (!baseDate && scanImageMutation.data.form.manufactureDate) {
          const parsedManufactureDate = new Date(
            scanImageMutation.data.form.manufactureDate,
          )
          if (!isNaN(parsedManufactureDate.getTime())) {
            baseDate = parsedManufactureDate
          }
        }
        if (baseDate) {
          estimatedEndOfLifeDate = LocalDate.parse(
            BzDateFns.formatLocalDate(
              BzDateFns.addYears(baseDate, averageLifeExpectancyYears),
            ),
          )
        }
      }
      onClose()
      onAddEquipment?.({
        equipmentType: scanImageMutation.data.form.equipmentType,
        averageLifeExpectancyYears,
        manufacturer: scanImageMutation.data.form.manufacturer,
        modelNumber: scanImageMutation.data.form.modelNumber,
        serialNumber: scanImageMutation.data.form.serialNumber,
        description: scanImageMutation.data.message,
        installationDate,
        estimatedEndOfLifeDate,
      })
    }, [onAddEquipment, onClose, scanImageMutation.data])

    const header = useMemo(() => {
      if (!imageInfo) {
        return 'Take a Photo'
      }
      if (!imageInfo.recordWritten) {
        return 'Uploading...'
      }
      if (imageInfo.recordWritten && !scanImageMutation.data) {
        return 'Analyzing...'
      }
      if (scanImageMutation.data) {
        return (
          <div className="flex flex-col items-center">
            <div className={classNames('w-full', CAPPED_WIDTH_CLASSNAME)}>
              {scanImageMutation.data.form.name}
            </div>
          </div>
        )
      }
      return 'Unknown Equipment'
    }, [imageInfo, scanImageMutation.data])

    const [content, footer] = useMemo(() => {
      if (isNullish(imageInfo)) {
        return [
          <div className="flex w-full flex-col items-center">
            <div
              className={classNames(
                'mb-4 w-full space-y-3',
                CAPPED_WIDTH_CLASSNAME,
              )}
            >
              <AsyncPhotoUpload
                disabled={scanImageMutation.isLoading}
                links={links}
                onPhotoUploadChange={setImageInfo}
              />
              <AsyncTakePhoto
                disabled={scanImageMutation.isLoading}
                links={links}
                onPhotoUploadChange={setImageInfo}
              />
            </div>
          </div>,
        ]
      }
      if (!scanImageMutation.data) {
        return [
          <div className="flex max-h-full flex-col items-center">
            <div
              className={classNames(
                'relative flex h-auto min-h-32 w-auto min-w-32 flex-1 flex-col items-center',
                CAPPED_WIDTH_CLASSNAME,
                isMobile ? 'p-4' : 'p-6',
              )}
            >
              <img
                className="h-full max-h-full w-auto"
                alt="scanned label"
                src={imageInfo.base64File}
              />
              <ReticlePart x="left" y="top" />
              <ReticlePart x="right" y="top" />
              <ReticlePart x="left" y="bottom" />
              <ReticlePart x="right" y="bottom" />
              <div
                className={classNames(
                  'absolute z-10 overflow-hidden',
                  isMobile ? 'inset-4' : 'inset-6',
                )}
              >
                <img
                  className="h-full w-full object-cover"
                  src={gridSVG}
                  alt="grid"
                />

                <div className="label-scanner-animated-loading-overlay absolute inset-x-0" />
              </div>
            </div>
            <div className="mt-4 text-center text-xs text-bz-text-tertiary">
              Please note: this is a beta AI feature and results may not be
              completely accurate.
            </div>
          </div>,
        ]
      }
      return [
        <InfoPanelContainer data={scanImageMutation.data} />,
        canManageJob && (
          <div className="flex flex-row justify-center">
            <Button
              block
              size="large"
              type="primary"
              onClick={addAsEquipment}
              className={CAPPED_WIDTH_CLASSNAME}
            >
              Add as Equipment
            </Button>
          </div>
        ),
      ]
    }, [
      addAsEquipment,
      canManageJob,
      imageInfo,
      isMobile,
      links,
      scanImageMutation.data,
      scanImageMutation.isLoading,
    ])

    return (
      <OnsiteModal drawer={false} onClose={onClose} open size="full">
        <OnsiteModalContent
          header={header}
          headerAlwaysUp={!scanImageMutation.data}
          onClose={onClose}
          footer={footer}
        >
          {content}
        </OnsiteModalContent>
      </OnsiteModal>
    )
  },
)
