import { ComprehensiveLocationDetails } from '@breezy/backend/src/application-types'
import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  LocationGuidContainer,
  PermissionV2,
  PhotoRecord,
  isNullish,
} from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo, useState } from 'react'
import CreateOrEditNewAccountLocationForm from '../../components/CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import EmptyColumnThree from '../../components/EmptyColumnThree/EmptyColumnThree'
import FileElements from '../../components/Files/FileElements'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { MigrationJunkView } from '../../components/MigrationJunk/MigrationJunkView'
import NotesListCard from '../../components/Notes/NotesListCard/NotesListCard'
import BzTabs, { BzTab } from '../../components/Page/BzTabs/BzTabs'
import TagColumn from '../../components/Page/Columns/TagColumn/TagColumn'
import { Page } from '../../components/Page/Page'
import ThreeColumnTemplate from '../../components/Page/ThreeColumnTemplate'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import { PhotoDetailDrawer } from '../../components/PhotoDetailDrawer/PhotoDetailDrawer'
import { AsyncFileUpload } from '../../components/Upload/AsyncFileUpload'
import { AsyncPhotoUpload } from '../../components/Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../../components/Upload/Upload'
import LocationCollapsibles from '../../components/collapsibles/LocationCollapsiblesGroup/LocationCollapsiblesGroup'
import BzColumn from '../../elements/BzColumn/BzColumn'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { useFetchComprehensiveLocationDetails } from '../../hooks/fetch/useFetchComprehensiveLocationDetails'
import { useCanManageAccount } from '../../hooks/permission/useCanManageAccount'
import { useCanViewAccount } from '../../hooks/permission/useCanViewAccount'
import { trpc } from '../../hooks/trpc'
import { useIntercom } from '../../hooks/useIntercom'
import { useRouteParam } from '../../hooks/useRouteParam'
import { Placeholder } from '../../utils/Placeholder'
import { useMessage } from '../../utils/antd-utils'
import { LocationIcon } from '../../utils/feature-icons'
import LocationInfoCard from './LocationInfoCard'

const LocationDetailsPageLoader = () => {
  const locationGuid = useRouteParam('locationGuid')
  if (!locationGuid) {
    return (
      <div className="center-children-vh h-full w-full">
        <h1>Missing Location Guid - Location Not Found</h1>
      </div>
    )
  }

  return <LocationDetailsPage locationGuid={locationGuid} />
}

const LocationDetailsPage = ({ locationGuid }: LocationGuidContainer) => {
  const comprehensiveLocationDetailsQuery =
    useFetchComprehensiveLocationDetails({ locationGuid })
  const refetch = useCallback(
    () => comprehensiveLocationDetailsQuery.refetch(),
    [comprehensiveLocationDetailsQuery],
  )

  if (comprehensiveLocationDetailsQuery.isLoading) {
    return <LoadingSpinner />
  }

  if (comprehensiveLocationDetailsQuery.isError) {
    return <div>{`Failed to load location: ${locationGuid}`}</div>
  }

  if (
    !comprehensiveLocationDetailsQuery.isSuccess ||
    !comprehensiveLocationDetailsQuery
  )
    return null

  const currentAccountGuid =
    comprehensiveLocationDetailsQuery.data.getAccountLocation()?.accountGuid

  return currentAccountGuid ? (
    <AuthorizedAccountPage
      currentAccountGuid={currentAccountGuid}
      locationGuid={locationGuid}
      comprehensiveLocationDetails={comprehensiveLocationDetailsQuery.data}
      refetch={refetch}
    />
  ) : (
    <Authorized to={PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE}>
      <LocationDetails
        locationGuid={locationGuid}
        comprehensiveLocationDetails={comprehensiveLocationDetailsQuery.data}
        refetch={refetch}
        editable
      />
    </Authorized>
  )
}

type LocationDetailsProps = {
  locationGuid: string
  comprehensiveLocationDetails: ComprehensiveLocationDetails
  refetch: () => void
  editable?: boolean
}

type AuthorizedAccountPageProps = {
  currentAccountGuid: string
} & LocationDetailsProps

const AuthorizedAccountPage = ({
  currentAccountGuid,
  comprehensiveLocationDetails,
  ...rest
}: AuthorizedAccountPageProps) => {
  const { canView, isLoading: isCanViewLoading } =
    useCanViewAccount(currentAccountGuid)
  const { canManage, isLoading: isCanManageLoading } =
    useCanManageAccount(currentAccountGuid)

  if (isCanViewLoading || isCanManageLoading) {
    return <LoadingSpinner />
  }

  if (!canView) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Placeholder>Unauthorized to view this Location</Placeholder>
      </div>
    )
  }

  return (
    <LocationDetails
      comprehensiveLocationDetails={comprehensiveLocationDetails}
      editable={canManage}
      {...rest}
    />
  )
}

const LocationDetails = ({
  locationGuid,
  comprehensiveLocationDetails,
  refetch,
  editable = true,
}: LocationDetailsProps) => {
  const message = useMessage()
  const [editingPhoto, setEditingPhoto] = useState<PhotoRecord | null>(null)

  const [upsertLocationFormOpen, setUpsertLocationFormOpen] = useState(false)

  const fetchMigrationJunkQuery = trpc.migrationJunk[
    'migration-junk:query'
  ].useQuery({ locationGuid })

  const photoElements = useMemo(() => {
    if (!comprehensiveLocationDetails) {
      return null
    }
    return comprehensiveLocationDetails.getLocationPhotos().map(photo => (
      <div
        key={photo.photoGuid}
        className="mb-2 flex flex-col items-center hover:cursor-pointer"
        onClick={() => {
          setEditingPhoto(photo)
        }}
      >
        <img src={photo.cdnUrl} alt="belongs to job" />
      </div>
    ))
  }, [comprehensiveLocationDetails])

  useIntercom({ isLauncherVisible: isNullish(editingPhoto) })

  const photoLinks = useMemo(() => ({ locationGuid }), [locationGuid])
  const fileLinks = useMemo(
    () => ({
      locationGuid: locationGuid,
      accountGuid:
        comprehensiveLocationDetails.getAccountLocation()?.accountGuid,
    }),
    [comprehensiveLocationDetails, locationGuid],
  )
  const onUploadChange = useSynchronousUpload(refetch)

  const tabs: BzTab[] = useMemo(() => {
    const t = [
      {
        title: 'Notes',
        content: (
          <NotesListCard
            linkData={{
              primaryNoteType: 'LOCATION',
              locationGuid: locationGuid,
            }}
            title=""
            editable={editable}
          />
        ),
      },
      {
        title: 'Photos',
        content: (
          <div className="w-full">
            {editable && (
              <AsyncPhotoUpload
                links={photoLinks}
                onPhotoUploadChange={onUploadChange}
              />
            )}
            <div className="my-3 w-full columns-2 gap-2">{photoElements}</div>
            {editingPhoto && (
              <PhotoDetailDrawer
                photo={editingPhoto}
                onClose={() => {
                  setEditingPhoto(null)
                }}
                onDelete={() => {
                  setEditingPhoto(null)
                  refetch()
                }}
                editable={editable}
              />
            )}
          </div>
        ),
      },
      {
        title: 'Attachments',
        content: (
          <div className="w-full">
            {comprehensiveLocationDetails && (
              <div>
                {editable && (
                  <AsyncFileUpload
                    links={fileLinks}
                    onFileUploadChange={onUploadChange}
                  />
                )}
                <div className="mt-3 w-full">
                  <FileElements
                    onMutate={() => refetch()}
                    files={comprehensiveLocationDetails.getLocationFiles()}
                  />
                </div>
              </div>
            )}
          </div>
        ),
      },
    ]

    if (
      fetchMigrationJunkQuery.data?.junk &&
      Object.keys(fetchMigrationJunkQuery.data.junk).length > 0
    ) {
      t.push({
        title: fetchMigrationJunkQuery.data.uiLabel ?? 'Legacy Migration Data',
        content: (
          <MigrationJunkView migrationJunk={fetchMigrationJunkQuery.data} />
        ),
      })
    }
    return t
  }, [
    locationGuid,
    editable,
    photoLinks,
    photoElements,
    editingPhoto,
    comprehensiveLocationDetails,
    fileLinks,
    onUploadChange,
    fetchMigrationJunkQuery.data,
    refetch,
  ])

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const activeTab = tabs[activeTabIndex]

  const onEdit = useCallback(() => setUpsertLocationFormOpen(true), [])

  const columnOne = (
    <TagColumn
      title="Location"
      icon={faLocationDot}
      headerCssClass={'bg-gradient-to-r from-orange-600 to-orange-500'}
    >
      <LocationInfoCard
        onEdit={onEdit}
        createdAtString={comprehensiveLocationDetails
          .getCreatedAt()
          .format(
            DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
              ENGLISH_LOCALE,
            ),
          )}
        location={comprehensiveLocationDetails.getLocation()}
        editable={editable}
      />
    </TagColumn>
  )
  const columnTwo = (
    <BzColumn>
      <BzTabs
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {activeTab.content}
    </BzColumn>
  )

  return (
    <Page requiresCompanyUser>
      <div className="w-full">
        <ThreeColumnTemplate
          columnOne={columnOne}
          columnTwo={columnTwo}
          columnThree={
            (comprehensiveLocationDetails && (
              <LocationCollapsibles
                data={comprehensiveLocationDetails}
                refetch={refetch}
                hideLocation
                editable={editable}
              />
            )) || <EmptyColumnThree />
          }
        />
        <BzDrawer
          title="Edit Location"
          preferredWidth={720}
          icon={LocationIcon}
          item={
            upsertLocationFormOpen
              ? { onCancel: () => setUpsertLocationFormOpen(false) }
              : undefined
          }
          destroyOnClose
        >
          <CreateOrEditNewAccountLocationForm
            showDivider={true}
            showCancelSubmitButtons={true}
            accountGuid={
              comprehensiveLocationDetails.getAccountLocation()?.accountGuid
            }
            onCancelButtonPressed={() => setUpsertLocationFormOpen(false)}
            editingLocation={comprehensiveLocationDetails.getLocation()}
            onLocationUpdated={() => {
              setUpsertLocationFormOpen(false)
              message.success('Successfully updated location')
              refetch()
            }}
            flexRowSpaceX="space-x"
            labelClassName="semibold_14_22 grey9"
          />
        </BzDrawer>
      </div>
    </Page>
  )
}
export default LocationDetailsPageLoader
