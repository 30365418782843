import { PaymentMethod } from '@breezy/shared'
import { faOctagonExclamation as faOctagonExclamationRegular } from '@fortawesome/pro-regular-svg-icons'
import { faMobileSignal } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { InfoMessage } from '../../../../elements/InfoMessage/InfoMessage'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import Switch from '../../../../elements/Switch/Switch'
import { LoadingSpinner } from '../../../LoadingSpinner'

export const PaymentProcessingCard = React.memo<{
  paymentMethod: PaymentMethod
}>(({ paymentMethod }) => {
  const [showSlowInternetCheckCard, setShowSlowInternetCheckCard] =
    useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSlowInternetCheckCard(true)
    }, 15000) // 15000 milliseconds = 15 seconds

    return () => clearTimeout(timer) // Cleanup the timer when the component unmounts or updates
  }, [])
  return (
    <div className="flex flex-col items-center justify-center pb-6 pt-6 transition-all ease-in-out">
      <div className="flex h-[52px] w-[52px] flex-col items-center justify-center">
        <LoadingSpinner noMinHeight />
      </div>
      <div className="mb-4 text-[30px] font-semibold">
        <Switch value={paymentMethod}>
          {{
            [PaymentMethod.CARD]: () => 'Processing payment...',
            [PaymentMethod.ACH]: () => 'Processing payment...',
            default: () => 'Recording payment...',
          }}
        </Switch>
      </div>
      <div className="flex flex-col gap-3">
        <InfoMessage icon={faOctagonExclamationRegular} variant="warning">
          <span className="font-semibold">
            Please do not close or navigate away from this page
          </span>{' '}
          until the transaction is complete.
        </InfoMessage>
        <RenderIf if={showSlowInternetCheckCard}>
          <InfoMessage icon={faMobileSignal} variant="info">
            <span className="font-semibold">
              The payment processing is taking longer than expected.
            </span>{' '}
            Check if your device is connected to the internet.
          </InfoMessage>
        </RenderIf>
      </div>
    </div>
  )
})
