import { zodResolver } from '@hookform/resolvers/zod'
import { createTsForm } from '@ts-react/form'
import { Divider } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useExpectedCompany } from '../../../../providers/PrincipalUser'
import { PrequalBanners } from '../../../FinancingSection/PrequalifiedBanners'
import PrequalStatusTag from '../../../PrequalStatusTag/PrequalStatusTag'
import FinancingAppFormWrapper from '../../FinancingAppFormWrapper'
import { useFetchDisabledFinancingContacts } from '../../hooks/useFetchDisabledFinancingContacts'
import useIsMobileOrTablet from '../../hooks/useIsMobileOrTablet'
import ContactSelectionField from '../ContactSelectionField/ContactSelectionField'
import { ContactGuidSchema } from '../ContactSelectionField/ContactSelectionField.schema'
import { FinancingConfigureAppFormProps } from '../types'

const ConfigurePrequalApplicationFormSchema = z.object({
  contactGuid: ContactGuidSchema.describe('Contact'),
})

export type PrequalApplicationConfiguration = z.infer<
  typeof ConfigurePrequalApplicationFormSchema
>

const mapping = [[ContactGuidSchema, ContactSelectionField]] as const

const ConfigurePreQualificationForm = createTsForm(mapping, {
  FormComponent: FinancingAppFormWrapper,
})

const FinancingConfigurePrequalAppForm = React.memo<
  FinancingConfigureAppFormProps<PrequalApplicationConfiguration>
>(({ account, refetchAccount, onCancel, onSubmit, config }) => {
  const isMobileOrTablet = useIsMobileOrTablet()

  const form = useForm<PrequalApplicationConfiguration>({
    resolver: zodResolver(ConfigurePrequalApplicationFormSchema),
    mode: 'onChange',
  })
  const company = useExpectedCompany()

  const disabledContactGuids = useFetchDisabledFinancingContacts(
    account.accountGuid,
  )

  return (
    <ConfigurePreQualificationForm
      form={form}
      formProps={{
        onCancel,
        dataDdActionName: 'bz-financing-prequal-configure-submit-button-click',
        submitButtonText: isMobileOrTablet ? 'Next' : 'Preview Message',
      }}
      schema={ConfigurePrequalApplicationFormSchema}
      onSubmit={onSubmit}
      defaultValues={{
        contactGuid:
          config?.contactGuid ??
          account.accountContacts[0]?.contact.contactGuid ??
          '',
      }}
      props={{
        contactGuid: {
          account,
          refetchAccount,
          disabledContactGuids,
          disabledExtra: (
            <div className="block">
              <PrequalStatusTag status="DECLINED" />
            </div>
          ),
        },
      }}
    >
      {({ contactGuid }) => (
        <div className="flex flex-col">
          <div className="flex-1 pb-0">{contactGuid}</div>
          <Divider className="my-0" />
          <PrequalBanners
            accountGuid={account.accountGuid}
            companyName={company.name}
            className="mt-4"
            hasLogo
            showDeclined={false}
            prequalMessageType="full"
            showLearnMore
          />
        </div>
      )}
    </ConfigurePreQualificationForm>
  )
})

export default FinancingConfigurePrequalAppForm
