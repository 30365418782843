import classNames from 'classnames'
import React, { useCallback } from 'react'

type InputWrapperProps = React.PropsWithChildren<{
  label: string
  invalid?: boolean
  className?: string
  noPadding?: boolean
}>

export const InputWrapper = React.memo<InputWrapperProps>(
  ({ label, children, invalid, className, noPadding }) => {
    return (
      <div className={classNames('mb-6 flex-1', className)}>
        <div className="mb-1 font-semibold">
          {label}
          <span className="ml-1 text-bz-red-500">*</span>
        </div>
        <div
          className={classNames(
            'flex h-[40px] flex-row items-center rounded-lg border border-solid border-bz-gray-500 px-[11px] py-[7px] transition-all ease-in-out hover:border-[#40a9ff] focus:border-[#40a9ff] active:border-[#1890ff]',
            {
              'border-bz-red-500': invalid,
              'px-3': !noPadding,
            },
          )}
        >
          {children}
        </div>
      </div>
    )
  },
)

type InputProps = {
  label: string
  value: string
  invalid?: boolean
  onChange: (value: string) => void
  className?: string
}

export const Input = React.memo<InputProps>(
  ({ value, onChange, label, invalid, className }) => {
    const ourOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
      [onChange],
    )
    return (
      <InputWrapper label={label} invalid={invalid} className={className}>
        <input
          value={value}
          onChange={ourOnChange}
          className="h-full w-full border-0 outline-none"
        />
      </InputWrapper>
    )
  },
)
