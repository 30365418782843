import { faDollarCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row } from 'antd'

type PayButtonProps = {
  readonly text?: string
  readonly readyToSubmitPayment: boolean
  readonly submit: () => void
}

const PayButton = ({
  text = 'Pay',
  readyToSubmitPayment,
  submit,
}: PayButtonProps) => (
  <div className="w-full p-2 pr-0 pt-6">
    <Button
      type="primary"
      className="w-full rounded-md"
      style={{ height: 44 }}
      disabled={!readyToSubmitPayment}
      onClick={submit}
    >
      <Row className="center-children-vh">
        <FontAwesomeIcon
          icon={faDollarCircle}
          style={{
            fontSize: 20,
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: 12,
          }}
        />
        <div className="regular_14_22 mr-2">{text}</div>
      </Row>
    </Button>
  </div>
)

export default PayButton
