import { bzExpect } from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import { ActionBar } from '../../../adam-components/ActionBar/ActionBar'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import useIsMobile from '../../../hooks/useIsMobile'
import { DisclaimerSection } from './DisclaimerSection'

type SignatureFormProps = {
  onCancel: () => void
  onSubmit: (signaturePNG: string) => void
  loading?: boolean
}

export const SignatureForm = React.memo<SignatureFormProps>(
  ({ onCancel, onSubmit, loading }) => {
    const isMobile = useIsMobile()
    const signaturePadRef = useRef<SignaturePad | null>(null)

    const [hasSignature, setHasSignature] = useState(false)

    const clearSignature = useCallback(() => {
      signaturePadRef.current?.clear()
      setHasSignature(false)
    }, [])

    const acceptEstimate = useCallback(async () => {
      const base64ImageUri = bzExpect(
        signaturePadRef.current?.toDataURL('image/png'),
        'The signature pad should exist',
      )
      onSubmit(base64ImageUri.split(',')[1])
    }, [onSubmit])

    return (
      <>
        <OnsitePageSection title="Signature">
          <div className="mb-4 mt-2 text-base">
            By signing below, you are indicating that you have reviewed this
            estimate and agree to the terms and conditions.
          </div>

          <SignaturePad
            ref={signaturePadRef}
            onEnd={() => setHasSignature(true)}
            clearOnResize={false}
            canvasProps={{
              className: 'border-2 border-solid border-bz-gray-500 rounded',
              style: { width: '100%', aspectRatio: '2.5' },
            }}
          />
          <Button
            block
            size="large"
            type="link"
            className={classNames('mt-2 font-semibold text-bz-primary', {
              'opacity-[0.36]': !hasSignature,
            })}
            onClick={clearSignature}
          >
            Clear Signature
          </Button>
        </OnsitePageSection>
        <DisclaimerSection className={isMobile ? 'flex-1' : ''} />
        <ActionBar>
          <Button size="large" className="flex-1" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            className="flex-1"
            disabled={!hasSignature}
            onClick={acceptEstimate}
            loading={loading}
          >
            Save
          </Button>
        </ActionBar>
      </>
    )
  },
)
