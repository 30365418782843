import { gql } from '../../generated'

export const ACTIVITY_ITEMS_BY_COMPANY_QUERY = gql(/* GraphQL */ `
  query FetchActivityItemsByCompanyGuid($companyGuid: uuid = "") {
    activityItems(
      where: { companyGuid: { _eq: $companyGuid } }
      orderBy: { createdAt: DESC }
      limit: 50
    ) {
      activityItemGuid
      activityItemType
      content
      createdAt
    }
  }
`)

export const ACTIVITY_ITEMS_BY_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query FetchActivityItemsByAccountGuid(
    $companyGuid: uuid = ""
    $accountGuid: uuid = ""
  ) {
    activityItems(
      where: {
        companyGuid: { _eq: $companyGuid }
        accountGuid: { _eq: $accountGuid }
      }
      orderBy: { createdAt: DESC }
      limit: 50
    ) {
      activityItemGuid
      activityItemType
      content: content
      createdAt
    }
  }
`)

export const ACTIVITY_ITEMS_BY_JOB_QUERY = gql(/* GraphQL */ `
  query FetchActivityItemsByJobGuid(
    $companyGuid: uuid = ""
    $jobGuid: uuid = ""
  ) {
    activityItems(
      where: { companyGuid: { _eq: $companyGuid }, jobGuid: { _eq: $jobGuid } }
      orderBy: { createdAt: DESC }
      limit: 50
    ) {
      activityItemGuid
      activityItemType
      content
      createdAt
    }
  }
`)
