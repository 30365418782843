export enum NotificationPreferenceType {
  Sms = 'SMS',
  Email = 'EMAIL',
  None = 'NONE',
}

export const NotificationPreferenceTypeDisplayNames = {
  [NotificationPreferenceType.Sms]: 'SMS',
  [NotificationPreferenceType.Email]: 'Email',
  [NotificationPreferenceType.None]: 'None',
} as const

export type NotificationPreferenceTypeDisplayName =
  (typeof NotificationPreferenceTypeDisplayNames)[keyof typeof NotificationPreferenceTypeDisplayNames]

export const toNotificationPreferenceTypeDisplayName = (type: NotificationPreferenceType): string => {
  switch (type) {
    case NotificationPreferenceType.Sms:
      return 'SMS'
    case NotificationPreferenceType.Email:
      return 'Email'
    case NotificationPreferenceType.None:
      return 'None'
    default:
      return 'Unknown'
  }
}

export const defaultNotificationPreferenceType = NotificationPreferenceType.None

export enum NotificationType {
  SMS = 'SMS',
  EMAIL = 'Email',
}
