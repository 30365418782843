import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { useSubscription } from 'urql'
import { BehindFeatureFlag } from '../../../components/BehindFeatureFlag'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import LinkedNotesListLoader from '../../../components/Notes/LinkedNotesList/LinkedNotesList'
import { NotesListCard } from '../../../components/Notes/NotesListCard/NotesListCard'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { LINKED_JOB_DATA_SUBSCRIPTION } from '../JobDetailsPage.gql'

type NotesListProps = {
  jobGuid: string
  editable: boolean
  className?: string
}

export const NotesList = React.memo<NotesListProps>(
  ({ jobGuid, editable, className }) => {
    const linkedJobsEnabled = useFeatureFlag('linkedJobs')

    const linkedJobDataQuery = useSubscription({
      query: LINKED_JOB_DATA_SUBSCRIPTION,
      variables: { jobGuid },
      pause: !linkedJobsEnabled,
    })

    return (
      <div className={classNames('flex flex-col', className)}>
        <NotesListCard
          linkData={{
            primaryNoteType: 'JOB',
            jobGuid,
          }}
          editable={editable}
          title=""
        />

        <BehindFeatureFlag
          enabledFeatureFlag="linkedJobs"
          render={
            <GqlQueryLoader
              query={linkedJobDataQuery}
              render={data => (
                <RenderIf
                  if={
                    data.jobsWithLinkedJob.length > 0 &&
                    data.jobsWithLinkedJob[0]?.linkNotes
                  }
                >
                  <div className="mb-5 bg-bz-gray-300 px-3 py-2">
                    <span className="text-sm font-semibold text-bz-gray-900">
                      Linked from{' '}
                      <Link
                        to={`/jobs/${data.jobsWithLinkedJob[0]?.linked_job.jobGuid}`}
                      >
                        Job #{data.jobsWithLinkedJob[0]?.linked_job.displayId}
                      </Link>
                    </span>
                  </div>

                  <LinkedNotesListLoader
                    linkData={{
                      primaryNoteType: 'JOB',
                      jobGuid: data.jobsWithLinkedJob[0]?.linked_job.jobGuid,
                    }}
                    noteLinks={{
                      jobGuid: data.jobsWithLinkedJob[0]?.linked_job.jobGuid,
                    }}
                    editable={false}
                  />
                </RenderIf>
              )}
            />
          }
        />
      </div>
    )
  },
)
