import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { CompanyGuid, ForCompanyUser } from '../Company/Company'
import { EquipmentType } from '../Equipment/EquipmentType'
import { Guid } from '../common-schemas'

export type EquipmentCoverageDefinitionGuid = Guid
export type EquipmentCoverageDefinitionGuidContainer = {
  readonly maintenancePlanEquipmentCoverageDefinitionGuid: EquipmentCoverageDefinitionGuid
}

export type EquipmentCoveragePricing = EquipmentCoverageDefinitionGuidContainer & {
  readonly planPricePerServiceUsd: number
  readonly regularPricePerServiceUsd: number
}

export type EquipmentCoverageDefinition = EquipmentCoverageDefinitionGuidContainer &
  EquipmentCoveragePricing & {
    readonly companyGuid: CompanyGuid
    readonly equipmentType: EquipmentType
    readonly isAvailableForSale: boolean
  }

export const equipmentCoverageDefinitionSchema = z.object({
  maintenancePlanEquipmentCoverageDefinitionGuid: guidSchema,
  companyGuid: guidSchema,
  equipmentType: z.nativeEnum(EquipmentType),
  isAvailableForSale: z.boolean(),
  planPricePerServiceUsd: z.number().min(0),
  regularPricePerServiceUsd: z.number().min(0),
})

export type EquipmentCoverageDefinitionWriter = AsyncFn<ForCompanyUser<EquipmentCoverageDefinition>>
