import { BzDateFns, IsoDateString, TimeZoneId } from '@breezy/shared'
import React from 'react'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'

const formatDistanceToNowAbbreviated = (
  date: IsoDateString,
  tzId: TimeZoneId,
) => {
  return BzDateFns.formatDistanceToNowStrict(BzDateFns.parseISO(date, tzId), {
    addSuffix: true,
  })
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' weeks', 'w')
    .replace(' week', 'w')
    .replace(' months', 'mo')
    .replace(' month', 'mo')
    .replace(' years', 'y')
    .replace(' year', 'y')
}

type TimeAgoProps = {
  prefix?: string
  date: IsoDateString
}

export const TimeAgo = React.memo<TimeAgoProps>(({ prefix, date }) => {
  const tzId = useExpectedCompanyTimeZoneId()
  return (
    <div className="text-xs text-bz-gray-800">
      {prefix ? prefix : ''}
      {formatDistanceToNowAbbreviated(date, tzId)}
    </div>
  )
})
