import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useCallback, useState } from 'react'
import { useMessage } from '../../utils/antd-utils'
import { DOWNLOADABLE_INVOICE_DATA_FETCHING_CLASS_NAME } from './DownloadableInvoice'

const INVOICE_RENDER_WAIT_TIME = 1000
const INVOICE_RENDER_WAIT_RETRIES = 3

const LETTER_PAPER_WIDTH = 816
const LETTER_PAPER_HEIGHT = 1056
const PAPER_HEIGHT_TO_WIDTH_RATIO = LETTER_PAPER_HEIGHT / LETTER_PAPER_WIDTH

type DownloadableInvoiceArgs = {
  fileName: string
  openInNewTab?: boolean
}

export const useDownloadableInvoice = (
  ref: React.RefObject<HTMLDivElement>,
) => {
  const message = useMessage()

  const [generating, setGenerating] = useState(false)

  const downloadInvoice = useCallback(
    (args: DownloadableInvoiceArgs) => {
      setGenerating(true)

      setTimeout(async () => {
        let retries = INVOICE_RENDER_WAIT_RETRIES
        while (retries) {
          const elem = ref.current
          const fetchingElem = elem?.querySelector(
            `.${DOWNLOADABLE_INVOICE_DATA_FETCHING_CLASS_NAME}`,
          )
          if (elem && !fetchingElem) {
            break
          }
          await new Promise(resolve =>
            setTimeout(resolve, INVOICE_RENDER_WAIT_TIME),
          )
          retries--
        }
        if (!retries) {
          console.error(
            'Invoice element either never rendered or never finished fetching.',
          )
          message.error('Could not download PDF.')
          setGenerating(false)
          return
        }

        // I know from the while loop above that this thing is there
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const elem = ref.current!

        // Quick fix for the styling of ordered (numbered) lists in the pdf. It's still not pretty but better
        // than before
        const listItems = elem.querySelectorAll('ol li')
        listItems.forEach(item => {
          if ((item as HTMLElement).style) {
            ;(item as HTMLElement).style.paddingLeft = '10px'
          }
        })

        const dimensions = elem.getBoundingClientRect()

        try {
          const { width, height } = dimensions
          const pageWidth = width
          const pageHeight = pageWidth * PAPER_HEIGHT_TO_WIDTH_RATIO

          const scale = LETTER_PAPER_WIDTH / pageWidth

          // Put invisible links on top of the links in the image
          const anchorsPerPage: Record<
            string,
            {
              x: number
              y: number
              width: number
              height: number
              url: string
            }[]
          > = {}
          const anchors = elem.querySelectorAll('a')
          for (const anchor of anchors) {
            const href = anchor.getAttribute('href')
            if (href) {
              const rect = anchor.getBoundingClientRect()

              const page = Math.floor(rect.y / pageHeight)

              anchorsPerPage[`${page}`] = [
                ...(anchorsPerPage[page] || []),
                {
                  x: -dimensions.x + rect.x,
                  y: (-dimensions.y + rect.y) % pageHeight,
                  width: rect.width,
                  height: rect.height,
                  url: href,
                },
              ]
            }
          }

          // `html2canvas` appends several elements to `document.body` to measure stuff (like default font size etc). It
          // deletes them after, but the "damage is done". Appending them to the body, based on our layout, will create
          // a vertical scroll bar. That now creates a horizontal scrollbar because the content is the width of the view
          // and now a scrollbar's width of space is no longer visible. But even though the elements are deleted, we
          // STILL get the vertical scrollbar because now we have this horizontal one and the same rules apply. So
          // downloading the PDF causes pointless scrollbars on the page. The solution is to put `overflow: hidden` on
          // the body. That's fine because our content is always the height and width of the viewport and has its own
          // `overflow: auto`s...EXCEPT on certain pages. Namely, basic pages need to have the entire body scroll for
          // mobile Safari to properly "hide the address bar". So setting the overflow breaks that. So we'll do this
          // awful thing. We make it overflow hidden just while we generate. Now those scroll bars that only really
          // exist because of each other never appear. After everything we unset this CSS and it's like nothing ever
          // happened.
          document.body.style.overflow = 'hidden'

          const pdf = new jsPDF({
            format: 'letter',
            unit: 'px',
            hotfixes: ['px_scaling'],
            compress: true,
          })

          let pageCount = 0
          while (pageCount * pageHeight < height) {
            if (pageCount > 0) {
              pdf.addPage('letter', 'portrait')
            }

            const canvas = await html2canvas(elem, {
              allowTaint: true,
              useCORS: true,
              width: pageWidth,
              height: pageHeight,
              y: pageCount * pageHeight,
              scale,
            })
            const base64 = canvas.toDataURL('image/jpeg')

            pdf.addImage(
              base64,
              'JPEG',
              0,
              0,
              LETTER_PAPER_WIDTH,
              LETTER_PAPER_HEIGHT,
            )

            const anchors = anchorsPerPage[`${pageCount}`]

            if (anchors) {
              for (const anchor of anchors) {
                pdf.link(
                  anchor.x * scale,
                  anchor.y * scale,
                  anchor.width * scale,
                  anchor.height * scale,
                  { url: anchor.url },
                )
              }
            }

            pageCount++
          }

          if (args.openInNewTab) {
            pdf.output('dataurlnewwindow', { filename: `${args.fileName}.pdf` })
          } else {
            pdf.save(`${args.fileName}.pdf`)
          }
        } catch (e) {
          console.error('Failed to generate PDF', e)
          message.error('Could not download PDF.')
        }
        // This is the part where we undo our secret little scroll bar fix.
        document.body.style.overflow = ''

        setGenerating(false)
      }, 0)
    },
    [message, ref],
  )

  return [downloadInvoice, generating] as const
}
