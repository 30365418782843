import { LinkedNoteViewModel, User } from '@breezy/shared'
import { faEdit, faTags, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { gray9 } from '../../../themes/theme'
import { DateFormat, DateView } from '../../Dates'
import PersonAvatar from '../../PersonAvatar/PersonAvatar'

import { NoteLinkData, isNullish } from '@breezy/shared'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import FaIconButton from '../../../elements/FaIconButton/FaIconButton'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import { trpc } from '../../../hooks/trpc'
import { useExpectedPrincipal } from '../../../providers/PrincipalUser'
import { Styled } from '../../../utils/Stylable'
import { useMessage } from '../../../utils/antd-utils'
import { lightUserColor, userColor } from '../../../utils/color-utils'
import { m } from '../../../utils/react-utils'
import { LoadingSpinner } from '../../LoadingSpinner'
import { NoteForm } from '../NoteForm'
import './NoteRowItem.less'

type NoteRowItemProps = Styled<{
  note: LinkedNoteViewModel
  linkData: NoteLinkData
  userMap: Record<string, User>
  onMutate: () => void
  highlighted?: boolean
  editable?: boolean
}>

export const NoteRowItem = m<NoteRowItemProps>(
  ({
    note,
    userMap,
    className,
    highlighted,
    onMutate,
    linkData,
    editable = true,
  }) => {
    const message = useMessage()
    const [isMutating, setIsMutating] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const avatarString = note.avatarAltShortString || note.userDisplayName[0]
    const avatarAltColor = useMemo(
      () => userColor(note.userGuid),
      [note.userGuid],
    )
    const userGuid = useExpectedPrincipal().userGuid
    const isMyNote = useMemo(
      () => userGuid === note.userGuid,
      [userGuid, note.userGuid],
    )

    const taggedUsers = useMemo(() => {
      const taggedUsers: { id: string; displayName: string; color: string }[] =
        []
      for (const taggedUserGuid of note.taggedUsers) {
        const userInfo = userMap[taggedUserGuid]
        if (userInfo) {
          taggedUsers.push({
            id: taggedUserGuid,
            displayName: `${userInfo.firstName} ${userInfo.lastName}`,
            color: lightUserColor(
              `${userInfo.firstName[0]}${userInfo.lastName[0]}`,
            ),
          })
        }
      }
      return taggedUsers
    }, [note.taggedUsers, userMap])

    // TODO: Confirm Delete with Pop Confirm
    const deleteNoteMut = trpc.notes['notes:delete'].useMutation()
    const deleteNote = useCallback(async () => {
      setIsMutating(true)
      try {
        await deleteNoteMut.mutateAsync({ id: note.noteGuid })
        setIsDeleted(true)
        setIsMutating(false)
        message.success('Note successfully deleted')
        onMutate?.()
      } catch (e) {
        setIsMutating(false)
        message.error(`Failed to delete note`)
      }
    }, [deleteNoteMut, onMutate, note.noteGuid, message])

    const startEditNote = useCallback(() => {
      setIsEditing(true)
    }, [])

    const onEditFinished = useCallback(() => {
      setIsEditing(false)
      onMutate?.()
    }, [onMutate])

    const canDelete = useMemo(() => editable, [editable])
    const canEdit = useMemo(() => editable && isMyNote, [editable, isMyNote])

    const deactivatedAt = useMemo(() => {
      const user = userMap[userGuid]
      return user?.deactivatedAt
    }, [userGuid, userMap])

    if (isDeleted) return null

    if (isMutating)
      return (
        <div className="min-h-[80px]">
          <LoadingSpinner />
        </div>
      )

    return (
      <div
        className={cn(
          'pb-3 pt-2',
          {
            'note-row-item-flashing': highlighted,
          },
          className,
        )}
      >
        <h3 className="row two-sides mb-1">
          <div className="row">
            <PersonAvatar
              userGuid={userGuid}
              deactivatedAt={deactivatedAt}
              avatarAltShortString={avatarString}
              avatarAltColor={avatarAltColor}
            />

            <span className="center-v ml1" style={{ color: gray9 }}>
              {note.userDisplayName}
            </span>

            <span className="note-row-item-date-container center-v">
              <DateView
                isoWithOffsetTimestamp={note.createdAt}
                format={DateFormat['MMM d, yyyy']}
                zone={{ type: 'company-timezone-of-principal-user' }}
              />
            </span>
          </div>

          {!isEditing && (
            <div className="row">
              {canEdit && (
                <FaIconButton icon={faEdit} onClick={startEditNote} />
              )}
              {canDelete && (
                <FaIconButton icon={faTrash} onClick={deleteNote} />
              )}
            </div>
          )}
        </h3>
        {isEditing && (
          <NoteForm
            linkData={linkData}
            note={note}
            addText="Update Note"
            onSuccess={onEditFinished}
          />
        )}
        {!isEditing && (
          <div className="note-row-item-content">
            <HtmlRenderer htmlContent={note.value} />
            <div className="flex flex-row space-x-2 overflow-x-auto">
              {note.noteAttachments
                ?.filter(attachment => !isNullish(attachment.photo))
                .map(attachment => (
                  <img
                    key={attachment.noteAttachmentGuid}
                    src={attachment.photo?.cdnUrl}
                    alt="attachment"
                    className="h-32 w-32 flex-auto flex-shrink-0 flex-grow-0 rounded-sm object-contain"
                  />
                ))}
            </div>
            <div className="flex flex-row space-x-2 overflow-x-auto">
              {note.noteAttachments
                ?.filter(attachment => !isNullish(attachment.file))
                .map((attachment, idx) => (
                  <div key={attachment.noteAttachmentGuid}>
                    <FontAwesomeIcon icon={faPaperclip} />
                    <a
                      className="ml-1 flex-auto flex-shrink-0 flex-grow-0"
                      href={attachment.file?.cdnUrl}
                      download={`Attachment ${idx + 1}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Attachment {idx}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        )}
        {note.taggedUsers.length > 0 && (
          <div className="mt-1 flex flex-wrap items-center gap-2">
            <FontAwesomeIcon icon={faTags} />
            {taggedUsers.map(({ id, displayName, color }) => (
              <div
                key={id}
                className="whitespace-nowrap rounded-lg px-1.5 py-0.5 text-xs"
                style={{ backgroundColor: color }}
              >
                {displayName}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  },
)
