import {
  AccountLocation,
  Guid,
  InstalledEquipmentSummary,
  isNullish,
} from '@breezy/shared'
import { Button, Tabs, Typography } from 'antd'
import classNames from 'classnames'
import React, { ReactNode, memo, useCallback, useMemo, useState } from 'react'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { Banner } from '../../elements/Banner/Banner'
import useIsMobile from '../../hooks/useIsMobile'
import { LocationOptionMaintenancePlanCard } from './LocationOptionMaintenancePlanCard'
import { InstalledEquipmentCard } from './components/InstalledEquipmentCard'
import { LocationCardHeader } from './components/LocationCardHeader'
import { useLocationActiveMaintenancePlan } from './hooks/useLocationActiveMaintenancePlan'

type LocationOptionProps = {
  accountLocation: AccountLocation
  onLocationSelected: (locationGuid: string) => void
  goToNextStep: () => void
  dataTestId?: string
}

type EquipmentCardsProps = {
  installedEquipments: InstalledEquipmentSummary[]
  onRemove?: (installedEquipmentGuid: Guid) => void
  className?: string
  emptyStateMessage?: React.ReactNode
  showEmptyState?: boolean
}

export const EquipmentCards: React.FC<EquipmentCardsProps> = ({
  installedEquipments,
  onRemove,
  className,
  emptyStateMessage,
  showEmptyState = false,
}) => {
  if (installedEquipments.length === 0) {
    return showEmptyState ? (
      <Banner color="gray">
        <div className="w-full text-sm text-bz-text-tertiary">
          {emptyStateMessage ??
            'No equipment has been added to this service location yet.'}
        </div>
      </Banner>
    ) : null
  }

  const rows = Math.ceil(installedEquipments.length / 2)

  return (
    <div className={classNames('grid gap-4', className)}>
      {Array.from({ length: rows }, (_, i) => (
        <div key={i} className="grid grid-cols-1 gap-3 md:grid-cols-2">
          {installedEquipments
            .slice(i * 2, i * 2 + 2)
            .filter(e => !isNullish(e) && !isNullish(e.installedEquipmentGuid))
            .map((equipment, index) => (
              <div key={equipment.installedEquipmentGuid}>
                <InstalledEquipmentCard
                  installedEquipment={equipment}
                  onRemove={onRemove}
                  dataTestId={`installed-equipment-card-${i}-${index}`}
                />
              </div>
            ))}
        </div>
      ))}
    </div>
  )
}

export const LocationOptionCard = memo<LocationOptionProps>(
  ({ accountLocation, onLocationSelected, goToNextStep, dataTestId }) => {
    const isMobile = useIsMobile()
    const [
      isConfirmingAdditionalMaintenancePlanModal,
      setIsConfirmingAdditionalMaintenancePlanModal,
    ] = useState(false)

    const allInstalledEquipment = useMemo(
      () => accountLocation.location.installedEquipment ?? [],
      [accountLocation.location.installedEquipment],
    )

    const { activeMaintenancePlan } = useLocationActiveMaintenancePlan(
      accountLocation.location.locationGuid,
    )

    const hasActiveMaintenancePlan = useMemo(
      () => !isNullish(activeMaintenancePlan),
      [activeMaintenancePlan],
    )

    const installedEquipmentCoveredByMaintenancePlan = useMemo(() => {
      if (hasActiveMaintenancePlan && allInstalledEquipment.length > 0) {
        return allInstalledEquipment.filter(eq => {
          return activeMaintenancePlan?.coveredInstalledEquipmentGuids.includes(
            eq.installedEquipmentGuid,
          )
        })
      } else {
        return []
      }
    }, [
      activeMaintenancePlan?.coveredInstalledEquipmentGuids,
      allInstalledEquipment,
      hasActiveMaintenancePlan,
    ])

    const installedEquipmentNotCoveredByMaintenancePlan = useMemo(() => {
      if (hasActiveMaintenancePlan && allInstalledEquipment.length > 0) {
        return allInstalledEquipment.filter(eq => {
          return !activeMaintenancePlan?.coveredInstalledEquipmentGuids.includes(
            eq.installedEquipmentGuid,
          )
        })
      } else {
        return []
      }
    }, [
      hasActiveMaintenancePlan,
      allInstalledEquipment,
      activeMaintenancePlan?.coveredInstalledEquipmentGuids,
    ])

    const equipmentPanelContent: ReactNode = useMemo(() => {
      if (allInstalledEquipment.length === 0) {
        return (
          <Banner color="gray">
            <div className="w-full text-sm text-bz-text-tertiary">
              No equipment has been added to this service location yet.
            </div>
          </Banner>
        )
      } else if (hasActiveMaintenancePlan) {
        return (
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Covered by Plan (${installedEquipmentCoveredByMaintenancePlan.length})`,
                children: (
                  <EquipmentCards
                    emptyStateMessage="No equipment is covered by the active maintenance plan."
                    installedEquipments={
                      installedEquipmentCoveredByMaintenancePlan ?? []
                    }
                    showEmptyState
                  />
                ),
              },
              {
                key: '2',
                label: `Not Covered (${installedEquipmentNotCoveredByMaintenancePlan.length})`,
                children: (
                  <EquipmentCards
                    showEmptyState
                    emptyStateMessage="All equipment is covered by the active maintenance plan."
                    installedEquipments={
                      installedEquipmentNotCoveredByMaintenancePlan ?? []
                    }
                  />
                ),
              },
            ]}
          />
        )
      } else {
        return (
          <EquipmentCards
            showEmptyState
            installedEquipments={allInstalledEquipment ?? []}
          />
        )
      }
    }, [
      allInstalledEquipment,
      hasActiveMaintenancePlan,
      installedEquipmentCoveredByMaintenancePlan,
      installedEquipmentNotCoveredByMaintenancePlan,
    ])

    const select = useCallback(() => {
      onLocationSelected(accountLocation.location.locationGuid)
      goToNextStep()
    }, [
      accountLocation.location.locationGuid,
      goToNextStep,
      onLocationSelected,
    ])

    const onClickSelect = useCallback(() => {
      if (!hasActiveMaintenancePlan) {
        select()
      } else {
        setIsConfirmingAdditionalMaintenancePlanModal(true)
      }
    }, [
      hasActiveMaintenancePlan,
      setIsConfirmingAdditionalMaintenancePlanModal,
      select,
    ])

    return (
      <>
        <SectionedCard
          dataTestId={dataTestId}
          anchoredFooter={
            <Button
              onClick={onClickSelect}
              type="primary"
              className="mt-3 w-full"
              size="large"
            >
              Select This Location
            </Button>
          }
          sections={[
            {
              content: (
                <LocationCardHeader
                  accountLocation={accountLocation}
                  numTagsToShow={2}
                />
              ),
            },
            <div className="max-h-[508px] overflow-auto">
              <Typography.Title level={5} className="mb-3">
                Maintenance Plan
              </Typography.Title>
              <LocationOptionMaintenancePlanCard
                locationActiveMaintenancePlan={activeMaintenancePlan}
              />
              <Typography.Title level={5} className="mb-3 mt-4">
                Equipment
              </Typography.Title>
              {equipmentPanelContent}
            </div>,
          ]}
        />
        {isConfirmingAdditionalMaintenancePlanModal && (
          <OnsiteConfirmModal
            header="Location already has active plan!"
            onCancel={() =>
              setIsConfirmingAdditionalMaintenancePlanModal(false)
            }
            onConfirm={() => {
              select()
            }}
            confirmText={isMobile ? 'Yes' : 'Yes, I want to add another'}
            cancelText={isMobile ? 'No' : 'No, I do not'}
          >
            Are you sure you want to add an additional active maintenance plan
            for this location?
          </OnsiteConfirmModal>
        )}
      </>
    )
  },
)
