import { gql } from '../../generated'

export const FETCH_CONTACTS_BY_PHONE_NUMBER_GQL = gql(`
  query FetchContactLiteByPhoneNumber($phoneNumber: String, $companyGuid: uuid) {
    phoneNumbers(where: {phoneNumber: {_eq: $phoneNumber}, _and: {companyGuid: {_eq: $companyGuid}}}) {
      contacts {
        contactGuid
        fullName
        firstName
        lastName
        accountContacts {
          contact {
            fullName
          }
          accountGuid
        }
      }
      contactsAdditionalPhone {
        contactGuid
        accountContacts {
          accountGuid
          contact {
            fullName
          }
        }
        fullName
        firstName
        lastName
      }
    }
  }
`)
