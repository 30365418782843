import {
  JobOutcomesFormSchema,
  User,
  formatUsc,
  isNullish,
  uscMultiply,
  usdToUsCents,
} from '@breezy/shared'
import { Button, Col, Row } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import OutcomeCard from './OutcomeCard'
import TechnicianBonusPopoverForm from './TechnicianBonusPopoverForm'
import TechnicianCommissionRatePopoverForm from './TechnicianCommissionRatePopoverForm'
import { useCommissionableBaseUsc } from './hooks/useCommissionableBaseUsc'
import { useJobCostsDeductionUsc } from './hooks/useJobCostsDeductionUsc'
import { useOverheadAllocationDeduction } from './hooks/useOverheadAllocationDeduction'
import { JobDetailsForOutcomes } from './job-outcome-modal-types'

type UserRevenueAttributionTotalCardProps = {
  user: User
  job: JobDetailsForOutcomes
  disableEditBonusAndCommissionButtons: boolean
  onOpenEditPopover?: () => void
  onCloseEditPopover?: () => void
  totalSoldRevenueUsc: number
  totalEarnedRevenueUsc: number
}

const UserRevenueAttributionTotalCard: React.FC<
  UserRevenueAttributionTotalCardProps
> = ({
  user,
  job,
  disableEditBonusAndCommissionButtons,
  onOpenEditPopover,
  onCloseEditPopover,
  totalSoldRevenueUsc,
  totalEarnedRevenueUsc,
}) => {
  const form = useFormContext<JobOutcomesFormSchema>()
  const [bonusPopoverOpen, setBonusPopoverOpen] = useState(false)
  const [commissionRatePopoverOpen, setCommissionRatePopoverOpen] =
    useState(false)

  const userGuid = user.userGuid

  const technicianGuidToCommissionAndBonusMap = form.watch(
    'technicianGuidToCommissionAndBonusMap',
    {},
  )

  // Hacky solution for now - The watched map value above can change without triggering the `useEffect`
  // below because React only runs a shallow comparison on objects
  const technicianGuidToCommissionAndBonusMapJsonString = JSON.stringify(
    technicianGuidToCommissionAndBonusMap,
  )

  const [commissionPercent, bonusUsc] = useMemo(() => {
    if (technicianGuidToCommissionAndBonusMap[userGuid]) {
      const commissionPercent =
        technicianGuidToCommissionAndBonusMap[userGuid].commissionPercent ?? 0
      const bonusUsc = usdToUsCents(
        technicianGuidToCommissionAndBonusMap[userGuid].bonusUsd ?? 0,
      )

      return [commissionPercent, bonusUsc]
    }

    return [null, null]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    technicianGuidToCommissionAndBonusMap,
    userGuid,
    technicianGuidToCommissionAndBonusMapJsonString,
  ])

  const onCancelTechnicianBonusPopoverForm = useCallback(() => {
    onCloseEditPopover?.()
    setBonusPopoverOpen(false)
  }, [onCloseEditPopover])

  const [overheadAllocationDeductionUsc] = useOverheadAllocationDeduction(
    form,
    totalSoldRevenueUsc,
  )
  const jobCostsDeductionUsc = useJobCostsDeductionUsc(
    form,
    totalSoldRevenueUsc,
  )
  const totalDeductionsUsc = useMemo(
    () => overheadAllocationDeductionUsc + jobCostsDeductionUsc,
    [jobCostsDeductionUsc, overheadAllocationDeductionUsc],
  )
  const commissionableBaseUsc = useCommissionableBaseUsc(
    form,
    totalSoldRevenueUsc,
  )
  const commissionUsc = useMemo(() => {
    if (isNullish(commissionPercent)) {
      return 0
    }

    return uscMultiply(commissionableBaseUsc, commissionPercent / 100)
  }, [commissionPercent, commissionableBaseUsc])

  const onSubmitBonusPopoverForm = useCallback(
    (bonusUsd: number | undefined) => {
      onCloseEditPopover?.()
      setBonusPopoverOpen(false)

      if (!isNullish(bonusUsd)) {
        form.setValue(
          `technicianGuidToCommissionAndBonusMap.${userGuid}.bonusUsd`,
          bonusUsd,
        )
        form.resetField(
          `technicianGuidToCommissionAndBonusMap.${userGuid}.bonusUsd`,
          {
            defaultValue: bonusUsd,
          },
        )
      }
    },
    [form, onCloseEditPopover, userGuid],
  )

  const onSubmitCommissionRatePopoverForm = useCallback(
    (commissionPercent: number | undefined) => {
      onCloseEditPopover?.()
      setCommissionRatePopoverOpen(false)

      if (!isNullish(commissionPercent)) {
        form.setValue(
          `technicianGuidToCommissionAndBonusMap.${userGuid}.commissionPercent`,
          commissionPercent,
        )
        form.resetField(
          `technicianGuidToCommissionAndBonusMap.${userGuid}.commissionPercent`,
          {
            defaultValue: commissionPercent,
          },
        )
      }
    },
    [form, onCloseEditPopover, userGuid],
  )

  return (
    <OutcomeCard
      title={`${user.firstName} ${user.lastName}`}
      cardClassName="h-full"
    >
      <Col className="flex h-[calc(100%-24px)] flex-col justify-between">
        <div>
          <Row justify="space-between" className="mb-1">
            <Col>Sold Revenue</Col>
            <Col>{formatUsc(totalSoldRevenueUsc)}</Col>
          </Row>

          {!isNullish(commissionPercent) && commissionPercent > 0 && (
            <>
              <Row
                justify="space-between"
                style={{ marginBottom: '4px', color: 'rgba(0, 0, 0, 0.45)' }}
              >
                <Col>Deductions</Col>
                <Col>{formatUsc(totalDeductionsUsc)}</Col>
              </Row>
              <Row
                justify="space-between"
                style={{ marginBottom: '4px', color: 'rgba(0, 0, 0, 0.45)' }}
              >
                <Col>Commissionable Base</Col>
                <Col>{formatUsc(commissionableBaseUsc)}</Col>
              </Row>
              <Row
                justify="space-between"
                style={{ marginBottom: '4px', color: 'rgba(0, 0, 0, 0.45)' }}
              >
                <Col>{`Commission (${Math.round(commissionPercent)}%)`}</Col>
                <Col>{formatUsc(commissionUsc)}</Col>
              </Row>
            </>
          )}

          <Row justify="space-between" className="mb-1">
            <Col>Earned Revenue</Col>
            <Col>{formatUsc(totalEarnedRevenueUsc)}</Col>
          </Row>

          {!isNullish(bonusUsc) && bonusUsc > 0 && (
            <Row justify="space-between" className="mb-1">
              <Col>Bonus</Col>
              <Col>{formatUsc(bonusUsc)}</Col>
            </Row>
          )}
        </div>

        <div>
          <Row>
            <TechnicianBonusPopoverForm
              initialBonusUsd={
                !isNullish(
                  form.formState.defaultValues
                    ?.technicianGuidToCommissionAndBonusMap,
                )
                  ? form.formState.defaultValues
                      ?.technicianGuidToCommissionAndBonusMap[userGuid]
                      ?.bonusUsd
                  : undefined
              }
              open={bonusPopoverOpen}
              onCancel={onCancelTechnicianBonusPopoverForm}
              onSubmit={onSubmitBonusPopoverForm}
            >
              <Button
                className="pl-0 pr-0"
                type="link"
                onClick={() => {
                  onOpenEditPopover?.()
                  setBonusPopoverOpen(true)
                }}
                disabled={
                  disableEditBonusAndCommissionButtons ||
                  commissionRatePopoverOpen ||
                  bonusPopoverOpen
                }
              >
                Bonus
              </Button>
            </TechnicianBonusPopoverForm>

            <div className="px-0.5 pb-0 pt-1 text-bz-gray-600">{'•'}</div>

            <TechnicianCommissionRatePopoverForm
              initialCommissionPercent={
                !isNullish(
                  form.formState.defaultValues
                    ?.technicianGuidToCommissionAndBonusMap,
                )
                  ? form.formState.defaultValues
                      ?.technicianGuidToCommissionAndBonusMap[userGuid]
                      ?.commissionPercent
                  : undefined
              }
              open={commissionRatePopoverOpen}
              onCancel={() => {
                onCloseEditPopover?.()
                setCommissionRatePopoverOpen(false)
              }}
              onSubmit={onSubmitCommissionRatePopoverForm}
            >
              <Button
                style={{ paddingLeft: '0px' }}
                type="link"
                onClick={() => {
                  onOpenEditPopover?.()
                  setCommissionRatePopoverOpen(true)
                }}
                disabled={
                  disableEditBonusAndCommissionButtons ||
                  commissionRatePopoverOpen ||
                  bonusPopoverOpen
                }
              >
                Commission
              </Button>
            </TechnicianCommissionRatePopoverForm>
          </Row>
        </div>
      </Col>
    </OutcomeCard>
  )
}

export default UserRevenueAttributionTotalCard
