import { toAssignedAppointmentViewModel } from '@breezy/backend/src/application-types'
import {
  TechAppointmentAndBlocksViewModel,
  TechnicianCapacityBlock,
} from '@breezy/shared'
import { useSubscription } from 'urql'
import { gql } from '../../generated'
import {
  JobAppointmentsBoolExp,
  TechnicianCapacityBlocksBoolExp,
} from '../../generated/user/graphql'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'

const FETCH_TECH_BLOCKS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription FetchTechScheduleBlocks(
    $where: TechnicianCapacityBlocksBoolExp
  ) {
    blocks: technicianCapacityBlocks(where: $where) {
      guid: technicianCapacityBlockGuid
      end
      reasonDescription
      reasonType
      start
      userGuids
      recurrenceRule
      recurrenceRuleExceptions
    }
  }
`)

const FETCH_APPOINTMENT_VIEW_MODEL_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription FetchAppointmentViewModelLiveQuery(
    $companyGuid: uuid!
    $jobAppointmentsWhere: JobAppointmentsBoolExp!
  ) {
    jobAppointments(
      where: {
        _and: [
          $jobAppointmentsWhere
          { job: { companyGuid: { _eq: $companyGuid } } }
        ]
      }
    ) {
      jobAppointmentGuid
      appointmentReferenceNumber
      appointmentWindowStart
      appointmentWindowEnd
      appointmentType
      description
      cancellationStatus {
        canceled
        jobAppointmentGuid
      }
      confirmationStatus {
        confirmed
        jobAppointmentGuid
      }
      job {
        jobGuid
        companyGuid
        accountGuid
        displayId
        installProjectType
        summary
        jobType {
          ...JobType
        }
        pointOfContact {
          ...Contact
        }
        equipmentTypeJobLinks {
          ...EquipmentTypeJobsLinks
        }
        location {
          locationGuid
          companyGuid
          displayName
          estimatedSquareFootage
          estimatedBuildDate
          propertyType
          municipality
          maintenancePlans {
            ...MaintenancePlanMinimal
          }
          address {
            addressGuid
            canonicalFullAddress
            line1
            line2
            city
            stateAbbreviation
            zipCode
            geoLocation
          }
          installedEquipment {
            installedEquipmentGuid
            locationGuid
            installedHvacSystemGuid
            averageLifeExpectancyYears
            description
            equipmentType
            estimatedEndOfLifeDate
            installationDate
            installationParty
            laborWarrantyEndDate
            laborWarrantyStartDate
            laborWarrantyTerms
            manufacturer
            manufacturerWarrantyEndDate
            manufacturerWarrantyStartDate
            manufacturerWarrantyTerms
            modelNumber
            operationalStatus
            serialNumber
          }
          installedHvacSystems {
            locationGuid
            filterSize
            friendlyName
            installedHvacSystemGuid
            notes
            zoningInfoJson
            installedEquipment {
              installedEquipmentGuid
              locationGuid
              installedHvacSystemGuid
              averageLifeExpectancyYears
              description
              equipmentType
              estimatedEndOfLifeDate
              installationDate
              installationParty
              laborWarrantyEndDate
              laborWarrantyStartDate
              laborWarrantyTerms
              manufacturer
              manufacturerWarrantyEndDate
              manufacturerWarrantyStartDate
              manufacturerWarrantyTerms
              modelNumber
              operationalStatus
              serialNumber
            }
          }
        }
        locationGuid
      }
      assignments {
        jobAppointmentAssignmentGuid
        technicianUserGuid
        assignmentStart
        assignmentEnd
        assignmentStatus {
          jobAppointmentAssignmentStatusGuid
          jobAppointmentAssignmentStatusType
          jobAppointmentAssignmentGuid
        }
        technician {
          firstName
          lastName
          emailAddress
          userGuid
        }
        jobAppointmentGuid
        jobGuid
      }
      jobGuid
    }
  }
`)

type useFetchAppointmentBlocksAndAppointmentsLiveQueryInput = {
  jobAppointmentsWhere: JobAppointmentsBoolExp
  techCapacityBlocksWhere?: TechnicianCapacityBlocksBoolExp
  pause?: boolean
}

export const useFetchAppointmentBlocksAndAppointmentsLiveQuery = ({
  jobAppointmentsWhere,
  techCapacityBlocksWhere,
  pause = false,
}: useFetchAppointmentBlocksAndAppointmentsLiveQueryInput) => {
  const companyGuid = useExpectedCompanyGuid()
  const userGuid = useExpectedUserGuid()

  const [jobAppointmentSubscription] = useSubscription({
    query: FETCH_APPOINTMENT_VIEW_MODEL_SUBSCRIPTION,
    variables: {
      companyGuid: companyGuid,
      jobAppointmentsWhere: jobAppointmentsWhere,
    },
    pause: !companyGuid || pause,
  })

  const [techBlockSubscription] = useSubscription({
    query: FETCH_TECH_BLOCKS_SUBSCRIPTION,
    variables: {
      where: techCapacityBlocksWhere,
    },
    pause: !companyGuid || pause || !techCapacityBlocksWhere,
  })

  const { data, error, fetching: loading } = jobAppointmentSubscription
  const {
    data: blockData,
    error: blockError,
    fetching: blockLoading,
  } = techBlockSubscription

  const blocks: TechnicianCapacityBlock[] = []
  for (const block of blockData?.blocks || []) {
    if (block.userGuids.includes(userGuid)) {
      blocks.push({
        ...block,
        reasonType: block.reasonType,
      })
    }
  }

  const result = {
    jobAppointmentSubscription,
    techBlockSubscription,
    data: {
      blocks,
      assignments: data?.jobAppointments.map(toAssignedAppointmentViewModel),
    } as TechAppointmentAndBlocksViewModel,
    error: error || blockError,
    loading: loading || blockLoading,
  }

  return result
}
