import {
  AbridgedFinancingInvoice,
  MAX_FINANCEABLE_AMOUNT_USD,
  MIN_FINANCEABLE_AMOUNT_USD,
  formatMoney,
  getServiceDescription,
  isFinanceable,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  createTsForm,
  createUniqueFieldSchema,
  useDescription,
  useStringFieldInfo,
  useTsController,
} from '@ts-react/form'
import { Divider, Form, Input, InputNumber, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../../../providers/PrincipalUser'
import { PrequalBanners } from '../../../FinancingSection/PrequalifiedBanners'
import PrequalStatusTag from '../../../PrequalStatusTag/PrequalStatusTag'
import FinancingAppFormWrapper from '../../FinancingAppFormWrapper'
import { getContactInvoiceMapKey } from '../../hooks/useFetchContactInvoiceLoanRecordAmountMap'
import { useFetchDisabledFinancingContacts } from '../../hooks/useFetchDisabledFinancingContacts'
import useIsMobileOrTablet from '../../hooks/useIsMobileOrTablet'
import ContactSelectionField from '../ContactSelectionField/ContactSelectionField'
import { ContactGuidSchema } from '../ContactSelectionField/ContactSelectionField.schema'
import { FinancingConfigureAppFormProps } from '../types'
import InvoiceSelectionField from './InvoiceSelectionField/InvoiceSelectionField'

const FinancingAmountUsdSchema = createUniqueFieldSchema(
  z
    .number()
    .min(MIN_FINANCEABLE_AMOUNT_USD, 'Financing amount must be at least $500')
    .max(
      MAX_FINANCEABLE_AMOUNT_USD,
      'Financing amount must be less than or equal to $25,000',
    ),
  'financingAmountUsd',
)

const InvoiceGuidSchema = createUniqueFieldSchema(
  z.string().nonempty('You must select an invoice'),
  'invoiceGuid',
)

const ServiceDescriptionSchema = createUniqueFieldSchema(
  z.string().nonempty('You must include a description'),
  'serviceDescription',
)

const HiddenInvoiceAmountUsdSchema = createUniqueFieldSchema(
  z.number(),
  'hiddenInvoiceAmountUsd',
)
const ConfigureLoanApplicationFormSchema = z
  .object({
    invoiceGuid: InvoiceGuidSchema.describe('Invoice'),
    serviceDescription: ServiceDescriptionSchema.describe(
      'Service Description',
    ),
    financingAmountUsd: FinancingAmountUsdSchema.describe('Financing Amount'),
    hiddenInvoiceAmountUsd: HiddenInvoiceAmountUsdSchema,
    contactGuid: ContactGuidSchema.describe('Contact'),
  })
  .superRefine((data, ctx) => {
    if (
      data.hiddenInvoiceAmountUsd > MIN_FINANCEABLE_AMOUNT_USD &&
      data.financingAmountUsd > data.hiddenInvoiceAmountUsd
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['financingAmountUsd'],
        message: `Financing amount must be less than or equal to the invoice amount of ${formatMoney(
          data.hiddenInvoiceAmountUsd,
        )}`,
      })
    }
  })

export type LoanApplicationConfiguration = z.infer<
  typeof ConfigureLoanApplicationFormSchema
>

type BaseTextFieldProps = React.PropsWithChildren

const BaseTextField = React.memo<BaseTextFieldProps>(({ children }) => {
  const { error } = useTsController<string>()

  const { label } = useStringFieldInfo()

  return (
    <Form.Item
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      {children}
    </Form.Item>
  )
})

type FinancingAmountUsdFieldProps = {
  disabled?: boolean
}
const FinancingAmountUsdField = React.memo<FinancingAmountUsdFieldProps>(
  ({ disabled = false }) => {
    const {
      field: { onChange, value },
    } = useTsController<string>()

    const { label, placeholder } = useDescription()
    const { error } = useTsController<string>()

    return (
      <Form.Item
        label={
          <span className="flex flex-row items-center gap-1">
            {label}
            {disabled && (
              <Tooltip
                title={
                  'A loan application already exists for this contact and invoice. To create an application for a different loan amount, please cancel the existing application.'
                }
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </Tooltip>
            )}
          </span>
        }
        validateStatus={error ? 'error' : 'success'}
        help={error?.errorMessage}
      >
        <InputNumber
          value={value}
          className="w-full"
          disabled={disabled}
          formatter={value =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          onChange={val => onChange(isNullish(val) ? undefined : val)}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  },
)

const ServiceDescriptionTextArea = React.memo(() => {
  const {
    field: { onChange, value, name },
  } = useTsController<string>()

  const { placeholder } = useStringFieldInfo()

  const length = value?.length ?? 0

  return (
    <BaseTextField>
      <Input.TextArea
        id={name}
        rows={2}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
      <div
        className={cn(
          'mt-1 text-xs text-bz-gray-700 transition-all ease-in-out',
        )}
      >
        {`${length} characters`}
      </div>
    </BaseTextField>
  )
})

const HiddenFormField = () => <></>

const mapping = [
  [InvoiceGuidSchema, InvoiceSelectionField],
  [FinancingAmountUsdSchema, FinancingAmountUsdField],
  [ServiceDescriptionSchema, ServiceDescriptionTextArea],
  [ContactGuidSchema, ContactSelectionField],
  [HiddenInvoiceAmountUsdSchema, HiddenFormField],
] as const

const ConfigureLoanApplicationForm = createTsForm(mapping, {
  FormComponent: FinancingAppFormWrapper,
})

export type FinancingConfigureLoanAppFormProps =
  FinancingConfigureAppFormProps<LoanApplicationConfiguration> & {
    invoices: AbridgedFinancingInvoice[]
    contactInvoiceAmountMap?: Record<string, number>
  }

const FinancingConfigureLoanAppForm =
  React.memo<FinancingConfigureLoanAppFormProps>(
    ({
      account,
      refetchAccount,
      invoices,
      contactInvoiceAmountMap = {},
      onCancel,
      onSubmit,
      config,
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const isMobileOrTablet = useIsMobileOrTablet()
      const company = useExpectedCompany()
      const form = useForm<LoanApplicationConfiguration>({
        resolver: zodResolver(ConfigureLoanApplicationFormSchema),
        mode: 'onChange',
        reValidateMode: 'onChange',
      })
      const disabledContactGuids = useFetchDisabledFinancingContacts(
        account.accountGuid,
      )

      const currentInvoiceGuid = form.watch('invoiceGuid')
      const currentContactGuid = form.watch('contactGuid')

      const existingContactInvoiceLoanAmountUsd = useMemo(() => {
        const key = getContactInvoiceMapKey(
          currentContactGuid,
          currentInvoiceGuid,
        )
        const usc = contactInvoiceAmountMap[key]

        return usc ? usCentsToUsd(usc) : undefined
      }, [contactInvoiceAmountMap, currentContactGuid, currentInvoiceGuid])

      const currentInvoice = useMemo(
        () =>
          invoices.find(invoice => invoice.invoiceGuid === currentInvoiceGuid),
        [invoices, currentInvoiceGuid],
      )

      useEffect(() => {
        const summaryResult = ServiceDescriptionSchema.safeParse(
          currentInvoice ? getServiceDescription(currentInvoice, tzId) : '',
        )
        if (summaryResult.success) {
          form.setValue('serviceDescription', summaryResult.data)
        }

        const financingAmountUsdResult = FinancingAmountUsdSchema.safeParse(
          existingContactInvoiceLoanAmountUsd ??
            currentInvoice?.totalAmountUsd ??
            MAX_FINANCEABLE_AMOUNT_USD,
        )

        if (financingAmountUsdResult.success) {
          form.setValue('financingAmountUsd', financingAmountUsdResult.data)
        }
        const hiddenInvoiceAmountUsdResult =
          HiddenInvoiceAmountUsdSchema.safeParse(
            existingContactInvoiceLoanAmountUsd ??
              currentInvoice?.totalAmountUsd,
          )

        if (hiddenInvoiceAmountUsdResult.success) {
          form.setValue(
            'hiddenInvoiceAmountUsd',
            hiddenInvoiceAmountUsdResult.data,
          )
          form.trigger()
        }
      }, [
        contactInvoiceAmountMap,
        currentContactGuid,
        currentInvoice,
        currentInvoiceGuid,
        existingContactInvoiceLoanAmountUsd,
        form,
        tzId,
      ])

      const defaultInvoice = useMemo(
        () => invoices.find(isFinanceable),
        [invoices],
      )

      const defaultValues = useMemo(() => {
        return {
          invoiceGuid: config?.invoiceGuid ?? defaultInvoice?.invoiceGuid ?? '',
          financingAmountUsd:
            config?.financingAmountUsd ??
            defaultInvoice?.totalAmountUsd ??
            MAX_FINANCEABLE_AMOUNT_USD,
          contactGuid:
            config?.contactGuid ??
            (
              account.accountContacts.find(ac => ac.primary) ??
              account.accountContacts[0]
            )?.contact.contactGuid ??
            '',
          serviceDescription: defaultInvoice
            ? getServiceDescription(defaultInvoice, tzId)
            : '',
          hiddenInvoiceAmountUsd:
            config?.financingAmountUsd ??
            defaultInvoice?.totalAmountUsd ??
            MAX_FINANCEABLE_AMOUNT_USD,
        }
      }, [
        config?.invoiceGuid,
        config?.financingAmountUsd,
        config?.contactGuid,
        defaultInvoice,
        account.accountContacts,
        tzId,
      ])

      const {
        formState: { isValid },
      } = form

      return (
        <ConfigureLoanApplicationForm
          form={form}
          formProps={{
            onCancel,
            disablePrimaryButton: !isValid,
            dataDdActionName:
              'bz-financing-loan-app-configure-submit-button-click',
            submitButtonText: isMobileOrTablet ? 'Next' : 'Preview Message',
          }}
          schema={ConfigureLoanApplicationFormSchema}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          props={{
            invoiceGuid: {
              invoices,
            },
            contactGuid: {
              account,
              refetchAccount,
              disabledContactGuids,
              disabledExtra: (
                <div className="block">
                  <PrequalStatusTag status="DECLINED" />
                </div>
              ),
            },
            financingAmountUsd: {
              disabled: !!existingContactInvoiceLoanAmountUsd,
            },
          }}
        >
          {({
            invoiceGuid,
            financingAmountUsd,
            serviceDescription,
            contactGuid,
          }) =>
            invoices.length ? (
              <>
                <div className="pb-0">
                  {contactGuid}
                  <Divider className="my-0" />
                  <PrequalBanners
                    accountGuid={account.accountGuid}
                    companyName={company.name}
                    className="my-4"
                    hasLogo
                    showDeclined={false}
                    prequalMessageType="full"
                    showLearnMore
                  />
                </div>
                <div className="grid grid-cols-5 gap-x-4 gap-y-[8px] pt-0">
                  <div className="col-span-5 md:col-span-3">{invoiceGuid}</div>
                  <div className="col-span-5 md:col-span-2">
                    {financingAmountUsd}
                  </div>
                  <div className="col-span-5">{serviceDescription}</div>
                </div>
              </>
            ) : (
              <div className="flex flex-1 flex-col items-center py-12 text-lg font-semibold text-bz-gray-800">
                You must have at least one unpaid invoice to be able to apply
                for financing.
              </div>
            )
          }
        </ConfigureLoanApplicationForm>
      )
    },
  )

export default FinancingConfigureLoanAppForm
