import { JobOutcomesFormSchema } from '@breezy/shared'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { RevenueAttributionUser } from '../job-outcome-modal-types'

/**
 * Calculate the total sold and earned revenue for a user based on data in the Job Outcomes Form
 *
 * @param {string} userGuid - The unique identifier for the user.
 * @param {UseFormReturn<JobOutcomesFormSchema>} form - The form instance from react-hook-form.
 * @returns {Array<number>} An array where the first element is the total sold revenue and the second element is the total earned revenue.
 *
 * @example
 * const [totalSoldRevenueUsc, totalEarnedRevenueUsc] = useUserTotalSoldAndEarnedRevenueUsc(userGuid, form);
 */
const calculateUserTotalSoldAndEarnedRevenueUsc = (
  userGuid: string,
  revenueAttributionInvoiceGuidToInvoiceMap: JobOutcomesFormSchema['revenueAttributionGuidToInvoiceMap'],
) => {
  let soldRevenueUsc = 0,
    earnedRevenueUsc = 0

  Object.values(revenueAttributionInvoiceGuidToInvoiceMap).forEach(invoice => {
    const itemGuidToItemMap = invoice.guidToItemMap

    Object.values(itemGuidToItemMap).forEach(item => {
      if ((item.soldRevenueAttributionUserGuids ?? []).includes(userGuid)) {
        soldRevenueUsc += item.totalRevenueUsc
      }

      if ((item.earnedRevenueAttributionUserGuids ?? []).includes(userGuid)) {
        earnedRevenueUsc += item.totalRevenueUsc
      }
    })
  })

  return [soldRevenueUsc, earnedRevenueUsc]
}

/**
 * Custom hook that returns all users that have non-zero revenue attribution in the Job Outcomes Form.
 *
 * @param {RevenueAttributionUser[]} allAttributableUsers - The array of users that can be attributed revenue.
 * @param {UseFormReturn<JobOutcomesFormSchema>} form - The form instance from react-hook-form.
 * @returns {Record<string, {user: RevenueAttributionUser, totalSoldRevenueUsc: number, totalEarnedRevenueUsc: number}>}
 *  A Record containing data for all users that have non-zero Earned Revenue OR non-zero Sold Revenue attributed.
 *  In this Record, the keys are the user guids and the values are object containing the user data as well as their
 *  total sold & earned revenue.
 *
 * @example
 * const usersWithRevenueAttribution = useUsersWithRevenueAttribution(allAttributableUsers, form);
 */
export const useUsersWithRevenueAttribution = (
  allAttributableUsers: RevenueAttributionUser[],
  form: UseFormReturn<JobOutcomesFormSchema>,
) => {
  const revenueAttributionInvoiceGuidToInvoiceMap = form.watch(
    'revenueAttributionGuidToInvoiceMap',
  )

  // Hacky solution for now - The watched map value above can change without triggering the `useEffect`
  // below because React only runs a shallow comparison on objects
  const revenueAttributionJsonString = JSON.stringify(
    revenueAttributionInvoiceGuidToInvoiceMap,
  )

  return useMemo(() => {
    return allAttributableUsers.reduce(
      (map, user) => {
        const [totalSoldRevenueUsc, totalEarnedRevenueUsc] =
          calculateUserTotalSoldAndEarnedRevenueUsc(
            user.userGuid,
            revenueAttributionInvoiceGuidToInvoiceMap,
          )

        if (totalSoldRevenueUsc === 0 && totalEarnedRevenueUsc === 0) {
          return map
        }

        map[user.userGuid] = {
          user,
          totalSoldRevenueUsc,
          totalEarnedRevenueUsc,
        }

        return map
      },
      {} as Record<
        string,
        {
          user: RevenueAttributionUser
          totalSoldRevenueUsc: number
          totalEarnedRevenueUsc: number
        }
      >,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allAttributableUsers,
    revenueAttributionInvoiceGuidToInvoiceMap,
    revenueAttributionJsonString,
  ])
}
