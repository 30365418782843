import { ZoneId } from '@breezy/shared'

export enum DateFormat {
  /**
   * e.g. Friday
   */
  'eeee' = 'eeee',

  /**
   * e.g. Sep 23, 2022
   */
  'MMM d, yyyy' = 'MMM d, yyyy',

  /**
   * e.g. Friday, Sep 23, 2022
   */
  'eeee, MMM d, yyyy' = 'eeee, MMM d, yyyy',

  /**
   * e.g. Friday, Sep 23, 2022 8:00 AM
   */
  'eeee, MMM d, yyyy h:mm a' = 'eeee, MMM d, yyyy h:mm a',

  /**
   * e.g. 8:00 AM
   */
  'h:mm a' = 'h:mm a',

  /**
   * eg 12/22/2022
   */
  'MM/dd/YYYY' = 'MM/dd/YYYY',

  /**
   * e.g. 3/22/23 @ 8:00 AM
   */
  'M/d/yy @ h:mm a' = 'M/d/yy @ h:mm a',
}

export type TimezoneStrategy =
  | {
      type: 'browser'
    }
  | {
      type: 'company-timezone-of-principal-user'
    }
  | {
      type: 'location'
      locationTimezone: string
    }
  | {
      type: 'explicit'
      timezone: string | ZoneId
    }
