import { HtmlString } from '@breezy/shared'
import DOMPurify from 'dompurify'
import { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

interface HtmlRendererProps {
  htmlContent: HtmlString
}

export const HtmlRenderer = memo<HtmlRendererProps>(({ htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent as string)

  return (
    <span>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{sanitizedHtml}</ReactMarkdown>
    </span>
  )
})
