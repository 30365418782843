import { isNullish, nextGuid } from '@breezy/shared'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from 'antd'
import { memo } from 'react'
import { TagList, TagStyleVersion } from '../../../components/Tags'
import FaIconWithCircularBackground from '../../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'

export type HeaderTagProp = {
  value: string
  label?: string
  color?: string
}

type HeaderProps = {
  faIcon?: IconDefinition
  iconBgColor?: string
  title: string
  description?: string
  descriptionRowsBeforeTruncation?: number
  // This prop can theoretically be used to configure any height for the header,
  // but this is meant to be a hard-coded, fixed height
  heightClassName?: string
  iconClassName?: string
  tags?: HeaderTagProp[]
  numTagsToShow?: number
  extra?: React.ReactNode
  titleLevel?: 4 | 5
  titleClassName?: string
  descriptionClassName?: string
  tagStyleVersion?: TagStyleVersion
}

export const StaticHeightOptionCardHeader = memo<HeaderProps>(
  ({
    faIcon,
    title,
    description,
    descriptionRowsBeforeTruncation,
    heightClassName = 'min-h-[54px]',
    iconBgColor = 'rgba(0, 0, 0, 0.04)',
    iconClassName,
    tags,
    numTagsToShow,
    extra,
    titleLevel = 4,
    titleClassName = 'mb-1 truncate',
    descriptionClassName = 'mb-3 font-normal',
    tagStyleVersion = 'v2',
  }) => {
    const shouldTruncate =
      !isNullish(descriptionRowsBeforeTruncation) &&
      descriptionRowsBeforeTruncation > 0

    return (
      <div
        className={`${heightClassName} flex max-w-full gap-x-3 overflow-hidden`}
      >
        {!isNullish(faIcon) && (
          <div className="mr-[-6px] flex flex-shrink-0 pt-[4px]">
            <FaIconWithCircularBackground
              iconDefinition={faIcon}
              backgroundColor={iconBgColor}
              diameterPx={44}
              iconClassName={iconClassName}
            />
          </div>
        )}
        <div className="flex min-w-0 flex-grow flex-col">
          <Typography.Title
            level={titleLevel}
            className={titleClassName}
            ellipsis={{ rows: 1 }}
          >
            {title}
          </Typography.Title>
          <Typography.Paragraph
            ellipsis={
              shouldTruncate
                ? {
                    rows: descriptionRowsBeforeTruncation,
                  }
                : false
            }
            className={descriptionClassName}
          >
            {description}
          </Typography.Paragraph>
          {tags && tags.length > 0 && (
            <TagList
              tagStyleVersion={tagStyleVersion}
              numTagsDisplayed={numTagsToShow}
              hideIcon
              truncateLength={28}
              noIndividualPopover
              spacingY={2}
              tags={tags.map(t => ({
                color: t.color ?? '#ffffff',
                tagGuid: nextGuid(),
                name: t.value,
                label: t.label,
              }))}
            />
          )}
        </div>
        {extra}
      </div>
    )
  },
)
