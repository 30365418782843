import { gql } from '../generated'

gql(/* GraphQL */ `
  fragment PricebookItem on PricebookItems {
    description
    isActive
    isDeleted
    isDiscountable
    isTaxable
    itemType
    name
    priceUsd
    pricebookItemGuid
    version
    photo {
      cdnUrl
      photoGuid
    }
  }
`)

export const GET_PRICEBOOK_DATA = gql(/* GraphQL */ `
  query GetPricebookData {
    pricebookCategories {
      pricebookItems(where: { isDeleted: { _eq: false } }) {
        ...PricebookItem
      }
      name
      pricebookCategoryGuid
      parentCategoryGuid
      photo {
        cdnUrl
        photoGuid
      }
    }
    uncategorizedItems: pricebookItems(
      where: {
        pricebookCategoryGuid: { _isNull: true }
        isDeleted: { _eq: false }
      }
    ) {
      ...PricebookItem
    }
  }
`)
