import {
  CompanyUser,
  EquipmentType,
  User,
  UserImpersonationSearchDTOItem,
  bzExpect,
  isCompanyUser,
  isNullish,
  phoneAgentIdentity,
} from '@breezy/shared'
import { createContext, useContext, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { IMPERSONATE_USER_KEY } from '../components/Admin/AdminImpersonateUser/AdminImpersonateUser'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { trpc } from '../hooks/trpc'
import { useAuth } from '../hooks/useAuth'
import { Composable } from '../utils/Composable'

type PrincipalUserContextWrapper = {
  principal: User | null
  expectCompanyUserPrincipal: () => CompanyUser
  impersonatedUser: UserImpersonationSearchDTOItem | undefined
  setImpersonatedUser: (
    value: UserImpersonationSearchDTOItem | undefined,
  ) => void
  logoutPrincipalUser: () => Promise<void>
  loggingOutStatus: null | 'initiated' | 'finishing'
}

export const PrincipalUserContext = createContext<PrincipalUserContextWrapper>({
  principal: null,
  impersonatedUser: undefined,
  expectCompanyUserPrincipal: () => {
    throw new Error('Principal User not yet set')
  },
  setImpersonatedUser: value => {},
  logoutPrincipalUser: () => Promise.resolve(),
  loggingOutStatus: null,
})

export const PrincipalUserWrapper: React.FC<Composable> = ({ children }) => {
  const startingImpersonation = sessionStorage.getItem(IMPERSONATE_USER_KEY)
  const [principal, setPrincipal] = useState<User | null>(null)
  const [loggingOutStatus, setLoggingOutStatus] =
    useState<PrincipalUserContextWrapper['loggingOutStatus']>(null)
  const [impersonatedUser, setImpersonatedUserPrivate] = useState<
    UserImpersonationSearchDTOItem | undefined
  >(startingImpersonation ? JSON.parse(startingImpersonation) : undefined)

  const setImpersonatedUser = (
    value: UserImpersonationSearchDTOItem | undefined,
  ) => {
    setImpersonatedUserPrivate(value)
    if (!isNullish(value)) {
      sessionStorage.setItem(IMPERSONATE_USER_KEY, JSON.stringify(value))
    } else {
      sessionStorage.removeItem(IMPERSONATE_USER_KEY)
    }
  }

  const expectCompanyUserPrincipal = (): CompanyUser => {
    if (!principal) throw new Error('Principal not yet set')

    if (isCompanyUser(principal)) return principal

    throw new Error('Principal user is not a company user')
  }

  const auth = useAuth()
  const trpcContext = trpc.useContext()
  const previousImpersonating = usePrevious(impersonatedUser)

  const logoutPrincipalUser = async () => {
    setLoggingOutStatus('initiated')
    return new Promise<void>(resolve => {
      setTimeout(() => {
        setLoggingOutStatus('finishing')
        auth.logout()
        resolve()
      }, 300)
    })
  }

  useEffect(() => {
    // if there is no auth user, there is no point in trying to fetch the principal user
    if (!auth.user) {
      return
    }

    // if we already have the principal user, no need to fetch it again
    if (
      principal &&
      impersonatedUser?.userGuid === previousImpersonating?.userGuid
    ) {
      return
    }

    trpcContext.client.user['users:self']
      .query()
      .then(principal => {
        setPrincipal(principal || null)
      })
      .catch(e => {
        // if there is an error fetching, logout and have the user sign in
        console.error(`There was an error fetching users:self`, e)
        auth.logout()
        auth.loginWithRedirect()
      })
  }, [
    setPrincipal,
    principal,
    auth,
    trpcContext,
    impersonatedUser,
    previousImpersonating,
  ])

  // if there is a auth user and a principal user, render the children (success state)
  if (auth.user && principal) {
    return (
      <PrincipalUserContext.Provider
        value={{
          principal,
          impersonatedUser,
          setImpersonatedUser,
          expectCompanyUserPrincipal,
          loggingOutStatus,
          logoutPrincipalUser,
        }}
      >
        {children}
      </PrincipalUserContext.Provider>
    )
  }

  // if there is not auth user, and we are not loading, then redirect the user to login
  if (!auth.user && !auth.loading) {
    auth.loginWithRedirect()
    // this will never render as the above redirects the page
    return null
  }

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <LoadingSpinner />
    </div>
  )
}

export const useCompanyGuid = () =>
  usePrincipalUser()?.principal?.company?.companyGuid
export const usePrincipalUser = () => useContext(PrincipalUserContext)
export const useExpectedPrincipal = () =>
  bzExpect(usePrincipalUser().principal, 'principal', 'User Not Logged In')

export const useExpectedUserGuid = () => useExpectedPrincipal().userGuid

export const useIsImpersonating = () => !!usePrincipalUser().impersonatedUser

export const useExpectedCompany = () =>
  bzExpect(
    useExpectedPrincipal().company,
    'company',
    'User is not a company user',
  )

export const useIsCompanyUser = () => {
  const user = usePrincipalUser()
  return !!user.principal && !!user.principal.company
}

export const useExpectedCompanyEquipmentTypes = () => {
  const company = useExpectedCompany()
  const hiddenEquipmentTypes = new Set(company.hiddenEquipmentTypes ?? [])
  return Object.values(EquipmentType).filter(x => !hiddenEquipmentTypes.has(x))
}

export const useExpectedCompanyInstallProjectTypes = () => {
  const company = useExpectedCompany()
  const installProjectTypes = new Set(company.visibleInstallProjectTypes ?? [])

  return Array.from(installProjectTypes)
}

export const useExpectedLocationMunicipalityFieldEnabledForCompany = () => {
  const company = useExpectedCompany()
  return company.locationMunicipalityFieldEnabled === true
}

export const useCompanyDefaultAccountManagerUserGuid = () => {
  const company = useExpectedCompany()
  return company.defaultAccountManagerUserGuid
}

export const useExpectedMerchantId = () => {
  const company = useExpectedCompany()
  return bzExpect(company.merchantId, 'merchantId', 'Company has no merchantId')
}

export const useMerchantId = (): string | undefined => {
  const company = useExpectedCompany()
  return company.merchantId
}

export const useExpectedCompanyGuid = () => useExpectedCompany().companyGuid

export const useExpectedCompanyTimeZoneId = () => useExpectedCompany().timezone

export const useExpectedPhoneIdentity = () => {
  const user = useExpectedPrincipal()
  const company = useExpectedCompany()
  return phoneAgentIdentity(company.name, user.firstName, user.lastName)
}

export type PhoneIdentityData = {
  companyGuid: string
  userGuid: string
  phoneIdentity: string
}

export const usePhoneIdentity = (): PhoneIdentityData | undefined => {
  const principal = usePrincipalUser()
  const companyGuid = principal?.principal?.company?.companyGuid
  const userGuid = principal?.principal?.userGuid
  const firstName = principal?.principal?.firstName
  const lastName = principal?.principal?.lastName
  const companyName = principal?.principal?.company?.name
  const phoneIdentity =
    firstName && lastName && companyName
      ? phoneAgentIdentity(companyName, firstName, lastName)
      : undefined
  return companyGuid && userGuid && phoneIdentity
    ? {
        companyGuid,
        userGuid,
        phoneIdentity,
      }
    : undefined
}
