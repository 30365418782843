import { z } from 'zod'
import {
  AsyncFn,
  BzDateFns,
  getPastNReportingTimeWindowsFor,
  IsoDateString,
  TimePastNReportRequest,
  TimeZoneId,
} from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { AccountGuid } from '../Accounts/Account'
import { CompanyGuidContainer, ForCompany } from '../Company/Company'
import { JobsV2Class } from '../Job'
import { bzOptional, ForAddonFields } from '../common-schemas'

export const STANDARD_REPORTING_TIME_HORIZON_OPTIONS = [
  'Last 7 days',
  'Last 15 days',
  'Last 6 weeks',
  'Last 3 months',
  'Last 6 months',
  'Last 12 months',
  'Year to Date',
] as const

export type StandardReportingTimeHorizon = (typeof STANDARD_REPORTING_TIME_HORIZON_OPTIONS)[number]

const reportingDateRangeSchema = z.object({
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type ReportingDateRange = z.infer<typeof reportingDateRangeSchema>

export type ReportingDateRangeWithTimeHorizon<T extends string = string> = ReportingDateRange & {
  timeHorizon?: T
}

export type StandardReportingDateRange = ReportingDateRangeWithTimeHorizon<StandardReportingTimeHorizon>

export const REPORTING_DATE_GROUPING_TYPES = ['days', 'weeks', 'months', 'years'] as const

export type ReportingDateGroupingType = (typeof REPORTING_DATE_GROUPING_TYPES)[number]

export const getYTDGroupingType = (tzId: TimeZoneId): ReportingDateGroupingType => {
  const today = BzDateFns.getToday(tzId)
  const firstOfYear = BzDateFns.startOfYear(today)
  const threeWeeksIntoYear = BzDateFns.addWeeks(firstOfYear, 3)

  if (BzDateFns.isBefore(today, threeWeeksIntoYear)) {
    return 'days'
  }

  const startOfMay = BzDateFns.addMonths(firstOfYear, 5)
  if (BzDateFns.isBefore(today, startOfMay)) {
    return 'weeks'
  }

  return 'months'
}

type DateRangeOrTimeHorizon =
  | {
      /** @deprecated: support for timeHorizon is ending */
      timeHorizon: StandardReportingTimeHorizon
      dateRange?: never
    }
  | {
      timeHorizon?: StandardReportingTimeHorizon
      dateRange: ReportingDateRange
    }

export const getReportingCutOffDate = (input: DateRangeOrTimeHorizon, tzId: TimeZoneId): IsoDateString => {
  if (input.timeHorizon) {
    return getCutoffDateForStandardTimeHorizon(input.timeHorizon, tzId)
  }
  return input.dateRange.start
}

export const getReportingEndDate = (input: DateRangeOrTimeHorizon): IsoDateString => {
  if (input.timeHorizon) {
    return BzDateFns.nowISOString()
  }
  return input.dateRange.end
}

/** @deprecated we are moving away from time units in favor of date ranges */
export const getCutoffDateForStandardTimeHorizon = (
  timeHorizon: StandardReportingTimeHorizon,
  tzId: TimeZoneId,
): IsoDateString => {
  let date = BzDateFns.getToday(tzId)
  switch (timeHorizon) {
    case 'Last 7 days':
      date = BzDateFns.subDays(date, 7)
      break
    case 'Last 15 days':
      date = BzDateFns.subDays(date, 15)
      break
    case 'Last 6 weeks':
      date = BzDateFns.subWeeks(date, 6)
      break
    case 'Last 3 months':
      date = BzDateFns.subMonths(date, 3)
      break
    case 'Last 6 months':
      date = BzDateFns.subMonths(date, 6)
      break
    case 'Last 12 months':
      date = BzDateFns.subMonths(date, 12)
      break
    case 'Year to Date':
      date = BzDateFns.startOfYear(date)
      break
  }

  return BzDateFns.formatISO(date, tzId)
}

/** @deprecated we are moving away from time units in favor of date ranges */
export const timeHorizonToUnitAndN = (
  tzId: TimeZoneId,
  timeHorizon: StandardReportingTimeHorizon,
): TimePastNReportRequest => {
  switch (timeHorizon) {
    case 'Last 7 days':
      return { timeUnit: 'days', timePastN: 7 }
    case 'Last 15 days':
      return { timeUnit: 'days', timePastN: 15 }
    case 'Last 6 weeks':
      return { timeUnit: 'weeks', timePastN: 6 }
    case 'Last 3 months': {
      const today = BzDateFns.getToday(tzId)
      const threeMonthsAgo = BzDateFns.subMonths(today, 3)
      return { timeUnit: 'weeks', timePastN: BzDateFns.differenceInWeeks(today, threeMonthsAgo) }
    }
    case 'Last 6 months':
      return { timeUnit: 'months', timePastN: 6 }
    case 'Last 12 months':
      return { timeUnit: 'months', timePastN: 12 }
    case 'Year to Date': {
      const today = BzDateFns.getToday(tzId)
      const startOfYear = BzDateFns.startOfYear(today)
      const timeUnit = getYTDGroupingType(tzId)
      if (timeUnit === 'days') {
        return { timeUnit, timePastN: BzDateFns.differenceInDays(today, startOfYear) }
      } else if (timeUnit === 'weeks') {
        return { timeUnit, timePastN: BzDateFns.differenceInWeeks(today, startOfYear) }
      } else if (timeUnit === 'months') {
        return { timeUnit, timePastN: BzDateFns.differenceInMonths(today, startOfYear) }
      } else {
        throw new Error(`Unhandled time unit: ${timeUnit}`)
      }
    }
    default:
      throw new Error(`Unhandled time horizon: ${timeHorizon}`)
  }
}

export const dateRangeToUnitAndN = (tzId: TimeZoneId, dateRange: ReportingDateRange): TimePastNReportRequest => {
  const start = BzDateFns.parseISO(dateRange.start, tzId)
  const end = BzDateFns.parseISO(dateRange.end, tzId)
  const daysBetween = BzDateFns.differenceInDays(end, start)

  if (daysBetween <= 21) {
    // The +1 is because the end of the range is going to be like 23:59:59. This applies to all the cases below as well
    return { timeUnit: 'days', timePastN: daysBetween + 1 }
  }

  const weeksBetween = BzDateFns.differenceInWeeks(end, start)

  const monthsBetween = BzDateFns.differenceInMonths(end, start)

  if (monthsBetween < 3) {
    return { timeUnit: 'weeks', timePastN: weeksBetween + 1 }
  }

  if (monthsBetween <= 23) {
    return { timeUnit: 'months', timePastN: monthsBetween + 1 }
  }

  return { timeUnit: 'years', timePastN: BzDateFns.differenceInYears(end, start) + 1 }
}

export const getUnitAndN = (tzId: TimeZoneId, input: DateRangeOrTimeHorizon) => {
  if (input.timeHorizon) {
    return timeHorizonToUnitAndN(tzId, input.timeHorizon)
  }
  return dateRangeToUnitAndN(tzId, input.dateRange)
}

/** @deprecated */
export const getDateGroupingTypeForHorizon = (
  timeHorizon: StandardReportingTimeHorizon,
  tzId: TimeZoneId,
): ReportingDateGroupingType => {
  if (timeHorizon === 'Last 7 days' || timeHorizon === 'Last 15 days') {
    return 'days'
  }
  if (timeHorizon === 'Last 6 weeks' || timeHorizon === 'Last 3 months') {
    return 'weeks'
  }
  if (timeHorizon === 'Last 6 months' || timeHorizon === 'Last 12 months') {
    return 'months'
  }
  return getYTDGroupingType(tzId)
}

export const getDateGroupingTypeForRange = (
  dateRange: ReportingDateRange,
  tzId: TimeZoneId,
): ReportingDateGroupingType => {
  return dateRangeToUnitAndN(tzId, dateRange).timeUnit
}

export const getDateGroupingType = (timeInput: DateRangeOrTimeHorizon, tzId: TimeZoneId): ReportingDateGroupingType => {
  if (timeInput.timeHorizon) {
    return getDateGroupingTypeForHorizon(timeInput.timeHorizon, tzId)
  }
  return getDateGroupingTypeForRange(timeInput.dateRange, tzId)
}

export const FUTURE_TIME_HORIZON_OPTIONS = ['Next 7 days', 'Next 15 days', 'Next 30 days'] as const
export type FutureTimeHorizon = (typeof FUTURE_TIME_HORIZON_OPTIONS)[number]

export type FutureReportingDateRange = ReportingDateRangeWithTimeHorizon<FutureTimeHorizon>

export const JOBS_BY_CLASS_ASSIGNED_SETTING_OPTIONS = ['Assigned', 'Unassigned', 'No Appointments'] as const
export type JobsByClassAssignedSetting = (typeof JOBS_BY_CLASS_ASSIGNED_SETTING_OPTIONS)[number]

export type JobsByClassReportIndividualData = Record<JobsV2Class, number>

export type JobsByClassReportData = Record<JobsByClassAssignedSetting, JobsByClassReportIndividualData>

export const jobsByClassRequestSchema = z.union([
  /** @deprecated: support for timeHorizon is ending */
  z.object({
    timeHorizon: z.enum(FUTURE_TIME_HORIZON_OPTIONS),
    dateRange: bzOptional(z.never()),
  }),
  z.object({
    timeHorizon: bzOptional(z.never()),
    dateRange: reportingDateRangeSchema,
  }),
])

export type JobsByClassRequest = z.infer<typeof jobsByClassRequestSchema>

export type JobsByClassReader = AsyncFn<
  ForAddonFields<'companyGuid' | 'tzId', JobsByClassRequest>,
  JobsByClassReportData
>

export type AvgInvoiceByJobTypeReportData = Record<
  string,
  {
    avgInvoice: number
    totalJobs: number
  }
>

export const avgInvoiceByJobTypeRequestSchema = z.union([
  /** @deprecated: support for timeHorizon is ending */
  z.object({
    timeHorizon: z.enum(STANDARD_REPORTING_TIME_HORIZON_OPTIONS),
    dateRange: bzOptional(z.never()),
  }),
  z.object({
    timeHorizon: bzOptional(z.never()),
    dateRange: reportingDateRangeSchema,
  }),
])

export type AvgInvoiceByJobTypeRequest = z.infer<typeof avgInvoiceByJobTypeRequestSchema>

export type AvgInvoiceByJobTypeReader = AsyncFn<
  ForAddonFields<'companyGuid' | 'tzId', AvgInvoiceByJobTypeRequest>,
  AvgInvoiceByJobTypeReportData
>

export const bucketedEarnedRevenueRequestSchema = z.union([
  /** @deprecated: support for timeHorizon is ending */
  z.object({
    timeHorizon: z.enum(STANDARD_REPORTING_TIME_HORIZON_OPTIONS),
    dateRange: bzOptional(z.never()),
  }),
  z.object({
    timeHorizon: bzOptional(z.never()),
    dateRange: reportingDateRangeSchema,
  }),
])

export type BucketedEarnedRevenueRequest = z.infer<typeof bucketedEarnedRevenueRequestSchema>

export type BucketedEarnedRevenueDatum = {
  date: IsoDateString
  value: number
}

export type BucketedEarnedRevenueReader = AsyncFn<
  ForAddonFields<'companyGuid' | 'tzId', BucketedEarnedRevenueRequest>,
  BucketedEarnedRevenueDatum[]
>

export const aggregatedEarnedRevenueRequestSchema = z.object({
  startDate: isoDateStringSchema,
  endDate: isoDateStringSchema,
})
export type AggregatedEarnedRevenueRequest = z.infer<typeof aggregatedEarnedRevenueRequestSchema>

export type AggregatedEarnedRevenueData = {
  revenue: number
}

export type AggregatedEarnedRevenueReader = AsyncFn<
  ForCompany<AggregatedEarnedRevenueRequest>,
  AggregatedEarnedRevenueData
>

export const jobsByLeadSourceRequestSchema = z.union([
  /** @deprecated: support for timeHorizon is ending */
  z.object({
    timeHorizon: z.enum(STANDARD_REPORTING_TIME_HORIZON_OPTIONS),
    dateRange: bzOptional(z.never()),
  }),
  z.object({
    timeHorizon: bzOptional(z.never()),
    dateRange: reportingDateRangeSchema,
  }),
])

export type JobsByLeadSourceRequest = z.infer<typeof jobsByLeadSourceRequestSchema>

export type JobsByLeadSourceDatum = {
  leadSource: string
  numJobs: number
  revenue: number
}

export type JobsByLeadSourceReader = AsyncFn<
  ForAddonFields<'companyGuid' | 'tzId', JobsByLeadSourceRequest>,
  JobsByLeadSourceDatum[]
>

export type StaleAccountResponse = {
  accountGuid: AccountGuid
  displayName: string
  jobCreatedAt: IsoDateString
}[]

export type StaleAccountsReader = AsyncFn<CompanyGuidContainer, StaleAccountResponse>

export type CustomerReviewSource = 'Google' | 'Yelp' | 'Facebook' | 'Angi' | 'BBB'

export type CustomerReview = {
  link: string
  user: string
  avatarLink?: string
  rating: number
  createdAt: IsoDateString
  content: string
  source: CustomerReviewSource
}

export type ReviewAggData = {
  overallRating: number
  totalReviews: number
  trendData: BasicReportingData[]
}

export const customerReviewReaderRequestSchema = z.union([
  /** @deprecated: support for timeHorizon is ending */
  z.object({
    timeHorizon: z.enum(STANDARD_REPORTING_TIME_HORIZON_OPTIONS),
    dateRange: bzOptional(z.never()),
  }),
  z.object({
    timeHorizon: bzOptional(z.never()),
    dateRange: reportingDateRangeSchema,
  }),
])

export type CustomerReviewReaderRequest = z.infer<typeof customerReviewReaderRequestSchema>

export type CustomerReviewsReaderResponse = ReviewAggData & {
  google: ReviewAggData
  yelp: ReviewAggData
  facebook: ReviewAggData
  angi: ReviewAggData
  bbb: ReviewAggData
  reviews: CustomerReview[]
}

export type CustomerReviewsReader = AsyncFn<
  ForAddonFields<'companyGuid' | 'userGuid' | 'tzId', CustomerReviewReaderRequest>,
  CustomerReviewsReaderResponse
>

export type BasicReportingData = {
  date: IsoDateString
  value: number
}

// This assumes data will be in reverse chronological order
/** @deprecated use the date range version */
export const assembleBucketedDataLegacy = (
  rawData: BasicReportingData[],
  tzId: TimeZoneId,
  timeHorizon: StandardReportingTimeHorizon,
  average?: boolean,
): BasicReportingData[] => {
  const groupingType = getDateGroupingTypeForHorizon(timeHorizon, tzId)

  if (groupingType === 'days') {
    const today = BzDateFns.getToday(tzId)
    const buckets = BzDateFns.eachDayOfInterval({
      start: BzDateFns.subDays(today, timeHorizon === 'Last 7 days' ? 7 : 15),
      end: BzDateFns.subDays(today, 1),
    })
      .map(date => ({
        date,
        value: 0,
        count: 0,
      }))
      .reverse()

    let bucketI = 0

    let dataI = 0

    while (bucketI < buckets.length && dataI < rawData.length) {
      const datum = rawData[dataI]
      const datumDate = BzDateFns.parseISO(datum.date, tzId)
      if (BzDateFns.isSameDay(buckets[bucketI].date, datumDate)) {
        buckets[bucketI].value += datum.value
        buckets[bucketI].count++
        dataI++
      } else {
        bucketI++
      }
    }

    // Data is in reverse chrono, but we pass in real chrono
    return buckets
      .map(bucket => ({
        value: average ? (bucket.count ? bucket.value / bucket.count : 0) : bucket.value,
        date: BzDateFns.formatISO(bucket.date, tzId),
      }))
      .reverse()
  }

  const startOfToday = BzDateFns.startOfToday()
  const startOfWeek = BzDateFns.startOfWeek(startOfToday, { weekStartsOn: 1 })
  const startOfMonth = BzDateFns.startOfMonth(startOfToday)

  const endDate = groupingType === 'weeks' ? startOfWeek : startOfMonth

  let timePastN = 0
  switch (timeHorizon) {
    case 'Last 6 weeks':
      timePastN = 6
      break
    case 'Last 3 months': {
      // Remember that 3 months is shown in weeks
      const startOfWeek3MonthsAgo = BzDateFns.startOfWeek(BzDateFns.subMonths(startOfWeek, 3))
      timePastN = BzDateFns.differenceInWeeks(startOfWeek, startOfWeek3MonthsAgo)
      break
    }
    case 'Last 6 months':
      timePastN = 6
      break
    case 'Last 12 months':
      timePastN = 12
      break
    case 'Year to Date': {
      const startOfYear = BzDateFns.startOfYear(endDate)
      if (groupingType === 'weeks') {
        timePastN = BzDateFns.differenceInWeeks(startOfWeek, startOfYear)
      } else {
        timePastN = BzDateFns.differenceInCalendarMonths(startOfMonth, startOfYear)
      }
      break
    }
    default:
      throw new Error('Invalid time horizon')
  }

  const bucketEnd = BzDateFns.unZonedFormatISO(BzDateFns.subDays(endDate, 1))

  const buckets = getPastNReportingTimeWindowsFor(
    {
      timeUnit: groupingType,
      timePastN,
    },
    tzId,
    bucketEnd,
  ).reverse()

  const data: BasicReportingData[] = []

  // The buckets should never be empty
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let currentBucket = buckets.shift()!

  let currentDatum = {
    date: currentBucket.start,
    value: 0,
    count: 0,
  }

  let i = 0

  while (i < rawData.length) {
    const datum = rawData[i]
    const date = BzDateFns.unZonedFormatISO(BzDateFns.parseISO(datum.date, tzId))
    // If our date is after the end of the current bucket, we haven't gotten to relevant data yet (it could be a day
    // outside of our date range) so we skip.
    if (date > currentBucket.end) {
      i++
      continue
    }
    // If our date is outside of the current bucket the other way, we need a new bucket
    if (date < currentBucket.start) {
      // If we have more buckets, then we add the current bucket to the data and reset with the next bucket. Otherwise
      // we're done.
      if (buckets.length) {
        // The whole point of the above check is we know this is not undefined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentBucket = buckets.shift()!
        data.push({
          date: currentDatum.date,
          value: average ? (currentDatum.count ? currentDatum.value / currentDatum.count : 0) : currentDatum.value,
        })
        currentDatum = {
          date: currentBucket.start,
          value: 0,
          count: 0,
        }
        continue
      } else {
        break
      }
    }

    currentDatum.value += datum.value
    currentDatum.count++
    i++
  }
  data.push({
    date: currentDatum.date,
    value: average ? (currentDatum.count ? currentDatum.value / currentDatum.count : 0) : currentDatum.value,
  })
  for (const bucket of buckets) {
    data.push({
      date: bucket.start,
      value: 0,
    })
  }

  // Easier to construct in reverse-chronological, but we should send it in chronological
  return data.reverse()
}

// This assumes data will be in reverse chronological order
export const assembleBucketedData = (
  rawData: BasicReportingData[],
  tzId: TimeZoneId,
  timeInput: DateRangeOrTimeHorizon,
  average?: boolean,
): BasicReportingData[] => {
  const { timeUnit, timePastN } = getUnitAndN(tzId, timeInput)

  if (timeUnit === 'days') {
    const [start, end] = (() => {
      if (timeInput.timeHorizon) {
        const today = BzDateFns.getToday(tzId)
        const start = BzDateFns.subDays(today, timePastN)
        const end = BzDateFns.subDays(today, 1)
        return [start, end]
      }
      return [BzDateFns.parseISO(timeInput.dateRange.start, tzId), BzDateFns.parseISO(timeInput.dateRange.end, tzId)]
    })()

    const buckets = BzDateFns.eachDayOfInterval({
      start,
      end,
    })
      .map(date => ({
        date,
        value: 0,
        count: 0,
      }))
      .reverse()

    let bucketI = 0

    let dataI = 0

    while (bucketI < buckets.length && dataI < rawData.length) {
      const datum = rawData[dataI]
      const datumDate = BzDateFns.parseISO(datum.date, tzId)
      if (BzDateFns.isSameDay(buckets[bucketI].date, datumDate)) {
        buckets[bucketI].value += datum.value
        buckets[bucketI].count++
        dataI++
      } else {
        bucketI++
      }
    }

    // Data is in reverse chrono, but we pass in real chrono
    return buckets
      .map(bucket => ({
        value: average ? (bucket.count ? bucket.value / bucket.count : 0) : bucket.value,
        date: BzDateFns.formatISO(bucket.date, tzId),
      }))
      .reverse()
  }

  const bucketEnd = (() => {
    if (timeInput.timeHorizon) {
      const startOfToday = BzDateFns.startOfToday()
      const startOfWeek = BzDateFns.startOfWeek(startOfToday, { weekStartsOn: 1 })
      const startOfMonth = BzDateFns.startOfMonth(startOfToday)

      const endDate = timeUnit === 'weeks' ? startOfWeek : startOfMonth

      const bucketEnd = BzDateFns.unZonedFormatISO(BzDateFns.subDays(endDate, 1))
      return bucketEnd
    }
    return timeInput.dateRange.end
  })()

  const buckets = getPastNReportingTimeWindowsFor(
    {
      timeUnit,
      timePastN,
    },
    tzId,
    bucketEnd,
  ).reverse()

  const data: BasicReportingData[] = []

  // The buckets should never be empty
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let currentBucket = buckets.shift()!

  let currentDatum = {
    date: currentBucket.start,
    value: 0,
    count: 0,
  }

  let i = 0

  while (i < rawData.length) {
    const datum = rawData[i]
    const date = BzDateFns.unZonedFormatISO(BzDateFns.parseISO(datum.date, tzId))
    // If our date is after the end of the current bucket, we haven't gotten to relevant data yet (it could be a day
    // outside of our date range) so we skip.
    if (date > currentBucket.end) {
      i++
      continue
    }
    // If our date is outside of the current bucket the other way, we need a new bucket
    if (date < currentBucket.start) {
      // If we have more buckets, then we add the current bucket to the data and reset with the next bucket. Otherwise
      // we're done.
      if (buckets.length) {
        // The whole point of the above check is we know this is not undefined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentBucket = buckets.shift()!
        data.push({
          date: currentDatum.date,
          value: average ? (currentDatum.count ? currentDatum.value / currentDatum.count : 0) : currentDatum.value,
        })
        currentDatum = {
          date: currentBucket.start,
          value: 0,
          count: 0,
        }
        continue
      } else {
        break
      }
    }

    currentDatum.value += datum.value
    currentDatum.count++
    i++
  }
  data.push({
    date: currentDatum.date,
    value: average ? (currentDatum.count ? currentDatum.value / currentDatum.count : 0) : currentDatum.value,
  })
  for (const bucket of buckets) {
    data.push({
      date: bucket.start,
      value: 0,
    })
  }

  // Easier to construct in reverse-chronological, but we should send it in chronological
  return data.reverse()
}

// This assumes data will be in reverse chronological order
export const assembleAccumulatingBucketedData = (
  rawData: BasicReportingData[],
  tzId: TimeZoneId,
  timeInput: DateRangeOrTimeHorizon,
  average?: boolean,
): BasicReportingData[] => {
  const { timeUnit, timePastN } = getUnitAndN(tzId, timeInput)

  let bucketEnd: IsoDateString
  if (timeInput.timeHorizon) {
    const startOfToday = BzDateFns.getToday(tzId)
    let endDate = startOfToday
    if (timeUnit === 'weeks') {
      endDate = BzDateFns.startOfWeek(startOfToday, { weekStartsOn: 1 })
    } else if (timeUnit === 'months') {
      endDate = BzDateFns.startOfMonth(startOfToday)
    } else if (timeUnit === 'years') {
      endDate = BzDateFns.startOfYear(startOfToday)
    }
    bucketEnd = BzDateFns.unZonedFormatISO(BzDateFns.subDays(endDate, 1))
  } else {
    bucketEnd = timeInput.dateRange.end
  }

  const buckets = getPastNReportingTimeWindowsFor(
    {
      timeUnit,
      timePastN,
    },
    tzId,
    bucketEnd,
  )

  const data: BasicReportingData[] = []

  let total = 0
  let count = 0

  let rawDataCopy = [...rawData]

  for (const bucket of buckets) {
    const bucketStart = BzDateFns.parseISO(bucket.start, tzId)
    const bucketEnd = BzDateFns.parseISO(bucket.end, tzId)

    let currentDatumIndex = rawDataCopy.length - 1

    let datum: BasicReportingData | undefined = rawDataCopy[currentDatumIndex]
    while (datum && BzDateFns.isBefore(BzDateFns.parseISO(datum.date, tzId), bucketStart)) {
      datum = rawDataCopy[--currentDatumIndex]
    }

    while (datum && BzDateFns.isBefore(BzDateFns.parseISO(datum.date, tzId), bucketEnd)) {
      total += datum.value
      count++
      datum = rawDataCopy[--currentDatumIndex]
    }

    data.push({
      date: bucket.start,
      value: average ? (count ? total / count : 0) : total,
    })
    rawDataCopy = rawDataCopy.slice(0, currentDatumIndex + 1)
  }

  return data
}

export const DisabledSourcesSchema = z.object({
  google: bzOptional(z.boolean()),
  yelp: bzOptional(z.boolean()),
  facebook: bzOptional(z.boolean()),
  angi: bzOptional(z.boolean()),
  bbb: bzOptional(z.boolean()),
})

export const customerReviewOverridesSchema = z.object({
  disableSourcesGlobally: bzOptional(DisabledSourcesSchema),
  disableSourcesByCompany: bzOptional(z.record(guidSchema, DisabledSourcesSchema)),
})
