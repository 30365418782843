import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsAuthorized } from './useIsAuthorized'

export const useTechnicianScheduleAppointmentPermissions = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    return {
      canManageTheirAppointments: isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE,
      ),
      canManageTheirAppointmentsToday: isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY,
      ),
      canManageTheirCurrentAppointment: isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT,
      ),
      canViewHistoricalAppointments: isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED,
      ),
      canManageTeamSchedule: isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_MANAGE,
      ),
      canViewTeamSchedule: isAuthorized(
        PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW,
      ),
    }
  }, [isAuthorized])
}
