import { Address, BzAddress } from '@breezy/shared'
import * as addresser from 'addresser'
import { AutoComplete, Form, Input, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useState } from 'react'
import { useGooglePlaces } from '../../../providers/GoogleMapsPlaces'
import { getPlacePostCodeById } from '../../../utils/GoogleMapsPlacesUtils'
import { validatorNotFalsy } from '../../../utils/validators'
import USStateFormItem from '../AddressFormItem/USState/USStateFormItem'

export type AddressOption = {
  value: string
  placeId: string
}

export type AddressFormItemProps = {
  required: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddressChanged: (
    addressObj: Address,
    addressStr: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name?: any,
  ) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: any
  initialValue?: string
  labelClassName?: string
  flexRowSpaceX?: string
  address?: Address
  showMoreThanLineOne?: boolean
  showLine2?: boolean
  headerLabel?: string
}

export const AddressFormCompactItem = ({
  onAddressChanged,
  required,
  name,
  labelClassName = '',
  address,
  showMoreThanLineOne = true,
  showLine2 = false,
  headerLabel = 'Street Address',
}: AddressFormItemProps) => {
  const [options, setOptions] = useState<AddressOption[]>([])
  // the session token is used to optimize maps charges for the autocomplete service
  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken(),
  )
  const { autocompleteService, placesService } = useGooglePlaces()

  // This will trigger the onChange method to dispatch an update to the parent component
  const updateAddressState = (value: string) => {
    const parsed = addresser.parseAddress(value)
    if (
      !parsed.addressLine1 ||
      !parsed.placeName ||
      !parsed.stateName ||
      !parsed.zipCode
    ) {
      throw new Error('Address is invalid')
    }

    onAddressChanged(
      {
        line1: parsed.addressLine1,
        line2: undefined,
        city: parsed.placeName,
        stateAbbreviation: parsed.stateAbbreviation,
        zipCode: parsed.zipCode,
      },
      value,
      name,
    )
  }

  // this fetches the post code from the google maps api on a selection
  // primarily an optimization to reduce the number of calls to the google maps api
  const onSelect = async (value: string, option: AddressOption) => {
    if (!placesService) {
      return
    }

    const { placeId } = option
    const postCode = await getPlacePostCodeById(placeId, placesService)
    value = option.value + ' ' + postCode
    updateAddressState(value)
    // refresh the session token on a selection to optimize maps charges
    setSessionToken(new google.maps.places.AutocompleteSessionToken())
  }

  const onSearch = async (searchText: string) => {
    if (!autocompleteService) {
      return
    }

    const locations = await autocompleteService.getPlacePredictions({
      input: searchText,
      componentRestrictions: { country: 'us' },
      types: ['address'],
      sessionToken,
    })

    setOptions(
      locations?.predictions?.map(location => {
        // remove the country from the address
        const address = location.description.split(',').slice(0, -1).join(',')
        // keep placeId on the option so we can get the post code later
        return {
          value: address,
          placeId: location.place_id,
        }
      }),
    )
    updateAddressState(searchText)
  }

  return (
    <div className="compact">
      <Form.Item
        name="addressLineOne"
        label={headerLabel}
        className={labelClassName + 'mb-1'}
        initialValue={
          address && showMoreThanLineOne
            ? address?.line1
            : address && BzAddress.formatAddressSingleLine(address)
        }
        rules={[
          {
            required: true,
            message: 'A title is required for all checklist items',
          },
        ]}
      >
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder={headerLabel}
        >
          <TextArea
            id="address"
            name="addressLineOne"
            autoComplete="street-address"
            rows={3}
          />
        </AutoComplete>
      </Form.Item>
      {showMoreThanLineOne && (
        <div>
          {showLine2 && (
            <Form.Item
              name="addressLineTwo"
              label="Apt, Unit, Building #"
              className={labelClassName}
              initialValue={address?.line2}
              required={false}
            >
              <Input name="addressLineTwo" />
            </Form.Item>
          )}
          <Row className="mb-0">
            <div className="m-0 w-[104px]">
              <Form.Item
                name="addressCity"
                label={null}
                className={labelClassName + 'p-0'}
                required
                initialValue={address?.city}
                rules={[{ validator: validatorNotFalsy('City') }]}
              >
                <Input name="addressCity" />
              </Form.Item>
            </div>
            <div className="m-0 w-[68px]">
              <USStateFormItem
                initialStateAbbreviation={address?.stateAbbreviation}
                required
                labelClassName={labelClassName + 'p-0'}
                showAbbreviation
                showLabel={false}
              />
            </div>
            <div className="m-0 w-[68px]">
              <Form.Item
                name="addressZipCode"
                label={null}
                className={labelClassName + 'p-0'}
                initialValue={address?.zipCode}
                required
                rules={[{ validator: validatorNotFalsy('Zip Code') }]}
              >
                <Input name="addressZipCode" />
              </Form.Item>
            </div>
          </Row>
        </div>
      )}
    </div>
  )
}

export default AddressFormCompactItem
