import { useQuery } from 'urql'
import { anonGql } from '../../../generated'

const ANON_COMPANY_BILLING_PROFILE_QUERY = anonGql(/* GraphQL */ `
  query AnonGetCompanyBillingProfile($companyGuid: uuid!) {
    billingProfiles(where: { companyGuid: { _eq: $companyGuid } }) {
      businessFullLegalName
      tilledMerchantAccountId
      wisetackMerchantId
      contractorLicenseNumber
      logoUrl
    }
  }
`)

export const useAnonGetCompanyBillingProfile = (companyGuid: string) => {
  const getAnonCompanyBillingProfileQuery = useQuery({
    query: ANON_COMPANY_BILLING_PROFILE_QUERY,
    variables: { companyGuid: companyGuid },
  })

  const { data, fetching, error } = getAnonCompanyBillingProfileQuery[0]
  return {
    getAnonCompanyBillingProfileQuery,
    data,
    fetching,
    error,
  }
}
