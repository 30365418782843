import { type PropsWithChildren } from 'react'
import { ConfigContext, RUNTIME_CONFIG } from '../context'

export function ConfigProvider(props: PropsWithChildren) {
  const { children } = props

  return (
    <ConfigContext.Provider value={RUNTIME_CONFIG}>
      {children}
    </ConfigContext.Provider>
  )
}
