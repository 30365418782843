import { useCallback, useRef } from 'react'

export const useReactHookFormSubmit = (): [
  submitElement: JSX.Element,
  triggerSubmit: () => void,
] => {
  const submitRef = useRef<HTMLInputElement>(null)

  const triggerSubmit = useCallback(() => {
    submitRef.current?.click()
  }, [])

  return [
    <input ref={submitRef} type="submit" className="hidden" />,
    triggerSubmit,
  ] as const
}
