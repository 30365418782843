import { Col, Row } from 'antd'
import { memo } from 'react'
import EmptyOutcomeCard from './EmptyOutcomeCard'
import InvoiceCard from './InvoiceCard'
import { JobOutcomeInvoice } from './job-outcome-modal-types'

type InvoicesPanelProps = {
  paidInvoices: JobOutcomeInvoice[]
}

const InvoicesPanel = memo<InvoicesPanelProps>(({ paidInvoices }) => {
  return (
    <>
      <h3>Invoices</h3>
      {
        // We're going to put 2 invoices in a row, so split this array into chunks of 2
        Array.from({ length: Math.ceil(paidInvoices.length / 2) }, (_v, i) =>
          paidInvoices.slice(i * 2, i * 2 + 2),
        ).map((invoiceChunk, i) => (
          <Row justify="space-between" key={i} className="mb-2">
            {invoiceChunk[0] && (
              <Col key={invoiceChunk[0].invoiceGuid} className="w-1/2 pr-1.5">
                <InvoiceCard invoice={invoiceChunk[0]} />
              </Col>
            )}
            {invoiceChunk[1] && (
              <Col key={invoiceChunk[1].invoiceGuid} className="w-1/2 pl-1.5">
                <InvoiceCard invoice={invoiceChunk[1]} />
              </Col>
            )}
          </Row>
        ))
      }

      {paidInvoices.length === 0 && (
        <Col className="w-1/2 pr-1.5">
          <EmptyOutcomeCard bodyText="No paid invoices exist for this job." />
        </Col>
      )}
    </>
  )
})

export default InvoicesPanel
