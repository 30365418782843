import { convertFetchedToPrequalRecordWithContactName } from '@breezy/backend/src/application-types'
import {
  PrequalRecord,
  PrequalRecordMinimal,
  PrequalRecordWithContactName,
  formatUscWholeDollars,
} from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useQuery, useSubscription } from 'urql'
import RenderIf from '../../elements/RenderIf/RenderIf'
import LoanDetailsLink from '../Financing/LoanDetailsLink/LoanDetailsLink'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import PrequalLearnMoreLink from '../PrequalLearnMoreLink/PrequalLearnMoreLink'
import UrqlSubscriptionLoader from '../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import {
  PREQUAL_RECORD_QUERY_BY_ACCOUNT,
  PREQUAL_RECORD_SUBSCRIPTION_BY_ACCOUNT,
} from './PrequalSection.gql'
import PrequalifiedBannerWrapper from './PrequalifiedBannerWrapper'

type DeclinedPrequalifiedBannerViewProps = {
  name: string
  companyName: string
  hasLogo?: boolean
}
const DeclinedPrequalifiedBannerView =
  React.memo<DeclinedPrequalifiedBannerViewProps>(
    ({ name, companyName, hasLogo = false }) => {
      return (
        <PrequalifiedBannerWrapper type="error" hasLogo={hasLogo}>
          {`Unfortunately, ${name} was `}
          <span className="font-semibold">not approved</span> for financing.
          Contact {companyName} to apply again with someone else's information.
        </PrequalifiedBannerWrapper>
      )
    },
  )
export type PrequalMessageType = 'default' | 'full'

type PrequalifiedBannersViewProps = {
  companyName: string
  hasLogo?: boolean
  className?: string
  showDeclined?: boolean
  showLearnMore?: boolean
  prequalMessageType?: PrequalMessageType
} & (
  | {
      records: PrequalRecordWithContactName[]
      recordsMinimal?: never
    }
  | { recordsMinimal: PrequalRecordMinimal[]; records?: never }
)

export const PrequalifiedBannersView = React.memo<PrequalifiedBannersViewProps>(
  ({
    companyName,
    className,
    records,
    recordsMinimal,
    prequalMessageType = 'default',
    hasLogo = false,
    showDeclined = true,
    showLearnMore = false,
  }) => {
    const declinedRecords = useMemo(
      () => records?.filter(pr => pr.status === 'DECLINED'),
      [records],
    )

    const declinedRecordsMinimal = useMemo(
      () => recordsMinimal?.filter(pr => pr.status === 'DECLINED'),
      [recordsMinimal],
    )

    const prequalifiedRecords = useMemo(
      () => records?.filter(pr => pr.status === 'PREQUALIFIED'),
      [records],
    )

    const prequalRecordsMinimal = useMemo(
      () => recordsMinimal?.filter(pr => pr.status === 'PREQUALIFIED'),
      [recordsMinimal],
    )

    const getPrequalMessage = useCallback(
      (name: string, prequalRecord: PrequalRecord) => {
        if (prequalMessageType === 'full') {
          return (
            <>
              {`${name} has already `}
              <span>
                <LoanDetailsLink
                  record={prequalRecord}
                  text={`pre-qualified for ${formatUscWholeDollars(
                    prequalRecord.maxQualifiedAmountUsc ?? 0,
                  )}.`}
                />
              </span>{' '}
              Wisetack has a limit for $25k at any given time.
              <RenderIf if={showLearnMore}>
                {' '}
                <PrequalLearnMoreLink />
              </RenderIf>
            </>
          )
        }

        return (
          <>
            {`Great news! ${name} `}
            <span>
              <LoanDetailsLink
                record={prequalRecord}
                text={`pre-qualified for ${formatUscWholeDollars(
                  prequalRecord.maxQualifiedAmountUsc ?? 0,
                )}.`}
              />
            </span>
            <RenderIf if={showLearnMore}>
              <PrequalLearnMoreLink />
            </RenderIf>
          </>
        )
      },
      [prequalMessageType, showLearnMore],
    )

    const hasBanner = useMemo(
      () =>
        declinedRecords?.length ||
        prequalifiedRecords?.length ||
        declinedRecordsMinimal?.length ||
        prequalRecordsMinimal?.length,
      [
        declinedRecords,
        prequalifiedRecords,
        declinedRecordsMinimal,
        prequalRecordsMinimal,
      ],
    )

    if (!hasBanner) return null

    return (
      <div
        className={classNames('flex flex-1 flex-col gap-3 text-sm', className)}
      >
        <RenderIf if={showDeclined}>
          {declinedRecords?.map(({ contactFullName }) => (
            <DeclinedPrequalifiedBannerView
              key={contactFullName}
              name={contactFullName}
              companyName={companyName}
              hasLogo={hasLogo}
            />
          ))}
          {declinedRecordsMinimal?.map(({ name }) => (
            <DeclinedPrequalifiedBannerView
              key={name}
              name={name}
              companyName={companyName}
              hasLogo={hasLogo}
            />
          ))}
        </RenderIf>
        {prequalifiedRecords?.map(({ contactFullName, ...rest }) => (
          <PrequalifiedBannerWrapper
            key={contactFullName}
            type="success"
            hasLogo={hasLogo}
          >
            {getPrequalMessage(contactFullName, rest)}
          </PrequalifiedBannerWrapper>
        ))}
        {prequalRecordsMinimal?.map(({ name, maxAmountUsc }) => (
          <PrequalifiedBannerWrapper
            key={name}
            type="success"
            hasLogo={hasLogo}
          >
            {`Great news, ${name}! You pre-qualified for ${formatUscWholeDollars(
              maxAmountUsc,
            )}.`}
          </PrequalifiedBannerWrapper>
        ))}
      </div>
    )
  },
)

export type PrequalBannersProps = {
  accountGuid: string
  companyName: string
  hasLogo?: boolean
  className?: string
  showDeclined?: boolean
  prequalMessageType?: PrequalMessageType
  showLearnMore?: boolean
}

export const PrequalBannersSubscription = React.memo<PrequalBannersProps>(
  ({
    accountGuid,
    companyName,
    className,
    hasLogo = false,
    showDeclined = true,
    showLearnMore = false,
  }) => {
    const [accountPrequalSubscription] = useSubscription({
      query: PREQUAL_RECORD_SUBSCRIPTION_BY_ACCOUNT,
      variables: {
        accountGuid,
      },
    })

    return (
      <UrqlSubscriptionLoader
        subscription={accountPrequalSubscription}
        render={data => {
          const accountPrequalRecords = data.wisetackPrequalRecords.map(
            convertFetchedToPrequalRecordWithContactName,
          )
          if (!accountPrequalRecords?.length) return <></>

          return (
            <PrequalifiedBannersView
              className={className}
              companyName={companyName}
              hasLogo={hasLogo}
              records={accountPrequalRecords}
              showDeclined={showDeclined}
              showLearnMore={showLearnMore}
            />
          )
        }}
      />
    )
  },
)

export const PrequalBanners = React.memo<PrequalBannersProps>(
  ({
    accountGuid,
    companyName,
    className,
    prequalMessageType,
    hasLogo = false,
    showDeclined = true,
    showLearnMore = false,
  }) => {
    const accountPrequalQuery = useQuery({
      query: PREQUAL_RECORD_QUERY_BY_ACCOUNT,
      variables: {
        accountGuid,
      },
    })
    return (
      <GqlQueryLoader
        query={accountPrequalQuery}
        render={data => {
          const accountPrequalRecords = data.wisetackPrequalRecords.map(
            convertFetchedToPrequalRecordWithContactName,
          )
          if (!accountPrequalRecords?.length) return <></>

          return (
            <div className="w-full">
              <PrequalifiedBannersView
                className={className}
                companyName={companyName}
                hasLogo={hasLogo}
                records={accountPrequalRecords}
                showDeclined={showDeclined}
                prequalMessageType={prequalMessageType}
                showLearnMore={showLearnMore}
              />
            </div>
          )
        }}
      />
    )
  },
)
