import {
  Address,
  BzDateFns,
  Location,
  bzExpect,
  expectUsState,
  getStateAbbreviationOrUndefined,
  nextGuid,
} from '@breezy/shared'
import {
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  message,
} from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { useCallback } from 'react'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useExpectedLocationMunicipalityFieldEnabledForCompany } from '../../providers/PrincipalUser'
import { AddressFormItem } from '../Addresses/AddressFormItem/AddressFormItem'
import DatePicker from '../DatePicker/DatePicker'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'

export type CreateOrEditNewLocationFormProps = {
  onSubmitStart?: () => void
  onSubmitSuccess?: (newLocationGuid: string) => Promise<string>
  onCancelButtonPressed?: () => void
  onLocationUpdated?: () => void
  onLocationAdded?: () => void
  setAsBillingAddressByDefault?: boolean
  accountGuid?: string
  editingLocation?: Location
  hideDisplayNameField?: boolean
  showDivider?: boolean
  showCancelSubmitButtons?: boolean
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  labelClassName?: string
  flexRowSpaceX?: string
  extendedForm?: FormInstance<
    Record<string, unknown> & AccountLocationFormSchema
  >
}

export type AccountLocationFormSchema = {
  displayName: string
  addressLineOne: string
  addressLineTwo: string
  addressCity: string
  addressState: string
  addressZipCode: string
  estimatedSquareFootage: number
  estimatedBuildDate: Date
  locationPropertyType: string
  municipality: string
  setAsBillingAddress: boolean
}

const CreateOrEditNewAccountLocationForm = ({
  onCancelButtonPressed,
  onLocationUpdated,
  onLocationAdded,
  accountGuid,
  hideDisplayNameField = false,
  editingLocation,
  showDivider = true,
  showCancelSubmitButtons = true,
  justifyFormCancelSubmitButtons = 'end',
  labelClassName = '',
  flexRowSpaceX = 'space-x-4',
  extendedForm,
  setAsBillingAddressByDefault = false,
}: CreateOrEditNewLocationFormProps) => {
  const billingAddresses = useFeatureFlag('billingAddresses')
  const [thisAccountLocationForm] = Form.useForm<AccountLocationFormSchema>()
  const municipalityFieldEnabled =
    useExpectedLocationMunicipalityFieldEnabledForCompany()

  const form = extendedForm ?? thisAccountLocationForm

  const addressLineOne = useWatch('addressLineOne', form)
  const locationDisplayNamePlaceholder = addressLineOne
    ? `${addressLineOne} (default)`
    : 'Leave blank for system-calculated display name'

  const locationMutation =
    trpc.location['account-locations:upsert'].useMutation()
  const upsertBillingAddressMutation =
    trpc.accounts['account-billing-address:upsert'].useMutation()

  const upsertAccountLocationViaApi = useCallback(
    (values: AccountLocationFormSchema) => {
      const stateAbbreviation = expectUsState(values.addressState).abbreviation
      const currentAccountGuid = bzExpect(
        accountGuid,
        'accountGuid',
        'Account Guid not found',
      )

      const locationGuid = editingLocation?.locationGuid || nextGuid()

      const moveNext = () => {
        if (editingLocation && onLocationUpdated) {
          onLocationUpdated()
        } else if (!editingLocation && onLocationAdded) {
          onLocationAdded()
        }
      }

      locationMutation.mutate(
        {
          accountGuid: currentAccountGuid,
          location: {
            locationGuid: locationGuid,
            displayName: values.displayName,
            address: {
              line1: values.addressLineOne,
              line2: values.addressLineTwo || undefined,
              city: values.addressCity,
              stateAbbreviation,
              zipCode: values.addressZipCode,
            },
            estimatedSquareFootage: values.estimatedSquareFootage,
            estimatedBuildDate: values.estimatedBuildDate
              ? BzDateFns.formatLocalDate(values.estimatedBuildDate)
              : undefined,
            municipality: values.municipality,
          },
          isArchived: false,
        },
        {
          onSuccess() {
            if (values.setAsBillingAddress) {
              upsertBillingAddressMutation.mutate(
                {
                  accountGuid: currentAccountGuid,
                  billingAddress: {
                    line1: values.addressLineOne,
                    line2: values.addressLineTwo || undefined,
                    city: values.addressCity,
                    stateAbbreviation,
                    zipCode: values.addressZipCode,
                  },
                },
                {
                  onSuccess: moveNext,
                  onError: () => {
                    message.error('Error setting location as billing address')
                    moveNext()
                  },
                },
              )
            }
            moveNext()
          },
        },
      )
    },
    [
      accountGuid,
      editingLocation,
      locationMutation,
      onLocationUpdated,
      onLocationAdded,
      upsertBillingAddressMutation,
    ],
  )

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={upsertAccountLocationViaApi}
      validateTrigger="onBlur"
    >
      {!hideDisplayNameField && (
        <Form.Item
          className={labelClassName}
          label="Location Display Name"
          name="displayName"
          initialValue={editingLocation?.displayName}
          tooltip="Leave blank for system-calculated display name"
        >
          <Input
            className="w-full"
            placeholder={locationDisplayNamePlaceholder}
          />
        </Form.Item>
      )}
      <AddressFormItem
        required
        onAddressChanged={(addressObj: Address) => {
          form.setFieldsValue({
            addressLineOne: addressObj.line1,
            addressCity: addressObj.city,
            addressState: getStateAbbreviationOrUndefined(
              addressObj.stateAbbreviation,
            ),
            addressZipCode: addressObj.zipCode,
          })
        }}
        flexRowSpaceX={flexRowSpaceX}
        address={editingLocation?.address}
        labelClassName={labelClassName}
      />
      <Form.Item
        className={labelClassName}
        label="Estimated build year"
        name="estimatedBuildDate"
      >
        <DatePicker
          allowClear
          placeholder=""
          format="yyyy"
          picker="year"
          className="w-full"
        />
      </Form.Item>
      <Form.Item
        className={labelClassName}
        label="Estimated square footage"
        name="estimatedSquareFootage"
        initialValue={editingLocation?.estimatedSquareFootage}
      >
        <InputNumber
          className="w-full"
          placeholder="Enter square footage"
          min={0}
          max={100000}
          controls={false}
        />
      </Form.Item>
      {municipalityFieldEnabled || editingLocation?.municipality ? (
        <Form.Item
          className={labelClassName}
          label="Municipality"
          name="municipality"
          initialValue={editingLocation?.municipality}
        >
          <Input className="w-full" placeholder="Add Municipality" />
        </Form.Item>
      ) : null}
      {billingAddresses && !editingLocation && (
        <div className="w-full">
          <Form.Item
            className={labelClassName}
            name="setAsBillingAddress"
            label="Set Location as Account Billing Address"
            initialValue={setAsBillingAddressByDefault}
          >
            <Radio.Group optionType="button">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      )}

      {showDivider && <Divider />}
      {showCancelSubmitButtons && (
        <div className="flex w-full justify-end">
          <FormCancelSubmitButtons
            onCancel={onCancelButtonPressed}
            primaryButtonText={editingLocation ? 'Save' : 'Create'}
            justify={justifyFormCancelSubmitButtons}
            disabled={locationMutation.isLoading}
          />
        </div>
      )}
    </Form>
  )
}

export default CreateOrEditNewAccountLocationForm
