import { PermissionV1 } from '@breezy/shared'
import { Navigate } from 'react-router-dom'
import { usePrincipalUser } from '../../../providers/PrincipalUser'
import { Composable } from '../../../utils/Composable'
import { Page } from '../../Page/Page'

type NotACompanyUserWarningProps = Partial<Composable> & {
  redirectToRouteIfCompanyUser?: string
}

const NotACompanyUserWarning = ({
  children,
  redirectToRouteIfCompanyUser,
}: NotACompanyUserWarningProps) => {
  const principal = usePrincipalUser().principal
  const isSuperUser =
    principal?.permissions.includes(PermissionV1.ALL_ACTIONS) ||
    principal?.permissions.includes(PermissionV1.EXECUTE_DEV_TOOLS)
  if (redirectToRouteIfCompanyUser && principal?.company) {
    return <Navigate to={redirectToRouteIfCompanyUser} replace />
  }

  if (!!children && isSuperUser) {
    return <>{children}</>
  }

  return (
    <Page requiresCompanyUser={false}>
      <h3>
        You tried to access a page that required a company, but you don't have a
        company
      </h3>
    </Page>
  )
}

export default NotACompanyUserWarning
