import {
  MaintenancePlanCollapsibleAndConsumptionViewModel,
  MaintenancePlanStatus,
} from '@breezy/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { JobMaintenancePlanVisitToggle } from '../../components/JobMaintenancePlanVisitToggle/JobMaintenancePlanVisitToggle'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'

type JobMaintenancePlanVisitsProps = {
  jobGuid: string
  maintenancePlans: MaintenancePlanCollapsibleAndConsumptionViewModel[]
  activeMaintenancePlanVisitGuid?: string
  refetchJob: () => void
  loadingJob?: boolean
}

export const JobMaintenancePlanVisits =
  React.memo<JobMaintenancePlanVisitsProps>(
    ({
      maintenancePlans,
      jobGuid,
      activeMaintenancePlanVisitGuid,
      refetchJob,
      loadingJob = false,
    }) => {
      // Filter out canceled maintenance plans
      maintenancePlans = maintenancePlans.filter(
        p => p.status !== MaintenancePlanStatus.CANCELED,
      )
      // Optimistically render the active maintenance plan visit
      const [currentVisitGuid, setCurrentVisitGuid] = useState<
        string | undefined
      >(activeMaintenancePlanVisitGuid)
      useEffect(() => {
        setCurrentVisitGuid(activeMaintenancePlanVisitGuid)
      }, [activeMaintenancePlanVisitGuid])

      const message = useMessage()

      const upsertJobMaintenancePlanVisitMut =
        trpc.jobs['jobs:upsert-maintenance-plan-visit'].useMutation()

      const onVisitSelected = useCallback(
        async (maintenancePlanVisitGuid: string | undefined) => {
          if (maintenancePlanVisitGuid === activeMaintenancePlanVisitGuid) {
            return
          }
          setCurrentVisitGuid(maintenancePlanVisitGuid)

          try {
            await upsertJobMaintenancePlanVisitMut.mutateAsync({
              jobGuid,
              visitGuid: maintenancePlanVisitGuid,
            })
          } catch (e) {
            console.error(e)
            message.error('Failed to update maintenance plan visit')
          }
          refetchJob()
        },
        [
          jobGuid,
          message,
          refetchJob,
          activeMaintenancePlanVisitGuid,
          upsertJobMaintenancePlanVisitMut,
        ],
      )

      if (maintenancePlans.length === 0) return null

      const loading = loadingJob || upsertJobMaintenancePlanVisitMut.isLoading

      return (
        <JobMaintenancePlanVisitToggle
          maintenancePlans={maintenancePlans}
          activeMaintenancePlanVisitGuid={currentVisitGuid}
          onVisitSelected={onVisitSelected}
          dataTestId="job-maintenance-plan-visits-card"
          loading={loading}
        />
      )
    },
  )
