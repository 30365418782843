import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsTechAssignedToAccount } from '../technician/useIsTechAssignedToAccount'
import { useIsOfficeApp } from '../useIsOfficeApp'
import { useCanViewAllFieldAccounts } from './useCanViewAllFieldAccounts'
import { useHasFieldAccountsFullAccess } from './useHasFieldAccountsFullAccess'
import { useIsAuthorized } from './useIsAuthorized'

const useCanManageOfficeAccount = () => {
  const isOfficeApp = useIsOfficeApp()
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    if (isOfficeApp)
      return isAuthorized(PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE)

    return true
  }, [isOfficeApp, isAuthorized])
}

const useCanManageFieldAssignedAccounts = () => {
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    return isAuthorized(PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED)
  }, [isAuthorized])
}

export const useCanManageAccount = (accountGuid: string) => {
  const isOfficeApp = useIsOfficeApp()
  const { isTechAssignedToAccount, isLoading } =
    useIsTechAssignedToAccount(accountGuid)

  const canManageOfficeAccount = useCanManageOfficeAccount()
  const hasFieldAccountsFullAccess = useHasFieldAccountsFullAccess()
  const canViewAllFieldAccounts = useCanViewAllFieldAccounts()

  const canManageFieldAssignedAccounts = useCanManageFieldAssignedAccounts()

  let canManage = false
  let loadingStatus = true

  if (isOfficeApp) {
    canManage = canManageOfficeAccount
    loadingStatus = false
  } else if (hasFieldAccountsFullAccess) {
    canManage = true
    loadingStatus = false
  } else if (canViewAllFieldAccounts || canManageFieldAssignedAccounts) {
    canManage = isTechAssignedToAccount
    loadingStatus = isLoading
  } else {
    loadingStatus = false
  }

  return {
    canManage,
    isLoading: loadingStatus,
  }
}
