import { Address, getStateAbbreviationOrUndefined } from '@breezy/shared'
import { Divider, Form, FormInstance } from 'antd'
import { AddressFormItem } from '../Addresses/AddressFormItem/AddressFormItem'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'

export const mapAddressFormSchemaToAddress = (
  a: AddressFormSchema,
): Address => {
  const stateAbbreviation = getStateAbbreviationOrUndefined(a.addressState)
  if (!stateAbbreviation) {
    throw new Error('Valid US State abbreviation is required')
  }
  return {
    line1: a.addressLineOne,
    line2: a.addressLineTwo,
    city: a.addressCity,
    stateAbbreviation,
    zipCode: a.addressZipCode,
  }
}

export type AddressFormSchema = {
  addressLineOne: string
  addressLineTwo: string
  addressCity: string
  addressState: string
  addressZipCode: string
}

export type AddressFormProps = {
  required?: boolean
  onSubmitStart?: () => void
  onSubmitSuccess?: (addressGuid: string) => Promise<string>
  onCancelButtonPressed?: () => void
  onAddressAdded?: () => void
  showDivider?: boolean
  showCancelSubmitButtons?: boolean
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  labelClassName?: string
  flexRowSpaceX?: string
  extendedForm?: FormInstance<Record<string, unknown> & AddressFormSchema>
}

const AddressForm = ({
  required,
  onCancelButtonPressed,
  onAddressAdded,
  showDivider = true,
  showCancelSubmitButtons = true,
  justifyFormCancelSubmitButtons = 'end',
  labelClassName = '',
  flexRowSpaceX = 'space-x-4',
  extendedForm,
}: AddressFormProps) => {
  const [baseForm] = Form.useForm<AddressFormSchema>()
  const form = extendedForm ?? baseForm

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onAddressAdded}
      validateTrigger="onBlur"
    >
      <AddressFormItem
        required={!!required}
        onAddressChanged={(a: Address) => {
          form.setFieldsValue({
            addressLineOne: a.line1,
            addressLineTwo: a.line2,
            addressCity: a.city,
            addressState: getStateAbbreviationOrUndefined(a.stateAbbreviation),
            addressZipCode: a.zipCode,
          })
        }}
        flexRowSpaceX={flexRowSpaceX}
        labelClassName={labelClassName}
      />
      {showDivider && <Divider />}
      {showCancelSubmitButtons && (
        <div className="flex w-full justify-end">
          <FormCancelSubmitButtons
            onCancel={onCancelButtonPressed}
            primaryButtonText={'Save'}
            justify={justifyFormCancelSubmitButtons}
          />
        </div>
      )}
    </Form>
  )
}

export default AddressForm
