import { PaymentStatus, PaymentStatusDisplayNames } from '@breezy/shared'
import React from 'react'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'

const toPaymentStatusDetails = (
  status: PaymentStatus,
): { text: string; color: StatusTagColor } => {
  switch (status) {
    case PaymentStatus.SUBMITTING:
      return {
        text: PaymentStatusDisplayNames[PaymentStatus.SUBMITTING],
        color: 'blue',
      }
    case PaymentStatus.PENDING:
      return {
        text: PaymentStatusDisplayNames[PaymentStatus.PENDING],
        color: 'darkGray',
      }
    case PaymentStatus.PROCESSING:
      return {
        text: PaymentStatusDisplayNames[PaymentStatus.PROCESSING],
        color: 'cyan',
      }
    case PaymentStatus.PAID:
      return {
        text: PaymentStatusDisplayNames[PaymentStatus.PAID],
        color: 'green',
      }
    case PaymentStatus.FAILED:
      return {
        text: PaymentStatusDisplayNames[PaymentStatus.FAILED],
        color: 'red',
      }
    // [X] Audited in BZ-921 -> No Action Required immediately
    // TODO: Add a different color for canceled
    case PaymentStatus.CANCELED:
      return {
        text: PaymentStatusDisplayNames[PaymentStatus.CANCELED],
        color: 'red',
      }
    default:
      return { text: 'Unknown', color: 'gray' }
  }
}

type PaymentStatusTagProps = {
  status: PaymentStatus
  className?: string
}

const PaymentStatusTag = React.memo<PaymentStatusTagProps>(
  ({ status, className }) => {
    return (
      <StatusTag
        className={className}
        text={toPaymentStatusDetails(status).text}
        color={toPaymentStatusDetails(status).color}
        border="strong"
      />
    )
  },
)

export default PaymentStatusTag
