import { MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/tiptap/styles.css'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import { ConfigProvider as AntConfigProvider, App } from 'antd'
import 'antd/dist/reset.css'
import React, { Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import ReactDOM from 'react-dom/client'
import { CSModeWrapper } from '../../components/Admin/CSMode/CSModeWrapper'
import ErrorModal from '../../components/ErrorModal/ErrorModal'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { PaymentStatusModalProvider } from '../../components/PaymentWorkflow/providers/PaymentStatusModalProvider'
import { PaymentStatusProvider } from '../../components/PaymentWorkflow/providers/PaymentStatusProvider'
import { TwilioPhoneProvider } from '../../components/PhoneIntegrated/useTwilioPhone'
import { ConfigProvider } from '../../config'
import {
  BreakpointContextWrapper,
  useIsTouchScreen,
} from '../../hooks/useIsMobile'
import { PricebookPhotosEnabledProvider } from '../../hooks/useIsPricebookPhotosEnabled'
import AuthWrapper from '../../providers/AuthWrapper'
import { CompanyFinancialConfigWrapper } from '../../providers/CompanyFinancialConfigWrapper'
import { ForceHardRefreshWrapper } from '../../providers/ForceHardRefreshWrapper'
import { GoogleMapsWrapper } from '../../providers/GoogleMaps'
import { GooglePlacesWrapper } from '../../providers/GoogleMapsPlaces'
import { LaunchDarklyWrapper } from '../../providers/LaunchDarklyWrapper'
import { PlatformVersionWrapper } from '../../providers/PlatformVersionWrapper'
import { PricebookProvider } from '../../providers/PricebookProvider'
import { PrincipalUserWrapper } from '../../providers/PrincipalUser'
import { TrpcWrapper } from '../../providers/TrpcWrapper'
import UrqlWrapper from '../../providers/UrqlWrapper'
import '../../themes/ant-override.less'
import '../../themes/tailwind.less'
import '../../themes/theme.less'
import { initTimeLibs } from '../../utils/init-time-libs'

export const CommonAppWrapper = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const isTouchScreen = useIsTouchScreen()
    return (
      <Suspense
        fallback={
          <div className="flex h-screen w-screen items-center justify-center">
            <LoadingSpinner />
          </div>
        }
      >
        <MantineProvider>
          <AntConfigProvider>
            <App>
              <ConfigProvider>
                <BreakpointContextWrapper>
                  <ErrorModal>
                    <DndProvider
                      backend={isTouchScreen ? TouchBackend : HTML5Backend}
                    >
                      {children}
                    </DndProvider>
                  </ErrorModal>
                </BreakpointContextWrapper>
              </ConfigProvider>
            </App>
          </AntConfigProvider>
        </MantineProvider>
      </Suspense>
    )
  },
)

export const withApp = (app: JSX.Element) => {
  initTimeLibs()

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )
  root.render(
    // <React.StrictMode>
    <CommonAppWrapper>{app}</CommonAppWrapper>,
    // </React.StrictMode>,
  )
}

type AuthenticatedAppWrapperProps = React.PropsWithChildren

export const AuthenticatedAppWrapper = React.memo<AuthenticatedAppWrapperProps>(
  ({ children }) => (
    <AuthWrapper>
      <TrpcWrapper>
        <GooglePlacesWrapper>
          <GoogleMapsWrapper>
            <PrincipalUserWrapper>
              <CSModeWrapper>
                <LaunchDarklyWrapper>
                  <ForceHardRefreshWrapper>
                    <PlatformVersionWrapper>
                      <CompanyFinancialConfigWrapper>
                        <UrqlWrapper>
                          <TwilioPhoneProvider>
                            <PricebookProvider>
                              <PaymentStatusProvider>
                                <PaymentStatusModalProvider>
                                  <PricebookPhotosEnabledProvider>
                                    {children}
                                  </PricebookPhotosEnabledProvider>
                                </PaymentStatusModalProvider>
                              </PaymentStatusProvider>
                            </PricebookProvider>
                          </TwilioPhoneProvider>
                        </UrqlWrapper>
                      </CompanyFinancialConfigWrapper>
                    </PlatformVersionWrapper>
                  </ForceHardRefreshWrapper>
                </LaunchDarklyWrapper>
              </CSModeWrapper>
            </PrincipalUserWrapper>
          </GoogleMapsWrapper>
        </GooglePlacesWrapper>
      </TrpcWrapper>
    </AuthWrapper>
  ),
)
