import { gql } from '../../generated'

export const RELEVANT_INVOICE_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription GetRelevantInvoiceData($companyGuid: uuid!) {
    companiesByPk(companyGuid: $companyGuid) {
      name
      companyConfig {
        blurb
        qboAutoSyncInvoiceOnFullyPaid
        qboAutoSyncInvoiceOnIssued
        qboAutoSyncInvoiceOnPayment
      }
      billingProfile {
        businessFullLegalName
        contractorLicenseNumber
        invoiceTerm
        logoUrl
        invoiceDisclaimer
        businessAddress {
          city
          line1
          line2
          zipCode
          stateAbbreviation
        }
        websiteDisplayName
        emailAddress {
          emailAddress
        }
        phoneNumber {
          phoneNumber
        }
        defaultPricebookTaxRateGuid
      }
      pricebookItems {
        pricebookItemGuid
      }
    }
  }
`)

export const RELEVANT_INVOICE_EDIT_ACCOUNT_DATA_SUBSCRIPTION =
  gql(/* GraphQL */ `
    subscription GetRelevantInvoiceEditAccountData($accountGuid: uuid!) {
      accountsByPk(accountGuid: $accountGuid) {
        accountType
        accountLocations(orderBy: { createdAt: ASC }) {
          location {
            address {
              addressGuid
              city
              line1
              line2
              stateAbbreviation
              zipCode
            }
          }
          locationGuid
        }
        accountContacts(orderBy: { primary: DESC }) {
          contact {
            contactGuid
            primaryEmailAddress {
              emailAddress
            }
            fullName
            primaryPhoneNumber {
              phoneNumber
            }
          }
        }
        mailingAddress {
          addressGuid
          city
          line1
          line2
          stateAbbreviation
          zipCode
        }
      }
    }
  `)

export const RELEVANT_INVOICE_EDIT_JOB_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription GetRelevantInvoiceEditJobData($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      locationGuid
      location {
        addressGuid
        address {
          zipCode
        }
      }
      customerPurchaseOrderNumber
      workCompletedAt
      pointOfContact {
        contactGuid
        fullName
        primaryEmailAddress {
          emailAddress
        }
        primaryPhoneNumber {
          phoneNumber
        }
      }
    }
  }
`)

export const UPSERT_INVOICE_MUTATION = gql(/* GraphQL */ `
  mutation UpsertInvoice(
    $invoiceGuid: uuid!
    $validCartItemGuids: [uuid!]!
    $validInvoiceDiscountGuids: [uuid!]!
    $invoice: InvoicesInsertInput!
  ) {
    insertInvoicesOne(
      object: $invoice
      onConflict: {
        constraint: invoices_pkey
        updateColumns: [
          billingAddressGuid
          billingContactGuid
          customerPurchaseOrderNumber
          dueAt
          invoiceTerm
          messageHtml
          serviceAddressGuid
          serviceCompletionDate
          status
          subtotalUsc
          taxRate
          taxRateGuid
          totalUsc
          invoiceTerm
          taxRateName
          issuedAt
          updatedAt
          dynamicPricingType
        ]
      }
    ) {
      __typename
    }
    deleteCartItems(
      where: {
        invoiceCartItems: {
          invoiceGuid: { _eq: $invoiceGuid }
          cartItemGuid: { _nin: $validCartItemGuids }
        }
      }
    ) {
      __typename
    }
    deleteInvoiceDiscounts(
      where: {
        invoiceGuid: { _eq: $invoiceGuid }
        invoiceDiscountGuid: { _nin: $validInvoiceDiscountGuids }
      }
    ) {
      __typename
    }
  }
`)

export const UPDATE_INVOICE_MUTATION = gql(/* GraphQL */ `
  mutation UpdateInvoice($invoiceGuid: uuid!, $set: InvoicesSetInput!) {
    updateInvoicesByPk(pkColumns: { invoiceGuid: $invoiceGuid }, _set: $set) {
      __typename
    }
  }
`)

export const INVOICE_FRAGMENT = gql(/* GraphQL */ `
  fragment Invoice on Invoices {
    messageHtml
    status
    companyGuid
    serviceAddress {
      city
      addressGuid
      line1
      line2
      stateAbbreviation
      zipCode
    }
    serviceCompletionDate
    taxRate
    taxRateName
    taxRateGuid
    invoiceTerm
    dynamicPricingType
    billingContact {
      fullName
      contactGuid
      primaryPhoneNumber {
        phoneNumber
      }
      primaryEmailAddress {
        emailAddress
      }
    }
    billingAddress {
      city
      addressGuid
      line1
      line2
      stateAbbreviation
      zipCode
    }
    invoiceEvents(orderBy: { occurredAt: DESC }) {
      eventType
      title
      createdByUser {
        userGuid
        fullName
        firstName
        lastName
      }
      metadata
      occurredAt
    }
    invoiceDiscounts(orderBy: { seq: ASC }) {
      name
      discountType
      discountAmountUsc
      discountRate
      invoiceDiscountGuid
      descriptionHtml
    }
    invoiceCartItems(orderBy: { seq: ASC }) {
      seq
      cartItem {
        itemType: cartItemType
        description
        isDiscountable
        isTaxable
        name
        unitPriceUsc
        quantity
        cartItemGuid
        originalPricebookItemGuid
        photo {
          cdnUrl
          photoGuid
        }
      }
    }
    jobLink {
      jobGuid
      job {
        createdAt
        displayId
        jobType {
          name
        }
        pointOfContact {
          firstName
          fullName
          lastName
          primaryPhoneNumber {
            phoneNumber
            type
          }
          primaryEmailAddress {
            emailAddress
          }
          contactGuid
        }
      }
    }
    maintenancePlanLink {
      maintenancePlanGuid
      maintenancePlan {
        activatedAt
        createdAt
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
        }
        location {
          addressGuid
        }
      }
    }
    locationLink {
      locationGuid
      location {
        addressGuid
      }
    }
    estimateLink {
      estimate {
        estimateGuid
        displayId
        createdAt
        acceptedAt
      }
    }
    jobAppointmentLink {
      jobAppointment {
        jobAppointmentGuid
        appointmentType
        appointmentWindowStart
        assignments {
          technician {
            firstNameInitial
          }
        }
      }
    }
    accountGuid
    displayId
    dueAt
    issuedAt
    createdAt
    customerPurchaseOrderNumber
    billingContactGuid
    signatureFileUrl
    account {
      createdAt
      accountContacts(where: { primary: { _eq: true } }) {
        contact {
          firstName
          fullName
          lastName
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            phoneNumber
            type
          }
          contactGuid
        }
      }
      mailingAddressGuid
    }
    paymentRecords(
      where: { paymentStatuses: { paymentStatus: { _nin: [CANCELED] } } }
      orderBy: { occurredAt: ASC }
    ) {
      paymentRecordGuid
      paymentMethod
      occurredAt
      paymentRefunds {
        amountUsd
        occurredAt
      }
      amountUsd
      paymentStatuses(
        orderBy: { occurredAt: DESC, createdAt: DESC }
        limit: 1
      ) {
        paymentStatus
      }
    }
    qboStaleInfo {
      stale: isQboStale
      syncedAt: qboSyncedAt
      updatedAt
    }
  }
`)

export const INVOICE_FRAGMENT_WITH_GUID = gql(/* GraphQL */ `
  fragment InvoiceWithGuid on Invoices {
    invoiceGuid
    ...Invoice
  }
`)

export const INVOICE_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription InvoiceData($invoiceGuid: uuid!) {
    invoicesByPk(invoiceGuid: $invoiceGuid) {
      ...Invoice
    }
  }
`)

export const INVOICES_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription InvoicesData($where: InvoicesBoolExp) {
    invoices(where: $where) {
      ...InvoiceWithGuid
    }
  }
`)

export const INVOICES_DATA_QUERY = gql(/* GraphQL */ `
  subscription InvoicesData($where: InvoicesBoolExp) {
    invoices(where: $where) {
      ...InvoiceWithGuid
    }
  }
`)
