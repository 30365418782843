import cn from 'classnames'
import React from 'react'
type ChipProps = {
  className?: string
}
const Chip = React.memo<React.PropsWithChildren<ChipProps>>(
  ({ children, className }) => {
    return (
      <div
        className={cn(
          'flex flex-row items-center rounded-md bg-bz-gray-300 p-[2px] px-2 text-xs leading-5',
          'text-overflow-ellipsis overflow-hidden whitespace-nowrap',
          className,
        )}
      >
        {children}
      </div>
    )
  },
)

export default Chip
