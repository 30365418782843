import { Form } from 'antd'
import FinancingWizardFooter from './FinancingWizardFooter'

type FinancingAppFormWrapperProps = React.PropsWithChildren<{
  onSubmit: () => void
  onCancel: () => void
  submitButtonText: string
  dataDdActionName?: string
  disabled?: boolean
  disablePrimaryButton?: boolean
}>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const FinancingAppFormWrapper = ({
  children,
  onSubmit,
  onCancel,
  submitButtonText,
  dataDdActionName,
  disabled = false,
  disablePrimaryButton = false,
}: FinancingAppFormWrapperProps) => {
  return (
    <div className="flex h-full min-h-0 w-full flex-1 flex-col">
      <Form
        onSubmitCapture={onSubmit}
        layout="vertical"
        className="flex min-h-0 flex-1 flex-col gap-1"
        requiredMark={label => (
          <div className="flex flex-row items-center">
            {label}
            <span className="ml-0.5 text-bz-primary">*</span>
          </div>
        )}
      >
        <div className="mb-6 flex flex-1 flex-col overflow-auto">
          {children}
        </div>
        <FinancingWizardFooter
          onCancel={onCancel}
          onSubmit={onSubmit}
          disabled={disabled}
          submitButtonText={submitButtonText}
          disablePrimaryButton={disablePrimaryButton}
          dataDdActionName={dataDdActionName}
        />
      </Form>
    </div>
  )
}

export default FinancingAppFormWrapper
