import { gql } from '../../generated'

export const LOCATION_OPTION_MAINTENANCE_PLAN_QUERY = gql(/* GraphQL */ `
  query LocationOptionMaintenancePlan($locationGuid: uuid!) {
    locationsByPk(locationGuid: $locationGuid) {
      maintenancePlans {
        maintenancePlanGuid
        status
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
          numVisitCreditsPerYear
        }
        activatedAt
        yearlyPriceUsc
        coveredInstalledEquipmentGuids
        maintenancePlanVisits {
          ...MaintenancePlanVisit
        }
      }
    }
  }
`)
