import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StateSetter } from '../../utils/react-utils'
import { useScheduleContext } from './ScheduleContext'
import {
  DEFAULT_PENDING_CHANGES,
  PendingScheduleChanges,
  resolvePendingChanges,
  SetPendingChangesArg,
  useScheduleOriginalArrivalWindowMap,
  useScheduleOriginalAssignmentMap,
  useScheduleOriginalBlockMap,
} from './utils'

type SchedulePendingChangesContextType = {
  pendingChanges: PendingScheduleChanges
  setPendingChanges: (
    arg: SetPendingChangesArg | SetPendingChangesArg[],
  ) => void
  setPendingChangesRaw: StateSetter<PendingScheduleChanges>
}

const SchedulePendingChangesContext =
  React.createContext<SchedulePendingChangesContextType>({
    pendingChanges: DEFAULT_PENDING_CHANGES,
    setPendingChanges: () => {},
    setPendingChangesRaw: () => {},
  })

export const SchedulePendingChangesWrapper =
  React.memo<React.PropsWithChildren>(({ children }) => {
    const [pendingChanges, setPendingChangesRaw] =
      useState<PendingScheduleChanges>(DEFAULT_PENDING_CHANGES)

    const { scheduleViewModel: vm } = useScheduleContext()

    const originalAssignmentMap = useScheduleOriginalAssignmentMap()
    const originalBlockMap = useScheduleOriginalBlockMap()
    const originalArrivalWindowMap = useScheduleOriginalArrivalWindowMap()

    const setPendingChanges = useCallback(
      (arg: SetPendingChangesArg | SetPendingChangesArg[]) => {
        setPendingChangesRaw(pendingChanges => {
          const args = Array.isArray(arg) ? arg : [arg]
          let changes = pendingChanges
          for (const arg of args) {
            changes = resolvePendingChanges(
              arg,
              changes,
              originalAssignmentMap,
              originalBlockMap,
              originalArrivalWindowMap,
            )
          }
          return changes
        })
      },
      [originalArrivalWindowMap, originalAssignmentMap, originalBlockMap],
    )

    // Side Effect to reset pending changes when the view model changes
    useEffect(() => {
      setPendingChangesRaw(DEFAULT_PENDING_CHANGES)
    }, [vm])

    return (
      <SchedulePendingChangesContext.Provider
        value={{ pendingChanges, setPendingChanges, setPendingChangesRaw }}
      >
        {children}
      </SchedulePendingChangesContext.Provider>
    )
  })

export const useSchedulePendingChanges = () =>
  useContext(SchedulePendingChangesContext)
