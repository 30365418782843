import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const NotCapturedView = React.memo(() => {
  return (
    <div>
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className="orange7 mt-0.5 h-[16px] w-[16px]"
      />
      <span className="orange7 ml-2">Not Captured</span>
    </div>
  )
})
