import { Guid, NotNullish } from '@breezy/shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { PricebookItemFragment } from '../generated/user/graphql'
import { useStrictContext } from '../utils/react-utils'
import { GET_PRICEBOOK_DATA } from './PricebookProvider.gql'
import { useExpectedPrincipal } from './PrincipalUser'

export type PricebookPickerItem = {
  id: Guid
  name: string
  value: number
  cdnUrl?: string
}

export type PricebookPickerCategory<
  T extends PricebookPickerItem = PricebookPickerItem,
> = {
  id: Guid
  name: string
  cdnUrl?: string
  items: (T | PricebookPickerCategory<T>)[]
}

export type PricebookItems = (PricebookPickerItem | PricebookPickerCategory)[]

type PricebookProviderContextType = {
  items: PricebookItems
  rawItems: PricebookItemFragment[]
  loaded: boolean
}

const PricebookProviderContext = React.createContext<
  PricebookProviderContextType | undefined
>(undefined)

const lowPriorityFetch: typeof fetch = (url, opts) =>
  fetch(url, { ...opts, priority: 'low' })

const queryContext = {
  fetch: lowPriorityFetch,
}

type PricebookProviderProps = React.PropsWithChildren

export const PricebookProvider = React.memo<PricebookProviderProps>(
  ({ children }) => {
    const principal = useExpectedPrincipal()

    const [loaded, setLoaded] = useState(false)

    const [{ data }] = useQuery({
      query: GET_PRICEBOOK_DATA,
      pause: !principal.company,
      context: queryContext,
    })

    useEffect(() => {
      if (data) {
        setLoaded(true)
      }
    }, [data])

    const rawItems = useMemo(() => {
      const rawItems = [...(data?.uncategorizedItems ?? [])]

      for (const category of data?.pricebookCategories ?? []) {
        rawItems.push(...category.pricebookItems)
      }

      return rawItems
    }, [data?.pricebookCategories, data?.uncategorizedItems])

    const items = useMemo<PricebookItems>(() => {
      type Datum = NotNullish<typeof data>['pricebookCategories'][number]
      const parentGuidToChildMap: Record<string, Datum[]> = {}

      const rootItems: Datum[] = []

      for (const datum of data?.pricebookCategories ?? []) {
        if (datum.parentCategoryGuid) {
          const list = parentGuidToChildMap[datum.parentCategoryGuid] || []
          list.push(datum)
          parentGuidToChildMap[datum.parentCategoryGuid] = list
        } else {
          rootItems.push(datum)
        }
      }

      const populateChildren = (
        categories: Datum[],
      ): PricebookPickerCategory[] =>
        categories.map(category => {
          const ourItems: PricebookPickerItem[] = category.pricebookItems.map(
            item => ({
              id: item.pricebookItemGuid,
              name: item.name,
              value: item.priceUsd,
              cdnUrl: item.photo?.cdnUrl,
            }),
          )
          const childCategoryItems: PricebookPickerCategory[] =
            populateChildren(
              parentGuidToChildMap[category.pricebookCategoryGuid] ?? [],
            )

          return {
            id: category.pricebookCategoryGuid,
            name: category.name,
            cdnUrl: category.photo?.cdnUrl,
            items: [...ourItems, ...childCategoryItems],
          }
        })

      const categories = populateChildren(rootItems)

      const uncategorizedItems =
        data?.uncategorizedItems.map(item => ({
          id: item.pricebookItemGuid,
          name: item.name,
          value: item.priceUsd,
          cdnUrl: item.photo?.cdnUrl,
        })) ?? []
      return [...categories, ...uncategorizedItems]
    }, [data?.pricebookCategories, data?.uncategorizedItems])

    return (
      <PricebookProviderContext.Provider value={{ items, rawItems, loaded }}>
        {children}
      </PricebookProviderContext.Provider>
    )
  },
)

export const usePricebook = () => useStrictContext(PricebookProviderContext)
