import {
  JobOutcomesCommissionDeductionsPopoverFormSchema,
  JobOutcomesFormSchema,
} from '@breezy/shared'
import { Button, Col, Popover, Radio, Row } from 'antd'
import { PropsWithChildren, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ThinDivider from '../../elements/ThinDivider'
import { useMessage } from '../../utils/antd-utils'
import PercentInputNumber from './PercentInputNumber'
import { UsdInputNumber } from './UsdInputNumber'

type CommissionDeductionsPopoverFormProps = {
  open: boolean
  onCancel?: () => void
  onSubmit?: (values: JobOutcomesCommissionDeductionsPopoverFormSchema) => void
}

const CommissionDeductionsPopoverForm: React.FC<
  PropsWithChildren<CommissionDeductionsPopoverFormProps>
> = ({ open, onCancel, onSubmit, children }) => {
  const message = useMessage()
  const form = useFormContext<JobOutcomesFormSchema>()

  const overheadAllocationDeductionTypeValue = form.watch(
    'overheadAllocationDeductionType',
  )
  const jobCostsDeductionTypeValue = form.watch('jobCostsDeductionType')

  const onFormSubmit = form.handleSubmit(async values => {
    try {
      if (Object.keys(form.formState.errors).length > 0) {
        return
      }

      onSubmit?.(values)

      message.success('Successfully updated fees & job costs.')
    } catch (e) {
      message.error(
        'Failed to update. Please reload the application and try again, or contact support if the problem persists',
      )
    }
  })

  const onClickCancelButton = useCallback(() => {
    onCancel?.()
    form.resetField('overheadAllocationDeductionType')
    form.resetField('overheadAllocationDeductionPercent')
    form.resetField('overheadAllocationFlatDeductionUsd')
    form.resetField('jobCostsDeductionType')
    form.resetField('jobCostsDeductionPercent')
    form.resetField('jobCostsFlatDeductionUsd')
  }, [form, onCancel])

  return (
    <Popover
      open={open}
      content={
        <div style={{ width: '265px' }}>
          <Row style={{ marginBottom: '8px' }}>
            <strong>Fees & Job Costs</strong>
          </Row>

          <p>
            Add any fees and job costs to deduct from the sold revenue to
            calculate the commissionable base.
          </p>

          <Row
            style={{ width: '100%', marginTop: '12px', marginBottom: '8px' }}
          >
            <strong>Overhead Allocation</strong>
          </Row>

          <Row style={{ width: '100%' }}>
            <Col span={15}>
              {overheadAllocationDeductionTypeValue === 'FIXED' ? (
                <Controller
                  key="overheadAllocationFixed"
                  name={'overheadAllocationFlatDeductionUsd'}
                  control={form.control}
                  render={({ field }) => (
                    <UsdInputNumber {...field} style={{ width: '100%' }} />
                  )}
                />
              ) : (
                <Controller
                  key="overheadAllocationPercent"
                  name={'overheadAllocationDeductionPercent'}
                  control={form.control}
                  render={({ field }) => (
                    <PercentInputNumber
                      onChange={field.onChange}
                      ref={field.ref}
                      value={field.value}
                      className="w-full"
                    />
                  )}
                />
              )}
            </Col>

            <Col span={9}>
              <Controller
                control={form.control}
                name="overheadAllocationDeductionType"
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    optionType="button"
                    style={{ marginLeft: '8px' }}
                  >
                    <Radio
                      value="PERCENT"
                      disabled={jobCostsDeductionTypeValue === 'PERCENT'}
                    >
                      %
                    </Radio>
                    <Radio value="FIXED">$</Radio>
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>

          <ThinDivider widthPx={1} />

          <Row style={{ width: '100%', marginBottom: '8px' }}>
            <strong>Job Costs</strong>
          </Row>

          <Row style={{ width: '100%' }}>
            <Col span={15}>
              {jobCostsDeductionTypeValue === 'FIXED' ? (
                <Controller
                  key="jobCostsFixed"
                  name={'jobCostsFlatDeductionUsd'}
                  control={form.control}
                  render={({ field }) => (
                    <UsdInputNumber {...field} style={{ width: '100%' }} />
                  )}
                />
              ) : (
                <Controller
                  key="jobCostsPercent"
                  name={'jobCostsDeductionPercent'}
                  control={form.control}
                  render={({ field }) => (
                    <PercentInputNumber {...field} style={{ width: '100%' }} />
                  )}
                />
              )}
            </Col>

            <Col span={9}>
              <Controller
                control={form.control}
                name="jobCostsDeductionType"
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    optionType="button"
                    style={{ marginLeft: '8px' }}
                  >
                    <Radio
                      value="PERCENT"
                      disabled={
                        overheadAllocationDeductionTypeValue === 'PERCENT'
                      }
                    >
                      %
                    </Radio>
                    <Radio value="FIXED">$</Radio>
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>

          <ThinDivider widthPx={1} />

          <Row
            style={{ width: '100%', marginTop: '12px', paddingTop: '4px' }}
            justify="end"
          >
            <Button
              type="default"
              onClick={onClickCancelButton}
              style={{ marginRight: '8px' }}
            >
              Cancel
            </Button>
            {form.formState.isDirty && (
              <Button htmlType="button" type="primary" onClick={onFormSubmit}>
                Save
              </Button>
            )}
          </Row>
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default CommissionDeductionsPopoverForm
