import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { SwitchField } from '../../../../elements/Forms/SwitchField'
import { TextField } from '../../../../elements/Forms/TextField'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import { CreditCardPaymentFormData } from './CreditCardPaymentForm'

const SendEmailField = React.memo(() => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CreditCardPaymentFormData>()
  return (
    <>
      <Controller
        control={control}
        name="sendEmail"
        render={({ field }) => (
          <div className="mb-2 flex flex-row items-center">
            <label
              htmlFor="sendEmail"
              className="mr-3 flex-1 cursor-pointer font-semibold"
            >
              Email me a receipt and a copy of the invoice
            </label>
            <SwitchField
              {...field}
              withIcons
              onChange={field.onChange}
              checked={field.value}
            />
          </div>
        )}
      />
      <RenderIf if={watch('sendEmail')}>
        <ReactHookFormItem
          control={control}
          name="emailAddress"
          label="Email address"
          required
          errors={errors}
          render={({ field }) => <TextField {...field} />}
        />
      </RenderIf>
    </>
  )
})

export default SendEmailField
