import { z } from 'zod'
import { AsyncFn, isNullish } from '../../common'

export const LatitudeSchema = z.number().min(-90).max(90)
export const LongitudeSchema = z.number().min(-180).max(180)
export type Latitude = z.infer<typeof LatitudeSchema>
export type Longitude = z.infer<typeof LongitudeSchema>
export const CoordinateSchema = z.tuple([LongitudeSchema, LatitudeSchema])

// https://datatracker.ietf.org/doc/html/rfc7946#appendix-A.1
export const PointSchema = z.object({
  type: z.literal('Point'),
  coordinates: CoordinateSchema,
})

export type Point = z.infer<typeof PointSchema>

// Discriminated union of all GeoJSON types https://datatracker.ietf.org/doc/html/rfc7946
export const GeoSchema = z.discriminatedUnion('type', [PointSchema])

export type Geo = z.infer<typeof GeoSchema>

export type StreetAddress = string
export type GeocodeReader = AsyncFn<StreetAddress, Point | undefined>

export type Coordinate = z.infer<typeof CoordinateSchema>

export type CoordinatePair = {
  lat: Latitude
  lng: Longitude
}
export const createPoint = (pair: CoordinatePair): Point => {
  return {
    type: 'Point',
    // NOTE: GeoJSON uses [lng, lat] order :monkaS:
    coordinates: [pair.lng, pair.lat],
  }
}

export const milesToMeters = (miles: number) => miles * 1609.34
export const metersToMiles = (meters: number) => meters / 1609.34

// Type guard to check if an object is a Point
export const isPoint = (value: Geo | undefined): value is Point => {
  if (isNullish(value)) return false

  return value && typeof value?.coordinates === 'object' && value?.coordinates?.length === 2
}
