import { convertFetchLoanRecordToLoanRecord } from '@breezy/backend/src/application-types'
import {
  CANCELABLE_LOAN_STATUSES,
  DECLINEABLE_LOAN_STATUSES,
  LoanChangedStatus,
  LoanRecord,
  LoanStatus,
} from '@breezy/shared'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import React, { useState } from 'react'
import { useSubscription } from 'urql'
import Switch from '../../../elements/Switch/Switch'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import UrqlSubscriptionLoader from '../../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import LoanStatusTag from '../LoanStatusTag/LoanStatusTag'
import { LOAN_RECORD_SUBSCRIPTION } from './DevToolLoanSimulator.gql'
import SimulateLoanPartialApprovalForm from './SimulateLoanPartialApprovalForm'
import { useWisetackWebhookTestMutation } from './hooks'

type DevToolLoanSimulatorProps = {
  loanRecordGuid: string
}

type BasicNextLoanStepInitiatorProps = {
  loanRecord: LoanRecord
  currentStatus: LoanStatus
  targetStatus: LoanChangedStatus
}
const BasicNextLoanStepInitiator = React.memo<BasicNextLoanStepInitiatorProps>(
  ({ loanRecord, currentStatus, targetStatus }) => {
    const { triggerWisetackWebhook, loading } = useWisetackWebhookTestMutation()

    return (
      <div className="flex items-center gap-4">
        <LoanStatusTag status={currentStatus} />
        <FontAwesomeIcon icon={faArrowRight} />
        <LoanStatusTag status={targetStatus} />
        <Button
          type="primary"
          disabled={loading}
          onClick={() => {
            triggerWisetackWebhook({ loanRecord, changedStatus: targetStatus })
          }}
        >
          Simulate
        </Button>
      </div>
    )
  },
)

const DevToolLoanSimulator = React.memo<DevToolLoanSimulatorProps>(
  ({ loanRecordGuid }) => {
    const [showSimulator, setShowSimulator] = useState(false)
    const [showAdvanced, setShowAdvanced] = useState(false)
    const companyGuid = useExpectedCompany().companyGuid
    const [loanRecordSubscription] = useSubscription({
      query: LOAN_RECORD_SUBSCRIPTION,
      variables: { loanRecordGuid, companyGuid },
    })

    return (
      <UrqlSubscriptionLoader
        subscription={loanRecordSubscription}
        render={loanRecordSubscription => {
          const loanRecord = convertFetchLoanRecordToLoanRecord(
            loanRecordSubscription.wisetackLoanRecords[0],
          )
          return (
            <>
              <Button
                className="max-w-0 pl-0"
                type="link"
                onClick={() => setShowSimulator(prev => !prev)}
              >
                {!showSimulator ? 'Show Dev Tools' : 'Hide Dev Tools'}
              </Button>
              {showSimulator ? (
                <div className="flex flex-col rounded-md bg-daybreak-blue-200 p-2">
                  <div className="text-lg font-semibold">Loan Simulator</div>
                  <div className="mt-2 inline-block">
                    <FontAwesomeIcon className="mr-1" icon={faCircleQuestion} />
                    <span className="text-bz-gray-800">
                      Make sure you refresh or switch tabs after triggering a
                      loan application update to see the changes reflected.
                    </span>
                  </div>
                  <div className="mt-4 flex flex-col">
                    <Switch
                      value={loanRecord.latestLoanRecordStatus.loanStatus}
                    >
                      {{
                        PENDING: () => (
                          <BasicNextLoanStepInitiator
                            loanRecord={loanRecord}
                            currentStatus="PENDING"
                            targetStatus="INITIATED"
                          />
                        ),
                        INITIATED: () => (
                          <div className="flex flex-col">
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                Approve Full Amount
                              </div>
                              <BasicNextLoanStepInitiator
                                loanRecord={loanRecord}
                                currentStatus="INITIATED"
                                targetStatus="AUTHORIZED"
                              />
                            </div>
                            <Divider />
                            <div className="flex flex-col">
                              <SimulateLoanPartialApprovalForm
                                loanRecord={loanRecord}
                              />
                            </div>
                          </div>
                        ),
                        AUTHORIZED: () => (
                          <BasicNextLoanStepInitiator
                            loanRecord={loanRecord}
                            currentStatus="AUTHORIZED"
                            targetStatus="LOAN_TERMS_ACCEPTED"
                          />
                        ),
                        LOAN_TERMS_ACCEPTED: () => (
                          <BasicNextLoanStepInitiator
                            loanRecord={loanRecord}
                            currentStatus="LOAN_TERMS_ACCEPTED"
                            targetStatus="LOAN_CONFIRMED"
                          />
                        ),
                        LOAN_CONFIRMED: () => (
                          <BasicNextLoanStepInitiator
                            loanRecord={loanRecord}
                            currentStatus="LOAN_CONFIRMED"
                            targetStatus="SETTLED"
                          />
                        ),
                        SETTLED: () => 'This loan has already been settled',
                        DECLINED: () => 'This loan has already been declined',
                        default: () => 'Something went wrong',
                      }}
                    </Switch>
                    <div className="mt-4">
                      <Button
                        className="max-w-0 pl-0"
                        type="link"
                        onClick={() => setShowAdvanced(prev => !prev)}
                      >
                        Advanced
                      </Button>
                      {showAdvanced ? (
                        <div className="mt-4 flex flex-col">
                          <div className="flex flex-col">
                            <div className="font-semibold">Decline Loan</div>
                            {DECLINEABLE_LOAN_STATUSES.includes(
                              loanRecord.latestLoanRecordStatus.loanStatus,
                            ) ? (
                              <BasicNextLoanStepInitiator
                                loanRecord={loanRecord}
                                currentStatus={
                                  loanRecord.latestLoanRecordStatus.loanStatus
                                }
                                targetStatus="DECLINED"
                              />
                            ) : (
                              <div className="text-red-500">
                                This loan cannot be declined
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <div className="font-semibold">Cancel Loan</div>
                            {CANCELABLE_LOAN_STATUSES.includes(
                              loanRecord.latestLoanRecordStatus.loanStatus,
                            ) ? (
                              <BasicNextLoanStepInitiator
                                loanRecord={loanRecord}
                                currentStatus={
                                  loanRecord.latestLoanRecordStatus.loanStatus
                                }
                                targetStatus="CANCELED"
                              />
                            ) : (
                              <div className="text-red-500">
                                This loan cannot be canceled
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )
        }}
      />
    )
  },
)

export default DevToolLoanSimulator
