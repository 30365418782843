import {
  BzDateTime,
  LocalDateString,
  MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION,
  MaintenancePlanPaymentInterval,
  PaymentMethod,
  bzExpect,
  nextGuid,
  paymentIntervalToSubscriptionInterval,
} from '@breezy/shared'
import { faBackward } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo, useRef, useState } from 'react'
import PaymentMethodButton from '../../components/Payments/PaymentMethodButton'
import {
  PaymentAsyncUiStatus,
  PaymentMethodCreationContextProps,
} from '../../components/Payments/PaymentTypes'
import AchCreatePaymentMethodForm from '../../components/Payments/Tilled/AchCreatePaymentMethodForm'
import CardCreatePaymentMethodForm from '../../components/Payments/Tilled/CardCreatePaymentMethodForm'
import TilledSandboxAchInfo from '../../components/Payments/Tilled/TilledSandboxAchInfo'
import TilledSandboxCcInfo from '../../components/Payments/Tilled/TilledSandboxCcInfo'
import BzRefCheckBox from '../../elements/BzCheckBox/BzRefCheckbox'
import LimitedWidthColumn from '../../elements/LimitedWidthColumn/LimitedWidthColumn'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedMerchantId,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import { MaintenancePlanPaymentViewProps } from './MaintenancePlanPayPage'
import { MaintenancePlanPaymentMethodSelectionView } from './MaintenancePlanPaymentMethodSelectionView'
import SubscriptionPaymentInfoView from './SubscriptionPaymentSummaryView'

type MaintenancePlanPaymentInnerProps = MaintenancePlanPaymentViewProps & {
  readonly setPaymentAsyncUiStatus: (status: PaymentAsyncUiStatus) => void
  readonly onSuccess: () => void
}

export const MaintenancePlanPaymentView = m(
  ({
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    suppressActivationEmailRef,
    maintenancePlan,
    setPaymentAsyncUiStatus,
    onSuccess,
    mode,
    renewalTypeOption,
  }: MaintenancePlanPaymentInnerProps) => {
    const companyGuid = useExpectedCompanyGuid()
    const tzId = useExpectedCompanyTimeZoneId()
    const merchantId = useExpectedMerchantId()

    const activateMaintenancePlan =
      trpc.maintenancePlans[
        'maintenance-plans:create-payment-subscription'
      ].useMutation()

    const updateSubscription =
      trpc.maintenancePlans[
        'maintenance-plans:update-payment-subscription'
      ].useMutation()

    const [confirmedBillingInfo, setConfirmedBillingInfo] = useState(false)
    const initialBillingStartDate = useMemo(() => {
      if (maintenancePlan.terminatesAt) {
        return BzDateTime.fromIsoString(maintenancePlan.terminatesAt, tzId)
          .plusDays(1)
          .toLocalDateString()
      }
    }, [maintenancePlan.terminatesAt, tzId])
    const customBillingStartDate = useRef<LocalDateString | undefined>(
      initialBillingStartDate,
    )

    const [paymentContext, setPaymentContext] =
      useState<PaymentMethodCreationContextProps>({
        paymentPeriodAmountUsc: maintenancePlan.totalPriceUsc,
        paymentInterval:
          maintenancePlan.paymentInterval ??
          MaintenancePlanPaymentInterval.MONTHLY,
        paymentBillingDetails: {
          name: maintenancePlan.defaultBillingInfo.payeeDisplayName,
          address: maintenancePlan.defaultBillingInfo.payeeAddress,
          customBillingStartDate: initialBillingStartDate,
          autoRenewSubscription: renewalTypeOption !== 'MANUAL',
        },
        onPaymentAsyncUiStatusChange: setPaymentAsyncUiStatus,
      })

    const onActivateSuccessInner = useCallback(
      (
        paymentMethodId: string,
        paymentMethodAdditionalInfo: string,
        paymentMethod: PaymentMethod,
      ) => {
        setPaymentAsyncUiStatus(PaymentAsyncUiStatus.SUBMITTING)

        if (!maintenancePlan.paymentInterval) {
          throw new Error('Maintenance Plan Payment Interval is required')
        }

        activateMaintenancePlan.mutateAsync(
          {
            companyGuid,
            merchantId,
            paymentSubscriptionGuid: nextGuid(),
            paymentMethodId,
            paymentMethod,
            paymentInterval: paymentIntervalToSubscriptionInterval(
              maintenancePlan.paymentInterval,
            ),
            periodTotalPaymentPriceUsc: maintenancePlan.totalPriceUsc,
            paymentMethodAdditionalInfo,
            accountGuid: maintenancePlan.accountGuid,
            activationDate: BzDateTime.now(tzId).toLocalDateString(),
            pricebookTaxRateGuid: maintenancePlan.taxRate?.taxRateGuid ?? null,
            maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
            customBillingStartDate: customBillingStartDate.current,
            suppressActivationEmail: suppressActivationEmailRef.current,
            numDaysUntilAutoCancelation: paymentContext.paymentBillingDetails
              .autoRenewSubscription
              ? undefined
              : MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION,
          },
          {
            onSuccess: () => {
              onSuccess()
            },
            onError: () => {
              setPaymentAsyncUiStatus(PaymentAsyncUiStatus.IDLE)
            },
          },
        )
      },
      [
        activateMaintenancePlan,
        companyGuid,
        merchantId,
        maintenancePlan,
        onSuccess,
        setPaymentAsyncUiStatus,
        tzId,
        customBillingStartDate,
        suppressActivationEmailRef,
        paymentContext.paymentBillingDetails.autoRenewSubscription,
      ],
    )

    const onUpdateSuccessInner = useCallback(
      (
        paymentMethodId: string,
        paymentMethodAdditionalInfo: string,
        paymentMethod: PaymentMethod,
      ) => {
        setPaymentAsyncUiStatus(PaymentAsyncUiStatus.SUBMITTING)

        updateSubscription.mutateAsync(
          {
            companyGuid,
            merchantId,
            paymentSubscriptionGuid: bzExpect(
              maintenancePlan.paymentSubscriptionGuid,
              'Maintenance Plan: paymentSubscriptionGuid',
            ),
            type: 'UPDATE_PAYMENT_METHOD',
            previousPaymentMethodId:
              maintenancePlan.paymentSubscription?.externalPaymentMethodId,
            paymentMethodId,
            paymentMethod,
            paymentMethodAdditionalInfo,
          },
          {
            onSuccess: () => {
              onSuccess()
            },
            onError: () => {
              setPaymentAsyncUiStatus(PaymentAsyncUiStatus.IDLE)
            },
          },
        )
      },
      [
        updateSubscription,
        companyGuid,
        merchantId,
        maintenancePlan,
        onSuccess,
        setPaymentAsyncUiStatus,
      ],
    )

    const onSuccessInner = useMemo(() => {
      return mode === 'activate' ? onActivateSuccessInner : onUpdateSuccessInner
    }, [mode, onActivateSuccessInner, onUpdateSuccessInner])

    const confirmBillingInfo = useCallback(() => {
      setConfirmedBillingInfo(true)
    }, [])

    const setPaymentContextAndCustomBillingStartDate = useCallback(
      (ctx: PaymentMethodCreationContextProps) => {
        customBillingStartDate.current =
          ctx.paymentBillingDetails.customBillingStartDate
        setPaymentContext(ctx)
      },
      [setPaymentContext],
    )

    return (
      <div className="row center-h center-children-h flex-wrap">
        {selectedPaymentMethod && (
          <LimitedWidthColumn>
            <SubscriptionPaymentInfoView
              onConfirm={confirmBillingInfo}
              paymentContext={paymentContext}
              setPaymentContext={setPaymentContextAndCustomBillingStartDate}
              renewalTypeOption={renewalTypeOption}
            />
            {mode === 'activate' && (
              <>
                <div className="row center-children-vh mt-4 w-full">
                  <BzRefCheckBox
                    label="Don't Send Activation Email"
                    valueRef={suppressActivationEmailRef}
                  />
                </div>
              </>
            )}
          </LimitedWidthColumn>
        )}

        {!selectedPaymentMethod && (
          <MaintenancePlanPaymentMethodSelectionView
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
        )}

        {confirmedBillingInfo && selectedPaymentMethod && (
          <>
            <LimitedWidthColumn>
              {selectedPaymentMethod === 'card' && (
                <CardCreatePaymentMethodForm
                  paymentContext={paymentContext}
                  onSuccess={onSuccessInner}
                />
              )}
              {selectedPaymentMethod === 'ach' && (
                <AchCreatePaymentMethodForm
                  paymentContext={paymentContext}
                  onSuccess={onSuccessInner}
                />
              )}
              {selectedPaymentMethod && (
                <div className="center-h mt-3 w-[260px]">
                  <PaymentMethodButton
                    icon={faBackward}
                    text="Another Payment Method"
                    onClick={() => setSelectedPaymentMethod(undefined)}
                  />
                </div>
              )}
            </LimitedWidthColumn>
            <LimitedWidthColumn>
              {selectedPaymentMethod === 'card' && <TilledSandboxCcInfo />}
              {selectedPaymentMethod === 'ach' && <TilledSandboxAchInfo />}
              <div className="min-w-[320px]" />
            </LimitedWidthColumn>
          </>
        )}
      </div>
    )
  },
)
