import {
  guidSchema,
  OfficeRoutes,
  PermissionV2,
  UserImpersonationSearchDTOItem,
} from '@breezy/shared'
import { Button, Divider, Select } from 'antd'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from '../../../hooks/trpc'
import { usePrincipalUser } from '../../../providers/PrincipalUser'
import { clearPreviousQueryStrings } from '../../../utils/react-utils'
import { Authorized } from '../../Permissions/Authorized/Authorized'

const { Option } = Select

export const IMPERSONATE_USER_KEY = 'impersonated_user'

type AdminImpersonateUserProps = {
  onImpersonateChange?: () => void
  showDivider?: boolean
}

export const AdminImpersonateUser = ({
  onImpersonateChange,
  showDivider,
}: AdminImpersonateUserProps) => {
  const navigate = useNavigate()
  // Holds the value of the currently impersonated user, if any
  const { impersonatedUser, setImpersonatedUser } = usePrincipalUser()

  const [selectedUser, setSelectedUserPrivate] = useState<
    UserImpersonationSearchDTOItem | undefined
  >(impersonatedUser)

  const updateImpersonation = () => {
    if (isImpersonating) {
      // We'll have bugs if we leave these because they are sometimes company-specific
      clearPreviousQueryStrings()
      // Go to limbo. Navigation doesn't happen instantly. If we navigate (to the admin page, which is what we want when
      // we stop impersonating) and then clear the impersonated user, the current page we're looking at might react and
      // have prod alerts because there's no longer a user. So go to this limbo page. On that limbo page we clear the
      // impersonated user (so nothing is there to break) and finally redirect to /admin
      navigate(OfficeRoutes.STOP_IMPERSONATING.path)
      onImpersonateChange?.()
      return
    }

    if (selectedUser && guidSchema.safeParse(selectedUser.userGuid).success) {
      setImpersonatedUser(selectedUser)
      onImpersonateChange?.()
    } else {
      setImpersonatedUser(undefined)
      onImpersonateChange?.()
    }
  }

  const isImpersonating = useMemo(() => {
    return (
      impersonatedUser &&
      guidSchema.safeParse(impersonatedUser.userGuid).success
    )
  }, [impersonatedUser])

  // For controlling the search before impersonating
  const setSelectedUser = (userGuid?: string) => {
    setSelectedUserPrivate(usersList.find(u => u.userGuid === userGuid))
  }

  const formatDisplay = (user?: UserImpersonationSearchDTOItem) => {
    return user?.emailAddress || ''
  }

  const [searchStringValue, setSearchStringValue] = useState(
    formatDisplay(impersonatedUser),
  )

  const setSearchString = (value: string) => {
    setSearchStringValue(value)
  }

  const [usersList, setUsersList] = useState<
    Array<UserImpersonationSearchDTOItem>
  >([])

  // BUG: Should not query this after impersonating
  trpc.user['users:searchForImpersonation'].useQuery(
    {
      searchString: `%${searchStringValue.trim()}%`,
    },
    {
      enabled: searchStringValue.trim() !== '',
      onSuccess(users) {
        setUsersList(users)
      },
      onError() {
        setUsersList([])
      },
    },
  )

  const options = useMemo(() => {
    return usersList.map(user => (
      <Option key={user.userGuid} style={{ transistionDelay: 0 }}>
        {formatDisplay(user)}
      </Option>
    ))
  }, [usersList])

  const buttonText = useMemo(() => {
    return isImpersonating ? 'Stop' : 'Start'
  }, [isImpersonating])

  return (
    <Authorized
      to={PermissionV2.IMPERSONATE_USER}
      overrideAuthorized={!!impersonatedUser}
      allowsNonCompanyUser
    >
      <>
        {showDivider && <Divider style={{ marginTop: 8, marginBottom: 16 }} />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Select
              showSearch
              title="Impersonate User Search"
              placeholder="Search for User"
              searchValue={searchStringValue}
              suffixIcon={false}
              filterOption={false}
              value={formatDisplay(selectedUser)}
              onSearch={setSearchString}
              onChange={setSelectedUser}
              defaultActiveFirstOption={false}
              notFoundContent={null}
              style={{ maxWidth: '200px', minWidth: '200px' }}
              dropdownAlign={{
                offset: [-40, 4],
                overflow: { adjustX: true, adjustY: true },
              }}
              dropdownStyle={{ minWidth: '260px' }}
            >
              {options}
            </Select>
          </div>
          <div style={{ paddingLeft: 8 }}>
            <Button size="small" onClick={updateImpersonation} type="primary">
              {buttonText}
            </Button>
          </div>
        </div>
      </>
    </Authorized>
  )
}
