import { faFrownOpen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { m } from '../../utils/react-utils'

interface NotFoundPageProps {
  entityType: string
  id: string | number
  otherDetails?: string
}

const NotFoundPage = m<NotFoundPageProps>(
  ({ entityType, id, otherDetails }) => {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center bg-gray-100 text-gray-800">
        <FontAwesomeIcon
          icon={faFrownOpen}
          size="3x"
          className="mb-4 text-red-600"
        />
        <h1 className="text-2xl font-semibold">Oops!</h1>
        <p className="text-lg">
          The {entityType} with ID "{id}" could not be found.
        </p>
        {otherDetails && <p>{otherDetails}</p>}
      </div>
    )
  },
)

export default NotFoundPage
