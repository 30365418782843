import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { UserSettingsView } from '../../../pages/SettingsPage/UserSettingsPage'

type TechAppUserSettingsPortalProps = {
  isOpen: boolean
  onClose: () => void
}

export const TechAppUserSettingsPortal = ({
  isOpen,
  onClose,
}: TechAppUserSettingsPortalProps) => {
  return (
    <OnsiteBasicModal
      open={isOpen}
      onClose={onClose}
      header="User Settings"
      headerBordered
    >
      <UserSettingsView />
    </OnsiteBasicModal>
  )
}
