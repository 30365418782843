import {
  BzDateFns,
  ComprehensiveSchedulingViewModel,
  IsoDateString,
} from '@breezy/shared'
import React from 'react'
import { StateSetter } from '../../utils/react-utils'
import {
  DEFAULT_SCHEDULE_MODE,
  DEFAULT_SCHEDULE_VIEW,
  ScheduleMode,
  ScheduleView,
} from '../ScheduleV2Page/ScheduleContext'

export type ScheduleContextType = {
  scheduleViewModel: ComprehensiveSchedulingViewModel | undefined
  scheduleView: ScheduleView
  setScheduleView: (view: ScheduleView) => void
  scheduleMode: ScheduleMode
  setScheduleMode: (mode: ScheduleMode) => void
  selectedDate: IsoDateString
  setSelectedDate: StateSetter<IsoDateString>
  selectedAssignableId: string | undefined
  setSelectedAssignableId: StateSetter<string | undefined>
  refetch: () => Promise<unknown>
  appointmentColorMap: Record<string, string>
}

export const ScheduleContext = React.createContext<ScheduleContextType>({
  scheduleViewModel: undefined,
  scheduleView: DEFAULT_SCHEDULE_VIEW,
  setScheduleView: () => {},
  scheduleMode: DEFAULT_SCHEDULE_MODE,
  setScheduleMode: () => {},
  selectedDate: BzDateFns.nowISOString(),
  setSelectedDate: () => {},
  selectedAssignableId: undefined,
  setSelectedAssignableId: () => {},
  refetch: Promise.resolve,
  appointmentColorMap: {},
})

export const useScheduleContext = () => React.useContext(ScheduleContext)
