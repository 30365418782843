import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type MediumCardProps = {
  small?: boolean
  borderColorClassName?: string
  accentBarColor?: string
  heightClassName?: string
  className?: string
  anchoredFooter?: React.ReactNode
  children?: React.ReactNode
}

export const MediumCard = React.memo<MediumCardProps>(
  ({
    small,
    className,
    accentBarColor,
    borderColorClassName = 'border-bz-gray-500',
    heightClassName = 'h-full',
    anchoredFooter,
    children,
  }) => {
    const isMobile = useIsMobile()

    return (
      <div
        className={classNames(
          'relative flex min-h-0 flex-col overflow-hidden border border-solid bg-white shadow-sm transition-all ease-in-out',
          small ? 'rounded-md' : 'w-full rounded-xl',
          isMobile ? 'p-4' : 'px-6 py-4',
          borderColorClassName,
          heightClassName,
          className,
        )}
      >
        {accentBarColor && (
          <div
            className={classNames('z-1 absolute inset-0 h-[10px]')}
            style={{ backgroundColor: accentBarColor }}
          />
        )}
        {children}
        {anchoredFooter ? (
          <div
            className={classNames(
              'border-0 border-t-[2px] border-solid border-[#F0F0F0] bg-bz-gray-100 pt-2',
              isMobile ? 'px-4 pb-4' : 'px-6 pb-6',
            )}
          >
            {anchoredFooter}
          </div>
        ) : null}
      </div>
    )
  },
)
