import { gql } from '../../../generated'

export const LOAN_RECORD_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription LoanRecord($companyGuid: uuid!, $loanRecordGuid: uuid!) {
    wisetackLoanRecords(
      where: {
        companyGuid: { _eq: $companyGuid }
        wisetackLoanRecordGuid: { _eq: $loanRecordGuid }
      }
    ) {
      ...LoanRecord
    }
  }
`)
