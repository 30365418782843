import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { ForCompanyUser, ForFullCompany } from '../Company/Company'
import { bzOptional } from '../common-schemas'

export const labelScannerRequestSchema = z.object({
  imageUrl: z.string(),
  photoGuid: guidSchema,
})

export const labelScannerFormSchema = z.object({
  name: bzOptional(z.string()),
  equipmentType: bzOptional(z.string()),
  avgLifeExpectancy: bzOptional(z.number()),
  manufacturer: bzOptional(z.string()),
  modelNumber: bzOptional(z.string()),
  serialNumber: bzOptional(z.string()),
  installationDate: bzOptional(z.string()),
  manufactureDate: bzOptional(z.string()),
})

export type LabelScannerFormData = z.infer<typeof labelScannerFormSchema>

export type LabelScannerRequestSchema = z.infer<typeof labelScannerRequestSchema>
export type LabelScannerRequest = ForFullCompany<ForCompanyUser<LabelScannerRequestSchema>>

export const labelScannerResponseSchema = z.object({
  message: z.string(),
  form: labelScannerFormSchema,
  extraFields: z.record(z.union([z.string(), z.number()])),
})
export type LabelScannerResponse = z.infer<typeof labelScannerResponseSchema>

export type LabelScanner = AsyncFn<LabelScannerRequest, LabelScannerResponse>
