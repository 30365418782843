import {
  AppointmentChecklistInstance,
  AppointmentType,
  Guid,
  toPlural,
} from '@breezy/shared'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spin } from 'antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { trpc } from '../../../../hooks/trpc'
import './AppointmentDetail.less'
import { ChecklistsModal } from './ChecklistsModal'

type ChecklistsButtonProps = {
  numChecklists: number
  instances: AppointmentChecklistInstance[]
  refetch: () => Promise<unknown>
  loading?: boolean
  jobTypeGuid: Guid
  appointmentType: AppointmentType
  jobAppointmentGuid: Guid
}

export const ChecklistsButton = React.memo<ChecklistsButtonProps>(
  ({
    instances: providedInstances,
    refetch,
    numChecklists,
    jobTypeGuid,
    loading = false,
    appointmentType,
    jobAppointmentGuid,
  }) => {
    const [isModalOpenRaw, setIsModalOpen] = useState(false)

    const onModalClose = useCallback(
      () => setIsModalOpen(false),
      [setIsModalOpen],
    )

    const [newInstances, setNewInstances] =
      useState<AppointmentChecklistInstance[]>()
    const createInstancesMutation = trpc.appointmentChecklist[
      'appointment-checklists:generate-for-appointment'
    ].useMutation({
      onSuccess: data => {
        setNewInstances(data)
        refetch()
      },
    })

    const instances = newInstances || providedInstances

    // The backend logic doesn't seem to be idempotent, so we're adding a client-side check to ensure we only call it once
    const hasRequestedInstances = useRef(false)

    // initialize the appointment checklist instances on mount
    useEffect(() => {
      if (
        !loading &&
        !hasRequestedInstances.current &&
        numChecklists > 0 &&
        !instances.length
      ) {
        hasRequestedInstances.current = true
        createInstancesMutation.mutate({
          jobTypeGuid,
          appointmentType,
          jobAppointmentGuid,
        })
      }
    }, [
      appointmentType,
      createInstancesMutation,
      hasRequestedInstances,
      instances.length,
      numChecklists,
      jobAppointmentGuid,
      jobTypeGuid,
      loading,
    ])

    const onClick = useCallback(() => {
      setIsModalOpen(true)
    }, [setIsModalOpen])

    const ourRefetch = useCallback(async () => {
      setNewInstances(undefined)
      await refetch()
    }, [refetch])

    const isModalOpen = !!(isModalOpenRaw && instances.length)

    const isAllComplete = useMemo(() => {
      for (const instance of instances) {
        for (const checklistItem of instance.checklist.items) {
          if (!checklistItem.notRequired && !checklistItem.response) {
            return false
          }
        }
      }
      return true
    }, [instances])

    return (
      <>
        <Spin
          spinning={createInstancesMutation.isLoading}
          indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
        >
          <Button
            type="primary"
            block
            disabled={isAllComplete}
            className="h-fit rounded-md py-2 font-semibold"
            onClick={isAllComplete ? undefined : onClick}
          >
            {isAllComplete
              ? 'All Checklists Completed'
              : `Complete ${toPlural(numChecklists, 'Checklist')}`}
          </Button>
        </Spin>
        {isModalOpen && (
          <ChecklistsModal
            instances={instances}
            onClose={onModalClose}
            refetch={ourRefetch}
          />
        )}
      </>
    )
  },
)
