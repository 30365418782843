import {
  AppointmentType,
  BzDateFns,
  CalculatePaths,
  formatMoney,
  InvoicePayment,
  IsoDateString,
  PaymentMethodDisplayNames,
  PaymentStatus,
  usCentsToUsd,
} from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar,
  faHomeUser,
  faMoneyBill,
  faReceipt,
  faScrewdriverWrench,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PaymentStatusTag from '../../../components/Payments/PaymentStatusTag'
import { useIsTechApp } from '../../../providers/AppContextWrapper'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceDataContext } from '../invoiceUtils'
import { InvoicePaymentRefundStatusTag } from './InvoicePaymentRefundStatusTag'

type InvoiceInfoModalContentLinkProps = {
  header: string
  headerExtra?: React.ReactNode
  subHeader: string
  icon: IconProp
  onClick: () => void
}

const InvoiceInfoModalContentLink = ({
  header,
  headerExtra,
  subHeader,
  icon,
  onClick,
}: InvoiceInfoModalContentLinkProps) => {
  return (
    <div
      key={header}
      onClick={onClick}
      className="flex cursor-pointer flex-row items-center transition-opacity hover:opacity-50"
    >
      <div className="flex h-11 w-11 items-center justify-center rounded-full bg-bz-gray-300">
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="ml-3 flex-1">
        <div className="text-base font-semibold leading-[26px]">
          {header}
          {headerExtra}
        </div>
        <div className="text-sm text-bz-gray-800">{subHeader}</div>
      </div>

      <Button
        type="text"
        icon={<FontAwesomeIcon className="text-base" icon={faChevronRight} />}
      />
    </div>
  )
}

type InvoiceJobLink = {
  jobGuid: string
  jobType: string
  jobDisplayId: number
  jobCreatedAt: IsoDateString
}

type InvoiceEstimateLink = {
  estimateGuid: string
  displayId: number
  acceptedAt?: IsoDateString
  createdAt: IsoDateString
}

type InvoiceMaintenancePlanLink = {
  maintenancePlanGuid: string
  activatedAt?: IsoDateString
  createdAt: IsoDateString
  planName?: string
}

export type InvoiceAppointmentLink = {
  appointmentGuid: string
  appointmentType: AppointmentType
  appointmentStartedAt: IsoDateString
  assignedTechniciansFirstNameLastInitial: string
}

export type InvoiceInfoContextType = {
  displayId: number
  issuedAt?: IsoDateString
  createdAt: IsoDateString
  jobLink?: InvoiceJobLink
  estimateLink?: InvoiceEstimateLink
  maintenancePlanLink?: InvoiceMaintenancePlanLink
  appointmentLink?: InvoiceAppointmentLink
  payments: InvoicePayment[]
  accountGuid?: string
  contactFullName?: string
  accountCreatedAt?: IsoDateString
}

export const InvoiceInfoContext = React.createContext<
  InvoiceInfoContextType | undefined
>(undefined)

const hiddenPaymentStatuses = [PaymentStatus.FAILED, PaymentStatus.CANCELED]
export const InvoiceInfoModalContent = React.memo(() => {
  const navigate = useNavigate()
  const tzId = useExpectedCompanyTimeZoneId()
  const isTechApp = useIsTechApp()
  const {
    displayId,
    issuedAt,
    createdAt,
    jobLink,
    estimateLink,
    maintenancePlanLink,
    appointmentLink,
    payments,
    accountGuid,
    contactFullName,
    accountCreatedAt,
  } = useStrictContext(InvoiceInfoContext)
  const { info } = useStrictContext(InvoiceDataContext)

  const createdAtFormatted = useMemo(
    () => BzDateFns.formatFromISO(issuedAt ?? createdAt, 'MMM d, yyyy', tzId),
    [createdAt, issuedAt, tzId],
  )

  const invoiceLinkItems: (InvoiceInfoModalContentLinkProps & {
    key: string
  })[] = useMemo(() => {
    const items: (InvoiceInfoModalContentLinkProps & {
      key: string
    })[] = []
    if (payments.length) {
      const sortedPayments = [...payments]
        .sort(
          (a, b) =>
            new Date(b.occurredAt).getTime() - new Date(a.occurredAt).getTime(),
        )
        .filter(p => !hiddenPaymentStatuses.includes(p.status))
      for (const payment of sortedPayments) {
        const { paymentRecordGuid, paymentMethod, occurredAt, paidUsc } =
          payment

        items.push({
          key: `payment-${paymentRecordGuid}`,
          header: `Payment: ${formatMoney(usCentsToUsd(paidUsc))} (${
            PaymentMethodDisplayNames[paymentMethod]
          })`,
          headerExtra: <PaymentLinkTag payment={payment} />,
          subHeader: `Payment Date: ${BzDateFns.formatFromISO(
            occurredAt,
            'MMMM d, yyyy',
            tzId,
          )}`,
          icon: faMoneyBill,
          onClick: () =>
            navigate(CalculatePaths.paymentDetails({ paymentRecordGuid })),
        })
      }
    }
    if (maintenancePlanLink && maintenancePlanLink.planName) {
      items.push({
        key: 'maintenancePlan',
        header: `Maintenance Plan (${maintenancePlanLink.planName})`,
        subHeader: maintenancePlanLink.activatedAt
          ? `Activated on: ${BzDateFns.formatFromISO(
              maintenancePlanLink.activatedAt,
              'MMMM d, yyyy',
              tzId,
            )}`
          : `Created on: ${BzDateFns.formatFromISO(
              maintenancePlanLink.createdAt,
              'MMMM d, yyyy',
              tzId,
            )}`,
        icon: faScrewdriverWrench,
        onClick: () =>
          navigate(
            CalculatePaths.maintenancePlanDetails({
              maintenancePlanGuid: maintenancePlanLink.maintenancePlanGuid,
            }),
          ),
      })
    }

    if (estimateLink) {
      const { estimateGuid, displayId, acceptedAt, createdAt } = estimateLink
      items.push({
        key: 'estimate',
        header: `Estimate #${displayId}`,
        subHeader: acceptedAt
          ? `Accepted on: ${BzDateFns.formatFromISO(
              acceptedAt,
              'MMMM d, yyyy',
              tzId,
            )}`
          : `Created on: ${BzDateFns.formatFromISO(
              createdAt,
              'MMMM d, yyyy',
              tzId,
            )}`,
        icon: faReceipt,
        onClick: () =>
          navigate(CalculatePaths.estimatesDetails({ estimateGuid })),
      })
    }
    if (isTechApp && appointmentLink) {
      items.push({
        key: 'appointment',
        header: `Appointment (${appointmentLink.appointmentType})`,
        subHeader: `Date: ${BzDateFns.formatFromISO(
          appointmentLink.appointmentStartedAt,
          'MMMM d, yyyy',
          tzId,
        )} • ${appointmentLink.assignedTechniciansFirstNameLastInitial}`,
        icon: faCalendar,
        onClick: () =>
          navigate(
            CalculatePaths.appointmentDetails({
              appointmentGuid: appointmentLink.appointmentGuid,
            }),
          ),
      })
    }

    if (jobLink) {
      const { jobGuid, jobDisplayId, jobType, jobCreatedAt } = jobLink
      items.push({
        key: 'originalJob',
        header: `Job #${jobDisplayId} (${jobType})`,
        subHeader: `Created on: ${BzDateFns.formatFromISO(
          jobCreatedAt,
          'MMMM d, yyyy',
          tzId,
        )}`,
        icon: faWrench,
        onClick: () => navigate(CalculatePaths.jobDetails({ jobGuid })),
      })
    }

    if (accountGuid && contactFullName && accountCreatedAt) {
      items.push({
        key: 'contact',
        header: contactFullName,
        subHeader: `Customer since: ${BzDateFns.formatFromISO(
          accountCreatedAt,
          'MMMM d, yyyy',
          tzId,
        )}`,
        icon: faHomeUser,
        onClick: () => navigate(CalculatePaths.accountDetails({ accountGuid })),
      })
    }

    return items
  }, [
    accountCreatedAt,
    accountGuid,
    appointmentLink,
    contactFullName,
    estimateLink,
    isTechApp,
    jobLink,
    maintenancePlanLink,
    navigate,
    payments,
    tzId,
  ])

  return (
    <>
      <div
        className={classNames(
          'grid text-base',
          info.customerPurchaseOrderNumber
            ? 'grid-cols-[1fr_auto_1fr_auto_1fr]'
            : 'grid-cols-[1fr_min-content_1fr]',
        )}
      >
        <div>
          <div className="mb-1 font-semibold">ID</div>

          <div>#{displayId}</div>
        </div>

        {info.customerPurchaseOrderNumber && (
          <>
            <Divider
              dashed
              type="vertical"
              className="mx-6 h-full border-bz-gray-500"
            />
            <div>
              <div className="mb-1 font-semibold">PO</div>
              <div>#{info.customerPurchaseOrderNumber}</div>
            </div>
          </>
        )}

        <Divider
          dashed
          type="vertical"
          className="mx-6 h-full border-bz-gray-500"
        />

        <div>
          <div className="mb-1 font-semibold">Issue Date</div>

          <div>{createdAtFormatted}</div>
        </div>
      </div>

      <Divider dashed className="my-4 border-bz-gray-500" />

      <div className="mb-3 text-base font-semibold">Related links</div>

      <div className="space-y-4">
        {invoiceLinkItems.map(
          ({ key, header, headerExtra, subHeader, icon, onClick }) => (
            <InvoiceInfoModalContentLink
              key={key}
              header={header}
              headerExtra={headerExtra}
              subHeader={subHeader}
              icon={icon}
              onClick={onClick}
            />
          ),
        )}
      </div>
    </>
  )
})

const PaymentLinkTag = React.memo<{ payment: InvoicePayment }>(
  ({ payment }) => {
    const { status, refunds, paidUsc } = payment

    const hasRefund = refunds.length > 0

    const paymentRefundAmount = refunds.reduce(
      (acc, refund) => acc + refund.amountUsc,
      0,
    )
    const refundMode = paymentRefundAmount < paidUsc ? 'partial' : 'full'

    if (hasRefund) {
      return (
        <InvoicePaymentRefundStatusTag
          className="ml-2"
          heightClassName=""
          mode={refundMode}
        />
      )
    }

    return <PaymentStatusTag className="ml-2 max-h-[24px]" status={status} />
  },
)
