import {
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  LocationCommon,
  NoteLinkData,
  PhotoLinks,
  PhotoRecordWithLinks,
  isNullish,
  noOp,
} from '@breezy/shared'
import { createContext, useCallback, useContext, useRef, useState } from 'react'
import { useIntercom } from '../../../hooks/useIntercom'
import { Composable } from '../../../utils/Composable'
import EquipmentUpsertDrawer from '../../EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import {
  PrefillEquipmentFormData,
  isPrefillEquipmentFormData,
} from '../../EquipmentUpsertForm/EquipmentUpsertForm'
import { InstalledHvacSystemUpsertDrawer } from '../../InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import PhotoUploadDrawer from '../../Upload/PhotoUploadDrawer'
import AddNoteDrawer from '../AddNote/AddLinkedNoteDrawer'
import PhotoDetailDrawer from '../PhotoDetailDrawer/PhotoDetailDrawer'

type PotentiallyPartialEquipment =
  | PrefillEquipmentFormData
  | InstalledEquipmentSummary

type TechExpDrawersInputItems = {
  closeAll: () => void
  beginUploadPhoto: (photoLinks: PhotoLinks, onMutate: () => void) => void
  beginAddNote: (noteLinkData: NoteLinkData, onMutate: () => void) => void
  beginUpsertInstalledEquipment: (
    location: LocationCommon,
    installedEquipment: PotentiallyPartialEquipment | undefined,
    onMutate: () => void,
  ) => void
  beginUpsertHvacSystem: (
    location: LocationCommon,
    installedHvacSystem: InstalledHvacSystem | undefined,
    onMutate: () => void,
  ) => void
  viewOrEditPhoto: (photo: PhotoRecordWithLinks, onMutate: () => void) => void
}

const ThrowNotInitialized = () => {
  throw new Error('TechExpDrawersContext not yet initialized')
}

const TechExpDrawersContext = createContext<TechExpDrawersInputItems>({
  closeAll: ThrowNotInitialized,
  beginUploadPhoto: ThrowNotInitialized,
  beginAddNote: ThrowNotInitialized,
  beginUpsertInstalledEquipment: ThrowNotInitialized,
  beginUpsertHvacSystem: ThrowNotInitialized,
  viewOrEditPhoto: ThrowNotInitialized,
})

const TechExpDrawersController: React.FC<Composable> = ({ children }) => {
  const [photoLinks, setPhotoLinks] = useState<PhotoLinks | undefined>(
    undefined,
  )
  const [noteLinkData, setNoteLinkData] = useState<NoteLinkData | undefined>(
    undefined,
  )
  const [photo, setPhoto] = useState<PhotoRecordWithLinks | undefined>(
    undefined,
  )

  const [installedEquipmentLocation, setInstalledEquipmentLocation] =
    useState<LocationCommon>()

  const [installedEquipment, setInstalledEquipment] =
    useState<PotentiallyPartialEquipment>()

  const [installedHvacSystemLocation, setInstalledHvacSystemLocation] =
    useState<LocationCommon | undefined>(undefined)

  const [installedHvacSystem, setInstalledHvacSystem] = useState<
    InstalledHvacSystem | undefined
  >(undefined)

  const onFinishedRef = useRef<() => void>(noOp)
  const closeAll = useCallback(() => {
    setPhotoLinks(undefined)
    setNoteLinkData(undefined)
    setPhoto(undefined)
    setInstalledEquipmentLocation(undefined)
    setInstalledEquipment(undefined)
    setInstalledHvacSystemLocation(undefined)
    setInstalledHvacSystem(undefined)
  }, [
    setPhotoLinks,
    setNoteLinkData,
    setPhoto,
    setInstalledEquipmentLocation,
    setInstalledEquipment,
    setInstalledHvacSystemLocation,
    setInstalledHvacSystem,
  ])

  const setOnFinishedAfterClose = (onFinished: () => void) => {
    onFinishedRef.current = () => {
      onFinished()
      closeAll()
    }
  }

  const beginUploadPhoto = (photoLinks: PhotoLinks, onMutate: () => void) => {
    closeAll()
    setOnFinishedAfterClose(onMutate)
    setPhotoLinks(photoLinks)
  }

  const beginAddNote = (noteLinkData: NoteLinkData, onMutate: () => void) => {
    closeAll()
    setOnFinishedAfterClose(onMutate)
    setNoteLinkData(noteLinkData)
  }

  const beginUpsertInstalledEquipment = (
    location: LocationCommon,
    installedEquipment: PotentiallyPartialEquipment | undefined,
    onMutate: () => void,
  ) => {
    closeAll()
    setOnFinishedAfterClose(onMutate)
    setInstalledEquipmentLocation(location)
    setInstalledEquipment(installedEquipment)
  }

  const beginUpsertHvacSystem = (
    location: LocationCommon,
    installedHvacSystem: InstalledHvacSystem | undefined,
    onMutate: () => void,
  ) => {
    closeAll()
    setOnFinishedAfterClose(onMutate)
    setInstalledHvacSystemLocation(location)
    setInstalledHvacSystem(installedHvacSystem)
  }

  const viewOrEditPhoto = (
    photo: PhotoRecordWithLinks,
    onMutate: () => void,
  ) => {
    closeAll()
    setOnFinishedAfterClose(onMutate)
    setPhoto(photo)
  }

  useIntercom({
    isLauncherVisible:
      isNullish(photoLinks) &&
      isNullish(noteLinkData) &&
      isNullish(photo) &&
      isNullish(installedEquipmentLocation) &&
      isNullish(installedHvacSystemLocation),
  })

  return (
    <TechExpDrawersContext.Provider
      value={{
        beginUploadPhoto,
        closeAll,
        beginAddNote,
        viewOrEditPhoto,
        beginUpsertInstalledEquipment,
        beginUpsertHvacSystem,
      }}
    >
      <>
        {children}
        <PhotoUploadDrawer
          photoLinks={photoLinks}
          onCancel={closeAll}
          onMutate={onFinishedRef.current}
        />
        <AddNoteDrawer
          noteLinkData={noteLinkData}
          onCancel={closeAll}
          onMutate={onFinishedRef.current}
        />
        <PhotoDetailDrawer
          photo={photo}
          onCancel={closeAll}
          onMutate={onFinishedRef.current}
        />
        {installedEquipmentLocation &&
          installedEquipment &&
          !isPrefillEquipmentFormData(installedEquipment) && (
            <EquipmentUpsertDrawer
              mode="update"
              location={installedEquipmentLocation}
              initialValues={installedEquipment}
              isOpen={Boolean(installedEquipmentLocation)}
              onCancel={closeAll}
              onMutate={onFinishedRef.current}
            />
          )}

        {installedEquipmentLocation &&
          (!installedEquipment ||
            isPrefillEquipmentFormData(installedEquipment)) && (
            <EquipmentUpsertDrawer
              mode="create-for-location"
              location={installedEquipmentLocation}
              initialValues={installedEquipment}
              isOpen={Boolean(installedEquipmentLocation)}
              onCancel={closeAll}
              onMutate={onFinishedRef.current}
            />
          )}

        {installedHvacSystemLocation && installedHvacSystem && (
          <InstalledHvacSystemUpsertDrawer
            mode="update"
            location={installedHvacSystemLocation}
            initialValues={installedHvacSystem}
            isOpen={Boolean(installedHvacSystemLocation)}
            onCancel={closeAll}
            onMutate={onFinishedRef.current}
          />
        )}
        {installedHvacSystemLocation && !installedHvacSystem && (
          <InstalledHvacSystemUpsertDrawer
            mode="create-for-location"
            location={installedHvacSystemLocation}
            isOpen={Boolean(installedHvacSystemLocation)}
            onCancel={closeAll}
            onMutate={onFinishedRef.current}
          />
        )}
      </>
    </TechExpDrawersContext.Provider>
  )
}

export const useTechExpDrawers = () => useContext(TechExpDrawersContext)

export default TechExpDrawersController
