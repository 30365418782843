import {
  AssignableApptViewModelWithBusinessProjections,
  AssignmentDTO,
  AssignmentStatus,
  BzAddress,
  BzDateFns,
  BzDateTime,
  Dfns,
  R,
  User,
  formatTechnicianCapacityBlockReasonType,
} from '@breezy/shared'
import {
  Eventcalendar,
  MbscEventClickEvent,
  MbscEventcalendarView,
  momentTimezone,
} from '@mobiscroll/react'
import { Select } from 'antd'
import moment from 'moment-timezone'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from '../../components/DatePicker/DatePicker'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { useCanManageSchedule } from '../../hooks/permission/useCanManageSchedule'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompany,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { blue6 } from '../../themes/theme'
import { useMessage } from '../../utils/antd-utils'
import { userColor } from '../../utils/color-utils'
import { StateSetter } from '../../utils/react-utils'
import {
  BzCalendarEvent,
  BzCalendarEventData,
  BzEventCreateEvent,
  isBlockEvent,
} from '../ScheduleV2Page/scheduleUtils'
import { CommitScheduleChangesListener } from './CommitScheduleChangesListener'
import {
  useCanOnlyViewCurrentAppointment,
  useCanOnlyViewTodaysAssignedAppointments,
  useCanViewPastAppointments,
  useCanViewTeamSchedule,
} from './hooks'
import { NewEventModal } from './NewEventModal'
import { useScheduleContext } from './ScheduleContext'
import { BlockEventContent, NonBlockEventContent } from './ScheduleEventContent'
import {
  SchedulePendingChangesWrapper,
  useSchedulePendingChanges,
} from './SchedulePendingChangesContext'
import { ScheduleWrapper } from './ScheduleWrapper'
import './TechnicianSchedulePage.less'
import {
  generateScheduleAppointmentMap,
  toMsbcTime,
  withMobiscrollBlockFixForRecurringEvent,
} from './utils'
import { WeeklyDayPicker } from './WeeklyDayPicker'

momentTimezone.moment = moment

enum TabValue {
  MySchedule = 'My Schedule',
  TeamSchedule = 'Team Schedule',
}

type Tab = {
  value: TabValue
  label: string
}

const TechnicianSchedulePage = React.memo(() => {
  const userGuid = useExpectedPrincipal().userGuid
  const message = useMessage()
  const timeZoneId = useExpectedCompany().timezone
  const canViewTeamSchedule = useCanViewTeamSchedule()
  const canViewPastAppointments = useCanViewPastAppointments()
  const canOnlyViewCurrentAppointment = useCanOnlyViewCurrentAppointment()
  const canOnlyViewTodaysAssignedAppointments =
    useCanOnlyViewTodaysAssignedAppointments()
  const canManageSchedule = useCanManageSchedule()
  const today = useMemo(
    () => BzDateTime.startOfToday(timeZoneId).toLocalDateString(),
    [timeZoneId],
  )

  const [selectedDate, setSelectedDate] = useState(today)

  const timeWindow = useMemo(
    () =>
      BzDateTime.fromDateString(selectedDate, timeZoneId).forDays(1).toDto(),
    [selectedDate, timeZoneId],
  )

  const viewModelQuery = trpc.scheduling[
    'scheduling:get-comprehensive-view-model'
  ].useQuery(timeWindow, { cacheTime: 0 })

  const techMap = useMemo(() => {
    if (!viewModelQuery.data) {
      return {}
    }
    const map: Record<string, User> = {}
    for (const tech of viewModelQuery.data.availability.users) {
      map[tech.userGuid] = tech
    }
    return map
  }, [viewModelQuery.data])

  const appointmentMap = useMemo(
    () =>
      generateScheduleAppointmentMap(
        viewModelQuery.data?.assignables.unassignedAppointments,
        viewModelQuery.data?.assignables.assignedAppointments,
      ),
    [
      viewModelQuery.data?.assignables.unassignedAppointments,
      viewModelQuery.data?.assignables.assignedAppointments,
    ],
  )

  const [pendingNewEvent, setPendingNewEvent] = useState<BzCalendarEvent>()

  const [myCalendarData, fullCalendarData] = useMemo(() => {
    if (!viewModelQuery.data) {
      return [[], []]
    }

    const createEventFromAssignment = (
      appointment: AssignableApptViewModelWithBusinessProjections,
      assignment: AssignmentDTO,
    ): BzCalendarEvent => ({
      resource: appointment.appointment.guid,
      assignmentGuid: assignment.assignmentGuid,
      appointmentGuid: appointment.appointment.guid,
      title: `${appointment.contact.name} - ${
        appointment.location.address.line1
      }, ${BzAddress.getZip5(appointment.location.address)}`,
      start: assignment.timeWindow.start,
      end: assignment.timeWindow.end,
      color: userColor(assignment.technicianUserGuid),
      userGuids: [assignment.technicianUserGuid],
    })

    const isUnfinishedAssignmentStatus = (status: AssignmentStatus) =>
      ['TO_DO', 'EN_ROUTE', 'IN_PROGRESS'].includes(status)

    let hasCurrentAssignedAppointment = false
    const myEvents: BzCalendarEvent[] = []
    const allEvents: BzCalendarEvent[] = []
    for (const appointment of viewModelQuery.data.assignables
      .assignedAppointments) {
      for (const assignment of appointment.assignments) {
        const event = createEventFromAssignment(appointment, assignment)

        const startDate = BzDateFns.parseISO(
          assignment.timeWindow.start,
          timeZoneId,
        )
        const endDate = BzDateFns.parseISO(
          assignment.timeWindow.end,
          timeZoneId,
        )

        if (assignment.technicianUserGuid === userGuid) {
          if (
            !canViewPastAppointments &&
            BzDateFns.isBeforeToday(endDate, timeZoneId)
          )
            continue
          if (
            canOnlyViewTodaysAssignedAppointments &&
            BzDateFns.isAfterToday(startDate, timeZoneId)
          )
            continue

          const assignmentIsToday = BzDateFns.windowIsToday(
            { start: startDate, end: endDate },
            timeZoneId,
          )

          // If the user can only view the current appointment,
          // only show the first unfinished appointment of the day
          if (
            canOnlyViewCurrentAppointment &&
            assignmentIsToday &&
            (!isUnfinishedAssignmentStatus(assignment.assignmentStatus) ||
              hasCurrentAssignedAppointment)
          )
            continue

          if (assignmentIsToday && !hasCurrentAssignedAppointment) {
            hasCurrentAssignedAppointment = true
          }

          myEvents.push(event)
          allEvents.push(event)
        } else {
          // always add teammate assignments to allEvents
          allEvents.push(event)
        }
      }
    }

    for (const rawBlock of viewModelQuery.data.availability.blocks) {
      const titleParts = [
        formatTechnicianCapacityBlockReasonType(rawBlock.reasonType),
      ]
      if (rawBlock.reasonDescription) {
        titleParts.push(rawBlock.reasonDescription)
      }
      titleParts.push(
        rawBlock.userGuids
          .map(
            guid =>
              `${techMap[guid].firstName.charAt(0)}. ${techMap[guid].lastName}`,
          )
          .join(', '),
      )
      const block = withMobiscrollBlockFixForRecurringEvent(
        { ...rawBlock, blockGuid: rawBlock.guid },
        timeZoneId,
      )
      const event = {
        blockGuid: block.blockGuid,
        title: titleParts.join(' - '),
        reasonType: block.reasonType,
        userGuids: block.userGuids,
        reasonDescription: block.reasonDescription,
        start: block.start,
        end: block.end,
        color: blue6,
        recurring: block.recurrenceRule,
        recurringException: block.recurrenceRuleExceptions,
      }
      allEvents.push(event)
      if (block.userGuids.includes(userGuid)) {
        myEvents.push(event)
      }
    }
    return [myEvents, allEvents]
  }, [
    techMap,
    userGuid,
    viewModelQuery.data,
    canOnlyViewTodaysAssignedAppointments,
    canOnlyViewCurrentAppointment,
    timeZoneId,
    canViewPastAppointments,
  ])

  const calendarView = useMemo<MbscEventcalendarView>(() => {
    const startEndTime = {
      ...(viewModelQuery.data
        ? {
            startTime: toMsbcTime(
              viewModelQuery.data.workingTime.workingHours.startHour,
            ),
            endTime: toMsbcTime(
              viewModelQuery.data.workingTime.workingHours.endHour,
            ),
          }
        : {}),
    }

    return {
      schedule: {
        type: 'day',
        allDay: false,
        startDay: 0,
        endDay: 6,
        ...startEndTime,
      },
    }
  }, [viewModelQuery.data])

  const navigate = useNavigate()

  const onEventClick = useCallback(
    (e: MbscEventClickEvent) => {
      e.event.resource && navigate(`/appointments/${e.event.resource}`)
    },
    [navigate],
  )

  const tabs: Tab[] = useMemo(() => {
    const tabs = [
      {
        value: TabValue.MySchedule,
        label: 'My Schedule',
      },
    ]

    if (canViewTeamSchedule) {
      tabs.push({
        value: TabValue.TeamSchedule,
        label: 'Team Schedule',
      })
    }

    return tabs
  }, [canViewTeamSchedule])

  const [activeTab, setActiveTab] = useState<TabValue>(tabs[0].value)

  const onEventCreate = useCallback(
    (e: BzEventCreateEvent) => {
      if (e.event.resource) {
        const user = techMap[`${e.event.resource}`]
        if (user.deactivatedAt) {
          message.warning(
            "You can't create a new event for a deactivated user.",
          )
          return false
        }
      }
      if (e.action === 'drag' || e.action === 'click') {
        // When you edit a recurring event, for whatever reason Mobiscroll, does a
        // "create" as well as an "update". I want to skip the create. I can tell if
        // it's a new event or an existing block that's being edited because an existing
        // one will have a block guid. Thus `isBlockEvent` inside this `onEventCreate`
        // will only be true in this strange case where we are modifying a recurring
        // block (recurring appointments aren't a thing) and it's telling us we created
        // something. So skip.
        if (isBlockEvent(e.event)) {
          return false
        }

        setPendingNewEvent(e.event)
        return false
      }
      return false
    },
    [techMap, message],
  )

  const assignableAppointmentWithBusinessProjectionsMap = useMemo(() => {
    const map: Record<string, AssignableApptViewModelWithBusinessProjections> =
      {}
    for (const appointment of [
      ...(viewModelQuery.data?.assignables.assignedAppointments ?? []),
      ...(viewModelQuery.data?.assignables.unassignedAppointments ?? []),
    ]) {
      map[appointment.appointment.guid] = appointment
    }
    return map
  }, [
    viewModelQuery.data?.assignables.assignedAppointments,
    viewModelQuery.data?.assignables.unassignedAppointments,
  ])

  const allAppointments = useMemo(
    () => R.values(assignableAppointmentWithBusinessProjectionsMap),
    [assignableAppointmentWithBusinessProjectionsMap],
  )

  const renderScheduleEventContent = useCallback(
    (data: BzCalendarEventData) => {
      const appointment =
        appointmentMap[(data.original?.resource as string) ?? '']

      if (appointment) {
        return (
          <NonBlockEventContent
            assignmentGuid={(data.original?.assignmentGuid as string) ?? ''}
            appointment={appointment}
          />
        )
      }

      if (isBlockEvent(data.original)) {
        return <BlockEventContent block={data.original} />
      }
      return (
        <div className="relative flex flex-row items-center p-2">
          <div className="leading-none">New event</div>
        </div>
      )
    },
    [appointmentMap],
  )

  return (
    <ScheduleWrapper
      scheduleViewModel={viewModelQuery.data}
      scheduleView="DAY"
      setScheduleView={() => {}}
      scheduleMode="CALENDAR"
      setScheduleMode={() => {}}
      // TODO This should be based on selectedDate state value above
      selectedDate={BzDateFns.nowISOString()}
      setSelectedDate={() => {}}
      refetch={viewModelQuery.refetch}
    >
      <SchedulePendingChangesWrapper>
        <CommitScheduleChangesListener />

        {/* NOTE: the "overflow-auto" here shouldn't be necessary, but it fixes a very odd
      iOS bug */}
        <div className="absolute inset-0 flex min-h-0 flex-1 flex-col overflow-auto">
          <div className="m-2 flex flex-row items-center justify-between">
            <DatePicker
              allowClear={false}
              value={Dfns.parseISO(selectedDate)}
              format="MMMM d"
              onChange={date =>
                setSelectedDate(
                  date
                    ? BzDateTime.fromDate(date, timeZoneId)
                        .atStartOfDay()
                        .toLocalDateString()
                    : today,
                )
              }
            />
            <Select
              value={activeTab}
              options={tabs}
              onChange={setActiveTab}
              disabled={tabs.length === 1}
              // To make sure all the options are always visible without ellipses
              className="min-w-[150px]"
            />
          </div>
          <div className="relative flex-1 overflow-auto">
            <TrpcQueryLoader
              query={viewModelQuery}
              render={() => (
                <>
                  <Eventcalendar
                    themeVariant="light"
                    timezonePlugin={momentTimezone}
                    dataTimezone={timeZoneId}
                    displayTimezone={timeZoneId}
                    view={calendarView}
                    data={
                      activeTab === TabValue.TeamSchedule
                        ? fullCalendarData
                        : myCalendarData
                    }
                    selectedDate={selectedDate}
                    onSelectedDateChange={({ date }) => {
                      setSelectedDate(
                        BzDateTime.fromDate(date as Date, timeZoneId)
                          .atStartOfDay()
                          .toLocalDateString(),
                      )
                    }}
                    clickToCreate={canManageSchedule}
                    dragToCreate={canManageSchedule}
                    onEventClick={onEventClick}
                    onEventCreate={onEventCreate}
                    eventDelete={false}
                    renderScheduleEventContent={renderScheduleEventContent}
                    // Render nothing for these so they take up less space. We're putting an overlay
                    renderHeader={() => null}
                    renderDay={() => null}
                  />
                  {pendingNewEvent && (
                    <NewEventModalWrapper
                      pendingNewEvent={pendingNewEvent}
                      allAppointments={allAppointments}
                      setPendingNewEvent={setPendingNewEvent}
                    />
                  )}
                  <div className="absolute inset-x-0 top-0 z-10 h-[54px] bg-white">
                    <WeeklyDayPicker
                      date={selectedDate}
                      onChange={setSelectedDate}
                    />
                  </div>
                </>
              )}
            />
          </div>
          {viewModelQuery.isLoading && (
            <div className="absolute inset-0 z-50 bg-white/70">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </SchedulePendingChangesWrapper>
    </ScheduleWrapper>
  )
})

type NewEventModalProps = {
  pendingNewEvent: BzCalendarEvent
  allAppointments: AssignableApptViewModelWithBusinessProjections[]
  setPendingNewEvent: StateSetter<BzCalendarEvent | undefined>
}
const NewEventModalWrapper = React.memo<NewEventModalProps>(
  ({ pendingNewEvent, allAppointments, setPendingNewEvent }) => {
    const { scheduleViewModel } = useScheduleContext()
    const { setPendingChanges } = useSchedulePendingChanges()

    const onNewEventModalClose = useCallback(
      (newEvent?: BzCalendarEvent) => {
        if (newEvent) {
          setPendingChanges({
            field: 'newEventMap',
            key: newEvent.appointmentGuid ?? newEvent.blockGuid,
            value: newEvent,
          })
        }

        setPendingNewEvent(undefined)
      },
      [setPendingChanges, setPendingNewEvent],
    )

    return (
      <NewEventModal
        onClose={onNewEventModalClose}
        pendingNewEvent={pendingNewEvent}
        timeZoneId={scheduleViewModel?.workingTime.timeZoneId ?? BzDateFns.UTC}
        technicians={scheduleViewModel?.availability.users ?? []}
        allAppointments={allAppointments}
      />
    )
  },
)

export default TechnicianSchedulePage
