import { anonGql } from '../../generated'

export const ANON_PREQUAL_RECORD_INFO_SUBSCRIPTION = anonGql(/* GraphQL */ `
  subscription AnonPrequalRecordInfo($accountGuid: uuid!) {
    wisetackPrequalRecords(where: { accountGuid: { _eq: $accountGuid } }) {
      maxQualifiedAmountUsc
      status
      contact {
        firstName
      }
    }
  }
`)
