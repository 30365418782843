import { PrequalStatus, PrequalStatusDisplayNames } from '@breezy/shared'
import React from 'react'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'

export type PrequalStatusTagProps = {
  status: PrequalStatus
  className?: string
}

const toStatusDetails = (
  status: PrequalStatus,
): { text: string; color: StatusTagColor } => {
  switch (status) {
    case 'PENDING':
      return {
        text: PrequalStatusDisplayNames[status],
        color: 'gray',
      }
    case 'INITIATED':
      return {
        text: PrequalStatusDisplayNames[status],
        color: 'gray',
      }
    case 'PREQUALIFIED':
      return {
        text: PrequalStatusDisplayNames[status],
        color: 'green',
      }
    case 'DECLINED':
      return {
        text: PrequalStatusDisplayNames[status],
        color: 'red',
      }
    case 'EXPIRED':
      return {
        text: PrequalStatusDisplayNames[status],
        color: 'gray',
      }
    default:
      return { text: 'Unknown', color: 'gray' }
  }
}

const PrequalStatusTag = React.memo<PrequalStatusTagProps>(
  ({ status, className }) => {
    return (
      <StatusTag
        color={toStatusDetails(status).color}
        text={toStatusDetails(status).text}
        border="strong"
        className={className}
      />
    )
  },
)

export default PrequalStatusTag
