import { Loader } from '@googlemaps/js-api-loader'
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getConfig } from '../config'
import { Composable } from '../utils/Composable'

const { mapsAPIKey } = getConfig()

type GooglePlacesContextWrapper = {
  googleLoading: boolean
  autocompleteService: google.maps.places.AutocompleteService | null
  placesService: google.maps.places.PlacesService | null
}

const GooglePlacesContext = createContext<GooglePlacesContextWrapper>({
  googleLoading: true,
  autocompleteService: null,
  placesService: null,
})

export const useGooglePlaces = () => {
  return useContext(GooglePlacesContext)
}

const GoogleMapsLoader = new Loader({
  apiKey: mapsAPIKey,
  version: 'weekly',
  libraries: ['places', 'marker'],
})

export const GooglePlacesWrapper: FC<Composable> = ({ children }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    GoogleMapsLoader.load().then(() => {
      setLoading(false)
    })
  }, [])

  const [autocompleteService, placesService] = useMemo(
    () =>
      loading
        ? [null, null]
        : ([
            new google.maps.places.AutocompleteService(),
            new google.maps.places.PlacesService(document.createElement('div')),
          ] as const),
    [loading],
  )

  return (
    <GooglePlacesContext.Provider
      value={{ googleLoading: loading, autocompleteService, placesService }}
    >
      {children}
    </GooglePlacesContext.Provider>
  )
}
