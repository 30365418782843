import {
  CartItem,
  DiscountType,
  DynamicPricingType,
  InvoiceCartItem,
  InvoiceDiscount,
  InvoicePayment,
  R,
  RichDiscountUsc,
  WithPartialKeys,
  cartItemToCartItemUsc,
  cartItemUscToCartItem,
  isNullish,
  nextGuid,
} from '@breezy/shared'
import {
  faAdd,
  faAddressCard,
  faCopy,
  faEdit,
  faPercent,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../../adam-components/OnsiteModal/ActionsModalContent'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../../adam-components/SectionedCard/SectionedContent'
import {
  DiscountMultiPicker,
  DiscountPickerDiscount,
} from '../../../components/Pricebook/DiscountMultiPicker'
import {
  PricebookItemEditModal,
  PricebookItemEditModalProps,
} from '../../../components/Pricebook/PricebookItemEditModal'
import { PricebookItemPicker } from '../../../components/Pricebook/PricebookItemPicker'
import { RichDiscountEditModal } from '../../../components/Pricebook/RichDiscountEditModal'
import { WysiwygEditor } from '../../../components/WysiwygEditor/WysiwygEditor'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { useDirtyingSetValue } from '../../../elements/Forms/useDirtyingSetValue'
import useIsMobile, { useIsTouchScreen } from '../../../hooks/useIsMobile'
import {
  StateSetter,
  useModalState,
  useStrictContext,
} from '../../../utils/react-utils'
import {
  InvoiceContext,
  InvoiceDataContext,
  InvoiceEditData,
  useIsInvoicePriceEditable,
} from '../invoiceUtils'
import { InvoiceEditInfoModal } from './InvoiceEditInfoModal'
import { InvoiceInfoContent } from './InvoiceInfoContent'
import { LineItemList } from './LineItemList'
import {
  useNegativeBalanceModal,
  useResultsInNegativeBalance,
} from './NegativeBalanceModal'
import { TotalsSection } from './TotalsSection'

type InvoiceItemEditModalProps = WithPartialKeys<
  PricebookItemEditModalProps,
  'item'
>

const InvoiceItemEditModal = React.memo<InvoiceItemEditModalProps>(
  ({ item, ...rest }) => {
    if (!item) {
      return null
    }

    return <PricebookItemEditModal item={item} {...rest} />
  },
)

const useIsLineItemRemovalAllowed = (
  lineItems: InvoiceCartItem[],
  taxRate: number,
  dynamicPricingType: DynamicPricingType | undefined,
  discounts: InvoiceDiscount[],
  existingPayments?: InvoicePayment[],
) => {
  const resultsInNegativeBalance = useResultsInNegativeBalance(existingPayments)

  return useCallback(
    (lineItemIndex: number) => {
      const stagedLineItems = R.remove(lineItemIndex, 1, lineItems)

      return !resultsInNegativeBalance(
        stagedLineItems,
        taxRate,
        dynamicPricingType,
        discounts,
      )
    },
    [
      lineItems,
      resultsInNegativeBalance,
      taxRate,
      dynamicPricingType,
      discounts,
    ],
  )
}

const useIsDiscountsEditAllowed = (
  lineItems: InvoiceCartItem[],
  taxRate: number,
  dynamicPricingType: DynamicPricingType | undefined,
  existingPayments?: InvoicePayment[],
) => {
  const resultsInNegativeBalance = useResultsInNegativeBalance(existingPayments)

  return useCallback(
    (discounts: InvoiceDiscount[]) => {
      return !resultsInNegativeBalance(
        lineItems,
        taxRate,
        dynamicPricingType,
        discounts,
      )
    },
    [resultsInNegativeBalance, lineItems, taxRate, dynamicPricingType],
  )
}

const useIsLineItemsEditAllowed = (
  taxRate: number,
  dynamicPricingType: DynamicPricingType | undefined,
  discounts: InvoiceDiscount[],
  existingPayments?: InvoicePayment[],
) => {
  const resultsInNegativeBalance = useResultsInNegativeBalance(existingPayments)

  return useCallback(
    (lineItems: InvoiceCartItem[]) => {
      return !resultsInNegativeBalance(
        lineItems,
        taxRate,
        dynamicPricingType,
        discounts,
      )
    },
    [resultsInNegativeBalance, taxRate, dynamicPricingType, discounts],
  )
}

const useInvoiceItemEdit = (
  lineItems: InvoiceCartItem[],
  setLineItems: StateSetter<InvoiceCartItem[]>,
  onLineItemActionsCancel: () => void,
  openedLineItemIndex?: number,
): {
  invoiceItemEditModalProps: InvoiceItemEditModalProps
} => {
  const editingLineItem = useMemo(
    () =>
      isNullish(openedLineItemIndex)
        ? undefined
        : cartItemUscToCartItem(lineItems[openedLineItemIndex]),
    [openedLineItemIndex, lineItems],
  )

  const onLineItemEdit = useCallback(
    (item: CartItem) => {
      if (!isNullish(openedLineItemIndex)) {
        if (item.quantity === 0) {
          setLineItems(R.remove(openedLineItemIndex, 1))
        } else {
          setLineItems(items => {
            const { cartItemGuid, seq } = items[openedLineItemIndex]
            const newItemUsc = cartItemToCartItemUsc(item)
            const newItem: InvoiceCartItem = {
              ...newItemUsc,
              seq,
              cartItemGuid,
            }
            return R.update(openedLineItemIndex, newItem, items)
          })
        }
      }
      onLineItemActionsCancel()
    },
    [openedLineItemIndex, setLineItems, onLineItemActionsCancel],
  )

  const onCancel = useCallback(
    () => onLineItemActionsCancel(),
    [onLineItemActionsCancel],
  )

  return useMemo(
    () => ({
      invoiceItemEditModalProps: {
        item: editingLineItem,
        onCancel,
        onSubmit: onLineItemEdit,
      },
    }),
    [editingLineItem, onCancel, onLineItemEdit],
  )
}

type ItemActionsModalProps = {
  header: string
  onCancel: () => void
  onEdit: () => void
  onDuplicate?: () => void
  onDelete: () => void
}

const ItemActionsModal = React.memo<ItemActionsModalProps>(
  ({ header, onCancel, onEdit, onDuplicate, onDelete }) => {
    return (
      <OnsiteBasicModal
        headerBordered
        onClose={onCancel}
        open
        header={header}
        size="small"
      >
        <ActionsModalContent>
          <ActionsModalAction
            onClick={onEdit}
            icon={<FontAwesomeIcon icon={faEdit} />}
          >
            Edit
          </ActionsModalAction>
          {onDuplicate && (
            <ActionsModalAction
              onClick={onDuplicate}
              icon={<FontAwesomeIcon icon={faCopy} />}
            >
              Duplicate
            </ActionsModalAction>
          )}
          <ActionsModalAction
            danger
            onClick={onDelete}
            icon={<FontAwesomeIcon icon={faTrash} />}
          >
            Remove
          </ActionsModalAction>
        </ActionsModalContent>
      </OnsiteBasicModal>
    )
  },
)

type EditableInvoiceContentProps = {
  form: UseFormReturn<InvoiceEditData>
  existingPayments?: InvoicePayment[]
}

export const EditableInvoiceContent = React.memo<EditableInvoiceContentProps>(
  ({ form, existingPayments }) => {
    const isMobile = useIsMobile()
    const isTouchScreen = useIsTouchScreen()

    const { displayId } = useStrictContext(InvoiceContext)
    const { invoiceTotals } = useStrictContext(InvoiceDataContext)
    const { negativeBalanceModal, showNegativeBalanceModal } =
      useNegativeBalanceModal()
    const isPriceEditable = useIsInvoicePriceEditable()

    const {
      formState: { errors },
      control,
      watch,
      setValue: rawSetValue,
    } = form
    const setValue = useDirtyingSetValue(rawSetValue)

    const lineItems = watch('lineItems')
    const discounts = watch('discounts')
    const infoFormData = watch('infoFormData')
    const dynamicPricingType = watch('dynamicPricingType')

    const isLineItemsEditAllowed = useIsLineItemsEditAllowed(
      invoiceTotals.taxRate,
      dynamicPricingType,
      discounts,
      existingPayments,
    )

    const setLineItems = useCallback<StateSetter<InvoiceCartItem[]>>(
      newState => {
        if (typeof newState === 'function') {
          if (!isLineItemsEditAllowed(newState(lineItems))) {
            showNegativeBalanceModal({
              title: 'Invalid line item edit',
              description: <NegativeBalanceLineItemsEditDescription />,
            })
          } else {
            setValue('lineItems', newState(lineItems))
          }
        } else {
          if (!isLineItemsEditAllowed(newState)) {
            showNegativeBalanceModal({
              title: 'Invalid line item edit',
              description: <NegativeBalanceLineItemsEditDescription />,
            })
          } else {
            setValue('lineItems', newState)
          }
        }
      },
      [isLineItemsEditAllowed, lineItems, setValue, showNegativeBalanceModal],
    )

    const [itemPickerOpen, openItemPicker, closeItemPicker] = useModalState()

    const onItemsSubmit = useCallback(
      (items: CartItem[]) => {
        setLineItems(lineItems => [
          ...lineItems,
          ...items.map((item, i) => {
            const itemUsc = cartItemToCartItemUsc(item)
            return {
              ...itemUsc,
              seq: lineItems.length + i,
              cartItemGuid: nextGuid(),
            }
          }),
        ])
        closeItemPicker()
      },
      [closeItemPicker, setLineItems],
    )

    const [openedLineItemIndex, setOpenedLineItemIndex] = useState<number>()

    const onLineItemActionsCancel = useCallback(() => {
      setOpenedLineItemIndex(undefined)
    }, [])

    const { invoiceItemEditModalProps } = useInvoiceItemEdit(
      lineItems,
      setLineItems,
      onLineItemActionsCancel,
      openedLineItemIndex,
    )

    const isLineItemRemovalAllowed = useIsLineItemRemovalAllowed(
      lineItems,
      invoiceTotals.taxRate,
      dynamicPricingType,
      discounts,
      existingPayments,
    )

    const onLineItemActionsDelete = useCallback(() => {
      if (!isNullish(openedLineItemIndex)) {
        if (!isLineItemRemovalAllowed(openedLineItemIndex)) {
          showNegativeBalanceModal({
            title: `Can't remove item`,
            description: (
              <NegativeBalanceRemoveLineItemDescription
                lineItem={lineItems[openedLineItemIndex]}
              />
            ),
            onClose: () => {
              setOpenedLineItemIndex(undefined)
            },
          })
          return
        }

        setLineItems(R.remove(openedLineItemIndex, 1))
        setOpenedLineItemIndex(undefined)
      }
    }, [
      isLineItemRemovalAllowed,
      lineItems,
      openedLineItemIndex,
      setLineItems,
      showNegativeBalanceModal,
    ])

    const [editInfoOpen, openEditInfo, closeEditInfo] = useModalState()

    const [discountPickerOpen, openDiscountPicker, closeDiscountPicker] =
      useModalState()

    const isDiscountsEditAllowed = useIsDiscountsEditAllowed(
      lineItems,
      invoiceTotals.taxRate,
      dynamicPricingType,
      existingPayments,
    )
    const setDiscounts = useCallback(
      (discounts: DiscountPickerDiscount[]) => {
        if (!isDiscountsEditAllowed(discounts)) {
          showNegativeBalanceModal({
            title: 'Invalid discount action',
            description: <NegativeBalanceDiscountsEditDescription />,
          })
        } else {
          setValue('discounts', discounts)
          closeDiscountPicker()
        }
      },
      [
        closeDiscountPicker,
        isDiscountsEditAllowed,
        setValue,
        showNegativeBalanceModal,
      ],
    )

    const [openedDiscountIndex, setOpenedDiscountIndex] = useState<number>()
    const [editingDiscountIndex, setEditingDiscountIndex] = useState<number>()

    const editingDiscount = useMemo(
      () =>
        isNullish(editingDiscountIndex)
          ? undefined
          : discounts[editingDiscountIndex],
      [discounts, editingDiscountIndex],
    )

    const clearEditingDiscount = useCallback(
      () => setEditingDiscountIndex(undefined),
      [],
    )

    const onDiscountActionsCancel = useCallback(() => {
      setOpenedDiscountIndex(undefined)
    }, [])

    const onDiscountActionsEdit = useCallback(() => {
      setEditingDiscountIndex(openedDiscountIndex)
      setOpenedDiscountIndex(undefined)
    }, [openedDiscountIndex])

    const onDiscountActionsDuplicate = useMemo(() => {
      if (isNullish(openedDiscountIndex)) {
        return undefined
      }
      const openedDiscount = discounts[openedDiscountIndex]
      // You can't have two percentage discounts so you can't duplicate one
      if (openedDiscount.type === DiscountType.RATE) {
        return undefined
      }
      return () => {
        if (!isNullish(openedDiscountIndex)) {
          setDiscounts(
            R.insert(
              openedDiscountIndex + 1,
              {
                ...discounts[openedDiscountIndex],
                discountGuid: nextGuid(),
              },
              discounts,
            ),
          )
        }

        setOpenedDiscountIndex(undefined)
      }
    }, [discounts, openedDiscountIndex, setDiscounts])

    const onDiscountActionsDelete = useCallback(() => {
      if (!isNullish(openedDiscountIndex)) {
        setDiscounts(R.remove(openedDiscountIndex, 1, discounts))
      }
      setOpenedDiscountIndex(undefined)
    }, [discounts, openedDiscountIndex, setDiscounts])

    const onDiscountEdit = useCallback(
      (discount: RichDiscountUsc) => {
        if (!isNullish(editingDiscountIndex)) {
          if (
            (discount.type === DiscountType.FLAT &&
              !discount.discountAmountUsc) ||
            (discount.type === DiscountType.RATE && !discount.discountRate)
          ) {
            setDiscounts(R.remove(editingDiscountIndex, 1, discounts))
          } else {
            const existingDiscountGuid =
              discounts[editingDiscountIndex].discountGuid
            const newDiscount: DiscountPickerDiscount = {
              ...discount,
              discountGuid: existingDiscountGuid,
            }
            setDiscounts(R.update(editingDiscountIndex, newDiscount, discounts))
          }
        }
        clearEditingDiscount()
      },
      [clearEditingDiscount, discounts, editingDiscountIndex, setDiscounts],
    )

    const onReorder = useCallback(
      (itemIndex: number, dropIndex: number) => {
        const reorderedLineItems = R.move(
          itemIndex,
          dropIndex < itemIndex ? dropIndex + 1 : dropIndex,
          lineItems,
        )

        const reSequencedLineItems = reorderedLineItems.map((item, i) => ({
          ...item,
          seq: i,
        }))

        setValue('lineItems', reSequencedLineItems)
      },
      [lineItems, setValue],
    )

    const orderedLineItems = useMemo(
      () => R.sortBy(R.prop('seq'), lineItems),
      [lineItems],
    )

    const sections = useMemo<SectionedSection[]>(
      () => [
        <InvoiceInfoContent />,
        {
          coloredIn: true,
          content: (
            <div>
              <div className="mb-1 text-sm font-semibold text-bz-gray-900">
                Message
              </div>
              <ReactHookFormItem
                control={control}
                name="messageHtml"
                errors={errors}
                render={({ field }) => (
                  <WysiwygEditor
                    {...field}
                    dataTestId="invoice-message-tiptap-editor"
                  />
                )}
              />
            </div>
          ),
        },
        {
          verticalPaddingClassName: 'py-1',
          content:
            lineItems.length === 0 ? (
              <div
                className={classNames(
                  'cursor-pointer rounded-lg bg-bz-gray-200 px-4 py-5 text-center text-sm text-bz-gray-900',
                  isMobile ? 'my-4' : 'my-6',
                )}
                onClick={openItemPicker}
              >
                Line items will be displayed here.{' '}
                {isTouchScreen ? 'Tap' : 'Click'} to add one now.
              </div>
            ) : (
              <LineItemList
                lineItems={orderedLineItems}
                discounts={discounts}
                onDiscountClick={
                  isPriceEditable ? setOpenedDiscountIndex : undefined
                }
                onItemClick={
                  isPriceEditable ? setOpenedLineItemIndex : undefined
                }
                onReorder={isPriceEditable ? onReorder : undefined}
              />
            ),
        },
        {
          content: (
            <div>
              <TotalsSection
                hideTotalSubTotal
                {...invoiceTotals}
                dynamicPricingType={dynamicPricingType}
                discounts={discounts}
              />
              <div className="mt-4 flex flex-row items-center space-x-3 border-0 border-t border-dashed border-bz-gray-500 pt-4 *:min-w-0">
                {isPriceEditable && (
                  <Button
                    size="large"
                    type="primary"
                    className={classNames({ 'flex-1': isMobile })}
                    onClick={openItemPicker}
                    icon={<FontAwesomeIcon icon={faAdd} />}
                    data-testid="add-line-item-button"
                  >
                    {isMobile ? '' : 'Add '}Line Item
                  </Button>
                )}
                <Button
                  icon={<FontAwesomeIcon icon={faAddressCard} />}
                  size="large"
                  onClick={openEditInfo}
                  data-testid="edit-info-button"
                >
                  {isMobile ? '' : 'Edit Info'}
                </Button>
                {isPriceEditable && (
                  <Button
                    icon={<FontAwesomeIcon icon={faPercent} />}
                    size="large"
                    onClick={openDiscountPicker}
                    data-testid="set-discount-button"
                  >
                    {isMobile ? '' : 'Set Discount'}
                  </Button>
                )}
              </div>
            </div>
          ),
        },
      ],
      [
        control,
        discounts,
        dynamicPricingType,
        errors,
        invoiceTotals,
        isMobile,
        isPriceEditable,
        isTouchScreen,
        lineItems.length,
        onReorder,
        openDiscountPicker,
        openEditInfo,
        openItemPicker,
        orderedLineItems,
      ],
    )
    return (
      <>
        <SectionedCard sections={sections} />
        {itemPickerOpen && (
          <PricebookItemPicker
            onSubmit={onItemsSubmit}
            onCancel={closeItemPicker}
          />
        )}
        {discountPickerOpen && (
          <DiscountMultiPicker
            preExistingDiscounts={discounts}
            onSubmit={setDiscounts}
            onCancel={closeDiscountPicker}
          />
        )}
        {editingDiscount && (
          <RichDiscountEditModal
            item={editingDiscount}
            onSubmit={onDiscountEdit}
            onCancel={clearEditingDiscount}
            // We can't mix and match rate and flat discounts. We also can't have more than one rate discount. If we
            // have one discount, then it's a rate or a flat. Either way, if they edit it they can switch it. If we have
            // more than one discount, the only way that's possible is if they have two or more flats. You can't mix and
            // match, so modifying this one to be a rate would be mixing and matching with the other one.
            disableRate={discounts.length > 1}
          />
        )}
        <InvoiceItemEditModal
          {...invoiceItemEditModalProps}
          onRemove={onLineItemActionsDelete}
        />
        {editInfoOpen && (
          <InvoiceEditInfoModal
            displayId={displayId}
            onClose={closeEditInfo}
            defaultValues={infoFormData}
            onSave={data => setValue('infoFormData', data)}
          />
        )}
        {!isNullish(openedDiscountIndex) && (
          <ItemActionsModal
            header="Discount actions"
            onCancel={onDiscountActionsCancel}
            onEdit={onDiscountActionsEdit}
            onDuplicate={onDiscountActionsDuplicate}
            onDelete={onDiscountActionsDelete}
          />
        )}
        {negativeBalanceModal}
      </>
    )
  },
)

const NegativeBalanceRemoveLineItemDescription = React.memo<{
  lineItem: InvoiceCartItem
}>(({ lineItem }) => {
  return (
    <>
      <p>
        Removing “{lineItem.name}” would make the invoice balance lower than
        what has already been paid by the customer. To remove this item you'll
        need to first add another that will ensure a positive balance.
      </p>
      <p>
        If you are not able to replace the item you are trying to remove, you
        may need to issue a refund and void this invoice.
      </p>
    </>
  )
})

const NegativeBalanceDiscountsEditDescription = React.memo(() => {
  return (
    <p>
      This action will make the invoice balance negative. Please adjust the
      discounts or add a line item to bring the balance back up to zero or more.
    </p>
  )
})

const NegativeBalanceLineItemsEditDescription = React.memo(() => {
  return (
    <p>
      This action will make the invoice balance negative. Please edit a line
      item to bring it back to zero or more.
    </p>
  )
})
