import { Button, notification } from 'antd'
import { useCallback, useState } from 'react'
import { useInterval } from 'react-use'
import { getConfig } from '../config'
import { useFeatureFlag } from '../hooks/useFeatureFlags'

export const openNotification = () =>
  notification.open({
    message: 'The version of the app you are using is out of date',
    description:
      'Please click the refresh button below to get the latest version',
    duration: 0,
    btn: (
      <Button
        type="primary"
        size="middle"
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    ),
    closeIcon: <>X</>,
  })

const apiUrl = getConfig().apiUrl
const myPlatformVersion = getConfig().platformVersion
const myLatestBreakingVersion = getConfig().latestBreakingVersion
const shouldCheckPlatformVersion = getConfig().shouldCheckPlatformVersion
const interval = 1000 * 60 // Every minute

export const PlatformVersionWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [latestPlatformVersion, setLatestPlatformVersion] =
    useState(myPlatformVersion)

  const platformVersionMismatchNotificationEnabled = useFeatureFlag(
    'platformVersionMismatchNotification',
  )

  const checkVersion = useCallback(() => {
    if (!shouldCheckPlatformVersion) return
    if (!platformVersionMismatchNotificationEnabled) return

    fetch(`${apiUrl}/platform-version`)
      .then(res => res.json())
      .then(({ platformVersion, latestBreakingVersion }) => {
        if (
          parseFloat(myLatestBreakingVersion) <
          parseFloat(latestBreakingVersion)
        ) {
          window.location.reload()
        }
        if (latestPlatformVersion !== platformVersion) {
          console.info(`Detected that current version is out of date`, {
            currentVersion: myPlatformVersion,
            latestVersion: platformVersion,
            currentBreakingVersion: myLatestBreakingVersion,
            latestBreakingVersion,
          })
          setLatestPlatformVersion(platformVersion)

          // Only show the notification once, otherwise it would appear multiple times if there were multiple releases
          if (latestPlatformVersion === myPlatformVersion) {
            openNotification()
          }
        }
      })
      .catch(e => {
        console.error(
          `There was an error fetching the latest platform version`,
          e,
        )
      })
  }, [
    latestPlatformVersion,
    setLatestPlatformVersion,
    platformVersionMismatchNotificationEnabled,
  ])

  useInterval(checkVersion, interval)
  return <>{children}</>
}
