import { DocumentType, gql } from '../../../generated'

export const ACCOUNT_BILLING_INFO_QUERY = gql(/* GraphQL */ `
  query AccountBillingInfo($accountGuid: uuid!) {
    accounts(where: { accountGuid: { _eq: $accountGuid } }) {
      accountContacts(where: { primary: { _eq: true } }) {
        contact {
          fullName
        }
      }
      mailingAddress {
        addressGuid
        line1
        line2
        city
        stateAbbreviation
        zipCode
      }
    }
    accountLocations(
      where: { accountGuid: { _eq: $accountGuid }, isArchived: { _neq: true } }
    ) {
      location {
        address {
          addressGuid
          line1
          line2
          city
          stateAbbreviation
          zipCode
        }
      }
    }
  }
`)
export type AccountBillingInfoQuery = DocumentType<
  typeof ACCOUNT_BILLING_INFO_QUERY
>
