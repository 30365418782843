import { MaintenancePlanDetailsViewModel } from '@breezy/shared'
import { faArrowsRotate, faFilePlus } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useState } from 'react'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import OptionCardButton from '../../elements/OptionCardButton/OptionCardButton'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
type MaintenancePlanRenewModalProps = {
  maintenancePlan: MaintenancePlanDetailsViewModel
  onClose: () => void
}

export const MaintenancePlanRenewModal = ({
  maintenancePlan,
  onClose,
}: MaintenancePlanRenewModalProps) => {
  const [renewMode, setRenewMode] = useState<
    'new-from-existing' | 'renew-existing' | undefined
  >(undefined)
  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'maintenance-plan-renew-modal')

  const onRenew = useCallback(
    (mode: 'new-from-existing' | 'renew-existing') => {
      setRenewMode(mode)
      openMaintenancePlanWizard()
    },
    [openMaintenancePlanWizard],
  )

  const onMaintenancePlanWizardClose = useCallback(() => {
    closeMaintenancePlanWizard()
    setRenewMode(undefined)
    onClose()
  }, [closeMaintenancePlanWizard, setRenewMode, onClose])
  return (
    <>
      <OnsiteBasicModal
        open
        size="large-width"
        onClose={onClose}
        header="Renew plan"
      >
        <div className="flex w-full flex-nowrap content-start items-start gap-3">
          <OptionCardButton
            optionKey="renew-existing"
            icon={faArrowsRotate}
            text={`Configure the same plan (${
              maintenancePlan.planDefinition?.name ?? 'Unknown Plan'
            })`}
            onClick={() => {
              onRenew('renew-existing')
            }}
          />
          <OptionCardButton
            optionKey="new-from-existing"
            icon={faFilePlus}
            text="Configure a new plan"
            onClick={() => {
              onRenew('new-from-existing')
            }}
          />
        </div>
      </OnsiteBasicModal>
      {maintenancePlanWizardOpen && renewMode && (
        <MaintenancePlanWizard
          onRamp="maintenance-plan-renew-modal"
          mode={renewMode}
          existingPlan={maintenancePlan}
          onClose={onMaintenancePlanWizardClose}
        />
      )}
    </>
  )
}
