import { BzDateFns, isNullish } from '@breezy/shared'
import { faPause, faPlay, faStop } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card } from 'antd'
import useIsMobile from 'src/hooks/useIsMobile'
import { trpc } from '../../hooks/trpc'
import tailwindConfig from '../../tailwind.config'
import { Styled } from '../../utils/Stylable'
import { m } from '../../utils/react-utils'
import { useTimeClock } from './useTimeClock'

export type UserTimeClockStatusCardProps = {
  hideClockInOutButton?: boolean
}

export type UserTimeClockCurrentAssignment = {
  activity: string
  jobGuid: string
  jobAppointmentGuid: string
  jobAppointmentAssignmentGuid: string
}

export const UserTimeClockStatusCard = ({
  hideClockInOutButton,
}: UserTimeClockStatusCardProps) => {
  const {
    currentAssignment,
    refetch,
    isClockedIn,
    clockedInTime,
    fetching,
    localElapsedDurationMin,
    clockIn,
  } = useTimeClock({ realtime: true })

  if (!isClockedIn) {
    return (
      <Card className="shadow-md" bodyStyle={{ padding: '12px' }}>
        <div className="flex w-full flex-row items-center">
          <div className="flex flex-col">
            <h2 className="m-0 text-base font-bold text-bz-gray-1000">
              Please clock in!
            </h2>
            <span className="text-xs text-bz-gray-1000">
              Your time is not being recorded.
            </span>
          </div>

          <Button
            className="ml-auto"
            type="primary"
            onClick={() => {
              clockIn()
              refetch()
            }}
          >
            Clock In
          </Button>
        </div>
      </Card>
    )
  }

  return (
    <Card className="shadow-md" bodyStyle={{ padding: '12px' }}>
      <div className="flex flex-row items-center gap-4">
        <ClockedInTimeDisplay clockedInTime={clockedInTime} />
        <ClockInElapsedDisplay
          currentAssignment={currentAssignment}
          localElapsedDurationMin={localElapsedDurationMin}
        />

        <ClockControlsRow
          currentAssignment={currentAssignment}
          refetch={refetch}
          fetching={fetching}
          hideClockInOutButton={hideClockInOutButton}
        />
      </div>
    </Card>
  )
}

type ClockControlsRowProps = {
  currentAssignment: UserTimeClockCurrentAssignment | null
  refetch: () => void
  fetching: boolean
  hideClockInOutButton?: boolean
}

export const ClockControlsRow = m<Styled<ClockControlsRowProps>>(
  ({
    currentAssignment,
    refetch,
    fetching,
    hideClockInOutButton,
    className,
  }: Styled<ClockControlsRowProps>) => {
    const isMobile = useIsMobile()
    const updateTimeClockStatus =
      trpc.timesheets['timesheets:update-time-clock-status'].useMutation()

    const updatePauseResumeStatus =
      trpc.assignments['assignments:update-pause-resume-status'].useMutation()
    return (
      <div className={`ml-auto flex flex-row gap-3 ${className}`}>
        {!isNullish(currentAssignment) && (
          <Button
            className="w-full border-bz-gray-900"
            shape={isMobile ? 'circle' : 'default'}
            icon={
              <FontAwesomeIcon
                icon={
                  ['DRIVE-TIME', 'ON-SITE'].includes(currentAssignment.activity)
                    ? faPause
                    : faPlay
                }
                color={tailwindConfig.theme.extend.colors['bz-gray'][900]}
                size="lg"
              />
            }
            onClick={() => {
              const {
                jobGuid,
                jobAppointmentGuid,
                jobAppointmentAssignmentGuid,
              } = currentAssignment
              updatePauseResumeStatus.mutate({
                jobGuid,
                jobAppointmentGuid,
                jobAppointmentAssignmentGuid,
              })
              refetch()
            }}
            disabled={updateTimeClockStatus.isLoading || fetching}
          >
            {!isMobile && (
              <span className="text-sm font-semibold text-bz-gray-900">
                {['DRIVE-TIME', 'ON-SITE'].includes(currentAssignment.activity)
                  ? 'Pause'
                  : 'Resume'}
              </span>
            )}
          </Button>
        )}

        {isNullish(hideClockInOutButton) || !hideClockInOutButton ? (
          <Button
            className="w-full border-bz-red-600"
            shape={isMobile ? 'circle' : 'default'}
            icon={
              <FontAwesomeIcon
                icon={faStop}
                color={tailwindConfig.theme.extend.colors['bz-red'][600]}
                size="lg"
              />
            }
            onClick={() => {
              updateTimeClockStatus.mutate({})
              refetch()
            }}
            disabled={updateTimeClockStatus.isLoading || fetching}
          >
            {!isMobile && (
              <span className="text-sm font-semibold text-bz-red-600">
                Clock Out
              </span>
            )}
          </Button>
        ) : null}
      </div>
    )
  },
)

export const ClockedInTimeDisplay = m(
  ({ clockedInTime }: { clockedInTime: Date | null }) => {
    return (
      <div className="flex flex-1 flex-col">
        <span className="text-base font-bold text-bz-gray-1000">
          {isNullish(clockedInTime)
            ? 'Unknown'
            : BzDateFns.format(clockedInTime, 'h:mm a')}
        </span>
        <span className="text-xs text-bz-gray-1000">clocked in</span>
      </div>
    )
  },
)

export const ClockInElapsedDisplay = m(
  ({
    currentAssignment,
    localElapsedDurationMin,
  }: {
    currentAssignment: UserTimeClockCurrentAssignment | null
    localElapsedDurationMin: number
  }) => {
    const elapsedHours = Math.floor(localElapsedDurationMin / 60)
    const elapsedMinutes = localElapsedDurationMin % 60
    return (
      <div className="flex flex-1 flex-col">
        <span
          className="text-base font-bold"
          style={{
            color:
              isNullish(currentAssignment) ||
              ['IDLE', 'DRIVE-TIME', 'ON-SITE'].includes(
                currentAssignment.activity,
              )
                ? tailwindConfig.theme.extend.colors['bz-green'][800]
                : tailwindConfig.theme.extend.colors['bz-orange'][700],
          }}
        >
          {elapsedHours ? `${elapsedHours} h ` : ''}
          {elapsedMinutes} min
        </span>
        <span className="text-xs text-bz-gray-1000">
          {isNullish(currentAssignment) ||
          ['IDLE', 'DRIVE-TIME', 'ON-SITE'].includes(currentAssignment.activity)
            ? 'elapsed'
            : 'paused'}
        </span>
      </div>
    )
  },
)
