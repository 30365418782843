import { toAssignedAppointmentViewModel } from '@breezy/backend/src/application-types'
import {
  AssignedApptViewModel,
  AssignmentWithTechnicianNameDTO,
  CalculatePaths,
  DUMMY_PREQUAL_RECORD_GUID,
  InstalledHvacSystem,
  bzExpect,
  convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary,
  convertInstalledHvacSystemDtoToInstalledHvacSystem,
  guidSchema,
  noOp,
  richJobTypeDescriptor,
} from '@breezy/shared'
import {
  faCalendar,
  faInfoCircle,
  faTools,
  faUser,
  faWrench,
} from '@fortawesome/pro-light-svg-icons'
import { faHistory } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useSubscription } from 'urql'
import { useFetchAppointmentBlocksAndAppointmentsLiveQuery } from '../../../../hooks/fetch/useFetchAppointmentBlocksAndAppointmentsLiveQuery'
import { useCanManageJob } from '../../../../hooks/permission/useCanManageJob'
import { useCanViewLocation } from '../../../../hooks/permission/useCanViewLocation'
import { useTechnicianScheduleAppointmentPermissions } from '../../../../hooks/permission/useTechnicianScheduleAppointmentPermissions'
import { trpc } from '../../../../hooks/trpc'
import { useMaintenancePlanCollapsibleData } from '../../../../pages/AccountDetailsPage/accountDetailsUtils'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { useWisetackEnabled } from '../../../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompany,
  useExpectedPrincipal,
} from '../../../../providers/PrincipalUser'
import { BehindFeatureFlag } from '../../../BehindFeatureFlag'
import GqlQueryLoader from '../../../GqlQueryLoader/GqlQueryLoader'
import JobAttachments from '../../../JobAttachments/JobAttachments'
import { LoadingSpinner } from '../../../LoadingSpinner'
import { MigrationJunkView } from '../../../MigrationJunk/MigrationJunkView'
import LinkedNotesList from '../../../Notes/LinkedNotesList/LinkedNotesList'
import UrqlSubscriptionLoader from '../../../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import { UserAssignmentTimeClockStatusCard } from '../../../UserTimeClockStatusCard/UserAssignmentTimeClockStatusCard'
import EstimatesCollapsible from '../../../collapsibles/EstimatesCollapsible/EstimatesCollapsible'
import { InstalledEquipmentCollapsibleItem } from '../../../collapsibles/InstalledEquipmentCollapsible/InstalledEquipmentCollapsible'
import { InstalledHvacSystemCollapsibleItem } from '../../../collapsibles/InstalledHvacSystemsCollapsible/InstalledHvacSystemsCollapsible'
import { InvoicesV2Collapsible } from '../../../collapsibles/InvoicesCollapsible/InvoicesV2Collapsible'
import MaintenancePlansCollapsible from '../../../collapsibles/MaintenancePlansCollapsible/MaintenancePlansCollapsible'
import WisetackFinancingCollapsible from '../../../collapsibles/WisetackFinancingCollapsible/WisetackFinancingCollapsible'
import TechAppFullScreenLoadingSpinner from '../../TechAppFullScreenLoadingSpinner/TechAppFullScreenLoadingSpinner'
import { useTechExpDrawers } from '../../TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import AboutAppointmentCard from './AboutAppointmentCard/AboutAppointmentCard'
import CollapsibleLikeLink from './AboutAppointmentCard/CollapsibleLikeLink'
import { AccountContactCard } from './AccountContactCard'
import { AppointmentActionMenu } from './AppointmentActionMenu/AppointmentActionMenu'
import {
  APPOINTMENT_DETAIL_ACCOUNT_SUBSCRIPTION,
  TAGS_MINIMAL_FOR_JOB_QUERY,
} from './AppointmentDetail.gql'
import './AppointmentDetail.less'
import AppointmentDetailCollapse from './AppointmentDetailCollapse/AppointmentDetailCollapse'
import {
  getAssignment,
  groupAppointmentAssignments,
} from './AppointmentDetailUtils'
import { AssignmentStatusSteps } from './AssignmentStatusSteps/AssignmentStatusSteps'
import { ChecklistsButton } from './ChecklistsButton'
import { HistoricalAppointmentsCard } from './HistoricalAppointmentsCard/HistoricalAppointmentsCard'
import LocationPhotos from './LocationPhotos/LocationPhotos'
import { OtherAssignedTechniciansCard } from './OtherAssignedTechniciansCard/OtherAssignedTechniciansCard'
import ServiceHistory from './ServiceHistory/ServiceHistory'

export type AppointmentDetailProps = {
  readonly appt: AssignedApptViewModel
  readonly version: number
  readonly onMutate?: () => void
}

export const AppointmentDetail = ({
  appt,
  version,
  onMutate = noOp,
}: AppointmentDetailProps) => {
  const wisetackEnabled = useWisetackEnabled()
  const principalUserGuid = useExpectedPrincipal().userGuid
  const companyGuid = useExpectedCompany().companyGuid
  const drawers = useTechExpDrawers()
  const { accountGuid, locationGuid } = appt

  const { canManageTeamSchedule } =
    useTechnicianScheduleAppointmentPermissions()
  const { canManage: canManageJob } = useCanManageJob(appt.jobGuid)
  const { canView: canViewLocation } = useCanViewLocation(appt.locationGuid)

  const [accountQuery] = useSubscription({
    query: APPOINTMENT_DETAIL_ACCOUNT_SUBSCRIPTION,
    variables: {
      accountGuid,
    },
  })

  const account = accountQuery.data?.accountsByPk

  const jobTagsQuery = useQuery({
    query: TAGS_MINIMAL_FOR_JOB_QUERY,
    variables: {
      companyGuid,
      jobGuid: appt.jobGuid,
    },
  })

  const filteredMaintenancePlans = useMemo(
    () =>
      (account?.maintenancePlans ?? []).filter(
        mp => mp.locationGuid === locationGuid,
      ),
    [account?.maintenancePlans, locationGuid],
  )

  const maintenancePlans = useMaintenancePlanCollapsibleData(
    filteredMaintenancePlans,
  )

  const loanRecordsForJobQuery = trpc.financing[
    'loan-records:for-job'
  ].useQuery({ jobGuid: appt.jobGuid })

  // If there isn't a valid account guid, we pass in a dummy guid to satisfy the trpc runtime type validation
  // which returns an empty array for the prequal records
  const prequalRecordsForAccountQuery = trpc.financing[
    'prequal-records:for-account'
  ].useQuery({ accountGuid: appt.accountGuid ?? DUMMY_PREQUAL_RECORD_GUID })

  const refetchWisetack = useCallback(() => {
    loanRecordsForJobQuery.refetch()
    prequalRecordsForAccountQuery.refetch()
  }, [loanRecordsForJobQuery, prequalRecordsForAccountQuery])

  const fetchMigrationJunkQuery = trpc.migrationJunk[
    'migration-junk:query'
  ].useQuery({ accountGuid })

  const fetchHistoricalAppointments = trpc.appointments[
    'historical-appointments-for-location:by-job-appointment-guid'
  ].useQuery({ jobAppointmentGuid: appt.appointment.guid })

  const fetchChecklistInfo = trpc.appointmentChecklist[
    'appointment-checklists:get-for-appointment'
  ].useQuery({
    jobAppointmentGuid: appt.appointment.guid,
    jobTypeGuid: appt.jobType.jobTypeGuid,
    appointmentType: appt.appointment.appointmentType,
  })

  const { myAssignment, otherAssignments } = useMemo(() => {
    return groupAppointmentAssignments(principalUserGuid, appt)
  }, [appt, principalUserGuid])

  const canManageAppointment = useMemo(() => {
    return !!myAssignment || canManageTeamSchedule
  }, [myAssignment, canManageTeamSchedule])

  const guids = useMemo(
    () => ({
      accountGuid: appt.accountGuid,
      locationGuid: appt.locationGuid,
      jobGuid: appt.jobGuid,
      jobAppointmentGuid: appt.appointment.guid,
    }),
    [appt],
  )

  const incompleteChecklistError = useMemo(() => {
    if (!fetchChecklistInfo.data) {
      return ''
    }

    for (const instance of fetchChecklistInfo.data.instances) {
      for (const checklistItem of instance.checklist.items) {
        if (!checklistItem.notRequired && !checklistItem.response) {
          return `You must complete the following checklist: ${instance.checklist.name}`
        }
      }
    }
    return ''
  }, [fetchChecklistInfo.data])

  const jobAppointmentAssignmentGuid = myAssignment
    ? myAssignment.assignmentGuid
    : otherAssignments[0]?.assignmentGuid

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'appt-detail')

  const onMaintenancePlanWizardClose = useCallback(() => {
    closeMaintenancePlanWizard()
    onMutate()
  }, [closeMaintenancePlanWizard, onMutate])

  if (accountQuery.fetching) {
    return <LoadingSpinner />
  }

  if (accountQuery.error || !account) {
    const errorMsg = `Failed to load account for appointment. Account Guid: ${appt.accountGuid}`
    console.error(errorMsg, accountQuery.error)
    return <div>{errorMsg}</div>
  }

  return (
    <div className="appt-detail w-full p-[2px]">
      <div className="mb-3 flex flex-col rounded-lg bg-white p-3 shadow-md">
        <div className="mb-4 mt-2 flex items-center space-x-3">
          <FontAwesomeIcon className="h-5 w-5" icon={faCalendar} />
          <div className="text-[16px] font-semibold text-bz-gray-1000">
            {richJobTypeDescriptor(appt.jobType.name)} Appointment
          </div>
        </div>
        {canManageAppointment && jobAppointmentAssignmentGuid && (
          <AssignmentStatusSteps
            assignmentStatus={
              myAssignment
                ? myAssignment.assignmentStatus
                : otherAssignments[0]?.assignmentStatus
            }
            apptAssignmentGuid={jobAppointmentAssignmentGuid}
            jobGuid={appt.jobGuid}
            apptGuid={appt.appointment.guid}
            onMutate={onMutate}
            markCompletedError={incompleteChecklistError}
          />
        )}
        {canManageAppointment &&
        fetchChecklistInfo.data?.numAssociatedChecklists ? (
          <>
            <Divider className="my-2" />
            <ChecklistsButton
              numChecklists={fetchChecklistInfo.data.numAssociatedChecklists}
              instances={fetchChecklistInfo.data.instances || []}
              refetch={fetchChecklistInfo.refetch}
              loading={fetchChecklistInfo.isLoading}
              jobTypeGuid={appt.jobType.jobTypeGuid}
              appointmentType={appt.appointment.appointmentType}
              jobAppointmentGuid={appt.appointment.guid}
            />
          </>
        ) : null}
      </div>

      <div className="grid grid-cols-1 space-y-3">
        {jobAppointmentAssignmentGuid && (
          <UserAssignmentTimeClockStatusCard
            jobAppointmentAssignmentGuid={jobAppointmentAssignmentGuid}
          />
        )}
        <AppointmentDetailCollapse
          title="Contacts"
          titleIcon={faUser}
          content={
            <AccountContactCard
              accountContacts={account.accountContacts}
              maintenancePlan={maintenancePlans?.[0]}
            />
          }
        />

        <BehindFeatureFlag
          enabledFeatureFlag="appointment-service-history"
          render={
            <AppointmentDetailCollapse
              title="Service History"
              titleIcon={faHistory}
              defaultOpen={false}
              showChildrenLength={false}
              content={
                <ServiceHistory appointmentGuid={appt.appointment.guid} />
              }
            />
          }
          fallback={<></>}
        />
        <GqlQueryLoader
          query={jobTagsQuery}
          render={jobTags => (
            <AppointmentDetailCollapse
              title="About this appointment"
              titleIcon={faInfoCircle}
              defaultOpen
              content={
                <AboutAppointmentCard
                  appt={appt}
                  myAssignment={myAssignment}
                  tagsForJob={jobTags.jobTags.map(({ tag }) => tag)}
                />
              }
              showChildrenLength={false}
            />
          )}
        />

        <MaintenancePlansCollapsible
          titleOverride="Maintenance Plan"
          plans={maintenancePlans}
          hideCanceledPlans
          createNew={
            canManageJob && maintenancePlans.length === 0
              ? () => {
                  openMaintenancePlanWizard()
                }
              : undefined
          }
          technicianAppStyle
        />

        <OtherAssignedTechniciansCard
          otherTechnicianAssignments={otherAssignments}
        />

        <InvoicesV2Collapsible
          accountGuid={guids.accountGuid}
          jobGuid={guids.jobGuid}
          jobAppointmentGuid={guids.jobAppointmentGuid}
          technicianAppStyle
          allowCreate={canManageJob}
        />

        {wisetackEnabled && (
          <WisetackFinancingCollapsible
            accountGuid={guids.accountGuid}
            loanRecords={loanRecordsForJobQuery.data ?? []}
            prequalRecords={prequalRecordsForAccountQuery.data ?? []}
            allowCreate={canManageJob}
            refetch={refetchWisetack}
          />
        )}

        <EstimatesCollapsible
          guids={guids}
          technicianAppStyle
          allowCreate={canManageJob}
        />

        <AppointmentDetailCollapse
          title="Equipment"
          defaultOpen={false}
          titleIcon={faTools}
          content={appt.installedEquipment
            .map(convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary)
            .map((equipment, equipmentIndex) => {
              return (
                <div key={equipment.installedEquipmentGuid} className="mt-2">
                  <InstalledEquipmentCollapsibleItem
                    equipment={equipment}
                    defaultOpen={equipmentIndex < 3}
                    onEdit={equipment => {
                      drawers.beginUpsertInstalledEquipment(
                        appt.location,
                        equipment,
                        onMutate,
                      )
                    }}
                    editable={canManageJob}
                  />
                </div>
              )
            })}
          showChildrenLength={false}
        />

        <AppointmentDetailCollapse
          title="HVAC Systems"
          titleIcon={faWrench}
          defaultOpen={false}
          content={appt.installedHvacSystems
            .map(convertInstalledHvacSystemDtoToInstalledHvacSystem)
            .map((installedHvacSystem, installedHvacSystemIndex) => {
              return (
                <InstalledHvacSystemCollapsibleItem
                  key={installedHvacSystem.installedHvacSystemGuid}
                  installedHvacSystem={installedHvacSystem}
                  installedHvacSystemIndex={installedHvacSystemIndex}
                  editable={canManageJob}
                  onEditInstalledEquipment={equipment => {
                    drawers.beginUpsertInstalledEquipment(
                      appt.location,
                      equipment,
                      onMutate,
                    )
                  }}
                  onEditInstalledHvacSystem={(
                    installedHvacSystem: InstalledHvacSystem,
                  ) => {
                    drawers.beginUpsertHvacSystem(
                      appt.location,
                      installedHvacSystem,
                      onMutate,
                    )
                  }}
                />
              )
            })}
          showChildrenLength={false}
        />

        <HistoricalAppointmentsCard
          historicalAppointments={fetchHistoricalAppointments.data ?? []}
        />

        {canViewLocation && (
          <CollapsibleLikeLink
            to={CalculatePaths.locationDetails({
              locationGuid: appt.locationGuid,
            })}
            text="View Location Job History"
          />
        )}

        {fetchMigrationJunkQuery.data &&
          Object.keys(fetchMigrationJunkQuery.data.junk).length > 0 && (
            <AppointmentDetailCollapse
              title={
                fetchMigrationJunkQuery.data.uiLabel ?? 'Legacy Migration Data'
              }
              titleIcon={faUser}
              showChildrenLength={false}
              content={
                <MigrationJunkView
                  migrationJunk={fetchMigrationJunkQuery.data}
                />
              }
            />
          )}

        {/* TODO BZ-625: Equipment Collapse */}
      </div>
      <Divider className="my-4" />
      <LocationPhotos
        onMutate={onMutate}
        locationGuid={appt.locationGuid}
        apptVersion={version}
      />
      <Divider className="my-4" />
      <div className="card-title-md mb-3">Appointment Notes</div>
      <LinkedNotesList
        noteLinks={{ jobGuid: appt.jobGuid }}
        linkData={{
          primaryNoteType: 'JOB_APPOINTMENT',
          jobGuid: appt.jobGuid,
          jobAppointmentGuid: appt.appointment.guid,
        }}
        // Explanation - Hide Photo Notes
        displayCondition={n => !n.photoGuid}
        version={version}
        editable={canManageJob}
      />

      <Divider className="my-4" />
      <div className="card-title-md mb-3">Attachments</div>
      <JobAttachments
        jobGuid={appt.jobGuid}
        disableUpload={!canManageJob}
        editable={canManageJob}
      />
      <div className="mb-20" />

      {maintenancePlanWizardOpen && (
        <MaintenancePlanWizard
          onRamp="appt-detail"
          accountGuid={appt.accountGuid}
          locationGuid={appt.locationGuid}
          jobGuid={appt.jobGuid}
          onClose={onMaintenancePlanWizardClose}
        />
      )}
    </div>
  )
}

const AppointmentDetailViewAndActions = ({
  appt,
  myAssignment,
  onMutate,
  version,
}: AppointmentDetailProps & {
  myAssignment: AssignmentWithTechnicianNameDTO | undefined
}) => (
  <>
    <AppointmentActionMenu
      appt={appt}
      onMutate={onMutate}
      version={version}
      apptAssignmentGuid={myAssignment?.assignmentGuid}
    />
    <AppointmentDetail appt={appt} onMutate={onMutate} version={version} />
  </>
)

const AppointmentDetailViewLoader = () => {
  const [version, setVersion] = useState(0)
  const appointmentGuidOrAppointmentReferenceNumber = bzExpect(
    useParams().appointmentGuidOrAppointmentReferenceNumber,
    'appointmentGuid or appointmentReferenceNumber must be passed in URL',
  )
  const userGuid = useExpectedPrincipal().userGuid

  const isAppointmentGuid = guidSchema.safeParse(
    appointmentGuidOrAppointmentReferenceNumber,
  ).success

  const {
    jobAppointmentSubscription: appointmentsByAppointmentGuidSubscription,
  } = useFetchAppointmentBlocksAndAppointmentsLiveQuery({
    jobAppointmentsWhere: {
      jobAppointmentGuid: {
        _eq: appointmentGuidOrAppointmentReferenceNumber,
      },
    },
    pause: !isAppointmentGuid,
  })

  const {
    jobAppointmentSubscription:
      appointmentsByAppointmentReferenceNumberSubscription,
  } = useFetchAppointmentBlocksAndAppointmentsLiveQuery({
    jobAppointmentsWhere: {
      appointmentReferenceNumber: {
        _eq: appointmentGuidOrAppointmentReferenceNumber,
      },
    },
    pause: isAppointmentGuid,
  })

  const subscription = isAppointmentGuid
    ? appointmentsByAppointmentGuidSubscription
    : appointmentsByAppointmentReferenceNumberSubscription

  const requery = useCallback(async () => {
    setVersion(version + 1)
  }, [version])

  return (
    <UrqlSubscriptionLoader
      subscription={subscription}
      loadingComponent={<TechAppFullScreenLoadingSpinner />}
      render={appts => {
        const apptVms = appts.jobAppointments.map(
          toAssignedAppointmentViewModel,
        )
        const appt = apptVms[0]

        return appt ? (
          <AppointmentDetailViewAndActions
            appt={appt}
            onMutate={requery}
            version={version}
            myAssignment={getAssignment(userGuid, appt)}
          />
        ) : (
          <div className="flex h-full flex-col items-center justify-center">
            <div className="text-2xl font-semibold">Appointment Not Found</div>
            <div className="text-gray-500">
              The appointment you are looking for could not be found.
            </div>
          </div>
        )
      }}
    />
  )
}

export default AppointmentDetailViewLoader
