import classNames from 'classnames'
import React, { useContext } from 'react'

import { useTsController } from '@ts-react/form'
import DisabledContext from 'antd/es/config-provider/DisabledContext'
import { WysiwygEditor } from '../../WysiwygEditor/WysiwygEditor'
import BaseTextField from '../BaseTextField/BaseTextField'

const RichTextArea = React.memo(() => {
  const {
    field: { onChange, value },
  } = useTsController<string>()

  const disabled = useContext(DisabledContext)

  return (
    <BaseTextField>
      <div className="min-h-[230px]">
        <WysiwygEditor
          value={value}
          dataTestId="rich-text-area"
          onChange={onChange}
          editorClassName={classNames('outline-none min-h-[230px]', {
            'cursor-not-allowed pointer-events-none text-[#00000040] bg-[#0000000A]':
              disabled,
          })}
        />
      </div>
    </BaseTextField>
  )
})

export default RichTextArea
