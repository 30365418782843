import { isNullish } from '@breezy/shared'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from 'antd'
import { ReactNode, memo } from 'react'
import FaIconWithCircularBackground from '../../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'

type HeaderProps = {
  faIcon?: IconDefinition
  title: string
  description?: string
  descriptionRowsBeforeTruncation?: number
  heightClassName?: string
  extraContent?: ReactNode
}

export const ExpandableHeightOptionCardHeader = memo<HeaderProps>(
  ({
    faIcon,
    title,
    description,
    descriptionRowsBeforeTruncation,
    heightClassName = 'h-full',
    extraContent,
  }) => {
    const ellipsisRows = Math.max(1, descriptionRowsBeforeTruncation ?? 1)

    return (
      <div className={`${heightClassName} flex gap-x-3 overflow-hidden`}>
        {!isNullish(faIcon) && (
          <div className="mr-[-6px] flex pt-[4px]">
            <FaIconWithCircularBackground
              iconDefinition={faIcon}
              backgroundColor="rgba(0, 0, 0, 0.04)"
              diameterPx={44}
              iconClassName="text-[20px]"
            />
          </div>
        )}
        <div className="flex flex-grow flex-col overflow-hidden">
          <Typography.Title
            level={4}
            className="mb-1 truncate"
            ellipsis={{ rows: 1 }}
          >
            {title}
          </Typography.Title>
          <Typography.Paragraph
            // If we upgrade antd to 5.16 we can make this "un-expandable"
            ellipsis={{
              rows: ellipsisRows,
              expandable: true,
              symbol: 'Read More',
            }}
            className="mb-0 font-normal"
          >
            {description}
          </Typography.Paragraph>
          {extraContent}
        </div>
      </div>
    )
  },
)
