import { formatUsc, isNullish } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo } from 'react'
import { UsersInfoForRevenueAttributionQuery } from '../../generated/user/graphql'

export type TechnicianPerformanceInfo = {
  totalEarnedRevenueUsc: number
  totalSoldRevenueUsc: number
  totalDeductionsUsc: number
  commissionableBaseUsc?: number
  commissionUsc?: number
  commissionRate?: number
  bonusUsc?: number
}

type TechnicianPerformanceSummaryProps = {
  technicianPerformanceInfo: TechnicianPerformanceInfo
  technicianUserInfo: UsersInfoForRevenueAttributionQuery['users'][0]
}

export const TechnicianPerformanceSummary =
  memo<TechnicianPerformanceSummaryProps>(
    ({ technicianPerformanceInfo, technicianUserInfo }) => {
      const shouldShowCommissionInfo =
        !isNullish(technicianPerformanceInfo.commissionRate) &&
        !isNullish(technicianPerformanceInfo.commissionableBaseUsc) &&
        !isNullish(technicianPerformanceInfo.commissionUsc) &&
        technicianPerformanceInfo.commissionRate > 0

      return (
        <>
          <Row className="mb-1 w-full">
            <strong>{`${technicianUserInfo.firstName} ${technicianUserInfo.lastName}`}</strong>
          </Row>

          <Row className="mb-1 w-full" justify="space-between">
            <Col>Sold Revenue</Col>
            <Col>
              {formatUsc(technicianPerformanceInfo.totalSoldRevenueUsc)}
            </Col>
          </Row>

          {shouldShowCommissionInfo && (
            <Row
              className="mb-1 w-full"
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
              justify="space-between"
            >
              <Col>Deductions</Col>
              <Col>
                {formatUsc(technicianPerformanceInfo.totalDeductionsUsc)}
              </Col>
            </Row>
          )}

          {shouldShowCommissionInfo && (
            <Row
              className="mb-1 w-full"
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
              justify="space-between"
            >
              <Col>Commissionable Base</Col>
              <Col>
                {formatUsc(
                  technicianPerformanceInfo.commissionableBaseUsc as number,
                )}
              </Col>
            </Row>
          )}
          {shouldShowCommissionInfo && (
            <Row
              className="mb-1 w-full"
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
              justify="space-between"
            >
              <Col>{`Commission (${(
                (technicianPerformanceInfo.commissionRate ?? 0) * 100
              ).toFixed(2)}%)`}</Col>
              <Col>
                {formatUsc(technicianPerformanceInfo.commissionUsc as number)}
              </Col>
            </Row>
          )}
          <Row className="mb-1 w-full" justify="space-between">
            <Col>Earned Revenue</Col>
            <Col>
              {formatUsc(technicianPerformanceInfo.totalEarnedRevenueUsc)}
            </Col>
          </Row>
          {!isNullish(technicianPerformanceInfo.bonusUsc) && (
            <Row style={{ width: '100%' }} justify="space-between">
              <Col>Bonus</Col>
              <Col>{formatUsc(technicianPerformanceInfo.bonusUsc)}</Col>
            </Row>
          )}
        </>
      )
    },
  )
