import z from 'zod'
import { DateTimeFormatter, IsoDateString, Merge, TimeZoneId, ZonedDateTime } from '../common'
import { guidSchema } from '../contracts/_common'
import { CompanyGuid } from './Company/Company'
import { UserGuid } from './Users/User'

/**
 * Accepts nullish values but then maps null to undefined which results in the inferred type not accepting null
 * as a valid type
 */
export const bzOptional = <T extends z.ZodTypeAny>(zObj: T) => zObj.nullish().transform(val => val ?? undefined)

export const GUID_REGEX_CHECK = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const firstNameSchema = z.string().min(1).max(75)
export const lastNameSchema = z.string().min(1).max(75)

export const positiveNumberLessThan10000 = z.number().positive().lte(10000)

export const isoWithOffsetDateSchema = z
  .string()
  .refine(val => ZonedDateTime.parse(val, DateTimeFormatter.ISO_OFFSET_DATE_TIME))
  .transform(data => data as IsoDateString)

export const localTimeSchema = z.string().refine(localTimeString => {
  const parts = localTimeString.split(':')
  const hoursComponent = parts[0]
  const minutesComponent = parts[1]

  return (
    hoursComponent.length === 2 &&
    hoursComponent >= '00' &&
    hoursComponent <= '23' &&
    minutesComponent.length === 2 &&
    minutesComponent >= '00' &&
    minutesComponent <= '59'
  )
})

export const GuidContainerSchema = z.object({
  guid: guidSchema,
})
export type GuidContainer = z.infer<typeof GuidContainerSchema>
export type GuidsCollectionContainer = { guids: Guid[] }

export const ReferenceNumberContainerSchema = z.object({
  referenceNumber: z.string(),
})

export type ReferenceNumberContainer = z.infer<typeof ReferenceNumberContainerSchema>
export type DisplayIdContainer = { displayId: number }

export type Guid = string
export type GuidArray = Guid[]
export type GuidAndReferenceNumber = GuidContainer & ReferenceNumberContainer

export type Named<T> = T & { name: string }

export const NonNegativeNumberSchema = z.number().int().nonnegative()

export type UriContainer = {
  uri: string
}
export type EntityIdContainer = {
  entityId: string
}

export const dataFilterConfigSchema = z.object({
  key: z.string(),
  label: z.string(),
  path: z.string().array(),
  compareAgainst: z.unknown(),
  displayTitle: bzOptional(z.string().min(1)),
})

export type DataFilterConfig = z.infer<typeof dataFilterConfigSchema>

export type AmountUscContainer = {
  amountUsc: number
}

export type AddOnFieldMap = {
  tzId: TimeZoneId
  companyGuid: CompanyGuid
  userGuid: UserGuid
  companyName: string
}

export type AddOnField = keyof AddOnFieldMap

export type ForAddonFields<F extends AddOnField, T> = Merge<T & Pick<AddOnFieldMap, F>>
