import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsTechnicianApp } from '../../../hooks/useIsTechnicianApp'
import {
  useIsCompanyUser,
  usePrincipalUser,
} from '../../../providers/PrincipalUser'

// See comment in AdminImpersonateUser.tsx for more context. Basically, this is a staging area after we stop
// impersonating so when we unset the impersonated user we're on a page (this page) that doesn't do anything and, thus,
// won't break because the user is suddenly not there.
export const StopImpersonatingLimbo = React.memo(() => {
  const { setImpersonatedUser } = usePrincipalUser()
  const isCompanyUser = useIsCompanyUser()
  const isTechnicianApp = useIsTechnicianApp()

  const navigate = useNavigate()

  useEffect(() => {
    setImpersonatedUser(undefined)
  }, [navigate, setImpersonatedUser])

  useEffect(() => {
    if (!isCompanyUser) {
      navigate(isTechnicianApp ? '/no-company' : '/admin')
    }
  }, [isCompanyUser, navigate, isTechnicianApp])

  return null
})
