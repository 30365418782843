import { ReactNode, RefObject } from 'react'
import { BzStepper, BzStepperProps } from '../../elements/BzStepper/BzStepper'
import useIsMobile from '../../hooks/useIsMobile'
import FullScreenModal from '../FullScreenModal/FullScreenModal'

export type ProgressiveFullScreenModalStepperProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: ReactNode
  sidebarContent?: ReactNode
  sidebarTitle?: string
  modalClassName?: string
  maxWidthPx?: number
  paddingClassName?: string
  containerRef?: RefObject<HTMLDivElement>
} & BzStepperProps

const ProgressiveFullScreenModalStepper = ({
  isOpen,
  setIsOpen,
  sidebarContent,
  sidebarTitle,
  children,
  modalClassName,
  maxWidthPx = 560,
  paddingClassName = 'pt-8',
  containerRef,
  ...stepper
}: ProgressiveFullScreenModalStepperProps) => {
  const isMobile = useIsMobile()
  const showSidebarContent = sidebarContent && !isMobile

  return (
    <FullScreenModal
      containerRef={containerRef}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className={modalClassName}
    >
      <div className="flex w-full justify-center">
        <div
          className={`flex w-full max-w-[930px] justify-center ${
            showSidebarContent && 'pr-[80px]'
          }`}
        >
          <div
            className={`max-w-[${maxWidthPx}px] ${
              (showSidebarContent && 'w-2/3 pr-[60px]') || 'w-full'
            }`}
          >
            <BzStepper {...stepper}>
              <div className={paddingClassName}>{children}</div>
            </BzStepper>
          </div>
          {showSidebarContent && (
            <div className="w-1/3 max-w-[370px] border-y-0 border-l border-r-0 border-solid border-l-[#D9D9D9] py-2 pl-6">
              {sidebarTitle && (
                <div className="semibold_20_28 grey9 mb-3">{sidebarTitle}</div>
              )}

              {sidebarContent}
            </div>
          )}
        </div>
      </div>
    </FullScreenModal>
  )
}

export default ProgressiveFullScreenModalStepper
