import { z } from 'zod'
import { guidSchema } from './_common'

const taggableTypes = [
  z.object({
    primaryNoteType: z.literal('LOCATION'),
    locationGuid: guidSchema,
  }),
  z.object({
    primaryNoteType: z.literal('ACCOUNT'),
    accountGuid: guidSchema,
  }),
  z.object({
    primaryNoteType: z.literal('JOB'),
    jobGuid: guidSchema,
  }),
  z.object({
    primaryNoteType: z.literal('JOB_APPOINTMENT'),
    jobGuid: guidSchema,
    jobAppointmentGuid: guidSchema,
  }),
  z.object({
    primaryNoteType: z.literal('ASSIGNMENT'),
    jobGuid: guidSchema,
    apptAssignmentGuid: guidSchema,
  }),
  z.object({
    primaryNoteType: z.literal('MAINTENANCE_PLAN'),
    maintenancePlanGuid: guidSchema,
  }),
] as const

const nonTaggableTypes = [
  z.object({
    primaryNoteType: z.literal('PHOTO'),
    photoGuid: guidSchema,
  }),
  z.object({
    primaryNoteType: z.literal('PAYMENT_RECORD'),
    paymentRecordGuid: guidSchema,
  }),
] as const

export const NoteLinkDataSchema = z.discriminatedUnion('primaryNoteType', [...taggableTypes, ...nonTaggableTypes])

export type NoteLinkData = z.infer<typeof NoteLinkDataSchema>

export const TaggableNoteLinkDataSchema = z.discriminatedUnion('primaryNoteType', [...taggableTypes])

export type TaggableNoteLinkData = z.infer<typeof TaggableNoteLinkDataSchema>
