import React, { useMemo, useState } from 'react'

import {
  TILLED_SANDBOX_CREDIT_CARD_INFO,
  TilledCreditCardSandboxInfo,
  getTilledCreditCardInfo,
} from '@breezy/shared'
import { Button, Table, Tabs } from 'antd'
import { OnsiteBasicModal } from '../../../../adam-components/OnsiteModal/OnsiteModal'
import { getConfig } from '../../../../config'
import BzSelect from '../../../../elements/BzSelect/BzSelect'
import CopyToClipboard, {
  CopyLabel,
} from '../../../../elements/CopyToClipboard/CopyToClipboard'

const TilledSandboxCreditCardDevTools = React.memo(() => {
  const isTilledSandbox = getConfig().tilled.useSandbox
  const [selectedCard, setSelectedCard] =
    useState<TilledCreditCardSandboxInfo>('visa-credit')
  const [showDevTools, setShowDevTools] = useState(false)

  const selectedOption = useMemo(() => {
    return getTilledCreditCardInfo(selectedCard)
  }, [selectedCard])

  const options = useMemo(
    () =>
      TILLED_SANDBOX_CREDIT_CARD_INFO.map(({ label, value }) => ({
        label,
        value,
      })),
    [],
  )

  const tabs = useMemo(
    () => [
      {
        label: 'Sandbox Cards',
        key: 'cardInfo',
        children: (
          <>
            <div className="text-sm font-semibold">Prefill Card Info</div>
            <BzSelect
              title="Tilled Sandbox Credit Card Info"
              className="mb-4"
              options={options}
              value={selectedCard}
              onChange={value => {
                setSelectedCard(value)
              }}
            />
            {selectedOption && (
              <div className="flex flex-col space-y-2 rounded-md bg-daybreak-blue-200 p-2">
                <div className="flex flex-row space-x-1">
                  <div className="font-semibold">Card #:</div>
                  <CopyToClipboard
                    payload={selectedOption.cardNumber}
                    label={<CopyLabel label={selectedOption.cardNumber} />}
                  />
                </div>
                <div className="flex flex-row space-x-1">
                  <div className="font-semibold">Expiry:</div>
                  <CopyToClipboard
                    payload={selectedOption.cardExpiry}
                    label={<CopyLabel label={selectedOption.cardExpiry} />}
                  />
                </div>
                <div className="flex flex-row space-x-1">
                  <div className="font-semibold">CVV:</div>
                  <CopyToClipboard
                    payload={selectedOption.cardCvv}
                    label={<CopyLabel label={selectedOption.cardCvv} />}
                  />
                </div>
              </div>
            )}
          </>
        ),
      },
      {
        label: 'Charge Decline',
        key: 'chargeDeclineErrors',
        children: (
          <div className="flex flex-col space-y-2">
            <p>
              The following exact amounts will result in the associated error
              code on a payment intent. This is only accessible for card.
              Example: 777701 = $7777.01.{' '}
              <a
                href="https://docs.tilled.com/docs/testing/testing/#simulating-charge-declines"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </p>

            <Table
              dataSource={[
                {
                  key: '1',
                  amount: 777701,
                  errorCode: 'INSUFFICIENT_FUNDS',
                },
                {
                  key: '2',
                  amount: 777702,
                  errorCode: 'AVS_CHECK_FAILED',
                },
                {
                  key: '3',
                  amount: 777703,
                  errorCode: 'GENERIC_DECLINE',
                },
                {
                  key: '4',
                  amount: 777704,
                  errorCode: 'CALL_ISSUER',
                },
                {
                  key: '5',
                  amount: 777705,
                  errorCode: 'EXPIRED_CARD',
                },
                {
                  key: '6',
                  amount: 777706,
                  errorCode: 'PICKUP_CARD',
                },
                {
                  key: '7',
                  amount: 777707,
                  errorCode: 'INVALID_NUMBER',
                },
                {
                  key: '8',
                  amount: 777708,
                  errorCode: 'LIMIT_EXCEEDED',
                },
                {
                  key: '9',
                  amount: 777709,
                  errorCode: 'NOT_PERMITTED',
                },
                {
                  key: '10',
                  amount: 777710,
                  errorCode: 'INCORRECT_CVC',
                },
                {
                  key: '11',
                  amount: 777711,
                  errorCode: 'SERVICE_NOT_ALLOWED',
                },
                {
                  key: '12',
                  amount: 777712,
                  errorCode: 'INVALID_EXPIRY',
                },
                {
                  key: '13',
                  amount: 777713,
                  errorCode: 'CARD_NOT_SUPPORTED',
                },
                {
                  key: '14',
                  amount: 777714,
                  errorCode: 'RESTRICTED_CARD',
                },
                {
                  key: '15',
                  amount: 777715,
                  errorCode: 'FRAUDULENT',
                },
                {
                  key: '16',
                  amount: 777716,
                  errorCode: 'PROCESSING_ERROR',
                },
              ]}
              columns={[
                {
                  title: 'Amounts (US Cents)',
                  dataIndex: 'amount',
                  key: 'amount',
                },
                {
                  title: 'Error Codes',
                  dataIndex: 'errorCode',
                  key: 'errorCode',
                },
              ]}
            />
          </div>
        ),
      },
      {
        label: 'Address Verification',
        key: 'addressVerificationErrors',
        children: (
          <div className="flex flex-col space-y-2">
            <p>
              By default, all card transactions with valid billing_details
              addresses will pass the address verification checks. To simulate a
              different response, add a single letter code from the table below
              as a prefix to the billing_details.street property. Example:
              billing_details.street='11 123 Main Street' will produce failed
              checks where the address matches, but postal code does not. These
              checks are available on the payment_method.card.checks property.{' '}
              <a
                href="https://docs.tilled.com/docs/testing/testing/#simulating-address-verification-responses"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </p>

            <Table
              dataSource={[
                {
                  key: '10',
                  prefix: 10,
                  description: 'Both street and postal code match.',
                },
                {
                  key: '11',
                  prefix: 11,
                  description: 'Street matches, but postal code does not.',
                },
                {
                  key: '12',
                  prefix: 12,
                  description: 'Postal code matches, but street does not.',
                },
                {
                  key: '18',
                  prefix: 18,
                  description: 'Address information is unavailable.',
                },
                {
                  key: '15',
                  prefix: 15,
                  description: 'Address information is unavailable.',
                },
              ]}
              columns={[
                {
                  title: 'Prefix',
                  dataIndex: 'prefix',
                  key: 'prefix',
                },
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
              ]}
            />
          </div>
        ),
      },
    ],
    [options, selectedCard, selectedOption],
  )

  if (!isTilledSandbox) return null
  return (
    <div className="absolute right-0 top-0 flex items-end space-y-1">
      <Button
        className="flex flex-1 pl-0"
        type="link"
        onClick={() => setShowDevTools(prev => !prev)}
      >
        Show Dev Tools
      </Button>
      {showDevTools && (
        <OnsiteBasicModal
          header="Tilled Dev Tools"
          onClose={() => setShowDevTools(false)}
          size="default"
        >
          <Tabs items={tabs} className="mb-4" />
        </OnsiteBasicModal>
      )}
    </div>
  )
})

export default TilledSandboxCreditCardDevTools
