import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConfigProvider as AntConfigProvider, Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import useIsMobile from '../../hooks/useIsMobile'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useFinancingWizard } from '../Financing/hooks/useFinancingWizard'
import PrequalLearnMoreLink from '../PrequalLearnMoreLink/PrequalLearnMoreLink'
import { PrequalBannersSubscription } from './PrequalifiedBanners'

type FinancingSectionProps = {
  accountGuid: string
  companyName: string
  jobGuid?: string
  invoiceGuid?: string
}

const FinancingSection = React.memo<FinancingSectionProps>(
  ({ accountGuid, companyName, jobGuid, invoiceGuid }) => {
    const isMobile = useIsMobile()
    const wisetackEnabled = useWisetackEnabled()

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      jobGuid,
      originatingInvoiceGuid: invoiceGuid,
    })

    const onShowFinancing = useCallback(() => {
      showFinancingWizard()
    }, [showFinancingWizard])

    if (!wisetackEnabled) {
      return null
    }
    return (
      <OnsitePageSection title="Financing">
        <OnsitePageSimpleSectionItem
          icon={
            <img
              src="/img/wisetack-logo.png"
              alt="Wisetack Logo"
              className="absolute inset-0"
            />
          }
          subContent={
            <>
              Send the customer an application to see how much they qualify for.{' '}
              <PrequalLearnMoreLink />
            </>
          }
          footer={
            <div className="space-y-3">
              <PrequalBannersSubscription
                accountGuid={accountGuid}
                companyName={companyName}
                className="w-full"
              />
              <AntConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#07c0ca',
                  },
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  className={classNames({
                    'w-full': isMobile,
                  })}
                  onClick={onShowFinancing}
                  data-dd-action-name="bz-financing-prequal-send-link-button-click"
                  icon={<FontAwesomeIcon icon={faPaperPlane} />}
                >
                  Send Financing Link
                </Button>
              </AntConfigProvider>
            </div>
          }
        >
          {isMobile ? 'Offer financing' : 'Win more jobs by offering financing'}
        </OnsitePageSimpleSectionItem>
        {financingWizard}
      </OnsitePageSection>
    )
  },
)

export default FinancingSection
