import classNames from 'classnames'
import React from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import useIsMobile from '../../hooks/useIsMobile'
import { SectionedContent, SectionedContentProps } from './SectionedContent'

type SectionedCardProps = SectionedContentProps & {
  noBorder?: boolean
  accentBarColor?: string
  heightClassName?: string
  anchoredFooter?: React.ReactNode
  loading?: boolean
  dataTestId?: string
}

export const SectionedCard = React.memo<SectionedCardProps>(
  ({
    small,
    className,
    accentBarColor,
    noBorder = false,
    heightClassName = 'h-full',
    anchoredFooter,
    loading = false,
    dataTestId,
    style,
    ...rest
  }) => {
    const isMobile = useIsMobile()

    return (
      <div
        className={classNames(
          'relative flex min-h-0 flex-col overflow-hidden border-solid border-bz-gray-500 bg-white transition-all ease-in-out',
          {
            'w-full': !small,
          },
          noBorder ? 'border-0' : 'rounded-xl border shadow-sm',
          heightClassName,
          className,
        )}
        style={style}
        data-testid={dataTestId}
      >
        {accentBarColor && (
          <div
            className={classNames('absolute inset-0 z-20 h-[10px]')}
            style={{ backgroundColor: accentBarColor }}
          />
        )}
        {loading ? (
          <div className="center-vh flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : null}
        {!loading && (
          <div
            className={classNames('flex flex-1 flex-col overflow-auto', {
              'px-3': small,
            })}
          >
            <SectionedContent
              small={small}
              className={small ? '*:px-0' : isMobile ? '*:px-4' : '*:px-6'}
              {...rest}
            />
          </div>
        )}
        {anchoredFooter ? (
          <div
            className={classNames(
              'border-0 border-t border-solid border-bz-border bg-bz-gray-100',
              isMobile ? 'px-4 pb-4 pt-2' : 'px-6 py-5',
            )}
          >
            {anchoredFooter}
          </div>
        ) : null}
      </div>
    )
  },
)
