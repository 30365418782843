import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

export type OnsiteMenuItemProps = {
  label: React.ReactNode
  onClick: () => void
  disabled?: boolean
  icon: IconDefinition | React.ReactNode
}

export const OnsiteMenuItem = React.memo<OnsiteMenuItemProps>(
  ({ label, onClick, disabled = false, icon }) => {
    return (
      <div
        onClick={disabled ? undefined : onClick}
        className={classNames(
          'flex items-center gap-3',
          {
            'cursor-pointer': !disabled,
          },
          {
            'pointer-events-none cursor-not-allowed opacity-50': disabled,
          },
        )}
      >
        <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-bz-gray-300">
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <FontAwesomeIcon
              className="flex h-5 w-5 flex-col justify-center"
              icon={icon as IconDefinition}
            />
          )}
        </div>
        <span className="ml-2 font-semibold text-black">{label}</span>
      </div>
    )
  },
)
