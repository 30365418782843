import { AccountType, getDisplayNameForAccountType } from '@breezy/shared'
import { ComponentProps } from 'react'
import SidebarItem from './SidebarItem'

export type SidebarAccountProps = Omit<
  ComponentProps<typeof SidebarItem>,
  'contentList' | 'sectionLabel'
> & {
  displayName: string
  accountType: AccountType
}

export const SidebarAccount = ({
  displayName,
  accountType,
  ...sidebarProps
}: SidebarAccountProps) => {
  return (
    <SidebarItem
      {...sidebarProps}
      sectionLabel="Account"
      contentList={[
        {
          key: 'Display name',
          value: displayName,
        },
        {
          key: 'Account type',
          value: getDisplayNameForAccountType(accountType),
        },
      ]}
    />
  )
}
