import {
  CartItem,
  JobOutcomesFormSchema,
  formatMoney,
  formatUsc,
  isNullish,
  usdToUsCents,
} from '@breezy/shared'
import { Row, Select, SelectProps, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { CSSProperties, memo, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ThinDivider from '../../elements/ThinDivider'
import './InvoiceRevenueAttributionTable.less'
import { calculateTotalCartItemPriceUsd } from './hooks/useDefaultRevenueAttributionsGuidToInvoiceMap'
import {
  JobOutcomeInvoice,
  RevenueAttributionUser,
} from './job-outcome-modal-types'

type InvoiceRevenueAttributionTableProps = {
  invoice: JobOutcomeInvoice
  allAttributableUsers: RevenueAttributionUser[]
  headerStyle?: CSSProperties
}

/**
 * Table component that allows users to configure technician revenue attribution
 * for a single invoice
 */
const InvoiceRevenueAttributionTable =
  memo<InvoiceRevenueAttributionTableProps>(
    ({ invoice, allAttributableUsers, headerStyle }) => {
      const form = useFormContext<JobOutcomesFormSchema>()
      const { control, setValue } = form

      const userSelectOptions = useMemo<SelectProps['options']>(() => {
        const recommendedUsers = allAttributableUsers.filter(
          user => user.recommended,
        )
        const nonRecommendedUsers = allAttributableUsers.filter(
          user => !user.recommended,
        )

        return [
          {
            label: <span>Suggested</span>,
            title: 'Suggested',
            options: recommendedUsers.map(user => {
              return {
                label: `${user.firstName} ${user.lastName.substring(0, 1)}`,
                value: user.userGuid,
                key: user.userGuid,
                // We want to include de-activated users as options so that their names still
                // appear if they were previously attributed to revenue, but we hide the names
                // so they can't be newly selected
                className: `${!isNullish(user.deactivatedAt) ? 'hidden' : ''}`,
              }
            }),
          },
          {
            label: <ThinDivider />,
            options: [],
          },
          {
            label: <span>Others</span>,
            title: 'Others',
            options: nonRecommendedUsers.map(user => {
              return {
                label: `${user.firstName} ${user.lastName.substring(0, 1)}`,
                value: user.userGuid,
                key: user.userGuid,
                // We want to include de-activated users as options so that their names still
                // appear if they were previously attributed to revenue, but we hide the names
                // so they can't be newly selected
                className: `${!isNullish(user.deactivatedAt) ? 'hidden' : ''}`,
              }
            }),
          },
        ]
      }, [allAttributableUsers])

      const tableColumns: ColumnsType<CartItem> = useMemo(() => {
        return [
          {
            title: 'Line Item',
            // TODO May need to add quantity to this display
            dataIndex: 'name',
            width: '25%',
            key: 'lineItem',
          },
          {
            title: 'Total',
            key: 'totalRevenue',
            width: '15%',
            render: (_, record) => {
              const totalPriceUsd = calculateTotalCartItemPriceUsd(record)
              const itemGuid = record.itemGuid
              return (
                <Controller
                  name={`revenueAttributionGuidToInvoiceMap.${invoice.invoiceGuid}.guidToItemMap.${itemGuid}.totalRevenueUsc`}
                  control={control}
                  defaultValue={usdToUsCents(totalPriceUsd)}
                  render={() => {
                    return <div>{formatMoney(totalPriceUsd)}</div>
                  }}
                />
              )
            },
          },
          {
            title: 'Sold Rev.',
            dataIndex: 'soldRev',
            key: 'soldRev',
            width: '30%',
            render: (_, record) => {
              const itemGuid = record.itemGuid
              return (
                <Controller
                  name={`revenueAttributionGuidToInvoiceMap.${invoice.invoiceGuid}.guidToItemMap.${itemGuid}.soldRevenueAttributionUserGuids`}
                  control={control}
                  render={({ field }) => {
                    const { onChange, ...restFields } = field
                    return (
                      <Select
                        {...restFields}
                        style={{
                          width: '100%',
                        }}
                        mode="multiple"
                        allowClear
                        options={userSelectOptions}
                        onChange={value => {
                          onChange(value) // Change handler produced by react-hook-form Controller

                          // TODO Can probably remove this
                          // Our side effects
                          setValue(
                            `revenueAttributionGuidToInvoiceMap.${invoice.invoiceGuid}.guidToItemMap.${itemGuid}.totalRevenueUsc`,
                            usdToUsCents(
                              calculateTotalCartItemPriceUsd(record),
                            ),
                          )
                        }}
                      />
                    )
                  }}
                />
              )
            },
          },
          {
            title: 'Earned Rev.',
            dataIndex: 'earnedRev',
            key: 'earnedRev',
            width: '30%',
            render: (_, record) => {
              const itemGuid = record.itemGuid
              return (
                <Controller
                  name={`revenueAttributionGuidToInvoiceMap.${invoice.invoiceGuid}.guidToItemMap.${itemGuid}.earnedRevenueAttributionUserGuids`}
                  control={control}
                  render={({ field }) => {
                    const { onChange, ...restFields } = field
                    return (
                      <Select
                        {...restFields}
                        style={{
                          width: '100%',
                        }}
                        mode="multiple"
                        allowClear
                        options={userSelectOptions}
                        onChange={value => {
                          onChange(value) // Change handler produced by react-hook-form Controller

                          // Our side effects
                          setValue(
                            `revenueAttributionGuidToInvoiceMap.${invoice.invoiceGuid}.guidToItemMap.${itemGuid}.totalRevenueUsc`,
                            usdToUsCents(
                              calculateTotalCartItemPriceUsd(record),
                            ),
                          )
                        }}
                      />
                    )
                  }}
                />
              )
            },
          },
        ]
      }, [control, invoice.invoiceGuid, setValue, userSelectOptions])

      return (
        <>
          <Row style={{ width: '100%', ...headerStyle }}>
            <h4 style={{ marginBottom: '12px' }}>
              Invoice {`#${invoice.displayId}`}
            </h4>
          </Row>
          <Row className="w-full">
            <Table
              className="job-outcomes-modal-revenue-attribution-table"
              rowKey="itemGuid"
              columns={tableColumns}
              dataSource={invoice.items}
              style={{ width: '100%' }}
              size="small"
              pagination={false}
              bordered
              summary={() => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="bg-gray-50 p-2">
                      <Table.Summary.Cell index={0} colSpan={4}>
                        {`Total (Pre-tax): ${formatUsc(
                          usdToUsCents(invoice.subtotalPriceUsd),
                        )}`}

                        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                          {' • '}
                        </span>
                        {`Total (Incl. tax): ${formatUsc(
                          usdToUsCents(invoice.totalPriceUsd),
                        )}`}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }}
            />
          </Row>
        </>
      )
    },
  )

export default InvoiceRevenueAttributionTable
