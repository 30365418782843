import { copyToClipboard } from '@breezy/shared'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { message } from 'antd'
import cn from 'classnames'
import React, { useCallback } from 'react'

type CopyToClipboardProps = {
  label: React.ReactNode
  payload: string
  onCopy?: () => void
  successMessage?: string
  className?: string
  isUrl?: boolean
}

const CopyToClipboard = React.memo<CopyToClipboardProps>(
  ({ label, payload, onCopy, successMessage, className, isUrl }) => {
    const onClick = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        copyToClipboard(payload)
        message.success(successMessage ?? 'Copied!', 2)
        onCopy?.()
      },
      [payload, successMessage, onCopy],
    )
    return isUrl ? (
      <a
        href={payload}
        className={cn('cursor-pointer text-bz-primary', className)}
        onClick={onClick}
      >
        {label}
      </a>
    ) : (
      <div
        className={cn('cursor-pointer text-bz-primary', className)}
        onClick={onClick}
      >
        {label}
      </div>
    )
  },
)

export const CopyLabel = React.memo<{ label: string }>(({ label }) => {
  return (
    <span className="flex items-center gap-2">
      {label}
      <FontAwesomeIcon icon={faCopy} />
    </span>
  )
})

export default CopyToClipboard
