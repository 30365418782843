import { type EditJobViewModel } from '@breezy/backend/src/application-types'
import { InstallProjectType, bzExpect, isNullish } from '@breezy/shared'
import { DrawerProps } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useFetchAccountByAccountGuidQuery } from '../../hooks/fetch/useFetchAccountByGuid'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { JobIcon } from '../../utils/feature-icons'
import {
  CreateOrEditJobForm,
  makeEquipmentTypeJobLinks,
  onFormSubmitValues,
} from '../CreateOrEditJobForm/CreateOrEditJobForm'
import { CreateOrEditNewJobFormSchema } from '../CreateOrEditJobForm/CreateOrEditNewJobFormSchema'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../LoadingSpinner'
import { JOB_QUERY } from './EditJobModal.gql'

type EditJobModalProps = {
  open: DrawerProps['open']
  onClose: () => void
  jobGuid: string
  onJobUpdateSuccess: () => void
}

export const EditJobModal: React.FC<EditJobModalProps> = ({
  open,
  onClose,
  jobGuid,
  onJobUpdateSuccess,
}) => {
  const { companyGuid } = useExpectedCompany()

  const jobQuery = useQuery({
    query: JOB_QUERY,
    variables: { companyGuid, jobGuid },
    requestPolicy: 'network-only',
    pause: !open,
  })

  return (
    <BzDrawer
      title="Edit Job"
      icon={JobIcon}
      preferredWidth={720}
      item={open && onClose ? { onCancel: onClose } : undefined}
      destroyOnClose
    >
      <GqlQueryLoader
        query={jobQuery}
        render={data => {
          const { jobs, companyLeadSourceLinks } = data
          const jobData = bzExpect(jobs[0])
          const leadSource = bzExpect(companyLeadSourceLinks[0])
          const maintenancePlanVisits = jobData.location.maintenancePlans
            .map(mp => mp.maintenancePlanVisits)
            .flat()
          const linkedMaintenancePlanVisit = maintenancePlanVisits.find(
            mpv => mpv.jobGuid === jobData.jobGuid,
          )

          return (
            <EditJobDrawerInner
              job={{
                jobGuid: jobData.jobGuid,
                jobType: {
                  name: jobData.jobType.name,
                  jobClass: jobData.jobType.jobClass,
                  jobTypeGuid: jobData.jobType.jobTypeGuid,
                  description: jobData.jobType.description,
                  isOpportunity: jobData.jobType.isOpportunity,
                  jobLifecycleGuid: jobData.jobType.jobLifecycleGuid,
                  isPotentialHotLead: jobData.jobType.isPotentialHotLead,
                  hotLeadMinimumEquipmentAgeYears:
                    jobData.jobType.hotLeadMinimumEquipmentAgeYears,
                  opportunityConversionThresholdUsc:
                    jobData.jobType.opportunityConversionThresholdUsc,
                },
                isOpportunity: jobData.isOpportunity,
                summary: jobData.summary ?? '',
                tagGuids: jobData.tags.map(tag => tag.tagGuid),
                displayId: jobData.displayId,
                isHotLead: jobData.isHotLead,
                accountGuid: jobData.accountGuid,
                locationGuid: jobData.locationGuid,
                leadSourceGuid: leadSource.companyLeadSourceGuid,
                pointOfContactGuid: jobData.pointOfContactGuid,
                installProjectType:
                  jobData.installProjectType as InstallProjectType,
                equipmentTypeJobLinks: jobData.equipmentTypeJobLinks.map(
                  ({
                    equipmentType,
                    relationshipType,
                    estimatedEquipmentAge,
                  }) => ({
                    equipmentType,
                    relationshipType,
                    estimatedEquipmentAge,
                  }),
                ),
                isMembershipOpportunity: jobData.isMembershipOpportunity,
                useMaintenancePlanCredit: jobData.useMaintenancePlanCredit,
                maintenancePlanVisitGuid:
                  linkedMaintenancePlanVisit?.maintenancePlanVisitGuid,
                customerPurchaseOrderNumber:
                  jobData.customerPurchaseOrderNumber,
                isMembershipRenewalOpportunity:
                  jobData.isMembershipRenewalOpportunity,
                leadSourceReferringContactGuid: leadSource.referringContactGuid,
                leadSourceAttributionDescription:
                  leadSource.attributionDescription,
                workCompletedAt: jobData.workCompletedAt,
              }}
              onClose={onClose}
              onJobUpdateSuccess={onJobUpdateSuccess}
            />
          )
        }}
      />
    </BzDrawer>
  )
}

export const EditJobDrawerInner: React.FC<
  Pick<EditJobModalProps, 'onClose' | 'onJobUpdateSuccess'> & {
    job: EditJobViewModel
  }
> = ({ onClose, job, onJobUpdateSuccess }) => {
  const companyGuid = useExpectedCompany().companyGuid
  const jobUpdateMutation = trpc.jobs['jobs:update'].useMutation()
  const accountQuery = useFetchAccountByAccountGuidQuery({
    accountGuid: job.accountGuid,
  })

  const jobTypesQuery = trpc.jobTypes['job-types:get'].useQuery()

  const validJobTypes = useMemo(() => {
    const currentJobType = job.jobType
    // Only allow them to change the job type to a job type with the same lifecycle. Otherwise the current status of the
    // job will not belong to the new lifecycle.
    return (jobTypesQuery.data ?? []).filter(
      jobType =>
        currentJobType.jobTypeGuid === jobType.jobTypeGuid ||
        (jobType.jobLifecycleGuid === currentJobType.jobLifecycleGuid &&
          isNullish(jobType.archivedAt)),
    )
  }, [job.jobType, jobTypesQuery.data])

  const [tagsQuery] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const fetchCompanyLeadSourcesQuery = trpc.leadAttribution[
    'company-lead-sources:fetch'
  ].useQuery({ companyGuid })

  const [form] = useForm<CreateOrEditNewJobFormSchema>()

  const updateJobViaApi = useCallback(
    (values: onFormSubmitValues) => {
      const {
        installProjectType,
        jobTypeGuid,
        summary,
        pointOfContactGuid,
        isOpportunity,
        isHotLead,
        isMembershipOpportunity,
        isMembershipRenewalOpportunity,
        leadSourceGuid,
        leadSourceReferringContactGuid,
        leadSourceAttributionDescription,
        tags,
        useMaintenancePlanCredit,
        customerPurchaseOrderNumber,
        workCompletedAt,
      } = values

      jobUpdateMutation.mutate(
        {
          jobGuid: job.jobGuid,
          jobTypeGuid,
          installProjectType,
          summary,
          equipmentTypeJobLinks: makeEquipmentTypeJobLinks(
            values,
            bzExpect(
              jobTypesQuery.data?.find(jt => jt.jobTypeGuid === jobTypeGuid),
            ),
          ),
          pointOfContactGuid,
          // These fields can be undefined if the form items are not shown (e.g. if the
          // technicianPerformance FF is off). In this case, default them to false. These
          // defaults can potentially be removed in the future
          isOpportunity: isOpportunity ?? false,
          isHotLead: isHotLead ?? false,
          isMembershipOpportunity: isMembershipOpportunity ?? false,
          isMembershipRenewalOpportunity:
            isMembershipRenewalOpportunity ?? false,
          companyLeadSource: {
            leadSourceGuid,
            leadSourceReferringContactGuid,
            leadSourceAttributionDescription,
          },
          tags: tags?.map(tagGuid => ({ jobGuid: job.jobGuid, tagGuid })) ?? [],
          useMaintenancePlanCredit: useMaintenancePlanCredit ?? false,
          customerPurchaseOrderNumber,
          workCompletedAt,
        },
        {
          onSuccess: () => {
            onJobUpdateSuccess()
          },
        },
      )
    },
    [jobUpdateMutation, job.jobGuid, jobTypesQuery.data, onJobUpdateSuccess],
  )

  const initialValues = useMemo(() => {
    const values: Partial<CreateOrEditNewJobFormSchema> &
      Required<
        Pick<
          CreateOrEditNewJobFormSchema,
          'preexistingEquipmentInfo' | 'newEquipmentInfo'
        >
      > = {
      locationGuid: job.locationGuid,
      jobTypeGuid: job.jobType.jobTypeGuid,
      installProjectType: job.installProjectType,
      summary: job.summary,
      pointOfContactGuid: job.pointOfContactGuid,
      isOpportunity: job.isOpportunity,
      isHotLead: job.isHotLead,
      isMembershipOpportunity: job.isMembershipOpportunity,
      isMembershipRenewalOpportunity: job.isMembershipRenewalOpportunity,
      leadSourceGuid: job.leadSourceGuid ?? '',
      leadSourceReferringContactGuid:
        job.leadSourceReferringContactGuid ?? undefined,
      leadSourceAttributionDescription: job.leadSourceAttributionDescription,
      preexistingEquipmentInfo: [],
      newEquipmentInfo: [],
      shouldCreateAppointmentAfterwards: true,
      tags: job.tagGuids,
      useMaintenancePlanCredit: job.useMaintenancePlanCredit,
      maintenancePlanVisitGuid: job.maintenancePlanVisitGuid,
      customerPurchaseOrderNumber: job.customerPurchaseOrderNumber,
      workCompletedAt: job.workCompletedAt,
    }

    for (const link of job.equipmentTypeJobLinks) {
      if (link.relationshipType === 'INSTALLING') {
        values.newEquipmentInfo.push(link.equipmentType)
      } else {
        values.preexistingEquipmentInfo.push(link.equipmentType)
        values[`equipment-age-${link.equipmentType}`] =
          link.estimatedEquipmentAge
      }
    }

    return values
  }, [job])

  if (jobUpdateMutation.isError) {
    return <>An error occurred editing the job ${jobUpdateMutation.error}</>
  }

  if (
    accountQuery.isLoading ||
    !accountQuery.data ||
    jobUpdateMutation.isLoading ||
    tagsQuery.fetching ||
    fetchCompanyLeadSourcesQuery.isLoading
  ) {
    return (
      <div className="center-children-vh flex min-h-[500px]">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <CreateOrEditJobForm
      formMode="edit"
      account={accountQuery.data}
      companyGuid={accountQuery.data.companyGuid}
      onCancel={onClose}
      onFormSubmit={updateJobViaApi}
      initialValues={initialValues}
      createOrEditJobForm={form}
      hiddenFields={['shouldCreateAppointmentAfterwards']}
      jobTypes={validJobTypes}
      jobTags={tagsQuery.data?.tags ?? []}
      leadSources={fetchCompanyLeadSourcesQuery?.data ?? []}
    />
  )
}
