import { EstimateV2Status, R } from '@breezy/shared'
import {
  faChevronRight,
  faDownload,
  faFileCheck,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'urql'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../../adam-components/OnsiteModal/ActionsModalContent'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { useDownloadableInvoice } from '../../../components/DownloadableInvoice/useDownloadableInvoice'
import { BaseLoadingSpinner } from '../../../components/LoadingSpinner'
import { useIsTechApp } from '../../../providers/AppContextWrapper'
import { useStrictContext } from '../../../utils/react-utils'
import { DELETE_ESTIMATE_QUERY } from '../EstimatesFlow.gql'
import {
  EstimateDataContext,
  EstimatesContext,
  FetchedEstimate,
  getEstimateV2StatusDisplayInfo,
  hasSpecialMarkAsBehavior,
  useEstimateStatusUpdater,
} from '../estimatesFlowUtils'
import { DownloadableEstimate } from './DownloadableEstimate'
import { SelectOptionForm } from './SelectOptionForm'

import { OnsiteEmbeddedContext } from '../../../adam-components/OnsitePage/onsitePageUtils'
type StatusChangeButtonProps = {
  currentStatus?: EstimateV2Status
  status: EstimateV2Status
  onChange: (status: EstimateV2Status) => void
}

const StatusChangeButton = React.memo<StatusChangeButtonProps>(
  ({ currentStatus, status, onChange }) => {
    const [showConfirm, setShowConfirm] = useState(false)

    const [updateEstimateStatus, loading] =
      useEstimateStatusUpdater(currentStatus)

    const hasSpecialBehavior = useMemo(
      () => hasSpecialMarkAsBehavior(status),
      [status],
    )

    const changeStatus = useCallback(async () => {
      await updateEstimateStatus(status, true)
      onChange(status)
    }, [onChange, status, updateEstimateStatus])

    const onOptionClick = useCallback(() => {
      if (hasSpecialBehavior) {
        onChange(status)
      } else {
        setShowConfirm(R.not)
      }
    }, [onChange, hasSpecialBehavior, status])

    const statusInfo = getEstimateV2StatusDisplayInfo(status)

    return (
      <div className="flex flex-row">
        <div
          className={classNames(
            'flex flex-1 cursor-pointer flex-row rounded px-2 py-1',
            statusInfo.colorClassNames,
          )}
          onClick={onOptionClick}
        >
          <div className="ml-2 flex-1 self-center font-semibold">
            {statusInfo.label}
          </div>
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </div>

        <div
          className={classNames(
            'flex flex-row overflow-hidden transition-all *:ml-2',
            showConfirm ? 'max-w-[240px]' : 'max-w-0',
          )}
        >
          <Button
            size="large"
            onClick={() => setShowConfirm(false)}
            loading={loading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={changeStatus}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    )
  },
)

type EstimateActionsModalProps = {
  onCancel: () => void
  canDelete?: boolean
  markAsOptions?: EstimateV2Status[]
  estimate?: FetchedEstimate
}

export const EstimateActionsModal = React.memo<EstimateActionsModalProps>(
  ({ onCancel, canDelete, markAsOptions, estimate }) => {
    const isTechApp = useIsTechApp()

    const { estimateGuid, accountGuid, companyName, disclaimer } =
      useStrictContext(EstimatesContext)

    const { defaultOnDeleteTo } = useContext(OnsiteEmbeddedContext)

    const estimateData = useStrictContext(EstimateDataContext)
    const { options } = estimateData
    const navigate = useNavigate()
    const [mode, setMode] = useState<
      'base' | 'delete' | 'accept' | 'changeSelectedOption'
    >('base')

    const [{ fetching: isDeleting }, deleteEstimate] = useMutation(
      DELETE_ESTIMATE_QUERY,
    )

    const onDelete = useCallback(async () => {
      const res = await deleteEstimate({
        estimateGuid,
      })

      if (!res.error) {
        navigate(
          isTechApp && estimate?.jobAppointmentGuid
            ? `/appointments/${estimate.jobAppointmentGuid}`
            : defaultOnDeleteTo ?? '/',
        )
      }
    }, [
      defaultOnDeleteTo,
      deleteEstimate,
      estimate?.jobAppointmentGuid,
      estimateGuid,
      isTechApp,
      navigate,
    ])

    const onStatusChange = useCallback(
      (status: EstimateV2Status) => {
        if (hasSpecialMarkAsBehavior(status)) {
          switch (status) {
            case 'ACCEPTED':
              setMode('accept')
              break
          }
        } else {
          onCancel()
        }
      },
      [onCancel],
    )

    const downloadableElementRef = useRef<HTMLDivElement>(null)

    const [downloadEstimate, estimateDownloading] = useDownloadableInvoice(
      downloadableElementRef,
    )

    const onDownloadPdf = useCallback(() => {
      downloadEstimate({
        fileName: `estimate-${estimate?.displayId}`,
      })
    }, [downloadEstimate, estimate?.displayId])

    const isAccepted = estimate?.status === 'ACCEPTED'

    const modalContentInfo = useMemo<
      | {
          header: string
          headerBordered: boolean
          content: React.ReactNode
          footer?: React.ReactNode
          onBack?: () => void
          fullOverride?: undefined
        }
      | {
          header?: never
          headerBordered?: never
          content?: never
          footer?: never
          onBack?: never
          fullOverride: JSX.Element
        }
    >(() => {
      const backToBase = () => setMode('base')
      switch (mode) {
        case 'base':
          return {
            header: 'Estimate actions',
            headerBordered: true,
            content: (
              <ActionsModalContent>
                {isAccepted && options.length > 1 && (
                  <ActionsModalAction
                    hasNext
                    icon={<FontAwesomeIcon icon={faFileCheck} />}
                    onClick={() => setMode('changeSelectedOption')}
                  >
                    Change selected option
                  </ActionsModalAction>
                )}
                {estimate && (
                  <ActionsModalAction
                    className={
                      estimateDownloading
                        ? 'pointer-events-none cursor-wait'
                        : ''
                    }
                    icon={
                      estimateDownloading ? (
                        <BaseLoadingSpinner size={7} />
                      ) : (
                        <FontAwesomeIcon icon={faDownload} />
                      )
                    }
                    onClick={onDownloadPdf}
                  >
                    Download PDF
                  </ActionsModalAction>
                )}
                {markAsOptions && (
                  <div
                    className={classNames(
                      'border-0 border-solid border-bz-gray-400 py-4',
                      canDelete ? 'border-y' : 'border-t',
                    )}
                  >
                    <div className="mb-4 text-base font-semibold">
                      Mark as...
                    </div>
                    <div className="space-y-2">
                      {markAsOptions.map(status => (
                        <StatusChangeButton
                          key={status}
                          currentStatus={estimate?.status}
                          status={status}
                          onChange={onStatusChange}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {canDelete && (
                  <ActionsModalAction
                    danger
                    onClick={() => setMode('delete')}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </ActionsModalAction>
                )}
              </ActionsModalContent>
            ),
          }
        case 'delete':
          return {
            header: 'Delete estimate?',
            headerBordered: false,
            onBack: backToBase,
            content: <div>Are you sure you want to delete this estimate?</div>,
            footer: (
              <OnsiteModalFooter
                danger
                onCancel={backToBase}
                onSubmit={onDelete}
                cancelText="Back"
                submitText="Delete Estimate"
                submitIsLoading={isDeleting}
              />
            ),
          }

        case 'accept':
        case 'changeSelectedOption':
          return {
            fullOverride: (
              <SelectOptionForm
                onClose={onCancel}
                onCancel={() => setMode('base')}
                onBack={() => setMode('base')}
                changeMode={mode === 'changeSelectedOption'}
                options={options}
                estimateGuid={estimateGuid}
                accountGuid={accountGuid}
                // I know that I can only get to this view if the estimate exists
                displayId={estimate?.displayId ?? 0}
                contactFirstName={estimate?.job.pointOfContact.firstName ?? ''}
                emailAddress={
                  estimate?.job.pointOfContact.primaryEmailAddress?.emailAddress
                }
                phoneNumber={
                  estimate?.job.pointOfContact.primaryPhoneNumber?.phoneNumber
                }
              />
            ),
          }
      }
    }, [
      mode,
      isAccepted,
      options,
      estimate,
      estimateDownloading,
      onDownloadPdf,
      markAsOptions,
      canDelete,
      onDelete,
      isDeleting,
      onCancel,
      estimateGuid,
      accountGuid,
      onStatusChange,
    ])

    return (
      <>
        <OnsiteModal drawer onClose={onCancel} open>
          {modalContentInfo.fullOverride ?? (
            <OnsiteModalContent
              onClose={onCancel}
              footer={modalContentInfo.footer}
              onBack={modalContentInfo.onBack}
              header={modalContentInfo.header}
              headerBordered={modalContentInfo.headerBordered}
            >
              <div className="text-base">{modalContentInfo.content}</div>
            </OnsiteModalContent>
          )}
        </OnsiteModal>

        {estimate && (
          <DownloadableEstimate
            downloadableElementRef={downloadableElementRef}
            messageHtml={estimate.messageHtml}
            jobGuid={estimate.jobGuid}
            displayId={estimate.displayId}
            createdAt={estimate.createdAt}
            status={estimate.status}
            options={options}
            companyName={companyName}
            contactFullName={
              estimate.job.pointOfContact.fullName ?? 'the account holder'
            }
            signatureFileUrl={estimate.signatureFileUrl}
            acceptedOnBehalfByUser={estimate.acceptedOnBehalfByUser}
            acceptedAt={estimate.acceptedAt}
            disclaimer={disclaimer}
          />
        )}
      </>
    )
  },
)
