import { NoteLinkData, PhotoRecord } from '@breezy/shared'
import { faImages } from '@fortawesome/pro-solid-svg-icons'
import { useMemo, useState } from 'react'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { m } from '../../utils/react-utils'
import { AsyncPhotoUpload, AsyncTakePhoto } from '../Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../Upload/Upload'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'

type PhotoPickerDrawerProps = {
  photos: PhotoRecord[]
  photosSelected?: { photoGuid: string; cdnUrl: string }[]
  linkData?: NoteLinkData
  onPhotosUpdated?: () => void
  onPhotosSelected?: (photos: { photoGuid: string; cdnUrl: string }[]) => void
  onClose?: () => void
}

const PhotoPickerDrawer = m(
  ({
    photos,
    photosSelected,
    linkData,
    onPhotosUpdated,
    onPhotosSelected,
    onClose,
  }: PhotoPickerDrawerProps) => {
    const [selectedPhotos, setSelectedPhotos] = useState<
      { photoGuid: string; cdnUrl: string }[]
    >(photosSelected ?? [])

    const links = useMemo(
      () =>
        linkData
          ? {
              locationGuid:
                linkData.primaryNoteType === 'LOCATION'
                  ? linkData.locationGuid
                  : undefined,
              accountGuid:
                linkData.primaryNoteType === 'ACCOUNT'
                  ? linkData.accountGuid
                  : undefined,
              jobGuid:
                linkData.primaryNoteType === 'JOB'
                  ? linkData.jobGuid
                  : undefined,
              apptAssignmentGuid:
                linkData.primaryNoteType === 'ASSIGNMENT'
                  ? linkData.apptAssignmentGuid
                  : undefined,
              jobAppointmentGuid:
                linkData.primaryNoteType === 'JOB_APPOINTMENT'
                  ? linkData.jobAppointmentGuid
                  : undefined,
            }
          : undefined,
      [linkData],
    )

    const onPhotoUploadChange = useSynchronousUpload(onPhotosUpdated)

    return (
      <BzDrawer
        title="Select A Photo"
        icon={faImages}
        item={onClose ? { onCancel: () => onClose() } : undefined}
        footer={
          <FormCancelSubmitButtons
            bypassFormSubmit
            primaryButtonText="Select Photo(s)"
            onSubmit={() => {
              onPhotosSelected?.(selectedPhotos)
              onClose?.()
            }}
            onCancel={onClose}
          />
        }
      >
        <div className="flex h-full flex-col">
          <div className="grid w-full grid-flow-row grid-cols-2 flex-col gap-2 overflow-x-hidden overflow-y-scroll">
            {photos.map(photo => {
              const selectedPhotoIdx = selectedPhotos.findIndex(
                curr => curr.photoGuid === photo.photoGuid,
              )

              return (
                <div
                  key={photo.photoGuid}
                  className="flex flex-col items-center rounded hover:cursor-pointer"
                  style={
                    selectedPhotoIdx >= 0
                      ? {
                          borderStyle: 'solid',
                          borderWidth: '0.2rem',
                          borderColor: '#3b82f6',
                        }
                      : {
                          borderStyle: 'solid',
                          borderWidth: '0.15rem',
                          borderColor: '#e5e7eb',
                        }
                  }
                  onClick={() => {
                    if (selectedPhotoIdx >= 0) {
                      const photos = [...selectedPhotos]
                      photos.splice(selectedPhotoIdx, 1)
                      setSelectedPhotos([...photos])
                      return
                    }

                    setSelectedPhotos([...selectedPhotos, photo])
                  }}
                >
                  <img src={photo.cdnUrl} alt="" />
                </div>
              )
            })}
          </div>

          {links && (
            <div className="mt-auto space-y-3">
              <AsyncPhotoUpload
                links={links}
                onPhotoUploadChange={onPhotoUploadChange}
              />
              <AsyncTakePhoto
                links={links}
                onPhotoUploadChange={onPhotoUploadChange}
              />
            </div>
          )}
        </div>
      </BzDrawer>
    )
  },
)

export default PhotoPickerDrawer
