import { z } from 'zod'

export const JOB_BUSINESS_EVENT_TYPES = ['JOB_LIFECYCLE_STATUS_CHANGED'] as const
export const jobBusinessEventTypeSchema = z.enum(JOB_BUSINESS_EVENT_TYPES)
export type JobBusinessEventType = z.infer<typeof jobBusinessEventTypeSchema>

export type BusinessEventType =
  | 'APPOINTMENT_SCHEDULED'
  | 'APPOINTMENT_CONFIRMED'
  | 'NOTE_CREATED'
  | 'ESTIMATE_ACCEPTED'
  | 'ESTIMATE_REJECTED'
  | 'ESTIMATE_DELETED'
  | 'MAINTENANCE_PLAN_ACTIVATED'
  | 'MAINTENANCE_PLAN_CANCELED'
  | 'MAINTENANCE_PLAN_CHANGED'
  | 'MAINTENANCE_PLAN_LAPSED'
  | 'MAINTENANCE_PLAN_EXPIRED'
  | 'ESTIMATE_CREATED'
  | 'INVOICE_CREATED'
  | JobBusinessEventType
