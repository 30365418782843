import { gql } from '../../generated'

export const ACCOUNT_REMINDERS_QUERY = gql(/* GraphQL */ `
  query AccountReminders($accountGuid: uuid!, $companyGuid: uuid!) {
    accountReminders(
      where: {
        accountGuid: { _eq: $accountGuid }
        reminder: { companyGuid: { _eq: $companyGuid } }
      }
      orderBy: [{ reminder: { status: DESC } }, { reminder: { dueAt: ASC } }]
    ) {
      reminder {
        reminderGuid
        status
        description
        dueAt
        updatedAt
        createdByUser {
          userGuid
          firstNameInitial
        }
        reminderAssignments {
          user {
            userGuid
            firstNameInitial
          }
        }
      }
    }
  }
`)

export const DISTINCT_REMINDER_DESCRIPTIONS_QUERY = gql(/* GraphQL */ `
  query DistinctReminderDescriptions {
    remindersAggregate(distinctOn: description) {
      nodes {
        description
      }
    }
  }
`)

export const UPDATE_REMINDER_STATUS_MUTATION = gql(/* GraphQL */ `
  mutation UpdateReminderStatus(
    $reminderGuid: uuid!
    $status: ReminderStatusTypesEnum!
  ) {
    updateRemindersByPk(
      pkColumns: { reminderGuid: $reminderGuid }
      _set: { status: $status }
    ) {
      reminderGuid
      status
    }
  }
`)
