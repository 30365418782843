import {
  BzAddress,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  Location,
  getMostRelevantMaintenancePlanMinimal,
} from '@breezy/shared'
import { useMemo } from 'react'
import AddressMultiLineView from '../../components/Addresses/AddressMultiLineView/AddressMultiLineView'
import MaintenancePlanVipIcon from '../../components/MaintenancePlanVipIcon/MaintenancePlanVipIcon'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { EditButton } from '../../elements/EditButton/EditButton'
import { EmDash } from '../../elements/EmDash/EmDash'
import { MaybeDataPoint } from '../../elements/MaybeDataPoint/MaybeDataPoint'
import { useMapLink } from '../../hooks/useMapLink'
import { useExpectedLocationMunicipalityFieldEnabledForCompany } from '../../providers/PrincipalUser'
import { getGoogleMapScreenshotSrc } from '../../utils/GoogleMapsPlacesUtils'
import { m } from '../../utils/react-utils'

type LocationInfoCardProps = {
  location: Location
  createdAtString: string
  onEdit?: () => void
  editable?: boolean
}

const LocationInfoCard = m<LocationInfoCardProps>(
  ({ location, createdAtString, onEdit, editable = true }) => {
    const municipalityFieldEnabled =
      useExpectedLocationMunicipalityFieldEnabledForCompany()

    const bzAddress = useMemo(() => {
      return new BzAddress(
        location.address.line1,
        location.address.line2,
        location.address.city,
        location.address.stateAbbreviation,
        location.address.zipCode,
      )
    }, [location])

    const [mapsButtonUrl, mapsButtonLabel] = useMapLink(location.address)

    return (
      <div className="flex flex-col space-y-6">
        <div className="flex w-full justify-between">
          <div className="semibold_20_28 text-bz-gray-900">
            <AddressMultiLineView
              address={BzAddress.create(location.address)}
              labelClassName="semibold_20_28 text-bz-gray-900"
            />
          </div>
          <div className="row space-x-3">
            <MaintenancePlanVipIcon
              maintenancePlan={getMostRelevantMaintenancePlanMinimal(
                location.maintenancePlans,
              )}
            />
            {editable && onEdit && <EditButton onClick={onEdit} />}
          </div>
        </div>
        <img
          className="border-1 rounded-lg border-solid border-bz-gray-500"
          src={getGoogleMapScreenshotSrc(bzAddress)}
          alt="Google Maps"
        />
        <div className="flex justify-between space-x-1">
          <VerticalKeyValue
            pair={{
              key: 'Created on',
              value: createdAtString,
            }}
          />
          <a
            className="semibold_14_22 text-bz-primary hover:cursor-pointer"
            href={mapsButtonUrl}
            target="_blank"
            rel="noreferrer"
          >
            {mapsButtonLabel}
          </a>
        </div>
        <div className="flex justify-between space-x-1">
          <VerticalKeyValue
            pair={{
              key: 'Property Built In',
              value: location.estimatedBuildDate?.format(
                DateTimeFormatter.ofPattern('yyyy').withLocale(ENGLISH_LOCALE),
              ) ?? <EmDash />,
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Est. Square Footage',
              value: location.estimatedSquareFootage ? (
                location.estimatedSquareFootage + ' sq. ft'
              ) : (
                <EmDash />
              ),
            }}
          />
        </div>
        {municipalityFieldEnabled || location.municipality ? (
          <div className="flex">
            <VerticalKeyValue
              pair={{
                key: 'Municipality',
                value: <MaybeDataPoint>{location.municipality}</MaybeDataPoint>,
              }}
            />
          </div>
        ) : null}
      </div>
    )
  },
)

export default LocationInfoCard
