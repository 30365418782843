import { lastNameSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

type LastNameFieldProps = {
  lastName?: string
  labelClassName?: string
}

export const LastNameField: React.FC<LastNameFieldProps> = ({
  lastName,
  labelClassName = '',
}) => (
  <Form.Item
    name="lastName"
    label="Last Name"
    className={labelClassName}
    initialValue={lastName}
    rules={[
      {
        required: true,
        validator: async (_, value) => {
          if (!value) {
            throw new Error('Last name is required')
          }

          const result = lastNameSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <Input
      defaultValue={lastName}
      autoComplete="family-name"
      name="lastName"
      id="lastName"
    />
  </Form.Item>
)
