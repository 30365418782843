import {
  AccountGuid,
  AccountGuidContainer,
  BzDateFns,
  CardOnFile,
  getCardOnFileDisplayName,
  PaymentMethodDisplayNames,
} from '@breezy/shared'
import React, { useCallback, useState } from 'react'
import { ActionItem } from '../../../elements/ActionItems/ActionItems'
import { CardOnFileStatusTag } from '../../CardOnFileStatusTag/CardOnFileStatusTag'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem, {
  ContentListItem,
} from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

import { convertPaymentMethodRecordToCardOnFile } from '@breezy/backend/src/application-types'
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons'
import { OnsiteConfirmModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { useFetchCardsOnFile } from '../../../hooks/fetch/useFetchCardsOnFile'
import { trpc } from '../../../hooks/trpc'
import { useExpectedTilledMerchantId } from '../../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../../providers/PrincipalUser'
import { useModalState } from '../../../utils/react-utils'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'
import AddPaymentMethodModal from '../../PaymentWorkflow/components/AddPaymentMethodModal/AddPaymentMethodModal'
import EditPaymentMethodModal from '../../PaymentWorkflow/components/EditPaymentMethodModal/EditPaymentMethodModal'
type CardOnFileCollapsibleProps = {
  accountGuid: AccountGuid
  cardsOnFile: CardOnFile[]
  editable: boolean
  refetch: () => void
}

const useSelectCardOnFile = (initialValue?: CardOnFile | undefined) => {
  const [selectedCardOnFile, setSelectedCardOnFileInner] = useState<
    CardOnFile | undefined
  >(initialValue)
  const reset = useCallback(() => {
    setSelectedCardOnFileInner(undefined)
  }, [setSelectedCardOnFileInner])

  const setSelectedCardOnFile = useCallback(
    (cardOnFile: CardOnFile) => {
      setSelectedCardOnFileInner(cardOnFile)
    },
    [setSelectedCardOnFileInner],
  )

  return [selectedCardOnFile, setSelectedCardOnFile, reset] as const
}
export const CardOnFileCollapsible = React.memo<CardOnFileCollapsibleProps>(
  ({ accountGuid, cardsOnFile, editable, refetch }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const [addCardOnFileOpen, showAddCardOnFile, hideAddCardOnFile] =
      useModalState(false)

    const [editingCardOnFile, setEditingCardOnFile, resetEditingCardOnFile] =
      useSelectCardOnFile()
    const onEditCardOnFile = useCallback(
      (cardOnFile: CardOnFile) => {
        setEditingCardOnFile(cardOnFile)
      },
      [setEditingCardOnFile],
    )

    const [removingCardOnFile, setRemovingCardOnFile, resetRemovingCardOnFile] =
      useSelectCardOnFile()
    const onRemoveCardOnFile = useCallback(
      (cardOnFile: CardOnFile) => {
        setRemovingCardOnFile(cardOnFile)
      },
      [setRemovingCardOnFile],
    )

    const getActionItemsForCardOnFile = (
      cardOnFile: CardOnFile,
    ): ActionItem[] => [
      {
        title: 'Edit Display Name',
        onClick: () => onEditCardOnFile(cardOnFile),
      },
      {
        title: cardOnFile.hasPaymentSubscription
          ? 'Remove Card (Tied to Maintenance Plan)'
          : 'Remove Card',
        disabled: cardOnFile.hasPaymentSubscription,
        onClick: () => onRemoveCardOnFile(cardOnFile),
      },
    ]

    const generateContentList = useCallback(
      (cardOnFile: CardOnFile): ContentListItem[] => {
        const contentList: ContentListItem[] = [
          {
            key: 'Type:',
            value: (
              <div className="break-all">
                {PaymentMethodDisplayNames[cardOnFile.paymentMethodType]}
              </div>
            ),
          },
        ]

        if (cardOnFile.paymentMethodBillingInfo?.name) {
          contentList.push({
            key: 'Name:',
            value: <div>{cardOnFile.paymentMethodBillingInfo?.name}</div>,
          })
        }

        if (
          cardOnFile.paymentMethodCardMetadata?.expMonth &&
          cardOnFile.paymentMethodCardMetadata?.expYear
        ) {
          contentList.push({
            key: 'Expiration:',
            value: (
              <div>
                {cardOnFile.paymentMethodCardMetadata?.expMonth}/
                {cardOnFile.paymentMethodCardMetadata?.expYear}
              </div>
            ),
          })
        }

        if (cardOnFile.createdAt) {
          contentList.push({
            key: 'Date Added:',
            value: (
              <div>
                {BzDateFns.format(
                  BzDateFns.parseISO(cardOnFile.createdAt, tzId),
                  'MMM d, yyyy',
                )}
              </div>
            ),
          })
        }

        return contentList
      },
      [tzId],
    )

    return (
      <>
        <BzCollapsible
          title="Payment Methods"
          defaultOpen
          titleIcon={faCreditCardFront}
          onPlus={editable ? () => showAddCardOnFile() : undefined}
        >
          {cardsOnFile.map((cardOnFile, i) => {
            return (
              <div
                key={cardOnFile.paymentMethodRecordGuid}
                className={i !== 0 ? 'mt-2' : ''}
              >
                <CollapsibleItem
                  alwaysOpen={true}
                  key={cardOnFile.paymentMethodRecordGuid}
                  title={getCardOnFileDisplayName(cardOnFile)}
                  tag={<CardOnFileStatusTag cardOnFile={cardOnFile} />}
                  actionItems={
                    editable
                      ? getActionItemsForCardOnFile(cardOnFile)
                      : undefined
                  }
                  contentList={generateContentList(cardOnFile)}
                />
              </div>
            )
          })}
        </BzCollapsible>
        {addCardOnFileOpen && (
          <AddPaymentMethodModal
            accountGuid={accountGuid}
            onClose={() => hideAddCardOnFile()}
            refetch={refetch}
          />
        )}
        {editingCardOnFile && (
          <EditPaymentMethodModal
            cardOnFile={editingCardOnFile}
            onClose={() => {
              resetEditingCardOnFile()
            }}
            refetch={refetch}
          />
        )}
        {removingCardOnFile && (
          <RemoveCardOnFileModal
            cardOnFile={removingCardOnFile}
            onClose={() => resetRemovingCardOnFile()}
            onSubmit={refetch}
          />
        )}
      </>
    )
  },
)

export const CardOnFileCollapsibleLoader = React.memo<
  AccountGuidContainer & { editable: boolean }
>(({ accountGuid, editable }) => {
  const { cardOnFileQuery, refetch } = useFetchCardsOnFile(accountGuid)

  return (
    <GqlQueryLoader
      query={cardOnFileQuery}
      render={data => {
        return (
          <div className="mt-3">
            <CardOnFileCollapsible
              accountGuid={accountGuid}
              cardsOnFile={
                data?.paymentMethodRecords.map(
                  convertPaymentMethodRecordToCardOnFile,
                ) ?? []
              }
              editable={editable}
              refetch={refetch}
            />
          </div>
        )
      }}
    />
  )
})

type RemoveCardOnFileModalProps = {
  cardOnFile: CardOnFile
  onSubmit: () => void
  onClose: () => void
}
const RemoveCardOnFileModal = React.memo<RemoveCardOnFileModalProps>(
  ({ onClose, onSubmit, cardOnFile }) => {
    const deletePaymentMethodMutation =
      trpc.payments['payments:payment-method:delete'].useMutation()
    const companyGuid = useExpectedCompanyGuid()
    const merchantId = useExpectedTilledMerchantId()
    const onConfirm = useCallback(async () => {
      await deletePaymentMethodMutation.mutateAsync({
        paymentMethodRecordGuid: cardOnFile.paymentMethodRecordGuid,
        companyGuid,
        merchantId,
      })
      onSubmit()
      onClose()
    }, [
      onClose,
      onSubmit,
      cardOnFile,
      deletePaymentMethodMutation,
      companyGuid,
      merchantId,
    ])

    return (
      <OnsiteConfirmModal
        danger
        onCancel={onClose}
        onConfirm={onConfirm}
        header="Remove Card On File?"
        confirmText="Yes, Exit"
        confirmDisabled={deletePaymentMethodMutation.isLoading}
      >
        <>
          After removing a card on file, you will no longer be able to use it
          for future payments.
        </>
      </OnsiteConfirmModal>
    )
  },
)
