import { AccountContact, BzContact, Guid } from '@breezy/shared'
import { faEdit, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import BzRadioButton from '../../../../elements/BzRadioButton/BzRadioButton'
import { useCanManageAccount } from '../../../../hooks/permission/useCanManageAccount'
import { CreateOrEditNewContactForm } from '../../../Contacts/CreateOrEditNewContactForm/CreateOrEditNewContactForm'
import { LoadingSpinner } from '../../../LoadingSpinner'

export type ContactRadioSelectionProps = {
  accountGuid: Guid
  accountContacts: AccountContact[]
  refetchAccountContacts: () => void
  selectedContactGuid: string | undefined
  setSelectedContactGuid: (contactGuid: string) => void
  title: React.ReactNode
  description: React.ReactNode
  editable?: boolean
  showAdditionalPhoneNumber?: boolean
  bypassFormSubmit?: boolean
  disabledContactGuids?: string[]
  disabledExtra?: React.ReactNode
}

const ContactRadioSelection = ({
  accountGuid,
  accountContacts,
  refetchAccountContacts,
  selectedContactGuid,
  setSelectedContactGuid,
  title,
  description,
  editable = true,
  showAdditionalPhoneNumber = true,
  bypassFormSubmit = false,
  disabledContactGuids,
  disabledExtra,
}: ContactRadioSelectionProps) => {
  const [addingNewContact, setAddingNewContact] = useState(false)
  const [editingContacts, setEditingContacts] = useState<string[]>([])

  const { canManage, isLoading } = useCanManageAccount(accountGuid)

  const removeContactFromEditingContacts = useCallback(
    (contactGuid: string) => {
      setEditingContacts(previousEditingContacts =>
        previousEditingContacts.filter(
          editingContactGuid => editingContactGuid !== contactGuid,
        ),
      )
    },
    [],
  )

  return (
    <div>
      <div className="mb-3">
        <div className="semibold_16_24 grey9">{title}</div>
        <div className="semibold_14_22 gray7 font-normal">{description}</div>
      </div>
      <div className="mb-4 flex flex-col space-y-3">
        {accountContacts
          .filter(ac => !ac.archived)
          .map(accountContact =>
            editingContacts.includes(accountContact.contact.contactGuid) ? (
              <div key={accountContact.contact.contactGuid}>
                <CreateOrEditNewContactForm
                  accountGuid={accountGuid}
                  editingAccountContact={accountContact}
                  showDivider={false}
                  justifyFormCancelSubmitButtons="start"
                  labelClassName="semibold_14_22 grey9"
                  flexRowSpaceX="space-x"
                  onCancelButtonPressed={() =>
                    removeContactFromEditingContacts(
                      accountContact.contact.contactGuid,
                    )
                  }
                  onAccountContactUpdated={() => {
                    refetchAccountContacts()
                    removeContactFromEditingContacts(
                      accountContact.contact.contactGuid,
                    )
                  }}
                  showAdditionalPhoneNumber={showAdditionalPhoneNumber}
                  bypassFormSubmit={bypassFormSubmit}
                />
              </div>
            ) : (
              <div className="flex" key={accountContact.contact.contactGuid}>
                <div className="col w-full">
                  <div className="row two-sides w-full">
                    <BzRadioButton
                      label={
                        <div>
                          <div className="semibold_14_22 grey9">
                            {BzContact.create(accountContact.contact).fullName}
                          </div>
                          <div className="semibold_14_22 gray7 font-normal">
                            {[
                              accountContact.contact.primaryEmailAddress
                                ?.emailAddress,
                              accountContact.contact.primaryPhoneNumber
                                ?.phoneNumber,
                            ]
                              .filter(Boolean)
                              .join(' | ')}
                          </div>
                        </div>
                      }
                      value={accountContact.contact.contactGuid}
                      checked={
                        selectedContactGuid ===
                        accountContact.contact.contactGuid
                      }
                      onChange={contactGuid =>
                        setSelectedContactGuid(contactGuid)
                      }
                      disabled={disabledContactGuids?.includes(
                        accountContact.contact.contactGuid,
                      )}
                    />
                    {disabledContactGuids?.includes(
                      accountContact.contact.contactGuid,
                    ) && disabledExtra}
                    {canManage && editable && (
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="gray7 h-4 w-4 hover:cursor-pointer"
                        onClick={() =>
                          setEditingContacts(previousEditingContacts =>
                            previousEditingContacts.concat(
                              accountContact.contact.contactGuid,
                            ),
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ),
          )}
        {addingNewContact ? (
          <CreateOrEditNewContactForm
            accountGuid={accountGuid}
            showDivider={false}
            justifyFormCancelSubmitButtons="start"
            labelClassName="semibold_14_22 grey9"
            flexRowSpaceX="space-x"
            onCancelButtonPressed={() => setAddingNewContact(false)}
            onAccountContactAdded={() => {
              refetchAccountContacts()
              setAddingNewContact(false)
            }}
            showAdditionalPhoneNumber={showAdditionalPhoneNumber}
            bypassFormSubmit={bypassFormSubmit}
          />
        ) : isLoading ? (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : canManage ? (
          <div
            className="flex items-center space-x-[10px] hover:cursor-pointer"
            onClick={() => setAddingNewContact(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="blue6 h-5 w-5" />
            <div className="semibold_14_22 blue6"> Add new contact</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ContactRadioSelection
