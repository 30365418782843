import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

type ActionsModalActionProps = React.PropsWithChildren<{
  onClick: () => void
  icon: React.ReactNode
  className?: string
  danger?: boolean
  hasNext?: boolean
}>

export const ActionsModalAction = React.memo<ActionsModalActionProps>(
  ({ children, onClick, icon, className, danger, hasNext }) => {
    return (
      <div
        className={classNames(
          'flex cursor-pointer flex-row items-center text-base font-semibold',
          {
            'text-red-800': danger,
          },
          className,
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            'mr-3 flex h-11 w-11 items-center justify-center rounded-full',
            danger
              ? 'bg-red-200 text-red-800'
              : 'border border-solid border-bz-border text-bz-gray-900',
          )}
        >
          {icon}
        </div>
        <div className="flex-1">{children}</div>
        {hasNext && <FontAwesomeIcon icon={faChevronRight} />}
      </div>
    )
  },
)

type ActionsModalContentProps = React.PropsWithChildren

export const ActionsModalContent = React.memo<ActionsModalContentProps>(
  ({ children }) => {
    return <div className="space-y-5">{children}</div>
  },
)
