import { AccountGuid, AccountLocation, Location, Merge } from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons'
import { memo, useCallback, useState } from 'react'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { useMessage } from '../../../utils/antd-utils'
import CreateOrEditNewAccountLocationForm from '../../CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import { LocationCollapsibleItem } from '../LocationsCollapsible/LocationCollapsibleItem'

type AccountLocationsCollapsibleLocation = Merge<
  Omit<AccountLocation, 'location'> & {
    location: Omit<Location, 'maintenancePlans'>
  }
>

export type AccountLocationsCollapsibleProps = {
  accountGuid: AccountGuid
  accountLocations: AccountLocationsCollapsibleLocation[]
  editable?: boolean
  refetch?: () => void
  onBillingAddressSet?: (mailingAddressGuid: string) => void
}

export const AccountLocationsCollapsible =
  memo<AccountLocationsCollapsibleProps>(
    ({
      accountGuid,
      accountLocations,
      editable,
      refetch,
      onBillingAddressSet,
    }) => {
      const message = useMessage()

      const [upsertLocationFormOpen, setUpsertLocationFormOpen] =
        useState(false)
      const [accountLocationBeingEdited, setAccountLocationBeingEdited] =
        useState<AccountLocationsCollapsibleLocation | undefined>(undefined)

      const addNewLocation = useCallback(() => {
        setUpsertLocationFormOpen(true)
        setAccountLocationBeingEdited(undefined)
      }, [setUpsertLocationFormOpen])

      const onUpsertLocationDrawerClosed = useCallback(() => {
        setUpsertLocationFormOpen(false)
        setAccountLocationBeingEdited(undefined)
      }, [setUpsertLocationFormOpen])

      const onLocationAdded = useCallback(() => {
        setUpsertLocationFormOpen(false)
        message.success(`Successfully added new location to the account`)
        refetch?.()
      }, [message, refetch])

      const onLocationUpdated = useCallback(() => {
        setUpsertLocationFormOpen(false)
        setAccountLocationBeingEdited(undefined)
        message.success(`Successfully updated location`)
        refetch?.()
      }, [message, refetch])

      return (
        <>
          <BzCollapsible
            title="Locations"
            titleIcon={faLocationDot}
            defaultOpen
            onPlus={editable ? addNewLocation : undefined}
          >
            {accountLocations.map(accountLocation => {
              return (
                <LocationCollapsibleItem
                  key={accountLocation.location.locationGuid}
                  location={accountLocation.location}
                  actionItems={
                    editable && onBillingAddressSet
                      ? [
                          {
                            title: 'Set as Billing Address',
                            onClick: () =>
                              onBillingAddressSet(
                                accountLocation.location.address.addressGuid,
                              ),
                          },
                        ]
                      : undefined
                  }
                  onEdit={
                    editable
                      ? () => {
                          setAccountLocationBeingEdited(accountLocation)
                          setUpsertLocationFormOpen(true)
                        }
                      : undefined
                  }
                />
              )
            })}
          </BzCollapsible>

          {editable && (
            <BzDrawer
              title={
                accountLocationBeingEdited
                  ? 'Edit Location'
                  : 'Add new Location'
              }
              icon={faLocationDot}
              preferredWidth={720}
              item={
                upsertLocationFormOpen
                  ? { onCancel: onUpsertLocationDrawerClosed }
                  : undefined
              }
              destroyOnClose
            >
              <CreateOrEditNewAccountLocationForm
                showDivider={true}
                showCancelSubmitButtons={true}
                accountGuid={accountGuid}
                onCancelButtonPressed={onUpsertLocationDrawerClosed}
                editingLocation={accountLocationBeingEdited?.location}
                onLocationAdded={onLocationAdded}
                onLocationUpdated={onLocationUpdated}
                flexRowSpaceX="space-x"
                labelClassName="semibold_14_22 grey9"
              />
            </BzDrawer>
          )}
        </>
      )
    },
  )
