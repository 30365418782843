import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import cn from 'classnames'
import { useState } from 'react'
import { dayBreakBlue1, dayBreakBlue6, gray8 } from '../../themes/theme'

export type FaIconButtonsProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  icon: IconProp
  tooltip?: string
  size?: number
  className?: string
  style?: React.CSSProperties
  isLoading?: boolean
}

const FaIconButton = ({
  style = {},
  className,
  tooltip,
  onClick,
  icon,
  size = 16,
  isLoading = false,
}: FaIconButtonsProps) => {
  const [hovered, setHovered] = useState(false)
  const hoverStyle = hovered
    ? { backgroundColor: dayBreakBlue1, borderRadius: '50%' }
    : {}

  return (
    <Tooltip title={tooltip} mouseEnterDelay={tooltip ? 0.1 : 999999}>
      <div
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          ...hoverStyle,
          ...style,
          width: size + 16,
          height: size + 16,
          cursor: 'pointer',
        }}
        className={cn(className, 'center-children-vh')}
      >
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="animate-spin"
            style={{
              color: style.color ?? (hovered ? dayBreakBlue6 : gray8),
              fontSize: size,
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: style.color ?? (hovered ? dayBreakBlue6 : gray8),
              fontSize: size,
            }}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default FaIconButton
