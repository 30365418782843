import { z } from 'zod'
import { bzOptional } from '../common-schemas'

export const EquipmentManufacturerSchema = bzOptional(z.string())
export type EquipmentManufacturer = z.infer<typeof EquipmentManufacturerSchema>

export const KNOWN_MANUFACTURERS: string[] = [
  'AirEase',
  'ACiQ',
  'AirQuest',
  'Goodman',
  'Bryant',
  'Ducane',
  'Trane',
  'Carrier',
  'Lennox',
  'Rheem',
  'American Standard',
  'York',
  'Frigidaire',
  'Amana',
  'Coleman',
  'Goettl',
  'Daikin',
  'Bosch',
  'WaterFurnace',
]

export const EquipmentModelNumberSchema = z.string()
export type EquipmentModelNumber = z.infer<typeof EquipmentModelNumberSchema>

export const EquipmentSerialNumberSchema = z.string()
export type EquipmentSerialNumber = z.infer<typeof EquipmentSerialNumberSchema>
