import { z } from 'zod'
import { AsyncFn } from '../../../common'
import { guidSchema } from '../../../contracts/_common'
import { CompanyGuid, ForCompanyUser } from '../../Company/Company'
import { ICompanyCreate } from '../../Company/CompanyAtoms'
import { Guid, bzOptional } from '../../common-schemas'
import { PaymentRecordGuid } from './PaymentTypes'

export type ExternalRefundReasonCode = 'duplicate' | 'fraudulent' | 'requested_by_customer' | 'other'
export type PaymentRefundGuid = Guid

export type ExternalRefundRequest = {
  readonly merchantId: string
  readonly paymentRecordGuid: PaymentRecordGuid
  readonly paymentRefundGuid: PaymentRefundGuid
  readonly externalPaymentId: string
  readonly amountUsd: number
  readonly reasonCode: ExternalRefundReasonCode
  readonly reasonDescription: string
}

export enum RefundStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export type ExternalRefundId = string
export type ExternalRefundResponse = {
  readonly externalRefundId: ExternalRefundId
  readonly occurredAt: string
  readonly status: RefundStatus
}

export type IExternalPaymentRefunder = ICompanyCreate<ExternalRefundRequest, ExternalRefundResponse>

export enum RefundReasonType {
  DUPLICATE = 'DUPLICATE',
  FRAUDULENT = 'FRAUDULENT',
  REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  OTHER = 'OTHER',
}

export const refundReasonTypeDisplayName = (type: RefundReasonType) => refundReasonTypeDisplayNames[type]
const refundReasonTypeDisplayNames = {
  [RefundReasonType.DUPLICATE]: 'Duplicate',
  [RefundReasonType.FRAUDULENT]: 'Fraudulent',
  [RefundReasonType.REQUESTED_BY_CUSTOMER]: 'Requested by customer',
  [RefundReasonType.INVOICE_VOIDED]: 'Voided Invoice',
  [RefundReasonType.OTHER]: 'Other',
}

export const paymentRefundRequestSchema = z.object({
  paymentRefundGuid: guidSchema,
  paymentRecordGuid: guidSchema,
  amountUsd: z.number().min(0),
  reasonType: z.nativeEnum(RefundReasonType),
  reasonDescription: z.string(),
  status: z.nativeEnum(RefundStatus),
  externalPaymentId: bzOptional(z.string()),
  merchantId: z.string(),
  occurredAt: z.string(),
})

export type PaymentRefundRequest = z.infer<typeof paymentRefundRequestSchema> & {
  readonly externalRefundId?: string
}

export type IPaymentRefunder = AsyncFn<ForCompanyUser<PaymentRefundRequest>>

export type ExternalRefundIdsQuery = {
  readonly companyGuid: CompanyGuid
  readonly externalRefundIds: ExternalRefundId[]
}

export type PaymentRefundGuidsByExternalPaymentIdsReader = AsyncFn<
  ExternalRefundIdsQuery,
  Record<ExternalRefundId, PaymentRecordGuid>
>
