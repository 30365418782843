export const NotFound = () => (
  <div className="flex h-screen w-screen items-center justify-center">
    <div className="text-base font-semibold">
      The requested invoice cannot be found.
    </div>
  </div>
)

export const IssueLoading = () => (
  <div className="flex h-screen w-screen items-center justify-center">
    <div className="text-base font-semibold">
      There was a problem loading your invoice. Please try again later or
      request a new payment link.
    </div>
  </div>
)
