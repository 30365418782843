import { gql } from '../../../../generated'

export const TAGS_MINIMAL_FOR_JOB_QUERY = gql(/* GraphQL */ `
  query TagsMinimalForJob($companyGuid: uuid!, $jobGuid: uuid!) {
    jobTags(
      where: {
        _and: {
          jobGuid: { _eq: $jobGuid }
          tag: { companyGuid: { _eq: $companyGuid } }
        }
      }
    ) {
      tag {
        ...TagMinimal
      }
    }
  }
`)

export const ASSIGNMENT_PAUSED_QUERY = gql(/* GraphQL */ `
  subscription AppointmentDetailGetAssignmentPausedStatus(
    $companyGuid: uuid!
    $userGuid: uuid!
    $jobGuid: uuid!
    $jobAppointmentGuid: uuid!
    $jobAppointmentAssignmentGuid: uuid!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        endTime: { _isNull: true }
        timesheetEntryActivity: { activityName: { _eq: "LUNCH" } }
        timesheetEntryLinkData: {
          jobGuid: { _eq: $jobGuid }
          jobAppointmentGuid: { _eq: $jobAppointmentGuid }
          jobAppointmentAssignmentGuid: { _eq: $jobAppointmentAssignmentGuid }
        }
      }
    ) {
      companyGuid
      userGuid
      timesheetEntryGuid
    }
  }
`)

// See `targets/web-app/src/pages/AccountDetailsPage/AccountDetailsPage.gql.ts` for the `MaintenancePlanCollapsibleData`
// fragment
export const APPOINTMENT_DETAIL_ACCOUNT_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription AppointmentDetailAccount($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountGuid
      maintenancePlans {
        ...MaintenancePlanCollapsibleData
      }
      accountContacts {
        accountContactGuid
        contact {
          lastName
          firstName
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            phoneNumber
          }
        }
        primary
      }
    }
  }
`)
