import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React, { useMemo } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { AccountReminderViewProps } from '../AccountReminderView/AccountReminderView'
import { useAccountRemindersContext } from '../AccountRemindersWrapper/AccountRemindersWrapper'
import Chip from '../Chip/Chip'
import CompleteStatusIcon from '../CompleteStatusIcon/CompleteStatusIcon'
import FormattedReminderDate from '../FormattedReminderDate/FormattedReminderDate'

type AccountReminderItemProps = {
  edit: () => void
  onDelete: () => void
  isDeleting: boolean
} & AccountReminderViewProps

const AccountReminderItem = React.memo<AccountReminderItemProps>(
  ({ reminder, toggleStatus, edit, onDelete, isDeleting }) => {
    const { submitting, isEditing, isCreating } = useAccountRemindersContext()
    const tzId = useExpectedCompanyTimeZoneId()
    const isLoading = useMemo(
      () => submitting || isDeleting,
      [submitting, isDeleting],
    )

    const isModifying = useMemo(
      () => isEditing || isCreating,
      [isEditing, isCreating],
    )

    const canDelete = useMemo(
      () => !isLoading && !isModifying,
      [isLoading, isModifying],
    )

    const canEdit = useMemo(
      () => !isModifying && !isLoading && reminder.status === 'INCOMPLETE',
      [isLoading, isModifying, reminder.status],
    )

    return (
      <div className="account-reminder-item flex flex-row gap-3 pt-3">
        <div className="flex flex-col py-3">
          <CompleteStatusIcon
            status={reminder.status}
            toggle={
              isLoading
                ? undefined
                : () => toggleStatus(reminder.reminderGuid, reminder.status)
            }
          />
        </div>
        <div
          className={cn(
            'flex flex-1 flex-col gap-1',
            'relative border-b border-l-0 border-r-0 border-t-0 border-solid border-bz-gray-400 pb-[14px]',
          )}
        >
          <div
            className={cn('flex flex-row', {
              'opacity-50': reminder.status === 'COMPLETE',
            })}
          >
            {reminder.description}
          </div>
          <div className={cn('flex flex-row gap-1')}>
            <Chip
              className={cn({
                'opacity-50': reminder.status === 'COMPLETE',
              })}
            >
              <FormattedReminderDate date={reminder.dueAt} timeZoneId={tzId} />
            </Chip>
            <Chip
              className={cn({
                'opacity-50': reminder.status === 'COMPLETE',
              })}
            >
              {reminder.reminderAssignments[0]?.user.firstNameInitial}
            </Chip>
            <div className="account-reminder-item_created-by ml-1 flex items-center text-xs text-bz-gray-700">
              {`Created by ${reminder.createdByUser.firstNameInitial}`}
            </div>
          </div>
          {
            <div className="account-reminder-item__controls absolute right-0 flex h-full flex-1 flex-col items-center justify-center pb-[14px]">
              <div className="flex flex-row gap-3">
                {canEdit ? (
                  <FontAwesomeIcon
                    className="h-4 w-4 cursor-pointer text-bz-gray-700"
                    size="sm"
                    aria-label="Edit Reminder"
                    icon={faEdit}
                    onClick={edit}
                  />
                ) : null}
                {canDelete ? (
                  <FontAwesomeIcon
                    className="h-4 w-4 cursor-pointer text-bz-gray-700"
                    size="sm"
                    aria-label="Delete Reminder"
                    icon={faTrash}
                    onClick={onDelete}
                  />
                ) : null}
              </div>
            </div>
          }
        </div>
      </div>
    )
  },
)

export default AccountReminderItem
