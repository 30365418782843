import { BzDateFns, LocalDateString } from '@breezy/shared'
import { memo } from 'react'

type LocalDateViewProps = {
  readonly localDate: LocalDateString
}

export const LocalDateView = memo<LocalDateViewProps>(({ localDate }) => (
  <>{BzDateFns.localDateToFriendlyDateString(localDate)}</>
))
