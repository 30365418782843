import {
  Account,
  BzDateTime,
  Guid,
  isNullish,
  MaintenancePlanDefinition,
  MaintenancePlanPaymentInterval,
  TaxRateSetEventData,
} from '@breezy/shared'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { usePrevious } from '../../../hooks/usePrevious'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { getBillingAnchorDate } from '../MaintenancePlanV3Utils'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'

type FormLogicWatcherInputs = {
  form: UseFormReturn<ConfigureMaintenancePlanFormData>
  currentAccount?: Account
  selectedLocationGuid?: Guid
  selectedPlanType?: MaintenancePlanDefinition
  reset: () => void
  defaultTaxRate: TaxRateSetEventData
}

export const useFormLogicWatcher = ({
  form,
  currentAccount,
  selectedPlanType,
  defaultTaxRate,
  reset,
  selectedLocationGuid,
}: FormLogicWatcherInputs) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { watch, setValue } = form

  const previousAccountGuid = usePrevious(currentAccount?.accountGuid)
  useEffect(() => {
    // NOTE: Reset Form State on Account Change
    if (
      previousAccountGuid &&
      currentAccount?.accountGuid !== previousAccountGuid
    ) {
      reset()
      // reset the tax rate since the effect below won't run again after this reset is called since the tax rate
      // isn't explicitly set again
      setValue('taxRate', defaultTaxRate)
    }

    if (currentAccount) {
      setValue('accountGuid', currentAccount.accountGuid)
    }
  }, [currentAccount, reset, setValue, previousAccountGuid, defaultTaxRate])

  useEffect(() => {
    setValue('taxRate', defaultTaxRate)
  }, [defaultTaxRate, setValue])

  const paymentInterval = watch('billingPaymentInterval')
  const activationDate = watch('activationDate')
  const billingAnchorDay = watch('billingAnchorDay')
  const isFreePlan = watch('isFreePlan')
  const isAutoRenewing = watch('isAutoRenewing')

  useEffect(() => {
    if (selectedLocationGuid) {
      setValue('locationGuid', selectedLocationGuid)
    }
  }, [selectedLocationGuid, setValue])

  // NOTE: Init Pricing whenever Plan Type is Selected
  useEffect(() => {
    if (!selectedPlanType || isNullish(selectedPlanType.yearlyStaticPriceUsc))
      return

    const annualPrice = selectedPlanType.yearlyStaticPriceUsc ?? 0
    setValue('subtotalYearlyPriceUsc', annualPrice)
    setValue('adjustmentYearlyPriceUsc', 0)
    if (annualPrice === 0) {
      setValue('isFreePlan', true)
    }
  }, [selectedPlanType, setValue, paymentInterval])

  // NOTE: Keep Billing Anchor Date in sync with Activation Date and Billing Anchor Day
  useEffect(() => {
    if (activationDate && billingAnchorDay) {
      const anchorDate = getBillingAnchorDate(
        billingAnchorDay,
        activationDate,
        tzId,
      )
      if (anchorDate) {
        setValue('billingAnchorDate', anchorDate)
      }
    }
  }, [activationDate, billingAnchorDay, tzId, setValue])

  // NOTE: Handle Auto-Renewal and Free Plan Logic Effects
  useEffect(() => {
    if (!isFreePlan) {
      if (!isAutoRenewing) {
        setValue(
          'billingPaymentInterval',
          MaintenancePlanPaymentInterval.YEARLY,
        )
      }
    }
    if (isFreePlan) {
      setValue('activationDate', BzDateTime.now(tzId).toLocalDateString())
    }
  }, [setValue, isAutoRenewing, isFreePlan, tzId])
}
