import { z } from 'zod'
import { AsyncFn, BzDateFns } from '../../../common'
import { guidSchema, isoDateStringSchema } from '../../../contracts'
import { AccountGuid } from '../../Accounts/Account'
import { Address } from '../../Address/Address'
import { ForCompany } from '../../Company/Company'
import { USStateAbbreviationSchema } from '../../Geolocation/USStates'
import { bzOptional } from '../../common-schemas'
import { WithMerchantId } from '../Lending/Lending'
import { PaymentMethod } from '../Payments/PaymentTypes'

const PaymentMethodCardTypes = ['CREDIT', 'DEBIT', 'PREPAID', 'UNKNOWN'] as const

export type PaymentMethodCardType = (typeof PaymentMethodCardTypes)[number]

export type ExternalAttachPaymentMethodToCustomerRequest = {
  merchantId: string
  accountGuid: AccountGuid
  externalCustomerId?: string
  paymentMethodId: string
}

export type ExternalAttachPaymentMethodToCustomerResponse = {
  externalCustomerId: string
  paymentMethodId: string
}

export type ExternalAttachPaymentMethodToCustomer = AsyncFn<
  ExternalAttachPaymentMethodToCustomerRequest,
  ExternalAttachPaymentMethodToCustomerResponse
>
const PaymentMethodBillingInfoDtoSchema = z.object({
  name: z.string(),
  streetAddress: z.string(),
  streetAddress2: bzOptional(z.string()),
  city: z.string(),
  state: USStateAbbreviationSchema,
  zipCode: z.string(),
})

type PaymentMethodBillingInfoDto = z.infer<typeof PaymentMethodBillingInfoDtoSchema>

export const convertPaymentMethodBillingInfoToAddress = (billingInfo: PaymentMethodBillingInfoDto): Address => ({
  line1: billingInfo.streetAddress,
  line2: billingInfo.streetAddress2,
  city: billingInfo.city,
  stateAbbreviation: billingInfo.state,
  zipCode: billingInfo.zipCode,
})

const PaymentMethodCardMetadataDtoSchema = z.object({
  lastFourDigits: bzOptional(z.string()),
  expirationYear: bzOptional(z.string()),
  expirationMonth: bzOptional(z.string()),
  cardBrand: bzOptional(z.string()),
  cardType: bzOptional(z.enum(PaymentMethodCardTypes)),
})

export type PaymentMethodCardMetadata = z.infer<typeof PaymentMethodCardMetadataDtoSchema>
const PaymentMethodCardTypeDisplayNames = {
  CREDIT: 'Credit Card',
  DEBIT: 'Debit Card',
  PREPAID: 'Prepaid Card',
  UNKNOWN: 'Card',
} as const
export const getDisplayNameFromCardMetadata = (cardMetadata: PaymentMethodCardMetadata) => {
  const cardBrand = cardMetadata.cardBrand ? cardMetadata.cardBrand.toUpperCase() : 'Unknown'

  return `${cardBrand} ${PaymentMethodCardTypeDisplayNames[cardMetadata.cardType ?? 'UNKNOWN']}`
}

export const PaymentMethodCreateRequestDtoSchema = z.object({
  accountGuid: guidSchema,
  paymentMethodId: z.string(),
  paymentMethodType: z.nativeEnum(PaymentMethod),
  billingInfo: PaymentMethodBillingInfoDtoSchema,
  displayName: bzOptional(z.string()),
  cardMetadata: bzOptional(PaymentMethodCardMetadataDtoSchema),
  companyGuid: guidSchema,
  merchantId: z.string(),
})

export type PaymentMethodCreateRequestDto = z.infer<typeof PaymentMethodCreateRequestDtoSchema>

export const PaymentMethodCreateFromSubscriptionRequestDtoSchema = z.object({
  accountGuid: guidSchema,
  paymentSubscriptionGuid: guidSchema,
  paymentMethodType: z.nativeEnum(PaymentMethod),
  billingInfo: PaymentMethodBillingInfoDtoSchema,
  displayName: bzOptional(z.string()),
  cardMetadata: bzOptional(PaymentMethodCardMetadataDtoSchema),
  companyGuid: guidSchema,
})
export type PaymentMethodCreateFromSubscriptionRequestDto = z.infer<
  typeof PaymentMethodCreateFromSubscriptionRequestDtoSchema
>

export const PaymentMethodUpdateRequestDtoSchema = z.object({
  paymentMethodRecordGuid: guidSchema,
  displayName: bzOptional(z.string()),
  companyGuid: guidSchema,
  merchantId: z.string(),
})

export type PaymentMethodUpdateRequestDto = z.infer<typeof PaymentMethodUpdateRequestDtoSchema>

export const PaymentMethodDeleteRequestDtoSchema = z.object({
  paymentMethodRecordGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),
})
export type PaymentMethodDeleteRequestDto = z.infer<typeof PaymentMethodDeleteRequestDtoSchema>

export type PaymentMethodWriteRequest =
  | {
      type: 'create'
      data: ForCompany<WithMerchantId<PaymentMethodCreateRequestDto>>
    }
  | {
      type: 'update'
      data: ForCompany<WithMerchantId<PaymentMethodUpdateRequestDto>>
    }
  | {
      type: 'create-from-subscription'
      data: ForCompany<PaymentMethodCreateFromSubscriptionRequestDto>
    }
  | {
      type: 'delete'
      data: ForCompany<WithMerchantId<PaymentMethodDeleteRequestDto>>
    }

export type PaymentMethodWriteResponse = {
  paymentMethodId: string
  paymentMethodRecordGuid: string
}

export type PaymentMethodWriter = AsyncFn<PaymentMethodWriteRequest, PaymentMethodWriteResponse>

export type PaymentMethodRecordGuid = string
export type PaymentMethodRecordGuidContainer = {
  paymentMethodRecordGuid: PaymentMethodRecordGuid
}

export type PaymentMethodRecordReader = AsyncFn<
  PaymentMethodRecordGuidContainer,
  {
    externalCustomerId: string
    externalPaymentMethodId: string
  }
>

export const CardOnFileSchema = z.object({
  paymentMethodRecordGuid: z.string(),
  displayName: bzOptional(z.string()),
  externalPaymentMethodId: z.string(),
  paymentMethodBillingInfo: bzOptional(
    z.object({
      name: bzOptional(z.string()),
    }),
  ),
  paymentMethodCardMetadata: bzOptional(
    z.object({
      brand: z.string(),
      expMonth: z.string(),
      expYear: z.string(),
      lastFourDigits: z.string(),
      type: z.enum(PaymentMethodCardTypes),
    }),
  ),
  paymentMethodType: z.nativeEnum(PaymentMethod),
  createdAt: isoDateStringSchema,
  hasPaymentSubscription: z.boolean(),
})
export type CardOnFile = z.infer<typeof CardOnFileSchema>

export const getCardOnFileDisplayName = (cardOnFile: CardOnFile) =>
  `${
    cardOnFile.displayName ??
    (cardOnFile.paymentMethodCardMetadata
      ? getDisplayNameFromCardMetadata(cardOnFile.paymentMethodCardMetadata)
      : 'Unknown Card')
  } (••••${cardOnFile.paymentMethodCardMetadata?.lastFourDigits ?? 'XXXX'})`

export const getCardOnFilePaymentMethodAdditionalInfo = (cardOnFile: CardOnFile) => {
  return `Card on File - ${getCardOnFileDisplayName(cardOnFile)}`
}

export const isCardExpired = (card: CardOnFile): boolean => {
  const { paymentMethodCardMetadata } = card
  if (!paymentMethodCardMetadata || !paymentMethodCardMetadata.expYear || !paymentMethodCardMetadata.expMonth) {
    return false
  }

  const expirationDate = BzDateFns.createDate({
    year: parseInt(paymentMethodCardMetadata.expYear, 10),
    month: parseInt(paymentMethodCardMetadata.expMonth, 10) - 1,
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })

  const now = BzDateFns.now(BzDateFns.UTC)
  return BzDateFns.isBefore(expirationDate, now)
}
