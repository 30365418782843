import { useCallback, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import PaymentWorkflowWizard, {
  PaymentSuccessHandler,
  PaymentWorkflowMetadata,
  PaymentWorkflowOptionConfig,
} from '../PaymentWorkflowWizard'

type UsePaymentWorkflowWizardProps = PaymentWorkflowMetadata & {
  onPaymentSuccess?: PaymentSuccessHandler
  onPaymentError?: () => void
} & PaymentWorkflowOptionConfig

export const usePaymentWorkflowWizard = ({
  onPaymentSuccess,
  onPaymentError,
  paymentInfo,
  hiddenOptions,
  disabledOptions,
  ...meta
}: UsePaymentWorkflowWizardProps) => {
  const [paymentWorkflowMeta, setPaymentWorkflowMeta] = useState<
    PaymentWorkflowMetadata | undefined
  >()

  const hidePaymentWorkflowWizard = useCallback(() => {
    setPaymentWorkflowMeta(undefined)
  }, [])
  const showPaymentWorkflowWizard = useCallback(() => {
    setPaymentWorkflowMeta(meta)
  }, [meta])

  const wizardContent = useMemo(() => {
    if (!paymentWorkflowMeta) return null

    return (
      <PaymentWorkflowWizard
        meta={paymentWorkflowMeta}
        onCancel={hidePaymentWorkflowWizard}
        onPaymentSuccess={onPaymentSuccess}
        onPaymentError={onPaymentError}
        paymentInfo={paymentInfo}
        hiddenOptions={hiddenOptions}
        disabledOptions={disabledOptions}
      />
    )
  }, [
    paymentWorkflowMeta,
    hidePaymentWorkflowWizard,
    onPaymentSuccess,
    onPaymentError,
    paymentInfo,
    hiddenOptions,
    disabledOptions,
  ])

  const paymentWorkflowWizard = useMemo(() => {
    return ReactDOM.createPortal(wizardContent, document.body)
  }, [wizardContent])

  return {
    paymentWorkflowWizard,
    showPaymentWorkflowWizard,
  }
}
