import {
  faBank,
  faDownload,
  faFileSpreadsheet,
  faGauge,
  faHouseUser,
  faLocationDot,
  faScrewdriverWrench,
  faUser,
  faWrench,
} from '@fortawesome/pro-light-svg-icons'

export const AccountIcon = faHouseUser
export const JobIcon = faWrench
export const ContactIcon = faUser
export const LocationIcon = faLocationDot
export const PayoutIcon = faBank
export const TimesheetsIcon = faFileSpreadsheet
export const TechnicianPerformanceIcon = faGauge
export const MaintenancePlanIcon = faScrewdriverWrench

export const DownloadIcon = faDownload
