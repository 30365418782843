import {
  faCalendar,
  faMapLocationDot,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'

export const VALID_SCHEDULE_VIEWS = ['DISPATCH', 'DAY', 'ONE_WEEK'] as const
export const DEFAULT_SCHEDULE_VIEW = VALID_SCHEDULE_VIEWS[0]
export type ScheduleView = (typeof VALID_SCHEDULE_VIEWS)[number]

export const SCHEDULE_VIEWS = {
  DISPATCH: { label: 'Dispatch' },
  DAY: { label: 'Day' },
  ONE_WEEK: { label: 'One Week' },
} satisfies Record<ScheduleView, { label: string; hidden?: boolean }>

export type ScheduleViewLabel = (typeof SCHEDULE_VIEWS)[ScheduleView]['label']

export const VALID_SCHEDULE_MODES = ['CALENDAR', 'MAP'] as const
export const DEFAULT_SCHEDULE_MODE = VALID_SCHEDULE_MODES[0]
export type ScheduleMode = (typeof VALID_SCHEDULE_MODES)[number]

export const SCHEDULE_MODE = {
  CALENDAR: { label: 'Calendar', icon: faCalendar },
  MAP: { label: 'Map', icon: faMapLocationDot },
} satisfies Record<ScheduleMode, { label: string; icon: IconDefinition }>
