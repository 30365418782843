import { bzExpect } from '@breezy/shared'
import { useParams } from 'react-router-dom'

export const useRouteParam = (paramName: string): string | undefined => {
  const val = useParams()[paramName]
  if (val === 'undefined') return undefined
  return val
}

export const useRequiredRouteParam = (paramName: string): string =>
  bzExpect(
    useRouteParam(paramName),
    paramName,
    `${paramName} is required in the route path`,
  )
