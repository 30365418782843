import { gql } from '../../../../../generated'

// Duplicated in assignable-appts.graphql#FetchEnRouteOrInProgressAppointmentAssignmentsForUser
export const FETCH_ACTIVE_APPOINTMENT_ASSIGNMENTS_FOR_USER = gql(/* GraphQL */ `
  query FetchActiveAppointmentAssignmentsForUser(
    $userGuid: uuid!
    $companyGuid: uuid!
  ) {
    jobAppointmentAssignments(
      where: {
        _and: [
          { technicianUserGuid: { _eq: $userGuid } }
          { companyGuid: { _eq: $companyGuid } }
          {
            assignmentStatus: {
              _or: [
                { jobAppointmentAssignmentStatusType: { _eq: IN_PROGRESS } }
                { jobAppointmentAssignmentStatusType: { _eq: EN_ROUTE } }
              ]
            }
          }
        ]
      }
    ) {
      jobAppointmentGuid
      jobAppointmentAssignmentGuid
      assignmentStatus {
        jobAppointmentAssignmentStatusType
      }
    }
  }
`)

export const TECH_CLOCKED_IN_QUERY = gql(/* GraphQL */ `
  subscription AssignmentStatusStepsFetchTimeClockStatus(
    $companyGuid: uuid!
    $userGuid: uuid!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        endTime: { _isNull: true }
      }
      orderBy: { startTime: DESC }
      limit: 1
    ) {
      companyGuid
      userGuid
      timesheetEntryGuid
    }
  }
`)
