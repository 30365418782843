import { Guid } from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useEmbeddedLoanApplication } from '../../../pages/SelfServePaymentPage/hooks/useEmbeddedLoanApplication'
import { useIsAccountApp } from '../../../providers/AppContextWrapper'
import { useWisetackEnabled } from '../../../providers/CompanyFinancialConfigWrapper'
import { useModalState } from '../../../utils/react-utils'
import SendPrequalLinkModal from '../SendPrequalLinkModal/SendPrequalLinkModal'
import { useFinancingWizard } from '../hooks/useFinancingWizard'
import { LoanPromoShortDisclaimer } from './LoanPromoDisclaimer'
import LoanPromo from './LoanPromoView'

type LoanPromoQualifyNowProps = {
  className?: string
  accountGuid: Guid
  jobGuid?: Guid
  showLoanDisclosure: boolean
  withQualifyNowButton?: boolean
  amountUsc: number
} & (
  | {
      type: 'prequal'
      prequalContactGuid?: Guid
    }
  | {
      type: 'loan-app'
      prequalContactGuid?: never
    }
)

const LoanPromoQualifyNowAuth = React.memo<LoanPromoQualifyNowProps>(
  ({
    accountGuid,
    jobGuid,
    className,
    showLoanDisclosure,
    type = 'prequal',
    withQualifyNowButton = true,
    amountUsc,
  }) => {
    const wisetackEnabled = useWisetackEnabled()

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      jobGuid,
    })

    const onQualify = useCallback(() => {
      datadogRum.addAction(`bz-financing-promo-${type}-qualify-click`)
      showFinancingWizard()
    }, [showFinancingWizard, type])

    if (!wisetackEnabled) return null
    return (
      <div className={classNames('flex flex-col', className)}>
        <LoanPromo
          showDisclosure={showLoanDisclosure}
          amountUsc={amountUsc}
          qualifyText={withQualifyNowButton ? 'Qualify Now' : undefined}
          onQualify={withQualifyNowButton ? onQualify : undefined}
        />
        {showLoanDisclosure && (
          <LoanPromoShortDisclaimer amountUsc={amountUsc} />
        )}
        {financingWizard}
      </div>
    )
  },
)

const LoanPromoQualifyNowPrequal = React.memo<LoanPromoQualifyNowProps>(
  ({
    className,
    accountGuid,
    jobGuid,
    showLoanDisclosure,
    withQualifyNowButton = true,
    amountUsc,
    prequalContactGuid,
  }) => {
    const [sendPrequalOpen, showSendPrequal, hideSendPrequal] =
      useModalState(false)

    const onQualify = useCallback(() => {
      if (prequalContactGuid) {
        showSendPrequal()
      }
      datadogRum.addAction('bz-financing-promo-prequal-qualify-click')
    }, [prequalContactGuid, showSendPrequal])

    return (
      <div className={classNames('flex flex-col', className)}>
        <LoanPromo
          showDisclosure={showLoanDisclosure}
          amountUsc={amountUsc}
          qualifyText={withQualifyNowButton ? 'Qualify Now' : undefined}
          onQualify={withQualifyNowButton ? onQualify : undefined}
        />
        <LoanPromoShortDisclaimer amountUsc={amountUsc} />
        {sendPrequalOpen && prequalContactGuid && (
          <SendPrequalLinkModal
            accountGuid={accountGuid}
            jobGuid={jobGuid}
            onClose={hideSendPrequal}
            amountUsc={amountUsc}
            contactGuid={prequalContactGuid}
          />
        )}
      </div>
    )
  },
)

const LoanPromoQualifyNowLoanApp = React.memo<LoanPromoQualifyNowProps>(
  ({
    className,
    showLoanDisclosure,
    withQualifyNowButton = true,
    amountUsc,
  }) => {
    const { embeddedLoanApplication, toggle } = useEmbeddedLoanApplication()

    const onQualify = useCallback(() => {
      toggle()
      datadogRum.addAction('bz-financing-promo-loan-app-qualify-click')
    }, [toggle])

    return (
      <div className={classNames('flex flex-col', className)}>
        <LoanPromo
          showDisclosure={showLoanDisclosure}
          amountUsc={amountUsc}
          qualifyText={withQualifyNowButton ? 'Qualify Now' : undefined}
          onQualify={withQualifyNowButton ? onQualify : undefined}
        />
        {showLoanDisclosure && (
          <LoanPromoShortDisclaimer amountUsc={amountUsc} />
        )}
        {embeddedLoanApplication}
      </div>
    )
  },
)

const LoanPromoQualifyNowUnauth = React.memo<LoanPromoQualifyNowProps>(
  props => {
    return props.type === 'prequal' ? (
      <LoanPromoQualifyNowPrequal {...props} />
    ) : (
      <LoanPromoQualifyNowLoanApp {...props} />
    )
  },
)
const LoanPromoQualifyNow = React.memo<LoanPromoQualifyNowProps>(props => {
  const isAccountApp = useIsAccountApp()

  return isAccountApp ? (
    <LoanPromoQualifyNowUnauth {...props} />
  ) : (
    <LoanPromoQualifyNowAuth {...props} />
  )
})

export default LoanPromoQualifyNow
