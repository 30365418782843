import {
  MaintenancePlanCancellationReasonType,
  VisitViewModel,
  bzExpect,
  bzOptional,
  maintenancePlanCancellationReasonTypesDisplayNames,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Radio, message } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  OnsiteBasicModal,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { BzSwitch } from '../../elements/BzSwitch/BzSwitch'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import {
  useExpectedCompanyGuid,
  useMerchantId,
} from '../../providers/PrincipalUser'

export type MaintenancePlanCancelInfo = {
  maintenancePlanGuid: string
  accountGuid: string
  paymentSubscriptionGuid?: string
  planDefinition?: {
    name: string
  }
  primaryContactDisplayName?: string
  visits?: VisitViewModel[]
}

type MaintenancePlanCancelProps = {
  readonly vm: MaintenancePlanCancelInfo
  readonly onSubmit: () => void
  readonly onClose: () => void
}

type MaintenancePlanCancelDrawerProps = {
  readonly item: MaintenancePlanCancelProps | undefined
}

const mpCancelFormSchema = z.object({
  cancellationReason: bzOptional(z.string()),
  cancellationReasonType: z.nativeEnum(MaintenancePlanCancellationReasonType),
  suppressCancellationEmail: z.boolean(),
  shouldExpireVisitsImmediately: z.boolean(),
})

type MpCancelFormType = z.infer<typeof mpCancelFormSchema>

export const MaintenancePlanCancelModal = ({
  item,
}: MaintenancePlanCancelDrawerProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const merchantId = useMerchantId()
  const companyGuid = useExpectedCompanyGuid()
  const useMpV3 = useFeatureFlag('createMaintenancePlanV2')

  const onClose = useCallback(() => {
    item?.onClose()
  }, [item])

  const form = useForm<MpCancelFormType>({
    resolver: zodResolver(mpCancelFormSchema),
    defaultValues: {
      suppressCancellationEmail: false,
      shouldExpireVisitsImmediately: false,
    },
  })

  useEffect(
    () =>
      form.reset({
        suppressCancellationEmail: false,
        shouldExpireVisitsImmediately: false,
      }),
    [item, form],
  )

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = form

  const suppressCancellationEmail = watch('suppressCancellationEmail')
  const shouldExpireVisitsImmediately = watch('shouldExpireVisitsImmediately')

  const cancelPlanAndSubMut =
    trpc.maintenancePlans[
      'maintenance-plans:cancel-payment-subscription'
    ].useMutation()

  const cancelPlanMut =
    trpc.maintenancePlans['maintenance-plans:cancel-plan'].useMutation()

  const onSuccess = useCallback(() => {
    message.success('Plan cancelled successfully')
    item?.onSubmit()
  }, [item])

  const onError = useCallback((e: unknown) => {
    message.error(
      `Unable to cancel plan: '${(e as Error)?.message ?? 'Unknown error'}'`,
    )
  }, [])

  const onFormSubmit = useCallback(async () => {
    if (!item) return

    const {
      cancellationReason,
      cancellationReasonType,
      suppressCancellationEmail,
      shouldExpireVisitsImmediately,
    } = form.getValues()

    const { vm } = item
    setIsUploading(true)
    const paymentSubscriptionGuid = vm.paymentSubscriptionGuid
    if (paymentSubscriptionGuid) {
      await cancelPlanAndSubMut.mutateAsync(
        {
          ...vm,
          companyGuid,
          cancellationReason: cancellationReason ?? 'No reason provided',
          cancellationReasonType,
          merchantId: bzExpect(merchantId, 'merchantId'),
          paymentSubscriptionGuid,
          suppressCancellationEmail,
          shouldExpireVisitsImmediately,
        },
        {
          onSuccess: onSuccess,
          onError: onError,
        },
      )
    } else {
      await cancelPlanMut.mutateAsync(
        {
          ...vm,
          cancellationReason: cancellationReason ?? 'No reason provided',
          cancellationReasonType,
          suppressCancellationEmail,
          shouldExpireVisitsImmediately,
        },
        {
          onSuccess: onSuccess,
          onError: onError,
        },
      )
    }
    setIsUploading(false)
  }, [
    item,
    form,
    cancelPlanAndSubMut,
    companyGuid,
    merchantId,
    onSuccess,
    onError,
    cancelPlanMut,
  ])

  const visits = useMemo(() => {
    return item?.vm.visits ?? []
  }, [item])

  const openVisitsCount = useMemo(() => {
    return visits.filter(
      v => v.status !== 'COMPLETED' && v.status !== 'EXPIRED',
    ).length
  }, [visits])

  const openVisitsText = useMemo(() => {
    return `${openVisitsCount} of ${visits.length} visits`
  }, [openVisitsCount, visits])

  return (
    <OnsiteBasicModal
      size="large-width"
      open={!!item}
      onClose={onClose}
      drawer
      header="Cancel maintenance plan?"
      secondaryFooter={
        <div className="space-between flex w-full flex-row items-center gap-x-3">
          <div className="text-[14px] font-semibold leading-[22px]">
            Send the customer a cancellation email
          </div>
          <BzSwitch
            checked={!suppressCancellationEmail}
            onChange={e => setValue('suppressCancellationEmail', !e)}
            disabled={isUploading}
          />
        </div>
      }
      footer={
        <OnsiteModalFooter
          onCancel={onClose}
          onSubmit={handleSubmit(onFormSubmit)}
          danger
          cancelText="Close"
          submitText="Cancel Plan"
          submitIsLoading={isUploading}
        />
      }
    >
      {item && (
        <FormProvider {...form}>
          <div className="mb-3 flex w-full flex-col gap-y-6">
            <div className="text-[16px] leading-[24px] text-[#202020]">
              Are you sure you want to cancel the{' '}
              <strong>{item.vm.planDefinition?.name} plan</strong> for{' '}
              <strong>{item.vm.primaryContactDisplayName}?</strong>{' '}
              {openVisitsCount > 0 && (
                <span>
                  They have <strong>{openVisitsText}</strong> left.
                </span>
              )}{' '}
              This action cannot be undone.
            </div>
            <ThinDivider
              widthPx={1}
              styleOverrides={{ marginTop: 0, marginBottom: 0 }}
            />
            <ReactHookFormItem
              noBottomMargin
              control={control}
              errors={errors}
              name={`cancellationReasonType`}
              label="Reason Type"
              required={true}
              className="w-full"
              labelCol={{ span: 24 }}
              render={({ field }) => (
                <SelectField
                  title="Reason Type"
                  showSearch
                  options={Object.values(
                    MaintenancePlanCancellationReasonType,
                  ).map(t => ({
                    value: t,
                    label:
                      maintenancePlanCancellationReasonTypesDisplayNames[t],
                  }))}
                  {...field}
                  size="large"
                  className="w-full"
                  disabled={isUploading}
                />
              )}
            />

            <ReactHookFormItem
              noBottomMargin
              control={control}
              errors={errors}
              name={`cancellationReason`}
              label={
                <div>
                  Cancellation Reason{' '}
                  <span className="font-normal text-bz-text-tertiary">
                    (optional)
                  </span>
                </div>
              }
              className="w-full"
              labelCol={{ span: 24 }}
              render={({ field }) => (
                <TextAreaField
                  {...field}
                  placeholder="Enter a cancellation reason"
                  size="middle"
                  rows={4}
                  className="w-full"
                  disabled={isUploading}
                />
              )}
            />

            {useMpV3 && openVisitsCount > 0 && (
              <ReactHookFormItem
                control={control}
                errors={errors}
                name="shouldExpireVisitsImmediately"
                label={`What would you like to do with the (${openVisitsCount}) remaining visit credits?`}
                required={openVisitsCount > 0}
                className="w-full"
                labelCol={{ span: 24 }}
                render={({ field }) => (
                  <Radio.Group
                    value={shouldExpireVisitsImmediately}
                    onChange={e =>
                      setValue('shouldExpireVisitsImmediately', e.target.value)
                    }
                    disabled={isUploading}
                  >
                    <Radio value={true} className="mb-4 w-full">
                      Expire unused visits immediately
                    </Radio>
                    <Radio value={false} className="w-full">
                      Keep unused visits until original plan expiration date
                    </Radio>
                  </Radio.Group>
                )}
              />
            )}
          </div>
        </FormProvider>
      )}
    </OnsiteBasicModal>
  )
}
