import { AbridgedInvoiceViewModel, formatMoney } from '@breezy/shared'
import { Popover, Tag } from 'antd'
import { memo } from 'react'

export type InvoiceTagItems = {
  invoices: AbridgedInvoiceViewModel[]
}

type AdditionalInvoicesPopoverContentProps = InvoiceTagItems

const AdditionalInvoicesPopoverContent = ({
  invoices,
}: AdditionalInvoicesPopoverContentProps) => {
  return (
    <div className="flex flex-row items-center gap-2">
      {invoices.map(invoice => {
        return (
          <Tag key={invoice.invoiceGuid}>
            # {invoice.displayId}
            {` (${formatMoney(invoice.totalAmountUsd)})`}
          </Tag>
        )
      })}
    </div>
  )
}

export type InvoicesTagListProps = InvoiceTagItems & {
  limit: number
}

export const InvoicesTagRow = memo(
  ({ invoices, limit }: InvoicesTagListProps) => {
    const shownInvoices = invoices.slice(0, limit)
    const hiddenInvoices = invoices.slice(limit)

    return (
      <div className="flex flex-row flex-wrap items-center gap-1">
        {shownInvoices.map(invoice => {
          return (
            <Tag key={invoice.invoiceGuid}>
              # {invoice.displayId}
              {` (${formatMoney(invoice.totalAmountUsd)})`}
            </Tag>
          )
        })}

        {hiddenInvoices.length > 0 && (
          <Popover
            trigger="hover"
            title="Additional Invoices"
            content={
              <AdditionalInvoicesPopoverContent invoices={hiddenInvoices} />
            }
          >
            <Tag>+ {hiddenInvoices.length}</Tag>
          </Popover>
        )}
      </div>
    )
  },
)
