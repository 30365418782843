import { DateFormat } from '../../utils/DateUtils'
import { m } from '../../utils/react-utils'
import { DateView } from './DateView'

type SimpleDateViewProps = {
  readonly isoDate: string
}

export const SimpleDateView = m<SimpleDateViewProps>(({ isoDate }) => (
  <DateView
    isoWithOffsetTimestamp={new Date(isoDate).toISOString()}
    zone={{ type: 'company-timezone-of-principal-user' }}
    format={DateFormat['MMM d, yyyy']}
  />
))
