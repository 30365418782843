import { JobLeadAvailabilitySchema } from '@breezy/shared'
import classNames from 'classnames'
import React from 'react'
import { NotCapturedView } from './NotCapturedView'
import { JobLead } from './types'

export const JobAvailabilitiesView = React.memo<{
  jobLead: JobLead
  withGap?: boolean
}>(({ jobLead, withGap = false }) => {
  if (!jobLead || !jobLead.appointmentAvailabilities) return <NotCapturedView />

  const result = JobLeadAvailabilitySchema.safeParse(
    jobLead.appointmentAvailabilities,
  )
  if (!result.success) return <NotCapturedView />

  const freeformTimes = result.data.times.filter(t => t.type === 'freeform')

  if (freeformTimes.length === 0) return <NotCapturedView />

  return (
    <div className={classNames('flex flex-col', withGap ? 'gap-y-1' : '')}>
      {freeformTimes.map((time, idx) => {
        return <div key={idx}>{time.value}</div>
      })}
    </div>
  )
})
