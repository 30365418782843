import { noOp } from '@breezy/shared'
import {
  faCheck,
  faCircleCheck,
  faSignature,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'

type SignatureSectionProps = {
  hasSignature?: boolean
  onSignatureClick?: () => void
}

export const SignatureSection = React.memo<SignatureSectionProps>(
  ({ hasSignature, onSignatureClick }) => {
    return (
      <OnsitePageSection
        hideTopBorder
        title={
          <span>
            Signatures needed{' '}
            <span className="text-bz-gray-700">({hasSignature ? 0 : 1})</span>
          </span>
        }
      >
        <OnsitePageSimpleSectionItem
          mainContentDisabled={!onSignatureClick || hasSignature}
          // If this is undefined it doesn't show the little right arrow thing. So the `noOp` makes it always visible
          onClick={onSignatureClick ?? noOp}
          icon={
            <FontAwesomeIcon
              icon={hasSignature ? faCircleCheck : faSignature}
            />
          }
          rightContent={
            hasSignature ? (
              <div className="flex h-full flex-row items-center space-x-2 text-sm font-semibold text-bz-gray-800">
                <div>Captured</div>
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-bz-green-700 text-xs text-white">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            ) : undefined
          }
        >
          {hasSignature ? 'Invoice signature' : 'Signature for the invoice'}
        </OnsitePageSimpleSectionItem>
      </OnsitePageSection>
    )
  },
)
