import {
  bzExpect,
  createDefaultLoanApplicationEmail,
  createDefaultLoanApplicationSms,
  createDefaultPrequalApplicationEmail,
  createDefaultPrequalApplicationSms,
  usdToUsCents,
} from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import React, { useCallback, useMemo, useState } from 'react'
import Switch from '../../elements/Switch/Switch'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import PreviewMessageOptionSelection, {
  MessageOption,
} from '../PreviewMessage/PreviewMessageOptionSelection/PreviewMessageOptionSelection'
import { SendAsEmailFormData } from '../PreviewMessage/SendEmailForm/SendEmailForm'
import { SendAsSmsFormData } from '../PreviewMessage/SendSmsForm/SendSmsForm'
import FinancingSendEmailForm from './FinancingSendAppForms/FinancingSendEmailForm'
import FinancingSendSmsForm from './FinancingSendAppForms/FinancingSendSmsForm'
import {
  FinancingWizardPreviewMessageStepContentProps,
  useFinancingWizardContext,
} from './FinancingWizardDrawer'

export const FinancingWizardPreviewMessageStepContent =
  React.memo<FinancingWizardPreviewMessageStepContentProps>(({ contact }) => {
    const {
      account,
      jobGuid,
      financingOption,
      onCancel,
      loanApplicationConfiguration,
      prequalApplicationConfiguration,
      originatingInvoiceGuid,
    } = useFinancingWizardContext()

    const company = useExpectedCompany()
    const [messageOption, setMessageOption] = useState<MessageOption>('sms')
    const message = useMessage()
    const { mutate: sendLoanApplication, isLoading: isSendingLoanApplication } =
      trpc.financing['financing:send-loan-application'].useMutation({
        onSuccess: () => {
          message.success('Loan application sent')
          onCancel()
        },
        onError: () => {
          message.error('Failed to send loan application')
        },
      })

    const {
      mutate: sendPrequalApplication,
      isLoading: isSendingPrequalApplication,
    } = trpc.financing['financing:send-prequal-application'].useMutation({
      onSuccess: () => {
        message.success('Pre-qualification application sent')
        onCancel()
      },
      onError: () => {
        message.error('Failed to send pre-qualification application')
      },
    })

    const submitLoanAppEmail = useCallback(
      async (data: SendAsEmailFormData) => {
        const cfg = bzExpect(loanApplicationConfiguration)
        const payload = {
          accountGuid: account.accountGuid,
          companyGuid: company.companyGuid,
          invoiceGuid: cfg.invoiceGuid,
          serviceDescription: cfg.serviceDescription,
          contactGuid: cfg.contactGuid,
          requestedLoanAmountUsc: usdToUsCents(cfg.financingAmountUsd),
          jobGuid,
          deliveryMethod: 'EMAIL',
          to: data.emailAddress,
          subject: data.subject,
          body: data.body,
        } as const
        sendLoanApplication(payload)
        datadogRum.addAction(
          'bz-financing-loan-app-submitted-via-email',
          payload,
        )
      },
      [
        account.accountGuid,
        company.companyGuid,
        jobGuid,
        loanApplicationConfiguration,
        sendLoanApplication,
      ],
    )
    const submitLoanAppSms = useCallback(
      (data: SendAsSmsFormData) => {
        const cfg = bzExpect(loanApplicationConfiguration)
        const payload = {
          accountGuid: account.accountGuid,
          companyGuid: company.companyGuid,
          invoiceGuid: cfg.invoiceGuid,
          serviceDescription: cfg.serviceDescription,
          contactGuid: cfg.contactGuid,
          requestedLoanAmountUsc: usdToUsCents(cfg.financingAmountUsd),
          jobGuid,
          deliveryMethod: 'SMS',
          to: data.phoneNumber,
          body: data.body,
        } as const
        sendLoanApplication(payload)
        datadogRum.addAction('bz-financing-loan-app-submitted-via-sms', payload)
      },
      [
        account.accountGuid,
        company.companyGuid,
        jobGuid,
        loanApplicationConfiguration,
        sendLoanApplication,
      ],
    )
    const submitPrequalAppEmail = useCallback(
      (data: SendAsEmailFormData) => {
        const cfg = bzExpect(prequalApplicationConfiguration)
        const payload = {
          accountGuid: account.accountGuid,
          companyGuid: company.companyGuid,
          contactGuid: cfg.contactGuid,
          jobGuid,
          invoiceGuid: originatingInvoiceGuid,
          deliveryMethod: 'EMAIL',
          to: data.emailAddress,
          subject: data.subject,
          body: data.body,
        } as const
        sendPrequalApplication(payload)
        datadogRum.addAction(
          'bz-financing-prequal-submitted-via-email',
          payload,
        )
      },
      [
        account.accountGuid,
        company.companyGuid,
        jobGuid,
        originatingInvoiceGuid,
        prequalApplicationConfiguration,
        sendPrequalApplication,
      ],
    )
    const submitPrequalAppSms = useCallback(
      (data: SendAsSmsFormData) => {
        const cfg = bzExpect(prequalApplicationConfiguration)
        const payload = {
          accountGuid: account.accountGuid,
          companyGuid: company.companyGuid,
          contactGuid: cfg.contactGuid,
          jobGuid,
          invoiceGuid: originatingInvoiceGuid,
          deliveryMethod: 'SMS',
          to: data.phoneNumber,
          body: data.body,
        } as const
        sendPrequalApplication(payload)
        datadogRum.addAction('bz-financing-prequal-submitted-via-sms', payload)
      },
      [
        account.accountGuid,
        company.companyGuid,
        jobGuid,
        originatingInvoiceGuid,
        prequalApplicationConfiguration,
        sendPrequalApplication,
      ],
    )

    const defaultLoanApplicationEmail = useMemo(
      () => createDefaultLoanApplicationEmail(contact.firstName, company.name),
      [company.name, contact.firstName],
    )

    const defaultPrequalEmail = useMemo(
      () =>
        createDefaultPrequalApplicationEmail(contact.firstName, company.name),
      [company.name, contact.firstName],
    )

    const defaultLoanApplicationSms = useMemo(
      () => createDefaultLoanApplicationSms(company.name),
      [company.name],
    )

    const defaultPrequalSms = useMemo(
      () => createDefaultPrequalApplicationSms(company.name),
      [company.name],
    )

    return (
      <div className="flex h-full min-h-0 flex-1 flex-col">
        <PreviewMessageOptionSelection
          messageOption={messageOption}
          setMessageOption={setMessageOption}
          className="pb-0"
        />
        <Switch value={financingOption}>
          {{
            'Loan Application': () => (
              <Switch value={messageOption}>
                {{
                  email: () => (
                    <FinancingSendEmailForm
                      contactEmailAddress={
                        contact.primaryEmailAddress?.emailAddress
                      }
                      defaultEmailBody={defaultLoanApplicationEmail}
                      onCancel={onCancel}
                      onSubmit={submitLoanAppEmail}
                      disabled={isSendingLoanApplication}
                      dataDdActionName="bz-financing-loan-app-email-submit-button-click"
                    />
                  ),
                  sms: () => (
                    <FinancingSendSmsForm
                      phoneNumber={contact.primaryPhoneNumber?.phoneNumber}
                      onCancel={onCancel}
                      defaultSms={defaultLoanApplicationSms}
                      onSubmit={submitLoanAppSms}
                      disabled={isSendingLoanApplication}
                      dataDdActionName="bz-financing-loan-app-sms-submit-button-click"
                    />
                  ),
                  default: () => null,
                }}
              </Switch>
            ),
            'Pre-Qualification': () => (
              <Switch value={messageOption}>
                {{
                  email: () => (
                    <FinancingSendEmailForm
                      contactEmailAddress={
                        contact.primaryEmailAddress?.emailAddress
                      }
                      defaultEmailBody={defaultPrequalEmail}
                      onCancel={onCancel}
                      onSubmit={submitPrequalAppEmail}
                      disabled={isSendingPrequalApplication}
                      dataDdActionName="bz-financing-prequal-email-submit-button-click"
                    />
                  ),
                  sms: () => (
                    <FinancingSendSmsForm
                      phoneNumber={contact.primaryPhoneNumber?.phoneNumber}
                      onCancel={onCancel}
                      defaultSms={defaultPrequalSms}
                      onSubmit={submitPrequalAppSms}
                      disabled={isSendingPrequalApplication}
                      dataDdActionName="bz-financing-prequal-sms-submit-button-click"
                    />
                  ),
                  default: () => null,
                }}
              </Switch>
            ),
            default: () => null,
          }}
        </Switch>
      </div>
    )
  })
