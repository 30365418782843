import {
  bzExpect,
  CalculatePaths,
  isNullish,
  nextGuid,
  PricebookTaxRateDto,
} from '@breezy/shared'
import {
  faCopy,
  faEdit,
  faEllipsis,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useFeatureFlag } from 'src/hooks/useFeatureFlags'
import { useSubscription } from 'urql'
import { ActionBar } from '../../adam-components/ActionBar/ActionBar'
import {
  MobileActionBar,
  MobileActionBarButton,
} from '../../adam-components/ActionBar/MobileActionBar'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageLoader } from '../../adam-components/OnsitePage/OnsitePageLoader'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import {
  OnsiteEmbeddedContext,
  useOnsitePageDimensions,
} from '../../adam-components/OnsitePage/onsitePageUtils'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import FinancingSection from '../../components/FinancingSection/FinancingSection'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import useIsMobile from '../../hooks/useIsMobile'
import { useMaintenancePlanJobOnsiteDiscount } from '../../hooks/useMaintenancePlanJobDiscount'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import {
  useModalState,
  useQueryParamFlag,
  useStrictContext,
} from '../../utils/react-utils'
import { AcceptEstimateView } from './AcceptEstimateView'
import { BasicEstimateOption } from './BasicEstimateOption'
import { Carousel } from './components/Carousel'
import { EstimateActionsModal } from './components/EstimateActionsModal'
import {
  EstimateInfoContext,
  EstimateInfoContextType,
} from './components/EstimateInfoModalContent'
import { FinalizeInvoiceDrawer } from './components/FinalizeInvoiceDrawer'
import { Gallery } from './components/Gallery'
import { SendEstimateModal } from './components/SendEstimateModal'
import { EstimateEditView } from './EstimateEditView'
import { ESTIMATE_DATA_SUBSCRIPTION } from './EstimatesFlow.gql'
import {
  CAROUSEL_ITEM_WIDTH,
  EstimateDataContext,
  EstimateDataContextType,
  EstimatesContext,
  FetchedEstimate,
  getDuplicatedOption,
  getEstimateV2StatusDisplayInfo,
  getPhotoRecordsFromEstimate,
  useEstimateStatusUpdater,
  useOptionsFromEstimateQuery,
  useRelevantEstimateData,
  useSaveEstimate,
} from './estimatesFlowUtils'
import { EstimatesPageContainer } from './EstimatesPageContainer'

type EditViewProps = {
  estimate: FetchedEstimate
}

const EditView = React.memo<EditViewProps>(({ estimate }) => {
  const { jobGuid } = useStrictContext(EstimatesContext)
  const defaultTaxRate = useMemo<PricebookTaxRateDto>(
    () => ({
      pricebookTaxRateGuid: estimate.taxRateGuid,
      rate: estimate.taxRate,
      name: estimate.taxRateName,
    }),
    [estimate.taxRate, estimate.taxRateGuid, estimate.taxRateName],
  )

  const mpOnsiteDiscounts = useMaintenancePlanJobOnsiteDiscount({
    jobGuid,
  })

  const options = useOptionsFromEstimateQuery(estimate.estimateOptions, true)

  const photoRecords = useMemo(
    () => getPhotoRecordsFromEstimate(estimate.estimatePhotos),
    [estimate.estimatePhotos],
  )

  return (
    <EstimateEditView
      status={estimate.status}
      displayId={estimate.displayId}
      defaultMessageHtml={estimate.messageHtml}
      defaultOptions={options}
      defaultTaxRate={defaultTaxRate}
      defaultDiscounts={mpOnsiteDiscounts}
      defaultPhotoRecords={photoRecords}
      defaultDynamicPricingType={estimate.dynamicPricingType}
      pointOfContact={{
        fullName: estimate.job.pointOfContact.fullName,
        firstName: estimate.job.pointOfContact.firstName,
        phoneNumber:
          estimate.job.pointOfContact.primaryPhoneNumber?.phoneNumber,
        email: estimate.job.pointOfContact.primaryEmailAddress?.emailAddress,
      }}
      location={{
        line1: estimate.job.location.address.line1,
        city: estimate.job.location.address.city,
        stateAbbreviation: estimate.job.location.address.stateAbbreviation,
        zipCode: estimate.job.location.address.zipCode,
      }}
    />
  )
})

type OverviewUIData = {
  primaryCta?: {
    label: string
    onClick: () => void
  }
  editCta?: {
    label: string
  }
}
type CarouselHeaderProps = {
  dots: React.ReactNode
}

const CarouselHeader = React.memo<CarouselHeaderProps>(({ dots }) => {
  const pageDimensions = useOnsitePageDimensions()
  const isMobile = useIsMobile()
  return (
    <div
      className={classNames('mx-auto mb-4 flex flex-1 flex-row items-center', {
        'px-4': isMobile,
      })}
      style={pageDimensions.style}
    >
      <div className="mr-4 flex-1 text-xl font-semibold">Options</div>
      {dots}
    </div>
  )
})

const renderCarouselHeader = (dots: React.ReactNode) => (
  <CarouselHeader dots={dots} />
)

type EstimateOverviewPageInnerProps = {
  estimate: FetchedEstimate
}

const EstimateOverviewPageInner = React.memo<EstimateOverviewPageInnerProps>(
  ({ estimate }) => {
    const linkedJobFFEnabled = useFeatureFlag('linkedJobs')

    const navigate = useNavigate()
    const isMobile = useIsMobile()

    const { embedded } = useContext(OnsiteEmbeddedContext)
    const { accountGuid, jobGuid, companyName } =
      useStrictContext(EstimatesContext)

    const options = useOptionsFromEstimateQuery(estimate.estimateOptions)

    const photoRecords = useMemo(
      () => getPhotoRecordsFromEstimate(estimate.estimatePhotos),
      [estimate],
    )

    const isAccepted = estimate.status === 'ACCEPTED'

    const selectedOption = useMemo(
      () => options.find(option => option.selected),
      [options],
    )

    const [updateEstimateStatus] = useEstimateStatusUpdater(estimate.status)

    const [presentMode, openPresentMode] = useQueryParamFlag('p')

    const onPresent = useCallback(async () => {
      openPresentMode()
      await updateEstimateStatus('REVIEWED')
    }, [openPresentMode, updateEstimateStatus])

    const [convertToInvoiceOpen, openConvertToInvoice, closeConvertToInvoice] =
      useModalState()

    const [sendModalOpen, openSendModal, closeSendModal] = useModalState()

    const { primaryCta, editCta } = useMemo<OverviewUIData>(() => {
      const presentDefault = {
        primaryCta: {
          label: embedded ? 'Send to Customer' : 'Present to Customer',
          onClick: embedded ? openSendModal : onPresent,
        },
        editCta: {
          label: 'Edit',
        },
      }

      switch (estimate.status) {
        case 'CREATED':
        case 'SENT':
        case 'REVIEWED':
          return presentDefault
        case 'ACCEPTED':
          return {
            primaryCta: {
              label: 'Create an Invoice',
              onClick: openConvertToInvoice,
            },
            editCta: {
              label: 'Revise',
            },
          }
        case 'VOIDED':
        case 'CLOSED':
        case 'EXPIRED':
          return {}
        default:
          throw new Error(`Unknown estimate status: ${estimate.status}`)
      }
    }, [
      embedded,
      estimate.status,
      onPresent,
      openConvertToInvoice,
      openSendModal,
    ])

    const {
      label: statusLabel,
      colorClassNames: statusColorClassNames,
      markAsOptions,
      canDelete,
    } = getEstimateV2StatusDisplayInfo(estimate.status)

    const [duplicateEstimate, isDuplicating] = useSaveEstimate()

    const [actionsModalOpen, openActionsModal, closeActionsModal] =
      useModalState()

    const [duplicateConfirmOpen, openDuplicateConfirm, closeDuplicateConfirm] =
      useModalState()

    const onDuplicate = useCallback(async () => {
      const newEstimateGuid = nextGuid()
      await duplicateEstimate({
        estimateGuid: newEstimateGuid,
        status: 'DRAFT',
        messageHtml: estimate.messageHtml,
        options: options.map(option => ({
          ...getDuplicatedOption(option),
          recommended: option.recommended,
        })),
        photoRecords,
        taxRate: {
          pricebookTaxRateGuid: estimate.taxRateGuid,
          rate: estimate.taxRate,
          name: estimate.taxRateName,
        },
        dynamicPricingType: estimate.dynamicPricingType,
      })

      navigate(CalculatePaths.estimatesEdit({ estimateGuid: newEstimateGuid }))
    }, [
      duplicateEstimate,
      estimate.dynamicPricingType,
      estimate.messageHtml,
      estimate.taxRate,
      estimate.taxRateGuid,
      estimate.taxRateName,
      navigate,
      options,
      photoRecords,
    ])

    const estimateDataContextValue = useMemo<EstimateDataContextType>(
      () => ({
        messageHtml: estimate.messageHtml ?? '',
        options,
        taxRate: {
          pricebookTaxRateGuid: estimate.taxRateGuid,
          rate: estimate.taxRate,
          name: estimate.taxRateName,
        },
        photoRecords,
        dynamicPricingType: estimate.dynamicPricingType,
        pointOfContact: {
          fullName: estimate.job.pointOfContact.fullName,
          firstName: estimate.job.pointOfContact.firstName,
          phoneNumber:
            estimate.job.pointOfContact.primaryPhoneNumber?.phoneNumber,
          email: estimate.job.pointOfContact.primaryEmailAddress?.emailAddress,
        },
        location: {
          line1: estimate.job.location.address.line1,
          city: estimate.job.location.address.city,
          stateAbbreviation: estimate.job.location.address.stateAbbreviation,
          zipCode: estimate.job.location.address.zipCode,
        },
      }),
      [
        estimate.messageHtml,
        estimate.taxRateGuid,
        estimate.taxRate,
        estimate.taxRateName,
        estimate.dynamicPricingType,
        estimate.job.pointOfContact.fullName,
        estimate.job.pointOfContact.firstName,
        estimate.job.pointOfContact.primaryPhoneNumber?.phoneNumber,
        estimate.job.pointOfContact.primaryEmailAddress?.emailAddress,
        estimate.job.location.address.line1,
        estimate.job.location.address.city,
        estimate.job.location.address.stateAbbreviation,
        estimate.job.location.address.zipCode,
        options,
        photoRecords,
      ],
    )
    const wisetackEnabled = useWisetackEnabled()

    const pageDimensions = useOnsitePageDimensions()

    const pageContainerRef = useRef<HTMLDivElement>(null)

    const [pageContainerWidth, setPageContainerWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setPageContainerWidth(width)
    }, [])

    useResizeObserver(pageContainerRef, onResize)

    const carouselMargin = useMemo(
      () =>
        isMobile
          ? 16
          : Math.max(
              (pageContainerWidth - pageDimensions.innerWidth) / 2,
              pageDimensions.margin,
            ),
      [
        isMobile,
        pageContainerWidth,
        pageDimensions.innerWidth,
        pageDimensions.margin,
      ],
    )

    const itemWidth = useMemo(
      // -32 gives 16px on either side
      () => (isMobile ? pageContainerWidth - 32 : CAROUSEL_ITEM_WIDTH),
      [isMobile, pageContainerWidth],
    )

    const [, onEdit] = useQueryParamFlag('edit')

    const estimateInfo: EstimateInfoContextType = useMemo(() => {
      const info: EstimateInfoContextType = {
        displayId: estimate.displayId,
        createdAt: estimate.createdAt,
        jobGuid: estimate.jobGuid,
        jobType: estimate.job.jobType.name,
        jobDisplayId: estimate.job.displayId,
        jobCreatedAt: estimate.job.createdAt,
        accountGuid: estimate.job.accountGuid,
        contactFullName: estimate.job.pointOfContact.fullName ?? 'Account',
        accountCreatedAt: estimate.job.account.createdAt,
        invoiceGuid: estimate.invoiceLink?.invoiceGuid,
        invoiceIssuedAt: estimate.invoiceLink?.invoice?.issuedAt,
        invoiceDisplayId: estimate.invoiceLink?.invoice?.displayId,
      }

      // Right now we're only allowing 1 linked job. This may increase in the future
      if (
        linkedJobFFEnabled &&
        !isNullish(estimate.linkedToJobs) &&
        estimate.linkedToJobs.length > 0
      ) {
        const linkedJob = estimate.linkedToJobs[0].job
        info.linkedJob = {
          jobGuid: linkedJob.jobGuid,
          jobDisplayId: linkedJob.displayId,
          jobType: linkedJob.jobType.name,
          jobCreatedAt: linkedJob.createdAt,
        }
      }

      return info
    }, [
      estimate.createdAt,
      estimate.displayId,
      estimate.invoiceLink?.invoice?.displayId,
      estimate.invoiceLink?.invoice?.issuedAt,
      estimate.invoiceLink?.invoiceGuid,
      estimate.job.account.createdAt,
      estimate.job.accountGuid,
      estimate.job.createdAt,
      estimate.job.displayId,
      estimate.job.jobType.name,
      estimate.job.pointOfContact.fullName,
      estimate.jobGuid,
      estimate.linkedToJobs,
      linkedJobFFEnabled,
    ])

    return (
      <EstimateDataContext.Provider value={estimateDataContextValue}>
        <EstimateInfoContext.Provider value={estimateInfo}>
          <EstimatesPageContainer
            containerRef={pageContainerRef}
            title={`Estimate #${estimate.displayId}`}
            statusText={statusLabel}
            statusColorClassName={statusColorClassNames}
          >
            <>
              {estimate.messageHtml ? (
                <OnsitePageSection title="Customer message">
                  <HtmlRenderer htmlContent={estimate.messageHtml} />
                </OnsitePageSection>
              ) : null}
              {photoRecords.length > 0 && (
                <OnsitePageSection title="Gallery">
                  <Gallery photoRecords={photoRecords} />
                </OnsitePageSection>
              )}
              <OnsitePageSection>
                {options.length > 1 ? (
                  <div
                    className="overflow-hidden"
                    style={{
                      margin: `0 -${carouselMargin}px`,
                    }}
                  >
                    <Carousel
                      renderHeaderWithDots={renderCarouselHeader}
                      mistyMargin={carouselMargin}
                      margin={carouselMargin}
                    >
                      {options.map(option => (
                        <div
                          key={option.optionGuid}
                          className="h-full"
                          style={{ width: `${itemWidth}px` }}
                        >
                          <BasicEstimateOption
                            index={option.seq}
                            option={option}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <BasicEstimateOption
                    index={selectedOption?.seq ?? 0}
                    option={selectedOption ?? options[0]}
                  />
                )}
              </OnsitePageSection>
              {wisetackEnabled && (
                <FinancingSection
                  accountGuid={accountGuid}
                  companyName={companyName}
                  jobGuid={jobGuid}
                />
              )}
            </>
            {isMobile ? (
              <MobileActionBar
                primaryCtaText={primaryCta?.label}
                primaryCtaOnClick={primaryCta?.onClick}
              >
                <MobileActionBarButton
                  icon={faEnvelope}
                  onClick={openSendModal}
                >
                  Send
                </MobileActionBarButton>
                {editCta && (
                  <MobileActionBarButton icon={faEdit} onClick={onEdit}>
                    {editCta.label}
                  </MobileActionBarButton>
                )}
                <MobileActionBarButton
                  icon={faCopy}
                  onClick={openDuplicateConfirm}
                >
                  Duplicate
                </MobileActionBarButton>

                <MobileActionBarButton
                  icon={faEllipsis}
                  onClick={openActionsModal}
                  name="actions-button"
                >
                  More
                </MobileActionBarButton>
              </MobileActionBar>
            ) : (
              <ActionBar>
                <Button
                  data-testid="actions-button"
                  className="min-w-10"
                  size="large"
                  icon={<FontAwesomeIcon icon={faEllipsis} />}
                  onClick={openActionsModal}
                />

                <Button
                  className="flex-1"
                  size="large"
                  icon={<FontAwesomeIcon icon={faCopy} />}
                  onClick={openDuplicateConfirm}
                >
                  Duplicate
                </Button>
                {editCta && (
                  <Button
                    className="flex-1"
                    size="large"
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={onEdit}
                  >
                    {editCta.label}
                  </Button>
                )}
                {(!embedded || isAccepted) && (
                  <Button
                    className="flex-1"
                    size="large"
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                    onClick={openSendModal}
                  >
                    Send
                  </Button>
                )}
                {primaryCta && (
                  <RightArrowButton
                    className="flex-[2]"
                    onClick={primaryCta.onClick}
                  >
                    {primaryCta.label}
                  </RightArrowButton>
                )}
              </ActionBar>
            )}
            {duplicateConfirmOpen && (
              <OnsiteConfirmModal
                onCancel={closeDuplicateConfirm}
                onConfirm={onDuplicate}
                open
                header="Duplicate estimate"
                confirmText="Yes, Duplicate"
                loading={isDuplicating}
              >
                Are you sure you want to duplicate this estimate and all of it's
                contents?
              </OnsiteConfirmModal>
            )}
            {presentMode
              ? createPortal(
                  <AcceptEstimateView estimate={estimate} />,
                  document.body,
                )
              : null}

            {actionsModalOpen && (
              <EstimateActionsModal
                estimate={estimate}
                onCancel={closeActionsModal}
                canDelete={canDelete}
                markAsOptions={markAsOptions}
              />
            )}
            {convertToInvoiceOpen && (
              <FinalizeInvoiceDrawer onClose={closeConvertToInvoice} />
            )}

            <SendEstimateModal
              open={sendModalOpen}
              status={estimate.status}
              onClose={closeSendModal}
              displayId={estimate.displayId}
              contactFirstName={estimate.job.pointOfContact.firstName}
              emailAddress={
                estimate.job.pointOfContact.primaryEmailAddress?.emailAddress
              }
              phoneNumber={
                estimate.job.pointOfContact.primaryPhoneNumber?.phoneNumber
              }
              address={estimate.job.location.address.line1}
            />
          </EstimatesPageContainer>
        </EstimateInfoContext.Provider>
      </EstimateDataContext.Provider>
    )
  },
)

export const EstimateOverviewPage = React.memo(() => {
  const estimateGuid = bzExpect(
    useParams().estimateGuid,
    'estimateGuid',
    'Estimate Guid is required',
  )

  const {
    isFetching: isRelevantDataFetching,
    companyDefaultTaxRateGuid,
    ...relevantData
  } = useRelevantEstimateData()

  const [{ data: estimateData, fetching: fetchingEstimateData, stale }] =
    useSubscription({
      query: ESTIMATE_DATA_SUBSCRIPTION,
      variables: {
        estimateGuid,
      },
    })

  const [editMode] = useQueryParamFlag('edit')

  // Show the loading spinner when we're loading the estimate data or the relevant data, but NOT if we are loading the
  // estimate data and already have it (meaning it changed). We don't want a loading spinner flashing every time we make
  // a change.
  if (
    (fetchingEstimateData && !estimateData) ||
    isRelevantDataFetching ||
    // Edge case: when we duplicate an estimate, it "redirects" to the overview page with the edit flag on but with a
    // new estimateGuid. This doesn't actually reload the page but just updates the state of this already-mounted
    // component. The subscription will rerun, but it will first render the edit view with the old data. It will then
    // rerender with the new data, but there are assumptions made about default values not changing that are broken. So
    // if the estimate data we have doesn't match the estimate we should be looking at, just show the loading spinner,
    // which will also force everything else to unmount.
    estimateGuid !== estimateData?.estimatesByPk?.estimateGuid
  ) {
    return <OnsitePageLoader />
  }

  if (!estimateData?.estimatesByPk) {
    return <Navigate to="/" />
  }
  if (estimateData.estimatesByPk.status === 'DRAFT') {
    if (stale) {
      return <OnsitePageLoader />
    }
  }

  return (
    <EstimatesContext.Provider
      value={{
        estimateGuid,
        jobGuid: estimateData.estimatesByPk.jobGuid,
        accountGuid: estimateData.estimatesByPk.job.accountGuid,
        locationGuid: estimateData.estimatesByPk.job.locationGuid,
        jobAppointmentGuid: estimateData.estimatesByPk.jobAppointmentGuid,
        ...relevantData,
      }}
    >
      {editMode || estimateData.estimatesByPk.status === 'DRAFT' ? (
        <EditView estimate={estimateData.estimatesByPk} />
      ) : (
        <EstimateOverviewPageInner estimate={estimateData.estimatesByPk} />
      )}
    </EstimatesContext.Provider>
  )
})
