import { z } from 'zod'
import { CompanyGuid, ForCompany, ForCompanyUser, Guid, UserGuid } from '..'
import { AsyncFn, IsoDateString } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts'
import { BzLinks, bzLinksSchema } from '../Links/Links'
import { bzOptional } from '../common-schemas'

export type FileGuid = Guid
export type FileUrl = string
export type FileUrn = string

export type FileFullId = {
  readonly fileGuid: FileGuid
  readonly companyGuid: CompanyGuid
}

export type FileMetadata = Record<string, string>

export type File = FileFullId & {
  readonly base64EncodedFile: string
  readonly fileName: string
  readonly metadata: FileMetadata
  readonly userGuid: UserGuid
  readonly fileSizeBytes?: number
  readonly fileTypeMime: string
  readonly preserveFileName?: boolean
}

export type FileWithExtension = File & {
  readonly extension: string
}

const FileStorageStrategySchema = z.enum(['GOOGLE_DRIVE', 'S3'])
export type FileStorageStrategy = z.infer<typeof FileStorageStrategySchema>

export const fileRecordSchema = z.object({
  fileGuid: guidSchema,
  companyGuid: guidSchema,
  fileName: z.string(),
  metadata: z.record(z.string(), z.string()),
  fileSizeBytes: bzOptional(z.number()),
  fileTypeMime: z.string(),
  cdnUrl: z.string(),
  resourceUrn: z.string(),
  createdByUserGuid: guidSchema,
  createdAt: isoDateStringSchema,
  storageStrategy: FileStorageStrategySchema,
})

export type FileRecord = Omit<File, 'base64EncodedFile'> & {
  readonly cdnUrl: FileUrl
  readonly resourceUrn: FileUrn
  readonly storageStrategy: FileStorageStrategy
  readonly createdAt: IsoDateString
}

export const fileFullIdSchema = z.object({
  fileGuid: guidSchema,
  companyGuid: guidSchema,
})

export const linkedFileSchema = fileFullIdSchema.extend({
  locationGuid: bzOptional(guidSchema),
  accountGuid: bzOptional(guidSchema),
  jobGuid: bzOptional(guidSchema),
  jobAppointmentGuid: bzOptional(guidSchema),
  apptAssignmentGuid: bzOptional(guidSchema),
})

export type LinkedFile = z.infer<typeof linkedFileSchema>

export const upsertFileRequestSchema = linkedFileSchema.extend({
  base64EncodedFile: z.string(),
  fileName: z.string(),
  metadata: z.record(z.string(), z.string()),
  userGuid: guidSchema,
  fileSizeBytes: z.number(),
  fileTypeMime: z.string(),
  extension: z.string(),
  preserveFileName: bzOptional(z.boolean()),
})

export type UpsertFileRequest = z.infer<typeof upsertFileRequestSchema>

export type FileLinks = BzLinks

export const fileLinksSchema = bzLinksSchema

export type FileRecordWithLinks = FileRecord & FileLinks

export type IFilesReader = AsyncFn<ForCompany<FileLinks>, FileRecordWithLinks[]>

export type ILinkedFileRecordWriter = AsyncFn<ForCompanyUser<Omit<FileRecordWithLinks, 'createdAt'>>, FileRecord>
export type ILinkedFileWriter = AsyncFn<ForCompanyUser<FileWithExtension & LinkedFile>>

export type ILinkedFileRecordDeleter = AsyncFn<FileGuid>
export type ILinkedFileDeleter = AsyncFn<FileGuid>

export const writeFileRecordRequestSchema = fileRecordSchema
  .omit({
    companyGuid: true,
    createdByUserGuid: true,
    createdAt: true,
  })
  .extend({
    preserveFileName: bzOptional(z.boolean()),
  })
  .merge(fileLinksSchema)

export type WriteFileRecordRequest = z.infer<typeof writeFileRecordRequestSchema>
