import React, { useCallback, useMemo } from 'react'
import { SelfServePaymentFormContext } from '../selfServePaymentUtils'

import { bzExpect } from '@breezy/shared'
import ReactDOM from 'react-dom'
import { trpc } from '../../../hooks/trpc'
import { useStrictContext } from '../../../utils/react-utils'
import ContactConfirmationDialog from '../ContactConfirmationDialog'
import { useContactQuery } from './useContactQuery'
import { useFullscreenIframe } from './useFullscreenIframe'

export const useEmbeddedLoanApplication = () => {
  const { loanRecord, invoice, contactGuid } = useStrictContext(
    SelfServePaymentFormContext,
  )
  const { iframe: wisetackLoanAppIframe, toggleIframe } = useFullscreenIframe()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false)

  const initiateLoanAppMutation =
    trpc.financing['unauth:initiate-loan-application'].useMutation()
  const { contact } = useContactQuery(invoice.companyGuid, contactGuid)

  const toggle = useCallback(() => {
    if (loanRecord?.loanApplicationLink) {
      toggleIframe(loanRecord.loanApplicationLink)
    } else {
      setIsConfirmDialogOpen(true)
    }
  }, [loanRecord?.loanApplicationLink, toggleIframe])

  const init = useCallback(async () => {
    await initiateLoanAppMutation.mutate(
      {
        invoiceGuid: invoice.invoiceGuid,
        companyGuid: invoice.companyGuid,
        contactGuid: bzExpect(contactGuid),
      },
      {
        onSuccess: data => {
          toggle()
          toggleIframe(data.loanApplicationLink)
        },
        onError: error => {
          console.error('error', error)
        },
      },
    )
  }, [
    contactGuid,
    initiateLoanAppMutation,
    invoice.companyGuid,
    invoice.invoiceGuid,
    toggle,
    toggleIframe,
  ])

  const loading = initiateLoanAppMutation.isLoading

  const embeddedLoanApplicationContent = useMemo(() => {
    if (!loanRecord?.loanApplicationLink && isConfirmDialogOpen) {
      return (
        <ContactConfirmationDialog
          onConfirm={init}
          onCancel={() => setIsConfirmDialogOpen(false)}
          contact={{
            firstName: bzExpect(contact?.firstName),
            firstNameInitial: bzExpect(contact?.firstNameInitial),
          }}
          loading={loading}
        />
      )
    }

    return wisetackLoanAppIframe
  }, [
    contact?.firstName,
    contact?.firstNameInitial,
    init,
    isConfirmDialogOpen,
    loading,
    loanRecord?.loanApplicationLink,
    wisetackLoanAppIframe,
  ])

  const embeddedLoanApplication = useMemo(() => {
    return ReactDOM.createPortal(embeddedLoanApplicationContent, document.body)
  }, [embeddedLoanApplicationContent])

  return { wisetackLoanAppIframe, embeddedLoanApplication, toggle }
}
