import { CommonDiscountUsc, DiscountType } from '@breezy/shared'
import { faDollar, faPercent } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'

type DiscountCircleProps = {
  flat?: boolean
  className?: string
}

export const DiscountCircle = React.memo<DiscountCircleProps>(
  ({ flat, className }) => (
    <div
      className={classNames(
        'flex h-6 w-6 items-center justify-center rounded-full border border-solid border-white bg-bz-green-200',
        className,
      )}
    >
      <FontAwesomeIcon
        icon={flat ? faDollar : faPercent}
        className="h-[14px] w-[14px] text-bz-green-800"
      />
    </div>
  ),
)

type DiscountCirclesComboProps = {
  discounts?: CommonDiscountUsc[]
  className?: string
}

export const DiscountCirclesCombo = React.memo<DiscountCirclesComboProps>(
  ({ discounts, className }) => {
    const [hasFlat, hasRate] = useMemo(() => {
      let hasFlat = false
      let hasRate = false
      for (const discount of discounts ?? []) {
        if (discount.type === DiscountType.FLAT) {
          hasFlat = true
        } else {
          hasRate = true
        }
        if (hasFlat && hasRate) {
          break
        }
      }
      return [hasFlat, hasRate]
    }, [discounts])
    return (
      <div className={classNames('flex flex-row', className)}>
        {hasFlat && <DiscountCircle flat />}
        {hasRate && (
          <DiscountCircle
            className={classNames({ 'ml-[-6px]': hasFlat && hasRate })}
          />
        )}
      </div>
    )
  },
)
