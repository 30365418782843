import {
  LocationContact,
  LocationContactRelationType,
  locationContactRelationTypeToDisplayName,
} from '@breezy/shared'
import React from 'react'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'

const toStatusTagColor = (
  status: LocationContactRelationType,
): StatusTagColor => {
  switch (status) {
    case 'HOME_OWNER':
      return 'cyan'
    case 'PREVIOUS_HOME_OWNER':
      return 'red'
    case 'TENANT__OCCUPANT':
      return 'blue'
    case 'PREVIOUS_TENANT__OCCUPANT':
      return 'red'
    case 'PROPERTY_MANAGER':
      return 'orange'
    case 'BUILDER':
      return 'purple'
    case 'HOA_CONTACT':
      return 'magenta'
    case 'REALTOR__REAL_STATE_AGENT':
      return 'yellow'
    case 'PLUMBER':
      return 'green'
    case 'ELECTRICIAN':
      return 'yellow'
    case 'CONTRACTOR':
      return 'lime'
    case 'VENDOR':
      return 'gray'
    case 'UTILITY_PROVIDER':
      return 'gray'
    default:
      return 'gray'
  }
}

export const LocationContactTag = React.memo<{
  locationContactRelationType: LocationContactRelationType
}>(({ locationContactRelationType }) => {
  return (
    <StatusTag
      text={locationContactRelationTypeToDisplayName(
        locationContactRelationType,
      )}
      color={toStatusTagColor(locationContactRelationType)}
    />
  )
})

export const LocationContactTagGroup = React.memo<{
  locationContact: LocationContact
}>(({ locationContact }) => {
  if (locationContact.locationContactRelationTypes.length === 0) return null

  return (
    <div className="flex items-center space-x-2">
      {locationContact.locationContactRelationTypes.map(relationType => {
        return (
          <LocationContactTag
            key={relationType}
            locationContactRelationType={relationType}
          />
        )
      })}
    </div>
  )
})
