import { isNullishOrEmpty } from '../../common'
import { JobClass } from '../Job'

export const JOB_CLASSES_THAT_TRIGGER_JOB_COMPLETION_CAMPAIGN = [
  JobClass.INSTALL,
  JobClass.SERVICE,
  JobClass.MAINTENANCE,
]

type JobCompletionEmailTemplateParameters = {
  bodyContent: string
  customerName: string
  signOff: string
  serviceLocation: string
  jobDescription?: string
  jobDateAndTime: string
  yelpReviewUrl?: string
  googleReviewUrl?: string
  facebookReviewUrl?: string
}

export const generateJobCompletionEmailContent = (params: JobCompletionEmailTemplateParameters) => {
  const reviewUrls = [params.yelpReviewUrl, params.googleReviewUrl, params.facebookReviewUrl]
  const validReviewUrlCount = reviewUrls.filter(url => !isNullishOrEmpty(url)).length
  let askForFeedbackText: string = ''
  if (validReviewUrlCount > 0) {
    askForFeedbackText = `
      <p><strong>We'd love your feedback!</strong></p>
      <p>Your feedback is crucial in helping us improve and serve you better. If you have a moment, we'd appreciate it if you could leave us reviews below. It only takes a minute, but it means the world to us!</p>
    `

    if (!isNullishOrEmpty(params.yelpReviewUrl)) {
      askForFeedbackText += `
        <a key="yelp" href="${params.yelpReviewUrl}" style="background-color: #d32323; color: white; padding: 10px 20px; text-decoration: none; font-weight: bold; border-radius: 5px; display: inline-block;">
          Yelp
        </a>
      `
    }

    if (!isNullishOrEmpty(params.googleReviewUrl)) {
      askForFeedbackText += `
        <a key="google" href="${params.googleReviewUrl}" style="padding: 10px 20px; color: white; text-decoration: none; border-radius: 5px; text-align: center; display: inline-block; font-weight: bold; background-color: #4285F4;">
          Google
        </a>
      `
    }

    if (!isNullishOrEmpty(params.facebookReviewUrl)) {
      askForFeedbackText += `
        <a key="facebook" href="${params.facebookReviewUrl}" style="padding: 10px 20px; color: white; text-decoration: none; border-radius: 5px; text-align: center; display: inline-block; font-weight: bold; background-color: #3b5998;">
          Facebook
        </a>
      `
    }
  }

  return `
    <p>Hello ${params.customerName},</p>
    <p>Here's a summary of the work we completed:</p>
    <ul>
      ${params.serviceLocation ? `<li><u>Service Location:</u> ${params.serviceLocation}</li>` : ''}
      ${params.jobDescription ? `<li><u>Job:</u> ${params.jobDescription}</li>` : ''}
      ${params.jobDateAndTime ? `<li><u>Appointment Date & Time:</u> ${params.jobDateAndTime}</li>` : ''}
    </ul>
    ${params.bodyContent}
    <p>Warm regards,</p>
    <p>${params.signOff}</p>
    ${askForFeedbackText}
  `
}

export const DEFAULT_JOB_COMPLETED_CUSTOM_EMAIL_CONTENT =
  `<p>We just wanted to extend our sincerest thanks for choosing us for your HVAC needs. We hope that our service met your expectations and that your system is now functioning smoothly.</p>` +
  `<p>If you encounter any issues, feel free to contact our support team using the contact information at the bottom of this email</p>` +
  `<p>Thank you once again for your trust in us. We look forward to serving you in the future!</p>`
