import React from 'react'
import {
  OnsitePageSection,
  OnsitePageSectionProps,
} from '../../adam-components/OnsitePage/OnsitePageSection'

type MaintenancePlanWizardPageSectionProps = OnsitePageSectionProps

export const MaintenancePlanWizardPageSection =
  React.memo<MaintenancePlanWizardPageSectionProps>(
    ({ children, padding = 'large-mobile', ...props }) => {
      return (
        <OnsitePageSection {...props} padding={padding}>
          {children}
        </OnsitePageSection>
      )
    },
  )
