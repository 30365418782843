import { InputNumber } from 'antd'
import { forwardRef, useCallback } from 'react'
import { Styled } from '../../utils/Stylable'

const nonNumericRegex = /[^\d.-]/g
const cleanedFloatValue = (value: string | undefined): number => {
  const intermediate = (value ?? '').replace(nonNumericRegex, '')
  const num = Number.parseFloat(intermediate)
  if (isNaN(num)) {
    return 0
  }

  if (num < 0) {
    return 0
  }

  return num
}

type PercentInputNumberProps = {
  value: number | undefined
  // Use `any` type because that's the typing that react-hook-form's Controller uses
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
}
const PercentInputNumber = forwardRef<
  HTMLInputElement | null,
  Styled<PercentInputNumberProps>
>(({ style, ...forwardedProps }, ref) => {
  const formatter = useCallback(
    (formValue: unknown, { userTyping }: { userTyping: boolean }): string => {
      const value = `${formValue}`

      if (userTyping) {
        return value
      }
      const num = cleanedFloatValue(value)
      if (isNaN(num)) {
        return ''
      }
      return `${num.toFixed(2)}%`
    },
    [],
  )

  return (
    <InputNumber
      {...forwardedProps}
      ref={ref}
      min={0 as number}
      max={100 as number}
      parser={value => (!value ? 0 : Number.parseFloat(value.replace('%', '')))}
      formatter={formatter}
      precision={2}
      style={style}
    />
  )
})

export default PercentInputNumber
