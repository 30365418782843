import {
  PermissionV1,
  PermissionV2,
  principalHasPermission,
} from '@breezy/shared'
import { usePrincipalUser } from '../../providers/PrincipalUser'

export const useIsAuthorized = () => {
  const { principal } = usePrincipalUser()
  return (permission: PermissionV1 | PermissionV2) => {
    if (!principal) return false

    return principalHasPermission(principal, permission)
  }
}

export const useIsAuthorizedTo = (permission: PermissionV1 | PermissionV2) => {
  const isAuthorized = useIsAuthorized()
  return isAuthorized(permission)
}
