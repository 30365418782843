import { z } from 'zod'
import { AsyncFn, HtmlString, IsoDateString, LocalDateString, TimeZoneId } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { Account } from '../Accounts/Account'
import { CompanyGuidContainer, ForCompany } from '../Company/Company'
import { CommonDiscount } from '../Discounts/DiscountTypes'
import { InvoiceGuid, InvoiceTerm } from '../Finance/Invoicing/InvoiceTypes'
import { ExternalPaymentId, PaymentRecordGuid, PaymentViewModel } from '../Finance/Payments/PaymentTypes'
import { PayoutEnriched } from '../Finance/Payouts/PayoutTypes'
import { CartItem, CartItemGuid, CartItemType } from '../Finance/Transactions/TransactionTypes'
import { EntityIdContainer, Guid, GuidsCollectionContainer, bzOptional } from '../common-schemas'

type QboSync<T> = AsyncFn<ForCompany<T>, Required<QboSyncData>>
type QboSyncAsync<T> = AsyncFn<ForCompany<T>>

export type QboSyncInfo = {
  foreignAppEntityId: string
  foreignAppSyncToken: string
}

export const syncAccountRequestSchema = z.object({
  accountGuid: guidSchema,
  // If the resource in the finance app was edited in the finance app since we last
  // updated it, we will return an error warning the user we might overwrite changes. If
  // they provide the force flag, we don't send the error and overwrite the finance app
  // entity with the latest breezy state.
  force: bzOptional(z.boolean()),
})

export type SyncAccountRequest = z.infer<typeof syncAccountRequestSchema>
export type QboAccountSyncByAccountGuid = AsyncFn<ForCompany<SyncAccountRequest>, Required<QboSyncData>>

export type QboEntity = EntityIdContainer & {
  syncToken: string
}

export type QboAccountUpsertRequest = ForCompany<{
  account: Account
  syncInfo?: QboSyncInfo
  // Same concept as syncAccountRequestSchema
  force?: boolean
}>
export type QboAccountSync = AsyncFn<QboAccountUpsertRequest, Required<QboEntity>>

export type QboSyncData = {
  syncInfo?: ForCompany<
    QboSyncInfo & {
      breezyIdentifier: string
      breezyEntityType: string
      lastSyncedAt: IsoDateString
    }
  >
}

export type QboSyncDataRequest = ForCompany<{
  breezyIdentifier: string
}>

export type QboAuthorizationData = {
  authorizationData?: ForCompany<{
    accessToken: string
    refreshToken: string
    realmId: string
    accessTokenExpireAt: IsoDateString
    refreshTokenExpireAt: IsoDateString
    /** If the refresh tokens are behind, we receive an invalid grant on a refresh request.
     * In that case, with reasonable certainty we expect all future auth calls to fail and the tokens to need to be re-authorized.
     * This flag is used to indicate that the tokens are invalid and should be re-authorized.
     * However, currently we don't want to throw away the other data, in case it might work, or to help with troubleshooting.
     */
    isInvalid?: boolean
  }>
}

export const syncInvoiceRequestSchema = z.object({
  invoiceGuid: guidSchema,
  userGuid: bzOptional(guidSchema),
  // Same concept as syncAccountRequestSchema
  force: bzOptional(z.boolean()),
})
export type SyncInvoiceRequest = z.infer<typeof syncInvoiceRequestSchema>
export type QboInvoiceSync = QboSync<SyncInvoiceRequest>
export type QboInvoiceSyncAsync = QboSyncAsync<SyncInvoiceRequest>

export const syncPayoutRequestSchema = z.object({
  payoutGuid: guidSchema,
  // Same concept as syncAccountRequestSchema
  force: bzOptional(z.boolean()),
})
export type SyncPayoutRequest = z.infer<typeof syncPayoutRequestSchema>
export type QboPayoutSync = QboSync<SyncPayoutRequest>
export type QboCompatInvoice = {
  invoiceGuid: string
  items: CartItem[]
  discount: CommonDiscount
  issuedAt: IsoDateString
  dueAt: IsoDateString

  summary?: string
  serviceCompletionDate?: LocalDateString

  // Totals
  taxAmountUsd: number
  discountAmountUsd: number
}
export type QboInvoiceUpsertRequest = ForCompany<{
  companyTimeZoneId: TimeZoneId
  invoice: QboCompatInvoice
  foreignAppAccountId: string
  foreignAppTaxCodeId: string
  foreignAppSalesTermId: string
  itemNameToForeignItemIdMap: Record<string, string>
  syncInfo?: QboSyncInfo
  // Same concept as syncAccountRequestSchema
  force?: boolean
}>

type QBOEntityDeleter = AsyncFn<ForCompany<QboSyncInfo>, void>
export type QBOInvoiceDeleter = QBOEntityDeleter
export type QBODepositDeleter = QBOEntityDeleter
export type QBOPaymentDeleter = QBOEntityDeleter

export type QboDepositUpsertRequest = ForCompany<{
  companyTimeZoneId: TimeZoneId
  payout: PayoutEnriched
  accounts: QboPayoutAccounts
  breezyPaymentGuidToQboPaymentIdsMap: Record<PaymentRecordGuid, ExternalPaymentId>
}>

export type QboTaxCodeRequest = ForCompany<{
  taxRateName: string
}>

export type QboSalesTermRequest = ForCompany<{
  term: InvoiceTerm
}>

export type QboItemMapItem = {
  itemGuid: CartItemGuid
  name: string
  description: HtmlString
  type: CartItemType
}

export type QboItemMapRequest = ForCompany<{
  items: QboItemMapItem[]
  incomeAccountMap: Record<Guid, string>
  // Note: Eventually we will need these. But, for backwards compatibility,
  // we may not have these configured yet.
  cartItemTypeAccounts: QboCartItemTypeAccounts | undefined
}>

export type QboPaymentUpsertRequest = ForCompany<{
  companyTimeZoneId: TimeZoneId
  payment: PaymentViewModel
  foreignAppAccountId: string
  foreignAppInvoiceId: string
  syncInfo?: QboSyncInfo
  // Same concept as syncAccountRequestSchema
  force?: boolean
}>

export type QboPaymentSync = AsyncFn<QboPaymentUpsertRequest, Required<QboEntity>>

export const QBO_ERROR_CODES = {
  /* Breezy defined */
  INTEGRATION: '1001',
  AUTH: '1002',
  INACTIVE: '1005',

  /* Quickbooks defined */
  // https://developer.intuit.com/app/developer/qbo/docs/develop/troubleshooting/error-codes#:~:text=the%20CustomSalesTax%20ID.-,6240,-Duplicate%20Name%20Exists
  DUPLICATE: '6240',
  // https://developer.intuit.com/app/developer/qbo/docs/develop/troubleshooting/error-codes#:~:text=a%20deprecated%20field.-,5010,-Stale%20Object
  STALE: '5010',
  // https://developer.intuit.com/app/developer/qbo/docs/develop/troubleshooting/error-codes#:~:text=feature%20isn%E2%80%99t%20supported.-,6000,-Business%20Validation%20Error
  BUSINESS_VALIDATION: '6000',
} as const

export type QboErrorCode = (typeof QBO_ERROR_CODES)[keyof typeof QBO_ERROR_CODES]

export const buildQboErrorMessage = (code: QboErrorCode, message: string) => `Error code: ${code} - ${message}`

export const parseQboErrorInfo = (message: string): [code: QboErrorCode, message: string] | undefined => {
  const match = message.match(/Error code: (\d+) - (.*)/)
  if (match) {
    return [match[1] as QboErrorCode, match[2]]
  }
}

export const qboAccountStaleRequestSchema = z.object({
  accountGuid: bzOptional(guidSchema),
})

export type QboAccountStaleRequest = z.infer<typeof qboAccountStaleRequestSchema>

export const qboInvoiceStaleRequestSchema = z.object({
  invoiceGuid: bzOptional(guidSchema),
})

export type QboInvoiceStaleRequest = z.infer<typeof qboInvoiceStaleRequestSchema>

export type QboNestedUpdatedAtSubObject = {
  updatedAt: IsoDateString
  [key: string]: string | QboNestedUpdatedAtSubObject | QboNestedUpdatedAtSubObject[] | undefined
}

export type QboNestedUpdatedAtObject = QboNestedUpdatedAtSubObject & {
  guid: Guid
}

export type QboNestedAccountUpdatedAtsResponse = { accounts: QboNestedUpdatedAtObject[] }

export type QboStaleInvoiceDataForCompanyResponse = {
  invoices: { lastAlteredAt: IsoDateString; invoiceGuid: InvoiceGuid }[]
}

export type QboPaymentsUpdatedAtsResponse = {
  payments: { createdAt: IsoDateString; invoiceGuid: InvoiceGuid }[]
}

export type QboSyncDataForInvoicesResponse = {
  syncInfo: { syncedAt: IsoDateString; invoiceGuid: InvoiceGuid }[]
}

export type QboStaleInfo = {
  stale: boolean
  syncedAt?: IsoDateString
  updatedAt: IsoDateString
}

export type QboStaleResponse = Record<Guid, QboStaleInfo>

export type QboIncomeAccount = {
  name: string
  id: string
  parentId?: string
  accountType: string
}

export type QboPricebookIncomeAccountsRequest = {
  itemGuids: Guid[]
}

export type QboPricebookIncomeAccountsResponse = {
  incomeAccounts: {
    pricebookItemGuid: Guid
    incomeAccountId?: string
  }[]
}

export const qboPayoutAccountsSchema = z.object({
  depositAccountId: z.string().min(1),
  feesExpenseAccountId: z.string().min(1),
  refundsExpenseAccountId: z.string().min(1),
  paymentProcessorBalanceAccountId: z.string().min(1),
})

export type QboPayoutAccounts = z.infer<typeof qboPayoutAccountsSchema>
export type QboPayoutAccountsConfigReaderNoResults = {
  noResults: true
}
export type QboPayoutAccountsConfigReader = AsyncFn<
  CompanyGuidContainer,
  QboPayoutAccounts | QboPayoutAccountsConfigReaderNoResults
>

export const qboPayoutAccountsConfigReaderNoResults = (
  data: QboPayoutAccounts | QboPayoutAccountsConfigReaderNoResults,
): data is QboPayoutAccountsConfigReaderNoResults => !!(data as QboPayoutAccountsConfigReaderNoResults).noResults
export type QboPayoutAccountsConfigWriter = AsyncFn<ForCompany<QboPayoutAccounts>, void>

export const qboCartItemTypeAccountsSchema = z.object({
  defaultItemQboIncomeAccountId: z.string().nonempty(),
  serviceItemDefaultQboIncomeAccountId: bzOptional(z.string().nonempty()),
  materialItemDefaultQboIncomeAccountId: bzOptional(z.string().nonempty()),
  equipmentItemDefaultQboIncomeAccountId: bzOptional(z.string().nonempty()),
  laborItemDefaultQboIncomeAccountId: bzOptional(z.string().nonempty()),
  membershipItemDefaultQboIncomeAccountId: bzOptional(z.string().nonempty()),
})

export type QboCartItemTypeAccounts = z.infer<typeof qboCartItemTypeAccountsSchema>
export type QboCartItemTypeAccountsReader = AsyncFn<CompanyGuidContainer, QboCartItemTypeAccounts | undefined>

export type QboSyncDataWriter = AsyncFn<QboSyncData>
export type QboSyncDataReader = AsyncFn<QboSyncDataRequest, QboSyncData>
export type QboSyncDataMultiReader = AsyncFn<ForCompany<GuidsCollectionContainer>, Record<Guid, QboSyncData>>

export type QboDepositWriter = AsyncFn<QboDepositUpsertRequest, QboEntity>

export type CompanyQboConfig = {
  qboEnabled: boolean
  qboAutoSyncInvoiceOnFullyPaid?: boolean
  qboAutoSyncInvoiceOnIssued?: boolean
  qboAutoSyncInvoiceOnPayment?: boolean
}
export type CompanyQboConfigReader = AsyncFn<CompanyGuidContainer, CompanyQboConfig>

export const createPaymentQboLink = (qboId: string) => `https://app.qbo.intuit.com/app/recvpayment?txnId=${qboId}`
export const createInvoiceQboLink = (qboId: string) => `https://app.sandbox.qbo.intuit.com/app/invoice?txnId=${qboId}`
export const createPayoutQboLink = (qboId: string) => `https://app.sandbox.qbo.intuit.com/app/deposit?txnId=${qboId}`
