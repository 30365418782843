import { z } from 'zod'
import {
  AsyncFn,
  BzDateFns,
  IsoDateString,
  ParsedRRule,
  TimeZoneId,
  clamp,
  getDatesInRangeForRRule,
  isNullish,
  parseRRule,
} from '../../common'
import { guidSchema } from '../../contracts/_common'
import { AssignmentStatus } from '../Appointments/Appointments'
import { ForCompanyUser } from '../Company/Company'
import { TimeWindowDto } from '../DateTime/TimeWindow'
import { UserGuidContainer } from '../Users/User'
import { bzOptional } from '../common-schemas'

export type CompanyTimesheetConfig = {
  timesheetsEnabled: boolean
  timesheetPayPeriodBeginRrule: string
  timesheetPayPeriodBeginAnchor: IsoDateString
}

export const TIMESHEET_ENTRY_ACTIVITY_NAMES = ['IDLE', 'DRIVE-TIME', 'ON-SITE', 'LUNCH'] as const
const TIMESHEET_ENTRY_ACTIVITY_NAMES_SET = new Set<string>(TIMESHEET_ENTRY_ACTIVITY_NAMES)
export type TimesheetEntryActivityName = (typeof TIMESHEET_ENTRY_ACTIVITY_NAMES)[number]

export const timesheetEntryActivityDisplayName = (n: TimesheetEntryActivityName): string => {
  switch (n) {
    case 'IDLE':
      return 'Idle'
    case 'DRIVE-TIME':
      return 'Drive Time'
    case 'ON-SITE':
      return 'On Site'
    case 'LUNCH':
      return 'Lunch'
  }
}

export const getTimesheetEntryActivityNameOrCustom = (n?: string): TimesheetEntryActivityName | 'CUSTOM' => {
  if (!n) return 'CUSTOM'
  if (TIMESHEET_ENTRY_ACTIVITY_NAMES_SET.has(n)) return n as TimesheetEntryActivityName
  return 'CUSTOM'
}

export const isPayableTimesheetEntryActivity = (n?: TimesheetEntryActivityName): boolean => {
  return !!n && n !== 'LUNCH'
}

export const formatTimesheetActivityName = (s?: string) => {
  if (!s) return 'Missing'
  if (TIMESHEET_ENTRY_ACTIVITY_NAMES_SET.has(s))
    return timesheetEntryActivityDisplayName(s as TimesheetEntryActivityName)
  return s
}

export const timesheetEntryWriterInputSchema = z.object({
  companyGuid: guidSchema,
  userGuid: guidSchema,
  activityName: z.enum(TIMESHEET_ENTRY_ACTIVITY_NAMES),
  isPayable: z.boolean(),
  startTime: z.string(),
  startTimeOverride: bzOptional(z.string()),
  endTime: bzOptional(z.string()),
  endTimeOverride: bzOptional(z.string()),
  createdByUserGuid: bzOptional(guidSchema),
  updatedByUserGuid: bzOptional(guidSchema),
  linkData: z.object({
    jobGuid: bzOptional(guidSchema),
    jobAppointmentGuid: bzOptional(guidSchema),
    jobAppointmentAssignmentGuid: bzOptional(guidSchema),
  }),
})

export type TimesheetEntryWriterInput = z.infer<typeof timesheetEntryWriterInputSchema>

export type TimesheetEntryWriter = AsyncFn<TimesheetEntryWriterInput>

export const createTimeSheetEntryWriterInputForJobAssignment = (input: {
  companyGuid: string
  userGuid: string
  isPayable: boolean
  assignmentStatus: AssignmentStatus
  linkData: {
    jobGuid?: string
    jobAppointmentGuid?: string
    jobAppointmentAssignmentGuid?: string
  }
}): TimesheetEntryWriterInput => {
  const { companyGuid, userGuid, isPayable, assignmentStatus, linkData } = input
  const timesheetEntryWriterInput: TimesheetEntryWriterInput = {
    companyGuid,
    userGuid,
    isPayable,
    startTime: BzDateFns.nowISOString(),
    activityName: 'IDLE',
    linkData: {},
  }

  switch (assignmentStatus) {
    case 'EN_ROUTE':
      timesheetEntryWriterInput.activityName = 'DRIVE-TIME'
      timesheetEntryWriterInput.linkData = {
        jobGuid: linkData.jobGuid,
        jobAppointmentGuid: linkData.jobAppointmentGuid,
        jobAppointmentAssignmentGuid: linkData.jobAppointmentAssignmentGuid,
      }
      break
    case 'IN_PROGRESS':
      timesheetEntryWriterInput.activityName = 'ON-SITE'
      timesheetEntryWriterInput.linkData = {
        jobGuid: linkData.jobGuid,
        jobAppointmentGuid: linkData.jobAppointmentGuid,
        jobAppointmentAssignmentGuid: linkData.jobAppointmentAssignmentGuid,
      }
      break
    default:
      break
  }
  return timesheetEntryWriterInput
}
export type TimesheetEntryTimeClockStatusUpdaterInput = ForCompanyUser<UserGuidContainer>
export type TimesheetEntryTimeClockStatusUpdater = AsyncFn<TimesheetEntryTimeClockStatusUpdaterInput>

export type PayPeriodConfig = {
  baseConfig: CompanyTimesheetConfig
  currentPayPeriod: TimeWindowDto
  rrule: ParsedRRule
}
export const FallbackCompanyTimesheetsConfig: CompanyTimesheetConfig = {
  timesheetsEnabled: false,
  timesheetPayPeriodBeginRrule: 'FREQ=WEEKLY;BYDAY=FR;INTERVAL=2',
  timesheetPayPeriodBeginAnchor: BzDateFns.nowISOString(),
}

export const DefaultPayPeriodConfig: PayPeriodConfig = {
  baseConfig: FallbackCompanyTimesheetsConfig,
  currentPayPeriod: {
    start: BzDateFns.nowISOString(),
    end: BzDateFns.addDays(BzDateFns.now(BzDateFns.UTC), 7).toISOString(),
  },
  rrule: parseRRule('FREQ=DAILY'),
}

const maxExpectedPayPeriodDays = 365
export const getPayPeriodWindowForDay = (
  cfg: CompanyTimesheetConfig,
  day: IsoDateString,
  tzId: TimeZoneId,
): TimeWindowDto | undefined => {
  const specifiedDay = BzDateFns.parseISO(day, tzId)
  const rrule = parseRRule(cfg.timesheetPayPeriodBeginRrule)
  const endAnchor = cfg.timesheetPayPeriodBeginAnchor
  const originalDate = BzDateFns.parseISO(endAnchor, tzId)

  const rrMatchesEnd = getDatesInRangeForRRule({
    rrule,
    originalDate,
    start: BzDateFns.subDays(specifiedDay, maxExpectedPayPeriodDays),
    end: BzDateFns.addDays(specifiedDay, maxExpectedPayPeriodDays),
    allowBeforeOriginal: true,
  })

  const endMatchIndex = rrMatchesEnd.findIndex(d => BzDateFns.isAfter(d, specifiedDay))
  const previousEndIndex = endMatchIndex - 1

  const finalEnd = rrMatchesEnd[endMatchIndex]
  const finalPreviousEnd = rrMatchesEnd[previousEndIndex]

  if (!finalEnd || !finalPreviousEnd) {
    return undefined
  }

  const result = {
    start: BzDateFns.formatISO(finalPreviousEnd, tzId),
    end: BzDateFns.formatISO(BzDateFns.subDays(finalEnd, 1), tzId),
  }

  return result
}

const MAX_PAYABLE_MINUTES_PER_DAY = 1439 as const // (60 min * 23) + 59 min

/*
 * Calculates the total amount of minutes for a list of timesheet entries (for a given time zone).
 *
 * @param entries The entries to calculate the total number of minutes for
 * @param tz The timezone for the given entries
 * @param maxPayableMinutesPerDay The limit for the number of minutes per day for the timesheets. This defaults to 1439 ((60 min * 23) + 59 min)
 */
export const getTotalMinutesPerDayForTimesheetEntries = (
  entries: { startTime: IsoDateString; endTime?: IsoDateString; isPayable: boolean; deletedAt?: IsoDateString }[],
  tz: TimeZoneId,
  maxPayableMinutesPerDay: number = MAX_PAYABLE_MINUTES_PER_DAY,
): Record<string, number> => {
  const minutesPerDay: Record<string, number> = {}

  for (const entry of entries) {
    if (isNullish(entry.endTime) || !isNullish(entry.deletedAt) || !entry.isPayable) {
      continue
    }

    const today = BzDateFns.formatFromISO(entry.startTime, 'yyyy-MM-dd', tz)

    if (isNullish(minutesPerDay[today])) {
      minutesPerDay[today] = 0
    }

    let entryLength: number
    if (BzDateFns.isSameDayWithTz(entry.startTime, entry.endTime, tz)) {
      const entryStartTimeDate = BzDateFns.parseISO(entry.startTime, tz)
      const entryEndTimeDate = BzDateFns.parseISO(entry.endTime, tz)
      entryLength = BzDateFns.differenceInMinutes(entryEndTimeDate, entryStartTimeDate)
    } else {
      const entryStartTimeDate = BzDateFns.parseISO(entry.startTime, tz)
      const entryStartTimeEndOfDay = BzDateFns.endOfDay(entryStartTimeDate)
      entryLength = BzDateFns.differenceInMinutes(entryStartTimeEndOfDay, entryStartTimeDate)
    }

    minutesPerDay[today] = clamp(minutesPerDay[today] + entryLength, 0, maxPayableMinutesPerDay)
  }

  return minutesPerDay
}
