import { R } from './ramda'
export type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] }

// This takes an object, makes all the keys optional, and for anything that is `| null`,
// instead make it `| undefined`. This is meant to be a utility for the next type.
type UndefinedifyNulls<T> = {
  [K in keyof T]?: null extends T[K] ? NonNullable<T[K]> | undefined : T[K]
}

// Takes any keys that are "| null" and makes them optional and "| undefined"
type UndefinedifiedObject<T> = T extends object
  ? Omit<T, NullableKeys<T>> & UndefinedifyNulls<Pick<T, NullableKeys<T>>>
  : T

// In a few places we make helper functions that take parameters with the result types
// generated from pgtyped. Our DB actually returned those but in UndefinedifiedObject
// because we convert the nulls to undefined. So anywhere we use those generated types,
// to make them actually match what comes out of the DB we need to use this. Aliasing
// the name because this will describe what's happening better than
// "UndefinedifiedObject".
export type BzDBResult<T> = UndefinedifiedObject<T>

// For any value that's `null`, make it `undefined`. Note that this is shallow
export const mapNullsToUndefineds = <T>(obj: T): UndefinedifiedObject<T> => {
  // These things don't make sense to undefined-ify. So pass them through.
  // UndefinedifiedObject is smart enough to know that for T that isn't an object, just
  // return T
  if (typeof obj !== 'object' || R.isNil(obj)) {
    return obj as UndefinedifiedObject<T>
  }
  const result = {}
  for (const key of R.keys(obj)) {
    const value = obj[key]
    // The TS type is too complex. It isn't able to figure this out
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, breezy/no-equals-null-or-undefined
    ;(result as any)[key] = value === null ? undefined : value
  }
  return result as UndefinedifiedObject<T>
}

type NullableKeys<T> = {
  [K in keyof T]-?: null extends T[K] ? (unknown extends T[K] ? never : K) : never
}[keyof T]

type Nullish = undefined | void | null

export const isNullish = (val: unknown): val is Nullish => {
  return R.isNil(val)
}

export const isNullishOrEmpty = (val: unknown): val is Nullish | '' => {
  return isNullish(val) || R.isEmpty(val)
}

export type Forceable<T> = T & {
  readonly force?: boolean
}

export type KeysOfType<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never
}[keyof T]

export type NotNullish<T> = Exclude<T, undefined | null>

// For WithRequireKeys and WithPartialKeys, if you don't do this, the type in Intellisense will be "Omit<Ew, Gross> &
// Required<Pick<Ew, Gross>>". This merges those back together.
export type Merge<T> = T extends Record<string, unknown> ? { [K in keyof T]: T[K] } : never

// TODO: This is the old implementation. I think the new one is equivalent, but better (for certain complex types, like
// mapped types that add `[x: string]` to them, it doesn't handle them properly). If we have issues with the new one we
// can switch back
// export type WithRequiredKeys<T, K extends keyof T> = Merge<Omit<T, K> & Required<Pick<T, K>>>
export type WithRequiredKeys<T, U extends keyof T> = Merge<
  {
    [K in keyof T as K extends U ? K : never]-?: NonNullable<T[K]>
  } & {
    [K in keyof T as K extends U ? never : K]: T[K]
  }
>
// TODO: same story as `WithRequiredKeys`
// export type WithPartialKeys<T, K extends keyof T> = Merge<Omit<T, K> & Partial<Pick<T, K>>>
export type WithPartialKeys<T, U extends keyof T> = Merge<
  {
    [K in keyof T as K extends U ? K : never]+?: T[K]
  } & {
    [K in keyof T as K extends U ? never : K]: T[K]
  }
>

export type SemVer = `${number}.${number}.${number}`

// In order for the branding strategy to work here, you need to do "brand1 & brand2". When you do that and the two
// things don't overlap, TypeScript's errors will call this an "HtmlString" without kind of unfolding what's under the
// hood. Normally we do "string & brand" so that anything that accepts a `string` also accepts this, but that's the
// literal opposite of our goal here (to block us from putting Html strings that need to be parsed into places that will
// just yeet them on the page). We can't use `never` because `never & a = never`. `unknown` seems to have a similar
// effect. We can't use `undefined` or `null` because then we'll errantly match to optional things. We could use
// `number` or `boolean` but those are kind of sketchy. We could do `{} & ` but that seems to do the same thing as
// `unknown`. But this works fine somehow. Also, we do `| string` with that because if you have a string, that's also a
// valid HtmlString (plain text is valid HTML). If something takes a string and you give it an HtmlString it will
// complain, but if it takes an HtmlString and you give it a string, it will work!
export type HtmlString =
  | ({
      readonly __htmlString: 'HtmlString'
    } & {
      readonly __htmlString2: 'HtmlString2'
    })
  | string

// Hasura inputs require you to put `{ data: ..., onConflict?: ... }` whenever you write something with a relationship.
// In places like the DB Seeder, we are building a big object that we pass to one hasura mutation. Ideally we can just
// return this object and use it in other seeders instead of specifying a type manually and transforming the data. But
// those `{ data: ..., onConflict?: ...}` layers are super annoying to deal with. So this "unrolls" the object. Whenever
// a value is an object with "data" as a key, it's going to take the value at `data` and put it at that level instead.
// It recursively applies to the value at `data`. If `data` is an array, it recursively applies to each element.
type UnrolledHasuraData<T> = T extends Array<infer U>
  ? Array<UnrolledHasuraData<U>>
  : T extends { data: unknown }
  ? UnrolledHasuraData<T['data']>
  : T extends Record<string, unknown>
  ? { [K in keyof T]: UnrolledHasuraData<T[K]> }
  : T

export const unrollHasuraData = <T>(obj: T): UnrolledHasuraData<T> => {
  // Note: there are "as never"s throughout this function. TypeScript has a really hard time with type narrowing where
  // the function signature says something and you use logic to narrow it down. It's easier to just cast it to `never`
  if (typeof obj === 'object' && !isNullish(obj)) {
    if (Array.isArray(obj)) {
      return obj.map(item => unrollHasuraData(item as never)) as never
    }
    if ('data' in obj) {
      return unrollHasuraData(obj.data as never)
    }
    return R.mapObjIndexed(value => unrollHasuraData(value), obj) as never
  }
  return obj as never
}

// See below
type Shape = {
  [K: string]: Shape | true
}

// See below
type MatchToShapeHelper<T, S extends Shape | true> = S extends Shape
  ? T extends Array<infer U>
    ? MatchToShape<U, S>[]
    : MatchToShape<T, S>
  : T

/*
 * Given a type and a shape, return the type but "shaken" so only the keys that are in the shape are kept. Works
 * recursively/deeply. They shape is an object where the keys must match the type at that level, and the values are
 * either `true`, meaning it takes whatever the type is at that key, or a nested shape, meaning it continues. If a key
 * is optional in the type, it will be optional in the resultant type. If a key is an array, the shape doesn't use array
 * notation; the resultant type will properly be an array.
 */
export type MatchToShape<T, S extends Shape> =
  // They type of object doesn't matter so long as it's an object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<any, any>
    ? Merge<
        {
          [K in keyof S as K extends keyof T ? (undefined extends T[K] ? never : K) : never]: K extends keyof T
            ? MatchToShapeHelper<T[K], S[K]>
            : never
        } & {
          [K in keyof S as K extends keyof T ? (undefined extends T[K] ? K : never) : never]+?: K extends keyof T
            ? MatchToShapeHelper<T[K], S[K]>
            : never
        }
      >
    : never
