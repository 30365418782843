import {
  faChevronsDown,
  faChevronsUp,
  faCopy,
  faEdit,
  faEllipsis,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useMemo, useState } from 'react'
import {
  DEFAULT_ESTIMATE_OPTION_NAME,
  EstimateDataContext,
  EstimateEditContext,
} from './estimatesFlowUtils'

import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../adam-components/OnsiteModal/ActionsModalContent'
import {
  OnsiteBasicModal,
  OnsiteConfirmModal,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import useIsMobile from '../../hooks/useIsMobile'
import { useModalState, useStrictContext } from '../../utils/react-utils'
import {
  BasicEstimateOption,
  BasicEstimateOptionProps,
} from './BasicEstimateOption'
import { RecommendWidget } from './components'

const DELETE_OPTION_HEADER = 'Delete option'
const DELETE_OPTION_CONFIRM_TEXT = 'Yes, Delete'

const makeDeleteOptionConfirmText = (displayName: string) =>
  `Are you sure you want to delete option "${displayName}"? This action cannot be undone.`

type MobileActionsModalProps = {
  onCancel: () => void
  displayName: string
  index: number
  isLast: boolean
  shiftOption: (index: number, up: boolean) => void
  duplicateOption: (index: number) => void
  deleteOption: (index: number) => void
}

const MobileActionsModal = React.memo<MobileActionsModalProps>(
  ({
    onCancel,
    displayName,
    index,
    shiftOption,
    duplicateOption,
    deleteOption,
    isLast,
  }) => {
    const [mode, setMode] = useState<'base' | 'delete'>('base')

    const { header, headerBordered, content, footer, onBack } = useMemo(() => {
      const backToBase = () => setMode('base')
      switch (mode) {
        case 'base':
          return {
            header: 'Option actions',
            headerBordered: true,
            content: (
              <ActionsModalContent>
                <ActionsModalAction
                  onClick={() => duplicateOption(index)}
                  icon={<FontAwesomeIcon icon={faCopy} />}
                >
                  Duplicate
                </ActionsModalAction>
                {index !== 0 && (
                  <ActionsModalAction
                    onClick={() => {
                      shiftOption(index, true)
                      onCancel()
                    }}
                    icon={<FontAwesomeIcon icon={faChevronsUp} />}
                  >
                    Move Up
                  </ActionsModalAction>
                )}
                {!isLast && (
                  <ActionsModalAction
                    onClick={() => {
                      shiftOption(index, false)
                      onCancel()
                    }}
                    icon={<FontAwesomeIcon icon={faChevronsDown} />}
                  >
                    Move Down
                  </ActionsModalAction>
                )}
                <ActionsModalAction
                  danger
                  onClick={() => setMode('delete')}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                >
                  Delete
                </ActionsModalAction>
              </ActionsModalContent>
            ),
          }

        case 'delete':
          return {
            header: DELETE_OPTION_HEADER,
            headerBordered: false,
            onBack: backToBase,
            content: <div>{makeDeleteOptionConfirmText(displayName)}</div>,
            footer: (
              <OnsiteModalFooter
                danger
                onCancel={backToBase}
                onSubmit={() => {
                  deleteOption(index)
                  onCancel()
                }}
                cancelText="Back"
                submitText={DELETE_OPTION_CONFIRM_TEXT}
              />
            ),
          }
      }
    }, [
      mode,
      index,
      isLast,
      displayName,
      duplicateOption,
      shiftOption,
      onCancel,
      deleteOption,
    ])
    return (
      <OnsiteBasicModal
        headerBordered={headerBordered}
        onClose={onCancel}
        open
        header={header}
        footer={footer}
        onBack={onBack}
      >
        {content}
      </OnsiteBasicModal>
    )
  },
)

type EditableEstimateOptionProps = Omit<BasicEstimateOptionProps, 'footer'> & {
  estimateIsAccepted?: boolean
}

export const EditableEstimateOption = React.memo<EditableEstimateOptionProps>(
  ({ option, index, estimateIsAccepted, ...rest }) => {
    const isMobile = useIsMobile()
    const { options } = useStrictContext(EstimateDataContext)
    const {
      onSetRecommended,
      setSelectedOptionIndex,
      shiftOption,
      deleteOption,
      duplicateOption,
    } = useStrictContext(EstimateEditContext)
    const { displayName, recommended } = option

    const resolvedDisplayName = displayName || DEFAULT_ESTIMATE_OPTION_NAME

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

    const [actionsModalOpen, openActionsModal, closeActionsModal] =
      useModalState()

    return (
      <BasicEstimateOption
        {...rest}
        option={option}
        index={index}
        footer={
          <div className="mt-4 flex flex-row items-center">
            <div className="flex flex-1 flex-row items-center space-x-2">
              <Button
                size="large"
                icon={<FontAwesomeIcon icon={faEdit} />}
                onClick={() => setSelectedOptionIndex(index)}
              >
                Edit
              </Button>
              {/* If an estimate has been accepted, can't do any of these things. We pretend the other options don't
                  exist, prevent adding new options, and prevent deleting options. So we can't duplicate because that's
                  creating a new option. We can shift the order because we're pretending there's only one option. We
                  obviously can't delete. */}
              {!estimateIsAccepted &&
                (isMobile ? (
                  <>
                    <Button
                      data-testid="mobile-estimate-actions-button"
                      size="large"
                      className="min-w-[40px]"
                      icon={<FontAwesomeIcon icon={faEllipsis} />}
                      onClick={openActionsModal}
                    />
                    {actionsModalOpen && (
                      <MobileActionsModal
                        onCancel={closeActionsModal}
                        displayName={resolvedDisplayName}
                        index={index}
                        duplicateOption={duplicateOption}
                        deleteOption={deleteOption}
                        shiftOption={shiftOption}
                        isLast={index === options.length - 1}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      size="large"
                      icon={<FontAwesomeIcon icon={faCopy} />}
                      onClick={() => duplicateOption(index)}
                    >
                      Duplicate
                    </Button>
                    <Button
                      size="large"
                      icon={<FontAwesomeIcon icon={faChevronsUp} />}
                      disabled={index === 0}
                      onClick={() => shiftOption(index, true)}
                    />
                    <Button
                      size="large"
                      icon={<FontAwesomeIcon icon={faChevronsDown} />}
                      disabled={index === options.length - 1}
                      onClick={() => shiftOption(index, false)}
                    />
                    <Button
                      danger
                      size="large"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => setShowDeleteConfirm(true)}
                    />
                    <OnsiteConfirmModal
                      danger
                      onCancel={() => setShowDeleteConfirm(false)}
                      onConfirm={() => deleteOption(index)}
                      open={showDeleteConfirm}
                      header={DELETE_OPTION_HEADER}
                      confirmText={DELETE_OPTION_CONFIRM_TEXT}
                    >
                      {makeDeleteOptionConfirmText(resolvedDisplayName)}
                    </OnsiteConfirmModal>
                  </>
                ))}
            </div>
            {/* Changing the "Recommended" option doesn't really make sense if the estimate has already been accepted
                since we're pretending there aren't other options. */}
            {!estimateIsAccepted && (
              <RecommendWidget
                recommended={!!recommended}
                onChange={recommended => onSetRecommended(index, recommended)}
              />
            )}
          </div>
        }
      />
    )
  },
)
