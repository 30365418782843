import {
  ByMonthOption,
  Dfns,
  LEGACY_FREQUENCY_OPTIONS,
  LegacyFrequency,
  LegacyWeekday,
  WEEKDAY_BUTTON_OPTIONS,
  getNthDOWOfMonth,
  getSuffixForNumber,
  isLastDOWOfMonth,
  makeRRule,
  parseRRule,
  toPlural,
} from '@breezy/shared'
import { Form, InputNumber, Radio, Select } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { Styled } from '../../utils/Stylable'

type RecurrenceFormProps = Styled<{
  rrule: string
  startingDate: string
  onChange: (rule: string) => void
  exclusiveDayOfWeek?: boolean
}>

export const RecurrenceForm = React.memo<RecurrenceFormProps>(
  ({ rrule, onChange, className, startingDate, exclusiveDayOfWeek }) => {
    const date = useMemo(() => Dfns.parseISO(startingDate), [startingDate])

    const parsedRRule = useMemo(() => parseRRule(rrule, date), [rrule, date])
    const { freq, interval, weekdayMap, byMonthOption } = parsedRRule

    const setFreq = useCallback(
      (freq: LegacyFrequency) =>
        onChange(makeRRule({ ...parsedRRule, freq }, date)),
      [date, onChange, parsedRRule],
    )
    const setRecurringInterval = useCallback(
      (interval: number) =>
        onChange(makeRRule({ ...parsedRRule, interval }, date)),
      [date, onChange, parsedRRule],
    )
    const setWeekdayMap = useCallback(
      (dow: LegacyWeekday) =>
        onChange(
          makeRRule(
            {
              ...parsedRRule,
              weekdayMap: exclusiveDayOfWeek
                ? {
                    SU: false,
                    MO: false,
                    TU: false,
                    WE: false,
                    TH: false,
                    FR: false,
                    SA: false,
                    [dow]: true,
                  }
                : {
                    ...weekdayMap,
                    [dow]: !weekdayMap[dow],
                  },
            },
            date,
          ),
        ),
      [date, onChange, parsedRRule, weekdayMap, exclusiveDayOfWeek],
    )

    const setByMonthOption = useCallback(
      (byMonthOption: ByMonthOption) =>
        onChange(makeRRule({ ...parsedRRule, byMonthOption }, date)),
      [date, onChange, parsedRRule],
    )

    const freqOptions = useMemo(
      () =>
        LEGACY_FREQUENCY_OPTIONS.map(({ value, label }) => ({
          value,
          label: toPlural(interval, label),
        })),
      [interval],
    )

    const byMonthSelectOptions = useMemo(() => {
      const dow = Dfns.format('eeee', date)
      const options: {
        value: ByMonthOption
        label: string
      }[] = [
        {
          value: 'BY_DAY',
          label: `The ${getSuffixForNumber(
            parseInt(Dfns.format('d', date)),
          )} day of the month`,
        },
        {
          value: 'BY_WEEKDAY',
          label: `The ${getSuffixForNumber(
            getNthDOWOfMonth(date),
          )} ${dow} of the month`,
        },
      ]
      if (isLastDOWOfMonth(date)) {
        options.push({
          value: 'BY_LAST_WEEKDAY',
          label: `The last ${dow} of the month`,
        })
      }
      return options
    }, [date])

    return (
      <Form className={className} layout="vertical">
        <Form.Item label="Repeat Every">
          <div className="flex flex-row items-center justify-start space-x-2">
            <InputNumber
              min={1}
              defaultValue={1}
              value={interval}
              onChange={val => setRecurringInterval(val ?? 1)}
            />
            <Select
              popupMatchSelectWidth={false}
              options={freqOptions}
              onChange={setFreq}
              value={freq}
              className="flex-[0]"
            />
            {freq === 'MONTHLY' && (
              <Select
                popupMatchSelectWidth={false}
                options={byMonthSelectOptions}
                onChange={setByMonthOption}
                value={byMonthOption}
              />
            )}
          </div>
        </Form.Item>
        {freq === 'WEEKLY' &&
          (exclusiveDayOfWeek ? (
            <Form.Item label="On">
              <Select
                popupMatchSelectWidth={false}
                className="max-w-fit"
                defaultValue={
                  Object.entries(weekdayMap).filter(
                    e => e[1],
                  )[0][0] as LegacyWeekday
                }
                onChange={setWeekdayMap}
                options={WEEKDAY_BUTTON_OPTIONS.map(({ value, fullName }) => ({
                  label: fullName,
                  value,
                }))}
              />
            </Form.Item>
          ) : (
            <Form.Item label="On These Days">
              {/* I'm aware that radio buttons are meant to be mutually exclusive and what we really want is
                  "checkboxes" or a "button group". But antd doesn't provide a component that looks exactly like their
                  radio buttons but where multiple are selectable, so here we are. */}
              <Radio.Group size="large" value="">
                {WEEKDAY_BUTTON_OPTIONS.map(({ label, value }) => (
                  <Radio.Button
                    key={value}
                    onClick={() => setWeekdayMap(value)}
                    className={
                      weekdayMap[value]
                        ? 'ant-radio-button-wrapper-checked'
                        : ''
                    }
                  >
                    {label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          ))}
      </Form>
    )
  },
)
