import { Account } from '@breezy/shared'
import { faUserCheck, faUserPlus } from '@fortawesome/pro-light-svg-icons'
import { Divider } from 'antd'
import { JobLead } from '../../../pages/JobLeadsPage/types'
import { JobLeadAccountMatches } from './JobLeadAccountMatches'
import OptionButton from './OptionButton'

type BaseNewOrExistingOptionsProps = {
  onClickNewAccount: () => void
  onClickExistingAccount: () => void
}

type WithoutJobLeadProps = {
  onRecommendedAccountSelected?: undefined
  jobLead?: undefined
}

type WithJobLeadProps = {
  onRecommendedAccountSelected: (account: Account) => void
  jobLead: JobLead
}

export type NewOrExistingOptionsProps = BaseNewOrExistingOptionsProps &
  (WithoutJobLeadProps | WithJobLeadProps)

const NewOrExistingOptions = ({
  onClickNewAccount,
  onClickExistingAccount,
  jobLead,
  onRecommendedAccountSelected,
}: NewOrExistingOptionsProps) => {
  return (
    <div>
      <div className="flex flex-col space-y-4 md:flex-row md:space-x-10 md:space-y-0">
        <OptionButton
          faIcon={faUserPlus}
          label="New Account"
          onClick={onClickNewAccount}
        />
        <OptionButton
          faIcon={faUserCheck}
          label="Existing Account"
          onClick={onClickExistingAccount}
        />
      </div>
      {jobLead && (
        <div>
          <Divider />
          <JobLeadAccountMatches
            jobLead={jobLead}
            onRecommendedAccountSelected={onRecommendedAccountSelected}
          />
        </div>
      )}
    </div>
  )
}

export default NewOrExistingOptions
