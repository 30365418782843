import cn from 'classnames'
import React, { useMemo } from 'react'

export type StatusTagColor =
  | 'red'
  | 'yellow'
  | 'cyan'
  | 'purple'
  | 'green'
  | 'gray'
  | 'magenta'
  | 'blue'
  | 'geek-blue'
  | 'lime'
  | 'orange'
  | 'volcano'
  | 'darkGray'

export type StatusTagProps = {
  color: StatusTagColor
  border?: 'regular' | 'none' | 'strong'
  text: string
  /**
   * Pass in any style overrides as tailwind class names.
   * Use the `!` prefix if you want to override the default background, text-color, or border styles.
   */
  className?: string
  children?: React.ReactNode
  size?: 'medium' | 'large'
}

type ColorWeight = 600 | 700 | 800
type BorderWeight = 300 | 600 | 700

// Using the clunkier switch statements rather than a simple string interpolation
// because of the way Tailwind extracts class names from the source code.
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
// The alternative would be to add these class names to the safelist file: https://tailwindcss.com/docs/content-configuration#safelisting-classes
const getBackgroundColor = (color: StatusTagColor, strong: boolean) => {
  switch (color) {
    case 'red':
      return strong ? 'bg-bz-red-600' : 'bg-bz-red-100'
    case 'yellow':
      return strong ? 'bg-bz-yellow-600' : 'bg-bz-yellow-100'
    case 'cyan':
      return strong ? 'bg-bz-cyan-600' : 'bg-bz-cyan-100'
    case 'purple':
      return strong ? 'bg-bz-purple-600' : 'bg-bz-purple-100'
    case 'green':
      return strong ? 'bg-bz-green-600' : 'bg-bz-green-100'
    case 'gray':
      return strong ? 'bg-bz-gray-600' : 'bg-bz-gray-100'
    case 'magenta':
      return strong ? 'bg-bz-magenta-600' : 'bg-bz-magenta-100'
    case 'blue':
      return strong ? 'bg-bz-primary' : 'bg-daybreak-blue-100'
    case 'geek-blue':
      return strong ? 'bg-geek-blue-600' : 'bg-geek-blue-100'
    case 'lime':
      return strong ? 'bg-bz-lime-600' : 'bg-bz-lime-100'
    case 'orange':
      return strong ? 'bg-bz-orange-600' : 'bg-bz-orange-100'
    case 'volcano':
      return strong ? 'bg-bz-volcano-600' : 'bg-bz-volcano-100'
    case 'darkGray':
      return strong ? 'bg-bz-gray-300' : 'bg-bz-gray-300'
  }
}

const getTextColor = (color: StatusTagColor, colorWeight: ColorWeight) => {
  switch (color) {
    case 'red':
      return colorWeight === 600
        ? 'text-bz-red-600'
        : colorWeight === 700
        ? 'text-bz-red-700'
        : 'text-bz-red-800'
    case 'yellow':
      return colorWeight === 600
        ? 'text-bz-yellow-600'
        : colorWeight === 700
        ? 'text-bz-yellow-700'
        : 'text-bz-yellow-800'
    case 'cyan':
      return colorWeight === 600
        ? 'text-bz-cyan-600'
        : colorWeight === 700
        ? 'text-bz-cyan-700'
        : 'text-bz-cyan-800'
    case 'purple':
      return colorWeight === 600
        ? 'text-bz-purple-600'
        : colorWeight === 700
        ? 'text-bz-purple-700'
        : 'text-bz-purple-800'
    case 'green':
      return colorWeight === 600
        ? 'text-bz-green-600'
        : colorWeight === 700
        ? 'text-bz-green-700'
        : 'text-bz-green-800'
    case 'gray':
      return colorWeight === 600
        ? 'text-bz-gray-600'
        : colorWeight === 700
        ? 'text-bz-gray-700'
        : 'text-bz-gray-800'
    case 'magenta':
      return colorWeight === 600
        ? 'text-bz-magenta-600'
        : colorWeight === 700
        ? 'text-bz-magenta-700'
        : 'text-bz-magenta-800'
    case 'blue':
      return colorWeight === 600
        ? 'text-bz-primary'
        : colorWeight === 700
        ? 'text-daybreak-blue-700'
        : 'text-daybreak-blue-800'
    case 'geek-blue':
      return colorWeight === 600
        ? 'text-bz-geek-blue-600'
        : colorWeight === 700
        ? 'text-geek-blue-700'
        : 'text-geek-blue-800'
    case 'lime':
      return colorWeight === 600
        ? 'text-bz-lime-600'
        : colorWeight === 700
        ? 'text-bz-lime-700'
        : 'text-bz-lime-800'
    case 'orange':
      return colorWeight === 600
        ? 'text-bz-orange-600'
        : colorWeight === 700
        ? 'text-bz-orange-700'
        : 'text-bz-orange-800'
    case 'volcano':
      return colorWeight === 600
        ? 'text-bz-volcano-600'
        : colorWeight === 700
        ? 'text-bz-volcano-700'
        : 'text-bz-volcano-800'
    case 'darkGray':
      return colorWeight === 600
        ? 'text-bz-gray-700'
        : colorWeight === 700
        ? 'text-bz-gray-800'
        : 'text-bz-gray-800'
    default:
      return 'text-bz-gray-600'
  }
}

const getBorderColor = (color: StatusTagColor, borderWeight: BorderWeight) => {
  switch (color) {
    case 'red':
      return borderWeight === 300
        ? 'border-bz-red-300'
        : borderWeight === 600
        ? 'border-bz-red-600'
        : 'border-bz-red-700'
    case 'yellow':
      return borderWeight === 300
        ? 'border-bz-yellow-300'
        : borderWeight === 600
        ? 'border-bz-yellow-600'
        : 'border-bz-yellow-700'
    case 'cyan':
      return borderWeight === 300
        ? 'border-bz-cyan-300'
        : borderWeight === 600
        ? 'border-bz-cyan-600'
        : 'border-bz-cyan-700'
    case 'purple':
      return borderWeight === 300
        ? 'border-bz-purple-300'
        : borderWeight === 600
        ? 'border-bz-purple-600'
        : 'border-bz-purple-700'
    case 'green':
      return borderWeight === 300
        ? 'border-bz-green-300'
        : borderWeight === 600
        ? 'border-bz-green-600'
        : 'border-bz-green-700'
    case 'gray':
      return borderWeight === 300
        ? 'border-bz-gray-300'
        : borderWeight === 600
        ? 'border-bz-gray-600'
        : 'border-bz-gray-700'
    case 'magenta':
      return borderWeight === 300
        ? 'border-bz-magenta-300'
        : borderWeight === 600
        ? 'border-bz-magenta-600'
        : 'border-bz-magenta-700'
    case 'blue':
      return borderWeight === 300
        ? 'border-daybreak-blue-300'
        : borderWeight === 600
        ? 'border-bz-primary'
        : 'border-daybreak-blue-700'
    case 'geek-blue':
      return borderWeight === 300
        ? 'border-geek-blue-300'
        : borderWeight === 600
        ? 'border-geek-blue-600'
        : 'border-geek-blue-700'
    case 'lime':
      return borderWeight === 300
        ? 'border-bz-lime-300'
        : borderWeight === 600
        ? 'border-bz-lime-600'
        : 'border-bz-lime-700'
    case 'orange':
      return borderWeight === 300
        ? 'border-bz-orange-300'
        : borderWeight === 600
        ? 'border-bz-orange-600'
        : 'border-bz-orange-700'
    case 'volcano':
      return borderWeight === 300
        ? 'border-bz-volcano-300'
        : borderWeight === 600
        ? 'border-bz-volcano-600'
        : 'border-bz-volcano-700'
    case 'darkGray':
      return borderWeight === 300
        ? 'border-bz-gray-500'
        : borderWeight === 600
        ? 'border-bz-gray-800'
        : 'border-bz-gray-800'
    default:
      return 'border-bz-gray-300'
  }
}

const StatusTag = React.memo<StatusTagProps>(
  ({ color, border, text, className, children, size = 'medium' }) => {
    const { colorWeight, borderWeight } = useMemo(() => {
      let colorWeight: ColorWeight = 600
      let borderWeight: BorderWeight = 300

      if (border === 'regular') {
        colorWeight = 700
        borderWeight = 600
      } else if (border === 'strong') {
        colorWeight = 800
        borderWeight = 700
      }

      return { colorWeight, borderWeight }
    }, [border])

    return (
      <span
        className={cn(
          'select-none overflow-hidden overflow-ellipsis whitespace-nowrap rounded border border-solid px-2 text-xs font-normal',
          {
            'py-[3px]': size === 'medium',
            'py-[7px] text-sm': size === 'large',
          },
          getBackgroundColor(color, false),
          getBorderColor(color, borderWeight),
          getTextColor(color, colorWeight),
          className,
        )}
      >
        {text}
        {children}
      </span>
    )
  },
)

export default StatusTag
