import { bzExpect } from '@breezy/shared'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { getConfig } from '../config'
import { trpc } from '../hooks/trpc'
import { useCompanyGuid } from './PrincipalUser'

const { env } = getConfig()
const isDemo = env === 'demo'

export const BreezyDemoFallbackWisetackMerchantId =
  '18de35f2-d5fe-457f-a5c8-679257181d98'

type CompanyFinancialConfig = {
  merchantId?: string
  qboEnabled?: boolean
  qboAutoSyncInvoiceOnFullyPaid?: boolean
  wisetackEnabled?: boolean
  wisetackMerchantId?: string
}

export const FinancialConfigContext = createContext<
  CompanyFinancialConfig | undefined
>(undefined)

const useFinancialConfig = () => {
  const [financialConfig, setFinancialConfig] =
    useState<CompanyFinancialConfig>({})
  const companyGuid = useCompanyGuid()

  const { data, refetch } = trpc.companies[
    'companies:financial-config'
  ].useQuery(undefined, {
    enabled: false,
  })

  useEffect(() => {
    if (companyGuid) {
      refetch()
    }
  }, [companyGuid, refetch])

  useEffect(() => {
    if (data) {
      setFinancialConfig(data)
    }
  }, [data])

  return financialConfig
}

export const CompanyFinancialConfigWrapper: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const financialConfig = useFinancialConfig()

  return (
    <FinancialConfigContext.Provider value={financialConfig}>
      {children}
    </FinancialConfigContext.Provider>
  )
}

const useFinancialConfigContext = () => {
  const context = useContext(FinancialConfigContext)
  if (!context) {
    throw new Error(
      'useFinancialConfigContext must be used within a FinancialConfigProvider',
    )
  }
  return context
}

export const useWisetackEnabled = () => {
  const financialConfig = useFinancialConfigContext()

  return financialConfig.wisetackEnabled ?? false
}

export const useWisetackConsumerFinancingEnabled = () => {
  const financialConfig = useFinancialConfigContext()

  return financialConfig.wisetackEnabled
}

export const useWisetackMerchantId = () => {
  const financialConfig = useFinancialConfigContext()
  return (
    financialConfig.wisetackMerchantId ??
    (isDemo ? BreezyDemoFallbackWisetackMerchantId : undefined)
  )
}

export const useTilledMerchantId = () => {
  const financialConfig = useFinancialConfigContext()
  return financialConfig.merchantId
}

export const useExpectedTilledMerchantId = () => {
  const financialConfig = useFinancialConfigContext()
  return bzExpect(
    financialConfig.merchantId,
    'tilledMerchantId',
    'Tilled Merchant ID not configured for company',
  )
}
