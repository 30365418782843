import { faCheck, faClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SpecialLifecycleStatus } from '@breezy/shared'
import classNames from 'classnames'
import React from 'react'
import { Styled } from '../../utils/Stylable'

type LifecycleStatusCircleProps = Styled<{
  color: string
  specialStatus?: SpecialLifecycleStatus
}>

export const LifecycleStatusCircle = React.memo<LifecycleStatusCircleProps>(
  ({ color, specialStatus, className, style }) => (
    <div
      className={classNames(
        'flex h-[16px] w-[16px] items-center justify-center rounded-full',
        className,
      )}
      style={{ backgroundColor: color, ...style }}
    >
      {(specialStatus === 'Canceled' || specialStatus === 'Closed - Lost') && (
        <FontAwesomeIcon icon={faClose} color="white" size="xs" />
      )}
      {specialStatus === 'Completed' && (
        <FontAwesomeIcon icon={faCheck} color="white" size="xs" />
      )}
    </div>
  ),
)
