import { Radio, RadioGroupProps } from 'antd'
import React from 'react'

type RadioButtonFieldProps = RadioGroupProps

export const RadioButtonField = React.memo(
  React.forwardRef<HTMLDivElement, RadioButtonFieldProps>(
    ({ className, ...rest }, ref) => (
      <div ref={ref} className={className}>
        <Radio.Group {...rest} />
      </div>
    ),
  ),
)
