import { ComprehensiveAccountDetails } from '@breezy/backend/src/application-types'
import { AccountGuid } from '@breezy/shared'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { trpc } from '../trpc'

type UseFetchComprehensiveAccountDetailsOpts = {
  enabled?: boolean
  onSuccess?: (account: ComprehensiveAccountDetails) => void
}
type UseFetchComprehensiveAccountDetailsProps = Readonly<{
  accountGuid: AccountGuid
  opts?: UseFetchComprehensiveAccountDetailsOpts
}>

/** @deprecated use frontend hasura */
export const useFetchComprehensiveAccountDetails = (
  props: UseFetchComprehensiveAccountDetailsProps,
) => {
  const { accountGuid } = props
  const opts = props.opts ?? {}

  return trpc.accounts['comprehensive-accounts-details:query'].useQuery(
    {
      type: 'by-account-guid',
      companyGuid: useExpectedCompany().companyGuid,
      accountGuid,
    },
    {
      select: data => {
        if (data.length !== 1) {
          throw new Error(`Failed to locate account by id = ${accountGuid}`)
        }
        return new ComprehensiveAccountDetails(data[0])
      },
      enabled: opts.enabled,
      onSuccess: opts.onSuccess,
    },
  )
}
