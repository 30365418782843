import {
  BzDateTime,
  ComprehensivePaymentViewModel,
  RefundReasonType,
  RefundStatus,
  asyncNoOp,
  formatMoney,
  nextGuid,
  paymentMethodDisplayName,
  refundReasonTypeDisplayName,
} from '@breezy/shared'
import { Button, Form, Input, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RowLabeledText from '../../elements/RowLabeledText/RowLabeledText'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExpectedMerchantId } from '../../providers/PrincipalUser'
import { dustRed6 } from '../../themes/theme'
import { useMessage } from '../../utils/antd-utils'
import { paymentDetailsLinkUrl } from '../Links/EntityLinks'
import PaymentAsyncStatusCard from './PaymentAsyncStatusCard'
import PaymentCardPanel from './PaymentCardPanel'
import { PaymentAsyncUiStatus } from './PaymentTypes'

type RefundRequestFormData = {
  amountUsd: number
  reasonType: RefundReasonType
  reasonDescription: string
}

type PaymentRefundFormProps = ComprehensivePaymentViewModel &
  Readonly<{
    defaultAmountUsd?: number | null
    defaultReason?: string | null
  }>

const PaymentRefundForm = (props: PaymentRefundFormProps) => {
  const message = useMessage()
  const { defaultAmountUsd, defaultReason } = props
  const payment = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const cardStyle = error ? { border: `2px solid ${dustRed6}` } : {}
  const navigate = useNavigate()
  const onCancel = () => navigate(paymentDetailsLinkUrl(payment.guid))
  const merchantId = useExpectedMerchantId()
  const refundGuid = nextGuid()
  const [form] = useForm<RefundRequestFormData>()
  const refundMut = trpc.payments['payments:refund'].useMutation({
    onError: asyncNoOp,
  })
  const unrefundedAmountUsd =
    payment.amountUsd - (payment.totalRefundedAmountUsd ?? 0)

  const submit = (values: RefundRequestFormData) => {
    setIsSubmitting(true)
    refundMut.mutate(
      {
        ...payment,
        ...values,
        amountUsd: Number(values.amountUsd),
        paymentRecordGuid: payment.guid,
        paymentRefundGuid: refundGuid,
        merchantId,
        status: RefundStatus.SUCCEEDED,
        occurredAt: BzDateTime.nowUtcIsoString(),
      },
      {
        onSuccess: () => {
          message.success('Refund submitted successfully')
          navigate(paymentDetailsLinkUrl(payment.guid))
        },
        onError: error => {
          setError(error.message)
          setIsSubmitting(false)
        },
      },
    )
  }

  return (
    <>
      {isSubmitting && (
        <PaymentAsyncStatusCard
          className="mx-3"
          status={PaymentAsyncUiStatus.SUBMITTING}
          titleOverride="Issuing Refund..."
        />
      )}
      {!isSubmitting && (
        <PaymentCardPanel style={cardStyle} className="mx-3">
          <Form form={form} onFinish={submit}>
            <h2>Refund</h2>

            {error && (
              <div className="error-red my-2 p-2 text-center">
                <b>Refund Error:</b>
                <br />
                {error}
              </div>
            )}

            <ThinDivider />
            <RowLabeledText label="Original Amount:">
              {formatMoney(payment.amountUsd)}
            </RowLabeledText>
            <RowLabeledText label="Original Method:">
              {paymentMethodDisplayName(payment.paymentMethod)}
            </RowLabeledText>
            <ThinDivider />

            <Form.Item
              name="amountUsd"
              label="Amount (USD)"
              initialValue={defaultAmountUsd}
              rules={[
                {
                  required: true,
                  validator: async (_, value) => {
                    if (value > unrefundedAmountUsd)
                      throw new Error(
                        'Amount may not be more to the collected payment amount',
                      )
                    if (value < 0.01)
                      throw new Error(
                        'Amount must be greater than or equal to $0.01',
                      )
                  },
                },
              ]}
            >
              <Input
                type="number"
                min={0.01}
                max={payment.amountUsd}
                step={0.01}
              />
            </Form.Item>

            <Form.Item
              name="reasonType"
              label="Reason"
              initialValue={defaultReason}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select a Reason">
                {Object.values(RefundReasonType).map(type => (
                  <Select.Option key={type} value={type}>
                    {refundReasonTypeDisplayName(type)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="reasonDescription"
              label="Description:"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  validator: async (_, value) => {
                    if (value.length < 10)
                      throw new Error(
                        'Description must be at least 20 characters',
                      )
                  },
                },
              ]}
            >
              <TextArea
                name="reasonDescription"
                id="reasonDescription"
                className="min-w-[250px]"
                rows={4}
              />
            </Form.Item>

            <Button type="primary" htmlType="submit" className="mt-2 w-full">
              Issue Refund
            </Button>
            <Button className="mt-2 w-full" onClick={onCancel}>
              Cancel
            </Button>
          </Form>
        </PaymentCardPanel>
      )}
    </>
  )
}

export default PaymentRefundForm
