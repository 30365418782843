import { FileRecord } from '@breezy/shared'
import { Form, Input, Modal } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingSpinner } from '../LoadingSpinner'

const FILE_NAME_MAX_LENGTH = 60 as const

type FileNameError = { hasError: false } | { hasError: true; error: string }

export type FileEditNameModalProps = {
  file: FileRecord
  open: boolean
  loading: boolean
  onSubmit: (fileGuid: string, newFileName: string) => void
  onCancel: () => void
}

export const FileEditNameModal = ({
  file,
  open,
  loading,
  onSubmit,
  onCancel,
}: FileEditNameModalProps) => {
  const [input, setInput] = useState(file.fileName)
  const fileName = useMemo(() => input.trim(), [input])

  const [error, setError] = useState<FileNameError>({ hasError: false })

  const validateFileName = useCallback(() => {
    if (fileName.length === 0 || fileName.length > FILE_NAME_MAX_LENGTH) {
      setError({
        hasError: true,
        error: `File name must be between 1 and ${FILE_NAME_MAX_LENGTH} characters`,
      })
      return false
    } else {
      setError({ hasError: false })
      return true
    }
  }, [fileName.length])

  useEffect(() => {
    setInput(file.fileName)
  }, [file])

  useEffect(() => {
    validateFileName()
  }, [validateFileName])

  return (
    <Modal
      open={open}
      onOk={() => {
        // Just in case the user somehow passes guard checks
        if (!validateFileName()) {
          return
        }

        onSubmit(file.fileGuid, fileName)
      }}
      onCancel={onCancel}
      title="Edit File Name"
      okText={loading ? 'Saving...' : 'Save'}
      okButtonProps={{
        icon: loading ? (
          <LoadingSpinner noMinHeight spinnerClassName="h-4 w-4" />
        ) : undefined,
        disabled: fileName === file.fileName || loading,
      }}
    >
      <Form.Item
        validateStatus={error.hasError ? 'error' : ''}
        help={error.hasError ? error.error : ''}
        className={error.hasError ? 'pb-4' : ''}
      >
        <Input
          placeholder="Enter file name here"
          value={input}
          onChange={event => setInput(event.currentTarget.value)}
        />
      </Form.Item>
    </Modal>
  )
}
