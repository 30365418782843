import {
  formatMoney,
  PaymentMethodDisplayNames,
  usCentsToUsd,
} from '@breezy/shared'
import { faOctagonExclamation } from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'antd'
import React from 'react'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import { DetailRow } from '../../../../elements/StatusCard/DetailRow'
import { StatusCard } from '../../../../elements/StatusCard/StatusCard'
import { CommonPaymentStatusProps } from './PaymentStatusCard'

export type PaymentErrorCardProps = CommonPaymentStatusProps & {
  /* The error message */
  errorMessage: string
  /* The error code */
  errorCode?: string
  /* The suggested error solution if there is one */
  solution?: string
}

export const PaymentErrorCard = React.memo<
  PaymentErrorCardProps & {
    onClose?: () => void
  }
>(
  ({
    nameOfPayer,
    paymentAmountUsc,
    paymentMethod,
    errorMessage,
    errorCode,
    solution,
    onClose,
  }) => {
    return (
      <StatusCard
        type="error"
        icon={faOctagonExclamation}
        title="Payment Failed"
        description="The payment attempt has failed. Please review the details below and try again."
        footer={
          <div className="mt-4 flex w-full flex-row gap-3 pb-6">
            <Button
              className="flex-1"
              size="large"
              data-dd-action-name="BZ Payment Workflow - Payment Error - Cancel"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <DetailRow
          label="Name of Payer"
          value={nameOfPayer}
          labelClassName="text-bz-red-800"
        />
        <DetailRow
          label="Amount"
          value={formatMoney(usCentsToUsd(paymentAmountUsc))}
          labelClassName="text-bz-red-800"
        />
        <DetailRow
          label="Payment Method"
          value={PaymentMethodDisplayNames[paymentMethod]}
          labelClassName="text-bz-red-800"
        />
        <RenderIf if={!!errorCode}>
          <DetailRow
            label="Error Code"
            value={errorCode?.toUpperCase()}
            labelClassName="text-bz-red-800"
          />
        </RenderIf>
        <DetailRow
          label="Error Message"
          value={errorMessage}
          labelClassName="text-bz-red-800"
        />
        <RenderIf if={!!solution}>
          <DetailRow
            label="Solution"
            value={solution}
            labelClassName="text-bz-red-800"
          />
        </RenderIf>
      </StatusCard>
    )
  },
)
