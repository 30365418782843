import {
  bzExpect,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  ZonedDateTime,
  ZoneId,
} from '@breezy/shared'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { DateFormat, TimezoneStrategy } from '../../utils/DateUtils'

export type DateViewProps = {
  isoWithOffsetTimestamp: string
  format?: DateFormat
  zone?: TimezoneStrategy
}

export const DateView: React.FC<DateViewProps> = ({
  isoWithOffsetTimestamp,
  zone = { type: 'company-timezone-of-principal-user' },
  format = DateFormat['MM/dd/YYYY'],
}) => {
  const principalContext = usePrincipalUser()
  let zoneId: ZoneId

  switch (zone.type) {
    case 'browser':
    case 'company-timezone-of-principal-user':
      zoneId = ZoneId.of(
        bzExpect(
          principalContext.principal?.company?.timezone,
          'Company Timezone',
          'Missing company timezone',
        ),
      )
      break
    case 'location':
      throw new Error('Not yet supported')
    case 'explicit':
      if (typeof zone.timezone === 'string') {
        zoneId = ZoneId.of(zone.timezone)
      } else {
        zoneId = zone.timezone
      }
      break
  }

  return (
    <>
      {ZonedDateTime.parse(isoWithOffsetTimestamp)
        .withZoneSameInstant(zoneId)
        .format(DateTimeFormatter.ofPattern(format).withLocale(ENGLISH_LOCALE))}
    </>
  )
}
