import {
  BzDateFns,
  bzExpect,
  formatInvoiceTerm,
  InvoiceTerm,
  R,
} from '@breezy/shared'
import { Form, Select } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'urql'
import { OnsiteConfirmModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { BehindFeatureFlag } from '../../../components/BehindFeatureFlag'
import DatePicker, {
  DatePickerProps,
} from '../../../components/DatePicker/DatePicker'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { trpc } from '../../../hooks/trpc'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { useIsTechApp } from '../../../providers/AppContextWrapper'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useStrictContext } from '../../../utils/react-utils'
import { ESTIMATE_WITH_LINKED_JOB_QUERY } from '../EstimatesFlow.gql'
import { EstimatesContext } from '../estimatesFlowUtils'

const INVOICE_TERM_OPTIONS = R.pipe(
  R.values,
  R.without([InvoiceTerm.AUTO]),
  R.map(name => ({ label: formatInvoiceTerm(name), value: name })),
)(InvoiceTerm)

type FinalizeInvoiceDrawerProps = {
  onClose: () => void
}

export const FinalizeInvoiceDrawer = React.memo<FinalizeInvoiceDrawerProps>(
  ({ onClose }) => {
    const linkedJobsFeatureFlagEnabled = useFeatureFlag('linkedJobs')

    const tzId = useExpectedCompanyTimeZoneId()

    const navigate = useNavigate()

    const isTechApp = useIsTechApp()

    const { defaultInvoiceTerm, estimateGuid } =
      useStrictContext(EstimatesContext)

    const defaultDate = useMemo(() => BzDateFns.now(tzId), [tzId])

    const [serviceCompletionDate, setServiceCompletionDate] = useState(() =>
      BzDateFns.formatLocalDate(defaultDate),
    )

    const onDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >((date, dateString) => {
      if (date) {
        const localDateString = BzDateFns.formatLocalDate(date)
        setServiceCompletionDate(localDateString)
      }
    }, [])

    const [invoiceTerm, setInvoiceTerm] =
      useState<InvoiceTerm>(defaultInvoiceTerm)

    const convertInvoiceMutation = trpc.invoice[
      'invoicing:estimatev2:convert-to-invoice'
    ].useMutation({
      onSuccess: res => {
        navigate(`/invoice/${res.invoiceGuid}`)
      },
    })

    const [createInvoiceForJob, setCreateInvoiceForJob] = useState<
      'linked-job' | 'original-job'
    >(linkedJobsFeatureFlagEnabled ? 'linked-job' : 'original-job')

    const estimateWithLinkedJobQuery = useQuery({
      query: ESTIMATE_WITH_LINKED_JOB_QUERY,
      variables: { estimateGuid },
    })

    const hasLinkedJob = useMemo(() => {
      const queryData = estimateWithLinkedJobQuery[0].data
      if (!queryData || !queryData.estimatesByPk) {
        return false
      }

      return queryData.estimatesByPk.linkedToJobs.length > 0
    }, [estimateWithLinkedJobQuery])

    const onConfirm = useCallback(() => {
      convertInvoiceMutation.mutate({
        estimateGuid,
        serviceCompletionDate,
        invoiceTerm,
        createForLinkedToJob: createInvoiceForJob === 'linked-job',
      })
    }, [
      convertInvoiceMutation,
      createInvoiceForJob,
      estimateGuid,
      invoiceTerm,
      serviceCompletionDate,
    ])

    useEffect(() => {
      if (linkedJobsFeatureFlagEnabled && hasLinkedJob) {
        setCreateInvoiceForJob('linked-job')
      } else {
        setCreateInvoiceForJob('original-job')
      }
    }, [hasLinkedJob, linkedJobsFeatureFlagEnabled])

    return (
      <OnsiteConfirmModal
        onCancel={onClose}
        onConfirm={onConfirm}
        header="Finalize invoice"
        loading={convertInvoiceMutation.isLoading}
      >
        <Form layout="vertical">
          <Form.Item label="Service Completion Date">
            <DatePicker
              className="m-0 w-full"
              onChange={onDateSelected}
              defaultValue={defaultDate}
              format="MMM. d, yyyy"
              allowClear={false}
              style={{
                minWidth: 160,
                marginLeft: 2,
                marginBottom: 24,
                marginTop: 8,
              }}
            />
          </Form.Item>

          <Form.Item label="Invoice Term">
            <Select
              options={INVOICE_TERM_OPTIONS}
              value={invoiceTerm}
              onChange={setInvoiceTerm}
            />
          </Form.Item>

          <BehindFeatureFlag
            enabledFeatureFlag="linkedJobs"
            render={
              <GqlQueryLoader
                query={estimateWithLinkedJobQuery}
                render={data => {
                  if (!hasLinkedJob || isTechApp) {
                    return <></>
                  }

                  const linkedJob = bzExpect(
                    data.estimatesByPk?.linkedToJobs[0].job,
                  )

                  const linkedJobDateStr = BzDateFns.format(
                    BzDateFns.parseISO(linkedJob.createdAt, tzId),
                    'MMM d, y',
                  )

                  const originalJob = bzExpect(data.estimatesByPk?.job)

                  const originalJobDateStr = BzDateFns.format(
                    BzDateFns.parseISO(originalJob.createdAt, tzId),
                    'MMM d, y',
                  )

                  return (
                    <Form.Item label="Select Job">
                      <Select<typeof createInvoiceForJob>
                        value={createInvoiceForJob}
                        options={[
                          {
                            label: `#${linkedJob.displayId} (${linkedJob.jobType.name}) (Linked Job) - ${linkedJobDateStr}`,
                            value: 'linked-job',
                          },
                          {
                            label: `#${originalJob.displayId} (${originalJob.jobType.name}) - ${originalJobDateStr}`,
                            value: 'original-job',
                          },
                        ]}
                        onChange={setCreateInvoiceForJob}
                      />
                    </Form.Item>
                  )
                }}
              />
            }
          />
        </Form>
      </OnsiteConfirmModal>
    )
  },
)
