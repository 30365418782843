import classNames from 'classnames'
import React, { useMemo } from 'react'
import StatusTag from '../../../elements/StatusTag/StatusTag'
import useIsMobile from '../../../hooks/useIsMobile'

export type InvoicePaymentRefundStatusTagProps = {
  mode: 'full' | 'partial'
  className?: string
  heightClassName?: string
}

export const InvoicePaymentRefundStatusTag =
  React.memo<InvoicePaymentRefundStatusTagProps>(
    ({ mode, className, heightClassName = 'max-h-[26px]' }) => {
      const isMobile = useIsMobile()

      const message = useMemo(() => {
        if (mode === 'full') {
          return 'Refunded'
        }

        if (isMobile) {
          return 'Partial'
        }

        return 'Partially refunded'
      }, [mode, isMobile])

      return (
        <StatusTag
          className={classNames(heightClassName, className)}
          color="orange"
          border="strong"
          text={message}
        />
      )
    },
  )
