// Why does this Tilled-related file live in Domain? The goose is cooked on any type of abstracted implementation.
// The frontend integration is a deeply-coupled integration that plugs in, in two different ways
// So, we're now too far down the tight-coupling road to back out.
// We're putting this here to at least hope to have some semblence of consistency in the Error Messages
// across the frontend and backend.

import { z } from 'zod'
import { Log, isNullish } from '../../../common'
import { PaymentMethod, PaymentStatusErrorDetail } from './PaymentTypes'

const getKnownUserFriendlyErrorMessage = (originalErrorMessage: string): string | undefined => {
  if (originalErrorMessage.includes('failed AVS check')) {
    return `Address Mismatch. Please ensure the billing address matches the card details or use another payment method.`
  }
  if (originalErrorMessage.includes('failed CVV check')) {
    return `CVV Mismatch. Please ensure the CVV matches the card details or use another payment method.`
  }
  if (originalErrorMessage.includes('exceeded the balance or credit limit available')) {
    return `Credit Limit Hit. Please ensure the card has enough remaining available credit or use another payment method.`
  }
  return undefined
}

export const getTilledUserFriendlyErrorMessage = (originalErrorMessage?: string): string => {
  if (!originalErrorMessage) return 'Cannot submit payment. Please try again later.'

  const knownErrorMessage = getKnownUserFriendlyErrorMessage(originalErrorMessage)
  if (knownErrorMessage) return knownErrorMessage

  const tunedMessage = originalErrorMessage.replace('The customer has', 'You have').replace('their', 'your')
  return tunedMessage
}

// Types Duplicated from tilledSdkTypes.ts
type TilledPaymentIntentStatus =
  | 'canceled'
  | 'processing'
  | 'requires_action'
  | 'requires_capture'
  | 'requires_confirmation'
  | 'requires_payment_method'
  | 'succeeded'

export type TilledPaymentIntentDto = {
  status: TilledPaymentIntentStatus
  last_payment_error?: TilledPaymentErrorDetail
}

export type TilledPaymentErrorDetail = {
  code: string
  message: string
}

export const isTilledPaymentErrorDetail = (error: unknown): error is TilledPaymentErrorDetail => {
  return !isNullish((error as TilledPaymentErrorDetail)?.code)
}

export const getTilledStatusDetail = (payment: TilledPaymentIntentDto): string => {
  if (payment.last_payment_error?.message) return getTilledUserFriendlyErrorMessage(payment.last_payment_error?.message)
  return payment.status
}

// https://docs.tilled.com/docs/testing/processing-error-codes/#card-declines
export const TilledCardDeclineErrorCodes = [
  'INSUFFICIENT_FUNDS',
  'AVS_CHECK_FAILED',
  'GENERIC_DECLINE',
  'CALL_ISSUER',
  'EXPIRED_CARD',
  'PICKUP_CARD',
  'INVALID_NUMBER',
  'LIMIT_EXCEEDED',
  'NOT_PERMITTED',
  'INCORRECT_CVC',
  'SERVICE_NOT_ALLOWED',
  'INVALID_EXPIRY',
  'CARD_NOT_SUPPORTED',
  'RESTRICTED_CARD',
  'FRAUDULENT',
  'PROCESSING_ERROR',
] as const

export type TilledCardDeclineErrorCode = (typeof TilledCardDeclineErrorCodes)[number]

// https://docs.tilled.com/docs/testing/processing-error-codes/#card-declines
export const TilledCardDeclineSolutions: Record<TilledCardDeclineErrorCode, string> = {
  INSUFFICIENT_FUNDS: 'A different payment method must be used by the customer.',
  AVS_CHECK_FAILED:
    'The address you enter during the checkout process must match the billing address on your credit card.',
  GENERIC_DECLINE: 'For more information, the customer should contact their bank.',
  CALL_ISSUER: 'For more information, the customer should contact their bank.',
  EXPIRED_CARD: 'The customer must use a different card.',
  PICKUP_CARD: 'The customer needs to contact their card issuer for more information.',
  INVALID_NUMBER: 'The customer must try again using the correct card number.',
  LIMIT_EXCEEDED: 'The customer needs to contact their card issuer for more information.',
  NOT_PERMITTED: 'The customer needs to contact their card issuer for more information.',
  INCORRECT_CVC: 'The customer must try again using the correct CVC.',
  SERVICE_NOT_ALLOWED: 'The customer needs to contact their card issuer for more information.',
  INVALID_EXPIRY: 'The customer must try again using the correct expiration date.',
  CARD_NOT_SUPPORTED:
    'To confirm that their card can be used for this type of purchase, the customer needs to contact their card issuer.',
  RESTRICTED_CARD: 'The customer needs to contact their card issuer for more information.',
  FRAUDULENT:
    'Avoid providing your customer with more specific details. Instead, advise the customer to get in touch with the card issuer for more information',
  PROCESSING_ERROR: 'Retry the payment. Try again later if it is still unable to be processed.',
}

// https://docs.tilled.com/docs/testing/processing-error-codes/#ach-debit-returns
export const TilledAchDebitReturnErrorCodes = [
  'INSUFFICIENT_FUNDS',
  'ACCOUNT_CLOSED',
  'NO_ACCOUNT',
  'ACCOUNT_NUMBER_INVALID',
  'AUTHORIZATION_REVOKED',
  'STOP_PAYMENT',
  'NOT_RECOGNIZED',
  'ACCOUNT_FROZEN',
  'NOT_PERMITTED',
  'INVALID_ID_FIELD',
  'NOT_AUTHORIZED',
] as const

export type TilledAchDebitReturnErrorCode = (typeof TilledAchDebitReturnErrorCodes)[number]

// https://docs.tilled.com/docs/testing/processing-error-codes/#ach-debit-returns
export const TilledAchDebitReturnSolutions: Record<TilledAchDebitReturnErrorCode, string> = {
  INSUFFICIENT_FUNDS:
    'Contact the account holder to discuss other payment options, or attempt the transaction again once sufficient funds are available.',
  ACCOUNT_CLOSED:
    'Verify the account information and attempt the transaction again with the correct account information, or contact the account holder to obtain updated account information.',
  NO_ACCOUNT:
    'Verify the account information and attempt the transaction again with the correct account information, or contact the account holder to obtain updated account information.',
  ACCOUNT_NUMBER_INVALID:
    'Verify the account information and attempt the transaction again with the correct account information, or contact the account holder to obtain updated account information.',
  AUTHORIZATION_REVOKED:
    'Contact the account holder to determine the reason for revoking authorization and take appropriate action to resolve the issue.',
  STOP_PAYMENT:
    'Contact the account holder to determine the reason for stopping payment and take appropriate action to resolve the issue.',
  NOT_RECOGNIZED:
    'Verify that the account holder has authorized the transaction, and if not, contact the account holder to resolve the issue.',
  ACCOUNT_FROZEN:
    'Contact the account holder to determine the reason for the freeze and take appropriate action to resolve the issue. If the account is frozen due to legal action, consult with legal counsel to determine the next steps.',
  NOT_PERMITTED:
    'Verify the account information and ensure that the account is a valid transaction account. If the account is not a transaction account, contact the account holder to obtain the correct account information.',
  INVALID_ID_FIELD:
    'Review the transaction information and ensure that all required fields are completed correctly. Resubmit the transaction with the correct information.',
  NOT_AUTHORIZED:
    'Verify that the transaction was authorized by the corporate account holder. If not, contact the corporate account holder to resolve the issue. If the transaction was authorized, contact the receiving bank to determine the reason for the return and take appropriate action.',
}

export const getTilledPaymentErrorCodeSolution = (
  paymentMethod: PaymentMethod.CARD | PaymentMethod.ACH,
  errorCode: string,
): string => {
  // Convert the Tilled Error Code to uppercase for compatibility with our error code constants
  errorCode = errorCode.toUpperCase()
  if (paymentMethod === PaymentMethod.CARD) {
    return (
      TilledCardDeclineSolutions[errorCode as TilledCardDeclineErrorCode] ??
      'Please contact support for further assistance.'
    )
  }

  return (
    TilledAchDebitReturnSolutions[errorCode as TilledAchDebitReturnErrorCode] ??
    'Please contact support for further assistance.'
  )
}

export const getTilledPaymentErrorDetail = (
  paymentMethod: PaymentMethod,
  maybeError: unknown,
): PaymentStatusErrorDetail | undefined => {
  if (paymentMethod !== PaymentMethod.CARD && paymentMethod !== PaymentMethod.ACH) {
    Log.warn('getTilledPaymentErrorDetail called with invalid payment method', { paymentMethod })
    return undefined
  }
  if (isTilledPaymentErrorDetail(maybeError)) {
    const uppercasedCode = maybeError.code.toLocaleUpperCase()
    return {
      errorCode: uppercasedCode,
      errorMessage: maybeError.message,
      errorSolution: getTilledPaymentErrorCodeSolution(paymentMethod, uppercasedCode),
    }
  }
  return undefined
}

/*
 * This isn't a good place for these types, they're coupled to Tilled and are more
 * dev tool/testing focused but I need a way to share these without blowing up
 * Cypress. Ideally this lives under a Tilled types, a testing utils, or a dev-tools
 * package but for now they'll stink in here.
 */
type Option = {
  label: string
  value: string
}
type TilledCreditCardInfo = Option & {
  cardNumber: string
  cardExpiry: string
  cardCvv: string
}

export const TILLED_SANDBOX_CREDIT_CARD_INFO = [
  {
    label: 'Visa (Credit)',
    value: 'visa-credit',
    cardNumber: '4111 1111 1111 1111',
    cardExpiry: '08/26',
    cardCvv: '111',
  },
  {
    label: 'Visa (Debit)',
    value: 'visa-debit',
    cardNumber: '4900 7700 0000 0001',
    cardExpiry: '08/26',
    cardCvv: '111',
  },
  {
    label: 'Mastercard',
    value: 'mastercard',
    cardNumber: '5100 4000 0000 0000',
    cardExpiry: '08/26',
    cardCvv: '111',
  },
  {
    label: 'Discover',
    value: 'discover',
    cardNumber: '6011 2345 6789 0123',
    cardExpiry: '08/26',
    cardCvv: '111',
  },
  {
    label: 'American Express',
    value: 'amex',
    cardNumber: '3701 234567 89017',
    cardExpiry: '08/26',
    cardCvv: '2222',
  },
] as const satisfies TilledCreditCardInfo[]

export type TilledCreditCardSandboxInfo = (typeof TILLED_SANDBOX_CREDIT_CARD_INFO)[number]['value']

export const getTilledCreditCardInfo = (card: TilledCreditCardSandboxInfo): TilledCreditCardInfo => {
  return TILLED_SANDBOX_CREDIT_CARD_INFO.find(({ value }) => value === card) as TilledCreditCardInfo
}

export type TilledAchDebitInfo = Option & {
  accountNumber: string
  routingNumber: string
}

export const TILLED_SANDBOX_ACH_DEBIT_INFO = [
  {
    label: 'TD Bank Connecticut',
    value: 'td-bank-connecticut',
    accountNumber: '123412341234',
    routingNumber: '011103093',
  },
  {
    label: 'TD Bank Florida',
    value: 'td-bank-florida',
    accountNumber: '123412341234',
    routingNumber: '067014822',
  },
  {
    label: 'Bank of America',
    value: 'bank-of-america',
    accountNumber: '123412341234',
    routingNumber: '011000138',
  },
  {
    label: 'Wells Fargo Bank',
    value: 'wells-fargo-bank',
    accountNumber: '123412341234',
    routingNumber: '021101108',
  },
  {
    label: 'HSBC',
    value: 'hsbc',
    accountNumber: '123412341234',
    routingNumber: '021004823',
  },
] as const satisfies TilledAchDebitInfo[]

export type TilledAchDebitSandboxInfo = (typeof TILLED_SANDBOX_ACH_DEBIT_INFO)[number]['value']

export const getTilledAchDebitInfo = (card: TilledAchDebitSandboxInfo): TilledAchDebitInfo => {
  return TILLED_SANDBOX_ACH_DEBIT_INFO.find(({ value }) => value === card) as TilledAchDebitInfo
}

export const TilledAchDebitAccountTypeSchema = z.enum(['checking', 'savings'])
export type TilledAchDebitAccountType = z.infer<typeof TilledAchDebitAccountTypeSchema>
