import { Instant, R } from '../../common'
import { Location, LocationGuid } from '../Locations/Location'
import { Contact, ContactGuid } from '../contacts/Contact'

export const LOCATION_CONTACT_RELATION_TYPE_MAP = {
  HOME_OWNER: {
    displayName: 'Home Owner',
  },
  PREVIOUS_HOME_OWNER: {
    displayName: 'Previous Home Owner',
  },
  TENANT__OCCUPANT: {
    displayName: 'Tenant / Occupant',
  },
  PREVIOUS_TENANT__OCCUPANT: {
    displayName: 'Previous Tenant / Occupant',
  },
  PROPERTY_MANAGER: {
    displayName: 'Property Manager',
  },
  BUILDER: {
    displayName: 'Builder',
  },
  HOA_CONTACT: {
    displayName: 'HOA Contact',
  },
  REALTOR__REAL_STATE_AGENT: {
    displayName: 'Realtor / Real Estate Agent',
  },
  PLUMBER: {
    displayName: 'Plumber',
  },
  ELECTRICIAN: {
    displayName: 'Electrician',
  },
  CONTRACTOR: {
    displayName: 'Contractor',
  },
  VENDOR: {
    displayName: 'Vendor',
  },
  UTILITY_PROVIDER: {
    displayName: 'Utility Provider',
  },
} as const

export type LocationContactRelationType = keyof typeof LOCATION_CONTACT_RELATION_TYPE_MAP

export const LocationContactRelationTypeValues = R.keys(LOCATION_CONTACT_RELATION_TYPE_MAP)
export type LocationContactRelationDisplayName =
  (typeof LOCATION_CONTACT_RELATION_TYPE_MAP)[LocationContactRelationType]['displayName']

export const locationContactRelationTypeToDisplayName = (
  locationRelationshipType: LocationContactRelationType,
): LocationContactRelationDisplayName => LOCATION_CONTACT_RELATION_TYPE_MAP[locationRelationshipType].displayName

export type BaseLocationContact = {
  locationGuid: LocationGuid
  contactGuid: ContactGuid
  locationContactRelationTypes: LocationContactRelationType[]
  notes?: string
}

export type LocationContactRecord = BaseLocationContact & {
  createdAt: Instant
  updatedAt: Instant
}

export type LocationContact = LocationContactRecord & {
  location: Location
  contact: Contact
}

export type UpsertLocationContactInput = BaseLocationContact

export type UpsertLocationContact = (locationContact: UpsertLocationContactInput) => Promise<void>

export type RemoveLocationContactRelationshipInput = {
  locationGuid: LocationGuid
  contactGuid: ContactGuid
}

export type RemoveLocationContactRelationship = (input: RemoveLocationContactRelationshipInput) => Promise<void>
