import React, { useCallback } from 'react'
import {
  WysiwygEditor,
  WysiwygEditorProps,
} from '../../components/WysiwygEditor/WysiwygEditor'

type RichTextAreaFieldProps = Omit<WysiwygEditorProps, 'onChange'> & {
  onChange: (e: { target: { value: string } }) => void
}

export const RichTextAreaField = React.memo<RichTextAreaFieldProps>(
  React.forwardRef<HTMLDivElement, RichTextAreaFieldProps>(
    ({ onChange, editorClassName = 'min-h-[100px]', ...rest }, ref) => {
      const ourOnChange = useCallback(
        (value: string) => onChange({ target: { value } }),
        [onChange],
      )
      return (
        <WysiwygEditor
          ref={ref}
          onChange={ourOnChange}
          {...rest}
          editorClassName={editorClassName}
        />
      )
    },
  ),
)
