import {
  AccountGuid,
  CartItemUsc,
  DEFAULT_BLURB,
  DUMMY_GUID,
  DiscountType,
  DynamicPricingType,
  EstimateV2Status,
  Guid,
  HtmlString,
  InvoiceTerm,
  JobAppointmentGuid,
  JobGuid,
  LocationGuid,
  PricebookTaxRateDto,
  R,
  isFinanceableAmountUsd,
  nextGuid,
} from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import { DiscountPickerDiscount } from '../../components/Pricebook/DiscountMultiPicker'
import { AsyncPhotoData } from '../../components/Upload/AsyncPhotoUpload'
import { StatusTagColor } from '../../elements/StatusTag/StatusTag'
import {
  EstimateOptionsInsertInput,
  GetEstimateSubscription,
} from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  usePrincipalUser,
} from '../../providers/PrincipalUser'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import {
  RELEVANT_ESTIMATE_DATA_QUERY,
  UPDATE_ESTIMATE_MUTATION,
  UPSERT_ESTIMATE_MUTATION,
} from './EstimatesFlow.gql'

export const JOB_APPOINTMENT_GUID_QUERY_PARAM = 'ja' as const

export type OptionCartItem = CartItemUsc & {
  cartItemGuid: Guid
  seq: number
  savedToPricebook?: boolean
}

export type OptionDiscount = DiscountPickerDiscount

export type OptionWithoutTotal = {
  optionGuid: Guid
  displayName?: string
  descriptionHtml?: HtmlString
  lineItems: OptionCartItem[]
  discounts: OptionDiscount[]
  recommended: boolean
  selected: boolean
  seq: number
  featuredPhotoGuid?: Guid
  featuredPhotoCdnUrl?: string
}

export type Option = OptionWithoutTotal & {
  totalUsc: number
}

export type FetchedEstimate = NonNullable<
  GetEstimateSubscription['estimatesByPk']
>

export type EstimatesContextType = {
  estimateGuid: Guid
  jobGuid: JobGuid
  accountGuid: AccountGuid
  locationGuid: LocationGuid
  jobAppointmentGuid?: JobAppointmentGuid
  realPricebookItemGuidMap: Record<Guid, true>
  disclaimer: string
  defaultInvoiceTerm: InvoiceTerm
  companyName: string
  companyBlurb: string
  logoUrl: string
  logoPhotoGuid: Guid
  logoPhotoCdnUrl: string
  /* Used for linking a contact to a prequal application in the consumer estimate review page */
  prequalContactGuid?: Guid
}

export const getPhotoRecordsFromEstimate = (
  estimatePhotos: FetchedEstimate['estimatePhotos'],
): EstimatePhoto[] =>
  estimatePhotos.map(photo => ({
    photoGuid: photo.photoGuid,
    cdnUrl: photo.photo.cdnUrl,
  }))

export const EstimatesContext = React.createContext<
  EstimatesContextType | undefined
>(undefined)

export type EstimatePointOfContact = {
  firstName: string
  fullName: string
  phoneNumber?: string
  email?: string
}

export type EstimateLocation = {
  line1: string
  city: string
  stateAbbreviation: string
  zipCode: string
}

export type EstimateDataContextType = {
  messageHtml: HtmlString
  taxRate: PricebookTaxRateDto
  dynamicPricingType?: DynamicPricingType
  options: Option[]
  photoRecords: EstimatePhoto[]
  pointOfContact?: EstimatePointOfContact
  location?: EstimateLocation
}

export const EstimateDataContext = React.createContext<
  EstimateDataContextType | undefined
>(undefined)

type FetchedEstimatePhoto = {
  photoGuid: Guid
  cdnUrl: string
  fromJob?: boolean
}

type UploadedEstimatePhoto = FetchedEstimatePhoto & AsyncPhotoData

export type EstimatePhoto = FetchedEstimatePhoto | UploadedEstimatePhoto

export const isUploadedEstimatePhoto = (
  photo: EstimatePhoto,
): photo is UploadedEstimatePhoto => !!(photo as UploadedEstimatePhoto).filename

type EstimateEditContextType = {
  setMessageHtml: StateSetter<HtmlString>
  setTaxRate: StateSetter<PricebookTaxRateDto>
  setDynamicPricingType: StateSetter<DynamicPricingType | undefined>
  onSetRecommended: (index: number, recommended?: boolean) => void
  setSelectedOptionIndex: StateSetter<number>
  shiftOption: (index: number, up: boolean) => void
  deleteOption: (index: number) => void
  duplicateOption: (index: number) => void
  isDirty: boolean
  clearDirtyFlag: () => void
  status?: EstimateV2Status
  originalAcceptedOptionTotalUsc?: number
  photoRecords: EstimatePhoto[]
  setPhotoRecords: StateSetter<EstimatePhoto[]>
}

export const EstimateEditContext = React.createContext<
  EstimateEditContextType | undefined
>(undefined)

export const useRelevantEstimateData = () => {
  const companyGuid = useExpectedCompanyGuid()

  // urql's `useQuery` will infuriatingly re-run when we do a mutation, which I really don't want because the whole page
  // will remount. So this tells it to cut it out after its initial fetch.
  const [isPaused, setIsPaused] = useState(false)

  const [
    { data: relevantEstimateData, fetching: fetchingRelevantEstimateData },
  ] = useQuery({
    query: RELEVANT_ESTIMATE_DATA_QUERY,
    variables: {
      companyGuid,
    },
    pause: isPaused,
  })

  useEffect(() => {
    if (relevantEstimateData) {
      setIsPaused(true)
    }
  }, [relevantEstimateData])

  const realPricebookItemGuidMap = useMemo(() => {
    const map: Record<Guid, true> = {}
    for (const pricebookItem of relevantEstimateData?.pricebookItems ?? []) {
      map[pricebookItem.pricebookItemGuid] = true
    }
    return map
  }, [relevantEstimateData?.pricebookItems])

  const defaultInvoiceTerm = useMemo(() => {
    const rawTerm =
      relevantEstimateData?.billingProfilesByPk?.invoiceTerm ??
      InvoiceTerm.DUE_ON_RECEIPT
    return rawTerm !== InvoiceTerm.AUTO ? rawTerm : InvoiceTerm.DUE_ON_RECEIPT
  }, [relevantEstimateData?.billingProfilesByPk?.invoiceTerm])

  return {
    realPricebookItemGuidMap,
    companyDefaultTaxRateGuid:
      relevantEstimateData?.billingProfilesByPk?.defaultPricebookTaxRateGuid,
    disclaimer:
      relevantEstimateData?.billingProfilesByPk?.estimateDisclaimer ?? '',
    defaultInvoiceTerm,
    companyName: relevantEstimateData?.companiesByPk?.name ?? '',
    companyBlurb:
      relevantEstimateData?.companyConfigByPk?.blurb ?? DEFAULT_BLURB,
    logoUrl: relevantEstimateData?.billingProfilesByPk?.logoUrl ?? '',
    logoPhotoGuid:
      relevantEstimateData?.billingProfilesByPk?.logoPhotoGuid ?? '',
    logoPhotoCdnUrl:
      relevantEstimateData?.billingProfilesByPk?.logoPhoto?.cdnUrl ?? '',
    isFetching: fetchingRelevantEstimateData,
  }
}

export type RelevantEstimateData = ReturnType<typeof useRelevantEstimateData>

export const useOptionsFromEstimateQuery = (
  estimateOptions:
    | NonNullable<GetEstimateSubscription['estimatesByPk']>['estimateOptions']
    | undefined,
  noSort?: boolean,
) => {
  return useMemo<Option[]>(() => {
    const options: Option[] = (estimateOptions || []).map(
      ({ estimateOptionDiscounts, cartItems, __typename, ...rest }) => ({
        ...rest,
        featuredPhotoGuid: rest.featuredPhotoGuid,
        featuredPhotoCdnUrl: rest.featuredPhoto?.cdnUrl,
        discounts: estimateOptionDiscounts.map(
          ({
            discountType,
            discountAmountUsc,
            discountRate,
            descriptionHtml,
            name,
            __typename,
            ...rest
          }) => {
            const common = {
              ...rest,
              name: name ?? '',
              descriptionHtml: descriptionHtml ?? '',
            }
            if (discountType === DiscountType.FLAT) {
              return {
                type: DiscountType.FLAT,
                // Though the typing thinks this could be undefined, we have DB-level checks that make sure if the type is
                // flat this isn't null
                discountAmountUsc: discountAmountUsc ?? 0,
                ...common,
              }
            } else {
              return {
                type: DiscountType.RATE,
                // Like above, we have DB-level checks that enforce that this won't be undefined if the type is rate
                discountRate: discountRate ?? 0,
                ...common,
              }
            }
          },
        ),
        lineItems: cartItems.map(
          ({
            cartItem: {
              originalPricebookItemGuid,
              description,
              photo,
              __typename,
              ...rest
            },
            seq,
          }) => ({
            // The type needs to have a guid here. If it was an ad-hoc item this will be null, so we need to put some
            // fake nonsense in here.
            itemGuid: originalPricebookItemGuid ?? nextGuid(),
            description: description ?? '',
            seq,
            photoCdnUrl: photo?.cdnUrl,
            photoGuid: photo?.photoGuid,
            ...rest,
          }),
        ),
      }),
    )
    if (noSort) {
      return options
    }
    return R.sort(R.descend(R.prop('selected')), options)
  }, [estimateOptions, noSort])
}

type SaveableEstimate = {
  estimateGuid: Guid
  status?: EstimateV2Status
  messageHtml?: HtmlString
  taxRate: PricebookTaxRateDto
  dynamicPricingType?: DynamicPricingType
  options: Option[]
  photoRecords: EstimatePhoto[]
}

export const useSaveEstimate = (existingDisplayId?: number) => {
  const companyGuid = useExpectedCompanyGuid()
  const user = usePrincipalUser()

  const {
    jobGuid,
    locationGuid,
    accountGuid,
    realPricebookItemGuidMap,
    jobAppointmentGuid,
  } = useStrictContext(EstimatesContext)

  const [, upsertEstimate] = useMutation(UPSERT_ESTIMATE_MUTATION)

  const generateDisplayIdMutation =
    trpc.invoice['invoicing:generate-display-id'].useMutation()

  const [isUpserting, setIsUpserting] = useState(false)
  const [displayId, setDisplayId] = useState<number | undefined>(
    existingDisplayId,
  )

  const saveEstimate = useCallback(
    async ({
      status,
      messageHtml,
      options,
      estimateGuid,
      taxRate,
      dynamicPricingType,
      photoRecords,
    }: SaveableEstimate) => {
      // Shouldn't be possible
      if (!user.principal?.userGuid) {
        return
      }
      setIsUpserting(true)

      try {
        const estimateOptionGuids: Guid[] = []
        const estimateOptionDiscountGuids: Guid[] = []
        const validCartItemGuids: Guid[] = []

        for (const option of options) {
          estimateOptionGuids.push(option.optionGuid)
          for (const { cartItemGuid } of option.lineItems) {
            validCartItemGuids.push(cartItemGuid)
          }
          for (const { discountGuid } of option.discounts) {
            estimateOptionDiscountGuids.push(discountGuid)
          }
        }

        const estimatePhotoGuids: Guid[] = []
        const nonJobPhotoGuids: Guid[] = []
        for (const photo of photoRecords) {
          estimatePhotoGuids.push(photo.photoGuid)
          if (!photo.fromJob) {
            nonJobPhotoGuids.push(photo.photoGuid)
          }
        }

        let ourDisplayId = displayId
        if (!ourDisplayId) {
          const newDisplayId = await generateDisplayIdMutation.mutateAsync({
            entityType: 'ESTIMATE',
          })
          ourDisplayId = newDisplayId
          setDisplayId(newDisplayId)
        }

        const upsertRes = await upsertEstimate({
          estimateGuid,
          companyGuid,
          estimateOptionGuids,
          estimateOptionDiscountGuids,
          validCartItemGuids,
          estimatePhotoGuids,
          jobPhotoLinks: nonJobPhotoGuids.map(photoGuid => ({
            photoGuid,
            jobGuid,
            accountGuid,
            locationGuid,
          })),
          estimate: {
            jobGuid,
            jobAppointmentGuid: jobAppointmentGuid || undefined,
            companyGuid,
            estimateGuid,
            messageHtml,
            status,
            displayId: ourDisplayId,
            taxRate: taxRate.rate,
            taxRateName: taxRate.name,
            taxRateGuid: taxRate.pricebookTaxRateGuid,
            dynamicPricingType,
            createdBy: user.principal.userGuid,
            estimatePhotos: photoRecords.length
              ? {
                  onConflict: {
                    constraint: 'estimate_photos_pkey',
                    updateColumns: [],
                  },
                  data: estimatePhotoGuids.map(photoGuid => ({
                    companyGuid,
                    photoGuid,
                  })),
                }
              : undefined,
            estimateOptions: {
              onConflict: {
                constraint: 'estimate_options_pkey',
                updateColumns: [
                  'descriptionHtml',
                  'displayName',
                  'seq',
                  'isRecommended',
                  'isSelected',
                  'totalUsc',
                  'featuredPhotoGuid',
                ],
              },
              data: options.map<EstimateOptionsInsertInput>(
                (
                  {
                    descriptionHtml,
                    discounts,
                    displayName,
                    optionGuid,
                    lineItems,
                    recommended,
                    selected,
                    totalUsc,
                    featuredPhotoGuid,
                  },
                  i,
                ) => ({
                  companyGuid,
                  descriptionHtml,
                  displayName,
                  totalUsc,
                  estimateOptionGuid: optionGuid,
                  seq: i,
                  isRecommended: !!recommended,
                  isSelected: !!selected,
                  featuredPhotoGuid,
                  estimateOptionDiscounts: {
                    onConflict: {
                      constraint: 'estimate_option_discounts_pkey',
                      updateColumns: [
                        'discountAmountUsc',
                        'discountRate',
                        'discountType',
                        'descriptionHtml',
                        'name',
                        'seq',
                      ],
                    },
                    data: discounts.map(
                      (
                        {
                          discountGuid,
                          type,
                          discountAmountUsc,
                          discountRate,
                          descriptionHtml,
                          name,
                        },
                        i,
                      ) => ({
                        companyGuid,
                        estimateOptionDiscountGuid: discountGuid,
                        discountType: type,
                        seq: i,
                        discountAmountUsc,
                        discountRate,
                        descriptionHtml,
                        name,
                      }),
                    ),
                  },
                  cartItems: {
                    onConflict: {
                      constraint: 'estimate_option_cart_items_pkey',
                      updateColumns: ['seq'],
                    },
                    data: lineItems.map(
                      ({
                        itemGuid,
                        itemType,
                        savedToPricebook,
                        seq,
                        photoCdnUrl,
                        ...rest
                      }) => ({
                        cartItem: {
                          onConflict: {
                            constraint: 'cart_items_pkey',
                            updateColumns: [
                              'name',
                              'description',
                              'quantity',
                              'unitPriceUsc',
                              'isTaxable',
                              'isDiscountable',
                              'cartItemType',
                              'originalPricebookItemGuid',
                              'photoGuid',
                            ],
                          },
                          data: {
                            ...rest,
                            companyGuid,
                            // If it's an ad-hoc item it gets a random guid, so we need to check if it's real
                            originalPricebookItemGuid: realPricebookItemGuidMap[
                              itemGuid
                            ]
                              ? itemGuid
                              : undefined,
                            cartItemType: itemType,
                          },
                        },
                        seq,
                      }),
                    ),
                  },
                }),
              ),
            },
          },
        })
        if (upsertRes.error) {
          throw upsertRes.error
        }

        return true
      } catch (e) {
        console.error(e)
      } finally {
        setIsUpserting(false)
      }
      return false
    },
    [
      accountGuid,
      companyGuid,
      displayId,
      generateDisplayIdMutation,
      jobAppointmentGuid,
      jobGuid,
      locationGuid,
      realPricebookItemGuidMap,
      upsertEstimate,
      user.principal?.userGuid,
    ],
  )

  return [saveEstimate, isUpserting] as const
}

export const getDuplicatedOption = ({
  optionGuid,
  lineItems,
  discounts,
  ...restOption
}: Option): Option => ({
  ...restOption,
  optionGuid: nextGuid(),
  lineItems: lineItems.map(({ cartItemGuid, ...restItem }) => ({
    cartItemGuid: nextGuid(),
    ...restItem,
  })),
  discounts: discounts.map(({ discountGuid, ...restDiscount }) => ({
    discountGuid: nextGuid(),
    ...restDiscount,
  })),
  selected: false,
  recommended: false,
})

export const useAnyFinanceable = (options: Option[]) => {
  const wisetackEnabled = useWisetackEnabled()

  const anyFinanceable = useMemo<boolean>(
    () =>
      (wisetackEnabled ?? false) &&
      options.some(o => !!isFinanceableAmountUsd(o.totalUsc)),
    [wisetackEnabled, options],
  )

  return anyFinanceable
}

export const useIsFinanceableCart = (totalPriceUsc: number) => {
  const wisetackEnabled = useWisetackEnabled()

  const isFinanceable = useMemo<boolean>(
    () => (wisetackEnabled ?? false) && !!isFinanceableAmountUsd(totalPriceUsc),
    [wisetackEnabled, totalPriceUsc],
  )

  return isFinanceable
}

type EstimateV2StatusMetadata = {
  label: string
  statusTagColor: StatusTagColor
  colorClassNames: string
  markAsOptions?: EstimateV2Status[]
  canDelete?: boolean
  hasSpecialMarkAsBehavior?: boolean
  // If we try to set an estimate to this status, but it's already in one of the statuses in this array, we don't do it.
  trumpedBy?: EstimateV2Status[]
}

const ESTIMATE_V2_STATUS_DISPLAY_INFO = {
  DRAFT: {
    label: 'Draft',
    colorClassNames: 'text-bz-gray-900 bg-bz-gray-400',
    statusTagColor: 'darkGray',
    canDelete: true,
  },
  CREATED: {
    label: 'Created',
    colorClassNames: 'text-cyan-900 bg-cyan-200',
    statusTagColor: 'cyan',
    markAsOptions: ['SENT', 'REVIEWED', 'ACCEPTED', 'VOIDED', 'CLOSED'],
    canDelete: true,
  },
  SENT: {
    label: 'Sent',
    colorClassNames: 'text-geek-blue-900 bg-geek-blue-200',
    statusTagColor: 'blue',
    markAsOptions: ['REVIEWED', 'ACCEPTED', 'VOIDED', 'CLOSED'],
    trumpedBy: ['REVIEWED', 'ACCEPTED', 'VOIDED', 'CLOSED', 'EXPIRED'],
  },
  REVIEWED: {
    label: 'Reviewed',
    colorClassNames: 'text-purple-900 bg-purple-200',
    statusTagColor: 'purple',
    markAsOptions: ['CREATED', 'SENT', 'ACCEPTED', 'VOIDED', 'CLOSED'],
    trumpedBy: ['ACCEPTED', 'VOIDED', 'CLOSED', 'EXPIRED'],
  },
  ACCEPTED: {
    label: 'Accepted',
    colorClassNames: `text-bz-green-900 bg-bz-green-200`,
    statusTagColor: 'green',
    markAsOptions: ['VOIDED', 'CLOSED'],
    hasSpecialMarkAsBehavior: true,
  },
  VOIDED: {
    label: 'Voided',
    colorClassNames: 'text-bz-red-900 bg-bz-red-200',
    statusTagColor: 'red',
  },
  CLOSED: {
    label: 'Closed - Lost',
    colorClassNames: 'text-bz-gray-900 bg-bz-gray-500',
    statusTagColor: 'darkGray',
    markAsOptions: ['ACCEPTED', 'VOIDED'],
  },
  EXPIRED: {
    label: 'Expired',
    colorClassNames: 'text-bz-gray-900 bg-bz-gray-400',
    statusTagColor: 'darkGray',
    markAsOptions: [
      'CREATED',
      'SENT',
      'REVIEWED',
      'ACCEPTED',
      'VOIDED',
      'CLOSED',
    ],
  },
} satisfies Record<EstimateV2Status, EstimateV2StatusMetadata>

export const getEstimateV2StatusDisplayInfo = (
  status: EstimateV2Status,
): EstimateV2StatusMetadata => ESTIMATE_V2_STATUS_DISPLAY_INFO[status]

type SpecialMarkAsStatus = {
  [K in keyof typeof ESTIMATE_V2_STATUS_DISPLAY_INFO]: (typeof ESTIMATE_V2_STATUS_DISPLAY_INFO)[K] extends {
    hasSpecialMarkAsBehavior: true
  }
    ? K
    : never
}[keyof typeof ESTIMATE_V2_STATUS_DISPLAY_INFO]

export const hasSpecialMarkAsBehavior = (
  status: EstimateV2Status,
): status is SpecialMarkAsStatus =>
  !!ESTIMATE_V2_STATUS_DISPLAY_INFO[status as SpecialMarkAsStatus]
    .hasSpecialMarkAsBehavior

export const DEFAULT_ESTIMATE_OPTION_NAME = 'Option'

export const CAROUSEL_ITEM_WIDTH = 660

// Use to update the status of the estimate. Useful for things like "setting the estimate to 'Sent' when they send". It
// verifies that you aren't setting it "backwards" either: if they have already "Reviewed" it and then send it, we don't
// set it to "Sent" because "Reviewed" trumps "Sent". The function takes an optional second argument "force", however,
// and if set we skip that check (in case you literally want to set it to exactly what you're choosing, necessary for a
// "Change Status" control where backwards moves are valid).
export const useEstimateStatusUpdater = (currentStatus?: EstimateV2Status) => {
  const { estimateGuid } = useStrictContext(EstimatesContext)
  const [{ fetching }, updateEstimate] = useMutation(UPDATE_ESTIMATE_MUTATION)

  const estimateStatusUpdater = useCallback(
    async (newStatus: EstimateV2Status, force?: boolean) => {
      if (!currentStatus) {
        return
      }
      if (newStatus === currentStatus) {
        return
      }
      if (
        !force &&
        getEstimateV2StatusDisplayInfo(newStatus).trumpedBy?.includes(
          currentStatus,
        )
      ) {
        return
      }
      let clearSelectedOptionsEstimateGuid = DUMMY_GUID
      if (currentStatus === 'ACCEPTED') {
        clearSelectedOptionsEstimateGuid = estimateGuid
      }
      await updateEstimate({
        estimateGuid,
        estimate: {
          status: newStatus,
        },
        clearSelectedOptionsEstimateGuid,
      })
    },
    [currentStatus, estimateGuid, updateEstimate],
  )

  return [estimateStatusUpdater, fetching] as const
}

export const isEstimateCreatePath = (pathname: string) =>
  !!pathname.match(
    /.*\/jobs\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}\/new-estimate$/,
  )

export const isEstimateOverviewPath = (pathname: string) =>
  !!pathname.match(
    /.*\/estimates\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}\/?$/,
  )
