import {
  DynamicPricingType,
  InvoiceCartItem,
  InvoiceDiscount,
  InvoicePayment,
  calculateInvoiceTotals,
} from '@breezy/shared'
import { Button } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'

type NegativeBalanceModalProps = {
  title: string
  description: React.ReactNode
  onClose: () => void
}

export const NegativeBalanceModal = React.memo<NegativeBalanceModalProps>(
  ({ title, description, onClose }) => {
    return (
      <OnsiteBasicModal
        open
        drawer
        onClose={onClose}
        header={title}
        footer={
          <Button
            className="mt-4"
            size="large"
            type="primary"
            onClick={onClose}
          >
            Got It
          </Button>
        }
      >
        {description}
      </OnsiteBasicModal>
    )
  },
)

type UseNegativeBalanceModalProps = Pick<
  NegativeBalanceModalProps,
  'title' | 'description'
> & {
  onClose?: () => void
}
export const useNegativeBalanceModal = () => {
  const [negativeBalanceProps, setNegativeBalanceProps] = useState<
    UseNegativeBalanceModalProps | undefined
  >()
  const hideNegativeBalanceModal = useCallback(() => {
    setNegativeBalanceProps(undefined)
  }, [])
  const showNegativeBalanceModal = useCallback(
    (props: UseNegativeBalanceModalProps) => {
      setNegativeBalanceProps(props)
    },
    [],
  )

  const onCloseInner = useCallback(() => {
    negativeBalanceProps?.onClose?.()
    setNegativeBalanceProps(undefined)
  }, [negativeBalanceProps])

  const content = useMemo(() => {
    if (!negativeBalanceProps) return null
    return (
      <NegativeBalanceModal {...negativeBalanceProps} onClose={onCloseInner} />
    )
  }, [negativeBalanceProps, onCloseInner])

  const negativeBalanceModal = useMemo(() => {
    return ReactDOM.createPortal(content, document.body)
  }, [content])

  return {
    negativeBalanceModal,
    showNegativeBalanceModal,
    hideNegativeBalanceModal,
  }
}

export const useResultsInNegativeBalance = (
  existingPayments?: InvoicePayment[],
) => {
  return useCallback(
    (
      lineItems: InvoiceCartItem[],
      taxRate: number,
      dynamicPricingType: DynamicPricingType | undefined,
      discounts: InvoiceDiscount[],
    ) => {
      const { dueUsc } = calculateInvoiceTotals(
        lineItems,
        taxRate,
        discounts,
        existingPayments ?? [],
        dynamicPricingType,
      )

      if (dueUsc < 0) {
        return true
      }

      return false
    },
    [existingPayments],
  )
}
