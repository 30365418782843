import { faSquare } from '@fortawesome/pro-light-svg-icons'
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { Styled } from '../../utils/Stylable'

export type BzCheckBoxProps = {
  label?: ReactNode | string
  value: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
  labelClassName?: string
  required?: boolean
  uncheckedColor?: string
}

const BzCheckBox = ({
  label,
  onChange,
  value,
  disabled,
  labelClassName,
  className,
  required,
  uncheckedColor,
}: Styled<BzCheckBoxProps>) => {
  const colorName = useMemo(() => {
    if (disabled) {
      return 'grey5'
    }
    if (!value && uncheckedColor) {
      return uncheckedColor
    }
    return 'blue6'
  }, [disabled, uncheckedColor, value])
  return (
    <div
      className={classNames(
        'flex w-fit cursor-pointer items-center',
        className,
        {
          'opacity-50': disabled,
        },
      )}
      onClick={() => {
        if (!disabled) {
          onChange?.(!value)
        }
      }}
    >
      <input checked={value} className="hidden" onChange={() => null} />
      <div className="h-6">
        {value ? (
          <FontAwesomeIcon
            icon={faSquareCheck}
            className={`${colorName} h-6 w-6`}
          />
        ) : (
          <FontAwesomeIcon icon={faSquare} className={`${colorName} h-6 w-6`} />
        )}
      </div>
      {label && (
        <div className={classNames('ml-3', labelClassName)}>
          {label}
          {/* With respect to being "required", checkboxes are pretty unique. In our
              app, if a form field is required, it gets a red asterisk in front of the
              label. But those form field labels are either on the left or above. Here
              the label is to the right. In order for it to not look weird with the red
              * between the checkbox and the label, we need to put it at the END of the
              label. Our "required" class puts it ""::before". So put a span at the end
              of the label that's empty and just has the *. */}
          {required && <span className="required ml-1" />}
        </div>
      )}
    </div>
  )
}

export default BzCheckBox
