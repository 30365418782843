import { PhoneNumberType } from '@breezy/shared'
import useIsMobile from '../../../hooks/useIsMobile'
import {
  PhoneNumberFormItem,
  PhoneNumberFormItemProps,
} from '../../form-fields/PhoneNumberFormItem/PhoneNumberFormItem'
import { PhoneNumberTypeFormItem } from '../../form-fields/PhoneNumberTypeFormItem/PhoneNumberTypeFormItem'

export type PhoneFormItemProps = {
  name?: string
  label?: string
  phoneNumber?: string
  phoneNumberType?: PhoneNumberType
  disabled?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChangeType?: (newType: PhoneNumberType) => void
  labelClassName?: string
  enabledSmsCapableControl: boolean
} & Pick<PhoneNumberFormItemProps, 'required' | 'requiredMessage'>

export const PhoneFormItem = ({
  name,
  label,
  phoneNumber,
  phoneNumberType,
  disabled,
  onBlur,
  onChangeType,
  labelClassName = '',
  required,
  requiredMessage,
  enabledSmsCapableControl,
}: PhoneFormItemProps) => {
  const isMobile = useIsMobile()

  return (
    <div className="flex w-full flex-col sm:flex-row sm:items-center">
      <div className="flex flex-1 flex-col">
        <PhoneNumberFormItem
          name={name}
          label={label}
          required={required}
          requiredMessage={requiredMessage}
          phoneNumber={phoneNumber}
          onBlur={onBlur}
          labelClassName={labelClassName}
          disabled={disabled}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <PhoneNumberTypeFormItem
          name={name ? `${name}Type` : undefined}
          enabled={enabledSmsCapableControl}
          type={phoneNumberType}
          onChange={onChangeType}
          label={isMobile ? '' : ' '}
        />
      </div>
    </div>
  )
}
