import { firstNameSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

type FirstNameFieldProps = {
  firstName?: string
  labelClassName?: string
}

export const FirstNameField: React.FC<FirstNameFieldProps> = ({
  firstName,
  labelClassName = '',
}) => (
  <Form.Item
    name="firstName"
    label="First Name"
    className={labelClassName}
    initialValue={firstName}
    rules={[
      {
        required: true,
        validator: async (_, value) => {
          if (!value) {
            throw new Error('First name is required')
          }

          const result = firstNameSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <Input
      defaultValue={firstName}
      autoComplete="given-name"
      id="firstName"
      name="firstName"
    />
  </Form.Item>
)
