import { telephoneNumberSchema } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PhoneNumberField } from '../../../elements/Forms/PhoneNumberField'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { useReactHookFormSubmit } from '../../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import LoanPromoDisclaimer from '../LoanPromo/LoanPromoDisclaimer'

const SendPrequalLinkFormSchema = z.object({
  phoneNumber: telephoneNumberSchema,
})

type SendPrequalLinkFormData = z.infer<typeof SendPrequalLinkFormSchema>

type SendPrequalLinkModalProps = {
  accountGuid: string
  jobGuid?: string
  onClose: () => void
  amountUsc?: number
  contactGuid: string
}
const SendPrequalLinkModal = React.memo<SendPrequalLinkModalProps>(
  ({ accountGuid, jobGuid, onClose, amountUsc, contactGuid }) => {
    const message = useMessage()
    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm<SendPrequalLinkFormData>({
      reValidateMode: 'onChange',
      resolver: zodResolver(SendPrequalLinkFormSchema),
    })

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()
    const initiatePrequalAppMutation =
      trpc.financing['unauth:initiate-prequal-application'].useMutation()

    const onSubmit = useCallback(
      async (data: SendPrequalLinkFormData) => {
        await initiatePrequalAppMutation.mutateAsync({
          accountGuid,
          contactGuid,
          jobGuid,
          phoneNumber: data.phoneNumber,
        })

        message.success('Pre-qualification link sent!')
        onClose()
      },
      [
        accountGuid,
        contactGuid,
        initiatePrequalAppMutation,
        jobGuid,
        message,
        onClose,
      ],
    )

    const isLoading = initiatePrequalAppMutation.isLoading

    return (
      <OnsiteBasicModal
        header="Get pre-qualified"
        onClose={onClose}
        open
        footer={
          <div className="flex gap-3 bg-white">
            {onClose && (
              <Button
                block
                size="large"
                onClick={onClose}
                disabled={isLoading}
                data-dd-action-name="bz-financing-prequal-send-link-cancel-button-click"
              >
                Cancel
              </Button>
            )}
            <Button
              block
              type="primary"
              size="large"
              data-testid="prequal-send-link-button"
              data-dd-action-name="bz-financing-prequal-send-link-button-click"
              htmlType="submit"
              onClick={triggerSubmit}
              loading={isLoading}
              disabled={isLoading}
            >
              Send Link
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          className="flex flex-col"
          onSubmitCapture={handleSubmit(onSubmit)}
        >
          <ReactHookFormItem
            control={control}
            errors={errors}
            name="phoneNumber"
            required
            label="Enter Your Phone Number"
            render={({ field }) => <PhoneNumberField {...field} />}
          />
          <p>
            Prequalify instantly!
            <ul>
              <li>Checking won't impact your credit score</li>
              <li>Rates from 0% APR*</li>
              <li>No hidden fees</li>
            </ul>
          </p>
          <p>
            Consumer-friendly financing makes it easy to afford the services you
            care about. Now you can invest in what matters most without
            surprises or unexpected late fees. Don't worry - checking your
            options will not affect your credit score.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.wisetack.com/consumers"
            >
              Learn more here
            </a>
            !
          </p>
          <LoanPromoDisclaimer amountUsc={amountUsc} />
          {submitElement}
        </Form>
      </OnsiteBasicModal>
    )
  },
)

export default SendPrequalLinkModal
