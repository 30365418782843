import { bzExpect } from '@breezy/shared'
import { useParams, useSearchParams } from 'react-router-dom'
import PaymentRefundForm from '../../components/Payments/PaymentRefundForm'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { trpc } from '../../hooks/trpc'

const RefundPageLoader = () => {
  const paymentRecordGuid = bzExpect(
    useParams().paymentRecordGuid,
    'paymentRecordGuid',
    'Payment Record Guid is required',
  )
  const query = trpc.payments['payments:get'].useQuery({ paymentRecordGuid })
  const [searchParams] = useSearchParams()
  const defaultAmountUsd = Number(searchParams.get('amountUsd'))
  const defaultReason = searchParams.get('reason')

  return (
    <TrpcQueryLoader
      query={query}
      render={payment => (
        <>
          <PaymentRefundForm
            {...payment}
            defaultAmountUsd={defaultAmountUsd}
            defaultReason={defaultReason}
          />
        </>
      )}
    />
  )
}

export default RefundPageLoader
