import { EndOfAppointmentNextStepsV1 } from '@breezy/shared/src'
import { Alert } from 'antd'
import React from 'react'

type EndOfAppointmentAlertBannerProps = {
  endOfAppointmentNextSteps: EndOfAppointmentNextStepsV1
}

export const EndOfAppointmentAlertBanner =
  React.memo<EndOfAppointmentAlertBannerProps>(
    ({ endOfAppointmentNextSteps }) => {
      return (
        <Alert
          type={
            endOfAppointmentNextSteps.data.allOnsiteWorkCompleted
              ? 'success'
              : 'warning'
          }
          message={
            <>
              {endOfAppointmentNextSteps.data.allOnsiteWorkCompleted ===
                true && (
                <div>
                  <div>
                    <strong>Onsite Work Complete</strong>
                  </div>
                  <div>
                    The technician indicated that all on-site work was completed
                    as part of the appointment.
                  </div>
                </div>
              )}
              {endOfAppointmentNextSteps.data.allOnsiteWorkCompleted ===
                false && (
                <div>
                  <div>
                    <strong>Onsite Work Incomplete</strong>
                  </div>
                  <div>
                    {endOfAppointmentNextSteps.data
                      .allOnsiteWorkNotCompletedDetails ? (
                      <>
                        {
                          endOfAppointmentNextSteps.data
                            .allOnsiteWorkNotCompletedDetails
                        }
                      </>
                    ) : (
                      <>
                        The technician indicated that all on-site work was
                        completed as part of the appointment.
                      </>
                    )}
                  </div>
                </div>
              )}
              {endOfAppointmentNextSteps.data.dispatcherNote && (
                <>
                  <br />
                  <strong>Note for Dispatch</strong>
                  <br />
                  {endOfAppointmentNextSteps.data.dispatcherNote}
                </>
              )}
            </>
          }
        />
      )
    },
  )
