import { Input } from 'antd'
import React from 'react'

import { phoneUtils } from '@breezy/shared'
import { useStringFieldInfo, useTsController } from '@ts-react/form'
import BaseTextField from '../BaseTextField/BaseTextField'

const PhoneNumberField = React.memo(() => {
  const {
    field: { onChange, value },
  } = useTsController<string>()

  const { placeholder } = useStringFieldInfo()

  return (
    <BaseTextField>
      <Input
        type="tel"
        size="large"
        placeholder={placeholder}
        onChange={e => onChange(phoneUtils.tryFormat(e.target.value))}
        value={value}
      />
    </BaseTextField>
  )
})

export default PhoneNumberField
