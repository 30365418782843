import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type FormBodyProps = {
  paddingClassName?: string
}
const FormBody = React.memo<React.PropsWithChildren<FormBodyProps>>(
  ({ children, paddingClassName = 'pb-4' }) => {
    const isMobile = useIsMobile()
    return (
      <div
        className={classNames(
          'min-h-0 flex-1 overflow-auto',
          isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
          paddingClassName,
        )}
      >
        {children}
      </div>
    )
  },
)

export default FormBody
