import { convertPaymentMethodRecordToCardOnFile } from '@breezy/backend/src/application-types'
import { useQuery } from 'urql'
import { CARD_ON_FILE_QUERY } from './useFetchCardsOnFile.anon-gql'

export const useFetchCardsOnFile = (accountGuid: string) => {
  const cardOnFileQuery = useQuery({
    query: CARD_ON_FILE_QUERY,
    variables: {
      accountGuid,
    },
  })
  const { data, fetching } = cardOnFileQuery[0]
  return {
    data:
      data?.paymentMethodRecords.map(convertPaymentMethodRecordToCardOnFile) ??
      [],
    fetching,
    refetch: cardOnFileQuery[1],
    cardOnFileQuery,
  }
}
