import {
  DEFAULT_DYNAMIC_PRICING_OPTION,
  DYNAMIC_PRICING_INFO_MAP,
  DYNAMIC_PRICING_TYPES,
} from '@breezy/shared'
import { DynamicPricingType } from '@breezy/shared/src'
import { faChartMixedUpCircleDollar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from 'antd'
import React, { useCallback } from 'react'
import { OnsitePageSimpleSectionItem } from '../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import { SwitchField } from '../../elements/Forms/SwitchField'

const SELECT_OPTIONS = DYNAMIC_PRICING_TYPES.map(value => {
  const info = DYNAMIC_PRICING_INFO_MAP[value]
  return {
    value,
    label: `${info.name} - ${info.multiplier}x`,
  }
})

export type ApplyDynamicPricingSectionProps = {
  dynamicPricingType?: DynamicPricingType
  setDynamicPricingType?: (dynamicPricingType?: DynamicPricingType) => void
}

export const ApplyDynamicPricingSection =
  React.memo<ApplyDynamicPricingSectionProps>(
    ({ dynamicPricingType, setDynamicPricingType }) => {
      const onSwitchOn = useCallback(
        (checked: boolean) => {
          setDynamicPricingType?.(
            checked ? DEFAULT_DYNAMIC_PRICING_OPTION : undefined,
          )
        },
        [setDynamicPricingType],
      )

      return (
        <OnsitePageSimpleSectionItem
          icon={<FontAwesomeIcon icon={faChartMixedUpCircleDollar} />}
          subContent={
            <>
              <div>
                Automatically apply a pricing multiplier for jobs scheduled
                outside of regular business hours or during holidays.
              </div>
              {dynamicPricingType ? (
                <Select
                  className="mt-4"
                  disabled={!setDynamicPricingType}
                  value={dynamicPricingType}
                  options={SELECT_OPTIONS}
                  onChange={setDynamicPricingType}
                />
              ) : undefined}
            </>
          }
          rightContent={
            <SwitchField
              name="applyDynamicPricingEnabled"
              withIcons
              checked={!!dynamicPricingType}
              onChange={onSwitchOn}
              disabled={!setDynamicPricingType}
            />
          }
        >
          Apply Dynamic Pricing
        </OnsitePageSimpleSectionItem>
      )
    },
  )
