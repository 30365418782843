import { AccountGuid } from '@breezy/shared'
import React, { useCallback } from 'react'
import { useMutation } from 'urql'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { SET_ACCOUNT_ARCHIVED_MUTATION } from './AccountDetailsPage.gql'

export const useArchiveAccountModal = (
  accountGuid: AccountGuid,
  archived: boolean,
) => {
  const companyGuid = useExpectedCompanyGuid()
  const [, mutateAccountArchived] = useMutation(SET_ACCOUNT_ARCHIVED_MUTATION)
  const syncAccountMutation =
    trpc.searcher['searcher:resync-accounts'].useMutation()

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal(true, {
    noNavBlocker: true,
  })

  const archiveAccount = useCallback(
    () =>
      withConfirmation(async () => {
        await mutateAccountArchived({ accountGuid, archived: !archived })
        syncAccountMutation.mutate({ companyGuid, accountGuid })
      }),
    [
      accountGuid,
      archived,
      companyGuid,
      mutateAccountArchived,
      syncAccountMutation,
      withConfirmation,
    ],
  )

  return {
    archiveAccount,
    closeConfirmProps,
  }
}

type ArchiveAccountModalProps = ReturnType<typeof useCloseConfirmModal>[1] & {
  archived: boolean
}

export const ArchiveAccountModal = React.memo<ArchiveAccountModalProps>(
  ({ archived, ...rest }) => {
    return (
      <CloseConfirmModal
        {...rest}
        danger={!archived}
        header={`${archived ? 'Una' : 'A'}rchive account?`}
        content={`Are you sure you’d like to ${
          archived ? 'un' : ''
        }archive this account? This action can be undone.`}
        confirmText={`${archived ? 'Una' : 'A'}rchive account`}
      />
    )
  },
)
