import { anonGql, DocumentType } from '../../generated'

export const ANON_LATEST_LOAN_RECORD_SUBSCRIPTION = anonGql(/* GraphQL */ `
  subscription WisetackLoanRecord($invoiceReferenceNumber: String!) {
    wisetackLoanRecords(
      where: { invoice: { referenceNumber: { _eq: $invoiceReferenceNumber } } }
      orderBy: { createdAt: DESC }
    ) {
      wisetackLoanRecordGuid
      companyGuid
      wisetackLoanStatuses(
        limit: 1
        orderBy: { occurredAt: DESC, createdAt: DESC }
      ) {
        loanStatus
        createdAt
      }
      createdAt
      updatedAt
      loanApplicationLink
      approvedLoanAmountUsc
      requestedLoanAmountUsc
    }
  }
`)

export type SimpleLoanRecord = DocumentType<
  typeof ANON_LATEST_LOAN_RECORD_SUBSCRIPTION
>['wisetackLoanRecords'][number]

export type SimpleLoanStatus =
  SimpleLoanRecord['wisetackLoanStatuses'][number]['loanStatus']
