import { JobLead } from './types'

export const jobLeadsHeader = (jobLead: JobLead) => {
  const nameToken = [jobLead.contactFirstName, jobLead.contactLastName]
    .map(str => str?.trim())
    .filter(Boolean)
    .join(' ')

  const jobTypeToken = [jobLead.jobType]
    .map(str => str?.trim())
    .filter(Boolean)
    .join('')

  return [nameToken, jobTypeToken].filter(Boolean).join(' • ') ?? 'Unknown Lead'
}
