import { z } from 'zod'
import { AsyncFn, Event, IsoDateString } from '../../../common'
import { guidSchema } from '../../../contracts/_common'
import { AccountGuid } from '../../Accounts/Account'
import { CompanyGuid, CompanyGuidContainer, CompanySimpleTimeWindowDtoRequest, ForCompany } from '../../Company/Company'
import { BzDateTime } from '../../DateTime/BzDateTime'
import { MaintenancePlanMinimalInfo } from '../../MaintenancePlans/MaintenancePlanTypes'
import { ForUser, UserGuid, UserGuidContainer } from '../../Users/User'
import { Guid, ReferenceNumberContainer, bzOptional } from '../../common-schemas'
import { InvoiceGuid } from '../Invoicing/InvoiceTypes'
import {
  Cart,
  ComprehensiveTransactionViewModel,
  TransactionLinks,
  TransactionMetadata,
  transactionGuidRequestSchema,
} from '../Transactions/TransactionTypes'

export const EstimateEntityTypeName = 'Estimate'

export type EstimateGuid = Guid
export type EstimateGuidContainer = {
  readonly estimateGuid: EstimateGuid
}

export const estimateGuidContainerSchema = z.object({
  estimateGuid: guidSchema,
})

export const estimateDuplicateForAccountSchema = estimateGuidContainerSchema.extend({
  accountGuid: guidSchema,
  jobGuid: guidSchema,
})

export type EstimateDuplicateForAccount = EstimateGuidContainer & {
  accountGuid: string
  jobGuid: string
}

export const estimateAndCompanyGuidContainerSchema = z.object({
  companyGuid: guidSchema,
  estimateGuid: guidSchema,
})

export type EstimateStatusContainer = {
  readonly status: EstimateStatus
}

/** @deprecated */
export type EstimateMetadata = EstimateStatusContainer & {
  readonly estimateVersion: number
  readonly estimateGuid: EstimateGuid
  readonly referenceNumber: string
  readonly summary?: string
  readonly displayName?: string
}

/** @deprecated */
export type EstimateViewModel = ComprehensiveTransactionViewModel &
  EstimateMetadata & {
    readonly issuedAt: IsoDateString
    readonly soldAt?: IsoDateString
    readonly signatureUrl?: string
    readonly generatedInvoiceGuids: InvoiceGuid[]
  }

/** @deprecated */
export type EstimateCreationData = Cart &
  TransactionMetadata &
  TransactionLinks & {
    readonly companyGuid: CompanyGuid
    readonly userGuid: UserGuid
    readonly accountGuid: AccountGuid
    readonly referenceNumber: string
    readonly summary?: string
    readonly displayName?: string
  }

/** @deprecated */
export enum EstimateStatus {
  CREATED = 'CREATED',
  PRESENTED = 'PRESENTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export const EstimateStatusDisplayNames = {
  [EstimateStatus.CREATED]: 'Created',
  [EstimateStatus.PRESENTED]: 'Presented',
  [EstimateStatus.ACCEPTED]: 'Accepted',
  [EstimateStatus.REJECTED]: 'Rejected',
  [EstimateStatus.DELETED]: 'Deleted',
}

export const markEstimateAcceptedRequestSchema = z.object({
  companyGuid: guidSchema,
  estimateGuid: guidSchema,
  accountGuid: guidSchema,
  base64EstimateSignaturePng: z.string(),
})

export type AcceptEstimateInput = ForUser<{
  base64EstimateSignaturePng: string
}>

export type DeleteEstimateInput = ForCompany<UserGuidContainer>

export type MarkEstimateAcceptedRequest = z.infer<typeof markEstimateAcceptedRequestSchema>

export type IEstimateHtmlGenerator = AsyncFn<EstimateViewModel, string>

export type IEstimatePDFGenerator = AsyncFn<EstimateViewModel, Buffer>

export const sendEstimateReviewLinkRequestSchema = z.object({
  estimateGuid: guidSchema,
  deliveryMethod: z.enum(['EMAIL', 'SMS']),
  customMessage: bzOptional(z.string()),
  overrideTo: bzOptional(z.string()),
})
export type SendEstimateReviewLinkRequest = z.infer<typeof sendEstimateReviewLinkRequestSchema>

export type EstimateQueryableRecord = {
  estimateGuid: Guid
  referenceNumber: string
  companyGuid: Guid
  accountGuid: Guid
  estimateStatus: EstimateStatus
  issuedAt: IsoDateString
  totalAmountUsd: number
  soldAt?: IsoDateString
  jobGuid?: Guid
  jobAppointmentGuid?: Guid
  locationGuid?: Guid
  createdByUserGuid: Guid
  updatedAt: IsoDateString
  latestInvoiceGuid?: Guid
  summary?: string
  latestInvoiceDisplayId?: number
  displayName?: string
}

export type EstimateQueryableRecordWithAccountDisplayName = EstimateQueryableRecord & {
  accountDisplayName: string
  accountMaintenancePlans: MaintenancePlanMinimalInfo[]
}

/**
 * Fires when the Estimate Summary has been set.
 */
export type EstimateSummarySetEvent = Event<EstimateSummarySetEventData>
export type EstimateSummarySetEventData = { readonly summary?: string }
export const EstimateSummarySetEventTypeName = 'EstimateSummarySet' as const
export const isEstimateSummarySetEvent = (event: Event<unknown>): event is EstimateSummarySetEvent =>
  event.eventType === EstimateSummarySetEventTypeName

export type EstimateFinalizeInput = ReferenceNumberContainer & {
  summary?: string
  displayName?: string
}

export const EstimateFinalizeSchema = transactionGuidRequestSchema.extend({
  summary: bzOptional(z.string()),
  displayName: bzOptional(z.string()),
})

/**
 * Fires when the Estimate DisplayName has been set.
 */
export type EstimateDisplayNameSetEvent = Event<EstimateDisplayNameSetEventData>
export type EstimateDisplayNameSetEventData = { readonly displayName?: string }
export const EstimateDisplayNameSetEventTypeName = 'EstimateDisplayNameSet' as const
export const isEstimateDisplayNameSetEvent = (event: Event<unknown>): event is EstimateDisplayNameSetEvent =>
  event.eventType === EstimateDisplayNameSetEventTypeName

export type EstimateFinalizeSchemaType = z.infer<typeof EstimateFinalizeSchema>

export type EstimatesNotDeletedRequest = {
  query: 'for-estimates-not-deleted'
} & CompanySimpleTimeWindowDtoRequest

export type EstimatesRequest = EstimatesNotDeletedRequest

export type EstimateQueryableRecordsReader = AsyncFn<EstimatesRequest, EstimateQueryableRecordWithAccountDisplayName[]>

export type EstimateSearchableRecordResyncRecordsWriter = AsyncFn<CompanyGuidContainer>

export type EstimateDuplicateForAccountInput = ForUser<
  ReferenceNumberContainer & {
    accountGuid: string
    jobGuid: string
  }
>

export type EstimatesQueryableSync = AsyncFn<CompanyGuidContainer>

export const mapEstimateVmToQueryable = (e: EstimateViewModel): EstimateQueryableRecord => ({
  companyGuid: e.companyGuid,
  estimateGuid: e.estimateGuid,
  referenceNumber: e.referenceNumber,
  estimateStatus: e.status,
  accountGuid: e.accountGuid,
  issuedAt: e.issuedAt,
  soldAt: e.soldAt,
  totalAmountUsd: e.totalPriceUsd,
  updatedAt: BzDateTime.nowUtcIsoString(),
  createdByUserGuid: e.originatingUserGuid,
  latestInvoiceGuid: e.generatedInvoiceGuids.length > 0 ? e.generatedInvoiceGuids.slice(-1)[0] : undefined,
  summary: e.summary,
  displayName: e.displayName,
  ...e.links,
})
