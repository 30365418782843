import { Guid } from '@breezy/shared'
import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  OnsiteConfirmModal,
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import useIsMobile from '../../../hooks/useIsMobile'
import { OnResize, useResizeObserver } from '../../../hooks/useResizeObserver'
import {
  StateSetter,
  stopPropagation,
  useModalState,
} from '../../../utils/react-utils'
import { EstimatePhoto, isUploadedEstimatePhoto } from '../estimatesFlowUtils'

import { Progress } from 'antd'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import './Gallery.less'

const MIN_THUMBNAIL_WIDTH = 116
const THUMBNAIL_SPACE = 2

const MOBILE_NUM_ACROSS = 3

type DeleteButtonProps = {
  large?: boolean
  onClick: () => void
}

const DeleteButton = React.memo<DeleteButtonProps>(({ onClick, large }) => {
  const [warningOpen, openWarning, closeWarning] = useModalState()

  const onConfirm = useCallback(() => {
    onClick()
    closeWarning()
  }, [closeWarning, onClick])

  return (
    <span onClick={stopPropagation}>
      <div
        className={classNames(
          'absolute flex items-center justify-center rounded-full border border-solid border-bz-gray-100 bg-bz-gray-800 text-bz-gray-100',
          large
            ? 'right-2 top-2 h-9 w-9 text-base'
            : 'right-1 top-1 h-5 w-5 text-xs',
        )}
        onClick={openWarning}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      {warningOpen && (
        <OnsiteConfirmModal
          danger
          header="Are you sure you want to remove this photo?"
          onCancel={closeWarning}
          onConfirm={onConfirm}
          confirmText="Yes, Remove Photo"
        >
          If the estimate has been saved, then the photo will still be viewable
          on the job and can be re-added to the estimate again later.
        </OnsiteConfirmModal>
      )}
    </span>
  )
})

type GalleryModalImageProps = {
  photoGuid: Guid
  cdnUrl: string
  onDeleteClick?: () => void
  scrolledToGuid?: Guid
}

const GalleryModalImage = React.memo<GalleryModalImageProps>(
  ({ photoGuid, cdnUrl, onDeleteClick, scrolledToGuid }) => {
    const divRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
      if (scrolledToGuid === photoGuid) {
        setTimeout(() => {
          divRef.current?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
      }
    }, [photoGuid, scrolledToGuid])
    return (
      <div key={photoGuid} className="relative mx-[-16px] mb-4" ref={divRef}>
        <img src={cdnUrl} alt={cdnUrl} />
        {onDeleteClick && <DeleteButton large onClick={onDeleteClick} />}
      </div>
    )
  },
)

type GalleryProps = {
  photoRecords: EstimatePhoto[]
  // Presence of this prop indicates that the gallery is editable
  setPhotoRecords?: StateSetter<EstimatePhoto[]>
  className?: string
}

export const Gallery = React.memo<GalleryProps>(
  ({ photoRecords, className, setPhotoRecords }) => {
    // On mobile, our gallery always has "MOBILE_NUM_ACROSS" thumbnails across, and up to two rows. On other devices, we
    // only have one row, and the thumbnails are a minimum of MIN_THUMBNAIL_WIDTH wide. When we figure out how many we
    // can fit at that width, and we have that many, we "stretch" them to fill the space (if we don't have that many, we
    // just make them 116);
    const isMobile = useIsMobile()

    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWidth, setContainerWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setContainerWidth(width)
    }, [])

    useResizeObserver(containerRef, onResize)

    const onDelete = useCallback(
      (photoGuid: Guid) => {
        setPhotoRecords?.(photos =>
          photos.filter(p => p.photoGuid !== photoGuid),
        )
      },
      [setPhotoRecords],
    )

    // Find the maximum number of thumbnails we could have
    const numThumbnails = useMemo(() => {
      // On mobile we can have two rows of "MOBILE_NUM_ACROSS" thumbnails
      if (isMobile) {
        return MOBILE_NUM_ACROSS * 2
      }
      // Find the number of thumbnails we can fit in this container if the thumbnails are at least "MIN_THUMBNAIL_WIDTH"
      // big and have "THUMBNAIL_SPACE" between. The formula, where W is the width of the container, I is the width of
      // the images, S is the space between, and n is the number of images, is "In + S(n - 1) <= W". Solve for "n" and
      // you get this.
      return Math.floor(
        (containerWidth + THUMBNAIL_SPACE) /
          (MIN_THUMBNAIL_WIDTH + THUMBNAIL_SPACE),
      )
    }, [containerWidth, isMobile])

    const thumbnailSize = useMemo(() => {
      // If it's mobile, it's always "MOBILE_NUM_ACROSS" across. Otherwise, it's the number of thumbnails we can fit.
      const n = isMobile ? MOBILE_NUM_ACROSS : numThumbnails
      // If we don't have enough thumbnails to fill in the whole row, just make them the minimum size
      if (photoRecords.length < n) {
        return MIN_THUMBNAIL_WIDTH
      }

      // If "F" is the width of the thumbnails, W is the width of the container, S is the space between, and n is the
      // number of thumbnails, the formula is: "Fn + S(n - 1) = W". Solve for "F" and you get this.
      return Math.floor((containerWidth - (n - 1) * THUMBNAIL_SPACE) / n)
    }, [containerWidth, isMobile, numThumbnails, photoRecords.length])

    const [clickedImageGuid, setClickedImageGuid] = useState<Guid | undefined>()

    const closeModal = useCallback(() => setClickedImageGuid(undefined), [])

    return (
      <div
        ref={containerRef}
        className={classNames(
          'flex max-w-full flex-row flex-wrap overflow-hidden',
          className,
        )}
      >
        {photoRecords.slice(0, numThumbnails).map((photo, i) => {
          const { photoGuid, cdnUrl } = photo
          const isLast = i === numThumbnails - 1
          const skippedImages = photoRecords.length - numThumbnails
          const needsPlusMoreOverlay = skippedImages > 0 && isLast

          let marginRight = THUMBNAIL_SPACE

          if (isMobile) {
            // If the index is divisible by the number of thumbnails across, then we're on the end, meaning we don't
            // have a margin.
            if ((i + 1) % MOBILE_NUM_ACROSS === 0) {
              marginRight = 0
            }
          } else if (isLast) {
            marginRight = 0
          }

          return (
            <div
              key={photoGuid}
              className={`relative min-w-0 overflow-hidden mb-[${THUMBNAIL_SPACE}px] w-[${thumbnailSize}px]`}
              style={{
                marginRight: `${marginRight}px`,
              }}
              onClick={() => setClickedImageGuid(photoGuid)}
            >
              {isUploadedEstimatePhoto(photo) ? (
                <>
                  <img
                    src={photo.base64File}
                    alt={cdnUrl}
                    className="rounded object-cover"
                    style={{
                      width: `${thumbnailSize}px`,
                      height: `${thumbnailSize}px`,
                    }}
                  />

                  {/* We show a loading spinner if the photo isn't loaded. There are three stages: 1. getting the S3
                      url, 2, uploading the image, and 3. updating our DB. In normal circumstances steps 1 and 3 are
                      instantaneous. But on really slow internets they take time. During step 2 we get progress
                      percentages so we can render the circle that fills in. During the other two we show a normal
                      loading spinner. We know step 1 is over when photo.progress > 0 (meaning step 2 has started). We
                      know step 2 is over when `photo.progress === 1`, and we know step 3 is done when
                      `photo.recordWritten` is true. Since `photo.recordWritten` indicates the end of everything, that's
                      what tells us if we're at any stage in the process, so show this loader if that is false. */}
                  {!photo.recordWritten && (
                    <div className="absolute inset-0 flex items-center justify-center backdrop-blur-sm">
                      {/* We show the normal spinner in stages 1 and 3. We know it's stage 1 if `photo.progress === 0`.
                          We know it's stage 3 when `photo.progress === 1`. So in either of those cases we show the
                          loading spinner. Otherwise we know we're in stage 2 and we show the progress circle. */}
                      {photo.progress === 0 || photo.progress === 1 ? (
                        <LoadingSpinner />
                      ) : (
                        <Progress
                          type="circle"
                          showInfo={false}
                          percent={photo.progress * 100}
                          // The loading spinner uses font awesome icons. This makes the circle with an SVG. I had to do
                          // "-3" to get it to look like the same size.
                          size={thumbnailSize / 2 - 3}
                          trailColor="#D9D9D9"
                          strokeColor="#69c0ff"
                          // This also came from eye-balling the regular spinner
                          strokeWidth={10}
                        />
                      )}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={cdnUrl}
                  alt={cdnUrl}
                  className="rounded object-cover"
                  style={{
                    width: `${thumbnailSize}px`,
                    height: `${thumbnailSize}px`,
                  }}
                />
              )}
              {setPhotoRecords && !needsPlusMoreOverlay && (
                <DeleteButton onClick={() => onDelete(photoGuid)} />
              )}
              {needsPlusMoreOverlay && (
                <div className="estimates-flow-gallery-more-images-overlay-text-stroke absolute inset-0 z-10 flex items-center justify-center rounded text-lg font-semibold text-bz-gray-100 backdrop-blur-sm">
                  {/* <div className="absolute inset-0 z-[-1] bg-black blur-2xl" /> */}
                  {/* + 1 because the current image isn't "skipped" but it now has an overlay over it */}
                  +{skippedImages + 1}
                </div>
              )}
            </div>
          )
        })}
        {clickedImageGuid && (
          <OnsiteModal size="large" open onClose={closeModal}>
            <OnsiteModalContent header="Gallery" onClose={closeModal}>
              <div>
                {photoRecords.map(({ photoGuid, cdnUrl }) => (
                  <GalleryModalImage
                    key={photoGuid}
                    photoGuid={photoGuid}
                    cdnUrl={cdnUrl}
                    onDeleteClick={
                      setPhotoRecords && (() => onDelete(photoGuid))
                    }
                    scrolledToGuid={clickedImageGuid}
                  />
                ))}
              </div>
            </OnsiteModalContent>
          </OnsiteModal>
        )}
      </div>
    )
  },
)
