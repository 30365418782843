import { useCallback } from 'react'
import { trpc } from './trpc'

// Instead of prop-drilling, we're going to manually invalidate known queries that depend on this hook
export const useInvalidateInvoiceQuery = () => {
  const utils = trpc.useUtils()
  return useCallback(async () => {
    await utils.invoice['invoicing:fetch'].invalidate()
  }, [utils])
}
