import { Row } from 'antd'

const RowLabeledText = ({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) => (
  <Row className="two-sides w-full">
    <div className="regular_14_22 grey9 font-bold">{label}</div>
    <div className="regular_14_22 font-semi-bold grey9 ml-2">{children}</div>
  </Row>
)

export default RowLabeledText
