import {
  TechnicianCapacityBlock,
  formatTechnicianCapacityBlockReasonType,
} from '@breezy/shared'
import { Row } from 'antd'
import { m } from '../../../../utils/react-utils'
import { DateTimeWindow } from '../../../Dates'

export const BlockCard = m<TechnicianCapacityBlock>(
  ({ start, end, reasonType, reasonDescription }) => {
    return (
      <div>
        <Row justify="space-between" className="mb-2 items-center">
          <div className="semibold_14_22 gray9">
            {`${formatTechnicianCapacityBlockReasonType(reasonType)}`}
          </div>
        </Row>

        <div className="flex flex-row flex-wrap justify-start gap-x-4 gap-y-3 sm:flex-nowrap">
          <div className="w-[172px] min-w-[172px]">
            <div className="semibold_14_22 gray9">Assigned Time</div>
            <DateTimeWindow
              startWindowIsoWithOffsetTimestamp={start}
              endWindowIsoWithOffsetTimestamp={end}
              includeDate={true}
              overrideClassName="regular_14_22 gray9"
              zone={{
                type: 'company-timezone-of-principal-user',
              }}
            />
          </div>

          <div className="w-full">
            <div className="semibold_14_22 gray9">Details</div>
            <div className="regular_14_22 gray9">
              {reasonDescription || 'No Details'}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
