import React, { useContext } from 'react'
import {
  OnsitePageContainer,
  OnsitePageContainerProps,
} from '../../adam-components/OnsitePage/OnsitePageContainer'
import {
  EstimateInfoContext,
  EstimateInfoModalContent,
} from './components/EstimateInfoModalContent'

type EstimatesPageContainerProps = Omit<
  OnsitePageContainerProps,
  'info' | 'infoHeader'
>

export const EstimatesPageContainer = React.memo<EstimatesPageContainerProps>(
  props => {
    const infoContext = useContext(EstimateInfoContext)

    if (infoContext) {
      return (
        <OnsitePageContainer
          {...props}
          info={<EstimateInfoModalContent />}
          infoHeader="Estimate info"
        />
      )
    }
    return <OnsitePageContainer {...props} />
  },
)
