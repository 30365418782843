import { useQuery } from 'urql'
import {
  ACCOUNT_BILLING_INFO_QUERY,
  AccountBillingInfoQuery,
} from '../gql/AccountBillingInfo.gql'

export const useDefaultAccountBillingInfo = (accountGuid: string) => {
  const accountBillingInfoQuery = useQuery({
    query: ACCOUNT_BILLING_INFO_QUERY,
    variables: { accountGuid },
  })

  return accountBillingInfoQuery
}

export const getDefaultBillingAddress = (data: AccountBillingInfoQuery) => {
  const mailingAddress = data?.accounts[0]?.mailingAddress
  const defaultLocationAddress = data?.accountLocations[0]?.location.address

  if (!mailingAddress && !defaultLocationAddress) {
    return null
  }

  const defaultBillingAddress = mailingAddress ?? defaultLocationAddress
  return {
    line1: defaultBillingAddress.line1,
    line2: defaultBillingAddress.line2,
    city: defaultBillingAddress.city,
    state: defaultBillingAddress.stateAbbreviation,
    zipCode: defaultBillingAddress.zipCode,
  }
}

export const getDefaultContactName = (data: AccountBillingInfoQuery) => {
  const contact = data?.accounts[0]?.accountContacts[0]?.contact
  return contact?.fullName
}
