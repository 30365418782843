import { gql } from '../../../generated'

export const PREQUAL_CONTACT_NAME_QUERY = gql(/* GraphQL */ `
  query FetchPrequalRecord($where: WisetackPrequalRecordsBoolExp!) {
    wisetackPrequalRecords(where: $where) {
      contact {
        fullName
      }
    }
  }
`)
