import React, { useMemo, useState } from 'react'

import {
  TILLED_SANDBOX_ACH_DEBIT_INFO,
  TilledAchDebitSandboxInfo,
  getTilledAchDebitInfo,
} from '@breezy/shared'
import { Button, Table, Tabs } from 'antd'
import { OnsiteBasicModal } from '../../../../adam-components/OnsiteModal/OnsiteModal'
import { getConfig } from '../../../../config'
import BzSelect from '../../../../elements/BzSelect/BzSelect'
import CopyToClipboard, {
  CopyLabel,
} from '../../../../elements/CopyToClipboard/CopyToClipboard'

const TilledSandboxAchDevTools = React.memo(() => {
  const isTilledSandbox = getConfig().tilled.useSandbox
  const [selectedCard, setSelectedCard] =
    useState<TilledAchDebitSandboxInfo>('bank-of-america')
  const [showDevTools, setShowDevTools] = useState(false)

  const selectedOption = useMemo(() => {
    return getTilledAchDebitInfo(selectedCard)
  }, [selectedCard])

  const options = useMemo(
    () =>
      TILLED_SANDBOX_ACH_DEBIT_INFO.map(({ label, value }) => ({
        label,
        value,
      })),
    [],
  )

  const tabs = useMemo(
    () => [
      {
        label: 'ACH Debit Info',
        key: 'achDebitInfo',
        children: (
          <>
            <div className="text-sm font-semibold">Prefill Card Info</div>
            <BzSelect
              title="Tilled Sandbox ACH Debit Info"
              className="mb-4"
              options={options}
              value={selectedCard}
              onChange={value => {
                setSelectedCard(value)
              }}
            />
            {selectedOption && (
              <div className="flex flex-col space-y-2 rounded-md bg-daybreak-blue-200 p-2">
                <div className="flex flex-row space-x-1">
                  <div className="font-semibold">Account #:</div>
                  <CopyToClipboard
                    payload={selectedOption.accountNumber}
                    label={<CopyLabel label={selectedOption.accountNumber} />}
                  />
                </div>
                <div className="flex flex-row space-x-1">
                  <div className="font-semibold">Routing #:</div>
                  <CopyToClipboard
                    payload={selectedOption.routingNumber}
                    label={<CopyLabel label={selectedOption.routingNumber} />}
                  />
                </div>
              </div>
            )}
          </>
        ),
      },
      {
        label: 'ACH Debit Returns',
        key: 'achDebitReturns',
        children: (
          <div className="flex flex-col space-y-2">
            <p>
              The following exact amounts will result in the associated error
              code on a payment intent. This is only accessible for ach_debit.
              Example: 888801 = $8888.01.{' '}
              <a
                href="https://docs.tilled.com/docs/testing/testing/#simulating-charge-declines"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </p>

            <Table
              dataSource={[
                { key: '1', amount: 888801, errorCode: 'INSUFFICIENT_FUNDS' },
                { key: '2', amount: 888802, errorCode: 'NOT_PERMITTED' },
                { key: '3', amount: 888803, errorCode: 'BANK_ACCOUNT_BLOCKED' },
                { key: '4', amount: 888804, errorCode: 'ACCOUNT_CLOSED' },
                { key: '5', amount: 888805, errorCode: 'ACCOUNT_FROZEN' },
                {
                  key: '6',
                  amount: 888806,
                  errorCode: 'ACCOUNT_NUMBER_INVALID',
                },
                {
                  key: '7',
                  amount: 888807,
                  errorCode: 'AUTHORIZATION_REVOKED',
                },
                { key: '8', amount: 888808, errorCode: 'INVALID_FIELD' },
                { key: '9', amount: 888809, errorCode: 'NO_ACCOUNT' },
                { key: '10', amount: 888810, errorCode: 'NOT_AUTHORIZED' },
                { key: '11', amount: 888811, errorCode: 'NOT_RECOGNIZED' },
                { key: '12', amount: 888812, errorCode: 'STOP_PAYMENT' },
              ]}
              columns={[
                {
                  title: 'Amounts (US Cents)',
                  dataIndex: 'amount',
                  key: 'amount',
                },
                {
                  title: 'Error Codes',
                  dataIndex: 'errorCode',
                  key: 'errorCode',
                },
              ]}
            />
          </div>
        ),
      },
      {
        label: 'Address Verification',
        key: 'addressVerificationErrors',
        children: (
          <div className="flex flex-col space-y-2">
            <p>
              By default, all card transactions with valid billing_details
              addresses will pass the address verification checks. To simulate a
              different response, add a single letter code from the table below
              as a prefix to the billing_details.street property. Example:
              billing_details.street='11 123 Main Street' will produce failed
              checks where the address matches, but postal code does not. These
              checks are available on the payment_method.card.checks property.{' '}
              <a
                href="https://docs.tilled.com/docs/testing/testing/#simulating-address-verification-responses"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </p>

            <Table
              dataSource={[
                {
                  key: '10',
                  prefix: 10,
                  description: 'Both street and postal code match.',
                },
                {
                  key: '11',
                  prefix: 11,
                  description: 'Street matches, but postal code does not.',
                },
                {
                  key: '12',
                  prefix: 12,
                  description: 'Postal code matches, but street does not.',
                },
                {
                  key: '18',
                  prefix: 18,
                  description: 'Address information is unavailable.',
                },
                {
                  key: '15',
                  prefix: 15,
                  description: 'Address information is unavailable.',
                },
              ]}
              columns={[
                {
                  title: 'Prefix',
                  dataIndex: 'prefix',
                  key: 'prefix',
                },
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
              ]}
            />
          </div>
        ),
      },
    ],
    [options, selectedCard, selectedOption],
  )

  if (!isTilledSandbox) return null
  return (
    <div className="absolute right-0 top-0 flex items-end space-y-1">
      <Button
        className="flex flex-1 pl-0"
        type="link"
        onClick={() => setShowDevTools(prev => !prev)}
      >
        Show Dev Tools
      </Button>
      {showDevTools && (
        <OnsiteBasicModal
          header="Tilled Dev Tools"
          onClose={() => setShowDevTools(false)}
          size="default"
        >
          <Tabs items={tabs} className="mb-4" />
        </OnsiteBasicModal>
      )}
    </div>
  )
})

export default TilledSandboxAchDevTools
