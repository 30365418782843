import { z } from 'zod'
import { bzOptional, Guid } from '../domain/common-schemas'
import { guidSchema, JsonString } from './_common'

export const SendDelayedSmsToTechnicianIfAssignmentUnchangedTaskName =
  'SendDelayedSmsToTechnicianIfAssignmentUnchangedTask' as const

export const SendDelayedSmsToTechnicianIfAssignmentUnchangedTaskDataSchema = z.object({
  assignmentGuid: guidSchema,
  companyGuid: guidSchema,
  comparisonJson: z.string(),
})

export type SendDelayedSmsToTechnicianIfAssignmentUnchangedTaskData = {
  assignmentGuid: Guid
  companyGuid: Guid
  comparisonJson: JsonString
}

export type SendDelayedSmsToTechnicianIfAssignmentUnchangedTask =
  SendDelayedSmsToTechnicianIfAssignmentUnchangedTaskData & {
    readonly taskType: typeof SendDelayedSmsToTechnicianIfAssignmentUnchangedTaskName
    readonly taskVersion: 1
    readonly taskGuid: Guid
    readonly delayDurationMs: number
  }

export const ReconcileExternalPayoutTaskName = 'ReconcileExternalPayoutTask' as const

export const ReconcileExternalPayoutTaskDataSchema = z.object({
  taskVersion: z.literal(1),
  externalPayoutId: z.string(),
  merchantId: z.string(),
})

export type ReconcileExternalPayoutTaskData = z.infer<typeof ReconcileExternalPayoutTaskDataSchema>

/**
 * This task is triggered by a Payout Webhook from an external payment processor (likely Tilled)
 */
export type ReconcileExternalPayoutTask = {
  readonly taskType: typeof ReconcileExternalPayoutTaskName
  readonly taskGuid: Guid
  readonly taskData: ReconcileExternalPayoutTaskData
}

export const SyncQboInvoiceTaskName = 'SyncQboInvoiceTask' as const

export const SyncQboInvoiceTaskDataSchema = z.object({
  companyGuid: guidSchema,
  invoiceGuid: z.string(),
  userGuid: bzOptional(guidSchema),
  force: bzOptional(z.boolean()),
})

export type SyncQboInvoiceTaskData = z.infer<typeof SyncQboInvoiceTaskDataSchema>

export type SyncQboInvoiceTask = {
  readonly taskType: typeof SyncQboInvoiceTaskName
  readonly taskVersion: 1
  readonly taskGuid: Guid
  readonly taskData: SyncQboInvoiceTaskData
}
