import {
  AssignableApptViewModelWithBusinessProjections,
  BzAddress,
  BzDateFns,
  BzDateTime,
  Dfns,
  Guid,
  Instant,
  JobAppointmentGuid,
  JobClass,
  JobGuid,
  JobType,
  LocalTime,
  R,
  TechnicianCapacityBlockReasonType,
  TimeZoneId,
  UpsertAppointmentAndAssignmentDTO,
  User,
  ZoneId,
  ZonedDateTime,
  effectiveLocationDisplayName,
  effectiveLocationLongDisplayName,
  formatTechnicianCapacityBlockReasonType,
  hasTechnicianRole,
  nextGuid,
} from '@breezy/shared'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Divider, Form, Input, Modal, Select, Spin } from 'antd'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { ArrivalWindowForm } from '../../components/NewAppointmentModal/UpsertAppointmentForm/ArrivalWindowForm'
import {
  PendingUpsertAppointmentAndAssignmentForm,
  UpsertAppointmentForm,
} from '../../components/NewAppointmentModal/UpsertAppointmentForm/UpsertAppointmentForm'
import ProgressiveJobCreationModal from '../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import { RecurrenceForm } from '../../components/RecurrenceForm/RecurrenceForm'
import { toRoleShortName } from '../../components/Scheduler/scheduleUtils'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { useFetchCompanyAppointmentArrivalWindows } from '../../hooks/fetch/useFetchCompanyAppointmentArrivalWindows'
import { useCanCreateJobs } from '../../hooks/permission/useCanCreateJob'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { blue6 } from '../../themes/theme'
import { StateSetter } from '../../utils/react-utils'
import { FETCH_JOBS_SCHEDULE_QUERY } from '../ScheduleV2Page/Schedule.gql'
import {
  BlockCalendarEvent,
  BzCalendarEvent,
  NonBlockCalendarEvent,
  fixMbscDate,
} from '../ScheduleV2Page/scheduleUtils'
import { ScheduleContext } from './ScheduleContext'

const ARRIVAL_WINDOW_HOURS = 2

type EventType = 'NEW_JOB' | 'NEW_APPOINTMENT' | 'NEW_ASSIGNMENT' | 'BLOCK'
type EventTypeOption = { label: string; value: EventType }
const DEFAULT_EVENT_TYPE_OPTIONS = [
  {
    label: 'New appointment for an existing job',
    value: 'NEW_APPOINTMENT',
  },
  {
    label: 'Add a technician to an existing appointment',
    value: 'NEW_ASSIGNMENT',
  },
  {
    label: 'Internal event',
    value: 'BLOCK',
  },
] satisfies EventTypeOption[]

const BLOCK_REASONS_OPTIONS = R.values(TechnicianCapacityBlockReasonType).map(
  value => ({
    value,
    label: formatTechnicianCapacityBlockReasonType(value),
  }),
)

const makeTechOptions = (
  technicians: User[],
  selectedTechGuids: readonly Guid[],
  techsOnly?: boolean,
) => {
  const techs: { value: string; label: string }[] = []

  const selectedGuidMap = selectedTechGuids.reduce(
    (acc, guid) => ({ ...acc, [guid]: true }),
    {} as Record<string, boolean>,
  )

  for (const user of technicians) {
    if (techsOnly) {
      if (!hasTechnicianRole(R.pluck('role', user.roles))) {
        continue
      }
    }
    const tech = {
      label: `${user.firstName} ${user.lastName} - ${user.roles
        .map(role => toRoleShortName(role.role))
        .join(', ')}`,
      value: user.userGuid,
    }
    if (user.deactivatedAt) {
      if (selectedGuidMap[user.userGuid]) {
        tech.label = `(DEACTIVATED) ${tech.label}`
      } else {
        continue
      }
    }
    techs.push(tech)
  }
  return techs
}

type TechMutliSelectFormProps = {
  techsOnly?: boolean
  technicians: User[]
  selectedTechGuids: readonly Guid[]
  onChange: (selectedTechGuids: Guid[]) => void
}

const TechMultiSelectForm = React.memo<TechMutliSelectFormProps>(
  ({ technicians, selectedTechGuids, onChange, techsOnly }) => {
    const technicianOptions = useMemo(
      () => makeTechOptions(technicians, selectedTechGuids, techsOnly),
      [selectedTechGuids, technicians, techsOnly],
    )

    return (
      <Form.Item label="Selected Technician">
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Select technicians"
          optionFilterProp="label"
          value={selectedTechGuids as Guid[]}
          onChange={onChange}
          options={technicianOptions}
        />
      </Form.Item>
    )
  },
)

type NewBlockFormProps = {
  newEvent: BzCalendarEvent
  suppliedNewEvent: BzCalendarEvent
  setNewEvent: StateSetter<BzCalendarEvent>
  technicians: User[]
}

const NewBlockForm = React.memo<NewBlockFormProps>(
  ({ newEvent, suppliedNewEvent, setNewEvent, technicians }) => {
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setTechs = useCallback(
      (userGuids: Guid[]) => {
        setNewEvent(event => ({
          ...event,
          userGuids,
        }))
      },
      [setNewEvent],
    )

    const setReasonType = useCallback(
      (reasonType: TechnicianCapacityBlockReasonType) =>
        setNewEvent(event => ({
          ...event,
          reasonType,
        })),
      [setNewEvent],
    )

    const isRecurring = !!newEvent.recurring

    const setIsRecurring = useCallback(
      (isRecurring: boolean) =>
        setNewEvent(event => ({
          ...event,
          recurring: isRecurring ? 'FREQ=DAILY;INTERVAL=1' : undefined,
        })),
      [setNewEvent],
    )

    const setRRule = useCallback(
      (rrule: string) => setNewEvent(event => ({ ...event, recurring: rrule })),
      [setNewEvent],
    )

    const startingDate = useMemo(
      () => fixMbscDate(newEvent.start),
      [newEvent.start],
    )

    return (
      <>
        <Form layout="vertical">
          {suppliedTechGuid ? null : (
            <TechMultiSelectForm
              technicians={technicians}
              selectedTechGuids={newEvent.userGuids ?? []}
              onChange={setTechs}
            />
          )}
          <Form.Item label="Event Type">
            <Select
              className="w-full"
              id="block-type-select"
              placeholder="Select a type"
              value={newEvent.reasonType}
              onChange={setReasonType}
              options={BLOCK_REASONS_OPTIONS}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[
              {
                required:
                  newEvent.reasonType ===
                  TechnicianCapacityBlockReasonType.OTHER,
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Description of the event"
              value={newEvent.reasonDescription}
              onChange={e =>
                setNewEvent(event => ({
                  ...event,
                  reasonDescription: e.target.value
                    ? e.target.value
                    : undefined,
                }))
              }
            />
          </Form.Item>
          <Checkbox
            disabled={!!suppliedNewEvent.recurring}
            checked={isRecurring}
            onChange={e => setIsRecurring(e.target.checked)}
          >
            This event repeats
          </Checkbox>
        </Form>
        {isRecurring && (
          <RecurrenceForm
            rrule={`${newEvent.recurring ?? ''}`}
            onChange={setRRule}
            className="mt-2"
            startingDate={startingDate}
          />
        )}
      </>
    )
  },
)

type NewAssignmentFormProps = {
  newEvent: BzCalendarEvent
  suppliedNewEvent: BzCalendarEvent
  setNewEvent: StateSetter<BzCalendarEvent>
  technicians: User[]
  allAppointments: AssignableApptViewModelWithBusinessProjections[]
}

const NewAssignmentForm = React.memo<NewAssignmentFormProps>(
  ({
    suppliedNewEvent,
    newEvent,
    setNewEvent,
    technicians,
    allAppointments,
  }) => {
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setTechs = useCallback(
      (userGuids: Guid[]) => {
        setNewEvent(event => ({
          ...event,
          userGuids,
        }))
      },
      [setNewEvent],
    )

    const setAppointmentGuid = useCallback(
      (appointmentGuid: JobAppointmentGuid) =>
        setNewEvent(event => ({
          ...(event as NonBlockCalendarEvent),
          userGuids: suppliedNewEvent.resource
            ? [`${suppliedNewEvent.resource}`]
            : [],
          appointmentGuid,
          assignmentGuid: nextGuid(),
        })),
      [setNewEvent, suppliedNewEvent.resource],
    )

    const selectOptions = useMemo(
      () =>
        allAppointments
          .filter(
            appointment =>
              Dfns.isSameDay(
                Dfns.parseISO(appointment.appointment.timeWindow.start),
                Dfns.parseISO(`${newEvent.start}`),
              ) && appointment.appointment.appointmentStatus !== 'UNASSIGNED',
          )
          .map(appointment => {
            const bzAddress = BzAddress.create(appointment.location.address)
            return {
              value: appointment.appointment.guid,
              label: `${appointment.contact.name} - ${bzAddress.line1}, ${
                bzAddress.zip5
              } - ${R.pipe(
                Dfns.parseISO,
                Dfns.format('h:mma'),
              )(appointment.appointment.timeWindow.start)} -> ${R.pipe(
                Dfns.parseISO,
                Dfns.format('h:mma'),
              )(appointment.appointment.timeWindow.end)}`,
            }
          }),
      [allAppointments, newEvent.start],
    )

    const validTechnicianGuids = useMemo(() => {
      const appointment = allAppointments.find(
        appointment =>
          appointment.appointment.guid === newEvent.appointmentGuid,
      )
      const alreadyAssignedTechGuidMap: Record<string, true> = {}
      for (const assignment of appointment?.assignments ?? []) {
        alreadyAssignedTechGuidMap[assignment.technicianUserGuid] = true
      }
      return technicians.filter(
        tech => !alreadyAssignedTechGuidMap[tech.userGuid],
      )
    }, [allAppointments, newEvent.appointmentGuid, technicians])

    return (
      <Form layout="vertical">
        <Form.Item label="Which appointment?">
          <Select
            showSearch
            optionFilterProp="label"
            className="w-full"
            placeholder="Select an appointment"
            value={newEvent.appointmentGuid}
            onChange={setAppointmentGuid}
            options={selectOptions}
          />
        </Form.Item>
        {suppliedTechGuid || !newEvent.appointmentGuid ? null : (
          <TechMultiSelectForm
            techsOnly
            technicians={validTechnicianGuids}
            selectedTechGuids={newEvent.userGuids ?? []}
            onChange={setTechs}
          />
        )}
      </Form>
    )
  },
)

type AppointmentArrivalWindowFormProps = {
  jobClass: JobClass
  suppliedNewEvent: BzCalendarEvent
  newAppointmentFormInfo: NewAppointmentFormInfo
  setNewAppointmentFormInfo: StateSetter<NewAppointmentFormInfo>
}

const AppointmentArrivalWindowForm =
  React.memo<AppointmentArrivalWindowFormProps>(
    ({
      jobClass,
      suppliedNewEvent,
      newAppointmentFormInfo,
      setNewAppointmentFormInfo,
    }) => {
      const appointmentArrivalWindowsQuery =
        useFetchCompanyAppointmentArrivalWindows()
      const tzId = useExpectedCompanyTimeZoneId()

      const arrivalWindowLocalDate = useMemo(
        () =>
          ZonedDateTime.parse(`${suppliedNewEvent.start}`)
            .withZoneSameInstant(ZoneId.of(tzId))
            .toLocalDate(),
        [suppliedNewEvent.start, tzId],
      )

      const arrivalLocalTimeWindow = useMemo(() => {
        if (
          !newAppointmentFormInfo.formData?.arrivalWindow.start ||
          !newAppointmentFormInfo.formData?.arrivalWindow.end
        ) {
          return {
            arrivalWindowStart: ZonedDateTime.parse(`${suppliedNewEvent.start}`)
              .withZoneSameInstant(ZoneId.of(tzId))
              .toLocalTime(),
            arrivalWindowEnd: ZonedDateTime.parse(`${suppliedNewEvent.start}`)
              .withZoneSameInstant(ZoneId.of(tzId))
              .toLocalTime()
              .plusHours(ARRIVAL_WINDOW_HOURS),
          }
        }
        return {
          arrivalWindowStart: ZonedDateTime.parse(
            newAppointmentFormInfo.formData.arrivalWindow.start,
          )
            .withZoneSameInstant(ZoneId.of(tzId))
            .toLocalTime(),
          arrivalWindowEnd: ZonedDateTime.parse(
            newAppointmentFormInfo.formData.arrivalWindow.end,
          )
            .withZoneSameInstant(ZoneId.of(tzId))
            .toLocalTime(),
        }
      }, [
        newAppointmentFormInfo.formData?.arrivalWindow.end,
        newAppointmentFormInfo.formData?.arrivalWindow.start,
        suppliedNewEvent.start,
        tzId,
      ])

      const setArrivalLocalTimeWindow = useCallback(
        (
          window: {
            arrivalWindowStart: LocalTime
            arrivalWindowEnd: LocalTime
          } | null,
        ) => {
          const offset = ZonedDateTime.ofInstant(
            Instant.now(),
            ZoneId.of(tzId),
          ).offset()
          setNewAppointmentFormInfo(
            R.assocPath(['formData', 'arrivalWindow'], {
              start: window?.arrivalWindowStart
                .atDate(arrivalWindowLocalDate)
                .toInstant(offset)
                .toString(),
              end: window?.arrivalWindowEnd
                .atDate(arrivalWindowLocalDate)
                .toInstant(offset)
                .toString(),
            }),
          )
        },
        [arrivalWindowLocalDate, setNewAppointmentFormInfo, tzId],
      )
      return (
        <Form layout="vertical">
          <Form.Item label="Arrival Window">
            <div className="m-auto w-[275px]">
              <TrpcQueryLoader
                query={appointmentArrivalWindowsQuery}
                render={appointmentArrivalWindows => (
                  <ArrivalWindowForm
                    jobClass={jobClass}
                    appointmentArrivalWindows={appointmentArrivalWindows}
                    arrivalWindowLocalDate={arrivalWindowLocalDate}
                    setArrivalWindowLocalDate={() => {}}
                    arrivalLocalTimeWindow={arrivalLocalTimeWindow}
                    setArrivalLocalTimeWindow={setArrivalLocalTimeWindow}
                    setTechnicianAssignments={() => {}}
                  />
                )}
              />
            </div>
          </Form.Item>
        </Form>
      )
    },
  )

type NewAppointmentFormInfo = {
  formData?: PendingUpsertAppointmentAndAssignmentForm
  selectedJobGuid: JobGuid
  selectedTechs: string[]
}

type NewAppointmentFormProps = {
  suppliedNewEvent: BzCalendarEvent
  technicians: User[]
  newAppointmentFormInfo: NewAppointmentFormInfo
  setNewAppointmentFormInfo: StateSetter<NewAppointmentFormInfo>
}

const NewAppointmentForm = React.memo<NewAppointmentFormProps>(
  ({
    suppliedNewEvent,
    technicians,
    newAppointmentFormInfo,
    setNewAppointmentFormInfo,
  }) => {
    const company = useExpectedCompany()
    const { selectedTechs, selectedJobGuid } = newAppointmentFormInfo
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setSelectedTechs = useCallback(
      (userGuids: Guid[]) =>
        setNewAppointmentFormInfo(info => ({
          ...info,
          selectedTechs: userGuids,
        })),
      [setNewAppointmentFormInfo],
    )

    const setSelectedJobGuid = useCallback(
      (selectedJobGuid: Guid) =>
        setNewAppointmentFormInfo(info => ({
          ...info,
          selectedJobGuid,
        })),
      [setNewAppointmentFormInfo],
    )

    const setAppointmentFormData = useCallback(
      (formData: PendingUpsertAppointmentAndAssignmentForm) =>
        setNewAppointmentFormInfo(info => ({ ...info, formData })),
      [setNewAppointmentFormInfo],
    )

    const fetchJobsQuery = useQuery({
      query: FETCH_JOBS_SCHEDULE_QUERY,
      variables: {
        companyGuid: company.companyGuid,
      },
    })

    const jobOptions = useMemo(
      () =>
        (fetchJobsQuery[0].data?.jobs || []).map(job => ({
          label: `${
            job.account.accountDisplayName
          } - ${effectiveLocationDisplayName(job.location)} - ${
            job.jobType.name
          }`,
          value: job.jobGuid,
        })),
      [fetchJobsQuery],
    )

    const jobDetails = useMemo(
      () =>
        fetchJobsQuery[0].data?.jobs.find(
          job => job.jobGuid === selectedJobGuid,
        ),
      [fetchJobsQuery, selectedJobGuid],
    )

    return (
      <>
        {suppliedTechGuid ? null : (
          <Form layout="vertical">
            <TechMultiSelectForm
              techsOnly
              technicians={technicians}
              selectedTechGuids={selectedTechs}
              onChange={setSelectedTechs}
            />
          </Form>
        )}
        <GqlQueryLoader
          query={fetchJobsQuery}
          render={() => (
            <>
              <Form layout="vertical">
                <Form.Item label="Which job?">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    className="w-full"
                    placeholder="Select job"
                    value={selectedJobGuid || null}
                    onChange={setSelectedJobGuid}
                    options={jobOptions}
                  />
                </Form.Item>
              </Form>
              {jobDetails && (
                <div className="grid-two-auto-columns grid gap-2">
                  {(
                    [
                      ['Account', jobDetails.account.accountDisplayName],
                      ['Type', jobDetails.jobType.name],
                      [
                        'Location',
                        effectiveLocationLongDisplayName(jobDetails.location),
                      ],
                      ['Status', jobDetails.jobLifecycleStatus.name],
                    ] as const
                  ).map(([label, value]) => (
                    <div key={label}>
                      <h4 className="text-sm">{label}</h4>
                      <div className="text-xs">{value}</div>
                    </div>
                  ))}
                </div>
              )}
              {jobDetails && (
                <>
                  <Divider />
                  <UpsertAppointmentForm
                    mode="create"
                    hideTechnicianAssignments
                    hideArrivalWindowForm
                    hideJobSelect
                    showFormCancelSubmitButtons={false}
                    showDivider={false}
                    onCancel={() => {}}
                    onAppointmentCreated={() => {}}
                    jobGuid={jobDetails.jobGuid}
                    jobClass={jobDetails.jobType.jobClass}
                    onChange={setAppointmentFormData}
                  />
                  <AppointmentArrivalWindowForm
                    jobClass={jobDetails.jobType.jobClass}
                    suppliedNewEvent={suppliedNewEvent}
                    newAppointmentFormInfo={newAppointmentFormInfo}
                    setNewAppointmentFormInfo={setNewAppointmentFormInfo}
                  />
                </>
              )}
            </>
          )}
        />
      </>
    )
  },
)

type NewJobFormProps = {
  suppliedNewEvent: BzCalendarEvent
  technicians: User[]
  newAppointmentFormInfo: NewAppointmentFormInfo
  setNewAppointmentFormInfo: StateSetter<NewAppointmentFormInfo>
  jobType: JobType | undefined
  setJobType: StateSetter<JobType | undefined>
}

const NewJobForm = React.memo<NewJobFormProps>(
  ({
    suppliedNewEvent,
    technicians,
    newAppointmentFormInfo,
    setNewAppointmentFormInfo,
    jobType,
    setJobType,
  }) => {
    const { selectedTechs } = newAppointmentFormInfo
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setSelectedTechs = useCallback(
      (techGuids: Guid[]) =>
        setNewAppointmentFormInfo(info => ({
          ...info,
          selectedTechs: techGuids,
        })),
      [setNewAppointmentFormInfo],
    )

    const setAppointmentFormData = useCallback(
      (formData: PendingUpsertAppointmentAndAssignmentForm) =>
        setNewAppointmentFormInfo(info => ({ ...info, formData })),
      [setNewAppointmentFormInfo],
    )

    const jobTypesQuery = trpc.jobTypes['job-types:get'].useQuery()

    const ourSelectJobTypeGuid = useCallback(
      (jobTypeGuid: string) => {
        const jobType = jobTypesQuery.data?.find(
          jobType => jobType.jobTypeGuid === jobTypeGuid,
        )
        setJobType(jobType)
      },
      [jobTypesQuery.data, setJobType],
    )

    return (
      <>
        <Form layout="vertical">
          {suppliedTechGuid ? null : (
            <TechMultiSelectForm
              techsOnly
              technicians={technicians}
              selectedTechGuids={selectedTechs}
              onChange={setSelectedTechs}
            />
          )}

          <TrpcQueryLoader
            query={jobTypesQuery}
            render={jobTypes => (
              <Form.Item label="Job Type">
                <Select
                  showSearch
                  optionFilterProp="label"
                  className="w-full"
                  placeholder="Job Type"
                  value={jobType?.jobTypeGuid}
                  onChange={ourSelectJobTypeGuid}
                  options={jobTypes.map(jobType => ({
                    value: jobType.jobTypeGuid,
                    label: jobType.name,
                  }))}
                />
              </Form.Item>
            )}
          />
        </Form>

        {jobType && (
          <>
            <UpsertAppointmentForm
              mode="create"
              hideTechnicianAssignments
              hideArrivalWindowForm
              showFormCancelSubmitButtons={false}
              showDivider={false}
              onCancel={() => {}}
              onAppointmentCreated={() => {}}
              jobGuid=""
              jobClass={jobType.jobClass}
              onChange={setAppointmentFormData}
            />
            <AppointmentArrivalWindowForm
              jobClass={jobType.jobClass}
              suppliedNewEvent={suppliedNewEvent}
              newAppointmentFormInfo={newAppointmentFormInfo}
              setNewAppointmentFormInfo={setNewAppointmentFormInfo}
            />
          </>
        )}
      </>
    )
  },
)

type NewEventModalProps = {
  onClose: (newEvent?: BzCalendarEvent) => void
  pendingNewEvent: BzCalendarEvent
  timeZoneId: TimeZoneId
  technicians: User[]
  allAppointments: AssignableApptViewModelWithBusinessProjections[]
}

export const NewEventModal = React.memo<NewEventModalProps>(
  ({ onClose, pendingNewEvent, timeZoneId, technicians, allAppointments }) => {
    const { refetch, appointmentColorMap } = useContext(ScheduleContext)
    const description = useMemo(() => {
      const start = BzDateTime.fromIsoString(
        fixMbscDate(pendingNewEvent.start),
        timeZoneId,
      )
      const end = BzDateTime.fromIsoString(
        fixMbscDate(pendingNewEvent.end),
        timeZoneId,
      )

      return (
        <p>
          You're creating a new event on{' '}
          <strong>{start.toDateFormat('MMMM d, yyyy')}</strong> from{' '}
          <strong>{start.toDateFormat('h:mma').toLowerCase()}</strong> to{' '}
          <strong>{end.toDateFormat('h:mma').toLowerCase()}</strong>.
        </p>
      )
    }, [pendingNewEvent.end, pendingNewEvent.start, timeZoneId])
    const canCreateJobs = useCanCreateJobs()

    const [newEvent, setNewEvent] = useState(pendingNewEvent)
    const [eventType, setEventTypeRaw] = useState<EventType>(
      canCreateJobs ? 'NEW_JOB' : 'NEW_APPOINTMENT',
    )

    const defaultNewAppointmentFormInfo = useMemo(
      () => ({
        selectedJobGuid: '',
        selectedTechs: newEvent.resource ? [`${newEvent.resource}`] : [],
      }),
      [newEvent.resource],
    )

    const [newAppointmentFormInfo, setNewAppointmentFormInfo] =
      useState<NewAppointmentFormInfo>(defaultNewAppointmentFormInfo)

    const setEventType = useCallback<StateSetter<EventType>>(
      arg => {
        setNewEvent(pendingNewEvent)
        setNewAppointmentFormInfo(defaultNewAppointmentFormInfo)
        setEventTypeRaw(arg)
      },
      [defaultNewAppointmentFormInfo, pendingNewEvent],
    )

    const [newJobJobType, setNewJobJobType] = useState<JobType>()

    const enabledEventTypeOptions = useMemo(() => {
      return canCreateJobs
        ? [
            { label: 'New job', value: 'NEW_JOB' },
            ...DEFAULT_EVENT_TYPE_OPTIONS,
          ]
        : [...DEFAULT_EVENT_TYPE_OPTIONS]
    }, [canCreateJobs])

    const [okText, formContent] = useMemo(() => {
      switch (eventType) {
        case 'NEW_JOB':
          return [
            'Continue',
            <NewJobForm
              suppliedNewEvent={pendingNewEvent}
              technicians={technicians}
              newAppointmentFormInfo={newAppointmentFormInfo}
              setNewAppointmentFormInfo={setNewAppointmentFormInfo}
              jobType={newJobJobType}
              setJobType={setNewJobJobType}
            />,
          ]
        case 'NEW_APPOINTMENT':
          return [
            'Create appointment',
            <NewAppointmentForm
              suppliedNewEvent={pendingNewEvent}
              technicians={technicians}
              newAppointmentFormInfo={newAppointmentFormInfo}
              setNewAppointmentFormInfo={setNewAppointmentFormInfo}
            />,
          ]
        case 'NEW_ASSIGNMENT':
          return [
            'Assign Tech',
            <NewAssignmentForm
              newEvent={newEvent}
              setNewEvent={setNewEvent}
              allAppointments={allAppointments}
              technicians={technicians}
              suppliedNewEvent={pendingNewEvent}
            />,
          ]
        default:
          return [
            'Create event',
            <NewBlockForm
              technicians={technicians}
              newEvent={newEvent}
              suppliedNewEvent={pendingNewEvent}
              setNewEvent={setNewEvent}
            />,
          ]
      }
    }, [
      eventType,
      pendingNewEvent,
      technicians,
      newAppointmentFormInfo,
      newJobJobType,
      newEvent,
      allAppointments,
    ])

    const upsertAppointmentMutation =
      trpc.appointments['appointment-and-assignments:upsert'].useMutation()

    const submitDisabled = useMemo(() => {
      if (upsertAppointmentMutation.isLoading) {
        return true
      }

      const isNewAppointmentFormInfoValid =
        newAppointmentFormInfo.selectedTechs?.length &&
        (newAppointmentFormInfo.formData?.appointmentType !== 'Other' ||
          newAppointmentFormInfo.formData?.description)
      if (eventType === 'BLOCK') {
        return (
          !(newEvent.resource || newEvent.userGuids?.length) ||
          !newEvent.reasonType ||
          (newEvent.reasonType === TechnicianCapacityBlockReasonType.OTHER &&
            !newEvent.reasonDescription)
        )
      } else if (eventType === 'NEW_ASSIGNMENT') {
        return (
          !newEvent.appointmentGuid ||
          !(newEvent.resource || newEvent.userGuids?.length)
        )
      } else if (eventType === 'NEW_APPOINTMENT') {
        return (
          !newAppointmentFormInfo.selectedJobGuid ||
          !isNewAppointmentFormInfoValid
        )
      } else if (eventType === 'NEW_JOB') {
        return !newJobJobType || !isNewAppointmentFormInfoValid
      }
      return false
    }, [
      eventType,
      newAppointmentFormInfo.formData?.appointmentType,
      newAppointmentFormInfo.formData?.description,
      newAppointmentFormInfo.selectedJobGuid,
      newAppointmentFormInfo.selectedTechs?.length,
      newEvent.appointmentGuid,
      newEvent.reasonDescription,
      newEvent.reasonType,
      newEvent.resource,
      newEvent.userGuids?.length,
      newJobJobType,
      upsertAppointmentMutation.isLoading,
    ])

    const [isProgressiveJobModalOpen, setIsProgressiveJobModalOpen] =
      useState(false)

    const newAppointmentUpsertBody = useMemo<UpsertAppointmentAndAssignmentDTO>(
      () => ({
        ...(newAppointmentFormInfo.formData as UpsertAppointmentAndAssignmentDTO),
        arrivalWindow: {
          start:
            newAppointmentFormInfo.formData?.arrivalWindow.start ??
            fixMbscDate(newEvent.start),
          end:
            newAppointmentFormInfo.formData?.arrivalWindow.end ??
            BzDateFns.withTimeZone(
              fixMbscDate(newEvent.start),
              timeZoneId,
              date => BzDateFns.addHours(date, ARRIVAL_WINDOW_HOURS),
            ),
        },
        assignments: newAppointmentFormInfo.selectedTechs.map(
          technicianUserGuid => ({
            assignmentGuid: nextGuid(),
            technicianUserGuid,
            timeWindow: {
              start: fixMbscDate(newEvent.start),
              end: fixMbscDate(newEvent.end),
            },
          }),
        ),
      }),
      [
        newAppointmentFormInfo.formData,
        newAppointmentFormInfo.selectedTechs,
        newEvent.end,
        newEvent.start,
        timeZoneId,
      ],
    )

    const onOk = useCallback(async () => {
      const color = appointmentColorMap[newEvent.assignmentGuid ?? ''] ?? blue6
      if (eventType === 'BLOCK') {
        onClose({
          ...(newEvent as BlockCalendarEvent),
          userGuids: newEvent.userGuids ?? [`${newEvent.resource}`],
          color,
          blockGuid: nextGuid(),
        })
      } else if (eventType === 'NEW_ASSIGNMENT') {
        onClose({
          ...newEvent,
          userGuids: newEvent.userGuids ?? [`${newEvent.resource}`],
          color,
        })
      } else if (eventType === 'NEW_APPOINTMENT') {
        await upsertAppointmentMutation.mutateAsync(newAppointmentUpsertBody)
        await refetch()
        onClose()
      } else if (eventType === 'NEW_JOB') {
        setIsProgressiveJobModalOpen(true)
      }
    }, [
      appointmentColorMap,
      eventType,
      newAppointmentUpsertBody,
      newEvent,
      onClose,
      refetch,
      upsertAppointmentMutation,
    ])

    const onJobCreateSuccess = useCallback(
      async (jobGuid: JobGuid) => {
        await upsertAppointmentMutation.mutateAsync({
          ...newAppointmentUpsertBody,
          jobGuid,
        })
        await refetch()
        setIsProgressiveJobModalOpen(false)
        onClose()
      },
      [newAppointmentUpsertBody, onClose, refetch, upsertAppointmentMutation],
    )

    return (
      <>
        <Modal
          open={!isProgressiveJobModalOpen}
          closable
          width={600}
          onCancel={() => onClose()}
          onOk={onOk}
          okText={okText}
          title="New Event"
          okButtonProps={{
            disabled: submitDisabled,
          }}
        >
          <Spin
            spinning={upsertAppointmentMutation.isLoading}
            indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
          >
            {description}

            <Form layout="vertical">
              <Form.Item label="What kind of event is it?">
                <Select
                  placeholder="Select an event type"
                  value={eventType}
                  onChange={setEventType}
                  options={enabledEventTypeOptions}
                />
              </Form.Item>
            </Form>
            {formContent}
          </Spin>
        </Modal>
        <ProgressiveJobCreationModal
          disableCreateNewAppointment
          isOpen={isProgressiveJobModalOpen}
          setIsOpen={setIsProgressiveJobModalOpen}
          onSuccess={onJobCreateSuccess}
          selectedJobTypeGuid={newJobJobType?.jobTypeGuid}
        />
      </>
    )
  },
)
