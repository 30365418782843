import { MaintenancePlanDefinition } from '@breezy/shared'
import { Skeleton } from 'antd'
import { RefObject, memo, useLayoutEffect, useMemo, useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { Carousel } from '../EstimatesFlow/components/Carousel'
import { MaintenancePlanTypeOptionCard } from './MaintenancePlanTypeOptionCard'
import { renderCarouselHeader } from './components/CarouselHeader'
import { MaintenancePlanTypeOption } from './create-or-edit-maintenance-plan-page-types'
import { usePageContainerWidth } from './hooks/usePageContainerWidth'
const GUTTER = 12
type Props = {
  readonly maintenancePlanTypeOptions: readonly MaintenancePlanTypeOption[]
  readonly isImportedPlan: boolean
  readonly onSelectMaintenancePlanType: (
    maintenancePlanDefinition: MaintenancePlanDefinition,
  ) => void
  readonly pageContainerRef: RefObject<HTMLDivElement>
}

export const SelectMaintenancePlanTypePanel = memo<Props>(
  ({
    maintenancePlanTypeOptions,
    isImportedPlan,
    onSelectMaintenancePlanType,
  }) => {
    const isMobile = useIsMobile()
    const { scrollbarWidth, pageContainerWidth } = usePageContainerWidth()
    const [isLoaded, setIsLoaded] = useState(false)

    const itemWidth = useMemo(() => {
      if (isMobile) {
        // Subtract 26px from the container width to show a peek of the next item
        return Math.min(
          pageContainerWidth - 32 - (scrollbarWidth ?? 0) - 26,
          360,
        )
      }
      return 371
    }, [isMobile, pageContainerWidth, scrollbarWidth])

    useLayoutEffect(() => {
      const timer = setTimeout(() => setIsLoaded(true), 500) // Simulate loading time
      return () => clearTimeout(timer)
    }, [])

    const mpItems = useMemo(
      () =>
        maintenancePlanTypeOptions
          .filter(o => !!o.plan.isAvailableForSale)
          .map(maintenancePlanTypeOption => (
            <div
              key={maintenancePlanTypeOption.plan.maintenancePlanDefinitionGuid}
              style={{ width: `${itemWidth}px` }}
              className="h-full max-h-full pb-2"
            >
              {isLoaded ? (
                <MaintenancePlanTypeOptionCard
                  maintenancePlanTypeOption={maintenancePlanTypeOption}
                  onSelectMaintenancePlanType={onSelectMaintenancePlanType}
                  isImportedPlan={isImportedPlan}
                />
              ) : (
                <Skeleton active paragraph={{ rows: 4 }} />
              )}
            </div>
          )),
      [
        maintenancePlanTypeOptions,
        onSelectMaintenancePlanType,
        itemWidth,
        isImportedPlan,
        isLoaded,
      ],
    )

    const carouselMargin = useMemo(
      () =>
        isMobile
          ? 16
          : Math.max((pageContainerWidth - 754 - (scrollbarWidth ?? 0)) / 3, 0),
      [isMobile, pageContainerWidth, scrollbarWidth],
    )

    return (
      <div
        style={{
          margin: `0 -${carouselMargin}px`,
        }}
      >
        <Carousel
          renderHeaderWithDots={renderCarouselHeader('Select Plan')}
          margin={carouselMargin}
          mistyMargin={carouselMargin}
          maxDots={
            isMobile
              ? maintenancePlanTypeOptions.length
              : maintenancePlanTypeOptions.length - 1
          }
          hideDots={maintenancePlanTypeOptions.length < 3}
          gutter={GUTTER}
        >
          {mpItems}
        </Carousel>
      </div>
    )
  },
)
