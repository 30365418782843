import { z } from 'zod'
import { AsyncFn, AsyncTask, minifyUrl, nextGuid } from '../../common'
import { guidSchema, isoDateStringSchema, localDateSchema } from '../../contracts'
import { ForCompany, ForCompanyUser } from '../Company/Company'
import { CommonDiscountUsc, DiscountType, RichDiscountUsc } from '../Discounts/DiscountTypes'
import { InvoiceTerm } from '../Finance/Invoicing/InvoiceTypes'
import { calculateCartOrderSummaryUsc } from '../Finance/Transactions/TransactionFunctionsUsc'
import { CartItemUsc, CartUsc } from '../Finance/Transactions/TransactionTypes'
import { JobClass } from '../Job'
import { basicBrandedMessengerRequestSchema } from '../Messaging/MessagingTypes'
import { UserGuid } from '../Users/User'
import { ForAddonFields, Guid, bzOptional } from '../common-schemas'

export const ESTIMATE_V2_STATUSES = [
  'DRAFT',
  'CREATED',
  'SENT',
  'REVIEWED',
  'ACCEPTED',
  'VOIDED',
  'CLOSED',
  'EXPIRED',
] as const

export type EstimateV2Status = (typeof ESTIMATE_V2_STATUSES)[number]

export const ACTIVE_ESTIMATE_STATUSES: EstimateV2Status[] = ['CREATED', 'SENT', 'REVIEWED', 'ACCEPTED']

export const baseAcceptEstimateRequestSchema = z.object({
  estimateGuid: guidSchema,
  accountGuid: guidSchema,
  selectedOptionGuid: guidSchema,
  noEmail: bzOptional(z.boolean()),
})

export const acceptEstimateRequestSchema = baseAcceptEstimateRequestSchema.extend({
  signaturePNG: bzOptional(z.string()),
})

export const unAuthAcceptEstimateRequestSchema = baseAcceptEstimateRequestSchema.extend({
  signaturePNG: z.string(),
  companyGuid: z.string(),
})

export type AcceptEstimateRequest = z.infer<typeof acceptEstimateRequestSchema>
export type UnAuthAcceptEstimateRequest = z.infer<typeof unAuthAcceptEstimateRequestSchema>

export type EstimateAccepterResponse = {
  customerEmailSentTo?: string
  internalEmailsSentTo?: string[]
}

export type EstimateAccepter = AsyncFn<
  AcceptEstimateRequest & {
    userGuid?: UserGuid
    companyGuid: Guid
  },
  EstimateAccepterResponse
>

export type EstimateAcceptedSetterRequest = {
  estimateGuid: Guid
  signatureUrl?: string
  selectedOptionGuid: Guid
  userGuid?: UserGuid
  companyGuid: Guid
}

export type EstimateAcceptedSetter = AsyncFn<EstimateAcceptedSetterRequest>

export const unAuthEstimateReviewedSetterRequestSchema = z.object({
  companyGuid: guidSchema,
  estimateGuid: guidSchema,
})

export type UnAuthEstimateReviewedSetterRequest = z.infer<typeof unAuthEstimateReviewedSetterRequestSchema>

export type EstimateReviewedSetter = AsyncFn<UnAuthEstimateReviewedSetterRequest>

export const invoiceFromEstimateRequestSchema = z.object({
  estimateGuid: guidSchema,
  serviceCompletionDate: localDateSchema,
  invoiceTerm: z.nativeEnum(InvoiceTerm),
  createForLinkedToJob: bzOptional(z.boolean()),
})

export type InvoiceFromEstimateRequest = z.infer<typeof invoiceFromEstimateRequestSchema>

export type InvoiceFromEstimateCreatorResponse = {
  invoiceGuid: Guid
}

export type InvoiceFromEstimateCreator = AsyncFn<
  ForCompanyUser<InvoiceFromEstimateRequest>,
  InvoiceFromEstimateCreatorResponse
>

export const getEstimateOptionCart = (
  lineItems: CartItemUsc[],
  discounts: RichDiscountUsc[] | undefined,
  taxRate: number,
): CartUsc => {
  let overallDiscount: CommonDiscountUsc = {
    type: DiscountType.FLAT,
    discountAmountUsc: 0,
  }
  if (discounts?.length) {
    if (discounts[0].type === DiscountType.FLAT) {
      const ourDiscount = {
        type: DiscountType.FLAT as const,
        discountAmountUsc: 0,
      }
      for (const discount of discounts) {
        if (discount.type !== DiscountType.FLAT) {
          throw new Error('Cannot mix percentage and flat discounts.')
        }
        ourDiscount.discountAmountUsc += discount.discountAmountUsc
      }
      overallDiscount = ourDiscount
    } else {
      if (discounts.length > 1) {
        throw new Error('You can only have one percentage-based discount.')
      }

      overallDiscount = {
        type: DiscountType.RATE,
        discountRate: discounts[0].discountRate,
      }
    }
  }

  return {
    items: lineItems,
    discount: overallDiscount,
    taxRate: {
      taxRateGuid: nextGuid(),
      rate: taxRate,
    },
  }
}

export const getTotalUscForOption = (
  option: {
    lineItems: CartItemUsc[]
    discounts: RichDiscountUsc[]
  },
  taxRate: number,
) => {
  return calculateCartOrderSummaryUsc(getEstimateOptionCart(option.lineItems, option.discounts, taxRate)).totalPriceUsc
}

export const createDirectEstimateLink = (accountAppFullUrl: string, estimateGuid: string, contactGuid?: string) =>
  `${accountAppFullUrl}/estimate/${estimateGuid}${contactGuid ? `/${contactGuid}` : ''}`
export const generateEstimateAccountAppLink = (accountAppFullUrl: string, estimateGuid: string, contactGuid?: string) =>
  minifyUrl(createDirectEstimateLink(accountAppFullUrl, estimateGuid, contactGuid))

export const estimatesSummaryStatsReaderRequestSchema = z.object({
  startDate: bzOptional(isoDateStringSchema),
  jobClasses: bzOptional(z.array(z.nativeEnum(JobClass))),
  statuses: bzOptional(z.array(z.enum(ESTIMATE_V2_STATUSES))),
  createdByGuids: bzOptional(z.array(guidSchema)),
})

export type EstimatesSummaryStatsReaderRequest = z.infer<typeof estimatesSummaryStatsReaderRequestSchema>

export type EstimatesSummaryStatsReaderResponse = {
  outstanding: {
    count: number
    totalUsc: number
  }
  accepted: {
    count: number
    totalUsc: number
  }
  expired: {
    count: number
    totalUsc: number
  }
  voided: {
    count: number
    totalUsc: number
  }
  closed: {
    count: number
    totalUsc: number
  }
}

export type EstimatesSummaryStatsReader = AsyncFn<
  ForCompany<EstimatesSummaryStatsReaderRequest>,
  EstimatesSummaryStatsReaderResponse
>

export const estimateReviewMessengerRequestSchema = basicBrandedMessengerRequestSchema.and(
  z.object({
    estimateGuid: guidSchema,
  }),
)

export type EstimateReviewMessengerRequest = z.infer<typeof estimateReviewMessengerRequestSchema>

export type EstimateReviewMessenger = AsyncFn<
  ForAddonFields<'companyGuid' | 'companyName', EstimateReviewMessengerRequest>
>

export type EstimateReminderSendTask = AsyncTask
