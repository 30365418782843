import { z } from 'zod'
import { AsyncFn, IsoDateString } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { APPOINTMENT_TYPES } from '../Appointments/Appointments'
import { CompanyGuidContainer, ForCompany } from '../Company/Company'
import { MinimalUser } from '../Users/User'
import { bzOptional } from '../common-schemas'

export const appointmentChecklistAssociationRuleSchema = z.object({
  jobType: bzOptional(
    z.object({
      jobTypeGuid: guidSchema,
      name: z.string(),
    }),
  ),
  appointmentType: bzOptional(z.enum(APPOINTMENT_TYPES)),
  required: bzOptional(z.boolean()),
})

type AppointmentChecklistAssociationRule = z.infer<typeof appointmentChecklistAssociationRuleSchema>
export type AppointmentChecklistJobTypeCondition = NonNullable<AppointmentChecklistAssociationRule['jobType']>

const APPOINTMENT_CHECKLIST_ITEM_TYPE = ['CHECKBOX', 'TEXT', 'PASS_FAIL_FLAG', 'YES_NO', 'INPUT'] as const

export type AppointmentChecklistItemType = (typeof APPOINTMENT_CHECKLIST_ITEM_TYPE)[number]

export const APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG = {
  CHECKBOX: {
    label: 'Checkbox',
    options: ['Yes', 'No'],
  },
  TEXT: {
    label: 'Text',
    options: undefined,
  },
  INPUT: {
    label: 'Short Answer',
    options: undefined,
  },
  PASS_FAIL_FLAG: {
    label: 'Pass/Fail/Flag',
    options: ['Pass', 'Fail', 'Flag'],
  },
  YES_NO: {
    label: 'Yes/No',
    options: ['Yes', 'No', 'N/A'],
  },
} satisfies Record<AppointmentChecklistItemType, { label: string; options: string[] | undefined }>

export const appointmentChecklistItemSchema = z.object({
  type: z.enum(APPOINTMENT_CHECKLIST_ITEM_TYPE),
  name: z.string(),
  notRequired: bzOptional(z.boolean()),
})

export const appointmentChecklistSchema = z.object({
  appointmentChecklistGuid: guidSchema,
  name: z.string(),
  associationRules: z.array(appointmentChecklistAssociationRuleSchema),
  items: z.array(appointmentChecklistItemSchema),
})

export type AppointmentChecklist = z.infer<typeof appointmentChecklistSchema>

export const appointmentChecklistDeleteRequestSchema = z.object({
  appointmentChecklistGuid: guidSchema,
})

export type AppointmentChecklistDeleteRequest = z.infer<typeof appointmentChecklistDeleteRequestSchema>

export const associatedAppointmentChecklistInstancesCreatorRequestSchema = z.object({
  jobAppointmentGuid: guidSchema,
  jobTypeGuid: guidSchema,
  appointmentType: z.enum(APPOINTMENT_TYPES),
})

export type AssociatedAppointmentChecklistInstancesCreatorRequest = z.infer<
  typeof associatedAppointmentChecklistInstancesCreatorRequestSchema
>

export const appointmentChecklistAssociationsMatch = (
  request: AssociatedAppointmentChecklistInstancesCreatorRequest,
  associationRules: AppointmentChecklistAssociationRule[],
) => {
  for (const associationRule of associationRules) {
    const matchesAppointmentType =
      !associationRule.appointmentType || associationRule.appointmentType === request.appointmentType
    const matchesJobClass = !associationRule.jobType || associationRule.jobType.jobTypeGuid === request.jobTypeGuid
    if (matchesAppointmentType && matchesJobClass) {
      return true
    }
  }
  return false
}

// Same data as a checklist item, but add an optional response
const appointmentChecklistInstanceItemSchema = appointmentChecklistItemSchema.and(
  z.object({
    response: bzOptional(z.string()),
  }),
)

export type AppointmentChecklistInstanceItem = z.infer<typeof appointmentChecklistInstanceItemSchema>

export const appointmentChecklistInstanceChecklistSchema = z.object({
  name: z.string(),
  items: z.array(appointmentChecklistInstanceItemSchema),
})

export type AppointmentChecklistInstanceChecklist = z.infer<typeof appointmentChecklistInstanceChecklistSchema>

export const writableAppointmentChecklistInstanceSchema = z.object({
  appointmentChecklistInstanceGuid: guidSchema,
  appointmentChecklistGuid: guidSchema,
  jobAppointmentGuid: guidSchema,
  lastEditorUserGuid: bzOptional(guidSchema),
  checklist: appointmentChecklistInstanceChecklistSchema,
})

export type WritableAppointmentChecklistInstance = z.infer<typeof writableAppointmentChecklistInstanceSchema>
export type AppointmentChecklistInstance = WritableAppointmentChecklistInstance & {
  updatedAt: IsoDateString
  lastEditor?: MinimalUser
}

export type AppointmentChecklistsReader = AsyncFn<CompanyGuidContainer, AppointmentChecklist[]>
export type AppointmentChecklistUpserter = AsyncFn<ForCompany<AppointmentChecklist>>
export type AppointmentChecklistDeleter = AsyncFn<ForCompany<AppointmentChecklistDeleteRequest>>

export type AssociatedAppointmentChecklistInstancesCreator = AsyncFn<
  ForCompany<AssociatedAppointmentChecklistInstancesCreatorRequest>,
  AppointmentChecklistInstance[]
>
export type AppointmentChecklistInstanceBulkUpserter = AsyncFn<
  WritableAppointmentChecklistInstance[],
  AppointmentChecklistInstance[]
>

export const checklistInfoForAppointmentReaderRequestSchema =
  associatedAppointmentChecklistInstancesCreatorRequestSchema.and(
    z.object({
      jobAppointmentGuid: guidSchema,
    }),
  )

export type ChecklistInfoForAppointmentReaderRequest = z.infer<typeof checklistInfoForAppointmentReaderRequestSchema>
export type ChecklistInfoForAppointmentReaderResponse = {
  instances: AppointmentChecklistInstance[]
  numAssociatedChecklists: number
}

export type ChecklistInfoForAppointmentReader = AsyncFn<
  ForCompany<ChecklistInfoForAppointmentReaderRequest>,
  ChecklistInfoForAppointmentReaderResponse
>
