import {
  Address,
  MapAppType,
  getMapAppType,
  getMapAppUrl,
} from '@breezy/shared'
import { useQuery } from 'urql'
import { GQL_USER_SETTINGS_QUERY } from '../pages/SettingsPage/MapAppPreferencePicker.gql'
import { useAppContext } from '../providers/AppContextWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../providers/PrincipalUser'
import { Applications } from '../utils/application-type'

export const usePreferredMapApp = (): MapAppType | undefined => {
  const userGuid = useExpectedUserGuid()
  const companyGuid = useExpectedCompanyGuid()

  const query = useQuery({
    query: GQL_USER_SETTINGS_QUERY,
    variables: { companyGuid, userGuid },
  })

  const isLoading = query[0].fetching
  return isLoading || query[0].data?.userSettings.length === 0
    ? undefined
    : getMapAppType(query[0].data?.userSettings[0].preferredMapApp)
}

export const useMapLink = (address: Address): [url: string, label: string] => {
  const isTechApp = useAppContext().appType === Applications.TECHNICIAN
  const preferredMapApp =
    usePreferredMapApp() ?? (isTechApp ? 'apple' : 'google')
  const label = 'Open in Maps'

  return [getMapAppUrl(address, preferredMapApp), label]
}

export const useGetMapAppUrl = (): ((address: Address) => string) => {
  const isTechApp = useAppContext().appType === Applications.TECHNICIAN
  const preferredMapApp =
    usePreferredMapApp() ?? (isTechApp ? 'apple' : 'google')

  return (address: Address) => getMapAppUrl(address, preferredMapApp)
}
