import { useEffect, useState } from 'react'
import { useScrollbarWidth } from 'react-use'

export const usePageContainerWidth = () => {
  const [pageContainerWidth, setPageContainerWidth] = useState(
    window.innerWidth,
  )
  const scrollbarWidth = useScrollbarWidth()

  useEffect(() => {
    const handleResize = () => {
      setPageContainerWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { pageContainerWidth, scrollbarWidth }
}
