import { useExpectedCompany } from '../../providers/PrincipalUser'
import { Composable } from '../../utils/Composable'
import NoMerchantIdSetupWarningView from './NoMerchantIdSetupWarningView'

type RequiresMerchantIdProps = Composable & {
  readonly fallbackComponent?: React.ReactNode
}

const RequiresMerchantId = ({
  children,
  fallbackComponent,
}: RequiresMerchantIdProps) =>
  !useExpectedCompany().merchantId ? (
    <>{fallbackComponent || <NoMerchantIdSetupWarningView />}</>
  ) : (
    <>{children}</>
  )

export default RequiresMerchantId
