export class BzDuration {
  static minutes = (minutes: number): BzDuration => new BzDuration(minutes * 60 * 1000)
  static hours = (hours: number): BzDuration => new BzDuration(hours * 60 * 60 * 1000)

  constructor(private readonly totalMilliseconds: number) {}

  compareTo(other: BzDuration): number {
    if (this.totalMilliseconds > other.totalMilliseconds) return +1
    if (this.totalMilliseconds < other.totalMilliseconds) return -1
    return 0
  }

  formatHoursDescription(): string {
    const hours = Math.floor(this.totalMilliseconds / 1000 / 60 / 60)
    return `${hours} hours`
  }

  formatHoursAndMinutes(): string {
    const hours = Math.floor(this.totalMilliseconds / 1000 / 60 / 60)
    const minutes = Math.floor(this.totalMilliseconds / 1000 / 60) % 60
    return `${hours} hours ${minutes} minutes`.replace(' 0 minutes', '')
  }

  formatHoursAndMinutesShort(): string {
    const hours = Math.floor(this.totalMilliseconds / 1000 / 60 / 60)
    const minutes = Math.floor(this.totalMilliseconds / 1000 / 60) % 60
    return `${hours}h ${minutes}m`.replace(' 0m', '')
  }

  formatHHMM(): string {
    const hours = Math.floor(this.totalMilliseconds / 1000 / 60 / 60)
    const minutes = Math.floor(this.totalMilliseconds / 1000 / 60) % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }

  formatMSS(): string {
    const seconds = Math.floor(this.totalMilliseconds / 1000) % 60
    const minutes = Math.floor(this.totalMilliseconds / 1000 / 60) % 60
    return `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  totalMinutes(): number {
    return Math.floor(this.totalMilliseconds / 1000 / 60)
  }

  static parse = (hhmm: string): BzDuration => {
    const [hours, minutes] = hhmm.split(':').map(s => parseInt(s, 10))
    return BzDuration.minutes(minutes + hours * 60)
  }
}
