import { getMapAppType } from '@breezy/shared'
import { faApple, faGoogle, faWaze } from '@fortawesome/free-brands-svg-icons'
import { faMap } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select, message } from 'antd'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import {
  GQL_USER_SETTINGS_QUERY,
  GQL_USER_SETTINGS_SET_MAP_PREFERENCE,
} from './MapAppPreferencePicker.gql'

export const MapAppPreferencePicker = m(() => {
  const userGuid = useExpectedUserGuid()
  const companyGuid = useExpectedCompanyGuid()

  const query = useQuery({
    query: GQL_USER_SETTINGS_QUERY,
    variables: { companyGuid, userGuid },
  })

  const [, set] = useMutation(GQL_USER_SETTINGS_SET_MAP_PREFERENCE)

  const onChange = useCallback(
    async (value: string | undefined) => {
      const mapAppType = getMapAppType(value)
      if (mapAppType) {
        await set({
          obj: {
            userGuid,
            companyGuid,
            updatedAt: new Date().toISOString(),
            preferredMapApp: mapAppType,
          },
        })
        message.success('Successfully updated Map App preference')
      }
    },
    [set, companyGuid, userGuid],
  )

  return (
    <GqlQueryLoader
      query={query}
      render={data => {
        const preferredMapApp =
          data.userSettings.length > 0
            ? getMapAppType(data.userSettings[0].preferredMapApp)
            : undefined
        return (
          <div className="flex w-[260px] flex-col">
            <p className="grey10 text-lg font-bold">
              <FontAwesomeIcon icon={faMap} className="mr-2" />
              Preferred Map App
            </p>
            <Select
              allowClear={false}
              value={preferredMapApp}
              onChange={(value: string) => onChange(value)}
              className="grey10 mb-6 w-full min-w-[200px] text-center font-semibold"
            >
              <Select.Option key={'google'} value={'google'}>
                <div className="grey9 text-md flex w-full flex-row items-center font-semibold">
                  <FontAwesomeIcon icon={faGoogle} className="mr-2" />
                  Google Maps
                </div>
              </Select.Option>
              <Select.Option key={'apple'} value={'apple'}>
                <div className="grey9 text-md flex w-full flex-row items-center font-semibold">
                  <FontAwesomeIcon icon={faApple} className="mr-2" />
                  Apple Maps
                </div>
              </Select.Option>
              <Select.Option key={'waze'} value={'waze'}>
                <div className="grey9 text-md flex w-full flex-row items-center font-semibold">
                  <FontAwesomeIcon icon={faWaze} className="mr-2" />
                  Waze
                </div>
              </Select.Option>
            </Select>
          </div>
        )
      }}
    />
  )
})
