import { PageHeader } from '@ant-design/pro-components'
import {
  MaintenancePlanPaymentFlow,
  MaintenancePlanPaymentViewModel,
  RenewalTypeOption,
  getRenewalTypeOptionFromBillingProfiles,
  noOp,
} from '@breezy/shared'
import { Button } from 'antd'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import PaymentAsyncStatusCard from '../../components/Payments/PaymentAsyncStatusCard'
import PaymentCardPanel from '../../components/Payments/PaymentCardPanel'
import { PaymentAsyncUiStatus } from '../../components/Payments/PaymentTypes'
import RequiresMerchantId from '../../components/Payments/RequiresMerchantId'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useGetCompanyBillingProfile } from '../../hooks/useGetCompanyBillingProfile'
import { useAppContext } from '../../providers/AppContextWrapper'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import { FreeMaintenancePlanActivationView } from './FreeMaintenancePlanActivationView'
import { MaintenancePlanPaymentView } from './MaintenancePlanPaymentView'
import { useMaintenancePlanInvoiceGuid } from './useMaintenancePlanInvoiceGuid'

export type MaintenancePlanPaymentViewProps = {
  readonly selectedPaymentMethod?: string
  readonly setSelectedPaymentMethod: (paymentMethod?: string) => void
  readonly suppressActivationEmailRef: MutableRefObject<boolean>
  readonly maintenancePlan: MaintenancePlanPaymentViewModel
  readonly renewalTypeOption: RenewalTypeOption
  readonly mode: 'activate' | 'update'
}

const overrideTitlesActivate: Record<PaymentAsyncUiStatus, string> = {
  [PaymentAsyncUiStatus.IDLE]: '',
  [PaymentAsyncUiStatus.PREPARING]: 'Preparing Payment Method...',
  [PaymentAsyncUiStatus.VERIFYING]: 'Verifying Payment Method...',
  [PaymentAsyncUiStatus.SUBMITTING]: 'Activating Subscription (Please Wait)...',
  [PaymentAsyncUiStatus.RECORDING]: 'Recording Subscription...',
  [PaymentAsyncUiStatus.SUCCEEDED]: '',
}

const overrideTitlesUpdate: Record<PaymentAsyncUiStatus, string> = {
  ...overrideTitlesActivate,
  [PaymentAsyncUiStatus.SUBMITTING]: 'Updating Subscription (Please Wait)...',
}

const ActivateAsNonRecurringButton = m(
  ({ maintenancePlanGuid }: { maintenancePlanGuid: string }) => {
    const mut =
      trpc.maintenancePlans[
        'maintenance-plans:create-one-time-payment-invoice'
      ].useMutation()

    const appNav = useAppNavigation()

    const onClick = useCallback(async () => {
      const r = await mut.mutateAsync({ maintenancePlanGuid })

      appNav.navigateToInvoiceOverviewPage(r.invoiceGuid)
    }, [appNav, mut, maintenancePlanGuid])

    return (
      <div className="mx-3 flex w-[320px] flex-col justify-center">
        <Button type="primary" onClick={onClick} className="h-[64px] text-wrap">
          Activate One-Year Plan with a One Time Payment
        </Button>
      </div>
    )
  },
)

const GoToOneShotPaymentInvoiceView = m(
  ({ maintenancePlanGuid }: { maintenancePlanGuid: string }) => {
    const appNav = useAppNavigation()
    const invoiceGuid = useMaintenancePlanInvoiceGuid(maintenancePlanGuid)
    const onClick = useCallback(() => {
      if (invoiceGuid) {
        appNav.navigateToInvoiceOverviewPage(invoiceGuid)
      }
    }, [appNav, invoiceGuid])

    return invoiceGuid ? (
      <Button onClick={onClick} type="primary">
        Go To Invoice
      </Button>
    ) : (
      <div className="w-[320px]">
        <LoadingSpinner />
      </div>
    )
  },
)

const MaintenancePlanPaymentAsyncStatusCard = m(
  (props: MaintenancePlanPaymentViewProps) => {
    const [status, setStatus] = useState(PaymentAsyncUiStatus.IDLE)
    const appNav = useAppNavigation()
    const isSinglePaymentFeatureEnabled = useFeatureFlag(
      'maintenancePlanSinglePayment',
    )

    const onSuccess = useCallback(() => {
      appNav.navigateToMaintenancePlanDetailsPage(
        props.maintenancePlan.maintenancePlanGuid,
      )
    }, [appNav, props.maintenancePlan])

    const containerClassName = useMemo(
      () =>
        status === PaymentAsyncUiStatus.IDLE ? 'visible' : 'invisible h-[0px]',
      [status],
    )

    const activationType = useMemo(() => {
      if (props.maintenancePlan.paymentSubscription && props.mode === 'update')
        return 'recurring'
      if (props.maintenancePlan.isFreePlan) return 'free'
      if (
        props.maintenancePlan.paymentFlow ===
          MaintenancePlanPaymentFlow.MANUAL &&
        !!props.maintenancePlan.terminatesAt
      )
        return 'one-time'
      return 'recurring'
    }, [props.maintenancePlan, props.mode])

    return (
      <div className="overflow-y-hidden">
        {(!props.maintenancePlan.paymentSubscriptionGuid ||
          props.mode === 'update') && (
          <>
            <PaymentAsyncStatusCard
              status={status}
              overrideTitles={
                props.mode === 'activate'
                  ? overrideTitlesActivate
                  : overrideTitlesUpdate
              }
            />
            <div className={containerClassName}>
              <>
                {activationType === 'free' && (
                  <FreeMaintenancePlanActivationView
                    maintenancePlan={props.maintenancePlan}
                    onSuccessfulActivation={onSuccess}
                    renewalTypeOption={props.renewalTypeOption}
                    setPaymentAsyncUiStatus={setStatus}
                  />
                )}
                {activationType === 'recurring' && (
                  <>
                    <MaintenancePlanPaymentView
                      {...props}
                      setPaymentAsyncUiStatus={setStatus}
                      onSuccess={onSuccess}
                    />
                    {isSinglePaymentFeatureEnabled &&
                      !props.maintenancePlan.paymentSubscriptionGuid &&
                      !props.maintenancePlan.terminatesAt && (
                        <>
                          <div className="mx-3 flex w-[320px] flex-col justify-center">
                            <p className="grey9 mb-3 w-full text-center text-[24px]">
                              {' '}
                              - OR -{' '}
                            </p>
                          </div>
                          <ActivateAsNonRecurringButton
                            maintenancePlanGuid={
                              props.maintenancePlan.maintenancePlanGuid
                            }
                          />
                        </>
                      )}
                  </>
                )}
                {activationType === 'one-time' && (
                  <div className="mx-3 flex w-[320px] flex-col justify-center">
                    <h3 className="w-full text-center">One-Time Payment</h3>
                    <GoToOneShotPaymentInvoiceView
                      maintenancePlanGuid={
                        props.maintenancePlan.maintenancePlanGuid
                      }
                    />
                  </div>
                )}
              </>
            </div>
          </>
        )}
        {props.mode === 'activate' &&
          props.maintenancePlan.paymentSubscriptionGuid && (
            <PaymentCardPanel>
              <h3 className="grey9 t-[24px]">Subscription Already Active</h3>
              <div className="grey7 mb-2">
                {props.maintenancePlan.defaultBillingInfo.payeeDisplayName}
              </div>
              <div className="grey7 break-words">
                Maintenance Plan: {props.maintenancePlan.maintenancePlanGuid}
              </div>
            </PaymentCardPanel>
          )}
        {props.mode === 'update' &&
          !props.maintenancePlan.paymentSubscription && (
            <PaymentCardPanel>
              <h3 className="grey9 t-[24px]">
                Unable to Update Maintenance Plan Payment Method
              </h3>
              <div className="grey7 mb-2">
                Contact Breezy Development Team for a Fix
              </div>
              <div className="grey7 break-words">
                Maintenance Plan: {props.maintenancePlan.maintenancePlanGuid}
              </div>
            </PaymentCardPanel>
          )}
      </div>
    )
  },
)

const scrollToTop = () => window.scrollTo(0, 0)
const paymentMethodParam = 'paymentMethod' as const

const MaintenancePlanPaymentPageLoader = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const searchSelectedPaymentMethod =
    useSearchParam(paymentMethodParam) ?? undefined
  const maintenancePlanGuid = useParams().maintenancePlanGuid
  const mode = useSearchParam('updatePaymentMethod') ? 'update' : 'activate'
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedPaymentMethod, setSelectedPaymentMethodState] = useState<
    string | undefined
  >(searchSelectedPaymentMethod)
  const suppressActivationEmailRef = useRef(false)

  // NOTE: For Mobile Small Screen Usability
  useEffect(() => {
    scrollToTop()
  }, [])

  const setSelectedPaymentMethod = useCallback(
    (paymentMethod?: string) => {
      const newSearchParams = new URLSearchParams(searchParams)
      if (!paymentMethod) newSearchParams.delete(paymentMethodParam)
      else newSearchParams.set(paymentMethodParam, paymentMethod ?? '')
      setSearchParams(newSearchParams)
      setSelectedPaymentMethodState(paymentMethod)
    },
    [searchParams, setSearchParams, setSelectedPaymentMethodState],
  )

  const query = trpc.maintenancePlans['maintenance-plans:get-payable'].useQuery(
    { maintenancePlanGuid: maintenancePlanGuid ?? '' },
    {
      enabled: !!maintenancePlanGuid,
      onError: noOp,
    },
  )

  const { getCompanyBillingProfileQuery } =
    useGetCompanyBillingProfile(companyGuid)

  return (
    <RequiresMerchantId>
      <Page requiresCompanyUser className="p-0">
        {!maintenancePlanGuid && <h3>Maintenance Plan Missing</h3>}
        {maintenancePlanGuid && (
          <TrpcQueryLoader
            query={query}
            errorComponent={
              <PaymentCardPanel>
                <h2>Unable to load Maintenance Plan '{maintenancePlanGuid}'</h2>
                <div>Error: {query.error?.message}</div>
              </PaymentCardPanel>
            }
            render={mpData => (
              <GqlQueryLoader
                query={getCompanyBillingProfileQuery}
                render={billingProfilesData => (
                  <MaintenancePlanPaymentAsyncStatusCard
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    suppressActivationEmailRef={suppressActivationEmailRef}
                    maintenancePlan={mpData}
                    mode={mode}
                    renewalTypeOption={getRenewalTypeOptionFromBillingProfiles(
                      billingProfilesData.billingProfiles,
                    )}
                  />
                )}
              />
            )}
          />
        )}
      </Page>
    </RequiresMerchantId>
  )
})

const OfficeMaintenancePlanPayPage = () => {
  return (
    <Page requiresCompanyUser>
      <PageHeader title="Maintenance Plan - Payment Method" />
      <MaintenancePlanPaymentPageLoader />
    </Page>
  )
}

const AppPagePicker = m(() => {
  const appType = useAppContext().appType

  if (appType === 'office') return <OfficeMaintenancePlanPayPage />
  return <MaintenancePlanPaymentPageLoader />
})

export default AppPagePicker
