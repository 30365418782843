import { Button } from 'antd'

import { faPlus as faPlusThick } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React from 'react'

type CreateAccountReminderButtonProps = {
  type: 'primary' | 'text'
  className: string
  onClick: () => void
  loading?: boolean
  disabled?: boolean
}

const CreateAccountReminderButton =
  React.memo<CreateAccountReminderButtonProps>(
    ({ type, className, onClick, loading = false, disabled = false }) => (
      <Button
        type={type}
        htmlType="button"
        className={cn('flex flex-row items-center text-xs', className)}
        onClick={onClick}
        loading={loading}
        disabled={disabled || loading}
      >
        <div className="flex flex-row items-center gap-2">
          <FontAwesomeIcon icon={faPlusThick} />
          <span className="font-bold">Create Reminder</span>
        </div>
      </Button>
    ),
  )

export default CreateAccountReminderButton
