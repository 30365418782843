import {
  BzDateTime,
  MaintenancePlanDetailsViewModel,
  MaintenancePlanPaymentFlow,
  MaintenancePlanPaymentInterval,
  TimeZoneId,
  futureCloneVisits,
} from '@breezy/shared'
import { ConfigureMaintenancePlanFormData } from './configureMaintenancePlanFormSchema'
import { zeroTaxRate } from './MaintenancePlanV3Utils'

const preferredPaymentInterval = MaintenancePlanPaymentInterval.YEARLY

const createDefaultValuesForNewFromScratch = (
  tzId: TimeZoneId,
  isImport: boolean,
): Partial<ConfigureMaintenancePlanFormData> => {
  const activationDate = BzDateTime.now(tzId).toLocalDateString()

  return {
    activationDate,
    shouldSendActivationEmail: !isImport,
    isImportedPlan: isImport,
    isAutoRenewing: !isImport,

    billingAnchorDay: BzDateTime.now(tzId)
      .toJsJodaLocalDate()
      .dayOfMonth()
      .toString(),
    billingAnchorDate: activationDate,
    billingPaymentInterval: isImport
      ? MaintenancePlanPaymentInterval.YEARLY
      : preferredPaymentInterval,

    visits: [],
    // NOTE: This gets replaced by useFormLogicWatcher, but this prevents issues during the lookup
    taxRate: zeroTaxRate,
  }
}

const createDefaultValuesForNewFromExisting = (
  tzId: TimeZoneId,
  mp: MaintenancePlanDetailsViewModel,
): Partial<ConfigureMaintenancePlanFormData> => {
  const activationDate = BzDateTime.now(tzId).toLocalDateString()
  return {
    activationDate,
    shouldSendActivationEmail: true,
    isFreePlan: false,
    isAutoRenewing: true,

    billingAnchorDay: BzDateTime.now(tzId)
      .toJsJodaLocalDate()
      .dayOfMonth()
      .toString(),
    billingAnchorDate: activationDate,
    billingPaymentInterval: preferredPaymentInterval,

    visits: futureCloneVisits(mp.visits, activationDate, tzId),

    // NOTE: This gets replaced by useFormLogicWatcher, but this prevents issues during the lookup
    taxRate: zeroTaxRate,
  }
}

const createDefaultValuesForRenewExisting = (
  tzId: TimeZoneId,
  mp: MaintenancePlanDetailsViewModel,
): Partial<ConfigureMaintenancePlanFormData> => {
  const activationDate = BzDateTime.now(tzId).toLocalDateString()
  return {
    activationDate,
    shouldSendActivationEmail: true,
    isFreePlan: mp.isFreePlan,
    isAutoRenewing: mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO,

    // NOTE: If they are manually renewing, then these next 2 values likely aren't
    // used, and these are the best if they switch to Auto-Renewing
    billingAnchorDay: BzDateTime.now(tzId)
      .toJsJodaLocalDate()
      .dayOfMonth()
      .toString(),
    billingAnchorDate: activationDate,

    billingPaymentInterval:
      mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO
        ? preferredPaymentInterval
        : mp.paymentInterval ?? MaintenancePlanPaymentInterval.YEARLY,

    visits: futureCloneVisits(mp.visits, activationDate, tzId),

    // NOTE: This gets replaced by useFormLogicWatcher, but this prevents issues during the lookup
    taxRate: zeroTaxRate,
  }
}

type CreateMaintenancePlanFormParams =
  | {
      tzId: TimeZoneId
      mode: 'new-from-scratch'
      isImport: boolean
      mp?: never
    }
  | {
      tzId: TimeZoneId
      mode: 'new-from-existing' | 'renew-existing'
      isImport: boolean
      mp: MaintenancePlanDetailsViewModel
    }

export const createDefaultValuesForCreateMaintenancePlanForm = ({
  tzId,
  mode,
  isImport,
  mp,
}: CreateMaintenancePlanFormParams): Partial<ConfigureMaintenancePlanFormData> => {
  if (mode === 'new-from-existing')
    return createDefaultValuesForNewFromExisting(tzId, mp)
  if (mode === 'renew-existing')
    return createDefaultValuesForRenewExisting(tzId, mp)

  return createDefaultValuesForNewFromScratch(tzId, isImport)
}
