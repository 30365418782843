import { Modal, Radio, Space } from 'antd'
import React, { useCallback, useState } from 'react'

export type RecurringChangeType =
  | 'THIS_EVENT_ONLY'
  | 'THIS_AND_FUTURE'
  | 'ALL_EVENTS'

type RecurringChangeFormProps = {
  changeType: RecurringChangeType
  setChangeType: (changeType: RecurringChangeType) => void
}

export const RecurringChangeForm = React.memo<RecurringChangeFormProps>(
  ({ changeType, setChangeType }) => (
    <Radio.Group
      value={changeType}
      onChange={e => setChangeType(e.target.value)}
    >
      <Space direction="vertical">
        <Radio value="THIS_EVENT_ONLY">This event only</Radio>
        <Radio value="THIS_AND_FUTURE">This and following events</Radio>
        <Radio value="ALL_EVENTS">All events</Radio>
      </Space>
    </Radio.Group>
  ),
)

type RecurringChangeModalProps = {
  onCancel: () => void
  onOk: (changeType: RecurringChangeType) => void
}

export const RecurringChangeModal = React.memo<RecurringChangeModalProps>(
  ({ onCancel, onOk }) => {
    const [changeType, setChangeType] =
      useState<RecurringChangeType>('THIS_EVENT_ONLY')

    const ourOnOk = useCallback(() => onOk(changeType), [onOk, changeType])

    return (
      <Modal
        open
        closable
        width={320}
        onCancel={onCancel}
        onOk={ourOnOk}
        title="Change recurring event"
      >
        <RecurringChangeForm
          changeType={changeType}
          setChangeType={setChangeType}
        />
      </Modal>
    )
  },
)
