import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  EquipmentType,
  Guid,
  InstalledEquipment,
  InstalledEquipmentSummary,
  formatMaybeEquipmentType,
  toTitleCase,
} from '@breezy/shared'
import { Button, Popover } from 'antd'
import { memo } from 'react'
import { SmallCard } from '../../../elements/Card/SmallCard'
import { KeyValueRow } from './KeyValueRow'

type Props = {
  installedEquipment: InstalledEquipmentSummary
  leftAlign?: boolean
  onRemove?: (installedEquipmentGuid: Guid) => void
  dataTestId?: string
}

export const InstalledEquipmentTitleAndContent = ({
  installedEquipment,
  leftAlign,
}: Props) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="mb-[6px] text-[16px] font-semibold leading-[24px]">
        {InstalledEquipment.calculateFriendlyName(installedEquipment)}
      </div>
      <InstalledEquipmentCardContent
        installedEquipment={installedEquipment}
        leftAlign={leftAlign}
      />
    </div>
  )
}

export const InstalledEquipmentCardContent = ({
  installedEquipment,
  leftAlign,
}: Props) => {
  return (
    <>
      <KeyValueRow
        label="Model #"
        value={installedEquipment.modelNumber}
        leftAlign={leftAlign}
      />
      <KeyValueRow
        label="Serial #"
        value={installedEquipment.serialNumber}
        leftAlign={leftAlign}
      />
      <KeyValueRow
        label="Est. Install Date"
        leftAlign={leftAlign}
        value={
          installedEquipment.installationDate
            ? installedEquipment.installationDate.format(
                DateTimeFormatter.ofPattern('yyyy').withLocale(ENGLISH_LOCALE),
              )
            : undefined
        }
      />
      <KeyValueRow
        label="Est. End of Life"
        leftAlign={leftAlign}
        value={
          installedEquipment.estimatedEndOfLifeDate
            ? installedEquipment.estimatedEndOfLifeDate.format(
                DateTimeFormatter.ofPattern('yyyy').withLocale(ENGLISH_LOCALE),
              )
            : undefined
        }
      />
      <KeyValueRow
        label="Installation Party"
        leftAlign={leftAlign}
        value={
          installedEquipment.installationParty
            ? toTitleCase(installedEquipment.installationParty)
            : undefined
        }
      />
    </>
  )
}

export const InstalledEquipmentCard = memo<Props>(
  ({ installedEquipment, onRemove, dataTestId }) => {
    const header = (
      <div className="flex items-center justify-between">
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
          {formatMaybeEquipmentType(
            installedEquipment.equipmentType as EquipmentType,
          )}
        </div>
      </div>
    )

    return (
      <SmallCard
        header={header}
        headClassName="pb-1.5 min-h-[22px]"
        cardClassName="w-full"
        dataTestId={dataTestId}
      >
        <div className="pt-2">
          <InstalledEquipmentCardContent
            installedEquipment={installedEquipment}
          />
          <div className="mt-2 flex items-center justify-between">
            {installedEquipment.description ? (
              <Popover
                placement="topLeft"
                content={
                  <div className="max-w-[320px]">
                    {installedEquipment.description}
                  </div>
                }
              >
                <div className="cursor-pointer text-[#1777ff]">Description</div>
              </Popover>
            ) : (
              <div className="text-[#bfbfbf]">Description</div>
            )}
            {onRemove && (
              <Button
                type="link"
                danger
                onClick={() =>
                  onRemove(installedEquipment.installedEquipmentGuid)
                }
                className="px-0"
              >
                Remove
              </Button>
            )}
          </div>
        </div>
      </SmallCard>
    )
  },
)
