import { PhotoLinks, PhotoRecord, isNullish, noOp } from '@breezy/shared'
import { Drawer } from 'antd'
import { useIntercom } from '../../hooks/useIntercom'
import { AsyncPhotoUpload, AsyncTakePhoto } from './AsyncPhotoUpload'
import { useSynchronousUpload } from './Upload'

export type PhotoUploadDrawerProps = {
  photoLinks?: PhotoLinks
  onCancel: () => void
  onMutate: (photoRecord: PhotoRecord) => void
  onLoadingChange?: (loading: boolean) => void
  uploadButton?: React.ReactNode
}

const PhotoUploadDrawer = ({
  photoLinks,
  onCancel = noOp,
  onMutate = noOp,
}: Partial<PhotoUploadDrawerProps>) => {
  useIntercom({ isLauncherVisible: isNullish(photoLinks) })

  const onPhotoUploadChange = useSynchronousUpload(onMutate)

  return (
    <Drawer
      title="Add Photo"
      placement={'bottom'}
      closable={true}
      onClose={onCancel}
      height={240}
      open={!!photoLinks}
    >
      {photoLinks && (
        <div className="space-y-3 px-4">
          <AsyncPhotoUpload
            links={photoLinks}
            onPhotoUploadChange={onPhotoUploadChange}
          />
          <AsyncTakePhoto
            links={photoLinks}
            onPhotoUploadChange={onPhotoUploadChange}
          />
        </div>
      )}
    </Drawer>
  )
}

export default PhotoUploadDrawer
