export const DYNAMIC_PRICING_TYPES = ['AFTER_HOURS', 'WEEKENDS', 'HOLIDAYS'] as const

export type DynamicPricingType = (typeof DYNAMIC_PRICING_TYPES)[number]

type DynamicPricingInfo = {
  key: DynamicPricingType
  name: string
  multiplier: number
}

export const DYNAMIC_PRICING_INFO = [
  {
    key: 'AFTER_HOURS',
    name: 'After Hours',
    multiplier: 1.25,
  },
  {
    key: 'WEEKENDS',
    name: 'Weekends',
    multiplier: 1.5,
  },
  {
    key: 'HOLIDAYS',
    name: 'Holidays',
    multiplier: 2,
  },
] satisfies DynamicPricingInfo[]

export const DEFAULT_DYNAMIC_PRICING_OPTION = DYNAMIC_PRICING_TYPES[0]

export const DYNAMIC_PRICING_INFO_MAP = DYNAMIC_PRICING_INFO.reduce((acc, info) => {
  acc[info.key] = info
  return acc
}, {} as Record<DynamicPricingType, DynamicPricingInfo>)

export const getDynamicPricingMultiplier = (type: DynamicPricingType) => DYNAMIC_PRICING_INFO_MAP[type].multiplier
