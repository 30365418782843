import {
  AccountJob,
  BzAddress,
  DateTimeFormatter,
  Guid,
  MatchToShape,
  richJobTypeDescriptor,
} from '@breezy/shared'
import { memo, useMemo, useState } from 'react'
import useAppNavigation from '../../../hooks/useAppNav'
import { JobIcon } from '../../../utils/feature-icons'
import { useQueryParamFlag } from '../../../utils/react-utils'
import { DateFormat, DateView } from '../../Dates'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import ProgressiveJobCreationModal from '../../ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import { TagList } from '../../Tags'

export type AccountJobsCollapsibleJob = MatchToShape<
  AccountJob,
  {
    jobGuid: true
    jobCreatedAt: true
    jobType: {
      name: true
    }
    displayId: true
    jobLifecycleStatus: {
      name: true
    }
    serviceLocation: {
      location: {
        address: {
          line1: true
          line2: true
          city: true
          stateAbbreviation: true
          zipCode: true
        }
      }
    }
    tags: true
  }
>

export type AccountJobsCollapsibleProps = {
  accountGuid: Guid
  jobs: AccountJobsCollapsibleJob[]
  canCreateNewJob?: boolean
}

export const AccountJobsCollapsible = memo<AccountJobsCollapsibleProps>(
  ({ accountGuid, jobs: unsortedJobs, canCreateNewJob = false }) => {
    const appNav = useAppNavigation()
    const shouldCreateJobQueryParam = useQueryParamFlag('shouldCreateJob')
    const [isCreatingNewJob, setIsCreatingNewJob] = useState(false)
    const jobs = useMemo(() => {
      // Doesn't mutate the internal jobs on the Account
      const jobs = [...unsortedJobs]
      jobs.sort((a, b) => {
        if (b.jobCreatedAt.isBefore(a.jobCreatedAt)) {
          return -1
        } else if (b.jobCreatedAt.isEqual(a.jobCreatedAt)) {
          return 0
        } else {
          return 1
        }
      })
      return jobs
    }, [unsortedJobs])

    return (
      <>
        <BzCollapsible
          title="Jobs"
          titleIcon={JobIcon}
          onPlus={canCreateNewJob ? () => setIsCreatingNewJob(true) : undefined}
        >
          {jobs.map((job, i) => {
            return (
              <div key={job.jobGuid} className={i !== 0 ? 'mt-2' : ''}>
                <CollapsibleItem
                  key={job.jobGuid}
                  title={richJobTypeDescriptor(job.jobType.name)}
                  displayId={job.displayId}
                  onTitleClick={() =>
                    appNav.navigateToJobDetailsPage(job.jobGuid)
                  }
                  defaultOpen={i < 3}
                  contentList={[
                    {
                      key: 'Status:',
                      value: job.jobLifecycleStatus.name,
                    },
                    {
                      key: 'Created on:',
                      value: (
                        <DateView
                          format={DateFormat['MMM d, yyyy']}
                          isoWithOffsetTimestamp={job.jobCreatedAt.format(
                            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
                          )}
                          zone={{
                            type: 'company-timezone-of-principal-user',
                          }}
                        />
                      ),
                    },
                    {
                      key: 'Address:',
                      value: BzAddress.create(
                        job.serviceLocation.location.address,
                      ).streetAddressLine1And2Condensed(),
                    },
                    {
                      key: 'Tags',
                      value: (
                        <TagList
                          tags={job.tags ?? []}
                          numTagsDisplayed={3}
                          truncateLength={24}
                          spacingY={2}
                        />
                      ),
                      hideKey: true,
                    },
                  ]}
                />
              </div>
            )
          })}
        </BzCollapsible>
        {isCreatingNewJob && (
          <ProgressiveJobCreationModal
            isOpen={isCreatingNewJob || shouldCreateJobQueryParam}
            setIsOpen={setIsCreatingNewJob}
            selectedAccountGuid={accountGuid}
          />
        )}
      </>
    )
  },
)
