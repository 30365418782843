import { CompanyLeadSource, fuzzyMatch } from '@breezy/shared'
import { AutoComplete, Form } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useIsSmallScreen } from '../../hooks/useIsMobile'

type LeadSourceAttributionDescriptionFormItemProps = {
  leadSource?: CompanyLeadSource
  className?: string
  defaultValue?: string
}

const LeadSourceAttributionDescriptionFormItem =
  React.memo<LeadSourceAttributionDescriptionFormItemProps>(
    ({ leadSource, defaultValue, className }) => {
      const isSmallScreen = useIsSmallScreen()

      return leadSource ? (
        <div className={classNames('flex', className)}>
          <div className="w-full">
            <Form.Item
              name="leadSourceAttributionDescription"
              label={leadSource.attributionPrompt}
              rules={[
                {
                  required: true,
                  message: 'Required Field',
                },
              ]}
              className="mb-1"
            >
              <AutoComplete
                size={isSmallScreen ? 'large' : 'middle'}
                defaultValue={defaultValue}
                placeholder={
                  leadSource.historicalAttributionDescriptions &&
                  leadSource.historicalAttributionDescriptions.length > 0
                    ? `Search or Add new ${leadSource.name}`
                    : `Add new ${leadSource.name}`
                }
                options={(
                  leadSource.historicalAttributionDescriptions ?? []
                ).map(opt => ({ label: opt, value: opt }))}
                filterOption={(inputValue, option) => {
                  return option?.value
                    ? fuzzyMatch(option?.value, inputValue)
                    : false
                }}
              />
            </Form.Item>
          </div>
        </div>
      ) : null
    },
  )

export default LeadSourceAttributionDescriptionFormItem
