import { Guid, bzExpect, guidSchema } from '@breezy/shared'
import { useParams } from 'react-router-dom'

export const useParamGuid = (paramName: string): [Guid | undefined, string] => {
  const guid = bzExpect(
    useParams()[paramName],
    paramName,
    `${paramName} is required`,
  )
  const isValidGuid = guidSchema.safeParse(guid).success
  return [isValidGuid ? guid : undefined, guid]
}
