import {
  AppointmentType,
  AssignedApptViewModel,
  AssignmentWithTechnicianNameDTO,
  EquipmentType,
  InstallProjectType,
  JobGuid,
  JobToEquipmentRelationshipType,
  LocationPropertyType,
  bzExpect,
  calculateInferredAppointmentStatus,
  castContactEnumTypes,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { FetchAppointmentViewModelQuery } from '../../query'
import { mapGqlLocationMaintenancePlansToMinimals } from '../maintenance-plans/MaintenancePlanConversions'

type FetchJobAppointment = FetchAppointmentViewModelQuery['jobAppointments'][number]

export const toAssignedAppointmentViewModel = (jobAppointment: FetchJobAppointment): AssignedApptViewModel => {
  const allAssignments: AssignmentWithTechnicianNameDTO[] = jobAppointment.assignments.map(assignment => {
    return {
      assignmentGuid: assignment.jobAppointmentAssignmentGuid,
      technicianFirstName: assignment.technician.firstName,
      technicianLastName: assignment.technician.lastName,
      assignmentStatus: bzExpect(assignment.assignmentStatus?.jobAppointmentAssignmentStatusType),

      technicianUserGuid: assignment.technicianUserGuid,
      timeWindow: {
        start: assignment.assignmentStart,
        end: assignment.assignmentEnd,
      },
    }
  })

  const assignedApptViewModel: AssignedApptViewModel = {
    jobGuid: jobAppointment.job.jobGuid as JobGuid,
    displayId: jobAppointment.job.displayId,
    locationGuid: jobAppointment.job.location.locationGuid,
    jobType: jobAppointment.job.jobType,
    installProjectType: jobAppointment.job.installProjectType as InstallProjectType | undefined,
    summary: jobAppointment.job.summary,
    accountGuid: jobAppointment.job.accountGuid,
    pointOfContact: castContactEnumTypes(jobAppointment.job.pointOfContact),
    location: {
      locationGuid: jobAppointment.job.location.locationGuid,
      companyGuid: jobAppointment.job.location.companyGuid,
      displayName: jobAppointment.job.location.displayName,
      address: {
        addressGuid: jobAppointment.job.location.address.addressGuid,
        line1: jobAppointment.job.location.address.line1,
        line2: jobAppointment.job.location.address.line2 ?? undefined,
        city: jobAppointment.job.location.address.city,
        stateAbbreviation: jobAppointment.job.location.address.stateAbbreviation,
        zipCode: jobAppointment.job.location.address.zipCode,
        geoLocation: jobAppointment.job.location.address.geoLocation,
      },
      estimatedSquareFootage: jobAppointment.job.location.estimatedSquareFootage,
      estimatedBuildDate: jobAppointment.job.location.estimatedBuildDate,
      propertyType: jobAppointment.job.location.propertyType as LocationPropertyType,
      municipality: jobAppointment.job.location.municipality,
    },
    equipmentTypeJobLinks: jobAppointment.job.equipmentTypeJobLinks.map(et => ({
      equipmentType: et.equipmentType as EquipmentType,
      relationshipType: et.relationshipType as JobToEquipmentRelationshipType,
      estimatedEquipmentAge: et.estimatedEquipmentAge,
    })),
    appointment: {
      guid: jobAppointment.jobAppointmentGuid,
      referenceNumber: jobAppointment.appointmentReferenceNumber,
      appointmentStatus: calculateInferredAppointmentStatus(
        jobAppointment.cancellationStatus?.canceled ?? false,
        allAssignments,
      ),
      appointmentType: jobAppointment.appointmentType as AppointmentType,
      description: jobAppointment.description,
      appointmentConfirmed: jobAppointment.confirmationStatus?.confirmed ?? false,
      appointmentCanceled: jobAppointment.cancellationStatus?.canceled ?? false,
      timeWindow: {
        start: jobAppointment.appointmentWindowStart,
        end: jobAppointment.appointmentWindowEnd,
      },
      endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(jobAppointment.endOfAppointmentNextSteps),
    },
    assignments: allAssignments,
    installedEquipment: jobAppointment.job.location.installedEquipment.map(installedEquipment => {
      return {
        installedEquipmentGuid: installedEquipment.installedEquipmentGuid,
        equipmentType: installedEquipment.equipmentType,
        installationDate: installedEquipment.installationDate,
        installationParty: installedEquipment.installationParty,
        estimatedEndOfLifeDate: installedEquipment.estimatedEndOfLifeDate,
        averageLifeExpectancyYears: installedEquipment.averageLifeExpectancyYears,
        manufacturer: installedEquipment.manufacturer,
        modelNumber: installedEquipment.modelNumber,
        serialNumber: installedEquipment.serialNumber,
        manufacturerWarrantyStartDate: installedEquipment.manufacturerWarrantyStartDate,
        manufacturerWarrantyEndDate: installedEquipment.manufacturerWarrantyEndDate,
        manufacturerWarrantyTerms: installedEquipment.manufacturerWarrantyTerms,
        operationalStatus: installedEquipment.operationalStatus,
        laborWarrantyStartDate: installedEquipment.laborWarrantyStartDate,
        laborWarrantyEndDate: installedEquipment.laborWarrantyEndDate,
        laborWarrantyTerms: installedEquipment.laborWarrantyTerms,
        locationGuid: installedEquipment.locationGuid,
        description: installedEquipment.description,
      }
    }),
    installedHvacSystems: jobAppointment.job.location.installedHvacSystems.map(installedHvacSystem => {
      return {
        installedHvacSystemGuid: installedHvacSystem.installedHvacSystemGuid,
        locationGuid: installedHvacSystem.locationGuid,
        filterSize: installedHvacSystem.filterSize,
        friendlyName: installedHvacSystem.friendlyName,
        notes: installedHvacSystem.notes,
        zoningInfo: installedHvacSystem.zoningInfoJson,
        installedEquipment: installedHvacSystem.installedEquipment.map(installedEquipment => {
          return {
            installedEquipmentGuid: installedEquipment.installedEquipmentGuid,
            equipmentType: installedEquipment.equipmentType,
            installationDate: installedEquipment.installationDate,
            installationParty: installedEquipment.installationParty,
            estimatedEndOfLifeDate: installedEquipment.estimatedEndOfLifeDate,
            averageLifeExpectancyYears: installedEquipment.averageLifeExpectancyYears,
            manufacturer: installedEquipment.manufacturer,
            modelNumber: installedEquipment.modelNumber,
            serialNumber: installedEquipment.serialNumber,
            manufacturerWarrantyStartDate: installedEquipment.manufacturerWarrantyStartDate,
            manufacturerWarrantyEndDate: installedEquipment.manufacturerWarrantyEndDate,
            manufacturerWarrantyTerms: installedEquipment.manufacturerWarrantyTerms,
            operationalStatus: installedEquipment.operationalStatus,
            laborWarrantyStartDate: installedEquipment.laborWarrantyStartDate,
            laborWarrantyEndDate: installedEquipment.laborWarrantyEndDate,
            laborWarrantyTerms: installedEquipment.laborWarrantyTerms,
            locationGuid: installedEquipment.locationGuid,
            description: installedEquipment.description,
          }
        }),
      }
    }),
    maintenancePlans: mapGqlLocationMaintenancePlansToMinimals(jobAppointment.job.location.maintenancePlans),
  }

  return assignedApptViewModel
}
