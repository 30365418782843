import { BzDateTime, UTC_TIME_ZONE, formatUsc, isNullish } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import EmptyOutcomeCard from './EmptyOutcomeCard'
import OutcomeCard from './OutcomeCard'
import { useJobSoldMaintenancePlan } from './hooks/useJobSoldMaintenancePlan'

type SoldMaintenancePlanPanelProps = {
  soldMaintenancePlan?: ReturnType<typeof useJobSoldMaintenancePlan>
}

const SoldMaintenancePlanPanel = memo<SoldMaintenancePlanPanelProps>(
  ({ soldMaintenancePlan }) => {
    const invoiceDisplayId = useMemo(() => {
      return soldMaintenancePlan?.invoiceDisplayId
    }, [soldMaintenancePlan?.invoiceDisplayId])

    return (
      <>
        <h3>New Maintenance Plan</h3>
        {isNullish(soldMaintenancePlan) && (
          <Col className="w-1/2 pr-1.5">
            <EmptyOutcomeCard bodyText="No maintenance plan was sold during this job." />
          </Col>
        )}

        {!isNullish(soldMaintenancePlan) && (
          <Col className="w-1/2 pr-1.5">
            <OutcomeCard
              title={`${
                soldMaintenancePlan.maintenancePlanDefinition?.marketingInfo
                  ?.name ?? 'Maintenance Plan'
              }`}
            >
              {!isNullish(soldMaintenancePlan.activatedAt) && (
                <Row justify="space-between" className="mb-1">
                  <Col>Sold Date</Col>
                  <Col>
                    {BzDateTime.fromIsoString(
                      soldMaintenancePlan.activatedAt,
                      UTC_TIME_ZONE,
                    ).toHumanFriendlyMonthDayYear()}
                  </Col>
                </Row>
              )}

              <Row
                justify="space-between"
                className={invoiceDisplayId ? 'mb-1' : 'mb-0'}
              >
                <Col>Annual Revenue</Col>
                <Col>{formatUsc(soldMaintenancePlan.yearlyPriceUsc)}</Col>
              </Row>

              {invoiceDisplayId && (
                <Row justify="space-between" className="mb-0">
                  <Col>Invoice</Col>
                  <Col>{`#${invoiceDisplayId}`}</Col>
                </Row>
              )}
            </OutcomeCard>
          </Col>
        )}
      </>
    )
  },
)

export default SoldMaintenancePlanPanel
