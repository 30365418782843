import React from 'react'
import { InputWrapper } from '../../Input/Input'
type TilledInputProps = {
  label: string
  prefix?: React.ReactNode
  id: string
  invalid?: boolean
}

export const TilledInput = React.memo<TilledInputProps>(
  ({ label, prefix, id, invalid }) => {
    return (
      <InputWrapper label={label} invalid={invalid}>
        {prefix && (
          <div className="mr-3 text-xl text-bz-gray-500">{prefix}</div>
        )}
        <div id={id} className="h-full flex-1" />
      </InputWrapper>
    )
  },
)
