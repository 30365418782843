import {
  IsoDateString,
  TimeZoneId,
  getReminderDisplayDate,
  getReminderOverdueStatus,
} from '@breezy/shared'
import cn from 'classnames'
import React, { useMemo } from 'react'

type FormattedReminderDateProps = {
  date: IsoDateString
  timeZoneId: TimeZoneId
  className?: string
}

const FormattedReminderDate = React.memo<FormattedReminderDateProps>(
  ({ date, timeZoneId, className }) => {
    const displayDate = useMemo(
      () => getReminderDisplayDate(date, timeZoneId),
      [date, timeZoneId],
    )
    const isOverdue = useMemo(
      () => getReminderOverdueStatus(date, timeZoneId),
      [date, timeZoneId],
    )
    return (
      <div
        className={cn(
          'text-bz-green-800',
          {
            'text-bz-red-800': isOverdue,
          },
          className,
        )}
      >
        {displayDate}
      </div>
    )
  },
)

export default FormattedReminderDate
