import classNames from 'classnames'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { OnsiteModalContent } from '../../adam-components/OnsiteModal/OnsiteModal'
import SelectContactForm from '../../components/Invoicing/SelfServePayments/CollectPaymentPanel/SelectContactForm'
import {
  CONTACT_BY_GUID_QUERY,
  PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY,
} from '../../components/Invoicing/SelfServePayments/CollectPaymentPanel/SendInvoiceDrawer.gql'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { useFetchComprehensiveAccountDetails } from '../../hooks/fetch/useFetchComprehensiveAccountDetails'
import useIsMobile from '../../hooks/useIsMobile'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
type UseSelectContactFormProps = {
  accountGuid: string
  selectedContactGuid: string | undefined
  setSelectedContactGuid: (contactGuid: string) => void
  onBack?: () => void
  onClose: () => void
}

export const useSelectContactStep = ({
  accountGuid,
  selectedContactGuid,
  setSelectedContactGuid,
  onBack,
  onClose,
}: UseSelectContactFormProps) => {
  const companyGuid = useExpectedCompanyGuid()

  const isMobile = useIsMobile()
  const fetchComprehensiveAccountDetailsQuery =
    useFetchComprehensiveAccountDetails({ accountGuid })

  const [{ data: accountData, fetching: fetchingAccountData }] = useQuery({
    query: PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY,
    variables: { accountGuid },
  })

  const [{ data: contactData, fetching: fetchingContactData }] = useQuery({
    query: CONTACT_BY_GUID_QUERY,
    variables: {
      query: {
        contactGuid: { _eq: selectedContactGuid },
        companyGuid: { _eq: companyGuid },
      },
    },
    pause: !selectedContactGuid,
  })

  const isLoading = fetchingAccountData || fetchingContactData

  const contact = useMemo(
    () =>
      contactData?.contacts[0] ??
      accountData?.accountsByPk?.accountContacts?.[0].contact,
    [accountData, contactData],
  )

  const SelectContactStep = useMemo(
    () => (
      <OnsiteModalContent
        unpadBottom
        header="Select Contact"
        onClose={onClose}
        onBack={onBack}
      >
        <TrpcQueryLoader
          query={fetchComprehensiveAccountDetailsQuery}
          render={comprehensiveAccountDetails => (
            <SelectContactForm
              account={comprehensiveAccountDetails.toAccount()}
              refetchAccount={fetchComprehensiveAccountDetailsQuery.refetch}
              selectContact={(contactGuid: string) =>
                setSelectedContactGuid(contactGuid)
              }
              wrapperClassName="p-0"
              footerClassName={classNames(
                'mt-2 flex flex-row justify-end space-x-2',
                'border-0 border-t-4 border-solid border-t-bz-gray-400',
                isMobile
                  ? 'mx-[-1rem] px-4 pt-2 pb-4'
                  : 'mx-[-1.5rem] px-6 pt-4 pb-6',
              )}
              onCancel={onClose}
            />
          )}
        />
      </OnsiteModalContent>
    ),
    [
      fetchComprehensiveAccountDetailsQuery,
      isMobile,
      onBack,
      onClose,
      setSelectedContactGuid,
    ],
  )

  return {
    contact,
    isLoading,
    SelectContactStep,
  }
}
