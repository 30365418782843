import { useCallback } from 'react'
import {
  AchBillingDetails,
  AchDebitDetails,
  CardBillingDetails,
  TilledPaymentMethodRequest,
} from '../../../utils/tilledSdkTypes'

export const useGetAchDebitDetails = (getAccountHolderName: () => string) => {
  return useCallback(
    () => ({
      account_holder_name: getAccountHolderName(),
      account_type:
        (
          document
            .getElementById('bank-account-type-element')
            ?.querySelector("input[type='radio']:checked") as unknown as
            | {
                value: 'checking' | 'savings'
              }
            | undefined
        )?.value ?? 'checking',
    }),
    [getAccountHolderName],
  )
}

type CreateSanitizedTilledPaymentMethodRequestProps =
  | {
      type: 'ach_debit'
      billingDetails: AchBillingDetails
      getAchDebitDetails: () => AchDebitDetails
    }
  | {
      type: 'card'
      billingDetails: CardBillingDetails
    }

export const createSanitizedTilledPaymentMethodRequest = (
  props: CreateSanitizedTilledPaymentMethodRequestProps,
): TilledPaymentMethodRequest => {
  if (props.type === 'ach_debit') {
    const debitDetails = props.getAchDebitDetails()
    return {
      type: 'ach_debit',
      billing_details: props.billingDetails,
      ach_debit: {
        ...debitDetails,
        // Name can't be longer than 22 characters
        account_holder_name: debitDetails.account_holder_name.slice(0, 22),
      },
    }
  } else {
    return {
      type: 'card',
      billing_details: props.billingDetails,
    }
  }
}
