import {
  Guid,
  MatchToShape,
  RichCompanyLeadSource,
  formatName,
} from '@breezy/shared'
import React from 'react'
import { Link } from 'react-router-dom'
import { EmDash } from '../../elements/EmDash/EmDash'

export type RichCompanyLeadSourceViewLeadSource = MatchToShape<
  RichCompanyLeadSource,
  {
    leadSource: {
      name: true
    }
    referringContact: {
      firstName: true
      lastName: true
    }
    attributionDescription: true
  }
>

export type RichCompanyLeadSourceViewProps = {
  accountGuid: Guid
  leadSource: RichCompanyLeadSourceViewLeadSource
}
export const RichCompanyLeadSourceView =
  React.memo<RichCompanyLeadSourceViewProps>(({ accountGuid, leadSource }) => {
    return (
      <>
        <span>{leadSource.leadSource.name}</span>
        {leadSource.referringContact && (
          <span>
            {' '}
            <EmDash />{' '}
            {accountGuid ? (
              <Link to={`/accounts/${accountGuid}`}>
                {formatName(leadSource.referringContact)}
              </Link>
            ) : (
              formatName(leadSource.referringContact)
            )}
          </span>
        )}
        {leadSource.attributionDescription && (
          <span>
            {' '}
            <EmDash /> {leadSource.attributionDescription}
          </span>
        )}
      </>
    )
  })
