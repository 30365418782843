import { RichDiscountUsc } from '@breezy/shared'
import React from 'react'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { RichDiscountForm } from './RichDiscountForm'

type RichDiscountEditModalProps = {
  onSubmit: (item: RichDiscountUsc) => void
  onCancel: () => void
  item: RichDiscountUsc
  disableRate?: boolean
}

export const RichDiscountEditModal = React.memo<RichDiscountEditModalProps>(
  ({ onSubmit, onCancel, item, disableRate }) => (
    <OnsiteModal size="large" onClose={onCancel}>
      <RichDiscountForm
        disableRate={disableRate}
        onCancel={onCancel}
        onSave={onSubmit}
        defaultValues={item}
        title="Edit discount"
      />
    </OnsiteModal>
  ),
)
