import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useIsMobile from '../../hooks/useIsMobile'

export type OnsiteEmbeddedContextType = {
  embedded?: boolean
  defaultOnDeleteTo?: string
}

export const OnsiteEmbeddedContext =
  React.createContext<OnsiteEmbeddedContextType>({})

export const useGoBack = () => {
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(-1)
  }, [navigate])
}

export const ONSITE_CONTENT_MAX_WIDTH = 834

export const useOnsitePageDimensions = () => {
  const isMobile = useIsMobile()
  const margin = isMobile ? 0 : 40

  return useMemo(
    () =>
      ({
        margin,
        maxWidth: ONSITE_CONTENT_MAX_WIDTH,
        innerWidth: ONSITE_CONTENT_MAX_WIDTH - margin * 2,
        style: {
          maxWidth: `${ONSITE_CONTENT_MAX_WIDTH}px`,
          padding: `0 ${margin}px`,
        },
      } as const),
    [margin],
  )
}
