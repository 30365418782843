import { BzDateFns, FileRecord, isNullish } from '@breezy/shared'
import {
  faCircleInfo,
  faEdit,
  faPaperclip,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import ContentList from '../ContentList/ContentList'
import { LoadingSpinner } from '../LoadingSpinner'
import { FileEditNameModal } from './FileEditNameModal'
import { FILE_UPDATE_NAME_MUTATION } from './FileElements.gql'

export type FileElementsProps = {
  files: FileRecord[]
  onMutate: () => void
  onClick?: (file: FileRecord) => void
  editable?: boolean
  selectedFileGuids?: string[]
}

export type FileElementProps = {
  file: FileRecord
  onMutate: () => void
  onClick?: (file: FileRecord) => void
  editable?: boolean
  selected?: boolean
}

const FileElementWithRename = m<FileElementProps>(
  ({ file, onMutate, onClick, editable = true, selected }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const message = useMessage()

    const [isUploading, setIsUploading] = useState(false)
    const removeFileMut = trpc.files['files:remove'].useMutation({
      onSuccess: () => {
        message.success('Successfully removed the file')
        onMutate()
        setIsUploading(false)
      },
      onError: err => {
        message.error('Failed to remove the file.')
        setIsUploading(false)
      },
    })

    const [editingFile, setEditingFile] = useState(false)
    const [updateFileNameRes, updateFileNameMutation] = useMutation(
      FILE_UPDATE_NAME_MUTATION,
    )

    const updateFileName = useCallback(
      async (fileGuid: string, newFileName: string) => {
        await updateFileNameMutation({
          fileGuid,
          fileName: newFileName,
        })

        setEditingFile(false)
      },
      [updateFileNameMutation],
    )

    const deleteFile = useCallback(() => {
      setIsUploading(true)
      removeFileMut.mutate({
        fileGuid: file.fileGuid,
      })
    }, [file.fileGuid, removeFileMut])

    const betterFileName = useMemo(() => {
      if (updateFileNameRes.data?.updateFilesByPk) {
        return updateFileNameRes.data.updateFilesByPk.fileName
      }

      return decodeURIComponent(file.fileName)
    }, [file.fileName, updateFileNameRes])

    const memoizedOnClick = useCallback(() => {
      onClick?.(file)
    }, [onClick, file])

    const hoverCursorClassName = useMemo(() => {
      return !isNullish(onClick) ? 'hover:cursor-pointer' : ''
    }, [onClick])

    if (isUploading) {
      return (
        <div className="mb-2 flex w-full items-center justify-between rounded-[8px] bg-bz-gray-200 px-4 py-2">
          <LoadingSpinner />
        </div>
      )
    }

    return (
      <div
        className={`mb-2 flex w-full items-center justify-between rounded-[8px] bg-bz-gray-200 px-4 py-2 ${
          selected ? 'border-2 border-solid border-[#3b82f6]' : ''
        }`}
      >
        <div
          className={`flex flex-1 items-center space-x-2 ${hoverCursorClassName}`}
          onClick={memoizedOnClick}
        >
          <FontAwesomeIcon
            icon={faPaperclip}
            className={hoverCursorClassName}
          />
          <a
            href={file.cdnUrl}
            download={betterFileName}
            target="_blank"
            rel="noreferrer"
          >
            {betterFileName}
          </a>
        </div>

        <div className="ml-auto flex flex-row items-center space-x-3">
          <span className={hoverCursorClassName} onClick={memoizedOnClick}>
            {BzDateFns.parseISO(file.createdAt, tzId).toLocaleString()}
          </span>
          {editable && (
            <>
              <PopoverMetadata metadata={file.metadata} />
              <FontAwesomeIcon
                icon={faEdit}
                className="hover:cursor-pointer"
                onClick={() => setEditingFile(true)}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="text-red-500 hover:cursor-pointer"
                onClick={deleteFile}
              />

              <FileEditNameModal
                file={file}
                open={editingFile}
                loading={updateFileNameRes.fetching}
                onSubmit={updateFileName}
                onCancel={() => setEditingFile(false)}
              />
            </>
          )}
        </div>
      </div>
    )
  },
)

const FileElements = m<FileElementsProps>(
  ({
    files,
    onMutate,
    onClick,
    editable = true,
    selectedFileGuids = [],
  }: FileElementsProps) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const sorted = files.sort((a, b) => {
      const first = BzDateFns.parseISO(a.createdAt, tzId)
      const second = BzDateFns.parseISO(b.createdAt, tzId)
      if (BzDateFns.isEqual(first, second)) {
        return 0
      }

      return BzDateFns.isBefore(second, first) ? -1 : 1
    })

    return (
      <div className="w-full">
        {sorted.map(file => (
          <FileElementWithRename
            file={file}
            onMutate={onMutate}
            key={file.resourceUrn}
            onClick={onClick}
            editable={editable}
            selected={selectedFileGuids.includes(file.fileGuid)}
          />
        ))}
      </div>
    )
  },
)

const PopoverMetadata = ({
  metadata,
}: {
  metadata: Record<string, string>
}) => {
  if (Object.keys(metadata).length === 0) return null

  return (
    <Popover
      style={{ width: 600 }}
      content={() => {
        return (
          <div>
            <ContentList
              contentList={Object.entries(metadata).map(([key, value]) => ({
                key,
                value,
              }))}
            />
          </div>
        )
      }}
      title="File Metadata"
      trigger="hover"
    >
      <FontAwesomeIcon
        icon={faCircleInfo}
        className="text-gray-500 hover:cursor-pointer"
      />
    </Popover>
  )
}

export default FileElements
