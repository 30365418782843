import {
  bzOptional,
  guidSchema,
  uscDivide,
  usCentsToUsd,
  usdDivide,
  usdMultiply,
} from '@breezy/shared'
import { faLinkHorizontal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import { useCallback } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { useQuery } from 'urql'
import { z } from 'zod'
import {
  OnsiteBasicModal,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { GET_PRICEBOOK_TAX_RATES } from '../../../components/Pricebook/PricebookPickers.gql'
import { NumberField } from '../../../elements/Forms/NumberField'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../../elements/Forms/SelectField'
import { useReactHookFormSubmit } from '../../../elements/Forms/useReactHookFormSubmit'
import ThinDivider from '../../../elements/ThinDivider'
import { preventFormSubmitOnEnter } from '../../../utils/form-helpers'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'
import { zeroTaxRate } from '../MaintenancePlanV3Utils'

type AdjustPricingModalProps = {
  onClose: () => void
  onSave: ({
    taxRate,
    adjustFormIntervalPricePerYearUsd,
  }: {
    taxRate: { taxRateGuid: string; rate: number }
    adjustFormIntervalPricePerYearUsd: number
  }) => void
}

const adjustPricingFormSchema = z.object({
  taxRate: z.object({
    taxRateGuid: bzOptional(guidSchema),
    rate: z.number().min(0).max(1),
  }),
  adjustFormIntervalPricePerMonthUsd: z.number().min(0),
  adjustFormIntervalPricePerYearUsd: z.number().min(0),
})

type AdjustPricingFormData = z.infer<typeof adjustPricingFormSchema>

export const AdjustPricingModal = ({
  onClose,
  onSave,
}: AdjustPricingModalProps) => {
  const parentMpWizardForm = useFormContext<ConfigureMaintenancePlanFormData>()

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<AdjustPricingFormData>({
    resolver: zodResolver(adjustPricingFormSchema),
    defaultValues: {
      taxRate: {
        // we have to do this because the taxRateGuid is nullable??? Something something
        // chesterton's fence, I'm not sure why it's nullable.
        taxRateGuid:
          parentMpWizardForm.watch('taxRate.taxRateGuid') ?? undefined,
        rate: parentMpWizardForm.watch('taxRate.rate') ?? 0,
      },
      adjustFormIntervalPricePerMonthUsd: usCentsToUsd(
        uscDivide(parentMpWizardForm.watch('subtotalYearlyPriceUsc'), 12),
      ),
      adjustFormIntervalPricePerYearUsd: usCentsToUsd(
        parentMpWizardForm.watch('subtotalYearlyPriceUsc'),
      ),
    },
  })

  const onSubmit = useCallback(
    (data: AdjustPricingFormData) => {
      onSave({
        taxRate: {
          taxRateGuid: data.taxRate.taxRateGuid ?? zeroTaxRate.taxRateGuid,
          rate: data.taxRate.rate ?? zeroTaxRate.rate,
        },
        adjustFormIntervalPricePerYearUsd:
          data.adjustFormIntervalPricePerYearUsd,
      })
      onClose()
    },
    [onSave, onClose],
  )

  const getPricebookQuery = useQuery({
    query: GET_PRICEBOOK_TAX_RATES,
  })

  const handleTaxRateChange = useCallback(
    (taxRateGuid: string, taxRate: number) => {
      setValue('taxRate', { taxRateGuid, rate: taxRate })
    },
    [setValue],
  )

  const handleMonthlyPriceChange = useCallback(
    (value: number) => {
      const monthlyValue = value || 0
      setValue('adjustFormIntervalPricePerMonthUsd', monthlyValue, {
        shouldValidate: true,
      })
      const yearlyValue = usdMultiply(monthlyValue, 12)
      setValue('adjustFormIntervalPricePerYearUsd', yearlyValue, {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const handleYearlyPriceChange = useCallback(
    (value: number) => {
      const yearlyValue = value || 0
      setValue('adjustFormIntervalPricePerYearUsd', yearlyValue, {
        shouldValidate: true,
      })
      const monthlyValue = usdDivide(yearlyValue, 12)
      setValue('adjustFormIntervalPricePerMonthUsd', monthlyValue, {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const [submitElement, triggerSubmit] = useReactHookFormSubmit()
  return (
    <OnsiteBasicModal
      open
      header="Adjust Pricing & Tax"
      onClose={onClose}
      footer={<OnsiteModalFooter onCancel={onClose} onSubmit={triggerSubmit} />}
    >
      <GqlQueryLoader
        query={getPricebookQuery}
        render={data => {
          const taxRates = data?.pricebookTaxRates ?? []

          const taxRateOptions =
            taxRates.map(taxRate => ({
              label: taxRate.name,
              value: taxRate.pricebookTaxRateGuid,
            })) ?? []

          // We need to manually add the zero tax rate option to the top of the list
          taxRateOptions.unshift({
            label: 'No Tax',
            value: zeroTaxRate.taxRateGuid,
          })
          return (
            <Form
              layout="vertical"
              onSubmitCapture={handleSubmit(onSubmit)}
              onKeyDown={preventFormSubmitOnEnter}
            >
              <ReactHookFormItem
                noBottomMargin
                control={control}
                name="taxRate.taxRateGuid"
                label="Tax Rate"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    title="Tax Rate"
                    options={taxRateOptions}
                    className="w-full"
                    sheetSize="half"
                    size="large"
                    {...field}
                    onChange={val => {
                      field.onChange(val)
                      const selectedTaxRate = taxRates?.find(
                        taxRate => taxRate.pricebookTaxRateGuid === val,
                      )
                      handleTaxRateChange(val, selectedTaxRate?.rate ?? 0)
                    }}
                  />
                )}
              />
              <div className="flex flex-col">
                <ThinDivider
                  widthPx={1}
                  styleOverrides={{ marginTop: 24, marginBottom: 24 }}
                />
                <div className="space-between flex flex-row items-end gap-x-2">
                  <div className="flex-1 flex-grow">
                    <ReactHookFormItem
                      noBottomMargin
                      control={control}
                      name="adjustFormIntervalPricePerMonthUsd"
                      label="Per Month"
                      errors={errors}
                      render={({ field }) => (
                        <NumberField
                          className="w-full"
                          dataDdActionName="BZ Maintenance Plan Wizard - Adjust Month Pricing"
                          isMoney
                          updateOnChange
                          {...field}
                          onChange={e => {
                            field.onChange(e)
                            handleMonthlyPriceChange(e.target.value)
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex-shrink-0">
                    <FontAwesomeIcon
                      icon={faLinkHorizontal}
                      className="mb-[6px] text-[20px] text-[#8c8c8c]"
                    />
                  </div>
                  <div className="flex-1 flex-grow">
                    <ReactHookFormItem
                      noBottomMargin
                      control={control}
                      name="adjustFormIntervalPricePerYearUsd"
                      label="Per Year"
                      errors={errors}
                      render={({ field }) => (
                        <NumberField
                          className="w-full"
                          dataDdActionName="BZ Maintenance Plan Wizard - Adjust Year Pricing"
                          isMoney
                          updateOnChange
                          {...field}
                          onChange={e => {
                            field.onChange(e)
                            handleYearlyPriceChange(e.target.value)
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {submitElement}
            </Form>
          )
        }}
      />
    </OnsiteBasicModal>
  )
}
