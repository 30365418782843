import { z } from 'zod'
import { AsyncFn } from '../../common'
import { NoteLinkDataSchema, guidSchema } from '../../contracts'
import { AccountGuid } from '../Accounts/Account'
import { ApptAssignmentGuid, JobAppointmentGuid } from '../Appointments/Appointments'
import { CompanyGuidContainer, ForCompanyUser } from '../Company/Company'
import { JobGuid } from '../Job'
import { MaintenancePlanGuid } from '../MaintenancePlans/MaintenancePlanTypes'
import { AvatarData, UserDisplayNameContainer } from '../Person'
import { UserGuid } from '../Users/User'
import { Guid, bzOptional } from '../common-schemas'

export const NoteLinksDTOSchema = z.object({
  locationGuid: bzOptional(guidSchema),
  accountGuid: bzOptional(guidSchema),
  jobGuid: bzOptional(guidSchema),
  jobAppointmentGuid: bzOptional(guidSchema),
  apptAssignmentGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
  maintenancePlanGuid: bzOptional(guidSchema),
  companyGuid: bzOptional(guidSchema),
  paymentRecordGuid: bzOptional(guidSchema),
})

export const CreateLinkedNoteDTOSchema = z.object({
  value: z.string().min(1),
  noteGuid: guidSchema,
  taggedUsers: z.array(guidSchema),
  linkData: NoteLinkDataSchema,
  attachments: bzOptional(
    z
      .object({
        photoGuid: guidSchema.nullable(),
        fileGuid: guidSchema.nullable(),
      })
      .array(),
  ),
})

export type NoteLinksDTO = z.infer<typeof NoteLinksDTOSchema>
export type CreateLinkedNoteDTO = z.infer<typeof CreateLinkedNoteDTOSchema>

export type NoteGuid = Guid
export type NoteGuidContainer = {
  readonly noteGuid: NoteGuid
}

export type SimpleNote = {
  readonly value: string
}

export type NoteLinks = {
  readonly locationGuid?: Guid
  readonly accountGuid?: AccountGuid
  readonly jobGuid?: JobGuid
  readonly jobAppointmentGuid?: JobAppointmentGuid
  readonly apptAssignmentGuid?: ApptAssignmentGuid
  readonly photoGuid?: Guid
  readonly paymentRecordGuid?: Guid
  readonly maintenancePlanGuid?: MaintenancePlanGuid
}

export type NoteCreatorAndTimeInfo = AvatarData &
  UserDisplayNameContainer & {
    readonly createdAt: string
  }

export type NoteTags = {
  taggedUsers: readonly UserGuid[]
}

export type NoteAttachments = {
  noteAttachments?: {
    noteAttachmentGuid: Guid
    photo?: {
      photoGuid: Guid
      cdnUrl: string
    } | null
    file?: {
      fileGuid: Guid
      cdnUrl: string
    } | null
  }[]
}

export type LinkedNote = SimpleNote & NoteLinks & NoteGuidContainer & NoteTags & NoteAttachments
export type LinkedNoteViewModel = LinkedNote & NoteCreatorAndTimeInfo

export type ILinkedNoteWriter = AsyncFn<ForCompanyUser<CreateLinkedNoteDTO>>

export type LinkedNotesQuerier = AsyncFn<NoteLinksDTO, LinkedNoteViewModel[]>

/**
 * Resyncs all notes for a given company with a Searcher
 */
export type NoteSearchableRecordResyncRecordsWriter = AsyncFn<CompanyGuidContainer>
