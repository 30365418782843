import { isFinanceable } from '@breezy/shared'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import { useMemo } from 'react'
import RenderIf from '../../elements/RenderIf/RenderIf'
import { m } from '../../utils/react-utils'
import {
  useFetchAbridgedFinancingInvoiceByAccountAndJob,
  useFinancingWizardContext,
} from './FinancingWizardDrawer'
import { WisetackLogo } from './WisetackLogo'
type FinancingOptionProps = {
  title: string
  description: string
  onClick: () => void
  dataTestId?: string
  disabledMessage?: string
  dataDdActionName?: string
}

const FinancingSelectionOption = m<FinancingOptionProps>(
  ({
    title,
    description,
    onClick,
    dataTestId,
    disabledMessage,
    dataDdActionName,
  }) => {
    return (
      <div className="relative flex w-full flex-row space-x-3 rounded-lg border border-solid border-bz-gray-500 p-6">
        <div className="flex flex-col">
          <div className="mb-[6px] flex items-center">
            <div className="text-[20px] font-semibold">{title}</div>
          </div>
          <div className="mb-4 text-[14px] text-bz-gray-900">{description}</div>
          <div className="flex flex-col items-start gap-2">
            <Button
              type="primary"
              size="large"
              className={classNames('font-semibold', {
                'cursor-not-allowed opacity-30': !!disabledMessage,
              })}
              data-testid={dataTestId}
              onClick={disabledMessage ? undefined : onClick}
              data-dd-action-name={dataDdActionName}
              icon={<FontAwesomeIcon icon={faPaperPlane} />}
            >
              Send Link
            </Button>
            <RenderIf if={!!disabledMessage}>
              <div className="text-[14px] italic text-bz-gray-900">
                {disabledMessage}
              </div>
            </RenderIf>
          </div>
        </div>
        <div className="block">
          <WisetackLogo />
        </div>
      </div>
    )
  },
)

export const FinancingWizardSelector = m(() => {
  const { selectFinancingOption, account, jobGuid } =
    useFinancingWizardContext()

  const { invoices, loading } = useFetchAbridgedFinancingInvoiceByAccountAndJob(
    account.accountGuid,
    jobGuid,
  )

  const hasFinanceableInvoices = invoices.some(i => isFinanceable(i))

  const disabledMessage = useMemo(() => {
    if (loading) return ''
    if (invoices.length === 0)
      return 'Financing applications require an invoice.'
    if (!hasFinanceableInvoices)
      return `Invoices can only be financed if they are between $500 and $25,000 and in the 'Open' status`
    return ''
  }, [loading, invoices.length, hasFinanceableInvoices])

  return (
    <div className="col w-full">
      <p className="mb-6">
        What kind of financing option would you like to send?
      </p>
      <div className="flex flex-col gap-3">
        <FinancingSelectionOption
          title="Pre-qualification Link"
          description="It’s quick and easy for your customers to see if they prequalify! Wise offers easy monthly payments, rates from 0% APR, and no hidden fees."
          onClick={() => selectFinancingOption('Pre-Qualification')}
          dataDdActionName="bz-financing-prequal-link-option-clicked"
          dataTestId="prequal-link-option"
        />
        <FinancingSelectionOption
          title="Financing Application"
          description="Have your customers pay over time through consumer-friendly financing. Wisetack offers easy monthly payments, rates from 0% APR, and no hidden fees."
          onClick={() => selectFinancingOption('Loan Application')}
          dataTestId="financing-application-option"
          dataDdActionName="bz-financing-loan-app-option-clicked"
          disabledMessage={disabledMessage}
        />
      </div>
    </div>
  )
})
