import { z } from 'zod'

export type CompanyTechnicianPerformanceConfig = {
  technicianPerformanceEnabled: boolean
}

// TODO These form-related things should be defined in the FE, not in domain
export const DeductionType = z.literal('PERCENT').or(z.literal('FIXED'))

export const companyTechnicianPerformanceSettingCommissionsFormSchema = z.object({
  defaultCommissionOverheadDeductionType: DeductionType,
  defaultCommissionOverheadDeductionPercent: z.number().min(0).max(100),
  defaultCommissionOverheadFlatDeductionUsd: z.number().min(0),
})

export type CompanyTechnicianPerformanceSettingCommissionsFormSchema = z.infer<
  typeof companyTechnicianPerformanceSettingCommissionsFormSchema
>
