import {
  AccountContact,
  AccountGuid,
  bzExpect,
  isNullish,
  isNullishOrEmpty,
  nextGuid,
  phoneUtils,
} from '@breezy/shared'
import { Divider, Form, Row } from 'antd'
import { FormInstance, useForm, useWatch } from 'antd/lib/form/Form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { ContactRootFormSchema } from '../../../utils/ContactFormTypes'
import { PhoneFormItem } from '../../Phones/PhoneFormItem/PhoneFormItem'
import { EmailAddressFormItem } from '../../form-fields/EmailAddressFormItem/EmailAddressFormItem'
import { FirstNameField } from '../../form-fields/FirstNameField/FirstNameField'
import { FormCancelSubmitButtons } from '../../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { LastNameField } from '../../form-fields/LastNameField/LastNameField'
import { NotificationPreferenceTypeFormItem } from '../../form-fields/NotificationPreferenceSelectorItem/NotificationPreferenceSelectorItem'

export type CreateOrEditNewContactFormProps = {
  onCancelButtonPressed?: () => void
  onAccountContactUpdated?: () => void
  onAccountContactAdded?: () => void
  accountGuid?: AccountGuid
  editingAccountContact?: AccountContact
  showDivider?: boolean
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  labelClassName?: string
  flexRowSpaceX?: string
  extendedForm?: FormInstance<Record<string, unknown> & ContactRootFormSchema>
  showAdditionalPhoneNumber?: boolean
  showCancelSubmitButtons?: boolean
  bypassFormSubmit?: boolean
}

type FormSchema = ContactRootFormSchema

export const CreateOrEditNewContactForm: React.FC<
  CreateOrEditNewContactFormProps
> = ({
  onCancelButtonPressed,
  onAccountContactUpdated,
  onAccountContactAdded,
  accountGuid,
  editingAccountContact,
  showDivider = true,
  justifyFormCancelSubmitButtons = 'end',
  labelClassName = '',
  flexRowSpaceX = 'space-x-4',
  extendedForm,
  showAdditionalPhoneNumber = true,
  showCancelSubmitButtons = true,
  bypassFormSubmit = false,
}) => {
  const company = useExpectedCompany()
  const upsertAccountContactMutation =
    trpc.accountContacts['account-contacts:upsert'].useMutation()
  const [form] = useForm<FormSchema>()

  const knownUnsubscribedPhoneNumberMap = useMemo(() => {
    const map: Record<string, boolean> = {}
    if (editingAccountContact?.contact.primaryPhoneNumber?.unsubscribed) {
      map[editingAccountContact.contact.primaryPhoneNumber.phoneNumber] = true
    }
    if (editingAccountContact?.contact.additionalPhoneNumber?.unsubscribed) {
      map[editingAccountContact.contact.additionalPhoneNumber.phoneNumber] =
        true
    }
    return map
  }, [editingAccountContact])

  const phoneNumberValue = useWatch('phoneNumber', form)
  const phoneNumberType = useWatch('phoneNumberType', form)
  const additionalPhoneNumberValue = useWatch('additionalPhoneNumber', form)
  const additionalPhoneNumberType = useWatch('additionalPhoneNumberType', form)
  const emailAddressValue = useWatch('emailAddress', form)
  const notificationPreferenceType = useWatch('notificationPreference', form)

  const [disabled, disabledReason] = useMemo(() => {
    if (notificationPreferenceType === 'SMS' && !phoneNumberValue) {
      return [
        true,
        'Notification preference cannot be "SMS" if there is no primary phone number.',
      ]
    }
    if (notificationPreferenceType === 'EMAIL' && !emailAddressValue) {
      return [
        true,
        'Notification preference cannot be "EMAIL" if there is no email address.',
      ]
    }
    if (knownUnsubscribedPhoneNumberMap[phoneNumberValue]) {
      return [
        true,
        `The primary phone number (${phoneNumberValue}) has opted out of automatic notifications.`,
      ]
    }
    if (knownUnsubscribedPhoneNumberMap[additionalPhoneNumberValue ?? '']) {
      return [
        true,
        `The additional phone number (${additionalPhoneNumberValue}) has opted out of automatic notifications.`,
      ]
    }
    return [false, undefined]
  }, [
    additionalPhoneNumberValue,
    emailAddressValue,
    knownUnsubscribedPhoneNumberMap,
    notificationPreferenceType,
    phoneNumberValue,
  ])

  /**
   * There seems to be an issue with antd's form abstraction where form#getFieldError is not
   * reliable for determining if a field is valid or not. Validation happens asynchronously
   * and even after it completes, form#getFieldError is not updated with the latest validation
   * status.
   *
   * The following is a hacky workaround where we manually keep track of the validation status for the
   * additionalPhoneNumber field. This lets us have a cleaner UI for displaying the
   * "Swap Phone Numbers" link. The downside is that our validation runs twice when the addtionaPhoneNumber
   * field is blurred, but its not an expensive operation or anything.
   *
   * Related: https://github.com/ant-design/ant-design/issues/15674
   */
  const [shouldShowSwapPhoneNumbersLink, setShouldShowSwapPhoneNumbersLink] =
    useState(true)

  const validateAdditionalPhoneNumber = useCallback(() => {
    form
      .validateFields(['additionalPhoneNumber'])
      .then(values => {
        if (isNullishOrEmpty(values?.additionalPhoneNumber)) {
          setShouldShowSwapPhoneNumbersLink(false)
        } else {
          setShouldShowSwapPhoneNumbersLink(true)
        }
      })
      .catch(() => {
        setShouldShowSwapPhoneNumbersLink(false)
      })
  }, [form])

  useEffect(
    () => validateAdditionalPhoneNumber(),
    [validateAdditionalPhoneNumber],
  )

  const swapPhoneNumbers = useCallback(() => {
    if (!phoneNumberValue || !additionalPhoneNumberValue) {
      return
    }

    form.setFieldsValue({
      phoneNumber: additionalPhoneNumberValue,
      additionalPhoneNumber: phoneNumberValue,
      phoneNumberType: additionalPhoneNumberType,
      additionalPhoneNumberType: phoneNumberType,
    })
  }, [
    additionalPhoneNumberType,
    additionalPhoneNumberValue,
    form,
    phoneNumberType,
    phoneNumberValue,
  ])

  const upsertAccountContactViaApi = useCallback(
    (values: FormSchema) => {
      upsertAccountContactMutation.mutate(
        {
          accountContactGuid:
            editingAccountContact?.accountContactGuid ?? nextGuid(),
          accountGuid: bzExpect(accountGuid, 'Required when not embedded'),
          contactGuid: editingAccountContact?.contact.contactGuid ?? nextGuid(),
          primary: editingAccountContact?.primary ?? false,
          contact: {
            contactGuid:
              editingAccountContact?.contact.contactGuid ?? nextGuid(),
            firstName: values.firstName,
            lastName: values.lastName,
            salutation: editingAccountContact?.contact.salutation,
            title: editingAccountContact?.contact.title,
            notificationPreferenceType: values.notificationPreference,
            primaryEmailAddress: values.emailAddress
              ? {
                  emailAddressGuid:
                    editingAccountContact?.contact.primaryEmailAddress
                      ?.emailAddressGuid ?? nextGuid(),
                  companyGuid:
                    editingAccountContact?.contact.primaryEmailAddress
                      ?.companyGuid ?? company.companyGuid,
                  emailAddress: values.emailAddress,
                }
              : undefined,
            additionalEmailAddress:
              editingAccountContact?.contact.additionalEmailAddress ??
              undefined,
            primaryPhoneNumber: values.phoneNumber
              ? {
                  phoneNumberGuid:
                    editingAccountContact?.contact.primaryPhoneNumber
                      ?.phoneNumberGuid ?? nextGuid(),
                  companyGuid:
                    editingAccountContact?.contact.primaryPhoneNumber
                      ?.companyGuid ?? company.companyGuid,
                  phoneNumber: values.phoneNumber,
                  type: values.phoneNumberType,
                }
              : undefined,
            additionalPhoneNumber: values.additionalPhoneNumber
              ? {
                  phoneNumberGuid:
                    editingAccountContact?.contact.additionalPhoneNumber
                      ?.phoneNumberGuid ?? nextGuid(),
                  companyGuid:
                    editingAccountContact?.contact.additionalPhoneNumber
                      ?.companyGuid ?? company.companyGuid,
                  phoneNumber: values.additionalPhoneNumber,
                  type: values.additionalPhoneNumberType ?? 'MOBILE',
                }
              : undefined,
          },
        },
        {
          onSuccess(data, variables, context) {
            if (!editingAccountContact && onAccountContactAdded) {
              onAccountContactAdded()
            } else if (onAccountContactUpdated) {
              onAccountContactUpdated()
            }
          },
        },
      )
    },
    [
      upsertAccountContactMutation,
      editingAccountContact,
      accountGuid,
      company.companyGuid,
      onAccountContactAdded,
      onAccountContactUpdated,
    ],
  )

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={upsertAccountContactViaApi}
      onFinishFailed={validateAdditionalPhoneNumber}
      validateTrigger="onBlur"
    >
      <div className={`flex ${flexRowSpaceX} gap-x-4`}>
        <div className="w-full">
          <FirstNameField
            firstName={editingAccountContact?.contact.firstName}
            labelClassName={labelClassName}
          />
        </div>
        <div className="w-full">
          <LastNameField
            lastName={editingAccountContact?.contact.lastName}
            labelClassName={labelClassName}
          />
        </div>
      </div>
      <div className={`flex ${flexRowSpaceX}`}>
        <div className="w-full">
          <PhoneFormItem
            name="phoneNumber"
            label="Phone Number"
            phoneNumber={
              editingAccountContact?.contact.primaryPhoneNumber?.phoneNumber
            }
            phoneNumberType={
              editingAccountContact?.contact.primaryPhoneNumber?.type
            }
            onBlur={e => {
              form.setFieldsValue({
                phoneNumber: phoneUtils.tryFormat(e.target.value),
              })
            }}
            enabledSmsCapableControl={!!phoneNumberValue}
            labelClassName={labelClassName}
            required={
              isNullish(emailAddressValue) || emailAddressValue.trim() === ''
            }
          />
        </div>
      </div>
      {showAdditionalPhoneNumber && (
        <div className={`flex ${flexRowSpaceX}`}>
          <div className="flex w-full flex-col">
            <PhoneFormItem
              name="additionalPhoneNumber"
              label="Additional Phone Number"
              phoneNumber={
                editingAccountContact?.contact.additionalPhoneNumber
                  ?.phoneNumber
              }
              phoneNumberType={
                editingAccountContact?.contact.additionalPhoneNumber?.type
              }
              onBlur={e => {
                form.setFieldsValue({
                  additionalPhoneNumber: phoneUtils.tryFormat(e.target.value),
                })

                validateAdditionalPhoneNumber()
              }}
              enabledSmsCapableControl={!!additionalPhoneNumberValue}
              labelClassName={labelClassName}
              required={false}
              disabled={!phoneNumberValue}
            />
            {!!additionalPhoneNumberValue && shouldShowSwapPhoneNumbersLink && (
              <div className="-mt-5">
                <span
                  onClick={swapPhoneNumbers}
                  className="inline-block cursor-pointer whitespace-nowrap text-blue-500 underline"
                >
                  Set as Primary Phone Number
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={`flex ${flexRowSpaceX}`}>
        <div className="w-full sm:w-1/2">
          <EmailAddressFormItem
            required={!phoneNumberValue}
            emailAddress={
              editingAccountContact?.contact.primaryEmailAddress?.emailAddress
            }
            labelClassName={labelClassName}
          />
        </div>
      </div>
      <div className={`flex ${flexRowSpaceX}`}>
        <div className="w-full sm:w-1/2">
          <NotificationPreferenceTypeFormItem
            notificationPreference={
              editingAccountContact?.contact.notificationPreferenceType
            }
            labelClassName={labelClassName}
            value={notificationPreferenceType}
            onChange={value => {
              form.setFieldsValue({ notificationPreference: value })
            }}
          />
        </div>
      </div>
      {showDivider && <Divider />}
      {showCancelSubmitButtons && (
        <Row>
          <div className="w-full">
            <FormCancelSubmitButtons
              onCancel={onCancelButtonPressed}
              primaryButtonText={editingAccountContact ? 'Save' : 'Create'}
              justify={justifyFormCancelSubmitButtons}
              bypassFormSubmit={bypassFormSubmit}
              disabled={disabled}
              disabledReason={disabledReason}
              onSubmit={
                bypassFormSubmit
                  ? () => {
                      form
                        .validateFields()
                        .then(() => {
                          upsertAccountContactViaApi(form.getFieldsValue())
                        })
                        .catch(errorInfo => {
                          // There are validation errors, do not proceed with upsert
                          console.error('Validation failed:', errorInfo)
                        })
                    }
                  : undefined
              }
            />
          </div>
        </Row>
      )}
    </Form>
  )
}
