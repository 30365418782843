import { gql } from '../../../generated'

export const SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION = gql(/* GraphQL*/ `
  mutation MutateAccountContactArchived(
    $accountContactGuid:uuid!,
    $archived:Boolean!
  ) {
    updateAccountContactsByPk(
      pkColumns: {accountContactGuid: $accountContactGuid},
      _set: {
        archived: $archived
      }
    ) {
      __typename
    }
  }
`)
