import { R } from '@breezy/shared'
import { DefinedUseQueryResult, UseQueryResult } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { typedMemo } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'

export const isQueryInitiallyLoading = (q: UseQueryResult<unknown>) =>
  q.status === 'loading' && q.fetchStatus === 'idle'

export type LoaderGenericProps<T> = {
  errorComponent?: JSX.Element
  notFoundComponent?: JSX.Element
  errorMessage?: string
  loadingComponent?: JSX.Element
  idleComponent?: JSX.Element
  render: (data: T) => JSX.Element
}

// https://chat.openai.com/share/59d20ac0-2c4a-41a6-b416-fe187fc738e1
type TrpcMultiQueryLoaderProps<T extends readonly unknown[]> =
  LoaderGenericProps<T> & {
    queries: { [K in keyof T]: UseQueryResult<T[K]> }
  }

export const TrpcMultiQueryLoader = typedMemo(
  <T extends readonly unknown[]>({
    queries,
    errorComponent,
    errorMessage,
    notFoundComponent,
    loadingComponent,
    idleComponent,
    render,
  }: TrpcMultiQueryLoaderProps<T>) => {
    if (R.any(isQueryInitiallyLoading, queries)) {
      return <>{idleComponent}</>
    }

    if (R.any(R.prop('isLoading'), queries)) {
      return loadingComponent || <LoadingSpinner />
    }

    const errors = queries.filter(x => x.isError)
    // Unknown error type. Runtime evaluated
    const notFound = queries.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      x => ((x.error as any)?.data?.httpStatus ?? 0) === 404,
    )
    if (errors.length > 0 || !R.all(R.prop('isSuccess'), queries)) {
      console.error({
        errors: queries.filter(x => x.isError).map(x => x.error),
      })
      if (notFound && notFoundComponent) return notFoundComponent
      if (errorComponent) return errorComponent
      return <div>{errorMessage || `Failed to load data`}</div>
    }

    return render(queries.map(q => q.data) as unknown as T)
  },
)

type TrpcQueryLoaderProps<T> = LoaderGenericProps<T> & {
  query: UseQueryResult<T>
}

const TrpcQueryLoader = typedMemo(
  <T,>({ query, render, ...rest }: TrpcQueryLoaderProps<T>) => {
    const queries = useMemo<[DefinedUseQueryResult<T>]>(
      () => [query as DefinedUseQueryResult<T>],
      [query],
    )
    const ourRender = useCallback((data: [T]) => render(data[0]), [render])
    return (
      <TrpcMultiQueryLoader queries={queries} render={ourRender} {...rest} />
    )
  },
)

export default TrpcQueryLoader
