export type CartSubtotalSummary = {
  subtotalPriceUsc: number
  creditAmountUsc: number
  nonTaxNonDiscSubtotalUsc: number
  nonTaxDiscSubtotalUsc: number
  taxNonDiscSubtotalUsc: number
  taxDiscSubtotalUsc: number
  discountAmountUsc: number
  upchargeAmountUsc: number
  taxAmountUsc: number
}

export type CartCalculationSummary = CartSubtotalSummary & {
  totalPriceUsc: number
}

export const withTotalPriceUsc = (s: CartSubtotalSummary): CartCalculationSummary => ({
  ...s,
  totalPriceUsc:
    s.nonTaxNonDiscSubtotalUsc +
    s.nonTaxDiscSubtotalUsc +
    s.taxNonDiscSubtotalUsc +
    s.taxDiscSubtotalUsc -
    s.creditAmountUsc,
})
