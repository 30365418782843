import { Address, BzAddress } from '@breezy/shared'
import { ComponentProps } from 'react'
import AddressMultiLineView from '../../Addresses/AddressMultiLineView/AddressMultiLineView'
import SidebarItem from './SidebarItem'

export type SidebarLocationProps = Omit<
  ComponentProps<typeof SidebarItem>,
  'contentList' | 'sectionLabel'
> & {
  displayName?: string
  address: Address
}

export const SidebarLocation = ({
  address,
  displayName,
  ...sidebarLocationProps
}: SidebarLocationProps) => {
  return (
    <SidebarItem
      {...sidebarLocationProps}
      sectionLabel="Service Location"
      contentList={[
        {
          key: 'Address',
          value: (
            <AddressMultiLineView
              displayName={displayName}
              address={BzAddress.create(address)}
            />
          ),
        },
      ]}
    />
  )
}
