import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { IconCard } from '../Card/IconCard'

type InfoMessageProps = {
  icon: IconProp
  variant: 'warning' | 'info' | 'error' | 'success'
}

const variantStyles = {
  warning: {
    cardBorder: 'border-bz-orange-300',
    cardBg: 'bg-bz-orange-100',
    iconBg: 'bg-bz-orange-200',
    iconColor: 'text-bz-orange-800',
  },
  info: {
    cardBorder: 'border-[#91CAFF]',
    cardBg: 'bg-[#E6F4FF]',
    iconBg: 'bg-[#BAE0FF]',
    iconColor: 'text-[#003EB3]',
  },
  error: {
    cardBorder: 'border-bz-red-200',
    cardBg: 'bg-bz-red-100',
    iconBg: 'bg-bz-red-200',
    iconColor: 'text-bz-red-600',
  },
  success: {
    cardBorder: 'border-daybreak-blue-300',
    cardBg: 'bg-daybreak-blue-100',
    iconBg: 'bg-daybreak-blue-200',
    iconColor: 'text-[#1677FF]',
  },
}

export const InfoMessage = React.memo<
  React.PropsWithChildren<InfoMessageProps>
>(({ icon, variant, children }) => (
  <IconCard
    icon={icon}
    cardBorderClassName={variantStyles[variant].cardBorder}
    cardBgClassName={variantStyles[variant].cardBg}
    iconBgClassName={variantStyles[variant].iconBg}
    iconColorClassName={variantStyles[variant].iconColor}
    iconSizeClassName="h-4 w-4"
  >
    {children}
  </IconCard>
))
