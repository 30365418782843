import tailwindConfig from '../tailwind.config'

/** @deprecated use src/tailwind.config directly */
export const polarGreen1 = tailwindConfig.theme.extend.colors['bz-green'][100]
/** @deprecated use src/tailwind.config directly */
export const polarGreen6 = tailwindConfig.theme.extend.colors['bz-green'][600]
/** @deprecated use src/tailwind.config directly */
export const polarGreen8 = tailwindConfig.theme.extend.colors['bz-green'][800]

/** @deprecated use src/tailwind.config directly */
export const green6 = tailwindConfig.theme.extend.colors['bz-green'][600]

/** @deprecated use src/tailwind.config directly */
export const dayBreakBlue1 =
  tailwindConfig.theme.extend.colors['daybreak-blue'][100]
/** @deprecated use src/tailwind.config directly */
export const dayBreakBlue2 =
  tailwindConfig.theme.extend.colors['daybreak-blue'][200]
/** @deprecated use src/tailwind.config directly */
export const dayBreakBlue6 =
  tailwindConfig.theme.extend.colors['daybreak-blue'][600]
/** @deprecated use src/tailwind.config directly */
export const dayBreakBlue8 =
  tailwindConfig.theme.extend.colors['daybreak-blue'][800]

/** @deprecated use src/tailwind.config directly */
export const goldenPurple1 =
  tailwindConfig.theme.extend.colors['bz-purple'][100]
/** @deprecated use src/tailwind.config directly */
export const goldenPurple6 =
  tailwindConfig.theme.extend.colors['bz-purple'][600]
/** @deprecated use src/tailwind.config directly */
export const goldenPurple8 =
  tailwindConfig.theme.extend.colors['bz-purple'][800]

/** @deprecated use src/tailwind.config directly */
export const sunriseYellow1 =
  tailwindConfig.theme.extend.colors['bz-yellow'][100]
/** @deprecated use src/tailwind.config directly */
export const sunriseYellow6 =
  tailwindConfig.theme.extend.colors['bz-yellow'][600]
/** @deprecated use src/tailwind.config directly */
export const sunriseYellow7 =
  tailwindConfig.theme.extend.colors['bz-yellow'][700]
/** @deprecated use src/tailwind.config directly */
export const sunriseYellow8 =
  tailwindConfig.theme.extend.colors['bz-yellow'][800]

/** @deprecated use src/tailwind.config directly */
export const sunsetOrange1 =
  tailwindConfig.theme.extend.colors['bz-orange'][100]
/** @deprecated use src/tailwind.config directly */
export const sunsetOrange4 =
  tailwindConfig.theme.extend.colors['bz-orange'][400]
/** @deprecated use src/tailwind.config directly */
export const sunsetOrange6 =
  tailwindConfig.theme.extend.colors['bz-orange'][600]
/** @deprecated use src/tailwind.config directly */
export const sunsetOrange7 =
  tailwindConfig.theme.extend.colors['bz-orange'][700]
/** @deprecated use src/tailwind.config directly */
export const sunsetOrange8 =
  tailwindConfig.theme.extend.colors['bz-orange'][800]

/** @deprecated use src/tailwind.config directly */
export const gray4 = tailwindConfig.theme.extend.colors['bz-gray'][400]
/** @deprecated use src/tailwind.config directly */
export const gray5 = tailwindConfig.theme.extend.colors['bz-gray'][500]
/** @deprecated use src/tailwind.config directly */
export const gray6 = tailwindConfig.theme.extend.colors['bz-gray'][600]
/** @deprecated use src/tailwind.config directly */
export const gray7 = tailwindConfig.theme.extend.colors['bz-gray'][700]
/** @deprecated use src/tailwind.config directly */
export const gray8 = tailwindConfig.theme.extend.colors['bz-gray'][800]
/** @deprecated use src/tailwind.config directly */
export const gray9 = tailwindConfig.theme.extend.colors['bz-gray'][900]

/** @deprecated use src/tailwind.config directly */
export const dustRed1 = tailwindConfig.theme.extend.colors['bz-red'][100]
/** @deprecated use src/tailwind.config directly */
export const dustRed6 = tailwindConfig.theme.extend.colors['bz-red'][600]
/** @deprecated use src/tailwind.config directly */
export const red6 = tailwindConfig.theme.extend.colors['bz-red'][600]
/** @deprecated use src/tailwind.config directly */
export const dustRed8 = tailwindConfig.theme.extend.colors['bz-red'][800]

/** @deprecated use src/tailwind.config directly */
export const cyan6 = tailwindConfig.theme.extend.colors['bz-cyan'][600]

export const yellowTrio = {
  backgroundColor: tailwindConfig.theme.extend.colors['bz-yellow'][100],
  borderColor: tailwindConfig.theme.extend.colors['bz-yellow'][700],
  color: tailwindConfig.theme.extend.colors['bz-yellow'][900],
}

/** @deprecated use src/tailwind.config directly */
export const alertOrange = tailwindConfig.theme.extend.colors['bz-orange'][600]

/** @deprecated use src/tailwind.config directly */
export const blue2 = tailwindConfig.theme.extend.colors['daybreak-blue'][200]
/** @deprecated use src/tailwind.config directly */
export const blue4 = tailwindConfig.theme.extend.colors['daybreak-blue'][400]
/** @deprecated use src/tailwind.config directly */
export const blue6 = tailwindConfig.theme.extend.colors['daybreak-blue'][600]

export const DEFAULT_FONT_FAMILY =
  '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
