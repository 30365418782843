import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'antd'
import cn from 'classnames'

export type PageTitleProps = {
  title: React.ReactNode
  icon?: IconProp
  className?: string
}

const PageTitle = ({ title, icon, className }: PageTitleProps) => (
  <Row className={cn('row center-children-v', className)}>
    {icon && (
      <FontAwesomeIcon icon={icon} style={{ marginRight: 12, fontSize: 24 }} />
    )}
    {title}
  </Row>
)

export default PageTitle
