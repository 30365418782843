import { AccountContact, toTitleCase } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo } from 'react'
import { SmallCard } from '../../elements/Card/SmallCard'
import StatusTag from '../../elements/StatusTag/StatusTag'
import { KeyValueRow } from './components/KeyValueRow'

type Props = {
  accountContact: AccountContact
}

export const AccountContactMiniCard = memo<Props>(({ accountContact }) => {
  const header = accountContact.primary ? (
    <Row justify="space-between">
      <Col className="pt-0.5">{`${accountContact.contact.firstName} ${accountContact.contact.lastName}`}</Col>
      <Col className="py-0.5">
        <StatusTag color="green" text="Primary" border="regular" />
      </Col>
    </Row>
  ) : (
    <Row>
      <Col className="pt-0.5">{`${accountContact.contact.firstName} ${accountContact.contact.lastName}`}</Col>
    </Row>
  )

  return (
    <SmallCard header={header}>
      <div className="flex flex-col gap-y-1">
        <KeyValueRow
          label="Email"
          value={accountContact.contact.primaryEmailAddress?.emailAddress}
        />
        <KeyValueRow
          label="Phone Number"
          value={accountContact.contact.primaryPhoneNumber?.phoneNumber}
        />
        <KeyValueRow
          label="Notifications"
          value={toTitleCase(accountContact.contact.notificationPreferenceType)}
        />
      </div>
    </SmallCard>
  )
})
