import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import {
  BzDateTime,
  CreditRecord,
  IsoDateString,
  UTC_TIME_ZONE,
  isCreditActive,
  isNullish,
} from '@breezy/shared'
import { faCheck, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, Row, Switch } from 'antd'
import { ReactNode, memo, useCallback, useMemo } from 'react'
import { useMutation } from 'urql'
import ThinDivider from '../../elements/ThinDivider'
import { getJobDoesNotHaveValidMaintenancePlanReason } from '../../hooks/useMaintenancePlanVisit'
import { UPDATE_JOB_USE_MAINTENANCE_PLAN_CREDIT } from './JobInfoCard.gql'

type JobMaintenancePlanMembershipCardProps = {
  comprehensiveJobDetails: ComprehensiveJobDetails
  refetchComprehensiveJobDetails: () => void
}

/**
 * ! This is very similar to the MaintenancePlanMembershipInfoCard component. Ideally
 * ! we'd try to combine these into a single component in the future, or at least share
 * ! larger parts of the logic between them
 */
/** @deprecated cleanup with maint plans v3 */
const JobMaintenancePlanMembershipCard =
  memo<JobMaintenancePlanMembershipCardProps>(
    ({ comprehensiveJobDetails, refetchComprehensiveJobDetails }) => {
      const [
        { fetching: mutatingUseMaintenancePlanCredit },
        executeJobUseMaintenancePlanCreditMutation,
      ] = useMutation(UPDATE_JOB_USE_MAINTENANCE_PLAN_CREDIT)

      const jobLocationHasAvailableMaintenancePlanCredits = useMemo(
        () =>
          !getJobDoesNotHaveValidMaintenancePlanReason(comprehensiveJobDetails),
        [comprehensiveJobDetails],
      )

      const maintenancePlan = comprehensiveJobDetails.getMaintenancePlan()

      const availableMaintenancePlanCreditsRemaining = useMemo(
        () =>
          comprehensiveJobDetails
            .getMaintenancePlan()
            ?.credits.filter(c => isCreditActive(c)).length ?? 0,
        [comprehensiveJobDetails],
      )

      const toggleUseMaintenancePlanCredit = useCallback(() => {
        if (jobLocationHasAvailableMaintenancePlanCredits) {
          executeJobUseMaintenancePlanCreditMutation({
            jobGuid: comprehensiveJobDetails.getJobGuid(),
            useCredit: !comprehensiveJobDetails.getUseMaintenancePlanCredit(),
          })

          refetchComprehensiveJobDetails()
        }
      }, [
        comprehensiveJobDetails,
        executeJobUseMaintenancePlanCreditMutation,
        jobLocationHasAvailableMaintenancePlanCredits,
        refetchComprehensiveJobDetails,
      ])

      // Approximate this using the earliest credit issue date
      // TODO Both this createdAt and lastVisitedAt are available on the maintenance plan model,
      // we just don't expose it in the ComprehensiveJobDetails at the time of writing this.
      // We should change this to use those values instead of doing this approximation
      const maintenancePlanCreatedAt = useMemo(() => {
        if (!maintenancePlan) {
          return undefined
        }

        const earliestIssuedCredit = maintenancePlan.credits.reduce(
          (earliest, current) => {
            const currentIssuedAt = BzDateTime.fromIsoString(
              current.issuedAt,
              UTC_TIME_ZONE,
            )

            if (isNullish(earliest)) {
              return current
            }

            const earliestIssuedAt = BzDateTime.fromIsoString(
              earliest.issuedAt,
              UTC_TIME_ZONE,
            )

            if (currentIssuedAt.isBefore(earliestIssuedAt)) {
              return current
            }

            return earliest
          },
          null as CreditRecord | null,
        )

        return earliestIssuedCredit?.issuedAt
          ? BzDateTime.fromIsoString(
              earliestIssuedCredit.issuedAt,
              UTC_TIME_ZONE,
            )
          : undefined
      }, [maintenancePlan])

      const maintenancePlanLastVisitAt = useMemo(() => {
        if (!maintenancePlan) {
          return undefined
        }

        const latestFulfilledCredit = maintenancePlan.credits.reduce(
          (latest, current) => {
            if (isNullish(current.fulfilledAt)) {
              return latest
            }

            const currentFulfilledAt = BzDateTime.fromIsoString(
              current.fulfilledAt,
              UTC_TIME_ZONE,
            )

            if (isNullish(latest) || isNullish(latest.fulfilledAt)) {
              return current
            }

            const latestFulfilledAt = BzDateTime.fromIsoString(
              latest.fulfilledAt,
              UTC_TIME_ZONE,
            )

            if (currentFulfilledAt.isAfter(latestFulfilledAt)) {
              return current
            }

            return latest
          },
          null as CreditRecord | null,
        )
        return latestFulfilledCredit?.fulfilledAt
          ? BzDateTime.fromIsoString(
              latestFulfilledCredit.fulfilledAt,
              UTC_TIME_ZONE,
            )
          : undefined
      }, [maintenancePlan])

      if (!maintenancePlan) {
        return null
      }

      let creditUsageContent: ReactNode = null
      if (
        !isNullish(comprehensiveJobDetails.getMaintenancePlanCreditUsedAt())
      ) {
        // A job can use a maximum of one maintenance credit, so if this job has
        // already consumed one, then let the user know and do not show the toggle

        const displayDate = BzDateTime.fromIsoString(
          comprehensiveJobDetails.getMaintenancePlanCreditUsedAt() as IsoDateString,
          comprehensiveJobDetails.getCompany().timezone,
        ).toDateFormat('MMM dd, yyyy')

        creditUsageContent = (
          <>
            <ThinDivider />
            <div className="italic text-gray-500">
              {`This job used a Maintenance Plan credit on ${displayDate}`}
            </div>
          </>
        )
      } else if (!jobLocationHasAvailableMaintenancePlanCredits) {
        creditUsageContent = null
      } else {
        const availableCreditsRemainingText = `Completing this job will use one of your Maintenance Plan credits. You'll have ${
          availableMaintenancePlanCreditsRemaining - 1
        } credits left afterwards.`
        const noCreditsRemainingText =
          `A Maintenance Plan credit will NOT be used when this job is ` +
          `completed because it has no credits remaining.`

        creditUsageContent = (
          <>
            <ThinDivider />
            <Row>
              <Col span={20}>
                <span className="semibold_14_22 grey9">
                  {`Use Plan Credit (${availableMaintenancePlanCreditsRemaining} remaining)`}
                </span>
                <div className="italic text-gray-500">
                  {comprehensiveJobDetails.getUseMaintenancePlanCredit() ? (
                    <>
                      {availableMaintenancePlanCreditsRemaining > 0
                        ? availableCreditsRemainingText
                        : noCreditsRemainingText}
                    </>
                  ) : (
                    <>
                      Check the box above to have this job use a credit for the
                      associated Maintenance Plan when it is completed
                    </>
                  )}
                </div>
              </Col>
              <Col
                span={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <Switch
                  disabled={
                    mutatingUseMaintenancePlanCredit ||
                    !isNullish(
                      comprehensiveJobDetails.getJobLifecycleStatus()
                        .specialStatus,
                    )
                  }
                  checked={comprehensiveJobDetails.getUseMaintenancePlanCredit()}
                  onChange={toggleUseMaintenancePlanCredit}
                  checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                  unCheckedChildren={<FontAwesomeIcon icon={faX} />}
                />
              </Col>
            </Row>
          </>
        )
      }

      return (
        <Card
          bodyStyle={{ padding: '0px', margin: '0px' }}
          style={{ backgroundColor: '#fafafa', padding: '12px 16px 12px 16px' }}
        >
          <h3 style={{ marginBottom: '2px' }}>Maintenance Plan Member</h3>
          <p style={{ margin: '0px' }}>
            {maintenancePlan.credits.length > 0 ? (
              <>
                This location has been a
                <strong>{` ${maintenancePlan.definition.name} `}</strong>
                plan member since
                <strong>{` ${maintenancePlanCreatedAt?.toHumanFriendlyMonthDayYear()}. `}</strong>
                {maintenancePlanLastVisitAt ? (
                  <>
                    The last maintenance visit was on
                    <strong>{` ${maintenancePlanLastVisitAt.toHumanFriendlyMonthDayYear()}.`}</strong>
                  </>
                ) : (
                  <>This location has not yet had a maintenance visit.</>
                )}
              </>
            ) : (
              <>
                This location is a
                <strong>{` ${maintenancePlan.definition.name} `}</strong>
                plan member.
              </>
            )}
          </p>

          {creditUsageContent}
        </Card>
      )
    },
  )

export default JobMaintenancePlanMembershipCard
