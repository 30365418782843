import { AppointmentChecklistInstance, R } from '@breezy/shared'
import { Button, Divider } from 'antd'
import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteBasicModal } from '../../../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { trpc } from '../../../../hooks/trpc'
import { useExpectedPrincipal } from '../../../../providers/PrincipalUser'
import { AppointmentChecklistInstanceForm } from '../../../AppointmentChecklists/AppointmentChecklistInstanceForm'
import { LoadingSpinner } from '../../../LoadingSpinner'
import './AppointmentDetail.less'

type ChecklistsModalProps = {
  instances: AppointmentChecklistInstance[]
  onClose: () => void
  refetch: () => Promise<unknown>
}

export const ChecklistsModal = React.memo<ChecklistsModalProps>(
  ({ instances: originalInstances, onClose, refetch }) => {
    const principalUserGuid = useExpectedPrincipal().userGuid

    const [instances, setInstances] = useState(originalInstances)

    const upsertChecklistInstancesMutation = trpc.appointmentChecklist[
      'appointment-checklists:upsert-instances'
    ].useMutation({
      onSuccess: () => {
        refetch()
        onClose()
      },
    })

    const upsertChecklistInstances = useCallback(() => {
      upsertChecklistInstancesMutation.mutate(
        instances.map(instance => ({
          ...instance,
          lastEditorUserGuid: principalUserGuid,
        })),
      )
    }, [instances, principalUserGuid, upsertChecklistInstancesMutation])

    const disabled = useMemo(() => {
      let isDisabled = false
      for (const instance of instances) {
        for (const item of instance.checklist.items) {
          if (!item.notRequired && !item.response) {
            isDisabled = true
            break
          }
        }
      }

      return isDisabled
    }, [instances])
    const [isDirty, setIsDirty] = useState(false)
    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal(isDirty)
    const onCancelWithConfirmation = useCallback(
      () => withConfirmation(onClose),
      [onClose, withConfirmation],
    )
    return (
      <>
        <OnsiteBasicModal
          open
          header="Appointment Checklists"
          drawer={false}
          onClose={onCancelWithConfirmation}
          footer={
            <div
              className={cn(
                'absolute inset-x-0 bottom-0 flex flex-row justify-between gap-2 bg-white px-5 py-3',
                'z-10 border-0 border-t-4 border-solid border-t-bz-gray-400',
              )}
            >
              <Button
                className="w-full font-semibold"
                size="large"
                onClick={onCancelWithConfirmation}
              >
                Cancel
              </Button>
              <Button
                className="w-full font-semibold"
                type="primary"
                size="large"
                disabled={disabled}
                onClick={upsertChecklistInstances}
              >
                Save
              </Button>
            </div>
          }
          children={
            <>
              <div className="mt-2 pb-14">
                {instances.map((instance, i) => (
                  <React.Fragment
                    key={instance.appointmentChecklistInstanceGuid}
                  >
                    <AppointmentChecklistInstanceForm
                      items={instance.checklist.items}
                      setIsDirty={setIsDirty}
                      setItems={items =>
                        setInstances(
                          R.adjust(
                            i,
                            R.assocPath(['checklist', 'items'], items),
                          ),
                        )
                      }
                    />
                    {i !== instances.length - 1 && <Divider className="my-6" />}
                  </React.Fragment>
                ))}
              </div>
              {upsertChecklistInstancesMutation.isLoading && (
                <div className="fixed inset-0 z-[100001] bg-[#ffffffaa]">
                  <LoadingSpinner />
                </div>
              )}
            </>
          }
        />
        <CloseConfirmModal {...closeConfirmProps} />
      </>
    )
  },
)
