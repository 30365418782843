import { PermissionV2 } from '@breezy/shared'
import { useCompanyPhoneEnabled } from '../useCompanyPhoneEnabled'
import { useFeatureFlag } from '../useFeatureFlags'
import { useIsAuthorizedTo } from './useIsAuthorized'

export const COMPANY_INTEGRATION_PHONE_CONFIG_QUERY = `
  query CompanyIntegrationPhoneConfig($companyGuid: uuid!) {
    integratedPhoneCompanyConfig(where: {companyGuid: {_eq: $companyGuid}}) {
      integratedPhoneEnabled
    }
  }
`

export const useCanUseIntegratedPhone = () => {
  const isFeatureFlagEnabled = useFeatureFlag('integratedCalling')
  const isAuthorized = useIsAuthorizedTo(PermissionV2.OFFICE_INTEGRATED_PHONE)
  const canUseCompanyPhone = useCompanyPhoneEnabled()

  const canUseIntegratedPhone =
    isFeatureFlagEnabled && isAuthorized && canUseCompanyPhone

  return canUseIntegratedPhone
}
