import {
  JobOutcomesCommissionDeductionsPopoverFormSchema,
  formatUsc,
  isNullish,
} from '@breezy/shared'
import { Button, Col, Collapse, Row } from 'antd'
import { useCallback, useState } from 'react'
import ThinDivider from '../../elements/ThinDivider'
import CommissionDeductionsPopoverForm from './CommissionDeductionsPopoverForm'
import { JobDetailsForOutcomes } from './job-outcome-modal-types'

type CommissionableBaseCollapsibleCardProps = {
  job: JobDetailsForOutcomes
  totalSoldRevenueUsc: number
  overheadAllocationDeductionUsc: number
  overheadAllocationDeductionPercent?: number
  jobCostsDeductionUsc: number
  maximumCommissionableBaseUsc: number
  onCommissionDeductionsPopoverSubformSubmit: (
    values: JobOutcomesCommissionDeductionsPopoverFormSchema,
  ) => void
}

const CommissionableBaseCollapsibleCard: React.FC<
  CommissionableBaseCollapsibleCardProps
> = ({
  job,
  totalSoldRevenueUsc,
  overheadAllocationDeductionUsc,
  overheadAllocationDeductionPercent,
  jobCostsDeductionUsc,
  maximumCommissionableBaseUsc,
  onCommissionDeductionsPopoverSubformSubmit,
}) => {
  const [feesAndJobCostsModalOpen, setFeesAndJobCostsModalOpen] =
    useState(false)

  const onCommissionDeductionsPopoverFormSubmit = useCallback(
    (values: JobOutcomesCommissionDeductionsPopoverFormSchema) => {
      onCommissionDeductionsPopoverSubformSubmit(values)

      setFeesAndJobCostsModalOpen(false)
    },
    [onCommissionDeductionsPopoverSubformSubmit],
  )

  return (
    <Collapse size="small" style={{ width: '100%' }}>
      <Collapse.Panel key="commissionableBase" header="Commissionable Base">
        <p>
          Deduct overhead allocation and job costs from sold revenue to
          calculate the commissionable base. This will be calculated
          individually for each employee.
        </p>

        <ThinDivider
          dividerStyle="dashed"
          styleOverrides={{ marginTop: '12px', marginBottom: '8px' }}
        />

        <Row justify="space-between">
          <Col>Total Sold Revenue</Col>
          <Col>{formatUsc(totalSoldRevenueUsc)}</Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginTop: '4px', color: 'rgba(0, 0, 0, 0.45)' }}
        >
          <Col>{`Overhead Allocation ${
            !isNullish(overheadAllocationDeductionPercent)
              ? `(${overheadAllocationDeductionPercent}%)`
              : ''
          }`}</Col>
          <Col>{formatUsc(overheadAllocationDeductionUsc)}</Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginTop: '4px', color: 'rgba(0, 0, 0, 0.45)' }}
        >
          <Col>Job Costs</Col>
          <Col>{formatUsc(jobCostsDeductionUsc)}</Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: '4px' }}>
          <Col>Maximum Commissionable Base</Col>
          <Col>{formatUsc(maximumCommissionableBaseUsc)}</Col>
        </Row>

        <CommissionDeductionsPopoverForm
          open={feesAndJobCostsModalOpen}
          onCancel={() => setFeesAndJobCostsModalOpen(false)}
          onSubmit={onCommissionDeductionsPopoverFormSubmit}
        >
          <Button
            style={{ padding: '0', marginTop: '8px' }}
            type="link"
            onClick={() => setFeesAndJobCostsModalOpen(true)}
          >
            Fees & Job Costs
          </Button>
        </CommissionDeductionsPopoverForm>
      </Collapse.Panel>
    </Collapse>
  )
}

export default CommissionableBaseCollapsibleCard
