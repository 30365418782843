import {
  AccountContact,
  BzDateTime,
  LocalDateString,
  MaintenancePlanDefinition,
  MaintenancePlanPaymentInterval,
  TaxRateSetEventData,
  formatPercentage,
  formatUsc,
  formatUscConcise,
  getBillingAnchorDayLabel,
  uscDivide,
  usdToUsCents,
} from '@breezy/shared'
import { faCircleDollar } from '@fortawesome/pro-regular-svg-icons'
import { Button, Card, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { MediumCard } from '../../../elements/Card/MediumCard'
import FaIconWithCircularBackground from '../../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import ThinDivider from '../../../elements/ThinDivider'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useModalState } from '../../../utils/react-utils'
import {
  calcMaintenancePlanPricing,
  toPlanBillingLabel,
} from '../MaintenancePlanV3Utils'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'
import { useShouldShowDelayedPaymentBanner } from '../hooks/useShouldShowDelayedPaymentBanner'
import { AdjustPricingModal } from './AdjustPricingModal'

const LineItem = ({
  value,
  label,
  dataTestId,
}: {
  label: string | React.ReactNode
  value: string
  dataTestId?: string
}) => {
  return (
    <div
      data-testid={dataTestId}
      className="mb-[7px] mt-[5px] flex flex-row justify-between"
    >
      <div
        data-testid={`${dataTestId}-label`}
        className="text-[16px] font-semibold leading-[24px] text-bz-text-tertiary"
      >
        {label}
      </div>
      <div
        data-testid={`${dataTestId}-value`}
        className="text-[16px] font-normal leading-[24px] text-bz-text-tertiary"
      >
        {value}
      </div>
    </div>
  )
}

type FinalPricingSectionProps = {
  accountContact: AccountContact
  maintenancePlanDefinition: MaintenancePlanDefinition
  onSubmit: () => void
}

export const FinalPricingSection = ({
  accountContact,
  maintenancePlanDefinition,
  onSubmit,
}: FinalPricingSectionProps) => {
  const { watch, setValue } = useFormContext<ConfigureMaintenancePlanFormData>()

  const taxRate = watch('taxRate')
  const billingPaymentInterval = watch('billingPaymentInterval')
  const isFreePlan = watch('isFreePlan')
  const isImportedPlan = watch('isImportedPlan')
  const isAutoRenewing = watch('isAutoRenewing')
  const billingAnchorDate = watch('billingAnchorDate')
  const activationDate = watch('activationDate')
  const billingAnchorDay = watch('billingAnchorDay')
  const subtotalYearlyPriceUsc = watch('subtotalYearlyPriceUsc')
  const adjustmentYearlyPriceUsc = watch('adjustmentYearlyPriceUsc')

  const [
    adjustPricingModalOpen,
    openAdjustPricingModal,
    closeAdjustPricingModal,
  ] = useModalState(false)

  const onAdjustFormSave = useCallback(
    ({
      taxRate,
      adjustFormIntervalPricePerYearUsd,
    }: {
      taxRate: { taxRateGuid: string; rate: number }
      adjustFormIntervalPricePerYearUsd: number
    }) => {
      const adjustment =
        usdToUsCents(adjustFormIntervalPricePerYearUsd) - subtotalYearlyPriceUsc
      // NOTE: Final subtotal should not go below 0
      const newAdjustmentYearlyPriceUsc = Math.max(
        adjustment,
        -subtotalYearlyPriceUsc,
      )
      setValue('adjustmentYearlyPriceUsc', newAdjustmentYearlyPriceUsc)
      const finalPrice = newAdjustmentYearlyPriceUsc + subtotalYearlyPriceUsc
      setValue('isFreePlan', finalPrice === 0)
      setValue('taxRate', taxRate)
    },
    [subtotalYearlyPriceUsc, setValue],
  )

  const intervalBillingLabel = useMemo(
    () =>
      toPlanBillingLabel(isFreePlan, isAutoRenewing, billingPaymentInterval),
    [isFreePlan, isAutoRenewing, billingPaymentInterval],
  )

  const {
    intervalSubtotalPriceUsc,
    intervalTaxPriceUsc,
    intervalTotalPriceUsc,
    annualTotalPriceUsc,
  } = calcMaintenancePlanPricing({
    isFreePlan,
    isImportedPlan,
    subtotalYearlyPriceUsc,
    adjustmentYearlyPriceUsc,
    billingPaymentInterval,
    // This cast is necessary because we allow setting the tax rate to null
    taxRate: taxRate as TaxRateSetEventData,
  })

  const perMonthLabel = useMemo(
    () => `${formatUscConcise(uscDivide(annualTotalPriceUsc, 12))} per month`,
    [annualTotalPriceUsc],
  )

  const perQuarterLabel = useMemo(
    () => `${formatUscConcise(uscDivide(annualTotalPriceUsc, 4))} per quarter`,
    [annualTotalPriceUsc],
  )

  const perYearLabel = useMemo(
    () => `${formatUscConcise(annualTotalPriceUsc)} per year`,
    [annualTotalPriceUsc],
  )

  const primaryPriceLabel = useMemo(
    () =>
      billingPaymentInterval === MaintenancePlanPaymentInterval.MONTHLY
        ? perMonthLabel
        : billingPaymentInterval === MaintenancePlanPaymentInterval.QUARTERLY
        ? perQuarterLabel
        : perYearLabel,
    [billingPaymentInterval, perMonthLabel, perQuarterLabel, perYearLabel],
  )

  const secondaryPriceLabel = useMemo(
    () =>
      billingPaymentInterval === MaintenancePlanPaymentInterval.YEARLY
        ? perMonthLabel
        : perYearLabel,
    [billingPaymentInterval, perMonthLabel, perYearLabel],
  )

  const shouldShowDelayedPaymentBanner = useShouldShowDelayedPaymentBanner({
    isFreePlan,
    isAutoRenewing,
    billingAnchorDate,
    activationDate,
  })

  const actionButtonLabel = useMemo(() => {
    if (isImportedPlan) {
      return 'Import Plan'
    }
    if (isFreePlan) {
      return 'Activate Free Plan'
    }
    if (shouldShowDelayedPaymentBanner) {
      return 'Collect Payment Info'
    }

    return `Collect ${formatUsc(intervalTotalPriceUsc)} Payment`
  }, [
    isFreePlan,
    isImportedPlan,
    intervalTotalPriceUsc,
    shouldShowDelayedPaymentBanner,
  ])

  if (isImportedPlan) {
    return (
      <MediumCard className="px-6 py-4">
        <div className="flex flex-col gap-y-2">
          <Button
            type="primary"
            className="min-h-[40px] min-w-[280px]"
            size="large"
            onClick={onSubmit}
          >
            {actionButtonLabel}
          </Button>
        </div>
      </MediumCard>
    )
  }

  return (
    <>
      <MediumCard>
        <div className="flex flex-col">
          <LineItem
            label={`${maintenancePlanDefinition.name} (${intervalBillingLabel})`}
            value={formatUsc(intervalSubtotalPriceUsc)}
            dataTestId="subtotal-price-line-item"
          />
          <LineItem
            label={
              <div className="font-semibold">
                Tax{' '}
                <span className="font-normal">
                  ({formatPercentage(taxRate.rate)})
                </span>
              </div>
            }
            value={formatUsc(intervalTaxPriceUsc)}
            dataTestId="tax-price-line-item"
          />
          <LineItem
            label="Grand Total"
            value={formatUsc(intervalTotalPriceUsc)}
            dataTestId="grand-total-price-line-item"
          />
          <ThinDivider dividerStyle="dotted" />
          {shouldShowDelayedPaymentBanner && (
            <div className="mb-2">
              <DelayedPaymentBanner
                accountContact={accountContact}
                billingAnchorDay={billingAnchorDay}
                billingPaymentInterval={billingPaymentInterval}
                activationDate={activationDate}
                billingAnchorDate={billingAnchorDate}
                intervalTotalPriceUsc={intervalTotalPriceUsc}
              />
            </div>
          )}
          <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between sm:gap-x-3">
            <div className="flex flex-row items-baseline sm:flex-col sm:items-start">
              <Typography.Text className="mr-2 text-xl font-semibold text-[#000] sm:mr-0">
                {primaryPriceLabel}
              </Typography.Text>
              <Typography.Text className="text-base font-normal text-[#8c8c8c]">
                / {secondaryPriceLabel}
              </Typography.Text>
            </div>
            <div className="flex w-full flex-col items-center gap-y-2 sm:w-auto sm:flex-row sm:items-center sm:gap-x-3">
              <Button
                type="link"
                size="large"
                className="px-0 text-[16px] leading-[24px] sm:px-4"
                onClick={openAdjustPricingModal}
              >
                Adjust Pricing & Tax
              </Button>
              <Button
                type="primary"
                className="min-h-[40px] w-full sm:w-auto sm:min-w-[280px]"
                size="large"
                data-testid="final-pricing-section-submit-button"
                onClick={onSubmit}
              >
                {actionButtonLabel}
              </Button>
            </div>
          </div>
        </div>
      </MediumCard>
      {adjustPricingModalOpen && (
        <AdjustPricingModal
          onClose={closeAdjustPricingModal}
          onSave={onAdjustFormSave}
        />
      )}
    </>
  )
}

type BannerProps = {
  children: React.ReactNode
  dataTestId?: string
}

const Banner = ({ children, dataTestId }: BannerProps) => {
  return (
    <Card
      style={{ borderColor: '#91caff', backgroundColor: '#e6f4ff' }}
      className="mb-3 w-full p-3"
      size="small"
      data-testid={dataTestId}
      bodyStyle={{
        color: '#e6f4ff',
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      <FaIconWithCircularBackground
        iconDefinition={faCircleDollar}
        color="#003eb3"
        diameterPx={32}
        backgroundColor="#bae0ff"
        iconClassName="text-[20px]"
      />
      <div className="ml-1 w-full text-left text-[#ad4d01]">{children}</div>
    </Card>
  )
}

type DelayedPaymentBannerProps = {
  accountContact: AccountContact
  billingAnchorDay: string
  billingPaymentInterval: MaintenancePlanPaymentInterval
  activationDate: LocalDateString
  billingAnchorDate?: LocalDateString
  intervalTotalPriceUsc: number
}

export const DelayedPaymentBanner: React.FC<DelayedPaymentBannerProps> = ({
  accountContact,
  billingAnchorDay,
  billingPaymentInterval,
  activationDate,
  billingAnchorDate,
  intervalTotalPriceUsc,
}) => {
  const tzId = useExpectedCompanyTimeZoneId()

  return (
    <Banner dataTestId="delayed-payment-banner">
      <div className="text-[14px] font-normal leading-[22px] text-[#000]">
        <span className="font-semibold">
          {accountContact.contact.firstName} will be billed{' '}
          {formatUsc(intervalTotalPriceUsc)} on{' '}
          {BzDateTime.fromDateString(
            billingAnchorDate ?? activationDate,
            tzId,
          ).toHumanFriendlyMonthDayYear()}
        </span>
        &nbsp;with subsequent billing on the{' '}
        {getBillingAnchorDayLabel(
          Number(billingAnchorDay),
          billingPaymentInterval,
        )}{' '}
        thereafter. The plan remains active until the first billing date to
        ensure uninterrupted service.
      </div>
    </Banner>
  )
}
