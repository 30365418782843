import cn from 'classnames'
import React from 'react'

export type WisetackLogoSize = 'sm' | 'md' | 'lg' | 'xl'
type LogoProps = {
  size?: WisetackLogoSize
}

export const WisetackLogo = React.memo<LogoProps>(({ size = 'lg' }) => (
  <div
    className={cn(
      'flex items-center justify-center',
      size === 'sm' ? 'h-6 w-6' : '',
      size === 'md' ? 'h-[36px] w-[36px]' : '',
      size === 'lg' ? 'h-11 w-11' : '',
      size === 'xl' ? 'h-12 w-12' : '',
    )}
  >
    <img src="/img/wisetack-logo.png" alt="Wisetack Logo" />
  </div>
))
