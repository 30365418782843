import { ComprehensiveLocationDetails } from '@breezy/backend/src/application-types'
import { LocationGuid } from '@breezy/shared'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { trpc } from '../trpc'

/** @deprecated use frontend hasura */
export const useFetchComprehensiveLocationDetails = ({
  locationGuid,
  opts = {},
}: {
  locationGuid: LocationGuid
  opts?: {
    enabled?: boolean
    onSuccess?: (locationJson: ComprehensiveLocationDetails) => void
  }
}) => {
  return trpc.location['comprehensive-location-details:query'].useQuery(
    {
      type: 'by-location-guid',
      companyGuid: useExpectedCompany().companyGuid,
      locationGuid,
    },
    {
      select: data => {
        if (data.length !== 1) {
          throw new Error(`Failed to locate location by id = ${locationGuid}`)
        }
        return new ComprehensiveLocationDetails(data[0])
      },
      enabled: opts.enabled,
      onSuccess: opts.onSuccess,
    },
  )
}
