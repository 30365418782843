import classNames from 'classnames'
import React from 'react'

type DetailRowProps = {
  label: string
  value: React.ReactNode
  className?: string
  labelClassName?: string
  valueClassName?: string
}

export const DetailRow = React.memo<DetailRowProps>(
  ({ label, value, className, labelClassName, valueClassName }) => {
    return (
      <div
        className={classNames(
          'flex flex-row items-center justify-between gap-8 py-2',
          className,
        )}
      >
        <div
          className={classNames(
            'flex-shrink-0 whitespace-nowrap font-semibold',
            labelClassName,
          )}
        >
          {label}
        </div>
        <div className={classNames('flex-1 text-right', valueClassName)}>
          {value}
        </div>
      </div>
    )
  },
)
