import {
  BusinessResourceConflictException,
  BusinessResourceDoesNotExistException,
  BzDateFns,
  ChronoUnit,
  Event,
  EventPayload,
  Forceable,
  IEventStore,
  IsoDateString,
  Log,
  Mutable,
  isNullish,
} from '../../common'

import {
  FreeMaintenancePlanActivatedEventData,
  FreeMaintenancePlanActivatedEventName,
  FreeMaintenancePlanCreditsGrantedEventName,
  ImportedMaintenancePlanActivatedEventData,
  ImportedMaintenancePlanActivatedEventName,
  MaintenancePlanActivatedEventData,
  MaintenancePlanActivatedEventName,
  MaintenancePlanActivatedWithOneTimePaymentEventData,
  MaintenancePlanActivatedWithOneTimePaymentEventName,
  MaintenancePlanCanceledEventData,
  MaintenancePlanCanceledEventName,
  MaintenancePlanCancellationReasonType,
  MaintenancePlanConfiguredEventData,
  MaintenancePlanConfiguredEventName,
  MaintenancePlanCreatedEventData,
  MaintenancePlanCreatedEventName,
  MaintenancePlanExpiredEventName,
  MaintenancePlanFixedDurationSetEventName,
  MaintenancePlanHistoryImportedEventData,
  MaintenancePlanHistoryImportedEventName,
  MaintenancePlanLapsedEventName,
  MaintenancePlanMigratedCreditsToVisitsEventData,
  MaintenancePlanMigratedCreditsToVisitsEventName,
  MaintenancePlanMigratedToStaticPricingEventData,
  MaintenancePlanMigratedToStaticPricingEventName,
  MaintenancePlanPaymentInterval,
  MaintenancePlanPaymentReceivedEventData,
  MaintenancePlanPaymentReceivedEventName,
  MaintenancePlanPricingSetEventData,
  MaintenancePlanPricingSetEventName,
  MaintenancePlanVisitCompletedEventData,
  MaintenancePlanVisitCompletedEventName,
  NumPeriodsPerYearForInterval,
} from '../../contracts'
import { ForCompany, ForCompanyUser } from '../Company/Company'
import { BzDateTime, UTC_TIME_ZONE } from '../DateTime/BzDateTime'
import { calculateSimplePriceOrderSummaryUsc } from '../Finance/Transactions/TransactionFunctionsUsc'
import { CartOrderSummaryUsc } from '../Finance/Transactions/TransactionTypes'
import { ForUser, UserGuidContainer } from '../Users/User'
import { Guid } from '../common-schemas'
import { MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION } from './MaintenancePlanConfigTypes'
import { MaintenancePlanMigrationInputData } from './MaintenancePlanMigrationTypes'
import { MaintenancePlanCreationRequest } from './MaintenancePlanRequestTypes'
import {
  BasicMaintenancePlanViewModel,
  BasicTemporalMaintenancePlanViewModel,
  MaintenancePlanEntityTypeName,
  MaintenancePlanGuidContainer,
  MaintenancePlanPaymentFlow,
  MaintenancePlanStatus,
  getLastVisitedAt,
  getNumActiveMaintenancePlanCredits,
  isCreditActive,
} from './MaintenancePlanTypes'

const notInitializedEx = () =>
  new BusinessResourceDoesNotExistException('Maintenance Plan cannot be found and most likely does not exist')

const emptyCart: CartOrderSummaryUsc = {
  totalPriceUsc: 0,
  taxAmountUsc: 0,
  discountAmountUsc: 0,
  upchargeAmountUsc: 0,
  subtotalPriceUsc: 0,
  creditAmountUsc: 0,
}

const withoutUserGuid = <TEventData>(e: UserGuidContainer & TEventData): Omit<TEventData, 'userGuid'> => {
  const { userGuid, ...eventData } = e
  return eventData
}

export class MaintenancePlan {
  private eventHistory: Event<unknown>[] = []
  private internalModel: Mutable<BasicMaintenancePlanViewModel> | undefined
  // NOTE: Injectable for Testing
  private readonly clock: () => IsoDateString

  static load = async (
    eventStore: IEventStore,
    req: ForCompany<MaintenancePlanGuidContainer>,
  ): Promise<MaintenancePlan> => new MaintenancePlan(eventStore).load(req)

  static readViewModel = async (
    eventStore: IEventStore,
    req: ForCompany<MaintenancePlanGuidContainer>,
  ): Promise<BasicTemporalMaintenancePlanViewModel> => {
    return await MaintenancePlan.load(eventStore, req).then(m => m.getComprehensiveViewModel())
  }

  static invoke = async (
    eventStore: IEventStore,
    req: ForCompany<MaintenancePlanGuidContainer>,
    f: (mp: MaintenancePlan) => Promise<void>,
  ): Promise<void> => {
    return MaintenancePlan.load(eventStore, req).then(f)
  }

  constructor(private readonly eventStore: IEventStore, clockOverride?: () => IsoDateString) {
    this.clock = clockOverride ? clockOverride : () => new Date().toISOString()
  }

  // Queries

  isInitialized = (): boolean => !!this.internalModel

  isFreePlan = (): boolean => !!this.internalModel?.isFreePlan

  getComprehensiveViewModel = (): BasicTemporalMaintenancePlanViewModel => {
    if (!this.internalModel) throw notInitializedEx()

    const numAvailableVisitCredits = getNumActiveMaintenancePlanCredits(this.internalModel.credits, this.clock())

    const lastVisitedAt = getLastVisitedAt({ credits: this.internalModel.credits })
    const numCreditsNeededToMigrate = this.internalModel.credits.filter(c => !c.visitGuid).length

    return { ...this.internalModel, numAvailableVisitCredits, lastVisitedAt, numCreditsNeededToMigrate }
  }

  // Commands

  //prettier-ignore
  init = async (req: ForCompanyUser<MaintenancePlanCreationRequest>): Promise<MaintenancePlan> => {
    if (this.isInitialized()) {
      throw new BusinessResourceConflictException('Maintenance Plan is already initialized')
    }

    this.internalModel = {
      ...req,
      ...emptyCart,
      maintenancePlanGuid: req.maintenancePlanGuid,
      maintenancePlanVersion: 0,
      status: MaintenancePlanStatus.NONE,
      isPricingInitialized: false,
      totalPaymentsReceivedUsc: 0,
      totalPaymentsEverReceivedUsc: 0,
      yearlyPriceUsc: 0,
      isFreePlan: false,
      paymentFlow: MaintenancePlanPaymentFlow.NONE,
      credits: [],
      createdByUserGuid: req.userGuid,
      payments: {},
      numConfigurationsEverSet: 0,
      isMigratedToStaticPricing: false,
    }

    await this.persistAndApplyEvent(this.createNextEvent<MaintenancePlanCreatedEventData>({
      eventType: MaintenancePlanCreatedEventName,
      eventData: {
        accountGuid: req.accountGuid,
        locationGuid: req.locationGuid,
        taxRate: req.taxRate,
      },
      userGuid: req.userGuid,
    }))


    if (req.configuration) {
      await this.setConfiguration({ ...req.configuration, userGuid: req.userGuid })
    }

    if (req.pricing) {
      await this.setPricing({ ...req.pricing, userGuid: req.userGuid })
    }

    return this
  }

  initMigrated = async (
    req: ForCompanyUser<MaintenancePlanMigrationInputData & { grantDeprecatedCredits: boolean }>,
  ): Promise<MaintenancePlan> => {
    await this.init(req)

    await this.pushForUser<MaintenancePlanHistoryImportedEventData>(MaintenancePlanHistoryImportedEventName, {
      userGuid: req.userGuid,
      activatedAt: req.activatedAt,
      numDaysUntilAutoCancelation: req.numDaysUntilAutoCancelation,
      historicalPaymentsReceived: req.historicalPaymentsReceived,
      historicalVisits: req.historicalVisits,
      grantDeprecatedCredits: req.grantDeprecatedCredits,
    })

    return this
  }

  load = async (req: ForCompany<MaintenancePlanGuidContainer>): Promise<MaintenancePlan> => {
    this.requireNotInitialized()
    const events = await this.eventStore.read({ entityGuid: req.maintenancePlanGuid, companyGuid: req.companyGuid })
    this.eventHistory = events
    events.map(this.applyEvent)
    return this
  }

  atInstant = (at: IsoDateString): MaintenancePlan => {
    this.internalModel = undefined
    this.eventHistory.filter(e => e.occurredAt <= at).map(e => this.applyEvent(e))
    return this
  }

  setConfiguration = async (req: ForUser<MaintenancePlanConfiguredEventData>): Promise<void> =>
    this.pushForUser(MaintenancePlanConfiguredEventName, req)

  setPricing = async (req: ForUser<MaintenancePlanPricingSetEventData>): Promise<void> =>
    this.pushForUser(MaintenancePlanPricingSetEventName, req)

  setAsFixedDuration = async (req: UserGuidContainer): Promise<void> =>
    this.pushForUser(MaintenancePlanFixedDurationSetEventName, req)

  activate = async (req: ForUser<MaintenancePlanActivatedEventData>): Promise<void> =>
    this.pushForUser(MaintenancePlanActivatedEventName, req)

  activateFreePlan = async (req: ForUser<FreeMaintenancePlanActivatedEventData>): Promise<void> => {
    this.requireInitialized()
    if (!this.internalModel?.isFreePlan) {
      throw new BusinessResourceConflictException(
        `Tried to activate free maintenance plan ${this.internalModel?.maintenancePlanGuid} but plan is not free.`,
      )
    }

    this.pushForUser(FreeMaintenancePlanActivatedEventName, req)
  }

  activateImported = async (req: ForUser<ImportedMaintenancePlanActivatedEventData>): Promise<void> => {
    this.requireInitialized()
    this.pushForUser(ImportedMaintenancePlanActivatedEventName, {
      userGuid: req.userGuid,
      activatedAt: req.activatedAt,
      numDaysUntilAutoCancelation: req.numDaysUntilAutoCancelation,
    })
  }

  activateWithOneTimePayment = async (
    req: ForUser<MaintenancePlanActivatedWithOneTimePaymentEventData>,
  ): Promise<void> => this.pushForUser(MaintenancePlanActivatedWithOneTimePaymentEventName, req)

  markCanceled = async (req: Forceable<ForUser<MaintenancePlanCanceledEventData>>): Promise<void> => {
    if (!req.force && this.internalModel?.status === MaintenancePlanStatus.CANCELED) return

    return this.pushForUser(MaintenancePlanCanceledEventName, req)
  }

  markLapsed = async (req: UserGuidContainer): Promise<void> => {
    if (this.internalModel?.status === MaintenancePlanStatus.LAPSED) return

    return this.pushForUser(MaintenancePlanLapsedEventName, req)
  }

  markExpired = async (req: UserGuidContainer): Promise<void> => {
    if (this.internalModel?.status === MaintenancePlanStatus.EXPIRED) return
    if (this.internalModel?.status !== MaintenancePlanStatus.ACTIVE) {
      throw new BusinessResourceConflictException(
        `Tried to mark maintenance plan ${this.internalModel?.maintenancePlanGuid} as expired but plan is not active`,
      )
    }

    return this.pushForUser(MaintenancePlanExpiredEventName, req)
  }

  recordPayment = async (req: ForUser<MaintenancePlanPaymentReceivedEventData>): Promise<void> => {
    if ((this.internalModel?.payments[req.paymentRecordGuid] ?? 0) > 0) return

    return this.pushForUser(MaintenancePlanPaymentReceivedEventName, req)
  }

  recordVisitCreditUsed = async (req: ForUser<MaintenancePlanVisitCompletedEventData>): Promise<void> =>
    this.pushForUser(MaintenancePlanVisitCompletedEventName, req)

  grantCreditsForFreePlanIfEligible = async (req: UserGuidContainer): Promise<boolean> => {
    this.requireInitialized()

    if (!this.internalModel?.isFreePlan) {
      Log.warn(`Tried to grant credits for a free plan but plan is not free`, { vm: this.internalModel })
      return false
    }

    if (vmIsFreePlanEligibleForCreditGrant(this.internalModel, this.clock())) {
      this.pushForUser(FreeMaintenancePlanCreditsGrantedEventName, req)
      return true
    } else {
      return false
    }
  }

  recordCreditsMigratedToVisits = async (
    req: ForUser<MaintenancePlanMigratedCreditsToVisitsEventData>,
  ): Promise<void> => {
    this.requireInitialized()

    this.pushForUser(MaintenancePlanMigratedCreditsToVisitsEventName, req)

    if (req.migrations.length !== this.internalModel?.credits.length) {
      Log.warn(`Migrated credits to visits but number of migrations does not match number of credits`, {
        vm: this.internalModel,
      })
    }
  }

  migrateToStaticPricing = async (req: ForUser<MaintenancePlanMigratedToStaticPricingEventData>): Promise<void> => {
    if (this.internalModel?.isMigratedToStaticPricing) return
    this.pushForUser(MaintenancePlanMigratedToStaticPricingEventName, req)
  }

  // Internal

  private applyEvent = (e: Event<unknown>): void => {
    if (e.eventType === MaintenancePlanCreatedEventName) {
      const { entityGuid, companyGuid, entityVersion } = e
      const { accountGuid, locationGuid, taxRate } = e.eventData as MaintenancePlanCreatedEventData
      this.internalModel = {
        ...emptyCart,
        maintenancePlanGuid: entityGuid,
        maintenancePlanVersion: entityVersion,
        companyGuid,
        accountGuid,
        locationGuid,
        status: MaintenancePlanStatus.NONE,
        totalPaymentsReceivedUsc: 0,
        totalPaymentsEverReceivedUsc: 0,
        credits: [],
        isPricingInitialized: false,
        yearlyPriceUsc: 0,
        paymentFlow: MaintenancePlanPaymentFlow.NONE,
        isFreePlan: false,
        taxRate,
        createdByUserGuid: e.actingUserGuid,
        payments: {},
        numConfigurationsEverSet: 0,
        isMigratedToStaticPricing: false,
      }
    }

    if (!this.internalModel) throw notInitializedEx()

    this.internalModel.maintenancePlanVersion = e.entityVersion

    if (e.eventType === MaintenancePlanConfiguredEventName) {
      this.internalModel.configuration = e.eventData as MaintenancePlanConfiguredEventData
      this.internalModel.numConfigurationsEverSet++
      const status = this.internalModel.status
      if (
        !this.internalModel.paymentSubscriptionGuid ||
        (status !== MaintenancePlanStatus.ACTIVE && status !== MaintenancePlanStatus.LAPSED)
      )
        this.internalModel.status = MaintenancePlanStatus.PENDING
    }

    if (e.eventType === MaintenancePlanActivatedEventName) {
      const { customBillingStartAt, numDaysUntilAutoCancelation } = e.eventData as MaintenancePlanActivatedEventData
      const billingStartAt = customBillingStartAt ?? e.occurredAt
      // "Forgets" history of previous plan upon activation of a new plan
      if (this.internalModel.status === MaintenancePlanStatus.CANCELED) {
        this.internalModel.payments = {}
        this.internalModel.totalPaymentsReceivedUsc = 0
      }
      vmSetActivatedAt(this.internalModel, e.occurredAt, numDaysUntilAutoCancelation)

      this.internalModel.paymentSubscriptionGuid = (
        e.eventData as MaintenancePlanActivatedEventData
      ).paymentSubscriptionGuid
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.AUTO
      this.internalModel.customBillingStartAt = customBillingStartAt
      this.internalModel.billingStartAt = billingStartAt
    }

    if (e.eventType === FreeMaintenancePlanActivatedEventName) {
      if (!this.internalModel.isFreePlan) {
        Log.error(`Should not have activated a free plan if the plan was not free`, { vm: this.internalModel })
        return
      }

      const { numDaysUntilAutoCancelation, grantDeprecatedCredits } =
        e.eventData as FreeMaintenancePlanActivatedEventData

      vmSetActivatedAt(this.internalModel, e.occurredAt, numDaysUntilAutoCancelation)

      this.internalModel.paymentFlow = isNullish(numDaysUntilAutoCancelation)
        ? MaintenancePlanPaymentFlow.AUTO
        : MaintenancePlanPaymentFlow.MANUAL

      // Since the plan is free, automatically grant credits at activation
      if (grantDeprecatedCredits) {
        vmGrantCreditsAsAppropriate(this.internalModel, e.occurredAt)
      }
    }

    if (e.eventType === ImportedMaintenancePlanActivatedEventName) {
      const { activatedAt, numDaysUntilAutoCancelation } = e.eventData as ImportedMaintenancePlanActivatedEventData
      vmSetActivatedAt(this.internalModel, activatedAt, numDaysUntilAutoCancelation)
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.MANUAL
    }

    if (e.eventType === MaintenancePlanFixedDurationSetEventName) {
      this.internalModel.terminatesAt = vmCalcTerminatesAt(
        e.occurredAt,
        MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION,
      )
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.MANUAL
    }

    if (e.eventType === MaintenancePlanActivatedWithOneTimePaymentEventName) {
      const { paymentRecordGuid, paymentAmountUsc, numDaysUntilAutoCancelation, grantDeprecatedCredits } =
        e.eventData as MaintenancePlanActivatedWithOneTimePaymentEventData

      // "Forgets" history of previous plan upon activation of a new plan
      if (this.internalModel.status === MaintenancePlanStatus.CANCELED) {
        this.internalModel.payments = {}
        this.internalModel.totalPaymentsReceivedUsc = 0
      }

      this.internalModel.payments[paymentRecordGuid] = paymentAmountUsc
      this.internalModel.totalPaymentsReceivedUsc += paymentAmountUsc
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.MANUAL
      if (this.internalModel.paymentInterval !== MaintenancePlanPaymentInterval.YEARLY) {
        const previousPaymentInterval = this.internalModel.paymentInterval
        this.internalModel.paymentInterval = MaintenancePlanPaymentInterval.YEARLY
        if (previousPaymentInterval === MaintenancePlanPaymentInterval.MONTHLY) {
          this.internalModel.subtotalPriceUsc = this.internalModel.subtotalPriceUsc * 12
          this.internalModel.discountAmountUsc = this.internalModel.discountAmountUsc * 12
          this.internalModel.upchargeAmountUsc = this.internalModel.upchargeAmountUsc * 12
          this.updatePricingSummary()
        }
        if (previousPaymentInterval === MaintenancePlanPaymentInterval.QUARTERLY) {
          this.internalModel.subtotalPriceUsc = this.internalModel.subtotalPriceUsc * 4
          this.internalModel.discountAmountUsc = this.internalModel.discountAmountUsc * 4
          this.internalModel.upchargeAmountUsc = this.internalModel.upchargeAmountUsc * 4
          this.updatePricingSummary()
        }
      }

      vmSetActivatedAt(this.internalModel, e.occurredAt, numDaysUntilAutoCancelation)
      if (grantDeprecatedCredits) {
        vmGrantCreditsAsAppropriate(this.internalModel, e.occurredAt)
      }
    }

    if (e.eventType === MaintenancePlanHistoryImportedEventName) {
      const {
        activatedAt,
        numDaysUntilAutoCancelation,
        historicalPaymentsReceived,
        historicalVisits,
        grantDeprecatedCredits,
      } = e.eventData as MaintenancePlanHistoryImportedEventData
      vmSetActivatedAt(this.internalModel, activatedAt, numDaysUntilAutoCancelation)
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.MANUAL
      for (let i = 0; i < historicalPaymentsReceived.length; i++) {
        const payment = historicalPaymentsReceived[i]
        this.internalModel.payments[payment.paymentRecordGuid ?? i] = payment.paymentAmountUsc
        this.internalModel.totalPaymentsReceivedUsc += payment.paymentAmountUsc
      }
      if (grantDeprecatedCredits) {
        vmGrantCreditsAsAppropriate(this.internalModel, activatedAt)
      }
      if (historicalVisits) {
        for (let j = 0; j < historicalVisits.length; j++) {
          vmMarkVisitCreditFulfilled(this.internalModel, historicalVisits[j].visitAt, e.occurredAt)
        }
      }
    }

    if (e.eventType === MaintenancePlanCanceledEventName) {
      const data = e.eventData as MaintenancePlanCanceledEventData
      this.internalModel.status = MaintenancePlanStatus.CANCELED
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.NONE
      this.internalModel.lapsedAt = undefined
      this.internalModel.credits = []
      this.internalModel.cancellation = {
        canceledAt: e.occurredAt,
        cancellationReason:
          data.cancellationReason ?? this.internalModel.cancellation?.cancellationReason ?? 'Unspecified',
        cancellationReasonType:
          data.cancellationReasonType ??
          this.internalModel.cancellation?.cancellationReasonType ??
          MaintenancePlanCancellationReasonType.OTHER,
        suppressCancellationEmail: data.suppressCancellationEmail ?? false,
        shouldExpireVisitsImmediately: data.shouldExpireVisitsImmediately ?? false,
      }
      this.internalModel.paymentSubscriptionGuid = undefined
      this.internalModel.terminatesAt = undefined
    }

    if (e.eventType === MaintenancePlanLapsedEventName) {
      this.internalModel.status = MaintenancePlanStatus.LAPSED
      this.internalModel.lapsedAt = e.occurredAt
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.NONE
      this.internalModel.cancellation = undefined
    }

    if (e.eventType === MaintenancePlanExpiredEventName) {
      this.internalModel.status = MaintenancePlanStatus.EXPIRED
      this.internalModel.paymentFlow = MaintenancePlanPaymentFlow.NONE
      this.internalModel.expiredAt = e.occurredAt
      this.internalModel.lapsedAt = undefined
      this.internalModel.cancellation = undefined
    }

    if (e.eventType === MaintenancePlanPricingSetEventName) {
      const { subtotalPeriodPriceUsc, discountPeriodAmountUsc, paymentInterval, upchargePeriodAmountUsc } =
        e.eventData as MaintenancePlanPricingSetEventData
      this.internalModel.subtotalPriceUsc = subtotalPeriodPriceUsc
      this.internalModel.discountAmountUsc = discountPeriodAmountUsc
      this.internalModel.upchargeAmountUsc = upchargePeriodAmountUsc ?? 0
      this.internalModel.paymentInterval = paymentInterval
      this.updatePricingSummary()

      this.internalModel.isPricingInitialized = true
      this.internalModel.isFreePlan = this.internalModel.totalPriceUsc === 0
    }

    if (e.eventType === MaintenancePlanPaymentReceivedEventName) {
      const { paymentRecordGuid, paymentAmountUsc, isOneTimePayment, grantDeprecatedCredits } =
        e.eventData as MaintenancePlanPaymentReceivedEventData
      this.internalModel.payments[paymentRecordGuid] = paymentAmountUsc
      this.internalModel.totalPaymentsReceivedUsc += paymentAmountUsc

      if (
        this.internalModel.status === MaintenancePlanStatus.PENDING ||
        this.internalModel.status === MaintenancePlanStatus.LAPSED
      )
        this.internalModel.status = MaintenancePlanStatus.ACTIVE

      if (this.internalModel.paymentFlow === MaintenancePlanPaymentFlow.NONE)
        this.internalModel.paymentFlow = isOneTimePayment
          ? MaintenancePlanPaymentFlow.MANUAL
          : MaintenancePlanPaymentFlow.AUTO

      if (grantDeprecatedCredits) {
        vmGrantCreditsAsAppropriate(this.internalModel, e.occurredAt)
      }
    }

    if (e.eventType === MaintenancePlanVisitCompletedEventName) {
      vmMarkVisitCreditFulfilled(this.internalModel, e.occurredAt, this.clock())
    }

    if (e.eventType === FreeMaintenancePlanCreditsGrantedEventName) {
      vmGrantCreditsAsAppropriateForFreePlan(this.internalModel, e.occurredAt)
    }

    if (e.eventType === MaintenancePlanMigratedCreditsToVisitsEventName) {
      const data = e.eventData as MaintenancePlanMigratedCreditsToVisitsEventData
      const migratedCreditsMap = data.migrations.reduce((m, c) => {
        m[c.creditIndex] = c.visitGuid
        return m
      }, {} as Record<number, Guid>)

      const updatedCredits = this.internalModel.credits.map((c, idx) => ({
        ...c,
        visitGuid: migratedCreditsMap[idx],
      }))
      this.internalModel.credits = updatedCredits
    }

    if (e.eventType === MaintenancePlanMigratedToStaticPricingEventName) {
      const data = e.eventData as MaintenancePlanMigratedToStaticPricingEventData
      if (this.internalModel && this.internalModel.configuration) {
        this.internalModel.configuration.yearlyStaticPriceUsc = data.yearlyStaticPriceUsc
        this.internalModel.isMigratedToStaticPricing = true
      }
    }

    return
  }

  private updatePricingSummary = () => {
    if (!this.internalModel) throw notInitializedEx()
    const vm = this.internalModel
    if (!vm.paymentInterval) return

    const periodCartSummary = calculateSimplePriceOrderSummaryUsc(
      vm.subtotalPriceUsc,
      vm.discountAmountUsc,
      vm.upchargeAmountUsc,
      vm.taxRate?.rate ?? 0,
    )
    this.internalModel = {
      ...vm,
      ...periodCartSummary,
      yearlyPriceUsc: Math.trunc(periodCartSummary.totalPriceUsc * NumPeriodsPerYearForInterval[vm.paymentInterval]),
    }
  }

  private requireNotInitialized = (): void => {
    if (this.isInitialized()) {
      throw new BusinessResourceConflictException('Maintenance Plan is already initialized')
    }
  }

  private requireInitialized = (): void => {
    if (!this.isInitialized()) {
      throw notInitializedEx()
    }
  }

  private pushForUser = async <TEventData>(eventType: string, eventDataForUser: ForUser<TEventData>): Promise<void> =>
    await this.push({ eventType, eventData: withoutUserGuid(eventDataForUser), userGuid: eventDataForUser.userGuid })

  private push = async <TEventData>({
    eventType,
    eventData,
    userGuid,
  }: ForUser<EventPayload<TEventData>>): Promise<void> => {
    this.requireInitialized()
    return this.persistAndApplyEvent(this.createNextEvent({ eventType, eventData, userGuid }))
  }

  private persistAndApplyEvent = async (e: Event<unknown>): Promise<void> => {
    await this.eventStore.create(e)
    this.applyEvent(e)
  }

  private createNextEvent = <TEventData>({
    eventType,
    eventData,
    userGuid,
  }: ForUser<EventPayload<TEventData>>): Event<TEventData> => {
    const { maintenancePlanGuid, companyGuid, maintenancePlanVersion } = this.getComprehensiveViewModel()

    return {
      companyGuid,
      entityType: MaintenancePlanEntityTypeName,
      entityGuid: maintenancePlanGuid,
      entityVersion: maintenancePlanVersion + 1,
      actingUserGuid: userGuid,
      occurredAt: this.clock(),
      eventType,
      eventData,
    }
  }
}

const vmSetActivatedAt = (
  vm: Mutable<BasicMaintenancePlanViewModel>,
  activatedAt: IsoDateString,
  numDaysUntilAutoCancelation?: number,
) => {
  // NOTE: In case of a secondary activation, for example due to migration, original activation date is kept
  if (!vm.activatedAt || vm.status !== MaintenancePlanStatus.ACTIVE) {
    vm.activatedAt = activatedAt
  }
  vm.status = MaintenancePlanStatus.ACTIVE
  vm.lapsedAt = undefined
  vm.cancellation = undefined
  vm.terminatesAt = vmCalcTerminatesAt(activatedAt, numDaysUntilAutoCancelation)
}

const vmCalcTerminatesAt = (
  activatedAt: IsoDateString,
  numDaysUntilAutoCancelation?: number,
): IsoDateString | undefined =>
  numDaysUntilAutoCancelation
    ? BzDateFns.withUtc(activatedAt, d => BzDateFns.addDays(d, numDaysUntilAutoCancelation))
    : undefined

const vmMarkVisitCreditFulfilled = (
  vm: Mutable<BasicMaintenancePlanViewModel>,
  fulfilledAt: IsoDateString,
  nowIsoString: IsoDateString,
) => {
  const idx = vm.credits.findIndex(c => isCreditActive(c, nowIsoString))
  if (idx > -1) vm.credits[idx] = { ...vm.credits[idx], fulfilledAt }
}

const vmGrantCreditsAsAppropriate = (vm: Mutable<BasicMaintenancePlanViewModel>, grantAt: IsoDateString) => {
  if (vm.totalPriceUsc === 0) {
    vmGrantCreditsAsAppropriateForFreePlan(vm, grantAt)
  } else {
    vmGrantCreditsAsAppropriateForNonFreePlan(vm, grantAt)
  }
}

const vmGrantCreditsAsAppropriateForNonFreePlan = (
  vm: Mutable<BasicMaintenancePlanViewModel>,
  grantAt: IsoDateString,
) => {
  if (!!vm.configuration && vm.paymentInterval) {
    const numPeriodsPerYear = NumPeriodsPerYearForInterval[vm.paymentInterval]
    const numPaymentsReceived = Object.values(vm.payments).length
    const numAnnualPeriodsOfCredits = Math.ceil(numPaymentsReceived / numPeriodsPerYear)

    const beforeNumAnnualPeriodsOfCredits = vm.credits.length / vm.configuration.numVisitsPerYear
    for (let i = 0; i < numAnnualPeriodsOfCredits - beforeNumAnnualPeriodsOfCredits; i++)
      for (let j = 0; j < vm.configuration.numVisitsPerYear; j++)
        vm.credits.push({
          issuedAt: grantAt,
          expiresAt: BzDateTime.fromIsoString(grantAt, UTC_TIME_ZONE)
            .plusDays(vm.configuration.numDaysUntilVisitCreditExpiration)
            .toIsoDateString(),
        })
  } else {
    Log.error('Cannot grant Maintenance Plan Credits without configuration and/or pricing set', { vm })
  }
}

const vmCalculateExpectedCreditsForFreePlan = (
  vm: Mutable<BasicMaintenancePlanViewModel>,
  grantAt: IsoDateString,
): number => {
  // A free Maintenance Plan wont have any payments, so we grant credits based on the time between the plan's activation
  // time and the specified grant time
  if (!!vm.configuration && vm.paymentInterval && vm.activatedAt) {
    const activatedDateTime = BzDateTime.fromIsoString(vm.activatedAt, UTC_TIME_ZONE).toJsJoda()
    const grantDateTime = BzDateTime.fromIsoString(grantAt, UTC_TIME_ZONE).toJsJoda()

    if (grantDateTime.isBefore(activatedDateTime)) {
      Log.warn(`Tried to get get expected number of credits for a free plan with a grant time before activation time`, {
        vm,
      })
      return 0
    }

    const differenceInYears = ChronoUnit.YEARS.between(activatedDateTime, grantDateTime)

    return vm.configuration.numVisitsPerYear + Math.floor(differenceInYears) * vm.configuration.numVisitsPerYear
  } else {
    return 0
  }
}

const vmIsFreePlanEligibleForCreditGrant = (
  vm: Mutable<BasicMaintenancePlanViewModel>,
  grantAt: IsoDateString,
): boolean => {
  return vmCalculateExpectedCreditsForFreePlan(vm, grantAt) > vm.credits.length
}

const vmGrantCreditsAsAppropriateForFreePlan = (vm: Mutable<BasicMaintenancePlanViewModel>, grantAt: IsoDateString) => {
  if (!!vm.configuration && vm.paymentInterval && vm.activatedAt) {
    const expectedNumberOfCredits = vmCalculateExpectedCreditsForFreePlan(vm, grantAt)

    for (let i = vm.credits.length; i < expectedNumberOfCredits; i++) {
      vm.credits.push({
        issuedAt: grantAt,
        expiresAt: BzDateTime.fromIsoString(grantAt, UTC_TIME_ZONE)
          .plusDays(vm.configuration.numDaysUntilVisitCreditExpiration)
          .toIsoDateString(),
      })
    }
  } else {
    Log.error('Cannot grant Maintenance Plan Credits without configuration, activation time, and/or pricing set', {
      vm,
    })
  }
}
