import {
  PermissionV1,
  PermissionV2,
  User,
  principalHasPermission,
} from '@breezy/shared'
import { useMemo } from 'react'

export const useIsPrincipalAuthorized = (
  principal: User | null,
  to?: PermissionV1 | PermissionV2,
) => {
  return useMemo(() => {
    if (!principal || !to) return false

    return principalHasPermission(principal, to)
  }, [principal, to])
}
