import { bzOptional } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FormBody from '../../../../elements/Forms/FormBody'
import FormHeader from '../../../../elements/Forms/FormHeader'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { TextAreaField } from '../../../../elements/Forms/TextAreaField'
import {
  SetIsDirty,
  useExternalIsDirty,
} from '../../../../hooks/useExternalIsDirty'
import { OtherPaymentDto } from '../OtherPaymentWorkflow/OtherPaymentWorkflow'
import PaymentFooterButtons from '../PaymentFooterButtons/PaymentFooterButtons'

const CashFormSchema = z.object({
  note: bzOptional(z.string()).describe('Note'),
})

export type CashFormData = z.infer<typeof CashFormSchema>

type CashFormProps = {
  onCancel?: () => void
  onSubmit: (data: OtherPaymentDto) => void
  isLoading: boolean
  footerClassName?: string
  setIsDirty?: SetIsDirty
}

const CashForm = React.memo<CashFormProps>(
  ({ onCancel, onSubmit, isLoading, footerClassName, setIsDirty }) => {
    const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
    } = useForm<CashFormData>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(CashFormSchema),
    })

    useExternalIsDirty(setIsDirty, isDirty)

    const onSubmitInner = useCallback(
      (data: CashFormData) => {
        onSubmit({
          note: data.note,
        })
      },
      [onSubmit],
    )

    return (
      <Form
        className="flex min-h-0 flex-1 flex-col"
        layout="vertical"
        onSubmitCapture={handleSubmit(onSubmitInner)}
      >
        <FormBody>
          <FormHeader>Payment Information</FormHeader>
          <ReactHookFormItem
            control={control}
            className="flex-1"
            name="note"
            label="Payment Memo"
            errors={errors}
            render={({ field }) => (
              <TextAreaField
                {...field}
                placeholder="(Optional) Additional details about the payment"
              />
            )}
          />
        </FormBody>
        <PaymentFooterButtons
          onCancel={onCancel}
          isLoading={isLoading}
          formStyle="modal"
          primaryButtonText="Record Payment"
          footerClassName={footerClassName}
          dataDdActionName="BZ Payment Workflow - Pay - Cash"
        />
      </Form>
    )
  },
)

export default CashForm
