import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import { JobGuid } from '@breezy/shared'
import { UseQueryResult } from '@tanstack/react-query'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { trpc } from '../trpc'

/** @deprecated use frontend hasura */
export const useFetchComprehensiveJobDetailsByJobGuidQuery = ({
  noCache = false,
  jobGuid,
  opts = {
    enabled: true,
  },
}: {
  noCache?: boolean
  jobGuid?: JobGuid
  opts?: {
    enabled?: boolean
    onSuccess?: (comprehensiveJobDetails: ComprehensiveJobDetails) => void
  }
}): UseQueryResult<ComprehensiveJobDetails> => {
  const finalJobGuid = jobGuid ?? ''

  return trpc.jobs['comprehensive-job-details:query'].useQuery(
    {
      type: 'by-job-guid',
      companyGuid: useExpectedCompany().companyGuid,
      jobGuid: finalJobGuid,
    },
    {
      select: data => {
        if (data.length !== 1) {
          throw new Error(`Failed to locate job by guid = ${finalJobGuid}`)
        }
        return new ComprehensiveJobDetails(data[0])
      },
      enabled: !!jobGuid && opts.enabled,
      onSuccess: opts.onSuccess,
      staleTime: noCache ? 0 : undefined,
      cacheTime: noCache ? 0 : undefined,
    },
  )
}
