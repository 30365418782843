import cn from 'classnames'
import { useAppContext } from '../../providers/AppContextWrapper'
import type { Stylable } from '../../utils/Stylable'
import { Applications } from '../../utils/application-type'
import { m } from '../../utils/react-utils'
import './Layout.less'
import type { Breakpoint } from './applyBreakpointProperties'
import { applyBreakpointProperties } from './applyBreakpointProperties'

export const COLUMN_GAPS = {
  xs: 8,
  sm: 8,
  md: 16,
  lg: 16,
  xl: 16,
  '2xl': 16,
} as const satisfies Record<Breakpoint, number>

export const ROW_GAPS = {
  xs: 8,
  sm: 8,
  md: 16,
  lg: 16,
  xl: 16,
  '2xl': 16,
} as const satisfies Record<Breakpoint, number>

// TODO: Set this once all other margins are removed.
export const PAGE_MARGINS = {
  xs: 8,
  sm: 8,
  md: 16,
  lg: 16,
  xl: 16,
  '2xl': 16,
} as const satisfies Record<Breakpoint, number>

type LayoutPageProps = Stylable & {
  maxSize?: Breakpoint
  // TODO: Remove when layouts are fully refactored.
  padded?: boolean
  children: React.ReactNode
}

export const LayoutPage = m<LayoutPageProps>(
  ({ children, style, className, maxSize, padded: paddedProp }) => {
    const isTechApp = useAppContext().appType === Applications.TECHNICIAN
    const padded = isTechApp ? false : paddedProp

    return (
      <div
        className={cn(
          'layout__page h-full',
          {
            'layout__page--xs': maxSize === 'xs',
            'layout__page--sm': maxSize === 'sm',
            'layout__page--md': maxSize === 'md',
            'layout__page--lg': maxSize === 'lg',
            'layout__page--xl': maxSize === 'xl',
            'layout__page--2xl': maxSize === '2xl',
          },
          className,
        )}
        style={{
          ...applyBreakpointProperties(breakpoint => {
            const paddingX = padded ? PAGE_MARGINS[breakpoint] : 0
            const paddingY = padded ? ROW_GAPS[breakpoint] : 0
            const calculatedMargin = paddingX - COLUMN_GAPS[breakpoint] / 2

            // Only adjust width if margins are positive.
            // Negative margins won't affect containers.
            const widthAdjustment =
              calculatedMargin > 0 ? 2 * calculatedMargin : 0

            return {
              width: `calc(100% - ${widthAdjustment}px)`,
              marginLeft: `${calculatedMargin}px`,
              marginRight: `${calculatedMargin}px`,
              padding: `${paddingY}px 0px`,
              rowGap: `${ROW_GAPS[breakpoint]}px`,
            }
          }),
          ...style,
        }}
      >
        {children}
      </div>
    )
  },
)
