import {
  BzDateTime,
  CalculatePaths,
  Guid,
  IsoDateString,
  MaintenancePlanPaymentFlow,
  MaintenancePlanStatus,
  maintenancePlanStatusDisplayNames,
} from '@breezy/shared'
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'

type MaintenancePlanStatusTagProps = {
  status: MaintenancePlanStatus
  paymentFlow: MaintenancePlanPaymentFlow
  cancellationReason?: string
  canceledAt?: IsoDateString
  maintenancePlanGuid?: Guid
  paymentSubscriptionGuid?: Guid
}

const statusTagColors: Record<MaintenancePlanStatus, StatusTagColor> = {
  [MaintenancePlanStatus.ACTIVE]: 'green',
  [MaintenancePlanStatus.LAPSED]: 'red',
  [MaintenancePlanStatus.PENDING]: 'volcano',
  [MaintenancePlanStatus.EXPIRED]: 'orange',
  [MaintenancePlanStatus.CANCELED]: 'gray',
  [MaintenancePlanStatus.NONE]: 'gray',
}

const MaintenancePlanStatusTag = m<MaintenancePlanStatusTagProps>(
  ({ status, maintenancePlanGuid, cancellationReason, canceledAt }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const link = useMemo(() => {
      if (status === MaintenancePlanStatus.PENDING && maintenancePlanGuid)
        return {
          uri: CalculatePaths.maintenancePlanPay({ maintenancePlanGuid }),
          tooltip: 'Collect Payment',
          icon: (
            <FontAwesomeIcon
              icon={faMoneyBill}
              className="ml-1 text-[#389e0d]"
            />
          ),
        }

      return undefined
    }, [status, maintenancePlanGuid])

    const text = useMemo(
      () => maintenancePlanStatusDisplayNames[status],
      [status],
    )

    const inner: React.ReactNode = (
      <StatusTag text={text} color={statusTagColors[status]} border="strong" />
    )

    if (link)
      return (
        <Tooltip title={link.tooltip ?? 'Maintenance Plan Status'}>
          <Link to={link.uri} className="react-plain-link">
            {inner} {link.icon}
          </Link>
        </Tooltip>
      )

    if (
      status === MaintenancePlanStatus.CANCELED &&
      canceledAt &&
      cancellationReason
    )
      return (
        <Tooltip
          title={`${BzDateTime.fromIsoString(
            canceledAt,
            tzId,
          ).toLocalDateString()} - ${cancellationReason}`}
        >
          {inner}
        </Tooltip>
      )

    return inner
  },
)

export default MaintenancePlanStatusTag
