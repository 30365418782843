import { AccountContact } from '@breezy/shared'
import React from 'react'
import StatusTag from '../../../elements/StatusTag/StatusTag'

export const AccountContactTag = React.memo<{ accountContact: AccountContact }>(
  ({ accountContact }) => {
    if (accountContact.primary) {
      return <StatusTag text={'Primary'} color="yellow" border="strong" />
    } else if (accountContact.archived) {
      return <StatusTag text={'Archived'} color="gray" border="strong" />
    } else {
      return null
    }
  },
)
