import { Guid, usCentsToUsd } from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import {
  ItemPicker,
  ItemPickerItem,
} from '../../components/Pricebook/ItemPicker'
import { OptionCartItem } from './estimatesFlowUtils'

type LineItemImagePickerProps = {
  lineItems: OptionCartItem[]
  onSave: (selectedItemGuid: Guid) => void
  onCancel: () => void
}

export const LineItemImagePicker = React.memo<LineItemImagePickerProps>(
  ({ lineItems, onSave, onCancel }) => {
    const items = useMemo<ItemPickerItem[]>(
      () =>
        lineItems.map(item => ({
          id: item.cartItemGuid,
          name: item.name,
          value: usCentsToUsd(item.unitPriceUsc),
          cdnUrl: item.photoCdnUrl,
        })),
      [lineItems],
    )
    const [selectedItemCountMap, setSelectedItemCountMap] = useState<
      Record<string, number>
    >({})
    const handleSave = useCallback(() => {
      const selectedItems = Object.entries(selectedItemCountMap)
        .filter(([_, count]) => count > 0)
        .map(([id]) => id)

      if (selectedItems.length > 0) {
        onSave(selectedItems[0])
      }
    }, [selectedItemCountMap, onSave])

    const onItemSelect = useCallback((id: string, count: number) => {
      setSelectedItemCountMap(prev => {
        const newMap = { ...prev, [id]: count }
        // Ensure only one item is selected at a time
        Object.keys(newMap).forEach(key => {
          if (key !== id) newMap[key] = 0
        })
        return newMap
      })
    }, [])

    return (
      <ItemPicker
        items={items}
        title="Select line item"
        onCancel={onCancel}
        onSave={handleSave}
        onBackFallback={onCancel}
        selectedItemCountMap={selectedItemCountMap}
        onItemSelect={onItemSelect}
        canSelectMultiple={false}
        hideMultipleQuantityPicker
        hideCart
        itemLabel="Save"
        addText=""
        emptyState="Add a line item to select its photo."
        enablePhotos
      />
    )
  },
)
