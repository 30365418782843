import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { Tag } from 'antd'
import { memo } from 'react'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'

const SUCCESS_ICON_COLOR = '#237804'
const SUCCESS_ICON_BG_COLOR = '#D9F7BE'

const FAILURE_ICON_COLOR = '#AD4E00'
const FAILURE_ICON_BG_COLOR = '#FFE7BA'

const SUCCESS_TEXT_COLOR = '#237804'
const FAILURE_TEXT_COLOR = '#AD4E00'

type JobOutcomeChipProps = {
  label: string
  success: boolean
  resultText?: string
  iconDefinition: IconDefinition
}

const JobOutcomeChip = memo<JobOutcomeChipProps>(
  ({ label, success, resultText, iconDefinition }) => {
    const iconColor = success ? SUCCESS_ICON_COLOR : FAILURE_ICON_COLOR

    const iconBackgroundColor = success
      ? SUCCESS_ICON_BG_COLOR
      : FAILURE_ICON_BG_COLOR

    return (
      <Tag
        style={{
          borderRadius: '100px',
          border: `1px solid #D9D9D9`,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '4px 10px 4px 4px',
          fontSize: '14px',
        }}
      >
        <FaIconWithCircularBackground
          iconDefinition={iconDefinition}
          color={iconColor}
          backgroundColor={iconBackgroundColor}
          diameterPx={26}
        />
        {label}
        {resultText && (
          <strong
            style={
              success
                ? { color: SUCCESS_TEXT_COLOR, paddingLeft: '8px' }
                : { color: FAILURE_TEXT_COLOR, paddingLeft: '8px' }
            }
          >
            {resultText}
          </strong>
        )}
      </Tag>
    )
  },
)

export default JobOutcomeChip
