import { ChronoUnit, DayOfWeek, ZoneId, ZonedDateTime } from '@js-joda/core'
import '@js-joda/timezone'
import { z } from 'zod'
import { ForAddonFields } from '../domain'
import { BzDateFns, IsoDateString, TimeZoneId } from './BzDateFns'
import { MissingCaseError } from './errors/PlatformErrors'
import type { AsyncFn } from './functional-core'

const REPORTING_TIME_UNITS = ['days', 'weeks', 'months', 'years'] as const

export type ReportingTimeUnit = (typeof REPORTING_TIME_UNITS)[number]

type ReportingTimeWindow = {
  readonly unit: ReportingTimeUnit
  readonly start: IsoDateString
  readonly end: IsoDateString
}

type PastNTimeWindowFnsProps = {
  n: number
  timeZoneId: string
  endDateTime: IsoDateString
}

export const timePastNReportRequestSchema = z.object({
  timeUnit: z.enum(REPORTING_TIME_UNITS),
  timePastN: z.number().positive(),
})

export type TimePastNReportRequest = z.infer<typeof timePastNReportRequestSchema>

export type PastNReporter<TReport> = AsyncFn<ForAddonFields<'companyGuid' | 'tzId', TimePastNReportRequest>, TReport>

export const getPastNReportingTimeWindowsFor = (
  req: TimePastNReportRequest,
  timeZoneId: TimeZoneId,
  specifiedEndDateTime?: IsoDateString,
): ReportingTimeWindow[] => {
  const endDateTime = specifiedEndDateTime ? specifiedEndDateTime : new Date().toISOString()
  const innerReq = { n: req.timePastN, timeZoneId, endDateTime }
  if (req.timeUnit === 'days') return getPastNReportingDays(innerReq)
  if (req.timeUnit === 'weeks') return getPastNReportingWeeks(innerReq)
  if (req.timeUnit === 'months') return getPastNReportingMonths(innerReq)
  if (req.timeUnit === 'years') return getPastNReportingYears(innerReq)
  throw new MissingCaseError(`Missing Time Reporting Windows for TimeUnit ${req.timeUnit}`)
}

const getPastNReportingDays = (req: PastNTimeWindowFnsProps): ReportingTimeWindow[] =>
  generateReportingWindows(req, 'days', zonedDateTime => [
    zonedDateTime.truncatedTo(ChronoUnit.DAYS),
    zonedDateTime.truncatedTo(ChronoUnit.DAYS).plusDays(1).minus(1, ChronoUnit.MILLIS),
    zonedDateTime.minusDays(1),
  ])

const getPastNReportingWeeks = (req: PastNTimeWindowFnsProps): ReportingTimeWindow[] =>
  generateReportingWindows(req, 'weeks', zonedDateTime => [
    zonedDateTime.with(DayOfWeek.MONDAY).truncatedTo(ChronoUnit.DAYS),
    zonedDateTime.with(DayOfWeek.MONDAY).truncatedTo(ChronoUnit.DAYS).plusDays(7).minus(1, ChronoUnit.MILLIS),
    zonedDateTime.minusWeeks(1),
  ])

const getPastNReportingMonths = (req: PastNTimeWindowFnsProps): ReportingTimeWindow[] =>
  generateReportingWindows(req, 'months', zonedDateTime => {
    const start = zonedDateTime.withDayOfMonth(1).truncatedTo(ChronoUnit.DAYS)
    const end = start.plusMonths(1).truncatedTo(ChronoUnit.DAYS).minus(1, ChronoUnit.MILLIS)
    const next = zonedDateTime.minusMonths(1)
    return [start, end, next]
  })

const getPastNReportingYears = (req: PastNTimeWindowFnsProps): ReportingTimeWindow[] =>
  generateReportingWindows(req, 'years', zonedDateTime => [
    zonedDateTime.withDayOfYear(1).truncatedTo(ChronoUnit.DAYS),
    zonedDateTime.withDayOfYear(1).truncatedTo(ChronoUnit.DAYS).plusYears(1).minus(1, ChronoUnit.MILLIS),
    zonedDateTime.minusYears(1),
  ])

const generateReportingWindows = (
  req: PastNTimeWindowFnsProps,
  unit: 'days' | 'weeks' | 'months' | 'years',
  computeBounds: (zdt: ZonedDateTime) => [ZonedDateTime, ZonedDateTime, ZonedDateTime],
): ReportingTimeWindow[] => {
  const { n, timeZoneId, endDateTime } = req
  const reportingWindows: ReportingTimeWindow[] = []

  let zonedDateTime = ZonedDateTime.parse(endDateTime).withZoneSameInstant(ZoneId.of(timeZoneId))

  for (let i = 0; i < n; i++) {
    const [start, end, nextZonedDateTime] = computeBounds(zonedDateTime)

    reportingWindows.push({
      unit,
      // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1016
      // eslint-disable-next-line breezy/no-to-iso-date-string
      start: BzDateFns.toIsoDateString(start.toInstant().toString()),
      // eslint-disable-next-line breezy/no-to-iso-date-string
      end: BzDateFns.toIsoDateString(end.toInstant().toString()),
    })

    zonedDateTime = nextZonedDateTime
  }

  return reportingWindows.reverse()
}
