import { mapQueryVisitsToVisitViewModels } from '@breezy/backend/src/application-types'
import { useQuery } from 'urql'
import { LOCATION_OPTION_MAINTENANCE_PLAN_QUERY } from '../CreateOrEditMaintenancePlanPage.gql'

export const useLocationActiveMaintenancePlan = (locationGuid: string) => {
  const [{ data, fetching, error }] = useQuery({
    query: LOCATION_OPTION_MAINTENANCE_PLAN_QUERY,
    variables: { locationGuid },
  })

  const activeMaintenancePlans = data?.locationsByPk?.maintenancePlans
    ?.filter(mp => mp.status === 'ACTIVE')
    .map(mp => ({
      ...mp,
      coveredInstalledEquipmentGuids:
        mp.coveredInstalledEquipmentGuids as string[],
      visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits ?? []),
    }))

  const activeMaintenancePlan =
    !!activeMaintenancePlans && activeMaintenancePlans.length > 0
      ? activeMaintenancePlans[0]
      : undefined

  return { activeMaintenancePlan, fetching, error }
}
