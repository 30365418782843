import classNames from 'classnames'
import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

type LinkProps = Omit<React.ComponentProps<typeof ReactRouterLink>, 'to'> & {
  bold?: boolean
  to?: React.ComponentProps<typeof ReactRouterLink>['to']
  blue?: boolean
}

export const Link = React.memo<LinkProps>(
  ({ className, bold = false, to, blue = true, ...props }) => {
    const resolvedClassName = classNames(
      'no-underline hover:underline',
      blue ? 'text-bz-primary' : 'text-inherit',
      { 'font-semibold': bold },
      className,
    )
    return to ? (
      <ReactRouterLink to={to} {...props} className={resolvedClassName} />
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a className={resolvedClassName} {...props} />
    )
  },
)
