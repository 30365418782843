import { AsyncFn, BzDateFns, IsoDateString, TimeZoneId, bzExpect, formatUsc, nextGuid } from '../../../common'
import { WisetackSampleLoanApplicationEvent } from '../../../contracts/WisetackWebhookEventContracts'
import { Guid } from '../../common-schemas'
import { LoanRecord } from './Lending'

export type WisetackWebhookEventRecord = {
  wisetackWebhookEventGuid: Guid
  wisetackTransactionGuid?: Guid
  wisetackPrequalGuid?: Guid
  wisetackMessageGuid: Guid
  eventType: string
  eventDataJson: unknown
  createdAt?: IsoDateString
}

export type WisetackWebhookEventRecordWriter = AsyncFn<WisetackWebhookEventRecord>

const convertIsoDateStringToWisetackLocalDateFormatString = (isoDateString: IsoDateString, tzId: TimeZoneId) =>
  BzDateFns.format(BzDateFns.parseISO(isoDateString, tzId), 'MM-dd-yyyy')

export const createInitiatedLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'INITIATED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})

export const createAuthorizedLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'AUTHORIZED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  approvedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})

export const createLoanTermsAcceptedLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'LOAN TERMS ACCEPTED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  approvedLoanAmount: formatUsc(bzExpect(loanRecord.approvedLoanAmountUsc)),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})

export const createLoanConfirmedLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'LOAN CONFIRMED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  approvedLoanAmount: formatUsc(bzExpect(loanRecord.approvedLoanAmountUsc)),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})

export const createSettledLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'SETTLED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  settlementDate: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  approvedLoanAmount: formatUsc(bzExpect(loanRecord.approvedLoanAmountUsc)),
  settledLoanAmount: formatUsc(bzExpect(loanRecord.approvedLoanAmountUsc) * 0.961),
  processingFee: formatUsc(bzExpect(loanRecord.approvedLoanAmountUsc) * 0.039),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})

export const createPartiallyApprovedLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  approvedLoanAmountUsc: number,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'AUTHORIZED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  approvedLoanAmount: formatUsc(approvedLoanAmountUsc),
  serviceCompletedOn: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
  expirationDate: convertIsoDateStringToWisetackLocalDateFormatString(
    BzDateFns.formatISO(BzDateFns.addDays(BzDateFns.now(companyTimeZoneId), 60), companyTimeZoneId),
    companyTimeZoneId,
  ),
})

export const createDeclinedLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'DECLINED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})

export const createCanceledLoanRecordStatusWebhook = (
  loanRecord: LoanRecord,
  companyTimeZoneId: TimeZoneId,
): WisetackSampleLoanApplicationEvent => ({
  transactionId: loanRecord.externalTransactionGuid,
  messageId: nextGuid(),
  merchantId: loanRecord.merchantGuid,
  changedStatus: 'CANCELED',
  date: convertIsoDateStringToWisetackLocalDateFormatString(BzDateFns.nowISOString(), companyTimeZoneId),
  requestedLoanAmount: formatUsc(loanRecord.requestedLoanAmountUsc),
  createdAt: convertIsoDateStringToWisetackLocalDateFormatString(loanRecord.createdAt, companyTimeZoneId),
  eventType: 'LOAN_APPLICATION',
})
