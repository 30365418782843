import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React from 'react'
import RenderIf from '../RenderIf/RenderIf'

type OptionCardButtonProps = {
  optionKey: string
  text: string
  icon: IconProp
  onClick: () => void
  info?: string
  disabled?: boolean
}

export const OptionCardButton = React.memo<OptionCardButtonProps>(
  ({ optionKey, text, icon, info, onClick, disabled }) => {
    return (
      <div
        onClick={onClick}
        data-testid={`optionCardButton-${optionKey}`}
        data-dd-action-name={`Select Option - ${optionKey}`}
        className={classNames(
          'relative flex min-h-[142px] w-full flex-col items-center justify-center gap-y-2 rounded-lg border border-solid border-bz-gray-500 px-4 font-semibold',
          {
            'cursor-pointer text-bz-gray-900': !disabled,
            'cursor-not-allowed bg-bz-gray-400 text-bz-gray-600': disabled,
          },
        )}
      >
        <RenderIf if={!!info}>
          <div className="absolute right-1 top-0">
            <Tooltip title={info} placement="top">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </div>
        </RenderIf>
        <FontAwesomeIcon
          className={classNames('text-[32px]', {
            'text-bz-gray-900': !disabled,
            'text-bz-gray-600': disabled,
          })}
          icon={icon}
        />
        <div className="text-center text-[16px] font-semibold leading-[24px]">
          {text}
        </div>
      </div>
    )
  },
)

export default OptionCardButton
