import {
  EstimateV2Status,
  generateEstimateAccountAppLink,
} from '@breezy/shared'
import { Form } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  MessageOption,
  PreviewMessageOptionSelection,
  PreviewMessageOptionSelectionProps,
} from '../../../components/PreviewMessage/PreviewMessageOptionSelection/PreviewMessageOptionSelection'
import { createSendEmailForm } from '../../../components/PreviewMessage/SendEmailForm/SendEmailForm'
import {
  SendAsEmailFormData,
  SendAsEmailFormSchema,
} from '../../../components/PreviewMessage/SendEmailForm/SendEmailForm.schema'
import { createSendSmsForm } from '../../../components/PreviewMessage/SendSmsForm/SendSmsForm'
import {
  SendAsSmsFormData,
  SendAsSmsFormSchema,
} from '../../../components/PreviewMessage/SendSmsForm/SendSmsForm.schema'
import { getConfig } from '../../../config'
import { trpc } from '../../../hooks/trpc'
import { useWisetackEnabled } from '../../../providers/CompanyFinancialConfigWrapper'
import { useExpectedPrincipal } from '../../../providers/PrincipalUser'
import { useMessage } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import {
  EstimatesContext,
  useEstimateStatusUpdater,
} from '../estimatesFlowUtils'
import { useSelectContactStep } from '../useSelectContactStep'

const config = getConfig()

const createDefaultEmail = (
  firstName: string | undefined,
  paymentLink: string,
  userFirstName: string,
  location: string,
) =>
  `<p><strong>Hello${
    firstName ? ` ${firstName}` : ''
  },</strong></p><p>Here's the estimate that I've prepared for you to review for the property at <strong>${location}</strong>. Click the link below to check it out.</p><p><strong>Estimate link</strong><br/><a href="${paymentLink}" rel="noopener noreferrer">${paymentLink}</a></p><p>Let me know if you have any questions. Thanks and have a great day!</p><p>${userFirstName}</p>`

const createDefaultSms = (
  firstName: string | undefined,
  paymentLink: string,
  userFirstName: string,
) =>
  `Hi${
    firstName ? ` ${firstName}` : ''
  }, Here's the estimate I've prepared for you: ${paymentLink}. ${userFirstName}`

type CustomFormProps = React.PropsWithChildren<
  PreviewMessageOptionSelectionProps & {
    onSubmit: () => void
    onCancel: () => void
    onBack?: () => void
    isLoading: boolean
  }
>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const CustomForm = ({
  children,
  onSubmit,
  onCancel,
  onBack,
  isLoading,
  messageOption,
  setMessageOption,
}: CustomFormProps) => {
  return (
    <OnsiteModalContent
      unpadBottom
      header="Preview Message"
      onClose={onCancel}
      onBack={onBack}
      footer={
        <OnsiteModalFooter
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitText="Send"
          submitIsLoading={isLoading}
        />
      }
    >
      <div>
        <PreviewMessageOptionSelection
          messageOption={messageOption}
          setMessageOption={setMessageOption}
        />
        <Form
          disabled={isLoading}
          onSubmitCapture={onSubmit}
          layout="vertical"
          className="flex min-h-0 flex-1 flex-col"
          requiredMark={label => (
            <div>
              {label}
              <span className="ml-0.5 text-bz-primary">*</span>
            </div>
          )}
        >
          {children}
        </Form>
      </div>
    </OnsiteModalContent>
  )
}

const SendEmailForm = createSendEmailForm(CustomForm)
const SendSmsForm = createSendSmsForm(CustomForm)

type SendEstimateModalProps = {
  onClose: () => void
  displayId: number
  contactFirstName?: string
  emailAddress?: string
  phoneNumber?: string
  status: EstimateV2Status
  address: string
  open?: boolean
}

export const SendEstimateModal = React.memo<SendEstimateModalProps>(
  ({
    status,
    onClose,
    displayId,
    contactFirstName,
    emailAddress,
    phoneNumber,
    address,
    open = true,
  }) => {
    const user = useExpectedPrincipal()
    const message = useMessage()
    const wisetackEnabled = useWisetackEnabled()

    const { companyName, estimateGuid, accountGuid } =
      useStrictContext(EstimatesContext)
    const [selectedContactGuid, setSelectedContactGuid] = useState<string>()

    const [messageOption, setMessageOption] = useState<MessageOption>('email')

    const {
      contact,
      isLoading: selectContactStepLoading,
      SelectContactStep,
    } = useSelectContactStep({
      accountGuid,
      selectedContactGuid,
      setSelectedContactGuid,
      onClose,
    })

    const [link, setLink] = useState<string>()

    useEffect(() => {
      if (!link) {
        if (!wisetackEnabled || selectedContactGuid) {
          ;(async () => {
            try {
              const url = await generateEstimateAccountAppLink(
                config.accountAppFullUrl,
                estimateGuid,
                selectedContactGuid,
              )
              setLink(url)
            } catch (e) {
              console.error(e)
              message.error('Failed to generate link.')
            }
          })()
        }
      }
    }, [estimateGuid, link, message, selectedContactGuid, wisetackEnabled])

    const defaultEmailValues = useMemo(() => {
      return {
        emailAddress:
          contact?.primaryEmailAddress?.emailAddress ?? emailAddress ?? '',
        subject: `Estimate #${displayId} from ${companyName}`,
        body: createDefaultEmail(
          contact?.firstName ?? contactFirstName,
          link ?? '',
          user.firstName,
          address,
        ),
      }
    }, [
      address,
      companyName,
      contact?.firstName,
      contact?.primaryEmailAddress?.emailAddress,
      contactFirstName,
      displayId,
      emailAddress,
      link,
      user.firstName,
    ])

    const defaultPhoneValues = useMemo(() => {
      return {
        phoneNumber:
          contact?.primaryPhoneNumber?.phoneNumber ?? phoneNumber ?? '',
        body: createDefaultSms(
          contact?.firstName ?? contactFirstName,
          link ?? '',
          user.firstName,
        ),
      }
    }, [contact, contactFirstName, link, phoneNumber, user.firstName])

    const [estimateStatusUpdater] = useEstimateStatusUpdater(status)

    const sendMessageMutation =
      trpc.invoice['invoicing:estimatev2:send-review-message'].useMutation()

    const onEmailSubmit = useCallback(
      async (data: SendAsEmailFormData) => {
        await sendMessageMutation.mutateAsync({
          estimateGuid,
          method: 'EMAIL',
          ...data,
        })
        message.success('Estimate sent!')
        estimateStatusUpdater('SENT')
        onClose()
      },
      [
        estimateGuid,
        estimateStatusUpdater,
        message,
        onClose,
        sendMessageMutation,
      ],
    )
    const onSmsSubmit = useCallback(
      async (data: SendAsSmsFormData) => {
        await sendMessageMutation.mutateAsync({
          estimateGuid,
          method: 'SMS',
          ...data,
        })
        message.success('Estimate sent!')
        estimateStatusUpdater('SENT')
        onClose()
      },
      [
        estimateGuid,
        estimateStatusUpdater,
        message,
        onClose,
        sendMessageMutation,
      ],
    )

    const emailForm = useForm<SendAsEmailFormData>({
      defaultValues: defaultEmailValues,
    })
    const smsForm = useForm<SendAsSmsFormData>({
      defaultValues: defaultPhoneValues,
    })

    const isDirty = emailForm.formState.isDirty || smsForm.formState.isDirty

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal(isDirty)

    const onCancelWithConfirm = useCallback(
      () =>
        withConfirmation(() => {
          emailForm.reset()
          smsForm.reset()
          onClose()
        }),
      [emailForm, onClose, smsForm, withConfirmation],
    )

    const onBackWithConfirm = useMemo(() => {
      if (!selectedContactGuid) {
        return undefined
      }
      return () =>
        withConfirmation(() => {
          emailForm.reset()
          smsForm.reset()
          setSelectedContactGuid(undefined)
        })
    }, [emailForm, selectedContactGuid, smsForm, withConfirmation])

    const content = useMemo(() => {
      if (wisetackEnabled && !selectedContactGuid) {
        return SelectContactStep
      }

      if (selectContactStepLoading || !link) {
        return <LoadingSpinner />
      }

      return (
        <>
          <div
            className={classNames('flex min-h-0 flex-1 flex-col', {
              hidden: messageOption !== 'email',
            })}
          >
            <SendEmailForm
              form={emailForm}
              schema={SendAsEmailFormSchema}
              onSubmit={onEmailSubmit}
              formProps={{
                isLoading: sendMessageMutation.isLoading,
                onCancel: onCancelWithConfirm,
                onBack: onBackWithConfirm,
                messageOption,
                setMessageOption,
              }}
              defaultValues={defaultEmailValues}
            />
          </div>
          <div
            className={classNames('flex min-h-0 flex-1 flex-col', {
              hidden: messageOption !== 'sms',
            })}
          >
            <SendSmsForm
              form={smsForm}
              schema={SendAsSmsFormSchema}
              onSubmit={onSmsSubmit}
              formProps={{
                isLoading: sendMessageMutation.isLoading,
                onCancel: onCancelWithConfirm,
                onBack: onBackWithConfirm,
                messageOption,
                setMessageOption,
              }}
              defaultValues={defaultPhoneValues}
            />
          </div>
        </>
      )
    }, [
      wisetackEnabled,
      selectedContactGuid,
      selectContactStepLoading,
      link,
      messageOption,
      emailForm,
      onEmailSubmit,
      sendMessageMutation.isLoading,
      onCancelWithConfirm,
      onBackWithConfirm,
      defaultEmailValues,
      smsForm,
      onSmsSubmit,
      defaultPhoneValues,
      SelectContactStep,
    ])

    return (
      <OnsiteModal open={open} onClose={onCancelWithConfirm} size="large">
        {content}
        <CloseConfirmModal {...closeConfirmProps} />
      </OnsiteModal>
    )
  },
)
