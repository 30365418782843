import { PaymentMethod } from '@breezy/shared'
import { faBank } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Radio, Row } from 'antd'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import useTilled from '../../../hooks/useTilled'
import { useExpectedMerchantId } from '../../../providers/PrincipalUser'
import { dustRed6 } from '../../../themes/theme'
import { TilledFormFieldChangeHandler } from '../../../utils/tilledSdkTypes'
import { LoadingSpinner } from '../../LoadingSpinner'
import PayButton from '../PayButton'
import PaymentCardPanel from '../PaymentCardPanel'
import { PaymentMethodFormProps } from '../PaymentTypes'
import { tilledFieldOptions, tilledFormFieldStyle } from './TilledStyles'
import { useGetAchDebitDetails } from './tilled-utils'
import { useTilledCreatePaymentMethod } from './useTilledCreatePaymentMethod'

const paymentMethod = PaymentMethod.ACH

const AchCreatePaymentMethodForm = React.memo<PaymentMethodFormProps>(
  ({ onSuccess, paymentContext }) => {
    const merchantAccountId = useExpectedMerchantId()
    const {
      register,
      formState: { errors },
      handleSubmit,
      getValues,
    } = useForm()
    const [validFields, setValidFields] = useState<Set<string>>(new Set())
    const [paymentSubmissionError, setPaymentSubmissionError] = useState<
      string | undefined
    >(undefined)
    const cardStyle = paymentSubmissionError
      ? { border: `2px solid ${dustRed6}` }
      : {}
    const readyToSubmitPayment =
      validFields.size === 2 &&
      validFields.has('bankAccountNumber') &&
      validFields.has('bankRoutingNumber')

    const onTilledFieldChange: TilledFormFieldChangeHandler = event => {
      if (event.valid)
        setValidFields(prev => new Set(prev).add(event.fieldType))
      else
        setValidFields(prev => {
          const s = new Set(prev)
          s.delete(event.fieldType)
          return s
        })
    }

    const tilledLoader = useTilled(
      merchantAccountId,
      {
        type: 'ach_debit',
        fields: {
          bankAccountNumber: { selector: '#bank-account-number-element' },
          bankRoutingNumber: { selector: '#bank-routing-number-element' },
        },
      },
      onTilledFieldChange,
      tilledFieldOptions,
    )

    const tilledRequestContext = tilledLoader.value

    const getAccountHolderName = useCallback(
      () => paymentContext.paymentBillingDetails.name,
      [paymentContext.paymentBillingDetails.name],
    )
    const getAchDebitDetails = useGetAchDebitDetails(getAccountHolderName)

    const submit = useTilledCreatePaymentMethod({
      paymentContext: paymentContext,
      tilled: tilledLoader.value?.tilled,
      paymentMethodType: 'ach_debit',
      getAchDebitDetails,
      onPaymentSubmissionError: setPaymentSubmissionError,
      onSuccess: (
        paymentMethodId: string,
        paymentMethodAdditionalInfo: string,
      ) =>
        onSuccess(paymentMethodId, paymentMethodAdditionalInfo, paymentMethod),
    })

    if (tilledLoader.error)
      return <div>Failed to load Tilled. Use another Payment Method</div>

    if (tilledLoader.loading)
      return (
        <div className="card-no-fixed-height center-h h-24 w-[320px] max-w-[500px]">
          <LoadingSpinner />
        </div>
      )

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (Object.entries(errors).length > 0)
      console.warn({
        reactFormValues: getValues(),
        errors,
        tilledForm: tilledRequestContext?.form,
      })

    return (
      <>
        <form className="credit-card_form" onSubmit={handleSubmit(submit)}>
          <PaymentCardPanel style={cardStyle}>
            <div className="pr-2">
              <Row className="center-h center-children-vh mb-2">
                <FontAwesomeIcon
                  icon={faBank}
                  className="center-v mr-4"
                  style={{ fontSize: 24 }}
                />
                <h2 className="m-0">Bank (ACH) Payment</h2>
              </Row>
              {paymentSubmissionError && (
                <div className="error-red my-2 p-2 text-center">
                  <b>Payment Error:</b>
                  <br />
                  {paymentSubmissionError}
                </div>
              )}
              <Radio.Group
                id="bank-account-type-element"
                defaultValue="checking"
                {...register('accountType', { value: 'checking' })}
                className="max-width-max ml-2 mt-4 flex justify-between rounded-md border p-2 pb-4"
              >
                <Radio value="checking">Checking</Radio>
                <Radio value="savings">Savings</Radio>
              </Radio.Group>
              <label className="flex flex-col p-2">
                <div style={{ marginBottom: 8 }} className="required">
                  Account Number
                </div>
                <div
                  className="tilled-js_input max-width-max ml-2 flex justify-between rounded-md border p-2"
                  id="bank-account-number-element"
                  style={tilledFormFieldStyle}
                  {...register('bankAccountNumber', { maxLength: 16 })}
                />
                {errors.accountNumber?.type === 'required' &&
                  'Account Number is required'}
              </label>
              <label className="flex flex-col p-2">
                <div style={{ marginBottom: 8 }} className="required">
                  Routing Number
                </div>
                <div
                  className="tilled-js_input max-width-max ml-2 flex justify-between rounded-md border p-2"
                  id="bank-routing-number-element"
                  style={tilledFormFieldStyle}
                  {...register('bankRoutingNumber', { maxLength: 10 })}
                />
                {errors.routingNumber?.type === 'required' &&
                  'Routing Number is required'}
              </label>
              <PayButton
                readyToSubmitPayment={readyToSubmitPayment}
                submit={submit}
              />
            </div>
          </PaymentCardPanel>
        </form>
      </>
    )
  },
)

export default AchCreatePaymentMethodForm
