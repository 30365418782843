import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type ItemIconActionRowProps = React.PropsWithChildren<{
  icon?: React.ReactNode
  onClick?: () => void
  rightContent?: React.ReactNode
  mainContentDisabled?: boolean
  className?: string
  grayIconButton?: boolean
  footer?: React.ReactNode
  subContent?: React.ReactNode
}>

export const ItemIconActionRow = React.memo<ItemIconActionRowProps>(
  ({
    children,
    icon,
    onClick,
    rightContent,
    mainContentDisabled,
    className,
    grayIconButton,
    footer,
    subContent,
  }) => {
    const isMobile = useIsMobile()
    const [templateColsStyle, footerClassName] = useMemo(() => {
      /**
       * The shape of this thing is kind of like this on non-mobile:
       *
       * +------+------------------------------+---------------+
       * | icon | main content                 | right content |
       * +------+------------------------------+---------------+
       * |      | footer                                       |
       * +------+----------------------------------------------+
       *
       * - The icon and the right content fit their content and the main content fills in the remaining space
       * - The footer aligns with the main content and spans across the right content, if it's there
       *
       * For mobile it looks like this:
       *
       * +------+------------------------------+---------------+
       * | icon | main content                 | right content |
       * +------+------------------------------+---------------+
       * | footer                                              |
       * +-----------------------------------------------------+
       *
       * - The only difference is the footer is the full bottom, including spanning across the icon.
       *
       */

      // Main content
      const templateColsParts = ['1fr']
      // On mobile we just span all columns
      const footerClassNames: string[] = isMobile ? ['col-span-full'] : []
      if (icon) {
        templateColsParts.unshift('auto')
        // If it's not mobile we start after the icon
        if (!isMobile) {
          footerClassNames.push('col-start-2')
        }
      }
      if (rightContent || onClick) {
        templateColsParts.push('auto')
        // On not-mobile we already span the full length. Otherwise, just end at the end.
        if (!isMobile) {
          footerClassNames.push('col-end-[-1]')
        }
      }
      return [templateColsParts.join(' '), footerClassNames.join(' ')]
    }, [icon, isMobile, onClick, rightContent])
    return (
      <div
        onClick={mainContentDisabled ? undefined : onClick}
        style={{
          gridTemplateColumns: templateColsStyle,
        }}
        className={classNames(
          'grid items-start gap-3',
          {
            'cursor-pointer': !!onClick && !mainContentDisabled,
          },
          className,
          {
            'text-bz-gray-700': mainContentDisabled,
          },
        )}
      >
        {icon &&
          (grayIconButton ? (
            <div className="relative flex h-10 w-10 items-center justify-center rounded-full bg-bz-fill-tertiary">
              {icon}
            </div>
          ) : (
            <Button
              shape="circle"
              size="large"
              icon={icon}
              className={classNames({
                'pointer-events-none': !onClick,
              })}
            />
          ))}

        <div
          className={classNames(
            'flex min-h-10 flex-1 flex-col justify-center text-base font-semibold',
            {
              'text-bz-gray-700': mainContentDisabled,
            },
          )}
        >
          {children}
          {subContent ? (
            <div className="text-sm font-normal">{subContent}</div>
          ) : undefined}
        </div>
        {rightContent ? (
          rightContent
        ) : onClick ? (
          <div className="flex h-full items-center">
            <FontAwesomeIcon
              className="text-base text-bz-gray-1000"
              icon={faChevronRight}
            />
          </div>
        ) : undefined}
        {footer ? <div className={footerClassName}>{footer}</div> : undefined}
      </div>
    )
  },
)

type OnsitePageSimpleSectionItemProps = ItemIconActionRowProps & {
  layoutClassName?: string
  borderColorClassName?: string
  footer?: React.ReactNode
}

export const OnsitePageSimpleSectionItem =
  React.memo<OnsitePageSimpleSectionItemProps>(
    ({
      className,
      layoutClassName,
      borderColorClassName = 'border-bz-gray-500',
      grayIconButton = true,
      ...rest
    }) => {
      const isMobile = useIsMobile()
      return (
        <ItemIconActionRow
          {...rest}
          grayIconButton={grayIconButton}
          className={classNames(
            'min-h-[72px] rounded-xl border border-solid shadow-sm',
            isMobile ? 'p-4' : 'p-6',
            layoutClassName,
            borderColorClassName,
            className,
          )}
        />
      )
    },
  )
