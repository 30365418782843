import { FileLinks, FileRecord, nextGuid } from '@breezy/shared'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { trpc } from '../../hooks/trpc'
import {
  AsyncUploadWrapper,
  GetExtraData,
  OnUploadChange,
  UploadButton,
  WriteRecord,
} from './Upload'

type FileUploadExtraData = {
  cdnFileNameAndPath: string
  fileGuid: string
}

type OnFileUploadChange = OnUploadChange<FileUploadExtraData, FileRecord>

type AsyncFileUploadProps = {
  onFileUploadChange: OnFileUploadChange
  links?: FileLinks
}

export const AsyncFileUpload = React.memo<AsyncFileUploadProps>(
  ({ onFileUploadChange, links }) => {
    const [loading, setLoading] = useState(false)
    const onUploadChange = useCallback<OnFileUploadChange>(
      (data, fileRecord) => {
        setLoading(!data.recordWritten)
        onFileUploadChange(data, fileRecord)
      },
      [onFileUploadChange],
    )

    const getExtraData = useCallback<GetExtraData<FileUploadExtraData>>(
      data => {
        const fileGuid = nextGuid()
        const cdnFileNameAndPath = `${fileGuid}/${data.filename}`
        return {
          fileGuid,
          cdnFileNameAndPath,
          ...data,
        }
      },
      [],
    )

    const writeFileRecord = trpc.files['files:write-file-record'].useMutation()

    const writeRecord = useCallback<
      WriteRecord<FileUploadExtraData, FileRecord>
    >(
      async data => {
        const fileRecord = await writeFileRecord.mutateAsync({
          fileGuid: data.fileGuid,
          cdnUrl: data.cdnUrl,
          resourceUrn: data.resourceUrn,
          fileName: data.filename,
          metadata: {},
          fileSizeBytes: data.bytes,
          fileTypeMime: data.type,
          storageStrategy: 'S3',
          ...(links ?? {}),
        })
        return fileRecord
      },
      [links, writeFileRecord],
    )

    return (
      <AsyncUploadWrapper
        onUploadChange={onUploadChange}
        getExtraData={getExtraData}
        writeRecord={writeRecord}
        isImage={false}
      >
        <UploadButton
          icon={<FontAwesomeIcon icon={faFile} />}
          loading={loading}
        >
          Choose file
        </UploadButton>
      </AsyncUploadWrapper>
    )
  },
)
