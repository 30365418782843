import { useCallback, useState } from 'react'
import { useSearchParam } from 'react-use'

export const useQueryStringBoolean = (
  queryParamName: string,
  onlyRemoveQueryParam?: boolean,
): [boolean, (value: boolean) => void] => {
  const initialIsEnabled = useSearchParam(queryParamName)
  const [isEnabled, setStateEnabled] = useState(!!initialIsEnabled)

  const disable = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.delete(queryParamName)
    const searchString = urlSearchParams.toString()
    const queryString = searchString.length > 0 ? `?${searchString}` : ''
    const newUrl = `${window.location.pathname}${queryString}`
    window.history.pushState({}, '', newUrl)
    setStateEnabled(false)
  }, [queryParamName, setStateEnabled])

  const enable = useCallback(() => {
    if (!onlyRemoveQueryParam) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      urlSearchParams.set(queryParamName, 'true')
      const searchString = urlSearchParams.toString()
      const queryString = searchString.length > 0 ? `?${searchString}` : ''
      const newUrl = `${window.location.pathname}${queryString}`
      window.history.pushState({}, '', newUrl)
    }
    setStateEnabled(true)
  }, [queryParamName, setStateEnabled, onlyRemoveQueryParam])

  const setEnabled = useCallback(
    (value: boolean) => {
      if (value) {
        enable()
      } else {
        disable()
      }
    },
    [enable, disable],
  )

  return [isEnabled, setEnabled]
}
