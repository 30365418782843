import { MutableRefObject, useCallback, useState } from 'react'
import BzCheckBox, { BzCheckBoxProps } from './BzCheckBox'

type BzRefCheckBoxProps = Omit<BzCheckBoxProps, 'value' | 'onChange'> & {
  readonly valueRef: MutableRefObject<boolean>
}

const BzRefCheckBox = (props: BzRefCheckBoxProps) => {
  const { valueRef } = props
  const [value, setValue] = useState(valueRef.current)
  const onChange = useCallback(
    (checked: boolean) => {
      valueRef.current = checked
      setValue(checked)
    },
    [valueRef],
  )

  return <BzCheckBox {...props} value={value} onChange={onChange} />
}

export default BzRefCheckBox
