import {
  IconDefinition,
  faFileImport,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import useIsMobile from '../../hooks/useIsMobile'

type AddMaintenancePlanModalProps = {
  onAddNewPlan: () => void
  onImportNewPlan: () => void
  onCancel: () => void
}

export const AddMaintenancePlanModal = React.memo<AddMaintenancePlanModalProps>(
  ({ onAddNewPlan, onImportNewPlan, onCancel }) => {
    return (
      <OnsiteBasicModal
        open
        headerBordered
        onClose={onCancel}
        header="Add maintenance plan"
        size="large-width"
      >
        <AddMaintenancePlanOption
          iconDefinition={faScrewdriverWrench}
          label="New Plan"
          description="Add and configure a new plan to a customer's service location"
          onClick={onAddNewPlan}
        />
        <AddMaintenancePlanOption
          iconDefinition={faFileImport}
          label="Import Plan"
          description="Add and configure an old plan that was created outside of Breezy"
          onClick={onImportNewPlan}
        />
      </OnsiteBasicModal>
    )
  },
)

type AddMaintenancePlanOptionsProps = {
  label: string
  description: string
  iconDefinition: IconDefinition
  onClick: () => void
  containerClassName?: string
}

const AddMaintenancePlanOption = React.memo<AddMaintenancePlanOptionsProps>(
  ({ label, description, iconDefinition, onClick, containerClassName }) => {
    const isMobile = useIsMobile()
    return (
      <Row
        onClick={onClick}
        className={classNames(
          'cursor-pointer py-4 transition-colors duration-200 hover:bg-gray-200',
          isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
          containerClassName,
        )}
      >
        <Col className="pr-3">
          <FaIconWithCircularBackground
            iconDefinition={iconDefinition}
            backgroundColor="#F5F5F5"
            color="#434343"
            diameterPx={44}
          />
        </Col>
        <Col>
          <Row className="text-base font-semibold text-bz-gray-1000">
            {label}
          </Row>
          <Row className="text-sm font-normal text-bz-gray-800">
            {description}
          </Row>
        </Col>
      </Row>
    )
  },
)
