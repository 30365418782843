import { Contact } from '@breezy/shared'
import { Form } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { CompanyContactAutocomplete } from '../CompanyContactAutocomplete/CompanyContactAutocomplete'

type LeadSourceReferringContactFormItemProps = {
  initialContactGuid?: string
  className?: string
  onContactUpdated: (contact: Contact | undefined) => void
}

const LeadSourceReferringContactFormItem =
  React.memo<LeadSourceReferringContactFormItemProps>(
    ({ initialContactGuid, onContactUpdated, className }) => {
      return (
        <div className={classNames('flex', className)}>
          <div className="w-full">
            <Form.Item
              name="leadSourceReferringContactGuid"
              label="Referring Contact"
              rules={[
                {
                  required: true,
                  message: 'Referring Contact is required',
                },
              ]}
              className="mb-1"
            >
              <CompanyContactAutocomplete
                initialContactGuid={initialContactGuid}
                onContactUpdated={onContactUpdated}
              />
            </Form.Item>
          </div>
        </div>
      )
    },
  )

export default LeadSourceReferringContactFormItem
