import { Address } from '@breezy/shared'
import * as addresser from 'addresser'
import { AutoComplete, Form, Input } from 'antd'
import { useState } from 'react'
import { useGooglePlaces } from '../../../providers/GoogleMapsPlaces'
import { getPlacePostCodeById } from '../../../utils/GoogleMapsPlacesUtils'
import { validatorNotFalsy } from '../../../utils/validators'
import USStateFormItem from './USState/USStateFormItem'

export type AddressOption = {
  value: string
  placeId: string
}

export type AddressFormItemProps = {
  required: boolean
  onAddressChanged: (addressObj: Address) => void
  initialValue?: string
  labelClassName?: string
  flexRowSpaceX?: string
  address?: Address
  showMoreThanLineOne?: boolean
  showLine2?: boolean
  headerLabel?: string
}

// TODO Update this to use AddressLineOnFormItem
export const AddressFormItem = ({
  onAddressChanged,
  required,
  labelClassName = '',
  flexRowSpaceX = 'space-x-4',
  address,
  showMoreThanLineOne = true,
  showLine2 = true,
  headerLabel = 'Street Address',
}: AddressFormItemProps) => {
  const [options, setOptions] = useState<AddressOption[]>([])
  // the session token is used to optimize maps charges for the autocomplete service
  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken(),
  )
  const { autocompleteService, placesService } = useGooglePlaces()

  // This will trigger the onChange method to dispatch an update to the parent component
  const updateAddressState = (value: string) => {
    const parsed = addresser.parseAddress(value)
    if (
      !parsed.addressLine1 ||
      !parsed.placeName ||
      !parsed.stateName ||
      !parsed.zipCode
    ) {
      throw new Error('Address is invalid')
    }

    onAddressChanged({
      line1: parsed.addressLine1,
      line2: undefined,
      city: parsed.placeName,
      stateAbbreviation: parsed.stateAbbreviation,
      zipCode: parsed.zipCode,
    })
  }

  // this fetches the post code from the google maps api on a selection
  // primarily an optimization to reduce the number of calls to the google maps api
  const onSelect = async (value: string, option: AddressOption) => {
    if (!placesService) {
      return
    }

    const { placeId } = option
    const postCode = await getPlacePostCodeById(placeId, placesService)
    value = option.value + ' ' + postCode
    updateAddressState(value)
    // refresh the session token on a selection to optimize maps charges
    setSessionToken(new google.maps.places.AutocompleteSessionToken())
  }

  const onSearch = async (searchText: string) => {
    if (!autocompleteService) {
      return
    }

    if (!searchText) {
      setOptions([])
      return
    }
    const locations = await autocompleteService.getPlacePredictions({
      input: searchText,
      componentRestrictions: { country: 'us' },
      types: ['address'],
      sessionToken,
    })

    setOptions(
      locations?.predictions?.map(location => {
        // remove the country from the address
        const address = location.description.split(',').slice(0, -1).join(',')
        // keep placeId on the option so we can get the post code later
        return {
          value: address,
          placeId: location.place_id,
        }
      }),
    )
  }

  return (
    <div>
      <Form.Item
        name="addressLineOne"
        label={headerLabel}
        className={labelClassName}
        required={required}
        initialValue={address?.line1}
        rules={[{ validator: validatorNotFalsy(headerLabel) }]}
      >
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder={headerLabel}
        >
          <Input
            id="address"
            name="addressLineOne"
            autoComplete="street-address"
          />
        </AutoComplete>
      </Form.Item>
      {showMoreThanLineOne && (
        <div>
          {showLine2 && (
            <Form.Item
              name="addressLineTwo"
              label="Apt, Unit, Building #"
              className={labelClassName}
              initialValue={address?.line2}
              required={false}
            >
              <Input name="addressLineTwo" autoComplete="off" />
            </Form.Item>
          )}
          <div className={`flex ${flexRowSpaceX} gap-x-4`}>
            <div className="w-full">
              <Form.Item
                name="addressCity"
                label="City"
                className={labelClassName}
                required={required}
                initialValue={address?.city}
                rules={[{ validator: validatorNotFalsy('City') }]}
              >
                <Input name="addressCity" autoComplete="off" />
              </Form.Item>
            </div>
            <div className="w-full">
              <USStateFormItem
                initialStateAbbreviation={address?.stateAbbreviation}
                showAbbreviation
                required={required}
                labelClassName={labelClassName}
              />
            </div>
            <div className="w-full">
              <Form.Item
                name="addressZipCode"
                label="Zip Code"
                className={labelClassName}
                initialValue={address?.zipCode}
                required={required}
                rules={[{ validator: validatorNotFalsy('Zip Code') }]}
              >
                <Input name="addressZipCode" autoComplete="off" />
              </Form.Item>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
