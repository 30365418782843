import {
  CartItem,
  JobOutcomesFormSchema,
  isNullish,
  usdMultiply,
  usdToUsCents,
} from '@breezy/shared'
import { useMemo } from 'react'
import {
  AllJobAssignedTechniciansQuery,
  AllRevenueAttributionsForJobQuery,
} from '../../../generated/user/graphql'
import {
  JobDetailsForOutcomes,
  JobOutcomeInvoice,
} from '../job-outcome-modal-types'

export const calculateTotalCartItemPriceUsd = (record: CartItem) => {
  let totalPriceUsd = 0

  if (record.quantity === 0 || record.quantity === 1) {
    totalPriceUsd = record.unitPriceUsd
  } else if (record.quantity > 1) {
    totalPriceUsd = usdMultiply(record.unitPriceUsd, record.quantity)
  }

  return totalPriceUsd
}

export const useDefaultRevenueAttributionsGuidToInvoiceMap = (
  invoices: JobOutcomeInvoice[],
  job: JobDetailsForOutcomes,
  allRevenueAttributionsForJob?: AllRevenueAttributionsForJobQuery,
  allJobAssignedTechniciansQuery?: AllJobAssignedTechniciansQuery,
) => {
  return useMemo(() => {
    const revenueAttributionsGuidToInvoiceMap: JobOutcomesFormSchema['revenueAttributionGuidToInvoiceMap'] =
      {}

    if (isNullish(allRevenueAttributionsForJob)) {
      return {}
    }

    // This is a brittle and hacky, but for now its a reliable way to determine if this is
    // the first time the form is being submitted. If the job's conversion property has
    // not been defined, then we know that this is the first time the Job Outcomes
    // is being submitted for the job. In this case, we use default values for the
    // revenue attribution map
    if (isNullish(job.isConverted)) {
      const invoiceGuidToAttributedTechnicianUserGuidsMap =
        allJobAssignedTechniciansQuery?.jobsByPk?.jobAppointmentAssignments.reduce(
          (acc, { technicianUserGuid, jobAppointment }) => {
            const invoiceGuidsLinkedToTechnicianAssignment =
              jobAppointment.jobAppointmentInvoices.map(
                ({ invoiceGuid }) => invoiceGuid,
              )

            invoiceGuidsLinkedToTechnicianAssignment.forEach(invoiceGuid => {
              if (isNullish(acc[invoiceGuid])) {
                acc[invoiceGuid] = []
              }

              acc[invoiceGuid].push(technicianUserGuid)
            })

            return acc
          },
          {} as Record<string, string[]>,
        ) ?? {}

      invoices.forEach(invoice => {
        revenueAttributionsGuidToInvoiceMap[invoice.invoiceGuid] = {
          guidToItemMap: {},
        }

        invoice.items.forEach(item => {
          let techniciansToAttribute: string[] = []
          if (
            !isNullish(
              invoiceGuidToAttributedTechnicianUserGuidsMap[
                invoice.invoiceGuid
              ],
            )
          ) {
            techniciansToAttribute =
              invoiceGuidToAttributedTechnicianUserGuidsMap[invoice.invoiceGuid]
          }

          revenueAttributionsGuidToInvoiceMap[
            invoice.invoiceGuid
          ].guidToItemMap[item.itemGuid] = {
            totalRevenueUsc: usdToUsCents(calculateTotalCartItemPriceUsd(item)),
            soldRevenueAttributionUserGuids: techniciansToAttribute,
            earnedRevenueAttributionUserGuids: techniciansToAttribute,
          }
        })
      })

      return revenueAttributionsGuidToInvoiceMap
    }

    // If the job has a value set for isConverted, then we know that the Job Outcomes
    // modal has already been submitted. So we use the existing revenue attribution
    // values (if present)
    allRevenueAttributionsForJob.jobTechnicianSoldRevenueAttributions.forEach(
      ({ invoiceGuid, itemGuid, revenueUsc, technicianUserGuid }) => {
        if (isNullish(revenueAttributionsGuidToInvoiceMap[invoiceGuid])) {
          revenueAttributionsGuidToInvoiceMap[invoiceGuid] = {
            guidToItemMap: {},
          }
        }

        if (
          isNullish(
            revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
              itemGuid
            ],
          )
        ) {
          revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
            itemGuid
          ] = {
            totalRevenueUsc: 0,
            soldRevenueAttributionUserGuids: [],
            earnedRevenueAttributionUserGuids: [],
          }
        }

        revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
          itemGuid
        ].totalRevenueUsc = revenueUsc
        revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
          itemGuid
        ].soldRevenueAttributionUserGuids.push(technicianUserGuid)
      },
    )

    allRevenueAttributionsForJob.jobTechnicianEarnedRevenueAttributions.forEach(
      ({ invoiceGuid, itemGuid, revenueUsc, technicianUserGuid }) => {
        if (isNullish(revenueAttributionsGuidToInvoiceMap[invoiceGuid])) {
          revenueAttributionsGuidToInvoiceMap[invoiceGuid] = {
            guidToItemMap: {},
          }
        }

        if (
          isNullish(
            revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
              itemGuid
            ],
          )
        ) {
          revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
            itemGuid
          ] = {
            totalRevenueUsc: 0,
            soldRevenueAttributionUserGuids: [],
            earnedRevenueAttributionUserGuids: [],
          }
        }

        revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
          itemGuid
        ].totalRevenueUsc = revenueUsc
        revenueAttributionsGuidToInvoiceMap[invoiceGuid].guidToItemMap[
          itemGuid
        ].earnedRevenueAttributionUserGuids.push(technicianUserGuid)
      },
    )

    return revenueAttributionsGuidToInvoiceMap
  }, [
    allJobAssignedTechniciansQuery?.jobsByPk?.jobAppointmentAssignments,
    allRevenueAttributionsForJob,
    invoices,
    job.isConverted,
  ])
}
