import { InvoiceGuid } from '../Finance/Invoicing/InvoiceTypes'
import { CartItemGuid } from '../Finance/Transactions/TransactionTypes'
import { JobLifecycleStatus } from '../JobLifecycles/JobLifecycles'
import { UserGuid } from '../Users/User'
import { JobClass } from './JobClass'
import { JobGuid } from './JobInterfaces'

/**
 * Job Outcomes represent any results we want to capture when a
 * job is completed or closed. This includes things like:
 * - Revenue attributions for the job
 * - Employee compensations such as commissions & bonuses
 */
export type JobTeamMember = {
  jobGuid: JobGuid
  userGuid: UserGuid
  turnedOverJob?: boolean
  commissionRate?: number
  bonusUsc?: number
}

export type JobTechnicianSoldRevenueAttribution = {
  jobGuid: JobGuid
  technicianUserGuid: UserGuid
  invoiceGuid: InvoiceGuid
  itemGuid: CartItemGuid
  revenueUsc: number
}

export type JobTechnicianEarnedRevenueAttribution = {
  jobGuid: JobGuid
  technicianUserGuid: UserGuid
  invoiceGuid: InvoiceGuid
  itemGuid: CartItemGuid
  revenueUsc: number
}

export const JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS = [JobClass.SERVICE, JobClass.MAINTENANCE]

export const JOB_CLASSES_THAT_SUPPORT_JOB_OUTCOMES = [JobClass.SERVICE, JobClass.MAINTENANCE, JobClass.INSTALL]

const transitionFromUncompletedToCompletedStatus = (from: JobLifecycleStatus, to: JobLifecycleStatus) => {
  const wasPreviouslyCompleted = from.specialStatus === 'Completed'
  const willBeCompleted = to.specialStatus === 'Completed'
  return !wasPreviouslyCompleted && willBeCompleted
}
export const jobOutcomesDataShouldBeCollectedOnTransition = (
  from: JobLifecycleStatus,
  to: JobLifecycleStatus,
  canManageTechnicianPerformance: boolean,
  jobClass: JobClass,
): boolean => {
  return (
    canManageTechnicianPerformance &&
    transitionFromUncompletedToCompletedStatus(from, to) &&
    JOB_CLASSES_THAT_SUPPORT_JOB_OUTCOMES.includes(jobClass)
  )
}
