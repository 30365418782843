import { BzDateFns } from '@breezy/shared'
import { faChevronLeft, faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryParam } from 'use-query-params'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import {
  useGoBack,
  useOnsitePageDimensions,
} from '../../adam-components/OnsitePage/onsitePageUtils'
import { trpc } from '../../hooks/trpc'
import {
  useModalState,
  useQueryParamFlag,
  useQueryParamState,
  useStrictContext,
} from '../../utils/react-utils'
import { EstimateAcceptedWidget } from './components/EstimateAcceptedWidget'
import { FinalizeInvoiceDrawer } from './components/FinalizeInvoiceDrawer'
import { SendAcceptedEstimateForm } from './components/SendAcceptedEstimateForm'
import { SignatureForm } from './components/SignatureForm'
import { EstimatePresentView } from './EstimatePresentView'
import { EstimatesContext, FetchedEstimate } from './estimatesFlowUtils'

type AcceptEstimateViewProps = {
  estimate: FetchedEstimate
}

export const AcceptEstimateView = React.memo<AcceptEstimateViewProps>(
  ({ estimate }) => {
    const { estimateGuid, accountGuid, jobAppointmentGuid } =
      useStrictContext(EstimatesContext)

    const goBack = useGoBack()
    const navigate = useNavigate()

    const [showCarousel] = useQueryParam('r')

    const [selectedOptionGuid, setSelectedOptionGuid] = useQueryParamState(
      'option',
      '',
    )

    const [acceptedWidgetShown, showAcceptedWidget] = useQueryParamFlag('a')
    const title = useMemo(() => {
      if (selectedOptionGuid) {
        return 'Accept Estimate'
      }
      if (showCarousel) {
        return 'Select Estimate'
      }

      return undefined
    }, [selectedOptionGuid, showCarousel])

    const isBasePage = !showCarousel

    const acceptedAt = useMemo(() => BzDateFns.nowISOString(), [])

    const onFinish = useCallback(() => {
      if (jobAppointmentGuid) {
        navigate(`/appointments/${jobAppointmentGuid}`)
      } else {
        navigate(`/estimates/${estimateGuid}`)
      }
    }, [jobAppointmentGuid, estimateGuid, navigate])

    const setAcceptedMutation =
      trpc.invoice['invoicing:estimatev2:set-accepted'].useMutation()

    const acceptEstimate = useCallback(
      async (signaturePNG: string) => {
        try {
          await setAcceptedMutation.mutateAsync({
            estimateGuid,
            accountGuid,
            selectedOptionGuid,
            signaturePNG,
          })

          showAcceptedWidget()
        } catch (e) {
          console.error(e)
        }
      },
      [
        accountGuid,
        estimateGuid,
        selectedOptionGuid,
        setAcceptedMutation,
        showAcceptedWidget,
      ],
    )

    const [convertToInvoiceOpen, openConvertToInvoice, closeConvertToInvoice] =
      useModalState()

    const [sendModalOpen, openSendModal, closeSendModal] = useModalState()

    const pageDimensions = useOnsitePageDimensions()

    return (
      // Note that the "pb-6" is for the little iPhone bar thing
      <div
        className="fixed inset-0 z-[1002] flex flex-col items-center bg-white pb-6"
        data-testid="accept-estimate-view"
      >
        <Button
          data-testid="accept-mode-back-button"
          className="absolute left-4 top-2 z-10 flex h-9 w-9 items-center justify-center text-lg"
          shape="circle"
          icon={<FontAwesomeIcon icon={isBasePage ? faClose : faChevronLeft} />}
          onClick={goBack}
        />
        {title && (
          <div className="relative my-2 flex h-9 items-center justify-center px-4">
            <div className="text-xl font-semibold">{title}</div>
          </div>
        )}
        <div className="w-full overflow-auto">
          <div
            className={classNames('mx-auto', {
              'pt-12': !title,
            })}
            style={pageDimensions.style}
          >
            {selectedOptionGuid ? (
              <SignatureForm
                onCancel={goBack}
                onSubmit={acceptEstimate}
                loading={setAcceptedMutation.isLoading}
              />
            ) : (
              <EstimatePresentView onOptionSelect={setSelectedOptionGuid} />
            )}
            {acceptedWidgetShown && (
              <>
                <EstimateAcceptedWidget
                  displayId={estimate.displayId}
                  acceptedAt={acceptedAt}
                  acceptedEmailSentTo={
                    setAcceptedMutation.data?.customerEmailSentTo
                  }
                  footer={
                    <div className="w-full space-y-2">
                      <Button
                        block
                        size="large"
                        type="primary"
                        onClick={onFinish}
                      >
                        {estimate.jobAppointmentGuid
                          ? 'View Appointment'
                          : 'Finish'}
                      </Button>
                      <Button block size="large" onClick={openSendModal}>
                        Send a Copy
                      </Button>
                      <Button block size="large" onClick={openConvertToInvoice}>
                        Convert to Invoice
                      </Button>
                    </div>
                  }
                />
                {sendModalOpen && (
                  <OnsiteModal drawer onClose={closeSendModal} open>
                    <SendAcceptedEstimateForm
                      header="Send a copy"
                      onClose={closeSendModal}
                      emailAddress={
                        estimate.job.pointOfContact.primaryEmailAddress
                          ?.emailAddress
                      }
                      phoneNumber={
                        estimate.job.pointOfContact.primaryPhoneNumber
                          ?.phoneNumber
                      }
                      contactFirstName={estimate.job.pointOfContact.firstName}
                      displayId={estimate.displayId}
                    />
                  </OnsiteModal>
                )}
              </>
            )}
          </div>
        </div>
        {convertToInvoiceOpen && (
          <FinalizeInvoiceDrawer onClose={closeConvertToInvoice} />
        )}
      </div>
    )
  },
)
