import {
  BzDateFns,
  localDateSchema,
  LocalDateString,
  TimeZoneId,
  VisitViewModel,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { DateField } from '../../elements/Forms/DateField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import ThinDivider from '../../elements/ThinDivider'

type RestoreVisitModalProps = {
  open: boolean
  onCancel: () => void
  onConfirm: (newExpirationDate?: LocalDateString) => void
  visit: VisitViewModel
  tzId: TimeZoneId
}

const restoreVisitFormSchema = z.object({
  newExpirationDate: localDateSchema,
})

type RestoreVisitFormData = z.infer<typeof restoreVisitFormSchema>

export const RestoreVisitModal = React.memo<RestoreVisitModalProps>(
  ({ open, onCancel, onConfirm, visit, tzId }) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<RestoreVisitFormData>({
      resolver: zodResolver(restoreVisitFormSchema),
      defaultValues: {
        newExpirationDate: BzDateFns.formatLocalDate(
          BzDateFns.addYears(BzDateFns.getToday(tzId), 1),
        ),
      },
    })

    const onSubmit = useCallback(
      (data: RestoreVisitFormData) => {
        onConfirm(data.newExpirationDate as LocalDateString | undefined)
      },
      [onConfirm],
    )

    const isTimeBasedExpiration =
      visit.status === 'EXPIRED' && !visit.isExpiredOverride

    return (
      <OnsiteConfirmModal
        open={open}
        onCancel={onCancel}
        onConfirm={handleSubmit(onSubmit)}
        header="Restore plan visit?"
        confirmText="Restore"
        cancelText="Cancel"
      >
        {isTimeBasedExpiration ? (
          <Form layout="vertical" onSubmitCapture={handleSubmit(onSubmit)}>
            <p className="text-base">
              <span className="font-semibold">{visit.name}</span> expired on{' '}
              {BzDateFns.formatFromISO(visit.expiresAt, 'MMMM d, yyyy', tzId)}.
              To restore this visit, you'll need to set a new expiration date
              below.
            </p>
            <ThinDivider widthPx={1} />
            <ReactHookFormItem
              control={control}
              name="newExpirationDate"
              label="New Expiration Date"
              required
              errors={errors}
              render={({ field }) => (
                <DateField
                  {...field}
                  disabledDate={current => current && current < new Date()}
                />
              )}
            />
          </Form>
        ) : (
          <p className="text-base">
            Are you sure you want to restore{' '}
            <span className="font-semibold">{visit.name}?</span> This action can
            be reversed.
          </p>
        )}
      </OnsiteConfirmModal>
    )
  },
)
