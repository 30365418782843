import { z } from 'zod'
import { AccountType } from '../Accounts/AccountType'
import { bzOptional } from '../common-schemas'

export const externalWorkRequestFormSubmissionRequestSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  phoneNumber: z.string(),
  streetAddress: z.string(),
  city: z.string(),
  state: z.string(),
  zipCode: z.string(),
  municipality: bzOptional(z.string()),
  residenceType: bzOptional(z.string()),
  estimatedSquareFootage: bzOptional(z.string()),
  yearOfConstruction: bzOptional(z.string()),
  isWorking: bzOptional(z.boolean()),
  hasFrequentOutages: bzOptional(z.boolean()),
  existingHeatingTypes: bzOptional(z.array(z.string())),
  fuelTypes: bzOptional(z.array(z.string())),
  consideringEV: bzOptional(z.boolean()),
  fossilFuelSystems: bzOptional(z.array(z.string())),
  servicesInterestedIn: bzOptional(z.array(z.string())),
  comments: bzOptional(z.string()),
  accountType: bzOptional(z.nativeEnum(AccountType)),
  jobType: z.string(),
})

export type ExternalWorkRequestFormSubmissionRequest = z.infer<typeof externalWorkRequestFormSubmissionRequestSchema>

export type ExternalWorkRequestFormSubmissionResponse = {
  accountUrl: string
  workUrl: string
}
