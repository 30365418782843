import React from 'react'

type EstimatesGraphicProps = {
  width?: string
  height?: string
  className?: string
}
export const EstimatesGraphic = React.memo<EstimatesGraphicProps>(
  ({ width = '721', height = '853', className }) => (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 721 853"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2392_200329)">
        <rect
          x="33.8096"
          y="265.219"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 33.8096 265.219)"
          fill="white"
        />
        <rect
          x="33.8096"
          y="265.219"
          width="108"
          height="32"
          transform="rotate(-25 33.8096 265.219)"
          fill="#F0F0F0"
        />
        <rect
          x="57.9648"
          y="298.09"
          width="92"
          height="8"
          transform="rotate(-25 57.9648 298.09)"
          fill="#F0F0F0"
        />
        <rect
          x="64.7266"
          y="312.591"
          width="92"
          height="8"
          transform="rotate(-25 64.7266 312.591)"
          fill="#F0F0F0"
        />
        <rect
          x="71.4883"
          y="327.092"
          width="92"
          height="8"
          transform="rotate(-25 71.4883 327.092)"
          fill="#F0F0F0"
        />
        <rect
          x="78.25"
          y="341.593"
          width="92"
          height="8"
          transform="rotate(-25 78.25 341.593)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(77.7617 359.475) rotate(-25)"
          fill="white"
        />
        <rect
          x="88.3936"
          y="363.344"
          width="92"
          height="16"
          transform="rotate(-25 88.3936 363.344)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="35.1385"
        y="265.702"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 35.1385 265.702)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip1_2392_200329)">
        <rect
          x="94.666"
          y="395.727"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 94.666 395.727)"
          fill="white"
        />
        <rect
          x="94.666"
          y="395.727"
          width="108"
          height="32"
          transform="rotate(-25 94.666 395.727)"
          fill="#F0F0F0"
        />
        <rect
          x="118.821"
          y="428.598"
          width="92"
          height="8"
          transform="rotate(-25 118.821 428.598)"
          fill="#F0F0F0"
        />
        <rect
          x="125.583"
          y="443.099"
          width="92"
          height="8"
          transform="rotate(-25 125.583 443.099)"
          fill="#F0F0F0"
        />
        <rect
          x="132.345"
          y="457.6"
          width="92"
          height="8"
          transform="rotate(-25 132.345 457.6)"
          fill="#F0F0F0"
        />
        <rect
          x="139.106"
          y="472.101"
          width="92"
          height="8"
          transform="rotate(-25 139.106 472.101)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(138.618 489.982) rotate(-25)"
          fill="white"
        />
        <rect
          x="149.25"
          y="493.852"
          width="92"
          height="16"
          transform="rotate(-25 149.25 493.852)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="95.9949"
        y="396.21"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 95.9949 396.21)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip2_2392_200329)">
        <rect
          x="155.523"
          y="526.235"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 155.523 526.235)"
          fill="white"
        />
        <rect
          x="155.523"
          y="526.235"
          width="108"
          height="32"
          transform="rotate(-25 155.523 526.235)"
          fill="#F0F0F0"
        />
        <rect
          x="179.679"
          y="559.106"
          width="92"
          height="8"
          transform="rotate(-25 179.679 559.106)"
          fill="#F0F0F0"
        />
        <rect
          x="186.44"
          y="573.607"
          width="92"
          height="8"
          transform="rotate(-25 186.44 573.607)"
          fill="#F0F0F0"
        />
        <rect
          x="193.202"
          y="588.108"
          width="92"
          height="8"
          transform="rotate(-25 193.202 588.108)"
          fill="#F0F0F0"
        />
        <rect
          x="199.964"
          y="602.609"
          width="92"
          height="8"
          transform="rotate(-25 199.964 602.609)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(199.476 620.491) rotate(-25)"
          fill="white"
        />
        <rect
          x="210.107"
          y="624.36"
          width="92"
          height="16"
          transform="rotate(-25 210.107 624.36)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="156.852"
        y="526.719"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 156.852 526.719)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip3_2392_200329)">
        <rect
          x="216.381"
          y="656.743"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 216.381 656.743)"
          fill="white"
        />
        <rect
          x="216.381"
          y="656.743"
          width="108"
          height="32"
          transform="rotate(-25 216.381 656.743)"
          fill="#F0F0F0"
        />
        <rect
          x="240.536"
          y="689.614"
          width="92"
          height="8"
          transform="rotate(-25 240.536 689.614)"
          fill="#F0F0F0"
        />
        <rect
          x="247.298"
          y="704.115"
          width="92"
          height="8"
          transform="rotate(-25 247.298 704.115)"
          fill="#F0F0F0"
        />
        <rect
          x="254.06"
          y="718.616"
          width="92"
          height="8"
          transform="rotate(-25 254.06 718.616)"
          fill="#F0F0F0"
        />
        <rect
          x="260.821"
          y="733.117"
          width="92"
          height="8"
          transform="rotate(-25 260.821 733.117)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(260.333 750.999) rotate(-25)"
          fill="white"
        />
        <rect
          x="270.965"
          y="754.868"
          width="92"
          height="16"
          transform="rotate(-25 270.965 754.868)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="217.71"
        y="657.227"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 217.71 657.227)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip4_2392_200329)">
        <rect
          x="105.132"
          y="143.69"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 105.132 143.69)"
          fill="white"
        />
        <rect
          x="105.132"
          y="143.69"
          width="108"
          height="32"
          transform="rotate(-25 105.132 143.69)"
          fill="#F0F0F0"
        />
        <rect
          x="129.287"
          y="176.562"
          width="92"
          height="8"
          transform="rotate(-25 129.287 176.562)"
          fill="#F0F0F0"
        />
        <rect
          x="136.049"
          y="191.062"
          width="92"
          height="8"
          transform="rotate(-25 136.049 191.062)"
          fill="#F0F0F0"
        />
        <rect
          x="142.811"
          y="205.563"
          width="92"
          height="8"
          transform="rotate(-25 142.811 205.563)"
          fill="#F0F0F0"
        />
        <rect
          x="149.572"
          y="220.064"
          width="92"
          height="8"
          transform="rotate(-25 149.572 220.064)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(149.084 237.946) rotate(-25)"
          fill="white"
        />
        <rect
          x="159.716"
          y="241.815"
          width="92"
          height="16"
          transform="rotate(-25 159.716 241.815)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="106.461"
        y="144.174"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 106.461 144.174)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip5_2392_200329)">
        <rect
          x="165.989"
          y="274.199"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 165.989 274.199)"
          fill="white"
        />
        <rect
          x="165.989"
          y="274.199"
          width="108"
          height="32"
          transform="rotate(-25 165.989 274.199)"
          fill="#F0F0F0"
        />
        <rect
          x="190.145"
          y="307.07"
          width="92"
          height="8"
          transform="rotate(-25 190.145 307.07)"
          fill="#F0F0F0"
        />
        <rect
          x="196.906"
          y="321.571"
          width="92"
          height="8"
          transform="rotate(-25 196.906 321.571)"
          fill="#F0F0F0"
        />
        <rect
          x="203.668"
          y="336.072"
          width="92"
          height="8"
          transform="rotate(-25 203.668 336.072)"
          fill="#F0F0F0"
        />
        <rect
          x="210.43"
          y="350.573"
          width="92"
          height="8"
          transform="rotate(-25 210.43 350.573)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(209.941 368.455) rotate(-25)"
          fill="white"
        />
        <rect
          x="220.573"
          y="372.324"
          width="92"
          height="16"
          transform="rotate(-25 220.573 372.324)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="167.318"
        y="274.683"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 167.318 274.683)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip6_2392_200329)">
        <rect
          x="226.846"
          y="404.707"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 226.846 404.707)"
          fill="white"
        />
        <rect
          x="226.846"
          y="404.707"
          width="108"
          height="32"
          transform="rotate(-25 226.846 404.707)"
          fill="#F0F0F0"
        />
        <rect
          x="251.001"
          y="437.578"
          width="92"
          height="8"
          transform="rotate(-25 251.001 437.578)"
          fill="#F0F0F0"
        />
        <rect
          x="257.763"
          y="452.079"
          width="92"
          height="8"
          transform="rotate(-25 257.763 452.079)"
          fill="#F0F0F0"
        />
        <rect
          x="264.524"
          y="466.58"
          width="92"
          height="8"
          transform="rotate(-25 264.524 466.58)"
          fill="#F0F0F0"
        />
        <rect
          x="271.286"
          y="481.081"
          width="92"
          height="8"
          transform="rotate(-25 271.286 481.081)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(270.798 498.963) rotate(-25)"
          fill="white"
        />
        <rect
          x="281.43"
          y="502.832"
          width="92"
          height="16"
          transform="rotate(-25 281.43 502.832)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="228.175"
        y="405.191"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 228.175 405.191)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip7_2392_200329)">
        <rect
          x="287.703"
          y="535.216"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 287.703 535.216)"
          fill="white"
        />
        <rect
          x="287.703"
          y="535.216"
          width="108"
          height="32"
          transform="rotate(-25 287.703 535.216)"
          fill="#F0F0F0"
        />
        <rect
          x="311.858"
          y="568.087"
          width="92"
          height="8"
          transform="rotate(-25 311.858 568.087)"
          fill="#F0F0F0"
        />
        <rect
          x="318.62"
          y="582.588"
          width="92"
          height="8"
          transform="rotate(-25 318.62 582.588)"
          fill="#F0F0F0"
        />
        <rect
          x="325.382"
          y="597.089"
          width="92"
          height="8"
          transform="rotate(-25 325.382 597.089)"
          fill="#F0F0F0"
        />
        <rect
          x="332.144"
          y="611.59"
          width="92"
          height="8"
          transform="rotate(-25 332.144 611.59)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(331.655 629.472) rotate(-25)"
          fill="white"
        />
        <rect
          x="342.287"
          y="633.341"
          width="92"
          height="16"
          transform="rotate(-25 342.287 633.341)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="289.032"
        y="535.7"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 289.032 535.7)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip8_2392_200329)">
        <rect
          x="244.073"
          y="167.171"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 244.073 167.171)"
          fill="white"
        />
        <rect
          x="244.073"
          y="167.171"
          width="108"
          height="32"
          transform="rotate(-25 244.073 167.171)"
          fill="#F0F0F0"
        />
        <rect
          x="268.229"
          y="200.042"
          width="92"
          height="8"
          transform="rotate(-25 268.229 200.042)"
          fill="#F0F0F0"
        />
        <rect
          x="274.99"
          y="214.543"
          width="92"
          height="8"
          transform="rotate(-25 274.99 214.543)"
          fill="#F0F0F0"
        />
        <rect
          x="281.752"
          y="229.044"
          width="92"
          height="8"
          transform="rotate(-25 281.752 229.044)"
          fill="#F0F0F0"
        />
        <rect
          x="288.514"
          y="243.545"
          width="92"
          height="8"
          transform="rotate(-25 288.514 243.545)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(288.025 261.427) rotate(-25)"
          fill="white"
        />
        <rect
          x="298.657"
          y="265.296"
          width="92"
          height="16"
          transform="rotate(-25 298.657 265.296)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="245.402"
        y="167.655"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 245.402 167.655)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip9_2392_200329)">
        <rect
          x="304.93"
          y="297.679"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 304.93 297.679)"
          fill="white"
        />
        <rect
          x="304.93"
          y="297.679"
          width="108"
          height="32"
          transform="rotate(-25 304.93 297.679)"
          fill="#F0F0F0"
        />
        <rect
          x="329.085"
          y="330.55"
          width="92"
          height="8"
          transform="rotate(-25 329.085 330.55)"
          fill="#F0F0F0"
        />
        <rect
          x="335.847"
          y="345.051"
          width="92"
          height="8"
          transform="rotate(-25 335.847 345.051)"
          fill="#F0F0F0"
        />
        <rect
          x="342.608"
          y="359.552"
          width="92"
          height="8"
          transform="rotate(-25 342.608 359.552)"
          fill="#F0F0F0"
        />
        <rect
          x="349.37"
          y="374.053"
          width="92"
          height="8"
          transform="rotate(-25 349.37 374.053)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(348.882 391.935) rotate(-25)"
          fill="white"
        />
        <rect
          x="359.514"
          y="395.804"
          width="92"
          height="16"
          transform="rotate(-25 359.514 395.804)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="306.259"
        y="298.162"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 306.259 298.162)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip10_2392_200329)">
        <rect
          x="365.787"
          y="428.188"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 365.787 428.188)"
          fill="white"
        />
        <rect
          x="365.787"
          y="428.188"
          width="108"
          height="32"
          transform="rotate(-25 365.787 428.188)"
          fill="#F0F0F0"
        />
        <rect
          x="389.942"
          y="461.059"
          width="92"
          height="8"
          transform="rotate(-25 389.942 461.059)"
          fill="#F0F0F0"
        />
        <rect
          x="396.704"
          y="475.56"
          width="92"
          height="8"
          transform="rotate(-25 396.704 475.56)"
          fill="#F0F0F0"
        />
        <rect
          x="403.466"
          y="490.061"
          width="92"
          height="8"
          transform="rotate(-25 403.466 490.061)"
          fill="#F0F0F0"
        />
        <rect
          x="410.228"
          y="504.562"
          width="92"
          height="8"
          transform="rotate(-25 410.228 504.562)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(409.739 522.443) rotate(-25)"
          fill="white"
        />
        <rect
          x="420.371"
          y="526.312"
          width="92"
          height="16"
          transform="rotate(-25 420.371 526.312)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="367.116"
        y="428.671"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 367.116 428.671)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip11_2392_200329)">
        <rect
          x="426.645"
          y="558.695"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 426.645 558.695)"
          fill="white"
        />
        <rect
          x="426.645"
          y="558.695"
          width="108"
          height="32"
          transform="rotate(-25 426.645 558.695)"
          fill="#F0F0F0"
        />
        <rect
          x="450.8"
          y="591.566"
          width="92"
          height="8"
          transform="rotate(-25 450.8 591.566)"
          fill="#F0F0F0"
        />
        <rect
          x="457.562"
          y="606.067"
          width="92"
          height="8"
          transform="rotate(-25 457.562 606.067)"
          fill="#F0F0F0"
        />
        <rect
          x="464.323"
          y="620.568"
          width="92"
          height="8"
          transform="rotate(-25 464.323 620.568)"
          fill="#F0F0F0"
        />
        <rect
          x="471.085"
          y="635.069"
          width="92"
          height="8"
          transform="rotate(-25 471.085 635.069)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(470.597 652.951) rotate(-25)"
          fill="white"
        />
        <rect
          x="481.229"
          y="656.82"
          width="92"
          height="16"
          transform="rotate(-25 481.229 656.82)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="427.973"
        y="559.179"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 427.973 559.179)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip12_2392_200329)">
        <rect
          x="315.396"
          y="45.6426"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 315.396 45.6426)"
          fill="white"
        />
        <rect
          x="315.396"
          y="45.6426"
          width="108"
          height="32"
          transform="rotate(-25 315.396 45.6426)"
          fill="#F0F0F0"
        />
        <rect
          x="339.551"
          y="78.5137"
          width="92"
          height="8"
          transform="rotate(-25 339.551 78.5137)"
          fill="#F0F0F0"
        />
        <rect
          x="346.312"
          y="93.0146"
          width="92"
          height="8"
          transform="rotate(-25 346.312 93.0146)"
          fill="#F0F0F0"
        />
        <rect
          x="353.074"
          y="107.516"
          width="92"
          height="8"
          transform="rotate(-25 353.074 107.516)"
          fill="#F0F0F0"
        />
        <rect
          x="359.836"
          y="122.017"
          width="92"
          height="8"
          transform="rotate(-25 359.836 122.017)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(359.348 139.898) rotate(-25)"
          fill="white"
        />
        <rect
          x="369.979"
          y="143.768"
          width="92"
          height="16"
          transform="rotate(-25 369.979 143.768)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="316.724"
        y="46.1263"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 316.724 46.1263)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip13_2392_200329)">
        <rect
          x="376.253"
          y="176.151"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 376.253 176.151)"
          fill="white"
        />
        <rect
          x="376.253"
          y="176.151"
          width="108"
          height="32"
          transform="rotate(-25 376.253 176.151)"
          fill="#F0F0F0"
        />
        <rect
          x="400.408"
          y="209.022"
          width="92"
          height="8"
          transform="rotate(-25 400.408 209.022)"
          fill="#F0F0F0"
        />
        <rect
          x="407.17"
          y="223.523"
          width="92"
          height="8"
          transform="rotate(-25 407.17 223.523)"
          fill="#F0F0F0"
        />
        <rect
          x="413.932"
          y="238.024"
          width="92"
          height="8"
          transform="rotate(-25 413.932 238.024)"
          fill="#F0F0F0"
        />
        <rect
          x="420.693"
          y="252.525"
          width="92"
          height="8"
          transform="rotate(-25 420.693 252.525)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(420.205 270.407) rotate(-25)"
          fill="white"
        />
        <rect
          x="430.837"
          y="274.276"
          width="92"
          height="16"
          transform="rotate(-25 430.837 274.276)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="377.582"
        y="176.635"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 377.582 176.635)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip14_2392_200329)">
        <rect
          x="437.109"
          y="306.659"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 437.109 306.659)"
          fill="white"
        />
        <rect
          x="437.109"
          y="306.659"
          width="108"
          height="32"
          transform="rotate(-25 437.109 306.659)"
          fill="#F0F0F0"
        />
        <rect
          x="461.265"
          y="339.53"
          width="92"
          height="8"
          transform="rotate(-25 461.265 339.53)"
          fill="#F0F0F0"
        />
        <rect
          x="468.026"
          y="354.031"
          width="92"
          height="8"
          transform="rotate(-25 468.026 354.031)"
          fill="#F0F0F0"
        />
        <rect
          x="474.788"
          y="368.532"
          width="92"
          height="8"
          transform="rotate(-25 474.788 368.532)"
          fill="#F0F0F0"
        />
        <rect
          x="481.55"
          y="383.033"
          width="92"
          height="8"
          transform="rotate(-25 481.55 383.033)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(481.062 400.915) rotate(-25)"
          fill="white"
        />
        <rect
          x="491.693"
          y="404.784"
          width="92"
          height="16"
          transform="rotate(-25 491.693 404.784)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="438.438"
        y="307.143"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 438.438 307.143)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <g clipPath="url(#clip15_2392_200329)">
        <rect
          x="497.967"
          y="437.168"
          width="108"
          height="136"
          rx="4"
          transform="rotate(-25 497.967 437.168)"
          fill="white"
        />
        <rect
          x="497.967"
          y="437.168"
          width="108"
          height="32"
          transform="rotate(-25 497.967 437.168)"
          fill="#F0F0F0"
        />
        <rect
          x="522.122"
          y="470.039"
          width="92"
          height="8"
          transform="rotate(-25 522.122 470.039)"
          fill="#F0F0F0"
        />
        <rect
          x="528.884"
          y="484.54"
          width="92"
          height="8"
          transform="rotate(-25 528.884 484.54)"
          fill="#F0F0F0"
        />
        <rect
          x="535.646"
          y="499.041"
          width="92"
          height="8"
          transform="rotate(-25 535.646 499.041)"
          fill="#F0F0F0"
        />
        <rect
          x="542.407"
          y="513.542"
          width="92"
          height="8"
          transform="rotate(-25 542.407 513.542)"
          fill="#F0F0F0"
        />
        <rect
          width="108"
          height="32"
          transform="translate(541.919 531.424) rotate(-25)"
          fill="white"
        />
        <rect
          x="552.551"
          y="535.293"
          width="92"
          height="16"
          transform="rotate(-25 552.551 535.293)"
          fill="#BAE7FF"
        />
      </g>
      <rect
        x="499.296"
        y="437.652"
        width="106"
        height="134"
        rx="3"
        transform="rotate(-25 499.296 437.652)"
        stroke="#F0F0F0"
        strokeWidth="2"
      />
      <defs>
        <clipPath id="clip0_2392_200329">
          <rect
            x="33.8096"
            y="265.219"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 33.8096 265.219)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_2392_200329">
          <rect
            x="94.666"
            y="395.727"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 94.666 395.727)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip2_2392_200329">
          <rect
            x="155.523"
            y="526.235"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 155.523 526.235)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip3_2392_200329">
          <rect
            x="216.381"
            y="656.743"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 216.381 656.743)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip4_2392_200329">
          <rect
            x="105.132"
            y="143.69"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 105.132 143.69)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip5_2392_200329">
          <rect
            x="165.989"
            y="274.199"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 165.989 274.199)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip6_2392_200329">
          <rect
            x="226.846"
            y="404.707"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 226.846 404.707)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip7_2392_200329">
          <rect
            x="287.703"
            y="535.216"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 287.703 535.216)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip8_2392_200329">
          <rect
            x="244.073"
            y="167.171"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 244.073 167.171)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip9_2392_200329">
          <rect
            x="304.93"
            y="297.679"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 304.93 297.679)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip10_2392_200329">
          <rect
            x="365.787"
            y="428.188"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 365.787 428.188)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip11_2392_200329">
          <rect
            x="426.645"
            y="558.695"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 426.645 558.695)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip12_2392_200329">
          <rect
            x="315.396"
            y="45.6426"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 315.396 45.6426)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip13_2392_200329">
          <rect
            x="376.253"
            y="176.151"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 376.253 176.151)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip14_2392_200329">
          <rect
            x="437.109"
            y="306.659"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 437.109 306.659)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip15_2392_200329">
          <rect
            x="497.967"
            y="437.168"
            width="108"
            height="136"
            rx="4"
            transform="rotate(-25 497.967 437.168)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  ),
)
