import { MaintenancePlanDefinition } from '@breezy/shared'
import { faScrewdriverWrench } from '@fortawesome/pro-regular-svg-icons'
import { ExpandableHeightOptionCardHeader } from './ExpandableHeightOptionCardHeader'
import { MaintenancePlanTypeDetailPopover } from './MaintenancePlanTypeDetailPopover'

type MaintenancePlanCardHeaderProps = {
  maintenancePlanDefinition: MaintenancePlanDefinition
}

export const MaintenancePlanCardHeader = ({
  maintenancePlanDefinition,
}: MaintenancePlanCardHeaderProps) => {
  return (
    <ExpandableHeightOptionCardHeader
      faIcon={faScrewdriverWrench}
      title={maintenancePlanDefinition.name}
      description={maintenancePlanDefinition.shortDescription}
      extraContent={
        <MaintenancePlanTypeDetailPopover
          maintenancePlanDefinition={maintenancePlanDefinition}
        />
      }
    />
  )
}
