import { useMemo } from 'react'
import { useQuery } from 'urql'
import { gql } from '../../generated'

const QUERY = gql(/* GraphQL */ `
  query MaintenancePlanInvoice($maintenancePlanGuid: uuid!) {
    maintenancePlanInvoices(
      where: { maintenancePlanGuid: { _eq: $maintenancePlanGuid } }
    ) {
      invoiceGuid
    }
  }
`)

export const useMaintenancePlanInvoiceGuid = (
  maintenancePlanGuid: string,
): string | undefined => {
  const [{ data }] = useQuery({
    query: QUERY,
    variables: { maintenancePlanGuid },
  })

  return useMemo(() => data?.maintenancePlanInvoices[0]?.invoiceGuid, [data])
}
