import { anonGql } from '../../generated'

export const CARD_ON_FILE_QUERY = anonGql(/* GraphQL */ `
  query ExternalCardsOnFile($accountGuid: uuid!) {
    paymentMethodRecords(
      where: {
        accountGuid: { _eq: $accountGuid }
        paymentMethodType: { _eq: CARD }
        isDeleted: { _eq: false }
      }
      orderBy: { createdAt: DESC }
    ) {
      paymentMethodRecordGuid
      displayName
      externalPaymentMethodId
      paymentMethodBillingInfo {
        name
      }
      paymentMethodType
      paymentMethodCardMetadata {
        brand
        expMonth
        expYear
        lastFourDigits
        type
      }
      createdAt
      paymentSubscriptions {
        paymentSubscriptionGuid
      }
    }
  }
`)
