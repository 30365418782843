import { floor } from '@breezy/shared'
import cn from 'classnames'
import { useMemo } from 'react'
import type { Stylable } from '../../utils/Stylable'
import { m } from '../../utils/react-utils'
import './Layout.less'
import { COLUMN_GAPS } from './LayoutPage'
import type {
  Breakpoint,
  BreakpointValues,
  DefaultCssValue,
} from './applyBreakpointProperties'
import {
  applyBreakpointProperties,
  getNearestBreakpointValue,
} from './applyBreakpointProperties'

type Size = number

type Order = DefaultCssValue | number

/**
 *
 */
const SIZE_BASIS = 12

const createSizeStyle = (
  breakpoint: Breakpoint,
  {
    size: sizeObj,
    order: orderObj,
  }: Pick<LayoutSectionProps, 'size' | 'order'>,
) => {
  const size = getNearestBreakpointValue(sizeObj, breakpoint) ?? SIZE_BASIS
  const order = getNearestBreakpointValue(orderObj, breakpoint)
  const gap = COLUMN_GAPS[breakpoint]
  const margin = `${gap / 2}px`
  const width = `calc(${floor((100 * size) / SIZE_BASIS, 4)}% - ${
    COLUMN_GAPS[breakpoint]
  }px)`

  return {
    order,
    marginLeft: margin,
    marginRight: margin,
    flexBasis: width,
    maxWidth: width,
    minWidth: width,
    // When the item is full width (size === SIZE_BASIS) we want the height of the item
    // to be the size of its content, and we don't want it to scroll (on mobile, the
    // whole page scrolls). When it's part of a multi-column layout, we want all columns
    // to be full page height (height: 100%) and we want them to internally scroll
    // (overflow: auto)
    overflow: size === SIZE_BASIS ? '' : 'auto',
    // NOTE: This may or may not work on Safari. If it doesn't, we ought to use 'auto' for Safari
    // eslint-disable-next-line no-restricted-syntax
    height: size === SIZE_BASIS ? 'fit-content' : '100%',
  }
}

export type LayoutSectionProps = Stylable & {
  children: React.ReactNode
  card?: boolean
  size?: BreakpointValues<Size>
  order?: BreakpointValues<Order>
}

export const LayoutSection = m<LayoutSectionProps>(
  ({ size = {}, order = {}, children, style, className, card }) => {
    const styledClasses = card && ['bg-white', 'shadow-md', 'rounded-md']

    const styleAttribute = useMemo(
      (): React.CSSProperties => ({
        ...applyBreakpointProperties(breakpoint =>
          createSizeStyle(breakpoint, { size, order }),
        ),
        ...style,
      }),
      [order, size, style],
    )

    return (
      <div
        style={styleAttribute}
        className={cn(className, styledClasses, 'layout__section')}
      >
        {children}
      </div>
    )
  },
)
