import { gql } from '../../generated/user/gql'

export const CHECK_IF_COMPANY_HAS_ACTIVE_MAINTENANCE_PLANS = gql(/* GraphQL */ `
  query CheckIfCompanyHasActiveMaintenancePlans($companyGuid: uuid!) {
    maintenancePlansQueryable(
      limit: 1
      where: { companyGuid: { _eq: $companyGuid }, status: { _eq: "ACTIVE" } }
    ) {
      status
    }
  }
`)

export const FETCH_COMPANY_MAINTENANCE_PLAN_ENABLED = gql(/* GraphQL */ `
  query FetchCompanyMaintenancePlansEnabled($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      maintenancePlansEnabled
    }
  }
`)
