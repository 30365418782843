import { trpc } from '../../hooks/trpc'

export const useTwilioTrpc = () => {
  const getAccessTokenMutation =
    trpc.twilio['twilio:get-access-token'].useMutation()

  const recordCallAnsweredMutation =
    trpc.twilio['twilio:record-call-answered'].useMutation()

  return {
    getAccessTokenMutation,
    recordCallAnsweredMutation,
  }
}
