import React, { useCallback, useMemo, useState } from 'react'
import { useStrictContext } from '../../../utils/react-utils'
import { PaymentStatusCardProps } from '../components/PaymentStatus/PaymentStatusCard'

type PaymentStatusContextTypes = {
  paymentStatusProps: PaymentStatusCardProps | undefined
  setPaymentStatusProps: (props: PaymentStatusCardProps) => void
  clearPaymentStatusProps: () => void
  isProcessingPayment: boolean
}

const PaymentStatusContext = React.createContext<
  PaymentStatusContextTypes | undefined
>({
  paymentStatusProps: undefined,
  setPaymentStatusProps: () => {},
  clearPaymentStatusProps: () => {},
  isProcessingPayment: false,
})

export const usePaymentStatusContext = () => {
  const context = useStrictContext(PaymentStatusContext)
  return context
}

export const PaymentStatusProvider = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const [props, setProps] = useState<PaymentStatusCardProps>()
    const setPaymentStatusProps = useCallback(
      (props: PaymentStatusCardProps) => {
        setProps(props)
      },
      [],
    )

    const clearPaymentStatusProps = useCallback(() => {
      setProps(undefined)
    }, [])

    const isProcessingPayment = useMemo(
      () => props?.type === 'payment-processing',
      [props],
    )

    return (
      <PaymentStatusContext.Provider
        value={{
          setPaymentStatusProps,
          clearPaymentStatusProps,
          paymentStatusProps: props,
          isProcessingPayment,
        }}
      >
        {children}
      </PaymentStatusContext.Provider>
    )
  },
)
