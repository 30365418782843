import { gql } from '../../generated'

export const MAINTENANCE_PLAN_COLLAPSIBLES_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanCollapsiblesQuery(
    $paymentWhereClause: PaymentRecordsBoolExp
    $jobsWhereClause: JobsBoolExp
    $appointmentsWhereClause: JobAppointmentsBoolExp
  ) {
    paymentRecords(where: $paymentWhereClause) {
      amountUsd
      occurredAt
      paymentMethod
      invoiceGuid
      guid: paymentRecordGuid
      referenceNumber: paymentReferenceNumber
      accountGuid
      account {
        accountDisplayName
      }
      paymentStatusesAggregate(
        orderBy: [{ occurredAt: DESC }, { createdAt: DESC }]
        limit: 1
      ) {
        nodes {
          paymentStatus
        }
      }
      links: paymentLink {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
        collectedByUserGuid
        loanRecord {
          ...LoanRecord
        }
      }
    }
    jobs(where: $jobsWhereClause) {
      jobGuid
      jobType {
        name
      }
      displayId
      jobCreatedAt: createdAt
      jobLifecycleStatus {
        name
      }
    }
    jobAppointments(where: $appointmentsWhereClause) {
      appointmentReferenceNumber
      appointmentWindowEnd
      appointmentWindowStart
      jobAppointmentGuid
      appointmentType
      description
      endOfAppointmentNextSteps
      job {
        jobType {
          jobClass
        }
        location {
          address {
            line1
            line2
            city
            stateAbbreviation
            zipCode
          }
        }
      }
      assignments {
        assignmentStart
        assignmentEnd
        jobAppointmentAssignmentGuid
        assignmentStatus {
          jobAppointmentAssignmentStatusGuid
          jobAppointmentAssignmentStatusType
          createdAt
        }
        technician {
          userGuid
          firstName
          lastName
        }
      }
      cancellationStatus {
        canceled
        createdAt
        updatedAt
      }
      confirmationStatus {
        confirmed
        createdAt
        updatedAt
      }
    }
  }
`)
