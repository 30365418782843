import { JobTeamMember, usCentsToUsd } from '@breezy/shared'
import { useMemo } from 'react'

export const useDefaultTechnicianGuidToCommissionAndBonusMap = (
  teamMembers: JobTeamMember[],
) => {
  return useMemo(() => {
    const map: Record<string, { commissionPercent: number; bonusUsd: number }> =
      {}

    teamMembers.forEach(teamMember => {
      map[teamMember.userGuid] = {
        commissionPercent: (teamMember.commissionRate ?? 0) * 100,
        bonusUsd: usCentsToUsd(teamMember.bonusUsc ?? 0),
      }
    })

    return map
  }, [teamMembers])
}
