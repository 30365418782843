import { DocumentType, gql } from '../../generated'

export const LINKED_JOB_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription JobAttachmentsLinkedJobDataQuery($jobGuid: uuid!) {
    jobsWithLinkedJob(where: { jobGuid: { _eq: $jobGuid } }) {
      jobGuid
      linkNotes
      linkAttachments
      linked_job {
        jobGuid
        displayId
        companyGuid
        fileLinks {
          fileGuid
          file {
            fileName
            metadata
            createdByUserGuid
            fileSizeBytes
            fileTypeMime
            cdnUrl
            resourceUrn
            storageStrategy
            createdAt
          }
        }
      }
    }
  }
`)

export type LinkedJobData = DocumentType<
  typeof LINKED_JOB_DATA_SUBSCRIPTION
>['jobsWithLinkedJob'][number]
