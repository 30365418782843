import { anonGql } from '../../../generated'

export const ANON_CONTACT_QUERY = anonGql(/* GraphQL */ `
  query AnonContact($companyGuid: uuid!, $contactGuid: uuid!) {
    contacts(
      where: {
        contactGuid: { _eq: $contactGuid }
        companyGuid: { _eq: $companyGuid }
      }
    ) {
      firstName
      firstNameInitial
    }
  }
`)
