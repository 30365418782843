import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Modal, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import { useIsSmallScreen } from '../../../hooks/useIsMobile'
import { m } from '../../../utils/react-utils'
type FormCancelSubmitButtonsProps = {
  primaryButtonText?: string
  onSubmit?: () => void
  onCancel?: () => void
  justify?: 'start' | 'end'
  confirmWarning?: string
  disabled?: boolean
  disabledReason?: string
  bypassFormSubmit?: boolean
}

export const FormCancelSubmitButtons = m<FormCancelSubmitButtonsProps>(
  ({
    onCancel,
    onSubmit,
    primaryButtonText,
    justify = 'end',
    confirmWarning,
    disabled,
    disabledReason,
    bypassFormSubmit,
  }) => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const submit = () => {
      setIsConfirmModalOpen(false)
      onSubmit?.()
    }

    const isSmallScreen = useIsSmallScreen()

    const confirmButton = useMemo(() => {
      let button: React.ReactNode = null
      if (confirmWarning && onSubmit) {
        button = (
          <Button
            disabled={disabled}
            block={isSmallScreen}
            size={isSmallScreen ? 'large' : 'middle'}
            type="primary"
            onClick={() => setIsConfirmModalOpen(true)}
          >
            {primaryButtonText || 'Submit'}
          </Button>
        )
      } else if (bypassFormSubmit && onSubmit) {
        // Behaves like a regular button if not part of a form
        button = (
          <Button
            disabled={disabled}
            type="primary"
            onClick={onSubmit}
            block={isSmallScreen}
            size={isSmallScreen ? 'large' : 'middle'}
          >
            {primaryButtonText || 'Submit'}
          </Button>
        )
      } else {
        // Default to form submit
        button = (
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            block={isSmallScreen}
            size={isSmallScreen ? 'large' : 'middle'}
          >
            {primaryButtonText || 'Submit'}
          </Button>
        )
      }
      if (disabled && disabledReason) {
        return <Tooltip title={disabledReason}>{button}</Tooltip>
      }
      return button
    }, [
      bypassFormSubmit,
      confirmWarning,
      disabled,
      disabledReason,
      isSmallScreen,
      onSubmit,
      primaryButtonText,
    ])

    return (
      <>
        {confirmWarning && onSubmit && (
          <Modal
            title="Are you sure?"
            open={isConfirmModalOpen}
            onOk={submit}
            onCancel={() => setIsConfirmModalOpen(false)}
            okButtonProps={{ danger: true }}
          >
            <div className="column">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="4x"
                color="#ffcc33"
                style={{ marginBottom: 12 }}
              />
              <div className="text-center">{confirmWarning}</div>
            </div>
          </Modal>
        )}
        <div
          className={cn('flex space-x-2', {
            'justify-end': justify === 'end',
            'justify-start': justify === 'start',
            'flex flex-1 flex-row justify-end': isSmallScreen,
          })}
        >
          {onCancel && (
            <Form.Item
              className={cn({
                'flex-1': isSmallScreen,
              })}
            >
              <Button
                block={isSmallScreen}
                size={isSmallScreen ? 'large' : 'middle'}
                onClick={() => onCancel?.()}
              >
                Cancel
              </Button>
            </Form.Item>
          )}
          <Form.Item
            className={cn({
              'flex-1': isSmallScreen,
            })}
          >
            {confirmButton}
          </Form.Item>
        </div>
      </>
    )
  },
)
