import { gql } from 'src/generated'

export const COMPANY_USERS_QUERY = gql(/* GraphQL */ `
  query UpsertAppointmentFormCompanyUsersQuery($companyGuid: uuid!) {
    users(where: { companyUser: { companyGuid: { _eq: $companyGuid } } }) {
      userGuid
      firstName
      lastName
      emailAddress
      deactivatedAt
      userPhoneNumbers {
        phoneNumber {
          phoneNumber
          type
        }
      }
      userRoles {
        roleById {
          role
          name
        }
      }
      companyUser {
        schedulingCapability
      }
    }
  }
`)
