import { Page, PageProps } from '../../components/Page/Page'
import { Styled } from '../../utils/Stylable'
import { m } from '../../utils/react-utils'

export const SettingsPage = m<Styled<PageProps>>((props: Styled<PageProps>) => (
  <Page
    {...props}
    className={`flex flex-col p-0 ${props.className}`}
    disableLayout={false}
  />
))
