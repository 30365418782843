import { Button, Popover, Row } from 'antd'
import { PropsWithChildren, memo, useCallback, useState } from 'react'
import ThinDivider from '../../elements/ThinDivider'
import { useMessage } from '../../utils/antd-utils'
import { UsdInputNumber } from './UsdInputNumber'

type TechnicianBonusPopoverFormProps = {
  initialBonusUsd?: number | undefined
  open: boolean
  onCancel?: () => void
  onSubmit?: (bonusUsdValue: number | undefined) => void
}

const TechnicianBonusPopoverForm = memo<
  PropsWithChildren<TechnicianBonusPopoverFormProps>
>(({ initialBonusUsd, open, onCancel, onSubmit, children }) => {
  const message = useMessage()
  const [bonusUsd, setBonusUsd] = useState<number | undefined>(initialBonusUsd)

  const onFormSubmit = useCallback(() => {
    try {
      onSubmit?.(bonusUsd)
    } catch (e) {
      message.error(
        'Failed to update. Please reload the application and try again, or contact support if the problem persists',
      )
    }
  }, [bonusUsd, message, onSubmit])

  const onClickCancelButton = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  return (
    <Popover
      open={open}
      destroyTooltipOnHide={true}
      content={
        <div style={{ width: '265px' }}>
          <Row className="mb-4">
            <strong>Bonus</strong>
          </Row>

          <p>Add a job bonus for this employee.</p>

          <Row className="mb-2 mt-3 w-full">
            <strong>Amount</strong>
          </Row>

          <Row className="w-full">
            <UsdInputNumber
              style={{ width: '100%' }}
              value={bonusUsd}
              onChange={setBonusUsd}
            />
          </Row>

          <ThinDivider widthPx={1} />

          <Row className="mt-3 w-full pt-1" justify="end">
            <Button
              type="default"
              onClick={onClickCancelButton}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button htmlType="button" type="primary" onClick={onFormSubmit}>
              Save
            </Button>
          </Row>
        </div>
      }
    >
      {children}
    </Popover>
  )
})

export default TechnicianBonusPopoverForm
