import {
  AmountUscContainer,
  isFinanceableAmountUsd,
  usCentsToUsd,
} from '@breezy/shared'
import { getConfig } from '../config'
import {
  useWisetackEnabled,
  useWisetackMerchantId,
} from '../providers/CompanyFinancialConfigWrapper'
import { trpc } from './trpc'

const isDemo = getConfig().env === 'demo'

export const useLoanPromo = ({ amountUsc }: AmountUscContainer) => {
  const wisetackEnabled = useWisetackEnabled()
  const wisetackMerchantId = useWisetackMerchantId()
  const isFinanceableAmount = isFinanceableAmountUsd(usCentsToUsd(amountUsc))
  const effectiveLoanPromoWisetackEnabled = isDemo ? true : wisetackEnabled
  const shouldShowLoanPromo =
    effectiveLoanPromoWisetackEnabled &&
    !!wisetackMerchantId &&
    isFinanceableAmount
  // NOTE: Promo is just for promotional purposes, so it won't matter if we're off by less than a penny
  const intAmountUsc = Math.ceil(amountUsc)
  const query = trpc.financing['unauth:financing:get-loan-promo'].useQuery(
    { amountUsc: intAmountUsc, merchantGuid: wisetackMerchantId ?? '' },
    {
      enabled: shouldShowLoanPromo,
    },
  )

  return { query, shouldShowLoanPromo }
}
