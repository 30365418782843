import { PaymentRecordGuidContainer, PermissionV2 } from '@breezy/shared'
import PaymentRecordDetailsView from '../../components/Payments/PaymentRecordDetailsView'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { trpc } from '../../hooks/trpc'
import { m } from '../../utils/react-utils'
import { useParamGuid } from '../../utils/routeParamsUtils'
import NotFoundPage from '../NotFoundPage/NotFoundPage'

const PaymentDetailsPageAuthWrapper = () => {
  return (
    <Authorized
      to={PermissionV2.READ_COMPANY_USERS}
      notAuthorizedView={
        <>
          You do not have permission to view the resource that is being
          requested
        </>
      }
    >
      <PaymentDetailsPageWrapper />
    </Authorized>
  )
}

const NotFoundPaymentPage = m<PaymentRecordGuidContainer>(
  ({ paymentRecordGuid }) => (
    <NotFoundPage entityType="Payment" id={paymentRecordGuid} />
  ),
)

const PaymentDetailsLoader = m<PaymentRecordGuidContainer>(
  ({ paymentRecordGuid }) => {
    const getPaymentsQuery = trpc.payments['payments:get'].useQuery(
      { paymentRecordGuid },
      {
        onError: _ => {},
      },
    )

    return (
      <TrpcQueryLoader
        query={getPaymentsQuery}
        notFoundComponent={
          <NotFoundPaymentPage paymentRecordGuid={paymentRecordGuid} />
        }
        render={payment => <PaymentRecordDetailsView {...payment} />}
      />
    )
  },
)

const PaymentDetailsPageWrapper = () => {
  const [paymentRecordGuid, rawId] = useParamGuid('paymentRecordGuid')

  return paymentRecordGuid ? (
    <PaymentDetailsLoader paymentRecordGuid={paymentRecordGuid} />
  ) : (
    <NotFoundPaymentPage paymentRecordGuid={rawId} />
  )
}

export default PaymentDetailsPageAuthWrapper
