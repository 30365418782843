import { zodResolver } from '@hookform/resolvers/zod'
import { Form, Radio } from 'antd'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import AddressLineOneField from '../../../../elements/Forms/AddressLineOneField'
import FormDivider from '../../../../elements/Forms/FormDivider'
import FormHeader from '../../../../elements/Forms/FormHeader'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../../../elements/Forms/TextField'

import { Address, bzOptional } from '@breezy/shared'
import FormBody from '../../../../elements/Forms/FormBody'
import { StateField, StateSchema } from '../../../../elements/Forms/StateField'
import {
  SetIsDirty,
  useExternalIsDirty,
} from '../../../../hooks/useExternalIsDirty'
import { preventFormSubmitOnEnter } from '../../../../utils/form-helpers'
import { ZipCodeSchema } from '../../utils/ZipCodeSchema'
import PaymentFooterButtons from '../PaymentFooterButtons/PaymentFooterButtons'
import TilledSandboxAchDevTools from '../TilledSandboxAchDevTools/TilledSandboxAchDevTools'
const AchPaymentFormSchema = z.object({
  accountType: z.enum(['checking', 'savings']).describe('Account Type'),
  accountHolderName: z.string().min(1).describe('Account Holder Name'),
  streetAddress: z.string().min(1).describe('Street address'),
  streetAddress2: bzOptional(z.string().describe('Apt, Unit, Building #')),
  city: z.string().min(1).describe('City'),
  state: StateSchema.describe('State'),
  zipCode: ZipCodeSchema,
  savePaymentMethod: z.boolean(),
})

export type AchPaymentFormData = z.infer<typeof AchPaymentFormSchema>

type AchPaymentFormProps = {
  onSubmit: (data: AchPaymentFormData) => void
  onSubmitValidationCheck: () => boolean
  onCancel?: () => void
  name?: string
  streetAddress?: string
  city?: string
  state?: string
  zipCode?: string
  setIsDirty?: SetIsDirty
  isLoading?: boolean
  footerClassName?: string
  primaryButtonText?: string
}

export const AchPaymentForm = React.memo<
  React.PropsWithChildren<AchPaymentFormProps>
>(
  ({
    name,
    streetAddress,
    city,
    state,
    zipCode,
    onCancel,
    onSubmit,
    onSubmitValidationCheck,
    isLoading = false,
    footerClassName,
    children,
    setIsDirty,
    primaryButtonText = 'Save',
  }) => {
    const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
      setValue,
      trigger,
    } = useForm<AchPaymentFormData>({
      defaultValues: {
        accountHolderName: name ?? '',
        accountType: 'checking',
        streetAddress: streetAddress ?? '',
        city: city ?? '',
        state: state ?? '',
        zipCode: zipCode ?? '',
        savePaymentMethod: false,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(AchPaymentFormSchema),
    })

    useExternalIsDirty(setIsDirty, isDirty)

    const ourOnSubmit = useCallback(
      (data: AchPaymentFormData) => {
        if (onSubmitValidationCheck()) {
          onSubmit(data)
        }
      },
      [onSubmit, onSubmitValidationCheck],
    )

    const onStateChange = useCallback(
      (state: string) => {
        setValue('state', state)
      },
      [setValue],
    )

    const onAddressChanged = useCallback(
      (addr: Address | string) => {
        if (typeof addr === 'string') {
          setValue('streetAddress', addr)
        } else {
          setValue('state', addr.stateAbbreviation)
          setValue('city', addr.city)
          setValue('zipCode', addr.zipCode)
          setValue('streetAddress', addr.line1)
          setValue('streetAddress2', addr.line2 ?? '')
          trigger()
        }
      },
      [setValue, trigger],
    )

    return (
      <Form
        className="flex min-h-0 flex-1 flex-col"
        layout="vertical"
        onSubmitCapture={handleSubmit(ourOnSubmit)}
        onKeyDown={preventFormSubmitOnEnter}
      >
        <FormBody>
          <div className="relative">
            <FormHeader>Payment Information</FormHeader>
            <TilledSandboxAchDevTools />
          </div>
          <ReactHookFormItem
            control={control}
            name="accountType"
            label="Account Type"
            errors={errors}
            render={({ field }) => (
              <Radio.Group size="large" {...field} optionType="button">
                <Radio value="checking">Checking</Radio>
                <Radio value="savings">Savings</Radio>
              </Radio.Group>
            )}
          />
          <ReactHookFormItem
            control={control}
            name="accountHolderName"
            label="Account Holder Name"
            required
            errors={errors}
            render={({ field }) => (
              <TextField
                {...field}
                ddActionName="BZ Payment Workflow - ACH - Account Holder Name"
              />
            )}
          />
          {children}
          <FormDivider />
          <FormHeader>Billing Address</FormHeader>
          <div className="mt-3 flex flex-col gap-2">
            <ReactHookFormItem
              control={control}
              name="streetAddress"
              required
              label="Street address or PO Box"
              errors={errors}
              render={({ field }) => {
                return (
                  <AddressLineOneField
                    showTypedAddress
                    size="large"
                    {...field}
                    onAddressChanged={onAddressChanged}
                  />
                )
              }}
            />
            <ReactHookFormItem
              className="col-span-2 md:col-span-1"
              control={control}
              name="streetAddress2"
              label="Apt, Unit, Building #"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <div className="grid grid-cols-2 gap-3 md:grid-cols-3">
              <ReactHookFormItem
                className="col-span-2 md:col-span-1"
                control={control}
                name="city"
                label="City"
                required
                errors={errors}
                render={({ field }) => <TextField {...field} />}
              />
              <ReactHookFormItem
                className="col-span-1"
                control={control}
                name="state"
                label="State"
                required
                errors={errors}
                render={({ field }) => (
                  <StateField {...field} onChange={onStateChange} />
                )}
              />
              <ReactHookFormItem
                className="col-span-1"
                control={control}
                name="zipCode"
                label="Zip Code"
                required
                errors={errors}
                render={({ field }) => <TextField {...field} />}
              />
            </div>
          </div>
        </FormBody>
        <PaymentFooterButtons
          onCancel={onCancel}
          primaryButtonText={primaryButtonText}
          isLoading={isLoading}
          footerClassName={footerClassName}
          dataDdActionName="BZ Payment Workflow - Pay - ACH"
          formStyle="modal"
        />
      </Form>
    )
  },
)
