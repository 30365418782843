import { useLayoutEffect } from 'react'

// https://stackoverflow.com/a/51733026/2108612
export const useIOSScrollingFix = (elem: HTMLDivElement | undefined | null) => {
  useLayoutEffect(() => {
    if (!elem) {
      return
    }
    let lastY = 0
    const touchStartEvent = (e: TouchEvent) => {
      lastY = e.touches[0].clientY
    }
    const touchMoveEvent = (e: TouchEvent) => {
      const top = e.touches[0].clientY
      const currentTarget = e.currentTarget as HTMLDivElement
      if (currentTarget) {
        const scrollTop = currentTarget.scrollTop
        const maxScrollTop =
          currentTarget.scrollHeight - currentTarget.offsetHeight
        const direction = lastY - top < 0 ? 'up' : 'down'

        if (
          e.cancelable &&
          ((direction === 'up' && scrollTop <= 0) ||
            (direction === 'down' && scrollTop >= maxScrollTop))
        ) {
          e.preventDefault()
        }
        lastY = top
      }
    }

    elem.addEventListener('touchstart', touchStartEvent)
    elem.addEventListener('touchmove', touchMoveEvent)
    return () => {
      elem?.removeEventListener('touchstart', touchStartEvent)
      elem?.removeEventListener('touchmove', touchMoveEvent)
    }
  }, [elem])
}
