import { gql } from '../../../../../generated'

export const UPDATE_END_OF_APPOINTMENT_NEXT_STEPS = gql(/* GraphQL */ `
  mutation UpdateEndOfAppointmentNextSteps(
    $companyGuid: uuid!
    $jobAppointmentGuid: uuid!
    $endOfAppointmentNextSteps: jsonb!
  ) {
    updateJobAppointments(
      where: {
        jobAppointmentGuid: { _eq: $jobAppointmentGuid }
        job: { companyGuid: { _eq: $companyGuid } }
      }
      _set: { endOfAppointmentNextSteps: $endOfAppointmentNextSteps }
    ) {
      __typename
    }
  }
`)

export const FETCH_END_OF_APPOINTMENT_NEXT_STEPS_SUBSCRIPTION =
  gql(/* GraphQL */ `
    subscription SubscribeToFetchEndOfAppointmentNextSteps(
      $jobAppointmentGuid: uuid!
    ) {
      jobAppointmentsByPk(jobAppointmentGuid: $jobAppointmentGuid) {
        endOfAppointmentNextSteps
      }
    }
  `)
