import {
  AccountContact,
  MaintenancePlanMinimalInfo,
  MatchToShape,
} from '@breezy/shared'
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { yellowTrio } from '../../../../../themes/theme'
import { ContactLabel } from '../../../../ContactLabel/ContactLabel'

type AccountContactCardContact = MatchToShape<
  AccountContact,
  {
    accountContactGuid: true
    primary: true
    contact: {
      firstName: true
      lastName: true
      primaryEmailAddress: {
        emailAddress: true
      }
      primaryPhoneNumber: {
        phoneNumber: true
      }
    }
  }
>

export type AccountContactCardProps = {
  accountContacts: AccountContactCardContact[]
  maintenancePlan?: MaintenancePlanMinimalInfo
}
// [X] Audited in BZ-921: No action required immediately
// TODO BZ-625: Add ability to edit contacts
export const AccountContactCard = ({
  accountContacts,
  maintenancePlan,
}: AccountContactCardProps) => {
  useEffect(() => {
    accountContacts.sort((a, b) => {
      if (a.primary && !b.primary) {
        return -1
      }
      if (!a.primary && b.primary) {
        return 1
      }
      return 0
    })
  }, [accountContacts])

  return (
    <div>
      {accountContacts.map((accountContact, index) => (
        <div className="pb-3" key={accountContact.accountContactGuid}>
          <div className="flex items-center space-x-2 pb-2">
            <ContactLabel
              className="semibold_14_22 grey9"
              contactFullName={accountContact.contact}
              maintenancePlan={maintenancePlan}
            />
            {index === 0 && (
              <div
                style={{
                  ...yellowTrio,
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderRadius: '4px',
                }}
              >
                <div className="regular_12_20 px-2 py-[2px]"> Primary </div>
              </div>
            )}
          </div>
          {/* [X] Audited in BZ-921: No action required immediately */}
          {/* TODO: Tech Exp - Reuse components w/ tooltips and link */}
          {accountContact.contact.primaryEmailAddress?.emailAddress && (
            <div className="flex items-center space-x-[14px]">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="grey9 text-[16px]"
              />
              <div className="regular_14_22 grey9 break-all">
                {accountContact.contact.primaryEmailAddress.emailAddress}
              </div>
            </div>
          )}
          {accountContact.contact.primaryPhoneNumber?.phoneNumber && (
            <div className="flex items-center space-x-[14px]">
              <FontAwesomeIcon icon={faPhone} className="grey9 text-[16px]" />
              <div className="regular_14_22 grey9">
                {accountContact.contact.primaryPhoneNumber.phoneNumber}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
