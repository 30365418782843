import {
  BzAddress,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  Location,
} from '@breezy/shared'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ActionItem } from '../../../elements/ActionItems/ActionItems'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { useCanViewLocation } from '../../../hooks/permission/useCanViewLocation'
import { useMapLink } from '../../../hooks/useMapLink'
import { m } from '../../../utils/react-utils'
import AddressMultiLineView from '../../Addresses/AddressMultiLineView/AddressMultiLineView'
import CollapsibleItem, {
  ContentListItem,
} from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type LocationCollapsibleItemProps = {
  location: Location
  actionItems?: ActionItem[]
  onEdit?: () => void
}

export const LocationCollapsibleItem = m<LocationCollapsibleItemProps>(
  ({ location: serviceLocation, actionItems, onEdit }) => {
    const [mapsButtonUrl, mapsButtonLabel] = useMapLink(serviceLocation.address)
    const { canView: canViewLocation } = useCanViewLocation(
      serviceLocation.locationGuid,
    )

    const contentList = useMemo(() => {
      let contentList: ContentListItem[] = [
        {
          key: 'Year Built',
          value: serviceLocation?.estimatedBuildDate?.format(
            DateTimeFormatter.ofPattern('YYYY').withLocale(ENGLISH_LOCALE),
          ) ?? <EmDash />,
        },
        {
          key: 'Square Footage',
          value: serviceLocation?.estimatedSquareFootage ? (
            serviceLocation.estimatedSquareFootage + ' sq. ft'
          ) : (
            <EmDash />
          ),
        },
      ]

      if (serviceLocation.displayName) {
        contentList = [
          {
            key: 'Address',
            value: (
              <a
                href={mapsButtonUrl}
                target="_blank"
                rel="noreferrer"
                className="w-fit "
              >
                {BzAddress.formatAddressSingleLine(serviceLocation.address)}
              </a>
            ),
          },
          ...contentList,
        ]
      }

      return contentList
    }, [mapsButtonUrl, serviceLocation])

    return (
      <CollapsibleItem
        key={serviceLocation.locationGuid}
        title={
          canViewLocation ? (
            <Link to={`/locations/${serviceLocation.locationGuid}`}>
              {serviceLocation.displayName ?? (
                <AddressMultiLineView
                  displayName={serviceLocation.displayName}
                  address={BzAddress.create(serviceLocation.address)}
                  labelClassName="semibold_14_22"
                  linkToMapApp={false}
                />
              )}
            </Link>
          ) : (
            serviceLocation.displayName ?? (
              <AddressMultiLineView
                displayName={serviceLocation.displayName}
                address={BzAddress.create(serviceLocation.address)}
                labelClassName="semibold_14_22"
                linkToMapApp={false}
              />
            )
          )
        }
        alwaysOpen
        onEdit={onEdit}
        actionItems={[
          ...(actionItems || []),
          {
            title: mapsButtonLabel,
            onClick: () => window.open(mapsButtonUrl),
          },
        ]}
        contentList={contentList}
      />
    )
  },
)
