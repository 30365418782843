import React from 'react'
import { useSubscription } from 'urql'
import { ANON_PREQUAL_RECORD_INFO_SUBSCRIPTION } from '../FinancingSection/PrequalifiedBanner.anon-gql'
import { PrequalifiedBannersView } from '../FinancingSection/PrequalifiedBanners'
import UrqlSubscriptionLoader from '../UrqlSubscriptionLoader/UrqlSubscriptionLoader'

type PrequalifiedBannerInfoProps = {
  accountGuid: string
  companyName: string
  className?: string
}

export const PrequalifiedBannerInfo = React.memo<PrequalifiedBannerInfoProps>(
  ({ accountGuid, companyName, className }) => {
    const [anonPrequalRecordLiveQuery] = useSubscription({
      query: ANON_PREQUAL_RECORD_INFO_SUBSCRIPTION,
      variables: {
        accountGuid,
      },
    })
    return (
      <UrqlSubscriptionLoader
        subscription={anonPrequalRecordLiveQuery}
        render={data => {
          const minimalPrequalRecords = data.wisetackPrequalRecords.map(pr => ({
            name: pr.contact.firstName,
            status: pr.status,
            maxAmountUsc: pr.maxQualifiedAmountUsc ?? 0,
          }))

          if (!minimalPrequalRecords.length) return <></>

          return (
            <div className="w-full">
              <PrequalifiedBannersView
                className={className}
                companyName={companyName}
                recordsMinimal={minimalPrequalRecords}
              />
            </div>
          )
        }}
      />
    )
  },
)
