import {
  BzDateTime,
  Guid,
  VisitDates,
  VisitRequest,
  nextGuid,
} from '@breezy/shared'
import { useCallback } from 'react'
import { useMutation } from 'urql'
import {
  MaintenancePlanVisitInstalledEquipmentsInsertInput,
  MaintenancePlanVisitsInsertInput,
} from '../../generated/user/graphql'
import {
  CLEAR_VISIT_JOB_LINK_MUTATION,
  UPSERT_BASE_VISIT_MUTATION,
  UPSERT_INSTALLED_EQUIPMENTS_MUTATION,
} from './EditVisitModal.gql'
export type UpsertVisitRequest = {
  baseVisit: VisitRequest
  visitDates: VisitDates
  maintenancePlanGuid: Guid
  companyGuid: Guid
}
export type UpsertVisitsRequest = UpsertVisitRequest[]

type UpsertVisitsReturnType = {
  upsertVisits: (
    v: UpsertVisitsRequest,
    onSuccess: () => void,
    onError: (e: unknown) => void,
  ) => void
  isUpserting: boolean
}

const mapToInstalledEquipmentLinks = (
  v: UpsertVisitRequest,
): MaintenancePlanVisitInstalledEquipmentsInsertInput[] => {
  return v.baseVisit.installedEquipmentGuids.map(ieGuid => ({
    maintenancePlanVisitInstalledEquipmentGuid: nextGuid(),
    companyGuid: v.companyGuid,
    maintenancePlanVisitGuid: v.baseVisit.visitGuid,
    installedEquipmentGuid: ieGuid,
  }))
}

const mapToInsertableVisit = (
  v: UpsertVisitRequest,
): MaintenancePlanVisitsInsertInput => ({
  maintenancePlanGuid: v.maintenancePlanGuid,
  companyGuid: v.companyGuid,

  maintenancePlanVisitGuid: v.baseVisit.visitGuid,
  name: v.baseVisit.name,

  affinityDate: v.baseVisit.affinityDate,
  completedAtOverride: v.baseVisit.completedAtOverride,
  isCompletedOverride: v.baseVisit.isCompletedOverride,

  jobGuid: v.baseVisit.jobGuid,
  issuedAt: v.visitDates.issuedAt,
  expiresAt: v.visitDates.expiresAt,
  dueAt: v.visitDates.dueAt,

  createdAt: BzDateTime.nowUtcIsoString(),
  updatedAt: BzDateTime.nowUtcIsoString(),
})

export const useUpsertVisitsViewModel = (): UpsertVisitsReturnType => {
  const [{ fetching: fetchingBaseVisit }, upsertVisitMut] = useMutation(
    UPSERT_BASE_VISIT_MUTATION,
  )
  const [, clearJobLinkMut] = useMutation(CLEAR_VISIT_JOB_LINK_MUTATION)
  const [
    { fetching: fetchingInstalledEquipment },
    upsertVisitInstalledEquipmentMut,
  ] = useMutation(UPSERT_INSTALLED_EQUIPMENTS_MUTATION)

  const upsertVisits = useCallback(
    async (
      visits: UpsertVisitsRequest,
      onSuccess: () => void,
      onError: (e: unknown) => void,
    ) => {
      try {
        for (const v of visits) {
          const insertVisit = mapToInsertableVisit(v)
          const insertVisitInstalledEquipment = mapToInstalledEquipmentLinks(v)

          const linkedJobGuid = v.baseVisit.jobGuid

          if (linkedJobGuid) {
            await clearJobLinkMut({ jobGuid: linkedJobGuid })
          }

          await upsertVisitMut({ objects: [insertVisit] })

          await upsertVisitInstalledEquipmentMut({
            maintenancePlanVisitGuid: v.baseVisit.visitGuid,
            installedEquipments: insertVisitInstalledEquipment,
          })
        }

        onSuccess()
      } catch (error) {
        onError(error)
      }
    },
    [upsertVisitMut, clearJobLinkMut, upsertVisitInstalledEquipmentMut],
  )

  return {
    upsertVisits,
    isUpserting: fetchingBaseVisit || fetchingInstalledEquipment,
  }
}
