import { FeatureFlags } from '@breezy/shared'
import {
  useFeatureFlagReader,
  useIsLdLoaded,
} from '../../hooks/useFeatureFlags'
import { FullScreenLoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

export type BehindFeatureFlagProps = {
  enabledFeatureFlag: keyof FeatureFlags
  otherRequiredFlags?: Array<keyof FeatureFlags>
  render: JSX.Element | React.ReactNode
  showLoaderWhileFlagsLoad?: boolean
  fallback?: JSX.Element | React.ReactNode
}

export const BehindFeatureFlag = ({
  enabledFeatureFlag,
  otherRequiredFlags,
  render,
  fallback,
  showLoaderWhileFlagsLoad,
}: BehindFeatureFlagProps) => {
  const flagReader = useFeatureFlagReader()
  const isLdLoaded = useIsLdLoaded()

  if (showLoaderWhileFlagsLoad && !isLdLoaded) {
    return <FullScreenLoadingSpinner />
  }

  if (
    !flagReader(enabledFeatureFlag) ||
    !(otherRequiredFlags ?? []).every(flagReader)
  ) {
    return fallback ? <>{fallback}</> : null
  }

  return <>{render}</>
}
