import { CardProps } from 'antd'
import { PropsWithChildren, memo } from 'react'
import { SmallCard } from '../../elements/Card/SmallCard'

type OutcomeCardProps = {
  title: CardProps['title']
  cardClassName?: string
}

/**
 * A Card component for the Job Outcomes Modal meant to be used when there is information or data to display.
 *
 * @component
 * @example
 * <OutcomeCard title="Outcome Title">
 *   <p>Outcome content</p>
 * </OutcomeCard>
 *
 * @param {object} props - The properties that define the OutcomeCard component.
 * @param {CardProps['title']} props.title - The title of the card.
 * @param {ReactNode} props.children - The children nodes of the card.
 *
 * @returns {ReactElement} The OutcomeCard component
 */
const OutcomeCard = memo<PropsWithChildren<OutcomeCardProps>>(
  ({ title, cardClassName, children }) => {
    return (
      <SmallCard header={title} cardClassName={cardClassName}>
        {children}
      </SmallCard>
    )
  },
)

export default OutcomeCard
