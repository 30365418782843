import { BzDateFns, Dfns, LocalDateString, R } from '@breezy/shared'
import cn from 'classnames'
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'

const WEEK_PADDING_PIXELS = 16

type Week = {
  start: Date
  dates: {
    date: Date
    dow: string
    dateNumber: string
  }[]
  id: string
}

const makeWeekFromDate = (date: Date): Week => {
  const start = Dfns.startOfWeek(date)
  return {
    start,
    dates: Dfns.eachDayOfInterval({
      start,
      end: Dfns.endOfWeek(date),
    }).map(date => ({
      date,
      dow: Dfns.format('EEE', date),
      dateNumber: Dfns.format('dd', date),
    })),
    id: R.pipe(Dfns.startOfWeek, Dfns.format('yyyyMMdd'))(date),
  }
}

const getPreviousWeek = (week: Week) =>
  makeWeekFromDate(Dfns.subWeeks(1, week.start))
const getNextWeek = (week: Week) =>
  makeWeekFromDate(Dfns.addWeeks(1, week.start))

type WeeklyDayPickerProps = {
  date: LocalDateString
  onChange: (newDate: LocalDateString) => void
}

export const WeeklyDayPicker = React.memo<WeeklyDayPickerProps>(
  ({ date: selectedDateStr, onChange }) => {
    const selectedDate = useMemo(
      () => Dfns.parseISO(selectedDateStr),
      [selectedDateStr],
    )
    const weeks = useMemo(() => {
      const thisWeek = makeWeekFromDate(selectedDate)
      const prevWeek = getPreviousWeek(thisWeek)
      const nextWeek = getNextWeek(thisWeek)
      return [prevWeek, thisWeek, nextWeek]
    }, [selectedDate])
    const scrollRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
      if (scrollRef.current) {
        // Move this over one full width, which should get it to the middle week
        // (current week)
        scrollRef.current.scrollLeft = scrollRef.current.offsetWidth
      }
    }, [selectedDateStr])

    const onScroll = useCallback(() => {
      if (scrollRef.current) {
        // If we're scrolled all the way to the left (padding overflows, so we don't
        // count it)
        if (scrollRef.current.scrollLeft === WEEK_PADDING_PIXELS) {
          onChange(
            BzDateFns.formatLocalDate(BzDateFns.subWeeks(selectedDate, 1)),
          )
        } else if (
          // If we're all the way to the left (the scroll plus the width of our row plus
          // the padding is the full size of the whole box)
          scrollRef.current.scrollLeft +
            scrollRef.current.offsetWidth +
            WEEK_PADDING_PIXELS >=
          scrollRef.current.scrollWidth
        ) {
          onChange(
            BzDateFns.formatLocalDate(BzDateFns.addWeeks(selectedDate, 1)),
          )
        }
      }
    }, [onChange, selectedDate])

    return (
      <div
        ref={scrollRef}
        className="flex snap-x snap-mandatory snap-always flex-row overflow-auto no-scrollbar"
        onScroll={onScroll}
      >
        {weeks.map(week => (
          <div
            key={week.id}
            style={{
              marginLeft: `${WEEK_PADDING_PIXELS}px`,
              marginRight: `${WEEK_PADDING_PIXELS}px`,
            }}
            className="flex min-w-full snap-center flex-row divide-x divide-slate-300 border-0 border-solid"
          >
            {week.dates.map(({ date, dow, dateNumber }) => (
              <div
                key={dow}
                className={cn(
                  'flex min-w-0 flex-1 flex-col items-center justify-center border-0 border-solid',
                  {
                    blue5: Dfns.isSameDay(date, selectedDate),
                  },
                )}
                onClick={() => onChange(BzDateFns.formatLocalDate(date))}
              >
                <div className="text-lg font-light">{dow}</div>
                <div className="font-bold">{dateNumber}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  },
)
