import { InstalledEquipmentSummary, InstalledHvacSystem, LocalDate, R, ZoningInfo } from '@breezy/shared'
import { FetchComprehensiveAccountDetailsQuery } from '../query'

type Equipment =
  FetchComprehensiveAccountDetailsQuery['accounts'][number]['accountLocations'][number]['location']['installedEquipment'][number]

const EQUIPMENT_DATE_FIELDS = [
  'installationDate',
  'estimatedEndOfLifeDate',
  'manufacturerWarrantyStartDate',
  'manufacturerWarrantyEndDate',
  'laborWarrantyStartDate',
  'laborWarrantyEndDate',
] as const

export const parseEquipment = (equipment: Equipment): InstalledEquipmentSummary => {
  const dateFields = R.pick(EQUIPMENT_DATE_FIELDS, equipment)
  return {
    ...R.mapObjIndexed(value => (value ? LocalDate.parse(value) : undefined), dateFields),
    ...R.omit(EQUIPMENT_DATE_FIELDS, equipment),
  }
}

type HvacSystem =
  FetchComprehensiveAccountDetailsQuery['accounts'][number]['accountLocations'][number]['location']['installedHvacSystems'][number]

export const parseHvacSystem = (equipment: HvacSystem): InstalledHvacSystem => ({
  ...equipment,
  zoningInfo: equipment.zoningInfoJson as ZoningInfo,
  installedEquipment: equipment.installedEquipment.map(parseEquipment),
})
