import { LinkedNoteViewModel, NoteLinkData } from '@breezy/shared'
import { faComment } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col } from 'antd'
import { useCallback, useState } from 'react'
import { gray7 } from '../../../themes/theme'
import { m } from '../../../utils/react-utils'
import LinkedNotesListLoader, {
  LinkedNotesViewProps,
} from '../LinkedNotesList/LinkedNotesList'
import { NoteForm } from '../NoteForm'

type NotesListCardProps = Omit<LinkedNotesViewProps, 'noteLinks'> & {
  title?: string
  editable?: boolean
  allowAddNote?: boolean
  linkData: NoteLinkData
}

export const NotesListCard = m<NotesListCardProps>(
  ({
    title = 'Recent Notes',
    allowAddNote = true,
    editable = true,
    linkData,
    ...restProps
  }) => {
    const excludePhotos: (n: LinkedNoteViewModel) => boolean = n => !n.photoGuid
    const [version, setVersion] = useState(0)

    const onMutate = useCallback(() => {
      setVersion(version => version + 1)
    }, [])

    return (
      <Col
        style={{
          padding: '0px 20px 20px 20px',
          backgroundColor: '#fff',
          borderRadius: 12,
        }}
      >
        <div className="row center-children-v mb2">
          {title && (
            <FontAwesomeIcon
              icon={faComment}
              color={gray7}
              size="lg"
              style={{ marginBottom: 2 }}
            />
          )}
          <h3 className="m0 ml1" style={{ color: gray7 }}>
            {title}
          </h3>
        </div>

        {allowAddNote && editable && (
          <NoteForm onSuccess={onMutate} linkData={linkData} />
        )}

        <LinkedNotesListLoader
          displayCondition={excludePhotos}
          noteLinks={linkData}
          {...restProps}
          version={version}
          onMutate={onMutate}
          linkData={linkData}
          editable={editable}
        />
      </Col>
    )
  },
)

export default NotesListCard
