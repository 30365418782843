import { BzContact, Contact, ContactGuid, noOp } from '@breezy/shared'
import { AutoComplete, SelectProps } from 'antd'
import React, { useCallback, useState } from 'react'
import { useFetchContactByContactGuidQuery } from '../../hooks/fetch/useFetchContactByGuid'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { LoadingSpinner } from '../LoadingSpinner'

const contactToValueMapper = (contact: Contact) => {
  const bzContact = BzContact.create(contact)

  return [
    bzContact.fullName,
    bzContact.primaryPhoneNumber?.phoneNumber,
    bzContact.primaryEmailAddress?.emailAddress,
  ]
    .filter(Boolean)
    .join(' | ')
}

type CompanyContactAutocompleteProps = {
  onContactUpdated: (contact?: Contact) => void
  initialContactGuid?: ContactGuid
  onInputChange?: (changedTo: string) => void
}

export const CompanyContactAutocomplete: React.FC<
  CompanyContactAutocompleteProps
> = ({ onContactUpdated, initialContactGuid, onInputChange = noOp }) => {
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [data, setData] = useState<Contact[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [search, setSearch] = useState('')
  const [searchInteractedWith, setSearchInteractedWith] = useState(false)
  const [initialLeadSourceContactGuid] = useState(initialContactGuid || '')

  const companyGuid = useExpectedCompany().companyGuid

  useFetchContactByContactGuidQuery({
    contactGuid: initialLeadSourceContactGuid,
    opts: {
      enabled: !!initialLeadSourceContactGuid && !searchInteractedWith,
      onSuccess: contact => {
        setData([contact])
        setSearchValue(contactToValueMapper(contact))
      },
    },
  })

  trpc.contacts['contacts:query'].useQuery(
    {
      type: 'search',
      searchValue: search,
      companyGuid,
    },
    {
      enabled: search.length > 0,
      onSuccess: results => {
        setData(results)
        setOptions(
          results.map(c => {
            return {
              value: contactToValueMapper(c),
              label: contactToValueMapper(c),
            }
          }),
        )
      },
    },
  )

  const onSelect = useCallback(
    (value: string) => {
      onContactUpdated(data.find(c => contactToValueMapper(c) === value))
    },
    [onContactUpdated, data],
  )

  if (!searchInteractedWith && initialContactGuid && !searchValue) {
    return <LoadingSpinner />
  }

  return (
    <AutoComplete
      style={{ width: '100%' }}
      value={searchValue}
      onSearch={setSearch}
      onSelect={onSelect}
      placeholder="Search for Contact"
      onChange={v => {
        setSearchInteractedWith(true)
        setSearchValue(v)
        onInputChange(v)
      }}
      allowClear
      options={options}
    ></AutoComplete>
  )
}
