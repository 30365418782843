import { isNullish, kebabCase } from '@breezy/shared'
import './Layout.less'

export const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'] as const

export type Breakpoint = (typeof BREAKPOINTS)[number]

export type BreakpointValues<T = unknown> = Partial<
  Record<Breakpoint, undefined | T>
>

/**
 * CSS variable values `inherit` and `default` won't apply themselves.
 * Instead, they will unset the *variable* to its default value.
 * To avoid this, we set the variable to an invalid value.
 * (Using a string literal to avoid null values.)
 */
export type DefaultCssValue = typeof DEFAULT_CSS_VALUE
const DEFAULT_CSS_VALUE = 'DEFAULT'
const parseDefaultValue = (value: unknown) => {
  if (value === DEFAULT_CSS_VALUE) {
    return '--'
  }
  return value
}

/**
 * Create css --[css property]-[breakpoint[]] variables from a callback mapped
 * over every breakpoint in ascending order. Result to be passed to inline
 * styles.
 * Use breakpointUtils.less to apply these inline styles.
 * @param callback called for each breakpoint, starting at xs.
 * @returns an object of CSS variables to pass to an element's style property.
 * @example
 * const color = { sm: 'red', lg: 'blue' }
 * const style = applyBreakpointProperties(
 *   (breakpoint) => {
 *     if (!color[breakpoint]) {
 *       return
 *     }
 *     return { borderColor: color[breakpoint], color: color[breakpoint] }
 *   }
 * )
 * // {
 * //   '--border-sm': 'red',
 * //   '--border-color-sm': 'red',
 * //   '--border-lg': 'blue',
 * //   '--border-color-lg': 'blue',
 * // }
 */
export const applyBreakpointProperties = (
  callback: (breakpoint: Breakpoint) => React.CSSProperties,
): Record<string, string | number> => {
  return BREAKPOINTS.reduce((obj, breakpoint) => {
    const result = callback(breakpoint)
    if (!result) {
      return obj
    }
    return Object.assign(
      obj,
      Object.fromEntries(
        Object.entries(result)
          .filter(([, v]) => !isNullish(v))
          .map(([p, v]) => [
            `--${kebabCase(p)}-${breakpoint}`,
            parseDefaultValue(v),
          ]),
      ),
    )
  }, {})
}

/**
 * Walk back up breakpoints on a breakpoint config
 * until a defined property is found.
 */
export const getNearestBreakpointValue = <ValueT>(
  values: undefined | BreakpointValues<ValueT>,
  breakpoint: Breakpoint,
): undefined | ValueT => {
  if (!values || !BREAKPOINTS.includes(breakpoint)) {
    return undefined
  }
  for (let i = BREAKPOINTS.indexOf(breakpoint); i >= 0; i -= 1) {
    if (!isNullish(values[BREAKPOINTS[i]])) {
      return values[BREAKPOINTS[i]]
    }
  }
  return undefined
}
