import { generateEstimateAccountAppLink } from '@breezy/shared'
import { Form } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import {
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  MessageOption,
  PreviewMessageOptionSelection,
  PreviewMessageOptionSelectionProps,
} from '../../../components/PreviewMessage/PreviewMessageOptionSelection/PreviewMessageOptionSelection'
import { createSendEmailForm } from '../../../components/PreviewMessage/SendEmailForm/SendEmailForm'
import { SendAsEmailFormSchema } from '../../../components/PreviewMessage/SendEmailForm/SendEmailForm.schema'
import { createSendSmsForm } from '../../../components/PreviewMessage/SendSmsForm/SendSmsForm'
import { SendAsSmsFormSchema } from '../../../components/PreviewMessage/SendSmsForm/SendSmsForm.schema'
import { getConfig } from '../../../config'
import { trpc } from '../../../hooks/trpc'
import { useWisetackEnabled } from '../../../providers/CompanyFinancialConfigWrapper'
import { useExpectedPrincipal } from '../../../providers/PrincipalUser'
import { useMessage } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import { EstimatesContext } from '../estimatesFlowUtils'
import { useSelectContactStep } from '../useSelectContactStep'

const config = getConfig()

type AcceptedEmailType = 'default' | 'acceptedForYou' | 'optionChanged'

const createDefaultEmail = (
  firstName: string | undefined,
  userFirstName: string,
  displayId: number,
  link: string,
  type: AcceptedEmailType,
) => {
  let extra = ''
  switch (type) {
    case 'acceptedForYou':
      extra = ' for you'
      break
    case 'optionChanged':
      extra = ' with a new option'
      break
  }
  return `<p><strong>Hello${
    firstName ? ` ${firstName}` : ''
  },</strong></p><p>Estimate #${displayId} has been accepted${extra}. Please find a link to the estimate below.</p><p><strong>View Estimate:</strong><br /><a href="${link}">${link}</a></p><p>Let me know if you have any questions. Thanks and have a great day!<br />${userFirstName}</p>`
}

const createDefaultSms = (
  firstName: string | undefined,
  userFirstName: string,
  displayId: number,
  link: string,
  type: AcceptedEmailType,
) => {
  let extra = ''
  switch (type) {
    case 'acceptedForYou':
      extra = ' for you'
      break
    case 'optionChanged':
      extra = ' with a new option'
      break
  }
  return `Hi${
    firstName ? ` ${firstName}` : ''
  }, Estimate #${displayId} has been accepted${extra}. Thanks! ${userFirstName} ${link}`
}

type CustomFormProps = React.PropsWithChildren<
  PreviewMessageOptionSelectionProps & {
    onSubmit: () => void
    onCancel: () => void
    onBack?: () => void
    isLoading: boolean
    header: string
  }
>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const CustomForm = ({
  children,
  onSubmit,
  onCancel,
  onBack,
  isLoading,
  messageOption,
  setMessageOption,
  header,
}: CustomFormProps) => {
  return (
    <OnsiteModalContent
      unpadBottom
      onBack={onBack}
      onClose={onCancel}
      header={header}
      footer={
        <OnsiteModalFooter
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitText="Send"
          submitIsLoading={isLoading}
        />
      }
    >
      <div>
        <PreviewMessageOptionSelection
          messageOption={messageOption}
          setMessageOption={setMessageOption}
        />
        <Form
          disabled={isLoading}
          onSubmitCapture={onSubmit}
          layout="vertical"
          className="flex min-h-0 flex-1 flex-col"
          requiredMark={label => (
            <div>
              {label}
              <span className="ml-0.5 text-bz-primary">*</span>
            </div>
          )}
        >
          {children}
        </Form>
      </div>
    </OnsiteModalContent>
  )
}

const SendEmailForm = createSendEmailForm(CustomForm)
const SendSmsForm = createSendSmsForm(CustomForm)

type SendAcceptedEstimateFormProps = {
  onClose: () => void
  onSuccess?: () => void
  onCancel?: () => void
  onBack?: () => void
  contactFirstName: string | undefined
  emailAddress: string | undefined
  phoneNumber: string | undefined
  displayId: number
  header?: string
  type?: AcceptedEmailType
}

export const SendAcceptedEstimateForm =
  React.memo<SendAcceptedEstimateFormProps>(
    ({
      onClose,
      onSuccess,
      onCancel,
      onBack,
      emailAddress,
      phoneNumber,
      header = 'Preview message',
      displayId,
      contactFirstName,
      type = 'default',
    }) => {
      const message = useMessage()
      const user = useExpectedPrincipal()
      const wisetackEnabled = useWisetackEnabled()

      const [selectedContactGuid, setSelectedContactGuid] = useState<string>()

      const { companyName, estimateGuid, accountGuid } =
        useStrictContext(EstimatesContext)

      const { contact, isLoading, SelectContactStep } = useSelectContactStep({
        accountGuid,
        selectedContactGuid,
        setSelectedContactGuid,
        onClose,
        onBack,
      })

      const [link, setLink] = useState<string>()

      useEffect(() => {
        if (!link) {
          if (!wisetackEnabled || selectedContactGuid) {
            ;(async () => {
              try {
                const url = await generateEstimateAccountAppLink(
                  config.accountAppFullUrl,
                  estimateGuid,
                  selectedContactGuid,
                )
                setLink(url)
              } catch (e) {
                console.error(e)
                message.error('Failed to generate link.')
              }
            })()
          }
        }
      }, [estimateGuid, link, message, selectedContactGuid, wisetackEnabled])

      const [messageOption, setMessageOption] = useState<MessageOption>('email')

      const defaultEmailValues = useMemo(() => {
        let subjectExtra = ''
        switch (type) {
          case 'acceptedForYou':
            subjectExtra = 'Update (Accepted) - '
            break
          case 'optionChanged':
            subjectExtra = 'Update (Option Changed) - '
            break
        }
        return {
          emailAddress:
            contact?.primaryEmailAddress?.emailAddress ?? emailAddress ?? '',
          subject: `${subjectExtra}Estimate #${displayId} from ${companyName}`,
          body: createDefaultEmail(
            contact?.firstName ?? contactFirstName,
            user.firstName,
            displayId,
            link ?? '',
            type,
          ),
        }
      }, [
        type,
        contact?.primaryEmailAddress?.emailAddress,
        contact?.firstName,
        emailAddress,
        displayId,
        companyName,
        contactFirstName,
        user.firstName,
        link,
      ])

      const defaultPhoneValues = useMemo(() => {
        return {
          phoneNumber:
            contact?.primaryPhoneNumber?.phoneNumber ?? phoneNumber ?? '',
          body: createDefaultSms(
            contact?.firstName ?? contactFirstName,
            user.firstName,
            displayId,
            link ?? '',
            type,
          ),
        }
      }, [
        contact?.firstName,
        contact?.primaryPhoneNumber?.phoneNumber,
        contactFirstName,
        displayId,
        link,
        phoneNumber,
        type,
        user.firstName,
      ])

      const sendMessageMutation =
        trpc.invoice['invoicing:estimatev2:send-review-message'].useMutation()

      const onEmailSubmit = useCallback(
        async (data: z.infer<typeof SendAsEmailFormSchema>) => {
          await sendMessageMutation.mutateAsync({
            estimateGuid,
            method: 'EMAIL',
            ...data,
          })
          message.success('Estimate sent!')
          onSuccess?.()
          onClose()
        },
        [estimateGuid, message, onClose, onSuccess, sendMessageMutation],
      )
      const onSmsSubmit = useCallback(
        async (data: z.infer<typeof SendAsSmsFormSchema>) => {
          await sendMessageMutation.mutateAsync({
            estimateGuid,
            method: 'SMS',
            ...data,
          })
          message.success('Estimate sent!')
          onSuccess?.()
          onClose()
        },
        [estimateGuid, message, onClose, onSuccess, sendMessageMutation],
      )

      const content = useMemo(() => {
        if (wisetackEnabled && !selectedContactGuid) return SelectContactStep

        if (isLoading || !link) {
          return (
            <>
              <div className="min-h-[150px]" />
              <div className="absolute inset-0 flex items-center justify-center">
                <LoadingSpinner />
              </div>
            </>
          )
        }

        return messageOption === 'email' ? (
          <SendEmailForm
            schema={SendAsEmailFormSchema}
            onSubmit={onEmailSubmit}
            formProps={{
              isLoading: sendMessageMutation.isLoading,
              onCancel: onCancel ?? onClose,
              onBack,
              messageOption,
              setMessageOption,
              header,
            }}
            defaultValues={defaultEmailValues}
          />
        ) : (
          <SendSmsForm
            schema={SendAsSmsFormSchema}
            onSubmit={onSmsSubmit}
            formProps={{
              isLoading: sendMessageMutation.isLoading,
              onCancel: onCancel ?? onClose,
              onBack,
              messageOption,
              setMessageOption,
              header,
            }}
            defaultValues={defaultPhoneValues}
          />
        )
      }, [
        SelectContactStep,
        defaultEmailValues,
        defaultPhoneValues,
        header,
        isLoading,
        link,
        messageOption,
        onBack,
        onCancel,
        onClose,
        onEmailSubmit,
        onSmsSubmit,
        selectedContactGuid,
        sendMessageMutation.isLoading,
        wisetackEnabled,
      ])

      return content
    },
  )
