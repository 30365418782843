import {
  AppointmentAssignment,
  BzCompanySchedulingConfig,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  JobClass,
  LocalDate,
  LocalTime,
  ZoneId,
  ZonedDateTime,
  dates,
} from '@breezy/shared'
import { Button } from 'antd'
import React, { useCallback, useMemo } from 'react'
import {
  TimeRangePicker,
  ValidTimeRangePickerTime,
} from '../../../elements/TimeRangePicker/TimeRangePicker'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { DateFormat } from '../../../utils/DateUtils'

const localTimePattern = DateTimeFormatter.ofPattern(
  DateFormat['h:mm a'],
).withLocale(ENGLISH_LOCALE)

type ArrivalLocalTimeWindow = {
  arrivalWindowStart: LocalTime
  arrivalWindowEnd: LocalTime
}

type ArrivalWindowFormProps = {
  jobClass: JobClass
  appointmentArrivalWindows: BzCompanySchedulingConfig
  arrivalWindowLocalDate: LocalDate
  setArrivalWindowLocalDate: (arrivalWindowLocalDate: LocalDate) => void
  arrivalLocalTimeWindow: ArrivalLocalTimeWindow | null
  setArrivalLocalTimeWindow: (
    arrivalLocalTimeWindow: ArrivalLocalTimeWindow | null,
  ) => void
  setTechnicianAssignments: (assignments: AppointmentAssignment[]) => void
  withLabels?: boolean
  hidePresets?: boolean
}

export const ArrivalWindowForm = React.memo<ArrivalWindowFormProps>(
  ({
    appointmentArrivalWindows,
    arrivalWindowLocalDate,
    setArrivalWindowLocalDate,
    jobClass,
    arrivalLocalTimeWindow,
    setArrivalLocalTimeWindow,
    setTechnicianAssignments,
    withLabels,
    hidePresets,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const zoneId = useMemo(() => ZoneId.of(tzId), [tzId])

    const pickerTimeRange = useMemo(() => {
      if (
        arrivalLocalTimeWindow?.arrivalWindowStart &&
        arrivalLocalTimeWindow?.arrivalWindowEnd
      ) {
        return {
          start: arrivalLocalTimeWindow.arrivalWindowStart.format(
            localTimePattern,
          ) as ValidTimeRangePickerTime,
          end: arrivalLocalTimeWindow.arrivalWindowEnd.format(
            localTimePattern,
          ) as ValidTimeRangePickerTime,
        }
      }
      return undefined
    }, [
      arrivalLocalTimeWindow?.arrivalWindowEnd,
      arrivalLocalTimeWindow?.arrivalWindowStart,
    ])

    const onRangePickerChange = useCallback(
      (range: {
        start: ValidTimeRangePickerTime
        end: ValidTimeRangePickerTime
      }) => {
        setArrivalLocalTimeWindow({
          arrivalWindowStart: LocalTime.parse(range.start, localTimePattern),
          arrivalWindowEnd: LocalTime.parse(range.end, localTimePattern),
        })
      },
      [setArrivalLocalTimeWindow],
    )

    // NOTE: Should the arrival window picker be limited to the company's working hours? I think it probably should be.
    // Technically the arrival window doesn't have to match when your technicians will be there: the window could be
    // 8-10 but you know your dudes won't be there until 9. But if your dudes don't even start work until 9, why would
    // you tell the customer they MIGHT show up at 8?
    const [firstValidStartTime, lastValidEndTime] = useMemo(() => {
      const startTime = appointmentArrivalWindows
        .getCompanyWorkingHourStart()
        .format(localTimePattern)
      const endTime = appointmentArrivalWindows
        .getCompanyWorkingHourEnd()
        .format(localTimePattern)
      return [startTime, endTime] as [
        ValidTimeRangePickerTime,
        ValidTimeRangePickerTime,
      ]
    }, [appointmentArrivalWindows])

    return (
      <div className="flex flex-col gap-3">
        <TimeRangePicker
          firstValidStartTime={firstValidStartTime}
          lastValidEndTime={lastValidEndTime}
          range={pickerTimeRange}
          setRange={onRangePickerChange}
          withLabels={withLabels}
        />

        {!hidePresets &&
          appointmentArrivalWindows
            .getArrivalWindows({
              localDate: arrivalWindowLocalDate,
              jobClass,
            })
            .map((window, i) => {
              let isSelected = false
              if (arrivalLocalTimeWindow) {
                const start = ZonedDateTime.of(
                  arrivalWindowLocalDate,
                  arrivalLocalTimeWindow.arrivalWindowStart,
                  zoneId,
                )
                const end = ZonedDateTime.of(
                  arrivalWindowLocalDate,
                  arrivalLocalTimeWindow.arrivalWindowEnd,
                  zoneId,
                )
                isSelected =
                  window.arrivalWindowStartZonedDateTime.equals(start) &&
                  window.arrivalWindowEndZonedDateTime.equals(end)
              }
              return (
                <div className="w-full flex-auto" key={i}>
                  <Button
                    onClick={() => {
                      if (isSelected) return
                      setArrivalLocalTimeWindow({
                        arrivalWindowStart:
                          window.arrivalWindowStartZonedDateTime.toLocalTime(),
                        arrivalWindowEnd:
                          window.arrivalWindowEndZonedDateTime.toLocalTime(),
                      })
                      setArrivalWindowLocalDate(
                        window.arrivalWindowStartZonedDateTime.toLocalDate(),
                      )
                      setTechnicianAssignments([])
                    }}
                    block
                    type={isSelected ? 'primary' : 'default'}
                  >
                    {dates.toDateTimeWindow(
                      window.arrivalWindowStartZonedDateTime,
                      window.arrivalWindowEndZonedDateTime,
                      { includeDate: false },
                    )}
                  </Button>
                </div>
              )
            })}
      </div>
    )
  },
)
