import {
  IconDefinition,
  faArrowUpFromSquare,
  faCheck,
  faEllipsisVertical,
  faSquarePlus,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSessionStorage } from 'react-use'
import {
  OnsiteModal,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import useIsMobile from '../../../hooks/useIsMobile'
import { useStrictContext } from '../../../utils/react-utils'
import chromeInstallIcon from './chromeInstallIcon.svg'
import techAppScreenshot from './techAppScreenshot.png'

type InstallPromptRef = {
  onInstallClick?: () => Promise<boolean>
  installIsSupported: boolean
}

// We're using an object so we can use it as a reference in our component
const installPromptRef: InstallPromptRef = {
  installIsSupported: false,
}

const beforeInstallPromptHandler = (e: Event) => {
  e.preventDefault()
  installPromptRef.installIsSupported = true

  if (!installPromptRef.onInstallClick) {
    installPromptRef.onInstallClick = async () => {
      // TypeScript's DOM types don't support 'beforeinstallprompt' so it doesn't recognize ".prompt()" as a thing
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = (e as any).prompt()

      return res.outcome === 'accepted'
    }
  }
}

// Sometimes this triggers before the component mounts, sometimes it doesn't. This sets our global ref if it triggers
// before.
window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler)

type PWAInstallPromptContextType = {
  promptDismissed: boolean
  showPrompt: () => void
  dismissPrompt: () => void
}

export const PWAInstallPromptContext = React.createContext<
  PWAInstallPromptContextType | undefined
>(undefined)

type PWAInstallPromptWrapperProps = React.PropsWithChildren

export const PWAInstallPromptWrapper = React.memo<PWAInstallPromptWrapperProps>(
  ({ children }) => {
    const [installIsSupported, setInstallIsSupported] = useState(
      // If the global event fired first, then we can pre-populate this state.
      installPromptRef.installIsSupported,
    )

    // We don't know if the event will fire before or after this mounts. If it's before, our global event listener will
    // register it. Otherwise, this has to trigger so we can update the state and trigger a rerender
    useEffect(() => {
      const handler = (e: Event) => {
        // This is so we can trigger a re-render so the prompt can show the install button
        setInstallIsSupported(true)
        beforeInstallPromptHandler(e)
      }
      window.addEventListener('beforeinstallprompt', handler)

      return () => window.removeEventListener('beforeinstallprompt', handler)
    }, [])

    // Dismissing the prompt will be at the session level.
    const [pwaInstallPromptDismissed, setPWAInstallPromptDismissed] =
      useSessionStorage<boolean>('install-pwa-prompt-dismissed', false)

    const showPrompt = useCallback(
      () => setPWAInstallPromptDismissed(false),
      [setPWAInstallPromptDismissed],
    )
    const dismissPrompt = useCallback(
      () => setPWAInstallPromptDismissed(true),
      [setPWAInstallPromptDismissed],
    )

    const installPwaPromptEnabled = useFeatureFlag('installPwaPrompt')

    return (
      <PWAInstallPromptContext.Provider
        value={{
          promptDismissed: pwaInstallPromptDismissed,
          showPrompt,
          dismissPrompt,
        }}
      >
        {children}
        {/* We have a kill switch for this in case there's an issue where it's constantly popping up and annoying
            people */}
        {installPwaPromptEnabled && (
          <PWAInstallPrompt installIsSupported={installIsSupported} />
        )}
      </PWAInstallPromptContext.Provider>
    )
  },
)

type Platform =
  | 'PWA_INSTALL_SUPPORTED'
  | 'SAFARI'
  | 'IOS_CHROME'
  | 'ANDROID_CHROME'
  | 'UNKNOWN'

// Browser sniffing sucks so hard. This is from ChatGPT. The platforms that don't support `beforeinstallprompt` are iOS
// Safari (and thus iOS Chrome since it sits on top of it) and mobile Firefox. Android Chrome, Edge, and Samsung
// Internet all support it. However, sometimes it doesn't trigger properly and we need to fall back to instructions. I
// only want to make instructions for Chrome since the other browsers have extremely low user share. But I can't show my
// instructions for Edge and Samsung because the don't match (the buttons/icons aren't the same). So for those I need to
// fall back to my "your browser isn't supported" message.
const detectAndroidChrome = () => {
  const userAgent = navigator.userAgent || navigator.vendor

  const isAndroid = /Android/i.test(userAgent)
  const isChrome = /Chrome/i.test(userAgent)
  const isEdge = /Edg\//i.test(userAgent) // Look for "Edg/" to identify Edge
  const isSamsungBrowser = /SamsungBrowser\//i.test(userAgent) // Look for Samsung Browser

  return isAndroid && isChrome && !isEdge && !isSamsungBrowser
}

type InstructionStepProps = React.PropsWithChildren<{
  icon: IconDefinition | string
  index: number
}>

const InstructionStep = React.memo<InstructionStepProps>(
  ({ icon, index, children }) => {
    return (
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-row items-start">
          <div>{index}.&nbsp;</div>
          <div className="flex-1">{children}</div>
        </div>
        <div className="ml-3 flex h-[44px] w-[44px] items-center justify-center rounded-full bg-bz-gray-300">
          {typeof icon === 'string' ? (
            <img
              src={icon}
              alt="icon"
              className="h-auto max-h-4 w-auto max-w-4"
            />
          ) : (
            <FontAwesomeIcon icon={icon} />
          )}
        </div>
      </div>
    )
  },
)

type PWAInstallPromptProps = {
  installIsSupported: boolean
}
export const PWAInstallPrompt = React.memo<PWAInstallPromptProps>(
  ({ installIsSupported }) => {
    const isMobile = useIsMobile()

    const { promptDismissed, dismissPrompt } = useStrictContext(
      PWAInstallPromptContext,
    )

    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    const platform = useMemo<Platform>(() => {
      if (installIsSupported) {
        return 'PWA_INSTALL_SUPPORTED'
      }

      if (detectAndroidChrome()) {
        return 'ANDROID_CHROME'
      }
      const userAgent = navigator.userAgent || navigator.vendor

      const isChrome = /CriOS/i.test(userAgent)
      const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)

      if (isChrome) {
        return 'IOS_CHROME'
      }
      if (isSafari) {
        return 'SAFARI'
      }

      return 'UNKNOWN'
    }, [installIsSupported])

    const instructions = useMemo(() => {
      if (platform === 'UNKNOWN') {
        return (
          <div className="mb-1 mt-3">
            Your browser doesn't support installing Breezy as an app. Please
            open this app in Google Chrome.
          </div>
        )
      }
      if (
        platform === 'SAFARI' ||
        platform === 'IOS_CHROME' ||
        platform === 'ANDROID_CHROME'
      ) {
        const isAndroidChrome = platform === 'ANDROID_CHROME'

        return (
          <div className="mb-1 mt-3 space-y-3 text-base font-semibold">
            <InstructionStep
              icon={isAndroidChrome ? faEllipsisVertical : faArrowUpFromSquare}
              index={1}
            >
              {isAndroidChrome
                ? 'Tap on the "More" icon in the upper-right of your browser'
                : 'Tap on the "Share" icon'}
            </InstructionStep>
            <InstructionStep
              icon={isAndroidChrome ? chromeInstallIcon : faSquarePlus}
              index={2}
            >
              {isAndroidChrome
                ? 'Tap "Install app"'
                : 'Swipe up and tap "Add to Home Screen"'}
            </InstructionStep>
            <InstructionStep icon={faCheck} index={3}>
              Close this tab and open the app from your home screen
            </InstructionStep>
          </div>
        )
      }
      return null
    }, [platform])

    const [didInstall, setDidInstall] = useState(false)

    const onInstallClick = useCallback(async () => {
      if (installPromptRef.onInstallClick) {
        setDidInstall(await installPromptRef.onInstallClick())
      }
    }, [])

    const open = !isPWA && !promptDismissed

    // This is so we can animate the phone screenshot so it "pops up". We need to do that in a `setTimeout` because it
    // will trigger on open and opening makes the modal/drawer pop in, which takes time as well.
    const [popUpPhone, setPopUpPhone] = useState(false)

    useEffect(() => {
      let killTimeout = false
      setTimeout(() => {
        if (!killTimeout) {
          setPopUpPhone(open)
        }
      }, 100)
      return () => {
        killTimeout = true
      }
    }, [open])

    return (
      <OnsiteModal open={open} drawer onClose={dismissPrompt}>
        <div
          className={classNames(
            'relative mb-6 h-[200px] overflow-hidden bg-[#1677FF] text-center text-bz-gray-1000',
            isMobile ? 'm-[-16px] ' : 'm-[-24px]',
          )}
          onClick={() => setPopUpPhone(true)}
        >
          <img
            onClick={e => {
              e.stopPropagation()
              setPopUpPhone(false)
            }}
            className={classNames(
              'mt-4 min-h-[200px] w-[236px] rounded-3xl border-[6.5px] border-solid border-bz-gray-1000',
              'transition-all delay-100 duration-500 ease-[cubic-bezier(0.7,-0.4,0.4,1.4)]',
              popUpPhone ? 'translate-y-0' : 'translate-y-[200px]',
            )}
            src={techAppScreenshot}
            alt="app screenshot"
          />
          <div className="absolute inset-x-0 bottom-0 z-10 h-[30px] bg-gradient-to-t from-white" />
        </div>
        <div className="text-3xl font-semibold">
          {didInstall ? 'App installed!' : 'Install the Breezy app'}
        </div>
        <div className="mt-3 text-base">
          {didInstall
            ? 'Close this window and open up the app you just added to your home screen.'
            : 'For the best user experience, install the app from your browser to your device.'}
        </div>
        {!didInstall && instructions}
        <OnsiteModalFooter
          className={classNames('mt-4', isMobile ? 'mb-4' : 'mb-6')}
          onCancel={dismissPrompt}
          hideSubmitButton={platform !== 'PWA_INSTALL_SUPPORTED'}
          onSubmit={onInstallClick}
          cancelText="Close"
          submitText="Install App"
        />
      </OnsiteModal>
    )
  },
)
