import { DEFAULT_FONT_FAMILY } from '../../../themes/theme'

export const tilledFieldOptions = {
  styles: {
    base: {
      fontFamily: DEFAULT_FONT_FAMILY,
      color: '#304166',
      fontWeight: '400',
      fontSize: '16px',
    },
    invalid: {
      ':hover': {
        textDecoration: 'underline dotted red',
      },
      color: '#777777',
    },
    valid: {
      color: '#32CD32',
    },
  },
}

export const tilledFormFieldStyle = {
  border: '1px solid #d9d9d9',
  borderRadius: 8,
  padding: '4px 11px',
  height: 36,
  width: '100%',
}
