import { z } from 'zod'
import { AsyncFn, IsoDateString } from '../../../common'
import { guidSchema, isoDateStringSchema } from '../../../contracts'
import { WithVerbosity } from '../../App/Logging'
import {
  CompanyGuid,
  CompanySimpleItemLimitRequest,
  CompanySimpleTimeWindowDtoRequest,
  ForCompany,
} from '../../Company/Company'
import { TimeWindowDto } from '../../DateTime/TimeWindow'
import { JobDisplayId } from '../../Job'
import { MaintenancePlanMinimalInfo } from '../../MaintenancePlans/MaintenancePlanTypes'
import { UserDisplayNameContainer } from '../../Person'
import { ForMaybeUser } from '../../Users/User'
import { Guid, GuidAndReferenceNumber, GuidsCollectionContainer, bzOptional } from '../../common-schemas'
import { LoanRecord } from '../Lending/Lending'

export const moneyAmountContainerSchema = z.object({
  amountUsd: z.number().positive(),
})

export const paymentLinksSchema = z.object({
  accountGuid: z.string(),
  invoiceGuid: z.string(),
  jobGuid: bzOptional(z.string()),
  jobAppointmentGuid: bzOptional(z.string()),
  jobAppointmentAssignmentGuid: bzOptional(z.string()),
  paymentSubscriptionGuid: bzOptional(z.string()),
  maintenancePlanGuid: bzOptional(z.string()),
  wisetackLoanRecordGuid: bzOptional(z.string()),
  paymentMethodRecordGuid: bzOptional(z.string()),
})

export const paymentRecordGuidContainerSchema = z.object({
  paymentRecordGuid: guidSchema,
})

export const paymentIntentRequestSchema = moneyAmountContainerSchema
  .merge(paymentLinksSchema)
  .merge(paymentRecordGuidContainerSchema)
  .extend({
    companyGuid: guidSchema,
    merchantId: z.string(),
  })

export type PaymentIntentRequest = z.infer<typeof paymentIntentRequestSchema>
export type PaymentRecordGuid = Guid

export type PaymentRecordGuidContainer = {
  readonly paymentRecordGuid: PaymentRecordGuid
}

export type UsdPaymentIntentRequest = {
  readonly merchantId: string
  readonly amountUsd: number
  readonly paymentRecordGuid: PaymentRecordGuid
  readonly paymentLinks: PaymentLinks
}

export type PaymentIntentId = string

export type PaymentIntentIdContainer = {
  readonly clientSecret: PaymentIntentId
}

export type IPaymentIntentCreator = AsyncFn<ForCompany<UsdPaymentIntentRequest>, PaymentIntentId>

export const paymentLinksRequestSchema = z.object({
  accountGuid: bzOptional(z.string()),
  invoiceGuid: bzOptional(z.string()),
  jobGuid: bzOptional(z.string()),
})

export type PaymentLinksRequestDto = z.infer<typeof paymentLinksRequestSchema>

export type PaymentLinks = RequiredPaymentLinks & OptionalPaymentLinks

export type RequiredPaymentLinks = {
  readonly accountGuid: string
  readonly invoiceGuid: string
}

export type OptionalPaymentLinks = {
  readonly jobGuid?: string
  readonly jobAppointmentGuid?: string
  readonly jobAppointmentAssignmentGuid?: string
  readonly paymentSubscriptionGuid?: string
  readonly maintenancePlanGuid?: string
  readonly loanRecord?: LoanRecord
  readonly accountDisplayName?: string
}

export enum PaymentMethod {
  CARD = 'CARD',
  ACH = 'ACH',
  CASH = 'CASH',
  CHECK = 'CHECK',
  PAYPAL = 'PAYPAL',
  VENMO = 'VENMO',
  CASHAPP = 'CASHAPP',
  OTHER = 'OTHER',
  WISETACK_LOAN = 'WISETACK_LOAN',
}

export const PaymentMethodDisplayNames = {
  [PaymentMethod.CARD]: 'Card',
  [PaymentMethod.ACH]: 'ACH',
  [PaymentMethod.CASH]: 'Cash',
  [PaymentMethod.CHECK]: 'Check',
  [PaymentMethod.PAYPAL]: 'PayPal',
  [PaymentMethod.VENMO]: 'Venmo',
  [PaymentMethod.CASHAPP]: 'CashApp',
  [PaymentMethod.OTHER]: 'Other',
  [PaymentMethod.WISETACK_LOAN]: 'Wisetack Loan',
}

export const paymentMethodDisplayName = (paymentMethod: PaymentMethod): string =>
  PaymentMethodDisplayNames[paymentMethod]

export enum PaymentStatus {
  SUBMITTING = 'SUBMITTING',
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  PROCESSING = 'PROCESSING',
}

export const PaymentStatusDisplayNames = {
  [PaymentStatus.SUBMITTING]: 'Submitting',
  [PaymentStatus.PENDING]: 'Pending',
  [PaymentStatus.PAID]: 'Paid',
  [PaymentStatus.FAILED]: 'Failed',
  [PaymentStatus.CANCELED]: 'Canceled',
  [PaymentStatus.PROCESSING]: 'Processing',
}

export const paymentStatusDisplayName = (paymentStatus: PaymentStatus): string =>
  PaymentStatusDisplayNames[paymentStatus]

export const paymentPendingStatuses = new Set([PaymentStatus.PENDING, PaymentStatus.PROCESSING])

export const paymentStatusErrorDetailSchema = z.object({
  errorMessage: z.string(),
  errorCode: z.string(),
  errorSolution: z.string(),
})

export const paymentStatusDtoSchema = z.object({
  invoiceGuid: guidSchema,
  paymentRecordGuid: guidSchema,
  status: z.nativeEnum(PaymentStatus),
  statusDetail: bzOptional(z.string()),
  occurredAt: isoDateStringSchema,
  errorInfo: paymentStatusErrorDetailSchema.nullish(),
})

export const paymentStatusDtoForExternalPaymentIdSchema = z.object({
  externalPaymentId: z.string(),
  status: z.nativeEnum(PaymentStatus),
  statusDetail: bzOptional(z.string()),
  occurredAt: isoDateStringSchema,
  errorInfo: paymentStatusErrorDetailSchema.nullish(),
})

export const paymentRecordDtoSchema = paymentLinksSchema.merge(paymentStatusDtoSchema).merge(
  z.object({
    invoiceReferenceNumber: z.string(),
    amountUsd: z.number().positive(),
    externalPaymentId: bzOptional(z.string()),
    paymentMethod: z.nativeEnum(PaymentMethod),
    paymentMethodAdditionalInfo: bzOptional(z.string()),
    note: bzOptional(z.string()),
    referenceNumber: bzOptional(z.string()),
  }),
)

export const clientSidePaymentRecordDtoSchema = paymentRecordDtoSchema.merge(
  z.object({
    // We are changing this field from optional to required because most of the time
    // we only want to generate the occurredAt field on the server side to avoid any client-side
    // clock issues and to ensure that the occurredAt field is consistent with the server's clock.
    // However, we still want to support the flexibility of passing in the occurredAt field from the client
    // in case we're forwarding from a third-party payment processor like Tilled.
    occurredAt: bzOptional(isoDateStringSchema),

    // We're requiring the companyGuid field to be passed in from the client side so we can use the same mutation for
    // authenticated and unauthenticated payments.
    companyGuid: guidSchema,
  }),
)

export type PaymentStatusErrorDetail = z.infer<typeof paymentStatusErrorDetailSchema>
export type PaymentStatusDto = z.infer<typeof paymentStatusDtoSchema>
export type PaymentStatusDtoForExternalPaymentId = z.infer<typeof paymentStatusDtoForExternalPaymentIdSchema>
export type PaymentRecordDto = z.infer<typeof paymentRecordDtoSchema>
export type ClientSidePaymentRecordDto = z.infer<typeof clientSidePaymentRecordDtoSchema>

export type PaymentRecordWriter = AsyncFn<ForCompany<ForMaybeUser<WithVerbosity<PaymentRecordDto>>>>

export type PaymentStatusChange = {
  readonly paymentRecordGuid: PaymentRecordGuid
  readonly previousStatus: PaymentStatus | null | undefined
  readonly newStatus: PaymentStatus
}

export type PaymentStatusWriter = AsyncFn<ForCompany<PaymentStatusDto>, PaymentStatusChange>
export type PaymentStatusWriterForExternalPaymentId = AsyncFn<
  ForCompany<PaymentStatusDtoForExternalPaymentId>,
  PaymentStatusChange
>

export const getPaymentReferenceNumberForInvoice = (
  invoiceReferenceNumber: string,
  numberOfPreviousInvoicePayments: number,
) =>
  numberOfPreviousInvoicePayments === 0
    ? invoiceReferenceNumber
    : `${invoiceReferenceNumber}-${numberOfPreviousInvoicePayments + 1}`

export const tilledMerchantIdPaymentMethodAdditionalInfo = (merchantId: string) => `Tilled-AccountId: ${merchantId}`

export type PaymentViewModel = GuidAndReferenceNumber &
  PaymentLinks & {
    readonly paymentMethod: PaymentMethod
    readonly amountUsd: number
    readonly displayId?: number
    readonly status: PaymentStatus
    readonly occurredAt: IsoDateString
  }

export type ComprehensivePaymentViewModel = PaymentViewModel &
  Partial<UserDisplayNameContainer> & {
    readonly externalPaymentId?: string
    readonly statusDetail?: string
    readonly paymentMethodAdditionalInfo?: string
    readonly collectedByUserGuid?: string
    readonly note?: string
    readonly totalRefundedAmountUsd?: number
    readonly accountMaintenancePlan?: MaintenancePlanMinimalInfo
    readonly accountDisplayName: string
    readonly jobDisplayId?: JobDisplayId
    readonly payoutGuid?: Guid
    readonly loanRecordGuid?: Guid
    readonly loanRecord?: LoanRecord
    readonly qboEntityId?: string
  }

export type LinkedPaymentsViewModelReader = AsyncFn<ForCompany<Partial<PaymentLinks>>, PaymentViewModel[]>
export type PaymentDetailsReader = AsyncFn<ForCompany<PaymentRecordGuidContainer>, ComprehensivePaymentViewModel>

export type MerchantIdTimeWindowDtoRequest = { merchantId: string } & TimeWindowDto

export type PaymentRecordIdsWithStatus = {
  readonly companyGuid: CompanyGuid
  readonly paymentRecordGuid: PaymentRecordGuid
  readonly externalPaymentId?: string
  readonly status: PaymentStatus
}

export const companyGuidWithMerchantIdTimeWindowRequestSchema = z.object({
  companyGuid: guidSchema,
  merchantId: z.string(),
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type CompanyGuidWithMerchantIdTimeWindowRequest = z.infer<
  typeof companyGuidWithMerchantIdTimeWindowRequestSchema
>

export type PaymentRecordsIdsWithStatusReader = AsyncFn<CompanySimpleTimeWindowDtoRequest, PaymentRecordIdsWithStatus[]>

export type PaymentsSync = AsyncFn<CompanyGuidWithMerchantIdTimeWindowRequest>

export type QueryPaymentsByGuid = {
  type: 'by-guid'
  paymentGuid: PaymentRecordGuid
}

export type QueryPaymentsByExternalPaymentId = {
  type: 'by-external-payment-id'
  externalPaymentId: string
}

export type QueryPayments = {
  type: 'all'
}

export type QueryPaymentsRequest = CompanySimpleItemLimitRequest &
  (QueryPaymentsByGuid | QueryPaymentsByExternalPaymentId | QueryPayments)

export type PaymentsViewModelReader = AsyncFn<QueryPaymentsRequest, ComprehensivePaymentViewModel[]>
export type PaymentsViewModelMultiReader = AsyncFn<ForCompany<GuidsCollectionContainer>, Record<Guid, PaymentViewModel>>

export type MerchantIdContainer = {
  readonly merchantId: string
}

export type CompanyGuidByMerchantIdReader = AsyncFn<MerchantIdContainer, CompanyGuid>

export type ExternalPaymentId = string
export type ExternalPaymentIdsQuery = {
  readonly companyGuid: CompanyGuid
  readonly externalPaymentIds: ExternalPaymentId[]
}

export type PaymentRecordGuidsByExternalPaymentIdsReader = AsyncFn<
  ExternalPaymentIdsQuery,
  Record<ExternalPaymentId, PaymentRecordGuid>
>

export const PaymentWorkflowOptionSchema = z.enum(['Payment Link', 'Credit Card', 'ACH', 'Check', 'Cash', 'Other'])
export type PaymentWorkflowOption = z.infer<typeof PaymentWorkflowOptionSchema>

export const getPaymentWorkflowOptionToPaymentMethod = (option?: PaymentWorkflowOption): PaymentMethod => {
  switch (option) {
    case 'Credit Card':
      return PaymentMethod.CARD
    case 'ACH':
      return PaymentMethod.ACH
    case 'Check':
      return PaymentMethod.CHECK
    case 'Cash':
      return PaymentMethod.CASH
    case 'Other':
      return PaymentMethod.OTHER
    default:
      return PaymentMethod.CARD
  }
}
