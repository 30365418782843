import { BzLinks } from '@breezy/shared'
import { Button, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import axios from 'axios'
import React, { useCallback } from 'react'
import { trpc } from '../../hooks/trpc'
import { typedMemo } from '../../utils/react-utils'
import { BaseLoadingSpinner } from '../LoadingSpinner'
import { optimizeImageFile } from './PhotoOptimize'

export type AsyncUploadExtraData = {
  // What we tell S3 to call it. This will generally be "{guid}/{filename}"
  cdnFileNameAndPath: string
}

export type AsyncBaseData = {
  // base64-encoded file (with the metadata before the ",") so you can make a thumbnail while it loads.
  base64File: string
  // Size in bytes of the file
  bytes: number
  // File MIME type
  type: string
  // Progress (0-1). When it's 1, it's fully uploaded
  progress: number
  // `true` after we're totally done
  recordWritten: boolean
  // Final URL of the uploaded file
  cdnUrl: string
  // The S3 arn
  resourceUrn: string
  // File name of the file
  filename: string
  // If true, then it failed. It could have failed uploading via the signed URL or writing the record.
  failed?: boolean
}

export type AsyncData<T extends AsyncUploadExtraData> = T & AsyncBaseData

export type OnUploadChange<
  ExtraData extends AsyncUploadExtraData,
  RecordType,
> = (data: AsyncData<ExtraData>, record?: RecordType) => void

export type GetExtraData<ExtraData extends AsyncUploadExtraData> = (
  data: AsyncBaseData,
) => AsyncData<ExtraData>

export type WriteRecord<ExtraData extends AsyncUploadExtraData, RecordType> = (
  data: AsyncData<ExtraData>,
) => Promise<RecordType>

export type UseUploadProps<
  ExtraData extends AsyncUploadExtraData,
  RecordType,
  LinksType = BzLinks,
> = {
  onUploadChange: OnUploadChange<ExtraData, RecordType>
  writeRecord: WriteRecord<ExtraData, RecordType>
  getExtraData: GetExtraData<ExtraData>
  isImage: boolean
  links?: LinksType
}

// Uploading a file is three steps: 1) Get a signed upload url 2) Upload to that url 3) Call our API to set the record.
export const useUpload = <
  ExtraData extends AsyncUploadExtraData,
  RecordType,
  LinksType = BzLinks,
>({
  onUploadChange,
  writeRecord,
  getExtraData,
  isImage,
}: UseUploadProps<ExtraData, RecordType, LinksType>) => {
  // Get the signed URL (step 1). This should really be in the files router, but I did it originally in the photos
  // router and I don't want to change for backwards compat. The tragedy here is the files and photos could probably
  // have been the same thing.
  const getSignedUrl = trpc.photos['photos:get-signed-upload-url'].useMutation({
    // https://trpc.io/docs/v9/links#2-perform-request-without-batching
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  })

  const upload = useCallback(
    async (file: File) => {
      const [optimizedFile, optimizedImgBase64] = isImage
        ? await optimizeImageFile(file, 2048)
        : [file, '']

      const data = getExtraData({
        base64File: optimizedImgBase64,
        bytes: optimizedFile.size,
        type: optimizedFile.type,
        filename: optimizedFile.name,
        progress: 0,
        cdnUrl: '',
        resourceUrn: '',
        recordWritten: false,
      })

      onUploadChange(data)

      const { uploadUrl, resultUrl, arn } = await getSignedUrl.mutateAsync({
        filename: data.cdnFileNameAndPath,
      })

      data.cdnUrl = resultUrl
      data.resourceUrn = arn

      // Using axios (we use tRPC everywhere else!) to upload straight to that signed url
      const res = await axios({
        url: uploadUrl,
        method: 'PUT',
        data: optimizedFile,
        headers: {
          'Content-Type': optimizedFile.type,
        },
        onUploadProgress: ({ progress }) => {
          if (progress) {
            data.progress = progress
          }
          onUploadChange({ ...data })
        },
      })

      if (res.status !== 200) {
        data.failed = true
        onUploadChange({ ...data })
        console.error(res)
        return
      }

      // Setting the record (step 3)
      try {
        const record = await writeRecord(data)
        data.recordWritten = true
        onUploadChange({ ...data }, record)
      } catch (e) {
        data.failed = true
        onUploadChange({ ...data })
        console.error(e)
      }
    },
    [getExtraData, getSignedUrl, onUploadChange, writeRecord, isImage],
  )

  return upload
}

type AsyncUploadProps<
  ExtraData extends AsyncUploadExtraData,
  RecordType,
  LinksType = BzLinks,
> = UseUploadProps<ExtraData, RecordType, LinksType> & {
  accept?: string
}

type AsyncUploadWrapperProps<
  ExtraData extends AsyncUploadExtraData,
  RecordType,
  LinksType = BzLinks,
> = React.PropsWithChildren<AsyncUploadProps<ExtraData, RecordType, LinksType>>

export const AsyncUploadWrapper = typedMemo(
  <ExtraData extends AsyncUploadExtraData, RecordType, LinksType = BzLinks>({
    accept,
    children,
    ...useUploadProps
  }: AsyncUploadWrapperProps<ExtraData, RecordType, LinksType>) => {
    const upload = useUpload(useUploadProps)

    const beforeUpload = useCallback(
      async (file: RcFile) => {
        await upload(file)
        // Return false because we're handling the uploading from here and don't want the component to do anything else.
        return false
      },
      [upload],
    )

    // TODO: it would be cool to use Upload.Dragger but we need to hack the styles
    return (
      // It was of critical importance to the Ant team to make my life as difficult as possible. There are multiple ways
      // to hook into this component that would have been really awesome. Namely, it has an "action" prop that can take
      // a function that returns a promise. I could have used that to fetch a signed URL. It then has an `onChange` that
      // triggers when progress is made, so I could have use that for the progress circles. Then when they were
      // finished, I could do my final step. But when you supply a url via `action`, it uploads it as a multipart form
      // instead of a raw file. So it has this header in the data. When that's uploaded to S3 it encodes that as
      // whatever the file type is so the file is corrupted. I tried and tried but there was no way to tell it not to do
      // that. The only real option was to use "customRequest", which overrode the upload process. But since I'm not
      // using any of the component's loading bars or item lists or anything (so using the hooks to inform the component
      // of progress etc wasn't necessary) then using that was no different than just cheesing `beforeUpload` (which is
      // meant for you to short circuit the upload process by returning `false` if you don't want it to go), having IT
      // do the upload, and just having it return `false` every time.
      <Upload
        accept={accept}
        multiple
        showUploadList={false}
        beforeUpload={beforeUpload}
        className="block w-full *:w-full"
      >
        {children}
      </Upload>
    )
  },
)

export const useSynchronousUpload = <RecordType,>(
  onUpload?: (record: RecordType) => void,
): OnUploadChange<AsyncUploadExtraData, RecordType> =>
  useCallback<OnUploadChange<AsyncUploadExtraData, RecordType>>(
    (_, record) => {
      if (onUpload && record) {
        onUpload(record)
      }
    },
    [onUpload],
  )
export type SynchronousUpload<RecordType> = (
  onUpload?: (record: RecordType) => void,
) => OnUploadChange<AsyncUploadExtraData, RecordType>

type UploadButtonProps = React.PropsWithChildren<{
  icon: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}>

export const UploadButton = React.memo<UploadButtonProps>(
  ({ children, icon, onClick, disabled, loading }) => {
    return (
      <Button
        block
        disabled={disabled || loading}
        className="flex h-[142px] w-full flex-col items-center justify-center px-12"
        onClick={onClick}
      >
        <div className="text-3xl">
          {loading ? <BaseLoadingSpinner size={8} /> : icon}
        </div>
        <div className="mt-2 text-base font-semibold">{children}</div>
      </Button>
    )
  },
)
