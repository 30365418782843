import { Button, Popover, Row } from 'antd'
import { PropsWithChildren, useCallback, useState } from 'react'
import ThinDivider from '../../elements/ThinDivider'
import { useMessage } from '../../utils/antd-utils'
import PercentInputNumber from './PercentInputNumber'

type TechnicianCommissionRatePopoverFormProps = {
  initialCommissionPercent?: number | undefined
  open: boolean
  onCancel?: () => void
  onSubmit?: (commissionPercent: number | undefined) => void
}

const TechnicianCommissionRatePopoverForm: React.FC<
  PropsWithChildren<TechnicianCommissionRatePopoverFormProps>
> = ({ initialCommissionPercent, open, onCancel, onSubmit, children }) => {
  const message = useMessage()
  const [commissionPercent, setCommissionPercent] = useState<
    number | undefined
  >(initialCommissionPercent)

  const onFormSubmit = useCallback(() => {
    try {
      onSubmit?.(commissionPercent)
    } catch (e) {
      message.error(
        'Failed to update. Please reload the application and try again, or contact support if the problem persists',
      )
    }
  }, [commissionPercent, message, onSubmit])

  const onClickCancelButton = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  return (
    <Popover
      open={open}
      destroyTooltipOnHide={true}
      content={
        <div style={{ width: '265px' }}>
          <Row style={{ marginBottom: '8px' }}>
            <strong>Sales Commission</strong>
          </Row>

          <p>
            Add a job commission for this employee. This will calculated from
            the commissionable base total.
          </p>

          <Row className="mb-2 mt-3 w-full">
            <strong>Amount</strong>
          </Row>

          <Row className="w-full">
            <PercentInputNumber
              className="w-full"
              value={commissionPercent}
              onChange={setCommissionPercent}
            />
          </Row>

          <ThinDivider widthPx={1} />
          <Row className="mt-3 w-full pt-1" justify="end">
            <Button
              type="default"
              onClick={onClickCancelButton}
              style={{ marginRight: '8px' }}
            >
              Cancel
            </Button>
            <Button htmlType="button" type="primary" onClick={onFormSubmit}>
              Save
            </Button>
          </Row>
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default TechnicianCommissionRatePopoverForm
