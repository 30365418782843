import { BzLinks, FileRecord } from '@breezy/shared'
import { faFile } from '@fortawesome/pro-solid-svg-icons'
import { useCallback, useMemo, useState } from 'react'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { m } from '../../utils/react-utils'
import FileElements from '../Files/FileElements'
import { AsyncFileUpload } from '../Upload/AsyncFileUpload'
import { useSynchronousUpload } from '../Upload/Upload'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'

export type FilePickerDrawerProps = {
  files: FileRecord[]
  filesSelected?: { fileGuid: string; cdnUrl: string; fileName: string }[]
  linkData?: BzLinks
  onFilesUpdated?: () => void
  onFilesSelected?: (
    files: { fileGuid: string; cdnUrl: string; fileName: string }[],
  ) => void
  onClose?: () => void
}

export const FilePickerDrawer = m(
  ({
    files,
    filesSelected,
    linkData,
    onFilesUpdated,
    onFilesSelected,
    onClose,
  }: FilePickerDrawerProps) => {
    const [selectedFiles, setSelectedFiles] = useState<
      { fileGuid: string; cdnUrl: string; fileName: string }[]
    >(filesSelected ?? [])

    const selectedFileGuids = useMemo(() => {
      return selectedFiles.map(file => file.fileGuid)
    }, [selectedFiles])

    const onFileUploadChange = useSynchronousUpload(onFilesUpdated)

    const onClickFileElement = useCallback(
      (file: FileRecord) => {
        const selectedFileIndex = selectedFiles.findIndex(
          curr => file.fileGuid === curr.fileGuid,
        )
        if (selectedFileIndex >= 0) {
          const newFiles = [...selectedFiles]
          newFiles.splice(selectedFileIndex, 1)
          setSelectedFiles(newFiles)
          return
        }

        setSelectedFiles([...selectedFiles, file])
      },
      [selectedFiles],
    )

    return (
      <BzDrawer
        title="Select A File"
        icon={faFile}
        item={onClose ? { onCancel: onClose } : undefined}
        footer={
          <FormCancelSubmitButtons
            bypassFormSubmit
            primaryButtonText="Select File(s)"
            onSubmit={() => {
              onFilesSelected?.(selectedFiles)
              onClose?.()
            }}
            onCancel={onClose}
          />
        }
      >
        <div className="flex h-full flex-col">
          <div className="flex max-h-full w-full flex-col gap-2 overflow-y-auto overflow-x-hidden">
            <FileElements
              files={files}
              onMutate={() => onFilesUpdated?.()}
              onClick={onClickFileElement}
              selectedFileGuids={selectedFileGuids}
            />
          </div>
          {linkData && (
            <div className="mt-auto">
              <AsyncFileUpload
                links={linkData}
                onFileUploadChange={onFileUploadChange}
              />
            </div>
          )}
        </div>
      </BzDrawer>
    )
  },
)
