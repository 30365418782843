import { convertFetchLoanRecordToLoanRecord } from '@breezy/backend/src/application-types'
import {
  BzAddress,
  BzTimeWindow,
  DateTimeFormatter,
  Guid,
  PaymentMethod,
  PaymentStatus,
  PaymentViewModel,
  ZoneId,
  ZonedDateTime,
  calculateInferredAppointmentStatus,
  isNullish,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { AppointmentsCollapsibleAppointment } from '../../components/collapsibles/AppointmentsCollapsible/AppointmentsCollapsible'
import {
  JobAppointmentsBoolExp,
  JobsBoolExp,
  PaymentRecordsBoolExp,
} from '../../generated/user/graphql'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { MAINTENANCE_PLAN_COLLAPSIBLES_QUERY } from './MaintenancePlanCollapsiblesGroup.gql'

export const useMaintenancePlanCollapsiblesData = (
  maintenancePlanGuid: Guid,
  maintenancePlanVisitLinkedJobGuids: Guid[],
) => {
  const tzId = useExpectedCompanyTimeZoneId()

  const paymentWhereClause = useMemo<PaymentRecordsBoolExp>(
    () => ({
      _or: [
        {
          invoiceV2: {
            maintenancePlanLink: {
              maintenancePlanGuid: { _eq: maintenancePlanGuid },
            },
          },
        },
        {
          invoiceV2: {
            jobLink: {
              jobGuid: { _in: maintenancePlanVisitLinkedJobGuids },
            },
          },
        },
      ],
    }),
    [maintenancePlanGuid, maintenancePlanVisitLinkedJobGuids],
  )

  const jobsWhereClause = useMemo<JobsBoolExp>(
    () => ({
      jobGuid: { _in: maintenancePlanVisitLinkedJobGuids },
    }),
    [maintenancePlanVisitLinkedJobGuids],
  )

  const appointmentsWhereClause = useMemo<JobAppointmentsBoolExp>(
    () => ({
      jobGuid: { _in: maintenancePlanVisitLinkedJobGuids },
    }),
    [maintenancePlanVisitLinkedJobGuids],
  )

  const [{ data, fetching, error }] = useQuery({
    query: MAINTENANCE_PLAN_COLLAPSIBLES_QUERY,
    variables: {
      paymentWhereClause,
      jobsWhereClause,
      appointmentsWhereClause,
    },
  })

  const payments = useMemo(() => {
    return (
      data?.paymentRecords.map(pl => {
        const vm: PaymentViewModel = {
          ...pl,
          ...pl.links,
          accountDisplayName: pl.account?.accountDisplayName,
          loanRecord:
            !isNullish(pl.links) && !isNullish(pl.links?.loanRecord)
              ? convertFetchLoanRecordToLoanRecord(pl.links.loanRecord)
              : undefined,
          paymentMethod: pl.paymentMethod as unknown as PaymentMethod,
          status:
            pl.paymentStatusesAggregate.nodes.length > 0
              ? (pl.paymentStatusesAggregate.nodes[0]
                  .paymentStatus as unknown as PaymentStatus)
              : PaymentStatus.SUBMITTING,
        }
        return vm
      }) ?? []
    )
  }, [data])

  const appointments = useMemo<AppointmentsCollapsibleAppointment[]>(() => {
    if (isNullish(data) || isNullish(data.jobAppointments)) {
      return []
    }

    return data.jobAppointments.map(appointment => ({
      appointmentReferenceNumber: appointment.appointmentReferenceNumber,
      appointmentGuid: appointment.jobAppointmentGuid,
      appointmentStatus: calculateInferredAppointmentStatus(
        appointment.cancellationStatus?.canceled || false,
        appointment.assignments.map(assignment => ({
          assignmentStatus:
            assignment.assignmentStatus?.jobAppointmentAssignmentStatusType ||
            'TO_DO',
        })),
      ),
      confirmed: appointment.confirmationStatus?.confirmed || false,
      canceled: appointment.cancellationStatus?.canceled || false,
      address: BzAddress.create(appointment.job.location.address),
      timeWindow: new BzTimeWindow(
        ZonedDateTime.parse(
          appointment.appointmentWindowStart,
          DateTimeFormatter.ISO_OFFSET_DATE_TIME,
        ).withZoneSameInstant(ZoneId.of(tzId)),
        ZonedDateTime.parse(
          appointment.appointmentWindowEnd,
          DateTimeFormatter.ISO_OFFSET_DATE_TIME,
        ).withZoneSameInstant(ZoneId.of(tzId)),
      ),
      assignments: appointment.assignments.map(assignment => ({
        assignmentGuid: assignment.jobAppointmentAssignmentGuid,
        technicianUserGuid: assignment.technician.userGuid,
        technician: {
          user: {
            firstName: assignment.technician.firstName,
            lastName: assignment.technician.lastName,
          },
        },
        timeWindow: new BzTimeWindow(
          ZonedDateTime.parse(
            assignment.assignmentStart,
            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
          ).withZoneSameInstant(ZoneId.of(tzId)),
          ZonedDateTime.parse(
            assignment.assignmentEnd,
            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
          ).withZoneSameInstant(ZoneId.of(tzId)),
        ),
      })),
      jobType: {
        jobClass: appointment.job.jobType.jobClass,
      },
      appointmentType: appointment.appointmentType,
      description: appointment.description,
      endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(
        appointment.endOfAppointmentNextSteps,
      ),
    }))
  }, [data, tzId])

  const jobs = useMemo(() => data?.jobs ?? [], [data])

  return {
    payments,
    appointments,
    jobs,
    isLoading: fetching,
    error,
  }
}
