import { Event } from '../../../common'
import { AccountGuidContainer } from '../../Accounts/Account'
import { ReferenceNumberContainer } from '../../common-schemas'
import { InvoiceGuid } from '../Invoicing/InvoiceTypes'
import { Cart, TransactionGuid, TransactionLinks } from '../Transactions/TransactionTypes'

export const EstimateCreatedEventTypeName = 'EstimateCreated' as const
export type EstimateCreatedEvent = Event<EstimateCreatedEventData>
export type EstimateCreatedEventData = Cart &
  ReferenceNumberContainer &
  AccountGuidContainer &
  TransactionLinks & {
    transactionGuid: TransactionGuid
    transactionVersion: number
    summary?: string
    displayName?: string
  }

export type EstimatePresentedEvent = Event
export const EstimatePresentedEventTypeName = 'EstimatePresented' as const

export type EstimateAcceptedEventData = {
  readonly signatureLinkUrl: string
}
export type EstimateAcceptedEvent = Event<EstimateAcceptedEventData>
export const EstimateAcceptedEventTypeName = 'EstimateAccepted' as const

export type EstimateRejectedEvent = Event
export const EstimateRejectedEventTypeName = 'EstimateRejected' as const

export type EstimateDeletedEvent = Event
export const EstimateDeletedEventTypeName = 'EstimateDeleted' as const

export type InvoiceCreatedFromEstimateEventData = {
  invoiceGuid: InvoiceGuid
  invoiceVersion: number
  referenceNumber: string
}
export const InvoiceCreatedFromEstimateEventTypeName = 'InvoiceCreatedFromEstimate' as const
export type InvoiceCreatedFromEstimateEvent = Event<InvoiceCreatedFromEstimateEventData>
