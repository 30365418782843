import { CartItem } from '@breezy/shared'
import React from 'react'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { LineItemForm } from './LineItemForm'

export type PricebookItemEditModalProps = {
  onSubmit: (item: CartItem) => void
  onCancel: () => void
  item: CartItem
  onRemove?: () => void
}

export const PricebookItemEditModal = React.memo<PricebookItemEditModalProps>(
  ({ onSubmit, onCancel, item, onRemove }) => (
    <OnsiteModal size="large" onClose={onCancel}>
      <LineItemForm
        onCancel={onCancel}
        onSave={onSubmit}
        defaultValues={item}
        title="Edit line item"
        onRemove={onRemove}
      />
    </OnsiteModal>
  ),
)
