import { AccountGuid, MaintenancePlanMinimalInfo } from '@breezy/shared'
import { HTMLAttributeAnchorTarget } from 'react'
import { useCanViewAccount } from '../../hooks/permission/useCanViewAccount'
import { Styled } from '../../utils/Stylable'
import { m } from '../../utils/react-utils'
import { MaintenancePlanVipElement } from '../MaintenancePlanVipElement/MaintenancePlanVipElement'

type ContactLabelProps = {
  readonly accountGuid?: AccountGuid
  readonly labelTextOverride?: string
  readonly maintenancePlan?: MaintenancePlanMinimalInfo
  readonly iconOnLeft?: boolean
  readonly plainLabel?: boolean
  readonly target?: HTMLAttributeAnchorTarget | undefined
} & (
  | {
      readonly contact: {
        readonly name: string
      }
      readonly contactFullName?: never
    }
  | {
      readonly contactFullName: {
        readonly firstName: string
        readonly lastName: string
      }
      contact?: never
    }
)

type LinkableContactProps = ContactLabelProps & {
  accountGuid: AccountGuid
}

const getContactName = (
  labelTextOverride: string | undefined,
  contact: { name: string } | undefined,
  pointOfContact: { firstName: string; lastName: string } | undefined,
) => {
  const pointOfContactName = pointOfContact
    ? `${pointOfContact.firstName} ${pointOfContact.lastName}`
    : undefined
  return (
    labelTextOverride ?? contact?.name ?? pointOfContactName ?? 'Missing Name'
  )
}

const Contact = ({
  className,
  style,
  labelTextOverride,
  contact,
  contactFullName,
  maintenancePlan,
  iconOnLeft = false,
  plainLabel,
  ...props
}: Styled<ContactLabelProps & { urlTo?: string }>) => {
  const contactName = getContactName(
    labelTextOverride,
    contact,
    contactFullName,
  )

  return (
    <MaintenancePlanVipElement
      className={className}
      style={style}
      labelText={contactName}
      maintenancePlan={maintenancePlan}
      iconOnLeft={iconOnLeft}
      plainLabel={plainLabel}
      {...props}
    />
  )
}

export const LinkableContact = m<Styled<LinkableContactProps>>(props => {
  const { accountGuid, ...rest } = props
  const { canView, isLoading } = useCanViewAccount(accountGuid)

  return canView || isLoading ? (
    <Contact {...rest} urlTo={`/accounts/${accountGuid}`} />
  ) : (
    <Contact {...props} />
  )
})

export const NonLinkableContact = m<Styled<ContactLabelProps>>(props => {
  return <Contact {...props} plainLabel />
})

export const ContactLink = m<Styled<ContactLabelProps>>(props => {
  const { plainLabel, accountGuid } = props
  const isLink = !plainLabel && !!accountGuid

  return isLink ? (
    <LinkableContact {...(props as Styled<LinkableContactProps>)} />
  ) : (
    <NonLinkableContact {...props} />
  )
})

export const ContactLabel = m<Styled<ContactLabelProps>>(props => (
  <ContactLink {...props} plainLabel />
))
