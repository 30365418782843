import z from 'zod'
import {
  AsyncFn,
  AsyncTask,
  Event,
  EventInputDto,
  EventInputMetadata,
  IsoDateString,
  LocalDateString,
} from '../../../common'
import { guidSchema, isoDateStringSchema, localDateSchema } from '../../../contracts'
import { AccountGuid, AccountGuidContainer } from '../../Accounts/Account'
import { Address } from '../../Address/Address'
import {
  ActingCompanyUserIds,
  CompanyGuidContainer,
  CompanySimpleTimeWindowDtoRequest,
  ForCompany,
  ForCompanyUser,
} from '../../Company/Company'
import { ICompanyPublish, ICompanyRead } from '../../Company/CompanyAtoms'
import { EmailAddressValue, ToEmailAddressContainer } from '../../Email/EmailTypes'
import { InvoiceV2Enriched } from '../../InvoicesV2/InvoicesV2'
import { JobDisplayId } from '../../Job'
import { MaintenancePlanMinimalInfo } from '../../MaintenancePlans/MaintenancePlanTypes'
import { DisplayIdContainer, Guid, ReferenceNumberContainer, bzOptional } from '../../common-schemas'
import { SelfServePaymentInvoice } from '../InvoicePayments/InvoicePaymentTypes'
import {
  Cart,
  ComprehensiveTransactionViewModel,
  DiscountV2EventData,
  TransactionGuid,
  TransactionLinks,
} from '../Transactions/TransactionTypes'

export enum InvoiceTerm {
  DUE_ON_RECEIPT = 'DUE_ON_RECEIPT',
  NET_7 = 'NET_7',
  NET_10 = 'NET_10',
  NET_14 = 'NET_14',
  NET_15 = 'NET_15',
  NET_30 = 'NET_30',
  NET_60 = 'NET_60',
  AUTO = 'AUTO',
}

export const invoiceTermDisplayNames = {
  [InvoiceTerm.DUE_ON_RECEIPT]: 'Due on Receipt',
  [InvoiceTerm.NET_7]: 'Net 7',
  [InvoiceTerm.NET_10]: 'Net 10',
  [InvoiceTerm.NET_14]: 'Net 14',
  [InvoiceTerm.NET_15]: 'Net 15',
  [InvoiceTerm.NET_30]: 'Net 30',
  [InvoiceTerm.NET_60]: 'Net 60',
  [InvoiceTerm.AUTO]: 'Automatic',
}

export const invoiceTermDays = {
  [InvoiceTerm.DUE_ON_RECEIPT]: 0,
  [InvoiceTerm.NET_7]: 7,
  [InvoiceTerm.NET_10]: 10,
  [InvoiceTerm.NET_14]: 14,
  [InvoiceTerm.NET_15]: 15,
  [InvoiceTerm.NET_30]: 30,
  [InvoiceTerm.NET_60]: 60,
  [InvoiceTerm.AUTO]: 0,
}

export const formatInvoiceTerm = (invoiceTerm: InvoiceTerm): string => invoiceTermDisplayNames[invoiceTerm]

export type InvoiceGuid = Guid

export const invoiceGuidContainerSchema = z.object({
  invoiceGuid: guidSchema,
})
export type InvoiceGuidContainer = { invoiceGuid: InvoiceGuid }
export const InvoiceEntityTypeName = 'Invoice' as const

export type AbridgedInvoiceMetadata = {
  invoiceGuid: InvoiceGuid
  accountGuid: AccountGuid
  displayId: number
  referenceNumber: string
  status: InvoiceStatuses
  serviceCompletionDate?: LocalDateString
}

export type AbridgedInvoiceMetadataWithAmountDue = AbridgedInvoiceMetadata & {
  amountDueUsc: number
}

/** @deprecated */
export type InvoiceMetadata = ReferenceNumberContainer &
  DisplayIdContainer &
  InvoiceStatusContainer &
  InvoiceFinalizeInput & {
    invoiceGuid: InvoiceGuid
    invoiceVersion: number
    lastAlteredAt: IsoDateString
    accountGuid: string
    selfServePaymentLinkSentAt?: IsoDateString
    previousSelfServePaymentLink?: string
    remindersSent: number
  }

/** @deprecated */
export type InvoiceTransactionIDs = {
  transactionGuid: TransactionGuid
  transactionVersion: number
}

/** @deprecated */
export type InvoiceFinalizeInput = {
  issuedAt?: IsoDateString
  serviceCompletionDate?: LocalDateString
  invoiceTerm: InvoiceTerm
  summary?: string
  displayName?: string
}

/** @deprecated */
export const createInvoiceEventInputSchema = EventInputDto.extend({
  eventData: z.object({
    issuedAt: bzOptional(isoDateStringSchema),
    serviceCompletionDate: localDateSchema,
    invoiceTerm: z.nativeEnum(InvoiceTerm),
    accountGuid: z.string(),
    summary: bzOptional(z.string()),
    displayName: bzOptional(z.string()),
  }),
})

/** @deprecated */
export type CreateInvoiceEventInput = {
  serviceCompletionDate: string
  invoiceTerm: InvoiceTerm
  accountGuid: AccountGuid
  summary?: string
  displayName?: string
}

/** @deprecated */
export type InvoiceCreatedEventData = InvoiceTransactionIDs &
  Cart &
  ReferenceNumberContainer &
  DisplayIdContainer &
  InvoiceFinalizeInput &
  AccountGuidContainer &
  TransactionLinks & {
    discountsV2?: DiscountV2EventData[]
  }

/** @deprecated */
export type InvoiceCreationMetadata = ActingCompanyUserIds &
  InvoiceTransactionIDs &
  Cart &
  InvoiceFinalizeInput &
  TransactionLinks & {
    readonly invoiceGuid?: InvoiceGuid
    readonly referenceNumber: string
    readonly displayId: number
    readonly accountGuid: string
    discountsV2?: DiscountV2EventData[]
  }

/** @deprecated */
export type InvoicePresentedEvent = Event
/** @deprecated */
export const InvoicePresentedEventTypeName = 'InvoicePresented' as const

/** @deprecated */
export type InvoiceAcceptedEvent = Event
/** @deprecated */
export const InvoiceAcceptedEventTypeName = 'InvoiceAccepted' as const

/** @deprecated */
export const InvoiceRejectedEventTypeName = 'InvoiceRejected' as const

/** @deprecated */
export type InvoiceFullyPaidEvent = Event
/** @deprecated */
export const InvoiceFullyPaidEventTypeName = 'InvoiceFullyPaid' as const

/** @deprecated */
export type InvoiceVoidedEvent = Event
/** @deprecated */
export const InvoiceVoidedEventTypeName = 'InvoiceVoided' as const

/** @deprecated */
export type InvoiceRefundedEvent = Event
/** @deprecated */
export const InvoiceRefundedEventTypeName = 'InvoiceRefunded' as const

/** @deprecated */
export type InvoiceSelfServePaymentLinkSentEventData = { paymentLink: string }

/** @deprecated */
export type InvoiceSelfServePaymentLinkSentEvent = Event<InvoiceSelfServePaymentLinkSentEventData>
/** @deprecated */
export const InvoiceSelfServePaymentLinkSentEventTypeName = 'InvoiceSelfServePaymentLinkSent' as const
/** @deprecated */
export const isInvoiceSelfServePaymentLinkSentEvent = (
  event: Event<unknown>,
): event is InvoiceSelfServePaymentLinkSentEvent => event.eventType === InvoiceSelfServePaymentLinkSentEventTypeName

/** @deprecated */
export type InvoiceReminderSentEvent = Event
export const InvoiceReminderSentEventTypeName = 'InvoiceReminderSent' as const

/**
 * Fires when the Invoice Summary has been set.
 * @deprecated
 */
export type InvoiceSummarySetEvent = Event<InvoiceSummarySetEventData>
/** @deprecated */
export type InvoiceSummarySetEventData = { readonly summary?: string }
/** @deprecated */
export const InvoiceSummarySetEventTypeName = 'InvoiceSummarySet' as const
/** @deprecated */
export const isInvoiceSummarySetEvent = (event: Event<unknown>): event is InvoiceSummarySetEvent =>
  event.eventType === InvoiceSummarySetEventTypeName

/** @deprecated */
export const InvoiceSummarySetInputDto = EventInputDto.extend({
  eventData: z.object({
    summary: bzOptional(z.string()),
  }),
})

/**
 * Fires when the Invoice DisplayName has been set.
 * @deprecated
 */
export type InvoiceDisplayNameSetEvent = Event<InvoiceDisplayNameSetEventData>
/** @deprecated */
export type InvoiceDisplayNameSetEventData = { readonly displayName?: string }
/** @deprecated */
export const InvoiceDisplayNameSetEventTypeName = 'InvoiceDisplayNameSet' as const
/** @deprecated */
export const isInvoiceDisplayNameSetEvent = (event: Event<unknown>): event is InvoiceDisplayNameSetEvent =>
  event.eventType === InvoiceDisplayNameSetEventTypeName
/** @deprecated */
export const InvoiceDisplayNameSetInputDto = EventInputDto.extend({
  eventData: z.object({
    displayName: bzOptional(z.string()),
  }),
})

/** @deprecated */
export enum InvoiceStatuses {
  DRAFT = 'Draft',
  CREATED = 'Created',
  PRESENTED = 'Presented',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  FULLY_PAID = 'Fully Paid',
  VOID = 'Void',
  REFUNDED = 'Refunded',
}

/** @deprecated */
export const FinalInvoiceStatuses: ReadonlySet<InvoiceStatuses> = new Set([
  InvoiceStatuses.REJECTED,
  InvoiceStatuses.FULLY_PAID,
  InvoiceStatuses.VOID,
])

/** @deprecated */
export const InvoiceStatusDisplayMap: { [x: string]: InvoiceStatuses } = {
  [InvoicePresentedEventTypeName]: InvoiceStatuses.PRESENTED,
  [InvoiceAcceptedEventTypeName]: InvoiceStatuses.ACCEPTED,
  [InvoiceRejectedEventTypeName]: InvoiceStatuses.REJECTED,
  [InvoiceFullyPaidEventTypeName]: InvoiceStatuses.FULLY_PAID,
  [InvoiceRefundedEventTypeName]: InvoiceStatuses.REFUNDED,
  [InvoiceVoidedEventTypeName]: InvoiceStatuses.VOID,
}

/** @deprecated */
export const ACCOUNTS_RECEIVABLE_INVOICE_STATUSES: InvoiceStatuses[] = [
  InvoiceStatuses.CREATED,
  InvoiceStatuses.PRESENTED,
  InvoiceStatuses.ACCEPTED,
  InvoiceStatuses.FULLY_PAID,
]
/** @deprecated */
export type AccountsReceivableInvoiceStatus = (typeof ACCOUNTS_RECEIVABLE_INVOICE_STATUSES)[number]

/** @deprecated */
export const setInvoiceStatusDtoSchema = invoiceGuidContainerSchema.extend({
  status: z.nativeEnum(InvoiceStatuses),
  companyGuid: z.string(),
})
/** @deprecated */
export type SetInvoiceStatusDto = {
  readonly invoiceGuid: InvoiceGuid
  readonly status: InvoiceStatuses
}
/** @deprecated */
export type InvoiceStatusContainer = {
  status: InvoiceStatuses
}

/** @deprecated */
export type InvoiceViewModel = ComprehensiveTransactionViewModel &
  InvoiceMetadata & {
    readonly issuedAt: IsoDateString
    readonly dueAt: IsoDateString
  }

/** @deprecated */
export type ComprehensiveInvoiceViewModel = InvoiceViewModel & {
  readonly companyName: string
  readonly accountPrimaryEmailAddress?: EmailAddressValue
}

/** @deprecated use InvoiceV2Reader instead */
export type IInvoiceViewModelReader = ICompanyRead<InvoiceGuidContainer, InvoiceViewModel>

/** @deprecated use InvoiceV2Reader instead */
export type IInvoiceComprehensiveViewModelReader = ICompanyRead<InvoiceGuidContainer, ComprehensiveInvoiceViewModel>

export type InvoiceEmailRequest = InvoiceGuidContainer & Partial<ToEmailAddressContainer>

/** @deprecated */
export type IInvoiceEmailer = ICompanyPublish<InvoiceEmailRequest>

export type InvoiceV2Emailer = AsyncFn<ForCompanyUser<InvoiceEmailRequest>, void>

/** @deprecated */
export type IInvoiceHtmlGenerator = AsyncFn<InvoiceViewModel, string>

export type InvoiceV2HtmlGenerator = AsyncFn<InvoiceV2Enriched, string>

/** @deprecated */
export type IInvoicePDFGenerator = AsyncFn<InvoiceViewModel, Buffer>

export type InvoiceV2PdfGenerator = AsyncFn<InvoiceV2Enriched, Buffer>

// NOTE: Prefer to use isNull to enable TS to help us prevent missed mappings
/** @deprecated */
export type InvoiceAndPaymentsQueryableRecord = {
  companyGuid: Guid
  invoiceGuid: Guid
  referenceNumber: string
  displayId: number
  invoiceStatus: InvoiceStatuses
  invoiceTerm: InvoiceTerm
  accountGuid: Guid
  issuedAt: IsoDateString
  dueAt: IsoDateString
  totalAmountUsd: number
  balanceAmountUsd: number
  paidAmountUsd: number
  subtotalPriceUsd: number
  discountAmountUsd: number
  taxAmountUsd: number
  lastPaidAt?: IsoDateString
  paymentMethodTypeDescription?: string
  workCompletedAt: IsoDateString
  jobGuid?: Guid
  jobAppointmentGuid?: Guid
  locationGuid?: Guid
  estimateGuid?: Guid
  maintenancePlanGuid?: Guid
  createdByUserGuid: Guid
  updatedAt: IsoDateString
  summary?: string
  displayName?: string
  lastAlteredAt: IsoDateString
  selfServePaymentLinkSentAt?: IsoDateString
  previousSelfServePaymentLink?: string
  remindersSent: number
}

/** @deprecated */
export type InvoiceQueryableRecordsWriter = AsyncFn<InvoiceAndPaymentsQueryableRecord>

/** @deprecated */
export type EnrichedInvoiceAndPaymentsQueryableRecord = InvoiceAndPaymentsQueryableRecord & {
  accountDisplayName: string
  accountMaintenancePlans: MaintenancePlanMinimalInfo[]
  jobDisplayId?: JobDisplayId
}

/** @deprecated */
export const InvoicesQueryableByAccountRequestSchema = z.object({
  accountGuid: guidSchema,
  companyGuid: guidSchema,
})

/** @deprecated */
export type InvoicesQueryableByAccountRequest = z.infer<typeof InvoicesQueryableByAccountRequestSchema>

/** @deprecated */
export const InvoicesQueryableByJobRequestSchema = z.object({
  jobGuid: guidSchema,
  companyGuid: guidSchema,
})

/** @deprecated */
export type InvoicesQueryableByJobRequest = z.infer<typeof InvoicesQueryableByJobRequestSchema>

/** @deprecated */
export type InvoiceQueryableRecordsReaderRequest =
  | {
      type: 'by-company-simple-time-window'
      input: CompanySimpleTimeWindowDtoRequest
    }
  | {
      type: 'by-account-guid'
      input: InvoicesQueryableByAccountRequest
    }
  | {
      type: 'by-job-guid'
      input: InvoicesQueryableByJobRequest
    }
  | {
      type: 'by-invoice-guid'
      input: InvoiceGuidContainer & CompanyGuidContainer
    }

/**
 * Reads invoices for a given company in a specified time window
 * @deprecated
 */
export type InvoiceQueryableRecordsReader = AsyncFn<
  InvoiceQueryableRecordsReaderRequest,
  EnrichedInvoiceAndPaymentsQueryableRecord[]
>

/** @deprecated */
export const SendInvoiceSchema = z.object({
  invoiceGuid: guidSchema,
  deliveryMethod: z.enum(['EMAIL', 'SMS']),
  body: z.string(),
  to: z.string(),
  subject: bzOptional(z.string()),
  paymentLink: z.string(),
})
export type SendInvoiceRequest = z.infer<typeof SendInvoiceSchema>

/** @deprecated */
export type InvoicesQueryableSync = AsyncFn<CompanyGuidContainer>

/** @deprecated */
export type InvoiceFromReferenceNumberReader = AsyncFn<ReferenceNumberContainer, SelfServePaymentInvoice>
/** @deprecated */
export type InvoiceEntityInfoFromReferenceNumberReader = AsyncFn<ReferenceNumberContainer, EventInputMetadata>

/** @deprecated */
export type InvoicesAverageAgeReader = AsyncFn<CompanyGuidContainer, number>

export type InvoiceServiceAddressReader = AsyncFn<InvoiceGuidContainer, Address | undefined>

export type InvoiceReminderSendTask = AsyncTask

/** @deprecated */
export type InvoiceMinimalViewModel = {
  invoiceGuid: Guid
  displayId: string
  totalAmountUsd: number
  invoiceStatus: InvoiceStatuses
}

/** Used to straddle InvoicesV1 and InvoicesV2 for code that relies on this type */
export type AbridgedInvoiceViewModel = {
  invoiceGuid: Guid
  displayId: string
  totalAmountUsd: number
  invoiceStatus: InvoiceStatuses
}

/** @deprecated */
export type InvoiceV1ExistsReader = AsyncFn<ForCompany<InvoiceGuidContainer>, boolean>
