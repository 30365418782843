import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsOfficeApp } from '../useIsOfficeApp'
import { useIsAuthorized } from './useIsAuthorized'

export const useCanCreateJobs = () => {
  const isOfficeApp = useIsOfficeApp()
  const isAuthorized = useIsAuthorized()

  return useMemo(() => {
    if (isOfficeApp)
      return isAuthorized(PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE)

    return isAuthorized(PermissionV2.FIELD_JOB_INFORMATION_CREATE)
  }, [isOfficeApp, isAuthorized])
}
