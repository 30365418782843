import React, { useCallback, useState } from 'react'
import { useMutation } from 'urql'
import { ReminderStatusTypesEnum } from '../../../generated/user/graphql'
import { useMessage } from '../../../utils/antd-utils'
import DangerConfirmModal from '../../DangerConfirmModal/DangerConfirmModal'
import AccountReminderItem from '../AccountReminderItem/AccountReminderItem'
import { Reminder } from '../AccountReminders.types'
import { AccountRemindersForm } from '../AccountRemindersForm/AccountRemindersForm'
import { useAccountRemindersContext } from '../AccountRemindersWrapper/AccountRemindersWrapper'
import { DELETE_REMINDER_MUTATION } from './AccountReminderView.gql'

export type AccountReminderViewProps = {
  reminder: Reminder
  toggleStatus: (
    reminderGuid: string,
    status: ReminderStatusTypesEnum,
  ) => Promise<void>
}

const AccountReminderView = React.memo<AccountReminderViewProps>(
  ({ reminder, ...rest }) => {
    const [isEditingReminder, setIsEditingReminder] = useState(false)
    const [deleteReminderModalOpen, setDeleteReminderModalOpen] =
      useState(false)
    const { toggleEditing, refetch } = useAccountRemindersContext()
    const message = useMessage()

    const toggleEditingReminder = useCallback(() => {
      setIsEditingReminder(editStatus => !editStatus)
      toggleEditing()
    }, [toggleEditing])

    const editSubmitted = useCallback(() => {
      refetch()
      message.success('Reminder updated')
    }, [message, refetch])

    const openDeleteReminderModal = useCallback(() => {
      setDeleteReminderModalOpen(true)
    }, [setDeleteReminderModalOpen])

    const closeDeleteReminderModal = useCallback(() => {
      setDeleteReminderModalOpen(false)
    }, [setDeleteReminderModalOpen])

    const [{ fetching: deleting }, executeMutation] = useMutation(
      DELETE_REMINDER_MUTATION,
    )

    const deleteReminder = useCallback(async () => {
      try {
        await executeMutation({
          reminderGuid: reminder.reminderGuid,
        })

        refetch()
        message.success('Reminder deleted')
        closeDeleteReminderModal()
      } catch (e) {
        message.error(`Failed to delete reminder`)
      }
    }, [
      closeDeleteReminderModal,
      executeMutation,
      message,
      refetch,
      reminder.reminderGuid,
    ])

    return isEditingReminder ? (
      <EditAccountReminderForm
        reminder={reminder}
        toggle={toggleEditingReminder}
        submitted={editSubmitted}
      />
    ) : (
      <>
        <AccountReminderItem
          {...rest}
          reminder={reminder}
          edit={toggleEditingReminder}
          onDelete={openDeleteReminderModal}
          isDeleting={deleting}
        />
        <DangerConfirmModal
          title="Delete Reminder"
          open={deleteReminderModalOpen}
          okButtonText="Yes, Delete Reminder"
          onOk={deleteReminder}
          onCancel={closeDeleteReminderModal}
          promptText={
            <>
              {`Are you sure you'd like to delete the reminder `}
              <strong>{`"${reminder.description}"`}</strong>
              {'?'}
            </>
          }
        />
      </>
    )
  },
)

type EditAccountReminderFormProps = {
  reminder: Reminder
  toggle: () => void
  submitted: () => void
}

const EditAccountReminderForm = React.memo<EditAccountReminderFormProps>(
  ({ reminder, toggle, submitted }) => {
    return (
      <div className="flex flex-row gap-3">
        <div className="flex min-w-[20px] flex-col py-3" />
        <div className="flex flex-1 flex-col">
          <AccountRemindersForm
            reminder={reminder}
            hide={toggle}
            submitted={submitted}
          />
        </div>
      </div>
    )
  },
)

export default AccountReminderView
