import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch, SwitchProps } from 'antd'
import React from 'react'

export const BzSwitch = React.memo(
  React.forwardRef<HTMLElement, SwitchProps & { dataTestId?: string }>(
    (props, ref) => (
      <Switch
        ref={ref}
        data-testid={props.dataTestId}
        checkedChildren={
          <div className="ml-[-2px] mr-[4px] pt-[0px]">
            <FontAwesomeIcon icon={faCheck} className="mt-[3px] text-[16px]" />
          </div>
        }
        unCheckedChildren={
          <div className="ml-[4px] mr-[-2px] pt-[0px]">
            <FontAwesomeIcon icon={faClose} className="mt-[1px] text-[16px]" />
          </div>
        }
        {...props}
      />
    ),
  ),
)
