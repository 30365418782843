import { debounce } from '@breezy/shared'
import { faClose } from '@fortawesome/pro-light-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Input } from 'antd'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Configure, InstantSearch, useSearchBox } from 'react-instantsearch'

import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AlgoliaQueryHook,
  useAlgoliaClient,
} from '../../hooks/useAlgoliaClient'
import useIsMobile from '../../hooks/useIsMobile'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { getIsMac } from '../../utils/OperatingSystem'
import { m } from '../../utils/react-utils'
import KeyboardKey from '../KeyboardKey/KeyboardKey'
import { useGlobalSearch } from './GlobalSearchContext'
import { GlobalSearchResults } from './GlobalSearchDisplay'

type ShortcutDisplayProps = {
  className?: string
  keyClassName?: string
}

function ShortcutDisplay({ className }: ShortcutDisplayProps) {
  const isMac = getIsMac()

  return (
    <div className={cn('flex gap-1', className)}>
      {isMac ? (
        <>
          <KeyboardKey>
            <span style={{ fontSize: '1.2em' }}>⌘</span>
          </KeyboardKey>
          <KeyboardKey>K</KeyboardKey>
        </>
      ) : (
        <>
          <KeyboardKey>Ctrl</KeyboardKey>
          <KeyboardKey>K</KeyboardKey>
        </>
      )}
    </div>
  )
}

export const GlobalSearchInputFacade = m(() => {
  const { setGlobalSearchVisible } = useGlobalSearch()

  return (
    <button
      className="flex h-[36px] w-[254px] flex-row items-center justify-between rounded-3xl border border-solid border-bz-gray-500 bg-white px-3 py-2 transition-all hover:cursor-pointer hover:border-bz-primary hover:shadow-sm hover:shadow-bz-primary/30"
      onClick={() => setGlobalSearchVisible(true)}
    >
      <div className="flex flex-row items-center">
        <FontAwesomeIcon icon={faMagnifyingGlass} color="#8C8C8C" />
        <span className="ml-2 text-bz-gray-500">Search...</span>
      </div>
      <div className="flex flex-row items-center">
        <ShortcutDisplay />
      </div>
    </button>
  )
})

export type GlobalSearchInputProps = {
  queryHook: (query: string, search: (value: string) => void) => void
  displayShortcut?: boolean
  onCancel: () => void
}

export const GlobalSearchInput = m<GlobalSearchInputProps>(
  ({ queryHook, displayShortcut = false, onCancel }) => {
    const { query, refine, clear } = useSearchBox({ queryHook })
    const isMobile = useIsMobile()
    const [searchInput, setSearchInput] = useState(query)

    const setQuery = (newQuery: string) => {
      setSearchInput(newQuery)
      refine(newQuery)
    }

    return (
      <Input
        placeholder="Search..."
        autoFocus
        className={classNames([
          'flex h-[72px] w-full flex-row items-center justify-center',
          'rounded-lg border-transparent px-6',
          'text-2xl placeholder:text-gray-600',
          'focus:border-transparent focus:ring-0',
          query !== '' ? 'rounded-b-none' : '',
        ])}
        value={searchInput}
        onChange={event => setQuery(event.currentTarget.value)}
        prefix={
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="pr-5"
            fontSize={isMobile ? 24 : 32}
            color="#1890FF"
          />
        }
        suffix={
          <div className="flex h-full flex-row items-center justify-center space-x-6 pl-5">
            {searchInput.trim() !== '' && (
              <>
                <FontAwesomeIcon
                  icon={faClose}
                  color="#BFBFBF"
                  className="hover:cursor-pointer"
                  onClick={() => {
                    setSearchInput('')
                    clear()
                  }}
                />
                <Divider type="vertical" />
              </>
            )}
            {displayShortcut && <ShortcutDisplay className="text-2xl" />}
            <Button
              type="text"
              className="p-0 text-bz-gray-600 hover:bg-bz-gray-300"
              onClick={() => {
                onCancel()
                setSearchInput('')
                clear()
              }}
            >
              Cancel
            </Button>
          </div>
        }
      />
    )
  },
)

const ALGOLIA_SEARCH_DEBOUNCE_WAIT_TIME_MS = 250

export const GlobalSearchModal = m(() => {
  const { client, indexName } = useAlgoliaClient()
  const { globalSearchVisible, setGlobalSearchVisible } = useGlobalSearch()

  const queryHook = useMemo<ReturnType<typeof debounce<AlgoliaQueryHook>>>(
    () =>
      debounce((query, search) => {
        search(query)
      }, ALGOLIA_SEARCH_DEBOUNCE_WAIT_TIME_MS),
    [],
  )

  const navigate = useNavigate()
  const location = useLocation()

  const expectedCompany = useExpectedCompany()

  useEffect(() => {
    setGlobalSearchVisible(false)
  }, [location, setGlobalSearchVisible])

  if (!globalSearchVisible) {
    return null
  }

  return (
    <InstantSearch searchClient={client} indexName={indexName} insights={true}>
      <Configure filters={`companyGuid:${expectedCompany.companyGuid}`} />

      <div
        className={classNames([
          'absolute left-0 top-0 z-50',
          'h-full w-full',
          'flex flex-col items-center',
          'bg-[#3F5A67] bg-opacity-50',
          'p-10 backdrop-blur-sm',
        ])}
        onClick={() => setGlobalSearchVisible(false)}
      >
        <div
          className={classNames([
            'flex h-full max-h-full w-[800px] flex-col items-center',
          ])}
          // Prevents parent onClick handler from firing
          onClick={event => event.stopPropagation()}
        >
          <GlobalSearchInput
            queryHook={queryHook}
            displayShortcut
            onCancel={() => setGlobalSearchVisible(false)}
          />
          <GlobalSearchResults
            onAccountClick={accountGuid => {
              setGlobalSearchVisible(false)
              navigate(`/accounts/${accountGuid}`)
            }}
          />
        </div>
      </div>
    </InstantSearch>
  )
})
