import { MaintenancePlanDefinition } from '@breezy/shared'
import { Typography } from 'antd'
import React from 'react'

export type MaintenancePlantTermsAndConditionsProps = {
  maintenancePlanDefinition: MaintenancePlanDefinition
}

export const MaintenancePlanTermsAndConditions = ({
  maintenancePlanDefinition,
}: MaintenancePlantTermsAndConditionsProps) => {
  return (
    <div className="flex w-full flex-grow flex-col overflow-hidden">
      <Typography.Title level={4} className="mb-1">
        Terms and Conditions
      </Typography.Title>
      <Typography.Paragraph
        ellipsis={{
          rows: 10,
          expandable: true,
          symbol: 'Read More',
        }}
        className="mb-0 font-normal"
      >
        {maintenancePlanDefinition.termsAndConditions
          .split('\n')
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
      </Typography.Paragraph>
    </div>
  )
}
