import { Guid } from '..'
import { AsyncFn } from '../../common'
import { Address } from '../Address/Address'
import { CompanyGuidContainer, ForCompanyUser } from '../Company/Company'
import { EmailAddress } from '../Email/EmailTypes'
import { PhoneNumber } from '../PhoneNumbers/Phone'
import { PricebookItemGuid, PricebookTaxRateGuid } from '../Pricebook/PricebookTypes'
import { InvoiceTerm } from './Invoicing/InvoiceTypes'

export type BillingProfileDto = {
  defaultPricebookTaxRateGuid: PricebookTaxRateGuid
  invoiceMemo: string
  invoiceTerm: InvoiceTerm
  estimateDisclaimer: string
  invoiceDisclaimer: string
  businessAddress: Address
  businessLegalName: string
  contractorLicenseNumber?: string
  businessLogoURL: string
  businessBillingEmailAddress: EmailAddress
  businessBillingContactPhoneNumber: PhoneNumber
  businessWebsiteURL?: string
  businessWebsiteDisplayName?: string
  maintenancePlanServiceItemGuid?: PricebookItemGuid
  wisetackMerchantId?: string
} & CompanyGuidContainer

export type IBillingProfileReader = AsyncFn<CompanyGuidContainer, BillingProfileDto>

export const defaultEstimateDisclaimer = (companyName: string) =>
  `THIS IS AN ESTIMATE, NOT A CONTRACT FOR SERVICES. The summary above is furnished by ${companyName} as a good faith estimate of work to be performed at the location described above and is based on our evaluation and does not include material price increases or additional labor and materials which may be required should unforeseen problems arise after the work has started. I understand that the final cost of the work may differ from the estimate, perhaps materially. THIS IS NOT A GUARANTEE OF THE FINAL PRICE OF WORK TO BE PERFORMED. I agree and authorize the work as summarized on these estimated terms, and I agree to pay the full amount for all work performed.`

export const defaultInvoiceDisclaimer =
  'This invoice document is a statement of the amount due for services rendered or products provided as outlined above. Please review the details of each charge carefully. By paying the invoice, you agree to the terms and conditions specified in the contract or agreement signed prior to the commencement of the services.\n\nPlease note that any discrepancies or disputes regarding this invoice must be brought to our attention within 15 days of the invoice date. Failure to do so will be considered as an acceptance of charges as accurate.\n\nPayment is due upon receipt of this invoice unless otherwise specified. Late payments may incur additional charges at the rate specified in your contract. All services provided are subject to the terms and conditions of the service agreement.\n\nThank you for your prompt attention to this invoice, and for your continued business.'

export type BillingProfileWriteRequest = {
  businessAddress: Address
  invoiceMemo: string
  invoiceTerm: InvoiceTerm
  estimateDisclaimer: string
  invoiceDisclaimer: string
  businessFullLegalName: string
  contractorLicenseNumber?: string
  websiteDisplayName: string
  websiteUrl: string
  tilledMerchantAccountId?: string
  wisetackMerchantId?: string
  defaultPricebookTaxRateGuid: PricebookTaxRateGuid
  maintenancePlanServiceItemGuid: PricebookItemGuid
  emailAddress: EmailAddress
  phoneNumber: PhoneNumber
  logoUrl: string
  logoPhotoGuid: Guid
}

export type BillingProfileWriter = AsyncFn<ForCompanyUser<BillingProfileWriteRequest>, void>
