import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type OptionButtonProps = {
  faIcon: IconProp
  label: string
  onClick: () => void
}

const OptionButton = ({ faIcon, label, onClick }: OptionButtonProps) => {
  return (
    <div
      className="center-children-vh flex w-full cursor-pointer flex-col items-center justify-center space-y-4 rounded-[8px] border border-solid border-[#BFBFBF] pb-8 pt-9 text-center"
      onClick={onClick}
    >
      <FontAwesomeIcon className="blue6 h-8 w-8" icon={faIcon} />
      <div className="semibold_16_24 grey9">{label}</div>
    </div>
  )
}

export default OptionButton
