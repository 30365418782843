import algoliasearch, { SearchClient } from 'algoliasearch'
import { getConfig } from '../config'

export type AlgoliaQueryHook = (
  query: string,
  search: (value: string) => void,
) => void

export const useAlgoliaClient = (): {
  client: SearchClient
  indexName: string
} => {
  const { algoliaAppId, algoliaReadApiKey, algoliaIndexName, env } = getConfig()

  if (algoliaIndexName === '') {
    if (env !== 'local-development') {
      console.warn('WARNING: Algolia Index Name missing, search is disabled')
    }
    return { client: algoliasearch('', ''), indexName: '' }
  }

  const client = algoliasearch(algoliaAppId, algoliaReadApiKey)

  return {
    client: {
      ...client,
      // This is required to prevent the client from search on an empty input.
      // See the following link to Algolia's docs for more info:
      // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/#detecting-empty-search-requests
      search: requests => {
        if (requests.every(({ params }) => !params?.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: '',
            })),
          })
        }

        return client.search(requests)
      },
    },
    indexName: algoliaIndexName,
  }
}
