import { isNullish } from '@breezy/shared'
import { useSubscription } from 'urql'
import {
  useExpectedCompanyGuid,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { TECH_CLOCKED_IN_QUERY } from './TechnicianHooks.gql'

export const useIsTechClockedIn = () => {
  const companyGuid = useExpectedCompanyGuid()
  const user = useExpectedPrincipal()
  const [res, refetch] = useSubscription({
    query: TECH_CLOCKED_IN_QUERY,
    variables: {
      companyGuid: companyGuid,
      userGuid: user.userGuid,
    },
  })

  return {
    isClockedIn: !isNullish(res.data) && res.data.timesheetEntries.length > 0,
    clockInTime: !isNullish(res.data?.timesheetEntries[0])
      ? res.data?.timesheetEntries[0].finalStartTime
      : undefined,
    isLoading: res.fetching,
    refetch,
  }
}
