import {
  JobOutcomesFormSchema,
  JobOutcomesTechnicianTurnoverSubformSchema,
  isNullish,
  isNullishOrEmpty,
} from '@breezy/shared'
import { Button, Col, Radio, Row, Select, SelectProps } from 'antd'
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { trpc } from 'src/hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import RequiredFieldMarker from './RequiredFieldMarker'
import { TechnicianMap } from './job-outcome-modal-types'

type TechnicianTurnoverPanelProps = {
  isEditing: boolean
  disableEditButton: boolean
  onClickEditButton: () => void
  onClickCancelEditingButton: () => void
  onSubformSubmit: (values: JobOutcomesTechnicianTurnoverSubformSchema) => void
}

const TechnicianTurnoverPanel: React.FC<TechnicianTurnoverPanelProps> = ({
  isEditing,
  disableEditButton,
  onClickEditButton,
  onClickCancelEditingButton,
  onSubformSubmit,
}) => {
  const message = useMessage()
  const { formState, getValues, resetField } =
    useFormContext<JobOutcomesFormSchema>()
  const fetchTechniciansQuery = trpc.user['users:get-technicians'].useQuery()

  const technicianGuidToTechnicianMap = useMemo(() => {
    if (fetchTechniciansQuery.data) {
      const activeTechnicians = fetchTechniciansQuery.data.filter(tech =>
        isNullish(tech.user.deactivatedAt),
      )
      return activeTechnicians.reduce((acc, technician) => {
        acc[technician.user.id] = technician
        return acc
      }, {} as TechnicianMap)
    }

    return {}
  }, [fetchTechniciansQuery.data])

  let content: ReactNode = null
  if (fetchTechniciansQuery.isLoading) {
    content = <LoadingSpinner />
  } else if (isEditing) {
    content = (
      <TechnicianTurnoverContentEditMode
        technicianGuidToTechnicianMap={technicianGuidToTechnicianMap}
      />
    )
  } else {
    content = (
      <TechnicianTurnoverContentViewMode
        technicianGuidToTechnicianMap={technicianGuidToTechnicianMap}
      />
    )
  }

  const onSubmit = useCallback(() => {
    try {
      if (Object.keys(formState.errors).length > 0) {
        console.error('formState.errors', formState.errors)
        message.error(
          'Could not assign technician turnover, please contact support',
        )
        return
      }

      const isTurnedOverByTechnician = getValues('isTurnedOverByTechnician')
      const technicianTurnoverUserGuids = getValues(
        'technicianTurnoverUserGuids',
      )

      onSubformSubmit({
        isTurnedOverByTechnician,
        technicianTurnoverUserGuids,
      })
    } catch (err) {
      console.error(err)
    }
  }, [formState.errors, getValues, message, onSubformSubmit])

  const onCancel = useCallback(() => {
    resetField('isTurnedOverByTechnician')
    resetField('technicianTurnoverUserGuids')
    onClickCancelEditingButton()
  }, [onClickCancelEditingButton, resetField])

  const ctaButtons = useMemo(() => {
    if (isEditing) {
      return (
        <Col>
          <Button
            key="cancel"
            onClick={onCancel}
            htmlType="button"
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>
          <Button
            key="save"
            onClick={onSubmit}
            htmlType="button"
            type="primary"
          >
            Save
          </Button>
        </Col>
      )
    } else {
      return (
        <Col>
          <Button
            type="default"
            disabled={disableEditButton}
            onClick={onClickEditButton}
          >
            Edit
          </Button>
        </Col>
      )
    }
  }, [disableEditButton, isEditing, onCancel, onClickEditButton, onSubmit])

  return (
    <>
      <Row className="space-between">
        <h3>Technician Turnover</h3>
        {ctaButtons}
      </Row>

      <Row>{content}</Row>
    </>
  )
}

type TechnicianTurnoverContentViewModeProps = {
  technicianGuidToTechnicianMap: TechnicianMap
}

const TechnicianTurnoverContentViewMode: React.FC<
  TechnicianTurnoverContentViewModeProps
> = ({ technicianGuidToTechnicianMap }) => {
  const { watch } = useFormContext<JobOutcomesFormSchema>()
  const technicianTurnoverUserGuidsValue = watch('technicianTurnoverUserGuids')
  const isTurnedOverByTechnicianValue = watch('isTurnedOverByTechnician')

  const technicianTurnoversList = useMemo(() => {
    if (
      isNullish(technicianTurnoverUserGuidsValue) ||
      technicianTurnoverUserGuidsValue.length === 0
    ) {
      return ''
    }

    const names = technicianTurnoverUserGuidsValue.map(
      turnoverTechnicianGuid => {
        const technician = technicianGuidToTechnicianMap[turnoverTechnicianGuid]
        if (technician) {
          return `${technician.user.firstName} ${technician.user.lastName}`
        }

        return null
      },
    )

    return names.filter(name => !isNullishOrEmpty(name)).join(', ')
  }, [technicianTurnoverUserGuidsValue, technicianGuidToTechnicianMap])

  return (
    <>
      <Row style={{ width: '100%', marginBottom: '4px' }}>
        <strong>Was this job turned over by a technician?</strong>
      </Row>
      {isTurnedOverByTechnicianValue ? (
        <>
          <Row style={{ width: '100%', paddingBottom: '8px' }}>Yes</Row>
          <Row style={{ width: '100%', marginBottom: '4px' }}>
            <strong>Technician(s)</strong>
          </Row>
          <Row style={{ width: '100%' }}>{technicianTurnoversList}</Row>
        </>
      ) : (
        <Row style={{ width: '100%' }}>
          <div>No</div>
        </Row>
      )}
    </>
  )
}

type TechnicianTurnoverContentEditModeProps = {
  technicianGuidToTechnicianMap: TechnicianMap
}

const TechnicianTurnoverContentEditMode: React.FC<
  TechnicianTurnoverContentEditModeProps
> = ({ technicianGuidToTechnicianMap }) => {
  const { control, watch, trigger, formState } =
    useFormContext<JobOutcomesFormSchema>()
  const isTurnedOverByTechnicianValue = watch('isTurnedOverByTechnician')
  const technicianTurnoverUserGuids = watch('technicianTurnoverUserGuids')

  const errorStateMessage = useMemo(() => {
    // I think this doesn't exist on the typing because the validation is done
    // through zod's `refine`
    return (formState.errors as Record<string, { message: string }>)[
      'mustSpecifyTechniciansIfTurnedOver'
    ]?.message
  }, [formState.errors])

  // For some reason, the validation in our zod schema that asserts that technician users
  // must be specified if the job was turned over is not run when these values are updated.
  // I'm not 100% sure the reason, maybe it's because the validation is in a zod refinement?
  // We manually trigger all validation here for now
  useEffect(() => {
    trigger()
  }, [isTurnedOverByTechnicianValue, trigger, technicianTurnoverUserGuids])

  const isInErrorState = useMemo(() => {
    return (
      errorStateMessage && formState.touchedFields.technicianTurnoverUserGuids
    )
  }, [errorStateMessage, formState.touchedFields.technicianTurnoverUserGuids])

  const technicianTurnoversSelectOptions = useMemo<
    SelectProps['options']
  >(() => {
    return Object.entries(technicianGuidToTechnicianMap).map(
      ([technicianGuid, technician]) => {
        return {
          label: `${technician.user.firstName} ${technician.user.lastName}`,
          value: technicianGuid,
        }
      },
    )
  }, [technicianGuidToTechnicianMap])

  const filterTechnicianOption = useCallback(
    (input: string, option?: { label: ReactNode }) => {
      if (option?.label && typeof option.label === 'string') {
        return option.label.toLowerCase().includes(input.toLowerCase())
      } else {
        return false
      }
    },
    [],
  )

  return (
    <>
      <Row style={{ width: '100%', marginBottom: '8px' }}>
        <strong>Was this job turned over by a technician?</strong>
        <RequiredFieldMarker />
      </Row>

      <Row style={{ width: '100%', paddingBottom: '16px' }}>
        <Controller
          name="isTurnedOverByTechnician"
          control={control}
          rules={{ required: true }}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group {...field} optionType="button">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          )}
        />
      </Row>

      {isTurnedOverByTechnicianValue && (
        <>
          <Row style={{ width: '100%', marginBottom: '8px' }}>
            <strong>Which technician(s) turned it over?</strong>
            <RequiredFieldMarker />
          </Row>
          <Row style={{ width: '100%' }}>
            <Controller
              name="technicianTurnoverUserGuids"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <Select
                      {...field}
                      style={{ width: '100%' }}
                      mode="multiple"
                      allowClear
                      placeholder="Select technician(s) to credit with turning over this job"
                      options={technicianTurnoversSelectOptions}
                      filterOption={filterTechnicianOption}
                      status={isInErrorState ? 'error' : undefined}
                    />
                  </>
                )
              }}
            />
          </Row>
          <Row>
            {isInErrorState ? (
              <p style={{ paddingTop: '2px', color: '#FF4D4F' }}>
                {errorStateMessage}
              </p>
            ) : (
              <p style={{ paddingTop: '2px', color: 'rgba(0, 0, 0, 0.45)' }}>
                Technicians who are credited with a turnover will be
                automatically attributed with the job's total sold revenue. You
                may change this in the "Revenue Attribution" section below.
              </p>
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default TechnicianTurnoverPanel
