import {
  MaintenancePlanCollapsibleViewModel,
  MaintenancePlanStatus,
  formatUsc,
  getAllInstalledEquipmentGuidsFromVisits,
  getNumActiveMaintenancePlanCredits,
  getNumUnusedMaintenancePlanVisits,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import ThinDivider from '../../../elements/ThinDivider'
import useAppNavigation from '../../../hooks/useAppNav'
import { useCompanyMaintenancePlansEnabled } from '../../../hooks/useCompanyMaintenancePlansEnabled'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import MaintenancePlanStatusTag from '../../../pages/MaintenancePlanDetailsPage/MaintenancePlanStatusTag'
import tailwindConfig from '../../../tailwind.config'
import { MaintenancePlanIcon } from '../../../utils/feature-icons'
import { m } from '../../../utils/react-utils'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type MaintenancePlansCollapsibleProps = {
  plans: MaintenancePlanCollapsibleViewModel[]
  createNew?: () => void
  titleOverride?: string
  technicianAppStyle?: boolean
  activeMaintenancePlanGuid?: string
  hideCanceledPlans?: boolean
}

const MaintenancePlansCollapsible = m<MaintenancePlansCollapsibleProps>(
  ({
    plans,
    createNew,
    titleOverride,
    technicianAppStyle,
    activeMaintenancePlanGuid,
    hideCanceledPlans = false,
  }) => {
    const appNav = useAppNavigation()
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
    const maintPlansV3Enabled = useFeatureFlag('createMaintenancePlanV2')

    const sortedPlans = useMemo(() => {
      const filteredPlans = hideCanceledPlans
        ? plans.filter(p => p.status !== MaintenancePlanStatus.CANCELED)
        : plans

      return filteredPlans.sort((a, b) => {
        if (a.maintenancePlanGuid === activeMaintenancePlanGuid) return -1
        if (b.maintenancePlanGuid === activeMaintenancePlanGuid) return 1
        return 0
      })
    }, [plans, hideCanceledPlans, activeMaintenancePlanGuid])

    if (!isCompanyMaintenancePlansEnabled) {
      return null
    }

    return (
      <BzCollapsible
        title={titleOverride ?? 'Maintenance Plans'}
        titleIcon={MaintenancePlanIcon}
        technicianAppStyle={technicianAppStyle}
        onPlus={createNew}
      >
        {sortedPlans.map((p, index) => (
          <React.Fragment key={p.maintenancePlanGuid}>
            <CollapsibleItem
              title={p.locationAddress.line1}
              tag={
                <MaintenancePlanStatusTag
                  status={p.status}
                  paymentFlow={p.paymentFlow}
                />
              }
              onTitleClick={() =>
                appNav.navigateToMaintenancePlanDetailsPage(
                  p.maintenancePlanGuid,
                )
              }
              contentList={[
                { key: 'Type', value: p.planTypeName },
                { key: 'Yearly Revenue', value: formatUsc(p.yearlyPriceUsc) },
                {
                  key: 'Num Covered Equipment',
                  value: maintPlansV3Enabled
                    ? getAllInstalledEquipmentGuidsFromVisits(
                        p.visits,
                      ).length.toString()
                    : p.coveredInstalledEquipmentGuids.length.toString(),
                },
                {
                  key: 'Num Unused Visits',
                  value: maintPlansV3Enabled
                    ? getNumUnusedMaintenancePlanVisits(p.visits).toString()
                    : getNumActiveMaintenancePlanCredits(p.credits).toString(),
                },
              ]}
              alwaysOpen
            />
            {sortedPlans.length > 1 &&
              index === 0 &&
              p.maintenancePlanGuid === activeMaintenancePlanGuid && (
                <ThinDivider
                  widthPx={1}
                  borderColorClassName={
                    tailwindConfig.theme.extend.colors.bz.border
                  }
                  styleOverrides={{ marginBottom: 4 }}
                />
              )}
          </React.Fragment>
        ))}
      </BzCollapsible>
    )
  },
)

export default MaintenancePlansCollapsible
