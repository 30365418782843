import { useIsTechAssignedToLocation } from '../technician/useIsTechAssignedToLocation'
import { useIsOfficeApp } from '../useIsOfficeApp'
import { useCanViewAllFieldAccounts } from './useCanViewAllFieldAccounts'
import { useCanViewOfficeAccount } from './useCanViewOfficeAccount'
import { useHasFieldAccountsFullAccess } from './useHasFieldAccountsFullAccess'

export const useCanViewLocation = (locationGuid: string) => {
  const isOfficeApp = useIsOfficeApp()
  const { isTechAssignedToLocation, isLoading } =
    useIsTechAssignedToLocation(locationGuid)
  const canViewOfficeAccount = useCanViewOfficeAccount()
  const hasFieldAccountsFullAccess = useHasFieldAccountsFullAccess()
  const canViewAllFieldAccounts = useCanViewAllFieldAccounts()

  let canView = false
  let loadingStatus = true

  if (isOfficeApp) {
    canView = canViewOfficeAccount
    loadingStatus = false
  } else if (hasFieldAccountsFullAccess || canViewAllFieldAccounts) {
    canView = true
    loadingStatus = false
  } else {
    canView = isTechAssignedToLocation
    loadingStatus = isLoading
  }

  return {
    canView,
    isLoading: loadingStatus,
  }
}
