import { Guid, R, isNullish, toPlural } from '@breezy/shared'
import { faWrench } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useQuery } from 'urql'
import {
  OnsiteBasicModal,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  AsyncPhotoData,
  AsyncPhotoUpload,
  AsyncTakePhoto,
} from '../../../components/Upload/AsyncPhotoUpload'
import { UploadButton } from '../../../components/Upload/Upload'
import { GetJobPhotosQuery } from '../../../generated/user/graphql'
import useIsMobile from '../../../hooks/useIsMobile'
import { OnResize, useResizeObserver } from '../../../hooks/useResizeObserver'
import {
  StateSetter,
  useModalState,
  useStrictContext,
} from '../../../utils/react-utils'
import { GET_JOB_PHOTOS_QUERY } from '../EstimatesFlow.gql'
import {
  EstimateEditContext,
  EstimatePhoto,
  EstimatesContext,
} from '../estimatesFlowUtils'

const MOBILE_NUM_JOB_THUMBNAILS_ACROSS = 3
const NON_MOBILE_NUM_JOB_THUMBNAILS_ACROSS = 4
const JOB_THUMBNAIL_SPACE = 2

type FromJobImagePickerProps = {
  jobPhotos?: GetJobPhotosQuery['photoLinks']
  selectedJobPhotoList: EstimatePhoto[]
  setSelectedJobPhotoList: StateSetter<EstimatePhoto[]>
}

const FromJobImagePicker = React.memo<FromJobImagePickerProps>(
  ({ jobPhotos, selectedJobPhotoList, setSelectedJobPhotoList }) => {
    const isMobile = useIsMobile()

    const containerRef = useRef<HTMLDivElement>(null)

    const [containerWidth, setContainerWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setContainerWidth(width)
    }, [])

    useResizeObserver(containerRef, onResize)

    const thumbnailSize = useMemo(() => {
      const n = isMobile
        ? MOBILE_NUM_JOB_THUMBNAILS_ACROSS
        : NON_MOBILE_NUM_JOB_THUMBNAILS_ACROSS

      // If "F" is the width of the thumbnails, W is the width of the container, S is the space between, and n is the
      // number of thumbnails, the formula is: "Fn + S(n - 1) = W". Solve for "F" and you get this.
      return Math.max(
        Math.floor((containerWidth - (n - 1) * JOB_THUMBNAIL_SPACE) / n),
        0,
      )
    }, [containerWidth, isMobile])

    const photoGuidToIndex = useMemo(() => {
      return R.fromPairs(
        selectedJobPhotoList.map((photo, i) => [photo.photoGuid, i]),
      )
    }, [selectedJobPhotoList])

    const onPhotoClick = useCallback(
      (photo: EstimatePhoto) => {
        const photoIndex = photoGuidToIndex[photo.photoGuid]
        if (isNullish(photoIndex)) {
          setSelectedJobPhotoList(prev => [
            ...prev,
            { ...photo, fromJob: true },
          ])
        } else {
          setSelectedJobPhotoList(prev =>
            prev.filter(prevPhoto => prevPhoto.photoGuid !== photo.photoGuid),
          )
        }
      },
      [photoGuidToIndex, setSelectedJobPhotoList],
    )

    return (
      <div className="w-full" ref={containerRef}>
        {jobPhotos?.length ? (
          <div
            className={classNames(
              'flex max-h-[50vh] flex-row flex-wrap overflow-auto',
              isMobile ? 'mr-[-1rem]' : 'mr-[-1.5rem]',
            )}
            style={{
              marginLeft: `-${JOB_THUMBNAIL_SPACE}px`,
              marginBottom: `-${JOB_THUMBNAIL_SPACE}px`,
            }}
          >
            {jobPhotos.map(({ photo }) => {
              const photoIndex = photoGuidToIndex[photo.photoGuid]
              return (
                <div
                  key={photo.photoGuid}
                  className="relative rounded-sm"
                  style={{
                    marginLeft: `${JOB_THUMBNAIL_SPACE}px`,
                    marginBottom: `${JOB_THUMBNAIL_SPACE}px`,
                    width: `${thumbnailSize}px`,
                    height: `${thumbnailSize}px`,
                  }}
                  onClick={() => onPhotoClick(photo)}
                >
                  <img
                    src={photo.cdnUrl}
                    alt={photo.cdnUrl}
                    className="h-full max-h-full w-full max-w-full object-cover"
                  />
                  <div
                    className={classNames(
                      'absolute right-1 top-1 flex h-5 w-5 items-center justify-center rounded-full border border-solid border-bz-gray-100 text-xs font-semibold text-bz-gray-100 transition-colors',
                      isNullish(photoIndex)
                        ? 'bg-[#FFFFFF66]'
                        : 'bg-bz-primary',
                    )}
                  >
                    {isNullish(photoIndex) ? null : photoIndex + 1}
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div>This job has no photos</div>
        )}
      </div>
    )
  },
)

type PhotoUploadModalProps = {
  onClose: () => void
  onJobPhotosAdded: (photos: EstimatePhoto[]) => void
  onPhotoUploadChange: (data: AsyncPhotoData) => void
}

export const PhotoUploadModal = React.memo<PhotoUploadModalProps>(
  ({
    onClose,
    onJobPhotosAdded,
    onPhotoUploadChange: onPhotoUploadChangeExternal,
  }) => {
    const { jobGuid } = useStrictContext(EstimatesContext)

    const { photoRecords } = useStrictContext(EstimateEditContext)

    const [fromJobPickerOpen, openFromJobPicker, closeFromJobPicker] =
      useModalState()

    const [{ data: jobPhotos, fetching: fetchingJobPhotos }] = useQuery({
      query: GET_JOB_PHOTOS_QUERY,
      variables: {
        jobGuid,
      },
    })
    const [selectedJobPhotoList, setSelectedJobPhotoList] = useState<
      EstimatePhoto[]
    >([])

    const onJobPhotoAddSave = useCallback(() => {
      onJobPhotosAdded(selectedJobPhotoList)
      onClose()
    }, [onClose, onJobPhotosAdded, selectedJobPhotoList])

    const photoRecordMap = useMemo(() => {
      const photoRecordMap: Record<Guid, true> = {}
      for (const photo of photoRecords) {
        photoRecordMap[photo.photoGuid] = true
      }
      return photoRecordMap
    }, [photoRecords])

    const dedupedJobPhotoLinks = useMemo(
      () =>
        jobPhotos?.photoLinks.filter(
          photo => !photoRecordMap[photo.photo.photoGuid],
        ),
      [jobPhotos?.photoLinks, photoRecordMap],
    )

    const onPhotoUploadChange = useCallback<typeof onPhotoUploadChangeExternal>(
      (...args) => {
        onPhotoUploadChangeExternal(...args)
        onClose()
      },
      [onClose, onPhotoUploadChangeExternal],
    )

    return (
      <OnsiteBasicModal
        headerBordered={!fromJobPickerOpen}
        header={fromJobPickerOpen ? 'Job photos' : 'Add photos'}
        onClose={onClose}
        onBack={fromJobPickerOpen ? closeFromJobPicker : undefined}
        footer={
          fromJobPickerOpen ? (
            <OnsiteModalFooter
              onCancel={closeFromJobPicker}
              onSubmit={onJobPhotoAddSave}
              submitDisabled={selectedJobPhotoList.length === 0}
              submitText={
                selectedJobPhotoList.length
                  ? `Add ${selectedJobPhotoList.length} ${toPlural(
                      selectedJobPhotoList.length,
                      'Photo',
                    )}`
                  : 'Add Photos'
              }
            />
          ) : undefined
        }
      >
        {fromJobPickerOpen ? (
          fetchingJobPhotos ? (
            <LoadingSpinner />
          ) : (
            <FromJobImagePicker
              jobPhotos={dedupedJobPhotoLinks}
              selectedJobPhotoList={selectedJobPhotoList}
              setSelectedJobPhotoList={setSelectedJobPhotoList}
            />
          )
        ) : (
          <div className="space-y-3">
            <AsyncPhotoUpload onPhotoUploadChange={onPhotoUploadChange} />
            {!!dedupedJobPhotoLinks?.length && (
              <UploadButton
                icon={<FontAwesomeIcon icon={faWrench} />}
                onClick={openFromJobPicker}
              >
                Choose from job
              </UploadButton>
            )}
            <AsyncTakePhoto onPhotoUploadChange={onPhotoUploadChange} />
          </div>
        )}
      </OnsiteBasicModal>
    )
  },
)
