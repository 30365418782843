export const AttributionLinkingStrategyValues = [
  'NONE',
  'REQUIRE_CONTACT_GUID',
  'REQUIRE_USER_GUID',
  'REQUIRE_JOB_GUID',
  'REQUIRE_JOB_AND_USER_GUID',
  'REQUIRE_JOB_GUID_AND_OPTIONAL_USER_GUID',
  'REQUIRE_ATTRIBUTION_DESCRIPTION',
] as const

export type AttributionLinkingStrategy = (typeof AttributionLinkingStrategyValues)[number]
