import { TimeZoneId } from '@breezy/shared'
import { useQuery } from 'urql'
import { FETCH_ANON_COMPANY_TIME_ZONE } from './useFetchCompanyTimeZone.anon-gql'

export const useFetchCompanyTimezone = (companyGuid: string) => {
  const [{ data, fetching: loading }] = useQuery({
    query: FETCH_ANON_COMPANY_TIME_ZONE,
    variables: {
      companyGuid,
    },
  })

  return {
    timezone: data?.companies[0]?.timezone as TimeZoneId | undefined,
    loading,
  }
}
