import {
  BzDateFns,
  PermissionV2,
  PrequalRecord,
  formatPrequalAmount,
} from '@breezy/shared'
import { Divider } from 'antd'
import React from 'react'
import CopyToClipboard from '../../../elements/CopyToClipboard/CopyToClipboard'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { Link } from '../../../elements/Link/Link'
import Switch from '../../../elements/Switch/Switch'
import { Authorized } from '../../Permissions/Authorized/Authorized'

import { useIsProduction } from '../../../hooks/useIsProduction'
import {
  useExpectedCompanyTimeZoneId,
  useIsImpersonating,
} from '../../../providers/PrincipalUser'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'

import { useQuery } from 'urql'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { gql } from '../../../generated'
import PrequalStatusTag from '../../PrequalStatusTag/PrequalStatusTag'
import DevToolPrequalSimulator from '../DevToolPrequalSimulator/DevToolPrequalSimulator'
const PREQUAL_CONTACT_NAME_QUERY = gql(/* GraphQL */ `
  query FetchPrequalRecord($where: WisetackPrequalRecordsBoolExp!) {
    wisetackPrequalRecords(where: $where) {
      contact {
        fullName
      }
    }
  }
`)

type PrequalDetailsModalProps = {
  prequalRecord: PrequalRecord
  onClose: () => void
}

const PrequalDetailsModal = React.memo<PrequalDetailsModalProps>(
  ({ prequalRecord, onClose }) => {
    const isImpersonating = useIsImpersonating()
    const isProduction = useIsProduction()
    const tzId = useExpectedCompanyTimeZoneId()

    const prequalContactNameQuery = useQuery({
      query: PREQUAL_CONTACT_NAME_QUERY,
      variables: {
        where: {
          wisetackPrequalRecordGuid: {
            _eq: prequalRecord.prequalRecordGuid,
          },
        },
      },
    })

    return (
      <OnsiteBasicModal
        header="Pre-qualification Details"
        open
        onClose={onClose}
        footer={null}
      >
        <div className="grid grid-cols-2 gap-x-2">
          <GqlQueryLoader
            query={prequalContactNameQuery}
            render={data => {
              return (
                <Column
                  label="Customer Name"
                  value={
                    data.wisetackPrequalRecords[0]?.contact?.fullName ?? (
                      <EmDash />
                    )
                  }
                />
              )
            }}
          />
          <Column
            label="Application Link"
            value={
              <CopyToClipboard
                label="Copy link"
                payload={prequalRecord.prequalLink}
                isUrl
              />
            }
          />
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          <Column
            label="Pre-qualified Amount"
            value={
              prequalRecord.maxQualifiedAmountUsc ? (
                formatPrequalAmount(prequalRecord)
              ) : (
                <EmDash />
              )
            }
          />
          <Column
            label="Status"
            value={
              <Switch value={prequalRecord.status}>
                {{
                  DECLINED: () => (
                    <>
                      <p>
                        <PrequalStatusTag status={prequalRecord.status} />
                        <br />
                        <br />
                        <span className="text-bz-gray-700">
                          The pre-qualification application was declined. To
                          continue with financing, try re-applying for prequal
                          with a different contact on the account.
                        </span>
                      </p>
                      <p>
                        <span className="text-bz-gray-700">
                          To create a new contact, navigate to the{' '}
                          <Link to={`/accounts/${prequalRecord.accountGuid}`}>
                            Account details page
                          </Link>{' '}
                          and add a new contact to the account.
                        </span>
                      </p>
                    </>
                  ),
                  default: () => (
                    <PrequalStatusTag status={prequalRecord.status} />
                  ),
                }}
              </Switch>
            }
          />
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          <Column
            label="Application Start Date"
            value={BzDateFns.format(
              BzDateFns.parseISO(prequalRecord.createdAt, tzId),
              'MMM dd, yyyy',
            )}
          />
          <Column
            label="Expiration Date"
            value={
              prequalRecord.expiredAt ? (
                BzDateFns.format(
                  BzDateFns.parseISO(prequalRecord.expiredAt, tzId),
                  'MMM dd, yyyy',
                )
              ) : (
                <EmDash />
              )
            }
          />
          <Column
            label="Date of Last Update"
            value={BzDateFns.format(
              BzDateFns.parseISO(prequalRecord.updatedAt, tzId),
              'MMM dd, yyyy',
            )}
          />
        </div>
        {!isProduction && (
          <Authorized
            to={PermissionV2.LOAN_SIMULATOR}
            overrideAuthorized={isImpersonating}
          >
            <DevToolPrequalSimulator
              prequalRecordGuid={prequalRecord.prequalRecordGuid}
            />
          </Authorized>
        )}
      </OnsiteBasicModal>
    )
  },
)

const Label = React.memo<React.PropsWithChildren>(({ children }) => {
  return (
    <div className="text-sm font-semibold text-bz-gray-900">{children}</div>
  )
})

const Column = React.memo<{ label: string; value: React.ReactNode }>(
  ({ label, value }) => {
    return (
      <div className="flex flex-col gap-1">
        <Label>{label}</Label>
        {<div className="text-sm">{value}</div>}
      </div>
    )
  },
)

export default PrequalDetailsModal
