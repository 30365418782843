import { VisitViewModel } from '@breezy/shared'
import { VisitMiniCard } from '../../../components/Visits/VisitMiniCard'

type ReadOnlyVisitsSectionProps = {
  visits: VisitViewModel[]
}

const inRowsOfTwo = (visits: VisitViewModel[]) => {
  return (
    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
      {visits.map((visit, index) => (
        <VisitMiniCard
          dataTestId={`visit-mini-card-${index}`}
          key={visit.visitGuid}
          visit={visit}
          visitIndex={index}
        />
      ))}
    </div>
  )
}

export const ReadOnlyVisitsSection = ({
  visits,
}: ReadOnlyVisitsSectionProps) => (
  <div className="flex flex-col gap-y-6">
    <div>
      <div className="mb-1 text-base font-semibold text-bz-text">
        Plan Visits
      </div>
      <div className="letter-spacing-[-0.14px] text-[14px] leading-[22px] text-bz-text-tertiary">
        Visits can have a custom name, date, and linked equipment for consistent
        and accurate service.
      </div>
    </div>
    <div className="min-h-[56px] rounded-2xl bg-[#fafafa] p-3">
      {inRowsOfTwo(visits)}
    </div>
  </div>
)
