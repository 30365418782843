import { z } from 'zod'
import { AsyncFn, BzDateFns, TimeZoneId, convertDataToCsvRows, formatMoney, formatUsc } from '../../common'
import { isoDateStringSchema, uscSchema } from '../../contracts/_common'
import { ForCompany } from '../Company/Company'
import { JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS, JobClassSchema, jobClassDisplayNames } from '../Job'

export const TechnicianPerformanceReportRowSchema = z.object({
  // The technician display name
  technicianName: z.string(),

  // The job display id e.g #12345
  jobNumber: z.string(),

  // The date the job was created, job.created_at
  jobCreatedAt: isoDateStringSchema,

  // The date the job was completed, job.work_completed_at
  jobWorkCompletedAt: isoDateStringSchema,

  // The job type name
  jobType: z.string(),

  jobClass: JobClassSchema,

  // The total amount of fully-paid invoices for the job in USD
  totalAmountInvoicedUsd: z.number(),

  // The total amount paid for fully-paid invoices for the job in USD
  totalPaidAmountUsd: z.number(),

  // The total amount invoiced prior to discounts and taxes for the job in USD
  totalSubtotalAmountUsd: z.number(),

  // The total amount discounted for the job in USD
  totalDiscountedAmountUsd: z.number(),

  // The total amount of taxes for the job in USD
  totalTaxAmountUsd: z.number(),

  // The revenue sold attributed to the technician for the job in USC
  revenueSoldUsc: uscSchema,

  // The revenue earned attributed to the technician for the job in USC
  revenueEarnedUsc: uscSchema,

  // The bonus amount awarded to the technician for the job
  bonusUsc: uscSchema,

  // The commission amount awarded to the technician for the job
  salesCommissionUsc: uscSchema,

  // The theshold on the job type for it to be considered a converted opportunity in USC
  opportunityConversionThresholdUsc: uscSchema,

  // The account display name for the job
  accountName: z.string(),

  // The service address for the job
  serviceAddress: z.string(),

  // Whether the job was marked as a hotlead or not
  isHotLead: z.boolean(),

  // Whether the job was marked as an opportunity or not
  isOpportunity: z.boolean(),

  // Whether the job opportunity was converted or not
  isConvertedOpportunity: z.boolean(),

  // Whether the job was for a membership member
  isMembershipMember: z.boolean(),

  // Whether there was an opportunity to sell a membership during the job
  isMembershipOpportunity: z.boolean(),

  // Whether a membership was sold during the job
  isMembershipSold: z.boolean(),

  // The lead source for the job
  leadSource: z.string(),

  // Whether the technician turned over the job
  technicianTurnOver: z.boolean(),
})

export type TechnicianPerformanceReportRow = z.infer<typeof TechnicianPerformanceReportRowSchema>

export const TechnicianPerformanceReportAggregateRowSchema = z.object({
  totalInvoicedUsd: z.number(),
  totalRevenueSoldUsc: uscSchema,
  totalRevenueEarnedUsc: uscSchema,
  totalBonusUsc: uscSchema,
  totalSalesCommissionUsc: uscSchema,
})

export type TechnicianPerformanceReportAggregateRow = z.infer<typeof TechnicianPerformanceReportAggregateRowSchema>

export type TechnicianPerformanceReport = {
  rows: TechnicianPerformanceReportRow[]
}

const TechnicianPerformanceCsvRowHeaders = [
  'Account',
  'Job Id',
  'Team Member',
  'Created On Date',
  'Work Completion Date',
  'Job Type',
  'Job Class',
  'Lead Source',
  'Sold Revenue Attribution $',
  'Earned Revenue Attribution $',
  'Bonus $',
  'Commission $',
  'Is Hot Lead?',
  'Is Opportunity?',
  'Did Convert?',
  'Conversion Threshold $',
  'Is Membership Opportunity?',
  'Was Membership Sold?',
  'Did turn the job over?',
  'Total Invoiced $',
  'Subtotal $',
  'Discount $',
  'Tax $',
  'Paid $',
] as const

export const TechnicianPerformanceCsvRowSchema = z.object({
  // Account: The account display name for the job
  accountName: z.string(),

  // Job Id: The job display id e.g #12345
  jobNumber: z.string(),

  // Team Member: The name of the technician/user
  teamMember: z.string(),

  // Created On Date: The formatted date when the job was created
  createdOnDate: z.string(),

  // Work Completion Date: The formatted date when the job work was completed
  workCompletionDate: z.string(),

  // Job Type: The job type display name
  jobType: z.string(),

  // Job Class: The job class display name
  jobClass: z.string(),

  // Lead Source: The lead source for the job
  leadSource: z.string(),

  // Sold Revenue Attribution $: Formatted USD for the sold revenue attribution
  soldRevenueAttribution: z.string(),

  // Earned Revenue Attribution $: Formatted USD for the earned revenue attribution
  earnedRevenueAttribution: z.string(),

  // Bonus $: Formatted USD for the bonus
  bonus: z.string(),

  // Commission $: Formatted USD for the commission
  commission: z.string(),

  // Is Hot Lead?: The hot lead status for the job (Yes/No)
  isHotLead: z.string(),

  // Is Opportunity?: The opportunity status for the job (Yes/No)
  isOpportunity: z.string(),

  // Did Convert?: The opportunity status for the job (Yes/No)
  didConvert: z.string(),

  // Conversion Threshold $: The threshold for a job to be considered a converted opportunity in USD
  conversionThreshold: z.string(),

  // Is Membership Opportunity?: Whether it was marked there was an opportunity to sell a membership during the job (Yes/No)
  isMembershipOpportunity: z.string(),

  // Is Membership Sold?: Whether a membership was sold during the job (Yes/No)
  isMembershipSold: z.string(),

  // Did turn the job over?: Whether the technician turned over the job (Yes/No)
  didTurnJobOver: z.string(),

  // Total Invoiced $: Formatted USD for the total invoiced
  totalInvoiced: z.string(),

  // Subtotal $: Formatted USD for the total subtotal
  subtotal: z.string(),

  // Discount $: Formatted USD for the total discount
  discount: z.string(),

  // Tax $: Formatted USD for the total tax
  tax: z.string(),

  // Paid $: Formatted USD for the total paid
  paid: z.string(),
})

export type TechnicianPerformanceCsvRow = z.infer<typeof TechnicianPerformanceCsvRowSchema>

export const TechnicianPerformanceReportRequestSchema = z.object({
  dateRange: z.object({
    startAt: isoDateStringSchema,
    endAt: isoDateStringSchema,
  }),
})

export type TechnicianPerformanceReportRequest = z.infer<typeof TechnicianPerformanceReportRequestSchema>
export type TechnicianPerformanceReportGenerator = AsyncFn<
  ForCompany<TechnicianPerformanceReportRequest>,
  TechnicianPerformanceReport[]
>

export const toTechPerformanceReport = (rows: TechnicianPerformanceReportRow[]): TechnicianPerformanceReport => {
  return {
    rows,
  }
}

const getIsConvertedOpportunity = (row: TechnicianPerformanceReportRow): string =>
  !JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS.includes(row.jobClass)
    ? 'N/A'
    : row.isConvertedOpportunity
    ? 'Yes'
    : 'No'

// NOTE: Order of these fields matters. Make sure it matches the ordering of the TechnicianPerformanceCsvRowHeaders
const toTechnicianPerformanceCsvRow = (
  techPerformanceReportRow: TechnicianPerformanceReportRow,
  tzId: TimeZoneId,
): TechnicianPerformanceCsvRow => {
  return {
    accountName: techPerformanceReportRow.accountName,
    jobNumber: `#${techPerformanceReportRow.jobNumber}`,
    teamMember: techPerformanceReportRow.technicianName,
    createdOnDate: BzDateFns.formatFromISO(techPerformanceReportRow.jobCreatedAt, 'MM/dd/yyyy', tzId),
    workCompletionDate: BzDateFns.formatFromISO(techPerformanceReportRow.jobWorkCompletedAt, 'MM/dd/yyyy', tzId),
    jobType: techPerformanceReportRow.jobType,
    jobClass: jobClassDisplayNames[techPerformanceReportRow.jobClass],
    leadSource: techPerformanceReportRow.leadSource,
    soldRevenueAttribution: formatUsc(techPerformanceReportRow.revenueSoldUsc),
    earnedRevenueAttribution: formatUsc(techPerformanceReportRow.revenueEarnedUsc),
    bonus: formatUsc(techPerformanceReportRow.bonusUsc),
    commission: formatUsc(techPerformanceReportRow.salesCommissionUsc),
    isHotLead: techPerformanceReportRow.isHotLead ? 'Yes' : 'No',
    isOpportunity: techPerformanceReportRow.isOpportunity ? 'Yes' : 'No',
    didConvert: getIsConvertedOpportunity(techPerformanceReportRow),
    conversionThreshold: formatUsc(techPerformanceReportRow.opportunityConversionThresholdUsc),
    isMembershipOpportunity: techPerformanceReportRow.isMembershipOpportunity ? 'Yes' : 'No',
    isMembershipSold: techPerformanceReportRow.isMembershipSold ? 'Yes' : 'No',
    didTurnJobOver: techPerformanceReportRow.technicianTurnOver ? 'Yes' : 'No',
    totalInvoiced: formatMoney(techPerformanceReportRow.totalAmountInvoicedUsd),
    subtotal: formatMoney(techPerformanceReportRow.totalSubtotalAmountUsd),
    discount: formatMoney(techPerformanceReportRow.totalDiscountedAmountUsd),
    tax: formatMoney(techPerformanceReportRow.totalTaxAmountUsd),
    paid: formatMoney(techPerformanceReportRow.totalPaidAmountUsd),
  }
}

type CsvFileContent = string

export const generateTechnicianPerformanceCsv = (
  technicianPerformanceReports: TechnicianPerformanceReport[],
  tzId: TimeZoneId,
): CsvFileContent => {
  const csvHeaderRow = TechnicianPerformanceCsvRowHeaders.join(',')

  let csvContent = `${csvHeaderRow}\r\n`

  for (const report of technicianPerformanceReports) {
    if (report.rows.length === 0) continue
    // Convert the report rows to CSV rows
    const csvRows = convertDataToCsvRows<TechnicianPerformanceCsvRow>(
      report.rows.map(r => toTechnicianPerformanceCsvRow(r, tzId)),
    )

    // Create the job row content
    const csvFileContent = `${csvRows}`

    csvContent += `${csvFileContent}\r\n`
  }

  return csvContent
}
