import { copyToClipboard } from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useMessage } from '../../../../utils/antd-utils'

export type TagColumnProps = {
  icon: IconProp
  title: string
  refId?: string
  headerCssClass: string
  titleCssClass?: string
  extra?: React.ReactNode
  children: React.ReactNode
}

const TagColumn = ({
  icon,
  title,
  headerCssClass,
  titleCssClass = 'text-white',
  refId,
  extra,
  children,
}: TagColumnProps) => {
  const message = useMessage()
  const [justCopied, setJustCopied] = useState(false)

  useEffect(() => {
    let copyTimeout: ReturnType<typeof setTimeout> | null = null

    if (justCopied) {
      copyTimeout = setTimeout(() => {
        setJustCopied(false)
      }, 2000)
    }

    return () => {
      copyTimeout && clearTimeout(copyTimeout)
    }
  }, [justCopied])

  return (
    <div className="w-full">
      <div
        className={`space-x flex w-full items-center justify-between rounded-t-md px-6 py-4 ${headerCssClass}`}
      >
        <div className="flex items-center space-x-3">
          <FontAwesomeIcon
            className={`text-2xl ${titleCssClass}`}
            icon={icon}
          />
          <div className={`text-xl ${titleCssClass}`}>{title}</div>
        </div>
        {refId && (
          <div
            className={`flex items-center space-x-3 hover:cursor-pointer `}
            onClick={() => {
              copyToClipboard(refId).then(() => {
                setJustCopied(true)
                message.success('Reference ID copied to clipboard', 2)
              })
            }}
          >
            <span
              className={`${
                justCopied ? 'bg-bz-green-500' : 'bg-none'
              } h-4 w-4 rounded-full text-white`}
            >
              <FontAwesomeIcon
                className="h-4 w-4"
                icon={justCopied ? faCheckCircle : faCopy}
              />
            </span>
            <div className="text-white">{refId}</div>
          </div>
        )}
        {extra}
      </div>
      <div className="px-4 py-5 lg:px-6 lg:py-6">{children}</div>
    </div>
  )
}

export default TagColumn
