import tailwindContainerQueriesPlugin from '@tailwindcss/container-queries'
import type { Config } from 'tailwindcss'
import plugin from 'tailwindcss/plugin'

export default {
  content: ['./index.html', './src/**/*.{js,jsx,ts,tsx}'],
  corePlugins: {
    preflight: false,
  },
  theme: {
    extend: {
      colors: {
        'daybreak-blue': {
          100: '#E6F7FF',
          200: '#BAE7FF',
          300: '#91D5FF',
          400: '#69c0ff',
          500: '#40a9ff',
          600: '#1890FF',
          700: '#096DD9',
          800: '#0050B3',
          900: '#003A8C',
          1000: '#002766',
        },
        'geek-blue': {
          100: '#F0F5FF',
          200: '#D6E4FF',
          300: '#ADC6FF',
          400: '#85A5FF',
          500: '#597EF7',
          600: '#2F54EB',
          700: '#1D39C4',
          800: '#10239E',
          900: '#061178',
          1000: '#030852',
        },
        'bz-blue': {
          100: '#E6F4FF',
          200: '#BAE0FF',
          300: '#91CAFF',
          400: '#69B1FF',
          500: '#4096FF',
          600: '#1677FF',
          700: '#0958D9',
          800: '#003EB3',
          900: '#002C8C',
          1000: '#001D66',
        },
        'bz-cyan': {
          100: '#e6fffb',
          200: '#b5f5ec',
          300: '#87e8de',
          400: '#5cdbd3',
          500: '#36cfc9',
          600: '#13c2c2',
          700: '#08979c',
          800: '#006d75',
          900: '#00474f',
          1000: '#002329',
        },
        'bz-orange': {
          100: '#fff7e6',
          200: '#ffe7ba',
          300: '#ffd591',
          400: '#ffc069',
          500: '#ffa940',
          600: '#fa8c16',
          700: '#d46b08',
          800: '#ad4e00',
          900: '#873800',
          1000: '#612500',
        },
        'bz-green': {
          100: '#F6FFED',
          200: '#D9F7BE',
          300: '#B7EB8F',
          400: '#95DE64',
          500: '#73D13D',
          600: '#52C41A',
          700: '#389E0D',
          800: '#237804',
          900: '#135200',
          1000: '#092B00',
        },
        'bz-yellow': {
          100: '#FEFFE6',
          200: '#FFFFB8',
          300: '#FFFB8F',
          400: '#FFF566',
          500: '#FFEC3D',
          600: '#FADB14',
          700: '#D4B106',
          800: '#AD8B00',
          900: '#876800',
          1000: '#614700',
        },
        'bz-lime': {
          100: '#FCFFE6',
          200: '#F4FFB8',
          300: '#EAFF8F',
          400: '#D3F261',
          500: '#BAE637',
          600: '#A0D911',
          700: '#7CB305',
          800: '#5B8C00',
          900: '#3F6600',
          1000: '#254000',
        },
        'bz-gray': {
          100: '#FFFFFF',
          200: '#FAFAFA',
          300: '#F5F5F5',
          400: '#F0F0F0',
          500: '#D9D9D9',
          600: '#BFBFBF',
          700: '#8C8C8C',
          800: '#595959',
          900: '#434343',
          1000: '#262626',
        },
        'bz-purple': {
          100: '#F9F0FF',
          200: '#EFDBFF',
          300: '#D3ADF7',
          400: '#B37FEB',
          500: '#9254DE',
          600: '#722ED1',
          700: '#531DAB',
          800: '#391085',
          900: '#22075E',
          1000: '#120338',
        },
        'bz-magenta': {
          100: '#FFF0F6',
          200: '#FFD6E7',
          300: '#FFADD2',
          400: '#FF85C0',
          500: '#F759AB',
          600: '#EB2F96',
          700: '#C41D7F',
          800: '#9E1068',
          900: '#780650',
          1000: '#520339',
        },
        'bz-red': {
          100: '#FFF1F0',
          200: '#FFCCC7',
          300: '#FFA39E',
          400: '#FF7875',
          500: '#FF4D4F',
          600: '#F5222D',
          700: '#CF1322',
          800: '#A8071A',
          900: '#820014',
          1000: '#5C0011',
        },
        'bz-volcano': {
          100: '#FFF2E8',
          200: '#FFD8BF',
          300: '#FFBB96',
          400: '#FF9C6E',
          500: '#FF7A45',
          600: '#FA541C',
          700: '#D4380D',
          800: '#AD2102',
          900: '#871400',
          1000: '#610B00',
        },
        bz: {
          primary: '#1677FF',
          'primary-bg': '#E6F4FF',
          'primary-border': '#91CAFF',
          'primary-border-hover': '#69B1FF',
          'primary-active': '#0958d9',
          'primary-hover': '#4096FF',
          text: 'rgba(0, 0, 0, 0.88)',
          'text-secondary': '#595959',
          'text-tertiary': '#8C8C8C',
          'text-quaternary': 'rgba(0, 0, 0, 0.25)',
          'text-description': '#8C8C8C',
          'text-hover': '#F0F0F0',
          border: '#D9D9D9',
          'border-secondary': '#F0F0F0',
          error: '#FF4D4F',
          fill: '#D9D9D9',
          'fill-secondary': 'rgba(0,0,0, 0.06)',
          'fill-tertiary': 'rgba(0,0,0, 0.04)',
          'fill-quaternary': '#FAFAFA',
          link: '#1677FF',
          mask: 'rgba(0, 0, 0, 0.45)',
        },
      },
      keyframes: {
        'fade-in': {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      animation: {
        'fade-in-100': 'fade-in 0.1s ease-in-out',
      },
      height: {
        'screen-minus-28': 'calc(100vh - 7rem)',
      },
      boxShadow: {
        'bz-bottom-100': '0 1px 2px rgba(0, 0, 0, 0.1)',
        secondary:
          '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
      },
      width: {
        '265px': '265px',
      },
    },
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.no-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
        '.no-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      })
    }),
    tailwindContainerQueriesPlugin,
  ],
  important: true,
} satisfies Config
