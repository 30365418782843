import { z } from 'zod'
import { HtmlString, IsoDateString, LocalDateString, TimeZoneId } from '../common'

// See https://github.com/trpc/trpc/issues/3602 -- There is a limitation with branding
//

const createBrandedString = <T>() => {
  const schema = z.string().transform(data => data as unknown as T)
  return schema as unknown as Omit<typeof schema, '_input'> & {
    _input: T
  }
}

export type JsonString = string

export const guidSchema = z.string().regex(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
export const localDateSchema = z.string().transform(data => data as LocalDateString)
export const isoDateStringSchema = z.string().transform(data => data as IsoDateString)
export const timeZoneIdSchema = z.string().transform(data => data as TimeZoneId)

export const PersonSchema = z.object({
  id: guidSchema,
  firstName: z.string(),
  lastName: z.string(),
})

export const htmlStringSchema = createBrandedString<HtmlString>()
export const uscSchema = z.number().int()
