export const Placeholder = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      flexDirection: 'column',
    }}
  >
    <h1>{children}</h1>
    <div
      style={{
        fontStyle: 'italic',
        fontSize: '30px',
        color: '#ddd',
        fontWeight: '600',
      }}
    >
      Preview Unavailable
    </div>
  </div>
)
