import { App } from 'antd'

// https://ant.design/docs/react/customize-theme#basic-usage
export const useMessage = () => {
  const { message } = App.useApp()

  return message
}

// https://ant.design/docs/react/customize-theme#basic-usage
export const useModal = () => {
  const { modal } = App.useApp()

  return modal
}
