import { UNAUTH_USER_KEY } from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import React, { useMemo } from 'react'
import { getConfig } from '../config'
import { usePrincipalUser } from './PrincipalUser'

const { launchDarklyClientId, launchDarklyTargetingEnabled } = getConfig()

export const LaunchDarklyWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const principalUser = usePrincipalUser()

    const App = useMemo(() => {
      const App = () => <>{children}</>

      return withLDProvider({
        clientSideID: launchDarklyClientId,
        context: {
          kind: 'multi',
          user: {
            key: launchDarklyTargetingEnabled
              ? principalUser.principal?.userGuid ?? 'Unknown User'
              : 'No Targeting',
          },
          company: {
            key: launchDarklyTargetingEnabled
              ? principalUser.principal?.company?.companyGuid ??
                'Unknown Company'
              : 'No Targeting',
          },
        },
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
        options: {
          inspectors: [
            {
              type: 'flag-used',
              name: 'dd-inspector',
              method: (key, detail) => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value)
              },
            },
          ],
        },
      })(App)
    }, [children, principalUser.principal])

    return <App />
  },
)

export const NonAuthLaunchDarklyWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const App = useMemo(() => {
      const App = () => <>{children}</>

      return withLDProvider({
        clientSideID: launchDarklyClientId,
        context: {
          kind: 'multi',
          user: {
            key: UNAUTH_USER_KEY, // Using a fixed key for non-authenticated users
          },
        },
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
      })(App)
    }, [children])

    return <App />
  },
)
