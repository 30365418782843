import { Account } from '@breezy/shared'
import { memo, useCallback, useMemo } from 'react'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import AccountSearchAutocomplete from '../../components/ProgressiveJobCreationModal/AccountSearchAutocomplete/AccountSearchAutocomplete'
import { Tag } from '../../components/Tags/Tag'
import { useFetchComprehensiveAccountDetails } from '../../hooks/fetch/useFetchComprehensiveAccountDetails'
import { AccountContactMiniCard } from './AccountContactMiniCard'
import { AccountJobCompletedMiniCard } from './AccountJobCompletedMiniCard'
import { AccountCardHeader } from './components/AccountCardHeader'
import { SectionHeaderText } from './components/SectionHeaderText'
import { WizardColum } from './components/WizardColum'

type Props = {
  selectedAccount: Account
  onAccountSelect: (account: Account | undefined) => void
  fetchComprehensiveAccountQuery: ReturnType<
    typeof useFetchComprehensiveAccountDetails
  >
}

export const SelectAccountPanel = memo<Props>(
  ({ selectedAccount, onAccountSelect, fetchComprehensiveAccountQuery }) => {
    // Primary contact sorted first
    const sortedContacts = useMemo(() => {
      return [...selectedAccount.accountContacts].sort((a, b) => {
        return (b.primary ? 1 : 0) - (a.primary ? 1 : 0)
      })
    }, [selectedAccount.accountContacts])
    const firstContact = useMemo(
      () => (sortedContacts.length > 0 ? sortedContacts[0] : undefined),
      [sortedContacts],
    )

    const handleAccountSelect = useCallback(
      (account: Account) => {
        if (selectedAccount?.accountGuid !== account.accountGuid) {
          onAccountSelect(account)
        }
      },
      [selectedAccount, onAccountSelect],
    )

    const lastCompletedJob = useMemo(() => {
      if (!fetchComprehensiveAccountQuery.data) {
        return undefined
      }
      if (fetchComprehensiveAccountQuery.data.getJobs().length === 0) {
        return undefined
      }
      return fetchComprehensiveAccountQuery.data
        .getJobs()
        .filter(job => job.jobLifecycleStatus.specialStatus === 'Completed')
        .sort((a, b) => {
          return b.jobCreatedAt.compareTo(a.jobCreatedAt)
        })[0]
    }, [fetchComprehensiveAccountQuery.data])

    const sections = useMemo<SectionedSection[]>(() => {
      if (!fetchComprehensiveAccountQuery.data) {
        return [
          <p>An unknown loading error has occurred. Try searching again.</p>,
        ]
      }
      return [
        {
          content: (
            <AccountCardHeader account={fetchComprehensiveAccountQuery.data} />
          ),
        },
        <div className="grid gap-4">
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            {firstContact && (
              <div>
                <h5 className="mb-3 text-base font-semibold">Contacts</h5>
                <AccountContactMiniCard accountContact={firstContact} />
              </div>
            )}
            <div>
              <h5 className="mb-3 text-base font-semibold">Tags</h5>
              <div className="flex flex-wrap gap-y-2">
                {fetchComprehensiveAccountQuery.data.getTags().map(tag => (
                  <Tag tag={tag} tagStyleVersion="v2" key={tag.tagGuid} />
                ))}
              </div>
            </div>
          </div>
          {lastCompletedJob && (
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-base font-semibold">Last Job</h5>
                <AccountJobCompletedMiniCard accountJob={lastCompletedJob} />
              </div>
            </div>
          )}
        </div>,
      ]
    }, [fetchComprehensiveAccountQuery.data, firstContact, lastCompletedJob])

    return (
      <WizardColum>
        <SectionHeaderText text="Select Account" />
        <AccountSearchAutocomplete
          currentAccount={selectedAccount}
          setCurrentAccount={handleAccountSelect}
          showLabel={false}
        />
        <SectionedCard
          heightClassName="min-h-[312px]"
          sections={sections}
          loading={fetchComprehensiveAccountQuery.isLoading}
        />
      </WizardColum>
    )
  },
)
