import { gql } from '../../generated'

export const FIND_ACTIVE_MAINTENANCE_PLAN_AT_ACCOUNT_LOCATION =
  gql(/* GraphQL */ `
    query FindActiveMaintenancePlanAtAccountLocation(
      $companyGuid: uuid!
      $accountGuid: uuid!
      $locationGuid: uuid!
    ) {
      maintenancePlansQueryable(
        where: {
          companyGuid: { _eq: $companyGuid }
          accountGuid: { _eq: $accountGuid }
          locationGuid: { _eq: $locationGuid }
          status: { _eq: "ACTIVE" }
        }
      ) {
        maintenancePlanGuid
        status
        credits
        terminatesAt
        lastVisitedAt
        activatedAt
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
        }
      }
    }
  `)

export const FIND_JOB_TYPE_REVENUE_POTENTIAL = gql(/* GraphQL */ `
  query FindJobTypeRevenuePotential($jobTypeGuid: uuid!) {
    jobTypesByPk(jobTypeGuid: $jobTypeGuid) {
      isOpportunity
      opportunityConversionThresholdUsc
      isPotentialHotLead
      hotLeadMinimumEquipmentAgeYears
    }
  }
`)

export const LINKED_JOB_QUERY = gql(/* GraphQL */ `
  query CreateOrEditJobFormLinkedJobQuery(
    $companyGuid: uuid!
    $jobGuid: uuid!
  ) {
    jobs(
      where: { companyGuid: { _eq: $companyGuid }, jobGuid: { _eq: $jobGuid } }
    ) {
      pointOfContact {
        fullName
      }
      jobType {
        name
      }
      jobLifecycleStatus {
        jobLifecycle {
          name
        }
      }
      photoLinksAggregate {
        aggregate {
          count
        }
      }
      noteLinksAggregate {
        aggregate {
          count
        }
      }
      fileLinksAggregate {
        aggregate {
          count
        }
      }
      estimates(where: { status: { _eq: "ACCEPTED" } }) {
        estimateGuid
        displayId
        estimateOptions(where: { isSelected: { _eq: true } }) {
          isSelected
          totalUsc
        }
      }
    }
  }
`)
