import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

type IconCardProps = {
  icon: IconProp
  cardBorderClassName?: string
  cardBgClassName?: string
  iconBgClassName?: string
  iconColorClassName?: string
  iconSizeClassName?: string
  children: React.ReactNode
}

export const IconCard = React.memo<IconCardProps>(
  ({
    icon,
    cardBorderClassName,
    cardBgClassName,
    iconBgClassName,
    iconColorClassName,
    iconSizeClassName,
    children,
  }) => (
    <div
      className={classNames(
        'flex items-center gap-3 rounded-lg border border-solid px-4 py-3',
        cardBorderClassName,
        cardBgClassName,
      )}
    >
      <div
        className={classNames(
          'flex h-[28px] w-[28px] items-center justify-center rounded-full p-2',
          iconBgClassName,
        )}
      >
        <FontAwesomeIcon
          className={classNames(iconSizeClassName, iconColorClassName)}
          icon={icon}
        />
      </div>
      <div className="flex-1">{children}</div>
    </div>
  ),
)
