import { LocationContact } from '@breezy/shared'
import { Drawer } from 'antd'
import { useWindowSize } from 'react-use'
import { LocationContactUpsertForm } from './LocationContactUpsertForm'

export type LocationContactUpsertDrawerProps = {
  locationGuid: string
  locationContact?: LocationContact
  isOpen: boolean
  onClose: () => void
  onMutate: () => void
}

export const LocationContactUpsertDrawer = ({
  locationGuid,
  locationContact,
  isOpen,
  onClose,
  onMutate,
}: LocationContactUpsertDrawerProps) => {
  const windowSize = useWindowSize()

  return (
    <Drawer
      title={`${locationContact ? 'Edit' : 'New'} Location Contact`}
      onClose={onClose}
      open={isOpen}
      width={
        windowSize.width < 640
          ? windowSize.width
          : Math.min(windowSize.width, 720)
      }
      destroyOnClose
      height={windowSize.width < 640 ? windowSize.height : undefined}
      placement={windowSize.width < 640 ? 'bottom' : 'right'}
    >
      <LocationContactUpsertForm
        locationGuid={locationGuid}
        onCancel={onClose}
        onMutate={onMutate}
        existingLocationContact={locationContact}
        flexRowSpaceX="space-x"
        labelClassName="semibold_14_22 grey9"
      />
    </Drawer>
  )
}
