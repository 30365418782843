import { Divider } from 'antd'
import { m } from '../../../utils/react-utils'
import ContentList from '../../ContentList/ContentList'
import { ContentListItem } from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type SidebarItemProps = {
  readonly sectionLabel: string
  readonly onEdit?: () => void
  readonly contentList: ContentListItem[]
  readonly hideDivider?: boolean
  readonly hideKeys?: boolean
}

const SidebarItem = m<SidebarItemProps>(
  ({ sectionLabel, onEdit, contentList, hideDivider, hideKeys }) => {
    return (
      <div className="mt-2 flex w-full flex-col space-y-2">
        <div className="flex justify-between">
          <div className="semibold_16_24 grey9">{sectionLabel}</div>
          {onEdit && (
            <div
              className="semibold_14_22 blue6 cursor-pointer"
              onClick={onEdit}
            >
              Edit
            </div>
          )}
        </div>
        <ContentList contentList={contentList} hideKeys={hideKeys} />
        {!hideDivider && <Divider />}
      </div>
    )
  },
)

export default SidebarItem
