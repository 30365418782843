import { Guid } from '@breezy/shared'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

const MAX_RECENT_RECORDS_PER_TYPE = 100

type RecordType = 'accounts' | 'jobs'

export const useAddRecentRecord = (type: RecordType, recordGuid: Guid) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value = {} as any, setValue] = useLocalStorage('recent-records', {})

  useEffect(() => {
    if (!value[type]) {
      setValue({
        ...value,
        [type]: [recordGuid],
      })
    } else {
      setValue({
        ...value,
        [type]: new UniqueQueue(
          value[type] as string[],
          MAX_RECENT_RECORDS_PER_TYPE,
        )
          .add(recordGuid)
          .getAll(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useReadRecentRecords = (type: RecordType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value = {} as any] = useLocalStorage('recent-records', {})
  return value[type] ?? []
}

// A LRU unique cache written by ChatGPT
class UniqueQueue {
  private queue: Array<string>
  private maxSize: number

  constructor(elements: string[], maxSize: number) {
    this.queue = [...elements]
    this.maxSize = maxSize
  }

  add(element: string): UniqueQueue {
    let copy = [...this.queue]
    copy = [element, ...copy.filter(el => el !== element)]
    if (copy.length > this.maxSize) {
      copy.pop()
    }
    this.queue = copy

    return this
  }

  // Additional methods for testing
  getAll(): Array<string> {
    return [...this.queue]
  }
}
