import { AppointmentGuid, HtmlString } from '@breezy/shared'
import { Button } from 'antd'
import React, { useCallback, useState } from 'react'
import { HtmlRenderer } from '../../../../../elements/HtmlRenderer/HtmlRenderer'
import { trpc } from '../../../../../hooks/trpc'
import { useMessage } from '../../../../../utils/antd-utils'

type ServiceHistoryProps = {
  appointmentGuid: AppointmentGuid
}
const generatePrompt = (appointmentGuid: AppointmentGuid): string => {
  return `
    Generate a service history for appointment ${appointmentGuid}. 
    Include details about the service performed, any issues encountered, and recommendations for future maintenance.
    Format the message with proper paragraphs, each wrapped in <p> tags.
    Return the message as a JSON object with a single key "message" containing the formatted HTML paragraph. Always use HTML, NEVER use Markdown.
    For example: {"message": "<p>During the service appointment ${appointmentGuid}, we performed the following tasks...</p><p>We encountered the following issues...</p><p>For future maintenance, we recommend...</p>"}
  `
}

export const useGenerateServiceHistory = (appointmentGuid: AppointmentGuid) => {
  const [isGenerating, setIsGenerating] = useState(false)
  const toastMessage = useMessage()
  const generateAIMessage =
    trpc.devTools['devtools:generate-llm-completion'].useMutation()

  const generateServiceHistory = useCallback(async (): Promise<
    HtmlString | undefined
  > => {
    setIsGenerating(true)
    try {
      const prompt = generatePrompt(appointmentGuid)
      const result = await generateAIMessage.mutateAsync(prompt)

      if (result) {
        try {
          const parsedResult = JSON.parse(result)
          if (parsedResult.message) {
            toastMessage.success('Service history generated successfully')
            return parsedResult.message as HtmlString
          } else {
            console.error('Invalid response format:', result)
            toastMessage.error(
              'Failed to generate service history. Please try again.',
            )
          }
        } catch (parseError) {
          console.error('Error parsing AI response:', parseError)
          toastMessage.error(
            'Failed to parse generated service history. Please try again.',
          )
        }
      } else {
        toastMessage.error(
          'Failed to generate service history. Please try again.',
        )
      }
    } catch (error) {
      console.error('Error generating service history:', error)
      toastMessage.error(
        'Failed to generate service history. Please try again.',
      )
    } finally {
      setIsGenerating(false)
    }
  }, [appointmentGuid, generateAIMessage, toastMessage])

  return { generateServiceHistory, isGenerating }
}
const ServiceHistory = React.memo<ServiceHistoryProps>(
  ({ appointmentGuid }) => {
    const [serviceHistoryTranscriptHtml, setServiceHistoryTranscriptHtml] =
      useState<HtmlString>('')
    const { generateServiceHistory, isGenerating } =
      useGenerateServiceHistory(appointmentGuid)

    const handleGenerateServiceHistory = async () => {
      const result = await generateServiceHistory()
      if (result) {
        setServiceHistoryTranscriptHtml(result)
      }
    }

    return (
      <div className="flex min-h-0 flex-col">
        {serviceHistoryTranscriptHtml ? (
          <HtmlRenderer htmlContent={serviceHistoryTranscriptHtml} />
        ) : (
          <Button onClick={handleGenerateServiceHistory} loading={isGenerating}>
            Generate Service History
          </Button>
        )}
      </div>
    )
  },
)

export default ServiceHistory
