import { isNullish } from '@breezy/shared'
import React from 'react'

type SwitchProps<T extends string | number | symbol> = {
  value?: T
  children: {
    [K in T]?: () => React.ReactNode
  } & {
    default: () => React.ReactNode
  }
}

const Switch = React.memo(
  <T extends string | number | symbol>({ value, children }: SwitchProps<T>) => {
    if (isNullish(value)) return <>{children.default()}</>

    const renderFunction = children[value] || children.default

    return <>{renderFunction()}</>
  },
)

export default Switch
