import { z } from 'zod'
import { AsyncFn, LocalDate } from '../../common'
import { guidSchema, localDateSchema } from '../../contracts/_common'
import { Account, createAccountSearchableRecord } from '../Accounts/Account'
import { AddressDtoSchema, BzAddress, IdentifiableAddress } from '../Address/Address'
import { Guid, bzOptional } from '../common-schemas'
import { CompanyGuid, CompanyGuidContainer, ForCompany, ForCompanyUser } from '../Company/Company'
import { InstalledEquipmentSummary } from '../InstalledEquipment/InstalledEquipment'
import { InstalledHvacSystem } from '../InstalledEquipment/InstalledHvacSystem'
import { JobGuid } from '../Job'
import { MaintenancePlanGuid, MaintenancePlanMinimalInfo } from '../MaintenancePlans/MaintenancePlanTypes'
import { LocationSearchableRecord, SearchableEntityCreate, createLocationSearchableRecordId } from '../Search'

export type LocationGuid = Guid

export type LocationGuidContainer = { locationGuid: LocationGuid }

export const LocationPropertyTypeUnknown = 'Unknown' as const

export const KNOWN_LOCATION_PROPERTY_TYPES = [
  'Residential: Single Family',
  'Residential: Multi-Family',
  'Residential: Condo/Townhouse',
  'Residential: Apartment',
  'Residential: Other',
  'Commercial: Office',
  'Commercial: Restaurant',
  'Commercial: Church',
  'Commercial: Other',
  'Other',
  LocationPropertyTypeUnknown,
] as const

// TODO: this is a better type but we need to refactor stuff that this breaks
// export type LocationPropertyType = typeof KNOWN_LOCATION_PROPERTY_TYPES[number]
export type LocationPropertyType = string

export type LocationCommon = {
  locationGuid: LocationGuid
  companyGuid: CompanyGuid
  displayName?: string
  address: IdentifiableAddress
}

export type Location = LocationCommon & {
  estimatedSquareFootage?: number
  estimatedBuildDate?: LocalDate
  propertyType: LocationPropertyType
  municipality?: string
  installedEquipment?: InstalledEquipmentSummary[]
  installedHvacSystems?: InstalledHvacSystem[]
  maintenancePlans?: MaintenancePlanMinimalInfo[]
  maintenancePlanVisits?: {
    maintenancePlanGuid: MaintenancePlanGuid
    maintenancePlanVisitGuid: string
    jobGuid?: JobGuid
  }[]
}

export type LocationDTO = LocationCommon & {
  estimatedSquareFootage?: number
  estimatedBuildDate?: string
  propertyType: LocationPropertyType
  municipality?: string
}

export const UpsertLocationDTOSchema = z.object({
  locationGuid: guidSchema,
  displayName: bzOptional(z.string()),
  address: AddressDtoSchema,
  estimatedSquareFootage: bzOptional(z.number().positive()),
  estimatedBuildDate: bzOptional(localDateSchema),
  propertyType: bzOptional(z.string()),
  municipality: bzOptional(z.string()),
  skipAddressValidation: bzOptional(z.boolean()),
})

export type UpsertLocationDTO = z.infer<typeof UpsertLocationDTOSchema>

export type LocationUpserter = AsyncFn<ForCompanyUser<UpsertLocationDTO>>

export type LocationQuery =
  | { type: 'by-location-guid'; locationGuid: LocationGuid }
  | { type: 'by-property-type'; propertyType: string }
export type LocationQuerier = AsyncFn<ForCompany<LocationQuery>, Location[]>

export const effectiveLocationDisplayName = (location: Pick<Location, 'displayName' | 'address'>) => {
  return location.displayName ?? BzAddress.formatAddressLine1And2Condensed(location.address)
}

export const effectiveLocationLongDisplayName = (location: Pick<Location, 'displayName' | 'address'>) => {
  return [
    BzAddress.formatAddressSingleLine(location.address),
    location.displayName ? `(${location.displayName})` : undefined,
  ]
    .filter(Boolean)
    .join(' ')
}

export const createLocationSearchableRecord = (account: Account): SearchableEntityCreate<Location> => {
  return async location => {
    const accountRecord = await createAccountSearchableRecord(account)
    const recordId = createLocationSearchableRecordId(location.locationGuid)
    return {
      recordId,
      record: {
        ...accountRecord.record,
        objectID: recordId,
        recordType: 'LOCATION',
        locationGuid: location.locationGuid,
        locationDisplayName: location.displayName ?? location.address.line1,
        streetAddress: location.address.line1,
        city: location.address.city,
        state: location.address.stateAbbreviation,
        zipCode: location.address.zipCode,
      } satisfies LocationSearchableRecord,
    }
  }
}

export type LocationSearchableRecordResyncRecordsWriter = AsyncFn<CompanyGuidContainer>
