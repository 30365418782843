import { PageHeader } from '@ant-design/pro-components'
import { InternalNotification } from '@breezy/shared'
import { ConfigProvider, Divider, Empty, List } from 'antd'
import React, { useMemo } from 'react'
import { Notification } from '../../components/Notifications/Notification'
import { useNotifications } from '../../components/Notifications/Notifications'
import { Page } from '../../components/Page/Page'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { getConfig } from '../../config'
import useIsMobile from '../../hooks/useIsMobile'

type NotificationContentProps = {
  data: InternalNotification[]
  refetch: () => Promise<unknown>
}

const NotificationContent = React.memo<NotificationContentProps>(
  ({ data, refetch }) => {
    const [read, unread] = useMemo(() => {
      const read: InternalNotification[] = []
      const unread: InternalNotification[] = []
      for (const notification of data) {
        if (notification.acknowledgedAt) {
          read.push(notification)
        } else {
          unread.push(notification)
        }
      }
      return [read, unread]
    }, [data])
    return (
      <ConfigProvider
        renderEmpty={() => <Empty description="No notifications" />}
      >
        <div>
          {(
            [
              ['Unread', unread],
              ['Read', read],
            ] as const
          ).map(([header, list]) => (
            <React.Fragment key={header}>
              <Divider orientation="left" orientationMargin={0}>
                {header}
              </Divider>
              <List
                dataSource={list}
                renderItem={notification => (
                  <List.Item>
                    <Notification
                      key={notification.internalNotificationGuid}
                      showDate
                      notification={notification}
                      refetch={refetch}
                    />
                  </List.Item>
                )}
              />
            </React.Fragment>
          ))}
        </div>
      </ConfigProvider>
    )
  },
)

export const NotificationsPage = React.memo(() => {
  const query = useNotifications(true)
  const isMobile = useIsMobile()
  const { env, featureFlags } = getConfig()

  return (
    <Page requiresCompanyUser>
      <div
        className="card container m-0 m-auto overflow-y-scroll"
        style={
          isMobile
            ? {
                padding: '4px',
                height: '70%',
                maxWidth: '100vw',
                boxSizing: 'border-box',
              }
            : undefined
        }
      >
        <PageHeader title="Notifications">
          {env !== 'production' && !featureFlags.notificationPollingEnabled ? (
            <div className="px-2 pt-2 text-center">
              <span>
                Notification Polling is disabled. To enable, set{' '}
                <code>featureFlags.notificationPollingEnabled</code> to{' '}
                <code>true</code> in <code>config/local.json</code> and restart
                the app.
              </span>
            </div>
          ) : (
            <TrpcQueryLoader
              query={query}
              render={data => (
                <NotificationContent data={data} refetch={query.refetch} />
              )}
            />
          )}
        </PageHeader>
      </div>
    </Page>
  )
})
