const OPERATING_SYSTEMS = [
  'Mac',
  'iOS',
  'Windows',
  'Android',
  'Linux',
  'Unknown',
] as const

type OperatingSystem = (typeof OPERATING_SYSTEMS)[number]

function getOperatingSystem(): OperatingSystem {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (macosPlatforms.includes(platform)) {
    return 'Mac'
  } else if (iosPlatforms.includes(platform)) {
    return 'iOS'
  } else if (windowsPlatforms.includes(platform)) {
    return 'Windows'
  } else if (/Android/.test(userAgent)) {
    return 'Android'
  } else if (!platform && /Linux/.test(userAgent)) {
    return 'Linux'
  }

  return 'Unknown'
}

export const getIsMac = () => getOperatingSystem() === 'Mac'
