import { useIsTechAssignedToAccount } from '../technician/useIsTechAssignedToAccount'
import { useIsOfficeApp } from '../useIsOfficeApp'
import { useCanViewAllFieldAccounts } from './useCanViewAllFieldAccounts'
import { useCanViewOfficeAccount } from './useCanViewOfficeAccount'
import { useHasFieldAccountsFullAccess } from './useHasFieldAccountsFullAccess'

export const useCanViewAccount = (accountGuid: string) => {
  const isOfficeApp = useIsOfficeApp()
  const { isTechAssignedToAccount, isLoading } =
    useIsTechAssignedToAccount(accountGuid)
  const canViewOfficeAccount = useCanViewOfficeAccount()
  const hasFieldAccountsFullAccess = useHasFieldAccountsFullAccess()

  const canViewAllFieldAccounts = useCanViewAllFieldAccounts()

  let canView = false
  let loadingStatus = true

  if (isOfficeApp) {
    canView = canViewOfficeAccount
    loadingStatus = false
  } else if (hasFieldAccountsFullAccess || canViewAllFieldAccounts) {
    canView = true
    loadingStatus = false
  } else {
    canView = isTechAssignedToAccount
    loadingStatus = isLoading
  }

  return {
    canView,
    isLoading: loadingStatus,
  }
}
