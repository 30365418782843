import { bzExpect } from '@breezy/shared'
import { useQuery } from 'urql'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { TECH_JOB_APPT_ASSIGNMENTS_QUERY } from './TechnicianHooks.gql'

export const useIsTechAssignedToJob = (jobGuid: string) => {
  const principal = useExpectedPrincipal()
  const companyGuid = bzExpect(
    principal.company?.companyGuid,
    'companyGuid',
    'User Has No Company',
  )

  const [techAssignedToJobQuery] = useQuery({
    query: TECH_JOB_APPT_ASSIGNMENTS_QUERY,
    variables: { jobGuid, companyGuid, technicianUserGuid: principal.userGuid },
  })

  return {
    isLoading: techAssignedToJobQuery.fetching,
    isTechAssignedToJob: Boolean(
      techAssignedToJobQuery.data?.jobAppointmentAssignments?.length &&
        techAssignedToJobQuery.data.jobAppointmentAssignments.length > 0,
    ),
  }
}
