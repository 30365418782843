import React, { useContext } from 'react'
import {
  OnsitePageContainer,
  OnsitePageContainerProps,
} from '../../../adam-components/OnsitePage/OnsitePageContainer'
import {
  InvoiceInfoContext,
  InvoiceInfoModalContent,
} from './InvoiceInfoModalContent'

type InvoicePageContainerProps = Omit<
  OnsitePageContainerProps,
  'info' | 'infoHeader'
>

export const InvoicePageContainer = React.memo<InvoicePageContainerProps>(
  props => {
    const infoContext = useContext(InvoiceInfoContext)

    if (infoContext) {
      return (
        <OnsitePageContainer
          {...props}
          info={<InvoiceInfoModalContent />}
          infoHeader="Invoice info"
        />
      )
    }
    return <OnsitePageContainer {...props} />
  },
)
