import classNames from 'classnames'
import React from 'react'
import BzRadioButton from '../BzRadioButton/BzRadioButton'

type RadioButtonOption = {
  value: string
  label?: React.ReactNode
  disabled?: boolean
}

type RadioButtonListFieldProps = {
  name: string
  value: string | undefined
  disabled?: boolean
  className?: string
  labelClassName?: string
  options: RadioButtonOption[]
  onChange: (e: { target: { value: string } }) => void
}

export const RadioButtonListField = React.memo(
  React.forwardRef<HTMLDivElement, RadioButtonListFieldProps>(
    (
      { name, disabled, className, value, onChange, options, labelClassName },
      ref,
    ) => (
      <div className={classNames('space-y-3', className)} ref={ref}>
        {options.map(
          ({ value: optionValue, label, disabled: optionDisabled }) => {
            const checked = value === optionValue
            return (
              <BzRadioButton
                key={optionValue}
                name={name}
                disabled={disabled || optionDisabled}
                label={label ?? optionValue}
                labelClassName={labelClassName}
                value={optionValue}
                checked={checked}
                onChange={() => onChange({ target: { value: optionValue } })}
              />
            )
          },
        )}
      </div>
    ),
  ),
)
