import seedrandom from 'seedrandom'
import { nextGuid } from '../uuid-utils'

export class RandomNumberGenerator {
  private readonly pseudoRandomNumberGenerator: seedrandom.PRNG

  constructor(seedOverride?: string) {
    this.pseudoRandomNumberGenerator = seedrandom(seedOverride || nextGuid())
  }

  nextInt(opts = { minInclusive: Number.MIN_SAFE_INTEGER, maxExclusive: Number.MAX_SAFE_INTEGER }) {
    const next = this.pseudoRandomNumberGenerator()
    const min = Math.min(opts.minInclusive, opts.maxExclusive)
    const max = Math.max(opts.minInclusive, opts.maxExclusive)
    const magnitude = max - min
    return Math.floor(min + next * magnitude)
  }
}
