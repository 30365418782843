import { CompanyLeadSource, fuzzyMatch } from '@breezy/shared'
import { Form, FormInstance } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import classNames from 'classnames'
import React from 'react'
import BzSelect from '../../elements/BzSelect/BzSelect'

type LeadSourceSelectFormItemProps = {
  companyLeadSources: CompanyLeadSource[]
  form: FormInstance<Record<string, unknown> & { leadSourceGuid?: string }>
  className?: string
  labelClassName?: string
  required?: boolean
}

const LeadSourceSelectFormItem = React.memo<LeadSourceSelectFormItemProps>(
  ({
    companyLeadSources,
    form,
    className,
    labelClassName,
    required = false,
  }) => {
    const leadSourceGuid = useWatch('leadSourceGuid')

    return (
      <div className={classNames('flex', className)}>
        <div className="w-full">
          <Form.Item
            name="leadSourceGuid"
            label="Lead Source"
            rules={
              required
                ? [
                    {
                      required: true,
                      message: 'Lead Source is required',
                    },
                  ]
                : []
            }
            className={classNames('mb-1', labelClassName)}
          >
            <BzSelect
              title="Lead Source"
              value={leadSourceGuid}
              placeholder="Lead Source"
              allowClear
              showSearch
              filterOption={(inputValue, option) => {
                return !![option?.children, option?.value, option?.label]
                  .filter(Boolean)
                  .map(v => `${v}`)
                  .find(v => fuzzyMatch(v, inputValue))
              }}
              options={companyLeadSources.map(leadSource => ({
                value: leadSource.companyLeadSourceGuid,
                label: leadSource.name,
              }))}
              onChange={(value: string) => {
                form.setFieldsValue({ leadSourceGuid: value })
              }}
            />
          </Form.Item>
        </div>
      </div>
    )
  },
)

export default LeadSourceSelectFormItem
