import { JobOutcomesFormSchema } from '@breezy/shared'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useJobCostsDeductionUsc } from './useJobCostsDeductionUsc'
import { useOverheadAllocationDeduction } from './useOverheadAllocationDeduction'

export const useCommissionableBaseUsc = (
  form: UseFormReturn<JobOutcomesFormSchema>,
  totalSoldRevenueUsc: number,
) => {
  const [overheadAllocationDeductionUsc] = useOverheadAllocationDeduction(
    form,
    totalSoldRevenueUsc,
  )
  const jobCostsDeductionUsc = useJobCostsDeductionUsc(
    form,
    totalSoldRevenueUsc,
  )
  return useMemo(() => {
    return Math.max(
      0,
      Math.round(
        totalSoldRevenueUsc -
          jobCostsDeductionUsc -
          overheadAllocationDeductionUsc,
      ),
    )
  }, [
    jobCostsDeductionUsc,
    overheadAllocationDeductionUsc,
    totalSoldRevenueUsc,
  ])
}
