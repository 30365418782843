import { nextGuid, noOp } from '@breezy/shared'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, Input, InputRef, Modal } from 'antd'
import classNames from 'classnames'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ReactDOM from 'react-dom/client'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { BzCloseButton } from '../../elements/BzCloseButton/BzCloseButton'
import { useIntercom } from '../../hooks/useIntercom'
import useIsMobile from '../../hooks/useIsMobile'
import './OnsiteModal.less'

type OnsiteModalSize = 'small' | 'large' | 'default' | 'large-width' | 'full'

type OnsiteModalFooterProps = {
  className?: string
  onCancel: () => void
  onSubmit?: () => void
  cancelText?: string
  submitText?: string
  submitDisabled?: boolean
  submitIsLoading?: boolean
  danger?: boolean
  hideCancelButton?: boolean
  hideSubmitButton?: boolean
}

export const OnsiteModalFooter = React.memo<OnsiteModalFooterProps>(
  ({
    onCancel,
    onSubmit,
    className,
    cancelText = 'Cancel',
    submitText = 'Save',
    submitDisabled,
    submitIsLoading,
    danger,
    hideCancelButton,
    hideSubmitButton,
  }) => {
    return (
      <div
        className={classNames(
          'mt-2 flex flex-row justify-end gap-x-3',
          className,
        )}
      >
        {!hideCancelButton && (
          <Button block size="large" onClick={() => onCancel()}>
            {cancelText}
          </Button>
        )}
        {!hideSubmitButton && (
          <Button
            block
            loading={submitIsLoading}
            danger={danger}
            type="primary"
            size="large"
            onClick={onSubmit ? () => onSubmit() : noOp}
            htmlType="submit"
            disabled={submitDisabled}
          >
            {submitText}
          </Button>
        )}
      </div>
    )
  },
)

type OnsiteModalHeaderProps = React.PropsWithChildren<{
  className?: string
  bordered?: boolean
}>

export const OnsiteModalHeader = React.memo<OnsiteModalHeaderProps>(
  ({ children, className, bordered }) => {
    const isMobile = useIsMobile()

    return (
      <div
        className={classNames(
          'text-3xl font-semibold text-bz-gray-1000',
          isMobile ? 'pb-4' : 'pb-6',
          {
            'border-0 border-b-4 border-solid border-b-bz-gray-400': bordered,
            'mx-[-16px] mb-4 px-4': bordered && isMobile,
            'mx-[-24px] mb-6 px-6': bordered && !isMobile,
          },
          className,
        )}
      >
        {children}
      </div>
    )
  },
)

type OnsiteModalContentProps = React.PropsWithChildren<
  {
    footer?: React.ReactNode
    onBack?: () => void
    onClose?: () => void
    isLoading?: boolean
    disabled?: boolean
    header?: React.ReactNode
    headerBordered?: boolean
    headerAlwaysUp?: boolean
    subtitle?: React.ReactNode
    alwaysShowScrollbar?: boolean
    unpadBottom?: boolean
    onScroll?: (scrollTop: number) => void
    searchInputRef?: React.RefObject<InputRef>
    className?: string
  } & (
    | {
        searchTerm?: never
        onSearch?: never
      }
    | {
        searchTerm: string
        onSearch: (searchTerm: string) => void
      }
  )
>

const HEADER_HEIGHT = 30

export const OnsiteModalContent = React.memo<OnsiteModalContentProps>(
  ({
    footer,
    onBack,
    onClose,
    isLoading,
    children,
    header,
    headerBordered,
    headerAlwaysUp,
    subtitle,
    searchTerm,
    onSearch,
    alwaysShowScrollbar,
    unpadBottom,
    onScroll,
    disabled = false,
    searchInputRef,
    className,
  }) => {
    const isMobile = useIsMobile()

    const bodyRef = useRef<HTMLDivElement>(null)

    const [isHeaderOutOfView, setIsHeaderOutOfView] = useState(false)
    const [searchFocused, setSearchFocused] = useState(false)

    useLayoutEffect(() => {
      if (bodyRef.current) {
        const container = bodyRef.current
        const handler = () => {
          setIsHeaderOutOfView(container.scrollTop > HEADER_HEIGHT)
          onScroll?.(container.scrollTop)
        }
        container.addEventListener('scroll', handler)
        return () => container?.removeEventListener('scroll', handler)
      }
    }, [isLoading, onScroll])

    // If you change the header while scrolled (so the upper-header is visible), it looks weird because the old header
    // fades out. So we keep track of the last header so we can hide it when that happens. Furthermore, because of how
    // we hide the header when we aren't scrolled, if you render the header at ALL when it changes, it will appear for a
    // moment and then disappear. So we don't just suppress the animation but we don't render it at all. Finally, if
    // you're scrolled in the modal and navigate in a way where it changes the header, it will maintain its scrolling,
    // but because of the quirks of this it won't show that hidden header. The solution to that is a non-solution: when
    // the header changes scroll all the way to the top. This is reasonable behavior anyway: when you change a screen it
    // should start at the top not maintain the previous scroll top.
    const prevHeader = useRef(header ?? '')
    useLayoutEffect(() => {
      prevHeader.current = header ?? ''
      bodyRef.current?.scrollTo(0, 0)
    }, [header])

    const collapseSearch = isHeaderOutOfView && !searchFocused

    if (isLoading) {
      return <LoadingSpinner />
    }
    return (
      <>
        <div
          className={classNames(
            'relative flex flex-row items-center',
            isMobile ? 'mb-4' : 'mb-6',
            className,
          )}
        >
          {onClose && (
            <BzCloseButton
              onClose={onClose}
              onBack={onBack}
              disabled={disabled}
            />
          )}
          {header && (header === prevHeader.current || headerAlwaysUp) ? (
            <div
              className={classNames(
                'mx-4 flex-1 truncate whitespace-nowrap text-center text-xl font-semibold transition-opacity',
                isHeaderOutOfView || headerAlwaysUp
                  ? 'opacity-100'
                  : 'opacity-0',
              )}
            >
              {header}
            </div>
          ) : null}
          {/* This div is to counter-balance the close button so the header can be properly centered */}
          <div className="h-10 w-10" />
          {onSearch && (
            <Input
              allowClear={
                collapseSearch
                  ? false
                  : {
                      clearIcon: (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="mr-1 flex items-center justify-center text-base text-bz-gray-1000"
                        />
                      ),
                    }
              }
              prefix={
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-2 text-bz-gray-700"
                />
              }
              className={classNames(
                'absolute inset-y-0 right-0 h-10 rounded-full text-base transition-all',
              )}
              style={{
                width: collapseSearch ? '40px' : 'calc(100% - 72px)',
              }}
              placeholder="Search..."
              data-testid="onsiteModalSearchInput"
              ref={searchInputRef}
              value={searchTerm}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setSearchFocused(false)}
              onChange={e => onSearch(e.target.value)}
            />
          )}
        </div>

        <div className="relative flex min-h-0 flex-1 flex-col">
          <div
            ref={bodyRef}
            className={classNames(
              'flex min-h-0 flex-1 flex-col',
              alwaysShowScrollbar
                ? 'overflow-x-auto overflow-y-scroll'
                : 'overflow-auto',
              isMobile ? 'mx-[-1rem] px-4' : 'mx-[-1.5rem] px-6',
              {
                [isMobile ? 'pb-4' : 'pb-6']: !unpadBottom,
              },
            )}
          >
            {header && !headerAlwaysUp ? (
              <OnsiteModalHeader bordered={headerBordered}>
                {header}
                {subtitle ? (
                  <div className="mt-2 text-base font-normal">{subtitle}</div>
                ) : null}
              </OnsiteModalHeader>
            ) : null}

            {children}
          </div>
        </div>
        {footer ? (
          <div
            className={classNames(
              'flex min-w-0 flex-col border-0 border-t-4 border-solid border-t-bz-gray-400 pt-2',
              isMobile ? 'mx-[-1rem] px-4 pb-4' : 'mx-[-1.5rem] px-6 pb-6',
            )}
          >
            {footer}
          </div>
        ) : null}
      </>
    )
  },
)

type OnsiteBasicModalProps = React.PropsWithChildren<{
  header?: string
  footer?: React.ReactNode
  secondaryFooter?: React.ReactNode
  onClose: () => void
  onBack?: () => void
  open?: boolean
  drawer?: boolean
  headerBordered?: boolean
  size?: OnsiteModalSize
  modalClassName?: string
}>

export const OnsiteBasicModal = React.memo<OnsiteBasicModalProps>(
  ({
    header,
    footer,
    secondaryFooter,
    onClose,
    drawer = true,
    open = true,
    children,
    headerBordered,
    onBack,
    size,
    modalClassName,
  }) => {
    const isMobile = useIsMobile()
    return (
      <OnsiteModal
        drawer={drawer}
        onClose={onClose}
        open={open}
        size={size}
        modalClassName={modalClassName}
      >
        <OnsiteModalContent
          unpadBottom={!!secondaryFooter}
          onClose={onClose}
          footer={footer}
          onBack={onBack}
          header={header}
          headerBordered={headerBordered}
        >
          <div className={classNames('text-base')}>{children}</div>
          {secondaryFooter && (
            <div
              className={classNames(
                'mt-4 flex flex-row items-center border-0 border-t-4 border-solid border-t-bz-gray-400 py-3',
                isMobile ? 'mx-[-1rem] px-4' : 'mx-[-1.5rem] px-6',
              )}
            >
              {secondaryFooter}
            </div>
          )}
        </OnsiteModalContent>
      </OnsiteModal>
    )
  },
)

export type OnsiteConfirmModalProps = React.PropsWithChildren<{
  header: string
  onCancel: () => void
  onConfirm?: () => void
  open?: boolean
  danger?: boolean
  cancelText?: string
  confirmText?: string
  loading?: boolean
  hideCancelButton?: boolean
  hideSubmitButton?: boolean
  confirmDisabled?: boolean
  size?: OnsiteModalSize
  modalClassName?: string
}>

export const OnsiteConfirmModal = React.memo<OnsiteConfirmModalProps>(
  ({
    header,
    onCancel,
    onConfirm,
    open = true,
    children,
    danger,
    cancelText,
    confirmText,
    loading,
    hideCancelButton,
    hideSubmitButton,
    confirmDisabled,
    size,
    modalClassName,
  }) => (
    <OnsiteBasicModal
      onClose={onCancel}
      open={open}
      header={header}
      size={size}
      modalClassName={modalClassName}
      footer={
        <OnsiteModalFooter
          danger={danger}
          onCancel={onCancel}
          onSubmit={onConfirm}
          cancelText={cancelText}
          submitText={confirmText}
          submitIsLoading={loading}
          hideCancelButton={hideCancelButton}
          hideSubmitButton={hideSubmitButton}
          submitDisabled={confirmDisabled}
        />
      }
    >
      {children}
    </OnsiteBasicModal>
  ),
)

type OnsiteModalProps = React.PropsWithChildren<{
  onClose?: () => void
  size?: OnsiteModalSize
  drawer?: boolean
  open?: boolean
  className?: string
  modalClassName?: string
}>

const OnsiteModalInner = React.memo<OnsiteModalProps>(
  ({ onClose, children, size = 'default', open = true, modalClassName }) => {
    // It's insane that this is all necessary. For the antd modal, it doesn't call your "onClose" when you outside
    // click. Instead it just totally closes it. This is how we can work around the problem: disable their "on outside
    // click", hook into the modal when it's created, insert our own "mask" component with our own click event, and use
    // the onClose.
    const wrapClass = useMemo(() => `onsite-modal-inner-wrap-${nextGuid()}`, [])

    useLayoutEffect(() => {
      // If we're not open, don't do it. Otherwise, when we close we might just create the mask again.
      if (!open) {
        return
      }
      const wrap = document.querySelector(`.${wrapClass}`)
      if (wrap) {
        const modal = wrap.querySelector('.ant-modal')
        if (modal) {
          const ourRoot = document.createElement('div')
          ourRoot.className = 'onsite-inner-modal-custom-mask'

          wrap.insertBefore(ourRoot, modal)

          const root = ReactDOM.createRoot(ourRoot)
          root.render(
            <div className="fixed inset-0" onClick={() => onClose?.()} />,
          )
        }
      }
      return () => {
        const wrap = document.querySelector(`.${wrapClass}`)
        if (wrap) {
          wrap.querySelector('.onsite-inner-modal-custom-mask')?.remove()
        }
      }
    }, [wrapClass, onClose, open])
    return (
      <Modal
        open={open}
        centered
        maskClosable={false}
        closeIcon={false}
        onCancel={() => onClose?.()}
        className={classNames(
          'onsite-modal',
          {
            'h-[90%] w-[75%] max-w-[600px]': size === 'large',
            'w-[600px] min-w-[600px]': size === 'large-width',
            'relative max-h-[90%]': size === 'default',
            'max-w-[390px]': size === 'small',
            // For some reason Tailwind defines min-h-screen and w-screen but not min-w-screen
            'full-screen-onsite-modal h-screen min-h-screen w-screen min-w-[100vw]':
              size === 'full',
          },
          modalClassName,
        )}
        footer={null}
        wrapClassName={classNames('z-[1010] onsite-modal-wrapper', wrapClass)}
        classNames={{
          mask: 'z-[1010]',
        }}
      >
        <div className="flex max-h-full min-h-0 min-w-0 flex-1 flex-col overflow-auto px-6 pt-6">
          {children}
        </div>
      </Modal>
    )
  },
)

const OnsiteModalDrawer = React.memo<OnsiteModalProps>(
  ({ onClose, children, open = true, className }) => {
    // This is all very similar to the stuff above for the modal but the drawer works slightly differently. Still very
    // sad.
    const wrapClass = useMemo(
      () => `onsite-modal-drawer-wrap-${nextGuid()}`,
      [],
    )
    useLayoutEffect(() => {
      setTimeout(() => {
        if (!open) {
          return
        }
        const wrap = document.querySelector(`.${wrapClass}`)
        if (wrap) {
          const drawer = wrap.querySelector('.ant-drawer-content-wrapper')
          if (drawer) {
            const ourRoot = document.createElement('div')
            ourRoot.className = 'onsite-modal-drawer-custom-mask'

            wrap.insertBefore(ourRoot, drawer)

            const root = ReactDOM.createRoot(ourRoot)
            root.render(
              <div className="absolute inset-0" onClick={() => onClose?.()} />,
            )
          }
        }
      }, 0)
      return () => {
        setTimeout(() => {
          const wrap = document.querySelector(`.${wrapClass}`)
          if (wrap) {
            const customMask = wrap.querySelector(
              '.onsite-modal-drawer-custom-mask',
            )
            customMask?.remove()
          }
        }, 0)
      }
    }, [wrapClass, onClose, open])

    useIntercom({ isLauncherVisible: false })

    return (
      <Drawer
        open={open}
        maskClosable={false}
        onClose={() => onClose?.()}
        placement="bottom"
        closeIcon={false}
        rootClassName={classNames(
          'onsite-modal-drawer onsite-modal',
          wrapClass,
          {
            'onsite-modal-drawer-open': open,
          },
        )}
        height="auto"
      >
        <div
          className={classNames(
            'flex min-h-0 flex-1 flex-col overflow-auto p-4',
            className,
          )}
        >
          {children}
        </div>
      </Drawer>
    )
  },
)

export const OnsiteModal = React.memo<OnsiteModalProps>(
  ({ children, drawer, onClose, open = true, ...rest }) => {
    const isMobile = useIsMobile()

    if (drawer && isMobile) {
      return (
        <OnsiteModalDrawer
          onClose={onClose}
          open={open}
          className={rest.className}
        >
          {children}
        </OnsiteModalDrawer>
      )
    }

    if (isMobile) {
      return createPortal(
        <div
          className={classNames(
            'onsite-modal fixed inset-0 bg-white',
            open ? 'translate-y-0' : 'translate-y-[100%]',
            {
              'transition-transform duration-500': rest.size !== 'full',
            },
            rest.className,
          )}
        >
          <div className="flex min-h-0 flex-1 flex-col p-4">{children}</div>
        </div>,
        document.body,
      )
    }
    return (
      <OnsiteModalInner open={open} {...rest} onClose={onClose}>
        {children}
      </OnsiteModalInner>
    )
  },
)
