import { AccountLocation, formatEquipmentTypeString } from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import { memo, useMemo } from 'react'
import tailwindConfig from '../../../tailwind.config'
import {
  HeaderTagProp,
  StaticHeightOptionCardHeader,
} from './StaticHeightOptionCardHeader'

type Props = {
  accountLocation: AccountLocation
  numTagsToShow?: number
}

export const LocationCardHeader = memo<Props>(
  ({ accountLocation, numTagsToShow }) => {
    const hasEquipment = useMemo(() => {
      const installedEquipment = accountLocation.location.installedEquipment
      return installedEquipment && installedEquipment.length > 0
    }, [accountLocation.location.installedEquipment])

    const equipmentTags = useMemo<HeaderTagProp[]>(() => {
      const installedEquipment = accountLocation.location.installedEquipment
      if (!installedEquipment || installedEquipment.length === 0) {
        return [
          {
            value: 'No Equipment',
            color: tailwindConfig.theme.extend.colors.bz['fill-quaternary'],
          },
        ]
      }
      const equipmentTypes: Set<string> = new Set()
      installedEquipment.forEach(equipment =>
        equipmentTypes.add(equipment.equipmentType),
      )
      return Array.from(equipmentTypes).map(type => ({
        value: formatEquipmentTypeString(type),
      }))
    }, [accountLocation.location.installedEquipment])

    return (
      <StaticHeightOptionCardHeader
        faIcon={faLocationDot}
        iconClassName="h-[20px] w-[20px]"
        title={accountLocation.location.address.line1}
        description={`${accountLocation.location.address.city}, ${accountLocation.location.address.stateAbbreviation}, ${accountLocation.location.address.zipCode}`}
        tags={equipmentTags}
        tagStyleVersion={hasEquipment ? 'v2' : 'empty'}
        numTagsToShow={numTagsToShow}
        heightClassName="min-h-[102px]"
      />
    )
  },
)
