import { AccountLocation } from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useState } from 'react'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { Refetchable } from '../../../utils/Refetchable'
import { useMessage } from '../../../utils/antd-utils'
import { LocationIcon } from '../../../utils/feature-icons'
import { m } from '../../../utils/react-utils'
import CreateOrEditNewAccountLocationForm from '../../CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import { LocationCollapsibleItem } from '../LocationsCollapsible/LocationCollapsibleItem'

type AccountLocationCollapsibleProps = Refetchable<AccountLocation>

const AccountLocationCollapsible = m<
  { editable?: boolean } & AccountLocationCollapsibleProps
>(({ data, refetch, editable = true }) => {
  const message = useMessage()

  const [upsertLocationFormOpen, setUpsertLocationFormOpen] = useState(false)
  const onUpsertLocationDrawerClosed = useCallback(() => {
    setUpsertLocationFormOpen(false)
  }, [])

  const onEdit = useCallback(() => {
    setUpsertLocationFormOpen(true)
  }, [])

  return (
    <>
      <BzCollapsible
        title="Location"
        titleIcon={faLocationDot}
        showChildrenLength={false}
        defaultOpen
      >
        <LocationCollapsibleItem
          location={data.location}
          onEdit={editable ? onEdit : undefined}
        />
      </BzCollapsible>
      <BzDrawer
        title="Edit Location"
        icon={LocationIcon}
        item={
          upsertLocationFormOpen
            ? { onCancel: onUpsertLocationDrawerClosed }
            : undefined
        }
        destroyOnClose
        preferredWidth={720}
      >
        <CreateOrEditNewAccountLocationForm
          showDivider={true}
          showCancelSubmitButtons={true}
          accountGuid={data.accountGuid}
          onCancelButtonPressed={onUpsertLocationDrawerClosed}
          editingLocation={data.location}
          onLocationUpdated={() => {
            setUpsertLocationFormOpen(false)
            message.success('Successfully updated location')
            refetch()
          }}
          flexRowSpaceX="space-x"
          labelClassName="semibold_14_22 grey9"
        />
      </BzDrawer>
    </>
  )
})

export default AccountLocationCollapsible
