import classNames from 'classnames'
import React from 'react'
import ThinDivider from '../ThinDivider'

type CalloutProps = {
  title: React.ReactNode
  description?: React.ReactNode
  className?: string
  dataTestId?: string
}
export const Callout = React.memo<React.PropsWithChildren<CalloutProps>>(
  ({ children, title, description, className, dataTestId }) => {
    return (
      <div
        className={classNames(
          'flex flex-col rounded-lg border border-solid border-bz-gray-500 bg-bz-fill-quaternary px-4 py-3',
          className,
        )}
        data-testid={dataTestId}
      >
        <div className="mb-[2px] text-sm font-semibold leading-[22px]">
          {title}
        </div>
        {description && (
          <div className="pb-1 text-xs leading-5">{description}</div>
        )}
        <ThinDivider
          widthPx={1}
          styleOverrides={{
            marginTop: 8,
            marginBottom: 8,
          }}
        />
        {children}
      </div>
    )
  },
)
