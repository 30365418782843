import { APIProvider } from '@vis.gl/react-google-maps'
import React from 'react'

import { getConfig } from '../config'
const { mapsAPIKey } = getConfig()

export const GoogleMapsWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    return <APIProvider apiKey={mapsAPIKey}>{children}</APIProvider>
  },
)
