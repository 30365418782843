import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useCanViewTechnicianPerformance } from './useCanViewTechnicianPerformance'
import { useIsAuthorizedTo } from './useIsAuthorized'

export const useCanManageTechnicianPerformance = () => {
  const canViewTechnicianPerformance = useCanViewTechnicianPerformance()
  const isAuthorized = useIsAuthorizedTo(
    PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE,
  )
  const canManageTechnicianPerformance = useMemo(
    () => canViewTechnicianPerformance && isAuthorized,
    [canViewTechnicianPerformance, isAuthorized],
  )

  return canManageTechnicianPerformance
}
