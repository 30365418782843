import { isNullish } from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import { useModalState } from '../../utils/react-utils'
import { OnsiteConfirmModal } from './OnsiteModal'

export type BaseCloseConfirmModalProps = {
  onCancelCancel: () => void
  onConfirmCancel: () => void
  open?: boolean
}

export const useCloseConfirmModal = (
  isDirty?: boolean,
  options?: {
    noNavBlocker?: boolean
  },
): [
  withConfirmation: (onCancelConfirm: () => void) => void,
  props: BaseCloseConfirmModalProps,
] => {
  const [confirmCloseOpen, openConfirmClose, closeConfirmCancel] =
    useModalState()

  const [confirmAction, setConfirmAction] = useState<
    { action: () => void } | undefined
  >()

  useUnsavedChangesWarning(
    !options?.noNavBlocker && (isNullish(isDirty) || isDirty),
  )

  const withConfirmation = useCallback(
    (onCancelConfirm: () => void) => {
      if (isNullish(isDirty) || isDirty) {
        setConfirmAction({
          action: onCancelConfirm,
        })
        openConfirmClose()
      } else {
        onCancelConfirm()
      }
    },
    [isDirty, openConfirmClose],
  )

  const onConfirmCancel = useCallback(() => {
    closeConfirmCancel()
    confirmAction?.action()
  }, [closeConfirmCancel, confirmAction])

  const onCancelCancel = useCallback(() => {
    setConfirmAction(undefined)
    closeConfirmCancel()
  }, [closeConfirmCancel])

  const props = useMemo(
    () => ({ onConfirmCancel, onCancelCancel, open: confirmCloseOpen }),
    [confirmCloseOpen, onCancelCancel, onConfirmCancel],
  )

  return [withConfirmation, props]
}

type CloseConfirmModalProps = BaseCloseConfirmModalProps & {
  content?: React.ReactNode
  header?: string
  confirmText?: string
  danger?: boolean
}

export const CloseConfirmModal = React.memo<CloseConfirmModalProps>(
  ({
    onCancelCancel,
    onConfirmCancel,
    content,
    open = true,
    danger = true,
    header = 'Are you sure you want to exit?',
    confirmText = 'Yes, Exit',
  }) => {
    if (!open) {
      return null
    }
    return (
      <OnsiteConfirmModal
        danger={danger}
        onCancel={onCancelCancel}
        onConfirm={onConfirmCancel}
        header={header}
        confirmText={confirmText}
      >
        {isNullish(content) ? (
          <>
            You have unsaved changes.
            <br />
            <br />
            Are you sure you want to exit?
          </>
        ) : (
          content
        )}
      </OnsiteConfirmModal>
    )
  },
)
