import { LocalDateString } from '@breezy/shared'
import { useMemo } from 'react'

export type UseShouldShowDelayedPaymentBannerProps = {
  isFreePlan: boolean
  isAutoRenewing: boolean
  billingAnchorDate?: LocalDateString
  activationDate?: LocalDateString
}
export const useShouldShowDelayedPaymentBanner = ({
  isFreePlan,
  isAutoRenewing,
  billingAnchorDate,
  activationDate,
}: UseShouldShowDelayedPaymentBannerProps) => {
  return useMemo(() => {
    return (
      !isFreePlan &&
      isAutoRenewing &&
      billingAnchorDate &&
      activationDate &&
      billingAnchorDate !== activationDate
    )
  }, [isFreePlan, isAutoRenewing, billingAnchorDate, activationDate])
}
