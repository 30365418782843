import { PhotoGuid } from '@breezy/shared'
import { useMemo } from 'react'
import { trpc } from 'src/hooks/trpc'
import { Styled } from '../../utils/Stylable'
import LinkedNotesListLoader from '../Notes/LinkedNotesList/LinkedNotesList'
import { NoteForm } from '../Notes/NoteForm'
import TrpcQueryLoader from '../TrpcQueryLoader'

type PhotoNotesListCardProps = Styled<{
  photoGuid: PhotoGuid
  editable?: boolean
}>

export const PhotoNotesListCard = ({
  photoGuid,
  className,
  style,
  editable = true,
}: PhotoNotesListCardProps) => {
  const notesGetLinkedQuery = trpc.notes['notes:get-linked'].useQuery({
    photoGuid,
  })

  const linkData = useMemo(
    () => ({ primaryNoteType: 'PHOTO', photoGuid } as const),
    [photoGuid],
  )

  return (
    <TrpcQueryLoader
      query={notesGetLinkedQuery}
      render={data => (
        <div className={'account-notes-list-card ' + className} style={style}>
          <h3>Description</h3>
          {data.length === 0 && (
            <NoteForm
              linkData={linkData}
              onSuccess={notesGetLinkedQuery.refetch}
              addText="Add Description"
              editable={editable}
            />
          )}
          <LinkedNotesListLoader
            noteLinks={{ photoGuid }}
            noNotesComponent={<span />}
            linkData={{ primaryNoteType: 'PHOTO', photoGuid }}
            editable={editable}
          />
        </div>
      )}
    />
  )
}
