import React from 'react'

type RenderIfProps = {
  if: boolean
}

const RenderIf = React.memo<React.PropsWithChildren<RenderIfProps>>(
  ({ if: condition, children }) => {
    if (condition) {
      return <>{children}</>
    }

    return null
  },
)

export default RenderIf
