import {
  JobOutcomesCommissionDeductionsPopoverFormSchema,
  JobOutcomesFormSchema,
  JobOutcomesRevenueAttributionSubformSchema,
  JobOutcomesTechnicianTurnoverSubformSchema,
  User,
  isNullish,
  jobOutcomesFormSchema,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Alert, Button, ConfigProvider } from 'antd'
import cn from 'classnames'
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'urql'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import ThinDivider from '../../elements/ThinDivider'
import {
  AllJobAssignedTechniciansQuery,
  AllRevenueAttributionsForJobQuery,
} from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { FETCH_COMPANY_COMMISSION_CONFIG } from '../../pages/TechnicianPerformanceSettingsPage/TechnicianPerformanceSettings.gql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { GqlMultiQueryLoader } from '../GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../LoadingSpinner'
import { TrpcMultiQueryLoader } from '../TrpcQueryLoader'
import ChipsPanel from './ChipsPanel'
import InvoicesPanel from './InvoicesPanel'
import {
  ALL_JOB_ASSIGNED_TECHNICIANS_QUERY,
  ALL_REVENUE_ATTRIBUTIONS_FOR_JOB_QUERY,
} from './JobOutcomesModal.gql'
import RevenueAttributionPanel from './RevenueAttributionPanel'
import SoldMaintenancePlanPanel from './SoldMaintenancePlanPanel'
import TechnicianTurnoverPanel from './TechnicianTurnoverPanel'
import { useDefaultRevenueAttributionsGuidToInvoiceMap } from './hooks/useDefaultRevenueAttributionsGuidToInvoiceMap'
import { useDefaultTechnicianGuidToCommissionAndBonusMap } from './hooks/useDefaultTechnicianGuidToCommissionAndBonusMap'
import { useJobSoldMaintenancePlan } from './hooks/useJobSoldMaintenancePlan'
import {
  JobDetailsForOutcomes,
  JobOutcomeInvoice,
  RevenueAttributionUser,
} from './job-outcome-modal-types'

import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import { useMessage } from '../../utils/antd-utils'
import {
  convertJobOutcomeInvoiceV2ToJobOutcomeInvoice,
  useFetchJobOutcomeInvoices,
} from './hooks/useFetchJobOutcomeInvoices'
type EditablePanels = 'TECHNICIAN_TURNOVER' | 'REVENUE_ATTRIBUTION'

interface CompanyCommissionConfigResult {
  defaultCommissionOverheadDeductionRate?: number
  defaultCommissionOverheadFlatDeductionUsc?: number
}

type JobOutcomesModalProps = {
  job: JobDetailsForOutcomes
  open: boolean
  onOk?: () => void
  onCancel: () => void
}

/**
 * A modal dialog that lets uses view and configure job outcomes such as
 * revenue attribution, technician turnover, and employee compensation (commissions, bonuses, etc.)
 *
 * This modal is meant to be displayed when a job is transitioned to a state where its revenue is
 * recognized. At the time of writing this, this would be a "Work Completed" state.
 *
 * @component
 * @param {object} props - Properties passed to the component.
 * @param {JobDetailsForOutcomes} props.job - The job details.
 * @param {boolean} props.open - Whether the modal is open.
 * @param {function} props.onOk - Function to be called when the modal's OK button is clicked.
 * @param {function} props.onCancel - Function to be called when the moda's cancel button is clicked.
 *
 * @returns {React.ElementType} Returns a modal with job outcome details.
 */
export const JobOutcomesModal = memo<JobOutcomesModalProps>(props => {
  const btnFormSubmit = useRef<HTMLInputElement>(null)
  const [editingPanel, setEditingPanel] = useState<EditablePanels | null>(null)
  const [completeJobButtonDisabled, setCompleteJobButtonDisabled] =
    useState(false)
  useEffect(() => {
    setCompleteJobButtonDisabled(!isNullish(editingPanel))
  }, [editingPanel])

  const onModalCancel = useCallback(() => {
    setEditingPanel(null)
    props.onCancel()
  }, [props])

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            borderColor: '#F0F0F0',
            cellPaddingBlockSM: 6,
            cellPaddingInlineSM: 6,
          },
          Card: {
            headerHeightSM: 22,
          },
        },
      }}
    >
      <OnsiteBasicModal
        open={props.open}
        size="large"
        onClose={onModalCancel}
        footer={
          <div
            className={cn(
              'absolute inset-x-0 bottom-0 flex flex-row justify-between gap-2 bg-white px-6 pb-6 pt-4',
              'z-10 border-0 border-t-4 border-solid border-t-bz-gray-400',
            )}
          >
            <Button
              key="cancel"
              size="large"
              className="w-full font-semibold"
              disabled={!isNullish(editingPanel)}
              onClick={onModalCancel}
            >
              Cancel
            </Button>
            <Button
              key="completeJob"
              htmlType="button"
              type="primary"
              size="large"
              className="w-full font-semibold"
              onClick={() => {
                btnFormSubmit.current?.click()
              }}
              disabled={completeJobButtonDisabled}
            >
              {props.job.workCompletedAt ? 'Save' : 'Complete Job'}
            </Button>
            ,
          </div>
        }
      >
        <JobOutcomesDataLoader
          {...props}
          editingPanel={editingPanel}
          setEditingPanel={setEditingPanel}
          btnFormSubmitRef={btnFormSubmit}
        />
      </OnsiteBasicModal>
    </ConfigProvider>
  )
})

type JobOutcomesDataLoaderProps = {
  job: JobDetailsForOutcomes
  onOk?: () => void
  editingPanel: EditablePanels | null
  setEditingPanel: Dispatch<SetStateAction<EditablePanels | null>>
  btnFormSubmitRef: React.RefObject<HTMLInputElement>
}

const JobOutcomesDataLoader = memo<JobOutcomesDataLoaderProps>(
  ({ job, ...rest }) => {
    const { jobGuid, accountGuid } = job
    const companyGuid = useExpectedCompanyGuid()

    const usersQuery = trpc.user['users:get'].useQuery()

    const fetchCompanyCommissionConfigQuery = useQuery({
      query: FETCH_COMPANY_COMMISSION_CONFIG,
      pause: !companyGuid,
      variables: { companyGuid: companyGuid ?? '' },
      requestPolicy: 'network-only',
    })
    // TODO We can probably combine some of these queries together
    const fetchAllRevenueAttributionsForJobQuery = useQuery({
      query: ALL_REVENUE_ATTRIBUTIONS_FOR_JOB_QUERY,
      variables: {
        jobGuid: job.jobGuid,
      },
    })

    const allJobAssignedTechniciansQuery = useQuery({
      query: ALL_JOB_ASSIGNED_TECHNICIANS_QUERY,
      variables: { jobGuid },
    })

    const { fetchJobOutcomeInvoicesQuery } = useFetchJobOutcomeInvoices(
      accountGuid,
      jobGuid,
    )

    // TODO refactor all of this
    return (
      <TrpcMultiQueryLoader
        queries={[usersQuery]}
        loadingComponent={<LoadingSpinner />}
        render={([usersData]) => {
          const companyUsers =
            (usersData as { users: User[] }).users ?? ([] as User[])

          return (
            <GqlMultiQueryLoader
              queries={[
                fetchJobOutcomeInvoicesQuery,
                fetchCompanyCommissionConfigQuery,
                fetchAllRevenueAttributionsForJobQuery,
                allJobAssignedTechniciansQuery,
              ]}
              loadingComponent={<LoadingSpinner />}
              render={([
                fetchJobOutcomeInvoicesData,
                companyCommissionConfigData,
                allRevenueAttributionsForJobData,
                allJobAssignedTechniciansData,
              ]: [
                (typeof fetchJobOutcomeInvoicesQuery)[0]['data'],
                (typeof fetchCompanyCommissionConfigQuery)[0]['data'],
                (typeof fetchAllRevenueAttributionsForJobQuery)[0]['data'],
                (typeof allJobAssignedTechniciansQuery)[0]['data'],
              ]) => (
                <JobOutcomesModalInner
                  {...rest}
                  job={job}
                  invoices={
                    fetchJobOutcomeInvoicesData?.invoices.map(
                      convertJobOutcomeInvoiceV2ToJobOutcomeInvoice,
                    ) ?? []
                  }
                  companyUsers={companyUsers}
                  companyCommissionConfig={
                    companyCommissionConfigData?.companyCommissionConfig[0]
                  }
                  allRevenueAttributionsForJob={
                    allRevenueAttributionsForJobData
                  }
                  allJobAssignedTechnicians={allJobAssignedTechniciansData}
                />
              )}
            />
          )
        }}
      />
    )
  },
)

type JobOutcomesModalInnerProps = {
  job: JobDetailsForOutcomes
  onOk?: () => void
  invoices: JobOutcomeInvoice[]
  companyUsers: User[]
  companyCommissionConfig?: CompanyCommissionConfigResult
  allRevenueAttributionsForJob?: AllRevenueAttributionsForJobQuery
  allJobAssignedTechnicians?: AllJobAssignedTechniciansQuery
  editingPanel: EditablePanels | null
  setEditingPanel: Dispatch<SetStateAction<EditablePanels | null>>
  btnFormSubmitRef: React.RefObject<HTMLInputElement>
}

/**
 * ! It might be better if this component just took in a `jobGuid` as a prop
 * ! and fetched only the data it needs, rather than forcing the parents to pass
 * ! in the job details. Consider this refactor in the future
 */
const JobOutcomesModalInner = memo<JobOutcomesModalInnerProps>(
  ({
    job,
    invoices,
    onOk,
    companyUsers,
    companyCommissionConfig,
    allRevenueAttributionsForJob,
    allJobAssignedTechnicians,
    editingPanel,
    setEditingPanel,
    btnFormSubmitRef,
  }) => {
    const maintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
    const message = useMessage()
    const jobSoldMaintenancePlan = useJobSoldMaintenancePlan(job.jobGuid)

    const jobOutcomesUpsertMutation =
      trpc.jobs['jobs:upsert-outcomes'].useMutation()

    // TODO This really only needs to be run once when the component is first mounted. We can try to think of a
    // way to implement it that way, or at least move this into a hook to clean up the code
    const initialFormOverheadAllocationDeductionType = useMemo(() => {
      if (!isNullish(job.commissionOverheadFlatDeductionUsc)) {
        return 'FIXED'
      }

      if (!isNullish(job.commissionOverheadDeductionRate)) {
        return 'PERCENT'
      }

      if (
        !isNullish(
          companyCommissionConfig?.defaultCommissionOverheadFlatDeductionUsc,
        )
      ) {
        return 'FIXED'
      }

      if (
        !isNullish(
          companyCommissionConfig?.defaultCommissionOverheadDeductionRate,
        )
      ) {
        return 'PERCENT'
      }

      return 'PERCENT'
    }, [
      companyCommissionConfig?.defaultCommissionOverheadDeductionRate,
      companyCommissionConfig?.defaultCommissionOverheadFlatDeductionUsc,
      job.commissionOverheadDeductionRate,
      job.commissionOverheadFlatDeductionUsc,
    ])

    // TODO Same comment as above
    const initialJobCostsAllocationDeductionType = useMemo(() => {
      if (!isNullish(job.commissionJobCostsDeductionRate)) {
        return 'PERCENT'
      }

      return 'FIXED'
    }, [job.commissionJobCostsDeductionRate])

    const form = useForm<JobOutcomesFormSchema>({
      resolver: zodResolver(jobOutcomesFormSchema),
      defaultValues: {
        jobGuid: job.jobGuid,
        isConverted: job.isConverted ?? false,
        isMembershipSold: job.isMembershipSold ?? false,
        technicianTurnoverUserGuids: job.teamMembers
          .filter(teamMember => teamMember.turnedOverJob)
          .map(teamMember => teamMember.userGuid),
        isTurnedOverByTechnician: job.teamMembers.some(
          teamMember => teamMember.turnedOverJob === true,
        ),
        revenueAttributionGuidToInvoiceMap:
          useDefaultRevenueAttributionsGuidToInvoiceMap(
            invoices,
            job,
            allRevenueAttributionsForJob,
            allJobAssignedTechnicians,
          ),
        overheadAllocationDeductionType:
          initialFormOverheadAllocationDeductionType,
        overheadAllocationDeductionPercent:
          initialFormOverheadAllocationDeductionType === 'PERCENT'
            ? !isNullish(
                job.commissionOverheadDeductionRate ??
                  companyCommissionConfig?.defaultCommissionOverheadDeductionRate,
              )
              ? (job.commissionOverheadDeductionRate ??
                  companyCommissionConfig?.defaultCommissionOverheadDeductionRate ??
                  0) * 100
              : undefined
            : undefined,
        overheadAllocationFlatDeductionUsd:
          initialFormOverheadAllocationDeductionType === 'FIXED'
            ? !isNullish(
                job.commissionOverheadFlatDeductionUsc ??
                  companyCommissionConfig?.defaultCommissionOverheadFlatDeductionUsc,
              )
              ? usCentsToUsd(
                  job.commissionOverheadFlatDeductionUsc ??
                    companyCommissionConfig?.defaultCommissionOverheadFlatDeductionUsc ??
                    0,
                )
              : undefined
            : undefined,
        jobCostsDeductionType: initialJobCostsAllocationDeductionType,
        jobCostsDeductionPercent:
          initialJobCostsAllocationDeductionType === 'PERCENT'
            ? !isNullish(job.commissionJobCostsDeductionRate)
              ? job.commissionJobCostsDeductionRate * 100
              : undefined
            : undefined,
        jobCostsFlatDeductionUsd:
          initialJobCostsAllocationDeductionType === 'FIXED'
            ? !isNullish(job.commissionJobCostsFlatDeductionUsc)
              ? usCentsToUsd(job.commissionJobCostsFlatDeductionUsc)
              : undefined
            : undefined,
        technicianGuidToCommissionAndBonusMap:
          useDefaultTechnicianGuidToCommissionAndBonusMap(job.teamMembers),
      },
      mode: 'onChange',
    })

    const firstError = useMemo(() => {
      if (Object.keys(form.formState.errors).length > 0) {
        const [firstErrorEntry] = Object.entries(form.formState.errors)

        if (typeof firstErrorEntry[1].message === 'string') {
          return firstErrorEntry[1].message
        }
      }

      return ''
    }, [form.formState])

    const technicianTurnoverUserGuids = form.watch(
      'technicianTurnoverUserGuids',
      [],
    )

    const assignedTechnicianUserGuids = useMemo(() => {
      return (
        allJobAssignedTechnicians?.jobsByPk?.jobAppointmentAssignments.map(
          assignment => assignment.technicianUserGuid,
        ) ?? []
      )
    }, [allJobAssignedTechnicians?.jobsByPk?.jobAppointmentAssignments])

    const allAttributableUsers: RevenueAttributionUser[] = useMemo(
      () =>
        companyUsers
          .map(user => {
            return {
              ...user,
              recommended:
                technicianTurnoverUserGuids.includes(user.userGuid) ||
                assignedTechnicianUserGuids.includes(user.userGuid),
            }
          })
          .sort((user1, user2) => {
            if (user1.recommended && !user2.recommended) {
              return -1
            } else if (!user1.recommended && user2.recommended) {
              return 1
            } else {
              return 0
            }
          }),
      [assignedTechnicianUserGuids, companyUsers, technicianTurnoverUserGuids],
    )

    // This might not be the proper naming for this value, but this is just the total
    // sum earned from all of the Fully Paid invoices for this job.
    const totalRevenueEarnedUsd = useMemo(() => {
      return invoices.reduce((acc, invoice) => {
        return acc + invoice.totalPriceUsd
      }, 0)
    }, [invoices])

    //! NOTE: This is different than the `isConverted` field on the job itself. `job.isConverted`
    //! represents the job's current conversion state, while this represents what the conversion state
    //! will be when this modal is submitted
    const willJobBeConverted = useMemo(() => {
      const isConverted =
        usdToUsCents(totalRevenueEarnedUsd) >=
        (job.jobType.opportunityConversionThresholdUsc ?? 0)

      return isConverted
    }, [job.jobType.opportunityConversionThresholdUsc, totalRevenueEarnedUsd])

    useEffect(() => {
      form.setValue('isConverted', willJobBeConverted)
    }, [form, willJobBeConverted])

    const onTechnicianTurnoverSubformSubmit = useCallback(
      (newValues: JobOutcomesTechnicianTurnoverSubformSchema) => {
        setEditingPanel(null)

        // Technicians that are credited with turning over the job should be attributed to
        // ALL sold revenue for the job
        const newTechnicianTurnoverUserGuids =
          newValues.isTurnedOverByTechnician
            ? newValues.technicianTurnoverUserGuids
            : []

        const currentTechnicianTurnoverUserGuids: string[] = form.formState
          .defaultValues?.isTurnedOverByTechnician
          ? (form.formState.defaultValues?.technicianTurnoverUserGuids?.filter(
              guid => !isNullish(guid),
            ) as string[]) ?? []
          : []

        const addedTechnicianTurnoverUserGuids =
          newTechnicianTurnoverUserGuids.filter(
            guid => !currentTechnicianTurnoverUserGuids.includes(guid),
          )

        const removedTechnicianTurnoverUserGuids =
          currentTechnicianTurnoverUserGuids.filter(
            guid => !newTechnicianTurnoverUserGuids.includes(guid),
          )

        const revenueAttributionGuidToInvoiceMap = form.getValues(
          'revenueAttributionGuidToInvoiceMap',
        )

        Object.entries(revenueAttributionGuidToInvoiceMap).forEach(
          ([invoiceGuid, invoiceData]) => {
            Object.entries(invoiceData.guidToItemMap).forEach(
              ([itemGuid, itemRevenueAttributionData]) => {
                const soldRevenueAttributionUserGuids = isNullish(
                  itemRevenueAttributionData.soldRevenueAttributionUserGuids,
                )
                  ? []
                  : itemRevenueAttributionData.soldRevenueAttributionUserGuids

                addedTechnicianTurnoverUserGuids.forEach(addedUserGuid => {
                  if (
                    !soldRevenueAttributionUserGuids.includes(addedUserGuid)
                  ) {
                    soldRevenueAttributionUserGuids.push(addedUserGuid)
                  }
                })

                removedTechnicianTurnoverUserGuids.forEach(removedUserGuid => {
                  // Do not remove Technicians if they are assigned to one of the job's appointment. They should
                  // still be attributed to Sold Revenue because they were assigned
                  if (
                    soldRevenueAttributionUserGuids.includes(removedUserGuid) &&
                    !assignedTechnicianUserGuids.includes(removedUserGuid)
                  ) {
                    soldRevenueAttributionUserGuids.splice(
                      soldRevenueAttributionUserGuids.indexOf(removedUserGuid),
                      1,
                    )
                  }
                })

                form.resetField(
                  `revenueAttributionGuidToInvoiceMap.${invoiceGuid}.guidToItemMap.${itemGuid}.soldRevenueAttributionUserGuids`,
                  {
                    defaultValue: soldRevenueAttributionUserGuids,
                  },
                )
              },
            )
          },
        )

        // We call the resetField API here to set the form's default value to this new
        // value. This ensures that if the user re-edits this subform and then cancels,
        // the values will be reset to the last saved values, rather than the initial
        // values from when the Job Outcomes Modal was first rendered
        form.resetField('isTurnedOverByTechnician', {
          defaultValue: newValues.isTurnedOverByTechnician,
        })

        form.resetField('technicianTurnoverUserGuids', {
          defaultValue: newValues.technicianTurnoverUserGuids,
        })
      },
      [assignedTechnicianUserGuids, form, setEditingPanel],
    )

    const onRevenueAttributionSubformSubmit = useCallback(
      (values: JobOutcomesRevenueAttributionSubformSchema) => {
        setEditingPanel(null)
      },
      [setEditingPanel],
    )

    const onCommissionDeductionsSubformSubmit = useCallback(
      (values: JobOutcomesCommissionDeductionsPopoverFormSchema) => {},
      [],
    )

    const onSubmit = form.handleSubmit(async values => {
      try {
        await jobOutcomesUpsertMutation.mutateAsync({
          jobGuid: job.jobGuid,
          isConverted: willJobBeConverted,
          isMembershipSold: !isNullish(jobSoldMaintenancePlan),
          isTurnedOverByTechnician: values.isTurnedOverByTechnician,
          technicianTurnoverUserGuids: values.isTurnedOverByTechnician
            ? values.technicianTurnoverUserGuids
            : [],
          revenueAttributionGuidToInvoiceMap:
            values.revenueAttributionGuidToInvoiceMap,
          overheadAllocationDeductionType:
            values.overheadAllocationDeductionType,
          overheadAllocationDeductionPercent:
            values.overheadAllocationDeductionPercent,
          overheadAllocationFlatDeductionUsd:
            values.overheadAllocationFlatDeductionUsd,
          jobCostsDeductionType: values.jobCostsDeductionType,
          jobCostsDeductionPercent: values.jobCostsDeductionPercent,
          jobCostsFlatDeductionUsd: values.jobCostsFlatDeductionUsd,
          technicianGuidToCommissionAndBonusMap:
            values.technicianGuidToCommissionAndBonusMap,
        })

        onOk?.()
      } catch (err) {
        message.error(
          'Failed to complete job. Please reload the application and try again. If the problem persists, please contact support',
        )
        console.error(err)
      }
    })

    return (
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <h2>Job Outcomes</h2>
          <ChipsPanel
            job={job}
            isJobConverted={willJobBeConverted}
            isMembershipSold={!isNullish(jobSoldMaintenancePlan)}
            isJobWorkComplete={true}
          />
          {maintenancePlansEnabled && (
            <>
              <ThinDivider
                widthPx={1}
                styleOverrides={{ marginTop: '24px', marginBottom: '24px' }}
              />
              <SoldMaintenancePlanPanel
                soldMaintenancePlan={jobSoldMaintenancePlan}
              />
            </>
          )}
          <ThinDivider
            widthPx={1}
            styleOverrides={{ marginTop: '24px', marginBottom: '24px' }}
          />
          <InvoicesPanel paidInvoices={invoices} />
          <ThinDivider
            widthPx={1}
            styleOverrides={{ marginTop: '24px', marginBottom: '24px' }}
          />
          <TechnicianTurnoverPanel
            isEditing={editingPanel === 'TECHNICIAN_TURNOVER'}
            disableEditButton={
              !isNullish(editingPanel) && editingPanel !== 'TECHNICIAN_TURNOVER'
            }
            onClickEditButton={() => setEditingPanel('TECHNICIAN_TURNOVER')}
            onClickCancelEditingButton={() => setEditingPanel(null)}
            onSubformSubmit={onTechnicianTurnoverSubformSubmit}
          />
          <ThinDivider
            widthPx={1}
            styleOverrides={{ marginTop: '24px', marginBottom: '24px' }}
          />
          <RevenueAttributionPanel
            job={job}
            paidInvoices={invoices}
            allAttributableUsers={allAttributableUsers}
            isEditing={editingPanel === 'REVENUE_ATTRIBUTION'}
            disableEditButton={
              !isNullish(editingPanel) && editingPanel !== 'REVENUE_ATTRIBUTION'
            }
            onClickEditButton={() => setEditingPanel('REVENUE_ATTRIBUTION')}
            onClickCancelEditingButton={() => setEditingPanel(null)}
            onSubformSubmit={onRevenueAttributionSubformSubmit}
            onCommissionDeductionsPopoverSubformSubmit={
              onCommissionDeductionsSubformSubmit
            }
          />
          <ThinDivider widthPx={1} />
          {/* Submitting our react-hook-form directly through antd component doesn't seem
                to work, so we create this hidden button with an attached ref so we can use it
                to trigger a form submission */}
          <input ref={btnFormSubmitRef} type="submit" className="hidden" />
          {firstError && isNullish(editingPanel) && (
            <Alert message={firstError} type="error" showIcon />
          )}
        </form>
      </FormProvider>
    )
  },
)
