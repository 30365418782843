import {
  ArgumentException,
  BzDateFns,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  Instant,
  IsoDateString,
  LocalDate,
  LocalDateString,
  LocalTime,
  TimeZoneId,
  ZonedDateTime,
  ZoneId,
} from '../../common'
import { BzTimeWindow } from './TimeWindow'

type BzDateTimeDto = {
  timeZoneId: TimeZoneId
  epochMillis: number
}

// We know "UTC" is a valid time zone id
// eslint-disable-next-line breezy/no-to-time-zone-id
export const UTC_TIME_ZONE = BzDateFns.toTimeZoneId('UTC')

// TODO: https://getbreezyapp.atlassian.net/browse/BZ-1016
export class BzDateTime {
  static nowUtcIsoString = (): IsoDateString => this.now(UTC_TIME_ZONE).toIsoString() as IsoDateString
  static startOfTodayUtcLocalDateString = (): LocalDateString => this.now(UTC_TIME_ZONE).toLocalDateString()
  static now = (tzid: TimeZoneId): BzDateTime => new BzDateTime(Instant.now().toEpochMilli(), tzid)
  static nowUtc = (): BzDateTime => new BzDateTime(Instant.now().toEpochMilli(), UTC_TIME_ZONE)
  static startOfMonth = (tzid: TimeZoneId): BzDateTime =>
    new BzDateTime(
      ZonedDateTime.now(ZoneId.of(tzid))
        .withDayOfMonth(1)
        .withHour(0)
        .withMinute(0)
        .withSecond(0)
        .withNano(0)
        .toInstant()
        .toEpochMilli(),
      tzid,
    )

  static startOfWeek = (tzid: TimeZoneId, startOfWeekDayNumber = 0): BzDateTime => {
    let day = this.startOfToday(tzid)
    while (day.toDate().getDay() !== startOfWeekDayNumber) day = day.minusDays(1)
    return day
  }

  static startOfToday = (tzid: TimeZoneId): BzDateTime =>
    new BzDateTime(
      ZonedDateTime.now(ZoneId.of(tzid)).withHour(0).withMinute(0).withSecond(0).withNano(0).toInstant().toEpochMilli(),
      tzid,
    )
  static startOfTomorrow = (tzid: TimeZoneId): BzDateTime => this.startOfToday(tzid).plusDays(1)
  static startOfDay = (tzId: TimeZoneId, year: number, month: number, day: number) =>
    new BzDateTime(ZonedDateTime.of(year, month, day, 0, 0, 0, 0, ZoneId.of(tzId)).toInstant().toEpochMilli(), tzId)

  static fromDto = (dto: BzDateTimeDto): BzDateTime => new BzDateTime(dto.epochMillis, dto.timeZoneId)
  static fromDate = (date: Date, timeZoneId: TimeZoneId): BzDateTime => new BzDateTime(date.valueOf(), timeZoneId)
  static fromDateString = (
    // We don't make this a LocalDateString because it doesn't necessarily match if `localDateFormat` is set
    localDateString: string,
    timeZoneId: TimeZoneId,
    localDateFormat?: string,
  ): BzDateTime =>
    this.fromJsJoda(
      ZonedDateTime.of(
        LocalDate.parse(localDateString, localDateFormat ? DateTimeFormatter.ofPattern(localDateFormat) : undefined),
        LocalTime.MIDNIGHT,
        ZoneId.of(timeZoneId),
      ),
    )
  static fromIsoString = (iso: IsoDateString, tzId: TimeZoneId): BzDateTime => new BzDateTime(Date.parse(iso), tzId)
  static fromJsJoda = (zdt: ZonedDateTime): BzDateTime =>
    // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1016
    // eslint-disable-next-line breezy/no-to-time-zone-id
    new BzDateTime(zdt.toInstant().toEpochMilli(), BzDateFns.toTimeZoneId(zdt.zone().id()))

  constructor(private readonly epochMills: number, private readonly timeZoneId: TimeZoneId) {
    if (Number.isNaN(epochMills)) throw new ArgumentException('epochMillis was NaN')
  }

  // Standard Conversions
  toString = (): string => this.toJsJoda().toString()
  toLocalDateString = (): LocalDateString => this.toJsJoda().toLocalDate().toString() as LocalDateString
  toDate = (): Date => new Date(this.epochMills)
  toDto = (): BzDateTimeDto => ({ timeZoneId: this.timeZoneId, epochMillis: this.epochMills })
  toIsoString = (): IsoDateString => new Date(this.epochMills).toISOString()
  toIsoDateString = (): IsoDateString => this.toJsJoda().toLocalDate().toString() as IsoDateString
  toJsJoda = (): ZonedDateTime =>
    ZonedDateTime.ofInstant(Instant.ofEpochMilli(this.epochMills), ZoneId.of(this.timeZoneId))
  toJsJodaLocalDate = (): LocalDate =>
    ZonedDateTime.ofInstant(Instant.ofEpochMilli(this.epochMills), ZoneId.of(this.timeZoneId)).toLocalDate()
  toJsJodaUtcLocalDate = (): LocalDate =>
    ZonedDateTime.ofInstant(Instant.ofEpochMilli(this.epochMills), ZoneId.of('UTC')).toLocalDate()
  toDateFormat = (pattern: string): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern(pattern).withLocale(ENGLISH_LOCALE))
  toHumanFriendlyMonthYear = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('MMM yyyy').withLocale(ENGLISH_LOCALE))
  toHumanFriendlyDateTimeString = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('eeee, MMM d, yyyy h:mm a').withLocale(ENGLISH_LOCALE))
  toHumanFriendlyDayName = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('eeee').withLocale(ENGLISH_LOCALE))
  toHumanFriendlyShortDayName = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('eee').withLocale(ENGLISH_LOCALE))
  toHumanFriendlyMonthDay = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('MMM d').withLocale(ENGLISH_LOCALE))
  toHumanFriendlyMonthDayYear = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(ENGLISH_LOCALE))
  toHumanFriendlyMonthDayShortenedYear = (): string =>
    `${this.toJsJoda().format(
      DateTimeFormatter.ofPattern('MMM d, ').withLocale(ENGLISH_LOCALE),
    )}'${this.toJsJoda().format(DateTimeFormatter.ofPattern('yy').withLocale(ENGLISH_LOCALE))}`
  toHumanFriendlyYear = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('yyyy').withLocale(ENGLISH_LOCALE))
  toBasicDateTimeString = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('MMM d, yyyy h:mm a').withLocale(ENGLISH_LOCALE))
  toHighPrecisionDateTimeString = (): string =>
    this.toJsJoda().format(DateTimeFormatter.ofPattern('MMM d, yyyy h:mm:ss.SSS a').withLocale(ENGLISH_LOCALE))

  dayOfMonth = (): number => this.toJsJoda().dayOfMonth()

  // Comparisons
  isAfter = (other: BzDateTime): boolean => this.epochMills > other.epochMills
  isBefore = (other: BzDateTime): boolean => this.epochMills < other.epochMills

  // Convenience Factories
  plusDays = (n: number): BzDateTime =>
    new BzDateTime(this.toJsJoda().plusDays(n).toInstant().toEpochMilli(), this.timeZoneId)
  plusMonths = (n: number): BzDateTime =>
    new BzDateTime(this.toJsJoda().plusMonths(n).toInstant().toEpochMilli(), this.timeZoneId)
  plusYears = (n: number): BzDateTime =>
    new BzDateTime(this.toJsJoda().plusYears(n).toInstant().toEpochMilli(), this.timeZoneId)
  atStartOfDay = (): BzDateTime =>
    new BzDateTime(
      this.toJsJoda().withHour(0).withMinute(0).withSecond(0).withNano(0).toInstant().toEpochMilli(),
      this.timeZoneId,
    )
  atEndOfDay = (): BzDateTime =>
    new BzDateTime(
      this.toJsJoda().withHour(23).withMinute(59).withSecond(59).withNano(999999999).toInstant().toEpochMilli(),
      this.timeZoneId,
    )

  minusMinutes = (n: number): BzDateTime =>
    new BzDateTime(this.toJsJoda().minusMinutes(n).toInstant().toEpochMilli(), this.timeZoneId)
  minusDays = (n: number): BzDateTime =>
    new BzDateTime(this.toJsJoda().minusDays(n).toInstant().toEpochMilli(), this.timeZoneId)
  minusMonths = (n: number): BzDateTime =>
    new BzDateTime(this.toJsJoda().minusMonths(n).toInstant().toEpochMilli(), this.timeZoneId)
  /** Exposes the ability to use any standard JsJoda code to project a new BzDateTime value */
  jsJodaMut = (op: (zdt: ZonedDateTime) => ZonedDateTime): BzDateTime => BzDateTime.fromJsJoda(op(this.toJsJoda()))

  // Time Windows
  forDays = (n: number): BzTimeWindow => this.through(this.plusDays(n))
  forMonths = (n: number): BzTimeWindow => this.through(this.jsJodaMut(zdt => zdt.plusMonths(n)))
  through = (other: BzDateTime): BzTimeWindow => new BzTimeWindow(this.toJsJoda(), other.toJsJoda())
}
