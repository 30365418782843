import {
  IconDefinition,
  faArrowsRotate,
  faHouseChimneyUser,
  faPrintMagnifyingGlass,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classnames from 'classnames'
import { m } from '../../utils/react-utils'

type GlobalSearchResultLoadStateProps = {
  icon: { icon: IconDefinition; className: string }
  title: string
  message: string
  onRetry?: () => void
}

const GlobalSearchResultLoadState = m<GlobalSearchResultLoadStateProps>(
  ({ icon, title, message, onRetry }) => (
    <div className="flex w-full flex-col items-center text-center">
      <div
        className={classnames([
          'flex flex-col items-center justify-center',
          'mb-6 h-14 w-14',
          'rounded-full bg-white drop-shadow-md',
        ])}
      >
        <FontAwesomeIcon
          icon={icon.icon}
          size="2x"
          className={icon.className}
        />
      </div>
      <h2 className="mb-2 text-xl font-semibold text-bz-gray-700">{title}</h2>
      <p className="text-sm text-bz-gray-700">{message}</p>
      {onRetry && (
        <Button
          type="link"
          className="flex flex-row items-center justify-center space-x-2"
          onClick={onRetry}
        >
          <FontAwesomeIcon icon={faArrowsRotate} className="text-bz-primary" />
          Try Again
        </Button>
      )}
    </div>
  ),
)

export const GlobalSearchResultItemPreviewEmpty = () => (
  <GlobalSearchResultLoadState
    icon={{ icon: faHouseChimneyUser, className: 'text-bz-primary' }}
    title="Account Preview"
    message="Select an account from the search results to view its details"
  />
)

type GlobalSearchResultErrorProps = {
  icon: IconDefinition
  title: string
  message: string
  onTryAgainClick: () => void
}

export const GlobalSearchResultError = ({
  icon,
  title,
  message,
  onTryAgainClick,
}: GlobalSearchResultErrorProps) => (
  <GlobalSearchResultLoadState
    icon={{ icon: icon, className: 'text-[#FF7A45]' }}
    title={title}
    message={message}
    onRetry={onTryAgainClick}
  />
)

type GlobalSearchResultNotFoundProps = {
  noResultQueryString: string
}

export const GlobalSearchResultNotFound = ({
  noResultQueryString,
}: GlobalSearchResultNotFoundProps) => (
  <GlobalSearchResultLoadState
    icon={{ icon: faPrintMagnifyingGlass, className: 'text-bz-primary' }}
    title="No Results"
    message={`No accounts matched "${noResultQueryString}". Please try again`}
  />
)
