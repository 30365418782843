import { z } from 'zod'
import { AccountGuid } from '../Accounts/Account'
import { EstimateGuid } from '../Finance/Estimates/EstimateTypes'
import { InvoiceGuid } from '../Finance/Invoicing/InvoiceTypes'
import { JobGuid } from '../Job'
import { LocationGuid } from '../Locations/Location'
import { NoteGuid } from '../Notes/NoteTypes'
import { bzOptional } from '../common-schemas'

const baseSearchableRecordSchema = z.object({
  objectID: z.string().min(1),
  companyGuid: z.string().uuid(),
})

export const accountSearchableRecordSchema = baseSearchableRecordSchema.extend({
  recordType: z.literal('ACCOUNT'),
  accountGuid: z.string().uuid(),
  accountDisplayName: z.string().min(1),
  archived: bzOptional(z.boolean()),
  primaryPhoneNumber: bzOptional(z.string().min(1)),
  primaryEmailAddress: bzOptional(z.string().min(1)),
  contacts: z
    .object({
      name: z.string().min(1),
      phoneNumber: bzOptional(z.string().min(1)),
      emailAddress: bzOptional(z.string().min(1)),
    })
    .array()
    .min(1),
  tags: bzOptional(z.string().min(1).array()),
})
export type AccountSearchableRecord = z.infer<typeof accountSearchableRecordSchema>
export const createAccountSearchableRecordId = (accountGuid: AccountGuid) => `account#${accountGuid}`

export const jobSearchableRecordSchema = accountSearchableRecordSchema.extend({
  recordType: z.literal('JOB'),
  jobGuid: z.string().uuid(),
  jobRefId: z.string().min(1),
})
export type JobSearchableRecord = z.infer<typeof jobSearchableRecordSchema>
export const createJobSearchableRecordId = (jobGuid: JobGuid) => `job#${jobGuid}`

export const locationSearchableRecordSchema = accountSearchableRecordSchema.extend({
  recordType: z.literal('LOCATION'),
  locationGuid: z.string().uuid(),
  locationDisplayName: z.string().min(1),
  streetAddress: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(1),
  zipCode: z.string().min(1),
})
export type LocationSearchableRecord = z.infer<typeof locationSearchableRecordSchema>
export const createLocationSearchableRecordId = (locationGuid: LocationGuid) => `location#${locationGuid}`

export const noteSearchableRecordSchema = accountSearchableRecordSchema.extend({
  recordType: z.literal('NOTE'),
  noteGuid: z.string().uuid(),
  noteContent: z.string().min(1),
})
export type NoteSearchableRecord = z.infer<typeof noteSearchableRecordSchema>
export const createNoteSearchableRecordId = (noteGuid: NoteGuid) => `note#${noteGuid}`

export const invoiceSearchableRecordSchema = accountSearchableRecordSchema.extend({
  recordType: z.literal('INVOICE'),
  invoiceGuid: z.string().uuid(),
  invoiceDisplayId: z.string().min(1),
  invoiceRefNumber: bzOptional(z.string().min(1)),
})
export type InvoiceSearchableRecord = z.infer<typeof invoiceSearchableRecordSchema>
export const createInvoiceSearchableRecordId = (invoiceGuid: InvoiceGuid) => `invoice#${invoiceGuid}`

export const estimateSearchableRecordSchema = accountSearchableRecordSchema.extend({
  recordType: z.literal('ESTIMATE'),
  estimateGuid: z.string().uuid(),
  estimateRefId: z.string().min(1),
})
export type EstimateSearchableRecord = z.infer<typeof estimateSearchableRecordSchema>
export const createEstimateSearchableRecordId = (estimateGuid: EstimateGuid) => `estimate#${estimateGuid}`

export const equipmentSearchableRecordSchema = accountSearchableRecordSchema.extend({
  recordType: z.literal('EQUIPMENT'),
  equipmentGuid: z.string().uuid(),
  manufacturer: z.string().min(1),
  modelNumber: z.string().min(1),
  serialNumber: z.string().min(1),
})
export type EquipmentSearchableRecord = z.infer<typeof equipmentSearchableRecordSchema>

export const searchableRecordSchema = z.discriminatedUnion('recordType', [
  accountSearchableRecordSchema,
  jobSearchableRecordSchema,
  locationSearchableRecordSchema,
  noteSearchableRecordSchema,
  invoiceSearchableRecordSchema,
  estimateSearchableRecordSchema,
  equipmentSearchableRecordSchema,
])

/**
 * A searchable record that is used by an Searcher
 */
export type SearchableRecord = z.infer<typeof searchableRecordSchema>
