import { gql } from '../../generated'

export const STATUS_CARD_QUERY = gql(/* GraphQL */ `
  subscription UserTimeClockStatusCardQuery(
    $companyGuid: uuid!
    $userGuid: uuid!
    $startTime: timestamptz!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        finalStartTime: { _gte: $startTime }
      }
      orderBy: { finalStartTime: ASC, finalEndTime: DESC_NULLS_LAST }
    ) {
      finalStartTime
      finalEndTime
      entryLengthInMinutes
      timesheetEntryActivity {
        activityName
      }
      timesheetEntryLinkData {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
      }
    }
  }
`)

export const CURRENT_ASSIGNMENT_TIMESHEET_ENTRIES_QUERY = gql(/* GraphQL */ `
  subscription UserAssignmentTimeClockStatusCardCurrentAssignmentTimesheetEntriesQuery(
    $companyGuid: uuid!
    $userGuid: uuid!
    $jobAppointmentAssignmentGuid: uuid!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        timesheetEntryLinkData: {
          jobAppointmentAssignmentGuid: { _eq: $jobAppointmentAssignmentGuid }
        }
      }
      orderBy: { finalStartTime: ASC, finalEndTime: ASC_NULLS_LAST }
    ) {
      finalStartTime
      finalEndTime
      entryLengthInMinutes
      timesheetEntryActivity {
        activityName
        isPayable
      }
      timesheetEntryLinkData {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
      }
    }
  }
`)
