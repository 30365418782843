import { noOp } from '@breezy/shared'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import {
  faAirConditioner,
  faBarcodeRead,
  faCommentLines,
  faFileContract,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faImage,
  faReceipt,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTechExpDrawers } from '../../../TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import { AppointmentDetailProps } from '../AppointmentDetail'

import {
  OnsiteMenuItem,
  OnsiteMenuItemProps,
} from '../../../../../adam-components/OnsiteMenuItem'
import { OnsiteBasicModal } from '../../../../../adam-components/OnsiteModal/OnsiteModal'
import { useCanManageJob } from '../../../../../hooks/permission/useCanManageJob'
import useAppNavigation from '../../../../../hooks/useAppNav'
import { useFeatureFlag } from '../../../../../hooks/useFeatureFlags'
import { JOB_APPOINTMENT_GUID_QUERY_PARAM } from '../../../../../pages/EstimatesFlow/estimatesFlowUtils'
import { useWisetackEnabled } from '../../../../../providers/CompanyFinancialConfigWrapper'
import { useModalState } from '../../../../../utils/react-utils'
import { useFinancingWizard } from '../../../../Financing/hooks/useFinancingWizard'
import { LabelScanner } from '../../../../LabelScanner/LabelScanner'
import './AppointmentActionMenu.less'

import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { PrefillEquipmentFormData } from '../../../../EquipmentUpsertForm/EquipmentUpsertForm'

export const AppointmentActionMenu = ({
  appt,
  apptAssignmentGuid,
  onMutate = noOp,
}: AppointmentDetailProps & { apptAssignmentGuid?: string | undefined }) => {
  const isWisetackEnabled = useWisetackEnabled()

  const navigate = useNavigate()
  const appNav = useAppNavigation()
  const drawers = useTechExpDrawers()
  const [isOpen, setIsOpen] = useState(false)
  const { canManage: canManageJob } = useCanManageJob(appt.jobGuid)
  const labelScannerEnabled = useFeatureFlag('label-scanner')

  const { showFinancingWizard, financingWizard } = useFinancingWizard({
    accountGuid: appt.accountGuid,
    jobGuid: appt.jobGuid,
    onCancel: onMutate,
  })

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'appt-action-menu')

  const onMaintenancePlanWizardClose = useCallback(() => {
    closeMaintenancePlanWizard()
    onMutate()
  }, [closeMaintenancePlanWizard, onMutate])

  const hide = useCallback(() => {
    setIsOpen(false)
    closeMaintenancePlanWizard()
  }, [setIsOpen, closeMaintenancePlanWizard])
  const show = useCallback(() => setIsOpen(true), [setIsOpen])

  const photoLinks = useMemo(
    () => ({
      locationGuid: appt.locationGuid,
      accountGuid: appt.accountGuid,
      jobGuid: appt.jobGuid,
      jobAppointmentGuid: appt.appointment.guid,
      apptAssignmentGuid: apptAssignmentGuid,
    }),
    [
      appt.locationGuid,
      appt.accountGuid,
      appt.jobGuid,
      appt.appointment.guid,
      apptAssignmentGuid,
    ],
  )

  const noteLinks = useMemo(
    () => ({
      locationGuid: appt.locationGuid,
      accountGuid: appt.accountGuid,
      jobGuid: appt.jobGuid,
      jobAppointmentGuid: appt.appointment.guid,
      apptAssignmentGuid: apptAssignmentGuid,
    }),
    [
      appt.locationGuid,
      appt.accountGuid,
      appt.jobGuid,
      appt.appointment.guid,
      apptAssignmentGuid,
    ],
  )

  const onAddNoteFinished = onMutate
  const onPhotoUploadFinished = onMutate
  const onUpsertInstalledEquipmentFinished = onMutate
  const onUpsertInstalledHvacSystemFinished = onMutate

  const [labelScannerOpen, openLabelScannerRaw, closeLabelScanner] =
    useModalState()

  const openLabelScanner = useCallback(() => {
    hide()
    openLabelScannerRaw()
  }, [hide, openLabelScannerRaw])

  const actionItems = useMemo(() => {
    const items: OnsiteMenuItemProps[] = []

    if (labelScannerEnabled) {
      items.push({
        label: 'Scan Equipment Label',
        icon: faBarcodeRead,
        onClick: openLabelScanner,
      })
    }

    if (canManageJob) {
      items.push({
        label: 'Create Estimate',
        onClick: () => {
          hide()
          navigate({
            pathname: `/jobs/${appt.jobGuid}/new-estimate`,
            search: `${JOB_APPOINTMENT_GUID_QUERY_PARAM}=${appt.appointment.guid}`,
          })
        },
        icon: faReceipt,
      })

      items.push({
        label: 'Create Invoice',
        onClick: () => {
          hide()
          appNav.navigateToCreateNewInvoiceV2(appt.accountGuid, appt.jobGuid, {
            jobAppointmentGuid: appt.appointment.guid,
          })
        },
        icon: faFileInvoiceDollar,
      })

      if (isWisetackEnabled) {
        items.push({
          label: 'Send Financing Link',
          onClick: () => {
            hide()
            showFinancingWizard()
          },
          icon: faHandHoldingUsd,
        })
      }

      items.push({
        label: 'Add Photos',
        onClick: () => {
          hide()
          drawers.beginUploadPhoto(photoLinks, onPhotoUploadFinished)
        },
        icon: faImage,
      })

      items.push({
        label: 'Add Note',
        onClick: () => {
          hide()
          drawers.beginAddNote(
            { ...noteLinks, primaryNoteType: 'JOB_APPOINTMENT' },
            onAddNoteFinished,
          )
        },
        icon: faCommentLines,
      })

      items.push({
        label: 'Add Equipment',
        onClick: () => {
          hide()
          drawers.beginUpsertInstalledEquipment(
            appt.location,
            undefined,
            onUpsertInstalledEquipmentFinished,
          )
        },
        icon: faScrewdriverWrench,
      })

      items.push({
        label: 'Add HVAC System',
        onClick: () => {
          hide()
          drawers.beginUpsertHvacSystem(
            appt.location,
            undefined,
            onUpsertInstalledHvacSystemFinished,
          )
        },
        icon: faAirConditioner,
      })
    }

    items.splice(
      items.findIndex(item => item.label === 'Add Photos'),
      0,
      {
        label: 'Create Maintenance Plan',
        onClick: () => {
          hide()
          openMaintenancePlanWizard()
        },
        icon: faFileContract,
      },
    )

    return items
  }, [
    labelScannerEnabled,
    canManageJob,
    openLabelScanner,
    isWisetackEnabled,
    hide,
    navigate,
    appt,
    appNav,
    showFinancingWizard,
    drawers,
    photoLinks,
    onPhotoUploadFinished,
    noteLinks,
    onAddNoteFinished,
    onUpsertInstalledEquipmentFinished,
    onUpsertInstalledHvacSystemFinished,
    openMaintenancePlanWizard,
  ])

  const onLabelScannerAddEquipment = useCallback(
    (summary: PrefillEquipmentFormData) => {
      hide()
      drawers.beginUpsertInstalledEquipment(
        appt.location,
        summary,
        onUpsertInstalledEquipmentFinished,
      )
    },
    [appt.location, drawers, hide, onUpsertInstalledEquipmentFinished],
  )

  return (
    <>
      {isOpen && (
        <OnsiteBasicModal
          open={isOpen}
          onClose={hide}
          header="Appointment actions"
          headerBordered
          children={
            <div className="flex flex-col gap-4">
              {actionItems.map((item, index) => (
                <OnsiteMenuItem key={index} {...item} />
              ))}
            </div>
          }
        />
      )}
      <div className="speed-dial-button-wrapper">
        <Button className="speed-dial-button" type="primary" onClick={show}>
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: 24 }} />
        </Button>
      </div>
      {financingWizard}
      {maintenancePlanWizardOpen && (
        <MaintenancePlanWizard
          onRamp="appt-action-menu"
          accountGuid={appt.accountGuid}
          locationGuid={appt.locationGuid}
          jobGuid={appt.jobGuid}
          onClose={onMaintenancePlanWizardClose}
        />
      )}
      {labelScannerOpen && (
        <LabelScanner
          onClose={closeLabelScanner}
          links={photoLinks}
          onAddEquipment={onLabelScannerAddEquipment}
        />
      )}
    </>
  )
}
