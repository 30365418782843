import { AsyncFn, BzDateFns, IsoDateString, TimeZoneId, nextGuid } from '../../common'
import {
  InternalNotificationAccountReminderDueMetadata,
  InternalNotificationEstimateMetadata,
  InternalNotificationMetadata,
  InternalNotificationTaggedInNoteMetadata,
} from '../../contracts/InternalNotifications'
import { AccountReminder } from '../AccountReminders/AccountReminders'
import { AppointmentType } from '../Appointments/Appointments'
import { ForCompanyUser } from '../Company/Company'
import { CreateLinkedNoteDTO } from '../Notes/NoteTypes'
import { Person } from '../Person'
import { UserGuid } from '../Users/User'
import { Guid } from '../common-schemas'

export const InternalNotificationStatusValues = ['UNACKNOWLEDGED', 'ACKNOWLEDGED'] as const

export type InternalNotificationStatus = (typeof InternalNotificationStatusValues)[number]

type InternalNotificationGuid = Guid
export type InternalNotification = {
  internalNotificationGuid: InternalNotificationGuid
  userGuid: UserGuid
  status: InternalNotificationStatus
  createdAt: IsoDateString
  acknowledgedAt?: IsoDateString
  content: string
  metadata: InternalNotificationMetadata
}

export type InternalNotificationMetadataType = InternalNotificationMetadata['type']

export function isInternalNotificationTaggedInNoteMetadata(
  meta: InternalNotificationMetadata,
): meta is InternalNotificationTaggedInNoteMetadata {
  return meta.type === 'TAGGED_IN_NOTE'
}

export function isInternalNotificationAccountReminderDueMetadata(
  meta: InternalNotificationMetadata,
): meta is InternalNotificationAccountReminderDueMetadata {
  return meta.type === 'ACCOUNT_REMINDER_DUE'
}

export function isInternalNotificationEstimateMetadata(
  meta: InternalNotificationMetadata,
): meta is InternalNotificationEstimateMetadata {
  return meta.type === 'ESTIMATE'
}

export type WritableInternalNotification = Omit<InternalNotification, 'createdAt' | 'acknowledgedAt'>

export type InternalNotificationsRequest = {
  type: 'all' | 'unacknowledged'
}

export type InternalNotificationAcknowledgementRequest = {
  internalNotificationGuid: InternalNotificationGuid
}

export type IInternalNotificationReader = AsyncFn<ForCompanyUser<InternalNotificationsRequest>, InternalNotification[]>
export type IInternalNotificationWriter = AsyncFn<WritableInternalNotification[], void>
export type IInternalNotificationAcknowledger = AsyncFn<InternalNotificationAcknowledgementRequest, void>

export const makeNotificationsFromNote = (input: CreateLinkedNoteDTO, user: Person): WritableInternalNotification[] => {
  const { linkData } = input
  if (linkData.primaryNoteType === 'PHOTO' || linkData.primaryNoteType === 'PAYMENT_RECORD') {
    return []
  }

  return input.taggedUsers.map(taggedUser => {
    return {
      internalNotificationGuid: nextGuid(),
      userGuid: taggedUser,
      status: 'UNACKNOWLEDGED',
      content: input.value,
      metadata: {
        type: 'TAGGED_IN_NOTE',
        noteGuid: input.noteGuid,
        taggedByUser: user,
        sourceData: linkData,
      },
    }
  })
}

export const makeNotificationsFromAccountReminder = (input: AccountReminder): WritableInternalNotification[] => {
  return input.reminder.reminderAssignments.map(assignment => {
    return {
      internalNotificationGuid: nextGuid(),
      userGuid: assignment.user.userGuid,
      status: 'UNACKNOWLEDGED',
      content: input.reminder.description,
      metadata: {
        type: 'ACCOUNT_REMINDER_DUE',
        reminderGuid: input.reminder.reminderGuid,
        accountGuid: input.accountGuid,
        accountDisplayName: input.account.accountDisplayName,
        dueAt: input.reminder.dueAt,
      },
    }
  })
}

export const makeNotificationsForEstimateOperation = ({
  estimateGuid,
  operation,
  jobTypeName,
  location,
  displayId,
  reviewedAt,
  tzId,
  userGuids,
  jobAppointmentType,
  contactFullName,
}: {
  estimateGuid: Guid
  operation: InternalNotificationEstimateMetadata['operation']
  jobTypeName: string
  location: string
  displayId: number
  reviewedAt: IsoDateString
  tzId: TimeZoneId
  userGuids: UserGuid[]
  jobAppointmentType?: AppointmentType
  contactFullName: string
}): WritableInternalNotification[] => {
  const timestamp = BzDateFns.formatFromISO(reviewedAt, 'h:mm a', tzId)
  return userGuids.map(userGuid => {
    return {
      internalNotificationGuid: nextGuid(),
      userGuid,
      status: 'UNACKNOWLEDGED',
      content: `The estimate created for the ${jobTypeName} ${
        jobAppointmentType ?? 'job'
      } at ${location} (Estimate #${displayId}) was ${
        operation === 'ACCEPTED' ? 'accepted' : 'viewed'
      } today at ${timestamp}`,
      metadata: {
        type: 'ESTIMATE',
        estimateGuid,
        operation,
        contactFullName,
      },
    }
  })
}

export type ReminderInternalNotificationType = 'ACCOUNT_REMINDER_DUE'

export type ReminderInternalNotification = {
  reminderGuid: Guid
  internalNotificationGuid: InternalNotificationGuid
  type: ReminderInternalNotificationType
  createdAt: IsoDateString
}

export type ReminderInternalNotificationWriterInput = {
  reminderGuid: Guid
  internalNotificationGuid: InternalNotificationGuid
  type: ReminderInternalNotificationType
}

export type ReminderInternalNotificationWriter = AsyncFn<ReminderInternalNotificationWriterInput, void>
