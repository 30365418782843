import {
  BzDateFns,
  CompanyGuid,
  EmailAddressLinks,
  EmailAddressValue,
  formatMoney,
  PaymentMethodDisplayNames,
  PaymentRecordGuid,
  TimeZoneId,
  usCentsToUsd,
} from '@breezy/shared'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import { DetailRow } from '../../../../elements/StatusCard/DetailRow'
import { StatusCard } from '../../../../elements/StatusCard/StatusCard'
import { trpc } from '../../../../hooks/trpc'
import { useMessage } from '../../../../utils/antd-utils'
import { LoadingSpinner } from '../../../LoadingSpinner'
import { SendEmailForm } from '../SendEmailForm/SendEmailForm'
import { CommonPaymentStatusProps } from './PaymentStatusCard'

export type PaymentSuccessCardProps = CommonPaymentStatusProps & {
  /* The invoice display ID  e.g #10013 */
  invoiceDisplayId: number

  /* The email address links */
  emailAddressLinks: EmailAddressLinks

  /* The guid for the newly created payment record */
  paymentRecordGuid: PaymentRecordGuid

  companyGuid: CompanyGuid

  /* The timezone ID for the company */
  tzId: TimeZoneId

  /* Whether to add padding to the bottom of the card */
  bottomPadding?: boolean
}

export const PaymentSuccessCard = React.memo<
  PaymentSuccessCardProps & {
    onClose?: () => void
  }
>(
  ({
    nameOfPayer,
    paymentAmountUsc,
    paymentMethod,
    invoiceDisplayId,
    emailAddressLinks,
    paymentRecordGuid,
    onClose,
    tzId,
    companyGuid,
    bottomPadding = true,
  }) => {
    const message = useMessage()
    const [sendEmail, setSendEmail] = useState(false)

    const getPaymentReceiptEmail = trpc.payments[
      'unauth:payments:get-receipt-email-address'
    ].useQuery({ ...emailAddressLinks, companyGuid })

    const email = useMemo(
      () => getPaymentReceiptEmail.data ?? '',
      [getPaymentReceiptEmail.data],
    )

    const emailReceiptMut =
      trpc.payments['unauth:payments:email-receipt'].useMutation()
    const emailReceipt = useCallback(
      async (emailAddress: EmailAddressValue) => {
        try {
          await emailReceiptMut.mutateAsync({
            paymentRecordGuid: paymentRecordGuid,
            to: emailAddress,
            companyGuid,
          })
          message.success('Emailed receipt!')
          onClose?.()
        } catch (e) {
          message.error('Failed to email receipt')
        }
      },
      [emailReceiptMut, paymentRecordGuid, companyGuid, message, onClose],
    )

    const isLoading = getPaymentReceiptEmail.isLoading

    if (isLoading) {
      return (
        <div className="flex min-h-[240px] flex-col items-center justify-center transition-all ease-in-out">
          <LoadingSpinner />
        </div>
      )
    }

    return (
      <StatusCard
        type="success"
        icon={faCheck}
        title="Payment Completed"
        description="A copy of the invoice and payment receipt has been sent to the email address below."
        footer={
          <>
            <RenderIf if={sendEmail}>
              <SendEmailForm
                isLoading={emailReceiptMut.isLoading}
                onSubmit={data => {
                  emailReceipt(data.to)
                }}
                onCancel={() => {
                  setSendEmail(false)
                }}
              />
            </RenderIf>
            <RenderIf if={!sendEmail && !!emailAddressLinks}>
              <div
                className={classNames('mt-4 flex w-full flex-row gap-3', {
                  'pb-6': bottomPadding,
                })}
              >
                <Button
                  className="flex-1"
                  size="large"
                  data-dd-action-name="BZ Payment Workflow - Send Another Copy"
                  onClick={() => {
                    setSendEmail(true)
                  }}
                >
                  Send Another Copy
                </Button>
                <RenderIf if={!!onClose}>
                  <Button
                    className="flex-1"
                    type="primary"
                    size="large"
                    data-dd-action-name="BZ Payment Workflow - Done"
                    onClick={onClose}
                  >
                    Done
                  </Button>
                </RenderIf>
              </div>
            </RenderIf>
          </>
        }
      >
        <DetailRow label="Name of Payer" value={nameOfPayer} />
        <DetailRow
          label="Amount"
          value={formatMoney(usCentsToUsd(paymentAmountUsc))}
        />
        <DetailRow
          label="Payment Method"
          value={PaymentMethodDisplayNames[paymentMethod]}
        />
        <DetailRow
          label="Payment Date"
          value={BzDateFns.format(BzDateFns.now(tzId), 'MMM dd, yyyy')}
        />
        <DetailRow label="Invoice ID" value={`#${invoiceDisplayId}`} />
        <DetailRow
          label="Email Sent To"
          value={email?.length > 0 ? email : 'Missing Email Address'}
        />
      </StatusCard>
    )
  },
)
