import { Divider } from 'antd'
import React, { CSSProperties } from 'react'

type FatDividerProps = {
  dividerStyle?: 'dashed' | 'dotted' | 'solid'
  widthPx?: number
  styleOverrides?: CSSProperties
}

const FatDivider: React.FC<FatDividerProps> = ({
  dividerStyle = 'solid',
  widthPx = 8,
  styleOverrides,
}) => {
  return (
    <Divider
      style={{
        marginTop: 24,
        marginBottom: 24,
        borderTop: `${dividerStyle} ${widthPx}px #f0f0f0`,
        ...styleOverrides,
      }}
    />
  )
}

export default FatDivider
