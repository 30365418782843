import {
  BzDateTime,
  ComprehensivePaymentViewModel,
  OfficeRoutes,
  PaymentStatus,
  PermissionV2,
  createPaymentQboLink,
  formatMoney,
  paymentMethodDisplayName,
  paymentStatusDisplayName,
  truncate,
} from '@breezy/shared'
import {
  faCheckCircle,
  faClock,
  faTruckFast,
  faXmarkCircle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row } from 'antd'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { blue6, cyan6, green6, red6, sunsetOrange6 } from '../../themes/theme'
import { useMessage } from '../../utils/antd-utils'
import LoanDetailsLink from '../Financing/LoanDetailsLink/LoanDetailsLink'
import {
  AccountDetailsLink,
  InvoiceDetailsLink,
  JobDetailsLink,
  MaintenancePlanDetailsLink,
  PayoutDetailsLink,
  UserDetailsLink,
} from '../Links/EntityLinks'
import { Authorized } from '../Permissions/Authorized/Authorized'
import { QuickbooksViewLinkButton } from '../Quickbooks/QuickbooksViewLinkButton'

const paymentStatusStyles = {
  [PaymentStatus.SUBMITTING]: { icon: faClock, color: blue6 },
  [PaymentStatus.PROCESSING]: { icon: faTruckFast, color: cyan6 },
  [PaymentStatus.PENDING]: { icon: faClock, color: sunsetOrange6 },
  [PaymentStatus.PAID]: { icon: faCheckCircle, color: green6 },
  [PaymentStatus.FAILED]: { icon: faXmarkCircle, color: red6 },
  [PaymentStatus.CANCELED]: { icon: faXmarkCircle, color: red6 },
}

const inferInvoiceNumber = (paymentReferenceNumber: string) => {
  const parts = paymentReferenceNumber.split('-')
  return `#${parts[0]}-${parts[1]}`
}

const WithLabel = ({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) => (
  <Row className="two-sides w-full">
    <div className="regular_14_22 grey9 font-bold">{label}</div>
    <div className="regular_14_22 font-semi-bold grey9 ml-2">{children}</div>
  </Row>
)

const PaymentRecordDetailsView = ({
  guid,
  status,
  statusDetail,
  amountUsd,
  invoiceGuid,
  referenceNumber,
  displayId,
  paymentMethod,
  paymentMethodAdditionalInfo,
  occurredAt,
  jobGuid,
  accountGuid,
  collectedByUserGuid,
  userDisplayName,
  note,
  totalRefundedAmountUsd,
  accountDisplayName,
  jobDisplayId,
  payoutGuid,
  loanRecordGuid,
  loanRecord,
  qboEntityId,
  maintenancePlanGuid,
}: ComprehensivePaymentViewModel) => {
  const message = useMessage()
  const tzId = useExpectedCompanyTimeZoneId()
  const statusStyle = paymentStatusStyles[status]
  const emailReceiptMut = trpc.payments['payments:email-receipt'].useMutation()
  const emailReceipt = () => {
    emailReceiptMut.mutate(
      { paymentRecordGuid: guid },
      {
        onSuccess: () => {
          message.success('Email receipt sent')
        },
      },
    )
  }
  const navigate = useNavigate()
  const issueRefund = () =>
    navigate(
      OfficeRoutes.REFUND_PAYMENT.build({
        params: { paymentRecordGuid: guid },
      }),
    )
  const numericTotalRefundedAmountUsd = useMemo(
    () => totalRefundedAmountUsd ?? 0,
    [totalRefundedAmountUsd],
  )

  const qboLink = useMemo(
    () => (qboEntityId ? createPaymentQboLink(qboEntityId) : undefined),
    [qboEntityId],
  )

  return (
    <div
      className="card-no-fixed-height column center-children-vh m-4 w-[320px] max-w-[500px]"
      style={{ overflow: 'visible' }}
    >
      <h1 style={{ fontSize: 40 }} className="grey9">
        Payment Detail
      </h1>
      <div className="flex-end w-full flex-row">
        {qboLink && <div className="min-w-8"></div>}
        <div className="column w-full">
          <FontAwesomeIcon
            icon={statusStyle.icon}
            style={{ fontSize: 52, color: statusStyle.color, marginBottom: 16 }}
          />
          <h2 className="grey9 text-center">
            <b>{paymentStatusDisplayName(status)}</b>
          </h2>
        </div>
        {qboLink && (
          <div className="min-w-8">
            <QuickbooksViewLinkButton
              onClick={() => window.open(qboLink, '_blank')}
            />
          </div>
        )}
      </div>
      {status !== PaymentStatus.PAID && (statusDetail ?? '').length > 0 && (
        <p className="text-center" style={{ color: statusStyle.color }}>
          {statusDetail}
        </p>
      )}
      <ThinDivider />
      <div className="column w-[280px] px-2">
        <WithLabel label="Payment Number:">#{referenceNumber}</WithLabel>
        <WithLabel label="Payment Date & Time:">
          {BzDateTime.fromIsoString(occurredAt, tzId).toDateFormat(
            'M/d/yy hh:mm',
          )}
        </WithLabel>
        <WithLabel label="Payment Method:">
          {paymentMethodDisplayName(paymentMethod)}
        </WithLabel>
        <WithLabel label="Payment Amount:">{formatMoney(amountUsd)}</WithLabel>
        {numericTotalRefundedAmountUsd > 0 && (
          <WithLabel label="Refunded Amount:">
            {formatMoney(numericTotalRefundedAmountUsd)}
          </WithLabel>
        )}
        {paymentMethodAdditionalInfo &&
          !(paymentMethodAdditionalInfo.indexOf('Tilled') > 0) && (
            <WithLabel label="Payment Method Info:">
              {paymentMethodAdditionalInfo}
            </WithLabel>
          )}
        <ThinDivider />
        <WithLabel label="Invoice:">
          <InvoiceDetailsLink
            guid={invoiceGuid}
            referenceNumber={
              displayId ? `#${displayId}` : inferInvoiceNumber(referenceNumber)
            }
          />
        </WithLabel>
        {accountGuid && accountDisplayName && (
          <WithLabel label="Account:">
            <AccountDetailsLink
              guid={accountGuid}
              label={truncate(accountDisplayName, 20, '...')}
            />
          </WithLabel>
        )}
        {jobGuid && jobDisplayId && (
          <WithLabel label="Job:">
            <JobDetailsLink guid={jobGuid} label={`#${jobDisplayId}`} />
          </WithLabel>
        )}
        {maintenancePlanGuid && (
          <WithLabel label="Maintenance Plan:">
            <MaintenancePlanDetailsLink
              guid={maintenancePlanGuid}
              label={'View'}
            />
          </WithLabel>
        )}
        {loanRecord && (
          <WithLabel label="Loan:">
            {<LoanDetailsLink record={loanRecord} text="Details" />}
          </WithLabel>
        )}
        {payoutGuid && (
          <WithLabel label="Payout:">
            <PayoutDetailsLink guid={payoutGuid} />
          </WithLabel>
        )}
        {collectedByUserGuid && (
          <WithLabel label="Collected By:">
            <UserDetailsLink
              guid={collectedByUserGuid}
              label={userDisplayName}
            />
          </WithLabel>
        )}
        {note && (
          <>
            <ThinDivider />
            <WithLabel label="Payment Note:">{note}</WithLabel>
          </>
        )}
        <ThinDivider />
        {status === PaymentStatus.PAID && (
          <>
            <Button className="my-2" type="primary" onClick={emailReceipt}>
              Email Receipt
            </Button>
            <Authorized to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE}>
              <Button className="my-2" danger onClick={issueRefund}>
                Start a Refund
              </Button>
            </Authorized>
          </>
        )}
      </div>
    </div>
  )
}

export default PaymentRecordDetailsView
