import { ErrorAlertRequest } from '@breezy/shared'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { CombinedError, Operation } from 'urql'
import { getConfig } from '../config'
import { getUrqlOperationName } from '../utils/GraphqlErrorMessages'

const config = getConfig()

export const createGraphqlDurationAlertMessage = (
  durationMs: number,
  operation: Operation,
): string =>
  `Slow Response - GraphQL query ${getUrqlOperationName(
    operation,
  )} - Duration ${durationMs}ms`

export const createGraphqlErrorAlertMessage = (
  error: CombinedError,
  operation: Operation,
): string =>
  `GraphQL Error - GraphQL query ${getUrqlOperationName(operation)} - ${
    error.message
  }`

export const createPublicErrorAlertRequest = (
  message: string,
): ErrorAlertRequest => {
  return {
    signature: '71aaa237-2370-405b-882e-63f6dee6ee8b',
    message,
  }
}

export const postErrorAlertWithDD = (message: string) => {
  const error = new Error(message)
  datadogRum.addError(error)
  datadogLogs.logger.error(message, {}, error)

  postErrorAlert(error.stack ?? message)
}

export const postErrorAlert = async (message: string) => {
  await fetch(`${config.apiUrl}/public/error-alert`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(createPublicErrorAlertRequest(message)),
  })
}

export const postGraphqlDurationErrorAlert = async (
  duration: number,
  operation: Operation,
) => {
  await postErrorAlert(createGraphqlDurationAlertMessage(duration, operation))
}
