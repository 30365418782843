import { useMemo } from 'react'
import { useQuery } from 'urql'
import { gql } from '../../../generated'

export const DECLINED_PREQUAL_RECORDS_QUERY = gql(/* GraphQL */ `
  query DeclinedAccountPrequalRecords($accountGuid: uuid!) {
    wisetackPrequalRecords(
      where: { status: { _eq: DECLINED }, accountGuid: { _eq: $accountGuid } }
    ) {
      contact {
        contactGuid
      }
    }
  }
`)

export const useFetchDisabledFinancingContacts = (accountGuid: string) => {
  const [query] = useQuery({
    query: DECLINED_PREQUAL_RECORDS_QUERY,
    variables: { accountGuid },
  })

  const disabledContactGuids = useMemo(
    () =>
      query.data?.wisetackPrequalRecords.map(
        record => record.contact.contactGuid,
      ) ?? [],
    [query.data],
  )

  return disabledContactGuids
}
