import { isNullish } from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import { retryExchange } from '@urql/exchange-retry'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AnyVariables,
  CombinedError,
  Operation,
  Provider,
  cacheExchange,
  createClient,
  fetchExchange,
  mapExchange,
} from 'urql'
import { useErrorModal } from '../components/ErrorModal/ErrorModal'
import { FullScreenLoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner'
import { getConfig } from '../config'
import { trpc } from '../hooks/trpc'
import { useAuth } from '../hooks/useAuth'
import {
  createGraphqlErrorAlertMessage,
  postErrorAlert,
} from '../utils/GraphqlAlerts'
import { toGraphQlError } from '../utils/GraphqlErrorMessages'
import {
  ConsecutiveFailureTracker,
  failureLimitExchange,
} from '../utils/UrqlFailureLimitExchange'
import { useUrqlSubscriptionExchange } from '../utils/UrqlSubscriptionExchange'

const useGraphQLAccessToken = () => {
  const [graphQLAccessToken, setGraphQLAccessToken] = useState('')
  const auth = useAuth()

  const trpcContext = trpc.useContext()

  useEffect(() => {
    // if there is no auth user, there is no point in trying to fetch the principal user
    if (!auth.user) {
      return
    }

    // if we already have the principal user, no need to fetch it again
    if (graphQLAccessToken) {
      return
    }
    trpcContext.client.user['users:self-and-graphql-access-token']
      .query()
      .then(({ graphqlAccessToken }) => {
        setGraphQLAccessToken(graphqlAccessToken)
      })
      .catch(e => {
        // if there is an error fetching, logout and have the user sign in
        console.error(
          `There was an error fetching users:self-and-graphql-access-token`,
          e,
        )
        auth.logout()
        auth.loginWithRedirect()
      })
  }, [auth, trpcContext, graphQLAccessToken])

  return graphQLAccessToken
}

const isErrorLikelyRelatedToQueryPermissionConfiguration = (
  stringErr: string,
): boolean =>
  stringErr.includes('field') && stringErr.includes('not found in type')

const useHandleGraphQLError = () => {
  const errorModal = useErrorModal()

  return useCallback(
    async (
      error: CombinedError,
      operation: Operation<unknown, AnyVariables>,
    ) => {
      if (error.graphQLErrors.length > 0) {
        const graphQlError = error.graphQLErrors[0]
        const stringErr = JSON.stringify(graphQlError)
        const { errorTitle, errorMessage } = toGraphQlError(
          graphQlError,
          operation,
        )
        errorModal.pushError({
          title: errorTitle,
          content: errorMessage,
        })

        if (isErrorLikelyRelatedToQueryPermissionConfiguration(stringErr)) {
          console.error(
            'Graphql error, likely related to Query Permission Configuration',
            graphQlError,
          )
        } else {
          console.error('Graphql error', graphQlError)
        }

        datadogRum.addError(error, operation)
        await postErrorAlert(createGraphqlErrorAlertMessage(error, operation))
      }

      if (error.networkError) {
        errorModal.pushError({
          title: 'Network error',
          content: error.networkError.message,
        })
        console.error('Network error', error.networkError)
      }
    },
    [errorModal],
  )
}

const useUrqlClient = (graphQLAccessToken?: string) => {
  const subscriptionExchange = useUrqlSubscriptionExchange(graphQLAccessToken)
  const handleError = useHandleGraphQLError()

  const failureTracker = useRef<ConsecutiveFailureTracker>({})

  const client = useMemo(
    () =>
      createClient({
        url: getConfig().querierApiUrl,
        requestPolicy: 'cache-and-network',
        fetchOptions: () => {
          const headers: Record<string, string> = {}
          // if an authorization header passed when it's set to jwt auth, it'll
          // infer that the user is unauthenticated and set the hasura role to the HASURA_GRAPHQL_UNAUTHORIZED_ROLE
          // env var we set in our hasura docker container
          // https://hasura.io/docs/latest/auth/authentication/unauthenticated-access/
          if (!isNullish(graphQLAccessToken)) {
            headers.authorization = `Bearer ${graphQLAccessToken}`
          }
          return { headers }
        },
        exchanges: [
          mapExchange({
            onOperation: operation => {
              return operation
            },
            onError: (error, operation) => {
              handleError(error, operation)
            },
            onResult: result => {
              return result
            },
          }),
          failureLimitExchange(failureTracker.current),
          cacheExchange,
          /**
           * Note that this retryExchange controls how operations are retried in the urql Client I/O stream,
           * but it does NOT prevent operations from being re-triggered elsewhere in the application (like
           * a re-render of a component that's using useQuery). So even though we set maxNumberAttempts to 2
           * here, it does not necessarily mean a useQuery hook will stop fetching after 2 failures, it simply means
           * that the hook's operation will be re-tried up to 2 times with exponential backoff before the
           * result gets propagated back through the urql Client I/O stream
           */
          retryExchange({
            initialDelayMs: 1500,
            maxDelayMs: 5000,
            randomDelay: true,
            maxNumberAttempts: 2,
            retryIf: (
              err: CombinedError,
              operation: Operation<unknown, AnyVariables>,
            ) => {
              // Currently we only retry w/ exponential backoff on fetches that fail due to network error

              if (operation.kind !== 'query') {
                return false
              }

              if (err.networkError) {
                return true
              }

              return false
            },
          }),
          fetchExchange,
          subscriptionExchange,
        ],
      }),
    [graphQLAccessToken, handleError, failureTracker, subscriptionExchange],
  )

  return client
}

const UrqlWrapper = React.memo<React.PropsWithChildren>(({ children }) => {
  const graphQLAccessToken = useGraphQLAccessToken()

  const client = useUrqlClient(graphQLAccessToken)
  if (!graphQLAccessToken) {
    return <FullScreenLoadingSpinner />
  }

  return <Provider value={client}>{children}</Provider>
})

export const NonAuthUrqlWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const client = useUrqlClient()

    return <Provider value={client}>{children}</Provider>
  },
)

export default UrqlWrapper
