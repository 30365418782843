import cn from 'classnames'

export default function KeyboardKey({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <kbd
      className={cn(
        'box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75) inline-block rounded border border-gray-400 bg-gray-200 px-1 py-0.5 font-mono text-xs leading-none text-gray-700 shadow-inner',
        className,
      )}
    >
      {children}
    </kbd>
  )
}
