import React from 'react'

type TextWithLineBreaksProps = {
  children: string
}

export const TextWithLineBreaks = React.memo<TextWithLineBreaksProps>(
  ({ children }) => {
    return (
      <>
        {children.split('\n').map((line, i) => (
          // Do `m-0` so there isn't space between the lines. However, if `line
          // === ''` that means we had a blank line, so it should get the margin
          // so it's a full-height blank line.
          <p key={`${i}-${line}`} className={line === '' ? '' : 'm-0'}>
            {line}
          </p>
        ))}
      </>
    )
  },
)
