import { PaymentMethod, ThisShouldNeverHappenError } from '@breezy/shared'
import { useCallback, useState } from 'react'

import { trpc } from '../../../hooks/trpc'
import { TilledFormInfo } from '../../../hooks/useTilled'

import { useMessage } from '../../../utils/antd-utils'
import { getTilledPaymentMethodAdditionalInfo } from '../../Payments/Tilled/useTilledCreatePaymentMethod'
import {
  PaymentFormData,
  convertTilledCardInfoToPaymentMethodCardMetadata,
  getTilledPaymentMethodDetails,
  isCreditCardFormData,
} from './useSubmitTilledPayment'

type UseCreateTilledPaymentMethodProps<
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
> = {
  accountGuid: string
  tilledFormInfo?: TilledFormInfo
  paymentMethod: PaymentMethodType
  onError: (error: Error) => void
}

export const useCreateTilledPaymentMethod = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>({
  accountGuid,
  tilledFormInfo,
  paymentMethod,
  onError,
}: UseCreateTilledPaymentMethodProps<PaymentMethodType>) => {
  const message = useMessage()
  const createPaymentMethodMutation =
    trpc.payments['unauth:payments:payment-method:create'].useMutation()

  const [isLoading, setIsLoading] = useState(false)

  const createPaymentMethod = useCallback(
    async (
      formData: PaymentFormData<PaymentMethod.CARD | PaymentMethod.ACH>,
      companyGuid: string,
      tilledMerchantId: string,
      // This is a flag that lets the caller opt-out of the payment record creation. If this is set to false, it'll create the payment method in Tilled and return.
      savePaymentMethodRecord = true,
    ) => {
      if (!tilledFormInfo) {
        console.error('Tilled form info undefined')
        message.error('Cannot create payment method. Please try again later.')
        return
      }
      const { tilled } = tilledFormInfo
      if (!tilled) {
        console.error('Tilled client undefined')
        message.error('Cannot create payment method. Please try again later.')
        return
      }

      setIsLoading(true)
      try {
        const tilledPaymentMethodRequest = getTilledPaymentMethodDetails(
          paymentMethod,
          formData,
        )
        const tilledPaymentMethod = await tilled.createPaymentMethod(
          tilledPaymentMethodRequest,
        )
        const tilledPaymentMethodId = tilledPaymentMethod.id

        const name = isCreditCardFormData(formData)
          ? formData.name
          : formData.accountHolderName
        if (
          !name ||
          !formData.streetAddress ||
          !formData.city ||
          !formData.state ||
          !formData.zipCode
        ) {
          throw new ThisShouldNeverHappenError(
            'Missing billing info during payment method creation',
          )
        }
        const cardMetadata =
          isCreditCardFormData(formData) && tilledPaymentMethod.card
            ? convertTilledCardInfoToPaymentMethodCardMetadata(
                tilledPaymentMethod.card,
              )
            : undefined
        if (savePaymentMethodRecord) {
          const newPaymentMethodRecord =
            await createPaymentMethodMutation.mutateAsync({
              accountGuid,
              companyGuid,
              merchantId: tilledMerchantId,
              paymentMethodId: tilledPaymentMethodId,
              paymentMethodType: paymentMethod,
              billingInfo: {
                name,
                streetAddress: formData.streetAddress,
                streetAddress2: formData.streetAddress2,
                city: formData.city,
                state: formData.state,
                zipCode: formData.zipCode,
              },
              cardMetadata,
            })
          return {
            paymentMethodRecordGuid:
              newPaymentMethodRecord.paymentMethodRecordGuid,
            externalPaymentMethodId: tilledPaymentMethodId,
            paymentMethodAdditionalInfo:
              getTilledPaymentMethodAdditionalInfo(tilledPaymentMethod),
            cardMetadata,
          }
        }
        return {
          externalPaymentMethodId: tilledPaymentMethodId,
          paymentMethodAdditionalInfo:
            getTilledPaymentMethodAdditionalInfo(tilledPaymentMethod),
          cardMetadata,
        }
      } catch (err: unknown) {
        console.error(err)
        onError?.(err as Error)
      } finally {
        setIsLoading(false)
      }
    },
    [
      tilledFormInfo,
      message,
      paymentMethod,
      createPaymentMethodMutation,
      accountGuid,
      onError,
    ],
  )

  return { createPaymentMethod, isLoading }
}
