import { AccountGuid } from '@breezy/shared'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'urql'
import { AccountRemindersQuery } from '../../generated/user/graphql'
import { useCompanyUserContext } from '../../hooks/useCompanyUserContext'
import { ACCOUNT_REMINDERS_QUERY } from './AccountReminders.gql'

const useAccountReminders = (accountGuid: AccountGuid) => {
  const { companyGuid } = useCompanyUserContext()

  const initialRemindersSetRef = useRef(false)
  const [reminders, setReminders] = useState<
    AccountRemindersQuery['accountReminders']
  >([])

  const [{ data: accountRemindersData, fetching }, refetchAccountReminders] =
    useQuery({
      query: ACCOUNT_REMINDERS_QUERY,
      variables: {
        accountGuid,
        companyGuid,
      },
    })

  useEffect(() => {
    if (accountRemindersData && !initialRemindersSetRef.current) {
      setReminders(accountRemindersData.accountReminders)
      initialRemindersSetRef.current = true
    }
  }, [accountRemindersData])

  const refetch = useCallback(async () => {
    initialRemindersSetRef.current = false
    await refetchAccountReminders()
  }, [refetchAccountReminders])

  const location = useLocation()

  // refetch the reminders whenever there's a url change
  useEffect(() => {
    refetch()
  }, [location, refetch])

  const updateReminderStatus = useCallback(
    (reminderGuid: string, status: string) => {
      setReminders(accountReminders =>
        accountReminders.map(accountReminder =>
          accountReminder.reminder.reminderGuid === reminderGuid
            ? {
                ...accountReminder,
                reminder: {
                  ...accountReminder.reminder,
                  status: status === 'COMPLETE' ? 'INCOMPLETE' : 'COMPLETE',
                },
              }
            : accountReminder,
        ),
      )
    },
    [],
  )

  return {
    reminders,
    fetching,
    refetch,
    updateReminderStatus,
  }
}

export default useAccountReminders
