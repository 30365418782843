import { MissingCaseError } from '../../../common'
import { DiscountType, calculateDiscountedAmountCentsUsc } from '../../Discounts/DiscountTypes'
import { UpchargeType } from '../Upcharges/UpchargeTypes'
import {
  CartItemType,
  CartOrderSummaryUsc,
  CartUsc,
  CreditSetEventData,
  calculateCartTaxCents,
} from './TransactionTypes'
import { CartCalculationSummary, CartSubtotalSummary, withTotalPriceUsc } from './utils'

export const calculateSimplePriceOrderSummaryUsc = (
  priceUsc: number,
  discountAmountUsc: number,
  upchargeAmountUsc: number,
  taxRate: number,
): CartOrderSummaryUsc =>
  calculateCartOrderSummaryUsc({
    items: [
      {
        itemGuid: 'N/A',
        name: 'N/A',
        description: 'N/A',
        quantity: 1,
        unitPriceUsc: priceUsc,
        isTaxable: true,
        isDiscountable: true,
        itemType: CartItemType.UNKNOWN,
      },
    ],
    discount: { type: DiscountType.FLAT, discountAmountUsc },
    upcharge: { type: UpchargeType.FLAT, upchargeAmountUsc },
    taxRate: { rate: taxRate, taxRateGuid: 'N/A' },
  })

export const calculateCreditAppliedAmountCentsUsc = (
  subtotals: CartSubtotalSummary,
  credit?: CreditSetEventData,
): CartCalculationSummary => {
  const unchanged = withTotalPriceUsc(subtotals)
  if (!credit) return unchanged

  const { totalPriceUsc } = unchanged

  const creditAmountUsc = Math.max(credit.creditMaxAmountUsc ?? 0, totalPriceUsc)
  const afterCredit = withTotalPriceUsc({ ...unchanged, creditAmountUsc })

  return afterCredit
}

export const calculateCartLineItemSubtotalsUsc = (cart: CartUsc): CartSubtotalSummary => {
  return cart.items.reduce(
    (acc: CartSubtotalSummary, i) => {
      const cents = i.quantity * i.unitPriceUsc

      switch (i.isTaxable) {
        case true:
          if (i.isDiscountable) {
            acc.taxDiscSubtotalUsc += cents
          } else {
            acc.taxNonDiscSubtotalUsc += cents
          }
          break
        case false:
          if (i.isDiscountable) {
            acc.nonTaxDiscSubtotalUsc += cents
          } else {
            acc.nonTaxNonDiscSubtotalUsc += cents
          }
          break
      }

      return {
        ...acc,
        subtotalPriceUsc: acc.subtotalPriceUsc + cents,
      }
    },
    {
      subtotalPriceUsc: 0,
      creditAmountUsc: 0,
      nonTaxNonDiscSubtotalUsc: 0,
      nonTaxDiscSubtotalUsc: 0,
      taxNonDiscSubtotalUsc: 0,
      taxDiscSubtotalUsc: 0,
      discountAmountUsc: 0,
      taxAmountUsc: 0,
      upchargeAmountUsc: 0,
    },
  )
}

export const calculateCartOrderSummaryUsc = (cart: CartUsc): CartOrderSummaryUsc => {
  // 1. Calculate Subtotal & individual buckets
  let subtotals = calculateCartLineItemSubtotalsUsc(cart)

  // 2. Calculate upcharge
  if (cart.upcharge) {
    switch (cart.upcharge.type) {
      case UpchargeType.FLAT:
        // Assumes that all upcharges are discountable and taxable.
        subtotals = {
          ...subtotals,
          taxDiscSubtotalUsc: subtotals.taxDiscSubtotalUsc + cart.upcharge.upchargeAmountUsc,
          upchargeAmountUsc: cart.upcharge.upchargeAmountUsc,
        }
        break
      default:
        throw new MissingCaseError(`Missing upcharge Type: ${cart.upcharge.type}`)
    }
  }

  // 3. Calculate Discount
  subtotals = calculateDiscountedAmountCentsUsc(cart.discount, subtotals)

  // 4. Calculate Credit
  subtotals = calculateCreditAppliedAmountCentsUsc(subtotals, cart.credit)

  // Pre-Tax Fees go here

  // 5. Calculate Taxes
  subtotals = calculateCartTaxCents(cart.taxRate, subtotals)

  // Post-Tax Fees go here
  return withTotalPriceUsc(subtotals)
}
