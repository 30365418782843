import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'
import BzCheckBox from '../../../elements/BzCheckBox/BzCheckBox'

type RecommendWidgetProps = {
  recommended: boolean
  onChange: (recommended: boolean) => void
  className?: string
}

export const RecommendWidget = React.memo<RecommendWidgetProps>(
  ({ recommended, onChange, className }) => (
    <Button
      size="large"
      icon={
        <BzCheckBox value={recommended} uncheckedColor="text-bz-gray-1000" />
      }
      className={classNames(
        'flex flex-row items-center leading-none',
        className,
      )}
      onClick={() => onChange(!recommended)}
    >
      Recommend
    </Button>
  ),
)
