import {
  Address,
  BzAddress,
  BzDateFns,
  Dfns,
  LocalDateString,
  MaintenancePlanPaymentIntervalAbbreviation,
  R,
  RenewalTypeOption,
  formatMoney,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Checkbox, Form, Input } from 'antd'
import { useCallback, useState } from 'react'
import AddressFormCompactItem from '../../components/Addresses/AddressFormCompactItem/AddressFormCompactItem'
import AddressMultiLineView from '../../components/Addresses/AddressMultiLineView/AddressMultiLineView'
import DatePicker from '../../components/DatePicker/DatePicker'
import PaymentCardPanel from '../../components/Payments/PaymentCardPanel'
import {
  PaymentMethodCreationContextProps,
  PaymentSubscriptionBillingDetails,
} from '../../components/Payments/PaymentTypes'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { dayBreakBlue6 } from '../../themes/theme'
import { m } from '../../utils/react-utils'

type SubscriptionPaymentInfoViewProps = {
  readonly renewalTypeOption: RenewalTypeOption
  readonly paymentContext: PaymentMethodCreationContextProps
  readonly setPaymentContext: (
    paymentContext: PaymentMethodCreationContextProps,
  ) => void
  readonly onConfirm: () => void
}

const SubscriptionPaymentInfoSummaryPanel = m(
  (paymentCfg: SubscriptionPaymentInfoViewProps) => {
    const { paymentPeriodAmountUsc, paymentBillingDetails, paymentInterval } =
      paymentCfg.paymentContext

    return (
      <div className="column center-h center-children-h">
        <div className="column center-h mt-[-4px] w-[240px] space-y-2">
          <h1 className="m0 grey9 text-[24px]">
            Autopay {formatMoney(usCentsToUsd(paymentPeriodAmountUsc))}{' '}
            {MaintenancePlanPaymentIntervalAbbreviation[paymentInterval]}
          </h1>
          <div className="gray7">{paymentBillingDetails.name}</div>
          <AddressMultiLineView
            labelClassName="gray7"
            address={BzAddress.create(paymentBillingDetails.address)}
          />
          <h2 className="grey9 text-bold pt-2 text-[16px]">
            First Payment:{' '}
            {paymentBillingDetails.customBillingStartDate ?? 'Today'}
          </h2>
          <h2 className="grey9 pt-2 text-[16px]">Subscription payment.</h2>
          <h2 className="grey9 pt-2 text-[16px]">
            {paymentBillingDetails.autoRenewSubscription
              ? 'Auto renews until canceled.'
              : 'Cancels in one year. Must be resold after cancellation.'}
          </h2>
        </div>
      </div>
    )
  },
)

type SubscriptionPaymentCfgFormSchema = {
  name: string
  address: Address
  customBillingStartDate?: LocalDateString
  autoRenewSubscription: boolean
}

type SubscriptionPaymentCfgEditFormProps = {
  paymentCfg: PaymentSubscriptionBillingDetails
  renewalTypeOption: RenewalTypeOption
  onSubmit: (paymentCfg: PaymentSubscriptionBillingDetails) => void
}

const SubscriptionPaymentCfgEditForm = m(
  ({
    paymentCfg,
    onSubmit,
    renewalTypeOption,
  }: SubscriptionPaymentCfgEditFormProps) => {
    const [form] = Form.useForm<SubscriptionPaymentCfgFormSchema>()
    const [address, setAddressFields] = useState(paymentCfg.address)
    const [customBillingStartDate, setCustomBillingStartDate] =
      useState<LocalDateString>()
    const canChangeRenewalType = renewalTypeOption === 'BOTH'

    const submit = useCallback(
      (values: SubscriptionPaymentCfgFormSchema) => {
        const newValues = {
          ...paymentCfg,
          address,
          name: values.name,
          customBillingStartDate,
          autoRenewSubscription: isNullish(values.autoRenewSubscription)
            ? paymentCfg.autoRenewSubscription
            : values.autoRenewSubscription,
        }
        onSubmit(newValues)
      },
      [address, onSubmit, paymentCfg, customBillingStartDate],
    )

    return (
      <>
        <div style={editSaveButtonStyle}>
          <FaIconButton
            icon={faCheck}
            onClick={() => form.submit()}
            size={24}
            style={{ color: dayBreakBlue6 }}
          />
        </div>
        <div className="column center-h center-children-h">
          <div className="column center-h mt-[-4px] w-[240px]">
            <Form
              form={form}
              validateTrigger="onBlur"
              layout="vertical"
              onFinish={submit}
              initialValues={paymentCfg}
            >
              <Form.Item
                label="Billing Name"
                name="name"
                rules={[
                  {
                    required: true,
                    validator: async (_, value) => {
                      if (!value || value.length < 3)
                        throw new Error('Billing Name is required')
                    },
                  },
                ]}
              >
                <Input autoComplete="name" id="name" />
              </Form.Item>
              <AddressFormCompactItem
                headerLabel="Billing Address"
                onAddressChanged={setAddressFields}
                required
                name="address"
                address={paymentCfg.address}
                showMoreThanLineOne={false}
                showLine2={false}
              />
              <Form.Item label="Custom Billing Start Date" className="mt-4">
                <DatePicker
                  allowClear
                  placeholder=""
                  format="MM/dd/yyyy"
                  className="w-full"
                  onChange={date => {
                    setCustomBillingStartDate(
                      date ? BzDateFns.formatLocalDate(date) : undefined,
                    )
                  }}
                  disabledDate={current =>
                    current < new Date() ||
                    current >
                      R.pipe(Dfns.addMonths(1), Dfns.subDays(1))(new Date())
                  }
                  style={{ width: '100%' }}
                  value={
                    customBillingStartDate
                      ? Dfns.parseISO(customBillingStartDate)
                      : undefined
                  }
                />
              </Form.Item>
              {canChangeRenewalType && (
                <Form.Item
                  label="Auto Renew Annually"
                  className="mt-4"
                  name="autoRenewSubscription"
                  valuePropName="checked"
                >
                  <Checkbox checked={paymentCfg.autoRenewSubscription} />
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      </>
    )
  },
)

// TECH DEBT: Convert to Tailwind
const editSaveButtonStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  zIndex: 10,
  position: 'relative',
  height: 0,
  bottom: 22,
  right: -18,
}

// TECH DEBT: Convert to Tailwind
const confirmButtonStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  zIndex: 10,
  position: 'relative',
  height: 0,
  bottom: 22,
  right: -18,
}

const SubscriptionPaymentInfoView = m(
  ({
    paymentContext,
    setPaymentContext,
    onConfirm,
    renewalTypeOption,
  }: SubscriptionPaymentInfoViewProps) => {
    const [isEditing, setIsEditing] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const setPaymentConfiguration = useCallback(
      (newPaymentCfg: PaymentMethodCreationContextProps) => {
        setPaymentContext({ ...paymentContext, ...newPaymentCfg })
        setIsEditing(false)
      },
      [paymentContext, setPaymentContext],
    )

    const onSubmit = useCallback(
      (b: PaymentSubscriptionBillingDetails) => {
        setPaymentConfiguration({
          ...paymentContext,
          paymentBillingDetails: b,
        })
      },
      [paymentContext, setPaymentConfiguration],
    )

    const confirm = useCallback(() => {
      setConfirmed(true)
      onConfirm()
    }, [onConfirm])

    return (
      <PaymentCardPanel>
        {!isEditing && (
          <>
            {!confirmed && (
              <div style={editSaveButtonStyle}>
                <FaIconButton
                  icon={faEdit}
                  onClick={() => setIsEditing(!isEditing)}
                  size={24}
                />
              </div>
            )}
            <SubscriptionPaymentInfoSummaryPanel
              onConfirm={onConfirm}
              paymentContext={paymentContext}
              setPaymentContext={setPaymentConfiguration}
              renewalTypeOption={renewalTypeOption}
            />
            {!confirmed && (
              <div style={confirmButtonStyle}>
                <FaIconButton
                  icon={faCheck}
                  onClick={confirm}
                  style={{ color: dayBreakBlue6 }}
                  size={24}
                />
              </div>
            )}
          </>
        )}
        {isEditing && (
          <SubscriptionPaymentCfgEditForm
            paymentCfg={paymentContext.paymentBillingDetails}
            onSubmit={onSubmit}
            renewalTypeOption={renewalTypeOption}
          />
        )}
      </PaymentCardPanel>
    )
  },
)

export default SubscriptionPaymentInfoView
