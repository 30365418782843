import { Row } from 'antd'
import classNames from 'classnames'
import { ReactNode, memo } from 'react'
import { usePageContainerWidth } from '../hooks/usePageContainerWidth'

type CarouselHeaderProps = {
  dots: ReactNode
  title: string
}

const CarouselHeader = memo<CarouselHeaderProps>(({ dots, title }) => {
  const { scrollbarWidth, pageContainerWidth } = usePageContainerWidth()

  return (
    <div
      style={{
        maxWidth: 754,
        width: `${pageContainerWidth - 32 - (scrollbarWidth ?? 0)}px`,
      }}
      className={classNames('mx-auto mb-4 flex flex-1 flex-row items-center')}
    >
      <Row className="mr-4 flex-1 break-words text-[20px] font-semibold leading-[28px] text-[rgba(0,0,0,0.88)]">
        {title}
      </Row>
      {dots}
    </div>
  )
})

export const renderCarouselHeader =
  (title: string, showDots: boolean = true) =>
  (dots: ReactNode) => {
    return <CarouselHeader dots={showDots ? dots : null} title={title} />
  }
