import { BzCompanySchedulingConfig } from '@breezy/shared'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { trpc } from '../trpc'

export const useFetchCompanyAppointmentArrivalWindows = (
  opts: {
    enabled?: boolean
    onSuccess?: (
      companyAppointmentArrivalWindows: BzCompanySchedulingConfig,
    ) => void
  } = {},
) => {
  const companyGuid = useExpectedCompany().companyGuid
  return trpc.appointments[
    'company-appointment-arrival-windows:query'
  ].useQuery(
    {
      companyGuid,
    },
    {
      select: data => {
        if (data.length !== 1) {
          throw new Error(
            `Failed to locate company appointment arrival windows for company guid = ${companyGuid}`,
          )
        }
        return BzCompanySchedulingConfig.create(data[0])
      },
      enabled: opts.enabled,
      onSuccess: opts.onSuccess,
    },
  )
}
