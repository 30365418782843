import {
  AccountType,
  Address,
  BzDateFns,
  ThisShouldNeverHappenError,
  getStateAbbreviationOrUndefined,
  nextGuid,
} from '@breezy/shared'
import { Divider } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from 'src/gql/queries/Tags.gql'
import { useQuery } from 'urql'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import {
  useCompanyDefaultAccountManagerUserGuid,
  useExpectedCompanyGuid,
} from '../../providers/PrincipalUser'
import ErrorMessages from '../../utils/ErrorMessages'
import { useMessage } from '../../utils/antd-utils'
import AddressForm, {
  AddressFormSchema,
  mapAddressFormSchemaToAddress,
} from '../Addresses/AddressForm'
import CreateOrEditNewAccountLocationForm, {
  AccountLocationFormSchema,
} from '../CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import {
  EmbeddedContactInformationForm,
  EmbeddedContactInformationFormSchema,
} from '../EmbeddedContactInformationForm/EmbeddedContactInformationForm'
import { SidebarAccount } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarAccount'
import { SidebarAddress } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarAddress'
import { SidebarContact } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarContact'
import { SidebarLocation } from '../ProgressiveFullScreenModalStepper/supporting-components/SidebarLocation'
import CreateNewAccountForm, {
  AccountFormSchema,
} from '../ProgressiveJobCreationModal/CreateOrEditAccountForm/CreateOrEditAccountForm'
import {
  ProgressiveStepContent,
  ProgressiveStepController,
  StepDefinition,
  TransitionErrorReason,
} from '../ProgressiveStepController/ProgressiveStepController'

const heading = 'Create A New Account'

const stepCreatePrimaryContact: StepDefinition = {
  id: 0,
  stepName: 'Contact',
  heading,
  subheading: 'Create the primary contact for this account',
  preRequiredData: [],
}

const stepCreateLocation: StepDefinition = {
  id: 1,
  stepName: 'Location',
  heading,
  subheading: 'Create a location for this account',
  preRequiredData: ['Contact'],
}

const stepCreateBillingAddress: StepDefinition = {
  id: 2,
  stepName: 'Billing Address',
  heading,
  subheading: '(Optional) Create a billing address for this account',
  preRequiredData: ['Location'],
}

const stepCreateAccount: StepDefinition = {
  id: 3,
  stepName: 'Account',
  heading,
  subheading: 'Finalize the new account details',
  preRequiredData: ['Contact', 'Location'],
}

const stepsWithoutBillingAddress = [
  stepCreatePrimaryContact,
  stepCreateLocation,
  stepCreateAccount,
]
const stepsWithBillingAddress = [
  stepCreatePrimaryContact,
  stepCreateLocation,
  stepCreateBillingAddress,
  stepCreateAccount,
]

export type ProgressiveAccountCreationModalV2Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children?: ReactNode
}

export const ProgressiveAccountCreationModalV2 = ({
  isOpen,
  setIsOpen,
}: ProgressiveAccountCreationModalV2Props) => {
  const message = useMessage()
  const companyGuid = useExpectedCompanyGuid()
  const appNav = useAppNavigation()
  const billingAddresses = useFeatureFlag('billingAddresses')

  const finalSteps = billingAddresses
    ? stepsWithBillingAddress
    : stepsWithoutBillingAddress

  const [contactForm] = useForm<EmbeddedContactInformationFormSchema>()
  const [locationForm] = useForm<AccountLocationFormSchema>()
  const [accountForm] = useForm<AccountFormSchema>()
  const [addressForm] = useForm<AddressFormSchema>()

  const urlParams = new URLSearchParams(window.location.search)
  const preFilledPhoneNumber = urlParams.get('phoneNumber')
  const preFilledLeadSourceGuid = urlParams.get('leadSourceGuid')

  useEffect(() => {
    if (preFilledPhoneNumber) {
      contactForm.setFieldsValue({
        phoneNumber: preFilledPhoneNumber,
      })
    }
  }, [preFilledPhoneNumber, contactForm])

  useEffect(() => {
    if (preFilledLeadSourceGuid) {
      accountForm.setFieldsValue({
        leadSourceGuid: preFilledLeadSourceGuid,
      })
    }
  }, [preFilledLeadSourceGuid, accountForm])

  const [primaryContact, setPrimaryContact] =
    useState<EmbeddedContactInformationFormSchema>()
  const [locationInformation, setLocationInformation] =
    useState<AccountLocationFormSchema>()
  const [requestedStep, setRequestedStep] = useState<StepDefinition>()
  const [billingAddress, setBillingAddress] = useState<Address>()

  const defaultAccountManagerUserGuid =
    useCompanyDefaultAccountManagerUserGuid()
  const createNewAccountMutation =
    trpc.accounts['accounts:create'].useMutation()
  const upsertBillingAddressMutation =
    trpc.accounts['account-billing-address:upsert'].useMutation()

  const [tagsQuery] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const onFinish = useCallback(() => {
    accountForm
      .validateFields()
      .then(accountInformation => {
        if (!primaryContact || !accountInformation || !locationInformation) {
          throw new ThisShouldNeverHappenError(
            'All of the above should be defined by this point',
          )
        }

        const stateAbbreviation = getStateAbbreviationOrUndefined(
          locationInformation.addressState,
        )
        if (!stateAbbreviation) {
          const msg = ErrorMessages.invalidStateSelection
          message.error(msg)
          throw new ThisShouldNeverHappenError(msg)
        }

        const accountGuid = nextGuid()
        createNewAccountMutation.mutate(
          {
            accountInfo: {
              accountGuid,
              ...accountInformation,
            },
            contactInfo: {
              ...primaryContact,
            },
            serviceLocationInfo: {
              address: {
                line1: locationInformation.addressLineOne,
                line2: locationInformation.addressLineTwo,
                city: locationInformation.addressCity,
                stateAbbreviation: stateAbbreviation,
                zipCode: locationInformation.addressZipCode,
              },
              estimatedSquareFootage:
                locationInformation.estimatedSquareFootage,
              estimatedBuildDate: locationInformation.estimatedBuildDate
                ? BzDateFns.formatLocalDate(
                    locationInformation.estimatedBuildDate,
                  )
                : undefined,
              municipality: locationInformation.municipality,
            },
            companyLeadSource: {
              leadSourceGuid: accountInformation.leadSourceGuid,
              leadSourceReferringContactGuid:
                accountInformation.leadSourceReferringContactGuid,
              leadSourceAttributionDescription:
                accountInformation.leadSourceAttributionDescription,
            },
            accountTags:
              accountInformation.accountTagGuids?.map(tagGuid => ({
                accountGuid,
                tagGuid,
              })) ?? [],
          },
          {
            onSuccess: () => {
              if (billingAddress) {
                upsertBillingAddressMutation.mutate(
                  {
                    accountGuid,
                    billingAddress,
                  },
                  {
                    onSuccess: () => {
                      appNav.navigateToAccountDetailsPage(accountGuid)
                    },
                    onError: () => {
                      message.error('Error setting billing address')
                      appNav.navigateToAccountDetailsPage(accountGuid)
                    },
                  },
                )
              } else {
                appNav.navigateToAccountDetailsPage(accountGuid)
              }
            },
          },
        )
      })
      .catch(console.error)
  }, [
    accountForm,
    createNewAccountMutation,
    primaryContact,
    locationInformation,
    appNav,
    message,
    upsertBillingAddressMutation,
    billingAddress,
  ])

  const getStepComponent = (step: StepDefinition) => {
    switch (step.id) {
      case stepCreatePrimaryContact.id:
        return (
          <ProgressiveStepContent step={step}>
            <EmbeddedContactInformationForm extendedForm={contactForm} />
          </ProgressiveStepContent>
        )
      case stepCreateLocation.id:
        return (
          <ProgressiveStepContent step={step}>
            <CreateOrEditNewAccountLocationForm
              showDivider={false}
              hideDisplayNameField
              showCancelSubmitButtons={false}
              flexRowSpaceX="space-x"
              labelClassName="semibold_14_22 grey9"
              extendedForm={locationForm}
              setAsBillingAddressByDefault
            />
          </ProgressiveStepContent>
        )
      case stepCreateBillingAddress.id:
        return (
          <ProgressiveStepContent step={step}>
            <AddressForm
              extendedForm={addressForm}
              showCancelSubmitButtons={false}
            />
          </ProgressiveStepContent>
        )
      case stepCreateAccount.id:
        return (
          <ProgressiveStepContent step={step}>
            <CreateNewAccountForm
              mode="embedded-create"
              extendedForm={accountForm}
              companyGuid={companyGuid}
              initialValues={{
                accountDisplayName: `${contactForm.getFieldValue(
                  'firstName',
                )} ${contactForm.getFieldValue(
                  'lastName',
                )} - ${locationForm.getFieldValue('addressLineOne')}`,
                accountManagerUserGuid: defaultAccountManagerUserGuid,
                leadSourceGuid: preFilledLeadSourceGuid ?? undefined,
              }}
              accountTags={tagsQuery.data?.tags ?? []}
            />
          </ProgressiveStepContent>
        )
    }
  }

  const phoneNumberValue = useWatch('phoneNumber', contactForm)
  const emailAddressValue = useWatch('emailAddress', contactForm)
  const notificationPreferenceValue = useWatch(
    'notificationPreference',
    contactForm,
  )

  const transitionStepAsync = useCallback(
    async (
      fromStep: StepDefinition,
      toStep: StepDefinition,
    ): Promise<TransitionErrorReason | 'done'> => {
      let result: TransitionErrorReason | 'done' = 'done'
      if (
        fromStep === stepCreatePrimaryContact &&
        toStep.id > stepCreatePrimaryContact.id
      ) {
        if (notificationPreferenceValue === 'SMS' && !phoneNumberValue) {
          return {
            fromStep,
            toStep,
            reason:
              'Notification preference cannot be "SMS" if there is no primary phone number.',
          }
        }
        if (notificationPreferenceValue === 'EMAIL' && !emailAddressValue) {
          return {
            fromStep,
            toStep,
            reason:
              'Notification preference cannot be "EMAIL" if there is no email address.',
          }
        }
        await contactForm
          .validateFields()
          .then(values => {
            setPrimaryContact(values)
          })
          .catch(e => {
            console.error(e)
            result = {
              fromStep,
              toStep,
              reason: 'Form Fields Invalid',
            }
          })
        return result
      }
      if (
        fromStep === stepCreateLocation &&
        toStep.id > stepCreateLocation.id
      ) {
        await locationForm
          .validateFields()
          .then(values => {
            setLocationInformation(values)
            if (values.setAsBillingAddress) {
              addressForm.setFieldsValue(values)
              setBillingAddress({
                line1: values.addressLineOne,
                line2: values.addressLineTwo,
                city: values.addressCity,
                stateAbbreviation:
                  getStateAbbreviationOrUndefined(values.addressState) ?? '',
                zipCode: values.addressZipCode,
              })
              setRequestedStep(stepCreateAccount)
            }
          })
          .catch(e => {
            console.error(e)
            result = {
              fromStep,
              toStep,
              reason: 'Form Fields Invalid',
            }
          })
        return result
      }
      if (
        fromStep === stepCreateBillingAddress &&
        toStep.id > stepCreateBillingAddress.id
      ) {
        await addressForm
          .validateFields()
          .then(values => {
            setBillingAddress(mapAddressFormSchemaToAddress(values))
          })
          .catch(e => {
            // Billing Address is not required, so we don't care about the error
          })
        return result
      }
      return result
    },
    [
      phoneNumberValue,
      emailAddressValue,
      notificationPreferenceValue,
      contactForm,
      locationForm,
      addressForm,
      setRequestedStep,
    ],
  )

  const getSidebarItems = useCallback(
    (step: StepDefinition) => {
      const accountNode: ReactNode = (step.id > stepCreateAccount.id && (
        <SidebarAccount
          displayName={accountForm.getFieldValue('displayName')}
          accountType={accountForm.getFieldValue('accountType') as AccountType}
          onEdit={() => setRequestedStep(stepCreateAccount)}
          hideDivider
        />
      )) || <div></div>

      const contactNode: ReactNode = (step.id > stepCreatePrimaryContact.id && (
        <SidebarContact
          firstName={contactForm.getFieldValue('firstName')}
          lastName={contactForm.getFieldValue('lastName')}
          phoneNumber={contactForm.getFieldValue('phoneNumber')}
          emailAddress={contactForm.getFieldValue('emailAddress')}
          onEdit={() => setRequestedStep(stepCreatePrimaryContact)}
          hideDivider
        />
      )) || <div></div>

      const billingAddressNode: ReactNode = (step.id >
        stepCreateBillingAddress.id &&
        billingAddresses &&
        billingAddress && (
          <SidebarAddress
            address={billingAddress}
            addressLabel="Billing Address"
            onEdit={() => setRequestedStep(stepCreateBillingAddress)}
          />
        )) || <div></div>

      const locationNode: ReactNode = (step.id > stepCreateLocation.id && (
        <SidebarLocation
          address={{
            line1: locationForm.getFieldValue('addressLineOne'),
            line2: locationForm.getFieldValue('addressLineTwo'),
            city: locationForm.getFieldValue('addressCity'),
            stateAbbreviation:
              getStateAbbreviationOrUndefined(
                locationForm.getFieldValue('addressState'),
              ) ?? '',
            zipCode: locationForm.getFieldValue('addressZipCode'),
          }}
          onEdit={() => setRequestedStep(stepCreateLocation)}
          hideDivider
        />
      )) || <div></div>

      return (
        <div className="flex flex-col space-y-3">
          {accountNode}
          {step.id > stepCreatePrimaryContact.id && <Divider />}
          {contactNode}
          {step.id > stepCreateLocation.id && <Divider />}
          {locationNode}
          {step.id > stepCreateBillingAddress.id &&
            billingAddresses &&
            billingAddress && <Divider />}
          {billingAddressNode}
        </div>
      )
    },
    [
      accountForm,
      locationForm,
      contactForm,
      setRequestedStep,
      billingAddress,
      billingAddresses,
    ],
  )

  const hasSelectedData = useCallback(
    (data: string) => {
      if (data === 'Contact') {
        return !!primaryContact
      }
      if (data === 'Location') {
        return !!locationInformation
      }
      if (data === 'Account') {
        return !!accountForm.getFieldValue('displayName')
      }
      return false
    },
    [primaryContact, locationInformation, accountForm],
  )

  return (
    <ProgressiveStepController
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      steps={finalSteps}
      transitionStepAsync={transitionStepAsync}
      finishButtonText="Create Account"
      hasSelectedData={hasSelectedData}
      getStepComponent={getStepComponent}
      onFinish={onFinish}
      sidebarTitle="Account Details"
      getSidebarItems={getSidebarItems}
      requestedStep={requestedStep}
      clearRequestedStep={() => setRequestedStep(undefined)}
    />
  )
}
