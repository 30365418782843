import {
  BzDateTime,
  formatMoney,
  MatchToShape,
  paymentMethodDisplayName,
  PaymentViewModel,
  R,
  SharedRoutes,
} from '@breezy/shared'
import { faDollarSign } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo } from 'react'
import { Link } from '../../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import LoanDetailsLink from '../../Financing/LoanDetailsLink/LoanDetailsLink'
import {
  InvoiceDetailsLink,
  JobDetailsLink,
  PaymentDetailsLink,
} from '../../Links/EntityLinks'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import PaymentStatusTag from '../PaymentStatusTag'

export type PaymentsCollapsiblePayment = MatchToShape<
  PaymentViewModel,
  {
    guid: true
    occurredAt: true
    paymentMethod: true
    amountUsd: true
    referenceNumber: true
    status: true
    invoiceGuid: true
    displayId: true
    jobGuid: true
    loanRecord: true
  }
>

type PaymentsCollapsibleProps = {
  payments: PaymentsCollapsiblePayment[]
  showJobLink?: boolean
}

export const PaymentsCollapsible = ({
  payments,
  showJobLink = true,
}: PaymentsCollapsibleProps) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const sortedPayments = useMemo(
    () => R.sort(R.descend(R.prop('occurredAt')), payments),
    [payments],
  )

  const getContentList = useCallback(
    (p: PaymentsCollapsiblePayment) => [
      {
        key: 'Payment Date:',
        value: (
          <div>
            {BzDateTime.fromIsoString(p.occurredAt, tzId).toDateFormat(
              'MM/dd/yy',
            )}
          </div>
        ),
      },
      {
        key: 'Payment Details:',
        value: (
          <PaymentDetailsLink
            guid={p.guid}
            referenceNumber={p.referenceNumber}
          />
        ),
      },
      {
        key: 'Invoice Details:',
        value: (
          <InvoiceDetailsLink
            guid={p.invoiceGuid}
            label={p.displayId ? `#${p.displayId}` : 'View'}
          />
        ),
      },
      {
        key: 'Job Details:',
        value: showJobLink && p.jobGuid && (
          <JobDetailsLink guid={p.jobGuid} label="View" />
        ),
      },
      {
        key: 'Loan Details:',
        value: p.loanRecord && (
          <LoanDetailsLink record={p.loanRecord} text="View" />
        ),
      },

      // [X] Audited in BZ-921 -> No Action Required immediately
      // TODO: In the future, maybe enrich with Appointment Details Page Link
    ],
    [showJobLink, tzId],
  )
  return (
    <BzCollapsible title="Payments" titleIcon={faDollarSign}>
      {sortedPayments.map(p => {
        return (
          <CollapsibleItem
            key={p.guid}
            title={
              <Link
                to={`${SharedRoutes.PAYMENT_DETAILS.build({
                  params: { paymentRecordGuid: p.guid },
                })}`}
              >
                {paymentMethodDisplayName(p.paymentMethod)} -{' '}
                {formatMoney(p.amountUsd)}
              </Link>
            }
            refId={<Link to={`/payments/${p.guid}`}>{p.referenceNumber}</Link>}
            tag={<PaymentStatusTag status={p.status} />}
            contentList={getContentList(p)}
          />
        )
      })}
    </BzCollapsible>
  )
}

export default PaymentsCollapsible
