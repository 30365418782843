import { PaymentMethod } from '@breezy/shared'
import { faBank, faCreditCard } from '@fortawesome/pro-light-svg-icons'
import PaymentCardPanel from '../../components/Payments/PaymentCardPanel'
import PaymentMethodButton from '../../components/Payments/PaymentMethodButton'
import LimitedWidthColumn from '../../elements/LimitedWidthColumn/LimitedWidthColumn'
import { m } from '../../utils/react-utils'

type MaintenancePlanPaymentMethodSelectionViewProps = {
  readonly setSelectedPaymentMethod: (paymentMethod?: string) => void
}

export const MaintenancePlanPaymentMethodSelectionView = m(
  ({
    setSelectedPaymentMethod,
  }: MaintenancePlanPaymentMethodSelectionViewProps) => (
    <LimitedWidthColumn>
      <PaymentCardPanel className="center-h">
        <div className="column center-h center-children-h">
          <h1 className="text-center">Select Recurring Payment Method</h1>
          <PaymentMethodButton
            icon={faCreditCard}
            text="Credit Card"
            onClick={() =>
              setSelectedPaymentMethod(PaymentMethod.CARD.toLowerCase())
            }
          />
          <PaymentMethodButton
            icon={faBank}
            text="Bank (ACH)"
            onClick={() =>
              setSelectedPaymentMethod(PaymentMethod.ACH.toLowerCase())
            }
          />
        </div>
      </PaymentCardPanel>
    </LimitedWidthColumn>
  ),
)
