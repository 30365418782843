import { z } from 'zod'
import { Event } from '../common'
import { bzOptional } from '../domain/common-schemas'
import { guidSchema, isoDateStringSchema, localDateSchema } from './_common'

export const visitRequestSchema = z.object({
  visitGuid: guidSchema,

  name: z.string(),
  affinityDate: localDateSchema,

  completedAtOverride: bzOptional(isoDateStringSchema),
  isCompletedOverride: bzOptional(z.boolean()),
  shouldLinkToJob: bzOptional(z.boolean()),
  jobGuid: bzOptional(guidSchema),

  installedEquipmentGuids: z.array(guidSchema),
})

export const maintenancePlanTaxRateSchema = z.object({
  taxRateGuid: guidSchema,
  rate: z.number(),
})

export const maintenancePlanCreatedSchema = z.object({
  accountGuid: guidSchema,
  locationGuid: guidSchema,
  taxRate: bzOptional(maintenancePlanTaxRateSchema),
})

export const MaintenancePlanCreatedEventName = 'MaintenancePlanCreated' as const
export type MaintenancePlanCreatedEventData = z.infer<typeof maintenancePlanCreatedSchema>
export type MaintenancePlanCreatedEvent = Event<MaintenancePlanCreatedEventData>

export const maintenancePlanDiscountBenefitItemSchema = z.object({
  jobClass: z.string(),
  discountRate: z.number(),
})

export const maintenancePlanCoveredEquipmentItemSchema = z.object({
  installedEquipmentGuid: guidSchema,
  equipmentType: z.string(),
})

export const maintenancePlanConfigurationSchema = z.object({
  maintenancePlanDefinitionGuid: guidSchema,
  numVisitsPerYear: z.number(),
  yearlyStaticPriceUsc: bzOptional(z.number()),
  numDaysUntilVisitCreditExpiration: z.number(),
  coveredEquipment: z.array(maintenancePlanCoveredEquipmentItemSchema),
  discounts: z.array(maintenancePlanDiscountBenefitItemSchema),

  suppressActivationEmail: bzOptional(z.boolean()),
  initialVisits: bzOptional(z.array(visitRequestSchema)),
})

export const MaintenancePlanConfiguredEventName = 'MaintenancePlanConfigured' as const
export type MaintenancePlanConfiguredEventData = z.infer<typeof maintenancePlanConfigurationSchema>
export type MaintenancePlanConfiguredEvent = Event<MaintenancePlanConfiguredEventData>

export const maintenancePlanActivationSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  customBillingStartAt: bzOptional(isoDateStringSchema),
  numDaysUntilAutoCancelation: bzOptional(z.number()),
})

export const MaintenancePlanActivatedEventName = 'MaintenancePlanActivated' as const
export type MaintenancePlanActivatedEventData = z.infer<typeof maintenancePlanActivationSchema>
export type MaintenancePlanActivatedEvent = Event<MaintenancePlanActivatedEventData>

export const freeMaintenancePlanActivationSchema = z.object({
  numDaysUntilAutoCancelation: bzOptional(z.number()),
  // This is used to determine if we should grant deprecated credits before maint plans v3 is enabled
  grantDeprecatedCredits: z.boolean(),
})

export const FreeMaintenancePlanActivatedEventName = 'FreeMaintenancePlanActivated' as const
export type FreeMaintenancePlanActivatedEventData = z.infer<typeof freeMaintenancePlanActivationSchema>
export type FreeMaintenancePlanActivatedEvent = Event<FreeMaintenancePlanActivatedEventData>

export const importedMaintenancePlanActivationSchema = z.object({
  activatedAt: isoDateStringSchema,
  numDaysUntilAutoCancelation: z.number(),
})

export const ImportedMaintenancePlanActivatedEventName = 'ImportedMaintenancePlanActivated' as const
export type ImportedMaintenancePlanActivatedEventData = z.infer<typeof importedMaintenancePlanActivationSchema>
export type ImportedMaintenancePlanActivatedEvent = Event<ImportedMaintenancePlanActivatedEventData>

export const MaintenancePlanFixedDurationSetEventName = 'MaintenancePlanSetAsFixedDuration' as const
export type MaintenancePlanFixedDurationSetEvent = Event

export const maintenancePlanActivatedWithOneTimePaymentSchema = z.object({
  paymentRecordGuid: guidSchema,
  paymentAmountUsc: z.number(),
  numDaysUntilAutoCancelation: z.number(),
  // This is used to determine if we should grant deprecated credits before maint plans v3 is enabled
  grantDeprecatedCredits: z.boolean(),
})

export const MaintenancePlanActivatedWithOneTimePaymentEventName = 'MaintenancePlanActivatedWithOneTimePayment' as const
export type MaintenancePlanActivatedWithOneTimePaymentEventData = z.infer<
  typeof maintenancePlanActivatedWithOneTimePaymentSchema
>
export type MaintenancePlanActivatedWithOneTimePaymentEvent = Event<MaintenancePlanActivatedWithOneTimePaymentEventData>

export enum MaintenancePlanCancellationReasonType {
  FINANCIAL = 'FINANCIAL',
  SERVICE_QUALITY = 'SERVICE_QUALITY',
  SERVICE_REQUIREMENTS = 'SERVICE_REQUIREMENTS',
  CONTRACT_ISSUE = 'CONTRACT_ISSUE',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  COMPETITOR_OFFER = 'COMPETITOR_OFFER',
  LIFESTYLE_CHANGE = 'LIFESTYLE_CHANGE',
  ENVIRONMENT_HEALTH_CONCERNS = 'ENVIRONMENT_HEALTH_CONCERNS',
  PLAN_USAGE = 'PLAN_USAGE',
  NEVER_ACTIVATED = 'NEVER_ACTIVATED',
  OTHER = 'OTHER',
}

export const maintenancePlanCancellationReasonTypesDisplayNames = {
  [MaintenancePlanCancellationReasonType.FINANCIAL]: 'Financial',
  [MaintenancePlanCancellationReasonType.SERVICE_QUALITY]: 'Service Quality',
  [MaintenancePlanCancellationReasonType.SERVICE_REQUIREMENTS]: 'Service Requirements',
  [MaintenancePlanCancellationReasonType.CONTRACT_ISSUE]: 'Contract Issue',
  [MaintenancePlanCancellationReasonType.LOCATION_CHANGE]: 'Location Change',
  [MaintenancePlanCancellationReasonType.COMPETITOR_OFFER]: 'Competitor Offer',
  [MaintenancePlanCancellationReasonType.LIFESTYLE_CHANGE]: 'Lifestyle Change',
  [MaintenancePlanCancellationReasonType.ENVIRONMENT_HEALTH_CONCERNS]: 'Environment & Health Concerns',
  [MaintenancePlanCancellationReasonType.PLAN_USAGE]: 'Plan Usage',
  [MaintenancePlanCancellationReasonType.NEVER_ACTIVATED]: 'Never Activated',
  [MaintenancePlanCancellationReasonType.OTHER]: 'Other',
}

export const maintenancePlanCanceledSchema = z.object({
  cancellationReason: bzOptional(z.string()),
  cancellationReasonType: bzOptional(z.nativeEnum(MaintenancePlanCancellationReasonType)),
  suppressCancellationEmail: bzOptional(z.boolean()),
  shouldExpireVisitsImmediately: bzOptional(z.boolean()),
})

export const MaintenancePlanCanceledEventName = 'MaintenancePlanCanceled' as const
export type MaintenancePlanCanceledEventData = z.infer<typeof maintenancePlanCanceledSchema>
export type MaintenancePlanCanceledEvent = Event<MaintenancePlanCanceledEventData>

export const MaintenancePlanLapsedEventName = 'MaintenancePlanLapsed' as const
export type MaintenancePlanLapsedEvent = Event

export const MaintenancePlanExpiredEventName = 'MaintenancePlanExpired' as const
export type MaintenancePlanExpiredEvent = Event

export enum MaintenancePlanPaymentInterval {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export const NumPeriodsPerYearForInterval = {
  [MaintenancePlanPaymentInterval.YEARLY]: 1,
  [MaintenancePlanPaymentInterval.MONTHLY]: 12,
  [MaintenancePlanPaymentInterval.QUARTERLY]: 4,
}

export const maintenancePlanPricingSchema = z.object({
  subtotalPeriodPriceUsc: z.number(),
  discountPeriodAmountUsc: z.number(),
  paymentInterval: z.nativeEnum(MaintenancePlanPaymentInterval),
  // NOTE: Must be optional to be backward compatible with old Domain Events
  upchargePeriodAmountUsc: bzOptional(z.number()),
})

export const MaintenancePlanPricingSetEventName = 'MaintenancePlanPricingSet' as const
export type MaintenancePlanPricingSetEventData = z.infer<typeof maintenancePlanPricingSchema>
export type MaintenancePlanPricingSetEvent = Event<MaintenancePlanPricingSetEventData>

export const maintenancePlanPaymentReceivedSchema = z.object({
  paymentRecordGuid: guidSchema,
  paymentAmountUsc: z.number(),
  isOneTimePayment: bzOptional(z.boolean()),
  // This is used to determine if we should grant deprecated credits before maint plans v3 is enabled
  grantDeprecatedCredits: z.boolean(),
})

export const MaintenancePlanPaymentReceivedEventName = 'MaintenancePlanPaymentReceived' as const
export type MaintenancePlanPaymentReceivedEventData = z.infer<typeof maintenancePlanPaymentReceivedSchema>
export type MaintenancePlanPaymentReceivedEvent = Event<MaintenancePlanPaymentReceivedEventData>

export const maintenancePlanVisitCompletedSchema = z.object({
  invoiceGuid: bzOptional(guidSchema),
  jobGuid: bzOptional(guidSchema),
})

export const MaintenancePlanVisitCompletedEventName = 'MaintenancePlanVisitCompleted' as const
export type MaintenancePlanVisitCompletedEventData = z.infer<typeof maintenancePlanVisitCompletedSchema>
export type MaintenancePlanVisitCompletedEvent = Event<MaintenancePlanVisitCompletedEventData>

export const maintenancePlanHistoricalPaymentReceivedSchema = z.object({
  paymentRecordGuid: bzOptional(guidSchema),
  paymentReceivedAt: isoDateStringSchema,
  paymentAmountUsc: z.number(),
})
export type MaintenancePlanHistoricalPaymentReceived = z.infer<typeof maintenancePlanHistoricalPaymentReceivedSchema>

export const maintenancePlanHistoricalVisitsSchema = z.object({
  visitAt: isoDateStringSchema,
  jobGuid: bzOptional(guidSchema),
  invoiceGuid: bzOptional(guidSchema),
})
export type MaintenancePlanHistoricalVisits = z.infer<typeof maintenancePlanHistoricalVisitsSchema>

export const maintenancePlanHistoryImportedEventData = z.object({
  activatedAt: isoDateStringSchema,
  numDaysUntilAutoCancelation: bzOptional(z.number().int()),
  historicalPaymentsReceived: z.array(maintenancePlanHistoricalPaymentReceivedSchema).min(1),
  historicalVisits: bzOptional(z.array(maintenancePlanHistoricalVisitsSchema)),
  // This is used to determine if we should grant deprecated credits before maint plans v3 is enabled
  grantDeprecatedCredits: z.boolean(),
})

export const MaintenancePlanHistoryImportedEventName = 'MaintenancePlanHistoryImported' as const
export type MaintenancePlanHistoryImportedEventData = z.infer<typeof maintenancePlanHistoryImportedEventData>
export type MaintenancePlanHistoryImportedEvent = Event<MaintenancePlanHistoryImportedEventData>

export const FreeMaintenancePlanCreditsGrantedEventName = 'FreeMaintenancePlanCreditsGranted' as const
export type FreeMaintenancePlanCreditsGrantedEvent = Event<null>

export const maintenancePlanMigratedCreditsToVisitsData = z.object({
  migrations: z.array(z.object({ creditIndex: z.number(), visitGuid: guidSchema })),
})

export const MaintenancePlanMigratedCreditsToVisitsEventName = 'MaintenancePlanMigratedCreditsToVisits' as const
export type MaintenancePlanMigratedCreditsToVisitsEventData = z.infer<typeof maintenancePlanMigratedCreditsToVisitsData>
export type MaintenancePlanMigratedCreditsToVisitsEvent = Event<MaintenancePlanMigratedCreditsToVisitsEventData>

export const MaintenancePlanMigratedToStaticPricingEventName = 'MaintenancePlanMigratedToStaticPricing' as const
export const maintenancePlanMigratedToStaticPricingSchema = z.object({
  yearlyStaticPriceUsc: z.number(),
})
export type MaintenancePlanMigratedToStaticPricingEventData = z.infer<
  typeof maintenancePlanMigratedToStaticPricingSchema
>
export type MaintenancePlanMigratedToStaticPricingEvent = Event<MaintenancePlanMigratedToStaticPricingEventData>
