import useIsMobile from '../../../../hooks/useIsMobile'
import { m } from '../../../../utils/react-utils'
import { ContentListItem } from '../../BzCollapsible/CollapsibleItem/CollapsibleItem'

export type VerticalKeyValueProps = Readonly<{
  pair: ContentListItem
  spacing?: 'narrow' | 'medium' | 'default'
  /**
   * Defaults to false
   */
  hideKey?: boolean
}>

const VerticalKeyValue = m<VerticalKeyValueProps>(
  ({ pair, spacing = false, hideKey = false }) => {
    const isMobile = useIsMobile()
    const spacingClassName = isMobile
      ? ''
      : spacing === 'narrow'
      ? ''
      : spacing === 'medium'
      ? 'space-y-1'
      : 'space-y-1'

    return (
      <div>
        {pair.value && (
          <div className={`flex flex-col ${spacingClassName}`}>
            {!hideKey && <div className="semibold_14_22 gray9">{pair.key}</div>}
            <div className="regular_14_22 gray9">{pair.value}</div>
          </div>
        )}
      </div>
    )
  },
)

export default VerticalKeyValue
