import { PermissionV1, PhotoRecordWithLinks } from '@breezy/shared'
import { faImage, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useExpectedPrincipal } from '../../../providers/PrincipalUser'
import { gray7 } from '../../../themes/theme'
import { useMessage } from '../../../utils/antd-utils'
import DangerConfirmModal from '../../DangerConfirmModal/DangerConfirmModal'
import { LoadingSpinner } from '../../LoadingSpinner'
import LinkedNotesListLoader from '../../Notes/LinkedNotesList/LinkedNotesList'
import { useTechExpDrawers } from '../TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'

export type PhotoDetailProps = {
  photo: PhotoRecordWithLinks
  onMutate: () => void
  onCancel?: () => void
}

const PhotoDetailAndEditView = ({
  photo,
  onMutate,
  onCancel,
}: PhotoDetailProps) => {
  const message = useMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const drawers = useTechExpDrawers()
  const deletePhotoMut = trpc.photos['photos:remove'].useMutation()
  const user = useExpectedPrincipal()
  const isAuthorizedToDelete =
    user.permissions.includes(PermissionV1.PHOTOS_ADMIN) ||
    user.userGuid === photo.createdByUserGuid

  const handleDelete = () => {
    setIsLoading(true)
    deletePhotoMut.mutate(
      { photoGuid: photo.photoGuid },
      {
        onSuccess: () => {
          setIsLoading(false)
          message.success('Photo deleted')
          onMutate()
        },
        onError: () => {
          message.error('Failed to delete photo. Unknown error.')
          setIsLoading(false)
        },
      },
    )
  }

  const handleAddNote = () =>
    drawers.beginAddNote({ ...photo, primaryNoteType: 'PHOTO' }, onMutate)

  return (
    <Col
      style={{
        maxWidth: '92vw',
        width: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row style={{ marginBottom: 8 }}>
        <FontAwesomeIcon
          icon={faImage}
          size="lg"
          style={{ color: gray7, marginTop: 3, marginBottom: 2 }}
        />
        <h3 className="m0 ml1" style={{ color: gray7 }}>
          Photo Detail
        </h3>
      </Row>
      <div style={{ borderRadius: 8, marginBottom: '8px' }}>
        <img src={photo.cdnUrl} className="object-fit rounded" alt="Location" />
      </div>

      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {isAuthorizedToDelete && (
            <Button
              type="primary"
              danger
              className="full-width mb-3 mt-1 rounded"
              onClick={() => setIsConfirmModalOpen(true)}
            >
              <FontAwesomeIcon icon={faTrashCan} style={{ marginRight: 8 }} />
              Delete Photo
            </Button>
          )}

          <LinkedNotesListLoader
            noteLinks={{ photoGuid: photo.photoGuid }}
            linkData={{ primaryNoteType: 'PHOTO', photoGuid: photo.photoGuid }}
            maxNotes={1}
            noNotesComponent={
              <Button
                type="primary"
                onClick={handleAddNote}
                className="full-width my-1 rounded"
              >
                Add Description
              </Button>
            }
          />

          {onCancel && (
            <Button onClick={onCancel} className="full-width mt-3 rounded">
              Cancel
            </Button>
          )}

          <DangerConfirmModal
            title="Are you sure?"
            open={isConfirmModalOpen}
            onOk={handleDelete}
            onCancel={() => setIsConfirmModalOpen(false)}
            promptText="This will permanently delete the selected photo!"
          />
        </>
      )}
    </Col>
  )
}

export default PhotoDetailAndEditView
