import { BzDateFns, guidSchema, localDateSchema } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Divider, Form } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'urql'
import { z } from 'zod'
import { AutoCompleteField } from '../../../elements/Forms/AutoCompleteField'
import { DateField } from '../../../elements/Forms/DateField'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../../elements/Forms/SelectField'
import { useReactHookFormSubmit } from '../../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../../hooks/trpc'
import { useCompanyUserContext } from '../../../hooks/useCompanyUserContext'
import { useAccountReminderAssignmentField } from '../AccountReminderAssignmentField/AccountReminderAssignmentField.hooks'
import { DISTINCT_REMINDER_DESCRIPTIONS_QUERY } from '../AccountReminders.gql'
import { Reminder } from '../AccountReminders.types'
import { useAccountRemindersContext } from '../AccountRemindersWrapper/AccountRemindersWrapper'

const AccountRemindersFormSchema = z.object({
  description: z
    .string({ required_error: 'Must add a description for the reminder.' })
    .min(1, { message: 'Description cannot be empty.' })
    .describe('Reminder'),
  dueAt: localDateSchema.describe('Due Date'),
  reminderAssignmentUserGuid: guidSchema.describe('Assign To'),
})

type AccountRemindersFormData = z.infer<typeof AccountRemindersFormSchema>

type AccountRemindersFormProps = {
  hide: () => void
  submitted: () => void
  reminder?: Reminder
}

export const AccountRemindersForm = React.memo<AccountRemindersFormProps>(
  ({ reminder, hide, submitted }) => {
    const { accountGuid, disabled: overallDisabled } =
      useAccountRemindersContext()

    const { timeZoneId, principalUserGuid } = useCompanyUserContext()

    const [
      { data: distinctRemindersData, fetching: distinctRemindersFetching },
    ] = useQuery({
      query: DISTINCT_REMINDER_DESCRIPTIONS_QUERY,
    })

    const { fetching: userOptionsFetching, companyUserOptions } =
      useAccountReminderAssignmentField()

    const descriptionOptions = useMemo(
      () =>
        (distinctRemindersData?.remindersAggregate.nodes ?? []).map(
          ({ description }) => ({
            value: description,
            label: description,
          }),
        ),
      [distinctRemindersData?.remindersAggregate.nodes],
    )

    const defaultValues = useMemo<Partial<AccountRemindersFormData>>(
      () => ({
        description: reminder?.description ?? '',
        reminderAssignmentUserGuid:
          reminder?.reminderAssignments[0].user.userGuid ?? principalUserGuid,
        dueAt: reminder?.dueAt
          ? BzDateFns.formatLocalDate(
              BzDateFns.parseISO(reminder.dueAt, timeZoneId),
            )
          : undefined,
      }),
      [
        principalUserGuid,
        reminder?.description,
        reminder?.dueAt,
        reminder?.reminderAssignments,
        timeZoneId,
      ],
    )

    const {
      control,
      formState: { isDirty, errors },
      handleSubmit,
      reset,
    } = useForm<AccountRemindersFormData>({
      resolver: zodResolver(AccountRemindersFormSchema),
      defaultValues,
    })

    const writeAccountReminder =
      trpc.accountReminders['account-reminders:write'].useMutation()

    const onSubmit = useCallback(
      async (data: AccountRemindersFormData) => {
        writeAccountReminder.mutate(
          {
            accountGuid,
            ...data,
            reminderGuid: reminder?.reminderGuid,
          },
          {
            onSuccess: () => {
              submitted()
              hide()
              reset()
            },
          },
        )
      },
      [
        accountGuid,
        hide,
        reminder?.reminderGuid,
        reset,
        submitted,
        writeAccountReminder,
      ],
    )

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    const disabled =
      overallDisabled ||
      distinctRemindersFetching ||
      userOptionsFetching ||
      writeAccountReminder.isLoading

    return (
      <Form
        className="mt-4"
        layout="vertical"
        onSubmitCapture={handleSubmit(onSubmit)}
        disabled={disabled}
      >
        <ReactHookFormItem
          control={control}
          name="description"
          label="Reminder"
          required
          errors={errors}
          render={({ field }) => (
            <AutoCompleteField
              {...field}
              size="middle"
              options={descriptionOptions}
            />
          )}
        />
        <div className="flex flex-row space-x-3 *:flex-1 *:basis-0">
          <ReactHookFormItem
            required
            noBottomMargin
            control={control}
            name="dueAt"
            label="Due Date"
            errors={errors}
            render={({ field }) => (
              <DateField format="MMMM d, yyyy" size="middle" {...field} />
            )}
          />
          <ReactHookFormItem
            required
            noBottomMargin
            control={control}
            name="reminderAssignmentUserGuid"
            label="Assign To"
            errors={errors}
            render={({ field }) => (
              <SelectField
                {...field}
                size="middle"
                title="Assign To"
                options={companyUserOptions}
              />
            )}
          />
        </div>
        <Divider />
        <div className="flex flex-row justify-end space-x-3">
          <Button onClick={hide}>Cancel</Button>
          <Button
            onClick={triggerSubmit}
            type="primary"
            disabled={!isDirty || disabled}
          >
            Save Reminder
          </Button>
        </div>
        <Divider />
        {submitElement}
      </Form>
    )
  },
)
