import { R } from '@breezy/shared'
import { isNullish } from '@breezy/shared/src'
import { useEffect, useRef, useState } from 'react'

/**
 * Runs a function when a save is completed, and, optionally, when data has changed.
 *
 * This is meant to help with a very specific, but common scenario. I am on an edit page and hit save. I want to
 * navigate to an overview page. I don't want to see "You have unsaved changes" and I don't want to see the old data on
 * the overview page while I wait for the new data to load.
 *
 * For the first issue, on edit pages we generally use useBlocker that checks if a form is dirty and blocks navigation
 * accordingly. However, there is an order-of-operations issue. On save I want to navigate. Ideally I could just clear a
 * dirty flag before I do to stop the blocker, but the navigation, and thus the blocking, happens before the blocker
 * function updates with the new dirty flag state. So our navigation needs to be deferred by one tick. Instead of
 * navigating we expect the caller to set a flag. That flag causes a rerender that updates the blocker function. That
 * flag can, in the same render, cause us to rerender, triggering our `useEffect` that uses a `setTimeout` to, at the
 * bottom of the event queue, execute the navigation.
 *
 * For the second issue, what often happens is the edit view is a state on an overview page. The overview page gets the
 * data via a subscription. When we hit save, we immediately navigate back to the overview page (less a navigate and
 * more setting a state that puts us out of edit mode). The subscription doesn't update instantaneously, so we see the
 * old data for a flash and then see the new data. One way we can get get around this is leverage "default values",
 * which are generally present on these edit pages. The edit pages get the data of the entity being edited to prefill
 * their forms. That data comes from the same subscription that fuels the overview page. If we defer our navigation not
 * just for that second rerender, but also until the new data has loaded, then we can make sure that when we navigate
 * the overview page has the latest data. All that said, the "wait for the new data" requirement isn't universal, so
 * that's an optional prop and when it's set we'll just navigate once the flag is set.
 *
 * These are two independent issues, but both involve deferring a navigation on an edit page, so it makes sense to merge
 * them into one helper.
 *
 * @param onSave The function to invoke when the save operation is done (and the new data has loaded, if supplied)
 * @param successfullySaved A flag that, when true, triggers the navigation (if the data is defined and changes)
 * @param data Some data that, when it deep-equal changes, we navigate away (once/if successfullySaved is true)
 */
export const useRedirectOnSave = (
  onSave: () => void,
  successfullySaved: boolean,
  data?: unknown,
) => {
  const lastData = useRef(data)
  const [dataHasChanged, setDataHasChanged] = useState(false)
  useEffect(() => {
    if (!R.equals(lastData.current, data)) {
      setDataHasChanged(true)
    }
  }, [data])

  // So this is a hack. We need to defer our navigation until the blocker has had a chance to update. The blocker is
  // presumably looking at `successfullySaved` as well. So we need to wait for that to happen. When a state variable
  // changes, it starts a rerender that could trigger a `useEffect`. If we set a state variable when `successfullySaved`
  // changes, that triggers ANOTHER useEffect on the NEXT render cycle. We'll use some setTimeouts for good measure.
  const [internalSuccessfullySaved, setInternalSuccessfullySaved] =
    useState(false)

  useEffect(() => {
    if (successfullySaved) {
      setTimeout(() => setInternalSuccessfullySaved(true), 0)
    }
  }, [successfullySaved])

  useEffect(() => {
    if (internalSuccessfullySaved && (dataHasChanged || isNullish(data))) {
      setTimeout(onSave, 0)
    }
  }, [data, dataHasChanged, onSave, internalSuccessfullySaved])
}

// Wrapper around `useRedirectOnSave` that encapsulates the target use case more specifically. That function requires
// the parent to have certain state variables. It also requires some logic when there's the ability to save as a draft.
export const useUnblockedNavigateOnSave = (
  onSave: () => void,
  onDraftSave: () => void,
  defaultValues?: unknown,
) => {
  const [successfullySaved, setSuccessfullySaved] = useState(false)
  const [successfullySavedAsDraft, setSuccessfullySavedAsDraft] =
    useState(false)

  useRedirectOnSave(onSave, successfullySaved, defaultValues)
  useRedirectOnSave(onDraftSave, successfullySavedAsDraft, defaultValues)

  return {
    // If we've successfully saved, never block
    savingSuccessful: successfullySaved || successfullySavedAsDraft,
    setSuccessfullySaved,
    setSuccessfullySavedAsDraft,
  }
}
