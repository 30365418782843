import React from 'react'
import { z } from 'zod'
import BzSelect from '../BzSelect/BzSelect'
export const StateSchema = z
  .string()
  .regex(
    /^(AK|AL|AR|AZ|CA|CO|CT|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NC|ND|NE|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)$/,
  )
export type State = z.infer<typeof StateSchema>

export const STATE_ABBREVIATIONS = [
  'AL', // Alabama
  'AK', // Alaska
  'AZ', // Arizona
  'AR', // Arkansas
  'CA', // California
  'CO', // Colorado
  'CT', // Connecticut
  'DE', // Delaware
  'FL', // Florida
  'GA', // Georgia
  'HI', // Hawaii
  'ID', // Idaho
  'IL', // Illinois
  'IN', // Indiana
  'IA', // Iowa
  'KS', // Kansas
  'KY', // Kentucky
  'LA', // Louisiana
  'ME', // Maine
  'MD', // Maryland
  'MA', // Massachusetts
  'MI', // Michigan
  'MN', // Minnesota
  'MS', // Mississippi
  'MO', // Missouri
  'MT', // Montana
  'NE', // Nebraska
  'NV', // Nevada
  'NH', // New Hampshire
  'NJ', // New Jersey
  'NM', // New Mexico
  'NY', // New York
  'NC', // North Carolina
  'ND', // North Dakota
  'OH', // Ohio
  'OK', // Oklahoma
  'OR', // Oregon
  'PA', // Pennsylvania
  'RI', // Rhode Island
  'SC', // South Carolina
  'SD', // South Dakota
  'TN', // Tennessee
  'TX', // Texas
  'UT', // Utah
  'VT', // Vermont
  'VA', // Virginia
  'WA', // Washington
  'WV', // West Virginia
  'WI', // Wisconsin
  'WY', // Wyoming
]

const STATE_ABBREVIATION_OPTIONS = STATE_ABBREVIATIONS.map(value => ({
  value,
}))

type StateFieldProps = {
  value: string | undefined
  onChange: (value: string) => void
  disabled?: boolean
  size?: 'large' | 'middle'
}
const StateFieldInner = (
  { value, onChange, disabled, size = 'large' }: StateFieldProps,
  ref: React.ForwardedRef<unknown>,
) => {
  return (
    <BzSelect
      title="State"
      size={size}
      showSearch
      value={value}
      options={STATE_ABBREVIATION_OPTIONS.map(option => ({
        ...option,
        label: option.value,
      }))}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export const StateField = React.memo(
  React.forwardRef(StateFieldInner),
) as typeof StateFieldInner
