import { z } from 'zod'
import { BzDateFns, DateTimeFormatter, IsoDateString, TimeZoneId, ZoneId, ZonedDateTime, convert } from '../../common'
import { isoDateStringSchema } from '../../contracts'
import { BzDateTime, UTC_TIME_ZONE } from './BzDateTime'

export interface TimeWindow {
  readonly start: ZonedDateTime
  readonly end: ZonedDateTime
}

export const timeWindowDtoSchema = z.object({
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type TimeWindowDto = z.infer<typeof timeWindowDtoSchema>

export interface NativeJsTimeWindowDto {
  readonly start: Date
  readonly end: Date
}

export class BzTimeWindow implements TimeWindow {
  constructor(public readonly start: ZonedDateTime, public readonly end: ZonedDateTime) {}

  static ancientPast(): BzTimeWindow {
    return new BzTimeWindow(ZonedDateTime.parse('0001-01-01T00:00:00Z'), ZonedDateTime.parse('0001-01-01T00:00:00Z'))
  }

  static fromStartEndOrCurrentDay(tz: TimeZoneId, start?: IsoDateString, end?: IsoDateString): BzTimeWindow {
    return start && end ? BzTimeWindow.fromDto({ start, end }, tz) : BzDateTime.startOfToday(tz).forDays(1)
  }

  static fromDto(dto: TimeWindowDto, tzId = UTC_TIME_ZONE): BzTimeWindow {
    return new BzTimeWindow(
      ZonedDateTime.parse(dto.start).withZoneSameInstant(ZoneId.of(tzId)),
      ZonedDateTime.parse(dto.end).withZoneSameInstant(ZoneId.of(tzId)),
    )
  }

  static toDto(start: ZonedDateTime, end: ZonedDateTime): TimeWindowDto {
    return {
      // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1016
      // eslint-disable-next-line breezy/no-to-iso-date-string
      start: BzDateFns.toIsoDateString(start.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)),
      // eslint-disable-next-line breezy/no-to-iso-date-string
      end: BzDateFns.toIsoDateString(end.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)),
    }
  }

  toDto(): TimeWindowDto {
    return BzTimeWindow.toDto(this.start, this.end)
  }

  toNativeJs(): NativeJsTimeWindowDto {
    return {
      start: convert(this.start.withZoneSameInstant(ZoneId.of('UTC'))).toDate(),
      end: convert(this.end.withZoneSameInstant(ZoneId.of('UTC'))).toDate(),
    }
  }

  startDate(): Date {
    return convert(this.start.withZoneSameInstant(ZoneId.of('UTC'))).toDate()
  }

  endDate(): Date {
    return convert(this.end.withZoneSameInstant(ZoneId.of('UTC'))).toDate()
  }

  contains(other: BzDateTime): boolean {
    return !this.start.isAfter(other.toJsJoda()) && !this.end.isBefore(other.toJsJoda())
  }

  containsMomentIso(iso: IsoDateString): boolean {
    return this.contains(BzDateTime.fromIsoString(iso, UTC_TIME_ZONE))
  }
}
