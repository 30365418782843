import { bzExpect } from '@breezy/shared'
import { useQuery } from 'urql'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { TECH_LOCATION_APPT_ASSIGNMENTS_QUERY } from './TechnicianHooks.gql'

export const useIsTechAssignedToLocation = (locationGuid: string) => {
  const principal = useExpectedPrincipal()
  const companyGuid = bzExpect(
    principal.company?.companyGuid,
    'companyGuid',
    'User Has No Company',
  )

  const [techAssignedToLocationQuery] = useQuery({
    query: TECH_LOCATION_APPT_ASSIGNMENTS_QUERY,
    variables: {
      locationGuid,
      companyGuid,
      technicianUserGuid: principal.userGuid,
    },
  })

  return {
    isLoading: techAssignedToLocationQuery.fetching,
    isTechAssignedToLocation: Boolean(
      techAssignedToLocationQuery.data?.jobAppointments?.length &&
        techAssignedToLocationQuery.data.jobAppointments.length > 0,
    ),
  }
}
