import {
  RenewalTypeOption,
  getRenewalTypeOptionFromBillingProfiles,
} from '@breezy/shared'
import { useMemo } from 'react'
import { useGetCompanyBillingProfile } from './useGetCompanyBillingProfile'

export const useCompanyMaintenancePlanRenewalType = (
  companyGuid: string,
): RenewalTypeOption | undefined => {
  const { data: companyBillingProfileData } =
    useGetCompanyBillingProfile(companyGuid)

  const renewalTypeOption = useMemo(() => {
    if (companyBillingProfileData?.billingProfiles) {
      return getRenewalTypeOptionFromBillingProfiles(
        companyBillingProfileData.billingProfiles,
      )
    }
    return undefined
  }, [companyBillingProfileData])

  return renewalTypeOption
}
