import { Keyed } from './common-type-wrappers'
import { AsyncFn } from './functional-core'
import { Log } from './logging'

export type AlertMessage = Keyed<{
  readonly message: string
}>

export class Alert {
  private static alertSink: AsyncFn<AlertMessage> = async () => {
    return
  }

  static init = (alertSink: AsyncFn<AlertMessage>) => {
    return (this.alertSink = alertSink)
  }

  static publish = async (message: AlertMessage) => this.alertSink(message)
  static fireAndForget = async (message: AlertMessage) => {
    try {
      return await this.alertSink(message)
    } catch (error) {
      Log.error(`Unable to publish Alert ${error}`)
      throw error
    }
  }
}
