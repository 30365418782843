import { useEffect } from 'react'

export type SetIsDirty = (isDirty: boolean) => void

export const useExternalIsDirty = (
  setIsDirty: SetIsDirty | undefined,
  isDirty: boolean,
) => {
  useEffect(() => {
    return () => {
      setIsDirty?.(false)
    }
    // I only want this to run on dismount. If they are messing around with the "setIsDirty" function I don't want to
    // constantly call it and unset it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsDirty?.(isDirty)
  }, [isDirty, setIsDirty])
}
