import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import classNames from 'classnames'
import React from 'react'

type TextAreaFieldProps = TextAreaProps

export const TextAreaField = React.memo(
  React.forwardRef<TextAreaRef, TextAreaFieldProps>(
    ({ name, className, rows = 5, ...rest }, ref) => (
      <Input.TextArea
        ref={ref}
        id={name}
        data-testid={name}
        rows={rows}
        className={classNames('w-full', className)}
        {...rest}
      />
    ),
  ),
)
