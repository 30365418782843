import { Address, BzAddress } from '@breezy/shared'
import { getConfig } from '../config'

const { mapsAPIKey } = getConfig()

export const getPlacePostCodeById = async (
  placeId: string,
  placesService: google.maps.places.PlacesService,
): Promise<string> => {
  return new Promise((resolve, _) => {
    try {
      placesService.getDetails(
        {
          placeId,
          fields: ['address_components'],
        },
        details => {
          let postcode = ''
          details?.address_components?.forEach(entry => {
            if (entry.types?.[0] === 'postal_code') {
              postcode = entry.long_name
            }
          })
          return resolve(postcode)
        },
      )
    } catch (e) {
      console.error(e)
      return resolve('')
    }
  })
}

export const getGoogleMapScreenshotSrc = (address: Address) =>
  'https://maps.googleapis.com/maps/api/staticmap?center=' +
  encodeURIComponent(
    address.line1 +
      ', ' +
      address.city +
      ', ' +
      address.stateAbbreviation +
      ' ' +
      BzAddress.getZip5(address),
  ) +
  '&zoom=15&size=400x250&maptype=roadmap&markers=color:red%7Clabel:A%7C' +
  `&key=${mapsAPIKey}`
