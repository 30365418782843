import { R } from '@breezy/shared'
import { useEffect, useState } from 'react'
import { getConfig } from '../config'
import {
  TilledClient,
  TilledForm,
  TilledFormFieldChangeHandler,
  TilledFormFieldOptions,
  TilledFormFieldType,
  TilledPaymentMethodType,
} from '../utils/tilledSdkTypes'
import useScript, { ErrorState } from './useScript'

type TilledPaymentObject = {
  type: TilledPaymentMethodType
  fields: Partial<Record<TilledFormFieldType, TilledFormFieldOptions>>
}

type LoadingTilled = {
  loading: true
  value: undefined
  error: undefined
}

type LoadedTilled<T> = {
  loading: false
  value: T
  error: undefined
}

type LoadedTilledError = {
  loading: false
  value: undefined
  error: ErrorState
}

type TilledLoadingWrapper<T> =
  | LoadingTilled
  | LoadedTilled<T>
  | LoadedTilledError

// TODO: Move this to a context so it's only ever instantiated once.
const useTilledInstance = (
  accountId: string,
): TilledLoadingWrapper<TilledClient> => {
  const { publicKey, useSandbox } = getConfig().tilled
  const [loading, error] = useScript({
    src: 'https://js.tilled.com/v2',
    checkForExisting: true,
  })
  const [tilledInstance, setTilledInstance] = useState<TilledClient>()
  useEffect(() => {
    if (loading || error) return
    setTilledInstance(
      new window.Tilled(publicKey, accountId, {
        sandbox: useSandbox,
        log_level: 0,
      }),
    )
  }, [loading, error, publicKey, accountId, useSandbox])

  if (error && !loading) {
    return {
      loading: false,
      value: undefined,
      error,
    }
  }

  if (loading || !tilledInstance) {
    return {
      loading: true,
      value: undefined,
      error: undefined,
    }
  }

  return {
    loading: false,
    value: tilledInstance,
    error: undefined,
  }
}

export type TilledFormInfo = {
  tilled: TilledClient
  form?: TilledForm
  type: TilledPaymentMethodType
  tilledFormBuilt: boolean
}

// TODO: Accept an array of field names instead of a mapped object with class names
// and return a mapped object with refs.
const useTilledForm = (
  accountId: string,
  paymentTypeObj: TilledPaymentObject,
  onChange: TilledFormFieldChangeHandler,
  commonFieldOptions: Partial<TilledFormFieldOptions> = {},
): TilledLoadingWrapper<TilledFormInfo> => {
  const { loading, error, value: tilledInstance } = useTilledInstance(accountId)

  const [tilledFormInstance, setFormTilledInstance] = useState<TilledForm>()
  const [tilledFormBuilt, setTilledFormBuilt] = useState(false)
  useEffect(() => {
    if (!tilledInstance || tilledFormInstance) {
      return
    }
    ;(async () => {
      const tilledForm = await tilledInstance.form({
        payment_method_type: paymentTypeObj.type,
      })
      for (const field of R.keys(paymentTypeObj.fields)) {
        const options = paymentTypeObj.fields[field]
        tilledForm
          .createField(field, {
            ...commonFieldOptions,
            ...options,
          })
          .on('change', onChange)
      }
      setFormTilledInstance(tilledForm)
      // We need to make sure this waits until the child form is rendered with its fields having the IDs before we
      // "hydrate" it. It worked ok for me with a 0, but I set to 50 just to be safe
      setTimeout(async () => {
        await tilledForm.build()
        setTilledFormBuilt(true)
      }, 50)
    })()
    // Note the commented-out dependencies below. I don't want changes to those to cause us to create a brand new form
    // (especially if the caller doesn't memoize them)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tilledInstance,
    tilledFormInstance,
    // onChange,
    // commonFieldOptions,
    // paymentTypeObj,
  ])

  useEffect(
    () => () => {
      tilledFormInstance?.teardown(() => Promise.resolve())
    },
    [tilledFormInstance],
  )

  if (error) {
    return {
      loading: false,
      value: undefined,
      error,
    }
  }
  if (loading || !tilledInstance) {
    return {
      loading: true,
      value: undefined,
      error: undefined,
    }
  }

  return {
    loading,
    error,
    value: {
      tilled: tilledInstance,
      form: tilledFormInstance,
      type: paymentTypeObj.type,
      tilledFormBuilt,
    },
  }
}

export default useTilledForm
