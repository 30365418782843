import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import { FileMetadata, FileRecord, noOp } from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSubscription } from 'urql'
import { useFetchComprehensiveJobDetailsByJobGuidQuery } from '../../hooks/fetch/useFetchComprehensiveJobDetailsByJobGuid'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { m } from '../../utils/react-utils'
import FileElements from '../Files/FileElements'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import { AsyncFileUpload } from '../Upload/AsyncFileUpload'
import { useSynchronousUpload } from '../Upload/Upload'
import {
  LINKED_JOB_DATA_SUBSCRIPTION,
  LinkedJobData,
} from './JobAttachments.gql'

const linkedJobDataToFileRecordMapper = (data: LinkedJobData): FileRecord[] => {
  return data.linked_job.fileLinks.map(({ file, fileGuid }) => ({
    fileGuid,
    companyGuid: data.linked_job.companyGuid,
    cdnUrl: file.cdnUrl,
    fileName: file.fileName,
    metadata: file.metadata as FileMetadata,
    userGuid: file.createdByUserGuid,
    createdAt: file.createdAt,
    resourceUrn: file.resourceUrn,
    fileTypeMime: file.fileTypeMime,
    fileSizeBytes: file.fileSizeBytes,
    storageStrategy: file.storageStrategy,
  }))
}

type JobAttachmentsProps = {
  jobGuid: string
  comprehensiveJobDetails?: ComprehensiveJobDetails
  disableUpload?: boolean
  editable?: boolean
  refetch?: () => unknown
}

const JobAttachments = m<JobAttachmentsProps>(
  ({
    jobGuid,
    comprehensiveJobDetails,
    disableUpload,
    editable = true,
    refetch,
  }) => {
    const linkedJobsEnabled = useFeatureFlag('linkedJobs')

    const shouldUseOwnData = !comprehensiveJobDetails
    const fetchComprehensiveJobDetailsQuery =
      useFetchComprehensiveJobDetailsByJobGuidQuery({
        jobGuid: jobGuid ?? comprehensiveJobDetails?.getJobGuid(),
        opts: {
          enabled: shouldUseOwnData,
        },
      })

    const jobDetails =
      fetchComprehensiveJobDetailsQuery.data ?? comprehensiveJobDetails

    const onMutate = useCallback(() => {
      if (shouldUseOwnData) {
        fetchComprehensiveJobDetailsQuery.refetch()
      }
      refetch?.()
    }, [fetchComprehensiveJobDetailsQuery, refetch, shouldUseOwnData])

    const linkedAttachments = useSubscription({
      query: LINKED_JOB_DATA_SUBSCRIPTION,
      variables: { jobGuid },
      pause: !linkedJobsEnabled,
    })

    const fileLinks = useMemo(
      () => ({
        accountGuid: jobDetails?.getAccount?.().accountGuid,
        jobGuid: jobGuid,
      }),
      [jobDetails, jobGuid],
    )

    const onFileUploadChange = useSynchronousUpload(onMutate)

    return (
      <div className="w-full">
        {jobDetails && (
          <div>
            {!disableUpload && (
              <div className="mb-3">
                <AsyncFileUpload
                  links={fileLinks}
                  onFileUploadChange={onFileUploadChange}
                />
              </div>
            )}
            <div className="flex w-full flex-col gap-5">
              <FileElements
                onMutate={onMutate}
                files={jobDetails.getFiles()}
                editable={editable}
              />

              <GqlQueryLoader
                query={linkedAttachments}
                render={data => (
                  <>
                    {data.jobsWithLinkedJob.length > 0 &&
                    data.jobsWithLinkedJob[0].linkAttachments ? (
                      <>
                        <div className="bg-bz-gray-300 px-3 py-2">
                          <span className="text-sm font-semibold text-bz-gray-900">
                            Linked from{' '}
                            <Link
                              to={`/jobs/${data.jobsWithLinkedJob[0].linked_job.jobGuid}`}
                            >
                              Job #
                              {data.jobsWithLinkedJob[0].linked_job.displayId}
                            </Link>
                          </span>
                        </div>

                        <FileElements
                          onMutate={noOp}
                          files={linkedJobDataToFileRecordMapper(
                            data.jobsWithLinkedJob[0],
                          )}
                          editable={false}
                        />
                      </>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
        )}
      </div>
    )
  },
)

export default JobAttachments
