import {
  LoanRecord,
  MIN_FINANCEABLE_AMOUNT_USD,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import {
  createTsForm,
  createUniqueFieldSchema,
  useDescription,
  useTsController,
} from '@ts-react/form'
import { Button, Form, InputNumber } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { z } from 'zod'
import { useMessage } from '../../../utils/antd-utils'
import { useWisetackWebhookTestMutation } from './hooks'

const createFinancingAmountUsdSchema = (maxValue: number) =>
  createUniqueFieldSchema(
    z
      .number()
      .min(MIN_FINANCEABLE_AMOUNT_USD, 'Financing amount must be at least $500')
      .lt(
        maxValue,
        `Financing amount must be less than the requested amount of $${maxValue}`,
      ),
    'financingAmountUsd',
  )

const FinancingAmountUsdField = React.memo(() => {
  const {
    field: { onChange, value },
  } = useTsController<string>()

  const { label, placeholder } = useDescription()
  const { error } = useTsController<string>()

  return (
    <Form.Item
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      <InputNumber
        value={value}
        className="w-full"
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        onChange={val => onChange(isNullish(val) ? undefined : val)}
        placeholder={placeholder}
      />
    </Form.Item>
  )
})

type SimulateLoanPartialApprovalFormProps = {
  loanRecord: LoanRecord
}

type FormWrapperProps = React.PropsWithChildren<
  SimulateLoanPartialApprovalFormProps & {
    loading: boolean
    onSubmit: () => void
  }
>

const FormWrapper = ({ onSubmit, loading, children }: FormWrapperProps) => {
  return (
    <Form
      onSubmitCapture={onSubmit}
      layout="vertical"
      className="flex min-h-0 flex-1 flex-col gap-1"
      requiredMark={label => (
        <div className="flex flex-row items-center">
          {label}
          <span className="ml-0.5 text-bz-primary">*</span>
        </div>
      )}
    >
      <div className="flex flex-col">
        {children}
        <Button
          className="mt-2"
          type="primary"
          disabled={loading}
          htmlType="submit"
        >
          Trigger
        </Button>
      </div>
    </Form>
  )
}

const useSimulateLoanPartialApprovalForm = (loanRecord: LoanRecord) => {
  const message = useMessage()
  const { triggerWisetackWebhook } = useWisetackWebhookTestMutation()
  const FinancingAmountUsdSchema = createFinancingAmountUsdSchema(
    usCentsToUsd(loanRecord.requestedLoanAmountUsc),
  )

  const SimulateLoanPartialApprovalFormSchema = useMemo(
    () =>
      z.object({
        financingAmountUsd: FinancingAmountUsdSchema.describe(
          `Approve Partial Amount (USD)`,
        ),
      }),
    [FinancingAmountUsdSchema],
  )

  type SimulateLoanPartialApprovalFormData = z.infer<
    typeof SimulateLoanPartialApprovalFormSchema
  >

  const mapping = useMemo(
    () => [[FinancingAmountUsdSchema, FinancingAmountUsdField]] as const,
    [FinancingAmountUsdSchema],
  )

  const TsForm = useMemo(
    () =>
      createTsForm(mapping, {
        FormComponent: FormWrapper,
      }),
    [mapping],
  )

  const onSubmit = useCallback(
    async (data: SimulateLoanPartialApprovalFormData) => {
      await triggerWisetackWebhook({
        loanRecord,
        changedStatus: 'AUTHORIZED',
        partialAmountUsd: data.financingAmountUsd,
      })
      message.success('Partial Amount Webhook triggered')
    },
    [triggerWisetackWebhook, loanRecord, message],
  )

  return {
    TsForm,
    SimulateLoanPartialApprovalFormSchema,
    onSubmit,
  }
}

const SimulateLoanPartialApprovalForm =
  React.memo<SimulateLoanPartialApprovalFormProps>(({ loanRecord }) => {
    const { TsForm, SimulateLoanPartialApprovalFormSchema, onSubmit } =
      useSimulateLoanPartialApprovalForm(loanRecord)

    return (
      <TsForm
        schema={SimulateLoanPartialApprovalFormSchema}
        onSubmit={onSubmit}
        formProps={{
          loanRecord: loanRecord,
          loading: false,
        }}
      />
    )
  })

export default SimulateLoanPartialApprovalForm
