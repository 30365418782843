import {
  AccountContact,
  BzContact,
  Contact,
  LocationContact,
  isNullish,
  prettifyPhoneNumberType,
  toNotificationPreferenceTypeDisplayName,
} from '@breezy/shared'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { ReactNode } from 'react'
import { ActionItem } from '../../../elements/ActionItems/ActionItems'
import { CallablePhoneLink } from '../../../elements/CallablePhoneLink/CallablePhoneLink'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { MaybeDataPoint } from '../../../elements/MaybeDataPoint/MaybeDataPoint'
import { m } from '../../../utils/react-utils'
import { AccountContactTag } from '../../Accounts/AccountContactTag/AccountContactTag'
import { LocationContactTagGroup } from '../../LocationContacts/LocationContactTag'
import BzCollapsible, {
  BzCollapsibleProps,
} from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem, {
  CollapsibleItemProps,
  ContentListItem,
} from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type BaseContactsCollapsibleProps = {
  title?: string
  onPlus?: BzCollapsibleProps['onPlus']
}

type GroupedContactsCollapsibleProps = BaseContactsCollapsibleProps & {
  accountContacts: AccountContact[]
  locationContacts: LocationContact[]
  onEditAccountContact?: (accountContact: AccountContact) => void
  onEditLocationContact?: (locationContact: LocationContact) => void
  onRemoveLocationContact?: (locationContact: LocationContact) => void
  editable?: boolean
}

export const GroupedContactsCollapsible = m<GroupedContactsCollapsibleProps>(
  ({
    accountContacts,
    locationContacts,
    onEditAccountContact,
    onEditLocationContact,
    onRemoveLocationContact,
    title = 'Contacts',
    onPlus,
    editable = true,
  }) => {
    return (
      <BzCollapsible
        title={title}
        titleIcon={faUser}
        defaultOpen
        showChildrenLength={false}
        onPlus={editable ? onPlus : undefined}
      >
        <div className="flex flex-col space-y-5">
          {locationContacts.length > 0 && (
            <div>
              <div className="semibold_14_22 grey7 mb-2">
                Contacts linked to Location
              </div>
              <div className="flex flex-col space-y-2">
                {locationContacts.map(locationContact => {
                  const actionItems: ActionItem[] = []
                  const onEdit = onEditLocationContact
                    ? () => onEditLocationContact(locationContact)
                    : undefined

                  if (editable && onRemoveLocationContact) {
                    actionItems.push({
                      title: 'Remove Location Contact',
                      onClick: () => onRemoveLocationContact(locationContact),
                    })
                  }

                  return (
                    <ContactCollapsibleItem
                      key={locationContact.contact.contactGuid}
                      contact={locationContact.contact}
                      note={locationContact.notes}
                      actionItems={actionItems}
                      onEdit={editable ? onEdit : undefined}
                      tag={
                        <LocationContactTagGroup
                          locationContact={locationContact}
                        />
                      }
                    />
                  )
                })}
              </div>
            </div>
          )}

          {accountContacts.length > 0 && (
            <div>
              <div className="semibold_14_22 grey7 mb-2">
                Contacts linked to Account
              </div>
              <div className="flex flex-col space-y-2">
                {accountContacts.map(accountContact => {
                  const onEdit = onEditAccountContact
                    ? () => onEditAccountContact(accountContact)
                    : undefined

                  return (
                    <ContactCollapsibleItem
                      key={accountContact.contact.contactGuid}
                      contact={accountContact.contact}
                      onEdit={editable ? onEdit : undefined}
                      tag={
                        <AccountContactTag accountContact={accountContact} />
                      }
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </BzCollapsible>
    )
  },
)

type FlatContactsCollapsibleProps = BaseContactsCollapsibleProps & {
  contacts: Contact[]
  onEditContact?: (accountContact: Contact) => void
}

export const FlatContactsCollapsible = m<FlatContactsCollapsibleProps>(
  ({ contacts, onEditContact, title = 'Contacts', onPlus }) => {
    return (
      <BzCollapsible
        title={title}
        titleIcon={faUser}
        defaultOpen
        showChildrenLength={false}
        onPlus={onPlus}
      >
        {contacts.length > 0 && (
          <>
            {contacts.map(contact => {
              const onEdit = onEditContact
                ? () => onEditContact(contact)
                : undefined
              return (
                <ContactCollapsibleItem
                  key={contact.contactGuid}
                  onEdit={onEdit}
                  contact={contact}
                />
              )
            })}
          </>
        )}
      </BzCollapsible>
    )
  },
)

type ContactCollapsibleItemProps = {
  contact: Contact
  note?: string | null
  actionItems?: CollapsibleItemProps['actionItems']
  tag?: ReactNode | null
  onEdit?: () => void
}

export const ContactCollapsibleItem = m<ContactCollapsibleItemProps>(
  ({ contact, note, tag = null, actionItems, onEdit }) => {
    const effectiveContact = BzContact.create(contact)

    const contentList: ContentListItem[] = []

    contentList.push({
      key: 'Email:',
      value: effectiveContact.primaryEmailAddress ? (
        <a
          className="break-all"
          href={`mailto:${effectiveContact.primaryEmailAddress.emailAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {effectiveContact.primaryEmailAddress.emailAddress}
        </a>
      ) : (
        <EmDash />
      ),
    })

    const phoneNumberContent = effectiveContact.primaryPhoneNumber
      ? `${
          effectiveContact.primaryPhoneNumber?.phoneNumber
        } (${prettifyPhoneNumberType(
          effectiveContact.primaryPhoneNumber?.type,
        )})`
      : ''

    contentList.push({
      key: 'Phone:',
      value: effectiveContact.primaryPhoneNumber ? (
        <CallablePhoneLink
          phoneNumber={effectiveContact.primaryPhoneNumber.phoneNumber}
        >
          {effectiveContact &&
          effectiveContact.primaryPhoneNumber.unsubscribed ? (
            <span>
              <span className="line-through">{phoneNumberContent}</span> (Opted
              out)
            </span>
          ) : (
            phoneNumberContent
          )}
        </CallablePhoneLink>
      ) : (
        <EmDash />
      ),
    })

    contentList.push({
      key: 'Notification Preference:',
      value: toNotificationPreferenceTypeDisplayName(
        effectiveContact.notificationPreferenceType,
      ),
    })

    if (!isNullish(note)) {
      contentList.push({
        key: 'Note:',
        value: <MaybeDataPoint>{note}</MaybeDataPoint>,
      })
    }

    return (
      <CollapsibleItem
        key={effectiveContact.contactGuid}
        title={effectiveContact.fullName}
        contentList={contentList}
        alwaysOpen={true}
        tag={tag}
        actionItems={actionItems}
        onEdit={onEdit}
      />
    )
  },
)
