import classNames from 'classnames'

type CreationStepHeadingProps = {
  readonly heading: string
  readonly subheading?: string
  className?: string
} & (
  | { bottomDivider: true; dividerClassName?: string }
  | { bottomDivider?: false; dividerClassName?: never }
)

const CreationStepHeading = ({
  heading,
  subheading,
  bottomDivider = false,
  className,
  dividerClassName = 'mb-2 mt-3',
}: CreationStepHeadingProps) => {
  return (
    <div className={classNames('flex w-full flex-col', className ?? 'gap-y-2')}>
      <div className="m-0 w-full p-0 text-left text-[30px] font-semibold leading-[38px] text-[#202020]">
        {heading}
      </div>
      {subheading && (
        <div className="gray7 text-left text-base">{subheading}</div>
      )}
      {bottomDivider && (
        <div
          className={classNames('h-2 w-full bg-[#f4f4f4]', dividerClassName)}
        />
      )}
    </div>
  )
}

export default CreationStepHeading
