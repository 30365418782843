import { Composable } from '../../utils/Composable'
import './PaymentAntOverrides.less'

type StylingProps = Composable & {
  readonly className?: string
  readonly style?: React.CSSProperties
}

const PaymentCardPanel = ({
  children,
  className = '',
  style,
}: StylingProps) => (
  <div
    className={
      'payment-card-panel card-no-fixed-height center-h w-[320px] max-w-[500px] ' +
      className
    }
    style={style}
  >
    {children}
  </div>
)

export default PaymentCardPanel
