import { AssignmentStatus, AssignmentStatusDisplayNames } from '@breezy/shared'
import React from 'react'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'
type AssignmentStatusTagProps = {
  assignmentStatus: AssignmentStatus
}

export const toAssignmentStatusDetails = (
  status: AssignmentStatus,
): { text: string; color: StatusTagColor } => {
  switch (status) {
    case 'TO_DO':
      return {
        text: AssignmentStatusDisplayNames['TO_DO'],
        color: 'yellow',
      }
    case 'EN_ROUTE':
      return {
        text: AssignmentStatusDisplayNames['EN_ROUTE'],
        color: 'blue',
      }
    case 'IN_PROGRESS':
      return {
        text: AssignmentStatusDisplayNames['IN_PROGRESS'],
        color: 'purple',
      }
    case 'COMPLETED':
      return {
        text: AssignmentStatusDisplayNames['COMPLETED'],
        color: 'green',
      }
    case 'CANCELED':
      return {
        text: AssignmentStatusDisplayNames['CANCELED'],
        color: 'red',
      }
    default:
      return { text: 'Unknown', color: 'gray' }
  }
}

const AssignmentStatusTag = React.memo<AssignmentStatusTagProps>(
  ({ assignmentStatus }) => {
    return (
      <StatusTag
        className="mt-1 h-fit w-fit text-center"
        text={toAssignmentStatusDetails(assignmentStatus).text}
        color={toAssignmentStatusDetails(assignmentStatus).color}
      />
    )
  },
)

export default AssignmentStatusTag
